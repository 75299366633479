import { makeStyles } from '@material-ui/core/styles';

export const UserTaskItemStyle = makeStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        //paddingRight: '1rem'
    },
    button: {
        margin: '1rem 0 0.5rem 1rem',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    taskComponent: {
        //margin: '0.5rem 1rem 0.5rem 1rem'
    },
    dropdownWrapper: {
        marginTop: '0.5rem'
    },
    commentsLabel: {
        marginBottom: '0.5rem'
    }
}));
