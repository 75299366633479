import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { TaskItemStyles } from "../../TaskItemStyles";
import InspectionReportInformationHidden from "../../../../../landing/components/KpiDashboard/navigations/KpiInspection/InspectionReports/ReportDialog/InspectionReportInformationHidden";

const InspectionTask = ({ task }) => {
    const classes = TaskItemStyles();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }

    //change fields show
    return (
        <Grid container direction='column' style={{marginBottom: 10}}>
            {field('Lot', task?.lotName)}
            <InspectionReportInformationHidden inspection={task?.inspectionEntity}></InspectionReportInformationHidden>
            
        </Grid>
    )
}
export default InspectionTask;