import * as React from "react";

function IconOilChange(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.45623 1.6575C9.18623 1.4475 8.81125 1.4475 8.54125 1.6575C7.11625 2.745 2.90874 6.2925 2.93124 10.425C2.93124 13.77 5.65375 16.5 9.00625 16.5C12.3587 16.5 15.0812 13.7775 15.0812 10.4325C15.0887 6.36 10.8737 2.7525 9.45623 1.6575Z" stroke="#0090FE" stroke-width="1.5" stroke-miterlimit="10" />
        </svg>

    );
}

export default IconOilChange;
