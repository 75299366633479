
function SvgIconDirection(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10 18.4583C9.96985 18.4583 9.93857 18.446 9.91293 18.4204C9.88728 18.3947 9.875 18.3634 9.875 18.3333V16.6666C9.875 16.6365 9.88728 16.6052 9.91293 16.5796C9.93857 16.5539 9.96985 16.5416 10 16.5416C10.0301 16.5416 10.0614 16.5539 10.0871 16.5796C10.1127 16.6052 10.125 16.6365 10.125 16.6666V18.3333C10.125 18.3634 10.1127 18.3947 10.0871 18.4204C10.0614 18.446 10.0301 18.4583 10 18.4583Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M10 15.125C9.96985 15.125 9.93857 15.1128 9.91293 15.0871C9.88728 15.0615 9.875 15.0302 9.875 15V13.3334C9.875 13.3032 9.88728 13.2719 9.91293 13.2463C9.93857 13.2207 9.96985 13.2084 10 13.2084C10.0301 13.2084 10.0614 13.2207 10.0871 13.2463C10.1127 13.2719 10.125 13.3032 10.125 13.3334V15C10.125 15.0302 10.1127 15.0615 10.0871 15.0871C10.0614 15.1128 10.0301 15.125 10 15.125Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M10 11.7917C9.96985 11.7917 9.93857 11.7794 9.91293 11.7538C9.88728 11.7281 9.875 11.6969 9.875 11.6667V9.16671C9.875 5.88452 12.5511 3.20837 15.8333 3.20837H18.3333C18.3635 3.20837 18.3948 3.22066 18.4204 3.2463C18.446 3.27195 18.4583 3.30323 18.4583 3.33337C18.4583 3.36352 18.446 3.3948 18.4204 3.42045C18.3948 3.44609 18.3635 3.45837 18.3333 3.45837H15.8333C12.6822 3.45837 10.125 6.01557 10.125 9.16671V11.6667C10.125 11.6969 10.1127 11.7281 10.0871 11.7538C10.0614 11.7794 10.0301 11.7917 10 11.7917Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M9.99996 11.7917C9.96981 11.7917 9.93853 11.7794 9.91289 11.7538C9.88724 11.7281 9.87496 11.6969 9.87496 11.6667V9.16671C9.87496 6.01557 7.31777 3.45837 4.16663 3.45837H1.66663C1.63648 3.45837 1.6052 3.44609 1.57955 3.42045C1.55391 3.3948 1.54163 3.36352 1.54163 3.33337C1.54163 3.30323 1.55391 3.27195 1.57955 3.2463C1.6052 3.22066 1.63648 3.20837 1.66663 3.20837H4.16663C7.44882 3.20837 10.125 5.88452 10.125 9.16671V11.6667C10.125 11.6969 10.1127 11.7281 10.087 11.7538C10.0614 11.7794 10.0301 11.7917 9.99996 11.7917Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M2.19642 2.97973L1.84286 3.33328L2.19642 3.68684L3.42145 4.91184C3.42145 4.91184 3.42145 4.91184 3.42145 4.91184C3.44298 4.93337 3.45625 4.9641 3.45625 4.99995C3.45625 5.0358 3.44297 5.06653 3.42145 5.08806C3.40678 5.10273 3.39273 5.11118 3.37986 5.11636C3.36653 5.12173 3.35099 5.12495 3.33331 5.12495C3.31562 5.12495 3.30009 5.12173 3.28676 5.11636C3.27389 5.11118 3.25984 5.10273 3.24517 5.08806L1.5785 3.4214C1.55698 3.39987 1.5437 3.36913 1.5437 3.33328C1.5437 3.29743 1.55698 3.2667 1.5785 3.24517L3.24517 1.5785C3.26669 1.55699 3.29743 1.5437 3.33331 1.5437C3.36919 1.5437 3.39993 1.55699 3.42145 1.5785L3.775 1.22495L3.42145 1.5785C3.44297 1.60003 3.45625 1.63077 3.45625 1.66662C3.45625 1.70246 3.44298 1.7332 3.42145 1.75473C3.42145 1.75473 3.42145 1.75473 3.42145 1.75473L2.19642 2.97973Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M17.8035 2.97973L16.5785 1.75473C16.5785 1.75473 16.5785 1.75473 16.5785 1.75473C16.557 1.7332 16.5437 1.70246 16.5437 1.66662C16.5437 1.63077 16.557 1.60003 16.5785 1.5785C16.6 1.55699 16.6308 1.5437 16.6666 1.5437C16.7025 1.5437 16.7333 1.55699 16.7548 1.5785L18.4214 3.24517C18.443 3.2667 18.4562 3.29743 18.4562 3.33328C18.4562 3.36913 18.443 3.39987 18.4214 3.4214L16.7548 5.08806C16.7401 5.10273 16.7261 5.11118 16.7132 5.11636C16.6999 5.12173 16.6843 5.12495 16.6666 5.12495C16.649 5.12495 16.6334 5.12173 16.6201 5.11636C16.6072 5.11118 16.5932 5.10273 16.5785 5.08806C16.557 5.06653 16.5437 5.0358 16.5437 4.99995C16.5437 4.9641 16.557 4.93337 16.5785 4.91184C16.5785 4.91184 16.5785 4.91184 16.5785 4.91184L17.8035 3.68684L18.1571 3.33328L17.8035 2.97973Z" fill="#0090FE" stroke="#0090FE" />
    </svg>
  );
}

export default SvgIconDirection;
