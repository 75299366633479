import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    count: {
        color: theme.palette.primary.main,
        fontSize: '3em',
        fontWeight: 700
    },
    text: {
        color: theme.palette.primary.dark,
        fontWeight: 500,
        fontSize: '1.1em',
        marginTop: 0
    },
    cardWrapper: {
        position: 'relative'
    },
    actions: {
        position: 'absolute',
        right: '-1em',
        top: '-1em'
    },
    exportButton: {

    },
}));