import { FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DateRange from 'components/DateRange';
import { DefaultRangesTypes } from 'components/DateRange/DefaultRanges';
import { TimeField } from 'components/TimeField/TimeField';
import { AccessCodeTypes } from 'features/access/accessConsts';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GenerateAccessCodeStyle } from './style';

const AccessCodeParameters = ({ accessToEdit, setAccessToEdit }) => {
    const classes = GenerateAccessCodeStyle()

    const dispatch = useDispatch();

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isOneTime, setIsOneTime] = useState(false);
    const [isAllDay, setIsAllDay] = useState(true);

    const onChangeValue = (key, val) => {
        setAccessToEdit({ ...accessToEdit, [key]: val });
    }

    const onChangeType = (val) => {
        setIsOneTime(val)
        onChangeValue("accessCodeType", val ? AccessCodeTypes.ONE_TIME : AccessCodeTypes.RECURRING);
    }

    const onChangeAllDay = (val) => {
        setIsAllDay(val)
        setAccessToEdit({ ...accessToEdit, ["startTime"]: null, ["endTime"]: null });
    }

    const onDateSelect = (value) => {
        setAccessToEdit({ ...accessToEdit, ["startDate"]: value?.startDate, ["endDate"]: value?.endDate });
    }

    return (
        <Paper className={classes.paramContainer}>
            <div className={classes.paramTitle}>Access Code Parameters</div>
            <Grid container direction='column' spacing={2}>
                <Grid item className={classes.toggleWrapper}>
                    <FormControlLabel
                        control={
                            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                                <Switch
                                    color="primary"
                                    checked={isOneTime}
                                    onChange={(e) => onChangeType(e.target.checked)}
                                />
                            </Authorize>
                        }
                        label={
                            <div className={classes.label}>One time code</div>
                        }
                        className={classes.wrapper}
                    />
                </Grid>
                <Grid item>
                    <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                        <DateRange
                            showPredefinedRanges
                            title={'Date Range'}
                            futureOnly
                            identifier={"dt"}
                            onSelect={onDateSelect}
                            err
                            required
                            dateValue={accessToEdit?.['dateRange']}
                        />
                    </Authorize>
                </Grid>
                {!isOneTime && <Grid item  >
                    <div>Hour Range</div>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid xs={12} item className={classes.toggleWrapper}>
                            <FormControlLabel
                                control={
                                    <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                                        <Switch
                                            color="primary"
                                            checked={isAllDay}
                                            onChange={(e) => onChangeAllDay(e.target.checked)}
                                        />
                                    </Authorize>
                                }
                                label={
                                    <div className={classes.label}>All day</div>
                                }
                                className={classes.wrapper}
                            />
                        </Grid>
                        <Grid item>
                            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                                <TimeField
                                    onChange={(val) => { setStartTime(val); onChangeValue("startTime", moment(new Date(val)).format('HH:mm')) }}
                                    disabled={isAllDay}
                                    value={startTime}
                                />
                            </Authorize>
                        </Grid>
                        <Grid item>
                            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                                <TimeField
                                    onChange={(val) => { setEndTime(val); onChangeValue("endTime", moment(new Date(val)).format('HH:mm')) }}
                                    disabled={isAllDay}
                                    value={endTime}
                                />
                            </Authorize>
                        </Grid>

                    </Grid>
                </Grid>}
            </Grid>
        </Paper>
    );
}

export default AccessCodeParameters;