import { IconCheck, IconSend } from "icons";
import FormatService from "services/formatService"
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { Tooltip } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';


export const ActivityInvoiceStatus = {
    PENDING: 1,
    APPROVED: 2,
    SENT_TO_QB: 3,
    FAILED: 4,
};

export const InvoiceStatuses = {
    [ActivityInvoiceStatus.PENDING]: {
        name: 'Pending',
        color: '#f39a59',
        icon: <HourglassEmptyIcon style={{ fill: 'white' }} />
    },
    [ActivityInvoiceStatus.APPROVED]: {
        name: 'Approved',
        color: '#90C534',
        icon: <IconCheck />
    },
    [ActivityInvoiceStatus.SENT_TO_QB]: {
        name: 'Sent To QB',
        color: '#0DBF45',
        icon: <IconSend />
    },
    [ActivityInvoiceStatus.FAILED]: {
        name: 'Sent To QB',
        color: '#ed782f',
        icon: <IconSend />,
        warningComponent: <Tooltip title="Not all invoices were sent to QB" ><WarningIcon style={{ fill: '#ff6f00' }}/></Tooltip>
    }
}

export const activityHeaderColumns = [
    {
        label: 'Lot',
        size: 4,
        key: 'lot',
        value: (invoice) => invoice?.lotInfo,
    },
    {
        label: 'Total Internal Cost',
        size: 2,
        key: 'totalInternalCost',
        value: (invoice) => FormatService.formatCurrency(parseFloat(invoice?.totalInternalCost).toFixed(2))
    },
    {
        label: 'Total Charge',
        size: 2,
        key: 'totalCharge',
        value: (invoice) => FormatService.formatCurrency(parseFloat(invoice?.totalCharge).toFixed(2))
    },
    {
        label: 'Total Revenue',
        size: 1,
        key: 'totalRevenue',
        value: (invoice) => FormatService.formatCurrency(parseFloat(invoice?.totalRevenue).toFixed(2))
    }
]

export const activityDetailsColumns = [
    {
        label: 'Activity',
        size: 2,
        key: 'activityItemName',
    },
    {
        label: 'Total Internal Cost',
        size: 2,
        key: 'totalInternalCost',
        value: (v) => FormatService.formatCurrency(parseFloat(v).toFixed(2)),
        editable: true
    },
    {
        label: 'Total Charge',
        size: 2,
        key: 'totalCharge',
        value: (v) => FormatService.formatCurrency(parseFloat(v).toFixed(2)),
        editable: true
    },
    {
        label: 'Total Revenue',
        size: 2,
        key: 'totalRevenue',
        value: (v) => FormatService.formatCurrency(parseFloat(v).toFixed(2)),
        // editable: true
    },
    {
        label: 'Tax Rate',
        key: 'taxRate',
        value: (v) => `${parseFloat(v).toFixed(2)} %`,
    },
    {
        label: 'Total',
        key: 'totalRevenueWithTax',
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
]