import { makeStyles } from "@material-ui/core"

export const dropZoneStyle = makeStyles((theme) => ({
    fileContainer: {
        padding: '1em',
        border:'1px solid #e1e1e1',
        '& ul': {
            padding:'0.5em',
            listStyleType: 'none',
            margin: '0',
        },
        '& .dropzone': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& .fileWrapper': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .MuiButtonBase-root': {
                marginLeft: 'auto'
            }
        }
    }
}));