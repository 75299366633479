import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DataListHeader from "components/DataListHeader";
import { useDispatch, useSelector } from "react-redux";
import BillingDashboardExport from "./BillingDashboardExport/BillingDashboardExport";
import { setBillingFilters, setShowAll, setShowCharge, setShowCost } from "./billingSlice";
import { billingDashboardStyle } from "./style";
import BillingDashboardCostChargeFilter from "./BillingDashboardCostChargeFilter";

const BillingDashboardFilters = ({ filterConfig, data }) => {
    const classes = billingDashboardStyle();

    const { filters, showCost, showCharge } = useSelector(state => state.billingDashboardState);
    const dispatch = useDispatch();


    const onFilter = (filterValue, prop) => {
        dispatch(setBillingFilters({ ...filters, [prop]: { value: filterValue } }))
    }

    return (
        <>
            <DataListHeader
                title={<BillingDashboardCostChargeFilter />}
                actionButtons={<BillingDashboardExport data={data} />}
                data={data}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onFilter}
            // onSearch={setSearch}
            // searchVal={search}
            />
        </>
    )
}

export default BillingDashboardFilters;