import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import UsersTasksStyle from "../UsersTasksStyle";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import UserTaskWeeklySchedule from "./UserTaskWeeklySchedule";
import { userTaskInterval } from "../UserTaskItem";

const useStyles = UsersTasksStyle;

const UserTaskItemSchedule = ({ task, setTask }) => {
    const classes = useStyles();

    const [interval, setInterval] = useState(task.interval)

    useEffect(() => {
        setInterval(task.interval)
    }, [task]);

    const toggleInteral = (val) => {
        setTask({ ...task, ["interval"]: val, ["scheduleList"]: null })
        setInterval(val)
    }

    return (
        <div className={classes.scheduleWrapper}>
            <Grid container direction="row" alignItems="center" className={classes.scheduleTitleWrapper}>
                <Grid xs={1} item className={classes.scheduleTitle}>Schedule</Grid>
                <Grid item xs={2} className={classes.intervalToggle}>
                    <ToggleButtonGroup
                        color="primary"
                        value={interval}
                        exclusive
                        aria-label="Platform"
                    >
                        <ToggleButton selected={interval == userTaskInterval.DAILY} onClick={() => toggleInteral(userTaskInterval.DAILY)}>
                            <span >DAILY</span>
                        </ToggleButton>
                        <ToggleButton selected={interval == userTaskInterval.WEEKLY} onClick={() => toggleInteral(userTaskInterval.WEEKLY)}>
                            <span >WEEKLY</span>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={true}
                                checked={task.notifyEmail}
                                onChange={(e, value) => setTask({ ...task, ["notifyEmail"]: value })}
                            />
                        }
                        label={<div className={classes.generateLabel}>Send reminder</div>}
                    />
                </Grid>
            </Grid>
            <UserTaskWeeklySchedule task={task} setTask={setTask} weekly={interval == userTaskInterval.WEEKLY} />


        </div>
    );
}
export default UserTaskItemSchedule;