import React from 'react';
import { LoadGroupJourneyStyles } from './LoadGroupJourneyStyles';
import { Grid } from '@material-ui/core';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import FormatService from 'services/formatService';


function LoadJourneyItem({ load, setIndex }) {
    const classes = LoadGroupJourneyStyles()

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    const { newLots } = useSelector(state => state.loadState);

    const dispatch = useDispatch();

    lots = lots || [];
    lots = [...lots, ...newLots];

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});



    return (
        <Grid container direction='row' onClick={() => setIndex(load.index)}>
            <Grid item className={`${classes.departure} ${classes.journeyItem}`} alignContent='center'>
                <div className={`${classes.departureTitle} ${classes.journeyItemTitle}`}><ArrowUpwardSharpIcon className={`${classes.icon} ${classes.departureIcon}`} /> Departure</div>
                <div className={classes.journeyItemText}>{FormatService.formatDateAndTimeWithFormat(load?.pickupStartDate, "yyyy-MM-DD")}</div>
                <div className={classes.journeyItemText}>{load?.pickupLotName}</div>
            </Grid>
            <Grid item className={`${classes.arrival} ${classes.journeyItem}`}>
                <div className={`${classes.arrivalTitle} ${classes.journeyItemTitle}`}><ArrowDownwardSharpIcon className={`${classes.icon} ${classes.arrivalIcon}`} /> Arrival</div>
                <div className={classes.journeyItemText}>{FormatService.formatDateAndTimeWithFormat(load?.deliveryStartDate, "yyyy-MM-DD")}</div>
                <div className={classes.journeyItemText}>{load?.deliveryLotName}</div>
            </Grid>
        </Grid>
    );
}

export default LoadJourneyItem;


