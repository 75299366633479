import * as React from "react";

function SvgIconGas(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10.57 12.368v.417H16.667V8.004H10.57v4.364zM5.066 25.943h.416V6.118a3.202 3.202 0 0 1 3.202-3.201h9.869a3.202 3.202 0 0 1 3.201 3.201v11.388l.006.034a.58.58 0 0 1 .007.092v2.309a2.054 2.054 0 0 0 4.11 0v-7.682c0-.445-.145-.88-.412-1.237l-1.447-1.93a.57.57 0 0 1 .912-.684l1.447 1.93c.416.554.64 1.228.64 1.921v7.682a3.194 3.194 0 0 1-4.655 2.842l-.608-.312V25.943h.746a.57.57 0 0 1 0 1.14H4.737a.57.57 0 1 1 0-1.14h.329zm15.131 0h.417V6.118a2.062 2.062 0 0 0-2.061-2.061H8.684a2.062 2.062 0 0 0-2.061 2.061V25.943h13.574zM9.597 7.031A.57.57 0 0 1 10 6.864h7.237a.57.57 0 0 1 .57.57v5.921a.57.57 0 0 1-.57.57H10a.57.57 0 0 1-.57-.57v-5.92a.57.57 0 0 1 .167-.404z"
                fill="#323B42"
                stroke="#0090FE"
                stroke-width=".833"
            />
        </svg>
    );
}

export default SvgIconGas;
