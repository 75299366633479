import { useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useGetTaskPicturesByIdQuery } from "../../userTaskSlice";

const PictureSecurity = ({ task, onChange, setValid }) => {
    let { data: pictures, error: tasksError, isFetching: isLoadingPictures, refetch: refectPictures } = useGetTaskPicturesByIdQuery(task?.id, { skip: !task?.id });
    useEffect(() => {
        refectPictures();
    }, [task])
        
    return (
        <>
            
            {
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Carousel dynamicHeight width={350} autoPlay={true} useKeyboardArrows>
                        {pictures?.map((img, i) => (
                            <div key={i} onClick={() => window.open(img.url)}>
                                <img
                                    src={img.url}                                    
                                    alt=""
                                />
                                <p className="legend">{img.createdDate}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            }
        </>
    )
}
export default PictureSecurity;