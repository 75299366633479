import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    input: {
        margin: 16,
    },
    radioLable: {
        paddingRight: 10,
    },
    radioContainer: {
        alignItems: "center",
        margin: 'auto',
        fontSize: 16,
    },
    radioButton: {
        margin: '0.5rem',
    },
    lot: {
        border: "solid 1px lightgrey",
        borderRadius: '5px',
        marginBottom: 20
    },
    lotAddress: {
        paddingLeft: '1.5rem'
    },
    openingHoursButton: {
        fontWeight: 600,
        padding: "0.5rem",
        marginLeft: "-0.5rem",
        marginBottom: "0.5rem"
    },
    openingHoursContainer: {
        padding: "0.5rem",
        minWidth: "170px"
    },
    openingHours: {
        margin: "0.5rem",
        fontSize: "0.rem",
        '& label': {
            fontWeight: 'bold',
            margin: "-0.5rem",
            fontSize: "0.85rem",
            padding: "0.5rem"
        }
    },
    openingHoursDivider: {
        margin: "0.5rem"
    },
    existingLotsContainer: {
        margin: '16px',
        padding: '0.5em 1em',
        borderRadius: '5px',
        border: '1px solid #36a03b',
        background: '#e4ffe4'
    },
    existingLotsTitle: {
        fontWeight: 'bold',
        padding: '0.4em 0',
        fontSize: 16
    },
    phoneInput: {
        margin: 16,
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        '& fieldset': {
            border: 'none'
        }
    },
    buttonSchedule: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        margin: 10,
        border: 0
    }
}));
