import { makeStyles } from "@material-ui/core";

export const readinessConfigStyles = makeStyles(theme => ({
    colorBox: {
        // height: '4em',
        // width: '2em'
    },
    configsContainer: {
        margin: '1em 0 1em 0'
    }
}));