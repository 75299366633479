import * as React from "react";

function IconCheckEngineLight(props) {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.87985 5.65967C6.87985 5.24545 7.21564 4.90967 7.62985 4.90967H15.6899C16.1041 4.90967 16.4399 5.24545 16.4399 5.65967C16.4399 6.07388 16.1041 6.40967 15.6899 6.40967H13.8498V7.26971H15.2399C15.8987 7.26971 16.3799 7.81052 16.3799 8.41971V9.50971H18.4799C19.1386 9.50971 19.6199 10.0505 19.6199 10.6597V10.8897H19.7199V10.2897H20.0179C20.2258 10.0465 20.5331 9.88971 20.8799 9.88971H21.8399C22.4987 9.88971 22.9799 10.4305 22.9799 11.0397V18.1197C22.9799 18.7416 22.4763 19.2697 21.8399 19.2697H20.8799C20.272 19.2697 19.8154 18.8094 19.7484 18.2597H19.5699V19.3897C19.5699 20.0116 19.0663 20.5397 18.4299 20.5397H11.3599C11.1545 20.5397 10.9453 20.4829 10.7611 20.365L7.27131 18.2497H4.54985C3.89105 18.2497 3.40985 17.7089 3.40985 17.0997V14.0597H2.59985V17.3397C2.59985 17.7539 2.26407 18.0897 1.84985 18.0897C1.43564 18.0897 1.09985 17.7539 1.09985 17.3397V9.28967C1.09985 8.87546 1.43564 8.53967 1.84985 8.53967C2.26407 8.53967 2.59985 8.87546 2.59985 9.28967V12.5597H3.40985V9.99971C3.40985 9.37785 3.9134 8.84971 4.54985 8.84971H5.85985V8.41971C5.85985 7.79785 6.36339 7.26971 6.99985 7.26971H9.45984V6.40967H7.62985C7.21564 6.40967 6.87985 6.07388 6.87985 5.65967ZM10.9598 6.40967V7.25967H12.3498V6.40967H10.9598ZM21.0811 11.7897H21.2399V11.3897H21.4799V17.7697H21.2313C21.1625 17.2106 20.6879 16.7597 20.0999 16.7597H19.2099C18.551 16.7597 18.0699 17.3005 18.0699 17.9097V19.0397H11.4684L7.97861 16.9244C7.79442 16.8065 7.5852 16.7497 7.37985 16.7497H4.90985V10.3497H6.21985C6.87865 10.3497 7.35985 9.8089 7.35985 9.19971V8.76971H14.8799V9.85971C14.8799 10.4816 15.3834 11.0097 16.0199 11.0097H18.1199V11.2397C18.1199 11.8616 18.6234 12.3897 19.2599 12.3897H20.0799C20.5271 12.3897 20.8925 12.1404 21.0811 11.7897Z" fill="#0090FE" />
        </svg>

    );
}

export default IconCheckEngineLight;
