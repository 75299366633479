import { makeStyles } from "@material-ui/core"

export const loadPaymentsStyle = makeStyles((theme) => ({
    paymentWrapper: {
        display: 'flex'
    },
    paymentAmount: {
        marginLeft: '1em',
        fontWeight: 'bold'
    }
}));
