import { makeStyles } from "@material-ui/core";

export const vehicleMapMarkerStyle = makeStyles((theme) => ({
    markerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0.1rem'
    },
    textWrapper: {
        borderColor: '#0061AA',
        background: '#0061AA',
        padding: '3px 6px',
        color: 'white',
        borderRadius: '4px',
    },
    coloredTextWrapper: {
        borderColor: '#E02B1D',
        background: '#E02B1D',
    },
    vehicleIcon: {
        position: 'absolute',
        marginTop: '-6px',
        // '& path': {
        //     stroke: 'white',
        // }
    }
}));
