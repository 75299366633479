import * as React from "react";

function IconClock(props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_2400_13590" fill="white">
        <path d="M6 11.375C3.035 11.375 0.625 8.965 0.625 6C0.625 3.035 3.035 0.625 6 0.625C8.965 0.625 11.375 3.035 11.375 6C11.375 8.965 8.965 11.375 6 11.375ZM6 1.375C3.45 1.375 1.375 3.45 1.375 6C1.375 8.55 3.45 10.625 6 10.625C8.55 10.625 10.625 8.55 10.625 6C10.625 3.45 8.55 1.375 6 1.375Z" />
      </mask>
      <path d="M6 11.375C3.035 11.375 0.625 8.965 0.625 6C0.625 3.035 3.035 0.625 6 0.625C8.965 0.625 11.375 3.035 11.375 6C11.375 8.965 8.965 11.375 6 11.375ZM6 1.375C3.45 1.375 1.375 3.45 1.375 6C1.375 8.55 3.45 10.625 6 10.625C8.55 10.625 10.625 8.55 10.625 6C10.625 3.45 8.55 1.375 6 1.375Z" fill="#FF5A4F" />
      <path d="M6 10.375C3.58728 10.375 1.625 8.41271 1.625 6H-0.375C-0.375 9.51728 2.48272 12.375 6 12.375V10.375ZM1.625 6C1.625 3.58728 3.58728 1.625 6 1.625V-0.375C2.48272 -0.375 -0.375 2.48272 -0.375 6H1.625ZM6 1.625C8.41271 1.625 10.375 3.58728 10.375 6H12.375C12.375 2.48272 9.51728 -0.375 6 -0.375V1.625ZM10.375 6C10.375 8.41271 8.41271 10.375 6 10.375V12.375C9.51728 12.375 12.375 9.51728 12.375 6H10.375ZM6 0.375C2.89772 0.375 0.375 2.89772 0.375 6H2.375C2.375 4.00228 4.00228 2.375 6 2.375V0.375ZM0.375 6C0.375 9.10228 2.89772 11.625 6 11.625V9.625C4.00228 9.625 2.375 7.99771 2.375 6H0.375ZM6 11.625C9.10228 11.625 11.625 9.10228 11.625 6H9.625C9.625 7.99771 7.99771 9.625 6 9.625V11.625ZM11.625 6C11.625 2.89772 9.10228 0.375 6 0.375V2.375C7.99771 2.375 9.625 4.00228 9.625 6H11.625Z" fill="#FF5A4F" mask="url(#path-1-inside-1_2400_13590)" />
      <mask id="path-3-inside-2_2400_13590" fill="white">
        <path d="M7.85495 7.965C7.78995 7.965 7.72495 7.95 7.66495 7.91L6.11495 6.985C5.72995 6.755 5.44495 6.25 5.44495 5.805V3.755C5.44495 3.55 5.61495 3.38 5.81995 3.38C6.02495 3.38 6.19495 3.55 6.19495 3.755V5.805C6.19495 5.985 6.34495 6.25001 6.49995 6.34L8.04995 7.265C8.22995 7.37 8.28495 7.60001 8.17995 7.78C8.10495 7.9 7.97995 7.965 7.85495 7.965Z" />
      </mask>
      <path d="M7.85495 7.965C7.78995 7.965 7.72495 7.95 7.66495 7.91L6.11495 6.985C5.72995 6.755 5.44495 6.25 5.44495 5.805V3.755C5.44495 3.55 5.61495 3.38 5.81995 3.38C6.02495 3.38 6.19495 3.55 6.19495 3.755V5.805C6.19495 5.985 6.34495 6.25001 6.49995 6.34L8.04995 7.265C8.22995 7.37 8.28495 7.60001 8.17995 7.78C8.10495 7.9 7.97995 7.965 7.85495 7.965Z" fill="#FF5A4F" />
      <path d="M7.66495 7.91L8.21965 7.07795L8.19886 7.0641L8.1774 7.05129L7.66495 7.91ZM6.11495 6.985L5.60209 7.84348L5.60249 7.84372L6.11495 6.985ZM6.49995 6.34L7.01244 5.48123L7.00208 5.47522L6.49995 6.34ZM8.04995 7.265L7.53746 8.12376L7.54608 8.12878L8.04995 7.265ZM8.17995 7.78L9.02795 8.31L9.03603 8.29706L9.04373 8.28388L8.17995 7.78ZM7.85495 6.965C7.94947 6.965 8.08644 6.98915 8.21965 7.07795L7.11025 8.74205C7.36345 8.91086 7.63043 8.965 7.85495 8.965V6.965ZM8.1774 7.05129L6.6274 6.12629L5.60249 7.84372L7.15249 8.76872L8.1774 7.05129ZM6.6278 6.12653C6.54562 6.07744 6.44495 5.89886 6.44495 5.805H4.44495C4.44495 6.60115 4.91427 7.43257 5.60209 7.84348L6.6278 6.12653ZM6.44495 5.805V3.755H4.44495V5.805H6.44495ZM6.44495 3.755C6.44495 4.10229 6.16723 4.38 5.81995 4.38V2.38C5.06266 2.38 4.44495 2.99772 4.44495 3.755H6.44495ZM5.81995 4.38C5.47266 4.38 5.19495 4.10229 5.19495 3.755H7.19495C7.19495 2.99772 6.57723 2.38 5.81995 2.38V4.38ZM5.19495 3.755V5.805H7.19495V3.755H5.19495ZM5.19495 5.805C5.19495 6.13172 5.31551 6.41678 5.42008 6.60064C5.52393 6.78324 5.70939 7.03732 5.99781 7.20479L7.00208 5.47522C7.08014 5.52054 7.12162 5.56512 7.13407 5.57912C7.14963 5.59661 7.15621 5.60774 7.15857 5.61187C7.16096 5.61608 7.16668 5.62663 7.17331 5.64737C7.17857 5.66382 7.19495 5.71894 7.19495 5.805H5.19495ZM5.98749 7.19872L7.53749 8.12372L8.5624 6.40629L7.0124 5.48129L5.98749 7.19872ZM7.54608 8.12878C7.2265 7.94237 7.15621 7.55035 7.31617 7.27613L9.04373 8.28388C9.41368 7.64966 9.23339 6.79764 8.55382 6.40123L7.54608 8.12878ZM7.33195 7.25001C7.43888 7.07892 7.63363 6.965 7.85495 6.965V8.965C8.32626 8.965 8.77101 8.72109 9.02795 8.31L7.33195 7.25001Z" fill="#FF5A4F" mask="url(#path-3-inside-2_2400_13590)" />
    </svg>

  );
}

export default IconClock;
