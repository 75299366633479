import moment from "moment";
import sanitizeHtml from 'sanitize-html';

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const dateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

const FormatService = {
    fromLocalDateToUtcNoConversion: date => new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString(),
    formatDate: date => date ? new Date(date).toISOString().split('T')[0] : "",
    formatDateAndTimeWithFormat: (date, format) => date ? moment(date).format(format) : "",
    formatDateTime: date => date ? moment.utc(date).local().format("yyyy-MM-DD, hh:mm a") : "",//date ? new Date(date).toISOString()?.substring(0, 16).replace('T', ' ') : "",
    formatDateTimeNoConversion: date => date ? new Date(moment(date).format("yyyy-MM-DD, hh:mm a")).toLocaleString({}, dateTimeFormatOptions) : "",
    formatDateNoConversion: date => date ? moment(date).format("yyyy-MM-DD") : "",
    formatDateNoConversionSpecifyFormat: date => date ? moment(date,"DD/MM/YYYY").format("yyyy-MM-DD") : "",
    formatFullDate: date => date ? moment(date).format("DD MMM yyyy") : "",
    formatToDatePicker: date => date ? moment(date).format("yyyy-MM-DD") : "",
    formatNumberWithCommas: x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    formatToMonth: x => moment(x).format("MMM"),
    formatToMonthAndYear: x => moment(x).format("MMM, yyyy"),
    formatDateByTimeZone: (date, timeZone) => date ? new Date(moment(date).format("MMM DD, yyyy, hh:mm a") + " UTC").toLocaleString('en-US', { timeZone: timeZone }) : "",
    formatDuration: seconds => {
        let duration = moment.duration(seconds ?? 0, 'seconds');
        return duration.asHours() > 24 ? `${Math.round(duration?.asDays())} days` : ` ${Math.round(duration.asHours())} hrs`
    },
    getMonths: () => ([
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'Áugust' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
    ]),
    getYears: (numOfYears, past = true, future = true) => {
        const rangeOfYears = (start, end, decrement) => {
            return Array(decrement ? start - end + 1 : end - start + 1)
                .fill(start)
                .map((year, index) => decrement ? year - index : year + index)
        }
        const curYear = new Date().getFullYear();
        return [
            ...(past ? rangeOfYears(curYear, curYear - numOfYears, true) : []),
            ...(future ? rangeOfYears(past ? curYear + 1 : curYear, curYear + numOfYears) : []),//If displaying past and future current year will be added by past
        ].sort();
    },
    formatCurrency: val => val || val === 0 ? currencyFormatter.format(val) : '',
    formats: {
        USDateTimeFormat: "MM/DD/YYYY hh:mm a"
    },
    getLast12Months: () => {
        let months = [];
        let d = new Date();
        d.setDate(1);
        for (let i = 12; i > 0; i--) {
            months.unshift({
                id: i,
                month: d.getMonth() + 1,
                year: d.getFullYear(),
                date: new Date(d)
            })
            d.setMonth(d.getMonth() - 1);
        }
        return months;
    },
    cleanHtmlTags: htmlContent => {
        return sanitizeHtml(htmlContent, {
            allowedTags: [],
            allowedAttributes: [],
            nonBooleanAttributes: [],
            // parser: {
            //     decodeEntities: false//If we don't set this to false, the &nbsp will be converted to &ampnbsp
            // },
        });
    },
};

export default FormatService;
