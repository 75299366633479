import { Grid } from "@material-ui/core";
import GraphHeader from "features/landing/components/WorkorderDashboard/GraphHeader/GraphHeader";
import { GraphLoader } from "features/landing/components/WorkorderDashboard/GraphLoader";
import { workorderDashboardPageStyles } from "features/landing/components/WorkorderDashboard/WorkorderDashboardPageStyles";
import { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const ActiveUsersGraphic = ({ users, loading, roles }) => {
    const usersFilter = users || [];
    
    const classes = workorderDashboardPageStyles();
    const [graphValue, setGraphValue] = useState([]);
    const RADIAN = Math.PI / 180;
    
    const getGraphByUserType = async() => {
        let allUsersList = [...usersFilter];
        let allRolesList = [...roles];
        allUsersList = allUsersList?.filter((user) => user?.assignedRole && user?.assignedRole?.length > 0 && !user?.isInactiveUser);

        const result = allRolesList?.map((role) => {
            let count = 0;
            allUsersList?.map((user) => {
                const usersFound = user?.assignedRole?.filter((x) => x.roleId === role.key);
                if (usersFound && usersFound?.length > 0) {
                    count += 1;
                }
            });
            return {
                name: role?.normalizedName,
                value: count
            };
        });
        return result?.filter((val) => val?.value > 0);
    }

    useEffect(() => {
        if (!usersFilter) return;
        const buildData = async () => {
            let chartVal = await getGraphByUserType();
            setGraphValue(chartVal);
        }
        buildData();
    }, [usersFilter]);

    const renderColorfulLegendText = (value) => {
        return (
            <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
                {value}
            </span>
        );
    };

    const CustomTooltip = ({ payload }) => {
        return (
            <div>
                <div className={classes.tooltip} >
                    <p>
                        <b>{payload?.[0]?.payload?.name} </b>
                        {payload?.[0]?.payload?.value}
                    </p>
                </div>
            </div>
        );
    };

    
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const graphColors = ['#0090FE', '#AADAFF', '#A6F6E3', 'black', 'red', 'green'];

    return (
        <Grid container direction="column" className={classes.graphWrapper} style={{ height: 350 }}>
            <Grid item>
                <GraphHeader
                    title={'Active Users'}
                />
            </Grid>
            <Grid item style={{ height: 300 }}>
                <ResponsiveContainer >
                    <PieChart>
                        <Pie
                            data={graphValue}
                            dataKey="value"
                            nameKey="name"
                            cx={"50%"} cy={"50%"}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            paddingAngle={0.5} >
                            {graphValue.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={graphColors[index % graphColors.length]} />
                            ))}
                        </Pie>
                        <Legend
                            height={36}
                            iconType="circle"
                            layout="horizontal"
                            verticalAlign="bottom"
                            iconSize={10}
                            formatter={renderColorfulLegendText}
                        />
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
            <GraphLoader loading={loading} />
        </Grid>
    )
}

export default ActiveUsersGraphic;