import { Button } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import { useState } from 'react';
import { DriverStyles } from './DriverStyles';
import PhoneInput from 'components/PhoneInput';
import { useAddDriverMutation } from 'features/drivers/driversSlice';
import NotificationProvider from 'components/NotificationProvider';

const AddDriver = ({ accessToEdit, driversToEdit, setDriversToEdit, handleClose, onSave }) => {
    const classes = DriverStyles()
    
    let [driverToAdd, setDriverToAdd] = useState();

    const [addDriver, { isLoading: isAdding }] = useAddDriverMutation();

    const onChange = (key, val) => {
        setDriverToAdd({ ...driverToAdd, [key]: val });
    };

    const onSubmit = async () => {
        let newDriver = driverToAdd;
        newDriver = {...newDriver, ["vendorId"]: accessToEdit?.carrierId};
        if(accessToEdit?.id) {
            let result = await addDriver(newDriver);
            if (result && !result.error) {
                NotificationProvider.success("Driver added successfully");
                await onSave(result?.data, newDriver);
            } else {
                NotificationProvider.error("Failed to save the driver");
            }
        } else {
            setDriversToEdit([...driversToEdit, newDriver])
        }
        onClose();
    };

    const onClose = () => {
        setDriverToAdd(null);
        handleClose();
    }

    const isValid = () => driverToAdd?.firstName && driverToAdd?.lastName


    return (
        <>
            <div className={classes.input}>
                <CustomInput
                    id="firstName"
                    label="First Name"
                    value={driverToAdd?.firstName}
                    elementType="input"
                    onChange={val => onChange("firstName", val)}
                />
            </div>
            <div className={classes.input}>
                <CustomInput
                    id="lastName"
                    label="Last Name"
                    value={driverToAdd?.lastName}
                    elementType="input"
                    onChange={val => onChange("lastName", val)}
                />
            </div>
            <div className={classes.input}>
                <CustomInput
                    id="email"
                    label="Email"
                    value={driverToAdd?.email}
                    elementType="input"
                    onChange={val => onChange("email", val)}
                />
            </div>
            <div className={classes.input}>
                <PhoneInput
                    id="phone"
                    label="Phone"
                    value={driverToAdd?.phone}
                    onChange={val => onChange("phone", val)}
                />
            </div>

            <div className={classes.btnContainer}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isAdding || !isValid()}
                        className={classes.button}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </div>
            </div>

        </>
    );
}

export default AddDriver;