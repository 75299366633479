import { makeStyles } from "@material-ui/core"

export const CreateLoadGroupStyles = makeStyles((theme) => ({
    name: {
        margin: 20
    },
    groupContainer: {
        padding: 20
    },
    button: {
        marginLeft: "1em",
        marginBottom: "1em",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark },
        '&:hover': {
            background: theme.palette.primary.dark,
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    errorWrapper: {
        maxWidth: '20em',
        padding: '0.5em 1em',
        zIndex: '10',
        position: 'fixed',
        right: '2em',
        top: '5em',
    },
    errors: {
        maxHeight: '30em',
        flex: 1,
        overflowY: 'auto',
    },
    warningAlert: {
        margin: 16,
        position: 'absolute',
        float: 'left',
        zIndex: 2,
        marginTop: '4.6rem'
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center',
        marginTop: '1rem'
    }
}));
