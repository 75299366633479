import { Tooltip } from '@material-ui/core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IconCar, IconLot } from 'icons';
import { searchStyle } from './SearchStyle';

function SearchMode({ searchMode, setSearchMode }) {
    const classes = searchStyle();

    const handleChange = (e, newVal) => {
        if (newVal)
            setSearchMode(newVal)
    }

    const getSelectedColor = (item) => {
        if (searchMode == item)
            return { backgroundColor: '#DBE9F6' };
    }

    return (
        <>
            <ToggleButtonGroup
                value={searchMode}
                exclusive
                color="primary"
                onChange={handleChange}
                aria-label="Platform"
                className={classes.searchMode}
            >
                <Tooltip title='Search for location' placement='top' arrow>
                    <ToggleButton value="location" aria-label="left aligned" sx={getSelectedColor('location')}>
                        <IconLot />
                    </ToggleButton>
                </Tooltip>
                <Tooltip title='Search for an asset' placement='top' arrow sx={getSelectedColor('asset')}>
                    <ToggleButton value="asset" aria-label="right aligned">
                        <IconCar />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </>
    );
}

export default SearchMode;