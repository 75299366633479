import { Button } from '@material-ui/core';
import { useGetreservationByIdQuery } from 'features/Reservation/reservationSlice';
import { ReservationView } from 'features/Reservation/ReservationView/ReservationView';
import { IconReservations } from 'icons';
import { useState } from 'react';
import { reservationButtonStyle } from './ReservationButtonStyle';

export default function ReservationButton({ reservationId }) {
    const classes = reservationButtonStyle();
    
    let { data: reservations, error: reservationError, isLoading: isLoadingReservation } = useGetreservationByIdQuery(reservationId, { skip: !reservationId });

    var reservation = reservations?.[0];

    const [open, setOpen] = useState(false);

    return (
        <div>
            {reservation && <Button onClick={() => setOpen(true)}>
                <IconReservations />
            </Button>}
            {open && reservation && <ReservationView
                reservation={reservation}
                onClose={() => setOpen(false)}
            />}
        </div >
    );
}
