import { Grid } from '@material-ui/core';
import { CardsStyle } from '../CardsStyle';
import PocError from './PocError/PocError';

const PocInfo = ({ access }) => {

    const classes = CardsStyle()

    return (
        <>
            <Grid item container direction='column' className={classes.cardItem}>
                <Grid item className={classes.data}>
                    {access.pocName && <><label className={classes.title}>POC: </label>
                        <label className={classes.data}>{access?.pocName}</label></>}
                </Grid>
                {access.pocEmail && <Grid item className={classes.data}>email: {access?.pocEmail}</Grid>}
                {access.pocPhone && <Grid item className={classes.data}>phone: {access?.pocPhone}</Grid>}
                <PocError access={access} />
            </Grid>
        </>
    );
}

export default PocInfo



