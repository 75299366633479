import * as React from "react";

function SvgIconPaint(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m6.78 7.74 1.452-3.99 15.043 5.475-5.24 14.397a2.627 2.627 0 0 1-3.366 1.57l-10.107-3.68a2.63 2.63 0 0 1-1.57-3.365l3.189-8.76L2.5 7.87l.667-1.62L6.78 7.74zm9.433 15.575a.875.875 0 0 0 .177-.292l2.464-6.77H5.544l-.906 2.492a.875.875 0 0 0 .523 1.123l10.107 3.678a.874.874 0 0 0 .945-.23zM7.801 10.053l-1.619 4.45h13.285v.066l1.564-4.297L9.28 5.994 8.4 8.406l2.003.825a1.786 1.786 0 1 1-.615 1.64l-1.988-.818zm15.594 7.842.684-.856.684.856c.457.572 2.737 3.514 2.737 5.247 0 1.994-1.535 3.617-3.421 3.617-1.887 0-3.421-1.623-3.421-3.617 0-1.733 2.28-4.675 2.737-5.247zm-.986 5.247c0 1.029.749 1.866 1.67 1.866.92 0 1.67-.837 1.67-1.866 0-.64-.847-2.074-1.67-3.24-.823 1.167-1.67 2.601-1.67 3.24z"
                fill="#0090FE" />
        </svg>
    );
}

export default SvgIconPaint;
