import { Grid } from "@material-ui/core";
// import { Draggable } from "react-beautiful-dnd";
import { List } from 'react-virtualized';
import KanbanCard from "./KanbanCard";
import { KanbanTableStyle } from "./KanbanTableStyle";
import { useDraggable } from "@dnd-kit/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const KanbanTableColumn = ({
    columnData,
    columnId,
    setIssuesTrackerToEdit,
    columns
}) => {
    const classes = KanbanTableStyle();

    function rowRenderer({
        key,
        index,
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style
    }) {
        const issue = columnData?.[index];
        if (!issue) return null;

        return (
            <div key={key} className={classes.rowWrapper} style={style}>
                <Grid item key={key}>
                    <Draggable index={index}
                        draggableId={issue?.id?.toString()}
                        key={issue?.id?.toString()}
                        columnId={columnId}
                        issue={issue}
                        setIssuesTrackerToEdit={setIssuesTrackerToEdit} >
                        {/* {(provided, snapshot) => ( */}
                        <div
                            //{...provided.dragHandleProps}
                            //{...provided.draggableProps}
                            //ref={provided.innerRef}
                            key={key}
                        // style={{
                        //     ...provided.draggableProps.style,
                        //     left: "auto !important",
                        //     top: "auto !important",
                        //     zIndex: 9999,
                        // }}
                        >
                            {/* <KanbanCard issue={issue} setIssuesTrackerToEdit={setIssuesTrackerToEdit} key={key} /> */}
                        </div>
                        {/* )} */}
                    </Draggable>
                </Grid>
            </div>
        );
    }

    return (
        <>
            <List
                width={columns?.length < 5 ? 300 : 250} //TODO - make dynamic
                height={515}
                rowCount={columnData?.length ?? 1}
                rowHeight={460}
                rowRenderer={rowRenderer}
                className={classes.scrollContainer}
            />
        </>
    );
}
export default KanbanTableColumn;

function Draggable({ draggableId, columnId, issue, setIssuesTrackerToEdit }) {
    const classes = KanbanTableStyle();

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: draggableId,
        data: {
            originalColumnId: columnId
        }
    });

    const style = transform ? {
        // transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;


    return (
        <div ref={setNodeRef} style={style} {...attributes}>
            <KanbanCard
                issue={issue}
                setIssuesTrackerToEdit={setIssuesTrackerToEdit}
                dragHandle={
                    <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
                        <div {...listeners} {...attributes} className={classes.dragHandler}>
                            <DragIndicatorIcon color="secondary" />
                        </div>
                    </Authorize>
                }
            />
        </div>
    );
}