import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const reservationPermissionProfile = {
    VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [permissions.RESERVATIONS.VIEW]
    },
    EDIT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.RESERVATIONS.EDIT]
    }
}