import {userTaskStatuses} from "../../userTaskConsts";

const LinkByComment =({ task, taskStatus }) => {    
    return(
        <>
            {taskStatus === userTaskStatuses.Complete && task?.comments !== "" &&
                <div>
                    <a href={task?.comments} target="_blank" >
                        Click me to see {task?.title}
                    </a>
                </div>
            }
        </>
    )
}

export default LinkByComment;