import { Grid, useMediaQuery } from "@material-ui/core";
import { VehicleDocumentsStyle } from "./style";
import FormatService from "services/formatService";
import mobileBreakpoint from "utils/mobileBreakpoint";
import FadeInImage from "features/vehicles/components/FadeInImage";
import LoadAttachments from "features/loads/components/LoadAttachments";
import { useState } from "react";
import { useDeleteDocumentMutation, useDeleteVehicleImageMutation, useGetImageTypesQuery, useUpdateImageMutation } from "features/vehicles/vehicleSlice";
import NotificationProvider from "components/NotificationProvider";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { NoteEntityTypes } from "features/notes/notesConsts";

export const VehicleDocuments = ({ vehicleDetails, latestLoad }) => {

    const classes = VehicleDocumentsStyle();
    let [deleteImage] = useDeleteVehicleImageMutation();
    let [deleteDocument] = useDeleteDocumentMutation();
    let [updateImageType] = useUpdateImageMutation();

    let {
        data: imageTypes,
        error: imageTypesError,
        isLoading: isLoadingImageTypes
    } = useGetImageTypesQuery();

    let [existingImages, setExistingImages] = useState();
    const isMobile = useMediaQuery(mobileBreakpoint);
    let images = vehicleDetails?.images || [];
    images = images.map(a => ({
        ...a,
        createdDate: FormatService.formatDate(a.createdDate)
    }))
    images = images.sort((a, b) => (new Date(a.createdDate) - new Date(b.createdDate)));

    if (!existingImages) {
        setExistingImages(images);
    }

    const groupedImagesByDate = {};

    images.forEach(img => {
        if (!groupedImagesByDate[img.createdDate]) groupedImagesByDate[img.createdDate] = [];
        groupedImagesByDate[img.createdDate].push(img);
    });

    let imagesDates = Object.keys(groupedImagesByDate);
    let vehicleDocuments = vehicleDetails?.documents;

    const onDeleteConfirm = async (img, isDocument) => {
        const imageToDelete = { ...img, createdDate: new Date(img.createdDate) }
        const res = isDocument ? await deleteDocument(imageToDelete) : await deleteImage(imageToDelete);
        if (res.error) {
            NotificationProvider.error("Failed to delete the image");
        } else {
            NotificationProvider.success("Successfully deleted the image");
        }
    }

    const changeImageTypeId = (image) => {
        setExistingImages([...existingImages].map((img) => {
            if (img.id == image.id) {
                return {
                    id: img.id,
                    url: img.url,
                    createdDate: img.createdDate,
                    imageTypeId: image.imageTypeId,
                    fileName: image.fileName
                };
            }
            else {
                return img;
            }
        }));
    };

    const onEditConfirm = async (img, isDocument) => {
        let imageToUpdate = existingImages.find(x => x.id == img.id);
        let res = await updateImageType({
            "id": imageToUpdate.id,
            "vehicleId": vehicleDetails?.id,
            "filename": imageToUpdate.fileName,
            "file_type": "image/png",
            "FileUrl": imageToUpdate.url,
            "ImageTypeId": imageToUpdate.imageTypeId
        });

        if (res.error) {
            NotificationProvider.error("Failed to change the image type");
        } else {
            res = res.data;
            img.url = res.fileUrl;
            img.id = res.id;
            img.imageTypeId = imageToUpdate.imageTypeId;
            img.fileName = imageToUpdate.fileName;
            setExistingImages(images);
            NotificationProvider.success("Successfully changed the image type");
        }
    }
    
    const hasFiles = !!(vehicleDocuments?.length ?? 0 + images?.length ?? 0);
    
    return (
        <>
            <Grid container>
                {!hasFiles && <EmptyPlaceholder text='No Files for Asset' />}
                {imagesDates.map((imageDate) =>
                    <Grid container direction="column">
                        <Grid item>
                            <div>Date: {!!imageDate ? imageDate : 'Unknown'}</div>
                        </Grid>
                        <Grid container>
                            {groupedImagesByDate[imageDate].map((img, i) =>
                                <Grid item key={i} className={isMobile && classes.imageMobile}>
                                    <FadeInImage
                                        img={img}
                                        alt={`Vehicle Image ${i}`}
                                        onDelete={onDeleteConfirm}
                                        onEdit={onEditConfirm}
                                        imageTypes={imageTypes}
                                        changeImageTypeId={changeImageTypeId}
                                        existingImages={existingImages}
                                        showCarousel={true}
                                        imagesForCarousel={groupedImagesByDate}
                                        noteType={NoteEntityTypes.VEHICLE_IMAGE}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
                {latestLoad?.id && <Grid container direction="column">
                    <Grid container className={classes.loadAttachments}>
                        <LoadAttachments groupId={latestLoad?.groupId} loads={[latestLoad]} placeholder={<></>} showLoading={false} hasTitle={true} />
                    </Grid>
                </Grid>}
            </Grid>
            {!!vehicleDocuments?.length &&
                <Grid container>
                    {<Grid container direction="column">
                        <Grid item>
                            <div className={classes.docTitle}>Vehicle Documents</div>
                        </Grid>
                        <Grid container>
                            {vehicleDocuments?.map((img, i) =>
                                <Grid item key={i} className={isMobile && classes.imageMobile}>
                                    <FadeInImage
                                        img={{ ...img, fileName: img.filename }}
                                        alt={`Vehicle Document ${i}`}
                                        isDocument={true}
                                        showFileName
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>}
                </Grid>
            }
        </>)
}