import { Grid } from '@material-ui/core';
import { Alert } from "@mui/material";
import CustomInput from "components/CustomInput";
import DropdownFilter from 'components/DropdownFilter';
import { selectIsClient } from 'features/user/userSlice';
import { useSelector } from "react-redux";
import { CreateWorkOrderStyles } from '../CreateWorkOrderStyles';

const ActivityType = ({ activity, setActivity, customActivity, setCustomActivity, isCustomActivity, activityItems, activityType, setActivityType, activityTypes, errors }) => {
    const classes = CreateWorkOrderStyles();

    const isClient = useSelector(selectIsClient);

    const activityItemsByType = activityItems?.filter(activity => activity.activityTypeId == activityType);
    const filteredActivityItems = isClient ? activityItemsByType?.filter(activity => !activity.isCustom) : activityItemsByType;

    return (
        <Grid container direction='row' spacing={1}>
            <Grid item xs={12} className={classes.activityLabel}>
                Activity Type
            </Grid>
            <Grid item xs={12}>
                <DropdownFilter
                    id="activityType"
                    value={activityType}
                    onSelect={(val) => setActivityType(val?.id)}
                    elementType="dropdown"
                    values={activityTypes}
                    withTypeAhead
                    newAutoCompleteStyle
                />
            </Grid>
            {!!errors['activityType'] &&
                <Grid xs={12} className={classes.alertWrapper}>
                    <Alert severity='error' >
                        {errors['activityType']}
                    </Alert>
                </Grid>}
            <Grid item xs={12} className={classes.activityLabel}>
                Activity
            </Grid>
            {!activityType && <Grid item xs={12} >
                Please select activity type
            </Grid>}
            <Grid item xs={12}>
                <DropdownFilter
                    id="activity"
                    value={activity}
                    onSelect={(val) => setActivity(val?.id)}
                    elementType="dropdown"
                    values={filteredActivityItems}
                    withTypeAhead
                    newAutoCompleteStyle
                    disabled={!activityType}
                />
            </Grid>
            {!!errors['activity'] &&
                <Grid xs={12} className={classes.alertWrapper}>
                    <Alert severity='error' >
                        {errors['activity']}
                    </Alert>
                </Grid>}
            {isCustomActivity() && <>
                <Grid item xs={12}>
                    <CustomInput
                        className={classes.otherActivity}
                        id="ddlCustomActivity"
                        label="Custom"
                        elementType="input"
                        value={customActivity}
                        required={true}
                        onChange={val => setCustomActivity(val)}
                    />
                </Grid>
                {!!errors['customActivity'] &&
                    <Grid xs={12} className={classes.alertWrapper}>
                        <Alert severity='error' >
                            {errors['customActivity']}
                        </Alert>
                    </Grid>}
            </>}
        </Grid>
    )
}

export default ActivityType;