import { arrayToObject } from "utils/object-util";
import { useGetStatusesForClientQuery } from "./vehicleStatusSlice";
import { Tooltip, Typography } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useSelector } from "react-redux";
import { selectIsClient } from "features/user/userSlice";
import DropdownFilter from "components/DropdownFilter";
import { orderBy } from "lodash";


export default function VehicleStatusView({ clientId, value, onChange, readonly = true, label = "Status", disabled = false }) {

    let { data: clientVehicleStatuses, error: clientVehicleStatusesError, loading: clientVehicleStatusesLoading } = useGetStatusesForClientQuery({ clientId: clientId ?? 0 })
    clientVehicleStatuses = clientVehicleStatuses || [];
    let isClient = useSelector(selectIsClient);

    const clientVehicleStatusesLookup = arrayToObject(clientVehicleStatuses);

    let statusObj = clientVehicleStatusesLookup?.[value]

    const renderOption = (value) => {
        return <>
            {value?.alias ? <>
                {isClient ? <div>{value?.alias}</div> : <Tooltip title={value?.label}>
                    <div>{value?.alias}</div>
                </Tooltip>}
            </>
                : <>{value?.label}</>
            }
        </>
    }

    return (
        <>
            {readonly ?
                <>
                    {renderOption(statusObj)}
                </> :
                <CustomInput
                    id="status"
                    label={label}
                    value={value}
                    elementType="dropdown"
                    onChange={val => onChange(+val)}
                    values={orderBy(clientVehicleStatuses, 'name')}
                    showEmpty
                    renderOption={renderOption}
                    disabled={disabled}
                />
                // <DropdownFilter
                //     withTypeAhead
                //     label={label}
                //     value={value}
                //     elementType="dropdown"
                //     onSelect={val => {
                //         onChange(+val?.id)
                //     }}
                //     values={orderBy(clientVehicleStatuses, 'name')}
                //     showEmpty
                //     renderOption={renderOption}
                //     disabled={disabled}
                // />
            }
        </>
    );
}
