import { Button } from "@material-ui/core";
import { Image, List } from "@material-ui/icons";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Guid } from 'js-guid';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import AddPhoto from "../../../../../../../../components/AddPhoto";
import CustomInput from "../../../../../../../../components/CustomInput";
import DropdownFilter from "../../../../../../../../components/DropdownFilter";
import NotificationProvider from '../../../../../../../../components/NotificationProvider';
import { useGetAllUserLotRelationsQuery } from "../../../../../../../../features/users/usersSlice";
import FileService from '../../../../../../../../services/fileService';
import { useGetLotsQuery } from "../../../../../../../lots/lotSlice";
import { useAddLotInspectionMutation, useGetInspectionDocumentsByLotIdQuery, useUpdateLotInspectionMutation } from "../../../KpiInspection/inspectionSlice";
import formStyles from "./formStyle";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";

const useStyles = formStyles;

const InspectionForm = ({ lotId, inspection, handleClose }) => {
    const classes = useStyles();
    const [addLotInspection, { isLoading }] = useAddLotInspectionMutation();
    let { data: lots, isLoading: isLoadingLots } = useGetLotsQuery();
    const [updateLotInspection, { isUpdateLoading }] = useUpdateLotInspectionMutation();
    let { data: inspectionDocumentsAPI, error: inspectionDocumentError, isLoading: isLoadingInspectionDocuments } = useGetInspectionDocumentsByLotIdQuery();
    let { data: userLotRelationsAPI, error: relationsError, isLoading: isLoadingRelations } = useGetAllUserLotRelationsQuery();
    let [inspectionDocuments, setInspectionDocuments] = useState(inspectionDocumentsAPI || []);
    let [userLotRelations, setUserLotRelations] = useState(userLotRelationsAPI || []);

    let [inspectionToAdd, setInspectionToAdd] = useState(inspection);
    let [isSaving, setIsSaving] = useState(false);
    let [showPhotos, setShowPhotos] = useState(false);
    const [newImages, setNewImages] = useState([]);
    const [existingImages, setExistingImages] = useState([]);
    const [statusDate, setStatusDate] = React.useState(new Date());
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const selectedLotId = inspectionToAdd?.lotId ?? lots?.filter(l => l.pmfLot)?.[0]?.id + "";
        if (!inspectionToAdd?.id) {
            //FIXME This only works once, since the useEffect is not called again
            setInspectionToAdd({ ...inspectionToAdd, lotId: selectedLotId });
        }
        loadingUsers(userLotRelationsAPI?.filter(a => a.lotId === parseInt(selectedLotId)));
    }, [lots, userLotRelationsAPI]);

    useEffect(() => {
        if (inspection) {
            setInspectionToAdd(inspection);
            setExistingImages(inspectionDocuments?.filter(a => a.inspectionId === inspection.id));
        }
    }, [inspection]);

    let loadingData = {
        bathrooms: [
            { id: true, name: "Clean" },
            { id: false, name: "Not Clean" }
        ],
        gateSecurities: [
            { id: true, name: "Gate Secure" },
            { id: false, name: "Gate Not Working" }
        ],
        offices: [
            { id: true, name: "Clean" },
            { id: false, name: "Not Clean" }
        ],
        yards: [
            { id: true, name: "Clean" },
            { id: false, name: "Not Clean" }
        ],
        trashRemovals: [
            { id: true, name: "Cans Emptied" },
            { id: false, name: "Not Emptied" }
        ],
        parqAis: [
            { id: true, name: "Working" },
            { id: false, name: "Error" }
        ],
        hoursVerifications: [
            { id: true, name: "Yes" },
            { id: false, name: "No" }
        ]
    }

    useEffect(() => {
        let inspectionCopy = { ...inspectionToAdd };
        if (inspectionToAdd.id) {
            inspectionCopy.isBatchromCleaned = inspectionToAdd.isBatchromCleaned.toString();
            inspectionCopy.isGateSecured = inspectionToAdd.isGateSecured.toString();
            inspectionCopy.isOfficeCleaned = inspectionToAdd.isOfficeCleaned.toString();
            inspectionCopy.isYardCleaned = inspectionToAdd.isYardCleaned.toString();
            inspectionCopy.isThereAnyTrash = inspectionToAdd.isThereAnyTrash.toString();
            inspectionCopy.isParkAIWorking = inspectionToAdd.isParkAIWorking.toString();
            inspectionCopy.hoursVerification = inspectionToAdd.hoursVerification.toString();
            //inspectionCopy.inspectionDate = FormatService.formatDateTime(inspectionToAdd?.inspectionDate);

            inspectionToAdd = inspectionCopy;
        }

        loadingUsers(userLotRelationsAPI?.filter(a => a.lotId === parseInt(inspectionCopy.lotId)));

        setExistingImages(inspectionDocuments?.filter(a => a.inspectionId === inspection.id));
        setImageToSend();
    }, [inspectionDocuments]);

    useEffect(() => {
        setInspectionDocuments(inspectionDocumentsAPI || []);
    }, [inspectionDocumentsAPI]);

    const onChange = async (key, val) => {
        await cleanTextAreas(key);
        setInspectionToAdd({ ...inspectionToAdd, [key]: val });
    };

    const onChangeLot = async (key, val) => {
        loadingUsers(userLotRelationsAPI?.filter(a => a.lotId === parseInt(val)));
        setInspectionToAdd({ ...inspectionToAdd, [key]: val });
    };

    const loadingUsers = async (usersToLoad) => {
        let users = [];
        if (typeof users !== "undefined") {
            users.push({ id: 0, name: "" });
            usersToLoad.map(userLot => {
                if (typeof users.find(x => x.id === userLot.userId) === "undefined") {
                    users.push({ id: userLot.userId, name: userLot.userName });
                }
            });
        }
        users = users.filter(x => x.id != 0 && x.name !== '');
        setUserLotRelations([...users]);
    };

    const refreshData = async () => {
        setShowPhotos(true);
        let inspectionRefresh = {
            userId: 0,
            inspectionDate: new Date(),
            numberOfTrucks: null,
            isBatchromCleaned: "true",
            bathroom: "",
            isGateSecured: "true",
            gateSecurity: "",
            isOfficeCleaned: "true",
            office: "",
            isYardCleaned: "true",
            yard: "",
            isThereAnyTrash: "true",
            trashRemoval: "",
            isParkAIWorking: "true",
            parqAI: "",
            otherIssuesReport: "",
            hoursVerification: "true",
            inspectionDocuments: [],
            needsAttention: null,
            regionalManager: "",
            lotId: inspectionToAdd?.lotId
        }
        loadingUsers(userLotRelationsAPI?.filter(a => a.lotId === parseInt(inspectionToAdd?.lotId)));
        setInspectionToAdd(inspectionRefresh);
        setShowPhotos(false);
        setNewImages([]);
        setInspectionDocuments([]);
        setStatusDate(new Date());
        setExistingImages([]);
    }

    const cleanTextAreas = async (key) => {
        switch (key) {
            case "isBatchromCleaned":
                inspectionToAdd.bathroom = "";
                break;
            case "isGateSecured":
                inspectionToAdd.gateSecurity = "";
                break;
            case "isOfficeCleaned":
                inspectionToAdd.office = "";
                break;
            case "isYardCleaned":
                inspectionToAdd.yard = "";
                break;
            case "isParkAIWorking":
                inspectionToAdd.parqAI = "";
                break;
            case "isThereAnyTrash":
                inspectionToAdd.trashRemoval = "";
                break;
            default:
                break;
        }
    };

    const onChangeNumberOfTrucks = (key, val) => {
        setInspectionToAdd({ ...inspectionToAdd, [key]: parseInt(val) });
    };

    const addImage = async (image) => {
        image.id = Guid.newGuid().StringGuid;
        setNewImages([...newImages, image]);
    };

    const handleDelete = async (img) => {
        if (typeof img.isDeleted !== "undefined") {
            const existingImagesCopy = [...existingImages];
            for (let i = 0; i < existingImagesCopy?.length; i++) {
                let imageCopy = { ...existingImagesCopy[i] };
                if (imageCopy.id === img.id) {
                    imageCopy.isDeleted = 1;
                    existingImagesCopy[i] = imageCopy;
                }
            }
            setExistingImages(existingImagesCopy);
        }
        else {
            let allImages = newImages.filter(x => x.id !== img.id)
            setNewImages(allImages);
        }
    };

    const setImageToSend = async () => {
        inspectionToAdd.InspectionDocuments = [];

        let objWithImages = { ...inspectionToAdd };
        for (let i = 0; i < newImages.length; i++) {
            let base64Data = await FileService.getBase64Data(newImages[i].file);
            let image = { lotId: lotId, fileName: newImages[i].file.name, fileData: base64Data, id: 0, isDeleted: 0 }
            objWithImages.InspectionDocuments.push(image);
        }

        for (let i = 0; i < existingImages?.length; i++) {
            objWithImages.InspectionDocuments.push(existingImages[i]);
        }

        setInspectionToAdd(objWithImages);
    };

    const onSubmit = async () => {
        setIsSaving(true);

        let isValid = await validateData();
        if (!isValid) {
            setIsSaving(false);
            return;
        }

        setErrorMessage("");

        await setImageToSend();
        inspectionToAdd.lotId = parseInt(inspectionToAdd.lotId);
        inspectionToAdd.hoursVerification = inspectionToAdd.hoursVerification === "true";

        let date = new Date(moment(inspectionToAdd.inspectionDate).format("yyyy-MM-DD, hh:mm a") + " UTC");
        inspectionToAdd.inspectionDate = date.toISOString();

        let result;
        let isUpdate = false;
        if (inspectionToAdd.id) {
            isUpdate = true;
            result = await updateLotInspection(inspectionToAdd);
        }
        else {
            result = await addLotInspection(inspectionToAdd);
        }

        if (result && !result.error) {
            await refreshData();
            if (isUpdate) {
                NotificationProvider.success("Lot Inspection updated successfully");
            }
            else {
                NotificationProvider.success("Lot Inspection added successfully");
                await refreshData();
            }


            handleClose && handleClose();
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }

        setIsSaving(false);
    };

    const validateData = async () => {
        let isValid = true;
        if (inspectionToAdd.userId == null || inspectionToAdd.userId == "" || parseInt(inspectionToAdd.userId) === 0) {
            setErrorMessage("Select a supervisor");
            isValid = false;
        }
        if (parseInt(inspectionToAdd.numberOfTrucks) === 0 || inspectionToAdd.numberOfTrucks === "" || inspectionToAdd.numberOfTrucks === null || isNaN(inspectionToAdd.numberOfTrucks)) {
            isValid = false;
        }

        if (inspectionToAdd.isBatchromCleaned === "false" && inspectionToAdd.bathroom === "") {
            isValid = false;
        }

        if (inspectionToAdd.isGateSecured === "false" && inspectionToAdd.gateSecurity === "") {
            isValid = false;
        }

        if (inspectionToAdd.isOfficeCleaned === "false" && inspectionToAdd.office === "") {
            isValid = false;
        }

        if (inspectionToAdd.isYardCleaned === "false" && inspectionToAdd.yard === "") {
            isValid = false;
        }

        if (inspectionToAdd.isParkAIWorking === "false" && inspectionToAdd.parqAI === "") {
            isValid = false;
        }

        if (inspectionToAdd.isThereAnyTrash === "false" && inspectionToAdd.trashRemoval === "") {
            isValid = false;
        }

        return isValid;
    }

    const onSelectTime = (e) => {
        setStatusDate(e)
    }

    let form;
    if (showPhotos) {
        form = (
            <AddPhoto
                newImages={newImages}
                existingImages={existingImages.filter(x => x.isDeleted === 0) || []}
                addImage={addImage}
                label='Add Photo'
                onDelete={handleDelete} />
        )
    } else {
        form = (
            <>
                <div className={classes.input}>
                    <h3> 1. Site Location <span className={classes.requiredField}>*</span></h3>
                    <LotsDropdown
                        pmfLot
                        title='Select site location'
                        identifier='SiteLocation'
                        onSelect={(event) => onChangeLot("lotId", event)}
                        value={inspectionToAdd.lotId ?? ""}
                        required={true}
                        showEmpty
                    />
                </div>
                <div className={classes.input}>
                    <h3> 2. Supervisor Name <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title='Select Supervisor Name'
                        identifier='UserLots'
                        values={userLotRelations || []}
                        onSelect={(event) => onChange("userId", event)}
                        value={inspectionToAdd.userId ?? ""}
                        showEmpty={true}
                        required={true} />
                    <p className={classes.requiredField}>{errorMessage}</p>
                </div>
                <div className={classes.input}>
                    <h3> 3. Date <span className={classes.requiredField}>*</span></h3>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns} >
                        <Stack spacing={3}>
                            <DateTimePicker
                                label=''
                                value={(inspectionToAdd?.inspectionDate)}
                                onChange={val => onChange("inspectionDate", val)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        sx={{ svg: { color: '#0090FE' } }}
                                    />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div className={classes.input}>
                    <h3> 4. Number Of Trucks <span className={classes.requiredField}>*</span></h3>
                    <CustomInput
                        id="numberOfTrucks"
                        label=""
                        value={inspectionToAdd.numberOfTrucks}
                        elementType="input"
                        onChange={val => onChangeNumberOfTrucks("numberOfTrucks", val)}
                        numberOnly={true}
                        required={true}
                    />
                </div>
                <div className={classes.input}>
                    <h3> 5. Bathrooms <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title=''
                        identifier='Bathrooms'
                        values={loadingData.bathrooms}
                        onSelect={(event) => onChange("isBatchromCleaned", event)}
                        value={inspectionToAdd?.isBatchromCleaned ?? true} />
                </div>
                {inspectionToAdd?.isBatchromCleaned === "false" &&
                    <div className={classes.input}>
                        <h3> 5.1 Why is Bathroom not clean and what action are you taking (or need us to take) to solve? <span className={classes.requiredField}>*</span></h3>
                        <CustomInput
                            id="bathroom"
                            label=""
                            value={inspectionToAdd.bathroom}
                            elementType="textarea"
                            onChange={val => onChange("bathroom", val)}
                        />
                    </div>}
                <div className={classes.input}>
                    <h3> 6. Gate Security <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title=''
                        identifier='isGateSecured'
                        values={loadingData.gateSecurities}
                        onSelect={(event) => onChange("isGateSecured", event)}
                        value={inspectionToAdd?.isGateSecured ?? true} />
                </div>
                {inspectionToAdd?.isGateSecured === "false" &&
                    <div className={classes.input}>
                        <h3> 6.1 Why is Gate not working and what action are you taking (or need us to take) to solve? <span className={classes.requiredField}>*</span></h3>
                        <CustomInput
                            id="gateSecurity"
                            label=""
                            value={inspectionToAdd.gateSecurity}
                            elementType="textarea"
                            onChange={val => onChange("gateSecurity", val)}
                        />
                    </div>}
                <div className={classes.input}>
                    <h3> 7. Office <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title=''
                        identifier='isOfficeCleaned'
                        values={loadingData.offices}
                        onSelect={(event) => onChange("isOfficeCleaned", event)}
                        value={inspectionToAdd?.isOfficeCleaned ?? true} />
                </div>
                {inspectionToAdd?.isOfficeCleaned === "false" &&
                    <div className={classes.input}>
                        <h3> 7.1 Why is Office not clean and what action are you taking (or need us to take) to solve? <span className={classes.requiredField}>*</span></h3>
                        <CustomInput
                            id="office"
                            label=""
                            value={inspectionToAdd.office}
                            elementType="textarea"
                            onChange={val => onChange("office", val)}
                        />
                    </div>}
                <div className={classes.input}>
                    <h3> 8. Yard <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title=''
                        identifier='isYardCleaned'
                        values={loadingData.yards}
                        onSelect={(event) => onChange("isYardCleaned", event)}
                        value={inspectionToAdd?.isYardCleaned ?? true} />
                </div>
                {inspectionToAdd?.isYardCleaned === "false" &&
                    <div className={classes.input}>
                        <h3> 8.1 Why is Yard not clean and what action are you taking (or need us to take) to solve? <span className={classes.requiredField}>*</span></h3>
                        <CustomInput
                            id="yard"
                            label=""
                            value={inspectionToAdd.yard}
                            elementType="textarea"
                            onChange={val => onChange("yard", val)}
                        />
                    </div>}
                <div className={classes.input}>
                    <h3> 9. Parq .ai <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title=''
                        identifier='isParkAIWorking'
                        values={loadingData.parqAis}
                        onSelect={(event) => onChange("isParkAIWorking", event)}
                        value={inspectionToAdd?.isParkAIWorking ?? true} />
                </div>
                {inspectionToAdd?.isParkAIWorking === "false" &&
                    <div className={classes.input}>
                        <h3> 9.1 What is the error? Have you completed a ticket form?  Ticket form is found here: <a href={"https://forms.monday.com/forms/e7ee31c1418234191134e0b34347fae3?r=use1"} target="_blank">
                            Trouble Ticketing | monday.com forms <span className={classes.requiredField}>*</span>
                        </a></h3>
                        <CustomInput
                            id="parqAI"
                            label=""
                            value={inspectionToAdd.parqAI}
                            elementType="textarea"
                            onChange={val => onChange("parqAI", val)}
                        />
                    </div>}
                <div className={classes.input}>
                    <h3> 10. Trash Removal <span className={classes.requiredField}>*</span></h3>
                    <DropdownFilter
                        title=''
                        identifier='isThereAnyTrash'
                        values={loadingData.trashRemovals}
                        onSelect={(event) => onChange("isThereAnyTrash", event)}
                        value={inspectionToAdd?.isThereAnyTrash ?? true} />
                </div>
                {inspectionToAdd?.isThereAnyTrash === "false" &&
                    <div className={classes.input}>
                        <h3> 10.1 Why are they not emptied and what action are you taking (or need us to take) to solve? <span className={classes.requiredField}>*</span></h3>
                        <CustomInput
                            id="trashRemoval"
                            label=""
                            value={inspectionToAdd.trashRemoval}
                            elementType="textarea"
                            onChange={val => onChange("trashRemoval", val)}
                        />
                    </div>}
                <div className={classes.input}>
                    <h3> 11. Any other issues to report?</h3>
                    <CustomInput
                        id="otherIssuesReport"
                        label=""
                        value={inspectionToAdd.otherIssuesReport}
                        elementType="textarea"
                        onChange={val => onChange("otherIssuesReport", val)}
                    />
                </div>
                <div className={classes.input}>
                    <h3> 12. Hours Verification Done? <span className={classes.requiredField}>*</span></h3>
                    <div>
                        {'Have you reviewed your teams timesheets in Connect and verified that all OT is legitimate and all hours are accurate? Have you entered notes for all anomalies (OT etc)?'}
                    </div>
                    <DropdownFilter
                        title=''
                        identifier='hoursVerification'
                        values={loadingData.hoursVerifications}
                        onSelect={(event) => onChange("hoursVerification", event)}
                        value={inspectionToAdd?.hoursVerification ?? true} />
                </div>
            </>
        );
    }

    return (
        <>

            {form}
            <div className={classes.buttonCenter}>
                <Button className={classes.button} variant="outlined" onClick={() => setShowPhotos(!showPhotos)} startIcon={showPhotos ? <List /> : <Image />}>{showPhotos ? "Details" : "Photos"}</Button>
                <Button className={classes.button} variant="outlined" disabled={!handleClose} onClick={() => handleClose()}>Cancel</Button>
                <Button
                    onClick={onSubmit}
                    disabled={isSaving}
                    className={classes.button}
                    variant="contained"
                >
                    {isSaving ? "Saving..." : "Submit"}
                </Button>
            </div>
        </>
    );
};

export default InspectionForm;