import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { IconLot } from 'icons';
import GenerateAccessCode from 'features/access/GenerateAccessCode/GenerateAccessCode';
import { useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListsStyle } from '../ListsStyle';


const SiteListItem = ({ access, lot }) => {

    const classes = ListsStyle()

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconLot className={classes.listItemIcon} alt="Asset ID" />
            <div className={classes.name}>{`${lot?.name}`}</div>
            <IconButton className={classes.moreVertIcon}>
                <MoreVertIcon onClick={handleClick} />
            </IconButton>
            <Menu
                id="lot-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lot-menu',
                }}
            >
                <MenuItem>
                    <GenerateAccessCode accessGroup={access} lotId={lot?.id} btnStyle="none" />
                </MenuItem>
            </Menu>
        </>
    );
}

export default SiteListItem



