import { makeStyles } from "@material-ui/core"

export const LoadDashboardStyle = makeStyles((theme) => ({
    dashboard: {
        maxWidth: 1600,
        position: 'relative'
    },
    card: {
        margin: '1rem 0',
        padding: '2rem',
    },
    graphCard: {
        margin: '1rem 0',
        padding: '0.2rem',
        textAlign: "center"
    },
    icon: {
        marginRight: "8px",
        height: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontWeight: 500,
        marginRight: "8px",
        color: theme.typography.color
    },
    pageTitle: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    titleRow: {
        marginBottom: "32px"
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0
    },
    subTitleRow: {
        marginBottom: "16px"
    },
    event: {
        color: theme.palette.primary.dark,
        marginBottom: 19
    },
    counter: {
        color: theme.palette.primary.main,
        fontSize: 72,
        fontWeight: 700
    },
    counterIcon: {
        width: 50,
        height: 50,
        opacity: 0.3
    },
    filter: {
        minWidth: "164px"
    },
    filterMobile: {
        maxWidth: "100%",
        width: "100%",
        flexBasis: "100%"
    },
    activeLoads: {
        cursor: "pointer",
        '&:hover': {
            opacity: 0.6,
        },
    },
    subTitleSla: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        margin: 'auto',
        marginTop: 20,
    },
    slaFilters: {
        paddingBottom: '1.5rem'
    },
    atRisk: {
        color: theme.palette.primary.dark,
        fontSize: 15,
        fontWeight: 300,
        marginTop: 0
    },
    topCards: {
        margin: '1rem 0',
        padding: '2rem',
        width: '245px',
        height: '225px'
    }
}));
