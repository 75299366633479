import { baseQuery } from "../../../../app/baseQuery";
import {apiTags} from "../../../../app/store/apiTags";
import { baseApi } from 'app/baseApi';

export const securityPhotosApi = baseApi.injectEndpoints({
    reducerPath: 'securityPhotosApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.SECURITY_PHOTOS],
    endpoints: (builder) => ({
        getSecurityPhotosByLotId: builder.query({
            query: (lotId) =>  `securityPhotos/getAllByLotId/?lotId=${lotId}`,
            providesTags: [apiTags.SECURITY_PHOTOS]
        })
    }),
});

export const {
    useGetSecurityPhotosByLotIdQuery
} = securityPhotosApi;

