import { HubConnectionBuilder } from "@microsoft/signalr";
import store from "../store/store";
import { notificationHandler } from "./handlers/notificationOptions";

const ScopeType = {
    Global: 1,
    ByClientId: 2,
    ByEmail: 3,
    ByUserId: 6
}
const MessageType = {
    info: 1,
    success: 2,
    error: 3
}

export const realTimeNotification = ({

    GetHubConnectionBuilder: () => {
        let state = store.getState();
        let user = state.oidc.user;
        let accessToken = user && user.access_token;
        const baseUrl = process.env.REACT_APP_API_URL || "https://localhost:6001";
        return new HubConnectionBuilder()
            .withUrl(`${baseUrl}/hub`, {
                accessTokenFactory: () => {
                    return accessToken
                }
            })
            .withAutomaticReconnect()
            .build();
    },
    ProcessMessage: (receiveObject) => {

        let showMessage = false;
        let state = store.getState();
        let user = state.oidc.user;
        const userClientId = user && user?.profile?.clientId || -1;
        const email = user && user?.profile?.email || '---';
        const userId = user && user?.profile?.sub || '---';
        switch (receiveObject.scopeType) {
            case ScopeType.Global:
                showMessage = true;
                break;
            case ScopeType.ByClientId:
                if (userClientId === receiveObject.clientId.toString())
                    showMessage = true;
                break
            case ScopeType.ByEmail:
                if (email === receiveObject.email.toString())
                    showMessage = true;
                break
            case ScopeType.ByUserId:
                if (userId === receiveObject.userId.toString())
                    showMessage = true;
                break
            default:
                break;
        }

        if (showMessage) {
            // switch (receiveObject.messageType) {
            //     case MessageType.info:
            //         NotificationProvider.info(receiveObject.message, receiveObject.toastOptions);
            //         break;
            //     case MessageType.success:
            //         NotificationProvider.success(receiveObject.message, receiveObject.toastOptions);
            //         break;
            //     default:
            //         NotificationProvider.error(receiveObject.message, receiveObject.toastOptions);
            //         break;

            // }

            notificationHandler[receiveObject.type]?.handle && notificationHandler[receiveObject.type]?.handle(receiveObject);
        }
    }
})