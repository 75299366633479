import { makeStyles } from '@material-ui/core/styles';

export const loadingSpinnerStyle = makeStyles((theme) => ({
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% - 20px)',
        zIndex: '99999'
    }
}));
