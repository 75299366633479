import React, { useState } from "react";
import { CloudUploadOutlined } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useDropzone } from 'react-dropzone';
import { dropZoneStyle } from "./DropZoneInDialogStyle";
import { Button, Grid, Paper, IconButton } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const DropZoneInDialog = ({
    onSave,
    id,
    labelDialog,
    btnStyle = 'contained',
    buttonLabel,
    formContent,
    dropMessage = "Drag and drop the file here, or click to select file",
    maxFiles = 1,
    accept = { 'text/csv': ['.csv'] },
    actionButtonLabel = "Import",
    valid = true,
    className
}) => {

    const classes = dropZoneStyle();

    const [open, setOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([])

    const onDrop = acceptedFiles => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    }

    const onRemove = () => {
        setSelectedFiles([]);
    }

    let fileText = maxFiles > 1 ? "Files" : "File";

    const { acceptedFiles, getRootProps, getInputProps,
        isDragActive, isDragAccept, isDragReject } = useDropzone(
            { maxFiles, accept, onDrop });

    const files = selectedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedFiles([]);
        setOpen(false);
    };

    const onClick = (e) => {
        let file = selectedFiles[0];
        onSave(file);
        handleClose();
    };

    return (
        <div className={className}>
            <Button
                variant={btnStyle}
                color="primary"
                onClick={handleClickOpen}
            >
                {buttonLabel}
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{labelDialog}</DialogTitle>
                <DialogContent>

                    {formContent}

                    <Paper className={classes.fileContainer}>

                        {!(!!files.length) && <div {...getRootProps({ className: 'dropzone' })}>
                            <BackupOutlinedIcon />
                            <input {...getInputProps()} />
                            {isDragAccept && (<p>All {fileText} will be accepted</p>)}
                            {isDragReject && (<p>Some {fileText} will be rejected</p>)}
                            <p> {!isDragActive && dropMessage} </p>
                        </div>}
                        {!!files.length && <div className="fileWrapper" >
                            <IconButton
                                title={'Remove'}
                                edge='end'
                                aria-label='Remove'
                                size='small'
                                onClick={onRemove}>
                                <CloseOutlinedIcon />
                            </IconButton>
                            <ul>{files}</ul>
                        </div>}
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <label htmlFor={id}>
                        <Button variant="contained" color="primary"
                            component="span"
                            onClick={onClick}
                            disabled={!(!!selectedFiles?.length) || !valid}>
                            {actionButtonLabel}
                        </Button>
                    </label>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DropZoneInDialog;