import { FormControlLabel, Grid } from "@material-ui/core";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccordionCore from "components/Shared/Accordion/AccordionCore";
import RoleDetail from "./RoleDetail";
import roleManagementStyles from "./roleManagementStyles";

const useStyles = roleManagementStyles;
const RoleList = ({ roles, onChangePermission, onChangeAllPermissions, onSave, onCancel, isLoading, onDeleteRole, renderCategoryActions }) => {
    const classes = useStyles();

    const onSaveBridge = (role) => {
        onSave(role);
    }

    return (<Grid className={classes.card}>
        <Grid container direction="row" spacing={2} style={{ maxHeight: 700, overflowY: 'auto' }}>
            {
                roles?.map((role, index) => (
                    <Grid item xs={12}>
                        <AccordionCore
                            headerActions={renderCategoryActions(role)}
                            headerText={role?.name}
                            headerTextIcon={<ManageAccountsIcon className={classes.manageIcon} fontSize="5" />}
                        >
                            <RoleDetail
                                role={role}
                                onChangePermission={onChangePermission}
                                onChangeAllPermissions={onChangeAllPermissions}
                                onSave={onSaveBridge}
                                onCancel={onCancel}
                                isLoading={isLoading}
                                onDeleteRole={onDeleteRole}
                                key={index}
                            />
                        </AccordionCore>
                    </Grid>
                ))
            }
        </Grid>
    </Grid>);
}
export default RoleList;