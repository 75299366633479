import { IconLot, IconPmfLogoWhite, IconServiceCenter, IconServiceCenterOEM } from 'icons';
import BasicMarker from '../BasicMarker/BasicMarker';
import { BasicMarkerStyle } from '../BasicMarker/BasicMarkerStyle';
import { LotTypeEnum } from 'features/lots/LotsEnums';

const LotsMapMarker = ({ lot, color, text, textColor, wrapperSize = '40', iconSize = '24' }) => {

    const classes = BasicMarkerStyle();

    const getIcon = () => {
        switch (lot?.type) {
            case LotTypeEnum.SERVICE_PROVIDER:
                return <IconServiceCenter fill='white' height={iconSize} width={iconSize} className={classes.lotIcon} />
            case LotTypeEnum.OEM_SERVICE:
                return <IconServiceCenterOEM fill='white' height={iconSize} width={iconSize} className={classes.lotIcon} />
            case LotTypeEnum.THIRD_PARTY:
                return <IconLot fill='white' height={iconSize} width={iconSize} className={classes.lotIcon} />
            default:
                if(lot?.pmfLot) return <IconPmfLogoWhite fill='white' height={iconSize} width={iconSize} className={classes.lotIcon} />
                return <IconLot fill='white' height={iconSize} width={iconSize} className={classes.lotIcon} />
        }
    }

    const getColor = () => {
        switch (lot?.type) {
            case LotTypeEnum.SERVICE_PROVIDER:
                return '#f14040'
            case LotTypeEnum.OEM_SERVICE:
                return '#f14040'
            case LotTypeEnum.THIRD_PARTY:
                return "green"
            default:
                return lot?.pmfLot ? "#0090fe" :  (color || "#E48900")
        }
    }


    return (
        <BasicMarker
            color={getColor()}
            text={text}
            textColor={textColor}
            wrapperSize={wrapperSize}
            icon={getIcon()}
        />
    )
}
export default LotsMapMarker;
