import { NoteEntityTypes } from "features/notes/notesConsts"

export const FlagEntityTypes = {
    ASSET: 1,
    ACTIVITY: 2,
    TRANSPORT: 3,
    ISSUE_TRACKER: 4
}

export const NoteTypeByFlagType = {
    [FlagEntityTypes.ASSET]: NoteEntityTypes.VEHICLE,
    [FlagEntityTypes.ACTIVITY]: NoteEntityTypes.ACTIVITY,
    [FlagEntityTypes.TRANSPORT]: NoteEntityTypes.TRANSPORT,
    [FlagEntityTypes.ISSUE_TRACKER]: NoteEntityTypes.ISSUE_TRACKER,
}