export const AttachmentEntityTypes = {
    ACTIVITY: 1,
    ISSUE_TRACKER: 2,
    LOAD_INVOICE: 3,
    ASSET_TYPE: 4,
    RESERVATION: 5,
}

export const AttachmentTypes = {
    IMAGE: 1,
    DOCUMENT: 2
}