import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItem } from "@material-ui/core";
import UsersTasksStyle from "../UsersTasksStyle";
import { TimeField } from "components/TimeField/TimeField";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput";
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = UsersTasksStyle;

const UserTaskWeeklySchedule = ({ task, setTask, weekly = false }) => {
    const classes = useStyles();

    let daysOfWeekList = [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thursday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" }
    ];

    const [scheduleList, setScheduleList] = useState(task.scheduleList || [])
    const [existing, setExisting] = useState(false)

    useEffect(() => {
        setScheduleList(task.scheduleList || [])
    }, [task]);

    const onAddNewSchedule = () => {
        let schedule = [...scheduleList];
        if (weekly) {
            schedule.push({ 'day': null, 'hour': null });
        } else {
            schedule.push({ 'hour': null });
        }
        setScheduleList(schedule);
        setTask({ ...task, ["scheduleList"]: schedule })
    }

    const onChangeTime = (item, index, val) => {
        let schedule = [...scheduleList];
        let newItem = { ...item };
        newItem.hour = val?.format('HH:mm');
        if (scheduleList?.find(i => ((i?.day === newItem?.day || !i?.day) && i?.hour === newItem?.hour))) {
            setExisting(true)
        } else {
            schedule[index] = { ...newItem };
            setScheduleList(schedule);
            setTask({ ...task, ["scheduleList"]: schedule })
        }
    }

    const onChangeDay = (item, index, val) => {
        let schedule = [...scheduleList];
        let newItem = { ...item };
        newItem.day = val;
        if (scheduleList?.find(i => (i?.day === newItem.day && i?.hour === newItem?.hour))) {
            setExisting(true)
        } else {
            schedule[index] = { ...newItem };
            setScheduleList(schedule);
            setTask({ ...task, ["scheduleList"]: schedule })
        }
    }

    const onDelete = (item) => {
        let schedule = [...scheduleList];
        schedule = schedule.filter(i => i != item);
        setScheduleList(schedule);
        setTask({ ...task, ["scheduleList"]: schedule })
    }


    return (
        <>
            {scheduleList?.map((item, index) => {
                return <div key={item}>
                    <Grid container direction="row" alignItems="center" className={classes.daySchedule}>
                        {weekly &&
                            <Grid item xs={2} className={classes.day}>
                                <CustomInput
                                    elementType="dropdown"
                                    label="Day"
                                    value={parseInt(item.day)}
                                    values={daysOfWeekList}
                                    showEmpty={true}
                                    onChange={(val) => { onChangeDay(item, index, val) }}
                                />
                            </Grid>}
                        <Grid item xs={3}>
                            <TimeField
                                onChange={(val) => { onChangeTime(item, index, val) }}
                                value={item?.hour ? new Date().setHours(parseInt(item?.hour?.slice(0, 2)), 0) : null}
                                views={["hours"]}
                                readOnly={true}
                            />
                        </Grid>
                        <IconButton edge="end" aria-label="delete"
                            onClick={() => onDelete(item)}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Grid>
                    {/* {scheduleList?.filter(i => (i?.day === item?.day && i?.hour === item?.hour && item?.day !== null && item?.hour !== null))?.length > 1 && <div className={classes.error}>{`This ${weekly ? "day and " : ""}hour already exists`}</div>} */}
                </div>
            }

            )}
            <Button
                className={classes.addButton}
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onAddNewSchedule}
            >
                Add
            </Button>
            <Dialog open={!!existing} >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <div className={classes.error}>{`This ${weekly ? "day and " : ""}hour already exists`}</div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={() => setExisting(false)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog >
        </>

    );
}
export default UserTaskWeeklySchedule;