export const isObjectEmpty = (object) => {
  return object && JSON.stringify(object) === '{}';
};

export const toArray = (val) => {
  if (!val) return [];
  if (!Array.isArray(val)) return [val];
  return val;
}

export const arrayToObject = (array, keyFieldName = 'id') => {
  return (array ?? [])?.reduce(function (map, obj) {
    map[obj[keyFieldName]] = obj;
    return map;
  }, {});
}

export const downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}