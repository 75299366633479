import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'note';

const getCacheKey = (args) => {
    return `${args.entityType}_${args.entityId}`
}

/**
 * This is used to refresh the notes api for a specific entity
 * Provide the entity id and type (based on the NoteEntityType const) and get the cache key that needs to be invalidated using the invalidateTags
 * @param {*} entityId 
 * @param {*} entityType 
 * @returns 
 */
export const getNoteInvalidateTag = (entityId, entityType) => {
    return { type: apiTags.NOTES, id: getCacheKey({ entityId, entityType }) };
}

/**
 * This is used to refresh the notes api for the vehicle activity log
 */
export const getVehicleActivityLogNotesTag = () => {
    return { type: apiTags.NOTES, id: 'vehicle' };
}

export const notesApi = baseApi.injectEndpoints({
    reducerPath: 'notesApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.NOTES],
    endpoints: (builder) => ({
        getEntityNotes: builder.query({
            query: ({ entityId, entityType }) => `${apiPrefix}?entityId=${entityId}&entityType=${entityType}`,
            providesTags: (result, error, arg) => [{ type: apiTags.NOTES, id: getCacheKey(arg) }],
        }),
        getVehicleActivityNotes: builder.query({
            query: ({ vehicleId }) => `${apiPrefix}/vehicle/${vehicleId}`,
            providesTags: [getVehicleActivityLogNotesTag()]
        }),
        getAllActivityLogNotes: builder.query({
            query: () => `${apiPrefix}/activityLog`,
            providesTags: [{ type: apiTags.NOTES, id: 'activity_log' }]
        }),
        addNote: builder.mutation({
            query: note => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: note
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.NOTES, id: getCacheKey(arg) },
                getVehicleActivityLogNotesTag(),
                { type: apiTags.NOTES, id: 'activity_log' }],
        }),
        editNote: builder.mutation({
            query: note => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: note
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.NOTES, id: getCacheKey(arg) },
                getVehicleActivityLogNotesTag(),
                { type: apiTags.NOTES, id: 'activity_log' }],
        }),
        deleteNote: builder.mutation({
            query: (note) => ({
                url: `${apiPrefix}`,
                method: "DELETE",
                body: { id: note.id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.NOTES, id: getCacheKey(arg) },
                getVehicleActivityLogNotesTag(),
                { type: apiTags.NOTES, id: 'activity_log' }],
        }),
    })

});

export const {
    useGetEntityNotesQuery,
    useGetAllActivityLogNotesQuery,
    useGetVehicleActivityNotesQuery,
    useAddNoteMutation,
    useEditNoteMutation,
    useDeleteNoteMutation
} = notesApi;

