import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useState } from "react";
import { billingPrograms } from "../consts";
import ActivityExportData from "./ActivityExportData";
import StorageExportData from "./StorageExportData";
import TransportExportData from "./TransportExportData";

const BillingDashboardExport = ({ data }) => {
    const [open, setOpen] = useState(false);
    //TEMP move to API

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});



    const transformByType = {
        'lotId': (lotId) => {
            return lotLookup?.[lotId]?.name
        },
        'clientId': (clientId) => {
            return clientLookup?.[clientId]?.name
        }
    }

    return <>
        <Button onClick={() => setOpen(true)} color='primary' variant="contained">
            Export Data
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)} >
            <DialogTitle id={'dialog-title'} >Export Data</DialogTitle>
            <DialogContent>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <StorageExportData data={data} transformData={transformByType} />
                    </Grid>
                    <Grid item>
                        <TransportExportData data={data} transformData={transformByType} />
                    </Grid>
                    <Grid item>
                        <ActivityExportData data={data} transformData={transformByType} program={billingPrograms.MECHANICALS} label='Mechanicals' />
                    </Grid>
                    <Grid item>
                        <ActivityExportData data={data} transformData={transformByType} program={billingPrograms.WASHES} label='Washes' />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setOpen(false)} color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>
}
export default BillingDashboardExport;