import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withUserPreferences } from "components/UserPreferences/WithUserPreferences";
import { userPreferencesComponentIds, userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import usePrompt from "hooks/usePrompt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAuthorized } from "../../../../components/Authorize/authorizeUtil";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NotificationProvider from "../../../../components/NotificationProvider";
import FilterService, { fieldTypes } from "../../../../services/filterService";
import { useGetClientsQuery } from "../../../clients/clientSlice";
import { setFilters, useDeleteLotMutation, useGetLotsQuery, useUpdateLotMutation } from "../../lotSlice";
import DistanceReportUpload from "../DistanceReport/DistanceReportUpload";
import ExportLotButton from "../ExportLot/ExportLotButton";
import ImportLots from "../ImportLots/ImportLots";
import { useGetSubMarketsQuery } from "../LotSubMarketsPage/lotSubMarketsSlice";
import LotsList from "./LotsList/LotsList";
import LotsMapWrapper from "./LotsMap/LotsMapWrapper";
import { lotsPageStyle } from "./lotsPageStyle";
import { LotTypeEnum } from "features/lots/LotsEnums";

const LotsPage = ({
    savedFilters,
    saveFilters
}) => {
    const classes = lotsPageStyle();
    const { triggerPrompt } = usePrompt();
    const isNarrowScreen = useMediaQuery("(max-width: 1450px)");
    
    const { filters } = useSelector(state => state.subMarketsState);

    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery(true);
    let [addOrUpdateLot, { isLoading: updateLotLoading }] = useUpdateLotMutation();
    let [deleteLot, { isLoading: deleteLotLoading }] = useDeleteLotMutation();

    let { data: subMarkets, error: subMarketsError, isLoading: isLoadingSubMarkets } = useGetSubMarketsQuery();
    subMarkets = subMarkets || [];

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();
    clients = clients || [];

    const [screenView, setScreenView] = useState('map');
    const [lotToEdit, setLotToEdit] = useState();
    const [lotViewImages, setLotViewImages] = useState();
    const [searchedVal, setSearchedVal] = useState('');
    const [deletePopup, setDeletePopup] = useState(false);

    const hasClientPermission = isAuthorized(permissionProfiles.LOT.LOT_VIEW_DROPDOWN_FILTER_CLIENT);

    let filterConfig = [
        ...(hasClientPermission ? [{
            type: fieldTypes.VALUE_LIST,
            size: 2,
            title: 'Client',
            key: 'client',
            values: clients
        }] : []),
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Sub Market', key: 'subMarket', values: subMarkets },
    ];

    lots = lots || [];
    lots = lots.filter(l => !l.deletedDate);

    const subMarketsByLot = {}
    subMarkets?.map(s => {
        s?.lotIds?.map(l => {
            subMarketsByLot[l] = [...(subMarketsByLot[l] ?? []), s];
        })
    });

    const getSubMarketByLot = (lotId) => {
        return subMarketsByLot[lotId]?.map(l => l?.id + '');
    }
    const getClientIds = (clients) => {
        return clients?.map(l => l?.clientId + '');
    }

    lots = lots?.map(l => ({
        ...l,
        client: getClientIds(l.clientLots),
        subMarket: getSubMarketByLot(l.id) || [],
        locationsFilter: l.type === LotTypeEnum.STANDARD ? `${l.type}_${l.pmfLot ? '1' : '0'}` : `${l.type}`
    }));

    const sortedLots = FilterService.filter(filters, searchedVal, lots, filterConfig);

    const searchLot = (searchedVal) => {
        setSearchedVal(searchedVal.trim());
    }

    const onNewLot = () => {
        setLotToEdit({});
    }

    const handleViewChange = (e, newView) => {
        setScreenView(newView);
    }

    const onEditLot = (lot) => {
        setLotToEdit({ ...lot });
    }

    const onViewImages = (lot) => {
        setLotViewImages(lot);
    }

    const onDelete = async (lot) => {
        if (lot?.numberOfAssets > 0) {
            setDeletePopup(true)
        } else {
            triggerPrompt({
                title: "Delete Lot",
                content: "Are you sure you want to delete the lot?",
                onConfirm: async () => {
                    const res = await deleteLot(lot)
                    if (!res?.error) {
                        NotificationProvider.success("Lot was deleted successfully");
                    } else {
                        NotificationProvider.error("Failed to delete lot");
                    }
                },
            });
        }
    }

    const onCloseImages = () => {
        setLotViewImages();
    }

    const onSaveLot = async (lot) => {
        const res = await addOrUpdateLot(lot);
        if (!res?.error) {
            NotificationProvider.success("Lot was saved successfully");
            setLotToEdit();
            refetchLots();
        } else {
            NotificationProvider.error("Failed to save lot");
        }
    }

    const onCancelEditLot = () => {
        setLotToEdit();
    }

    const dispatch = useDispatch();

    const applyFilters = (filters) => {
        dispatch(setFilters(filters));
        saveFilters(filters);
    }

    useEffect(() => {
        if (savedFilters) {
            dispatch(setFilters(savedFilters));
        }
    }, [savedFilters]);

    return (
        <>
            <LoadingSpinner loading={isLoadingLots || updateLotLoading} />
            <div>
                <Grid container spacing={1} className={classes.buttonsContainer}>
                    <Grid item>
                        <ExportLotButton lots={lots} />
                    </Grid>
                    <Grid item>
                        <ImportLots reloadLots={refetchLots} />
                    </Grid>
                    <Grid item >
                        <DistanceReportUpload />
                    </Grid>
                </Grid>
            </div>
            {screenView == 'map' ?
                <LotsMapWrapper
                    lots={sortedLots}
                    screenView={screenView}
                    handleViewChange={handleViewChange}
                    onNewLot={onNewLot}
                    filters={filters}
                    setFilters={applyFilters}
                    searchLot={searchLot}
                    subMarketsByLot={subMarketsByLot}
                    onEditLot={onEditLot}
                    onViewImages={onViewImages}
                    onDelete={onDelete}
                    lotToEdit={lotToEdit}
                    onCloseImages={onCloseImages}
                    onSaveLot={onSaveLot}
                    onCancelEditLot={onCancelEditLot}
                    lotViewImages={lotViewImages}
                    updateLotLoading={updateLotLoading}
                    deletePopup={deletePopup}
                    setDeletePopup={setDeletePopup}
                    subMarkets={subMarkets}
                    isNarrowScreen={isNarrowScreen}
                    clients={clients}
                /> :
                <LotsList
                    allLots={lots}
                    lots={sortedLots}
                    deletePopup={deletePopup}
                    setDeletePopup={setDeletePopup}
                    updateLotLoading={updateLotLoading}
                    lotViewImages={lotViewImages}
                    setLotViewImages={setLotViewImages}
                    lotToEdit={lotToEdit}
                    subMarketsByLot={subMarketsByLot}
                    searchLot={searchLot}
                    filterConfig={filterConfig}
                    filters={filters}
                    setFilters={applyFilters}
                    searchedVal={searchedVal}
                    onNewLot={onNewLot}
                    screenView={screenView}
                    handleViewChange={handleViewChange}
                    onEditLot={onEditLot}
                    onViewImages={onViewImages}
                    onDelete={onDelete}
                    onCloseImages={onCloseImages}
                    onSaveLot={onSaveLot}
                    onCancelEditLot={onCancelEditLot}
                    isNarrowScreen={isNarrowScreen}
                    clients={clients}
                />
            }
        </>
    )
}

export default withUserPreferences(LotsPage, userPreferencesComponentIds.LOT_MAP, userPreferencesTypes.FILTERS);

