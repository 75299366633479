import { baseApi } from '../../../app/baseApi';
import { createSlice } from "@reduxjs/toolkit";
import { sum } from 'lodash';
import { ServiceItemTypes } from './loadInvoiceConsts';
import { apiTags } from '../../../app/store/apiTags';
import { getAttachmentsInvalidateTag } from 'features/attachments/attachmentSlice';
import { AttachmentEntityTypes } from 'features/attachments/attachmentsConsts';

const basePath = 'loadinvoice';

export const loadInvoiceApi = baseApi.injectEndpoints({
    reducerPath: 'loadInvoiceApi',
    endpoints: (builder) => ({
        getInvoices: builder.query({
            query: (statuses) => ({
                url: `${basePath}?statuses=${statuses?.join(',')}`
            }),
            providesTags: [apiTags.LOAD_INVOICES],
            transformResponse: (response) => {
                return response.map(invoice => {
                    return ({
                        ...invoice,
                        status: invoice?.statusId,
                        pickupLocationId: invoice?.vehicles?.[0]?.pickupLocationId,
                        deliveryLocationId: invoice?.vehicles?.[0]?.deliveryLocationId,
                    })
                });
            }
        }),
        getInvoiceItems: builder.query({
            query: () => ({
                url: `${basePath}/items`
            }),
        }),
        getInvoiceClasses: builder.query({
            query: () => ({
                url: `${basePath}/classes`
            }),
        }),
        getInvoiceAccounts: builder.query({
            query: () => ({
                url: `${basePath}/accounts`
            }),
        }),
        saveInvoice: builder.mutation({
            query: (body) => ({
                url: `${basePath}`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.LOAD_INVOICES]
        }),
        addInvoice: builder.mutation({
            query: (body) => ({
                url: `${basePath}`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.LOAD_INVOICES]
        }),
        saveInvoiceService: builder.mutation({
            query: (body) => ({
                url: `${basePath}/service`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.LOAD_INVOICES]
        }),
        addInvoiceService: builder.mutation({
            query: (body) => ({
                url: `${basePath}/service`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.LOAD_INVOICES]
        }),
        deleteInvoiceService: builder.mutation({
            query: (id) => ({
                url: `${basePath}/service`,
                method: "DELETE",
                body: {
                    serviceId: id
                }
            }),
            invalidatesTags: [apiTags.LOAD_INVOICES]
        }),
        updateInvoiceStatus: builder.mutation({
            query: (body) => ({
                url: `${basePath}/status`,
                method: "POST",
                body: {
                    id: body.id,
                    statusId: body.statusId,
                }
            }),
            invalidatesTags: [apiTags.LOAD_INVOICES]
        }),
        getInvoiceVendors: builder.query({
            query: () => ({
                url: `${basePath}/vendors`
            }),
        }),
        getInvoiceCustomer: builder.query({
            query: () => ({
                url: `${basePath}/customers`
            }),
        }),
        refreshAttachments: builder.mutation({
            query: (body) => ({
                url: `${basePath}/files/refresh`,
                method: "POST",
                body: {
                    id: body.id,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                getAttachmentsInvalidateTag(arg?.id, AttachmentEntityTypes.LOAD_INVOICE)
            ]
        }),
    })

});


export const {
    useGetInvoicesQuery,
    useGetInvoiceItemsQuery,
    useGetInvoiceAccountsQuery,
    useGetInvoiceClassesQuery,
    useUpdateInvoiceStatusMutation,
    useSaveInvoiceMutation,
    useAddInvoiceMutation,
    useSaveInvoiceServiceMutation,
    useAddInvoiceServiceMutation,
    useDeleteInvoiceServiceMutation,
    useGetInvoiceVendorsQuery,
    useGetInvoiceCustomerQuery,
    useRefreshAttachmentsMutation
} = loadInvoiceApi;

export const loadInvoiceStateSlice = createSlice({
    name: 'loadInvoiceStateSlice',
    initialState: {
        searchVal: '',
        filters: {
            'clientId': { value: '' },
            'statusId': { value: '' }
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = { ...state.filters, [action.payload.key]: { value: action.payload.value } }
        },
    },
});

export const { setSearchVal, setFilters, setFilter } = loadInvoiceStateSlice.actions;
