import { makeStyles } from "@material-ui/core";

export const IncrementorStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    justifycontent: 'center',
  },
  value: {
    margin: '3px 8px 3px',
    fontWeight: '600',
    maxWidth: 50,
    minWidth: 50,
    maxHeight: 40,
    minHeight: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #0090FE',
    borderRadius: '20%',
    backgroundColor: 'transparent',
    '& input': {
      textAlign: 'center',
    }
  }
}));
