import { useEffect, useState } from "react";
import { Box, Chip, Grid, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetAllActivityItemsQuery, useGetWorkordersForVehicleQuery } from "features/activity/activitySlice";
import FormatService from "services/formatService";
import { arrayToObject } from "utils/object-util";
import { IconActivityDefault } from "icons/mechanicals";
import { linkedWorkordersStyle } from "./style";

const LinkedWorkorderSelector = ({ linkedWorkorders, setLinkedWorkorders, vehicleId, disabled }) => {
    const classes = linkedWorkordersStyle();

    let { data: workorders, error: workordersError, isFetching: isFetchingWorkorders, refetch: refetchWorkorders } = useGetWorkordersForVehicleQuery({ vehicleId: vehicleId }, {skip: !vehicleId})
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    workorders = workorders || [];
    activityItems = activityItems || [];
    const activityItemsLookup = arrayToObject(activityItems);

    workorders = workorders.map(workorder => ({
        ...workorder,
        dueDate: FormatService.formatDate((new Date(workorder?.activityDate)).setDate((new Date(workorder?.activityDate))?.getDate() + workorder?.daysToComplete)),
    }));

    const [open, setOpen] = useState(false);

    useEffect(() => {
        refetchWorkorders({ vehicleId: vehicleId });
    }, [vehicleId])

    const handleChange = (newValue) => {
        !!newValue.length ? setLinkedWorkorders([newValue.at(-1)]) : setLinkedWorkorders(newValue)        
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        limit: 30,
    });

    const renderOption = (props, option) => {
        return (
            <Box  {...props}>
                <Grid container xs={12} alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Grid container alignItems='center'>
                            <Grid item className={classes.itemIcon}>
                                <IconActivityDefault width={20} height={20} />
                            </Grid>
                            <Grid item>
                                <Grid container direction='column'>
                                    <Grid item>
                                        <label className={classes.itemLabel}>Activity: </label>
                                        {activityItems?.find(a => a.id === option.activityItemId)?.name}
                                    </Grid>
                                    {option?.activityDate ? <Grid item >
                                        <label className={classes.itemLabel}>Due date: </label>
                                        {option.dueDate}
                                    </Grid> : <></>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    
    return (
        <Grid item xs={12} >
            <Autocomplete
                id="workorder_selector"
                multiple
                autoComplete
                filterSelectedOptions
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                handleHomeEndKeys
                autoHighlight
                value={linkedWorkorders}
                options={workorders}
                loading={isFetchingWorkorders}
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) => {
                    return option?.id == value?.id
                }}
                onChange={(event, newInputValue) => {
                    handleChange(newInputValue);
                }}
                renderOption={(props, option) => renderOption(props, option)}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={`${activityItemsLookup?.[option?.activityItemId]?.name} ${FormatService.formatDate((new Date(option?.activityDate)).setDate((new Date(option?.activityDate))?.getDate() + option?.daysToComplete))}`}
                        {...getTagProps({ index })}
                        icon={<IconActivityDefault height={12} />}
                      />
                    ))
                }
                renderInput={(params) => (
                    <div>
                        <TextField {...params} 
                            variant='outlined'
                            fullWidth 
                            onFocus={() => setOpen(true)}
                            onBlur={() => setOpen(false)}
                        />
                        <LoadingSpinner loading={isFetchingWorkorders} />
                    </div>
                )}
                className={classes.autoComplete}
            />
        </Grid>
    );
}

export default LinkedWorkorderSelector;