import { Pagination } from "@mui/material";
import { useState } from "react";

const GenericPagination = ({totalPages, onChangePagination, offset, limit}) => {

    const [page, setPage] = useState(offset); //currentPage chosen

    const handlePageChange = (event, value) => {
        setPage(value);
        onChangePagination(value, limit)
    };

    // const handleLimitChange = (even, value) => { }
    
    return (
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary"
            showFirstButton showLastButton
        />
    );
};

export default GenericPagination;