import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const activityPermissionProfile = {
    ACTIVITY_UPLOAD: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
    },
    ACTIVITY_EDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
    },
    PENDING_APPROVAL_ADD_PRICE: {
        [modes.EDIT]: [roles.OWNER],
    },
    ACTIVITY_BULK_ACTIONS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
    },
    ACTIVITY_MOVE_TO_NEXT_STATUS: {
        [modes.EDIT]: [roles.OWNER],
    },
    PENDING_INVOICE_ADD_PRICE: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.MANAGE_PENDING_INVOICE],
    },
    ABOVE_CAP_APPROVAL: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.APPROVE_ABOVE_PRICE_CAP],
    },
    ABOVE_CAP_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    EDIT_ACTIVITY_INTERNAL_FIELDS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    VIEW_FLAGS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC]
    },
    EDIT_ACTIVITY_ATTACHMENTS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR]
    },
    WORK_ORDER_PAGE_SAVE: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.MECHANIC, roles.SUPERVISOR],
    },
    WORK_ORDER_PAGE_FIELDS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.MECHANIC, roles.SUPERVISOR],
        [modes.READONLY]: [roles.CLIENT]
    },
    DELETE_ACTIVITY_TYPE: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.DELETE_ACTIVITY_TYPE],
    }
}