import { makeStyles } from "@material-ui/core"

export const userMenuStyle = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.dark
    },
    userMenu: {
        paddingTop: '0 !important'
    },
    userEmail: {
        background: theme.palette.secondary.main,
        padding: '16px',
        color: theme.palette.secondary.contrastText
    },
    icon: {
        fill: theme.palette.primary.contrastText
    }
}));