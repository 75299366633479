
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { IssueTrackerStyles } from "../style";
import { IconGraph, IconTasks, IconKanbanView } from "icons";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Grid } from "@material-ui/core";

const WorkflowPageViewSelector = ({ }) => {
    const classes = IssueTrackerStyles();

    const params = useParams();
    const view = params.view ?? 'list';
    const history = useHistory();

    const changeView = (view) => {
        history.push(`/workflow/${view}`);
    }

    return (
        <Grid container spacing={1} className={classes.viewTypeSelector}>

            <ToggleButtonGroup
                value={view}
                exclusive
                color="primary"
                className={classes.viewToggleBtn}
            >
                <Tooltip title='Dashboard' placement='top' arrow>
                    <ToggleButton className={classes.toggleButton} selected={view == 'dashboard'} onClick={() => changeView('dashboard')} value="dashboard" aria-label="left aligned" >
                        <IconGraph />
                    </ToggleButton>
                </Tooltip>
                <Tooltip title='Kanban' placement='top' arrow >
                    <ToggleButton className={classes.toggleButton} selected={view == 'kanban'} onClick={() => changeView('kanban')} value="kanban" aria-label="right aligned">
                        <IconKanbanView />
                    </ToggleButton>
                </Tooltip>
                <Tooltip title='List' placement='top' arrow >
                    <ToggleButton className={classes.toggleButton} selected={view == 'list'} onClick={() => changeView('list')} value="list" aria-label="right aligned">
                        <IconTasks />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </Grid>
    )
}
export default WorkflowPageViewSelector;