import * as React from "react";

function IconTireReplace(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9989 1.875C5.06456 1.875 1.8739 5.06566 1.8739 9C1.8739 12.9343 5.06456 16.125 8.9989 16.125C9.30956 16.125 9.5614 16.3768 9.5614 16.6875C9.5614 16.9982 9.30956 17.25 8.9989 17.25C4.44324 17.25 0.748901 13.5557 0.748901 9C0.748901 4.44434 4.44324 0.75 8.9989 0.75C13.5546 0.75 17.2489 4.44434 17.2489 9C17.2489 9.69317 17.1602 10.3599 17.0083 10.9915C16.9357 11.2936 16.6319 11.4795 16.3299 11.4069C16.0278 11.3343 15.8419 11.0305 15.9145 10.7285C16.0476 10.1751 16.1239 9.59683 16.1239 9C16.1239 5.06566 12.9332 1.875 8.9989 1.875ZM8.99891 5.16C6.87957 5.16 5.15891 6.88066 5.15891 9C5.15891 9.66261 5.32243 10.2756 5.6159 10.8169C5.76397 11.09 5.66261 11.4314 5.38951 11.5795C5.1164 11.7276 4.77498 11.6262 4.62691 11.3531C4.24538 10.6494 4.03391 9.85239 4.03391 9C4.03391 6.25934 6.25824 4.035 8.99891 4.035C11.7396 4.035 13.9639 6.25934 13.9639 9C13.9639 9.31066 13.7121 9.5625 13.4014 9.5625C13.0907 9.5625 12.8389 9.31066 12.8389 9C12.8389 6.88066 11.1182 5.16 8.99891 5.16ZM9.83165 9.07475C10.0513 9.29442 10.0513 9.65058 9.83165 9.87024L8.5869 11.115H12.2839C12.5946 11.115 12.8464 11.3668 12.8464 11.6775C12.8464 11.9882 12.5946 12.24 12.2839 12.24H8.5944L9.83165 13.4772C10.0513 13.6969 10.0513 14.0531 9.83165 14.2727C9.61198 14.4924 9.25583 14.4924 9.03616 14.2727L7.10116 12.3377C6.73149 11.9681 6.73149 11.3794 7.10116 11.0098L9.03616 9.07475C9.25583 8.85508 9.61198 8.85508 9.83165 9.07475ZM13.8512 11.8873C14.0708 11.6676 14.427 11.6676 14.6466 11.8873L16.5816 13.8223C16.9513 14.1919 16.9513 14.7806 16.5816 15.1502L14.6466 17.0852C14.427 17.3049 14.0708 17.3049 13.8512 17.0852C13.6315 16.8656 13.6315 16.5094 13.8512 16.2898L15.0959 15.045H11.4064C11.0957 15.045 10.8439 14.7932 10.8439 14.4825C10.8439 14.1718 11.0957 13.92 11.4064 13.92H15.0884L13.8512 12.6827C13.6315 12.4631 13.6315 12.1069 13.8512 11.8873Z" fill="#0090FE" />
        </svg>

    );
}

export default IconTireReplace;
