import React, {useState, useEffect} from 'react';
import {
    Button,
    Grid,
    IconButton,
    ListItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomInput from '../../../../../components/CustomInput';
import {relationTypeEnum} from '../../../UsersEnums';
import {
    useDeleteSupervisorMutation,
    useGetAllSupervisorQuery,
    useRemoveSupervisorMutation,
    useSaveUserLotRelationsMutation
} from '../../../usersSlice';
import usersPageStyle from '../UsersPageStyle';
import NotificationProvider from 'components/NotificationProvider';
import {selectUserId} from 'features/user/userSlice';
import {useSelector} from 'react-redux';
import DropdownFilter from "../../../../../components/DropdownFilter";
import {getLotsWithPmfText} from "../../../../lots/lotUtil";
import {useGetUserLotRelationsForUserByManagerQuery} from "../../userManagementSlice";

const useStyles = usersPageStyle;

const EditUserLotRelations = ({userId, setRelations,refetchUsers,lots, relations, onCancel}) => {
    const classes = useStyles();

    const loggedInUserId = useSelector(selectUserId);

    let {
        data: userLotRelations,
        error: relationsError,
        isLoading: isLoadingRelations,
        refetch: refetchUserLotRelations
    } = useGetUserLotRelationsForUserByManagerQuery(userId, {skip: !userId});
    
    let {
        data: allSupervisor,
        error: getAllSupervisorError,
        isLoading: isLoadingGetAllSupervisor,
        refetch: refetchAllSupervisors
    } = useGetAllSupervisorQuery();
    
    let {
        data: loggedInLotRelations,
        error: loggedInRelationsError,
        isLoading: isLoadingLoggedInRelations,
        refetch: refetchUserLotRelationsForUser
    } = useGetUserLotRelationsForUserByManagerQuery(loggedInUserId, {skip: !loggedInUserId});

    userLotRelations = userLotRelations || [];
    allSupervisor = allSupervisor || [];
    loggedInLotRelations = loggedInLotRelations || [];

    let [saveRelations] = useSaveUserLotRelationsMutation();
    let [removeSupervisorRelations] = useRemoveSupervisorMutation();
    let [deleteSupervisorRelations] = useDeleteSupervisorMutation();

    const [saving, setSaving] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        userLotRelations = (!loggedInLotRelations.length) 
            ? userLotRelations 
            : userLotRelations.filter(r => loggedInLotRelations.map(l => l.id).includes(r.id));
        
        setRelations(userLotRelations?.length ? userLotRelations : [{
            'userId': userId,
            'relationType': null,
            'lotId': null
        }]);
    }, [isLoadingRelations, userId, loggedInLotRelations]);
    const onAddNewRelation = () => {
        setHasChanged(true);
        let relationsList = [...relations];
        relationsList.push({'userId': userId, 'relationType': null, 'lotId': null});
        setRelations(relationsList);
    }

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogActions, setDialogActions] = useState([]);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogAction = async (action) => {
        await action();
        handleDialogClose();
    };

    const showDialog = (content, actions) => {
        setDialogContent(content);
        setDialogActions(actions);
        setDialogOpen(true);
    };
    
    const checkLotAlreadyHasSupervisor = (newItem, relationsList) => {
        const currentSupervisor = allSupervisor.find(r => r.lotId === newItem.lotId && r.relationType === 2);
        if (currentSupervisor) {
            showDialog(
                `${currentSupervisor.userName} is already the Super of this Lot. What would you like to do?`,
                [
                    {
                        text: 'Remove Supervisor',
                        action: async () => {
                            let res = await removeSupervisorRelations({
                                UserId: currentSupervisor.userId,
                                LotId: newItem.lotId
                            });

                            if (!res?.error) {
                                NotificationProvider.success('Saved successfully');
                                relationsList[relations.findIndex(i => i === newItem)] = {...newItem};
                                setRelations(relationsList);
                                refetchUserLotRelations();
                                refetchAllSupervisors();
                                refetchUserLotRelationsForUser();
                            } else {
                                NotificationProvider.error('Failed to save');
                            }
                        },
                    },
                    {
                        text: 'Delete Supervisor',
                        action: async () => {
                            let res = await deleteSupervisorRelations({UserId: currentSupervisor.userId});
                            if (!res?.error) {
                                relationsList[relations.findIndex(i => i === newItem)] = {...newItem};
                                setRelations(relationsList);
                                refetchUserLotRelations();
                                refetchAllSupervisors();
                                refetchUserLotRelationsForUser();
                            } else {
                                NotificationProvider.error('Failed to save');
                            }
                        },
                    },
                    {
                        text: 'Keep both Supervisors',
                        action: () => {
                            relationsList[relations.findIndex(i => i === newItem)] = {...newItem};
                            setRelations(relationsList);
                        },
                    },
                    {
                        text: 'Cancel',
                        action: handleDialogClose,
                    },
                ]
            );
            return true;
        }
        return false;
    };

    const onChangeLot = (item, val) => {
        setHasChanged(true);
        let relationsList = [...relations];
        let newItem = {...item};
        newItem.lotId = parseInt(val);
        relationsList[relations.findIndex(i => i === item)] = {...newItem};
        setRelations(relationsList);
    }

    const onChangeRelationType = (item, val) => {
        setHasChanged(true);
        let relationsList = [...relations];
        let newItem = {...item};
        newItem.relationType = parseInt(val);

        relationsList[relations.findIndex(i => i === item)] = {...newItem};
        setRelations(relationsList);

        if (newItem.relationType === 2) {
            const lotAlreadyHasSupervisor = checkLotAlreadyHasSupervisor(newItem, relationsList);
            if (!lotAlreadyHasSupervisor) {
                setRelations(relationsList);
            }
        }
    }

    const onDelete = (item) => {
        setHasChanged(true);
        let relationsList = [...relations];
        relationsList = relationsList.filter(i => i != item);
        setRelations(relationsList);
    }

    const handleSave = async () => {
        setSaving(true);

        let relationsToSave = {
            userId: userId,
            relationEntities: relations.filter(r => r.relationType && r.lotId)
        }

        let res = await saveRelations(relationsToSave);

        setSaving(false);

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
            refetchUserLotRelations();
            refetchAllSupervisors();
            refetchUsers();
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    }

    const isLoading = () => isLoadingRelations;
    const getRelationTypeValues = () => {
        return relationTypeEnum
    }

    return (
        <div>
            <div className={classes.label}> Lot Associations</div>
            {!isLoading() &&
                <div>
                    {
                        relations?.map((item, index) => {
                            return <div key={item.id}>
                                <ListItem>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <DropdownFilter
                                                title={"Lot"}
                                                identifier={index}
                                                values={getLotsWithPmfText(lots)}
                                                onSelect={val => onChangeLot(item, val)}
                                                value={item.lotId}
                                                showEmpty={true}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <CustomInput
                                                key={index}
                                                label="Relation Type"
                                                elementType="dropdown"
                                                values={getRelationTypeValues()}
                                                value={item.relationType}
                                                onChange={val => onChangeRelationType(item, val)}
                                                showEmpty={true}
                                            />
                                        </Grid>
                                        <IconButton edge="end" aria-label="delete"
                                                    onClick={() => onDelete(item)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Grid>
                                </ListItem>
                            </div>
                        })
                    }
                </div>
            }

            <Grid container className={classes.buttonWrapper}>
                <Grid item>
                    <Button
                        className={classes.addButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon/>}
                        onClick={onAddNewRelation}
                    >
                        Add lot association
                    </Button>
                </Grid>
                {hasChanged ?
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid> : null
                }
            </Grid>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {dialogActions.map((action, index) => (
                        <Button key={index} onClick={() => handleDialogAction(action.action)}>
                            {action.text}
                        </Button>
                    ))}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditUserLotRelations;
