import * as React from "react";

function IconDrag(props) {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.63623 1C0.63623 0.447715 1.08395 0 1.63623 0C2.18852 0 2.63623 0.447715 2.63623 1C2.63623 1.55228 2.18852 2 1.63623 2C1.08395 2 0.63623 1.55228 0.63623 1Z" fill="#8793A1" />
      <path d="M0.63623 4.5C0.63623 3.94772 1.08395 3.5 1.63623 3.5C2.18852 3.5 2.63623 3.94772 2.63623 4.5C2.63623 5.05228 2.18852 5.5 1.63623 5.5C1.08395 5.5 0.63623 5.05228 0.63623 4.5Z" fill="#8793A1" />
      <path d="M4.13623 1C4.13623 0.447715 4.58395 0 5.13623 0C5.68852 0 6.13623 0.447715 6.13623 1C6.13623 1.55228 5.68852 2 5.13623 2C4.58395 2 4.13623 1.55228 4.13623 1Z" fill="#8793A1" />
      <path d="M4.13623 4.5C4.13623 3.94772 4.58395 3.5 5.13623 3.5C5.68852 3.5 6.13623 3.94772 6.13623 4.5C6.13623 5.05228 5.68852 5.5 5.13623 5.5C4.58395 5.5 4.13623 5.05228 4.13623 4.5Z" fill="#8793A1" />
      <path d="M7.63623 1C7.63623 0.447715 8.08395 0 8.63623 0C9.18851 0 9.63623 0.447715 9.63623 1C9.63623 1.55228 9.18851 2 8.63623 2C8.08395 2 7.63623 1.55228 7.63623 1Z" fill="#8793A1" />
      <path d="M7.63623 4.5C7.63623 3.94772 8.08395 3.5 8.63623 3.5C9.18851 3.5 9.63623 3.94772 9.63623 4.5C9.63623 5.05228 9.18851 5.5 8.63623 5.5C8.08395 5.5 7.63623 5.05228 7.63623 4.5Z" fill="#8793A1" />
    </svg>

  );
}

export default IconDrag;
