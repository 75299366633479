import { Grid } from "@material-ui/core";
import { useGetAllActivityTypesQuery } from "features/activity/activitySlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Tooltip as MuiTooltip } from '@material-ui/core';
import GraphHeader from "../GraphHeader/GraphHeader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";
import { dashboardColors } from "../workorderDashboardConsts";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { GraphLoader } from "../GraphLoader";
import { activityStatuses } from "features/activity/activityConsts";

const WorkorderSlaGraph = ({ workorders, loading }) => {

    const classes = workorderDashboardPageStyles();

    let { data: clients, error: clientsError, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    const cleaningType = activityTypes?.find(a => a.name == 'Cleaning');

    const filteredMechanicalActivities = workorders?.filter(a => a.activityStatus == activityStatuses.ItemCompleted && a.activityTypeId != cleaningType?.id);
    const withinSlaCount = filteredMechanicalActivities?.filter(a => a.withinSla)?.length;

    const SLAData = [
        { name: "Within SLA", value: withinSlaCount },
        { name: "Above SLA", value: filteredMechanicalActivities?.length - withinSlaCount },
    ];

    const mapExportData = row => {
        return ({
            'Client': clients?.find(c => c.id == row.clientId)?.name,
            'Within SLA': row.withinSla.length,
            'Above SLA': row.aboveSla.length
        })
    };

    const renderColorfulLegendText = (value) => {
        return (
            <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
                {value}
            </span>
        );
    };

    const CustomTooltip = ({ payload }) => {
        return (
            <div>
                <div className={classes.tooltip} >
                    <p>
                        <b>{payload?.[0]?.payload?.name} </b>
                        {payload?.[0]?.payload?.value}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <Grid container direction="column" className={classes.graphWrapper} >
            <Grid item>
                <GraphHeader
                    title={' Activities Within SLA'}
                    rightContent={
                        <MuiTooltip title={'The SLA calcultion does not include washes'}>
                            <InfoOutlinedIcon />
                        </MuiTooltip>
                    }
                />
            </Grid>
            <Grid item style={{ height: 300 }}>
                <ResponsiveContainer >
                    <PieChart>
                        <Pie
                            data={SLAData}
                            dataKey="value"
                            nameKey="name"
                            cx={"50%"} cy={"50%"}
                            innerRadius={70} outerRadius={90}
                            paddingAngle={0.5} >
                            {SLAData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={dashboardColors[index % dashboardColors.length]} />
                            ))}
                        </Pie>
                        <Legend
                            height={36}
                            iconType="circle"
                            layout="horizontal"
                            verticalAlign="bottom"
                            iconSize={10}
                            //padding={5}
                            formatter={renderColorfulLegendText}
                        //wrapperStyle={{ top: 200, left: 15 }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
            <GraphLoader loading={loading} />
        </Grid>
    )
}

export default WorkorderSlaGraph;