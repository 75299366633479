import { groupBy } from "lodash";
import moment from "moment";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, LabelList, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { theme } from "styles/theme";
import GraphHeader from "../GraphHeader";
import { billingDashboardStyle } from "../style";


const BillingPerProgramGraph = ({ data }) => {
    const classes = billingDashboardStyle();


    const dataGrouped = {};
    data?.forEach(req => {
        if (!dataGrouped[req.programName]) {
            dataGrouped[req.programName] = { name: req.programName, totalRevenue: 0, cost: 0, charge: 0, count: 0 };
        }
        dataGrouped[req.programName].cost += req.totalCost;
        dataGrouped[req.programName].charge += req.totalChargeToClient;
        dataGrouped[req.programName].totalRevenue += req.totalRevenue;
        dataGrouped[req.programName].count++;
    });

    const COLORS = ['#f0c808', '#086788', '#06aed5', '#82ca9d'];

    const values = Object.values(dataGrouped);

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        return (
            name
        );
    }
    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.graphTootltip}>
                <p>
                    <div><b>{payload?.[0]?.payload?.name} </b></div>
                    ${payload?.[0]?.payload?.totalRevenue.toFixed(2)}
                </p>
            </div>
        );
    };


    return (
        <div style={{ width: "100%", height: 500 }}>
            <GraphHeader title={'Revenue Per Program'} />
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400} margin={{ bottom: 100 }}>
                    <Pie
                        nameKey="name"
                        dataKey="totalRevenue"
                        isAnimationActive={false}
                        data={values}
                        outerRadius={120}
                        fill="#8884d8"
                        label={renderCustomizedLabel}
                    >
                        {values.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default BillingPerProgramGraph;
