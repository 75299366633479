import { makeStyles } from "@material-ui/core";

export const activityTypeStyle = makeStyles((theme) => ({
    errorIcon: {
        fill: 'white',
    },
    errorIconWrapper:{
        background: '#fd8f8f',
    },
    errorTooltip: {
        padding: '2em',
        width: '500px'
    },
    card: {
        margin: '1rem 0',
        padding: '2rem ',
        paddingBottom: '5rem',
        height: '100%'
    },
    titleWrapper: {
        margin: '1rem 0'
    },
    warning: {
        marginBottom: '1em',
        alignItems: 'center',
        "& .MuiAlert-icon": {
            fontSize: 25
        },
    }
}));