import { keyBy } from "lodash"
import FormatService from "services/formatService";

export const InvoiceStatuses = {
    PENDING: 1,
    APPROVED: 2,
    NEEDSATTENTION: 3,
    REJECTED: 4,
    SENDQUICKBOOKS: 5
}

export const ServiceItemTypes = {
    TRANSPORT: 1,
    FUEL: 2,
}

export const InvoiceStatusList = [
    {
        id: InvoiceStatuses.PENDING,
        name: 'Pending',
        color: '#7FC7FF'
    },
    {
        id: InvoiceStatuses.APPROVED,
        name: 'Approved',
        color: '#90C534'
    },
    {
        id: InvoiceStatuses.REJECTED,
        name: 'Rejected',
        color: '#f16d6d'
    },
    {
        id: InvoiceStatuses.NEEDSATTENTION,
        name: 'Needs Attention',
        color: '#FFB533'
    },
    {
        id: InvoiceStatuses.SENDQUICKBOOKS,
        name: 'Sent to QuickBooks',
        color: '#0DBF45'
    }
]


export const loadInvoiceColumns = [
    { name: "QB Bill Number", key: "invoiceNumber", selectedByDefault: true, width: 100 },
    { name: "QB Invoice Number", key: "quickbooksInvoiceDocumentNumber", selectedByDefault: true, width: 100 },
    { name: "Invoice Date", key: "issueDate", component: (row) => <>{FormatService.formatDate(row?.issueDate)}</>, selectedByDefault: true, width: 100 },
    { name: "Vendor", key: "vendorName", selectedByDefault: true, width: 100 },
    { name: "Customer", key: "customerName", selectedByDefault: true },
    { name: "Load ID", key: "externalId", selectedByDefault: true, width: 100 },
    { name: "Departure", key: "pickupLotName", selectedByDefault: true, width: 200 },
    { name: "Arrival", key: "deliveryLotName", selectedByDefault: true, width: 200 },
    { name: "Distance", key: "miles", selectedByDefault: true, width: 100 },
    { name: "Carrier cost", key: "carrierCost", selectedByDefault: true, width: 100 },
    { name: "Fuel cost", key: "fuelCost", selectedByDefault: true, width: 100 },
];

export const InvoiceStatusMap = keyBy(InvoiceStatusList, 'id');