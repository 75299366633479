
function SvgIconGps(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M9 14.6875C5.86364 14.6875 3.3125 12.1364 3.3125 9C3.3125 5.86364 5.86364 3.3125 9 3.3125C12.1364 3.3125 14.6875 5.86364 14.6875 9C14.6875 12.1364 12.1364 14.6875 9 14.6875ZM9 3.4375C5.93386 3.4375 3.4375 5.93386 3.4375 9C3.4375 12.0661 5.93386 14.5625 9 14.5625C12.0661 14.5625 14.5625 12.0661 14.5625 9C14.5625 5.93386 12.0661 3.4375 9 3.4375Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M9 11.3125C7.72364 11.3125 6.6875 10.2764 6.6875 9C6.6875 7.72364 7.72364 6.6875 9 6.6875C10.2764 6.6875 11.3125 7.72364 11.3125 9C11.3125 10.2764 10.2764 11.3125 9 11.3125ZM9 6.8125C7.79386 6.8125 6.8125 7.79386 6.8125 9C6.8125 10.2061 7.79386 11.1875 9 11.1875C10.2061 11.1875 11.1875 10.2061 11.1875 9C11.1875 7.79386 10.2061 6.8125 9 6.8125Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M9 3.0625C8.98694 3.0625 8.97128 3.0573 8.95699 3.04301C8.9427 3.02872 8.9375 3.01306 8.9375 3V1.5C8.9375 1.48694 8.9427 1.47128 8.95699 1.45699C8.97128 1.4427 8.98694 1.4375 9 1.4375C9.01306 1.4375 9.02872 1.4427 9.04301 1.45699C9.0573 1.47128 9.0625 1.48694 9.0625 1.5V3C9.0625 3.01306 9.0573 3.02872 9.04301 3.04301C9.02872 3.0573 9.01306 3.0625 9 3.0625Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M3 9.0625H1.5C1.48694 9.0625 1.47128 9.0573 1.45699 9.04301C1.4427 9.02872 1.4375 9.01306 1.4375 9C1.4375 8.98694 1.4427 8.97128 1.45699 8.95699C1.47128 8.9427 1.48694 8.9375 1.5 8.9375H3C3.01306 8.9375 3.02872 8.9427 3.04301 8.95699C3.0573 8.97128 3.0625 8.98694 3.0625 9C3.0625 9.01306 3.0573 9.02872 3.04301 9.04301C3.02872 9.0573 3.01306 9.0625 3 9.0625Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M9 16.5625C8.98694 16.5625 8.97128 16.5573 8.95699 16.543C8.9427 16.5287 8.9375 16.5131 8.9375 16.5V15C8.9375 14.9869 8.9427 14.9713 8.95699 14.957C8.97128 14.9427 8.98694 14.9375 9 14.9375C9.01306 14.9375 9.02872 14.9427 9.04301 14.957C9.0573 14.9713 9.0625 14.9869 9.0625 15V16.5C9.0625 16.5131 9.0573 16.5287 9.04301 16.543C9.02872 16.5573 9.01306 16.5625 9 16.5625Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M16.5 9.0625H15C14.9869 9.0625 14.9713 9.0573 14.957 9.04301C14.9427 9.02872 14.9375 9.01306 14.9375 9C14.9375 8.98694 14.9427 8.97128 14.957 8.95699C14.9713 8.9427 14.9869 8.9375 15 8.9375H16.5C16.5131 8.9375 16.5287 8.9427 16.543 8.95699C16.5573 8.97128 16.5625 8.98694 16.5625 9C16.5625 9.01306 16.5573 9.02872 16.543 9.04301C16.5287 9.0573 16.5131 9.0625 16.5 9.0625Z" fill="#0090FE" stroke="#0090FE" />
    </svg>
  );
}

export default SvgIconGps;
