import { makeStyles } from "@material-ui/core"

export const addLegStyle = makeStyles((theme) => ({
    assetSelector: {
        padding: '2em',
        borderRadius: '5px',
        border: '1px solid #efefef',
        marginBottom: '1em'
    },
    desc: {
        marginBottom: '1em'
    },
    option: {
        minHeight: '2.5em',
        fontSize: '0.8em'
    },
    loadInfo: {
        borderRadius: '5px',
        border: '1px solid #efefef',
    }
}));
