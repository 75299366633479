import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';

export const clientConfigApi = baseApi.injectEndpoints({
    reducerPath: 'clientConfigApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.CLIENT_CONFIG],
    endpoints: (builder) => ({
        getClientsWithConfigs: builder.query({
            query: (clientId) => `clientConfig/configs?${clientId ? `clientId=${clientId}` : ''}`,
            providesTags: [apiTags.CLIENT_CONFIG],
        }),
        updateClient: builder.mutation({
            query: clientConfigInfo => ({
                url: "clientConfig/update",
                method: "POST",
                body: clientConfigInfo
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG, apiTags.CLIENT]
        }),
        addClient: builder.mutation({
            query: client => ({
                url: "clientConfig/add",
                method: "POST",
                body: client
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG, apiTags.CLIENT]
        }),
        getBusinessUnits: builder.query({
            query: () => `clientValues?type=bu`,
            providesTags: [apiTags.CLIENT_VALUES],
        }),
        getDefaultStatusByClientId: builder.query({
            query: (params) => `clientConfig/defaultStatusChecking?${Object.keys(params).filter(key => params[key]).map(key => `${key}=${params[key]}`).join('&')}`,
            providesTags: [apiTags.CLIENT_VALUES],
        }),
        getDefaultCheckoutStatusByClientId: builder.query({
            query: (params) => `clientConfig/defaultCheckoutStatus?${Object.keys(params).filter(key => params[key]).map(key => `${key}=${params[key]}`).join('&')}`,
            providesTags: [apiTags.CLIENT_VALUES],
        }),
        saveBusinessUnits: builder.mutation({
            query: clientValues => ({
                url: `clientValues`,
                method: "POST",
                body: clientValues,
            }),
            invalidatesTags: [apiTags.CLIENT_VALUES]
        }),
        updateDefaultCheckinStatus: builder.mutation({
            query: clientStatusEntity => ({
                url: "clientConfig/updateDefaultCheckinStatus",
                method: "POST",
                body: clientStatusEntity
            }),
            invalidatesTags: [apiTags.CLIENT_VALUES]
        }),
        updateDefaultCheckoutStatus: builder.mutation({
            query: clientStatusEntity => ({
                url: "clientConfig/updateDefaultCheckoutStatus",
                method: "POST",
                body: clientStatusEntity
            }),
            invalidatesTags: [apiTags.CLIENT_VALUES]
        }),
    }),
});

export const {
    useGetClientsWithConfigsQuery,
    useUpdateClientMutation,
    useAddClientMutation,
    useGetBusinessUnitsQuery,
    useSaveBusinessUnitsMutation,
    useGetDefaultStatusByClientIdQuery,
    useGetDefaultCheckoutStatusByClientIdQuery,
    useUpdateDefaultCheckinStatusMutation,
    useUpdateDefaultCheckoutStatusMutation
} = clientConfigApi;