import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@mui/material";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CopyToClipboard from "components/CopyToClipboard";
import CustomInput from "components/CustomInput";
import DropdownFilter from "components/DropdownFilter";
import { EntityAuditTooltip } from "components/EntityAudit/EntityAuditTooltip";
import NotificationProvider from "components/NotificationProvider";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetUsersNoFilteringQuery } from "features/users/usersSlice";
import usePrompt from "hooks/usePrompt";
import { IconClient, IconDatePicker, IconDollar, IconEdit, IconGroup, IconInfo, IconLot, IconText, IconUser, IconWarning } from "icons";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import FormatService from "services/formatService";
import { arrayToObject } from "utils/object-util";
import EnterCommentDialog from "../EnterCommentDialog/EnterCommentDialog";
import { IssueTrackerCompleteWorkflow } from "../IssueTrackerCompleteWorkflow/IssueTrackerCompleteWorkflow";
import { IssueTrackerDateSelector } from "../IssueTrackerDateSelector/IssueTrackerDateSelector";
import { IssueTrackerDescriptionField } from "../IssueTrackerDescriptionField/IssueTrackerDescriptionField";
import { IssueTrackerLinks } from "../IssueTrackerLinks/IssueTrackerLinks";
import { IssueTrackerUser } from "../IssueTrackerUser/IssueTrackerUser";
import LinkedAssetsSelector from "../LinkedAssetsSelector/LinkedAssetsSelector";
import { LinkedTicketsSelector } from "../LinkedTicketsSelector/LinkedTicketsSelector";
import LinkedWorkorderSelector from "../LinkedWorkorderSelector/LinkedWorkorderSelector";
import { LotSelector } from "../LotSelector/LotSelector";
import { PrioritySelector } from "../PrioritySelector/PrioritySelector";
import { StatusSelector } from "../StatusSelector/StatusSelector";
import TaskAttachments from "../TaskAttachments/TaskAttachments";
import TaskComments from "../TaskComments/TaskComments";
import { completedStatuses, issueTrackerStatuses, issueTrackerTypes, issueTrackerTypesList } from "../issueTrackerConsts";
import { useAddIssueTrackerMutation, useGetIssueTrackerCategoriesQuery, useGetStatusIssueTrackerQuery, useUpdateIssueTrackerMutation } from "../issuesTrackerSlice";
import { StatusTypesTooltip } from "./StatusTypesTooltip";
import { UpdateIssueStyle } from "./style";
import { useSelector } from "react-redux";
import { selectUserClientId } from "features/user/userSlice";
import { issueTrackerPermissions } from "components/Authorize/permissions/issueTrackerPermissions";
import { hasPermission } from "components/Authorize/authorizeUtil";

const IssueTrackerView = ({
    issueTracker,
    onClose,
    withDialog
}) => {
    const classes = UpdateIssueStyle();

    let loggedInClientClaim = useSelector(selectUserClientId);
    let hasClientPerm = hasPermission(issueTrackerPermissions.VIEW_ASSIGNED_TO_CLIENT.key);

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !!loggedInClientClaim?.length });
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: categories, error: categoriesError, isFetching: isLoadingCategories } = useGetIssueTrackerCategoriesQuery();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersNoFilteringQuery();

    const clientsLookup = arrayToObject(clients);
    const statusLookup = arrayToObject(statuses);
    const categoryLookup = arrayToObject(categories);
    const lotsLookup = arrayToObject(lots);

    const [issueTrackerToEdit, setIssueTrackerToEdit] = useState(issueTracker);
    const [changedIssueTracker, setChangedIssueTracker] = useState({ id: issueTracker?.id });
    const [changed, setChanged] = useState(false);
    const [errors, setErrors] = useState({});
    const [showAllLots, setShowAllLots] = useState(false);
    const [showAllUsers, setShowAllUsers] = useState(false);
    const [editMode, setEditMode] = useState(withDialog && !issueTracker?.id);
    const { triggerPrompt } = usePrompt();
    const [phoneType, setPhoneType] = useState(issueTracker?.issueType === issueTrackerTypes.PHONE_CALL ? true : false)
    const [checkWorkorders, setCheckWorkorders] = useState(false);
    const [statusChanged, setStatusChanged] = useState(false);
    const [linkedAssets, setLinkedAssets] = useState(issueTracker?.linkedAssets ?? []);
    const [linkedWorkorders, setLinkedWorkorders] = useState(issueTracker?.linkedWorkOrders ?? []);
    const [openCommentDialog, setOpenCommentDialog] = useState(false);

    useEffect(() => {
        setIssueTrackerToEdit({ ...(issueTracker ?? {}) });
        setChangedIssueTracker({ id: issueTracker?.id, issueType: issueTracker?.issueType, clientId: issueTracker?.clientId, lotId: issueTracker?.lotId });
        setChanged(false);
        setErrors({});
        setPhoneType(issueTracker?.issueType === issueTrackerTypes.PHONE_CALL ? true : false);
        setStatusChanged(false);
        setEditMode(withDialog && !issueTracker?.id);
        setLinkedAssets(issueTracker?.linkedAssets ?? []);
        setLinkedWorkorders(issueTracker?.linkedWorkOrders ?? []);
    }, [issueTracker, isLoadingLots]);

    const onFieldChange = (key, value) => {
        const handleFieldChange = () => {
            setIssueTrackerToEdit({ ...issueTrackerToEdit, [key]: value });
            setChangedIssueTracker({ ...changedIssueTracker, [key]: value });
            setChanged(true);
            if (value) {
                setErrors({ ...errors, [key]: undefined });
            }
            if (key === 'status') {
                setStatusChanged(true);
            }
            if (key === 'issueType') {
                setPhoneType(value === issueTrackerTypes.PHONE_CALL);
            }
        };

        if (key === 'clientId' && linkedAssets.length > 0) {
            triggerPrompt({
                title: "Warning",
                content: 'You are about to change the client on this issue causing you to lose any linked assets or work orders. Would you like to continue?',
                onConfirm: () => {
                    setLinkedAssets([]);
                    setLinkedWorkorders([]);
                    handleFieldChange();
                },
                onCancel: () => {
                    return;
                }
            });
            return;
        }

        if (key === 'lotId' && linkedAssets.length > 0) {
            triggerPrompt({
                title: "Warning",
                content: 'You are about to change the lot on this issue causing you to lose any linked assets or work orders. Would you like to continue?',
                onConfirm: () => {
                    setLinkedAssets([]);
                    setLinkedWorkorders([]);
                    handleFieldChange();
                },
                onCancel: () => {
                    return;
                }
            });
            return;
        }

        // If no prompts are needed, do the field change
        handleFieldChange();
    };

    const setIssueDate = (wos) => {
        let orderedWorkorders = orderBy(wos, 'activityDate', ['asc']);
        let date = orderedWorkorders[0]?.activityDate;
        onFieldChange('issueDate', date);
    }

    const validate = () => {
        const msgs = {};
        let valid = true;

        [titleField, ...issueTrackerFields]?.map(field => {
            if (field?.required == true && !issueTrackerToEdit?.[field?.key]) {
                valid = false;
                msgs[field?.key] = `${field.label} is required`
            }
        })

        setErrors(msgs);
        return valid;
    }

    const titleField = {
        key: 'title',
        label: 'Title',
        hideLabel: true,
        component: <CustomInput
            id="title"
            value={issueTrackerToEdit?.title ?? ''}
            onChange={(val) => onFieldChange('title', val)}
        />,
        required: true,
        width: 12,
        viewComponent: <div className={classes.titleField}>{issueTrackerToEdit?.title}</div>,

    }
    const isNewIssue = !issueTracker?.id;

    const issueTrackerFields = [
        ...(phoneType ? [] : [{
            key: 'priority',
            label: 'Priority',
            component: <PrioritySelector priority={issueTrackerToEdit?.priority} onChange={(val) => onFieldChange('priority', +val)} />,
            viewComponent: <PrioritySelector priority={issueTrackerToEdit?.priority} readonly />,
            width: 6,
            icon: <IconWarning />
        }]),
        ...((isNewIssue || phoneType) ? [] : [{
            key: 'id',
            label: 'Issue #',
            width: 6,
            viewComponent: <Grid container>
                <div>#{issueTrackerToEdit?.id}</div>
                <div><CopyToClipboard title={'Copy link to issue'} text={`${window.location.origin}/ticket/${issueTrackerToEdit?.id}`} /></div>
            </Grid>
        }]),
        {
            key: 'description',
            label: phoneType ? 'Description' : 'Problem Description',
            component: <IssueTrackerDescriptionField
                content={issueTrackerToEdit?.description ?? ''}
                onChange={(val) => onFieldChange('description', val)}
            />,
            viewComponent: <IssueTrackerDescriptionField
                content={issueTrackerToEdit?.description ?? ''}
                readonly
            />,
            required: true,
            width: 12,
            icon: <IconText />
        },
        {
            key: 'issueType',
            label: 'Ticket Type',
            component: <div>
                <DropdownFilter
                    id="issueType"
                    value={issueTrackerToEdit?.issueType}
                    onSelect={(lot) => onFieldChange('issueType', lot?.id)}
                    elementType="dropdown"
                    values={issueTrackerToEdit?.issueType != issueTrackerTypes.PHONE_CALL ? issueTrackerTypesList.filter(t => t.id != issueTrackerTypes.PHONE_CALL) : issueTrackerTypesList}
                    showEmpty
                    withTypeAhead
                    disabled={phoneType}
                />
            </div>,
            viewComponent: <>{issueTrackerTypesList?.find(t => t.id == issueTrackerToEdit?.issueType)?.name}</>,
            width: 12
        },
        {
            key: 'category',
            label: 'Category',
            component: <DropdownFilter
                id="category"
                value={issueTrackerToEdit?.category}
                onSelect={(val) => onFieldChange('category', +val?.id)}
                elementType="dropdown"
                values={categories}
                showEmpty
                withTypeAhead
            />,
            required: true,
            viewComponent: <>{categoryLookup?.[issueTrackerToEdit?.category]?.name}</>,
            width: 6,
            icon: <IconGroup />
        },
        ...(phoneType ? [] : [{
            key: 'status',
            label: <Grid container><div>Status</div> <StatusTypesTooltip /></Grid>,
            component: <StatusSelector status={issueTrackerToEdit?.status} onChange={(val) => onFieldChange('status', val)} />,
            viewComponent: <StatusSelector status={issueTrackerToEdit?.status} readonly />,
            width: 6,
            icon: <IconInfo />
        }]),
        {
            key: 'reporter',
            label: 'Reporter',
            component: <>
                <DropdownFilter
                    withTypeAhead
                    id="reporter"
                    value={issueTrackerToEdit?.reporter ?? ''}
                    onSelect={(val) => onFieldChange('reporter', val?.id ?? '')}
                    elementType="dropdown"
                    values={(showAllUsers ? users : users?.filter(l => l.isPmfUser || l.id == issueTrackerToEdit?.reporter)) ?? []}
                    showEmpty
                    renderOption={(option) => <IssueTrackerUser userId={option?.id} />}
                />
            </>,
            viewComponent: <IssueTrackerUser userId={issueTrackerToEdit?.reporter} />,
            width: 6,
            icon: <IconUser />,
            editFieldAction: <div item><Checkbox
                checked={showAllUsers}
                onChange={(e, value) => setShowAllUsers(value)}
                title="View non-pmf users?"
            /> View non-pmf users?</div>
        },
        ...(phoneType ? [] : [{
            key: 'assignee',
            label: 'Assignee',
            component: <>
                <DropdownFilter
                    withTypeAhead
                    id="assignee"
                    value={issueTrackerToEdit?.assignee ?? ''}
                    onSelect={(val) => onFieldChange('assignee', val?.id ?? '')}
                    elementType="dropdown"
                    values={(showAllUsers ? users : users?.filter(l => l.isPmfUser || l.id == issueTrackerToEdit?.assignee)) ?? []}
                    showEmpty
                    renderOption={(option) => <IssueTrackerUser userId={option?.id} />}
                />
            </>,
            viewComponent: <IssueTrackerUser userId={issueTrackerToEdit?.assignee} />,
            width: 6,
            icon: <IconUser />,
            editFieldAction: <div item><Checkbox
                checked={showAllUsers}
                onChange={(e, value) => setShowAllUsers(value)}
                title="View non-pmf users?"
            /> View non-pmf users?</div>
        }]),
        {
            key: 'lotId',
            label: 'Lot',
            component: <div>
                <DropdownFilter
                    id="lotId"
                    value={issueTrackerToEdit?.lotId}
                    onSelect={(lot) => onFieldChange('lotId', lot?.id)}
                    elementType="dropdown"
                    values={(showAllLots ? lots : lots?.filter(l => l.pmfLot || l.id == issueTrackerToEdit?.lotId)) ?? []}
                    showEmpty
                    withTypeAhead
                />
            </div>,
            editFieldAction: <div item><Checkbox
                checked={showAllLots}
                onChange={(e, value) => setShowAllLots(value)}
                title="View non-pmf lots?"
            /> View non-pmf lots?</div>,
            required: true,
            viewComponent: <>
                <LotSelector lotId={issueTrackerToEdit?.lotId} lotLookup={lotsLookup} />
            </>,
            width: 6,
            icon: <IconLot />
        },
        ...(!!loggedInClientClaim?.length ? [] : [{
            label: "Client",
            key: "clientId",
            component: <DropdownFilter
                id="clientId"
                value={issueTrackerToEdit?.clientId}
                onSelect={(val) => onFieldChange('clientId', +val?.id)}
                elementType="dropdown"
                values={clients}
                showEmpty
                withTypeAhead
            />,
            viewComponent: <>{clientsLookup?.[issueTrackerToEdit?.clientId]?.name}</>,
            width: 6,
            icon: <IconClient />
        }]),
        {
            key: 'createdDate',
            label: 'Reported Date',
            viewComponent: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                field={'createdDate'}
                readonly
                date={issueTrackerToEdit?.createdDate}
            />,
            width: 6,
            icon: <IconDatePicker />
        },
        ...(phoneType ? [] : [{
            key: 'issueDate',
            label: 'Issue Date',
            component: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                date={issueTrackerToEdit?.issueDate}
                onChange={(val) => onFieldChange('issueDate', val ? val : null)} />,
            viewComponent: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                field={'issueDate'}
                readonly
                date={issueTrackerToEdit?.issueDate}
            />,
            width: 6,
            icon: <IconDatePicker />
        }]),
        ...(phoneType ? [] : [{
            key: 'solutionEta',
            label: 'Due Date',
            component: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                date={issueTrackerToEdit?.solutionEta}
                onChange={(val) => onFieldChange('solutionEta', val ? val : null)}
            />,
            required: issueTrackerToEdit?.status == issueTrackerStatuses.RemedyScheduled,
            viewComponent: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                field={'solutionEta'}
                readonly
                date={issueTrackerToEdit?.solutionEta}
            />,
            width: 6,
            icon: <IconDatePicker />
        }]),
        ...(phoneType ? [] : [{
            key: 'completedDate',
            label: 'Completed Date',
            component: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                date={issueTrackerToEdit?.completedDate}
                onChange={(val) => onFieldChange('completedDate', val ? val : null)}
            />,
            required: issueTrackerToEdit?.status == issueTrackerStatuses.Complete,
            viewComponent: <IssueTrackerDateSelector
                issue={issueTrackerToEdit}
                field={'completedDate'}
                readonly
                date={issueTrackerToEdit?.completedDate}
            />,
            width: 6,
            icon: <IconDatePicker />
        }]),
        ...(phoneType ? [] : [{
            key: 'cost',
            label: 'Total cost',
            component: <CustomInput
                id="cost"
                value={issueTrackerToEdit?.cost}
                onChange={(val) => onFieldChange('cost', val)}
                numberOnly
                step="0.1"
            />,
            viewComponent: <>{FormatService?.formatCurrency(issueTrackerToEdit?.cost)}</>,
            width: 6,
            icon: <IconDollar />,
        }]),
        ...(!phoneType ? [{
            key: 'vehicle',
            label: <Grid container>
                <div>Asset</div>
                {editMode && !!issueTrackerToEdit?.linkedLoads?.length ?
                    <Tooltip style={{ paddingLeft: '.5em' }} title={"This ticket is linked to a load, so you are unable to edit the asset"}>
                        <div><IconInfo /></div>
                    </Tooltip> : null}
            </Grid>,
            component: <LinkedAssetsSelector
                assets={linkedAssets}
                setAssets={(assets) => { setLinkedAssets(assets); setChanged(true); !(!!assets.length) ?? setLinkedWorkorders([]); }}
                selectedClient={hasClientPerm ? parseInt(loggedInClientClaim) : issueTrackerToEdit?.clientId}
                selectedLot={issueTrackerToEdit?.lotId}
                disabled={(!issueTrackerToEdit?.clientId && !hasClientPerm) || !issueTrackerToEdit?.lotId || !!issueTrackerToEdit?.linkedLoads?.length}
            />,
            viewComponent: <IssueTrackerLinks
                issue={issueTrackerToEdit}
                showAssets
            />,
            width: 6,
        }] : []),
        ...((linkedAssets?.length == 1 && !phoneType) ? [{
            key: 'workorder',
            label: 'Activity',
            component: <LinkedWorkorderSelector
                vehicleId={linkedAssets?.length == 1 ? linkedAssets[0]?.id : null}
                linkedWorkorders={linkedWorkorders}
                setLinkedWorkorders={(wos) => { setLinkedWorkorders(wos); setChanged(true); setIssueDate(wos) }}
            />,
            viewComponent: <IssueTrackerLinks
                issue={issueTrackerToEdit}
                showWorkorders
            />,
            width: 6,
        }] : []),
        ...(issueTrackerToEdit?.linkedLoads?.length ? [{
            key: 'load',
            label: 'Transport',
            viewComponent: <IssueTrackerLinks issue={issueTrackerToEdit} showLoads />,
            width: 6,
        }] : []),
        ...(!isNewIssue ? [{
            key: 'linkedTickets',
            label: <>Linked Tickets</>,
            viewComponent: <LinkedTicketsSelector
                issue={issueTrackerToEdit}
                onSelect={(selectedIssue) => {
                    let list = issueTrackerToEdit?.linkedTickets?.filter(t => !t.isNew) ?? [];
                    list.push({ ...selectedIssue, isNew: true });
                    onFieldChange('linkedTickets', list);
                }}
            />,
            width: 12,
        }] : []),
        ...(phoneType ? [] : [{
            key: 'solutionDescription',
            label: 'Solution description',
            component: <IssueTrackerDescriptionField
                content={issueTrackerToEdit?.solutionDescription ?? ''}
                onChange={(val) => onFieldChange('solutionDescription', val)}
            />,
            required: issueTrackerToEdit?.status == issueTrackerStatuses.Complete,
            viewComponent: <IssueTrackerDescriptionField
                content={issueTrackerToEdit?.solutionDescription ?? ''}
                readonly
            />,
            width: 12,
            icon: <IconText />
        }]),
        ...(phoneType ? [] : [{
            key: 'label',
            label: 'Label',
            component: <CustomInput
                value={issueTrackerToEdit?.label ?? ''}
                onChange={(val) => onFieldChange('label', val)}
            />,
            width: 12,
            icon: <IconText />
        }])
    ]

    const [addIssueTracker, { isLoading: isAdding }] = useAddIssueTrackerMutation();
    const [updateIssueTracker, { isLoading: isUpdating }] = useUpdateIssueTrackerMutation();

    const isSaving = isAdding || isUpdating;

    const onSubmit = async () => {
        if (!validate()) {
            return;
        }

        if (completedStatuses.includes(changedIssueTracker?.status)
            && issueTrackerToEdit?.linkedWorkOrders?.length
            && issueTrackerToEdit?.linkedWorkOrders?.some(a => !a.isComplete)) {
            setCheckWorkorders(true);
            return;
        }
        if (changedIssueTracker?.id && statusChanged) {
            setOpenCommentDialog(true);
        } else {
            await saveChanges(changedIssueTracker);
        }
    };

    const saveChanges = async (issueToSave) => {
        let result;
        issueToSave = { ...issueToSave, linkedAssetIds: linkedAssets?.map(a => a.id), linkedWorkOrderIds: (linkedAssets.length != 1) ? [] : linkedWorkorders?.map(w => w.id) }

        if (issueToSave.id) {
            result = await updateIssueTracker(issueToSave);
        }
        else {
            result = await addIssueTracker({ ...issueToSave });
        }

        if (result && !result.error) {
            NotificationProvider.success("Ticket saved successfully");
            setChanged(false);
            !issueToSave?.id && onClose();
            setStatusChanged(false);
            setEditMode(false);
            setLinkedAssets([]);
            setLinkedWorkorders([]);
        } else {
            NotificationProvider.error("Failed to save the ticket. Try again or contact support.");
        }
        setCheckWorkorders(false);
    }

    const onCancel = () => {
        if (changed) {
            triggerPrompt({
                title: "Unsaved Changes",
                content: 'You have unsaved changes, are you sure you want to continue?',
                onConfirm: () => {
                    setIssueTrackerToEdit({ ...(issueTracker ?? {}) });
                    setEditMode(false);
                }
            });
        } else {
            setIssueTrackerToEdit({ ...(issueTracker ?? {}) });
            setEditMode(false);
        }
    }

    const onEdit = () => {
        setEditMode(true);
    }

    const getField = (field, key) => {
        return <Grid item container alignItems="center" spacing={1} xs={field?.width} key={key}>
            {field.hasDivider && <Grid item xs={12} style={{ marginBottom: '1em' }}><Divider /></Grid>}
            {!field?.hideLabel && <Grid justifyContent="space-between" container className={classes.fieldLabel} xs={12}>
                <Grid item container spacing={1} alignItems="center">
                    <Grid item> {field?.label}</Grid>
                    {field?.required && editMode && <Grid item> <span style={{ color: 'red' }}>*</span></Grid>}
                </Grid>
                {editMode && field.editFieldAction && <Grid item>{field.editFieldAction}</Grid>}
            </Grid>}
            <Grid className={classes.field} xs={12}
            >
                {editMode ? field?.component ?? field?.viewComponent : <>{field?.viewComponent ?? issueTrackerToEdit?.[field?.key]}</>}
            </Grid>
            {!!errors?.[field?.key] && <Grid xs={12} className={classes.alertWrapper}>
                <Alert severity='error' className={classes.alert} >
                    {errors?.[field?.key]}
                </Alert>
            </Grid>
            }
        </Grid>
    }

    const content = <Grid container>
        <Grid item xs={editMode ? 12 : 8} container spacing={5} className={classes.content}>
            {withDialog && <Grid item xs={12}>{getField(titleField)}</Grid>}
            {issueTrackerFields?.map((field, index) => getField(field, index))}
        </Grid >
        {!editMode && <Grid item xs={4} container spacing={1} direction='column'>
            <Grid item>
                <TaskComments issueTracker={issueTrackerToEdit} latestStatus={issueTrackerToEdit?.latestStatus} statusLookup={statusLookup} />
            </Grid>
            <Grid item>
                <TaskAttachments issueTracker={issueTrackerToEdit} />
            </Grid>
        </Grid>}
    </Grid>

    const buttons = <Grid className={classes.titleWrapper} xs={12} item container spacing={1} justifyContent="flex-end" alignItems="center">
        {!withDialog && <Grid style={{ marginRight: 'auto' }} item xs={8} container direction="column" spacing={1}>
            <Grid container item alignItems="center">
                <Grid item style={{ whiteSpace: 'nowrap' }} >
                    {getField(titleField)}
                </Grid>
            </Grid>
        </Grid>}

        {!isNewIssue && <Grid item> <EntityAuditTooltip entity={issueTrackerToEdit} /></Grid>}
        {!editMode && <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
            <Grid item><Button className={classes.btn} variant="contained" startIcon={<IconEdit className={classes.editIcon} />} color='primary' onClick={onEdit}>
                Edit
            </Button></Grid></Authorize>}
        {!withDialog && editMode && <Grid item>
            <Button className={classes.btn} variant="outlined" color='primary' onClick={onCancel} >
                Cancel
            </Button></Grid>}
        {withDialog && <Grid item>
            <Button className={classes.btn} variant="outlined" color='primary' onClick={() => onClose()} >
                Cancel
            </Button></Grid>}
        {editMode && <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}><Grid item>
            <Tooltip title={Object.keys(errors)?.length ? 'Please correct the missing or invalid fields.' : ''}>
                <Button className={classes.btn} disabled={isSaving || !changed} variant="contained" color='primary' onClick={onSubmit}>
                    {isSaving ? 'Saving...' : 'Save'}
                </Button>
            </Tooltip>
        </Grid></Authorize>}
        {!withDialog && onClose && <Grid item className={classes.btn}>
            <IconButton onClick={onClose} ><CloseIcon /></IconButton>
        </Grid>}
    </Grid>;

    return (<>
        <IssueTrackerCompleteWorkflow
            onCancel={() => setCheckWorkorders(false)}
            issue={issueTrackerToEdit}
            open={checkWorkorders}
            onSave={async (woStatus) => {
                setCheckWorkorders(false);
                saveChanges({ ...changedIssueTracker, linkedActivityStatus: parseInt(woStatus) });
            }}
        />
        {openCommentDialog &&
            <EnterCommentDialog
                onCancel={() => setOpenCommentDialog(false)}
                open={openCommentDialog}
                onSave={async (note) => {
                    setOpenCommentDialog(false);
                    await saveChanges({ ...changedIssueTracker, note: note.content, latestStatusId: issueTrackerToEdit?.latestStatus.id });
                }}
            />
        }
        {withDialog ?
            <Dialog open={!!issueTracker} onClose={() => onClose()} maxWidth='md' fullWidth>
                <DialogTitle>
                    {isNewIssue ? phoneType ? "Create Phone Call" : "Create Ticket" : 'Edit Ticket'}
                </DialogTitle>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    {buttons}
                </DialogActions>
            </Dialog >
            :
            <>
                {buttons}
                {content}
            </>
        }
    </>
    )
}
export default IssueTrackerView;
