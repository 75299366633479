import { CircularProgress } from "@material-ui/core"

export const GraphLoader = ({ loading, position = 'top' }) => {
    const posStyling = position == 'top' ? { right: '1em', 'top': '1em' } : { right: '0', 'bottom': '0' };
    return (<>{loading &&
        <CircularProgress
            style={{ position: 'absolute', ...posStyling }}
            color="primary"
            size='1em'
        />
    }
    </>
    )
}