import { Button, Grid, IconButton, ListItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LotsDropdown from 'components/SharedDropdowns/LotsDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import { EditListsStyle } from './EditListsStyle';


const EditSitesList = ({ access, lots, setLots }) => {

    const classes = EditListsStyle()

    const onAddNewLot = () => {
        let lotsList = lots ? [...lots] : [];
        lotsList.push({ 'id': null });
        setLots(lotsList);
    }

    const onChangeLot = (item, val) => {
        let lotsList = lots ? [...lots] : [];
        let newItem = { ...item };
        newItem.id = parseInt(val);
        lotsList[lots?.findIndex(i => i == item)] = { ...newItem };
        setLots(lotsList);
    }

    const onDelete = (item) => {
        let lotsList = lots ? [...lots] : [];
        lotsList = lotsList.filter(i => i != item);
        setLots(lotsList);
    }

    return (
        <>
            {
                lots?.map((item, index) => {
                    return <div key={item}>
                        <ListItem>
                            <Grid container spacing={2} >
                                <Grid item xs={10}>
                                    <LotsDropdown
                                        clientId={access?.clientId}
                                        key={index}
                                        title="Lot"
                                        value={item?.id}
                                        onSelect={val => onChangeLot(item, val)}
                                        showEmpty={true}
                                        pmfLot={true}
                                    />
                                </Grid>
                                <IconButton edge="end" aria-label="delete"
                                    onClick={() => onDelete(item)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </ListItem>
                    </div>
                })
            }
            <Grid container>
                <Grid item >
                    <Button
                        className={classes.addItemButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onAddNewLot}
                    >
                        Add lot
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default EditSitesList



