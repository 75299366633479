import { Card, Grid, IconButton, List, Tooltip } from "@material-ui/core";
import DataSearchBar from "components/DataSearchBar";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { IconSearch } from "icons";
import { useEffect, useMemo, useState } from "react";
import FilterService from "services/filterService";
import { StorageInvoiceFieldEnum, TransportFieldEnum, costCodesById } from "./consts";
import CostCodeItem from "./CostCodeItem";
import CostCodeStyles from "./CostCodeStyles";

const useStyles = CostCodeStyles;

const CostCodesList = ({ costCodeType, codes, onEditCode, setDisableUpdate, selectedRows, toggleRowSelected }) => {
    const classes = useStyles();

    let { data: activityItems, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery({skip: costCodeType?.id != costCodesById.ACTIVITIES});
    
    const [items, setItems] = useState();
    const [searchVal, setSearchVal] = useState();
    const [showSearch, setShowSearch] = useState(false);

    let filteredItems = useMemo(() => FilterService.filter([], searchVal, activityItems ?? [], []),  [searchVal, activityItems]);

    useEffect(() => {
        switch (costCodeType?.id) {
            case costCodesById.ACTIVITIES:
                setItems(filteredItems.map(a => ({ id: a.id, name: a.name, costCode: getCostCode(a) })));
                break;
            case costCodesById.STORAGE:
                setItems(StorageInvoiceFieldEnum.map(s => ({ ...s, costCode: getCostCode(s) })));
                break;
            case costCodesById.TRANSPORT:
                setItems(TransportFieldEnum.map(t => ({ ...t, costCode: getCostCode(t) })));
                break;
        }
    }, [filteredItems, costCodeType, codes])

    const getCostCode = (item) => {
        return codes?.find(c => costCodeType.id === costCodesById.ACTIVITIES ? c.activityItemId === item?.id 
                    : costCodeType.id === costCodesById.STORAGE ? c.storageInvoiceField === item?.id 
                    : costCodeType.id === costCodesById.TRANSPORT ? c.transportField == item?.id : false)?.costCode ?? "";
    }

    return (
        <div>
            <Card className={classes.list}>
                <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.card}>
                    <Grid item direction='row'>
                        <Grid container direction='row' alignItems='center'>
                            {costCodeType?.icon}
                            <div className={classes.subTitle}>{costCodeType?.label}</div>
                        </Grid>
                    </Grid>
                    {costCodeType.id === costCodesById.ACTIVITIES && (
                        <Grid item>
                            <Tooltip title="Search Activities">
                                <IconButton size='small' onClick={() => setShowSearch(!showSearch)}>
                                    <IconSearch />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>

                {(costCodeType.id === costCodesById.ACTIVITIES  && showSearch) &&
                    <Grid container className={classes.searchBar}>                        
                        <DataSearchBar 
                            searchVal={searchVal}
                            onSearch={(val) => setSearchVal(val)}
                        /> 
                    </Grid>                              
                }
                <Grid>
                    <List>
                        {items?.map(item =>
                            <CostCodeItem 
                                costCodeType={costCodeType.id}
                                item={item}
                                onClickEdit={onEditCode}
                                setDisableUpdate={setDisableUpdate}
                                selectedRows={selectedRows}
                                toggleRowSelected={toggleRowSelected}
                            />
                        )}        
                    </List>
                </Grid>                        
            </Card>
        </div>
    );
}

export default CostCodesList;
