import React, {useState} from "react";
import {useDropzone} from "react-dropzone";
import Button from "@material-ui/core/Button";
import {selectIsAdmin, selectIsOwner} from "../user/userSlice";
import {useSelector} from "react-redux";
import {useGetLotsQuery} from "../lots/lotSlice";
import {useGetClientsQuery} from "../clients/clientSlice";
import NotificationProvider from "../../components/NotificationProvider";
import VehicleService from "../../services/vehicleService";

/*DataTable*/
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    }
}));

const FileUploadPage = () => {
    const classes = useStyles();
    const fileText = "File";
    const dropMessage = "Drag 'n' drop the file here, or click to select file";
    const maxFiles = 1;
    const accept = {'text/csv': ['.csv']};
    const fileReader = new FileReader();
    const [array, setArray] = useState([]);
    const [file, setFile] = useState(null);
    const isAdmin = useSelector(selectIsAdmin);
    const isOwner = useSelector(selectIsOwner);

    let {data: lots, error: lotError, isLoading: isLoadingLots} = useGetLotsQuery();
    let {data: clients, error: clientError, isLoading: isLoadingClients} = useGetClientsQuery();

    lots = lots || [];
    clients = clients || [];
    lots = lots.filter(l => !l.deletedDate);
    clients = clients.filter(l => !l.deletedDate);

    const lotsOptions = lots.map((b) => {
        return {label: b.name, value: b.name};
    });

    const clientsOptions = clients.map((b) => {
        return {label: b.name, value: b.name};
    });

    const {
        acceptedFiles, getRootProps, getInputProps,
        isDragActive, isDragAccept, isDragReject
    } = useDropzone(
        {maxFiles, accept});

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const onClickReset = () => {
        setArray([]);
        setFile(null);
    };

    const onClickShowPreview = (e) => {
        let file = acceptedFiles[0];
        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
                NotificationProvider.info("Verify that all fields have valid information.");
            };
            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = string => {
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        let csvHeader = ["VIN", "Asset", "Lot"];
        if (isAdmin || isOwner) {
            csvHeader.push("Client");
        }

        const array = csvRows.map(i => {
            const values = i.split(",");
            return csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
        });
        array.forEach((o, i) => o.id = i + 1);

        let filtered = array.filter(function (el) {
            return el.VIN !== "";
        });

        setArray(filtered);
    };

    const onRowEditComplete = (e) => {
        let _array = [...array];
        let {newData, index} = e;

        _array[index] = newData;

        setArray(_array);
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}/>;
    }

    const getLotsLabel = (lot) => {
        let result = lotsOptions.filter(data => data.value.trim() === lot);
        if (result.length > 0) {
            return lot;
        } else {
            return 'N/A';
        }
    }

    const getClientsLabel = (client) => {
        let result = clientsOptions.filter(data => data.value.trim() === client);
        if (result.length > 0) {
            return client;
        } else {
            return 'N/A';
        }
    }

    const lotEditor = (options) => {
        return (
            <Dropdown value={options.value} options={lotsOptions} optionLabel="label" optionValue="value"
                      onChange={(e) => options.editorCallback(e.value)} placeholder="Select a Lot"/>
        );
    }

    const clientEditor = (options) => {
        return (
            <Dropdown value={options.value} options={clientsOptions} optionLabel="label" optionValue="value"
                      onChange={(e) => options.editorCallback(e.value)} placeholder="Select a Client"/>
        );
    }

    const lotBodyTemplate = (rowData) => {
        return getLotsLabel(rowData.Lot?.trim());
    }

    const clientBodyTemplate = (rowData) => {
        return getClientsLabel(rowData.Client?.trim());
    }

    const onClickSave = async () => {
        if (saveValidation()) {
            NotificationProvider.info("Processing data");
            let assetInFile = array.map((item) => {

                let lotId = 0;
                let clientId = item.Client;

                let clientResult = clients.filter(data => data.name.trim() === clientId?.trim());
                if (clientResult.length !== 0){
                    clientId= clientResult[0].id;
                }

                let lotResult = lots.filter(data => data.name.trim() === item.Lot?.trim());
                if (lotResult.length !== 0){
                    lotId= lotResult[0].id;
                }

                return {
                    VIN: item.VIN,
                    Client: clientId,
                    Asset: item.Asset??"",
                    Lot: lotId
                };
            });

            let data = { AssetFiles: assetInFile };
            let result = await VehicleService.saveAssetByFile(data);

            if (result === null || (typeof result !== 'undefined' && result.length !== 0)) {
                NotificationProvider.error("Some VIN´s could not be processed");
            } else {
                NotificationProvider.success("The VIN´s were processed correctly");
            }
        }
    };

    const saveValidation = () => {
        let isValid = true;
        let fields = [];
        let isRoleValidationRequiered = (isAdmin || isOwner);
        array.forEach(x => {
            if (x.VIN === undefined || x.VIN === "") {
                isValid = false;
                fields.push("VIN");
            }
            if (x.Lot === undefined || x.Lot === "") {
                isValid = false;
                fields.push("Lot");
            } else {
                let result = lotsOptions.filter(data => data.value.trim() === x.Lot?.trim());
                if (result.length === 0) {
                    isValid = false;
                    fields.push("Lot");
                }
            }
            if ( isRoleValidationRequiered&& (x.Client === undefined || x.Client === "")) {
                isValid = false;
                fields.push("Client");
            } else {
                let result = clientsOptions.filter(data => data.value.trim() === x.Client?.trim());
                if (isRoleValidationRequiered && result.length === 0) {
                    isValid = false;
                    fields.push("Client");
                }
            }
        });

        if (!isValid) {
            let unique = [...new Set(fields)];
            if (unique.length === 1) {
                NotificationProvider.error("The column [" + unique.join("") + "] have error.");
            } else {
                NotificationProvider.error("The columns [" + unique.join(",") + "] have error.");
            }
        }
        return isValid;
    };

    return (
        <>
            {isLoadingLots && isLoadingClients && <CircularProgress className={classes.spinner}/>}
            <section className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    {isDragAccept && (<p>All {fileText} will be accepted</p>)}
                    {isDragReject && (<p>Some {fileText} will be rejected</p>)}
                    <p> {!isDragActive && dropMessage} </p>
                </div>
                <aside>
                    <h4>{fileText}</h4>
                    <ul>{files}</ul>
                </aside>
            </section>

            <hr/>

            <Button color="primary" onClick={onClickReset}>
                Reset
            </Button>

            <Button variant="contained" color="primary" component="span" onClick={onClickShowPreview}>
                Upload
            </Button>

            <hr/>
            
            <div className="card p-fluid">
                <DataTable value={array} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete}
                           responsiveLayout="scroll">
                    <Column field="VIN" header="VIN" editor={(options) => textEditor(options)}
                            style={{width: '20%'}}></Column>

                    <Column field="Asset" header="Asset" editor={(options) => textEditor(options)}
                            style={{width: '20%'}}></Column>

                    <Column field="Lot" header="Lot" body={lotBodyTemplate}
                            editor={(options) => lotEditor(options)} style={{width: '20%'}}></Column>
                    {
                        (isAdmin || isOwner) &&
                        <Column field="Client" header="Client" body={clientBodyTemplate}
                                editor={(options) => clientEditor(options)} style={{width: '20%'}}></Column>
                    }

                    <Column rowEditor headerStyle={{width: '10%', minWidth: '8rem'}}
                            bodyStyle={{textAlign: 'center'}}></Column>
                </DataTable>
            </div>

            <br/>

            <Button variant="contained" color="primary" component="span" onClick={onClickSave}>
                Save
            </Button>

        </>
    );
};

export default FileUploadPage;