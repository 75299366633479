import * as React from "react";

function SvgIconProfile(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M12 12.6587C9.10614 12.6587 6.75 10.3025 6.75 7.40869C6.75 4.51483 9.10614 2.15869 12 2.15869C14.8939 2.15869 17.25 4.51483 17.25 7.40869C17.25 10.3025 14.8939 12.6587 12 12.6587ZM12 2.65869C9.38386 2.65869 7.25 4.79255 7.25 7.40869C7.25 10.0248 9.38386 12.1587 12 12.1587C14.6161 12.1587 16.75 10.0248 16.75 7.40869C16.75 4.79255 14.6161 2.65869 12 2.65869Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M20.5901 22.6587C20.4563 22.6587 20.3401 22.5425 20.3401 22.4087C20.3401 18.5825 16.4843 15.6587 12.0002 15.6587C7.51602 15.6587 3.66016 18.5825 3.66016 22.4087C3.66016 22.5425 3.54401 22.6587 3.41016 22.6587C3.2763 22.6587 3.16016 22.5425 3.16016 22.4087C3.16016 18.498 7.03505 15.1587 12.0002 15.1587C16.9653 15.1587 20.8401 18.498 20.8401 22.4087C20.8401 22.5425 20.724 22.6587 20.5901 22.6587Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconProfile;
