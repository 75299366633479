import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';

const apiPrefix = 'activityTypeSteps';
export const activityTypeStepApi = baseApi.injectEndpoints({
    reducerPath: 'activityTypeStepApi',
    tagTypes: [apiTags.ACTIVITY_TYPE_INVENTORY],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllStepsByWorkOrder: builder.query({
            query: (workOrderId) => `${apiPrefix}/getByWorkOrder/${workOrderId}`,
            providesTags: [apiTags.ACTIVITY_TYPE_STEP],
        }),
        updateActivityTypeStep: builder.mutation({
            query: steps => ({
                url: `${apiPrefix}/saveStepsByActivity`,
                method: "POST",
                body: steps
            }),
            invalidatesTags: [apiTags.ACTIVITY_TYPE_STEP]
        }),
    }),
});

export const {
    useGetAllStepsByWorkOrderQuery, 
    useUpdateActivityTypeStepMutation
} = activityTypeStepApi;