import { Tooltip } from "@material-ui/core";
import { activityStatusesList, completeStatuses } from "features/activity/activityConsts";
import { useGetAllAliasesQuery } from "features/activity/activityStatusAliasSlice";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";
import { useGetAllActivitiesQuery } from "features/activity/activitySlice";

const ActivityStatusAliasView = ({
    activityIds = [],
    clientId,
    readonly,
    value,
    dropdownValues,
    onChange,
    label,
    selectCompleteStatusesdOnly
}) => {

    let { data: aliases, error: aliasError, isLoading: isLoadingAlias } = useGetAllAliasesQuery();
    let { data: activityStatuses, error: activityError, isLoading: isLoadingActivity } = useGetAllActivitiesQuery();

    dropdownValues = (dropdownValues ?? activityStatuses)?.filter(a => !selectCompleteStatusesdOnly || completeStatuses.includes(a?.id));

    const [activityAliases, setActivityAliases] = useState();

    useEffect(() => {
        if (activityIds?.length && !!aliases?.length && clientId) {
            let currentAlias = aliases?.filter(a => activityIds?.includes(a.activityStatusId) && a.clientId === parseInt(clientId));
            setActivityAliases(currentAlias);
        } else {
            setActivityAliases([]);
        }
    }, [aliases, clientId, activityIds?.length])

    const getDropdownValues = () => {
        if (!activityAliases) return dropdownValues;

        const values = dropdownValues?.map(value => ({
            id: value.id,
            name: activityAliases.find(a => a.activityStatusId === value.id)?.alias ?? value?.name
        }));

        return values;
    }

    const renderAlias = !!activityAliases?.length && activityAliases?.length > 1 ? activityAliases?.map(a => a.alias).join(', ')
        : !!activityAliases?.length ? activityAliases?.[0].alias : activityStatusesList.filter(a => activityIds?.includes(a.id))?.map(s => s.name).join(', ');

    return (
        <span>
            {readonly ? <>
                <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_ALIAS_TOOLTIP}>
                    <Tooltip title={!!activityAliases?.length ? activityStatusesList.find(a => a.id === activityIds[0])?.name : ''}>
                        <span>{renderAlias}</span>
                    </Tooltip>
                </Authorize>
                <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_ALIAS_NO_TOOLTIP}>
                    <>{renderAlias}</>
                </Authorize>
            </> :
                <CustomInput
                    elementType='dropdown'
                    values={getDropdownValues()}
                    value={value}
                    label={label ?? "Activity Status"}
                    showEmpty
                    onChange={onChange}
                />
            }
        </span>
    );
}

export default ActivityStatusAliasView;