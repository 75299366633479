import { Button, Checkbox, FormControlLabel, Tooltip, Icon, FormGroup } from "@material-ui/core";
import DataSearchBar from "components/DataSearchBar";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { getLotsWithPmfText } from "features/lots/lotUtil";
import { selectIsClient } from "features/user/userSlice";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import FilterService from "services/filterService";
import { useGetVehicleTypeCategoryQuery, useGetVehicleTypesQuery } from "../../../features/vehicles/vehicleSlice";
import { tableFiltersStyle } from "./style";
import {
    useGetAllActivityItemsWithCustomFieldsQuery,
    useGetAllActivityTypesQuery
} from "../../../features/activity/activitySlice";

const ListFilter = ({ values, value, onChangeValue, multiple, identifier, renderOption, grouped }) => {
    const classes = tableFiltersStyle();
    const [showMore, setShowMore] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [expandedGroups, setExpandedGroups] = useState({});

    const sortedValues = values?.slice()?.sort((a, b) => a?.name?.localeCompare(b?.name));
    const filteredValues = FilterService.filter([], searchVal, sortedValues ?? [], []);

    const toggleGroup = (groupId) => {
        setExpandedGroups(prev => ({
            ...prev,
            [groupId]: !prev[groupId]
        }));
    };

    const renderGroup = (group) => (
        <div key={group.id}>
            <div className={classes.groupHeader} onClick={() => toggleGroup(group.id)}>
                {group.name}
                <Icon className={classes.icon}>
                    {expandedGroups[group.id] ? 'expand_less' : 'expand_more'}
                </Icon>
            </div>
            {expandedGroups[group.id] && group.options.map(val => multiple ?
                <MultipleFilter
                    key={val.id}
                    val={val}
                    filterValue={value}
                    onChangeValue={onChangeValue}
                    identifier={identifier}
                    renderOption={renderOption}
                /> :
                <SingleFilter
                    key={val.id}
                    val={val}
                    filterValue={value}
                    onChangeValue={onChangeValue}
                    identifier={identifier}
                />
            )}
        </div>
    );

    return (
        <>
            <div className={classes.filter}>
                {sortedValues?.length > 10 &&
                    <DataSearchBar
                        rows={filteredValues}
                        onSearch={(val) => setSearchVal(val)}
                        searchVal={searchVal}
                    />
                }
                {(showMore ? filteredValues : filteredValues.slice(0, 10))?.map(val => (
                    grouped ? renderGroup(val) :
                        multiple ?
                            <MultipleFilter
                                key={val.id}
                                val={val}
                                filterValue={value}
                                onChangeValue={onChangeValue}
                                identifier={identifier}
                                renderOption={renderOption}
                            /> :
                            <SingleFilter
                                key={val.id}
                                val={val}
                                filterValue={value}
                                onChangeValue={onChangeValue}
                                identifier={identifier}
                            />
                ))}
            </div>
            {filteredValues?.length > 10 &&
                <Button color="primary" onClick={() => setShowMore(!showMore)} className={classes.showMoreBtn}>
                    {showMore ? "Show less" : "Show more"}
                </Button>
            }
        </>
    );
};



const SingleFilter = ({ val, filterValue, onChangeValue, identifier }) => {
    const classes = tableFiltersStyle();

    return (
        <div className={classes.radioLabel}>
            <input className={classes.radioButton} type="radio" checked={filterValue == val.id} onChange={() => onChangeValue(val.id, identifier)} />
            <label className={classes.radioName}>{val.name}</label>
        </div>
    );
};

const MultipleFilter = ({ val, filterValue, onChangeValue, identifier, renderOption }) => {
    const classes = tableFiltersStyle();

    const onSelect = (value) => {
        let valueList = filterValue || [];
        valueList = Array.isArray(filterValue) ? filterValue : filterValue !== undefined && filterValue !== null ? [filterValue] : [];
        valueList = valueList?.filter(v => v !== undefined && v !== null) ?? [];
        if (value) {
            valueList = [...(valueList ?? []), val.id];
        } else {
            valueList = valueList.filter(v => v != val.id);
        }
        onChangeValue(valueList?.length > 0 ? valueList : null, identifier);
    }

    return (
        <div className={classes.radioLabel}>
            <FormControlLabel
                control={<Checkbox
                    checked={Array.isArray(filterValue) ? filterValue?.includes(val.id) : filterValue === val.id}
                    onChange={(e, value) => onSelect(value)}
                    className={classes.checkbox}
                    size="small"
                />}
                label={renderOption ? renderOption(val) : <label className={classes.radioName}>{val.name}</label>}
            />
        </div>
    );
};

export const LotsFilter = ({
    identifier,
    onSelect,
    clientId,
    value,
    multiple,
    pmfLot,
    newLots = []
}) => {

    const classes = tableFiltersStyle();

    let { data: clientsWithConfigs, error: error, isLoading: isLoading } = useGetClientsWithConfigsQuery(null);
    let { data: getLots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    const clientsConfigs = clientsWithConfigs?.find(c => c.id == clientId);
    let lots = !clientsConfigs?.clientLots ? getLots : getLots?.filter(lot => clientsConfigs?.clientLots?.find(l => l.lotId == lot.id));
    lots = pmfLot ? lots?.filter(lot => lot.pmfLot) : lots;
    lots = (lots && newLots) ? [...(lots ?? []), ...newLots] : lots;

    const [onlyPfmLots, setOnlyPfmLots] = useState(true);

    const getCorrectLots = (lots) => {
        if (!onlyPfmLots) return getLotsWithPmfText(lots) ?? [];
        let lotsFiltered = (lots ?? [])?.filter((lot) => lot?.pmfLot == onlyPfmLots);
        return getLotsWithPmfText(lotsFiltered) ?? [];
    }

    return (<>
        <div className={classes.radioLabel}>
            <FormControlLabel
                control={<Checkbox
                    checked={onlyPfmLots}
                    onChange={(e, value) => setOnlyPfmLots(value)}
                    className={classes.checkbox}
                    size="small"
                />}
                label={<label className={classes.radioName}>Show PMF Lots only</label>}
            />
        </div>
        <ListFilter
            values={getCorrectLots(lots)}
            value={value}
            onChangeValue={onSelect}
            multiple={multiple}
            identifier={identifier}
        />
    </>
    );
};

export const AssetStatusFilter = ({
    identifier,
    onSelect,
    values,
    value,
    multiple,
}) => {
    const classes = tableFiltersStyle();
    let isClient = useSelector(selectIsClient);
    const noLabelValues = values?.map(status => ({
        ...status,
        aliasLabel: status?.alias ? status?.label : '',
        label: status?.alias ? '' : status?.label
    }));
    const renderOption = (value) => {
        return <>
            {value?.alias ?
                <>
                    {isClient ?
                        <label className={classes.radioName}>{value?.alias}</label> :
                        <Tooltip title={value?.aliasLabel}>
                            <label className={classes.radioName}>{value?.alias}</label>
                        </Tooltip>
                    }
                </>
                : <label className={classes.radioName}>{value?.name}</label>
            }
        </>
    }
    return (
        <ListFilter
            values={noLabelValues ?? []}
            value={value}
            onChangeValue={onSelect}
            multiple={multiple}
            identifier={identifier}
            renderOption={renderOption}
        />
    );
};


export const VehicleTypeListFilter = ({
    identifier,
    onSelect,
    value,
    multiple,
}) => {
    const { data: vehicleTypes, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    const { data: vehicleTypeCategories, isLoading: isLoadingVehicleTypeCategory } = useGetVehicleTypeCategoryQuery();

    const handleSelectChange = (option) => {
        onSelect(option, identifier);
    };

    const combinedData = useMemo(() => {
        if (!vehicleTypes || !vehicleTypeCategories) return [];

        const sortedVehicleTypes = [...vehicleTypes].sort((a, b) => a.name.localeCompare(b.name));
        const sortedVehicleTypeCategories = [...vehicleTypeCategories].sort((a, b) => a.name.localeCompare(b.name));

        return sortedVehicleTypeCategories.map(type => ({
            name: type.name,
            id: type.id,
            options: sortedVehicleTypes
                .filter(item => item.vehicleTypeCategoryId === type.id)
                .map(item => ({ name: item.name, id: item.id }))
        }));
    }, [vehicleTypes, vehicleTypeCategories]);

    return (
        <>
            {!isLoadingVehicleTypeCategory && !isLoadingVehicleTypes && (
                <ListFilter
                    values={combinedData}
                    value={value}
                    onChangeValue={handleSelectChange}
                    multiple={multiple}
                    identifier={identifier}
                    grouped={true}
                />
            )}
        </>
    );
};

export const ActivityTypeListFilter = ({
    identifier,
    onSelect,
    value,
    multiple,
}) => {
    let { data: activityTypes, error: activityTypesError, isLoading: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: activityItemsWithCustomFields, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsWithCustomFieldsQuery();
    const handleSelectChange = (option) => {
        onSelect(option, identifier);
    };

    const combinedData = useMemo(() => {
        if (!activityTypes || !activityItemsWithCustomFields) return [];
        const sortedActivityTypes = [...activityTypes].sort((a, b) => a.name.localeCompare(b.name));
        const sortedActivityItems = [...activityItemsWithCustomFields].sort((a, b) => a.name.localeCompare(b.name));

        return sortedActivityTypes.map(type => ({
            name: type.name,
            id: type.id,
            options: sortedActivityItems
                .filter(item => item.activityTypeId === type.id)
                .map(item => ({ name: item.name, id: item.id }))
        }));
    }, [activityTypes, activityItemsWithCustomFields]);

    return (
        <>
            {!isLoadingActivityTypes && !isLoadingActivityItems && (
                <ListFilter
                    values={combinedData}
                    value={value}
                    onChangeValue={handleSelectChange}
                    multiple={multiple}
                    identifier={identifier}
                    grouped={true}
                />
            )}
        </>
    );
};
export default ListFilter;
