import { LotTypeEnum } from "features/lots/LotsEnums"
import LotsMapMarker from "features/maps/markers/LotsMapMarker/LotsMapMarker"
import { keyBy } from "lodash"

export const LocationFilterTypes = {
    PMF_LOTS: 1,
    CLIENT_LOTS: 2,
    SERVICE_CENTERS: 3,
    OEM_SERVICE_CENTERS: 4,
    THIRD_PARTY_LOTS: 5
}

export const locationTypes = [
    {
        id: LocationFilterTypes.PMF_LOTS,
        name: 'PMF Lots',
        value: `${LotTypeEnum.STANDARD}_1`,
        icon: (lot, text) => <LotsMapMarker lot={lot} text={text} color="#0090fe" wrapperSize="30" iconSize="17" />,
    },
    {
        id: LocationFilterTypes.CLIENT_LOTS,
        name: 'Client Lots',
        value: `${LotTypeEnum.STANDARD}_0`,
        icon: (lot, text) => <LotsMapMarker lot={lot} text={text} color="#E48900" wrapperSize="30" iconSize="18" />,
    },
    {
        id: LocationFilterTypes.SERVICE_CENTERS,
        name: 'Service Centers',
        lotType: LotTypeEnum.SERVICE_PROVIDER,
        value: `${LotTypeEnum.SERVICE_PROVIDER}`,
        icon: (lot, text) => <LotsMapMarker lot={lot} text={text} wrapperSize="30" iconSize="17" />,
    },
    {
        id: LocationFilterTypes.OEM_SERVICE_CENTERS,
        name: 'OEM Service Centers',
        lotType: LotTypeEnum.OEM_SERVICE,
        value: `${LotTypeEnum.OEM_SERVICE}`,
        icon: (lot, text) => <LotsMapMarker lot={lot} text={text} wrapperSize="30" iconSize="17" OEM={true} />,
    },
    {
        id: LocationFilterTypes.THIRD_PARTY_LOTS,
        name: "3rd Party Lots",
        lotType: LotTypeEnum.THIRD_PARTY,
        value: `${LotTypeEnum.THIRD_PARTY}`,
        icon: (lot, text) => <LotsMapMarker lot={lot} text={text} color="green" wrapperSize="30" iconSize="18" />,
    },
]

export const locationTypesById = keyBy(locationTypes, 'id');