import * as React from "react";

function SvgIconUndo(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M17.6517 21.7367H8.31833C8.11614 21.7367 7.94333 21.5639 7.94333 21.3617C7.94333 21.1595 8.11614 20.9867 8.31833 20.9867H17.6517C20.6578 20.9867 23.11 18.5345 23.11 15.5283C23.11 12.5222 20.6578 10.07 17.6517 10.07H4.81833C4.61614 10.07 4.44333 9.89719 4.44333 9.695C4.44333 9.49281 4.61614 9.32 4.81833 9.32H17.6517C21.0739 9.32 23.86 12.1061 23.86 15.5283C23.86 18.9505 21.0739 21.7367 17.6517 21.7367Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M5.39811 9.27145L5.04456 9.625L5.39811 9.97855L7.76644 12.3469C7.90952 12.49 7.90952 12.7334 7.76644 12.8764L7.76035 12.8825L7.75447 12.8888C7.70089 12.9463 7.60869 12.9867 7.50166 12.9867C7.40545 12.9867 7.31317 12.9527 7.23688 12.8764L4.25022 9.88978C4.10715 9.74671 4.10715 9.50329 4.25022 9.36022L7.23688 6.37355C7.37996 6.23048 7.62337 6.23048 7.76644 6.37355C7.90952 6.51663 7.90952 6.76004 7.76644 6.90311L5.39811 9.27145Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconUndo;
