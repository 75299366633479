import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const getCacheKey = (args) => {
    return `${args.componentId}_${args.viewId ?? "0"}`
}

const getViewsCacheKey = (args) => {
    return `${args.componentId}_"views"`
}

const apiPrefix = 'userpreferences';
export const userPreferencesApi = baseApi.injectEndpoints({
    reducerPath: 'userPreferencesApi',
    tagTypes: [apiTags.USER_TASK],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUserPreferences: builder.query({
            query: ({ componentId, type, viewId }) => `${apiPrefix}?componentId=${componentId}&type=${type}&viewId=${viewId}`,
            providesTags: (result, error, arg) => [{ type: apiTags.USER_PREFERENCES, id: getCacheKey(arg) }],
            transformResponse: (response) => {
                return response;
            }
        }),
        updateUserPreference: builder.mutation({
            query: ({ componentId, type, filters, columns, customValue, lastViewId, viewId }) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: {
                    componentId,
                    type,
                    filters,
                    columns,
                    customValue,
                    lastViewId,
                    viewId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.USER_PREFERENCES, id: getCacheKey(arg) },
            ],
        }),
        resetUserPreference: builder.mutation({
            query: ({ componentId, type }) => ({
                url: `${apiPrefix}/reset`,
                method: "POST",
                body: {
                    componentId,
                    type,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.USER_PREFERENCES, id: getCacheKey(arg) },
            ],
        }),
        getUserViews: builder.query({
            query: ({ componentId }) => `${apiPrefix}/views?componentId=${componentId}`,
            providesTags: (result, error, arg) => [{ type: apiTags.USER_PREFERENCES, id: getViewsCacheKey(arg) }],
        }),
        createView: builder.mutation({
            query: ({ name, componentId, filters, columns, customValue, viewId }) => ({
                url: `${apiPrefix}/createView`,
                method: "POST",
                body: {
                    name,
                    componentId,
                    filters,
                    columns,
                    customValue,
                    viewId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.USER_PREFERENCES, id: getViewsCacheKey(arg) },
            ],
        }),
        deleteView: builder.mutation({
            query: ({viewId, componentId}) => ({
                url: `${apiPrefix}/deleteView`,
                method: "DELETE",
                body: { id: viewId }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.USER_PREFERENCES, id: getViewsCacheKey(arg) },
            ],
        }),
    }),
});

export const {
    useGetUserPreferencesQuery,
    useUpdateUserPreferenceMutation,
    useResetUserPreferenceMutation,
    useGetUserViewsQuery,
    useCreateViewMutation,
    useDeleteViewMutation
} = userPreferencesApi;
