import { sumBy } from "lodash";
import { billingDashboardStyle } from "../style";
import CountGraph from "./CountGraph";

const TotalRevenue = ({ data }) => {

    const classes = billingDashboardStyle();
    const totalCost = (sumBy(data, item => item.totalRevenue) ?? 0)?.toFixed(2);
    return (
        <CountGraph title='Total Revenue' count={totalCost} />
    )
}

export default TotalRevenue;