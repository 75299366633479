import { useGetTransportersQuery } from 'features/transporter/transporterSlice';
import {  useSelector } from 'react-redux';
import { StepsStyles } from '../StepsStyles';

const TransporterOverview = ({ }) => {
    const classes = StepsStyles()
    
    const { accessToEdit } = useSelector(state => state.accessState);
    
    let { data: transporters, error: transportersError, isLoading } = useGetTransportersQuery();

    const transportersLookup = transporters?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});


    return (
        <>
            <div className={classes.overviewField}><label >Transporter: </label>{accessToEdit?.transporterName || transportersLookup[accessToEdit?.transporterId]?.name}</div>
            <div className={classes.overviewField}><label >USDOT: </label>{accessToEdit?.usdot || transportersLookup[accessToEdit?.transporterId]?.usdot}</div>
        </>
    );
}

export default TransporterOverview;