import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AccordionCore from "components/Shared/Accordion/AccordionCore";
import RoleCategory from "./RoleCategory";
import roleManagementStyles from "./roleManagementStyles";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const useStyles = roleManagementStyles;
const RoleDetail = ({ role, onChangeAllPermissions, onChangePermission, onSave, onCancel, isLoading }) => {
    const disableAllText = "Disable all permissions that belong to this category.";
    const activeAllText = "Active all permissions that belong to this category.";

    const classes = useStyles();

    const renderActions = () => (
        <Grid className={classes.card}>
            <Grid container direction="row" xs={12} >
                <Grid item xs={6} className={classes.card}>
                    <Button fullWidth onClick={() => onCancel()} color='primary' variant="outlined">
                        Cancel Changes
                    </Button>
                </Grid>
                <Grid item xs={6} className={classes.card}>
                    <Button onClick={() => onSave(role)} fullWidth color='primary' variant="contained" disabled={isLoading}>
                        {isLoading == true ? "Saving..." : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )

    const onChangePermissionBridge = async (p_category, p_permission, p_val, attribute) => {
        await onChangePermission(role, p_category, p_permission, p_val, attribute);
    }

    const renderCategoryActions = (category) => {
        const isAllDisable = (permissions) => permissions?.every((per) => per?.isActivInMapping === false);
        const getActionToolTipText = (action) => !action ? disableAllText : activeAllText;
        const getActionText = (action) => action ? "Active all" : "Disable all";

        let action = isAllDisable(category?.permisions);
        return <>
            <Tooltip title={getActionToolTipText(action)}>
                <IconButton onClick={() => onChangeAllPermissions(role, category, action)}>
                    {!action ? <DisabledByDefaultIcon color="error" /> : <CheckBoxIcon color="success" />}
                </IconButton>
            </Tooltip>
        </>
    }

    const getCounterByActive = (category) => {
        const activeCount = category?.permisions?.filter((per) => per?.isActivInMapping);
        return `${category?.name} (${activeCount?.length ?? "0"} of ${category?.permisions?.length})`
    }

    return (<Grid direction="row" xs={12}>
        <span className={classes.roleDesc}>{role?.description}</span>
        {
            <div className={classes.rolesContainer}>
                {
                    role?.categories?.map((category, index) => (
                        <Grid item xs={12} className={classes.rolesCategoryContainer}>
                            <AccordionCore
                                headerActions={renderCategoryActions(category)}
                                headerText={getCounterByActive(category)}
                                headerTextIcon={<ManageAccountsIcon className={classes.manageIcon} />}
                            >
                                <RoleCategory
                                    category={category}
                                    onChangePermission={onChangePermissionBridge}
                                    key={index}
                                />
                            </AccordionCore>
                        </Grid>
                    ))
                }
            </div>
        }
        {renderActions()}
    </Grid>);
}
export default RoleDetail;