
export const licensePlateTypes = {
    PERMANENT: 1,
    TEMPORARY: 2
}

export const licensePlateTypesList = [
    { id: licensePlateTypes.PERMANENT, name: "Permanent" },
    { id: licensePlateTypes.TEMPORARY, name: "Temporary" }
];

export const getLPTypeName = (typeId) => {
    const type = licensePlateTypesList.find(type => type.id === typeId);
    return type?.name;
};