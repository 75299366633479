import { makeStyles } from "@material-ui/core"

export const subscriptionPageStyle = makeStyles((theme) => ({
    pageTitle: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    userEmail: {
        display: "flex",
        alignItems: "center",
        fontWeight: 300,
        fontSize: 13,
        paddingLeft: 8,
        marginRight: 32
    }
}));
