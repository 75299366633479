import {IconLot} from "icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Divider, FormControlLabel, Popover} from "@material-ui/core";
import {useState} from "react";
import {locationSelectorStyle} from "./styles";
import Checkbox from '@mui/material/Checkbox';
import {LocationFilterTypes, locationTypes} from "./LocationsViewConsts";
import Authorize from "../../../../../../components/Authorize";

const LocationsViewSelector = ({ locationFilters, setLocationFilters }) => {
    const classes = locationSelectorStyle();
    const [anchorEl, setAnchorEl] = useState(null);

    const openPanel = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closePanel = () => {
        setAnchorEl();
    }

    const onChangeFilter = (location, val) => {
        let locationsFilter = [...(locationFilters?.['locationsFilter']?.value ?? [])]
        if(val) {
            locationsFilter = [...locationsFilter, location]
        } else {
            locationsFilter = locationsFilter?.filter(value => value != location);
        }
        setLocationFilters({ ...locationFilters, ['locationsFilter']: { value: locationsFilter?.length ? locationsFilter : [] } });
    }

    const isChecked = (value) => {
        return !![...(locationFilters?.['locationsFilter']?.value ?? [])]?.find(v => v == value);
    }

    const render = (index, location, locationTypes) => {
        return (<div key={index} id={index}>
            <div className={classes.lotFilterCheck}>
                <FormControlLabel
                    control={<Checkbox
                        checked={isChecked(location.value)}
                        onChange={(e, val) => {
                            onChangeFilter(location.value, val)
                        }}
                    />}
                />
                <div className={classes.ItemName}> {location?.name}</div>
                <div>{location.icon({
                    type: location.lotType,
                    pmfLot: location.id === LocationFilterTypes.PMF_LOTS
                })}</div>
            </div>
            {index !== locationTypes.length - 1 && <Divider/>}
        </div>)
    }

    return (
        <>
            <div className={classes.lotFilterContainer} onClick={openPanel}>
                <div className={classes.lotFilterHeader}>
                    <IconLot color={"#0090FE"} height='24' width='24'/>
                    <div className={classes.lotFilterLabel}>Locations View Filter</div>
                </div>
                <ExpandMoreIcon/>
            </div>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closePanel}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {width: '21.5%'},
                }}
            >
                <div className={classes.content}>
                    {locationTypes?.map((location, index) => location.permission ?
                        <Authorize profile={location.permission}>
                            {render(index, location, locationTypes)}
                        </Authorize> : render(index, location, locationTypes))} 
                </div>
            </Popover>
        </>
    )
}
export default LocationsViewSelector;