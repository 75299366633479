import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const baseUrl = 'mytask';
export const userTaskApi = baseApi.injectEndpoints({
    reducerPath: 'userTaskApi',
    tagTypes: [apiTags.USER_TASK],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUserTasks: builder.query({
            query: () => `/${baseUrl}`,
            providesTags: [apiTags.USER_TASK]
        }),
        getTasksByUserId: builder.query({
            query: (id) => `/${baseUrl}/userId/${id}`,
            providesTags: [apiTags.USER_TASK_BY_USER_ID]
        }),
        getTaskById: builder.query({
            query: (id) => `/${baseUrl}/${id}`,
            providesTags: [apiTags.USER_TASK_BY_ID]
        }),
        getTaskPicturesById: builder.query({
            query: (id) => `/${baseUrl}/images/${id}`,
        }),
        editUserTask: builder.mutation({
            query: task => ({
                url: `${baseUrl}/edit`,
                method: "POST",
                body: task
            }),
            invalidatesTags: [apiTags.USER_TASK_BY_USER_ID, apiTags.USER_TASK_BY_ID, apiTags.USER_TASK]
        }),
    }),
});

export const {
    useGetUserTasksQuery,
    useGetTasksByUserIdQuery,
    useGetTaskByIdQuery,
    useGetTaskPicturesByIdQuery,
    useEditUserTaskMutation
} = userTaskApi;
