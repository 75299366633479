
import { makeStyles } from "@material-ui/core"

export const userPageStyle = makeStyles((theme) => ({
    pageTitle: {
        alignItems: "center",
        fontWeight: 500,
        fontSize: '1.5em',
    },
    title: {
        display: 'flex',
        padding: '1em',
        alignItems: 'center',
        '& h2': {
            margin: 0
        }
    },
    box: {
        margin: '1em 0'
    },
    content: {
        padding: '0 2.5em 1em 2.5em',
    },
    header: {
        fontSize: '1.1em',
        padding: '1em',
        display: 'flex',
        '& img': {
            marginRight: '0.5em',
            width: '1.2em'
        }
    },
    line: {
        padding: '2px 0'
    },
    userDetails: {
        display: 'flex',
        alignItems: 'center'
    }
}));