import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    wrapper: {
        padding: '0 0.5rem 0.5rem 0.5rem'
    },
    titleHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5rem'
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        paddingRight: '1.5rem'
    },
    groupHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    gridContainer: {
        border: '1px solid #ccc',
        padding: '10px',
        position: 'relative',
        marginTop: "10px",
    },
    buttonSchedule: {
        marginTop: 15,
    },
    schedulelines: {
        paddingTop: '1rem'
    },
    deleteButton: {
        position: 'absolute',
        bottom: '5px',
        right: '5px',
    },
    addButton: {
        position: 'absolute',
        right: '5px',
    },
    invalid: {
        color: 'red',
        paddingLeft: 10
    },
    done: {
        fill: 'white'
    }
}));