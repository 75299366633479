import { makeStyles } from "@material-ui/core"

export const AccessGroupListStyle = makeStyles((theme) => ({
    listWrapper: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        "& ul": {
            flex: '1'
        }
    },
    listItem: {
        borderBottom: '1px solid lightgrey',
        '&:last-of-type': {
            border: 'none'
        }
    },
    footer: {
        display: 'flex',
        margin: '1rem auto'
    },
    columnName: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 15,
        paddingBottom: 10,
        paddingTop: 20,
    },
    cardItem: {
        display: 'flex',
        width: 220
    },
    loadBox: {
        padding: '0.5em 1.3rem',
        width: '95%',
    },
    editButton: {
        width: '100%'
    }
}));