import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    invalidInput: {
        borderBlockColor: theme.palette.error.dark
    },
    inputIcon: {
        cursor: 'pointer'
    },
    divDesign: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dropDownLine: {
        marginTop: 5,
        paddingRight: 5
    },
    fieldGroup: {
        padding: '0.5em',
        margin: '0 0.5em 1em 0.5em'
    },
    groupTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '0.5em 0em'
    },
    buttonSchedule: {
        marginTop: 15,
    },
    invalid: {
        color: 'red',
        paddingLeft: 10
    }
}));