const { makeStyles } = require("@material-ui/core");

export const vehiclePageStyles = makeStyles((theme) => ({
    flaggedRow: {
        position: 'realtive',
        "&::before": {
            content: "''",
            position: "absolute",
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
        },
    },
    flaggedRowAsset: {
        "&::before": {
            background: '#ffff0029',
        },
    },
    flaggedRowActivity: {
        "&::before": {
            background: '#ff940033',
        },
    },
    flaggedRowTransport: {
        "&::before": {
            background: '#ff940033',
        },
    },
    table: {
        flex: 1
    },
    withMarginTop:{
        marginTop:'1rem'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    icon: {
        fill: theme.palette.common.white,
    },
    actionButton: {
        marginRight: 16,
    },
    actionButtonWrapper: {
        padding: "15px 10px"
    },
    redLabel: {
        color: "red"
    }
}));