import { baseApi } from "../../app/baseApi";

export const subscriptionApi = baseApi.injectEndpoints({
    reducerPath: 'subscriptionApi',
    endpoints: (builder) => ({
        subscription: builder.query({
            providesTags: ["subscriptions"],
            query: () => `subscription/getsubscriptionsforemail`,
        }),
        updateSubscription: builder.mutation({
            query: subscription => ({
                url: "subscription/addsubscription",
                method: "POST",
                body: subscription
            }),
            invalidatesTags: ["subscriptions"],
        }),
        getEmailTypes: builder.query({
            query: () => "subscription/getemailtypes",
        }),
        deleteSubscription: builder.mutation({
            query: subscription => ({
                url: 'subscription/deletesubscription',
                method: "POST",
                body: subscription
            }),
            invalidatesTags: ["subscriptions"],
        }),
        deleteLotService: builder.mutation({
            query: (email) => ({
                url: `subscription/deletelotservice`,
                method: "POST",
                body: { email }
            }),
            invalidatesTags: ["subscriptions"],
        })
    }),
});

export const { useSubscriptionQuery, useUpdateSubscriptionMutation, useGetEmailTypesQuery, useDeleteSubscriptionMutation, useDeleteLotServiceMutation } = subscriptionApi;