const FileService = {
    getBase64Data(file) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = function () {
                let parts = reader.result.split("base64,");
                resolve(parts?.length ? parts[1] : '');
            };

            reader.readAsDataURL(file);
        });
    }
};

export default FileService;
