import { Grid } from "@material-ui/core";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Mode from '@mui/icons-material/Mode';
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import AccordionCore from "components/Shared/Accordion/AccordionCore";
import { useEffect, useState } from "react";
import { useUpdateRolesMutation } from "../../roleSlice";
import PermissionDetail from "./PermissionDetail";
import UserPermissionManagementStyle from "./UserPermissionManagementStyle";

const useStyles = UserPermissionManagementStyle;

const UserPermissionManagement = ({ rolesWithAllData, refetchRolesWithData, handleSave }) => {
    const classes = useStyles();
    const [updateRoles, { isUpdating }] = useUpdateRolesMutation();
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!rolesWithAllData) return;
        setRoles([...rolesWithAllData]);
    }, [rolesWithAllData]);

    const renderHeaderRoleInfo = () => (
        <Grid container direction="column">
            <Grid item xs={12}>
                <span className={classes.headerTitle}>
                    <ManageAccountsIcon className={classes.manageIcon} />
                    Permissions Management
                </span>
            </Grid>
        </Grid>
    )

    const onChangePermission = (role, p_category, p_permission, p_val) => {
        if (!role || !p_category || !p_permission) return;

        const updatePermissionInRole = (role, category, permissionKey, value) => {
            if (!role?.categories) return;

            const catIndex = role.categories.findIndex((cat) => cat.id === category.id);
            if (catIndex === -1) return;

            const perIndex = role.categories[catIndex]?.permisions?.findIndex(per => per.key === permissionKey);
            if (perIndex !== -1) {
                role.categories[catIndex].permisions[perIndex].isActivInMapping = value;
                console.log(`Permission ${permissionKey} set to ${value}`);
            }
        };

        updatePermissionInRole(role, p_category, p_permission.key, p_val);

        if (p_val) {
            if (p_permission.dependentKeys && p_permission.dependentKeys.length > 0) {
                p_permission.dependentKeys.forEach(dependentKey => {
                    roles.forEach((role) => {
                        role.categories.forEach((category) => {
                            updatePermissionInRole(role, category, dependentKey, true);
                        });
                    });
                });
            }
        } else {
            roles.forEach((role) => {
                role.categories.forEach((category) => {
                    category.permisions.forEach((perm) => {
                        if (perm.dependentKeys && perm.dependentKeys.includes(p_permission.key)) {
                            updatePermissionInRole(role, category, perm.key, false);
                        }
                    });
                });
            });
        }

        setRoles([...roles]);
    };


    const onDeletePermission = async (role, p_category, p_permission, p_val) => {
        const objectToArray = async (object) => Object.values(object);

        if (!role) return;

        const rolIndex = [...roles]?.findIndex((rl) => rl?.id == role?.id);
        const catIndex = role?.categories.findIndex((cat) => cat.id == p_category?.id);

        const permissions = [...role?.categories[catIndex]?.permisions]?.filter(per => per.id != p_permission?.id);

        const categoryUpdated = ({ ...role?.categories[catIndex], ["permisions"]: permissions });
        const categoriesUpdated = ({ ...role?.categories, [catIndex]: categoryUpdated });
        const categoriesArray = await objectToArray(categoriesUpdated);

        const roleUpdated = ({ ...role, ["categories"]: categoriesArray });

        let result = ({ ...roles, [rolIndex]: roleUpdated });
        result = await objectToArray(result);
        setRoles(result);
    }

    const onDeletePermissionByCategory = async (role, category) => {
        const objectToArray = async (object) => Object.values(object);

        if (!role) return;

        const rolIndex = [...roles]?.findIndex((rl) => rl?.id == role?.id);

        const categories = [...role?.categories]?.filter((cat) => cat.id != category?.id);

        const roleUpdated = ({ ...role, ["categories"]: categories });

        let result = ({ ...roles, [rolIndex]: roleUpdated });
        result = await objectToArray(result);
        setRoles(result);
    }

    const onCancel = async () => {
        if (!rolesWithAllData || !rolesWithAllData?.data) return;
        setRoles([...rolesWithAllData?.data]);
    }

    const onChangeAllPermissions = async (role, category, val) => {
        const objectToArray = async (object) => Object.values(object);

        if (!role) return;

        const rolIndex = [...roles]?.findIndex((rl) => rl?.id == role?.id);
        const catIndex = role?.categories.findIndex((cat) => cat.id == category?.id);
        const permissions = [...role.categories[catIndex]?.permisions];

        let permissionsUpdated = permissions?.map((permission) => ({
            ...permission,
            isActivInMapping: val
        }));
        const categoryUpdated = ({ ...role?.categories[catIndex], ["permisions"]: permissionsUpdated });
        const categoriesUpdated = ({ ...role?.categories, [catIndex]: categoryUpdated });
        const categoriesArray = await objectToArray(categoriesUpdated);

        const roleUpdated = ({ ...role, ["categories"]: categoriesArray });

        let result = ({ ...roles, [rolIndex]: roleUpdated });
        result = await objectToArray(result);
        setRoles(result);
    }

    const onAddPermission = (role, categoria, permission) => {
        NotificationProvider.success("Permission Added");
    }

    const onSave = () => {
        let request = {
            NewList: [...roles]
        }
        handleSave(request);
    }

    const renderRoles = () => (
        <Grid className={classes.card}>
            <Grid container direction="row" spacing={2} style={{ maxHeight: 700, overflowY: 'auto' }}>
                {
                    roles?.map((role) => (
                        <Grid item xs={12}>
                            <AccordionCore
                                headerText={role?.name}
                            >
                                <PermissionDetail role={role} onAddPermission={onAddPermission} onChangePermission={onChangePermission} onChangeAllPermissions={onChangeAllPermissions} onSave={onSave} onCancel={onCancel} onDeletePermission={onDeletePermission} />
                            </AccordionCore>
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    )

    return (<>
        <LoadingSpinner loading={isLoading || isUpdating} />
        <Grid justifyContent="space-between" className={classes.container}>
            {renderHeaderRoleInfo()}
            {renderRoles()}
        </Grid>
    </>);
}
export default UserPermissionManagement;
