import { Grid, Paper } from "@material-ui/core";
import BasicModal from "components/BasicModal";
import { useState } from "react";
import StatusIndicator from "../../SupersTaskList/superTaskCard/statusIndicator";
import UserTaskItem from "../../UserTaskItem/UserTaskItem";
import { userTaskStatuses } from '../../userTaskConsts';
import { LATaskCardStyle } from "./style";

const LATaskCard = ({ group, lotId }) => {
    const classes = LATaskCardStyle();

    const [openDialog, setOpenDialog] = useState(false);
    const dateString = new Date(group?.date)?.toDateString();

    const numberOfTasks = group?.tasks?.length;
    const numberOfCompletedTasks = group?.tasks?.filter(t => t.taskStatus === userTaskStatuses.Complete)?.length;

    return (
        <>
            <div className={`${classes.itemHeader} ${classes.report} ${openDialog ? classes.openReport : ""}`} onClick={() => { setOpenDialog(true) }}>
                <Grid container spacing={1}>
                    <Grid xs={4} item container spacing={1} direction="column">
                        <Grid item>
                            Daily tasks for:<br />
                            <b>{dateString}</b>
                        </Grid>
                    </Grid>
                    <Grid xs={6} item container spacing={1} direction="row" alignItems="center" >
                        <Grid item>
                            <StatusIndicator
                                taskStatus={{ id: numberOfCompletedTasks < numberOfTasks ? userTaskStatuses.Pending : userTaskStatuses.Complete }}
                            />
                        </Grid>
                        <Grid item>
                            {numberOfCompletedTasks} out of {numberOfTasks} complete
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <BasicModal
                open={openDialog}
                header={
                    <div className={classes.dialogHeader}>
                        Daily tasks for: {dateString}
                    </div>
                }
                wide={true}
                handleClose={() => setOpenDialog(false)}
                component={
                    <>
                        {group?.tasks?.map(task =>
                            <Paper variant="outlined" className={classes.paper}>
                                <div className={classes.title}><b>{task?.title}</b></div>
                                <div className={classes.description}>{task?.description}</div>
                                <div className={classes.taskFormWrapper}>
                                    <UserTaskItem task={task} lotId={lotId} />
                                </div>
                            </Paper>
                        )}
                    </>
                }
            />
        </>
    );
};

export default LATaskCard;
