import { Button, Grid, Tooltip } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormatService from "../../../../../../services/formatService";
import { TaskItemStyles } from "../../TaskItemStyles";

const UserTask = ({ task }) => {
    const classes = TaskItemStyles();

    const field = (label, value, fieldClassName) => {
        return <Grid item container direction="row">
            {label && <Grid ><label className={classes.label}>{label}</label></Grid>}
            <Grid className={fieldClassName}><Tooltip title={value}><span>{value}</span></Tooltip></Grid>
        </Grid>;

    }

    const history = useHistory();

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid xs={11} item container direction='column' spacing={1}>
                {field('', <b>{task.title}</b>)}
                {field('', task.description, classes.longText)}
                {field('Due ', FormatService.formatDateTime(task?.createdDate))}
            </Grid>
            <Grid xs={1} item>
                <Button onClick={() => history.push(`/usertask/${task?.id}`)} variant="outlined" color='primary' endIcon={<ArrowForwardIcon />}>
                    Complete
                </Button>
            </Grid>
        </Grid>
    )
}
export default UserTask;