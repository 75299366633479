import * as React from "react";

function SvgIconArrowCircle(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M11.6464 13.5535L11.9999 13.9071L12.3535 13.5535L15.3535 10.5535C15.4482 10.4588 15.6116 10.4588 15.7064 10.5535C15.8011 10.6483 15.8011 10.8117 15.7064 10.9064L12.1764 14.4364C12.1251 14.4877 12.0645 14.51 11.9999 14.51C11.9354 14.51 11.8748 14.4877 11.8235 14.4364L8.29347 10.9064C8.19873 10.8117 8.19873 10.6483 8.29347 10.5535C8.3882 10.4588 8.55162 10.4588 8.64636 10.5535L11.6464 13.5535Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconArrowCircle;
