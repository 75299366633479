import { Grid, Paper } from "@material-ui/core";
import { billingDashboardStyle } from "./style";
import { useGetBillingSummaryQuery } from "./billingSlice";
import TrendingBillingGraph from "./TrendingBillingGraph/TrendingBillingGraph";
import BillingPerLotGraph from "./BillingPerLotGraph/BillingPerLotGraph";
import BillingPerProgramGraph from "./BillingPerProgramGraph/BillingPerProgramGraph";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import Authorize from "components/Authorize";
import BillingDashboardFilters from "./BillingDashboardFilters";
import { useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import BillingDataTable from "./BillingDataTable/BillingDataTable";
import FormatService from "services/formatService";
import TotalCost from "./CountGraph/TotalCost";
import TotalCharge from "./CountGraph/TotalCharge";
import TotalRevenue from "./CountGraph/TotalRevenue";
import { programList } from "./consts";
import BillingDashboardCostChargeFilter from "./BillingDashboardCostChargeFilter";

const BillingDashboard = () => {
    const classes = billingDashboardStyle();

    let { data: billingSummary, error: billingSummaryError, isLoading: isLoadingBillingSummary } = useGetBillingSummaryQuery({ clientId: 1 });
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const { filters } = useSelector(state => state.billingDashboardState);

    const filterConfig = [
        {
            type: fieldTypes.TEXT,
            title: 'Client',
            size: 3,
            key: 'clientId',
            values: [...(clients ?? [])],//{ id: '', name: 'All clients' }, 
        },
        {
            type: fieldTypes.LOT,
            pmfLot: true,
            title: 'Lot',
            size: 3,
            key: 'lotId',
            multiple: true
        },
        {
            type: fieldTypes.TEXT,
            title: 'Program',
            size: 3,
            key: 'programType',
            values: programList
        },
        {
            type: fieldTypes.DATERANGE,
            title: 'Date',
            size: 3,
            key: 'billingDate',
            values: []
        },
    ];

    const dataFiltered = FilterService.filter(filters, null, billingSummary, filterConfig);
    const dataMapped = dataFiltered.map(row => ({
        ...row,
        lotName: lotLookup[row.lotId]?.name,
        formattedDate: FormatService.formatDate(row.billingDate)
    }));

    const wrapReport = (report) => {
        return (
            <Paper className={classes.graphContainer}>
                {report}
            </Paper>
        )
    }
    return (
        <Grid container spacing={2}>
            <LoadingSpinner loading={isLoadingBillingSummary} />
            <Grid item xs={12}>
                <BillingDashboardFilters filterConfig={filterConfig} data={dataMapped} />
            </Grid>
            <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                    {wrapReport(<TotalCost data={dataMapped} />)}
                </Grid>
                <Grid item xs={4}>
                    {wrapReport(<TotalCharge data={dataMapped} />)}
                </Grid>
                <Grid item xs={4}>
                    {wrapReport(<TotalRevenue data={dataMapped} />)}
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <BillingDashboardCostChargeFilter />
            </Grid> */}
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                    {wrapReport(<TrendingBillingGraph data={dataMapped} />)}
                </Grid>
                <Grid item xs={6}>
                    {wrapReport(<BillingPerLotGraph data={dataMapped} />)}
                </Grid>
                <Grid item xs={6}>
                    {wrapReport(<BillingPerProgramGraph data={dataMapped} />)}
                </Grid>

                <Grid item xs={12}>
                    {wrapReport(<BillingDataTable data={dataMapped} />)}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default BillingDashboard;
