import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { EditService } from './EditService';
import { EditWrapper } from "./EditWrapper";
import NotificationProvider from 'components/NotificationProvider';
import { PCOInvoiceDetailsStyle } from './PCOInvoiceDetailsStyle';
import { useGetAllInvoiceItemsQuery, useUpdateInvoiceActivityMutation, useUpdateInvoiceStorageServiceMutation } from 'features/invoice/storage/storageInvoiceSlice.ts';
import { arrayToObject } from 'utils/object-util';
import LoadingSpinner from 'components/LoadingSpinner';
import { storageInvoiceServiceTypes } from 'features/invoice/storage/storageInvoiceConsts';

export const Service = ({ service = {}, onDone, editMode = false, type, editable = true }) => {

    let { data: invoiceItems, error, isLoading: isLoadingItems, isFetching } = useGetAllInvoiceItemsQuery();

    let [saveInvoiceService, { isLoading: isSavingService, isSuccess: saveServiceSuccess }] = useUpdateInvoiceActivityMutation();
    let [saveInvoiceStorageService, { isLoading: isSavingStorageService, isSuccess: saveStorageServiceSuccess }] = useUpdateInvoiceStorageServiceMutation();

    let invoiceItemsLookup = arrayToObject(invoiceItems);

    const [serviceToEdit, setServiceToEdit] = useState();

    const onFieldChange = async (fieldName, value, index) => {
        setServiceToEdit({
            ...serviceToEdit,
            [fieldName]: value
        })
    }

    useEffect(() => {
        setServiceToEdit({ ...service })
    }, [service])


    const onServiceFieldSave = async () => {
        const fields = ['totalCharge', 'description', 'qbItemId'];
        const serviceToSave = {
            id: serviceToEdit.id,
        }
        fields.map(f => {
            serviceToSave[f] = serviceToEdit[f];
        })
        let res;
        if (serviceToEdit.id) {
            res = await onSave(serviceToSave);
        }

        onDone && onDone(!res?.error && serviceToEdit);
    }

    const onSave = async (serviceToSave) => {
        let result;
        if(type == storageInvoiceServiceTypes.ACTIVITY) {
            result = await saveInvoiceService(serviceToSave);
        } else {
            result = await saveInvoiceStorageService(serviceToSave);
        }
        if (result?.error) {
            NotificationProvider.error(`Failed to update service`)
        } else {
            NotificationProvider.success(`Successfully updated service`);
        }
        return result;
    }

    const isLoading = isLoadingItems || isSavingStorageService || isSavingService;

    const classes = PCOInvoiceDetailsStyle();
    return (
        <>
            <LoadingSpinner loading={isLoading} />
            <EditWrapper
                editMode={editMode}
                loading={isLoading}
                editable={editable}
                readonlyComponent={
                    <Grid container >
                        <Grid item xs={4}>{invoiceItemsLookup?.[serviceToEdit?.qbItemId]?.name ?? '-'}</Grid>
                        <Grid item xs={4} className={classes.description}>{serviceToEdit?.description}</Grid>
                        <Grid item xs={4} >${serviceToEdit?.totalCharge ?? 0}</Grid>
                    </Grid>
                }
                editComponent={<EditService onFieldChange={onFieldChange} serviceToEdit={serviceToEdit} type={type}/>}
                onSave={onServiceFieldSave}
                onCancel={() => {
                    onDone && onDone();
                }}
            />
        </>
    )
}

