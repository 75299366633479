import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import { TagInput } from "components/TagInput/TagInput";
import usePrompt from "hooks/usePrompt";
import { IconSend, IconWarning } from "icons";
import { useEffect, useState } from "react";
import Validator from "services/validator";
import { useGetStorageInvoicePdfQuery, useSendStorageInvoicePdfMutation } from "../../storageInvoiceSlice.ts";
import { storageInvoiceStatusStyles } from "./style";

const StorageInvoicePdfDisplay = ({ invoiceDetail }) => {
    const classes = storageInvoiceStatusStyles();
    let [openDialog, setOpenDialog] = useState(false);
    let [toEmails, setToEmails] = useState([]);
    let [bccEmails, setBccEmails] = useState([]);

    const { triggerPrompt } = usePrompt();
    let { data: fileInfo, error, isFetching } = useGetStorageInvoicePdfQuery({ invoiceDetailId: invoiceDetail?.id }, { skip: !openDialog });
    const [send, { isLoading: isSending }] = useSendStorageInvoicePdfMutation();

    const sendInvoice = () => {
        triggerPrompt({
            title: "Send Invoice",
            content: <div>
                <div><b>Are you sure you want to send the invoice to the following emails?</b></div>
                <div>To: {toEmails?.join(', ')}</div>
                <div>Bcc: {bccEmails?.join(', ')}</div>
            </div>,
            onConfirm: async () => {
                const res = await send({
                    invoiceDetailId: invoiceDetail?.id,
                    toEmails,
                    bccEmails
                });
                if (res && !res.error) {
                    NotificationProvider.success("Successfully sent the invoice!");
                } else {
                    NotificationProvider.error("Failed to email the invoice");
                }
            }
        });
    }

    useEffect(() => {
        if (fileInfo) {
            setToEmails(fileInfo?.emails ?? []);
        }
    }, [fileInfo])

    return (<>
        <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}>
            <IconSend />
        </Button>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth={"md"} fullWidth>
            <DialogTitle>Preview & Email Invoice</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4} >
                        <Grid spacing={2} container className={classes.emailsWrapper}>
                            <Grid item container direction="column">
                                <Grid item><b>To</b></Grid>
                                <TagInput
                                    placeholder={'Enter email'}
                                    tagList={toEmails}
                                    onChange={(val) => {
                                        setToEmails(val)
                                    }}
                                    validateFunc={(tag) => {
                                        const res = Validator.validateEmail(tag)
                                        return !!res;
                                    }}
                                />
                            </Grid>
                            <Grid item container direction="column">
                                <Grid item><b>Bcc</b></Grid>
                                <TagInput
                                    placeholder={'Enter email'}
                                    tagList={bccEmails}
                                    onChange={(val) => {
                                        setBccEmails(val);
                                    }}
                                    validateFunc={(tag) => {
                                        const res = Validator.validateEmail(tag)
                                        return !!res;
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} className={classes.previewWrapper}>
                        {isFetching && <ShimmerLoader
                            count={1}
                            height="500"
                        />}
                        {!fileInfo?.file && !isFetching && <Grid container direction="column" alignItems="center" justifyContent="space-between">
                            <IconWarning />
                            <br />
                            <div>We could not find an invoice to send please try again later.</div>
                        </Grid>}
                        {!isFetching && !!fileInfo?.file?.content && <embed
                            style={{ width: '100%', height: '1000px' }}
                            src={`data:application/pdf;base64,${fileInfo?.file?.content}`} />}

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpenDialog(false)}>
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<IconSend />}
                    disabled={isSending || !toEmails?.length || !fileInfo?.file?.content}
                    onClick={sendInvoice}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default StorageInvoicePdfDisplay;