import { makeStyles } from "@material-ui/core";

export const storageInvoiceStatusStyles = makeStyles((theme) => ({
    invoiceStatusWrapper: {
        color: 'white',
        marginRight: '1rem',
        padding: '0.5em 1em'
    },
    warningComponent: {
        marginRight: '0.5rem'
    },
    previewWrapper: {
        minHeight: '500px'
    },
    emailsWrapper: {
        //background: '#efefef',
        padding: '1.5em',
    }
}));