import { Button, ListItem, Paper } from '@material-ui/core';
import { ListsStyle } from './ListsStyle';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';


const ListComponent = ({ access, list, listTitle, listItem, addComponent, buttonText='Add' }) => {

    const classes = ListsStyle()
    const [openModal, setOpenModal] = useState(false);

    const onAddClick = () => {
        setOpenModal(true)
    }

    const onClose = () => {
        setOpenModal(false)
    }

    return (
        <>
            <Paper className={classes.listWrapper}>
                <div className={classes.listTitle}>{listTitle}</div>
                <div className={classes.listContainer}>
                    {list?.map(item => {
                        return <>
                            <ListItem className={classes.listItem} key={item?.id}>
                                {listItem(item)}
                            </ListItem>
                        </>
                    })}
                </div>
                <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                    <Button className={classes.addButton} startIcon={<AddIcon />} onClick={onAddClick}>
                        {buttonText}
                    </Button>
                </Authorize>
            </Paper>
            {addComponent(access, openModal, onClose)}

        </>
    );
}

export default ListComponent



