import { Button, Grid, Paper, Switch } from "@material-ui/core";
import UsersTasksStyle from "./UsersTasksStyle";
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import NotificationProvider from "components/NotificationProvider";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect, useState } from "react";
import UserTaskItemSchedule from "./Schedule/UserTaskItemSchedule";
import { useAddUserTaskConfigMutation, useDeleteUserTaskConfigMutation, useUpdateUserTaskConfigMutation } from "./userTaskConfigSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";

const useStyles = UsersTasksStyle;

const UserTaskItem = ({ task, userId }) => {
    const classes = useStyles();

    let [deleteTaskConfig, { isLoading: deleteTaskConfigLoading }] = useDeleteUserTaskConfigMutation();
    let [addTaskConfig, { isLoading: addTaskConfigLoading }] = useAddUserTaskConfigMutation();
    let [updateTaskConfig, { isLoading: updateTaskConfigLoading }] = useUpdateUserTaskConfigMutation();

    const [taskToEdit, setTaskToEdit] = useState(task);

    const onSwitch = (val) => {
        setTaskToEdit({ ...taskToEdit, ["on"]: val })
    }

    useEffect(() => {
        setTaskToEdit(task);
    }, [task]);


    const onSave = async () => {
        let result;
        if (!taskToEdit.on) {
            result = await deleteTaskConfig({ id: task.taskConfigId });
            if (result && !result.error) {
                NotificationProvider.success("Task removed successfully");
            } else {
                NotificationProvider.error("Failed to remove the task");
            }
        } else {
            if (taskToEdit?.scheduleList) {
                let schedule;
                if (taskToEdit.interval === userTaskInterval.WEEKLY) {
                    schedule = taskToEdit?.scheduleList?.filter(s => s.day && s.hour);
                } else {
                    schedule = taskToEdit?.scheduleList?.filter(s => s.hour);
                }
                setTaskToEdit({ ...taskToEdit, ["scheduleList"]: schedule })
                schedule = JSON.stringify(schedule);
                result = await updateTaskConfig({ ...taskToEdit, ["id"]: taskToEdit.taskConfigId, ["schedule"]: schedule, ["userId"]: userId, ["taskTypeId"]: task.id });
            } else {
                result = await updateTaskConfig({ ...taskToEdit, ["id"]: taskToEdit.taskConfigId, ["schedule"]: null, ["userId"]: userId, ["taskTypeId"]: task.id });
            }

            if (result && !result.error) {
                NotificationProvider.success("Task updated successfully");
            } else {
                NotificationProvider.error("Failed to update the task");
            }
        }
    }

    const hasChanges = () => {
        return task.on != taskToEdit.on ||
                task.interval != taskToEdit.interval ||
                task.schedule != taskToEdit.schedule ||
                task.scheduleList != taskToEdit.scheduleList ||
                task.notifyEmail != taskToEdit.notifyEmail
    }

    const onCancel = () => {
        setTaskToEdit(task)
    }

    const isLoading = updateTaskConfigLoading || addTaskConfigLoading || deleteTaskConfigLoading;

    return (
        <Paper className={`${classes.taskItemWrapper} ${taskToEdit.on && classes.taskOnWrapper}`}>
            <LoadingSpinner loading={addTaskConfigLoading} />
            <Grid container alignItems="center" className={classes.taskHeader}>
                <Grid item><div className={classes.icon}><AlarmOnOutlinedIcon /></div></Grid>
                <Grid item xs={10}>
                    <div className={classes.title}>{task.title}</div>
                    <div className={classes.description}>{task.description}</div>
                </Grid>
                {(task.lotRelation == relationTypeEnumById.ATTENDANT || !task.on) &&
                    <Grid item>
                        <Switch
                            color="primary"
                            checked={taskToEdit.on}
                            onChange={(e) => { onSwitch(e.target.checked) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Grid>}
            </Grid>
            {taskToEdit.on && <UserTaskItemSchedule task={taskToEdit} setTask={setTaskToEdit} userId={userId} />}
            {hasChanges() &&

                <Grid container spacing={2} justifyContent="flex-end">
                    <Button variant="outlined" color="primary" className={classes.button}
                        onClick={onCancel} >
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button}
                        onClick={onSave}
                        disabled={isLoading} >
                        {isLoading ? "Saving..." : "Save"}
                    </Button>
                </Grid>
            }
        </Paper>
    );
}
export default UserTaskItem;

export const userTaskInterval = {
    DAILY: 1,
    WEEKLY: 2,
}