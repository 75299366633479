import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import roleManagementStyles from "./roleManagementStyles";

const useStyles = roleManagementStyles;
const Permission = ({permision, onChangePermission}) =>{
    const classes = useStyles(); 

    return(
        <>
            <Grid direction="row" justifyContent="center" alignItems="center" xs={12}>
                <Grid item xs={12} className={classes.permissionSectionColor}>
                    <Grid item xs={8}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={permision?.name}
                            checked={permision?.isActivInMapping}
                            onChange={(e, checked) => onChangePermission(permision, checked, "isActivInMapping")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={"Can be updated per user"}
                            checked={permision?.isVisibleMapping}
                            onChange={(e, checked) => onChangePermission(permision, checked, "isVisibleMapping")}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default Permission;