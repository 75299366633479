import { makeStyles } from "@material-ui/core";

export const dispatchDashboardStyle = makeStyles((theme) => ({
    graphWrapper: {
        padding: '2em',
        minHeight: '300px',
    },
    countWrapper: {
        padding: '2em',
        height: '220px'
    },
    graphTitle: {
        fontSize: '1.3em',
        color: theme.palette.primary.dark,
        margin: 0,
        marginBottom: '1em'
    },
    barLabel: {
        marginRight: '5em'
    },
    table: {
        height: '60vh'
    }
}));