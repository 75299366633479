import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    formContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        margin: 20
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
        minHeight: 704
    },
    disabledForm: {
        pointerEvents: 'none',
        opacity: 0.7
    },
    input: {
        margin: 16,
        position: 'relative'
    },

    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    buttonFloat: {
        margin: "16px 8px",
        width: '20px',
        '&.MuiButton-contained': {
            backgroundColor: "transparent",
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    inverseButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        margin: 15,
        borderColor: theme.palette.primary.main,
        width: '100%',
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.7
        }
    },
    notesButton: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        color: theme.palette.primary.main,
        margin: 20,
        borderColor: theme.palette.primary.main
    },
    icon: {
        fill: theme.palette.common.white,
    },
    inputIcon: {
        fill: theme.palette.text.secondary,
        width: 30
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    btnGroup: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    addPhoto: {
        marginTop: 20
    },
    error: {
        textAlign: "center",
        fontSize: 18,
        color: theme.palette.error.main,
        maxWidth: 500,
        margin: "0 auto 16px auto"
    },
    hiddenButtons: {
        visibility: "hidden",
        opacity: 0
    },
    groupTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 1em'
    },
    inputFieldExtended: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    inputField: {
        width: 250,
        //padding: '0.5em' ,
    },
    labelFieldExtended: {
        fontSize: "1rem",
        display: 'flex',
        padding: '0.5em 0',
    },
    groupTitleForm: {
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 1em'
    },
    subSectionContainer: {
        padding: 3,
        textDecoration: "underline", 
        borderStyle: "solid", 
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderColor: "#b4b4b9",
        marginBottom: 7,
        borderLeftWidth: 1,
        borderRightWidth: 0
    },
    subSectionContainerPhoto: {
        padding: 3,
        textDecoration: "underline", 
        borderStyle: "solid", 
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderColor: "#b4b4b9",
        marginBottom: 7,
        borderLeftWidth: 1,
        borderRightWidth: 0,
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
    },
    sectionTitle: {
        fontSize: 20,
        marginBottom: 30,
        textDecoration: "none",
        textUnderlineOffset: 10, 
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    subSectionContainerTitle: {
        marginLeft: 16
    },
    subSectionTitle: {
        textDecoration: "none",
        fontWeight: "bold",
    },
    tabs: {
        padding: '2em 0',
    },
    checkboxList: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '0.2rem',
    },
    checkboxLabel: {
        margin: '-0.3rem'
    },
    checkbox: {
        paddingLeft: '1rem',
    },
    iconHelp: {
        padding: '0.2rem',
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center',
    }
}));
