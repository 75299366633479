import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import { IssueDescStyle } from "./style";
import { Grow, Popper } from "@material-ui/core";
import { useState } from "react";

export const IssueTrackerDescriptionField = ({ content, readonly, onChange, withTooltip }) => {
    const classes = IssueDescStyle();

    const [anchorEl, setAnchorEl] = useState(null);

    const onOpenPopover = (e) => {
        withTooltip && setAnchorEl(e.currentTarget);
    }

    const closePopover = (e) => {
        setAnchorEl();
    }
    const openPopover = Boolean(anchorEl);

    return (<>{readonly ? <>
        <div aria-owns={openPopover ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={onOpenPopover}
            onMouseLeave={closePopover}
            className={withTooltip && classes.descTooltipTrigger}
            dangerouslySetInnerHTML={{ __html: content }}>
        </div>
        <Popper

            style={{ zIndex: 9999 }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closePopover}
            transition
            marginThreshold={100}
            disableRestoreFocus
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps} timeout={350}>
                    <div className={classes.descTootlip} dangerouslySetInnerHTML={{ __html: content }}></div>
                </Grow>
            )}
        </Popper> </> :
        <RichTextEditor
            content={content ?? ''}
            onChange={(val) => onChange(val)}
        />}
    </>
    )
}