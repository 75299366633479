import { useParams } from "react-router-dom";
import { useGetHistoryByIdQuery } from "./lotAuditSlice";
import AuditTable from "./components/AuditTable";
import LoadingSpinner from "components/LoadingSpinner";

const VehicleLotAuditHistory = () => {
    let params = useParams();
    
    let {data: lotAudit, error: lotAuditError, isLoading: isLoadingLotAuditHistory } = useGetHistoryByIdQuery(params?.id);
    lotAudit = lotAudit?.data?.auditResult || [];

    return (
        <>{isLoadingLotAuditHistory ? 
        <LoadingSpinner loading={isLoadingLotAuditHistory}/> :
        <AuditTable params={params} auditResult={lotAudit} isLoadingLotAudit={isLoadingLotAuditHistory}></AuditTable>
    }</>
    )
}
export default VehicleLotAuditHistory;