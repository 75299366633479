import * as React from "react";

function IconClient(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.37096 15.3143L7.36905 15.3132L3.16346 12.8847C3.16317 12.8845 3.16287 12.8844 3.16258 12.8842C2.46481 12.4768 2.03027 11.7267 2.03027 10.9224V6.07746C2.03027 5.27323 2.46481 4.52314 3.16258 4.11569C3.16287 4.11552 3.16317 4.11535 3.16346 4.11518L7.36905 1.68671L7.36906 1.68671L7.37096 1.6856C8.06464 1.28096 8.92831 1.27991 9.63115 1.68651L9.63149 1.68671L13.8371 4.11518C13.8374 4.11537 13.8377 4.11555 13.8381 4.11574C14.5358 4.5232 14.9703 5.27326 14.9703 6.07746V10.9224C14.9703 11.7267 14.5357 12.4768 13.838 12.8842C13.8377 12.8844 13.8374 12.8845 13.8371 12.8847L9.63149 15.3132L9.62959 15.3143C9.28544 15.515 8.89154 15.6145 8.50027 15.6145C8.109 15.6145 7.71511 15.515 7.37096 15.3143ZM7.40347 1.73969L7.40346 1.73968L7.40024 1.74154L3.19274 4.17112L3.19274 4.17112L3.19156 4.1718C2.51483 4.56504 2.09277 5.28849 2.09277 6.07746V10.9224C2.09277 11.7032 2.51383 12.4343 3.19156 12.8281L3.19274 12.8288L7.39907 15.2577C7.39925 15.2578 7.39943 15.2579 7.39962 15.258C8.07879 15.6524 8.92166 15.6524 9.60084 15.258C9.60106 15.2579 9.60127 15.2578 9.60148 15.2577L13.8078 12.8288L13.809 12.8281C14.4857 12.4349 14.9078 11.7114 14.9078 10.9224V6.07746C14.9078 5.29673 14.4867 4.56562 13.809 4.1718L13.8078 4.17112L9.6003 1.74154L9.60031 1.74153L9.59708 1.73969C9.26056 1.54869 8.87813 1.44786 8.50027 1.44786C8.12241 1.44786 7.73999 1.54869 7.40347 1.73969Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M8.50003 7.82279C7.57201 7.82279 6.81836 7.06914 6.81836 6.14111C6.81836 5.21309 7.57199 4.45947 8.50003 4.45947C9.42806 4.45947 10.1817 5.21309 10.1817 6.14111C10.1817 7.06914 9.42805 7.82279 8.50003 7.82279ZM8.50003 4.52197C7.60765 4.52197 6.88086 5.2487 6.88086 6.14111C6.88086 7.03349 7.60763 7.76029 8.50003 7.76029C9.39243 7.76029 10.1192 7.03349 10.1192 6.14111C10.1192 5.2487 9.3924 4.52197 8.50003 4.52197Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M11.3332 11.8321C11.3286 11.8321 11.3208 11.8304 11.3122 11.8218C11.3036 11.8132 11.3019 11.8054 11.3019 11.8008C11.3019 11.1247 10.9433 10.5448 10.4321 10.1497C9.92096 9.7547 9.23589 9.52295 8.49984 9.52295C7.76378 9.52295 7.07871 9.7547 6.56758 10.1497C6.05634 10.5448 5.69775 11.1247 5.69775 11.8008C5.69775 11.8054 5.69609 11.8132 5.68748 11.8218C5.67887 11.8304 5.67103 11.8321 5.6665 11.8321C5.66198 11.8321 5.65414 11.8304 5.64553 11.8218C5.63691 11.8132 5.63525 11.8054 5.63525 11.8008C5.63525 10.5874 6.83759 9.46045 8.49984 9.46045C10.1621 9.46045 11.3644 10.5874 11.3644 11.8008C11.3644 11.8054 11.3628 11.8132 11.3541 11.8218C11.3455 11.8304 11.3377 11.8321 11.3332 11.8321Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default IconClient;
