import { makeStyles } from "@material-ui/core";

export const FormListStyle = makeStyles((theme) => ({
    formListFilters: {
        padding: '1em',
        fontSize: '1.1em',
    },
    formBuilderContainer: {
        marginTop: '1em'
    },
    filterLabel: {
        fontWeight: '500',
        marginBottom: '0.5em'
    },
    
}))