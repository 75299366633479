import * as React from "react";

function SvgIconTire(props, width, height) {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m21.298 10.444-.39-1.019 2.015-.758c-.996-2.297-2.773-4.138-5.005-5.265l-.866 1.928-.997-.455.867-1.95A10.709 10.709 0 0 0 13 2.167c-1.148 0-2.253.238-3.293.542l.736 1.993-1.018.39-.758-2.015C6.37 4.074 4.528 5.85 3.402 8.082l1.928.867-.455.996-1.95-.866A10.709 10.709 0 0 0 2.167 13c0 1.149.238 2.254.541 3.294l1.994-.737.39 1.018-2.015.759c.996 2.296 2.773 4.138 5.005 5.265l.866-1.929.997.455-.867 1.95c1.214.477 2.535.759 3.922.759 1.148 0 2.253-.239 3.293-.542l-.736-1.993 1.018-.39.758 2.015c2.297-.997 4.139-2.774 5.265-5.005l-1.928-.867.455-.997 1.95.867c.477-1.213.758-2.535.758-3.922 0-1.148-.238-2.253-.541-3.293l-1.994.737zm-5.806 8.558c-3.315 1.365-7.129-.195-8.494-3.51-1.365-3.315.195-7.128 3.51-8.493 3.315-1.365 7.129.195 8.494 3.51a6.486 6.486 0 0 1-3.51 8.493z" fill="#0090FE" />
        </svg>

    );
}

export default SvgIconTire;
