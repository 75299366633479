import {  useSelector } from 'react-redux';
import { StepsStyles } from '../StepsStyles';
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';

const VendorOverview = ({ }) => {
    const classes = StepsStyles()
    
    const { accessToEdit } = useSelector(state => state.accessState);
    
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const serviceProvidersLookup = serviceProviders?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});


    return (
        <>
            <div className={classes.overviewField}><label >Vendor Name: </label>{serviceProvidersLookup[accessToEdit?.serviceProviderId]?.name}</div>
        </>
    );
}

export default VendorOverview;