import * as React from "react";

function SvgIconPhoto(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M12.5001 18.4587H7.50008C5.29498 18.4587 3.84042 17.9856 2.92759 17.0728C2.01476 16.16 1.54175 14.7054 1.54175 12.5003V7.50033C1.54175 5.29522 2.01476 3.84067 2.92759 2.92784C3.84042 2.01501 5.29498 1.54199 7.50008 1.54199H12.5001C14.7052 1.54199 16.1597 2.01501 17.0726 2.92784C17.9854 3.84067 18.4584 5.29522 18.4584 7.50033V12.5003C18.4584 14.7054 17.9854 16.16 17.0726 17.0728C16.1597 17.9856 14.7052 18.4587 12.5001 18.4587ZM7.50008 1.79199C5.54397 1.79199 4.06899 2.13473 3.10174 3.10198C2.13449 4.06923 1.79175 5.54422 1.79175 7.50033V12.5003C1.79175 14.4564 2.13449 15.9314 3.10174 16.8987C4.06899 17.8659 5.54397 18.2087 7.50008 18.2087H12.5001C14.4562 18.2087 15.9312 17.8659 16.8984 16.8987C17.8657 15.9314 18.2084 14.4564 18.2084 12.5003V7.50033C18.2084 5.54422 17.8657 4.06923 16.8984 3.10198C15.9312 2.13473 14.4562 1.79199 12.5001 1.79199H7.50008Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M7.49992 8.45833C6.50939 8.45833 5.70825 7.65719 5.70825 6.66667C5.70825 5.67614 6.50939 4.875 7.49992 4.875C8.49044 4.875 9.29159 5.67614 9.29159 6.66667C9.29159 7.65719 8.49044 8.45833 7.49992 8.45833ZM7.49992 5.125C6.64878 5.125 5.95825 5.81552 5.95825 6.66667C5.95825 7.51781 6.64878 8.20833 7.49992 8.20833C8.35106 8.20833 9.04159 7.51781 9.04159 6.66667C9.04159 5.81552 8.35106 5.125 7.49992 5.125Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M2.12433 15.8644L2.12434 15.8644L2.12245 15.8616C2.08633 15.8083 2.10014 15.7309 2.15789 15.693L2.15791 15.693L2.16201 15.6902L6.27035 12.9319L6.27164 12.931C6.98385 12.4496 7.97695 12.5089 8.62355 13.0539L8.89492 13.2924L8.8949 13.2924L8.89895 13.2959C9.2118 13.5649 9.61448 13.6855 9.99476 13.6855C10.3739 13.6855 10.7758 13.5656 11.0858 13.2946L14.5506 10.3212L14.5507 10.3212C15.2455 9.72466 16.361 9.72242 17.0668 10.3219C17.0671 10.3222 17.0674 10.3224 17.0677 10.3227L18.4216 11.4855C18.4219 11.4857 18.4221 11.486 18.4224 11.4862C18.4742 11.5321 18.4819 11.61 18.437 11.6663C18.3904 11.7168 18.3126 11.7233 18.2568 11.6772C18.2565 11.6769 18.2561 11.6766 18.2557 11.6763L16.901 10.5127C16.9009 10.5126 16.9008 10.5126 16.9008 10.5125C16.2966 9.99316 15.3113 9.99323 14.7073 10.5127L11.241 13.4874L11.2409 13.4874C10.5468 14.0834 9.43276 14.0862 8.72681 13.4884L8.45503 13.2495L8.45508 13.2495L8.44832 13.2438C7.89194 12.772 7.01167 12.7284 6.40271 13.1446L2.30731 15.8943C2.28025 15.9094 2.24894 15.9168 2.22497 15.9168C2.18504 15.9168 2.14439 15.8945 2.12433 15.8644Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    )
}

export default SvgIconPhoto;
