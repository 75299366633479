import { IconButton } from "@material-ui/core";
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import { completeStatuses } from "features/activity/activityConsts";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import { setSelectedLoad } from "features/loads/loadGroupViewSlice";
import { FlagAsset } from "features/vehicles/Flags/FlagAsset";
import { IconArrow, IconSettings } from "icons";
import { useDispatch } from "react-redux";
import { loadLegsStyle } from "../LoadGroupLegsStyle";
import { legTypes } from "../loadLegConsts";
import ActivityLeg from "./legTypes/ActivityLeg";
import LoadLeg from "./legTypes/LoadLeg";
const LoadGroupLeg = ({ loadGroup, leg, setActivityLogToEdit }) => {

    const classes = loadLegsStyle();
    const dispatch = useDispatch();


    const onSelectLoad = (load) => {
        dispatch(setSelectedLoad(load));
    }

    const onSelectActivity = (activity) => {
        setActivityLogToEdit({ ...activity, activityLogId: activity.id });
    }

    const isCompleteActivity = () => {
        return completeStatuses?.includes(leg?.activityId);
    }

    const componentPerType = {
        [legTypes.PICKUP]: {
            className: classes.legIconPickup,
            icon: <IconArrow />,
            component: <LoadLeg leg={leg} />,
            onRowClick: onSelectLoad,
            flag: <FlagAsset
                iconOnly
                selectedRows={[leg?.id]}
                allData={[{ ...leg, vehicleId: leg?.assets?.[0]?.id }]}
                entityType={FlagEntityTypes.TRANSPORT}
            />
        },
        [legTypes.DELIVERY]: {
            className: classes.legIconDelivery,
            icon: <IconArrow />,
            component: <LoadLeg leg={leg} />,
            onRowClick: onSelectLoad,
            flag: <FlagAsset
                iconOnly
                selectedRows={[leg?.id]}
                allData={[{ ...leg, vehicleId: leg?.assets?.[0]?.id }]}
                entityType={FlagEntityTypes.TRANSPORT}
            />
        },
        [legTypes.ACTIVITY]: {
            className: isCompleteActivity() ? classes.legIconActivityComplete : classes.legIconActivity,
            icon: <IconSettings />,
            component: <ActivityLeg leg={leg} loadGroup={loadGroup} actions={<IconButton
                size={'small'}
                onClick={() => onSelectActivity(leg)}>
                <RateReviewOutlinedIcon className={classes.legIcons} />
            </IconButton>} />,
            flag: <FlagAsset
                iconOnly
                selectedRows={[leg?.id]}
                allData={[leg]}
                entityType={FlagEntityTypes.ACTIVITY}
            />
            // actions:
            //     // <Authorize profile={permissionProfiles.ASSETS.EDIT_ACTIVITY}>
            //     <div className={classes.buttonWrapper}>

            //     </div>
            // // </Authorize>
        },
    };

    return (
        <>
            <div className={classes.legWrapper}>
                <div className={`${classes.legIcon} ${componentPerType[leg?.type]?.className}`} onClick={() => componentPerType[leg?.type].onRowClick && componentPerType[leg?.type].onRowClick(leg)}>
                    {componentPerType[leg?.type]?.icon}
                </div>
                <div className={classes.legData} onClick={() => componentPerType[leg?.type].onRowClick && componentPerType[leg?.type].onRowClick(leg)}>
                    {componentPerType[leg?.type]?.component}
                </div>
                <div className={classes.flag}>
                    {componentPerType[leg?.type]?.flag}
                </div>
                {/* {componentPerType[leg?.type].actions} */}
            </div>
        </>
    );
}
export default LoadGroupLeg;