import { createSlice } from "@reduxjs/toolkit";

export const reservationsFiltersSlice = createSlice({
    name: 'reservationsFiltersSlice',
    initialState: {
        searchVal: '',
        filters: {
            clientId: { value: null },
            lotId: { value: null },
            statusId: { value: null },
        },
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters: (state, action) => {
            state.filters = {
                clientId: { value: null },
                lotId: { value: null },
                statusId: { value: null },
            }
        },
    },
});

export const { setSearchVal, setFilters, resetFilters } = reservationsFiltersSlice.actions;
export default reservationsFiltersSlice.reducer;