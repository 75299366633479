
function SvgIconTransport(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.21885 17.7963L7.21886 17.7963L7.21742 17.7925C6.88245 16.9102 7.12448 15.9396 7.84032 15.3053C7.84066 15.305 7.84101 15.3047 7.84135 15.3044L15.8292 8.31632L15.8292 8.31629C16.5048 7.72512 16.5097 6.91782 16.3206 6.39107L16.3207 6.39102L16.3166 6.38051C16.1134 5.85206 15.5798 5.25 14.68 5.25H12C11.8661 5.25 11.75 5.13386 11.75 5C11.75 4.86614 11.8661 4.75 12 4.75H14.68C15.6326 4.75 16.4544 5.32148 16.7811 6.20366L16.7811 6.20366L16.7825 6.20746C17.1175 7.08983 16.8754 8.06043 16.1596 8.69471C16.1593 8.695 16.1589 8.69529 16.1586 8.69558L8.17075 15.6837L8.17072 15.6837C7.4951 16.2749 7.49028 17.0822 7.67937 17.6089L7.67925 17.609L7.6833 17.6195C7.88655 18.1479 8.42014 18.75 9.31997 18.75H12C12.1338 18.75 12.25 18.8661 12.25 19C12.25 19.1339 12.1338 19.25 12 19.25H9.31997C8.36734 19.25 7.54558 18.6785 7.21885 17.7963ZM5.50997 6C5.23611 6 5.00997 5.77386 5.00997 5.5C5.00997 5.22287 5.22937 5 5.50997 5H5.51997C5.79383 5 6.01997 5.22614 6.01997 5.5C6.01997 5.77062 5.79707 6 5.50997 6ZM18.51 19C18.2361 19 18.01 18.7739 18.01 18.5C18.01 18.2229 18.2294 18 18.51 18H18.52C18.7938 18 19.02 18.2261 19.02 18.5C19.02 18.7706 18.7971 19 18.51 19ZM5.46997 9.25C3.40611 9.25 1.71997 7.56386 1.71997 5.5C1.71997 3.43614 3.40611 1.75 5.46997 1.75C7.53383 1.75 9.21997 3.43614 9.21997 5.5C9.21997 7.56462 7.54306 9.25 5.46997 9.25ZM5.46997 2.25C3.67383 2.25 2.21997 3.70386 2.21997 5.5C2.21997 7.29614 3.67383 8.75 5.46997 8.75C7.26611 8.75 8.71997 7.29614 8.71997 5.5C8.71997 3.70386 7.26611 2.25 5.46997 2.25ZM19.97 22.25H16.97C15.7261 22.25 14.72 21.2439 14.72 20V17C14.72 15.7561 15.7261 14.75 16.97 14.75H19.97C21.2138 14.75 22.22 15.7561 22.22 17V20C22.22 21.2439 21.2138 22.25 19.97 22.25ZM16.97 15.25C16.0038 15.25 15.22 16.0339 15.22 17V20C15.22 20.9661 16.0038 21.75 16.97 21.75H19.97C20.9361 21.75 21.72 20.9661 21.72 20V17C21.72 16.0339 20.9361 15.25 19.97 15.25H16.97Z" fill="white" stroke="white" />
    </svg>
  );
}

export default SvgIconTransport;
