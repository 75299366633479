import React from "react";
import { bulkActionsToolbarStyle } from "./style";
import CloseIcon from '@material-ui/icons/Close';
import { Grid, IconButton, Tooltip } from "@material-ui/core";

const BulkActionsToolbar = ({ selectedRows, actions, allData, onClear }) => {
    const classes = bulkActionsToolbarStyle();
    return (
        <div className={classes.container}>
            <div className={classes.selectedCount}>{selectedRows?.length}</div>
            <div className={classes.selectedText}>Records selected</div>
            <Grid container alignItems="center" className={classes.actions}>
                {actions?.map(a => <Grid item>
                    {React.cloneElement(a, { selectedRows, allData })}
                </Grid>)}
            </Grid>
            {!!selectedRows?.length && <div className={classes.clearIcon}>
                <Tooltip title='Clear selection'>
                    <IconButton size="small" onClick={onClear}><CloseIcon /></IconButton>
                </Tooltip>
            </div>}
        </div>
    )
}
export default BulkActionsToolbar;