import DropdownFilter from "components/DropdownFilter";
import { arrayToObject } from "utils/object-util";
import { useGetIssueTrackerPrioritiesQuery } from "../issuesTrackerSlice";
import { PrioritySelectorStyle } from "./style";
import { IconFlag, IconRedFlag, IconWarning } from "icons";
import { Grid, Tooltip } from "@material-ui/core";

export const PrioritySelector = ({ priority, onChange, readonly }) => {
    const classes = PrioritySelectorStyle();
    let { data: priorities, error: prioritiesError, isFetching: isLoadingPriorities } = useGetIssueTrackerPrioritiesQuery();
    const prioritiesLookup = arrayToObject(priorities);

    return (<>{readonly ? <PriorityItem priority={priority} prioritiesLookup={prioritiesLookup} /> :
        <DropdownFilter
            id="priority"
            value={priority}
            onSelect={(val) => onChange(+val)}
            elementType="dropdown"
            values={priorities}
            renderOption={(option) => <PriorityItem priority={option?.id} prioritiesLookup={prioritiesLookup} showLabel />}
        />}</>
    )
}
const PriorityItem = ({ priority, prioritiesLookup, showLabel }) => {
    const classes = PrioritySelectorStyle();
    return <Grid container alignItems="flex-start" spacing={1} className={`${classes.priority} ${classes?.[`priority${priority}`]}`}>
        {/* <Grid item><IconWarning /></Grid>
        <Grid item>{prioritiesLookup?.[priority]?.name}</Grid> */}
        <Tooltip title={`Priority ${prioritiesLookup?.[priority]?.name}`}>
            <Grid item style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', color: 'white', fontSize: '8px', padding: '2px 0 1px 5px' }}>{prioritiesLookup?.[priority]?.name}</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <path d="M14.6574 3.00978L14.6574 3.00978L14.6587 3.01272C14.8634 3.50292 14.6757 4.28727 13.6665 5.29645L12.4665 6.49645C12.0431 6.91981 11.8053 7.50228 11.8303 8.0972C11.8446 8.63005 12.0651 9.12878 12.4585 9.47557L13.6648 10.6818C13.665 10.6821 13.6653 10.6824 13.6656 10.6827C14.6519 11.6789 14.8347 12.454 14.6287 12.9473C14.4207 13.4451 13.7358 13.87 12.35 13.87H1.90002H1.40002V14.37V19.25C1.40002 19.3839 1.28388 19.5 1.15002 19.5C1.01617 19.5 0.900024 19.3839 0.900024 19.25V0.75C0.900024 0.616142 1.01617 0.5 1.15002 0.5C1.28388 0.5 1.40002 0.616142 1.40002 0.75V1.61V2.11H1.90002H12.35C13.7697 2.11 14.4615 2.53221 14.6574 3.00978Z" fill="#FF5A4F" stroke="#FF5A4F" />
                </svg>
            </Grid>
        </Tooltip>
        {showLabel && <Grid item>Priority {prioritiesLookup?.[priority]?.name}</Grid>}
    </Grid>
}