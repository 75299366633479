import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export const AuthorizeTokenViewStyle = makeStyles((theme) => ({
    logo: {
        width: '10em'
    },
    container: {
        textAlign: 'center',
        display: 'flex',
        width: '500px',
        padding: '2em',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '1.5em'
    }
}));
