import { makeStyles } from "@material-ui/core"

export const loadGroupDetailsStyle = makeStyles((theme) => ({
    container: {
        padding: '1em',
    },
    card: {
        padding: '1em',
    },
    longCard: {
        height: 'calc(100%)',
        minHeight: '200px',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
    },
    loadMap: {
        minHeight: '400px',
    },
    boxTitle: {
        marginBottom: '1em'
    },
    cardTitleWrapper: {
        marginBottom: '1em'
    },
    cardIcon: {
        marginRight: '0.5em'
    },
    cardTitle: {
        fontWeight: 600,
        fontSize: '1.1em',
        display: 'flex',
        flex: '1'
    },
    legTitle: {
        color: theme.palette.secondary.main,
        fontSize: '1.2em',
        fontWeight: '600'
    },
    addLegMenu: {
        marginLeft: 'auto',
        marginTop: 'auto',
        //marginRight: '0.5em'
    }
}));
