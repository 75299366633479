import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    container: {
        paddingLeft: '4em'
    },
    title: {
        padding: '1em 0',
        fontWeight: 'bold'
    }
}));
