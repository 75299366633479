import { makeStyles } from "@material-ui/core";

export const InspectionReportStyles = makeStyles((theme) => ({
    dropdownStyle: {
        minWidth: 260,
        width: '100%',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    itemWrapper: {
        paddingRight: '70%',
        paddingLeft: '2rem',
        paddingBottom: '0.5em 0',
        // border: '1px solid lightgrey',
        marginBottom: '1em'
    },
    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        // background: 'lightgrey',
        padding: '0.5em',
        cursor: 'pointer',
        alignItems: 'center'
    },
    collapseWrapper: {
        padding: '0 0.5em'
    },
    report: {
        padding: '1em 1rem',
        cursor: 'pointer',
        borderBottom: '1.5px solid #e1e3e4',
        '&:last-of-type': {
            border: 'none'
        },
        '&:hover': {
            background: theme.palette.neutral.light,
        }
    },
    openReport: {
        background: theme.palette.neutral.light,
    },
    errorIcon: {
        '& path': {
            fill: 'orange'
        }
    },
    inspectionTitle: {
        color: "#0090fe",
        fontWeight: "bold",
        letterSpacing: "1px"
    }

}));
