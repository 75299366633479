import DropdownFilter from "components/DropdownFilter";
import { UserTaskItemStyle } from "../style";

const StatusDropdown = ({ task, taskStatus, userTaskStatuses, onChange }) => {
    const classes = UserTaskItemStyle();

    const handleDropdownChange = (selectedVal, identifier) => {
        onChange(identifier, selectedVal);
    }

    return (
        <>
            {taskStatus == userTaskStatuses?.Complete &&
                <div className={classes.dropdownWrapper}>
                    <div className={classes.commentsLabel}>Task Status</div>
                    <DropdownFilter
                        //title='Status'
                        values={Object.keys(userTaskStatuses).map(key => ({ id: userTaskStatuses[key], name: key }))}
                        value={task?.taskStatus}
                        identifier={'taskStatus'}
                        onSelect={handleDropdownChange}
                    />
                </div>
            }
        </>
    )
}
export default StatusDropdown;