import { IconArrow, IconSettings } from "icons";
import { loadLegsStyle } from "../../LoadGroupLegsStyle";
import { legTypes } from "../../loadLegConsts";
import { Grid } from "@material-ui/core";
import FormatService from "services/formatService";
import LoadStatus from "features/loads/components/LoadStatus";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CopyToClipboard from "components/CopyToClipboard";

const LoadLeg = ({ leg }) => {
    const classes = loadLegsStyle();

    const label = (text) => {
        return <Grid item className={classes.label}>
            {text}
        </Grid>
    }

    const value = (text) => {
        return <Grid item className={classes.value}>
            {text}
        </Grid>
    }
    return (

        <Grid container spacing={2}>
            <Grid item style={{ width: '1.2em' }}>
                <Grid container direction='column'>
                    {label('#')}
                    {value(leg?.order)}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container direction='column'>
                    {label(leg?.type == legTypes.PICKUP ? 'From' : 'To')}
                    {value(
                        <>
                            {leg?.type == legTypes.PICKUP && leg?.pickupLocation?.name}
                            {leg?.type == legTypes.DELIVERY && leg?.deliveryLocation?.name}
                        </>)}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container direction='column'>
                    {label('Date')}
                    {value(
                        <>
                            {leg?.type == legTypes.PICKUP && FormatService.formatDateNoConversion(leg?.pickupStartDate)}
                            {leg?.type == legTypes.DELIVERY && FormatService.formatDateNoConversion(leg?.deliveryStartDate)}
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container direction='column'>
                    {label('Status')}
                    {value(
                        <LoadStatus load={leg} status={leg?.latestStatusObject} />
                    )}
                </Grid>
            </Grid>
            <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                <Grid item >
                    <Grid container direction='column'>
                        {label(leg?.lmId ? 'LMP ID' : '')}
                        {value(
                            leg?.lmId
                        )}
                    </Grid>
                </Grid>
            </Authorize>
        </Grid>



    );
}

export default LoadLeg;