import { createSlice } from "@reduxjs/toolkit";

export const auditListFiltersSlice = createSlice({
    name: 'auditListFilters',
    initialState: {
        filters: {
            lotId: { value: null },
            auditDate: { value: null },
        },

    },
    reducers: {
        setAuditListFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setAuditListFilters } = auditListFiltersSlice.actions;
export default auditListFiltersSlice.reducer;
