import * as React from "react";

function IconNoxSensor(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.936401 2.25C0.936401 1.52513 1.52403 0.9375 2.2489 0.9375H5.8264C6.55128 0.9375 7.1389 1.52513 7.1389 2.25V3.6602C7.14389 3.66007 7.14889 3.66 7.15391 3.66H13.8364C15.6996 3.66 17.2114 5.17184 17.2114 7.035C17.2114 8.89816 15.6996 10.41 13.8364 10.41H4.3114C3.06956 10.41 2.0614 11.4182 2.0614 12.66C2.0614 13.8131 2.93055 14.7646 4.0489 14.8948V14.22C4.0489 13.9093 4.30074 13.6575 4.6114 13.6575H5.5264C5.83706 13.6575 6.0889 13.9093 6.0889 14.22V14.2799H6.29141C6.60207 14.2799 6.85391 14.5318 6.85391 14.8424V15.9824C6.85391 16.2931 6.60207 16.5449 6.29141 16.5449H6.0889V16.605C6.0889 16.9156 5.83706 17.1675 5.5264 17.1675H4.6114C4.30074 17.1675 4.0489 16.9156 4.0489 16.605V16.0249C2.30829 15.8909 0.936401 14.4348 0.936401 12.66C0.936401 10.7968 2.44824 9.285 4.3114 9.285H13.8364C15.0782 9.285 16.0864 8.27684 16.0864 7.035C16.0864 5.79316 15.0782 4.785 13.8364 4.785H7.15391C7.14889 4.785 7.14389 4.78494 7.1389 4.78481V5.8275C7.1389 6.55237 6.55127 7.14 5.8264 7.14H2.2489C1.52403 7.14 0.936401 6.55237 0.936401 5.8275V2.25ZM2.2489 2.0625C2.14535 2.0625 2.0614 2.14645 2.0614 2.25V5.8275C2.0614 5.93105 2.14535 6.015 2.2489 6.015H5.8264C5.92996 6.015 6.0139 5.93105 6.0139 5.8275V2.25C6.0139 2.14645 5.92995 2.0625 5.8264 2.0625H2.2489Z" fill="#0090FE" />
        </svg>

    );
}

export default IconNoxSensor;
