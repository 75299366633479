import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    formContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        width: '600px'
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: theme.palette.common.white,
        margin: "16px 20px"
    },
    input: {
        margin: 20,
    },
    configWrapper: {
        borderRadius: '5px',
        margin: '1em',
        padding: '1em',
    },
    configLabel: {
        padding: 16,
        fontWeight: "bold",
        fontSize: 15
    },
    title: {
        color: theme.palette.primary.main,
        padding: 20,
        fontSize: 16
    },
    mainTitle: {
        fontSize: 25,
        fontWeight: 500,
        marginBottom: "1.5rem",
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },

}));