import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import UserPermissionManagementStyle from "./UserPermissionManagementStyle";
import UserPermissionCategory from "./UserPermissionCategory";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionCore from "components/Shared/Accordion/AccordionCore";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = UserPermissionManagementStyle;
const PermissionDetail = ({
    role,
    onChangeAllPermissions,
    onChangePermission,
    onSave,
    onCancel,
    onAddPermission,
    onDeletePermission
}) => {
    const classes = useStyles();

    const renderActions = () => (
        <Grid className={classes.card}>
            <Grid container direction="row" xs={12}>
                <Grid item xs={12} className={classes.card}>
                    <Button onClick={() => onSave()} fullWidth color='primary' variant="contained">
                        Save Configuration
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    const onChangePermissionBridge = async (p_category, p_permission, p_val) => {
        await onChangePermission(role, p_category, p_permission, p_val);
    };

    const onAddPermissionBridge = async (p_category, p_permission, p_val) => {
        await onAddPermission(role, p_category, p_permission, p_val);
    };

    const onDeletePermissionBridge = async (p_category, p_permission, p_val) => {
        await onDeletePermission(role, p_category, p_permission, p_val);
    };

    const renderCategoryActions = (category) => {
        return <></>;
    };

    const getCounterByActive = (category) => {
        const activeCount = category?.permisions?.filter((per) => per?.isActivInMapping);
        const visibleCount = category?.permisions?.filter((per) => per?.isVisibleMapping);
        return `${category?.name} (${activeCount?.length ?? "0"} of ${visibleCount?.length})`
    }

    return (
        <Grid direction="row" xs={12}>
            <span className={classes.roleDesc}>{role?.description}</span>
            {
                <div className={classes.rolesContainer}>
                    {
                        role?.categories?.map((category) => {

                            const visiblePermissions = category?.permisions?.filter(
                                (permision) => permision.isVisibleMapping || permision.isActivInMapping
                            );

                            if (visiblePermissions.length === 0) {
                                return null;
                            }

                            return (
                                <Grid item xs={12} className={classes.rolesCategoryContainer} key={category?.name}>
                                    <AccordionCore
                                        headerActions={renderCategoryActions(category)}
                                        headerText={getCounterByActive(category)}
                                    >
                                        <UserPermissionCategory
                                            category={category}
                                            onChangePermission={onChangePermissionBridge}
                                            onDeletePermission={onDeletePermissionBridge}
                                            onAddPermission={onAddPermissionBridge}
                                        />
                                    </AccordionCore>
                                </Grid>
                            );
                        })
                    }
                </div>
            }
            {renderActions()}
        </Grid>
    );
};
export default PermissionDetail;

