import React, {useState} from 'react';
import {Button, Paper, Checkbox, FormControlLabel} from "@material-ui/core";
import { selectFieldsPopupStyle } from './style';

const SelectFieldsPopup = ({handleClose, columns, selectedColumns, isChecked, onSubmit}) => {

    const classes = selectFieldsPopupStyle();

    const [checked, setChecked] = useState([...selectedColumns]);

    const onSelectField = (index, value) => {
        if (value) {
            setChecked(
                columns.map((column,i) => ( i === index || isChecked(checked,column.name) ) ? column : null)
                .filter(column=>column)
            );
        }
        else{
            setChecked(
                checked.filter( c => c ? c.name !== columns[index].name : false )
            );
        }
    }

    return (
        <Paper className={classes.popupContainer}>
            <div className={classes.checkboxList}>
                {columns.map((column, index) => (
                    <FormControlLabel
                        control={<Checkbox defaultChecked={isChecked(checked, column.name)} onChange={(e, value) => onSelectField(index, value)}/>}
                        label={column.name}
                        key={index}
                    />
                ))}
            </div>
            <div className={classes.btnContainer}>
                <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                <Button
                    onClick={() => onSubmit(checked)}
                    className={classes.button}
                    variant="contained"
                >Finish</Button>
            </div>
        </Paper>
    );
};

export default SelectFieldsPopup;
