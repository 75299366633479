import { makeStyles } from "@material-ui/core";

export const vehicleMatchStyle = makeStyles((theme) => ({
    iconWrapper: {
        position: 'absolute',
        right: '0',
        top: '0',
        border: '1px solid',
        padding: '0.2em',
        borderColor: '#fb7171',
        background: '#fb7171'
    },
    spinnerWrapper: {
        position: 'absolute',
        right: '1em',
        top: '0',
        padding: '0.5em',
    },
    icon: {
        fill: '#fff'
    },
    matchTooltip: {
        padding: '1em'
    },
    record: {
        padding: '1em'
    }
}));