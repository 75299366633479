import { Grid, Tooltip } from "@material-ui/core";
import { useGetUsersNoFilteringQuery, useGetUsersQuery } from "features/users/usersSlice";
import { IssueUserStyle } from "./style";
import { arrayToObject } from "utils/object-util";
import stc from 'string-to-color';
import { orderBy } from "lodash";

export const IssueTrackerUser = ({ userId, iconOnly }) => {
    const classes = IssueUserStyle();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersNoFilteringQuery();

    const userLookup = arrayToObject(users);

    return (<>
        {!!userId && <Tooltip title={userLookup?.[userId]?.name}>
            <Grid container alignItems="center" >
                <Grid item className={classes.userInitials} style={{ background: userLookup?.[userId]?.initials && stc(userLookup?.[userId]?.name) }}>{userLookup?.[userId]?.initials}</Grid>
                {!iconOnly && <Grid item className={classes.userName}> {userLookup?.[userId]?.name}</Grid>}
            </Grid>
        </Tooltip>}
    </>
    )
}