import { Paper } from "@material-ui/core";
import React, { useState } from 'react';
import configStyles from "../../../configStyles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import EditMovementType from "../editMovementType/editMovementType";
import { calcTypeEnum } from "../editMovementType/TransportsEnums";
import { permissionProfiles } from "../../../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../../../components/Authorize";

const useStyles = configStyles;

const MovementType = ({ transportConfigParams, movementType, onConfigChange, localMovementTypes, setLocalMovementTypes }) => {

    const [editMovementType, setEditMovementType] = useState(true);

    const classes = useStyles();

    const enumList = calcTypeEnum;

    const onClickEdit = () => {
        setEditMovementType(false);
    }

    const onClickDelete = () => {
        let newLocalMovementType = [];
        newLocalMovementType = localMovementTypes.filter(m => m.id != movementType.id);
        setLocalMovementTypes(newLocalMovementType);
        onConfigChange(undefined, "loadMoveTypes", newLocalMovementType);
    }

    return (
        <>
            {editMovementType && <Paper className={classes.card}>

                <div className={classes.titleWrapper}>
                    <div className={classes.subTitle}>{movementType?.name}</div>
                    <div className={classes.iconsWrapper}>
                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_EDIT_MOVEMENT_TYPES}>
                            <div>
                                {movementType?.id && <IconButton edge="end" aria-label="delete" onClick={onClickEdit}
                                    disabled={!transportConfigParams?.useTransport}>
                                    <CreateIcon />
                                </IconButton>}
                                <IconButton edge="end" aria-label="delete" onClick={onClickDelete} disabled={!transportConfigParams?.useTransport}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Authorize>
                        <div >
                            <img className={classes.iconWrapper} src="/icons/icon-truck.svg" alt="Contracts" />
                        </div>
                    </div>
                </div>

                <div className={classes.informationText}>
                    {enumList.find(e => e.id == movementType.calcType)?.name}.<br />
                    {movementType.minPickup > 0 && <div>{("Pickup cannot be less than " + movementType.minPickup + " days after transport Request.")}<br /></div>}
                    {movementType.maxDelivery > 0 && ("Delivery no more than " + movementType.maxDelivery + " days after pickup.")}
                </div>

            </Paper>}

            {!editMovementType && <EditMovementType movementType={movementType} localMovementTypes={localMovementTypes} setLocalMovementTypes={setLocalMovementTypes} setAddNewMovementType={setEditMovementType} onConfigChange={onConfigChange} />}
        </>

    );
};

export default MovementType;
