import { Button, Checkbox, FormControlLabel, Grid, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CustomInput from "components/CustomInput";
import { IconCopy, IconDeleteBlue } from "icons";
import DateTimeComponent from "../../../../../components/DateTimeSchedule/DateScheduleComponent";
import { ScheduleGroupType, ScheduleGroupTypeList } from './LotScheduleGroupsEnums';
import LotScheduleStyle from "./LotScheduleStyle";

const LotScheduleGroups = ({ schedules, setSchedules, lotToEdit, setLotToEdit, transports = false }) => {
  const classes = LotScheduleStyle();

  let daysOfWeekList = [
    { id: 1, name: "Sunday" },
    { id: 2, name: "Monday" },
    { id: 3, name: "Tuesday" },
    { id: 4, name: "Wednesday" },
    { id: 5, name: "Thursday" },
    { id: 6, name: "Friday" },
    { id: 7, name: "Saturday" }
  ];

  let scheduleLine = {
    dayOfWeekId: 1,
    openTime: "00:00:00",
    closeTime: "01:00:00",
    createdDate: new Date(),
    lotId: lotToEdit?.id ?? 0,
    id: 0,
    deletedDate: null
  };

  let scheduleGroup = {
    id: 0,
    name: null,
    type: transports ? ScheduleGroupType.TRANSPORTS : null,
    lotId: lotToEdit?.id ?? 0,
    lotSchedules: [scheduleLine]
  };

  const onChangeSchedule = (data, groupIndex, lineIndex, validateResult) => {
    const newSchedules = [...schedules];
    let newGroupSchedules = [...newSchedules[groupIndex]?.lotSchedules];
    let sCopy = { ...newGroupSchedules[lineIndex] };

    if (validateResult) {
      sCopy.dayOfWeekId = parseInt(data.dayOfWeekId);
      sCopy.openTime = data.openTime;
      sCopy.closeTime = data.closeTime;
      newGroupSchedules[lineIndex] = sCopy;
      newSchedules[groupIndex] = { ...newSchedules[groupIndex], lotSchedules: newGroupSchedules };
    }

    if (setSchedules)
      setSchedules(newSchedules);
    else
      setLotToEdit({ ...lotToEdit, schedule: newSchedules });

  }

  const onDeleteSchedule = (groupIndex, lineIndex) => {
    const newSchedules = [...schedules];
    let newGroupSchedules = [...newSchedules[groupIndex]?.lotSchedules];
    let sCopy = { ...newGroupSchedules[lineIndex] };
    sCopy.deletedDate = new Date();
    newGroupSchedules[lineIndex] = sCopy;
    newSchedules[groupIndex] = { ...newSchedules[groupIndex], lotSchedules: newGroupSchedules };

    if (setSchedules)
      setSchedules(newSchedules);
    else
      setLotToEdit({ ...lotToEdit, schedule: newSchedules });
  }

  const onChangeGroupName = (groupIndex, name) => {
    const newObj = [...schedules];
    const newGroup = { ...schedules[groupIndex], name: name };
    newObj[groupIndex] = { ...newGroup };

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });
  }

  const markTransoprts = (groupIndex, val) => {
    const newObj = [...schedules];
    const newGroup = { ...schedules[groupIndex], type: val ? ScheduleGroupType.TRANSPORTS : null };
    newObj[groupIndex] = { ...newGroup };

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });
  }

  const addScheduleLine = (groupIndex) => {
    const newObj = [...schedules];
    let newGroup = { ...schedules[groupIndex] };
    newGroup.lotSchedules = schedules[groupIndex]?.lotSchedules ? [...schedules[groupIndex]?.lotSchedules, scheduleLine] : [scheduleLine];
    newObj[groupIndex] = { ...newGroup };

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });
  }

  const addScheduleGroup = () => {
    const newObj = [...schedules, scheduleGroup];

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });
  }

  const deleteScheduleGroup = (group) => {
    const newObj = [...schedules].filter(item => item !== group);

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });
  }

  const duplicateLine = (groupIndex, lineIndex) => {
    const newObj = [...schedules];
    let newGroup = { ...schedules[groupIndex] };
    newGroup.lotSchedules = [...newGroup?.lotSchedules, { ...newGroup?.lotSchedules[lineIndex], id: 0 }];
    newObj[groupIndex] = { ...newGroup };

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });
  }

  const duplicateGroup = (group) => {
    const newGroupSchedules = group.lotSchedules.map(schedule => ({ ...schedule, id: 0, groupId: 0 }));
    const newObj = [...schedules, { ...group, id: 0, lotSchedules: newGroupSchedules }];

    if (setSchedules)
      setSchedules(newObj);
    else
      setLotToEdit({ ...lotToEdit, schedule: newObj });

    scrollDown();
  }

  const scrollDown = () => {
    const element = document.getElementById("bottomPage");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: "end" });
    }
  }

  return (
    <>

      <Grid container className={classes.titleHeader}>
        <Grid item>
          <div className={classes.title}>Schedules</div>
        </Grid>
        <Grid item>
          <Button variant="outlined"
            color="primary"
            onClick={addScheduleGroup}
            startIcon={<AddIcon />}
          >
            Add Schedule Group
          </Button>
        </Grid>
      </Grid>

      {schedules?.map((group, groupIndex) => (
        <div className={classes.wrapper}>
          <Grid container className={classes.gridContainer} key={groupIndex}>
            <Grid item xs={12} className={classes.groupHeader} key={groupIndex}>
              <Grid item xs={5}>
                <div className={classes.groupName}>
                  <CustomInput
                    identifier='groupName'
                    value={group?.name}
                    onChange={val => onChangeGroupName(groupIndex, val)}
                    required
                  />
                </div>
              </Grid>

              <Grid item >
                <FormControlLabel
                  control={<Checkbox
                    checked={group?.type == ScheduleGroupType.TRANSPORTS}
                    onChange={(e, value) => markTransoprts(groupIndex, value)}
                  />}
                  label={ScheduleGroupTypeList?.find(item => item.id == ScheduleGroupType.TRANSPORTS)?.name} />

                <Tooltip title={`Delete Group`} placement="top">
                  <IconButton onClick={() => deleteScheduleGroup(group)}>
                    <IconDeleteBlue width={"25"} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={`Duplicate Group`} placement="top">
                  <IconButton onClick={() => duplicateGroup(group)}>{<IconCopy width={"25"} fill="#0090FE" />} </IconButton>
                </Tooltip>

              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.schedulelines}>
              {group?.lotSchedules?.map((scheduleLine, lineIndex) => (
                scheduleLine.deletedDate === null && (
                  <>
                    <DateTimeComponent
                      data={scheduleLine}
                      daysOfWeekList={daysOfWeekList}
                      onChangeSchedule={onChangeSchedule}
                      lineIndex={lineIndex}
                      groupIndex={groupIndex}
                      onDeleteSchedule={onDeleteSchedule}
                      valueFromAPI={scheduleLine.id > 0 ? scheduleLine.dayOfWeekId : 1}
                      schedules={schedules}
                      duplicateLine={duplicateLine}
                    />
                  </>
                )
              ))}
            </Grid>

            <Grid item>
              <Button variant="text"
                color="primary"
                onClick={() => addScheduleLine(groupIndex)}
                startIcon={<AddIcon />}
              >
                Add Schedule Line
              </Button>
            </Grid>
          </Grid>
        </div>
      ))}

      <div id="bottomPage" />
    </>
  );
};

export default LotScheduleGroups;
