import CheckCircleIcon from '@material-ui/icons/CheckCircle';
export const statuses = {
    1: {
        label: "Match",
        icon: <CheckCircleIcon style={{
            fill: '#79c179',
            height: '0.8em'
        }} />,
        explanation: "Match - Parq matches what the SS Submitted!"
    },
    2: {
        label: "Not on lot in Parq",
        explanation: "Asset is 'Unavailable' in Parq but SS notes that it is physically on the lot"
    },
    3: {
        label: "On lot in Parq, missing in file",
        explanation: "Parq shows this asset on the lot but the SS notes that it is no longer physically on the lot"
    },
    4: {
        label: "Does not exist in Parq",
        explanation: "Asset has never been checked into Parq but SS notes that it is physically on the lot"
    },
    5: {
        label: "Duplicate",
        explanation: "Duplicate - Parq shows this asset more than once"
    }
}

export const auditStatusesById = {
    MATCH: 1,
    NOT_ON_LOT_IN_PARQ: 2,
    ON_LOT_IN_PARQ_MISSING_IN_FILE: 3,
    NO_EXIST_IN_PARQ: 4,
    DUPLICATE: 5
};

export const auditFileStatuses = {
    1: 'Pending',
    2: 'Processing',
    3: 'Done'
}
