import { makeStyles } from "@material-ui/core"

export const LoadsDropDownStyle = makeStyles((theme) => ({
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    input: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    },
    upwardArrow: {
        margin: '0 0.5rem',
        marginLeft: '1em',
        background: '#FB8C00',
        padding: '1px',
        borderRadius: '50%',
        '& path': {
            fill: 'white'
        }
    },
    downwardArrow: {
        margin: '0 0.5rem',
        background: '#4CAF50',
        padding: '1px',
        borderRadius: '50%',
        '& path': {
            fill: 'white'
        }
    },
    loadLabel: {
        fontWeight: 600,
        paddingLeft: '1em'
    },
}));