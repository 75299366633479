import { Grid, IconButton, Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { copyToClipboardStyle } from './style';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import { IconCheck, IconCopy } from 'icons';
import { useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';

const CopyToClipboard = ({
    text,
    title = 'Copy to clipboard',
    children,
    buttonComponent
}) => {
    const classes = copyToClipboardStyle();

    const [copied, setCopied] = useState(false);

    const onCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <>
            {children}
            <Copy text={text} onCopy={onCopy}>
                {copied ? <span className={classes.copied} ><CheckIcon style={{ fill: 'green' }} />Copied </span> : buttonComponent ?? <Tooltip title={title}>
                    <IconButton size='small'>{<IconCopy className={classes.icon} color='secondary' />} </IconButton>
                </Tooltip>}
            </Copy>
        </>
    )
}
export default CopyToClipboard;