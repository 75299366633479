import { makeStyles } from '@material-ui/core/styles';

export const tableStyle = makeStyles((theme) => ({
    table: {
        backgroundColor: theme.palette.common.white,
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: theme.palette.common.white
    },
    tableRow: {
        height: 51,
        verticalAlign: "center"
    },
    tableContainer: {
        padding: "0 15px",
        //boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.15)",
        height: '100%',
        minHeight: '50vh'
    },
    columnHeader: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontWeight: 400,
        fontSize: 16
    },
    hiddenIcon: {
        visibility: 'hidden'
    },
    noItemsDiv: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '3em',
        fontSize: '1.2em'
    },
    iconButtonCell: {
        paddingTop: 11
    },
    iconButton: {
        width: 32,
        height: 32
    },
    tableActions: {
        //background: '#f3f5f6',
        borderRadius: '5px 5px 0 0',
        maxHeight: '45px',
        margin: '10px 0',
        alignContent: 'center'
    },
    card: {
        width: '80%',
        height: '100%'
    },
    listContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        position: 'relative'
    },
    filters: {
        marginRight: '0.7rem'
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    subtitle: {
        fontSize: 12,
        fontStyle: "italic",
        marginBottom: 6
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row"
    },
    actionButtonsContainer: {
        //padding: "17px 1px"
    },
    header: {
        padding: '1em',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
        //height: '17vh'
    },
    views: {
        display: 'flex'
    },
    tableViewsSelector: {
        marginLeft: '0.5em',
        paddingLeft: '1em',
        paddingTop: '3px',
        fontWeight: '500',
        borderLeft: '1px solid #efefef'
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 13,
    },
    titleWrapper: {
        marginBottom: '1em'
    }
}));
