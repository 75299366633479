import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    addButton: {
        margin: "0.5rem 0 0 1rem",
        marginBottom: '15px'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 400,
        margin: "0 0 1rem 0",
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        margin: "0 0 0.5rem 1rem",
        marginTop: '10px'
    },
    cardWrapper: {
        marginTop: 10,
        padding: "20px 20px 20px",
    },
    buttonWrapper: {
        justifyContent: 'space-between'
    },
    saveButton: {
        margin: "0.5rem 0 0 1rem",
        marginRight: '20px',
        marginBottom: '15px',
    },
    description: {
        color: 'grey',
        fontSize: '12px'
    },
    name: {
        fontWeight: 'bold',
        fontSize: '14px'
    },
    dropdown: {
        whiteSpace: 'pre-wrap'
    },
    restrictions: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14
    },
    restrictionDropdown: {
        justifyContent: 'space-between'
    },
    paper: {
        padding: "20px 20px 20px",
        color: theme.palette.common.darkText,
        marginBottom: '10px',
        minHeight: '60vh',
    },
    grids: {
        padding: "5px 5px 5px",
        marginBottom: '4px'
    },
    label: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '.5em .5em 0.5em .5em'
    },
    title: {
        fontSize: '1.7em',
    },
    list: {
        padding: ".6px 15px .6px",
        marginBottom: '3px',
        marginRight: '5px',
        marginLeft: '5px'
    },
    save: {
        marginRight: '4px',
        marginTop: '6px'
    },
    resetButton: {
        marginBottom: '10px',
        marginRight: '5px',
        marginTop: '8px'
    },
    btnSave: {
        float: 'right'
    },
    wrapperSave: {
        justifyContent: 'flex-end'
    },
    requiredField: {
        color: "#f75050"
    },
    editUserDialogContent: {
        minHeight: '70vh',
        maxHeight: '70vh',
    },
    labelIcon: {
        marginBottom: '0.5rem',
        padding: 0
    },
    componentWrapper: {
        padding: "20px 20px 20px",
        height: '60vh',
        flex: 1,
        overflowY: 'auto',
        paddingBottom: '10px',
    },
    icon: {
        position: 'absolute', 
        right: '15px', 
        top: '50%', 
        transform: 'translateY(-50%)'
    }
}));