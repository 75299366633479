import LoadingSpinner from "components/LoadingSpinner";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { kpiDashboardStyles } from "./KpiDashboardStyles";
import KpiInspection from "./navigations/KpiInspection/KpiInspection";
import KpiOverview from "./navigations/KpiOverview/KpiOverview";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import { useTitle } from "hooks/useTitle";

const KpiDashboardPage = ({ lotId }) => {

    useTitle('KPI Dashboard');
    
    const classes = kpiDashboardStyles();

    const isLoadingDashboardData = () => false;

    const tabs = [
        {
            label: 'Overview',
            component: <KpiOverview />,
            profile: permissionProfiles.DASHBOARD.KPI_DASHBOARD_VIEW_TAB_OVERVIEW
        },
        {
            label: 'Inspection',
            component: <KpiInspection lotId={lotId} />,
            profile: permissionProfiles.DASHBOARD.KPI_DASHBOARD_VIEW_TAB_INSPECTION
        }
    ]
    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <TabsWrapper tabs={tabs} />
            }
        </>
    );
};

export default KpiDashboardPage;
