import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import UsersPageStyle from "../../UsersPageStyle";
import { useState } from "react";
import CustomInput from "components/CustomInput";
import {useUserResetPasswordMutation} from "features/users/usersSlice";
import NotificationProvider from "components/NotificationProvider";
import { Alert } from "@mui/material";
import {validatePassword} from "../../validatePasswordUtil";

const useStyles = UsersPageStyle;

const ResetPasswordDialog = ({open, setOpenDialog, userId}) => {
    const classes = useStyles();

    const [resetPassword] = useUserResetPasswordMutation()

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [editingPassword, setEditingPassword] = useState(false);
    const [editingConfirm, setEditingConfirm] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [validPassword, setValidPassword] = useState(false);
    const [validConfirm, setValidConfirm] = useState(false);
    const [validBoth, setValidBoth] = useState(false);

    const onReset = async () => {
        setIsSaving(true);

        let objToSend = {
            userId: userId,
            password: password,
            confirmPassword: confirmPassword
        }
        let result = await resetPassword(objToSend);

        if (result?.error) {
            NotificationProvider.error("New passsword failed to save.");

        } else {
            NotificationProvider.success(`Password changed successfully.`);
            setOpenDialog(false);
        }
        setIsSaving(false);

    }

    const onClose = () => {
        setOpenDialog(false);
        setPassword("");
        setConfirmPassword("");
        setEditingPassword(false);
        setDisableSave(true);
        setEditingConfirm(false);
        setValidBoth(false);
        setValidConfirm(false);
        setValidPassword(false);
    }

    const onChangePasswords = (pword, cPassword) => {
        pword ? setEditingPassword(true) : setEditingPassword(false);
        cPassword ? setEditingConfirm(true) : setEditingConfirm(false);

        let validPasswords = validatePassword(pword ? pword : password, cPassword ? cPassword : confirmPassword);

        setValidPassword(validPasswords.validPassword);
        setValidConfirm(validPasswords.validConfirm);
        setValidBoth(validPasswords.validBoth);

        setPassword(pword);
        setConfirmPassword(cPassword);

        if (validPasswords.validBoth) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle className={classes.label}>Reset Password</DialogTitle>
            <DialogContent>
                <div>Enter your new password</div>
                <Grid item className={classes.grids}>
                    <CustomInput
                        id="password"
                        label="Password"
                        elementType="input"
                        onChange={val => onChangePasswords(val, confirmPassword)}
                        classStyles={classes.dropdown}
                        required
                        touched
                    />
                </Grid>
                {
                    !validPassword && editingPassword ?
                        <Grid className={classes.grids}>
                            <Alert severity="warning">
                                {'Password requires a minimum of 8 characters, upper and lower case letters, a number and a special character'}
                            </Alert>
                        </Grid> : null
                }
                <Grid item className={classes.grids}>
                    <CustomInput
                        id="confirmpassword"
                        label="Confirm Password"
                        elementType="input"
                        onChange={val => onChangePasswords(password, val)}
                        classStyles={classes.dropdown}
                        required
                        touched
                    />
                </Grid>
                {
                    !validConfirm && editingConfirm ?
                        <Grid className={classes.grids}>
                            <Alert severity="warning">
                                {"Passwords do not match"}
                            </Alert>
                        </Grid> : null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">Close</Button>
                <Button
                    onClick={onReset}
                    disabled={isSaving || disableSave}
                    variant="contained"
                    color="primary"
                >
                    {isSaving ? "Saving..." : "Reset"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResetPasswordDialog;