import AssignmentIcon from '@material-ui/icons/Assignment';
import ChecklistIcon from '@material-ui/icons/Check';
import LotsConfig from "./clientConfigs/lots";
import TransportsConfig from "./clientConfigs/transports";
import WashConfig from "./clientConfigs/washes";
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import BuildIcon from '@material-ui/icons/Build';
import MechanicalWorkConfig from './contracts/MechanicalWorkConfig/MechanicalWorkConfig';
import { IconTruck } from 'icons';

export const clientConfigTypes = {
    Transportation: 1,
    Wash: 2,
    Lot: 3,
    Readiness: 4,
    Mechanicals: 5,
    ConditionalReport: 6,
    Checkin: 7,
    Checkout: 9
};

export const clientConfigTypeToName = {
    [clientConfigTypes.Transportation]: 'transportConfig',
    [clientConfigTypes.Wash]: 'washConfig',
    [clientConfigTypes.Lot]: 'lotsConfig',
    [clientConfigTypes.Readiness]: 'readinessConfig',
    [clientConfigTypes.Mechanicals]: 'mechanicalsConfig',
    [clientConfigTypes.ConditionalReport]: 'conditionalReportConfig',
    [clientConfigTypes.Checkin]: 'checkinConfig',
    [clientConfigTypes.Checkout]: 'checkoutConfig'
};

export const clientConfigSetup = (clientToEdit, onConfigChange) => {
    let list = [
        {
            label: 'Storage',
            configType: clientConfigTypes.Lot,
            configName: 'lotsConfig',
            component: <LotsConfig clientToEdit={clientToEdit} onConfigChange={onConfigChange} />,
            showOnCreate: false,
            icon: <img src="/icons/icon-parking.svg" />,
            isOn: () => true,
            showSubClients: true
        },
        {
            label: 'Transports',
            configType: clientConfigTypes.Transportation,
            configName: 'transportConfig',
            component: <TransportsConfig clientToEdit={clientToEdit} onConfigChange={onConfigChange} />,
            showOnCreate: true,
            icon: <IconTruck />,
            isOn: (clientToEdit) => {
                return clientToEdit?.config?.transportConfig?.useTransport
            }
        },
        {
            label: 'Wash',
            configType: clientConfigTypes.Wash,
            configName: 'washConfig',
            component: <WashConfig clientToEdit={clientToEdit} onConfigChange={onConfigChange} />,
            showOnCreate: true,
            isOn: (clientToEdit) => clientToEdit?.config?.washConfig?.useWashProgram
        },
        {
            label: 'Readiness',
            configType: clientConfigTypes.Readiness,
            configName: 'readiness',
            icon: <BeenhereOutlinedIcon />,
            showOnCreate: false,
            isOn: (clientToEdit) => !!clientToEdit?.readinessOptions?.length
        },
        {
            label: 'Check-in',
            configType: clientConfigTypes.Checkin,
            configName: 'checkinConfig',
            icon: <ChecklistIcon />,
            showOnCreate: false,
            isOn: () => true
        },
        {
            label: 'Check-out',
            configType: clientConfigTypes.Checkout,
            configName: 'checkoutConfig',
            icon: <ChecklistIcon />,
            showOnCreate: false,
            isOn: () => true
        },
        {
            label: 'Mechanical Work',
            configType: clientConfigTypes.Mechanicals,
            component: <MechanicalWorkConfig
                clientToEdit={clientToEdit}
                onConfigChange={onConfigChange}
            />,
            configName: 'mechanicals',
            icon: <BuildIcon />,
            showOnCreate: false,
            isOn: (clientToEdit) => clientToEdit?.config?.mechanicalsConfig?.useMechanicals
        },
        {
            label: 'Condition Report',
            configType: clientConfigTypes.ConditionalReport,
            configName: 'conditionalReport',
            icon: <AssignmentIcon />,
            showOnCreate: false,
            isOn: (clientToEdit) => clientToEdit?.config?.conditionalReportConfig?.useConditionalReport
        }
    ];
    // if (window.location.href.includes('demo.parq.ai') || window.location.href.includes('localhost')) {
    //     list.push({
    //         label: 'New Check-in',
    //         configName: 'newCheckin',
    //         icon: <ChecklistIcon />,
    //         showOnCreate: false,
    //         isOn: () => true
    //     },)
    // }
    return list;
};
