import { IconButton, Tooltip } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { vehicleMatchStyle } from "./VinMatchingStyle";

const VinMatching = ({ decodedData, editedData, field }) => {
    
    const classes = vehicleMatchStyle();
    const title = "The entered " + field + " doesn't match the " + field + " decoded from the VIN: " + decodedData;
    const doesntMatch = decodedData && editedData && (decodedData != editedData);

    return (
        <>
            {doesntMatch && <div className={classes.iconWrapper}>
                <Tooltip title={title}><IconButton className={classes.iconButton}>
                    <ErrorOutlineOutlinedIcon
                        className={classes.icon}
                    />
                </IconButton>
                </Tooltip>
            </div>}
        </>
    )
}

export default VinMatching; 