import { Grid, Paper } from '@material-ui/core';
import { IconAccessCode, IconLot } from 'icons';
import FormatService from 'services/formatService';
import { AccessCodeListStyle } from './style';

const AccessCodeView = ({ accessCode }) => {
    const classes = AccessCodeListStyle()

    const lotFields = [
        {
            key: 'lotName',
            className: classes.lotName
            //label: 'Location Name'
        },
        {
            key: 'addressLine1',
            //label: 'Address'
        },
        {
            key: 'city',
            // label: 'City'
        },
        {
            key: 'state',
            //label: 'State'
        },
        {
            key: 'zipCode',
            //label: 'Zip Code'
        }
    ];

    const otherFields = [
        {
            key: 'pinCode',
            label: 'PIN'
        },
        {
            key: 'startDate',
            label: 'Start date',
            value: (val) => FormatService.formatDate(val)
        },
        {
            key: 'endDate',
            label: 'End date',
            value: (val) => FormatService.formatDate(val)
        },
        {
            key: 'startTime',
            label: 'Start time',
        },
        {
            key: 'endTime',
            label: 'End time',
        },
    ];

    const fieldGroups = [
        {
            fields: lotFields,
            icon: <IconLot className={classes.icon} />
        },
        {
            fields: otherFields,
            icon: <IconAccessCode className={classes.icon} />
        }];

    return (
        <Paper className={classes.accessCodeWrapper}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <Grid container direction='column' spacing={2} >
                        {fieldGroups.map(fieldGroup =>
                            <Grid className={classes.fieldGroupWrapper} item container spacing={2} alignItems='center'>
                                <Grid item >{fieldGroup.icon}</Grid>
                                <Grid item direction='column'>
                                    {fieldGroup?.fields?.map(field =>
                                        <Grid item className={field.className}>
                                            {!!field.label && <span className={classes.label} >{field.label}</span>}
                                            {field?.value ? field.value(accessCode?.[field.key]) : accessCode?.[field.key] ?? '-'}
                                        </Grid>)}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item className={classes.qrCode}>
                    <a target='_blank' download='qr_code' href={accessCode.qrCodeUrl}>
                        <img height='200' src={accessCode.qrCodeUrl} />
                    </a>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default AccessCodeView;