import * as React from "react";

function IconGroup(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.95817 7.11442H3.5415C2.7735 7.11442 2.25329 6.90473 1.92412 6.57555C1.59495 6.24638 1.38525 5.72618 1.38525 4.95817V3.5415C1.38525 2.7735 1.59495 2.25329 1.92412 1.92412C2.25329 1.59495 2.7735 1.38525 3.5415 1.38525H4.95817C5.72618 1.38525 6.24638 1.59495 6.57555 1.92412C6.90473 2.25329 7.11442 2.7735 7.11442 3.5415V4.95817C7.11442 5.72618 6.90473 6.24638 6.57555 6.57555C6.24638 6.90473 5.72618 7.11442 4.95817 7.11442ZM3.5415 1.44775C2.92545 1.44775 2.36647 1.5763 1.97139 1.97139C1.5763 2.36647 1.44775 2.92545 1.44775 3.5415V4.95817C1.44775 5.57422 1.5763 6.1332 1.97139 6.52829C2.36647 6.92337 2.92545 7.05192 3.5415 7.05192H4.95817C5.57422 7.05192 6.1332 6.92337 6.52829 6.52829C6.92337 6.1332 7.05192 5.57422 7.05192 4.95817V3.5415C7.05192 2.92545 6.92337 2.36647 6.52829 1.97139C6.1332 1.5763 5.57422 1.44775 4.95817 1.44775H3.5415Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M13.4582 7.11442H12.0415C11.2735 7.11442 10.7533 6.90473 10.4241 6.57555C10.0949 6.24638 9.88525 5.72618 9.88525 4.95817V3.5415C9.88525 2.7735 10.0949 2.25329 10.4241 1.92412C10.7533 1.59495 11.2735 1.38525 12.0415 1.38525H13.4582C14.2262 1.38525 14.7464 1.59495 15.0756 1.92412C15.4047 2.25329 15.6144 2.7735 15.6144 3.5415V4.95817C15.6144 5.72618 15.4047 6.24638 15.0756 6.57555C14.7464 6.90473 14.2262 7.11442 13.4582 7.11442ZM12.0415 1.44775C11.4255 1.44775 10.8665 1.5763 10.4714 1.97139C10.0763 2.36647 9.94775 2.92545 9.94775 3.5415V4.95817C9.94775 5.57422 10.0763 6.1332 10.4714 6.52829C10.8665 6.92337 11.4255 7.05192 12.0415 7.05192H13.4582C14.0742 7.05192 14.6332 6.92337 15.0283 6.52829C15.4234 6.1332 15.5519 5.57422 15.5519 4.95817V3.5415C15.5519 2.92545 15.4234 2.36647 15.0283 1.97139C14.6332 1.5763 14.0742 1.44775 13.4582 1.44775H12.0415Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M13.4582 15.6144H12.0415C11.2735 15.6144 10.7533 15.4047 10.4241 15.0756C10.0949 14.7464 9.88525 14.2262 9.88525 13.4582V12.0415C9.88525 11.2735 10.0949 10.7533 10.4241 10.4241C10.7533 10.0949 11.2735 9.88525 12.0415 9.88525H13.4582C14.2262 9.88525 14.7464 10.0949 15.0756 10.4241C15.4047 10.7533 15.6144 11.2735 15.6144 12.0415V13.4582C15.6144 14.2262 15.4047 14.7464 15.0756 15.0756C14.7464 15.4047 14.2262 15.6144 13.4582 15.6144ZM12.0415 9.94775C11.4255 9.94775 10.8665 10.0763 10.4714 10.4714C10.0763 10.8665 9.94775 11.4255 9.94775 12.0415V13.4582C9.94775 14.0742 10.0763 14.6332 10.4714 15.0283C10.8665 15.4234 11.4255 15.5519 12.0415 15.5519H13.4582C14.0742 15.5519 14.6332 15.4234 15.0283 15.0283C15.4234 14.6332 15.5519 14.0742 15.5519 13.4582V12.0415C15.5519 11.4255 15.4234 10.8665 15.0283 10.4714C14.6332 10.0763 14.0742 9.94775 13.4582 9.94775H12.0415Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M4.95817 15.6144H3.5415C2.7735 15.6144 2.25329 15.4047 1.92412 15.0756C1.59495 14.7464 1.38525 14.2262 1.38525 13.4582V12.0415C1.38525 11.2735 1.59495 10.7533 1.92412 10.4241C2.25329 10.0949 2.7735 9.88525 3.5415 9.88525H4.95817C5.72618 9.88525 6.24638 10.0949 6.57555 10.4241C6.90473 10.7533 7.11442 11.2735 7.11442 12.0415V13.4582C7.11442 14.2262 6.90473 14.7464 6.57555 15.0756C6.24638 15.4047 5.72618 15.6144 4.95817 15.6144ZM3.5415 9.94775C2.92545 9.94775 2.36647 10.0763 1.97139 10.4714C1.5763 10.8665 1.44775 11.4255 1.44775 12.0415V13.4582C1.44775 14.0742 1.5763 14.6332 1.97139 15.0283C2.36647 15.4234 2.92545 15.5519 3.5415 15.5519H4.95817C5.57422 15.5519 6.1332 15.4234 6.52829 15.0283C6.92337 14.6332 7.05192 14.0742 7.05192 13.4582V12.0415C7.05192 11.4255 6.92337 10.8665 6.52829 10.4714C6.1332 10.0763 5.57422 9.94775 4.95817 9.94775H3.5415Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default IconGroup;
