import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';
import {useGetImportLotsTemplateMutation} from "../../lotSlice";
import {downloadBase64File} from "../../../../utils/object-util";

import formStyles from "./Styles";
function ImportLotsTemplateDialog({ open, setOpen }) {
    const classes = formStyles();

    let columns = [
        { name: "SKU", key: "SKU" },
        { name: "Alias", key: "Alias" },
        // { name: "Status", key: "Status" },
        { name: "Size in Acres", key: "SizeAcres" },
        { name: "POC Email", key: "POCEmail" },
        { name: "Offices", key: "Offices" },
        { name: "Bathrooms", key: "Bathrooms" },
        { name: "Audit Day", key: "AuditDay" },
        { name: "Time Zone", key: "TimeZone" },
        { name: "Driving Directions", key: "DrivingDirections"}
    ];

    const [importTemplate, { data: importAssetsTemplate, error: templateError, isLoading: templateLoading }] = useGetImportLotsTemplateMutation();
    const [checked, setChecked] = useState([...columns]);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSelectFeild = (index, value) => {
        if (value) {
            setChecked(
                columns.map((column, i) => (i == index || isChecked(checked, column.name)) ? column : null)
                    .filter(column => column)
            );
        }
        else {
            setChecked(
                checked.filter(c => c ? c.name != columns[index].name : false)
            );
        }
    }

    const handleClose = async () => {
        const uncheckedColumns = columns
            .filter(column => !isChecked(checked, column.name))
            .map(column => column.key);

        const result = await importTemplate({ columnsToIgnore: uncheckedColumns });

        downloadBase64File(result?.data?.type, result?.data?.content, result?.data?.name);

        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="import-dialog-title">{'Select fields to include in the template'}</DialogTitle>
            <DialogContent className={classes.content}>
                {<>
                    <div className={classes.checkboxList}>
                        {columns.map((column, index) => (
                            <>
                                <FormControlLabel
                                    className={classes.checkboxLabel}
                                    control={<Checkbox defaultChecked={isChecked(checked, column.name)} 
                                                       onChange={(e, value) => onSelectFeild(index, value)} />}
                                    label={column.name}
                                />
                            </>
                        ))}
                    </div>
                </>}
            </DialogContent>
            <DialogActions>
                {<Button
                    onClick={handleClose}
                    color="primary"
                    variant='contained'
                    disabled={templateLoading}>
                    Download
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

export default ImportLotsTemplateDialog;
