import { makeStyles } from "@material-ui/core";

export const IssueTrackerCompleteWorkflowStyle = makeStyles((theme) => ({
    text: {
        fontSize: '1.2em',
        marginBottom: '1em'
    },
    content: {
        marginBottom: '1em'
    }
}))