import { questionTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { Grid, Paper } from "@material-ui/core";
import { TextComponent } from "./TextComponent";
import { EditFormOutputStyle } from "../style";
import { DateComponent } from "./DateComponent";
import { DropdownComponent } from "./DropdownComponent";
import { ImageComponent } from "./ImageComponent";
import { MultipleChoiceComponent } from "./MultipleChoiceComponent";
import CustomInput from "components/CustomInput";
import { BooleanComponent } from "./BooleanComponent";

export const Question = ({ question, formAnswers, setFormAnswers, base64ImagesLookup }) => {
    const classes = EditFormOutputStyle();

    const questionTypeComponents = {
        [questionTypes.Freetext]: TextComponent,
        [questionTypes.Date]: DateComponent,
        [questionTypes.Dropdown]: DropdownComponent,
        [questionTypes.Image]: ImageComponent,
        [questionTypes.MultipleChoice]: MultipleChoiceComponent,
        [questionTypes.Boolean]: BooleanComponent,
    };

    const QuestionComponent = questionTypeComponents[question?.questionType];

    const getValues = () => {
        if (question?.values) {
            let valuesObj = JSON.parse(question?.values);
            valuesObj = Array.isArray(valuesObj) ? valuesObj?.map((v, i) => {
                return { ...v, id: i, name: v.value }
            }) : [];
            return valuesObj;
        } else {
            return null
        }
    }

    const getImageValue = () => {
        if (formAnswers?.[question.id]?.pictureObj) {
            return formAnswers?.[question.id]?.pictureObj?.url
        }
        return formAnswers?.[question.id]?.pictureUrl;
    }

    const getValue = () => {
        switch (question?.questionType) {
            case questionTypes.Image:
            case questionTypes.Signature:
                return getImageValue()
            default:
                return formAnswers?.[question.id]?.values
        }
    }

    const onChangeValue = (val, key) => {
        let newAnswer;
        if (formAnswers?.[question.id]) {
            newAnswer = { ...formAnswers?.[question.id], [key]: val };
        } else {
            newAnswer = {
                ...question,
                id: 0,
                questionId: question.id,
                customFormReponseId: 0,
                [key]: val,
            }
        }
        setFormAnswers({ ...formAnswers, [question.id]: newAnswer });
    }

    return (
        <Paper className={classes.question}>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <div className={classes.questionTitle}>
                        <b>{question?.question}{question.required && <label className={classes.required}> *</label>}</b>
                    </div>
                    <div>
                        {QuestionComponent ? <QuestionComponent
                            value={getValue()}
                            values={getValues()}
                            onChange={(val, key) => onChangeValue(val, key)} /> :
                            <></>}
                    </div>
                    {!!question?.includeNotes &&
                        <div className={classes.notesAndPhotos}>
                            <CustomInput
                                elementType='input'
                                value={formAnswers?.[question.id]?.note}
                                onChange={(val) => onChangeValue(val, "note")}
                                label="Note" />
                        </div>}
                    {(!!question?.includePhoto && question?.questionType !== questionTypes.Image) &&
                        <div className={classes.notesAndPhotos}>
                            <ImageComponent
                                value={getImageValue()}
                                onChange={(val, key) => onChangeValue(val, key)} />
                        </div>}
                </Grid>
            </Grid>
        </Paper>
    )
}