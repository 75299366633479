import * as React from "react";

function SvgIconBox(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M11.8718 13.1767L11.8703 13.1758L3.04179 8.0667C3.0416 8.06659 3.04142 8.06649 3.04123 8.06638C2.92125 7.99599 2.88035 7.84285 2.95172 7.7205C3.02089 7.60193 3.16563 7.55629 3.30162 7.63259L11.7494 12.5213L11.9998 12.6662L12.2502 12.5213L20.6502 7.66136L20.6518 7.66046C20.7655 7.59411 20.9242 7.63101 20.9994 7.75314C21.0638 7.86679 21.0264 8.02373 20.905 8.09834L12.1611 13.1632C12.0766 13.2003 12.027 13.2086 11.9998 13.2086C11.9481 13.2086 11.9069 13.1972 11.8718 13.1767Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M12 22.2687C11.8661 22.2687 11.75 22.1526 11.75 22.0187V12.9487C11.75 12.8149 11.8661 12.6987 12 12.6987C12.1339 12.6987 12.25 12.8149 12.25 12.9487V22.0187C12.25 22.1526 12.1339 22.2687 12 22.2687Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M4.46315 19.1817L4.46166 19.1809C3.83167 18.8333 3.24632 18.2235 2.81758 17.4931C2.3889 16.7628 2.14014 15.9519 2.14014 15.2287V9.57867C2.14014 8.85572 2.38876 8.04739 2.8174 7.31836C3.24586 6.58963 3.83153 5.97872 4.4633 5.6256C4.46352 5.62547 4.46374 5.62535 4.46397 5.62522L9.80253 2.666L9.80267 2.66592C10.3975 2.33595 11.1871 2.15869 12.0001 2.15869C12.8131 2.15869 13.6028 2.33595 14.1976 2.66592L19.5371 5.63563L19.5386 5.63645C20.1686 5.98404 20.7539 6.59391 21.1827 7.32429C21.6113 8.05455 21.8601 8.86551 21.8601 9.58868V15.2387C21.8601 15.9616 21.6115 16.77 21.1828 17.499C20.7543 18.2278 20.1686 18.8388 19.5367 19.1919C19.5366 19.192 19.5364 19.1921 19.5363 19.1921L14.1989 22.1507C14.1988 22.1508 14.1987 22.1508 14.1985 22.1509C13.5973 22.4819 12.8144 22.6587 12.0001 22.6587C11.1861 22.6587 10.4033 22.482 9.80218 22.1512C9.80189 22.151 9.80161 22.1509 9.80132 22.1507L4.46315 19.1817ZM10.0514 3.09938L10.0514 3.09936L10.0471 3.10173L4.70711 6.0717L4.7071 6.07169L4.705 6.07287C4.12653 6.39826 3.6171 6.96606 3.25446 7.58091C2.8919 8.19564 2.64014 8.91835 2.64014 9.58868V15.2387C2.64014 15.9044 2.89217 16.6271 3.25422 17.2423C3.6164 17.8577 4.12575 18.4286 4.705 18.7544L4.7071 18.7556L10.0457 21.7249C10.0461 21.7251 10.0464 21.7253 10.0468 21.7255C10.5988 22.0345 11.3176 22.1712 12.0001 22.1712C12.6826 22.1712 13.4014 22.0345 13.9534 21.7255C13.9538 21.7253 13.9542 21.7251 13.9546 21.7249L19.2931 18.7556L19.2952 18.7544C19.8737 18.4291 20.3831 17.8613 20.7458 17.2464C21.1083 16.6317 21.3601 15.909 21.3601 15.2387V9.58868C21.3601 8.92297 21.1081 8.2003 20.746 7.58508C20.3838 6.96964 19.8745 6.3987 19.2952 6.07287L19.2931 6.0717L13.9531 3.10173L13.9532 3.1017L13.9489 3.09938C13.3967 2.7993 12.689 2.65868 12.0001 2.65868C11.3112 2.65868 10.6035 2.7993 10.0514 3.09938Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M16.7502 10.4288V10.1399L16.5 9.99564L7.38212 4.73687C7.38185 4.73671 7.38159 4.73656 7.38132 4.7364C7.25723 4.66345 7.22554 4.51161 7.28891 4.40599L7.28894 4.40601L7.29205 4.40067C7.36388 4.27755 7.50842 4.24071 7.62825 4.31061L7.63015 4.31171L17.1201 9.79175L17.1201 9.79177L17.1241 9.79404C17.2 9.83689 17.2502 9.92213 17.2502 10.0087V13.6688C17.2502 13.7739 17.1428 13.8987 17.0002 13.8987C16.8663 13.8987 16.7502 13.7826 16.7502 13.6487V10.4288Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconBox;
