import { makeStyles } from "@material-ui/core"

export const vehicleLotAuditStyle = makeStyles((theme) => ({
    iconContainer: {
        display: 'flex',
    },
    checkIcon: {
        fill: '#79c179',
        height: '0.8em'
    },
    tableWrapper: {
        marginTop: '0.5em'
    },
    uploadFileFields: {
        paddingBottom: '1rem'
    },
    actionButtons: {
        margin: '1rem',
        padding: 2,
        width: '9rem',
        height: '2.8rem'
    }
}));