import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const baseUrl = 'userTaskConfig';
export const userTaskConfigApi = baseApi.injectEndpoints({
    reducerPath: 'userTaskConfigApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUserTaskConfigs: builder.query({
            query: (userId) => `${baseUrl}/${userId}/get`,
            providesTags: [apiTags.USER_TASK_CONFIG, apiTags.USER_ROLE]
        }),
        deleteUserTaskConfig: builder.mutation({
            query: id => ({
                url: `${baseUrl}`,
                method: "DELETE",
                body: id
            }),
            invalidatesTags: [apiTags.USER_TASK_CONFIG],
        }),
        AddUserTaskConfig: builder.mutation({
            query: body => ({
                url: `${baseUrl}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: [apiTags.USER_TASK_CONFIG],
        }),
        UpdateUserTaskConfig: builder.mutation({
            query: body => ({
                url: `${baseUrl}`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: [apiTags.USER_TASK_CONFIG],
        }),
    }),
});

export const {
    useGetUserTaskConfigsQuery,
    useDeleteUserTaskConfigMutation,
    useAddUserTaskConfigMutation,
    useUpdateUserTaskConfigMutation
} = userTaskConfigApi;
