import { FormControlLabel, Grid, Paper, Switch } from "@material-ui/core";
import { useState } from "react";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../components/CustomInput";
import { IconPercent } from "../../../../icons";
import ContractHeader from "../../contracts/ContractHeader/ContractHeader";
import configStyles from "../configStyles";
import DisabledContract from "../DisabledContract";
import BusinessUnits from './businessUnits/businessUnits';
import MovementTypes from "./movementTypes/movementTypes";
import ActiveTransportersList from "./transporters/transporters";
import VehicleStatusView from "features/vehicles/VehicleStatus/VehicleStatusView";

const useStyles = configStyles;

const TransportsConfig = ({ clientToEdit, onConfigChange, configName = 'transportConfig' }) => {
    const classes = useStyles();

    const [isChecked, setIsChecked] = useState(clientToEdit?.config?.transportConfig?.allowNewLots);

    const onAlloeNewLots = (e) => {
        setIsChecked(e.target.checked);
        onConfigChange(configName, "allowNewLots", e.target.checked)
    }

    const transportConfigParams = clientToEdit?.config?.transportConfig;

    return (
        <>
            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                <ContractHeader
                    label={'Transports'}
                    flag={transportConfigParams?.useTransport}
                    onChange={() => { onConfigChange(configName, "useTransport", !transportConfigParams?.useTransport) }}
                />
            </Authorize>
            {!transportConfigParams?.useTransport && <Authorize profile={permissionProfiles.CLIENT_CONFIG.CONTRACT_OFF_CLIENT}>
                <DisabledContract contractName={'transportation'} />
            </Authorize>}
            <Authorize profile={transportConfigParams?.useTransport ? permissionProfiles.CLIENT_CONFIG.CONTRACT_ON : permissionProfiles.CLIENT_CONFIG.CONTRACT_OFF}>
                <Paper className={classes.card}>
                    <Grid container spacing={1} direction='column'>
                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                            <Grid item>
                                <div className={classes.input}>
                                    <div className={classes.label}>Margin Percent </div>
                                    <div className={classes.inputField}>
                                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                                            <CustomInput
                                                disabled={!transportConfigParams?.useTransport}
                                                id="transporterMargin"
                                                value={transportConfigParams?.transporterMargin}
                                                onChange={(val) => { onConfigChange(configName, "transporterMargin", val) }}
                                                elementType="input"
                                                numberOnly
                                                step="0.1"
                                                icon={<IconPercent />}
                                            />
                                        </Authorize>
                                    </div>
                                </div>
                            </Grid>
                        </Authorize>

                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                            <Grid item>
                                <div className={classes.input}>
                                    <div className={classes.label}>Tech Fee </div>
                                    <div className={classes.inputField}>
                                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                                            <CustomInput
                                                disabled={!transportConfigParams?.useTransport}
                                                id="transportTechFee"
                                                value={transportConfigParams?.transportTechFee}
                                                onChange={(val) => { onConfigChange(configName, "transportTechFee", val) }}
                                                elementType="input"
                                                numberOnly
                                                step="1"
                                                icon={<IconPercent />}
                                            />
                                        </Authorize>
                                    </div>
                                </div>
                            </Grid>
                        </Authorize>

                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                            <Grid item>
                                {
                                    clientToEdit?.config?.transportConfig?.allowNewLots ?
                                        <FormControlLabel
                                            control={
                                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CREATE_LOTS}>
                                                    <Switch
                                                        color="primary"
                                                        checked={isChecked}
                                                        onChange={onAlloeNewLots}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        disabled={!transportConfigParams?.useTransport}
                                                    />
                                                </Authorize>
                                            }
                                            label={
                                                <div className={classes.label}>Allow create new lots with loads</div>
                                            }
                                            className={classes.wrapper}
                                        /> :
                                        <FormControlLabel
                                            control={
                                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CREATE_LOTS_OFF}>
                                                    <Switch
                                                        color="primary"
                                                        checked={isChecked}
                                                        onChange={onAlloeNewLots}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        disabled={!transportConfigParams?.useTransport}
                                                    />
                                                </Authorize>
                                            }
                                            label={
                                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CREATE_LOTS_OFF}>
                                                    <div className={classes.label}>Allow create new lots with loads</div>
                                                </Authorize>
                                            }
                                            className={classes.wrapper}
                                        />
                                }
                            </Grid>
                        </Authorize>


                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                                        <Switch
                                            color="primary"
                                            checked={transportConfigParams?.handOffWeek}
                                            onChange={(e) => { onConfigChange(configName, "handOffWeek", e.target.checked) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={!transportConfigParams?.useTransport}
                                        />
                                    </Authorize>
                                }
                                label={
                                    <div className={classes.label}>Hand Off Week</div>
                                }
                                className={classes.wrapper}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                                        <Switch
                                            color="primary"
                                            checked={transportConfigParams?.needByWeek}
                                            onChange={(e) => { onConfigChange(configName, "needByWeek", e.target.checked) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={!transportConfigParams?.useTransport}
                                        />
                                    </Authorize>
                                }
                                label={
                                    <div className={classes.label}>Need By Week</div>
                                }
                                className={classes.wrapper}
                            />
                        </Grid>
                        <Grid item>
                            <div className={classes.input}>
                                <div className={classes.label}>Asset status upon delivery</div>
                                <div className={classes.inputField}>
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.ASSET_STATUS_DELIVERED_LOAD} readonlyComponent={
                                        <div className={classes.deliveredStatusReadonly}>
                                            {
                                                transportConfigParams?.deliveredStatus ?
                                                    <VehicleStatusView
                                                        clientId={clientToEdit.id}
                                                        value={transportConfigParams?.deliveredStatus}
                                                    />
                                                    : 'Not defined'
                                            }
                                        </div>
                                    }>
                                        <VehicleStatusView
                                            clientId={clientToEdit.id}
                                            label={null}
                                            value={transportConfigParams?.deliveredStatus}
                                            onChange={(e) => onConfigChange(configName, "deliveredStatus", e == '' ? 0 : parseInt(e))}
                                            readonly={false}
                                            disabled={!transportConfigParams?.useTransport}
                                        />
                                    </Authorize>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Authorize>
            <Authorize profile={transportConfigParams?.useTransport ? permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT : permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT_INTERNAL}>

                <MovementTypes transportConfigParams={transportConfigParams} movementTypes={clientToEdit?.loadMoveTypes} onConfigChange={onConfigChange} />

                <ActiveTransportersList
                    transportConfigParams={transportConfigParams}
                    activeTransporters={clientToEdit?.clientTransporters}
                    onConfigChange={onConfigChange}
                />

            </Authorize>

            <BusinessUnits clientToEdit={clientToEdit} />
        </>
    )
}

export default TransportsConfig;
