import { ListItem, Paper } from "@material-ui/core";
import UserTaskItem from "./UserTaskItem";
import UsersTasksStyle from "./UsersTasksStyle";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetUserTaskConfigsQuery } from "./userTaskConfigSlice";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { IconClipboard } from "icons";

const useStyles = UsersTasksStyle;

const UserTasksList = ({ userId }) => {
    const classes = useStyles();

    let { data: userTasks, error: userTasksError, isLoading: isLoadingUserTasks } = useGetUserTaskConfigsQuery(userId, { skip: !userId })
    userTasks = userTasks?.data || [];


    return (
        <>
            <LoadingSpinner loading={isLoadingUserTasks} />
            <div>
                {userTasks?.map((item, index) => {
                    return <div key={item.id}>
                        <ListItem>
                            <UserTaskItem task={item} userId={userId} />
                        </ListItem>
                    </div>
                })}
                {!isLoadingUserTasks && !userTasks?.length && <EmptyPlaceholder text="There are no available tasks for this user" />}
            </div>
        </>
    );
}
export default UserTasksList;