import * as React from "react";

function SvgIconTicket(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.163 4.488a.937.937 0 0 0-1.325 0l-12.35 12.35a.938.938 0 0 0 0 1.325l1.336 1.335a3.438 3.438 0 0 1 4.677 4.677l1.337 1.338a.936.936 0 0 0 1.326 0l12.349-12.35a.937.937 0 0 0 0-1.324L24.175 10.5a3.437 3.437 0 0 1-4.677-4.677l-1.335-1.335zm-2.65-1.325a2.813 2.813 0 0 1 3.977 0l1.464 1.465c.639.637.525 1.533.202 2.075a1.563 1.563 0 0 0 2.142 2.14c.54-.32 1.437-.435 2.075.203l1.465 1.467a2.812 2.812 0 0 1 0 3.977L14.49 26.838a2.81 2.81 0 0 1-3.977 0l-1.465-1.464c-.638-.638-.525-1.535-.203-2.075a1.563 1.563 0 0 0-2.141-2.141c-.541.32-1.438.435-2.075-.204l-1.466-1.465a2.812 2.812 0 0 1 0-3.978l12.35-12.348z"
                fill="#0090FE"
            />
        </svg>
    );
}

export default SvgIconTicket;
