import * as React from "react";

function SvgIconToggle(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 34 20" fill="none" stroke="#0090FE" strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" width="32" height="20" rx="10" fill="none" />
            <rect x="4" y="3" width="14" height="14" rx="8" fill="none" stroke="#0090FE"/>
        </svg>
    );
}

export default SvgIconToggle;
