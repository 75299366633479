import { Button, Dialog, Grid } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import LotScheduleGroups from "features/lots/components/LotDialog/Schedule/LotScheduleGroups";
import LotSchedule from "features/lots/components/LotSummary/LotDispatchDetails/components/LotSchedule";
import { useState } from 'react';
import LocationFieldStyle from './LocationFieldStyle';

const useStyles = LocationFieldStyle;
const LocationSchedule = ({ schedules, lotToEdit, newLot, anchorEl, setAnchorEl, onValueChange }) => {

    const classes = useStyles();
    const [localSchedules, setLocalSchedules] = useState(schedules || []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDone = () => {
        handleClose();
        onValueChange("Schedule", localSchedules);
    }

    return <>
        <Dialog
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            maxWidth={'lg'}
            fullWidth={localSchedules?.length}
        >
            <div className={classes.scheduleContainer} >
                {newLot ?
                    <Grid container >
                        <Grid item xs={12}>
                            <LotScheduleGroups
                                schedules={localSchedules}
                                setSchedules={setLocalSchedules}
                                lotToEdit={lotToEdit}
                                transports
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.scheduleGroupsContainer}>
                            {localSchedules?.length ?
                                <Button variant="outlined"
                                    color="primary"
                                    onClick={handleDone}
                                    startIcon={<DoneIcon />}
                                >
                                    Done
                                </Button>
                                : <></>}
                        </Grid>
                    </ Grid>
                    :
                    <LotSchedule lot={lotToEdit} />
                }
            </div>
        </Dialog>
    </>
};

export default LocationSchedule;