import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';
import formStyles from "../formStyles";
import { useGetImportAssetsTemplateMutation } from '../vehicleSlice';

const useStyles = formStyles;

function ImportAssetsTemplate({ open, setOpen }) {
    const classes = useStyles();

    let columns = [
        { name: "Domicile Lot", key: "DomicileLot" },
        { name: "Year", key: "Year" },
        { name: "Make", key: "Make" },
        { name: "Model", key: "Model" },
        { name: "Vehicle Type", key: "VehicleType" },
        { name: "Mileage", key: "Mileage" },
        { name: "Status", key: "Status" },
        { name: "Registration", key: "Registration" },
        { name: "License Plate", key: "LicensePlate" },
        { name: "Keys handed over", key: "KeysHandedOver" },
        { name: "Fobs handed over", key: "FobsHandedOver" },
        { name: "Other Dash Light", key: "OtherDashLight" },
        { name: "AC Working", key: "ACWorking" },
        { name: "Heat Working", key: "HeatWorking" },
        { name: "Radio Working", key: "RadioWorking" },
        { name: "Low Tire Pressure", key: "LowTirePressure" },
        { name: "Tire Tread Reading", key: "TireTreadReading" },
        { name: "Check Spare", key: "CheckSpare" },
        { name: "Brake Noise", key: "BrakeNoise" },
        { name: "Body Damage", key: "BodyDamage" },
        { name: "MISC", key: "Note" }
    ];

    const [importTemplate, { data: importAssetsTemplate, error: templateError, isLoading: templateLoading }] = useGetImportAssetsTemplateMutation();
    const [checked, setChecked] = useState([...columns]);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSelectFeild = (index, value) => {
        if (value) {
            setChecked(
                columns.map((column, i) => (i == index || isChecked(checked, column.name)) ? column : null)
                    .filter(column => column)
            );
        }
        else {
            setChecked(
                checked.filter(c => c ? c.name != columns[index].name : false)
            );
        }
    }

    const handleClose = async () => {
        const selectedColumns = {};

        for (const column of columns) {
            selectedColumns[column.key] = checked.some(item => item.key === column.key);
        }

        await importTemplate({ columnsToInclude: checked.map(c => c.key) });

        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="import-dialog-title">{'Select fields to include in the template'}</DialogTitle>
            <DialogContent className={classes.content}>
                {<>
                    <div className={classes.checkboxList}>
                        {columns.map((column, index) => (
                            <>
                                <FormControlLabel
                                    className={classes.checkboxLabel}
                                    control={<Checkbox defaultChecked={isChecked(checked, column.name)} onChange={(e, value) => onSelectFeild(index, value)} />}
                                    label={column.name}
                                />
                            </>
                        ))}
                    </div>
                </>}
            </DialogContent>
            <DialogActions>
                {<Button
                    onClick={handleClose}
                    color="primary"
                    variant='contained'
                    disabled={templateLoading}>
                    Download
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

export default ImportAssetsTemplate;
