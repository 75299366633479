import { makeStyles } from "@material-ui/core";

export const ConfigureLotStyle = makeStyles((theme) => ({
    lotRankingWrapper: {
        padding: '0.5rem 2rem',
        width: 200,
        height: 200,
    },
    name: {
        color: theme.palette.primary.main,
        fontSize: 11,
        fontWeight: 550,
        paddingBottom: '5px'
    },
    value: {
        fontWeight: '700',
        fontSize: 14,
        color: theme.palette.primary.dark,

    }

}));