import { Grid } from "@material-ui/core";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DataSearchBar from "components/DataSearchBar";
import CreateRoleDialog from "./CreateRoleDialog";
import roleManagementStyles from "./roleManagementStyles";

const useStyles = roleManagementStyles;
const RoleManagementHeader = ({title, setSearchQuery, searchQuery, refetchRolesWithData, roles}) =>{
    const classes = useStyles();
    

    return(<Grid container direction="column">
            <Grid item xs={12} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <div className={classes.roleHeader}>
                        <div>
                            <span className={classes.headerTitle}>
                                <ManageAccountsIcon className={classes.manageIcon}/>
                                {title}
                            </span>
                        </div>
                        <div>
                            <CreateRoleDialog allRoles={roles} refetchRolesWithData={refetchRolesWithData}/>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}  className={classes.dataSearchContainer}>
                <DataSearchBar
                    searchButton={false}
                    onSearch={setSearchQuery}
                    searchVal={searchQuery}
                />
            </Grid>
        </Grid>);
}
export default RoleManagementHeader;