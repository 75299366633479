import React from 'react';
import { loadingSpinnerStyle } from "./LoadingSpinnerStyle"
import { CircularProgress } from '@material-ui/core';

const LoadingSpinner = ({ loading }) => {
    const classes = loadingSpinnerStyle();

    const isLoading = () => loading;

    return (
        isLoading()
            ? <CircularProgress className={classes.spinner} />
            : <></>

    );
}

export default LoadingSpinner;