import { Grid } from "@material-ui/core";
import ExportButton from "components/ExportButton/ExportButton";
import { IssueTrackerDashboardStyle } from "../style";

const GraphHeader = ({ title, rightContent, mapExportData, data }) => {
    const classes = IssueTrackerDashboardStyle();

    return (
        <Grid container justifyContent='space-between'>
            <Grid item>
                <h3 className={classes.graphHeader}>
                    {title}
                </h3>
            </Grid>
            {!!mapExportData && <Grid item> <ExportButton label='Export' mapExportData={mapExportData} data={data} /></Grid>}
            {rightContent && <Grid item>{rightContent}</Grid>}
        </Grid>
    )
}
export default GraphHeader;