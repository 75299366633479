import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useEffect } from "react";
import { UserTaskItemStyle } from "../style";

const CommentsInput = ({ task, onChange, setValid, label = 'Comments' }) => {
    const classes = UserTaskItemStyle();

    useEffect(() => {
        setValid(task?.comments?.length > 0)
    }, [task?.comments])

    return (
        <>
            <div className={classes.dropdownWrapper}>
                <div className={classes.commentsLabel}>{label}</div>
                <RichTextEditor
                    onChange={(e) => onChange('comments', e)}
                    content={task?.comments}
                />
            </div>
        </>
    )
}
export default CommentsInput;