
import AddIcon from '@material-ui/icons/Add';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { lotMechanicalConfigStyles } from './components/LotMechanicalConfig/style';
import Authorize from 'components/Authorize';
import { Button, Grid } from '@material-ui/core';
import Rule from './components/LotMechanicalConfig/Rule';

const AddRule = ({ clientId, lotConfig, clientLots, mechanicalsRulesToEdit, setMechanicalsRulesToEdit, selectLot}) => {
    const classes = lotMechanicalConfigStyles();

    const onAdd = () => {
        setMechanicalsRulesToEdit([
            {
                clientId: clientId,
                lotId: lotConfig?.lotId,
                isNew: true,
                appliesTo: "specific-lots"
            },
            ...mechanicalsRulesToEdit
        ])
    }

    const onCancel = () => {
        setMechanicalsRulesToEdit([...mechanicalsRulesToEdit?.filter(l => !l?.isNew)])
    }

    return (
        <>
            <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.SAVE_CONFIG}>
                <div className={classes.addButton}>
                <Button
                    id="basic-button"
                    color="primary"
                    variant="outlined"
                    onClick={() => onAdd()}
                    startIcon={<AddIcon />}
                    disabled={mechanicalsRulesToEdit?.find(a => a.isNew)}
                >
                    {'Add Rule'}
                </Button>
                </div>
            </Authorize>
            <Grid item>
                {selectLot &&
                    mechanicalsRulesToEdit?.map(rule => <Rule key={rule.id} rule={rule} onCancel={onCancel} clientLots={clientLots} selectLot={selectLot} />)
                }
            </Grid>
        </>
        //rule
    )
}

export default AddRule;