import { makeStyles } from "@material-ui/core"

export const loadDetailsStyle = makeStyles((theme) => ({
    container: {
        //padding: '2em',
    },
    assetsCard: {
        marginBottom: '2em'
    },
    card: {
        padding: '1em',
    },
    longCard: {
        height: 'calc(100% )',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
    },
    boxTitle: {
        marginBottom: '1em'
    },
    footer: {
        "&>div:not(:last-child)": {
            marginRight: '1em'
        }
    }
}));
