export const washProgramIntervalTypes = [
    { id: 1, name: "Weeks", textual: 'Week' },
    { id: 2, name: "Months", textual: 'Month' },
    { id: 3, name: "Quarters", textual: 'Quarter' },
    { id: 4, name: "Per Stay", textual: 'Stay' }
];
export const washProgramRateTypes =
    [{ id: 1, name: 'Flat Rate' },
    { id: 2, name: 'Margin' }]

export const washIntervalTypesMap = {
    WEEKLY: 1,
    MONTHLY: 2,
    QUARTERLY: 3,
    STAY: 4
};

export const washRateTypes = {
    FLAT_RATE: 1,
    MARGIN: 2,
};