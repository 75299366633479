import { makeStyles } from '@material-ui/core/styles';

export const EditUserTaskStyle = makeStyles((theme) => ({
    taskWrapper: {
        padding: '2em',
        minHeight: '80vh'
    },
    title: {
        fontSize: '1.3em',
        marginBottom: '1em',
        color: theme.palette.primary.main,
        fontWeight: '400',
        display: 'flex',
        flexDirection: 'row',

    },
    icon: {
        marginRight: '0.5em',
    },
    subTitle: {
        fontSize: '1.1em',
        fontWeight: '500',
        marginBottom: '1em',
        background: theme.palette.background.default,//'#d4ecfd',
        padding: '1em 1.5em',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '1px 2px 5px #dddddd'
    },
    taskTitle: {
        fontSize: '0.9em',
        marginBottom: '0.5em',
        color: theme.palette.primary.main,
        fontWeight: '300'
    }
}));
