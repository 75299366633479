import { Button, CircularProgress, Grid, Paper, Tooltip } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import ActivityInvoiceDetails from "./ActivityInvoiceDetails";
import { activityInvoiceStyles } from "./style";
import { ActivityInvoiceStatus, activityHeaderColumns } from "../../activityInvoiceConsts";
import WarningIcon from '@material-ui/icons/Warning';
import { useSendActivityInvoiceToQBMutation } from "../../activityInvoiceSlice";
import NotificationProvider from "components/NotificationProvider";
import usePrompt from "hooks/usePrompt";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";


const ActivityInvoice = ({ invoice }) => {
    const classes = activityInvoiceStyles();
    const { triggerPrompt } = usePrompt();

    const [expanded, setExpanded] = useState(false);
    const [sendActivityInvoiceToQB, { isLoading: isSending }] = useSendActivityInvoiceToQBMutation();

    const columnStyling = {
        'lot': 'lotCol'
    }

    const sendInvoiceToQB = async () => {
        triggerPrompt({
            title: "Send Invoice to QB",
            content: <div>
                Are you sure you want to send this invoice to QuickBooks?
            </div>,
            onConfirm: async () => {
                let res = await sendActivityInvoiceToQB({ activityInvoiceId: invoice.id });
                if (res?.data?.[0]?.data) {
                    NotificationProvider.success('The invoice was sent to QB successfully');
                } else {
                    NotificationProvider.error('Failed to send the invoice to QB');
                }
            },
            onCancel: async () => {
            },
        });

    }

    return (
        <Paper className={classes.wrapper}>

            <Grid container spacing={1} alignItems='center' justifyContent="flex-end" >
                {activityHeaderColumns?.map((col, index) =>
                    <Grid className={`${classes.column} ${classes[columnStyling[col.key]]}`} xs={col.size} item key={`${invoice.id}_col.key`}>
                        {col?.value ? col.value(invoice) : invoice?.[col.key]}
                    </Grid>
                )}

                <Grid className={classes.column} xs={1} item container key={`col_actions`} justifyContent='flex-end'>
                    {invoice.status === ActivityInvoiceStatus.FAILED &&
                        <>
                            <Tooltip title="We failed to send this invoice to QuickBooks. This might be due to a missing mapping in Parq. Please try again or contact support.">
                                <div className={classes.warning}><WarningIcon /></div>
                            </Tooltip>
                        </>
                    }
                </Grid>
                <Grid className={classes.column} xs={1} item container key={`col_actions`} justifyContent='flex-end'>
                    {invoice.status === ActivityInvoiceStatus.FAILED &&
                        <>
                            <Authorize profile={permissionProfiles.INVOICE.INVOICES_MANAGE}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.sendBtn}
                                    disabled={isSending}
                                    onClick={sendInvoiceToQB}>
                                    {isSending && <CircularProgress size={'1em'} style={{ marginRight: '0.1rem' }} />}
                                    {`Send to QB `}
                                </Button>
                            </Authorize>
                        </>
                    }
                </Grid>
                <Grid className={classes.column} xs={1} item container key={`col_actions`} justifyContent='flex-end'>
                    <Button
                        color='primary'
                        onClick={() => setExpanded(!expanded)}
                        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {expanded ? 'Less' : 'More'}
                    </Button>
                </Grid>
            </Grid>
            {invoice?.activityInvoiceDetailsGrouped?.map((col, index) =>
                <ActivityInvoiceDetails invoiceDetail={col} expanded={expanded} canEdit={invoice.status == ActivityInvoiceStatus.PENDING || invoice.status == null} />
            )}
        </Paper>
    )
}

export default ActivityInvoice;