import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import CustomInput from 'components/CustomInput';
import DropdownFilter from 'components/DropdownFilter';
import NotificationProvider from 'components/NotificationProvider';
import usePrompt from 'hooks/usePrompt';
import { useState } from 'react';
import { useOpenLotGateMutation, useSaveDoorReleaseNoteMutation } from '../DoorRelease/doorReleaseSlice';
import { useGetLotsWithAccessQuery } from '../accessSlice';

const OpenGate = ({ }) => {

    let [openLotGate, { isLoading: opening }] = useOpenLotGateMutation();
    let [saveNote, { isLoading: isSavingNote }] = useSaveDoorReleaseNoteMutation();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsWithAccessQuery();

    const { triggerPrompt } = usePrompt();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [openNoteDialog, setOpenNoteDialog] = useState(false);
    const [note, setNote] = useState(null);
    const [doorReleaseId, setDoorReleaseId] = useState(null);
    const [lotId, setLotId] = useState();

    const onOpenGate = () => {
        const lot = lots?.find(l => l.id == lotId);
        triggerPrompt({
            title: "Open Gate",
            content: `Are you sure you want to open the gate at lot ${lot?.name}`,
            onConfirm: async () => {
                let result = await openLotGate({ lotId: lot?.id });
                if (result && !result.error) {
                    NotificationProvider.success("Successfully opened gate");
                    setDoorReleaseId(result?.data);
                    setOpenNoteDialog(true);
                } else {
                    NotificationProvider.error("Failed to open gate");
                }
            }
        });
    }

    const handleSaveNote = async () => {
        let result = await saveNote({ doorReleaseId, note });
        if (result && !result.error) {
            NotificationProvider.success("Successfully saved note");
            setOpenNoteDialog(true);
        } else {
            NotificationProvider.error("Failed to save note");
        }
        setOpenNoteDialog(false);
        setNote(null);
        setDoorReleaseId(null);
        setDialogOpen(false);
    };

    return (
        <>
            <Authorize profile={permissionProfiles.LOT.LOT_OPEN_GATE}>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<LockOpenIcon />}
                    onClick={() => setDialogOpen(true)}
                >
                    Unlock Gate
                </Button>
            </Authorize>
            <Dialog
                open={dialogOpen}
                onClose={() => { }}
                fullWidth
                maxWidth='sm'>
                <DialogTitle>Unlock Lot Gate</DialogTitle>
                <DialogContent>
                    <div>
                        <DropdownFilter
                            values={lots}
                            onSelect={(val) => setLotId(val)}
                            title='Select Lot'
                            showEmpty
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary"
                        variant="contained"
                        onClick={onOpenGate}
                        disabled={opening || !lotId}
                    >
                        {opening ? 'Sending...' : 'Unlock'}
                    </Button>
                </DialogActions>
            </Dialog >

            <Dialog
                open={openNoteDialog}
                fullWidth
                maxWidth='md'>
                <DialogTitle>Add Note</DialogTitle>
                <DialogContent>
                    <CustomInput
                        id="note"
                        label="Please Add Note"
                        value={note}
                        elementType="textarea"
                        onChange={setNote}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                        variant="contained"
                        onClick={handleSaveNote}
                        disabled={!note || isSavingNote}
                    >
                        {isSavingNote ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog >

        </>
    );
}

export default OpenGate;