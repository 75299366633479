
function SvgIconCheck(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.2264 14.1236L10.58 14.4771L10.9336 14.1236L16.0736 8.98355C16.1683 8.88882 16.3317 8.88882 16.4264 8.98355C16.5212 9.07829 16.5212 9.24171 16.4264 9.33645L10.7564 15.0064C10.7091 15.0537 10.6459 15.08 10.58 15.08C10.5141 15.08 10.4509 15.0537 10.4036 15.0064L7.57355 12.1764C7.47882 12.0817 7.47882 11.9183 7.57355 11.8236C7.66829 11.7288 7.83171 11.7288 7.92645 11.8236L10.2264 14.1236ZM12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z" fill="white" stroke="white" />
    </svg>
  );
}

export default SvgIconCheck;
