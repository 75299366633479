import * as React from "react";

function SvgIconSubtitle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M18.61 6.17995C18.4761 6.17995 18.36 6.0638 18.36 5.92995V4.41995C18.36 3.54676 17.6491 2.81995 16.76 2.81995H3.82999C2.95681 2.81995 2.22999 3.53085 2.22999 4.41995V5.92995C2.22999 6.0638 2.11385 6.17995 1.97999 6.17995C1.85482 6.17995 1.73999 6.07257 1.73999 5.92995V4.41995C1.73999 3.26609 2.68613 2.31995 3.83999 2.31995H16.76C17.9151 2.31995 18.86 3.25734 18.86 4.41995V5.92995C18.86 6.0638 18.7438 6.17995 18.61 6.17995Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M10.3 18.3499C10.1661 18.3499 10.05 18.2338 10.05 18.0999V3.31995C10.05 3.18609 10.1661 3.06995 10.3 3.06995C10.4338 3.06995 10.55 3.18609 10.55 3.31995V18.0999C10.55 18.2395 10.4382 18.3499 10.3 18.3499Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M12.48 18.35H6.90002C6.76617 18.35 6.65002 18.2338 6.65002 18.1C6.65002 17.9661 6.76617 17.85 6.90002 17.85H12.48C12.6139 17.85 12.73 17.9661 12.73 18.1C12.73 18.2338 12.6139 18.35 12.48 18.35Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M22.01 12.7C21.8761 12.7 21.76 12.5838 21.76 12.45V11.65C21.76 11.0638 21.2761 10.58 20.69 10.58H13.68C13.5461 10.58 13.43 10.4638 13.43 10.33C13.43 10.1961 13.5461 10.08 13.68 10.08H20.69C21.5539 10.08 22.26 10.7861 22.26 11.65V12.45C22.26 12.5938 22.1539 12.7 22.01 12.7Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M16.08 21.68C15.9462 21.68 15.83 21.5639 15.83 21.43V10.87C15.83 10.7361 15.9462 10.62 16.08 10.62C16.2139 10.62 16.33 10.7361 16.33 10.87V21.43C16.33 21.5639 16.2139 21.68 16.08 21.68Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M18.22 21.6801H13.94C13.8061 21.6801 13.69 21.5639 13.69 21.4301C13.69 21.2962 13.8061 21.1801 13.94 21.1801H18.22C18.3539 21.1801 18.47 21.2962 18.47 21.4301C18.47 21.5639 18.3539 21.6801 18.22 21.6801Z" fill="#0090FE" stroke="#0090FE" />
    </svg>
  );
}

export default SvgIconSubtitle;
