import { IconProfile } from "../../../../icons";
import UsersTable from "../UsersTable";
import { DialogContent, IconButton } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import { Dialog } from "@mui/material";
import { useState } from "react";


const InactiveUserListDialog = ({user, roles, clients, lots, permission, refetchUsers}) => {

    const [showList, setShowList] = useState(false);
    return (
        <>
            <IconButton size="small" onClick={() => setShowList(true)}><ListIcon/></IconButton>
            <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                <DialogContent>
                    <UsersTable
                        permission={permission}
                        users={user}
                        clients={clients}
                        lots={lots}
                        roles={roles}
                        searchVal={""}
                        refetchUsers={refetchUsers}
                        title={<><IconProfile/> Inactive Users</>}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default InactiveUserListDialog;