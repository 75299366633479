import { makeStyles } from "@material-ui/core";

export const lotMechanicalConfigStyles = makeStyles(theme => ({
    lotName: {
        fontWeight: 'bold',
        padding: '1em 0',
        fontSize: '1.2em'
    },
    ruleWrapper: {
        padding: '2em'
    },
    iconsWrapper: {
        alignItems: "center",
        display: 'flex',
        justifyContent: "flex-end"
    },
    iconWrapper: {
        backgroundColor: theme.palette.primary.light,
        height: '50px',
        width: '40px',
        borderRadius: '2px',
        '& fieldset': {
            border: 'none'
        },
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
        padding: 7
    },
    titleWrapper: {
        marginBottom: '1em',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    // ruleOperand: {
    //     '&:last-of-type': {
    //         backgroundColor: 'red',
    //         visibility: "hidden",
    //         opacity: 0
    //     },
    // },
    params: {
        marginBottom: '1em',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: theme.palette.common.white,
        paddingTop: '1rem'
    },
    button: {
        margin: "5px",
    },
    listItemWrapper: {
        margin: '1em 0',
        border: '1px solid transparent',
        "&:hover": {
            "& $lotActions": {
                opacity: 1,
            }
        },
    },
    paramWrapper: {
        padding: '2em'
    },
    listItemWrapperNew: {
        borderColor: '#90C534',
        position: 'relative',
        "&::before": {
            content: "'NEW'",
            color: 'white',
            background: '#90C534',
            position: "absolute",
            fontSize: '0.8em',
            padding: '1px 5px',
            top: '-0.2em',
            left: "1em",
            borderRadius: '4px',
            transform: "translate(0px, -50%)",
        },
    },
    lotContainer: {
        padding: '1em'
    },
    lotMultiSelect: {
        width: '400px',
        flexWrap: 'wrap',
        display: 'flex',
        marginBottom: '1em'
    },
    addButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        padding: '0em'
    }
}));