import { Button, ButtonGroup, Checkbox, Divider, FormControlLabel, Grid, TextField } from '@material-ui/core';
import Popover from '@mui/material/Popover';
import _ from 'lodash';
import moment from 'moment';
import React, { forwardRef, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import CustomInput from '../../../../../components/CustomInput';
import DateTimeComponent from '../../../../../components/DateTimeSchedule/DateScheduleComponent';
import LotsDropdown from '../../../../../components/SharedDropdowns/LotsDropDown/LotsDropDown';
import { usStates } from '../../../../../services/usStates';
import { useGetLotsQuery } from '../../../../lots/lotSlice';
import LocationFieldStyle from './LocationFieldStyle';

const useStyles = LocationFieldStyle;

const LocationField_single = ({ clientId, allowNewLots, name, title, onChange, newLoad, setNewLoad, errors, loadToEdit, newLot, setNewLot, showMandatoryError, onChangeLocation = () => { } }) => {

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();

    const classes = useStyles();

    const onValueChange = (key, val) => {
        onChange(name.concat(key), val)
    }

    const onLotTypeChange = (newLot) => {
        setNewLot(newLot);
    }

    const [lotAddress, setLotAddress] = useState();
    const [lotSchedule, setLotSchedule] = useState();

    const onLotChange = (val) => {
        let lot = lots.find(l => l.id == val)
        let address = `Address: ${lot?.addressLine1} ${lot?.addressLine2 || ""}, City: ${lot?.city || ""}, State: ${lot?.state || ""}, Zip: ${lot?.zipCode || ""}, Phone: ${lot?.phoneNumber || ""}`
        const groupedSchedule = _.groupBy(lot?.schedule, 'dayOfWeekId');
        setLotAddress(address);
        setLotSchedule(groupedSchedule);
        onChangeLocation();
        setNewLoad({ ...newLoad, [`${name}LocationId`]: val, [`${name}Name`]: null, [`pickupStartDate`]: null, [`deliveryStartDate`]: null });
    }

    // Lot start here
    const [pickupSchedules, setPickupSchedules] = React.useState([]);
    const [deliverySchedules, setDeliverySchedules] = React.useState([]);
    const [isTimeAvailable, setTimeAvailable] = React.useState(true);
    const [isTimeAvailableBetweenDays, setIsTimeAvailableBetweenDays] = React.useState(true);

    let data = {
        dayOfWeekId: 1,
        openTime: "00:00:00",
        closeTime: "01:00:00",
        createdDate: new Date(),
        lotId: 0,
        id: 0,
        deletedDate: null
    };

    let daysOfWeekList = [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thursday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" }
    ];

    const handleCallback = (childData, key, name) => {
        if (name === 'pickup') // Refactor
        {
            const newSchedule = [...pickupSchedules];

            let validateResult = validateTime(childData, key, name);
            if (validateResult) {
                newSchedule[key].dayOfWeekId = parseInt(childData.dayOfWeekId);
                newSchedule[key].openTime = childData.openTime;
                newSchedule[key].closeTime = childData.closeTime;
            }

            setPickupSchedules(newSchedule);
            setNewLoad({ ...newLoad, PickupSchedule: newSchedule });
        }
        else {
            const newSchedule = [...deliverySchedules];
            let validateResult = validateTime(childData, key);
            if (validateResult) {
                newSchedule[key].dayOfWeekId = parseInt(childData.dayOfWeekId);
                newSchedule[key].openTime = childData.openTime;
                newSchedule[key].closeTime = childData.closeTime;
            }

            setDeliverySchedules(newSchedule);
            setNewLoad({ ...newLoad, DeliverySchedule: newSchedule });
        }
    }

    const removeHandleCallback = (key, name) => {
        if (name === 'pickup') // Refactor
        {
            const newSchedules = [...pickupSchedules];
            let sCopy = { ...newSchedules[key] };
            sCopy.deletedDate = new Date();
            newSchedules[key] = sCopy;
            setTimeAvailable(true);
            setIsTimeAvailableBetweenDays(true);
            setPickupSchedules(newSchedules);
            setNewLoad({ ...newLoad, PickupSchedule: newSchedules });
        }
        else {
            const newSchedules = [...deliverySchedules];
            let sCopy = { ...newSchedules[key] };
            sCopy.deletedDate = new Date();
            newSchedules[key] = sCopy;
            setTimeAvailable(true);
            setIsTimeAvailableBetweenDays(true);
            setDeliverySchedules(newSchedules);
            setNewLoad({ ...newLoad, DeliverySchedule: newSchedules });
        }
    }

    const addSchedule = () => {
        if (name === 'pickup') // Refactor
        {
            const newObj = [...pickupSchedules, data];
            setPickupSchedules(newObj);
            setNewLoad({ ...newLoad, PickupSchedule: newObj });
        }
        else {
            const newObj = [...deliverySchedules, data];
            setDeliverySchedules(newObj);
            setNewLoad({ ...newLoad, DeliverySchedule: newObj });
        }
    }

    const validateTime = (currentSchedule, key, name) => {
        setTimeAvailable(true);
        if (getTimeFormated(currentSchedule.openTime) >= getTimeFormated(currentSchedule.closeTime)) {
            setTimeAvailable(false);
            return false;
        }

        let result = true;
        setIsTimeAvailableBetweenDays(true);
        [...pickupSchedules, ...deliverySchedules].map((item, index) => {
            if (index !== key && parseInt(item.dayOfWeekId) == parseInt(currentSchedule.dayOfWeekId) && item.deletedDate === null) {
                if ((getTimeFormated(currentSchedule.openTime) >= getTimeFormated(item.openTime) &&
                    getTimeFormated(currentSchedule.openTime) <= getTimeFormated(item.closeTime)) ||
                    (getTimeFormated(currentSchedule.closeTime) >= getTimeFormated(item.openTime) &&
                        getTimeFormated(currentSchedule.closeTime) <= getTimeFormated(item.closeTime))) {
                    setIsTimeAvailableBetweenDays(false);
                    result = false;
                }
            }
        })

        return result;
    }

    function TimeMessage() {
        return isTimeAvailable ? <span></span> : <span>Please validate hours. Open bigger than close time</span>
    }

    function SecondTimeInOtherDayRangeTimeMessage() {
        return isTimeAvailableBetweenDays ? <span></span> : <span>Please validate hours. Times are overlaping with other rows with the same day.</span>
    }
    // Lot End here

    const [existingLots, setExistingLots] = useState();

    const onZipChange = (val) => {
        let existingLots = lots.filter(l => l.zipCode == val)
        setExistingLots(existingLots)
        onValueChange("Zip", val)
    }

    const onExistingLotSelect = (lotId) => {
        setNewLot(false)
        onLotChange(lotId)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDayOfWeekById = (id) => {
        switch (id) {
            case 1:
                return "Sunday:";
            case 2:
                return "Monday:";
            case 3:
                return "Tuesday:";
            case 4:
                return "Wednesday:";
            case 5:
                return "Thursday:"
            case 6:
                return "Friday:"
            case 7:
                return "Saturday:"
        }
    }

    const getTimeFormated = (time) => {
        let fullTime = moment(time, "H:m:ss");
        return fullTime.format("HH:mm");
    }

    const OpeningHoursPopover = () => {
        return <><Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Grid container className={classes.openingHoursContainer} >
                <Grid item xs={12}>
                    {Object.keys(lotSchedule).length ?
                        [1, 2, 3, 4, 5, 6, 7].map(function (key) {
                            return (<div key={key.toString()}>
                                <div className={classes.openingHours}>
                                    <label>{getDayOfWeekById(key)}</label>
                                    {lotSchedule[key] ? lotSchedule[key].map(
                                        (schedule, index) => (
                                            <div key={index}>{getTimeFormated(schedule.openTime)} - {getTimeFormated(schedule.closeTime)}</div>
                                        )
                                    ) : <div>closed</div>}
                                </div>
                                {key != 7 && <Divider className={classes.openingHoursDivider} />}
                            </div>)
                        })
                        : <div className={classes.openingHours}>
                            <label>Lot is open 24/7</label>
                        </div>
                    }
                </Grid>
            </Grid>
        </Popover>
        </>
    }

    return (
        <div className={classes.lot}>
            <Grid container>
                {!newLot &&
                    <Grid item xs={loadToEdit ? 12 : 8}>
                        <div className={classes.input}>
                            <LotsDropdown
                                clientId={clientId}
                                title={`${title} Location Name`}
                                value={newLoad?.[`${name}LocationId`]}
                                onSelect={val => onLotChange(val)}
                                values={lots}
                                showEmpty
                                required={!newLot}
                                valid={!(showMandatoryError && !newLoad?.[`${name}LocationId`])}
                                touched
                            />
                        </div>
                    </Grid>}
                {newLot &&
                    <Grid item xs={8}>
                        <div className={classes.input}>
                            <CustomInput
                                label={`${title} Location Name`}
                                value={newLoad?.[`${name}Name`]}
                                elementType="input"
                                onChange={(val) => { setNewLoad({ ...newLoad, [`${name}Name`]: val, [`${name}LocationId`]: 0 }); }}
                                values={lots}
                                showEmpty
                                required={newLot}
                                valid={!(showMandatoryError && !newLoad?.[`${name}Name`])}
                                touched
                                clientId={clientId}
                            />
                        </div>
                    </Grid>}
                {!loadToEdit && allowNewLots &&
                    <Grid item xs={4} className={classes.radioContainer}>
                        <div className={classes.input}>
                            <label className={classes.radioLable}>
                                <input className={classes.radioButton} type="radio" checked={!newLot} onChange={() => onLotTypeChange(!newLot)} />
                                Existing Lot
                            </label>
                            <label className={classes.radioLable}>
                                <input className={classes.radioButton} type="radio" checked={newLot} onChange={() => onLotTypeChange(!newLot)} />
                                New Lot
                            </label>
                        </div>
                    </Grid>
                }
            </Grid>

            {
                (!newLot && newLoad?.[`${name}LocationId`]) &&
                <div className={classes.lotAddress}>
                    {lotAddress}
                    {lotAddress && <div>
                        <Button onClick={handleClick} className={classes.openingHoursButton}>
                            Show opening hours
                        </Button>
                        <OpeningHoursPopover />
                    </div>}
                </div>
            }
            {
                newLot &&
                <div>
                    <Grid container>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="Node Name"
                                    value={newLoad?.[`${name}NodeName`]}
                                    elementType="input"
                                    onChange={val => onValueChange("NodeName", val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}NodeName`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="Address"
                                    value={newLoad?.[`${name}Address`]}
                                    elementType="input"
                                    onChange={val => onValueChange("Address", val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}Address`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="City"
                                    value={newLoad?.[`${name}City`]}
                                    elementType="input"
                                    onChange={val => onValueChange("City", val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}City`])}
                                    touched
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="State"
                                    value={newLoad?.[`${name}State`]}
                                    elementType="dropdown"
                                    onChange={val => onValueChange("State", val)}
                                    disabled={!newLot}
                                    values={usStates}
                                    showEmpty
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}State`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="Zip"
                                    value={newLoad?.[`${name}Zip`] ?? ''}
                                    elementType="input"
                                    onChange={val => onZipChange(val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}Zip`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.phoneInput}>
                                <Input
                                    variant="outlined"
                                    defaultCountry="US"
                                    value={newLoad?.[`${name}Phone`]}
                                    defaultValue={newLoad?.[`${name}Phone`]}
                                    onChange={val => onValueChange("Phone", val)}
                                    error={(newLoad?.[`${name}Phone`] && !isValidPhoneNumber(newLoad?.[`${name}Phone`])) || (showMandatoryError && !newLoad?.[`${name}Phone`])}
                                    label="POC Phone"
                                    disabled={!newLot}
                                    required={newLot}
                                    fullWidth
                                    InputLabelProps={{ className: classes.label }}
                                    inputComponent={phoneInput}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
            {newLot && <Grid container>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            label="POC Name"
                            value={newLoad?.[`${name}PocName`]}
                            elementType="input"
                            onChange={val => onValueChange("PocName", val)}

                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            label="POC Email"
                            value={newLoad?.[`${name}PocEmail`]}
                            elementType="input"
                            onChange={val => onValueChange("PocEmail", val)}
                            valid={(!errors[`${name}PocEmail`]) && !(showMandatoryError && !newLoad?.[`${name}PocEmail`])}
                            required={newLot}
                            touched

                        />
                    </div>
                </Grid>
                <Grid container xs={12}>
                    <Grid xs={12} className={classes.groupTitle}>
                        Schedule
                        <Grid item xs={6}>
                            <ButtonGroup >
                                <Button onClick={addSchedule} className={classes.buttonSchedule}>
                                    Add Operating Hours
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <div className={classes.inputWrapper}>
                        <TimeMessage />
                        <SecondTimeInOtherDayRangeTimeMessage />
                        {
                            [...pickupSchedules, ...deliverySchedules].map((item, index) => {
                                if (item.deletedDate === null) {
                                    return <DateTimeComponent data={item}
                                        daysOfWeekList={daysOfWeekList}
                                        handleCallback={handleCallback}
                                        key={index}
                                        indexChild={index}
                                        removeHandleCallback={removeHandleCallback}
                                        name={name} />
                                }
                            })
                        }
                    </div>
                </Grid>
            </Grid>}
            {/* <div className={classes.input}>
                      <CustomInput
                          label="Pick-Up POC Phone"
                          value={newLoad?.[`${name}PocPhone`]}
                          elementType="input"
                          onChange={val => onValueChange("PocPhone", val)}
                      />
                  </div> */}
            {
                existingLots?.length > 0 && newLot &&
                <div className={classes.existingLotsContainer}>
                    <div className={classes.existingLotsTitle}>There are lots with the same zip code...</div>
                    {existingLots.map(lot =>
                        <FormControlLabel
                            control={<Checkbox
                                checked={newLoad?.[`${name}LocationId`] === lot.id && !newLot}
                                onChange={() => onExistingLotSelect(lot.id)}
                            />}
                            label={`Name: ${lot?.name}, Address: ${lot?.addressLine1 || ""} ${lot?.addressLine2 || ""} ${lot?.city || ""} ${lot?.state || ""} ${lot?.zipCode || ""}`} />
                    )}
                </div>
            }
        </div >
    );
};

export default LocationField_single;

const phoneInput = forwardRef((props, ref) => {

    return (
        <TextField
            {...props}
            inputRef={ref}
            variant='outlined'
            name='phone'
        />
    )
})

