import { Collapse } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { groupBy } from "lodash";
import { useState } from "react";
import LATaskCard from "../LAsTaskList/LATaskCard/LATaskCard";
import SuperTaskCard from "../SupersTaskList/superTaskCard/superTaskCard";
import { UserTaskGroupStyle } from "./style";


const UserTaskGroup = ({ tasks, groupName, lotId, isSuper }) => {
    const classes = UserTaskGroupStyle();

    const [collapsed, setCollapsed] = useState(false);

    const mappedTasks = tasks?.map(t => ({
        ...t,
        taskDateOnly: new Date(new Date(t.taskDate)?.setHours(0, 0, 0, 0))
    }));
    const groupedTasksByDate = groupBy(mappedTasks, 'taskDateOnly');
    const groupedTasks = Object.keys(groupedTasksByDate).map((key) => ({ date: key, tasks: groupedTasksByDate[key] }));

    return (
        <>
            {tasks && <div className={classes.itemWrapper}>
                {groupName &&
                    <div className={classes.itemHeader} onClick={() => setCollapsed(!collapsed)}>
                        <h3>{groupName}</h3>
                        <KeyboardArrowDownIcon />
                    </div>
                }
                <Collapse in={collapsed} timeout="auto" unmountOnExit>
                    {isSuper ?
                        tasks?.map((task, key) => (
                            <SuperTaskCard key={key} task={task} lotId={lotId} />
                        )) :
                        groupedTasks?.map((group, key) =>
                            <LATaskCard key={key} group={group} lotId={lotId} />
                        )
                    }
                </Collapse>
            </div>
            }
        </>
    );
};

export default UserTaskGroup;
