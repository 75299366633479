import PushUserConfig from "app/notifications/pushUserConfig";
import { TasksPanelStyles } from "features/Tasks/TasksPanelStyles";
import { TaskTypes } from "features/Tasks/taskConsts";
import { orderBy } from "lodash";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TaskList from "../TaskList";
import { useGetUserTasksQuery } from "./userTaskSlice";
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";

const UserTasks = () => {
    const classes = TasksPanelStyles();

    let { data: tasks, error: tasksError, isLoading: isLoadingTasks } = useGetUserTasksQuery();

    const taskData = orderBy(tasks?.map(task => ({
        ...task,
        taskType: TaskTypes.UserTask,
    })), 'createdDate', 'desc');


    return (
        <>
            <PushUserConfig />
            {isLoadingTasks ?
                <LoadingSpinner loading={isLoadingTasks} />
                : <TaskList
                    tasks={taskData}
                    itemHeight={160}
                    placeholder={
                        <EmptyPlaceholder
                            icon={<></>}
                            text={<div className={classes.emptyText}>There are no pending tasks, you are all caught up, good job!</div>}
                        />
                    }
                />}
        </>
    )
}
export default UserTasks;