import { makeStyles } from "@material-ui/core";

export const activityHeaderStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '2em'
    },
    column: {
        marginTop: '2em',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}));