
import { InspectionsGroupsStyles } from "./InspectionsGroupsStyles";
import InspectionsGroup from "../InspectionsGroup/InspectionsGroup";
import moment from "moment";
import { groupBy } from "lodash";
import { useGetAllInspectionsQuery, useGetInspectionsByLotQuery } from "../../inspectionSlice";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";

const InspectionsGroups = ({ inspections, filteredInspections }) => {
    const classes = InspectionsGroupsStyles();

    const groups = {
        'invalid': {
            name: 'Invalid',
            key: 'invalid',
            checkRange: (date) => {
                let today = new Date();
                return moment(date).isAfter(today, 'day'); 
            }
        },
        'today': {
            name: 'Today',
            key: 'today',
            checkRange: (date) => {
                let today = new Date();
                return moment(date).isSame(today, 'day');
            }
        },
        'thisWeek': {
            name: 'This week',
            key: 'thisWeek',
            checkRange: (date) => {
                let today = new Date();
                let thisSunday = new Date(today.setDate(today.getDate() - today.getDay()));
                return moment(date).isSameOrAfter(thisSunday, 'day');
            }
        },
        'lastWeek': {
            name: 'Last week',
            key: 'lastWeek',
            checkRange: (date) => {
                let today = new Date();
                let lastSunday = new Date(today.setDate(today.getDate() - today.getDay() - 7));
                return moment(date).isSameOrAfter(lastSunday, 'day');
            }
        },
        'thisMonth': {
            name: 'This Month',
            key: 'thisMonth',
            checkRange: (date) => {
                let today = new Date();
                let lastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                return moment(date).isSameOrAfter(lastMonth, 'day');
            }
        },
        'lastMonth': {
            name: 'Last Month',
            key: 'lastMonth',
            checkRange: (date) => {
                let today = new Date();
                let lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                return moment(date).isSameOrAfter(lastMonth, 'day');
            }
        }        
    }

    inspections = filteredInspections.map(inspection => {
        for (let index = 0; index < Object.values(groups).length; index++) {
            if (Object.values(groups)[index].checkRange(inspection.inspectionDate)) {
                return { ...inspection, dateGroup: Object.values(groups)[index].key}
            }
            // inspection.dateGroup = "older"
        }
    });
    
    const dataGrouped = groupBy(inspections, 'dateGroup');

    const inspectionsGroups = Object.keys(groups).map((entry, index) => (
        <div>
            <InspectionsGroup inspections={dataGrouped[entry]} name={groups[entry]?.name} />
        </div>
    ))

    return (
        <div>
            {filteredInspections?.length ? inspectionsGroups : <EmptyPlaceholder text='No Inspections Found' />}
        </div>
    );
};

export default InspectionsGroups;
