import { Button, DialogContent, Dialog, DialogTitle, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ConfirmationPopup from '../../../../components/ConfirmationPopup';
import NotificationProvider from '../../../../components/NotificationProvider';
import { useGetTransportersQuery } from '../../../transporter/transporterSlice';
import { useDispatchLoadMutation } from '../../loadsSlice';
import { loadDispatchStyle } from './LoadDispatchStyle';

const selectMenuStyle = {
    backgroundColor: '#f7f6fb',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.38)',

}

function LoadDispatch({ load }) {
    const classes = loadDispatchStyle()
    let { data: transporters, error: transporterError, isLoading: loadingTransporters } = useGetTransportersQuery();
    let [dispatchLoad, { isLoading: isLoadingDispatchLoad, isError, isSuccess }] = useDispatchLoadMutation();


    transporters = transporters?.filter(t => t.usdot)
    const [open, setOpen] = useState(false);

    const [value, setValue] = useState();

    const [openPopup, setOpenPopup] = useState(false);

    const [showError, setShowError] = useState(false);


    const openDialog = async (file) => {
        setOpen(true);
    }
    const closeDialog = async (file) => {
        setOpen(false);
    }

    const dispatch = async (file) => {
        if (value && value.name) {
            setOpenPopup(true)
        }
        else {
            setShowError(true)
        }
    }

    const handleChange = (event) => {
        let trans = transporters.find(t => t.id.toString() === event.target.value)
        setValue({ name: trans.name, id: trans.id });
        if (event.target.value) {
            setShowError(false)
        }

    };

    const onConfirm = async () => {
        const result = await dispatchLoad({ loadId: load?.id, transporterId: value.id, groupId: load?.groupId });
        if (result?.error) {
            NotificationProvider.error(`Failed to tender to carrier ${value.name}`)
        } else {
            NotificationProvider.success(`Successfully tendered to carrier ${value.name}`);
        }
        setOpenPopup(false);
        setOpen(false);
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={openDialog}
            >
                Tender to carrier
            </Button>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Tender load</DialogTitle>
                <DialogContent>
                    {showError &&
                        <div className={classes.error}>Please choose a transporter</div>
                    }
                    <Select native
                        className={classes.select}
                        variant='outlined'
                        value={value?.id}
                        onChange={handleChange}
                    >
                        <option style={selectMenuStyle} aria-label="None" value="" />
                        {transporters?.map((transporter) => (
                            <option style={selectMenuStyle} value={transporter.id} id={transporter.id}>{transporter.name}</option>
                        ))}
                    </Select>
                    <Button
                        className={classes.dispatchButton}
                        variant="contained"
                        color="primary"
                        onClick={dispatch}
                    >
                        Tender
                    </Button>
                </DialogContent>
                <ConfirmationPopup
                    onCancel={() => setOpenPopup(false)}
                    onConfirm={onConfirm}
                    open={openPopup}
                    loading={isLoadingDispatchLoad}
                >
                    <div>{`Are you sure you want to tender to carrier ${value?.name}`}</div>
                </ConfirmationPopup>
            </Dialog>
        </div>
    );
}

export default LoadDispatch;
