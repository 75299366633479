import { Select } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";
import { PCOInvoiceStatusDropdownStyle } from "./PCOInvoiceStatusDropdownStyle";
import NotificationProvider from "components/NotificationProvider";
import { StorageInvoiceStatus, StorageInvoiceStatusesList } from "features/invoice/storage/storageInvoiceConsts";
import { useUpdatePCOInvoiceStatusMutation } from "features/invoice/storage/storageInvoiceSlice.ts";
import usePrompt from "hooks/usePrompt";

const PCOInvoiceStatusDropdown = ({ invoice, filled = true }) => {
    const classes = PCOInvoiceStatusDropdownStyle();
    const { triggerPrompt } = usePrompt();

    let [updateStatus, { isLoading: isLoadingUpdateStatus, isSuccess: updateStatusSucces }] = useUpdatePCOInvoiceStatusMutation();

    const onSave = async (invoice, event, prevStatus) => {
        const result = await updateStatus({
            id: invoice.storageInvoiceId,
            statusId: event.target.value,
        });
        if (result?.error) {
            invoice.statusId = prevStatus;
            NotificationProvider.error(`Failed to update status`)
        } else {
            NotificationProvider.success(`Successfully updated status`);
        }
    }

    const onStatusChange = async (event) => {
        let prevStatus = invoice.statusId;
        invoice.statusId = event.target.value;
        if (event.target.value == StorageInvoiceStatus.CANCELED) {
            triggerPrompt({
                title: "Cancel Invoice",
                content: <>Are you sure you want to cancel the invoice? <br/> The invoice will not be billed through ParQ and will need to be billed manually."</>,
                onConfirm: async () => {
                    await onSave(invoice, event, prevStatus); 
                },
            });
        } else {
            await onSave(invoice, event, prevStatus);
        }

    }

    const renderValue = (selected) => {
        const color = StorageInvoiceStatusesList?.find(s => s.id == selected)?.color;
        return <div className={`${classes.selectItem} ${filled ? 'filled' : ''}`} style={filled ? { backgroundColor: color } : { color }}>
            {StorageInvoiceStatusesList?.find(s => s.id == selected)?.name}
        </div>
    };

    return (
        <Select
            native={false}
            className={classes.select}
            value={invoice?.statusId}
            onChange={onStatusChange}
            variant="outlined"
            inputProps={{
                className: classes.select,
            }}
            MenuProps={{
                className: classes.selectMenu,
                style: { padding: 0 }
            }}
            IconComponent={ExpandMoreIcon}
            renderValue={renderValue}
            disabled={invoice?.statusId == StorageInvoiceStatus.SENT_TO_QB}
        >
            {StorageInvoiceStatusesList?.map((value, key) => (
                <div
                    className={classes.statusItem}
                    style={{ color: value.color }}
                    id={key} key={key} value={value.id}>
                    {value.name}
                </div>
            ))}
        </Select>
    )
}
export default PCOInvoiceStatusDropdown;