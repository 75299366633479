import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useGetConfigurationByVehicleQuery } from "features/vehicles/configurationSlice";

export const vehicleTabTypes = {
    DETAILS: 'details',
    ACCESS: 'access',
    CHECK_IN: 'checkin',
    CHECK_OUT: 'checkout'
}

export const VehicleTabs = ({ vehicleId, selected = 0, setSelected, setSelectedKey }) => {

    const vehicleTabs = [
        {
            label: 'Asset Details',
            key: vehicleTabTypes.DETAILS
        },
        {
            label: 'Access Groups',
            permission: permissionProfiles.ACCESS.MANAGE_ACCESS,
            key: vehicleTabTypes.ACCESS
        }
    ]


    let { data: configuration, error: configurationError, isLoading: isLoadingConfiguration } = useGetConfigurationByVehicleQuery(vehicleId);

    if (configuration?.useCustomCheckin) {
        vehicleTabs.push({
            label: 'Check In',
            key: vehicleTabTypes.CHECK_IN
        });
    }

    if (configuration?.useCustomCheckOut) {
        vehicleTabs.push({
            label: 'Check Out',
            key: vehicleTabTypes.CHECK_OUT
        });
    }

    const selectTab = (tab) => {
        setSelected(tab);
    }

    return (
        <TabsWrapper tabs={vehicleTabs} selectedTab={selected} setSelectedTab={selectTab} selectByTabKey />
    )
}