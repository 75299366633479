import { makeStyles } from "@material-ui/core"

export const loadStatusUpdateStyle = makeStyles((theme) => ({
    label: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,
    },
    box: {
        margin: '1rem'
    },
    dispatchButton: {
        margin: 'auto',
        display: 'flex'
    },
    error: {
        color: 'red'
    },
    commentReminder: {
        padding: '0.5em 1em',
        background: '#d5edff',
        marginBottom: '0.5em',
        borderRadius: '3px',
    }
}));