import { makeStyles } from "@material-ui/core";

export const IssueTrackerStyles = makeStyles((theme) => ({
    viewToggleBtn: {
    },
    toggleButton: {
        border: 'none !important',
    },
    rightSide: {
        height: '100%',
        overflowY: 'auto',
        padding: '1em 2em',
        boxShadow: theme.shadows?.[2]
    },
    leftSide: {
        boxShadow: theme.shadows?.[2]
    },
    viewTypeSelector: {
        //marginLeft: '1em'
    },
    closeBtn: {
        textAlign: 'right'
    },
    resizer: {
        marginRight: '0.2em',
        marginLeft: '0.2em',
        backgroundColor: 'transparent',
        transition: 'background-color 0.2s ease',
        width: '10px',
        '&:hover': {
            backgroundColor: 'lightgrey',
        }
    },
    issueTrackerPageWrapper: {
        padding: '2em',
        maxWidth: '80%'
    },
    overdue: {
        "&::before": {
            background: '#ff940033',
            content: "''",
            position: "absolute",
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
        },
    },
}));