import { Button, Card, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Paper } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import BasicModal from "components/BasicModal";
import DropdownFilter from "components/DropdownFilter";
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import EditServiceProvider from "features/serviceProvider/EditServiceProvider";
import { useAddServiceProviderMutation, useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { useState } from "react";
import { mechanicalConfigStyles } from "../../style";

const ClientServiceProviders = ({ mechanicalsConfigParams, serviceProviders, onConfigChange }) => {
    const classes = mechanicalConfigStyles();

    let { data: allServiceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    const [addServiceProvider, { isLoading: isAdding }] = useAddServiceProviderMutation();

    const [anchorEl, setAnchorEl] = useState(null);
    const openAddMenu = Boolean(anchorEl);
    const [openNew, setOpenNew] = useState(false);
    const [openSelected, setOpenSelected] = useState(false);
    const [serviceProviderIds, setServiceProviderIds] = useState([]);

    let filteredServiceProviders = [];
    allServiceProviders?.map(sp => {
        if (!(serviceProviders?.find(a => a?.serviceProviderId == sp?.id)))
            filteredServiceProviders.push(sp);
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onAddNewClick = () => {
        handleClose();
        setOpenNew(true)
    }

    const onSelectExistingClick = () => {
        handleClose();
        setOpenSelected(true)
    }

    const handleChange = (event) => {
        setServiceProviderIds(event);
    }


    const onClickDelete = (id) => {
        let updatedServiceProviders = serviceProviders?.filter(serviceProvider => serviceProvider.id != id)
        onConfigChange(undefined, "clientServiceProviders", updatedServiceProviders);
    };

    const handleSaveNew = async (data) => {
        setOpenNew(false);

        let result = await addServiceProvider(data);

        let serviceProviderToAdd = { serviceProviderId: result.data, name: data.name }
        let updatedServiceProviders = serviceProviders?.concat(serviceProviderToAdd);

        onConfigChange(undefined, "clientServiceProviders", updatedServiceProviders);
    }

    const handleSaveSelected = async () => {
        setOpenSelected(false)

        let serviceProvidersToAdd = [];
        serviceProviderIds?.forEach((id) => {
            let s = allServiceProviders?.find(serviceProvider => serviceProvider.id == id);
            serviceProvidersToAdd.push({ serviceProviderId: id, name: s.name });
        })
        let updatedserviceProviders = serviceProviders?.concat(serviceProvidersToAdd);

        onConfigChange(undefined, "clientServiceProviders", updatedserviceProviders);
    }

    return (
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={9}>
                    <div className={classes.title}>Service Providers</div>
                </Grid>
                <Grid item xs={3}>
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                        <div className={classes.addButton}>
                            <Button
                                disabled={!mechanicalsConfigParams?.useMechanicals}
                                variant="contained"
                                aria-controls={openAddMenu ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openAddMenu ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                Add Service Provider
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openAddMenu}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onAddNewClick}>Add new</MenuItem>
                                <MenuItem onClick={onSelectExistingClick}>Select existing</MenuItem>
                            </Menu>
                        </div>


                        <EditServiceProvider
                            label="Add New Service Provider"
                            open={openNew}
                            setOpen={setOpenNew}
                            onSave={handleSaveNew}
                        />

                        <BasicModal
                            open={openSelected}
                            header={`Select Service Provider`}
                            handleClose={() => setOpenSelected(false)}
                            component={
                                <div className={classes.selectServiceProviders}>
                                    <DropdownFilter
                                        title={'Service Providers'}
                                        values={filteredServiceProviders}
                                        onSelect={(val) => handleChange(val)}
                                        value={serviceProviderIds}
                                        multiple={true}
                                        className={classes.multiSelect}
                                    />
                                    <div className={classes.selectActionButtons}>
                                        <Button onClick={() => setOpenSelected(false)} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSaveSelected} color="primary" variant="contained">
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                    </Authorize>
                </Grid>
            </Grid>
            <List>
                {serviceProviders?.length ?
                    <>{serviceProviders?.map(serviceProvider =>
                        <Card className={classes.listItem}>
                            <ListItem key={serviceProvider?.id} className={classes.spacing}>
                                <ListItemText primary={serviceProvider?.name} />
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete"
                                            disabled={!mechanicalsConfigParams?.useMechanicals}
                                            onClick={() => onClickDelete(serviceProvider?.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>

                                    </ListItemSecondaryAction>
                                </Authorize>
                            </ListItem>
                        </Card>
                    )}</> :
                    <EmptyPlaceholder text='No Service Providers' />
                }
            </List>
        </Paper>
    );
}

export default ClientServiceProviders;