import LoadingSpinner from "components/LoadingSpinner";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice"
import { PricingLookupStyle } from "./style";
import { useGetActivityPricingByParametersQuery } from "../pricingSlice";
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import FormatService from "services/formatService";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { useState } from "react";
import { useEffect } from "react";

export const PricingLookup = ({ onChange, activityItemId, lotId, vehicleTypeId, serviceProviderId, setServiceProviderId, price, setUseInventory, setNotSupported }) => {
    const classes = PricingLookupStyle()

    let { data: prices, error: pricesError, isLoading: isLoadingPrices } = useGetActivityPricingByParametersQuery({ activityItemId, lotId, vehicleTypeIds: vehicleTypeId, includeAllResults: true }, { skip: !activityItemId || !lotId || !vehicleTypeId });
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const serviceProvidersLookup = serviceProviders?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    prices = prices?.find(p => p.vehicleType == vehicleTypeId)?.activityPrices?.map(activityPrice => {
        return {
            ...activityPrice,
            serviceProvider: serviceProvidersLookup?.[activityPrice?.serviceProviderId]?.name,
            formattedPrice: FormatService.formatCurrency(activityPrice?.internalCost),
        }
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (price === 0 || price === undefined || isNaN(price) || price === "") {

            let priceObject = prices?.find(p => p.serviceProviderId == serviceProviderId)

            onChange(priceObject?.internalCost);

            priceObject?.useInventory ? setUseInventory(true) : setUseInventory(false);
        }
    }, [serviceProviderId]);


    return <>
        <LoadingSpinner loading={isLoadingServiceProviders || isLoadingPrices} />
        {
            (!!activityItemId && !!lotId && !!vehicleTypeId) &&
            <>
                <Tooltip title='Lookup Price'>
                    <IconButton
                        onClick={handleClick}

                    >
                        <LocalAtmIcon />
                    </IconButton>
                    {/* <Button
                        onClick={handleClick}
                        variant="contained"
                        color="primary"
                        className={classes.calculateButton}
                    >
                        Lookup
                    </Button> */}
                </Tooltip>

                <Menu
                    id="pricing-list"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {prices?.map((value, key) => (
                        <MenuItem onClick={() => {
                            setNotSupported(value.notSupported);
                            onChange(value?.internalCost);
                            setServiceProviderId(value?.serviceProviderId);
                            setUseInventory(value?.useInventory);
                            handleClose();
                        }} >
                            Service Provider: {value.serviceProvider}, Price: {value.formattedPrice}
                        </MenuItem>
                    ))}

                    {prices?.length == 0 &&
                        <MenuItem disabled >
                            There are no prices in the price database
                            <br />
                            for this activity, lot and vehicle type
                        </MenuItem>
                    }
                </Menu>
            </>
        }
    </>

}