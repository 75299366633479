import { IconLocationWrapper, IconVehicle } from 'icons';
import { vehicleMapMarkerStyle } from './style';

const VehicleMapMarker = ({ text, textColor }) => {
    const classes = vehicleMapMarkerStyle();
    return (
        <div className={classes.markerWrapper}>
            <div className={classes.iconWrapper}>
                <IconLocationWrapper fill={'#ffffff'} height='40' width='40' />
                <IconVehicle fill='white' height='20' width='20' className={classes.vehicleIcon} />
            </div>
            {text && <div className={`${classes.textWrapper} ${textColor ? classes.coloredTextWrapper : ""}`} >
                {text}
            </div>}
        </div>
    )
}
export default VehicleMapMarker;
