import * as React from "react";

function Icon3rdParty(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3199 2.321C3.32076 1.32015 4.81842 0.9375 6.7489 0.9375H11.2489C13.1794 0.9375 14.677 1.32015 15.6779 2.321C16.6788 3.32185 17.0614 4.81952 17.0614 6.75V11.25C17.0614 13.1805 16.6788 14.6781 15.6779 15.679C14.677 16.6798 13.1794 17.0625 11.2489 17.0625H6.7489C4.81842 17.0625 3.32076 16.6798 2.3199 15.679C1.31905 14.6781 0.936401 13.1805 0.936401 11.25V6.75C0.936401 4.81952 1.31905 3.32185 2.3199 2.321ZM3.1154 3.1165C2.42875 3.80315 2.0614 4.93048 2.0614 6.75V11.25C2.0614 13.0695 2.42875 14.1969 3.1154 14.8835C3.80205 15.5702 4.92938 15.9375 6.7489 15.9375H11.2489C13.0684 15.9375 14.1958 15.5702 14.8824 14.8835C15.5691 14.1969 15.9364 13.0695 15.9364 11.25V6.75C15.9364 4.93048 15.5691 3.80315 14.8824 3.1165C14.1958 2.42985 13.0684 2.0625 11.2489 2.0625H6.7489C4.92938 2.0625 3.80205 2.42985 3.1154 3.1165ZM4.6864 7.5C4.6864 5.95184 5.95074 4.6875 7.4989 4.6875H10.4989C12.0471 4.6875 13.3114 5.95184 13.3114 7.5V10.5C13.3114 12.0482 12.0471 13.3125 10.4989 13.3125H7.4989C5.95074 13.3125 4.6864 12.0482 4.6864 10.5V7.5ZM5.8114 9.5625V10.5C5.8114 11.4268 6.57206 12.1875 7.4989 12.1875H8.4364V9.5625H5.8114ZM8.4364 8.4375H5.8114V7.5C5.8114 6.57316 6.57206 5.8125 7.4989 5.8125H8.4364V8.4375ZM9.5614 9.5625V12.1875H10.4989C11.4257 12.1875 12.1864 11.4268 12.1864 10.5V9.5625H9.5614ZM12.1864 8.4375H9.5614V5.8125H10.4989C11.4257 5.8125 12.1864 6.57316 12.1864 7.5V8.4375Z" fill="#0090FE" />
        </svg>

    );
}

export default Icon3rdParty;
