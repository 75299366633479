
import { Button, Checkbox, FormControlLabel, Grid, Switch } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useGetRoleConfigurationQuery, useSaveRoleConfigurationMutation } from "features/users/UserManagement/roleSlice";
import { useEffect, useState } from "react";
import roleConfigurationStyles from "./roleConfigurationStyles";

const useStyles = roleConfigurationStyles;
const RoleConfiguration = ({ role, allRoles, refetchRolesWithData, onCancel}) => {
    const classes = useStyles(); 

    let {
        data: roleConfiguration,
        error: roleConfigurationError,
        isLoading: isLoadingRoleConfiguration
    } = useGetRoleConfigurationQuery(role?.id , {skip: !role?.id});
    const [updateRolesConf, { isUpdating }] = useSaveRoleConfigurationMutation();

    const [config, setConfig] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRoleForClient, setIsRoleForClient] = useState(false);

    useEffect(() => {
        if (!roleConfiguration || !roleConfiguration?.data) return;
        setConfig([...roleConfiguration?.data]);     
    }, [roleConfiguration]);

    useEffect(() => {
        if (!allRoles || !allRoles) return;
        let options = [...allRoles].map((rol) => ({
            id: rol.id,
            name: rol.name
        }));
        options = options.filter((op) => op.id != role?.id);
        setRolesOptions([...options]);     
    }, [allRoles]);

    useEffect(() => {
        if (!role) return;
        setIsRoleForClient(role?.isRoleForClient ?? false);     
    }, [role]);

    const onChangeRolConf = (id, val) => {
        if (val == true) {
            let newConfig = [...config];
            newConfig.push({
                id: -1,
                roleId: role?.id,
                roleAssignedId: id,
                isRoleEnable: true
            });
            setConfig([...newConfig]);
            return;
        }
        let newConfig = [...config];
        let rolesFound = newConfig.filter((confRol) => confRol.roleAssignedId !== id);
        setConfig([...rolesFound]); 
    }
    
    const isRoleEnable = (id) => {
        let roleFound = [...config].find((confRol) => confRol.roleAssignedId === id && confRol?.isRoleEnable == true);
        if(!roleFound) return false;
        return true;
    }

    const save = async () => {
        setIsLoading(true);
        let body = {
            Mappings: config,
            RoleId: role?.id,
            isRoleForClient: isRoleForClient
        };
        let res = await updateRolesConf(body);

        if (res && !res.error) {
            refetchRolesWithData();
            NotificationProvider.success("Roles Configuration Saved successfully");            
        } else {
            NotificationProvider.error("Failed to update Roles Configuration");
        }
        setIsLoading(false);
    }

    const tabs = [
        {
            label: 'What roles can this role see?',
            component: <>
            <Grid container direction="row" xs={12} spacing={2}>
                {
                    rolesOptions?.map((rolEna) => (
                        <Grid item direction="row" xs={4} justifyContent="center">
                            <FormControlLabel
                                control={<Checkbox />}
                                label={rolEna?.name}
                                checked={isRoleEnable(rolEna?.id)}
                                onChange={(e, checked) => onChangeRolConf(rolEna?.id, checked)}
                                style={{ width: "100%"}}

                            />
                        </Grid>
                    ))
                }
            </Grid>
            
            <Grid xs={12} style={{ display: "flex", justifyContent: "flex-end"}}>
                <Button onClick={() => save()} color='primary' variant="contained" disabled={isLoading}>
                    {isLoading == true ? "Saving..." : "Save" }
                </Button>
                <Button onClick={() => onCancel()} color='primary' disabled={isLoading}>
                    Cancel
                </Button>
            </Grid>
        </>
        }
    ]
    
    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" xs={12} alignItems="center">
                <Grid item xs={12}>
                    <span className={classes.headerTitle}>Role Configuration: 
                    <span className={classes.headerTitleRoleName}> {role?.name} </span></span>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch  color="primary"/>}
                        label={`Role for Clients ${ isRoleForClient ? "(You will be able to see these roles only for the client user type when you are assigning role for a user)": "" }`}
                        labelPlacement="end"
                        checked={isRoleForClient}
                        onChange={(e, checked) => setIsRoleForClient(checked)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TabsWrapper tabs={tabs} />
                </Grid>
            </Grid>
        </>
    )
}

export default RoleConfiguration;