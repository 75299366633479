import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetAuditsByUserQuery, useRunAuditMutation } from './lotAuditSlice';
import AuditTable from './components/AuditTable';

function VehicleLotAudit() {
    let { data: audits, error: auditsError, isLoading: isLoadingAudits } = useGetAuditsByUserQuery(null);
    audits = audits?.data || [];

    let [runAudit, { data: auditResult, error: runError, isLoading: runLoading }] = useRunAuditMutation();

    let params = useParams();

    useEffect(async () => {
        let audit = audits?.find(a => a.id === parseInt(params?.id));
        let body = {
            lotId: audit?.lotId,
            url: audit?.fileUrl,
            email: audit?.createdBy,
            auditId: parseInt(params?.id)
        }
        const res = await runAudit(body);
    }, [])

    const isLoading = isLoadingAudits;

    return (
        <>{isLoading ?
            <LoadingSpinner loading={isLoading} /> :
            <AuditTable params={params} auditResult={auditResult} isLoadingLotAudit={runLoading} />
        }</>
    );
}

export default VehicleLotAudit;

