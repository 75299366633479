import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const accessPermissionProfile = {
    MANAGE_ACCESS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.CLIENT],
    },
    CLIENT_FIELDS: {
        [modes.READONLY]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    GENERATE_ONE_TIME: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.ADMIN],
    },
    ACCESS_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.ACCESS.VIEW_CAMERA_EVENTS]
    },
    DOOR_RELEASE_LIST: {
        [modes.EDIT]: [roles.OWNER],
    },
    CAMERA_EVENTS_LIST: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.ACCESS.VIEW_CAMERA_EVENTS]
    },
}