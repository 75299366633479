import { makeStyles } from "@material-ui/core"

export const AddInvoiceStyle = makeStyles((theme) => ({
    fieldWrapper: {
        whiteSpace: 'nowrap',
        "&:hover": {
            "& $icons": {
                opacity: 1,
            }
        }
    },
    field: {
        position: 'relative',
    },
    icons: {
        display: 'flex',
        position: 'absolute',
        right: '0',
        opacity: 0.1,
        top: '-2px'
    },
    groupWrapper: {
        whiteSpace: 'nowrap'
    },
    label: {
        fontWeight: 'bold',
    },
    groupLabel: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        margin: '0.5em 0'
    },
    headers: {
        color: theme.palette.primary.main,
    },
    totals: {
        fontSize: '1.2em'
    },
    totalSummary: {
        color: theme.palette.primary.main,
    },
    services: {
        margin: '1em 0'
    },
    service: {
        // whiteSpace: 'normal'
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    input: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    },
    upwardArrow: {
        margin: '0 0.5rem',
        marginLeft: '1em',
        background: '#FF5A4F',
        padding: '1px',
        borderRadius: '50%',
        '& path': {
            fill: 'white'
        }
    },
    downwardArrow: {
        margin: '0 0.5rem',
        background: '#32C997',
        padding: '1px',
        borderRadius: '50%',
        '& path': {
            fill: 'white'
        }
    },
    loadLabel: {
        fontWeight: 600,
        paddingLeft: '1em'
    },
    invoiceDialog: {
        padding: '1rem'
    },
    editServiceContainer: {
        margin: '0.2rem 0'
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    document: {
        marginTop: '10px'
    },
}));