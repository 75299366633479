import { makeStyles } from "@material-ui/core"

export const ListsStyle = makeStyles((theme) => ({
    listWrapper: {
        backgroundColor: theme.palette.grey[200],
        maxHeight: 220,
        minHeight: 220,
        maxWidth: 300,
        minWidth: 300,
        padding: '0.5rem 0.5rem',
    },
    listTitle: {
        color: theme.palette.primary.main,
        padding: '0.5rem 1.5rem',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '2px solid',
        borderColor: theme.palette.grey[200],
        fontWeight: '500'
    },
    list: {
        margin: '0.5rem 0',
        borderRadius: '5px',
        backgroundColor: theme.palette.grey[200],
        
    },
    listContainer:{
        maxHeight: 120,
        minHeight: 120,
        flex: 1,
        overflowY: 'auto',
    },
    listItem: {
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '2px solid',
        borderColor: theme.palette.grey[200],
    },
    listItemIcon: {
        marginRight: '1rem',
        padding: 0
    },
    moreVertIcon: {
        marginLeft: '1rem',
        padding: '0'
    },
    addButton: {
        color: theme.palette.primary.main,
        padding: '0.5rem 1.5rem',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '2px solid',
        borderColor: theme.palette.grey[200],
        fontWeight: '500',
        width: '100%',
        justifyContent: 'left',
        // marginTop: '0.3rem'
    },
    name: {
        width: '70%'
    }
}));
