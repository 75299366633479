import { makeStyles } from "@material-ui/core";

export const RankingPieChartStyle = makeStyles((theme) => ({
    valueLabel: {
        fontSize: 26,
        fontWeight: 700,
    },
    label: {
        textAlign: 'center',
        paddingTop: '0.5rem',
        color: theme.palette.primary.main,
        fontWeight: '500',
        fontSize: 16
    },
    pieChart: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        marginBottom: '1.2rem'
    }
    
}));