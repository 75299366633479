


import * as React from "react";

function SvgIconPercent(props, width, height) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81304 18.1872C5.52015 17.8943 5.52015 17.4194 5.81304 17.1265L17.1268 5.81281C17.4196 5.51992 17.8945 5.51992 18.1874 5.81281C18.4803 6.10571 18.4803 6.58058 18.1874 6.87347L6.87371 18.1872C6.58081 18.4801 6.10594 18.4801 5.81304 18.1872Z" fill="#0090fe" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50987 7.50987C6.98266 8.03708 6.98266 8.89185 7.50987 9.41906C8.03708 9.94626 8.89185 9.94626 9.41906 9.41906C9.94626 8.89185 9.94626 8.03708 9.41906 7.50987C8.89185 6.98266 8.03708 6.98266 7.50987 7.50987ZM6.59063 10.3383C5.55574 9.30341 5.55574 7.62552 6.59063 6.59063C7.62552 5.55574 9.30341 5.55574 10.3383 6.59063C11.3732 7.62552 11.3732 9.30341 10.3383 10.3383C9.30341 11.3732 7.62552 11.3732 6.59063 10.3383Z" fill="#0090fe" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5809 14.5809C14.0537 15.1082 14.0537 15.9629 14.5809 16.4901C15.1081 17.0173 15.9629 17.0173 16.4901 16.4901C17.0173 15.9629 17.0173 15.1082 16.4901 14.5809C15.9629 14.0537 15.1081 14.0537 14.5809 14.5809ZM13.6617 17.4094C12.6268 16.3745 12.6268 14.6966 13.6617 13.6617C14.6966 12.6268 16.3745 12.6268 17.4093 13.6617C18.4442 14.6966 18.4442 16.3745 17.4093 17.4094C16.3745 18.4443 14.6966 18.4443 13.6617 17.4094Z" fill="#0090fe" />
        </svg>

    );
}

export default SvgIconPercent;
