import { Button, Grid } from '@material-ui/core';
import Authorize from 'components/Authorize/Authorize.js';
import { permissionProfiles } from 'components/Authorize/permissionProfiles.js';
import DataListHeader from "components/DataListHeader";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import NotificationProvider from 'components/NotificationProvider.js';
import PageComponent from "components/Page/PageComponent";
import { useGetClientsWithConfigsQuery } from 'features/clients/clientConfigSlice';
import { useGetLotsQuery } from "features/lots/lotSlice";
import usePrompt from 'hooks/usePrompt.js';
import { orderBy, sortBy } from 'lodash';
import { useState } from "react";
import { useSelector } from "react-redux";
import { arrayToObject } from 'utils/object-util';
import BasicModal from "../../../../../../src/components/BasicModal";
import SelectFieldsPopup from "../../../../../components/SelectFieldsPopup/selectFieldsPopup.js";
import { useGetSubMarketsQuery } from "../../../../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { useGetInvoiceReportsMutation, useUpdateStorageInvoiceMutation } from "../../../../lots/lotSlice";
import { InvoiceTypes } from '../../../invoiceConsts.ts';
import BillingReportsDialog from '../../../reports/generateReports/BillingReportsDialog';
import InvoiceScreenSelector from '../../../shared/InvoiceScreenSelector';
import { StorageInvoiceStatus, storageInvoiceTypes } from '../../storageInvoiceConsts.js';
import { useApproveStorageInvoiceMutation, useGetStorageInvoicesQuery } from '../../storageInvoiceSlice.ts';
import PCOInvoiceTable from '../PCOInvoice/PCOInvoiceTable';
import StorageInvoice from '../StorageInvoice/StorageInvoice';
import StorageInvoiceHeader from '../StorageInvoiceHeader/StorageInvoiceHeader';
import StorageInvoiceStatusDisplay from '../StorageInvoiceStatusDisplay/StorageInvoiceStatusDisplay.js';
import StorageInvoiceSummary from '../StorageInvoiceSummary/StorageInvoiceSummary';

const StorageInvoicePage = () => {
    const { triggerPrompt } = usePrompt();
    const { filters } = useSelector(state => state.storageInvoiceState);
    const [openBillingReports, setOpenBillingReports] = useState(false);

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isLoading: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery();
    clientsWithConfigs = clientsWithConfigs || [];
    let clientsWithConfigsLookup = arrayToObject(clientsWithConfigs);

    const config = clientsWithConfigsLookup?.[filters?.['clientId']];
    const invoiceCycleType = config?.config?.lotConfig?.invoiceCycleType;

    let { data: invoices, error, isFetching } = useGetStorageInvoicesQuery({
        clientId: filters?.['clientId'],
        month: invoiceCycleType !== storageInvoiceTypes.PER_CHECKOUT ? filters?.['month'] : null,
        year: invoiceCycleType !== storageInvoiceTypes.PER_CHECKOUT ? filters?.['year'] : null,
    }, { skip: !filters?.['clientId'] || (!filters?.['month'] && invoiceCycleType !== storageInvoiceTypes.PER_CHECKOUT) });

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: submarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    const [getInvoiceReports, { isLoading: removingLot }] = useGetInvoiceReportsMutation();
    const [approveInvoice, { isLoading: isApproving }] = useApproveStorageInvoiceMutation();

    const [updateStorageInvoice] = useUpdateStorageInvoiceMutation();
    let [isDownloadingReports, setIsDownloadingReports] = useState(false);
    let [isRegeneratingData, setIsRegeneratingData] = useState(false);

    const lastInvoice = orderBy(invoices ?? [], ['year'], ['desc'])?.[0] ?? {};
    const invoiceDetails = lastInvoice?.storageInvoiceDetails?.map(det => {
        let resultInfo;
        let result = lots?.find(l => l.id === det?.lotId);

        if (typeof (result) === "undefined") {
            result = submarkets?.find(l => l.id === det?.subMarketId);
            resultInfo = [result?.name];
        }
        else {
            resultInfo = [result?.name, result?.addressLine1, result?.city, result?.state, result?.zipCode]?.join(', ');
        }

        return ({
            ...det,
            lot: result,
            lotInfo: resultInfo,
        });
    });

    const invoiceData = {
        ...lastInvoice,
        details: sortBy(invoiceDetails, [d => !!d.subMarketId, d => d.lotInfo])
    };

    let reportColumns = [
        { name: "Invoice Report", key: "1", selectedByDefault: true, width: 280 },
        { name: "Lot/Day Matrix PMF Days", key: "2", selectedByDefault: true, width: 200 },
        { name: "VIN Report PMF Days", key: "3", selectedByDefault: true },
        { name: "Lot/Day Matrix Day Count", key: "4", selectedByDefault: true, width: 200 },
        { name: "VIN Report Day Count", key: "5", selectedByDefault: true, width: 200 }
    ];

    const [selectedColumns, setSelectedColumns] = useState([...reportColumns].filter(c => c.selectedByDefault));

    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name === columnName : false).length > 0;
    };

    const onSubmitSelectedFields = async (checked) => {
        setSelectedColumns(
            reportColumns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );

        let reportsToSend = [];
        reportColumns.map((column) => (isChecked(checked, column.name) ? column : null)).forEach((id) => {
            if (id !== null) {
                reportsToSend.push(parseInt(id.key));
            }
        });

        let reportsToSendObj = {
            ReportTypes: reportsToSend,
            ClientId: parseInt(filters?.clientId),
            Month: parseInt(filters?.month),
            Year: parseInt(filters?.year)
        };

        setOpenSelectFieldsPopup(false);

        setIsDownloadingReports(true);
        await getInvoiceReports(reportsToSendObj);
        setIsDownloadingReports(false);
    };

    const onSubmitRegenerateData = async (checked) => {
        let option = {
            ClientId: parseInt(filters?.['clientId']),
            Month: parseInt(filters?.['month']),
            Year: parseInt(filters?.['year'])
        }
        setIsRegeneratingData(true);
        await updateStorageInvoice(option);
        setIsRegeneratingData(false);
    };

    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true);
        setIsDownloadingReports(true);
    };

    const onCloseModal = () => {
        setOpenSelectFieldsPopup(false);
        setIsDownloadingReports(false);
    };

    const needsFiltering = !filters?.['clientId'] || (!filters?.['month'] && invoiceCycleType !== storageInvoiceTypes.PER_CHECKOUT);

    const onApproveInvoice = async () => {
        triggerPrompt({
            title: "Approve Invoice",
            content: <div>
                The approved invoice will be sent to QuickBooks in a few hours.
                <br />Are you sure you want to continue?
            </div>,
            onConfirm: async () => {
                const res = await approveInvoice(invoiceData?.id);
                if (!res?.error) {
                    NotificationProvider.success('Invoice approved successfully');
                } else {
                    NotificationProvider.error('Failed to approve invoice');
                }
            },
            onCancel: async () => {
            },
        });
    };

    const isFiltersSet = () => filters?.clientId && filters?.month && filters?.year;

    return (
        <PageComponent
            loading={isFetching}
            header={
                <DataListHeader
                    titleSize={6}
                    titleComponent={(
                        <InvoiceScreenSelector
                            selected={InvoiceTypes.STORAGE}
                        />
                    )}
                    actionButtons={
                        <Grid container alignItems='center' spacing={1}>
                            {invoiceCycleType !== storageInvoiceTypes.PER_CHECKOUT ? <>
                                <StorageInvoiceStatusDisplay invoice={invoiceData} />
                                {
                                    <>
                                        {invoiceData?.status === StorageInvoiceStatus.PENDING && <Authorize profile={permissionProfiles.INVOICE.INVOICES_MANAGE}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isApproving}
                                                    onClick={onApproveInvoice}>
                                                    Approve Invoice
                                                </Button>
                                            </Grid>
                                        </Authorize>}
                                        {(!invoiceData?.id || invoiceData?.status === StorageInvoiceStatus.PENDING) && <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onSubmitRegenerateData}
                                                disabled={isRegeneratingData || !isFiltersSet()}>
                                                Re-generate Invoice
                                            </Button>
                                        </Grid>}
                                        {!!invoiceData && <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onSelectExistingClick}
                                                disabled={isDownloadingReports || !isFiltersSet()}>
                                                Export
                                            </Button>
                                        </Grid>}

                                    </>
                                }</> :
                                <>
                                    {filters?.['clientId'] &&
                                        <Authorize profile={permissionProfiles.REPORTS.VIEW_BILLING_REPORTS}>
                                            <Grid item>
                                                <Button variant='contained' color="primary" onClick={() => setOpenBillingReports(true)}>
                                                    Generate Reports
                                                </Button>
                                            </Grid>
                                        </Authorize>
                                    }
                                </>}
                        </Grid>}
                />}>
            <StorageInvoiceHeader invoiceCycleType={invoiceCycleType} />
            <BasicModal
                open={openSelectFieldsPopup}
                header={`Select Reports`}
                handleClose={() => onCloseModal(false)}
                component={<SelectFieldsPopup columns={reportColumns}
                    selectedColumns={selectedColumns}
                    isChecked={isChecked}
                    onSubmit={onSubmitSelectedFields}
                    handleClose={() => onCloseModal(false)} />}
            />
            {needsFiltering ?
                <EmptyPlaceholder text={'Please select client and month'} /> :
                !!invoiceData ?
                    <>
                        {invoiceCycleType == storageInvoiceTypes.PER_CHECKOUT ?
                            <div style={{ marginTop: '1em' }}>
                                <PCOInvoiceTable invoices={invoices} />
                            </div> :
                            <>
                                <Grid direction='column' spacing={1}>
                                    {invoiceData?.details?.map(invoice => <Grid item key={invoice.id}><StorageInvoice invoice={invoice} /></Grid>)}
                                </Grid>
                                <StorageInvoiceSummary invoice={invoiceData} />
                            </>
                        }
                    </> :
                    <EmptyPlaceholder text={'No Invoices'} />}
            {openBillingReports && <BillingReportsDialog open={openBillingReports} onClose={() => setOpenBillingReports(false)} clientId={filters?.['clientId']} />}
        </PageComponent>
    );
};

export default StorageInvoicePage;
