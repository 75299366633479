import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { IconPhoto } from 'icons';
import { useState } from 'react';
const { Tooltip, IconButton, Dialog, DialogContent } = require("@material-ui/core");
const { AttachmentsStyle } = require("./AttachmentsStyle");
const { useGetEntityAttachmentsQuery } = require("./attachmentSlice");
const { default: AttachmentsView } = require("./AttachmentsView");

const AttachmentsTooltip = ({ attachments, entityId, entityType }) => {
    const classes = AttachmentsStyle();

    let { data: attachmentsFromApi, error: attachmentsError, isFetching: isLoadingAttachments } = useGetEntityAttachmentsQuery({ entityId: entityId, entityType: entityType }, { skip: !entityId || !!attachments });
    
    const attachmentData = attachments ?? attachmentsFromApi ?? [];
    const [listOpen, setListOpen] = useState(false);

    return (
        <>
            {!!attachmentData?.length ?
                <Tooltip title={`Click to view ${attachmentData?.length} attachments`}>
                    <IconButton size='small'onClick={() => setListOpen(true)} className={classes.attachmentsTooltipOn}>
                        <IconPhoto />
                    </IconButton>
                </Tooltip> :
                <><Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_ATTACHMENTS}>
                    <Tooltip title={'Add attachments'}>
                        <IconButton size='small' onClick={() => setListOpen(true)} className={classes.attachmentsTooltip}>
                            <IconPhoto />
                        </IconButton>
                    </Tooltip>
                </Authorize></>
            }
            <Dialog maxWidth='md' fullWidth open={listOpen} onClose={() => setListOpen(false)}>
                <DialogContent>
                    <AttachmentsView attachmentsSent={attachmentData} entityId={entityId} entityType={entityType} />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AttachmentsTooltip;