import { makeStyles, Modal, Paper, Grid } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { Autorenew } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    modalBody: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.common.white,
        boxShadow: 24,
        padding: 10,
        maxWidth: '100%',
        maxHeight: '95%',
        display: 'flex',
        flexDirection: 'column'
    },
    modalBodyWide: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.common.white,
        boxShadow: 24,
        padding: 10,
        width: '900px',
        maxWidth: '100%',
        maxHeight: '95%',
        display: 'flex',
        flexDirection: 'column'
    },
    modalContentWrapper: {
        flex: '1',
        overflowY: 'auto'
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1em 1em 0 1em'
    },
    header: {
        margin: 0,
        fontWeight: '500'
    },
    closeIcon: {
        cursor: 'pointer'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginRight: 10
    }
}));

const BasicModal = ({ open, title, icon, handleClose, component, header, wide, headerComponent }) => {
    const classes = useStyles();
    const onCloseIcon = () => { handleClose() }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Paper className={wide ? classes.modalBodyWide : classes.modalBody}>
                <div className={classes.modalHeader}>
                    {
                        header &&
                        <Grid container direction="row" alignItems="flex-start" justifyContent="space-between">
                            <Grid item alignItems="flex-start">
                                <h2 className={classes.header}>{header}</h2>
                            </Grid>
                            <Grid item direction="row" alignItems="center">
                                {headerComponent}
                            </Grid>
                        </Grid>
                    }
                    <div className={classes.titleContainer}>
                        <div className={classes.iconContainer}>{icon}</div>
                        <h1>{title}</h1>
                    </div>
                    <CloseIcon className={classes.closeIcon} onClick={() => onCloseIcon()} />
                </div>
                <div className={classes.modalContentWrapper}>
                    {component}
                </div>
            </Paper>
        </Modal>
    )
}

export default BasicModal
