import * as React from "react";

function SvgIconBill({ width = 30, height = 30, ...props }) {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3757 14.1667H1.04232C0.854456 14.1667 0.674289 14.092 0.54145 13.9592C0.408612 13.8264 0.333984 13.6462 0.333984 13.4583V0.708333C0.333984 0.520472 0.408612 0.340304 0.54145 0.207466C0.674289 0.0746277 0.854456 0 1.04232 0H12.3757C12.5635 0 12.7437 0.0746277 12.8765 0.207466C13.0094 0.340304 13.084 0.520472 13.084 0.708333V13.4583C13.084 13.6462 13.0094 13.8264 12.8765 13.9592C12.7437 14.092 12.5635 14.1667 12.3757 14.1667ZM11.6673 12.75V1.41667H1.75065V12.75H11.6673ZM3.87565 5.66667C3.87565 5.27547 4.19278 4.95833 4.58398 4.95833H8.83399C9.22519 4.95833 9.54232 5.27547 9.54232 5.66667C9.54232 6.05787 9.22519 6.375 8.83399 6.375H4.58398C4.19278 6.375 3.87565 6.05787 3.87565 5.66667ZM3.87565 8.5C3.87565 8.1088 4.19278 7.79167 4.58398 7.79167H8.83399C9.22519 7.79167 9.54232 8.1088 9.54232 8.5C9.54232 8.8912 9.22519 9.20833 8.83399 9.20833H4.58398C4.19278 9.20833 3.87565 8.8912 3.87565 8.5Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconBill;
