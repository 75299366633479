import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Button, Card, CircularProgress, FormControlLabel, Grid, Tooltip } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { subscriptionListStyle } from "./SubscriptionListStyle";
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import Checkbox from '@mui/material/Checkbox';
import { selectIsClient, selectUserClientId, selectUserEmail, selectUserLotId } from "../user/userSlice";
import { useSelector } from "react-redux";
import { useDeleteLotServiceMutation, useDeleteSubscriptionMutation, useUpdateSubscriptionMutation } from "./subscriptionSlice";
import { useState } from "react";
import Authorize from "../../components/Authorize";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";
import DropdownFilter from "components/DropdownFilter";
import NotificationProvider from "components/NotificationProvider";
import { getErrorsFromResponse } from "app/errorHandling";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton, Popover, Typography } from "@mui/material";
import usePrompt from "hooks/usePrompt";

const SubscriptionList = ({ subscriptions }) => {
    const classes = subscriptionListStyle();

    const combinedSubscriptions = [];

    subscriptions.map(sub => {
        const combinedSubscription = {
        ...sub,
        changed: false
        }
    combinedSubscriptions.push(combinedSubscription);
    });

    return (
        <>
            <List justifyContent='space-between'>
                {combinedSubscriptions.map((c, index) =>
                    <Authorize profile={permissionProfiles.SUBSCRIPTION[`EMAIL_TYPE_${c.id}`]} key={index}>
                        <ListItem >
                            <Card className={classes.subscriptionBox}>
                                <SubscriptionItem key={c.id} combinedSubscription={c} />
                            </Card>
                        </ListItem>
                    </Authorize>
                )}
            </List >
        </>
    )
};

export default SubscriptionList;

const SubscriptionItem = ({ combinedSubscription }) => {
    const classes = subscriptionListStyle();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let [saveSubscription, { isLoading: isSavingSubscription, isError, isSuccess }] = useUpdateSubscriptionMutation();

    clients = clients || [];
    lots = lots || [];

    // const onSave = async subscription => {
    //     return await saveSubscription(subscription);
    // };

    const [clientIds, setClientIds] = React.useState(combinedSubscription?.userSubscription?.clients?.length ? combinedSubscription?.userSubscription?.clients : [null]);
    const [lotIds, setLotIds] = React.useState(combinedSubscription?.userSubscription?.lots?.length ? combinedSubscription?.userSubscription?.lots : [null]);
    const [subscribed, setSubscribed] = React.useState(combinedSubscription?.userSubscription?.active);
    const [changed, setChanged] = React.useState(combinedSubscription?.changed);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [subToDelete, setSubToDelete] = useState(null);
    const [subToEdit, setSubToEdit] = useState(null);
    let readonly = combinedSubscription?.readonly;
    let subscribedBySystem = combinedSubscription?.userSubscription?.subscribedBySystem;

    let [deleteSubscription, { isLoading: isDeleting }] = useDeleteSubscriptionMutation();

    const userEmail = useSelector(selectUserEmail);
    const userClientId = useSelector(selectUserClientId);
    const userLotId = useSelector(selectUserLotId);
    const isClient = useSelector(selectIsClient);

    const handleSave = async () => {
        const res = await saveSubscription({
            email: userEmail,
            emailTypeId: combinedSubscription.id,
            clients: clientIds?.includes(null) ? null : clientIds,
            lots: lotIds?.includes(null) ? null : lotIds
        });
        if (res.error) {
            NotificationProvider.error(getErrorsFromResponse(res, 'Failed to save subscription'));
        } else {
            NotificationProvider.success('Subscription saved successfully');
            setChanged(false);
        }
    };

    const handleCheckChange = async (combinedSubscription) => {
        if (!subscribed) //if not subscribed
        {
            setSubscribed(true);
            combinedSubscription.userSubscription = [{
                clients: clientIds, email: userEmail,
                emailTypeId: combinedSubscription.id, lots: lotIds, subscribedBySystem: subscribedBySystem
            }];
            setSubToEdit(combinedSubscription.userSubscription);
        }
        else //if is subscribed
        {
            setSubscribed(false);
            await deleteSubscription({ emailTypeId: combinedSubscription.id, email: userEmail });
            setSubToDelete(combinedSubscription.userSubscription)
            setChanged(false);
        }
    }

    const onSelectClient = (v) => {
        if (v.includes(null)) {
            if (userClientId) {
                v = clients.map(c => c.id)
            }
            else {
                v = [null];
            }
        }
        setClientIds(v);
        setChanged(true)
    }

    const onSelectLot = (v) => {
        if (v.includes(null)) {
            if (userLotId || isClient) {
                v = lots.map(c => c.id)
            }
            else {
                v = [null];
            }
        }
        setLotIds(v);
        setChanged(true)
    }
    
    let [deleteLotService] = useDeleteLotServiceMutation();
    const [anchorEl, setAnchorEl] = useState(false);
    const { triggerPrompt } = usePrompt();

    const confirmDelete = async () => {
        triggerPrompt({
            title: "Remove Automatic Subscription",
            content: "Are you sure you want to remove this automatic subscription?",
            onConfirm: async () => {
                let email = userEmail;

                let res = await deleteLotService(email);
                if (res.error) {
                    NotificationProvider.error('Failed to remove automatic subscription');
                } else {
                    NotificationProvider.success('Subscription removed successfully');
                }
            }
        });
    }

    const lotsLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };
    
    const isValid = (isClient || clientIds.length > 0) && lotIds.length > 0;

    return (
        <Grid container direction='row' alignItems='center' spacing={1}>
            {
                <>
                    {!readonly ?
                        <>
                            <Grid item xs={3}>
                                <Grid container direction='row' alignItems='center'>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={subscribed}
                                            onChange={c => { setChanged(!changed); handleCheckChange(combinedSubscription) }}
                                        />}
                                    />
                                    <div key={combinedSubscription?.id}>{combinedSubscription?.name}</div>
                                </Grid>
                            </Grid>

                            {subscribed &&
                                <>
                                    <Grid item xs={3}>
                                        <Authorize profile={permissionProfiles.SUBSCRIPTION.SUBSCRIPTION_AUDIT}>
                                            <ListItemText key={combinedSubscription?.id} primary={
                                                <DropdownFilter
                                                    required
                                                    title={'Clients'}
                                                    value={clientIds}
                                                    onSelect={v => onSelectClient(v)}
                                                    values={[{ name: 'All', id: null }, ...clients]}
                                                    multiple={true}
                                                />
                                            } />
                                        </Authorize>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <DropdownFilter
                                            required
                                            title={'Lots'}
                                            value={lotIds}
                                            onSelect={v => onSelectLot(v)}
                                            values={[{ name: 'All', id: null }, ...lots]}
                                            multiple={true}
                                        />
                                    </Grid>
                                </>
                            }

                            {
                                subscribedBySystem?.length > 0 &&
                                <>                       
                                    <IconButton 
                                        onClick={(event) =>  handleClick(event)}
                                        aria-describedby={!!anchorEl ? 'simple-popover' : undefined}
                                    >            
                                        <InfoOutlinedIcon />
                                    </IconButton> 
                                    <Popover
                                        id={!!anchorEl ? 'simple-popover' : undefined}
                                        open={!!anchorEl}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}                                        
                                    >
                                        <Grid>
                                            <Typography sx={{ p: 1 }}>{"You are receiving this email automatically from the system for the following lots: " + subscribedBySystem?.map(l => lotsLookup?.[l]?.name).join(', ')}</Typography>
                                            <Button 
                                                onClick={() => confirmDelete()} 
                                                color="primary"                                                
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    </Popover>                                    
                                </>
                            }

                            {
                                changed &&
                                <>
                                    {isSavingSubscription && <CircularProgress size={'1em'} />}
                                    <Button
                                        variant="contained"
                                        className={classes.saveButton}
                                        onClick={handleSave}
                                        color='primary'
                                        disabled={isSavingSubscription || !isValid}
                                    >
                                        Save
                                    </Button>
                                </>
                            } 
                        </> 
                        
                        : <Grid container direction='row' alignItems='center'>
                            <Grid item xs={4}>
                                <div key={combinedSubscription?.id}>{combinedSubscription?.name}</div>
                            </Grid>
                            <Grid item xs={4}>
                            {!!combinedSubscription?.userSubscription?.lots.length ? 
                                <div>{'Lots: '}</div> : null }
                                <div>{combinedSubscription?.userSubscription?.lots?.map(l => lotsLookup?.[l]?.name).join(', ')}</div>
                            </Grid>                             
                        </Grid> 
                    } 
                </>
            }
        </Grid >
    )
}