export const SectionType = {
    Header : 1,
    Misc : 2,
    Front : 3,
    Front_Driver_Side : 4,
    Driver_Side_Rear : 5,
    Rear : 6,
    Passenger_Rear : 7,
    Front_Passenger : 8,
    Interior : 9,
    Under_Carrige : 10,
    Interior2 : 11,
    Interior3 : 12,
}