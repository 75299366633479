import { makeStyles } from "@material-ui/core"

export const loadLegsStyle = makeStyles((theme) => ({
    loadLegs: {
        maxHeight: '300px',
        overflowY: 'auto',
        marginBottom: '1.5em'
    },
    legWrapper: {
        borderRadius: '5px',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'stretch',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        }
    },
    legData: {
        padding: '0.5em 1em',
        flex: 1,
    },
    legIcon: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0.5em',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px'
    },
    legIconActivity: {
        background: '#FFB240',
        '& path': {
            fill: 'white'
        }
    },
    legIconActivityComplete: {
        background: '#0090FE',
        '& path': {
            fill: 'white'
        }
    },
    legIconPickup: {
        background: '#FF5A4F'
    },
    legIconDelivery: {
        background: '#32C997',
        '& svg': {
            transform: 'rotate(180deg)'
        }
    },
    label: {
        fontSize: '0.7em',
        color: '#8793A1'
    },
    value: {
        fontWeight: 'bold'
    },
    legIcons: {
        fill: theme.palette.secondary.light,
        '&:hover': {
            fill: theme.palette.secondary.main,
        }
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '0.5em'
    },
    flag: {
        margin: 'auto'
    }
}));
