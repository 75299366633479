import * as React from "react";

function SvgIconCommentBlue(props) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.9791 7.46875H6.02075C6.01623 7.46875 6.00839 7.46709 5.99977 7.45848C5.99116 7.44987 5.9895 7.44202 5.9895 7.4375C5.9895 7.43298 5.99116 7.42513 5.99977 7.41652C6.00839 7.40791 6.01623 7.40625 6.02075 7.40625H10.9791C10.9836 7.40625 10.9915 7.40791 11.0001 7.41652C11.0087 7.42513 11.0103 7.43298 11.0103 7.4375C11.0103 7.44202 11.0087 7.44987 11.0001 7.45848C10.9915 7.46709 10.9836 7.46875 10.9791 7.46875Z" fill="#0090FE" stroke="#0090FE"/>
            <path d="M7.90618 13.1768L7.78021 13.0929H7.62882H4.95842C3.85462 13.0929 2.96959 12.7244 2.36176 12.1166C1.75393 11.5088 1.3855 10.6237 1.3855 9.51994V5.26994C1.3855 4.16614 1.75393 3.28111 2.36176 2.67328C2.96959 2.06545 3.85462 1.69702 4.95842 1.69702H12.0417C13.1455 1.69702 14.0306 2.06545 14.6384 2.67328C15.2462 3.28111 15.6147 4.16614 15.6147 5.26994V9.51994C15.6147 10.5406 15.2984 11.3747 14.7707 11.9742C14.2437 12.5729 13.4786 12.969 12.5219 13.0672L12.073 13.1132V13.5645V14.5704C12.073 14.8494 11.9242 15.0947 11.6884 15.2187L11.6884 15.2187L11.6843 15.2209C11.5741 15.2802 11.4503 15.3099 11.3334 15.3099C11.19 15.3099 11.0451 15.2676 10.9218 15.1873L7.90618 13.1768ZM7.80466 13.0264L7.80461 13.0265L7.81234 13.0317L10.9644 15.1283L10.9644 15.1284L10.9729 15.1339C11.2534 15.3124 11.5336 15.2374 11.6827 15.1479C11.7752 15.0924 12.0176 14.9178 12.0176 14.5633V13.0545C12.0176 13.05 12.0192 13.0421 12.0279 13.0335C12.0365 13.0249 12.0443 13.0233 12.0488 13.0233C13.0725 13.0233 13.9601 12.6898 14.5929 12.0569C15.2258 11.4241 15.5593 10.5365 15.5593 9.51285V5.26285C15.5593 4.23921 15.2258 3.35164 14.5929 2.71877C13.9601 2.08591 13.0725 1.75243 12.0488 1.75243H4.95842C3.93477 1.75243 3.0472 2.08591 2.41434 2.71877C1.78148 3.35164 1.448 4.23921 1.448 5.26285V9.51285C1.448 10.5365 1.78148 11.4241 2.41434 12.0569C3.0472 12.6898 3.93477 13.0233 4.95842 13.0233H7.79175C7.79983 13.0233 7.80191 13.0242 7.80057 13.0238C7.80031 13.0238 7.80014 13.0237 7.80014 13.0237L7.80027 13.0238C7.80056 13.0239 7.802 13.0246 7.80466 13.0264Z" fill="#0090FE" stroke="#0090FE"/>
        </svg>
    );
}

export default SvgIconCommentBlue;