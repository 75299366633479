import { useGetUserLotRelationsQuery } from 'features/users/usersSlice';
import { useSelector } from "react-redux";
import { useGetLotsQuery } from "../../../features/lots/lotSlice";
import { getLotsWithPmfText } from "../../../features/lots/lotUtil";
import { selectIsOwner } from "../../../features/user/userSlice";
import DropdownFilter from "../../DropdownFilter";

export default function AssociatedLotsDropDown({ title, identifier, onSelect, showEmpty, clientId, value, multiple, required }) {

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsQuery();
    userLotRelations = userLotRelations?.filter(r => r.relationType === 2 || r.relationType === 3 )

    const isOwner = useSelector(selectIsOwner);
    const filteredLots = (!userLotRelations?.length && isOwner) ? lots : lots?.filter(l => userLotRelations?.map(r => r.lotId)?.includes(l.id));

    return (
        <DropdownFilter
            title={title}
            identifier={identifier}
            values={getLotsWithPmfText(filteredLots)}
            onSelect={onSelect}
            value={value}
            showEmpty={showEmpty}
            multiple={multiple}
            required={required}
        />
    )
}