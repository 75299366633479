import { Grid, IconButton, Tooltip as MuiTooltip } from "@material-ui/core";
import DateRange from "components/DateRange";
import DropdownFilter from "components/DropdownFilter";
import { useGetUsersNoFilteringQuery } from "features/users/usersSlice";
import { IconDownload, IconFilters } from "icons";
import { groupBy } from "lodash";
import { useState } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import FormatService from "services/formatService";
import { theme } from "styles/theme";
import { arrayToObject } from "utils/object-util";
import GraphHeader from "../GraphHeader/GraphHeader";
import { IssueTrackerDashboardStyle } from "../style";
import ExportButton from "components/ExportButton/ExportButton";

export const AvgTimePerAssigneWidget = ({ dashboardData, calcTimeToResolution, graphHeight, selectedDashboardRange }) => {
    const classes = IssueTrackerDashboardStyle();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersNoFilteringQuery();

    const [assigneeFilter, setAssigneeFilter] = useState();
    const [dateRange, setDateRange] = useState();
    const [showFilters, setShowFilters] = useState();

    const userLookup = arrayToObject(users ?? []);

    const startDateBeginOfDay = new Date(dateRange?.startDate);
    startDateBeginOfDay.setHours(0, 0, 0, 0, 0);
    const startTime = startDateBeginOfDay.getTime();
    const endDateEndofDay = new Date(dateRange?.endDate);
    endDateEndofDay.setHours(23, 59, 59, 59, 59);
    const endTime = endDateEndofDay.getTime();

    const issueList = dateRange?.startDate && dateRange.endDate ? dashboardData?.issues?.filter(i => {
        const createdTime = new Date(i.createdDate).getTime();
        return createdTime >= startTime && createdTime <= endTime
    }) : dashboardData?.issues;

    const dataGrouped = [];

    const issuesByAssignee = groupBy(issueList, 'assignee');

    Object.keys(issuesByAssignee)?.map(assignee => {
        let unassigned = false;
        if (assignee === 'null') {
            unassigned = true;
        }
        const issuesForAssignee = issuesByAssignee[assignee] ?? [];
        const seconds = issuesForAssignee?.map(issue => calcTimeToResolution ? issue.timeToResolution ?? 0 : issue.timeToFirstResponse ?? 0);
        const avg = (seconds.reduce((a, b) => a + b) / seconds.length) ?? 0;
        dataGrouped.push({
            assignee,
            initials: unassigned ? 'UA' : userLookup?.[assignee]?.initials ?? userLookup?.[assignee]?.userName ?? '??',
            name: unassigned ? 'Unassigned' : userLookup?.[assignee]?.name ?? assignee,
            avg,
            display: FormatService.formatDuration(avg)
        })
    });

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.graphTootltip}>
                <p>
                    <div><b>{payload?.[0]?.payload.name}</b></div>
                    <div>{payload?.[0]?.payload?.display}</div>
                </p>
            </div>
        );
    };

    const dataToShow = assigneeFilter ? dataGrouped?.filter(g => g.assignee === assigneeFilter) : dataGrouped;

    const mapExportData = (a => {
        return {
            ['User']: a.name,
            ['Average']: a.display
        };
    })

    return (
        <div style={{ height: dataToShow?.length * 30 }}>
            <GraphHeader title={calcTimeToResolution ? 'Avg Time to Resolution' : 'Avg Time to First Response'} rightContent={<>
                <MuiTooltip title={showFilters ? 'Close filters' : 'Show filters'}>
                    <IconButton size="small" className={classes.filterIcon} onClick={() => setShowFilters(!showFilters)}> <IconFilters /></IconButton>
                </MuiTooltip>
                <ExportButton
                    className={classes.exportButton}
                    btnStyle='text' label=""
                    icon={<IconDownload />}
                    fileLabel="Export"
                    data={dataGrouped}
                    mapExportData={mapExportData}
                />
            </>} />
            {showFilters && <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DateRange
                        onSelect={(value) => {
                            setDateRange(value);
                        }}
                        dateValue={dateRange}
                    //minDate={selectedDashboardRange?.startDate}
                    //maxDate={selectedDashboardRange?.endDate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DropdownFilter
                        withTypeAhead
                        id="assignee"
                        value={assigneeFilter}
                        onSelect={(val) => setAssigneeFilter(val?.id)}
                        values={dataGrouped?.map(a => ({ id: a.assignee, name: a.name }))}
                        showEmpty
                    />
                </Grid>
            </Grid>}
            <ResponsiveContainer width="100%" height={showFilters ? "70%" : "95%"}>
                <BarChart data={dataToShow} layout="vertical"
                    margin={{ bottom: 15, top: 15 }}
                >
                    <XAxis
                        type="number"
                        hide
                        axisLine={false}
                        tickLine={false}

                    />
                    <YAxis
                        interval={0}
                        type="category"
                        axisLine={{ stroke: '#ccc' }}
                        dataKey="initials"
                        tickLine={false}
                        tick={{ color: theme.palette.primary.main, fontSize: '0.7em', fontWeight: 'bold' }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        barSize={20}
                        dataKey="avg"
                        fill={calcTimeToResolution ? '#84DFC1' : '#AADAFF'}
                    >
                        {dataToShow.map((entry, index) => (
                            <Cell key={`cell-${index}`} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )

}
