import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    hidden: {
        display: "none"
    }
})

const CsvDownload = ({href, fileName}) => {
    const classes = useStyles();
    const [ref, setRef] = useState(null);

    useEffect(() => {
        if (href) {
            ref.click();
        }
    }, [ref, href])

    return (
        <a href={href} download={fileName} ref={r => setRef(r)} className={classes.hidden}>Download</a>
    );
};

export default CsvDownload;
