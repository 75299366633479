import React from "react";
import { AssetReadinessStatusesStyle } from "./AssetReadinessStatusesStyle";
import AssetReadiness from "../AssetReadiness/AssetReadiness";
import { readinessStatuses, readinessTypes } from "../AssetReadiness/readinessUtil";
import ReadinessIndicator from "../AssetReadiness/ReadinessIndicator/ReadinessIndicator";
import ReadyBadge from "../AssetReadiness/ReadinessIndicator/ReadyBadge";
import ReadinessTooltipContent from "../AssetReadiness/ReadinessTooltipContent";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { useSelector } from "react-redux";
import ManualReadinessTooltipContent from "../AssetReadiness/ManualReadinessTooltipContent";
import EditReadiness from "../AssetReadiness/EditReadiness/EditReadiness";
import { CircularProgress, Grid } from "@material-ui/core";

const AssetReadinessStatuses = ({ readinessStatus = {}, showActivityList = false, editable = false, loading }) => {

    const classes = AssetReadinessStatusesStyle();

    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null, { skip: !isUserLoaded });

    const clientConfig = clientsWithConfigs?.find(c => c.id == readinessStatus.clientId);

    const isUsingManualReadiness = clientConfig?.config?.readinessConfig?.readinessType == readinessTypes.MANUAL;

    let readinessValues = isUsingManualReadiness ? clientConfig?.readinessOptions?.map(opt => {
        const vehicleReadiness = readinessStatus?.readinessValues?.find(r => r.readinessConfigId === opt.id);
        const readinessStatusForVehicle = vehicleReadiness?.status ?? readinessStatuses.RED;
        return {
            ...vehicleReadiness,
            status: readinessStatusForVehicle,
            text: opt?.[`statusText${readinessStatusForVehicle}`]
        }
    }) : [
        ...(readinessStatus?.washStatus ? [{
            status: readinessStatus?.washStatus ?? readinessStatus.RED,
            text: 'Washed'
        }] : []),
        ...(readinessStatus?.ameritInspectionStatus ? [{
            status: readinessStatus?.ameritInspectionStatus ?? readinessStatus.RED,
            text: 'Inspected'
        }] : [])
    ]

    const showReadiness = isUsingManualReadiness || readinessStatus.status;
    return (
        <>
            {showReadiness && <Grid container className={classes.readinessStatus}>

                {showActivityList && <Grid item xs={5} className={classes.status}>
                    {
                        readinessValues?.map(opt =>
                            <ReadinessIndicator
                                text={opt.text}
                                status={opt.status}
                            />
                        )
                    }
                </Grid>}
                <Grid item xs={showActivityList ? 5 : 10} className={classes.readiness}>
                    {isUsingManualReadiness ?
                        <ReadyBadge
                            status={Math.max(...readinessValues?.map(r => r.status))}
                            tooltipContent={
                                <ManualReadinessTooltipContent readinessValues={readinessValues} />
                            }
                        />
                        :
                        <ReadyBadge
                            status={readinessStatus.status}
                            tooltipContent={<ReadinessTooltipContent statusText={readinessStatus.status == readinessStatuses.GREEN ? 'Ready' : 'Not ready'} status={readinessStatus.status} readinessStatus={readinessStatus} />}
                        />}
                </Grid>
                {isUsingManualReadiness && editable && <Grid item xs={1}><EditReadiness vehicleId={readinessStatus?.vehicleId} readinessValues={readinessStatus?.readinessValues} clientId={readinessStatus.clientId} /></Grid>}
                {loading && <CircularProgress className={classes.loading} size={'1em'} />}
            </Grid>}
        </>
    );
};

export default AssetReadinessStatuses;
