import { makeStyles } from "@material-ui/core";

export const listSortStyle = makeStyles(theme => ({
    selectedSort: {
        /*border: '1px solid',
        borderColor: theme.palette.primary.main,*/
        background: '#ddd'
    },
    selectedSortIcon: {
        //fill: 'white'
    },
    select: {
        fontSize: '0.8em'
    }
}));