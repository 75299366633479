import { Grid, IconButton } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { readinessConfigStyles } from "./style";
import DeleteIcon from '@material-ui/icons/Delete';
import { readinessColors } from "features/vehicles/components/AssetReadiness/readinessUtil";

export const ReadinessConfigItem = ({ item, onChange, onDelete, validate = false }) => {
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    const classes = readinessConfigStyles();

    return (
        <Grid key={item.id} container alignItems="center" spacing={1}>
            <Grid xs={3} item>
                <Authorize profile={permissionProfiles.CLIENT_CONFIG.READINESS_CONFIG_EDIT}>
                    {!isLoadingActivityItems && <CustomInput
                        label="Activity"
                        elementType="dropdown"
                        values={activityItems ?? []}
                        required={true}
                        showEmpty={true}
                        touched
                        valid={item.activityItemId || !validate}
                        value={item.activityItemId}
                        onChange={val => onChange(item, 'activityItemId', val)}
                    />}
                </Authorize>
            </Grid>
            {Object.keys(readinessColors).map(color =>
                <Grid xs={2} item container key={`color_${color}`}>
                    <Grid xs={1} className={classes.colorBox} style={{ background: readinessColors[color] }}></Grid>
                    <Grid xs={11}>
                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.READINESS_CONFIG_EDIT}>
                            <CustomInput
                                type='input'
                                label="Display text"
                                required={true}
                                value={item?.[`statusText${color}`]}
                                onChange={val => onChange(item, `statusText${color}`, val)}
                                touched
                                valid={item?.[`statusText${color}`] || !validate}
                            />
                        </Authorize>
                    </Grid>
                </Grid>)}
            <Grid xs={1}>
                <Authorize profile={permissionProfiles.CLIENT_CONFIG.READINESS_CONFIG_ACTIONS}>
                    <IconButton
                        title={'Delete'}
                        edge='end'
                        aria-label='delete'
                        onClick={() => onDelete(item)}>
                        <DeleteIcon />
                    </IconButton>
                </Authorize>
            </Grid>
        </Grid >
    );
}