import { Grid, Tooltip } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import EntityAudit from "components/EntityAudit";
import { EntityAuditTooltip } from "components/EntityAudit/EntityAuditTooltip";
import { useState } from "react";
import FormatService from "services/formatService";
import BasicModal from "../../../../../../../../components/BasicModal";
import InspectionForm from "./InspectionForm";
import { InspectionReportStyles } from "./InspectionReportStyles";
import { useGetLotsQuery } from "features/lots/lotSlice";

const InspectionReport = ({ inspection }) => {
    const classes = InspectionReportStyles();
    const [openDialog, setOpenDialog] = useState(false);

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();

    return (
        <>
            <div className={`${classes.itemHeader} ${classes.report} ${openDialog ? classes.openReport : ""}`} onClick={() => { setOpenDialog(true) }}>
                <Grid container spacing={1}>
                    <Grid xs={3} item container spacing={1} direction="column">
                        <Grid item>
                            <b>Site Location</b>
                        </Grid>
                        <Grid item>
                            {lots?.find(l => l?.id === inspection?.lotId)?.name}
                        </Grid>
                    </Grid>
                    <Grid xs={3} item container spacing={1}>
                        <Grid item>
                            <b>Inspection Date</b>
                        </Grid>
                        <Grid item>
                            {FormatService.formatDateTimeNoConversion(inspection.inspectionDate)}
                        </Grid>
                    </Grid>
                    <EntityAudit entity={inspection} itemWidth={3} />
                    <Grid item>
                        {inspection.needsAttention && <Tooltip title={'Some answers in this inspection form need attention'}>
                            <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
                        </Tooltip>}
                    </Grid>
                </Grid>
            </div>
            <BasicModal
                open={openDialog}
                header={<div className={classes.inspectionTitle}>
                    Edit Site Inspection
                    <EntityAuditTooltip entity={inspection} />
                </div>}
                wide={true}
                handleClose={() => setOpenDialog(false)}
                component={<InspectionForm
                    lotId={parseInt(inspection.lotId)}
                    inspection={inspection}
                    handleClose={() => setOpenDialog(null)} />}
            />
        </>
    );
};

export default InspectionReport;
