import {makeStyles} from "@material-ui/core/styles";

export const ExportLotButtonStyle = makeStyles((theme) => ({
    popupContainer: {
        boxShadow: 'none',
        margin: 20,
    },
    btn: {
        marginLeft: 'auto',
        marginBottom: '1em'
    },
    checkboxList: {
        display: 'flex',
        flexDirection: 'column',
    },
    btnContainer: {
        margin: "70px",
        marginBottom: "30px",
        marginTop: "30px",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    button: {
        width: '140px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    }
}));