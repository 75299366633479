import { makeStyles } from "@material-ui/core";

export const ElementsStyles = makeStyles((theme) => ({
    titleElement: {
        fontSize: 20
    },
    image: {
        maxHeight: '10rem',
        maxWidth: '20rem'
    },
    addPhotoContainer: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        height: 140,
        width: 140,
        '&:hover': {
            opacity: 0.8
        }
    },
}));