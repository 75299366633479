import { makeStyles } from "@material-ui/core";

export const LotRankingListStyle = makeStyles((theme) => ({
    wrapper: {
        height: '500px',
        marginBottom: '5em'
    },
    searchWrapper: {
        padding: '1em'
    },
    timeFilterButton: {
        borderRadius: '7em / 7em'
    }
}));