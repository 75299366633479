import { Button, Grid, IconButton, Paper, Card } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { getLotsWithPmfText } from "features/lots/lotUtil";
import { useUpdateUserRestrictionClaimsMutation } from "features/users/usersSlice";
import { useEffect, useState } from "react";
import { isAuthorized} from "../../../../../components/Authorize/authorizeUtil";
import {permissionProfiles} from "../../../../../components/Authorize/permissionProfiles";
const { default: UsersPageStyle } = require("../UsersPageStyle");

const useStyles = UsersPageStyle;

const EditUserRestrictions = ({ user, clients, lots, userClaims, refetchUsers, userLotRelations }) => {
    const classes = useStyles();

    const [updateRestrictions] = useUpdateUserRestrictionClaimsMutation();

    const [lotClaims, setLotClaims] = useState([]);
    const [clientClaims, setClientClaims] = useState([]);
    const [hasChanged, setHasChanged] = useState(false);
    const [saving, setSaving] = useState(false);

    const lotIds = lots?.map(x => x.id);
    const clientIds = clients?.map(x => x.id);

    const canAddEmptyAccess = isAuthorized(permissionProfiles.USER_MANAGEMENT.ACCESS_LOT_CLIENT_EMPTY_FIELD);

    useEffect(() => {
        let lotIdClaims = userClaims?.filter(c => (c.claimType === 'lotId') && lotIds?.includes(parseInt(c.claimValue)));
        let clientIdClaims = userClaims?.filter(c => (c.claimType === 'clientId') && clientIds?.includes(parseInt(c.claimValue)));

        setLotClaims(lotIdClaims ?? []);
        setClientClaims(clientIdClaims ?? []);
    }, [userClaims]);

    const handleRestrictionsSave = async () => {
        if (!canAddEmptyAccess && (lotClaims.length === 0 || clientClaims.length === 0)) {
            NotificationProvider.error('You must add at least one lot and one client.');
            return;
        }

        setSaving(true);

        const objToSend = {
            userId: user?.id,
            lotClaimEntities: lotClaims,
            clientClaimEntities: clientClaims
        };

        let result = await updateRestrictions(objToSend);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
            refetchUsers();
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    };

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const onAddLot = (lotId) => {
        setHasChanged(true);
        let lotIdList = [...lotClaims];
        let toAdd = {
            userId: user?.id,
            claimType: "lotId",
            claimValue: lotId
        };
        lotIdList.push(toAdd);
        setLotClaims(lotIdList);
    };

    const onAddClient = (clientId) => {
        setHasChanged(true);
        let clientIdList = [...clientClaims];
        let toAdd = {
            userId: user?.id,
            claimType: "clientId",
            claimValue: clientId
        };
        clientIdList.push(toAdd);
        setClientClaims(clientIdList);
    };

    const onRemoveLot = (lotId) => {
        setHasChanged(true);
        let lotIdList = lotClaims;
        lotIdList = lotIdList.filter(l => l.claimValue != lotId);
        setLotClaims(lotIdList);
    };

    const onRemoveClient = (clientId) => {
        setHasChanged(true);
        let clientIdList = clientClaims;
        clientIdList = clientIdList.filter(c => c.claimValue != clientId);
        setClientClaims(clientIdList);
    };

    const getLotValues = () => {
        return getLotsWithPmfText(lots);
    };

    const clientValues = (val) => {
        let values = clients?.filter(c => !clientClaims?.find(l => l.claimValue === (c.id).toString()) || c.id === val);
        return values;
    };

    return (
        <>
            <Paper className={classes.paper}>
                <div className={classes.label}>Lot Access</div>
                <Grid item className={classes.grids}>
                    <CustomInput
                        id="lot"
                        label="Add Lot Access"
                        value={""}
                        elementType="dropdown"
                        onChange={val => onAddLot(val)}
                        values={getLotValues()}
                        showEmpty
                    />
                </Grid>
                {
                    lotClaims?.map(claim =>
                        <Card className={classes.list}>
                            <Grid item className={classes.restrictions} alignItems='center'>
                                {lotLookup[claim?.claimValue]?.name}
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => onRemoveLot(claim?.claimValue)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Card>
                    )
                }
                <div className={classes.label}>Client Access</div>
                <Grid item className={classes.grids}>
                    <CustomInput
                        id="clientId"
                        label="Add Client Access"
                        value={""}
                        elementType="dropdown"
                        onChange={val => onAddClient(val)}
                        values={clientValues()}
                        showEmpty
                    />
                </Grid>
                {
                    clientClaims?.map(claim =>
                        <Card className={classes.list}>
                            <Grid item className={classes.restrictions} alignItems='center'>
                                {clientLookup[claim?.claimValue]?.name}
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => onRemoveClient(claim?.claimValue)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Card>
                    )
                }
                {hasChanged ?
                    <Grid container className={classes.wrapperSave}>
                        <Grid item>
                            <Button
                                className={classes.resetButton}
                                onClick={handleRestrictionsSave}
                                color="primary"
                                variant="contained"
                                disabled={saving}
                            >
                                {saving ? 'Saving' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid> : null}
            </Paper>
        </>
    )
}

export default EditUserRestrictions;
