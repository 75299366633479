import { Chip, Grid } from "@material-ui/core";
import { setViewArchived } from "features/loads/loadsFiltersSlice";
import { useDispatch, useSelector } from "react-redux";

const LoadViewSelector = ({ }) => {

    const { viewArchived } = useSelector(state => state.loadFilters);
    const dispatch = useDispatch();

    return (
        <>
            <Grid item>
                <Chip  onClick={() => dispatch(setViewArchived(false))} label={`Active Loads`} color={viewArchived ? "" : "primary"} />
            </Grid>
            <Grid item>
                <Chip onClick={() => dispatch(setViewArchived(true))} label="Archived Loads" color={viewArchived ? "primary" : ""} />
            </Grid>
        </>
    )
}
export default LoadViewSelector;