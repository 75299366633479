import { Grid } from "@material-ui/core";
import { activityPriceConfig, activityPriceFields, toggleOptions } from "./pricingConsts";
import { useStyles } from "./AddActivityPrice/AddActivityPriceStyles";

function ActivityPriceDialogContent({ isLoading, activityPrice, onChange, activityItems, serviceProviders, lots, vehicleTypes, onChangeVehicleTypes, multiple }) {
    const classes = useStyles();
    
    return (
        <>
        {!(isLoading) &&
            <Grid container spacing={1} direction='column'>
                <Grid item className={classes.field}>
                    {activityPriceConfig[activityPriceFields.ACTIVITY]?.component(activityPrice?.activityItemId, activityItems, (val) => onChange("activityItemId", parseInt(val)), true, "Activity")}
                </Grid>
                <Grid item className={classes.field}>
                    {activityPriceConfig[activityPriceFields.SERVICE_PROVIDER]?.component(activityPrice?.serviceProviderId, serviceProviders, (val) => onChange("serviceProviderId", val), true, "Service Provider", multiple)}
                </Grid>
                <Grid item className={classes.field}>
                    {activityPriceConfig[activityPriceFields.LOT_OPTIONS]?.component(activityPrice?.priceAppliesTo, null, (event) => onChange("priceAppliesTo", event.target.value), true, "Select an option for the activity price to apply to")}
                </Grid>
                {activityPrice?.priceAppliesTo == toggleOptions.SPECIFIC_LOTS &&
                    <Grid item className={classes.field}>
                        {activityPriceConfig[activityPriceFields.LOT_TOGGLE]?.component(activityPrice?.includeNonPmf, (event) => onChange("includeNonPmf", event.target.checked), "Include Non PMF Lots")}
                    </Grid>
                }
                {activityPrice?.priceAppliesTo == toggleOptions.SPECIFIC_LOTS &&
                    <Grid item className={classes.field}>
                        {activityPriceConfig[activityPriceFields.LOT]?.component(activityPrice?.lotId, lots, (val) => onChange("lotId", val), true, "Lot", multiple, !activityPrice?.includeNonPmf)}
                    </Grid>
                }
                <Grid item className={classes.field}>
                    {activityPriceConfig[activityPriceFields.VEHICLE_TYPES]?.component(activityPrice?.vehicleTypeIdsList ?? (activityPrice?.id ? [null] : []), [{ name: 'All vehicle types', id: null }, ...vehicleTypes], (val) => onChangeVehicleTypes(val), false, "Vehicle Types")}
                </Grid>
                <Grid item className={classes.checkbox}>
                    {activityPriceConfig[activityPriceFields.NOT_SUPPORTED]?.component(activityPrice?.notSupported, (val) => onChange("notSupported", val.target.checked), "Service provider doesn’t offer this combination")}
                </Grid>
                <Grid item className={classes.field}>
                    {activityPriceConfig[activityPriceFields.PRICE]?.component(activityPrice?.price, null, (val) => onChange("price", parseInt(val)), !activityPrice?.notSupported, "Price")}
                </Grid>
                <Grid item className={classes.checkbox}>
                    {activityPriceConfig[activityPriceFields.USE_INVENTORY]?.component(activityPrice?.useInventory, (val) => onChange("useInventory", val.target.checked), "Uses Inventory?")}
                </Grid>
            </Grid>
        }
        </>
    )
}

export default ActivityPriceDialogContent;