import { Button, Checkbox, Grid, IconButton } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../../components/CustomInput";
import NotificationProvider from "../../../../../components/NotificationProvider";
import usePrompt from "../../../../../hooks/usePrompt";
import { IconDollar, IconLot, IconMarket, IconParkingLot, IconSettings, IconSlip } from "../../../../../icons";
import { useGetSubMarketsQuery } from "../../../../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { addSelectedLot, addSelectedSubmarket, removeSelectedLot, removeSelectedSubMarket, useAddClientLotMutation, useAddClientSubMarketMutation, useUpdateClientLotMutation } from "../lotConfigSlice";
import LotsConfigStyle from "../LotsConfigStyle";
import { LotsMarketsMenu } from "./LotsSubmarketsTreeView/LotsSubMarketsMenu";
import LotButton from "./LotButton";
import { arrayToObject } from "utils/object-util";
import { storageInvoiceTypes } from "features/invoice/storage/storageInvoiceConsts";

const useStyles = LotsConfigStyle

export const Lot = ({
    activeLot,
    activeLots,
    activeSubMarkets,
    onRemove,
    lotsEdited,
    setLotsEdited,
    invoiceCycleType
}) => {
    let [addClientLot, { isLoading: addingLot }] = useAddClientLotMutation()
    let [addClientSubMarket, { isLoading: addingSubMarket }] = useAddClientSubMarketMutation()
    let [updateClientLot, { isLoading: updatingLot }] = useUpdateClientLotMutation()
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: subMarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    lots = lots || [];
    subMarkets = subMarkets || [];

    let lotsLookup = arrayToObject(lots);
    let subMarketsLookup = arrayToObject(subMarkets);

    const classes = useStyles();

    const [lotToEdit, setLotToEdit] = useState({ ...activeLot });

    const { bulkEdit, selectedLots, selectedSubmarkets } = useSelector(state => state.lotConfigState);

    const isSaving = addingLot || updatingLot || addingSubMarket;

    useEffect(() => {
        setLotToEdit(activeLot);
        let key = activeLot.lotId;
        if (activeLot.isSubMarket) key = `${activeLot.id}_sub`
        if (activeLot.isNew && !lotsEdited?.includes(key)) {
            setLotsEdited([...lotsEdited, key]);
        }
    }, [activeLot]);

    const { triggerPrompt } = usePrompt();
    const dispatch = useDispatch();

    const onDelete = async () => {
        triggerPrompt({
            title: "Remove Lot",
            content: "Are you sure you want to remove the lot from the client?",
            onConfirm: async () => {
                const res = await onRemove(activeLot);
                if (res?.error) {
                    NotificationProvider.error('Failed to remove lot');
                } else {
                    NotificationProvider.success('Successfully removed lot');
                }
            },
        });

    }

    const onSaveLot = async () => {
        let res;
        if (lotToEdit.id) {
            const clientLots = lotToEdit?.isSubMarket ? [] : [lotToEdit];
            const clientSubmarkets = lotToEdit?.isSubMarket ? [lotToEdit] : [];
            res = await updateClientLot({ clientLots, clientSubmarkets });
        } else {
            if (lotToEdit?.isSubMarket) {
                res = await addClientSubMarket(lotToEdit);
            } else {
                res = await addClientLot(lotToEdit);
            }
            lotToEdit['id'] = res?.data?.data?.id;
        }
        if (!res?.error) {
            NotificationProvider.success('Successfully saved lot');
            lotToEdit['isNew'] = false;
            onClickEdit(false);
        } else {
            NotificationProvider.error('Failed to save lot');
        }
    }

    const onSelectRow = (selected, row) => {
        if (row.isSubMarket) {
            if (selected) {
                dispatch(addSelectedSubmarket(row.id));
            } else {
                dispatch(removeSelectedSubMarket(row.id));
            }
        } else {
            if (selected) {
                dispatch(addSelectedLot(row.id));
            } else {
                dispatch(removeSelectedLot(row.id));
            }
        }
    }

    const onSave = async () => {
        onSaveLot();
    }

    const onFieldValueChange = (fieldName, value) => {
        let lotName = lotToEdit?.name;
        let isSubMarket = lotToEdit?.isSubMarket;
        if (value) {
            if (fieldName == 'lotId') {
                lotName = lotsLookup?.[value]?.name;
                isSubMarket = false;
            }
            if (fieldName == 'subMarketId') {
                lotName = subMarketsLookup?.[value]?.name;
                isSubMarket = true;
            }
        }
        setLotToEdit({
            ...lotToEdit,
            [fieldName]: value,
            isSubMarket: isSubMarket,
            name: lotName
        })
    }

    const editField = (fieldName, label, numberOnly, icon) => (
        <Grid item alignItems='center' container className={classes.input} >
            <Grid xs={6} className={classes.label}>{label} </Grid>
            <Grid xs={6} className={classes.inputField}>
                {<CustomInput
                    value={lotToEdit?.[fieldName] ?? ''}
                    onChange={(val) => {
                        onFieldValueChange(fieldName, val)
                    }}
                    icon={icon}
                    elementType="input"
                    numberOnly={numberOnly}
                />}
            </Grid>
        </Grid>
    )

    const viewField = (fieldName, label, component, icon) => (
        <Grid item xs={3} className={classes.fieldWrapper}>
            <div className={classes.label}>{label} </div>
            <div className={classes.inputField}>
                {component ?? <span className={classes.viewFieldValue}>{lotToEdit?.[fieldName] ?? '-'}</span>}
            </div>
        </Grid>
    )

    const getLotAddress = (lotId) => {
        let lot = lotsLookup?.[lotId]
        let addressLine1 = lot?.addressLine1;
        let state = lot?.state;
        let city = lot?.city;
        return <div className={classes.addressWrapper}>{lot ? addressLine1 + ', ' + state + ', ' + city : ""}</div>
    }

    const getLot = (lotId) => {
        return lotsLookup?.[lotId];
    }

    const onClickEdit = (val = true) => {
        let key = activeLot.lotId;
        if (activeLot.isSubMarket) key = `${activeLot.id}_sub`
        if (lotsEdited?.includes(key) || !val) {
            let filteredList = lotsEdited.filter(l => l != key)
            setLotsEdited(filteredList);
        } else {
            setLotsEdited([...lotsEdited, key]);
        }
    }

    const isEdit = () => {
        let key = activeLot.lotId;
        if (activeLot.isSubMarket) key = `${activeLot.id}_sub`
        return lotsEdited?.includes(key);
    }

    const isMonthly = invoiceCycleType == storageInvoiceTypes.MONTHLY;

    return (

        <Grid container alignItems="center" spacing={3} className={` ${isEdit() && classes.listItemWrapperEdit}`}>
            <Grid item xs={lotToEdit?.isNew ? 12 : 12} container direction='column'>
                <Grid item container alignItems="center" className={classes.lotName}>
                    <Grid spacing={1} container item xs={isEdit() ? 12 : 9} alignItems='center' >
                        {lotToEdit?.isNew ?
                            <LotsMarketsMenu
                                onSelectLot={(val) => {
                                    onFieldValueChange('subMarketId');
                                    onFieldValueChange('lotId', val);
                                }}
                                onSelectSubMarket={(val) => {
                                    onFieldValueChange('lotId');
                                    onFieldValueChange('subMarketId', val);
                                }}
                                selectedLot={lotToEdit?.isSubMarket ? undefined : lotToEdit?.lotId}
                                selectedSubMarket={lotToEdit?.isSubMarket ? lotToEdit.subMarketId : undefined}
                                filterLots={activeLots?.map(l => l.lotId).filter(l => l)}
                                filterSubMarkets={activeSubMarkets?.map(l => l.subMarketId)}
                            />
                            :
                            <>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_ALIAS}>
                                    <Grid item>
                                        <div>{lotToEdit?.name}</div>
                                        {getLotAddress(lotToEdit?.lotId)}
                                    </Grid>
                                </Authorize>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_NAME}>
                                    <Grid container spacing={1} alignItems='center'>
                                        {bulkEdit && <Grid item><Checkbox
                                            checked={(lotToEdit?.isSubMarket && selectedSubmarkets.includes(lotToEdit?.id)) || selectedLots.includes(lotToEdit?.id)}
                                            onChange={(e, value) => { onSelectRow(value, lotToEdit) }}
                                        /></Grid>}
                                        {!bulkEdit && <Grid item>{lotToEdit?.isSubMarket ? <div><IconMarket /></div> : <div><IconParkingLot width={20} height={20} /></div>}</Grid>}
                                        <Grid item>
                                            <div>{lotToEdit?.name}</div>
                                            {getLotAddress(lotToEdit?.lotId)}
                                        </Grid>
                                    </Grid>
                                </Authorize>
                            </>
                        }
                    </Grid>
                    {!isEdit() && <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_EDIT}>
                        <Grid item alignItems="center" justifyContent="flex-end" container xs={3} className={classes.lotActions}>

                            <LotButton label={""} icon={<IconLot />}
                                disabled={false}
                                lot={getLot(lotToEdit?.lotId)} />

                            <IconButton onClick={onClickEdit}>
                                {<IconSettings />}
                            </IconButton>
                            <IconButton title={'Delete'} onClick={() => onDelete()}>
                                <DeleteOutline />
                            </IconButton>
                        </Grid>
                    </Authorize>}
                </Grid>

                <Grid item>
                    <>
                        {isEdit() ? <Grid container spacing={1} justifyContent="flex-end">
                            {editField('numberOfSpots', 'Total spots', true, <IconSlip />)}
                            {editField('pricePerSpot', 'Price per spot', true, <IconDollar />)}
                            {isMonthly && editField('overage', 'Overage', true, <IconDollar />)}
                            {!lotToEdit?.isSubMarket && editField('clientAlias', 'Client alias', false)}
                            <Grid item className={classes.btnWrapper}>
                                <Button
                                    onClick={() => {
                                        if (lotToEdit.isNew) {
                                            onRemove(lotToEdit);
                                            return;
                                        }
                                        onClickEdit(false);
                                        setLotToEdit(activeLot)

                                    }}
                                    className={classes.button}
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={onSave}
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    disabled={isSaving}
                                >
                                    {isSaving ? "Saving..." : "Save"}
                                </Button>
                            </Grid>
                        </Grid > : <Grid container>
                            {viewField('numberOfSpots', 'Total spots', <span className={classes.numOfSpots}>{lotToEdit?.['numberOfSpots'] > 0 ? lotToEdit?.['numberOfSpots'] : <AllInclusiveIcon className={classes.infinity} />}</span>)}
                            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                                {viewField('pricePerSpot', 'Price per spot', <span className={classes.pricePerSpot}>${lotToEdit?.['pricePerSpot'] ?? '0'}</span>)}
                            </Authorize>
                            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                                {isMonthly && viewField('overage', 'Overage', <span className={classes.overage}>${lotToEdit?.['overage'] ?? '0'}</span>)}
                            </Authorize>
                            {!lotToEdit?.isSubMarket && <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                                {viewField('clientAlias', 'Client alias')}
                            </Authorize>}
                        </Grid>}
                    </>
                </Grid>

            </Grid>
        </Grid>
    )
}


