import { Box, Button, Grid } from '@material-ui/core';
import { IconShop } from 'icons';
import { CreateWorkOrderStyles } from '../CreateWorkOrderStyles';

const ServiceProviderSelectorItem = ({ props, option, isNarrowScreen }) => {
    const classes = CreateWorkOrderStyles();

    return (
        <Box  {...props}>
            <Grid container xs={12} alignItems='center' justifyContent='space-between'>
                <Grid item >
                    <Grid container alignItems='center'>
                        <Grid item className={classes.itemIcon}>
                            <IconShop height='27' width='27' />
                        </Grid>
                        <Grid item >
                            <label className={classes.itemLabel}>{option?.name}</label>
                        </Grid>
                    </Grid>
                </Grid>
                {!isNarrowScreen && <Grid item>
                    <Button size='small' variant='contained' color='primary'>
                        Select Service Provider
                    </Button>
                </Grid>}
            </Grid>
        </Box>
    )
}

export default ServiceProviderSelectorItem;