import { createSlice } from "@reduxjs/toolkit";

export const auditFiltersSlice = createSlice({
    name: 'auditFilters',
    initialState: {
        searchVal: '',
        filters: {
            status: { value: '' },
        },

    },
    reducers: {
        setAuditFilters: (state, action) => {
            state.filters = action.payload;
        },
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
    },
});

export const { setAuditFilters, setSearchVal } = auditFiltersSlice.actions;
export default auditFiltersSlice.reducer;
