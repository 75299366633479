import { Paper } from "@material-ui/core";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import LATaskList from '../LAsTaskList/LAsTaskList';
import SupersTaskList from '../SupersTaskList/supersTaskList';
import { UsersTaskPageStyle } from "./style";

const UsersTaskPage = () => {
    const classes = UsersTaskPageStyle();

    return (
        <>
            <Paper className={classes.tabsWrapper} >
                <TabsWrapper tabs={[
                    {
                        label: 'Supervisor Tasks',
                        component: <div className={classes.listWrapper}>
                            <SupersTaskList />
                        </div>
                    },
                    {
                        label: 'LA Tasks',
                        component: <div className={classes.listWrapper}>
                            <LATaskList />
                        </div>
                    }
                ]} />
            </Paper>
        </>
    )
}
export default UsersTaskPage;