import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'customReport';
export const customReportApi = baseApi.injectEndpoints({
    reducerPath: 'customReportApi',
    tagTypes: [apiTags.CUSTOM_REPORT],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getReportByTypeForClient: builder.query({
            query: ({ formType, clientId }) => `${apiPrefix}/getByType?formType=${formType}&${clientId ? `clientId=${clientId}` : ''}`,
            providesTags: [apiTags.CUSTOM_REPORT],
        }),
        createReport: builder.mutation({
            query: ({ id, formId, title, description, clientId, formType, sections }) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: {
                    id, 
                    formId,
                    title,
                    description,
                    clientId,
                    formType,
                    sections
                }
            }),
            invalidatesTags: [apiTags.CUSTOM_REPORT]
        }),
        updateFormResponse: builder.mutation({
            query: ({ id, clientId, formId, lotId, vehicleId, answers }) => ({
                url: `${apiPrefix}/updateFormResponse`,
                method: "POST",
                body: {
                    id, 
                    clientId,
                    formId,
                    lotId,
                    vehicleId,
                    answers
                }
            }),
            invalidatesTags: [apiTags.CUSTOM_REPORT, apiTags.VEHICLE, apiTags.VEHICLES]
        }),
    }),
});

export const {
    useGetReportByTypeForClientQuery,
    useCreateReportMutation,
    useUpdateFormResponseMutation,
} = customReportApi;
