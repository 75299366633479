import { makeStyles } from "@material-ui/core";

export const BasicMarkerStyle = makeStyles((theme) => ({
    markerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0.1rem',
    },
    lotIcon: {
        position: 'absolute',
        marginTop: '-6px'
    },
    textWrapper: {
        borderColor: '#0061AA',
        background: '#0061AA',
        padding: '3px 6px',
        color: 'white',
        borderRadius: '4px',
    },
    coloredTextWrapper: {
        borderColor: '#E02B1D',
        background: '#E02B1D',
    },
    coloredSubTextWrapper: {
        backgroundColor: '#0090fe'
    },
    mixedNumber: {
        color: 'white',
        fontSize: 11,
        fontWeight: 700,
        borderRadius: '50%',
        fontFamily: 'Montserrat',
        width: 15,
        height: 15,
        backgroundColor: '#17314e',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '4px',
        lineHeight: '13.4px'
    },
    whiteIcon: {
        position: 'absolute',
        marginTop: '-6px',
        fill: 'white',
        '& path': {
            fill: 'white',
            stroke: 'white'
        }
    },
}));
