import { Paper } from "@material-ui/core";
import _ from 'lodash';
import { useGetClientsWithConfigsQuery } from '../../../../clients/clientConfigSlice';
import { groupLegTypes } from '../../../enums';
import GroupLoadForm from "../LoadsForm/GroupLoadForm";
import GroupLegsFormStyles from "./GroupLegsFormStyles";
import ActivityForm from "../ActivityForm/ActivityForm";

const useStyles = GroupLegsFormStyles;

const GroupLegsForm = ({ loadGroup, setLoadGroup, clientConfigs, setClientConfigs, legIndex = 0 }) => {

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);

    clientsWithConfigs = clientsWithConfigs || [];

    const classes = useStyles();

    const isLoading = () => isLoadingClientsWithConfigs;


    return (
        <>
            <Paper className={classes.formContainer}>
                {loadGroup?.loads?.length > 1 &&
                    <h3 className={classes.legTitle}>
                        Leg {legIndex + 1}/{loadGroup?.loads?.length}
                    </h3>
                }
                <form id="createLoadForm" key={legIndex} className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                    {loadGroup?.loads?.[legIndex]?.type == groupLegTypes.TRANSPORT ?
                        <GroupLoadForm loadGroup={loadGroup} setLoadGroup={setLoadGroup} clientConfigs={clientConfigs} setClientConfigs={setClientConfigs} legIndex={legIndex} /> :
                        <ActivityForm loadGroup={loadGroup} setLoadGroup={setLoadGroup} clientConfigs={clientConfigs} setClientConfigs={setClientConfigs} legIndex={legIndex} />
                    }
                </form>
            </Paper>
        </>
    );
};

export default GroupLegsForm;
