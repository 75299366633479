import { Grid } from '@material-ui/core';
import { IconComment } from 'icons';
import { useEffect, useState } from 'react';

const NoteView = ({ note }) => {

    const [html, setHTML] = useState({ __html: "" });

    useEffect(() => {
        setHTML({ __html: note.content })
    }, [note]);

    return (
        <Grid container alignItems='center'>
            <Grid style={{ paddingRight: '.3em', marginTop: '.5em' }}><IconComment width='13' height='13'/></Grid> <Grid><div dangerouslySetInnerHTML={html} /></Grid>
        </Grid>
    );
}
export default NoteView;