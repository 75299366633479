import React, {useEffect, useRef, useState} from 'react';
import { Grid, Paper } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from "../../styles/theme";
import {debounce} from "lodash";

const useStyles = makeStyles({
    fullWidth: {
        width:  "100%"
    },
    icon: {
        fontSize: '500%',
        marginRight: "8px"
    },
    iconDark: {
        fontSize: '500%',
        fill: '#000',
        marginRight: '-3rem',
    },
    card: {
        margin: '1rem',
        padding: '2rem',
    },
    noteCard: {
        backgroundColor: '#fbfaff',
    },
    titleRow: {
        marginBottom: "32px"
    },
    title: {
        margin: 0,
        padding: 0,
        fontWeight: 500,
        marginRight: "8px",
        color: theme.typography.color
    },
    detailLabel: {
        marginRight: "4px",
        fontWeight: 600
    },
    dateIn: {
        color: theme.palette.success.dark
    },
    dateOut: {
        color: theme.palette.error.dark
    },
    details: {
        color: theme.palette.secondary.light,
        fontSize: 12,
    },
    detail: {
        minWidth: "290px",
        position: "relative",
    },
    vehicleDetail: {
        display: 'flex',
    },
    vehicleDetailLabel: {
        marginRight: '4px',
    },
    parkingDetail: {
        marginBottom: 8,
    },
    imageContainer: {
        position: "relative",
        width: 223,
        height: 120,
        margin: "0 1rem 1rem",
        borderRadius: 4,
        overflow: "hidden"
    },
    image: {
        position: "absolute",
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        opacity: 0,
        transition: "opacity 1s ease",
        objectFit: "cover"
    },
    placeholder: {
        height: '100%',
        width: '100%',
        background: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    placeholderIcon: {
        opacity: ".2",
        width: "5rem",
        height: "5rem"
    },
    imageVisible: {
        opacity: 1
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    bordered: {
        position: "relative",
        "&::before": {
            content: "''",
            width: 1,
            height: "100%",
            background: theme.palette.neutral.light,
            position: "absolute",
            top: 0,
            left: 0,
        }
    },
    assetInformation: {
        flexGrow: 1,
        minWidth: 700
    },
    legend: {
        minWidth: 330
    },
    legendHeader: {
        marginBottom: 0
    },
    legendContainer: {
        marginBottom: 20
    },
    legendDetail: {
        fontSize: 10,
        marginLeft: 8,
        marginBottom: 4
    },
    tireSection: {
        marginBottom: 17
    },
    tireSides: {
        fontSize: 20,
        fontWeight: 500,
        height: 294,
        width: 107,
        padding: "14px 0 22px"
    },
    tireSide: {
        color: theme.palette.primary.main
    },
    tires: {
        flexGrow: 1
    },
    tireRow: {
        paddingBottom: 24,
        marginBottom: 24,
        borderBottom: `1px solid ${theme.palette.neutral.medium}`
    },
    tireContainer: {
        paddingLeft: 18,
        marginRight: 28,
        height: 123,
        borderLeft: `solid 1px ${theme.palette.primary.main}`
    },
    tireLabel: {
        fontSize: 14
    },
    tireImage: {
        width: 84
    },
    damageHeader: {
        borderBottom: `solid 1px ${theme.palette.neutral.medium}`,
        paddingBottom: 20
    },
    damageSlider: {
        position: "relative",
        width: 778,
        height: 10,
        marginBottom: 12,
        background: `linear-gradient(to right, ${theme.palette.warning.dark} 1%, ${theme.palette.warning.dark} 34%, ${theme.palette.error.dark} 100%);`,
        borderRadius: 5
    },
    damageSliderIndicator: {
        width: 24,
        height: 24,
        background: "#f3f5f6",
        border: "solid 1px #aab5bd",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: 120,
        transform: "translate(0, -50%)"
    },
    damageSliderLabel: {
        color: theme.palette.neutral.medium,
        fontSize: 14
    },
    damageDetails: {
        color: "#6f818f"
    },
    damageDetailColumn: {

    },
    damageDetailRow: {
        marginBottom: 16
    },
    damageDetailLabel: {
        fontWeight: 600,
        marginRight: 6,
    },
    damageDetail: {
        fontSize: 12
    }
});

const conditionReport = {
    vin: "JH4KA3160LC017215",
    year: "2015",
    make: "Freightliner",
    model: "Cascadia",
    lotId: 4,
    details: [
        {label: "Engine", value: "DETROIT DD13"},
        {label: "HP", value: "450 (RATED) 450 (ECU HP)"},
        {label: "Clean air idele", value: "Yes"},
        {label: "Sleeper Size in", value: "NO (N/A)"},
        {label: "Wheelbase", value: "179"},
        {label: "Mileage", value: "374980 (ODOM)"},
        {label: "Trans Make Model", value: "EATON"},
        {label: "Trans Type", value: "Manual 10 SPD"},
        {label: "Suspension", value: "Air Ride"},
        {label: "Front Axle", value: "AXLE ALLIANCE CO"},
        {label: "Axle Type Ratio", value: "Axle Tandem, 3.42"},
        {label: "3rd Axle", value: "NONE"},
        {label: "GVW", value: "5200"},
        {label: "Power Steering", value: "YES"},
        {label: "Power Windows", value: "DUAL"},
        {label: "Air Cond", value: "YES"},
        {label: "Fuel Type", value: "Diesel"},
        {label: "Fuel Tanks", value: "2 Aluminium / 70 / 70"},
        {label: "Chassis Fairings", value: "YES"},
        {label: "Visor", value: "FIBERGLASS"},
        {label: "Side Extendors", value: "NO"},
        {label: "Body Type / Cab Type", value: "Truck / Convention"},
        {label: "Fifth Wheel", value: "AIR SLIDE"},
        {label: "Tire Size F / R", value: "275/80 R22.5 / 275/80 R22.5"},
        {label: "Steer Tire", value: "BFGOODRICH / RECAPS"},
        {label: "Cluster Test", value: "Yes/Pass"},
        {label: "Frame", value: "Integral"},
        {label: "Interior Type / Level", value: "CUSTOM / MID"},
        {label: "Upholstery", value: "CLOTH"},
        {label: "Refrigerator", value: "No"},
        {label: "Seat Suspension", value: "LH AIR(DUAL)"},
        {label: "Bumper", value: "AERO"},
        {label: "Air Horns", value: "SINGLE"},
        {label: "New Paint / Color / Int", value: "NO / WHITE / Gray"},
        {label: "Identification", value: "None"},
        {label: "Exhaust", value: "SINGLE VERTICAL (STEEL)"},
        {label: "Air Cleaners", value: "INTERNAL"},
        {label: "Front Wheels / Rear Wheels", value: "AL / AL"},
        {label: "Radio", value: "AM/FM CD WB"},
        {label: "PTO", value: "NO"},
        {label: "Mirrors", value: "DUAL POWER"},
        {label: "Wet Line Kit", value: "NO"},
        {label: "Brake Type", value: "ABS, AIR, DRUM"},
        {label: "Engine Brake", value: "JAKE"},
        {label: "Dual Bunks", value: "N/A"},
        {label: "Steer Axle Brakes", value: "90% / 90%"},
        {label: "Drive Axle Brakes", value: "70% / 70%"},
        {label: "Backing Plates", value: "Front R / Rear N"},
        {label: "Engine Serial", value: "50264591"},
        {label: "Engine CPL (Hours)", value: "N/A"},
        {label: "Engine Family", value: "EDDXH12"},
        {label: "APU / Conditions Make Model", value: "N/A"},
        {label: "Miscellaneous", value: "Yes Pass"},
        {label: "CARB Equipment", value: "DEF / Passed Snap"},
    ]
};

const ConditionReportDetail = ({label, value, firstX}) => {
    const classes = useStyles();
    const ref = useRef();
    const [bordered, setBordered] = useState(false);

    useEffect(() => {
        const onResize = debounce(() => {
            let rect = ref.current.getBoundingClientRect();
            setBordered(firstX && rect && rect.x > firstX);
        }, 100);
        window.addEventListener("resize", onResize);
    }, [bordered]);

    return (
        <Grid item className={`${classes.detail} ${bordered ? classes.bordered : ""}`} ref={ref}>
            <Grid container>
                <div className={`${classes.detailLabel} ${bordered ? classes.bordered : ""}`}>{label}:</div>
                <div>{value}</div>
            </Grid>
        </Grid>
    );
};

const ConditionReportDetailList = ({details}) => {
    const classes = useStyles();

    const firstRef = useRef();
    const [firstX, setFirstX] = useState(null);

    useEffect(() => {
        let rect = firstRef.current.getBoundingClientRect();
        setFirstX(rect.x)
    }, [firstX]);

    return (
        <Grid container direction="row" alignItems="center" spacing={2} className={classes.details} ref={firstRef}>
            <ConditionReportDetail label={"VIN"} value={conditionReport.vin} />
            <ConditionReportDetail label={"Year"} value={conditionReport.year} firstX={firstX} />
            <ConditionReportDetail label={"Make"} value={conditionReport.make} firstX={firstX} />
            <ConditionReportDetail label={"Model"} value={conditionReport.model} firstX={firstX} />
            {
                details.map((detail, i) => (
                    <ConditionReportDetail label={detail.label} value={detail.value} firstX={firstX} key={i}/>
                ))
            }
        </Grid>
    );
};

const IconHeader = ({icon, header, className, children}) => {
    const classes = useStyles();

    return (
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2} className={`${classes.titleRow} ${className}`}>
            <Grid item className={classes.fullWidth}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Grid container>
                            <img src={icon} className={classes.icon} />
                            <h2 className={classes.title}>{header}</h2>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const Tire = ({numTires}) => {
    const classes = useStyles();

    const tireImages = [];
    for (let i = 0; i < numTires; i++) {
        tireImages.push(<Grid item><img src="/images/tire.png" className={classes.tireImage} alt=""/></Grid>);
    }

    return (
        <Grid item>
            <Grid container direction="column" justifyContent="space-between" className={classes.tireContainer}>
                <Grid item className={classes.tireLabel}>14 / 32nd Highway / V</Grid>
                { tireImages }
                <Grid item className={classes.tireLabel}>14 / 32nd Highway / V</Grid>
            </Grid>
        </Grid>
    );
};

const DamageSlider = () => {
    const classes = useStyles();

    return (
        <Grid container direction="column">
            <Grid item>
                <div className={classes.damageSlider}>
                    <div className={classes.damageSliderIndicator}></div>
                </div>
            </Grid>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item className={classes.damageSliderLabel}>Light</Grid>
                    <Grid item className={classes.damageSliderLabel}>Severe</Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

const ConditionReportPage = () => {
    const classes = useStyles();
    let vehicleDetails = {};

    return (
        <>
            <Paper className={classes.card} elevation={1}>
                <IconHeader icon="/icons/icon-wheel.svg" header="Asset Information" />
                <ConditionReportDetailList details={conditionReport.details} />
            </Paper>
            <Grid container>
                <Grid item className={classes.assetInformation}>
                    <Paper className={classes.card} elevation={1}>
                        <IconHeader icon="/icons/icon-tire.svg" header="Asset Tires" />
                        <Grid container className={classes.tireSection}>
                            <Grid item>
                                <Grid container direction="column" justifyContent="space-around" className={classes.tireSides}>
                                    <Grid item className={classes.tireSide}>RIGHT</Grid>
                                    <Grid item>FRONT</Grid>
                                    <Grid item className={classes.tireSide}>LEFT</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.tires}>
                                <Grid container className={classes.tireRow}>
                                    <Tire numTires={1}/>
                                    <Tire numTires={2}/>
                                    <Tire numTires={2}/>
                                </Grid>
                                <Grid container>
                                    <Tire numTires={1}/>
                                    <Tire numTires={2}/>
                                    <Tire numTires={2}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item className={classes.legend}>
                    <Paper className={classes.card} elevation={1}>
                        <IconHeader icon="/icons/icon-brake.svg" header="Break Legend" className={classes.legendHeader} />
                        <div className={classes.legendContainer}>
                            <div className={classes.legendDetail}>BD = DRUM HAS MORE THAN 1/16 INCH LIP</div>
                            <div className={classes.legendDetail}>CL = CRACKED LINING</div>
                            <div className={classes.legendDetail}>LS = LEAKED SEAL(S)</div>
                        </div>
                        <IconHeader icon="/icons/icon-tire.svg" header="Tire Legend" className={classes.legendHeader} />
                        <div className={classes.legendContainer}>
                            <div className={classes.legendDetail}>C = CUT D = DRY ROT F = FLAT</div>
                            <div className={classes.legendDetail}>M = MISMATCHED TIRES / CASING</div>
                            <div className={classes.legendDetail}>MA = MISMATCHED AXLES (FRONT vs REAR)</div>
                            <div className={classes.legendDetail}>V = VIRGIN</div>
                            <div className={classes.legendDetail}>W = IRREGULAR WEAR</div>
                            <div className={classes.legendDetail}>R = RECAP: 1 = ONCE,</div>
                            <div className={classes.legendDetail}>2 = TWICE, 3 = 3 TIMES</div>
                        </div>
                        <IconHeader icon="/icons/icon-tire.svg" header="Tread Legend" className={classes.legendHeader} />
                        <div className={classes.legendContainer}>
                            <div className={classes.legendDetail}>H = HIGHWAY</div>
                            <div className={classes.legendDetail}>L = LUG</div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Paper className={classes.card} elevation={1}>
                <IconHeader icon="/icons/icon-warning.svg" header="Asset Damages" className={classes.damageHeader}>
                    <Grid container justifyContent="flex-end">
                        <DamageSlider percentage={30} />
                    </Grid>
                </IconHeader>
                <Grid container direction="row" className={classes.damageDetails}>
                    <Grid container>
                        <Grid item>
                            <Grid container className={classes.damageDetailColumn}>
                                <Grid container alignItems="flex-end" className={classes.damageDetailRow}>
                                    <Grid item className={classes.damageDetailLabel}>Location:</Grid>
                                    <Grid item className={classes.damageDetail}>Staten Island</Grid>
                                </Grid>
                                <Grid container alignItems="flex-end" className={classes.damageDetailRow}>
                                    <Grid item className={classes.damageDetailLabel}>Severity:</Grid>
                                    <Grid item className={classes.damageDetail}>Light</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Grid container className={classes.damageDetailColumn}>
                                <Grid container alignItems="flex-end" className={classes.damageDetailRow}>
                                    <Grid item className={classes.damageDetailLabel}>Damage Type:</Grid>
                                    <Grid item className={classes.damageDetail}>Unspecified</Grid>
                                </Grid>
                                <Grid container alignItems="flex-end">
                                    <Grid item className={classes.damageDetailLabel}>Description:</Grid>
                                    <Grid item className={classes.damageDetail}>Not Found</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>
        </>
    );
};

export default ConditionReportPage;
