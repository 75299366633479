import { Grid, Paper } from "@material-ui/core";
import TableContainer from '@material-ui/core/TableContainer';
import TableCustomizationPanel from "components/DataTable/TableCustomizationPanel";
import TableViewsSelector from 'components/DataTable/TableViews';
import ExportButton from 'components/ExportButton/ExportButton';
import { userPreferencesTypes } from 'components/UserPreferences/userPreferencesConsts';
import { useGetUserPreferencesQuery, useResetUserPreferenceMutation, useUpdateUserPreferenceMutation } from 'components/UserPreferences/userPreferencesSlice';
import { IconDownload } from 'icons';
import { debounce, orderBy } from 'lodash';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrayToObject } from "utils/object-util";
import KanbanTableContent from "./KanbanTableContent";
import { KanbanTableStyle } from "./KanbanTableStyle";

const KanbanTable = ({ columns, data, mapExportData, fieldsToExport, tableId, filters, setIssuesTrackerToEdit, columnsSorted, setColumnsSorted }) => {
    const classes = KanbanTableStyle();

    const [selectedViewId, setSelectedViewId] = useState(0);
    const [initialUserPrefSet, setInitialUserPrefSet] = useState(false);

    let { data: userColumnsPreferences, error, isLoadingUserPref } = useGetUserPreferencesQuery({ componentId: tableId, type: userPreferencesTypes.COLUMNS, viewId: selectedViewId || 0 }, { skip: !tableId });
    const [updatePref, { isLoading: isSaving }] = useUpdateUserPreferenceMutation();
    const [resetPref, { isLoading: isReset }] = useResetUserPreferenceMutation();

    const { viewArchived } = useSelector(state => state.issuesTrackerFilters);

    useEffect(() => {
        if (userColumnsPreferences?.columns?.length) {
            setColumnsByPref();
        } else {
            resetToDefault();
        }
    }, []);

    const resetToDefault = () => {
        const columnsToSet = columns?.filter(col => col.selectedByDefault !== false).map((col, index) => ({ ...col, order: index }));
        setColumnsSorted(columnsToSet);
        return columnsToSet;
    }

    const setColumnsByPref = () => {
        const columnPrefLookup = arrayToObject(userColumnsPreferences?.columns, 'key');
        const selectedColumns = orderBy(columns?.filter(col => col.customizable === false || columnPrefLookup?.[col.key]).map((col, index) => ({ ...col, name: columnPrefLookup?.[col.key]?.name, order: columnPrefLookup?.[col.key]?.order ?? -1, index })), 'order');
        setColumnsSorted(selectedColumns);
    }

    useEffect(() => {
        if (!initialUserPrefSet) {
            if (userColumnsPreferences?.columns?.length) {
                setColumnsByPref();
                setInitialUserPrefSet(true);
            } else {
                resetToDefault();
            }
        }
    }, [userColumnsPreferences]);

    const getData = () => {
        const columnsToSave = resetColumnsSorted(columnsSorted);
        let filtersToSave = Object.entries(filters).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }));
        return {
            filters: filtersToSave,
            columns: columnsToSave
        }
    }

    const saveViewColumns = debounce((listToSave) => {
        const prefToSave = { columns: listToSave.map(col => ({ key: col.key, name: col.name, order: col.order })) };
        updatePref({ componentId: tableId, type: userPreferencesTypes.COLUMNS, ...prefToSave, viewId: selectedViewId })
    }, 500)

    const onSelectView = (val) => {
        setInitialUserPrefSet(false);
        setSelectedViewId(val);
    }

    const resetColumnsSorted = (newColList) => {
        const newList = newColList?.map((col, index) => ({ ...col, order: index }));
        setColumnsSorted(newList);
        return newList;
    }

    const onColumnSelectionChange = (selectedColumns) => {
        const existingCols = arrayToObject(columnsSorted, 'key');
        const newCols = arrayToObject(selectedColumns, 'key')
        const newList = [...columnsSorted?.filter(col => newCols[col.key])];
        selectedColumns.map(col => {
            if (!existingCols?.[col.key]) {
                newList.push(col);
            }
        });
        const listToSave = resetColumnsSorted(newList);
        saveViewColumns(listToSave);
    }

    const onColumnTitleChange = (selectedColumns) => {
        const existingCols = arrayToObject(columnsSorted, 'key');
        const newCols = arrayToObject(selectedColumns, 'key')
        const newList = [...columnsSorted?.filter(col => newCols[col.key])];
        selectedColumns.map(col => {
            if (existingCols?.[col.key] && existingCols?.[col.key]?.name != col?.name) {
                const insertIndex = newList?.findIndex(l => l.key == col.key);
                newList[insertIndex] = col;
            }
        });
        const listToSave = resetColumnsSorted(newList);
        saveViewColumns(listToSave);
    }

    const reset = async () => {
        await resetPref({ componentId: tableId, type: userPreferencesTypes.COLUMNS });
        setInitialUserPrefSet(false);
    }

    const exportData = (row) => {
        const exportData = mapExportData(row);
        const filteredData = {};
        for (const field of fieldsToExport) {
            filteredData[field.name] = exportData[field.name];
        }
        return filteredData;
    }

    return (
        <TableContainer component={Paper} className={classes.tableContainer} >
            <Grid alignItems='center' container className={classes.tableActions} justifyContent='space-between'>
                <Grid item className={classes.views}>
                    <TableCustomizationPanel
                        columns={columns}
                        onSaveColumns={onColumnSelectionChange}
                        selectedColumns={columnsSorted}
                        isCustomized={initialUserPrefSet}
                        onReset={reset}
                        resetting={isReset}
                        title={`Customize ${viewArchived ? 'Archived' : 'Active'} View`}
                    />
                    <div className={classes.tableViewsSelector}>
                        <TableViewsSelector
                            selectedView={selectedViewId}
                            onSelectView={onSelectView}
                            tableId={tableId}
                            getData={getData}
                        />
                    </div>
                </Grid>
                {mapExportData && <Grid item>
                    <ExportButton btnStyle='outlined' icon={<IconDownload />} label='Export' fileLabel="export"
                        data={data?.filter(d => columnsSorted?.map(c => c.id)?.includes(d.latestStatusId))}
                        mapExportData={exportData}
                    />
                </Grid>}
            </Grid>
            <KanbanTableContent
                columns={columnsSorted}
                data={data?.filter(d => columnsSorted?.map(c => c.id)?.includes(d.latestStatusId))}
                setIssuesTrackerToEdit={setIssuesTrackerToEdit}
                setColumnPref={onColumnTitleChange}
            />
        </TableContainer>
    );
}

export default KanbanTable;