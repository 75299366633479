import { useSelector } from 'react-redux';
import { StepsStyles } from '../StepsStyles';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';

const ClientOverview = ({ }) => {
    const classes = StepsStyles()

    const { accessToEdit } = useSelector(state => state.accessState);

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    return (
        <>
            <Authorize profile={permissionProfiles.ACCESS.CLIENT_FIELDS}>
                <div className={classes.overviewField}><label >Client: </label>{clientLookup?.[accessToEdit?.clientId]?.name}</div>
            </Authorize>
        </>
    );
}

export default ClientOverview;