import { Grid } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetFormByTypeForClientQuery } from "../formBuilder/formBuilderSlice";
import { emptyReport } from "./CustomReportConsts";
import { useCreateReportMutation, useGetReportByTypeForClientQuery } from "./customReportSlice";
import ReportActions from "./ReportActions/ReportActions";
import ReportHeader from "./ReportHeader/ReportHeader";
import ReportSections from "./ReportSections/ReportSections";
import { CustomReportStyles } from "./style";

const CustomReport = () => {
    const classes = CustomReportStyles();

    const { clientId, formType } = useSelector(state => state.formState);

    let { data: reportFromApi, error: reportError, isFetching: isLoadingReports } = useGetReportByTypeForClientQuery({ clientId, formType }, { skip: !formType });
    let { data: formFromApi, error: formError, isFetching: isLoadingForms } = useGetFormByTypeForClientQuery({ clientId, formType }, { skip: !formType });
    const [saveReport, { isLoading: saving }] = useCreateReportMutation();

    const [report, setReport] = useState(emptyReport(formType));
    const [changeStack, setChangeStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    useEffect(() => {
        if (!isLoadingReports) {
            const empty = emptyReport(formType);
            setReport(reportFromApi?.id ? reportFromApi : empty);
            setChangeStack([]);
            setRedoStack([]);
        }
    }, [isLoadingReports, reportFromApi]);

    const updateReport = (newReport) => {
        setChangeStack([report, ...changeStack]);
        setReport(newReport);
        setRedoStack([])
    }

    const onReportChange = (fieldName, value) => {
        updateReport({ ...report, [fieldName]: value });
    }

    const updateSections = (newSectionList) => {
        updateReport({ ...report, ['sections']: newSectionList?.map((q, index) => ({ ...q, order: index })) });
    }

    const onAddSection = (index, numberOfColumns) => {
        let columns = [];
        for (let index = 0; index < numberOfColumns; index++) {
            columns.push({
                width: 3 / numberOfColumns,
                elements: []
            });
        }
        let sections = report?.sections ? [...report?.sections] : [];
        sections.splice(index, 0, { columns: columns });
        updateSections(sections);
        // scrollToEndOfPage();
    }

    const scrollToEndOfPage = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    const redo = () => {
        const lastVersion = redoStack[0];
        if (lastVersion) {
            updateReport(lastVersion)
        }
    }

    const undo = () => {
        const lastVersion = changeStack[0];
        changeStack.splice(0, 1);
        if (lastVersion) {
            setRedoStack([report]);
            setReport(lastVersion);
        }
    }

    const onSaveReport = async () => {
        let result = await saveReport({
            ...report,
            formId: formFromApi.id,
            clientId,
            formType,
            sections: report?.sections
        });
        if (result && !result.error) {
            NotificationProvider.success("Report saved successfully");
        } else {
            NotificationProvider.error("Failed to save report");
        }
        return result;
    }

    const isLoading = isLoadingReports;
    const invalidReport = !formFromApi?.id;

    return (
        <div className={classes.formBuilderWrapper}>
            {isLoading ?
                <div className={classes.loaderWrapper}>
                    <ShimmerLoader count={10} height="120" />
                </div> :
                <>
                    <Grid container spacing={2} className={classes.formBuilderContainer}>
                        <Grid item xs={12}>
                            <ReportHeader
                                report={report}
                                onReportFieldChange={onReportChange}
                            />
                            <ReportSections
                                report={report}
                                onAddSection={onAddSection}
                                onReportFieldChange={onReportChange}
                            />
                        </Grid>
                    </Grid>
                    <ReportActions
                        onSave={onSaveReport}
                        saving={saving}
                        disabled={invalidReport}
                        undo={undo}
                        redo={redo}
                        redoEnabled={!!redoStack?.length}
                        undoEnabled={!!changeStack?.length}
                    />
                </>
            }
        </div>
    );
}
export default CustomReport;