import { Grid } from "@mui/material";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import EditActivityLog from "features/vehicles/VehicleDetailsPage/EditActivityLog/EditActivityLog";
import { orderBy } from "lodash";
import { useState } from "react";
import LoadGroupLeg from "./LoadGroupLeg/LoadGroupLeg";
import { loadLegsStyle } from "./LoadGroupLegsStyle";
import { legTypes } from "./loadLegConsts";
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";

const LoadGroupLegs = ({ loadGroup, reload, loading }) => {

    const classes = loadLegsStyle();
    const [activityLogToEdit, setActivityLogToEdit] = useState(null);

    let legs = [];

    loadGroup?.loads?.forEach(load => {
        legs.push({
            ...load,
            legDate: load?.pickupStartDate,// load.confirmedPickupDate ?? 
            type: legTypes.PICKUP
        });
        legs.push({
            ...load,
            legDate: load?.deliveryStartDate,// load.confirmedDeliveryDate ?
            type: legTypes.DELIVERY
        })
    });

    loadGroup?.activities?.forEach(activity => {
        legs.push({
            ...activity,
            legDate: activity.activityDate,
            type: legTypes.ACTIVITY
        })
    });

    legs = orderBy(legs, 'legDate', 'asc');

    const onSaveActivity = () => {
        setActivityLogToEdit()
        reload && reload();
    }

    return (<>
        <Grid spacing={1} container direction='column' className={classes.loadLegs} wrap="nowrap">
            {loading && <ShimmerLoader count={3} />}
            {
                legs?.map((leg, index) =>
                    <Grid key={index} item>
                        <LoadGroupLeg leg={leg} loadGroup={loadGroup} setActivityLogToEdit={setActivityLogToEdit} />
                    </Grid>
                )

            }
        </Grid>
        {activityLogToEdit &&
            //  <Authorize profile={permissionProfiles.ASSETS.EDIT_ACTIVITY}>
            <EditActivityLog
                onConfirm={onSaveActivity}
                clientId={loadGroup?.clientId}
                activityLog={{ ...activityLogToEdit, date: activityLogToEdit.activityDate }}
                onCancel={() => setActivityLogToEdit()}
                vehicleTypeId={loadGroup?.assets?.[0]?.vehicleTypeId}
            />
            // </Authorize>
        }
    </>
    );
}
export default LoadGroupLegs;