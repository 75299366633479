import DataTable from "../../../../components/DataTable/DataTable";
import { assetTableStyle } from "./AssetTableStyle";
import { useGetVehicleTypesQuery } from "../../vehicleSlice";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import DataListHeader from "../../../../components/DataListHeader";
import { AssetFilterTypes, assetLocationsTypesById } from "../VehicleMapConsts";
import LoadButton from "components/LoadButton";
import VehicleStatusView from "features/vehicles/VehicleStatus/VehicleStatusView";


const AssetTable = ({ assets, color }) => {
    const classes = assetTableStyle()
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();

    const assetsWithObj = assets;

    let columns = [
        {
            name: "Location Type",
            key: "locationType",
            width: 250,
            component: (row) => <div className={classes.locationTypeColumn}>
                <div className={classes.locationIcon}>{assetLocationsTypesById?.[row?.locationType]?.icon(null, null, color)}</div>
                <div className={classes.locationText}>{assetLocationsTypesById?.[row?.locationType]?.name}</div>
                {row?.locationType == AssetFilterTypes.TRANSPORT && <LoadButton latestLoadID={row?.latestLoadId} />}
            </div>
        },
        {
            name: "VIN",
            key: "descriptor",
            width: 200,
            component: (row) => <Link to={`/assets/detail/${row?.id}`} target="_blank"
                                        className={classes.link}>{row?.descriptor}</Link>
        },
        {
            name: "Asset Id",
            key: "assetId",
            width: 200,
            component: (row) => <Link to={`/assets/detail/${row?.id}`} target="_blank"
                                        className={classes.link}>{row?.assetId}</Link>
        },
        { name: "Client", key: "client", requireAdmin: true, selectedByDefault: true },
        { name: "Current Location", key: "lotName", selectedByDefault: true, width: 200 },
        { name: "Vehicle Type", key: "vehicleTypeId", values: vehicleTypes, selectedByDefault: true, width: 200 },
        { name: "Last In", key: "formattedDateIn", sortKey: "dateIn", selectedByDefault: true, sortable: true },
        { 
            name: "Status", key: "vehicleStatusId", selectedByDefault: true,
            component: (row) => {
                return <VehicleStatusView value={row.vehicleStatusId} clientId={row?.clientId}/>
            }
        },
    ];

    return (
        <>
            <Paper className={classes.paper}>
                <DataListHeader
                    title={
                        <div className={classes.titleWrapper}>
                            <>{`Assets (${assetsWithObj?.length})`}</>
                        </div>}
                    data={assetsWithObj}
                />

                <div className={classes.dialogTable}>
                    <DataTable
                        columns={columns}
                        rows={assetsWithObj}
                        rowIdentifier='id'
                        noItemsMessage='There are no assets matching this criteria'
                    />
                </div>
            </Paper>

        </>
    )
}
export default AssetTable;