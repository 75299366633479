import { makeStyles } from "@material-ui/core";

export const StepsToCompleteStyles = makeStyles((theme) => ({
    dragIcon: {
        cursor: 'move',
        lineHeight: '1rem',
        '& path': {
            fill: '#ddd'
        },
        '&:hover': {
            '& path': {
                fill: theme.palette.secondary.dark
            },
        }
    },
    optionsContainer: {
        padding: '1em 1em'
    },
    multiValueOpt: {
        borderBottom: '1px solid #efefef',
        height: '3rem',
        paddingTop: '0.7rem'
    },
    saveBtn: {
        width: '30%',
        marginTop: '2rem'
    },
    checkbox: {
        margin: '0 0.5em',
        marginBottom: '1rem'
    },
    dragContainer: {
        paddingTop: '1rem'
    },
    overwriteConfig: {
        marginTop: '2rem',
        marginLeft: '1rem'
    }
}));