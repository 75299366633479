import { Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { useEffect, useState } from "react";
import { readinessConfigStyles } from "./style";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import usePrompt from "hooks/usePrompt";
import { ReadinessConfigItem } from "./ReadinessConfigItem";
import { readinessTypes } from "features/vehicles/components/AssetReadiness/readinessUtil";
import { useUpdateReadinessConfigMutation } from "features/vehicles/readiness/readinessSlice";

const ReadinessConfig = ({ contract, handleClose, clientConfig, open }) => {
    const classes = readinessConfigStyles();

    const [updateReadinessConfig, { isLoading }] = useUpdateReadinessConfigMutation();

    const [readinessOptions, setReadinessOptions] = useState([]);
    const [messages, setMessages] = useState([]);
    const [changed, setChanged] = useState(false);
    const [on, setOn] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);

    const { triggerPrompt } = usePrompt();

    const handleSave = async () => {
        const isValid = validate();
        if (isValid) {
            setMessages([]);
            setShowInvalid(false);
            const res = await updateReadinessConfig({
                configs: readinessOptions,
                clientAccountId: clientConfig.accountId,
                isManualReadiness: on == readinessTypes.MANUAL
            });
            if (!res?.error) {
                setChanged(false);
                NotificationProvider.success('Saved successfully');
            } else {
                NotificationProvider.error('Failed to save');
            }
        } else {
            setShowInvalid(true);
            setMessages([...messages, 'Please enter all required fields']);
        }
    }

    const mandatoryFields = ['activityItemId', 'statusText1', 'statusText2', 'statusText3'];

    const validate = () => {
        return on === readinessTypes.AUTO || (readinessOptions?.length && readinessOptions.every(item => {
            return mandatoryFields.every(field => item[field]);
        }))
    }
    const onChange = (row, fieldName, value) => {
        let options = [...readinessOptions];
        row[fieldName] = value;
        options[row.rowNumber] = row;
        setReadinessOptions(options);
        setChanged(true);
    }

    const getNewOption = (rowIndex) => {
        return ({ rowNumber: readinessOptions?.length })
    }
    const onAdd = () => {
        setReadinessOptions([...readinessOptions, getNewOption()]);
        setChanged(true);
    }

    const onDelete = async (row) => {
        triggerPrompt({
            title: "Remove",
            content: "Are you sure you want to delete",
            onConfirm: () => {
                let options = [...readinessOptions];
                options.splice(row.rowNumber, 1);
                setReadinessOptions(options);
                setChanged(true);
            },
        });
    }

    const toggleReadinessType = async (value) => {
        if (readinessTypes.AUTO === value && on === readinessTypes.MANUAL) {
            triggerPrompt({
                title: "Readiness Calculation Change",
                content: "Are you sure you want switch to auto calculation of the readiness? All configuration will be removed.",
                onConfirm: () => {
                    setReadinessOptions([]);
                    setChanged(true);
                    setOn(value)
                },
            });
        } else {
            setOn(value)
            setChanged(true);
            if (!readinessOptions?.length) {
                setReadinessOptions([getNewOption()]);
            }
        }
    }

    useEffect(() => {
        if (open) {
            if (clientConfig?.config?.readinessConfig?.readinessType == readinessTypes.MANUAL) {
                if (clientConfig?.readinessOptions?.length) {
                    setReadinessOptions([...(clientConfig?.readinessOptions?.map((c, index) => ({ rowNumber: index, ...c })) ?? [])]);
                } else {
                    setReadinessOptions([getNewOption()]);
                }
            } else {
                setReadinessOptions([]);
            }
            setOn(clientConfig?.config?.readinessConfig?.readinessType ?? readinessTypes.AUTO);
            setShowInvalid(false);
            setMessages([]);
        }
    }, [clientConfig?.readinessOptions, open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle>
                <Grid container justifyContent='space-between'>
                    <div>{`${contract?.label} SLA`}</div>
                    {contract?.headerComponent}
                </Grid>
            </DialogTitle>
            <DialogContent >

                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                    <ToggleButtonGroup
                        color="primary"
                        value={on}
                        exclusive
                        aria-label="Platform"
                    >
                        <ToggleButton selected={on == readinessTypes.AUTO} onClick={() => toggleReadinessType(readinessTypes.AUTO)}>
                            <Tooltip title={'Determine the asset readiness based on automatically calculated factors such as transports and the wash program'} ><span >Auto</span></Tooltip>
                        </ToggleButton>
                        <ToggleButton selected={on == readinessTypes.MANUAL} onClick={() => toggleReadinessType(readinessTypes.MANUAL)}>
                            <Tooltip title={'Determine the asset readiness based on manually selected statuses'}><span >Manual</span></Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Authorize>
                {!!messages?.length && <p>
                    {messages?.map((m, index) => <Alert key={`message_${index}`} severity="error">{m}</Alert>)}
                </p>}
                <div className={classes.configsContainer}>
                    {on === readinessTypes.MANUAL && <Grid className={classes.configsContainer} container direction="column" spacing={2}>
                        {
                            readinessOptions?.map(readinessOption =>
                                <Grid item key={readinessOption.id}>
                                    <ReadinessConfigItem
                                        item={readinessOption}
                                        onChange={onChange}
                                        onDelete={onDelete}
                                        validate={showInvalid}
                                    />
                                </Grid>)
                        }</Grid>}
                </div>
                {on === readinessTypes.MANUAL && readinessOptions?.length < 3 &&
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.READINESS_CONFIG_ACTIONS}>
                        <Button variant="outlined" onClick={onAdd} startIcon={<AddCircleOutline />}>Add</Button>
                    </Authorize>}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    className={classes.button}
                    variant="contained"
                >
                    Close
                </Button>
                {changed &&
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.READINESS_CONFIG_ACTIONS}>
                        <Button
                            onClick={handleSave}
                            disabled={isLoading}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                        >
                            {isLoading ? "Saving..." : "Save"}
                        </Button>
                    </Authorize>}
            </DialogActions>

        </Dialog >
    )
}
export default ReadinessConfig;

