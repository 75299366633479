
function SvgIconServiceCenterOEM(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00562 6.97998C2.28176 6.97998 2.50562 7.20384 2.50562 7.47998V10.4733C2.50562 11.9204 2.7987 12.8043 3.33334 13.3389C3.86797 13.8736 4.75187 14.1666 6.19895 14.1666H9.79228C11.2394 14.1666 12.1233 13.8736 12.6579 13.3389C13.1925 12.8043 13.4856 11.9204 13.4856 10.4733V7.47998C13.4856 7.20384 13.7095 6.97998 13.9856 6.97998C14.2618 6.97998 14.4856 7.20384 14.4856 7.47998V10.4733C14.4856 12.0196 14.1787 13.2323 13.365 14.046C12.5513 14.8597 11.3385 15.1666 9.79228 15.1666H6.19895C4.6527 15.1666 3.43993 14.8597 2.62623 14.046C1.81253 13.2323 1.50562 12.0196 1.50562 10.4733V7.47998C1.50562 7.20384 1.72947 6.97998 2.00562 6.97998Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.94821 1.2836C5.97384 1.02806 6.1889 0.833496 6.44572 0.833496H9.55905C9.81615 0.833496 10.0313 1.02848 10.0566 1.28433L10.4966 5.73767C10.6454 7.2506 9.51568 8.50016 7.99905 8.50016C6.48242 8.50016 5.35264 7.25081 5.50145 5.73788L5.94821 1.2836ZM6.89808 1.8335L6.49665 5.83577C6.49664 5.83592 6.49666 5.83562 6.49665 5.83577C6.40572 6.76264 7.07583 7.50016 7.99905 7.50016C8.92235 7.50016 9.59253 6.76296 9.50147 5.83599C9.50147 5.83592 9.50148 5.83606 9.50147 5.83599L9.10602 1.8335H6.89808Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16153 0.997967C9.25631 0.893242 9.39098 0.833496 9.53223 0.833496H11.5656C12.4901 0.833496 13.246 1.00775 13.7965 1.51621C14.3404 2.01859 14.5891 2.76155 14.7142 3.66492C14.715 3.67088 14.7157 3.67686 14.7163 3.68285L14.9031 5.51732C15.0656 7.15058 13.8489 8.50016 12.2056 8.50016C10.8493 8.50016 9.64114 7.41311 9.50153 6.05807L9.50137 6.05651L9.0347 1.38318C9.02067 1.24262 9.06674 1.10269 9.16153 0.997967ZM10.0846 1.8335L10.4963 5.95559C10.4963 5.95583 10.4963 5.95607 10.4963 5.95631C10.5838 6.80095 11.362 7.50016 12.2056 7.50016C13.2553 7.50016 14.0119 6.66338 13.9081 5.6169C13.9081 5.61671 13.9081 5.61708 13.9081 5.6169L13.7224 3.79327C13.6073 2.96887 13.4031 2.51414 13.118 2.25078C12.8384 1.99258 12.3877 1.8335 11.5656 1.8335H10.0846Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.16139 1.51621C2.71185 1.00775 3.46776 0.833496 4.39231 0.833496H6.42565C6.56691 0.833496 6.70159 0.893253 6.79637 0.997993C6.89116 1.10273 6.93722 1.24268 6.92317 1.38325L6.45665 6.05509C6.45663 6.0553 6.45667 6.05488 6.45665 6.05509C6.32464 7.41289 5.11436 8.50016 3.75898 8.50016C2.11571 8.50016 0.8991 7.15077 1.0614 5.51767C1.06139 5.51779 1.06141 5.51756 1.0614 5.51767L1.24137 3.68464C1.24202 3.67805 1.2428 3.67148 1.24371 3.66492C1.36879 2.76155 1.61751 2.01859 2.16139 1.51621ZM2.2356 3.79239L2.05659 5.61569C1.95243 6.66243 2.70903 7.50016 3.75898 7.50016C4.60339 7.50016 5.37968 6.80048 5.46131 5.95857L5.46144 5.9573L5.87315 1.8335H4.39231C3.5702 1.8335 3.11944 1.99258 2.83991 2.25078C2.5549 2.51404 2.35077 2.96855 2.2356 3.79239Z" fill="white" />
      <path d="M11.5758 12.621L11.5721 11.0747L10.8137 12.3485H10.545L9.79029 11.1079V12.621H9.23071V10.0439H9.72403L10.6886 11.6454L11.6384 10.0439H12.128L12.1354 12.621H11.5758Z" fill="white" />
      <path d="M8.75755 12.1424V12.621H6.76221V10.0439H8.70969V10.5225H7.35492V11.0821H8.55139V11.546H7.35492V12.1424H8.75755Z" fill="white" />
      <path d="M4.90364 12.6654C4.63613 12.6654 4.39438 12.6077 4.1784 12.4923C3.96488 12.377 3.79676 12.2187 3.67404 12.0174C3.55378 11.8137 3.49365 11.5855 3.49365 11.3327C3.49365 11.0799 3.55378 10.8529 3.67404 10.6516C3.79676 10.4479 3.96488 10.2884 4.1784 10.173C4.39438 10.0577 4.63613 10 4.90364 10C5.17116 10 5.41168 10.0577 5.62521 10.173C5.83873 10.2884 6.00685 10.4479 6.12957 10.6516C6.25228 10.8529 6.31364 11.0799 6.31364 11.3327C6.31364 11.5855 6.25228 11.8137 6.12957 12.0174C6.00685 12.2187 5.83873 12.377 5.62521 12.4923C5.41168 12.6077 5.17116 12.6654 4.90364 12.6654ZM4.90364 12.1573C5.05581 12.1573 5.19325 12.123 5.31597 12.0542C5.43868 11.9831 5.5344 11.8849 5.60312 11.7597C5.67429 11.6346 5.70988 11.4922 5.70988 11.3327C5.70988 11.1732 5.67429 11.0308 5.60312 10.9056C5.5344 10.7805 5.43868 10.6835 5.31597 10.6148C5.19325 10.5436 5.05581 10.508 4.90364 10.508C4.75148 10.508 4.61404 10.5436 4.49132 10.6148C4.36861 10.6835 4.27166 10.7805 4.20049 10.9056C4.13177 11.0308 4.09741 11.1732 4.09741 11.3327C4.09741 11.4922 4.13177 11.6346 4.20049 11.7597C4.27166 11.8849 4.36861 11.9831 4.49132 12.0542C4.61404 12.123 4.75148 12.1573 4.90364 12.1573Z" fill="white" />
    </svg>
  );
}

export default SvgIconServiceCenterOEM;
