import { makeStyles } from '@material-ui/core/styles';

export const columnCustomizationPanelStyle = makeStyles((theme) => ({
    item: {

    },
    toggleButton: {

    },
    title: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: '0 1em',
        height: '3.5em',
        fontWeight: '500',
    },
    content: {
        //width: '250px',
        maxWidth: '80vw',
    },
    actions: {
        padding: '0.5em 1em',
    },
    columnContainer: {
        padding: '0.5em 1em',

    },
    columnList: {
        maxHeight: '300px',
        //overflowY: 'auto'
        maxWidth: '100%',
        overflowX: 'auto'
    },
    statusItem: {
        padding: '0.5em 1em',
        cursor: 'pointer',
    },
    titleText: {
        paddingRight: '2rem'
    },
    views: {
        background: theme.palette.grey[200],
        padding: '0 1em',
        height: '3.5em',
        fontWeight: '500'
    },
}));
