import { Grid, IconButton, MenuItem, Select, Tooltip } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConfirmationPopup from "components/ConfirmationPopup";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import { useCreateViewMutation, useDeleteViewMutation, useGetUserPreferencesQuery, useGetUserViewsQuery, useUpdateUserPreferenceMutation } from "components/UserPreferences/userPreferencesSlice";
import usePrompt from "hooks/usePrompt";
import { IconAddCircle } from "icons";
import { useEffect, useState } from "react";
import { tableViewsStyle } from "./style";

const TableViewsSelector = ({
    tableId,
    selectedView,
    onSelectView,
    getData,
    setFiltersSetFromOtherScreen
}) => {

    const classes = tableViewsStyle();

    let { data: views, error: viewsError, isLoading: isLoadingViews } = useGetUserViewsQuery({ componentId: tableId }, { skip: !tableId });
    let { data: userLastViewPreference, error, isLoading } = useGetUserPreferencesQuery({ componentId: tableId, type: userPreferencesTypes.LAST_VIEW_ID, viewId: 0 }, { skip: !tableId });
    const [deleteView, { isLoading: isDeleting }] = useDeleteViewMutation();
    const [createView, { isLoading: isAddingView }] = useCreateViewMutation();
    const [updatePref, { isLoading: isSaving }] = useUpdateUserPreferenceMutation();

    views = views || [];
    views = [{ id: 0, name: 'default' }, ...views];

    const { triggerPrompt } = usePrompt();

    const [open, setOpen] = useState(false)
    const [name, setName] = useState()

    useEffect(() => {
        if (userLastViewPreference?.lastViewId) {
            onSelectView(parseInt(userLastViewPreference?.lastViewId));
        } else {
            onSelectView(0);
        }
    }, [userLastViewPreference]);

    const onSelectViewId = (val) => {
        setFiltersSetFromOtherScreen && setFiltersSetFromOtherScreen(false);
        onSelectView(val)
        const prefToSave = { lastViewId: val.toString() };
        updatePref({ componentId: tableId, type: userPreferencesTypes.LAST_VIEW_ID, ...prefToSave })
    }

    const renderValue = (selected) => {
        return <div className={`${classes.selectItem}`}>
            {views?.find(s => s.id == selected)?.name}
        </div>
    };

    const onClose = () => {
        setOpen(false);
        setName(null);
    }

    const onConfirm = async () => {
        let viewId = await onCreateView();
        onSelectViewId(viewId);
        onClose();
    }

    const onDelete = async (view) => {
        triggerPrompt({
            title: "Delete View",
            content: "Are you sure you want to delete this view?",
            onConfirm: async () => {
                let res = await deleteView({ viewId: view.id, componentId: tableId });

                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                    onSelectViewId(0)
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    };

    const onCreateView = async () => {
        let data = getData();
        let result = await createView({ name: name, componentId: tableId, filters: data?.filters, columns: data?.columns })
        if (result && !result.error) {
            NotificationProvider.success("View added successfully");
            return result.data;
        } else {
            NotificationProvider.error("Failed to save the view");
        }
    }

    return (
        <>
            <Grid >
                <label className={classes.selectTitle}>Select View:</label>
                <Select
                    native={false}
                    className={classes.select}
                    value={selectedView ?? 1}
                    onChange={(e) => {
                        onSelectViewId(e.target.value)
                    }}
                    variant="outlined"
                    inputProps={{
                        className: classes.select,
                    }}
                    MenuProps={{
                        className: classes.selectMenu,
                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                        transformOrigin: { vertical: "top", horizontal: "left" },
                        getContentAnchorEl: null
                    }}
                    IconComponent={ExpandMoreIcon}
                    renderValue={renderValue}
                >
                    {views?.map((value, key) => (
                        <MenuItem className={classes.statusItem}
                            id={key} key={key} value={value.id}>
                            {value.name}
                            {value.id != 0 &&
                                <DeleteOutlineIcon className={classes.deleteIcon} onClick={() => onDelete(value)} />
                            }
                        </MenuItem>
                    ))}
                </Select>
                <Tooltip title="Create New View">
                    <IconButton onClick={() => setOpen(true)}><IconAddCircle /></IconButton>
                </Tooltip>

            </Grid>
            <ConfirmationPopup
                title="Create New View"
                onCancel={() => {
                    onClose()
                }}
                onConfirm={() => {
                    onConfirm()
                }}
                open={open}
            >
                <CustomInput
                    id="name"
                    label="View Name"
                    value={name}
                    elementType="input"
                    onChange={val => setName(val)}
                />
            </ConfirmationPopup>
        </>
    )
}
export default TableViewsSelector;