import * as React from "react";

function SvgIconArrow(props, width, height) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M9 17.0625C4.82864 17.0625 1.4375 13.6714 1.4375 9.5C1.4375 5.32864 4.82864 1.9375 9 1.9375C13.1714 1.9375 16.5625 5.32864 16.5625 9.5C16.5625 13.6714 13.1714 17.0625 9 17.0625ZM9 2.0625C4.89886 2.0625 1.5625 5.39886 1.5625 9.5C1.5625 13.6011 4.89886 16.9375 9 16.9375C13.1011 16.9375 16.4375 13.6011 16.4375 9.5C16.4375 5.39886 13.1011 2.0625 9 2.0625Z" fill="white" stroke="white" />
            <path d="M10.875 9.5625H6.375C6.36194 9.5625 6.34628 9.5573 6.33199 9.54301C6.3177 9.52872 6.3125 9.51306 6.3125 9.5C6.3125 9.48694 6.3177 9.47128 6.33199 9.45699C6.34628 9.4427 6.36194 9.4375 6.375 9.4375H10.875C10.8881 9.4375 10.9037 9.4427 10.918 9.45699C10.9323 9.47128 10.9375 9.48694 10.9375 9.5C10.9375 9.51306 10.9323 9.52872 10.918 9.54301C10.9037 9.5573 10.8881 9.5625 10.875 9.5625Z" fill="white" stroke="white" />
            <path d="M11.1836 9.85363L11.5372 9.50008L11.1836 9.14652L9.33113 7.29402C9.32169 7.28458 9.31445 7.26989 9.31445 7.25008C9.31445 7.23027 9.32169 7.21558 9.33113 7.20613C9.34058 7.19669 9.35527 7.18945 9.37508 7.18945C9.39489 7.18945 9.40958 7.19669 9.41902 7.20613L11.669 9.45613C11.6785 9.46557 11.6857 9.48027 11.6857 9.50008C11.6857 9.51989 11.6785 9.53458 11.669 9.54402L9.41902 11.794C9.41059 11.8025 9.40363 11.8063 9.39827 11.8085C9.39245 11.8108 9.38484 11.8126 9.37508 11.8126C9.36531 11.8126 9.35771 11.8108 9.35188 11.8085C9.34653 11.8063 9.33956 11.8025 9.33113 11.794C9.32169 11.7846 9.31445 11.7699 9.31445 11.7501C9.31445 11.7303 9.32169 11.7156 9.33113 11.7061L11.1836 9.85363Z" fill="white" stroke="white" />
        </svg>

    );
}

export default SvgIconArrow;
