import { IconImage, IconQuestion, IconSubtitle, IconTitle } from "icons";
import { ElementComponent } from "./ReportSections/ReportSection/ReportColumn/ElementComponent";

export const reportElementTypes = {
    Question: 1,
    Title: 2,
    Subtitle: 3,
    Image: 4
}

export const elementTypeSetup = {
    [reportElementTypes.Question]: {
        id: reportElementTypes.Question,
        name: 'Add Single Question',
        icon: <IconQuestion style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement, questionsUsed) => <ElementComponent
            elementType={reportElementTypes.Question}
            element={element}
            onEditElement={onEditElement}
            questionsUsed={questionsUsed} />
    },
    [reportElementTypes.Title]: {
        id: reportElementTypes.Title,
        name: 'Add Title',
        icon: <IconTitle style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Title}
            element={element}
            onEditElement={onEditElement} />
    },
    [reportElementTypes.Subtitle]: {
        id: reportElementTypes.Subtitle,
        name: 'Add Subtitle',
        icon: <IconSubtitle style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Subtitle}
            element={element}
            onEditElement={onEditElement} />
    },
    [reportElementTypes.Image]: {
        id: reportElementTypes.Image,
        name: 'Add Image',
        icon: <IconImage style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Image}
            element={element}
            onEditElement={onEditElement} />
    },
}

export const emptyReport = (formType) => {
    return {
        formType,
        title: '',
        description: '',
        sections: []
    }
}