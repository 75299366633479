import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    container: {
        padding: '1em'
    },
    assetSelector: {
        padding: '0 1em'
    }
}));
