import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    dropdownStyle: {
        minWidth: 260,
        width: '100%',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    logicTitle: {
        fontWeight: 600,
        margin: '1rem 8px',
        fontSize: '14px'
    },
    paramTypes: {
        marginLeft: '8px',
        maxWidth: 250
    },
    alert: {
        alignItems: 'center',
        "& .MuiAlert-icon": {
            fontSize: 25
        },
        width: 420
    },
    duplicateActivityTypeInventory: {
        padding: 8, 
        border: "1px dashed red", 
        borderRadius: 2
    }
}));
