import { Button, Grid } from "@material-ui/core"
import { VehicleAccessStyle } from "./VehicleAccessStyle";
import { AccessGroupTypes } from "features/access/accessConsts";
import GenerateAccessCode from "features/access/GenerateAccessCode/GenerateAccessCode";
import AccessCodeList from "features/access/AccessCodeList/AccessCodeList";
import Authorize from "components/Authorize";
import DeleteIcon from '@material-ui/icons/Delete';
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useUpdateVehiclesMutation } from "features/access/accessSlice";
import usePrompt from "hooks/usePrompt";
import NotificationProvider from "components/NotificationProvider";

export const VehicleAccessListItem = ({ access, vehicleId }) => {
    const classes = VehicleAccessStyle();
    const { triggerPrompt } = usePrompt();

    const [updateVehicles, { isLoading }] = useUpdateVehiclesMutation();

    const onDelete = async () => {
        triggerPrompt({
            title: "Delete Access",
            content: "Are you sure you want to remove the asset from the access group?",
            onConfirm: async () => {
                let body = { accessGroupId: access?.id, vehicleIds: access?.vehicleIds?.filter(v => v !== +vehicleId) };
                let result = await updateVehicles(body);
                if (result && !result.error) {
                    NotificationProvider.success("The asset was removed from the access group successfully");
                } else {
                    NotificationProvider.error("Failed to remove the asset from the access group");
                }
            },
        });

    }


    return (
        <div className={`${classes.itemHeader}`}>
            <Grid container spacing={1}>
                <Grid xs={3} item container spacing={1} direction="column">
                    <Grid item>
                        <b>Client</b>
                    </Grid>
                    <Grid item>
                        {access?.clientName}
                    </Grid>
                </Grid>
                <Grid xs={3} item container spacing={1}>
                    <Grid item>
                        <b>POC Name:</b>
                    </Grid>
                    <Grid item>
                        {access?.pocName}
                    </Grid>
                </Grid>
                <Grid xs={3} item container spacing={1} direction="column">
                    {access?.groupType === AccessGroupTypes.CARRIER &&
                        <>
                            <Grid item>
                                <b>Carrier</b>
                            </Grid>
                            <Grid item>
                                {access?.carrierName}
                            </Grid>
                        </>}
                </Grid>
                <Grid xs={3} item container spacing={1} direction="column">
                    <Grid item>
                        <GenerateAccessCode accessGroup={access} vehicleId={vehicleId} />
                    </Grid>
                    <Grid item>
                        <AccessCodeList accessGroup={access} vehicleId={vehicleId} />
                    </Grid>
                    <Grid item>
                        <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                            <Button
                                disabled={isLoading}
                                variant="outlined"
                                onClick={onDelete}
                                startIcon={<DeleteIcon className={classes.icon} />}>
                                Delete
                            </Button>
                        </Authorize>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}