import { LocalDrinkSharp } from '@material-ui/icons';
import React from 'react';
import { AssetReadinessStyle } from "./AssetReadinessStyle";
import Popover from '@material-ui/core/Popover';
import { Button } from '@material-ui/core';
import FormatService from '../../../../services/formatService';
import ReadinessIndicator from './ReadinessIndicator/ReadinessIndicator';

const ReadinessTooltipContent = ({ readinessStatus = {}, statusText }) => {
    const classes = AssetReadinessStyle();

    return (
        <div className={classes.tooltipContent}>
            <div className={classes.tooltipField}><label>Status: </label>{statusText}</div>
            {readinessStatus?.pickupDate && <div className={classes.tooltipField}>
                <label>Load pickup date: </label>{FormatService.formatDate(readinessStatus?.pickupDate)}
            </div>}
            {readinessStatus?.washStatus && <div className={classes.tooltipField}>
                <label>Latest Wash Date: </label>
                {readinessStatus?.latestCompletedWash ? FormatService.formatDate(readinessStatus?.latestCompletedWash) : 'Unknown'}
                <ReadinessIndicator
                    className={classes.tooltipIndicator}
                    status={readinessStatus?.washStatus}
                />
            </div>}
            {readinessStatus?.ameritInspectionStatus && <div className={classes.tooltipField}>
                <label>Latest Amerit Inspection: </label>
                {readinessStatus.latestAmeritInspection ? FormatService.formatDate(readinessStatus?.latestAmeritInspection) : 'Unknown'}
                <ReadinessIndicator
                    className={classes.tooltipIndicator}
                    status={readinessStatus?.ameritInspectionStatus}
                />
            </div>}
            {readinessStatus?.requestedWashInterval && <div className={classes.tooltipField}>
                <label>Requested Wash Interval: </label>{readinessStatus?.requestedWashInterval}
            </div>}
        </div>
    )
}

export default ReadinessTooltipContent;

