import React, {useState} from "react";
import {orderBy} from "lodash";
import DataTable from "../../../../components/DataTable/DataTable";
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import FilterService, {fieldTypes} from "../../../../services/filterService";
import NotificationProvider from "../../../../components/NotificationProvider";
import usePrompt from "../../../../hooks/usePrompt";
import {useDeleteUserMutation} from "../../usersSlice";
import useSortOptions from "../../../../hooks/useSortOptions";
import useStyles from "./styles";
import {IconDeleteBlue, IconEdit} from "../../../../icons";
import {userPreferencesComponentIds} from "../../../../components/UserPreferences/userPreferencesConsts";
import {useDispatch, useSelector} from "react-redux";
import {setFilters, setSearchVal, setFiltersSetFromOtherScreen} from "./usersTableFiltersSlice";
import EditUserDialog from "../EditUser/EditUserDialog";

const UsersTable = ({users, permission, roles, clients, lots, refetchUsers, title}) => {
    let usersFilter = users || [];
    const userTypes = [{id: 1, name: "Internal PMF"},{id: 2, name: "Client"}];
    const rolesFilter = roles || [];
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);

    const classes = useStyles();
    const [sortOptions, sortData] = useSortOptions();
    const {triggerPrompt} = usePrompt();
    const [deleteUser, {isLoading: isDeletingUser}] = useDeleteUserMutation();
    const [clickedUser, setClickedUser] = useState(null);
    const dispatch = useDispatch();

    const {searchVal, filters, filtersSetFromOtherScreen} = useSelector(state => state.usersTableFilters);

    const usersColumns = [
        {name: "Name", key: "name"},
        {name: "User Name", key: "userName"},
        {name: "Email", key: "email"},
        {name: "Assigned Role", key: "assignedRoleText"},
        {name: "Last Check In", key: "lastLoginDateFormant"},
    ];

    const userRelations = [
        {id: 1, name: "Attendant"},
        {id: 2, name: "Supervisor"},
        {id: 3, name: "Regional Manager"}
    ];

    const updateUsersWithAssignedRoles = (users, roles) => {
        return users.map(user => {
            const matchingRoles = user.assignedRole
                .map(permission => roles.find(role =>
                    role.key?.trim()?.toLowerCase() === permission.roleId?.trim()?.toLowerCase()))
                .filter(role => role)
            const assignedRoleText = matchingRoles.map(role => role.name).join('; ');
            return {
                ...user,
                assignedRoleText
            };
        });
    };
    usersFilter = updateUsersWithAssignedRoles(usersFilter, rolesFilter);
    const specialBehaviorHandlerById = (prop, value) => {
        if (!Array.isArray(prop)) {
            return false;
        }
        return prop.some(item => item.id === value);
    };
    const specialBehaviorHandlerByRelationType = (prop, value) => {
        if (!Array.isArray(prop)) {
            return false;
        }
        return prop.some(item => item.relationType === value);
    };
    const specialBehaviorHandlerByAssignedRole = (prop, value) => {
        if (!Array.isArray(prop)) {
            return false;
        }
        return prop.some(item => item.roleId === value);
    };
    const specialBehaviorHandlerByHasAccessTo = (prop, value) => {
        if (!Array.isArray(prop)) {
            return false;
        }
        return prop.some(item => item.permissionKey.trim().toLowerCase() === value.trim().toLowerCase());
    };

    const specialBehaviorHandlerByUserType = (prop, value) => {
        if (!Array.isArray(prop)) {
            return false;
        }
        return prop.some(item => item.userType.trim().toLowerCase() === value.trim().toLowerCase());
    };

    const filterConfig = [
        {
            type: fieldTypes.VALUE_LIST_OBJECT,
            size: 2,
            title: 'Client Access',
            key: 'accessClient',
            values: clients,
            multiple: false,
            applySpecialBehavior:true,
            specialBehaviorHdl: specialBehaviorHandlerById
        },
        {
            type: fieldTypes.VALUE_LIST_OBJECT,
            size: 2,
            title: 'Lot Access',
            key: 'accessLot',
            values: lots,
            applySpecialBehavior:true,
            specialBehaviorHdl: specialBehaviorHandlerById
        },
        {
            type: fieldTypes.VALUE_LIST_OBJECT,
            size: 2,
            title: 'Association',
            key: 'userRelations',
            values: userRelations,
            applySpecialBehavior:true,
            specialBehaviorHdl: specialBehaviorHandlerByRelationType
        },
        {
            type: fieldTypes.VALUE_LIST_OBJECT,
            size: 2,
            title: 'Role',
            key: 'assignedRole',
            values: rolesFilter?.map(x => ({name: x.name, id: x.key})),
            applySpecialBehavior:true,
            specialBehaviorHdl: specialBehaviorHandlerByAssignedRole
        },
        {
            type: fieldTypes.VALUE_LIST_OBJECT,
            size: 2,
            title: 'Has Access To',
            key: 'assignedPermission',
            values: permission?.map(x => ({name: x.name, id: x.key})),
            applySpecialBehavior:true,
            specialBehaviorHdl: specialBehaviorHandlerByHasAccessTo
        },
        {
            type: fieldTypes.LIST,
            size: 2,
            title: 'PMF/ Client User',
            key: 'userType',
            values: userTypes,
            applySpecialBehavior:true,
            specialBehaviorHdl: specialBehaviorHandlerByUserType
        },
        // { type: fieldTypes.TEXT, size: 2, title: 'Deactivated Users', key: 'lockoutEnabled', values: [{ id: false, name: 'Yes' }, { id: true, name: 'No' }] },
    ];

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    const updateFilters = (prop, value) => {
        setPage(1);
        const newFilters = {...filters, [prop]: {value}}
        dispatch(setFilters(newFilters));
    }
    const searchUserByText = value => {
        setPage(1);
        dispatch(setSearchVal(value.trim()));
    }
    const onResetFilters = () => {
        dispatch(setFilters({
            clientId: {value: null},
            lotId: {value: null}
        }));
    }
    const updateAllFilters = (newFilters) => {
        setPage(1);
        dispatch(setFilters(newFilters));
    }

    const setFiltersSetFromOtherScreenFunc = (val) => {
        dispatch(setFiltersSetFromOtherScreen(val));
    }
    const deleteUserConfirmation = async (user) => {
        if (!user || !user?.id) return {error: "Failed to delete"};
        return await deleteUser({userId: user?.id});
    };

    const getUserById = (id) => {
        return usersFilter?.find(u => u.id === id);
    };

    const onEdit = (id) => {
        const user = getUserById(id);
        setClickedUser(user);
    };

    const onCancel = () => {
        setClickedUser(null);
    };

    const onDelete = (rowId) => {
        const user = getUserById(rowId);
        triggerPrompt({
            title: "Add to blacklist",
            content: "Are you sure you want to add to blacklist this user?",
            onConfirm: async () => {
                let res = await deleteUserConfirmation(user);
                if (!res?.error) {
                    refetchUsers();
                    NotificationProvider.success('Add to blacklist successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    };

    const rowActions = (rowId) => ([{
        icon: <IconEdit/>,
        label: 'Edit',
        onClick: () => onEdit(rowId),
        profile: permissionProfiles.USER_MANAGEMENT.EDIT_USER
    }, {
        icon: <IconDeleteBlue width={15}/>,
        label: 'Add to blacklist',
        onClick: () => onDelete(rowId),
        profile: permissionProfiles.USER_MANAGEMENT.DELETE_USER
    }]);

    let filteredUsers = FilterService.filter(filters, searchVal, usersFilter, filterConfig);

    const onChangePagination = async (page, limit) => {
        setPage(page);
        setLimit(limit);
    }

    return (
        <>
            <DataTable
                title={title}

                columns={usersColumns}
                rows={orderBy(filteredUsers, sortOptions.columnToSort, sortOptions.sortDirection)}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}

                actions={rowActions}
                showActionsInMenu

                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}

                onChangePagination={onChangePagination}
                limit={limit}

                withFilterPanel={true}
                onResetFilters={onResetFilters}
                updateAllFilters={updateAllFilters}

                onSearch={searchUserByText}
                searchVal={searchVal}

                searchButton={false}
                filtersSetFromOtherScreen={filtersSetFromOtherScreen}
                setFiltersSetFromOtherScreen={setFiltersSetFromOtherScreenFunc}

                tableId={userPreferencesComponentIds.USER_ACTIVE_MAP}
                noItemsMessage='There are no users to display'
                customFilterBehavior={true}
            />
            <EditUserDialog user={clickedUser} refetchUsers={refetchUsers} clients={clients} lots={lots} roles={roles} onCancel={onCancel}/>
        </>
    );
};

export default UsersTable;
