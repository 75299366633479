import { TextField } from '@material-ui/core';
import { Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect } from 'react';
import { dateTimePickerStyle } from './style';

const MonthYearPicker = ({
    label,
    value,
    onChange,
    required = false
}) => {
    const classes = dateTimePickerStyle();
    const [dateValue, setDateValue] = React.useState(value)

    useEffect(() => {
        setDateValue(value)
        if (value === '' || value === null) {
            setDateValue("");
        }
    }, [value]);

    return (
        <div className={classes.picker}>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <Stack spacing={3}>
                    <DatePicker
                        label={label}
                        views={['month', 'year']}
                        value={dateValue}
                        onChange={onChange}
                        // format={format}
                        renderInput={(params) =>
                            <TextField {...params}
                                variant='outlined'
                                required={required}
                                sx={{ svg: { color: '#0090FE' } }}
                            />}
                    />
                </Stack>
            </LocalizationProvider>
        </div>
    )
}

export default MonthYearPicker
