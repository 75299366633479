import { makeStyles } from "@material-ui/core";

export const IssueUserStyle = makeStyles((theme) => ({
    userInitials: {
        borderRadius: '50%',
        textTransform: 'uppercase',
        background: '#ddd',
        color: 'white',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.8em',
        justifyContent: 'space-around'
    },
    userName: {
        marginLeft: '0.5em'
    }
}))