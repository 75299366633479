import {useEffect, useMemo, useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import {useGetVehicleTypeCategoryQuery, useGetVehicleTypesQuery} from "../../vehicleSlice";

const VehicleTypeDropDown = ({required,showEmpty,colorInput,label, onChange,value}) => {
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: vehicleTypeCategories, error: vehicleTypeCategoryError, isLoading: isLoadingVehicleTypeCategory, isSuccess: isVehicleTypeCategorySuccess } = useGetVehicleTypeCategoryQuery();
    const handleSelectChange = (option) => {
        const value = JSON.parse(option);
        setSelectedVehicleType(option);
        onChange(value);
    };

    vehicleTypes = vehicleTypes || [];
    vehicleTypeCategories = vehicleTypeCategories || [];

    const combinedData = useMemo(() => {
        const sortedVehicleTypes = [...vehicleTypes].sort((a, b) => a.name.localeCompare(b.name));
        const sortedVehicleTypeCategories = [...vehicleTypeCategories].sort((a, b) => a.name.localeCompare(b.name));

        return sortedVehicleTypeCategories.map(type => ({
            label: type.name,
            options: sortedVehicleTypes
                .filter(item => item.vehicleTypeCategoryId === type.id)
                .map(item => ({ label: item.name, value: { vehicleTypeCategoryId: type.id, vehicleTypeId: item.id } }))
        }));
    }, [vehicleTypes, vehicleTypeCategories]);
    
    useEffect(() => {
        if (value && vehicleTypes.length > 0) {
            const selectedVehicle = vehicleTypes.find(vehicle => vehicle.id === value);
            if (selectedVehicle) {
                const selectedOption = JSON.stringify({
                    vehicleTypeCategoryId: selectedVehicle.vehicleTypeCategoryId,
                    vehicleTypeId: selectedVehicle.id
                });
                setSelectedVehicleType(selectedOption);
            }
        }
    }, [value, vehicleTypes]);
    
    return(
        <>
            {!isLoadingVehicleTypeCategory && !isLoadingVehicleTypes && (
                <CustomInput
                    id="ddlvehicleTypeId"
                    label={label}
                    elementType="dropdown"
                    combinedData={combinedData}
                    value={selectedVehicleType}
                    required={required}
                    showEmpty={showEmpty}
                    colorInput={colorInput}
                    onChange={handleSelectChange}
                />
            )}
        </>
    );
    
}
export default VehicleTypeDropDown;