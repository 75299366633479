import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Popover, Button } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import EditTransporterFormDialog from "./EditTransporterFormDialog";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import { useSelector } from "react-redux";
import { useGetLotsQuery } from "../lots/lotSlice";
import Authorize from "../../components/Authorize";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: "1rem",
        maxWidth: "800px"
    },
    itemLine: {},
    noTransportersMessage: {
        textAlign: "center"
    },
    transporterToDelete: {
        textAlign: "center",
        fontWeight: "bold",
        marginTop: 16
    },
    nameWrapper: {
        width: '20%',
    },
    laneItem: {
        display: 'flex',
        padding: '0.4em 0.5em',
        '& div': {
            marginRight: '0.5em',
        }
    },
    laneAmount: {
        marginLeft: 'auto',
        color: theme.palette.primary.main
    }
}));

const TransporterList = ({ transporters, onClickEdit, onClickDelete, isDeleting }) => {
    const classes = useStyles();
    const [transportersToEdit, settransportersToEdit] = useState();
    const [transporterToDelete, setTransporterToDelete] = useState(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();

    const onClickOpenEdit = client => {
        settransportersToEdit(client);
    };

    const confirmDelete = () => {
        setTransporterToDelete(null);
        onClickDelete(transporterToDelete);
    };

    if (transporters?.length === 0) {
        return <h3 className={classes.noTransportersMessage}>No Transporters found</h3>
    }

    return (
        <>
            {!!transporters?.length && <Card className={classes.container}>
                <List>
                    {
                        transporters?.map(transporter =>
                            <TransporterItem
                                lots={lots}
                                transporter={transporter}
                                onClickEdit={onClickOpenEdit}
                                onClickDelete={onClickDelete}
                            />
                        )
                    }
                </List>
            </Card>}
            {

                transportersToEdit &&
                <EditTransporterFormDialog
                    open={!!transportersToEdit}
                    setOpen={open => open ? null : settransportersToEdit(null)}
                    onSave={onClickEdit}
                    transporter={transportersToEdit}
                    // defaultValue={transportersToEdit.name}
                    // usdotValue={transportersToEdit.usdot}
                    id={transportersToEdit.id}
                />
            }
            <ConfirmationPopup
                title={'Delete Transporter'}
                onCancel={() => setTransporterToDelete(null)}
                onConfirm={confirmDelete}
                open={!!transporterToDelete}
                loading={isDeleting}
            >
                <div>{'Are you sure you want to delete this Transporter?'}</div>
                {
                    transporterToDelete &&
                    <div className={classes.transporterToDelete}>{transporterToDelete.name}</div>
                }
            </ConfirmationPopup>
        </>
    );
};

export default TransporterList;

const TransporterItem = ({ transporter, onClickEdit, onClickDelete, lots }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();

    return (
        <ListItem key={transporter.id} className={classes.itemLine}>
            <ListItemText className={classes.nameWrapper} primary={transporter.name} />
            {transporter.usdot && <ListItemText primary={`USDOT: ${transporter.usdot}`} />}
            {transporter.lanes &&
                <Authorize profile={permissionProfiles.TRANSPORTER.TRANSPORTER_VIEW_LANES} >
                    <ListItemText>
                        <Button onClick={(e) => transporter?.lanes?.length && setAnchorEl(e.currentTarget)}>{`${transporter.lanes?.length} lanes`}</Button>
                        <Popover
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            {transporter?.lanes?.map(lane =>
                                <div className={classes.laneItem}>
                                    <div>{lots?.find(l => l.id == lane.pickupLocationId)?.name}</div>
                                    <div> -> </div>
                                    <div>{lots?.find(l => l.id == lane.deliveryLocationId)?.name}</div>
                                    <div className={classes.laneAmount}>$ {lane.amount}</div>
                                </div>)}
                        </Popover>
                    </ListItemText>
                </Authorize>}
            <ListItemText primary={transporter.createddate} />

            <Authorize profile={permissionProfiles.TRANSPORTER.TRANSPORTER_EDIT}> <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onClickEdit(transporter)}>
                    <CreateIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete"
                    onClick={() => onClickDelete(transporter)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
            </Authorize>
        </ListItem>
    )
}
