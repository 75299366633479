import * as React from "react";

function SvgIconClipboard(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.053 2.20882C18.1407 1.80399 17.8836 1.40473 17.4788 1.31705C17.074 1.22937 16.6747 1.48646 16.587 1.89129L16.3014 3.20996H11.2145L11.449 2.23561C11.546 1.8329 11.2981 1.42785 10.8954 1.3309C10.4927 1.23395 10.0877 1.48181 9.9907 1.88452L9.67161 3.20996H7.44991C6.13621 3.20996 4.97934 4.07888 4.59352 5.32799L4.59323 5.32895L0.383606 18.8476C-0.214045 20.7734 1.21733 22.75 3.23989 22.75H15.9983L16 22.75L16.3799 22.75L20.78 22.75C22.5085 22.75 23.8582 21.2878 23.7382 19.5678L22.748 5.94563C22.7441 5.891 22.7343 5.83816 22.7195 5.78767C22.6837 5.53283 22.614 5.2837 22.5097 5.04543C22.0669 3.98141 21.0134 3.20996 19.7499 3.20996H17.8362L18.053 2.20882ZM15.9765 4.70996L15.647 6.23126C15.5594 6.63608 15.8165 7.03534 16.2213 7.12302C16.6261 7.2107 17.0254 6.95361 17.113 6.54878L17.5113 4.70996H19.7499C20.383 4.70996 20.9073 5.0944 21.1266 5.62596L21.1301 5.63457L21.1339 5.64309C21.2522 5.91074 21.2851 6.23065 21.2013 6.58216L21.2006 6.58498L17.8321 20.1088L17.8302 20.1166C17.6719 20.7827 17.0786 21.25 16.3799 21.25L16 21.25L3.23989 21.25C2.24254 21.25 1.51408 20.2664 1.8162 19.2922L6.02598 5.77303L6.02656 5.77115C6.22104 5.14078 6.80395 4.70996 7.44991 4.70996H9.31049L8.95069 6.20447C8.85374 6.60717 9.10161 7.01223 9.50432 7.10918C9.90702 7.20613 10.3121 6.95826 10.409 6.55555L10.8533 4.70996H15.9765ZM18.9798 21.25H20.78C21.6312 21.25 22.3013 20.5327 22.2419 19.6731L21.6194 11.1097L19.2886 20.4675C19.2215 20.748 19.1165 21.0103 18.9798 21.25ZM6.9502 12C6.9502 11.5858 7.28598 11.25 7.7002 11.25H15.7002C16.1144 11.25 16.4502 11.5858 16.4502 12C16.4502 12.4142 16.1144 12.75 15.7002 12.75H7.7002C7.28598 12.75 6.9502 12.4142 6.9502 12ZM6.7002 15.25C6.28598 15.25 5.9502 15.5858 5.9502 16C5.9502 16.4142 6.28598 16.75 6.7002 16.75H14.7002C15.1144 16.75 15.4502 16.4142 15.4502 16C15.4502 15.5858 15.1144 15.25 14.7002 15.25H6.7002Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconClipboard;
