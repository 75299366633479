import { IconAccessCode } from 'icons';
import { GenerateAccessCodeStyle } from '../style';
import { AccessLoaderStyle } from './style';

const AccessCodeLoader = ({ show }) => {
    const classes = AccessLoaderStyle()

    return (
        <>
            {show &&
                <div className={classes.iconWrapper}>
                    <IconAccessCode className={classes.icon} viewBox="0 0 50 50" />
                    <div className={classes.text}>Generating access code.<br/>Please wait</div>
                </div>
            }
        </>
    );
}

export default AccessCodeLoader;