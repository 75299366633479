import { Chip, Grid, IconButton } from "@material-ui/core";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import ExportButton from "components/ExportButton/ExportButton";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { IconDownload } from 'icons';
import { groupBy } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { theme } from "styles/theme";
import { dispatchDashboardStyle } from "../style";
import { AssetListDialog } from "./AssetListDialog";

const AllAssetsCountOnLot = ({ outOfPmfResponsibilityId }) => {
    const classes = dispatchDashboardStyle()

    const [includeHistory, setIncludeHistory] = useState(false);

    let { data: vehiclesData, error: vehicleError, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory });
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);

    const { filters } = useSelector(state => state.dashboardState);

    const pmfLots = lots?.filter(l => l.pmfLot)?.map(l => l.id);

    let filteredData = vehiclesData?.filter(v =>
        (!filters.clientId || filters.clientId == v.clientId) &&
        v.vehicleStatusId != outOfPmfResponsibilityId &&
        pmfLots?.includes(v.lotId)
    );
   
    const dataGrouped = groupBy(filteredData, 'lotId');

    const finalData = Object.keys(dataGrouped).map(key => ({ lotId: +key, count: dataGrouped[key]?.length }));

    const mapExportData = row => {
        return ({
            'VIN': row.descriptor,
            'Asset Id': row.assetId,
            'Client': clients?.find(c => c.id == row.clientId)?.name,
            'Lot': lots?.find(c => c.id == row.lotId)?.name,
        })
    };

    const onClick = (includeHistory) => {
        setIncludeHistory(includeHistory)
    }


    const loading = isFetchingVehicles || isLoadingClients || isLoadingLots;
    return (
        <div>

            <Grid container justifyContent="space-between" >
                <h4 className={classes.graphTitle}>Assets on PMF Lots {!includeHistory ? ' Now' : ''}</h4>
                <div>
                    <AssetListDialog data={filteredData} />
                    <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={filteredData} mapExportData={mapExportData} />
                </div>
            </Grid>
            <Grid container spacing={1}>
                <Grid item><Chip onClick={() => onClick(false)} label="On lot now" variant={!includeHistory ? "default" : "outlined"} /></Grid>
                <Grid item><Chip onClick={() => onClick(true)} label="On and Off Lot" variant={includeHistory ? "default" : "outlined"} /></Grid>
            </Grid>
            {loading ?
                <LoadingSpinner loading={loading} /> :
                <>
                    {!!finalData?.length ?
                        <div style={{ width: "100%", height: finalData?.length * 40 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    layout="vertical"
                                    data={finalData}
                                    margin={{ top: 30, right: 30, left: 100, bottom: 5 }}
                                >
                                    <XAxis
                                        type="number"
                                        dataKey="count"
                                        axisLine={false}
                                        tickLine={false}
                                    />
                                    <YAxis
                                        type="category"
                                        axisLine={false}
                                        dataKey="lotId"
                                        tickLine={false}
                                        tick={{ color: theme.palette.primary.main, fontSize: '1em', fontWeight: '500' }}
                                        tickFormatter={lotId => {
                                            return lots?.find(l => l.id == lotId)?.name;
                                        }}
                                    />
                                    <Bar
                                        dataKey="count"
                                        fill={'#00c3fe'}
                                    //minPointSize={20}
                                    >
                                        <LabelList className={classes.barLabel} dataKey="count" position="end" offset={-50} style={{ fill: theme.palette.primary.contrastText }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        :
                        <EmptyPlaceholder text='No Washes' />}
                </>}

        </div>)
}

export default AllAssetsCountOnLot;