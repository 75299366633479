import { Button, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useGetDefaultStatusByClientIdQuery } from "features/clients/clientConfigSlice";
import ClientVehicleTypes from "features/clients/contracts/EditContractPopup/EditWorkFlowPopup/components/ClientVehicleTypes/ClientVehicleTypes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import configStyles from "../configStyles";
import { useUpdateCheckInMutation } from "./CheckInConfigSlice";
import CheckInConfigStyle from "./CheckInConfigStyle";
import CheckInGeneralDataSinglePage from "./CheckInGeneralDataSinglePage";
import { Alert } from "@mui/material";
import { DataPointType } from "./DataPointType";


const useStyles = CheckInConfigStyle;
const useConfigStyles = configStyles;

const SingleCheckInConfig = ({ contract, open, clientConfig, handleClose, onSave }) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();

    const [checkinSla, setCheckinSla] = useState([]);
    const [updateCheckIn] = useUpdateCheckInMutation();
    const [isSaving, setIsSaving] = useState(false);
    const { selectedVehicleTypes } = useSelector(state => state.mobileWorkflowState);
    let { data: defaultCheckinStatus, error: statusIdError, isLoading: isLoadingStatusId, refetch } = useGetDefaultStatusByClientIdQuery({ clientId: clientConfig?.id });

    //Client config params
    const [configFields, setConfigFields] = useState({});
    const [selectedActiveCount, setSelectedActiveCount] = useState(0);
    const [invalidFieldMessage, setInvalidFieldMessage] = useState("At least one of the following options must be active: Vin, Asset ID or Vehicle Type.");
    

    const onClose = () => {
        setCheckinSla(null);
        handleClose();
    }

    const runFieldsValidation = () => {
        if (!checkinSla || !checkinSla?.length > 0) return false;
        return [...checkinSla]?.some((config) => config?.dataPointTypeId == DataPointType.Vin && config?.isActive) 
               || [...checkinSla]?.some((config) => config?.dataPointTypeId == DataPointType.Asset_ID  && config?.isActive)
               || [...checkinSla]?.some((config) => config?.dataPointTypeId == DataPointType.Vehicle_Type  && config?.isActive);
    }

    const handleSave = async () => {
        setIsSaving(true);

        let objectToSave = {
            clientId: clientConfig?.id,
            checkInSla: checkinSla?.map(row => ({ ...row, clientId: clientConfig?.id })) ?? [],
            selectedVehicleTypes: selectedVehicleTypes,
            ...configFields,
            typeId: 3
        };
        let res = await onSave(objectToSave);

        setIsSaving(false);
        handleClose();

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
            onClose();
        } else {
            NotificationProvider.error('Failed to save');
        }
    }


    useEffect(() => {
        if (defaultCheckinStatus) {
            setConfigFields({ ...configFields, ['defaultCheckinStatus']: defaultCheckinStatus })
        }
    }, [defaultCheckinStatus])

    useEffect(() => {
        if (clientConfig) {
            const conditionalReportConfigParams = clientConfig?.config?.conditionalReportConfig;
            setConfigFields({ ...configFields, ...conditionalReportConfigParams })
        }
    }, [clientConfig])

    useEffect(() => {
        if (checkinSla) {
            let allActives = [...checkinSla]?.filter((x) => x.isActive);
            setSelectedActiveCount(allActives?.length);
        }
    }, [checkinSla])

    const tabs = [
        {
            label: 'General Data',
            component: <Grid container direction="column" spacing={1}>
                { !runFieldsValidation() && <Alert severity="error">{invalidFieldMessage}</Alert>}
                <CheckInGeneralDataSinglePage
                    open={open}
                    clientConfig={clientConfig}
                    handleClose={handleClose}
                    checkinSla={checkinSla}
                    setCheckinSla={setCheckinSla}
                    activeCount={selectedActiveCount}
                />
            </Grid>
        },
        {
            label: 'Asset Types',
            component: <ClientVehicleTypes clientId={clientConfig?.id} />
        }
    ]

    return (
        <>
            {
                <Grid>
                    <Grid container justifyContent={'flex-end'} alignItems="center">
                        <div className={classes.btnWrapper}>
                            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                                <Button
                                    onClick={handleSave}
                                    disabled={isSaving || (selectedActiveCount > 25 || !runFieldsValidation())}
                                    className={classes.button}
                                    variant="outlined"
                                    color="primary"
                                >
                                    {isSaving ? "Saving..." : "Save"}
                                </Button>
                            </Authorize>
                        </div>
                        {contract?.headerComponent}
                    </Grid>
                    <TabsWrapper tabs={tabs} />
                </Grid>

            }
        </>
    )
}
export default SingleCheckInConfig;
