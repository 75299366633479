import React, { useState } from 'react'
import SearchBar from "material-ui-search-bar";
import { Button, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        border: 0,
        height: '100%'
    },
    input: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default
    },
    button: {
        margin: '.5rem',
        height: '2rem',
    }
}));

const DataSearchBar = (({ onSearch, searchVal, searchButton }) => {
    const classes = useStyles();

    const [searchValue, setSearchValue] = useState("");

    const cancelSearch = () => {
        onSearch("");
    };

    return (
        <Grid container direction="row">
            <div style={{ flex: 1 }}>
                <SearchBar
                    className={classes.root}
                    value={searchVal}
                    onChange={val => searchButton ? setSearchValue(val) : onSearch(val)}
                    onCancelSearch={() => cancelSearch()}
                    inputProps={{ className: classes.input, datatestid: 'search-bar' }}
                />
            </div>
            {searchButton ?
                <Button
                    onClick={() => onSearch(searchValue)}
                    variant='contained'
                    color='primary'
                    className={classes.button}
                >
                    Search
                </Button> : null}
        </Grid>
    )
})

export default DataSearchBar
