import { IconGps, IconMechanicals } from 'icons';
import BasicMarker from '../BasicMarker/BasicMarker';
import { BasicMarkerStyle } from '../BasicMarker/BasicMarkerStyle';

const TelematicsMarker = ({
    color = '#fbb644',
    text, subText,
    textColor,
    wrapperSize = '40',
    iconSize = '24',
    tooltip
}) => {

    const classes = BasicMarkerStyle();
    return (
        <BasicMarker
            color={color}
            text={text}
            subText={subText}
            textColor={textColor}
            wrapperSize={wrapperSize}
            icon={<IconGps fill='white' height={iconSize} width={iconSize} className={classes.whiteIcon} />}
            tooltip={tooltip}
        />

    )
}
export default TelematicsMarker;
