import TransporterList from "./TransporterList";
import React from "react";
import NewDialog from "./NewTransporterDialog";
import { useSelector } from "react-redux";
import {
    useCreateTransporterMutation,
    useDeleteTransporterMutation,
    useGetTransportersQuery,
    useSaveTransporterMutation
} from "./transporterSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import Authorize from "../../components/Authorize";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";

const TransporterPage = () => {
    let { data: transporters, error: transportersError, isLoading } = useGetTransportersQuery();
    let [createTransporter] = useCreateTransporterMutation();
    let [saveTransporter] = useSaveTransporterMutation();
    let [deleteTransporter, { isLoading: isDeleting }] = useDeleteTransporterMutation();

    const onCreate = async data => {
        await createTransporter(data);
    };

    const onEdit = async transporter => {
        await saveTransporter(transporter);
    };

    const onDelete = async transporter => {
        await deleteTransporter(transporter.id);
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div>
            <Authorize profile={permissionProfiles.TRANSPORTER.TRANSPORTER_EDIT}>
                <NewDialog onSave={onCreate} />
            </Authorize>


            <TransporterList
                transporters={transporters}
                onClickEdit={onEdit}
                onClickDelete={onDelete}
                isDeleting={isDeleting} />
        </div>
    );
};

export default TransporterPage;
