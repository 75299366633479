import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setShowAll, setShowCharge, setShowCost } from "./billingSlice";
import { billingDashboardStyle } from "./style";

const BillingDashboardCostChargeFilter = ({ filterConfig, data }) => {
    const classes = billingDashboardStyle();

    const { showCost, showCharge } = useSelector(state => state.billingDashboardState);
    const dispatch = useDispatch();


    const toggleView = async (value) => {
        switch (value) {
            case 'all':
                return dispatch(setShowAll());
            case 'cost':
                return dispatch(setShowCost());
            case 'charge':
                return dispatch(setShowCharge());
        }
    }

    return (

        <ToggleButtonGroup
            color="primary"
            exclusive
        >
            <ToggleButton selected={showCost && showCharge} onClick={() => toggleView('all')}>
                Show cost & charge
            </ToggleButton>
            <ToggleButton selected={showCost && !showCharge} onClick={() => toggleView('cost')}>
                Show cost
            </ToggleButton>
            <ToggleButton selected={showCharge && !showCost} onClick={() => toggleView('charge')}>
                Show charge
            </ToggleButton>
        </ToggleButtonGroup>

    )
}

export default BillingDashboardCostChargeFilter;