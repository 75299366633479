
function SvgIconDetails(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.60706 4.051L6.49727 3.65103L6.0839 3.68504C5.36989 3.74379 4.6795 3.9406 4.17963 4.48466C3.68209 5.02618 3.45825 5.81916 3.45825 6.87502V15C3.45825 15.9927 3.68786 16.8242 4.26512 17.4015C4.84237 17.9787 5.67393 18.2084 6.66659 18.2084H13.3333C14.3259 18.2084 15.1575 17.9787 15.7347 17.4015C16.312 16.8242 16.5416 15.9927 16.5416 15V6.87502C16.5416 5.81904 16.3177 5.02787 15.8198 4.48731C15.3203 3.94504 14.6307 3.74836 13.9191 3.68531L13.4998 3.64816L13.3917 4.055C13.1649 4.90881 12.3835 5.54169 11.4583 5.54169H8.54158C8.00779 5.54169 7.50989 5.33621 7.12848 4.9548C6.8756 4.70192 6.70171 4.39579 6.60706 4.051ZM7.3047 4.77857L7.65825 4.42502L7.3047 4.77857C7.63073 5.1046 8.07413 5.29169 8.54158 5.29169H11.4583C12.4261 5.29169 13.2083 4.5095 13.2083 3.54169C13.2083 3.51154 13.2205 3.48026 13.2462 3.45462C13.2718 3.42897 13.3031 3.41669 13.3333 3.41669C14.5667 3.41669 15.4076 3.72941 15.9432 4.26503C16.4789 4.80065 16.7916 5.64159 16.7916 6.87502V15C16.7916 16.164 16.4599 17.0121 15.9026 17.5694C15.3454 18.1266 14.4972 18.4584 13.3333 18.4584H6.66659C5.50261 18.4584 4.65449 18.1266 4.09722 17.5694C3.53996 17.0121 3.20825 16.164 3.20825 15V6.87502C3.20825 5.64159 3.52098 4.80065 4.0566 4.26503C4.59222 3.72941 5.43315 3.41669 6.66659 3.41669C6.69673 3.41669 6.72801 3.42897 6.75366 3.45462C6.7793 3.48026 6.79158 3.51154 6.79158 3.54169C6.79158 4.00914 6.97867 4.45254 7.3047 4.77857Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M11.4584 5.54169H8.54175C8.00795 5.54169 7.51006 5.33621 7.12864 4.9548C6.74722 4.57338 6.54175 4.07548 6.54175 3.54169C6.54175 2.44283 7.44289 1.54169 8.54175 1.54169H11.4584C11.9922 1.54169 12.4901 1.74716 12.8715 2.12858C13.2529 2.50999 13.4584 3.00789 13.4584 3.54169C13.4584 4.64054 12.5573 5.54169 11.4584 5.54169ZM8.54175 1.79169C7.57394 1.79169 6.79175 2.57388 6.79175 3.54169C6.79175 4.00914 6.97883 4.45254 7.30486 4.77857C7.63089 5.1046 8.0743 5.29169 8.54175 5.29169H11.4584C12.4262 5.29169 13.2084 4.5095 13.2084 3.54169C13.2084 3.07424 13.0213 2.63083 12.6953 2.3048C12.3693 1.97877 11.9259 1.79169 11.4584 1.79169H8.54175Z" fill="#0090FE" stroke="#0090FE"/>
      <path d="M10.0001 10.9583H6.66675C6.6366 10.9583 6.60532 10.946 6.57968 10.9204C6.55403 10.8947 6.54175 10.8635 6.54175 10.8333C6.54175 10.8032 6.55403 10.7719 6.57968 10.7462C6.60532 10.7206 6.6366 10.7083 6.66675 10.7083H10.0001C10.0302 10.7083 10.0615 10.7206 10.0872 10.7462C10.1128 10.7719 10.1251 10.8032 10.1251 10.8333C10.1251 10.8635 10.1128 10.8947 10.0872 10.9204C10.0615 10.946 10.0302 10.9583 10.0001 10.9583Z" fill="#0090FE" stroke="#0090FE"/>
      <path d="M13.3334 14.2917H6.66675C6.6366 14.2917 6.60532 14.2794 6.57968 14.2538C6.55403 14.2281 6.54175 14.1968 6.54175 14.1667C6.54175 14.1365 6.55403 14.1053 6.57968 14.0796C6.60532 14.054 6.6366 14.0417 6.66675 14.0417H13.3334C13.3636 14.0417 13.3948 14.054 13.4205 14.0796C13.4461 14.1053 13.4584 14.1365 13.4584 14.1667C13.4584 14.1968 13.4461 14.2281 13.4205 14.2538C13.3948 14.2794 13.3636 14.2917 13.3334 14.2917Z" fill="#0090FE" stroke="#0090FE"/>
    </svg>
  );
}

export default SvgIconDetails;
