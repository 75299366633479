import { Grid, Tooltip } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import EntityAudit from "components/EntityAudit";
import { EntityAuditTooltip } from "components/EntityAudit/EntityAuditTooltip";
import { useState } from "react";
import FormatService from "services/formatService";
import BasicModal from "../../../../../../../../components/BasicModal";
import InspectionForm from "./InspectionForm";
import { InspectionReportStyles } from "./InspectionReportStyles";

const InspectionReportInformationHidden = ({ inspection }) => {
    const classes = InspectionReportStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const validInspection = inspection.isThereAnyTrash &&
        inspection.hoursVerification &&
        inspection.isBatchromCleaned &&
        inspection.isGateSecured &&
        inspection.isOfficeCleaned &&
        inspection.isParkAIWorking &&
        inspection.isYardCleaned

    return (
        <>
            <div className={`${classes.itemHeader} ${classes.report} ${openDialog ? classes.openReport : ""}`} onClick={() => { setOpenDialog(true) }}>
                <Grid item>
                    <b>Inspection Date</b>
                </Grid>
                <Grid item>
                    {FormatService.formatDateTimeNoConversion(inspection.inspectionDate)}
                </Grid>
                <Grid item >{!validInspection && <Tooltip title={'Some answers in this inspection form need attention'}>
                    <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
                </Tooltip>}
                </Grid>
            </div>
            <BasicModal
                open={openDialog}
                header={<div className={classes.inspectionTitle}>
                    Edit Site Inspection
                    <EntityAuditTooltip entity={inspection} />
                </div>}
                wide={true}
                handleClose={() => setOpenDialog(false)}
                component={<InspectionForm
                    lotId={parseInt(inspection.lotId)}
                    inspection={inspection}
                    handleClose={() => setOpenDialog(null)} />}
            />
        </>
    );
};

export default InspectionReportInformationHidden;
