import { makeStyles } from "@material-ui/core";

export const assetTableStyle = makeStyles((theme) => ({
    paper: {
        minHeight: '50vh'
    },

    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogTable: {
        height: '60vh'
    },
    locationTypeColumn: {
        display: 'flex',
        justifyContent: 'start',
    },
    locationIcon: {
        marginRight: '0.5rem',
    },
    locationText: {
        marginTop: '0.3rem'
    }
}));