import { Grid, Tooltip } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Alert from '@mui/material/Alert';
import { IconCopy, IconDeleteBlue } from "icons";
import moment from 'moment';
import { useEffect, useState } from 'react';
import DropdownFilter from "../DropdownFilter";
import DateTimeScheduleStyle from './DateTimeScheduleStyle';

const useStyles = DateTimeScheduleStyle;

const DateScheduleComponent = ({ data, daysOfWeekList, onChangeSchedule, lineIndex, groupIndex, onDeleteSchedule, name, schedules, duplicateLine }) => {
  const classes = useStyles();

  const [jsonData, setJsonData] = useState(data);
  const [isTimeAvailable, setIsTimeAvailable] = useState(true);
  const [isTimeAvailableBetweenDays, setIsTimeAvailableBetweenDays] = useState(true);

  const getTimeFormated = (time) => {
    let fullTime = moment(time, "H:m:ss");
    return fullTime.format("HH:mm:ss");
  }

  const validateTime = (currentSchedule, groupIndex, key) => {
    setIsTimeAvailable(true);
    if (getTimeFormated(currentSchedule.openTime) >= getTimeFormated(currentSchedule.closeTime)) {
      setIsTimeAvailable(false);
      return false;
    }

    let result = true;
    setIsTimeAvailableBetweenDays(true);
    schedules[groupIndex]?.lotSchedules?.map((item, index) => {
      if (index !== key && currentSchedule !== item && parseInt(item.dayOfWeekId) == parseInt(currentSchedule.dayOfWeekId) && item.deletedDate === null) {
        if ((getTimeFormated(currentSchedule.openTime) >= getTimeFormated(item.openTime) &&
          getTimeFormated(currentSchedule.openTime) <= getTimeFormated(item.closeTime)) ||
          (getTimeFormated(currentSchedule.closeTime) >= getTimeFormated(item.openTime) &&
            getTimeFormated(currentSchedule.closeTime) <= getTimeFormated(item.closeTime))) {
          setIsTimeAvailableBetweenDays(false);
          result = false;
        }
      }
    })

    return result;
  }

  useEffect(() => {
    schedules.forEach((schedule, key) => {
      validateTime(data, groupIndex, key);
    })
  }, [data])

  const openDateChange = (event) => {
    let changeOpenTime = { ...jsonData };
    changeOpenTime.openTime = getTimeFormated(event.selection);
    setJsonData(changeOpenTime);

    let validateResult = validateTime(changeOpenTime, groupIndex, event.lineIndex);
    onChangeSchedule(changeOpenTime, groupIndex, event.lineIndex, validateResult);
  };

  const closeDateChange = (event) => {
    let changeOpenTime = { ...jsonData };
    changeOpenTime.closeTime = getTimeFormated(event.selection);
    setJsonData(changeOpenTime);
    let validateResult = validateTime(changeOpenTime, groupIndex, event.lineIndex);
    onChangeSchedule(changeOpenTime, groupIndex, event.lineIndex, validateResult);
  };

  const dropdownChange = (val, lineIndex, name) => {
    let changeOpenTime = { ...jsonData };
    changeOpenTime.dayOfWeekId = parseInt(val);
    setJsonData(changeOpenTime);
    let validateResult = validateTime(changeOpenTime, groupIndex, lineIndex);
    onChangeSchedule(changeOpenTime, groupIndex, lineIndex, validateResult);
  };

  const removeSchedule = (event) => {
    setIsTimeAvailable(true);
    setIsTimeAvailableBetweenDays(true);
    onDeleteSchedule(groupIndex, event.lineIndex);
  };

  function TimeMessage() {
    return isTimeAvailable ? null : 'Please validate hours. Open bigger than close time';
  }

  function RangeTimeMessage() {
    return isTimeAvailableBetweenDays ? null : 'Please validate hours. Times are overlaping with other rows with the same day.';
  }

  return (
    <div>
      <div className={classes.divDesign}>
        <Grid xs={5} className={classes.dropDownLine}>
          <DropdownFilter
            title='Days'
            identifier='days'
            values={daysOfWeekList}
            onSelect={(event) => dropdownChange(event, lineIndex, name)}
            value={jsonData?.dayOfWeekId || 1}
            showEmpty={true} />
        </Grid>

        <Grid xs={5} classeName={classes.rowWrapper}>
          <div className={classes.inputOpenTimeWrapper}>
            <input className={classes.inputOpenTime} name='openDate' type='time' value={jsonData.openTime} onChange={(event) => {
              openDateChange({ selection: event.target.value, lineIndex: lineIndex, name: name });
            }}></input>
          </div>
        </Grid>

        <Grid xs={5} classeName={classes.rowWrapper}>
          <div className={classes.inputOpenTimeWrapper}>
            <input className={classes.inputOpenTime} name='closeDate' type='time' value={jsonData.closeTime} onChange={(event) => {
              closeDateChange({ selection: event.target.value, lineIndex: lineIndex, name: name });
            }}></input>
          </div>
        </Grid>

        <Grid className={classes.iconsWrapper}>
          <Tooltip title={`Delete Line`} placement="top">
            <IconButton
              onClick={(event) => { removeSchedule({ event, lineIndex: lineIndex, name: name }) }}
            >
              <IconDeleteBlue width={"25"} />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Duplicate Line`} placement="top">
            <IconButton onClick={() => duplicateLine(groupIndex, lineIndex)}>{<IconCopy width={"25"} fill="#0090FE" />} </IconButton>
          </Tooltip>
        </Grid>
      </div>
      {TimeMessage() &&
        <div className={classes.errorMessage}>
          <Alert severity="error">{TimeMessage()}</Alert>
        </div>
      }

      {RangeTimeMessage() &&
        <div className={classes.errorMessage}>
          <Alert severity="error">{RangeTimeMessage()}</Alert>
        </div>
      }
    </div>
  )
}

export default DateScheduleComponent;
