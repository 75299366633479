import React from "react";
import Button from '@material-ui/core/Button';
import {Add as AddIcon} from "@material-ui/icons";
import EditTransporterFormDialog from "./EditTransporterFormDialog";

const NewTransporterDialog = ({onSave}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon/>}
                onClick={handleClickOpen}>
                New Transporter
            </Button>

            <EditTransporterFormDialog
                label="New Transporter"
                open={open}
                setOpen={setOpen}
                onSave={onSave}
            />
        </div>
    );
};

export default NewTransporterDialog;