import { createSlice } from '@reduxjs/toolkit';

export const washSlaSlice = createSlice({
    name: 'washSlaSlice',
    initialState: {
        bulkEdit: false,
        selectedLots: [],
    },
    reducers: {
        setBulkEdit: (state, action) => {
            state.bulkEdit = action.payload;
        },
        addSelectedLot: (state, action) => {
            state.selectedLots = [...state.selectedLots, action.payload];
        },
        setSelectedLots: (state, action) => {
            state.selectedLots = [...action.payload];
        },
        resetSelectedLots: (state, action) => {
            state.selectedLots = [];
        },
        resetSelected: (state, action) => {
            state.selectedLots = [];
            state.selectedSubmarkets = [];
        },
        removeSelectedLot: (state, action) => {
            state.selectedLots = state.selectedLots.filter(l => l != action.payload);
        },
        resetBulkUpdate: (state, action) => {
            state.selectedLots = [];
            state.selectedSubmarkets = [];
            state.bulkEdit = false
        },
    },
});

export const {
    setBulkEdit,
    addSelectedLot,
    setSelectedLots,
    resetSelectedLots,
    resetSelected,
    removeSelectedLot,
    resetBulkUpdate,
} = washSlaSlice.actions;

