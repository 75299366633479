import { makeStyles } from "@material-ui/core"

export const loadHistoryStyle = makeStyles((theme) => ({
    listWrapper: {
        overflow: 'auto'
    },
    card: {
        padding: '1em',
    },
    emptyPlaceHolder: {
        margin: '-1rem'
    },
    historyItemActions: {
        opacity: 0,
        cursor: 'pointer'
    },
    historyItem: {
        "&:hover": {
            "& $historyItemActions": {
                opacity: 1,
            }
        }
    },
    statusTootlip: {
        padding: '1em'
    },
    commentIcon: {
        cursor: 'pointer',
        opacity: 0.6,
        '&:hover': {
            opacity: 1,
        }
    }
}));