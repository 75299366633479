import { Button, Chip, Grid, Paper, Switch, useMediaQuery } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { setSelectedLoad } from 'features/loads/loadGroupViewSlice';
import { useGetLoadGroupQuery } from 'features/loads/loadsSlice';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import NoteList from 'features/notes/NoteList';
import { NoteEntityTypes } from 'features/notes/notesConsts';
import { IconCar, IconDollar, IconGps, IconMap } from 'icons';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import mobileBreakpoint from 'utils/mobileBreakpoint';
import LoadAssets from '../LoadAssets';
import LoadAttachments from '../LoadAttachments';
import LoadDetails from '../LoadDetails';
import LoadGroupLegs from '../LoadGroupLegs';
import LoadHistory from '../LoadHistory';
import LoadMap from '../LoadMap';
import LoadPayments from '../LoadPayments';
import { AddLegMenu } from './AddLegMenu';
import { CardTitle } from './LoadCardTitle';
import { loadGroupDetailsStyle } from './LoadGroupDetailsStyle';

const LoadGroupDetails = ({ loadGroup, readinessForLoads, contacts, reload }) => {
    const isMobile = useMediaQuery(mobileBreakpoint);

    const classes = loadGroupDetailsStyle()

    const history = useHistory();

    let { id } = useParams();

    const groupId = id ?? loadGroup?.id;

    let { data: loadGroupApi, error, isLoading, refetch } = useGetLoadGroupQuery(groupId, { skip: !groupId });
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();

    let loadGroupData = loadGroupApi ?? loadGroup ?? {};

    const [showLocations, setShowLocations] = useState(false);
    const dispatch = useDispatch();

    const setShowLocationsVal = (val) => {
        setShowLocations(val.target.checked);
    };

    const onBack = () => {
        dispatch(setSelectedLoad());
    }

    const onBackToList = () => {
        history.goBack();
    }

    useEffect(() => {
        return () => {
            dispatch(setSelectedLoad());
        };
    }, []);



    useEffect(() => {
        if (selectedLoad) {
            dispatch(setSelectedLoad(loadGroupApi?.loads?.find(l => l.id == selectedLoad.id)));
        }

        // if (loadGroupApi) {
        //     const patchCollection = dispatch(
        //         baseApi.util.updateQueryData('getLoadsGrouped', undefined, (groups) => {
        //             groups.push(loadGroupApi)
        //         })
        //     )
        // }
    }, [loadGroupApi])


    const { selectedLoad } = useSelector(state => state.loadGroupViewState);

    const isMultiLeg = loadGroupData?.loads?.length > 1;

    let legs = [];

    loadGroupData?.loads?.forEach(load => {
        legs.push({
            ...load,
            legDate: load?.pickupStartDate,// load.confirmedPickupDate ?? 
            entityType: NoteEntityTypes.TRANSPORT
        });
    });

    loadGroupData?.activities?.forEach(activity => {
        legs.push({
            ...activity,
            legDate: activity.activityDate,
            entityType: NoteEntityTypes.ACTIVITY
        })
    });

    legs = orderBy(legs, 'legDate', 'asc');

    return (
        <Grid container direction="column" className={classes.container} >
            {selectedLoad ?
                <Grid item>
                    <Button size={'small'} color='primary' onClick={onBack}>
                        <ChevronLeftIcon />
                        <div className={classes.legTitle}>{isMultiLeg && loadGroupData?.name && `${loadGroupData?.name} - `} leg {selectedLoad?.order} of {loadGroupData?.loads?.length}</div>
                    </Button>
                    <LoadDetails loadGroup={loadGroupData} load={selectedLoad} readiness={readinessForLoads?.[selectedLoad?.id]} />
                </Grid>
                :
                <Grid item container direction="row" spacing={3} >
                    <Grid item xs={12}>
                        <Button size={'small'} color='primary' onClick={onBackToList}>
                            <ChevronLeftIcon />
                            <div className={classes.legTitle}>Back</div>
                        </Button>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Paper className={classes.longCard} elevation={1}>
                            <Grid container justifyContent='space-between' >
                                <Grid item>
                                    <CardTitle title={<>
                                        <div>{`Transport Tracker ${isMultiLeg ? loadGroupData?.name ?? '' : ''}`}</div>
                                    </>}
                                        icon={<IconGps />}
                                    />
                                </Grid>
                                {loadGroupData?.archived && <Grid item>
                                    <Chip label='Archived' variant='containded' color='primary' />
                                </Grid>}
                            </Grid>
                            <LoadGroupLegs loadGroup={loadGroupData} reload={refetch} loading={isLoading} />
                            <AddLegMenu loadGroup={loadGroupData} />
                        </Paper>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Paper className={`${classes.longCard} ${classes.loadMap}`} elevation={1}>
                            <CardTitle title='Transport Map' icon={<IconMap />} />
                            <FormControlLabel
                                value={showLocations}
                                control={<Switch color="primary" />}
                                label="Show locations"
                                labelPlacement="start"
                                onChange={setShowLocationsVal}
                            />
                            {!!loadGroupData?.id && <LoadMap groupId={loadGroupData?.id} loads={loadGroupData?.loads} showLocations={showLocations} />}
                        </Paper>
                    </Grid>
                    <Grid item container spacing={3} >
                        <Grid item xs={isMobile ? 12 : 6} >
                            <Paper className={classes.longCard} elevation={1}>
                                <CardTitle title='Assets' icon={<IconCar height={'1.2em'} />} />
                                <LoadAssets assets={loadGroupData?.assets} readiness={readinessForLoads?.[loadGroupData?.loads?.[0]?.id]} />
                            </Paper>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6} >
                            <Paper className={classes.longCard} elevation={1}>
                                <CardTitle title='History' />
                                <LoadHistory load={loadGroupData} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.card}>
                            <CardTitle title='Attachments' />
                            <LoadAttachments groupId={loadGroupData?.id} loads={loadGroupData?.loads} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.card}>
                            <CardTitle title='Notes' icon={<MessageOutlinedIcon />} />
                            {legs?.map((leg, index) =>
                                <NoteList entityId={leg?.id} entityType={leg.entityType} collapsible={false} readOnly listOnly title={`Leg ${index + 1}:`} />
                            )
                            }
                        </Paper>
                    </Grid>
                    <Authorize profile={permissionProfiles.LOAD.LOAD_PAYMENTS}>
                        <Grid item xs={12}>
                            <Paper className={classes.card}>
                                <CardTitle title='Payments' icon={<IconDollar />} />
                                <LoadPayments groupId={loadGroupData?.id} />
                            </Paper>
                        </Grid>
                    </Authorize>
                </Grid >
            }
        </Grid >
    );
}

export default LoadGroupDetails
