import { Grid, Paper } from "@material-ui/core";
import AllAssetsCountOnLot from "./AllAssetCountOnLot/AllAssetsCountOnLot";
import DomiciledCount from "./DomiciledCount/DomiciledCount";
import TotalAssets from "./TotalAssets/TotalAssets";
import TotalAssetsDomicile from "./DomiciledCount/TotalAssetsDomicile";
import { useGetStatusesForClientQuery } from 'features/vehicles/VehicleStatus/vehicleStatusSlice';

const { dispatchDashboardStyle } = require("./style")

const DispatchDashboard = () => {
    const classes = dispatchDashboardStyle();

    let { data: vehicleStatuses, error: statusError, isLoading: isLoadingStatuses } = useGetStatusesForClientQuery({ clientId: 0 });
    vehicleStatuses = vehicleStatuses || [];
    const outOfPmfResponsibilityId = vehicleStatuses?.find(vs => vs?.label == "Out of PMF Responsibility")?.id;

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={4}>
                    <Paper className={classes.countWrapper}>
                        <TotalAssets outOfPmfResponsibilityId={outOfPmfResponsibilityId} />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.countWrapper}>
                        <TotalAssets onLot={true} outOfPmfResponsibilityId={outOfPmfResponsibilityId} />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.countWrapper}>
                        <TotalAssetsDomicile outOfPmfResponsibilityId={outOfPmfResponsibilityId} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                    <Paper className={classes.graphWrapper}>
                        <AllAssetsCountOnLot outOfPmfResponsibilityId={outOfPmfResponsibilityId} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.graphWrapper}>
                        <DomiciledCount outOfPmfResponsibilityId={outOfPmfResponsibilityId} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid xs={12}>

            </Grid>
        </Grid>
    )
}

export default DispatchDashboard;