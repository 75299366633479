import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    header: {
    },
    headerTitle: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    headerIcon: {
        marginRight: theme.spacing(1),
    },
    filters: {
        marginBottom: theme.spacing(2),
    },
}));