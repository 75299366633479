import * as AllMaterialIcons from '@material-ui/icons';

const importStatuses = {
    '1': {
        label: 'New load created'
    },
    '2': {
        label: 'Failed'
    },
    '3': {
        label: 'New load created (existing asset)'
    },
    '4': {
        label: 'Existing load'
    },
    '5': {
        label: 'Load updated'
    },

};

export default importStatuses;