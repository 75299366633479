import { IconUpdate } from 'icons';
import BasicMarker from '../BasicMarker/BasicMarker';
import { BasicMarkerStyle } from '../BasicMarker/BasicMarkerStyle';

const RecentUpdateMarker = ({ color, text, subText, textColor, wrapperSize = '40', iconSize = '24' }) => {

    const classes = BasicMarkerStyle();
    return (
        <BasicMarker
            color={color}
            text={text}
            subText={subText}
            textColor={textColor}
            wrapperSize={wrapperSize}
            icon={<IconUpdate fill='white' height={iconSize} width={iconSize} className={classes.lotIcon} />}
        />
    )
}
export default RecentUpdateMarker;
