import * as React from "react";

function SvgIconContent(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.5 5.217c0-.81.657-1.467 1.467-1.467h22.066c.81 0 1.467.657 1.467 1.467v.079c0 .767-.622 1.389-1.389 1.389L22.64 26.25H7.36L3.89 6.685A1.389 1.389 0 0 1 2.5 5.295v-.078zm20.792 1.468H6.708l2.945 16.77h10.694l2.945-16.77z"
                fill="#0090FE"
            />
        </svg>
    );
}

export default SvgIconContent;
