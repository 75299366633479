import { Grid, IconButton } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NotificationProvider from "components/NotificationProvider";
import { useGetAllActivitiesQuery, useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { useDeleteActivityFromLoadMutation } from "features/loads/loadsSlice";
import usePrompt from "hooks/usePrompt";
import FormatService from "services/formatService";
import { loadLegsStyle } from "../../LoadGroupLegsStyle";
import { activityStatusesList } from "features/activity/activityConsts";

const ActivityLeg = ({ loadGroup, leg, actions }) => {

    let { data: activityItems, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activities, error: activityError, isLoading: isLoadingActivity } = useGetAllActivitiesQuery();
    const [deleteActivity, { isDeleting }] = useDeleteActivityFromLoadMutation();
    const { triggerPrompt } = usePrompt();

    const classes = loadLegsStyle();

    const onDelete = async () => {

        triggerPrompt({
            title: "Delete Activity",
            content: <div>
                Are you sure you want to remove the activity from the transport?
            </div>,
            onConfirm: async () => {
                let result = await deleteActivity({ activityGroupId: leg?.groupId, loadGroupId: loadGroup?.id });
                if (result && !result.error) {
                    NotificationProvider.success("Activity deleted successfully");
                } else {
                    NotificationProvider.error("Failed to delete the activity");
                }
            },
            onCancel: async () => {
            },
        });
    }

    const getActivityStatusName = (activityId) => {
        const status = activityStatusesList?.find(a => a.id == activityId);
        return status?.shortName ?? status?.name;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item >
                    {/* placeholder for alignment */}
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction='column'>
                        <Grid item className={classes.label}>
                            Type
                        </Grid>
                        <Grid item className={classes.value}>
                            {activityItems?.find(a => a.id == leg.activityItemId)?.name}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction='column'>
                        <Grid item className={classes.label}>
                            Date
                        </Grid>
                        <Grid item className={classes.value}>
                            {FormatService.formatDate(leg?.activityDate)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction='column'>
                        <Grid item className={classes.label}>
                            Status
                        </Grid>
                        <Grid item className={classes.value}>
                            {getActivityStatusName(leg?.activityId)}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item style={{ marginLeft: 'auto', zIndex: 10 }} >
                    <IconButton onClick={onDelete} size={'small'} ><DeleteOutlineIcon className={classes.legIcons} /></IconButton>
                    {actions}
                </Grid>
            </Grid>

        </>



    );
}

export default ActivityLeg;