import {createSlice} from "@reduxjs/toolkit";

export const addAssetSlice = createSlice({
    name: 'addAsset',
    initialState: {
        images: {},
    },
    reducers: {
        addImage: (state, action) => {
            state.images.push = action.payload;
        }
    },
});

export const { addImage } = addAssetSlice.actions;
export default addAssetSlice.reducer;
