import { baseApi } from 'app/baseApi';
import { apiTags } from 'app/store/apiTags';
import { baseQuery } from "../../app/baseQuery";

const baseUrl = 'access';
export const accessApi = baseApi.injectEndpoints({
    reducerPath: 'authorizedUserApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAuthorizedUsers: builder.query({
            query: () => `${baseUrl}`,
        }),
        getAuthorizedUsersCount: builder.query({
            query: () => `${baseUrl}/count`,
        }),
        getAccessCategories: builder.query({
            query: () => `accessCategory`,
        }),
        getLicenseClasses: builder.query({
            query: () => `licenseClass`,
        }),
        getAccessesByVehicleId: builder.query({
            query: (vehicleId) => `${baseUrl}/${vehicleId}/vehicle`,
            providesTags: [apiTags.ACCESS]
        }),
        getAccessesByGroupType: builder.query({
            query: (groupType) => `${baseUrl}/${groupType ?? ''}`,
            providesTags: [apiTags.ACCESS]
        }),
        getAccessGroups: builder.query({
            query: () => `${baseUrl}/groups`,
            providesTags: [apiTags.ACCESS]
        }),
        updateAccessGroup: builder.mutation({
            query: accessGroup => ({
                url: `${baseUrl}`,
                method: "PUT",
                body: accessGroup,
            }),
            invalidatesTags: [apiTags.ACCESS]
        }),
        getAllVendors: builder.query({
            query: () => `${baseUrl}/vendors`,
            providesTags: [apiTags.ACCESS]
        }),
        addVendor: builder.mutation({
            query: vendor => ({
                url: `${baseUrl}/vendor`,
                method: "POST",
                body: vendor
            }),
            invalidatesTags: ["Vendors", apiTags.ACCESS]
        }),
        generateAccessCode: builder.mutation({
            query: ({ accessGroupId, accessCodeType, lotId = undefined, vehicleId = undefined, startDate, endDate, startTime, endTime, pocName, pocEmail, pocPhone, sendEmail, sendSMS }) => ({
                url: `${baseUrl}/generate`,
                method: "POST",
                body: {
                    accessGroupId,
                    accessCodeType,
                    lotId,
                    vehicleId,
                    startDate,
                    endDate,
                    startTime,
                    endTime,
                    pocName,
                    pocEmail,
                    pocPhone,
                    sendEmail,
                    sendSMS
                }
            }),
            invalidatesTags: [apiTags.ACCESS]
        }),
        generateOneTimeAccessCode: builder.mutation({
            query: ({ lotId, pocName, pocEmail, pocPhone, sendEmail, sendSMS }) => ({
                url: `${baseUrl}/generate/one_time`,
                method: "POST",
                body: {
                    lotId,
                    pocName,
                    pocEmail,
                    pocPhone,
                    sendEmail,
                    sendSMS
                }
            }),
        }),
        sendAccessCode: builder.mutation({
            query: ({ accessGroupId, sendEmail, sendSMS, lotId = undefined }) => ({
                url: `${baseUrl}/send`,
                method: "POST",
                body: {
                    accessGroupId,
                    sendEmail,
                    sendSMS,
                    lotId,
                }
            }),
        }),
        deleteAccess: builder.mutation({
            query: id => ({
                url: 'access',
                method: "DELETE",
                body: id
            }),
            invalidatesTags: [apiTags.ACCESS],
        }),
        updateLots: builder.mutation({
            query: body => ({
                url: `${baseUrl}/lots`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: [apiTags.ACCESS]
        }),
        updateVehicles: builder.mutation({
            query: body => ({
                url: `${baseUrl}/vehicles`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: [apiTags.ACCESS]
        }),
        //TODO: delete
        // updateDrivers: builder.mutation({
        //     query: body => ({
        //         url: `${baseUrl}/drivers`,
        //         method: "PUT",
        //         body: body,
        //     }),
        //     invalidatesTags: [apiTags.ACCESS]
        // }),
        getAccessCodes: builder.query({
            query: (accessGroupId) => `${baseUrl}/${accessGroupId}/codes`,
            providesTags: [apiTags.ACCESS]
        }),
        getLotsWithAccess: builder.query({
            query: () => `${baseUrl}/lots`,
        }),
    }),
});

export const {
    useGetAuthorizedUsersQuery,
    useGetAuthorizedUsersCountQuery,
    useGetLicenseClassesQuery,
    useGetAccessCategoriesQuery,
    useGetAccessesByVehicleIdQuery,
    useGetAccessesByGroupTypeQuery,
    useGetAccessGroupsQuery,
    useUpdateAccessGroupMutation,
    useGetAllVendorsQuery,
    useAddVendorMutation,
    useDeleteAccessMutation,
    useGenerateAccessCodeMutation,
    useGenerateOneTimeAccessCodeMutation,
    useUpdateLotsMutation,
    useUpdateVehiclesMutation,
    //TODO: delete
    // useUpdateDriversMutation,
    useSendAccessCodeMutation,
    useGetAccessCodesQuery,
    useGetLotsWithAccessQuery
} = accessApi;
