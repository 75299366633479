import { createSlice } from "@reduxjs/toolkit";

export const doorReleaseStateSlice = createSlice({
    name: 'doorReleaseState',
    initialState: {
        filters: {
            lotId: { value: null },
            clientId: { value: null },
            formatedDate: { value: { startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)), endDate: new Date() } }
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },

    },
});

export const { setFilters } = doorReleaseStateSlice.actions;
export default doorReleaseStateSlice.reducer;
