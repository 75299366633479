import { Checkbox } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetActivityFieldsTypesQuery } from "features/activity/activitySlice";

export const VehicleField = ({ ruleParam, onChange, paramSubType }) => {
    let { data: fieldTypes, error: fieldTypesError, isLoading: fieldTypesLoading } = useGetActivityFieldsTypesQuery();
    const field = fieldTypes?.find(f => f.paramSubType == paramSubType);

    return <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#F3F5F6', borderRadius: '4px', padding: '4px 0', paddingRight: '10px' }}>
        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
            <Checkbox
                title={field?.name}
                checked={ruleParam?.paramValue === "yes"}
                onChange={(e, value) => { onChange(value ? "yes" : "no") }} />
        </Authorize>
        <p>{field?.name}</p>
    </div>
    // <CustomInput
    //     label={field?.name}
    //     elementType={field?.values ? 'dropdown' : 'input'}
    //     values={field?.values ?? null}
    //     showEmpty={true}
    //     value={ruleParam.paramValue}
    //     onChange={val => onChange(val)}
    // />
}