import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@mui/material";
import React from "react";
import UserMenu from "./UserMenu/UserMenu";
import { useSelector } from "react-redux";
import { IconButton, makeStyles } from "@material-ui/core";
import TasksPanel from "../Tasks/components/TaskPanel/TasksPanel";
import MechanicalTasksPanel from "../mechanicalTasks/components/taskPanel";
import MenuIcon from "@material-ui/icons/Menu";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";
import { selectIsSeamless } from "../user/userSlice";

export const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: '400'
    },
    iconButton: {
        position: "absolute",
        left: 100,
        fill: "white"
    },
    iconButton2: {
        position: "absolute",
        left: 260,
        fill: "white"
    },
    logos: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
    },
    logoContainer: {
        display: "flex",
        background: "#FFF",
        padding: "0 24px",
        alignItems: "center"
    },
    parqLogo: {
        maxWidth: "60px",
    },
    pmfLogo: {
        maxWidth: "214px"
    },
}));


const Header = ({ }) => {
    const classes = useStyles();
    let user = useSelector(state => state.oidc.user);
    const isSeamless = useSelector(selectIsSeamless);

    let isUserLoaded = !!user;

    return (
        <AppBar color="secondary">
            {
                isSeamless &&
                <div className={classes.logos}>
                    <div className={classes.logoContainer}>
                        <img
                            src="/images/ParqAIlogo.png"
                            className={classes.parqLogo}
                            alt="parq.ai"
                        />
                    </div>
                    <div className={classes.logoContainer}>
                        <img
                            src="/images/parkmyfleetLogo.png"
                            className={classes.pmfLogo}
                            alt="parkmyfleet"
                        />
                    </div>
                </div>
            }
            {
                !isSeamless &&
                <Toolbar style={{ justifyContent: "flex-end" }}>
                    {
                        isUserLoaded &&
                        <Grid container justifyContent="flex-end">
                            <TasksPanel />
                            {/* <Authorize profile={permissionProfiles.MECHANICALS_TASKS.TASK_LIST}>
                                <MechanicalTasksPanel />
                            </Authorize> */}
                            <UserMenu />

                        </Grid>
                    }
                </Toolbar>
            }
        </AppBar>
    );
};

export default Header;
