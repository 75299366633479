import { Button, Paper, Tooltip } from "@material-ui/core";
import { useState } from 'react';

import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import EmptyPlaceholder from "../../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import configStyles from "../../../clientConfigs/configStyles";
import EditMovementType from "./editMovementType/editMovementType";
import MovementType from "./movementType/movementType";

const useStyles = configStyles;

const MovementTypes = ({ transportConfigParams, movementTypes, onConfigChange }) => {
    const classes = useStyles();

    const [addNewMovementType, setAddNewMovementType] = useState(true);
    const [localMovementTypes, setLocalMovementTypes] = useState(movementTypes);
    const [open, setOpen] = useState(false);

    const couldAddNewMovementType = localMovementTypes ? localMovementTypes.length < 6 : true;

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        !couldAddNewMovementType ? setOpen(true) : setOpen(false);
    };

    const onAddNewMovementType = () => {
        setAddNewMovementType(false);
    }

    return (
        <>
            <Paper className={classes.card}>
                <div className={classes.title}>Movement Types:</div>
                {addNewMovementType && <div className={classes.selectActionButtons}>
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_EDIT_MOVEMENT_TYPES}>
                        <Tooltip title="The maximum number of movement types is 6" placement="top" open={open} onClose={handleClose} onOpen={handleOpen} >
                            <span>
                                <Button onClick={onAddNewMovementType} disabled={(!transportConfigParams?.useTransport) || (!couldAddNewMovementType)} color="primary" variant="contained">
                                    Add New Movement Type
                                </Button>
                            </span>
                        </Tooltip>
                    </Authorize>
                </div>}
                {!(!!localMovementTypes?.length) && <EmptyPlaceholder text="No Movement Types Defined" />}
                {!addNewMovementType && <EditMovementType localMovementTypes={localMovementTypes} setLocalMovementTypes={setLocalMovementTypes} setAddNewMovementType={setAddNewMovementType} onConfigChange={onConfigChange} />}
                <div>
                    {
                        localMovementTypes?.map((item, index) => (
                            <MovementType transportConfigParams={transportConfigParams} movementType={localMovementTypes[index]} localMovementTypes={localMovementTypes} setLocalMovementTypes={setLocalMovementTypes} onConfigChange={onConfigChange} />
                        ))
                    }
                </div>
            </Paper>
        </>
    );
};

export default MovementTypes;
