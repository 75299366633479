import * as React from "react";

function IconDetailing(props) {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.49854 0.5C4.71655 0.5 4.90944 0.641266 4.97523 0.849122L5.73835 3.26018L8.14941 4.02331C8.35727 4.0891 8.49854 4.28198 8.49854 4.5C8.49854 4.71802 8.35727 4.9109 8.14941 4.97669L5.73835 5.73982L4.97523 8.15088C4.90944 8.35873 4.71655 8.5 4.49854 8.5C4.28052 8.5 4.08763 8.35873 4.02184 8.15088L3.25872 5.73982L0.847657 4.97669C0.639801 4.9109 0.498535 4.71802 0.498535 4.5C0.498535 4.28198 0.639801 4.0891 0.847657 4.02331L3.25872 3.26018L4.02184 0.849122C4.08763 0.641266 4.28052 0.5 4.49854 0.5ZM4.49854 2.65697L4.13377 3.80942C4.08466 3.96458 3.96311 4.08613 3.80796 4.13524L2.6555 4.5L3.80796 4.86476C3.96311 4.91387 4.08466 5.03542 4.13377 5.19058L4.49854 6.34303L4.8633 5.19058C4.91241 5.03542 5.03396 4.91387 5.18911 4.86476L6.34157 4.5L5.18911 4.13524C5.03396 4.08613 4.91241 3.96458 4.8633 3.80942L4.49854 2.65697ZM10.3652 3.5C10.5832 3.5 10.7761 3.64127 10.8419 3.84912L12.5346 9.19724L17.8827 10.89C18.0906 10.9558 18.2319 11.1486 18.2319 11.3667C18.2319 11.5847 18.0906 11.7776 17.8827 11.8434L12.5346 13.5361L10.8419 18.8842C10.7761 19.0921 10.5832 19.2333 10.3652 19.2333C10.1472 19.2333 9.9543 19.0921 9.88851 18.8842L8.19577 13.5361L2.84766 11.8434C2.6398 11.7776 2.49854 11.5847 2.49854 11.3667C2.49854 11.1486 2.6398 10.9558 2.84766 10.89L8.19577 9.19724L9.88851 3.84912C9.9543 3.64127 10.1472 3.5 10.3652 3.5ZM10.3652 5.65696L9.07083 9.74648C9.02172 9.90164 8.90017 10.0232 8.74501 10.0723L4.6555 11.3667L8.74501 12.661C8.90017 12.7101 9.02172 12.8317 9.07083 12.9869L10.3652 17.0764L11.6596 12.9869C11.7087 12.8317 11.8302 12.7101 11.9854 12.661L16.0749 11.3667L11.9854 10.0723C11.8302 10.0232 11.7087 9.90164 11.6596 9.74648L10.3652 5.65696Z" fill="#0090FE" />
        </svg>

    );
}

export default IconDetailing;
