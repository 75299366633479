import * as React from "react";

function SvgIconAlignLeft(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5a1 1 0 000 2h18a1 1 0 100-2H3zm0 4a1 1 0 000 2h14a1 1 0 100-2H3zm-1 5a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zm1 3a1 1 0 100 2h14a1 1 0 100-2H3z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgIconAlignLeft;
