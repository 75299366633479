import React from "react";
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { SiteSizingStyles } from "./SiteSizingStyle";
import { useDeleteSiteSizingMutation, useGetSiteSizingQuery } from "features/lots/lotSlice";
import { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import AddSiteSizing from "./AddSiteSizing/AddSiteSizing";
import NotificationProvider from "components/NotificationProvider";
import EditSiteSizing from "./EditSiteSizing/EditSiteSizing";
import ConfirmationPopup from "components/ConfirmationPopup";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";


const SiteSizing = () => {

    const classes = SiteSizingStyles();

    let { data: siteSizing, error: siteSizingError, isLoading: isLoadingSiteSizing, isSuccess: siteSizingSuccess } = useGetSiteSizingQuery();
    siteSizing = siteSizing || [];

    const [deleteSiteSizing, { isLoading }] = useDeleteSiteSizingMutation();

    const [sizeToDelete, setSizeToDelete] = useState(null);
    const [sizeToEdit, setSizeToEdit] = useState(null);

    const isLoadingDashboardData = () => isLoadingSiteSizing;

    const deleteSizing = async () => {
        let result = await deleteSiteSizing(sizeToDelete);
        if (result && !result.error) {
            NotificationProvider.success("Site size deleted successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
        setSizeToDelete(null)

    };

    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <>
                        <Grid container alignItems="center">
                            <Grid item><img src="/icons/icon-inspect.svg" className={classes.icon} /></Grid>
                            <Grid item><h3 className={classes.subTitle}>Site Sizing</h3></Grid>
                            <Grid container className={classes.sizing}>
                                <TableContainer component={Grid}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.siteSizingHeader}>Size</TableCell>
                                                <TableCell className={classes.siteSizingHeader}>Assets</TableCell>
                                                <TableCell className={classes.siteSizingHeader}>Hours TH</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {siteSizing.map((item, i) => (
                                                <>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} /*key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}*/>
                                                        <TableCell className={classes.siteSizingBody}>{item.name}</TableCell>
                                                        <TableCell className={classes.siteSizingBody}>
                                                            {item.maxSize == 0 && '>'}
                                                            {item.minSize != 0 && item.minSize}
                                                            {item.minSize == 0 && '<'}
                                                            {item.maxSize != 0 && item.minSize != 0 && `-`}
                                                            {item.maxSize != 0 && item.maxSize}
                                                        </TableCell>
                                                        <TableCell className={classes.siteSizingBody}>{item.hoursTH}</TableCell>
                                                        <Authorize profile={permissionProfiles.LOT.LOT_SIZING}>
                                                            <TableCell className={classes.iconButtons}>
                                                                <Grid container direction="row">
                                                                    <Grid item>
                                                                        <IconButton className={classes.iconButton} variant="outlined" onClick={() => setSizeToEdit(item)}><EditIcon /></IconButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <IconButton className={classes.iconButton} variant="outlined" onClick={() => setSizeToDelete(item)}><DeleteIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </Authorize>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Grid className={classes.addSizeButton} >
                            <AddSiteSizing />
                        </Grid>

                        <ConfirmationPopup
                            title={'Delete Site Sizing'}
                            onCancel={() => setSizeToDelete(null)}
                            onConfirm={deleteSizing}
                            open={!!sizeToDelete}
                        // loading={isDeleting}
                        >
                            <div>Are you sure you want to delete the Size</div>
                        </ConfirmationPopup>
                        {!!sizeToEdit &&
                            <EditSiteSizing sizeToEdit={sizeToEdit} setSizeToEdit={setSizeToEdit} />
                        }
                    </>
            }
        </>
    );
};

export default SiteSizing;
