import { Button, Menu, MenuItem } from "@material-ui/core";
import IssueTrackerView from "./IssueTrackerView/IssueTrackerView";
import { useState } from "react";
import { issueTrackerTypes } from "./issueTrackerConsts";
import { useSelector } from "react-redux";
import { selectUserId } from "features/user/userSlice";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const CreateIssueMenu = ({ issuesTrackerToAdd, setIssueTrackerToAdd }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const loggedInUserId = useSelector(selectUserId);

    return (
        <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
            <div>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={handleClick}
                >
                    Create New Ticket
                </Button>
                <Menu
                    id="issue-options"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => (setIssueTrackerToAdd({ ...issuesTrackerToAdd, ['issueType']: issueTrackerTypes.REGULAR, ['reporter']: loggedInUserId }), handleClose)}>Create Issue</MenuItem>
                    <MenuItem onClick={() => (setIssueTrackerToAdd({ ...issuesTrackerToAdd, ['issueType']: issueTrackerTypes.PHONE_CALL, ['reporter']: loggedInUserId }), handleClose)}>Create Phone Call</MenuItem>
                </Menu>
                <IssueTrackerView withDialog issueTracker={issuesTrackerToAdd} onClose={() => setIssueTrackerToAdd()} />
            </div>
        </Authorize>
    )
}

export default CreateIssueMenu;