import { Button, Dialog, DialogContent } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useState } from "react";
import ReservationForm from "./Components/ReservationForm/ReservationForm";

export const ReservationEditAction = ({ reservation, buttonVariant }) => {
    const [open, setOpen] = useState();

    return (
        <>
            <Authorize profile={permissionProfiles.RESERVATIONS.EDIT}>
                <Button
                    color='primary'
                    variant={buttonVariant}
                    onClick={() => setOpen(true)}
                    style={{ width: '100%', justifyContent: 'start' }}
                >
                    Edit                
                </Button>
            </Authorize>
            <Dialog open={open} onClose={() => setOpen()} maxWidth='xs' fullWidth>
                <DialogContent>
                    <ReservationForm reservation={reservation} onSave={() => setOpen()} />
                </DialogContent>
            </Dialog>
        </>)
}