import { Box, Button, Grid, IconButton } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import TextField from '@mui/material/TextField';
import DropdownFilter from 'components/DropdownFilter';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetVehicleTypesQuery, useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { IconCar, IconFilters, IconSearch } from 'icons';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService.js";
import { theme } from 'styles/theme';
import { CreateWorkOrderStyles } from '../CreateWorkOrderStyles';
import { resetFilters, setFilters } from './assetsFiltersSlice';
import { useGetStatusesForClientQuery } from 'features/vehicles/VehicleStatus/vehicleStatusSlice';

const AssetsSelector = ({ assets, setAssets, isNarrowScreen, disabled = false }) => {
    const classes = CreateWorkOrderStyles();

    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.assetsFilters);

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: vehicleStatuses, error: statusError, isLoading: isLoadingStatuses } = useGetStatusesForClientQuery({ clientId: 0 });
    let { data: vehicles, error: vehicleError, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: false });

    lots = lots || [];
    vehicleTypes = vehicleTypes || [];
    vehicleStatuses = vehicleStatuses || [];
    vehicles = vehicles || [];

    const [showFilters, setShowFilters] = useState(false);

    let filterConfig = [
        { type: fieldTypes.LOT, size: 3, title: 'Current Location', key: 'lotId', values: lots },
        { type: fieldTypes.VEHICLE_TYPE, size: 3, title: 'Vehicle Type', key: 'vehicleTypeId', values: vehicleTypes },
        { type: fieldTypes.TEXT, size: 3, title: 'Status', key: 'vehicleStatusId', values: vehicleStatuses },
    ];

    const onDropFilter = (prop, filterValue) => {
        dispatch(setFilters({ ...filters, ...{ [prop]: { value: filterValue?.id ?? '' } } }));
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    }

    let filteredAssets = FilterService.filter(filters, null, vehicles, filterConfig);

    const getOptionLabel = (option) => {
        return `${option.descriptor || ''} ${option.assetId || ''} ${option.licensePlate || ''}`;
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        limit: 30,
    });

    const handleChange = (newValue) => {
        setAssets(newValue);
    }

    const handleFilterClick = () => {
        setShowFilters(!showFilters);
    }

    const renderOption = (props, option) => {
        return (
            <Box  {...props}>
                <Grid container xs={12} alignItems='center' justifyContent='space-between'>
                    <Grid item  >
                        <Grid container alignItems='center'>
                            <Grid item className={classes.itemIcon}>
                                <IconCar />
                            </Grid>
                            <Grid item>
                                <Grid container direction='column'>
                                    {option?.descriptor ? <Grid item >
                                        <label className={classes.itemLabel}> VIN: </label>
                                        {option?.descriptor}
                                    </Grid> : <></>}
                                    {option?.assetId ? <Grid item >
                                        <label className={classes.itemLabel}> Asset ID: </label>
                                        {option?.assetId}
                                    </Grid> : <></>}
                                    {option?.licensePlate ? <Grid item >
                                        <label className={classes.itemLabel}> License Plate: </label>
                                        {option?.licensePlate}
                                    </Grid> : <></>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isNarrowScreen && <Grid item>
                        <Button size='small' variant='contained' color='primary'>
                            Add Asset
                        </Button>
                    </Grid>}
                </Grid>
            </Box>
        )
    }

    return (
        <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={10}>
                <Grid container alignItems='center' >
                    {!disabled && showFilters && filterConfig?.map((filter, index) =>
                        <Grid item xs={3} className={classes.filter} key={index}>
                            <DropdownFilter
                                values={filter.values}
                                onSelect={(val) => onDropFilter(filter.key, val)}
                                value={filters?.[filter.key]?.value}
                                title={filter.title}
                                showEmpty
                                withTypeAhead
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {!disabled && <Grid item>
                {showFilters && <Button onClick={() => onResetFilters()}>Clear All</Button>}
                <IconButton
                    className={classes.filterIconWhite}
                    onClick={handleFilterClick}
                >
                    <IconFilters />
                </IconButton>
            </Grid>}

            <Grid item xs={12} >
                <Autocomplete
                    id="asset-selector"
                    multiple
                    autoComplete
                    filterSelectedOptions
                    PaperComponent={Box}
                    PopperComponent={!disabled ? Box : null}
                    open={!disabled}
                    disabled={disabled}
                    value={assets}
                    options={filteredAssets}
                    loading={isFetchingVehicles}
                    getOptionLabel={(option) => getOptionLabel(option)}
                    filterOptions={filterOptions}
                    isOptionEqualToValue={(option, value) => {
                        return option?.id == value?.id
                    }}
                    onChange={(event, newInputValue) => {
                        handleChange(newInputValue);
                    }}
                    renderOption={(props, option) => renderOption(props, option)}
                    popupIcon={!disabled && <IconSearch fill={theme.palette.primary.main} className={classes.searchIcon} />}
                    renderInput={(params) => (
                        <div>
                            <TextField {...params} fullWidth />
                            < LoadingSpinner loading={isFetchingVehicles} />
                        </div>
                    )}
                    className={classes.autoComplete}
                />
            </Grid>
        </Grid >
    );
}
export default AssetsSelector;