import { Button, Grid, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@mui/material";
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DateTimeField from "components/DateTimePicker/DateTimePicker";
import DropdownFilter from "components/DropdownFilter";
import ActivityInventory from "features/mechanicalWork/components/Inventory/ActivityInventory";
import { NoteContent } from "features/notes/NoteEditor";
import { ChargeCalculation } from "features/pricing/ChargeCalculation/ChargeCalculation";
import { PricingLookup } from "features/pricing/PricingLookup/PricingLookup";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";
import { orderBy } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from 'react';
import Authorize from "../../../components/Authorize";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../components/CustomInput";
import NotificationProvider from "../../../components/NotificationProvider";
import { IconAdd } from "../../../icons";
import FormatService from '../../../services/formatService';
import { ActivityStyles } from '../ActivityStyles';
import { activityCustomFieldSetup, activityStatusOrder, activityStatuses } from "../activityConsts";
import {
    useAddActivityMutation,
    useGetActivityTypePriceByItemIdQuery,
    useGetAllActivitiesQuery,
    useGetAllActivityItemsWithCustomFieldsQuery,
    useGetAllActivityTypesQuery
} from "../activitySlice";

import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import ActivityStatusAliasView from "features/activityStatusAlias/ActivityStatusAliasView";
import { arrayToObject } from "utils/object-util";


const AddActivity = ({ vehicleId, lotId, clientId, onClose, vehicleTypeId, entityId, vehicleDetails }) => {
    const classes = ActivityStyles();

    const [addActivity, { isLoading }] = useAddActivityMutation();

    const [isSaving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [activityTypeId, setActivityType] = useState(null);
    const [activityId, setActivity] = useState(null);
    const [activityItemId, setActivityItem] = useState(null);
    const [price, setPrice] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [note, setNote] = useState();
    const [serviceProviderId, setServiceProviderId] = useState(null);
    const [customActivity, setCustomActivity] = useState(null);
    const [dateTime, setDateTime] = useState(FormatService.formatDateTime(moment()));
    const [inventoryUsed, setInventoryUsed] = useState(null);
    const [useInventory, setUseInventory] = useState(false);
    const [notSupported, setNotSupported] = useState(false);
    const [lotIdLocal, setLotIdLocal] = useState(null);
    const [estimatedDaysToComplete, setEstimatedDaysToComplete] = useState(0);
    const [lotsLocalOptions, setLotsLocalOptions] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [workorderCustomData, setWorkorderCustomData] = useState([]);

    useEffect(() => {
        if (lotId) {
            setLotIdLocal(lotId);
        }
    }, [lotId])

    let {

        data: clientsWithConfigs,
        isLoading: isLoadingAllLots } = useGetClientsWithConfigsQuery(clientId);

    let {
        data: activityTypes, error: activityTypesError,
        isLoading: isLoadingActivityTypes
    } = useGetAllActivityTypesQuery();

    let {
        data: activities, error: activityError,
        isLoading: isLoadingActivity
    } = useGetAllActivitiesQuery();

    let {
        data: activityItemsWithCustomFields, error: activityItemError,
        isLoading: isLoadingActivityItems
    } = useGetAllActivityItemsWithCustomFieldsQuery();

    let {
        data: activityPrices, error: activityPricesError,
        isLoading: isLoadingActivityPrices
    } = useGetActivityTypePriceByItemIdQuery(activityItemId);

    let {
        data: serviceProviders, error: serviceProvidersError,
        isLoading: isLoadingServiceProviders
    } = useGetServiceProvidersQuery();

    useEffect(() => {
        if (clientsWithConfigs && clientsWithConfigs.length > 0) {
            let lots = (clientsWithConfigs[0]?.clientLots?.map(x => ({ name: x.name, id: x.lotId })) || []);
            setLotsLocalOptions(lots);
        }
    }, [clientsWithConfigs])

    activityTypes = activityTypes || [];
    activities = orderBy(activities?.map(a => ({ ...a, order: activityStatusOrder[a.id] })), 'order') || [];
    activityItemsWithCustomFields = activityItemsWithCustomFields || [];
    activityPrices = activityPrices || {};
    serviceProviders = serviceProviders || {};

    const activityItemsLookup = arrayToObject(activityItemsWithCustomFields);
    let currentActivityItem = activityItemsLookup?.[activityItemId];

    useEffect(() => {
        if(vehicleDetails) {
            let customDataWithVehicleFields = [...workorderCustomData] || []
            currentActivityItem?.activityItemCustomFields.map(customField => {
                if(customField.mappedTo) {
                    if(!workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value) {
                        let value = activityCustomFieldSetup?.[customField?.mappedTo]?.mappedValue(vehicleDetails);
                        if(value) {
                            let updated = onChangeCustomFieldData(customField, value)
                            customDataWithVehicleFields = [...customDataWithVehicleFields, ...updated]
                        }
                    }
                }
            })
            setWorkorderCustomData(customDataWithVehicleFields);
        }
    }, [currentActivityItem]);

    let activityPrice = activityPrices

    if (!activityPrice) {
        activityPrice = { price: 0, revenue: 0 };
    }

    const isCustomActivity = () => {
        if (!activityItemId) {
            return false;
        }

        let activityItem = activityItemsLookup?.[activityItemId];

        return activityItem?.isCustom;
    };

    const onChangeCustomFieldData = (customField, val) => {
        let customDataList = [...workorderCustomData] || []
        let index = customDataList.findIndex(c => c.activityItemCustomFieldId == customField.id);
        if (index == -1) {
            customDataList = [...customDataList, {
                activityItemCustomFieldId: customField.id,
                value: val,
                mappedTo: customField?.mappedTo
            }]
        } else {
            customDataList[index] = { ...customDataList[index], ["value"]: val };
        }
        setWorkorderCustomData(customDataList)
        return customDataList
    }

    const onSelectDateTime = (e) => {
        setDateTime(e)
    }

    const resetform = () => {
        setSelectedActivity(null)
        setActivityType(null);
        setEstimatedDaysToComplete(0);
        setActivity(null);
        setActivityItem(null);
        setCustomActivity(null);
        setDateTime(FormatService.formatDateTime(moment()));
        setPrice(0);
        setRevenue(0);
        setServiceProviderId(null)
        setIsSaving(false);
        onClose();
        setNote();
        setInventoryUsed(null);
        setUseInventory(false);
        setNotSupported(false);
        setWorkorderCustomData([])
    }

    const handleClickOpen = () => {
        resetform()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetform()
    };

    const onChangeValue = (key, val) => {
        switch (key) {
            case "activitytype":
                setActivityType(getIntValue(val));
                break
            case "activityitem":
                setActivityItem(getIntValue(val));
                updatePrice(0);
                break;
            case "activity":
                setActivity(getIntValue(val));
                break;
            case "price":
                updatePrice(val);
                break;
            case "revenue":
                updateRevenue(val);
                break;
            case "serviceProvider":
                updateServiceProvider(getIntValue(val));
                break;
            case "content":
                setNote({ ...note, content: val });
                break;
            case "viewByClient":
                setNote({ ...note, viewByClient: val })
        }
    };

    const getIntValue = (val) => {
        return parseInt(val);
    };

    const updatePrice = price => {
        setPrice(price);
    }

    const updateRevenue = revenue => {
        setRevenue(revenue);
    }

    const updateServiceProvider = serviceProvider => {
        setServiceProviderId(serviceProvider);
    }

    const onClickFinish = async () => {
        setIsSaving(true);
        let date = new Date(moment(dateTime).format("yyyy-MM-DD, , hh:mm a") + " UTC");
        let activityDate = date.toISOString();
        let invToSend = inventoryUsed?.filter(i => !(i.inventoryId === null && i.unitNumber === null));

        let data = {
            activityTypeId,
            activityItemId,
            activityId,
            vehicleIds: [parseInt(vehicleId)],
            serviceProviderId,
            lotId: lotIdLocal,
            price,
            revenue,
            activityDate,
            note,
            estimateDaysToComplete: estimatedDaysToComplete,
            inventoryUsed: invToSend,
            workorderCustomData: workorderCustomData
        };

        if (isCustomActivity()) {
            data.customActivity = customActivity;
        }

        let result = await addActivity(data);
        if (result && !result.error) {
            NotificationProvider.success("Activity added successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
        handleClose();
    };

    const handleSelectChange = (option) => {
        const value = JSON.parse(option);
        onChangeValue("activitytype", value.activityTypeId);
        onChangeValue("activityitem", value.activityItemId);
        setSelectedActivity(option);
    };

    const combinedData = useMemo(() => {
        const sortedActivityTypes = [...activityTypes].sort((a, b) => a.name.localeCompare(b.name));
        const sortedActivityItems = [...activityItemsWithCustomFields].sort((a, b) => a.name.localeCompare(b.name));

        return sortedActivityTypes.map(type => ({
            label: type.name,
            options: sortedActivityItems
                .filter(item => item.activityTypeId === type.id)
                .map(item => ({ label: item.name, value: { activityTypeId: type.id, activityItemId: item.id } }))
        }));
    }, [activityTypes, activityItemsWithCustomFields]);


    useEffect(() => {
        if (!activityPrice) {
            return;
        }

        updatePrice(activityPrice?.price);
        updateRevenue(activityPrice?.revenue);
    }, [activityItemId]);

    useEffect(() => {
        if (!activityPrice) {
            return;
        }

        updatePrice(activityPrice?.price ?? price);
        updateRevenue(activityPrice?.revenue ?? revenue);
    }, [activityPrice]);

    useEffect(() => {
        setInventoryUsed([]);
    }, [useInventory])

    const isValid = activityTypeId && activityItemId && activityId;
    let statusValues = activities?.filter(a => a.id !== activityStatuses.ItemCompleted && a.id != activityStatuses.CompleteBilling)//For now completed is only allowed for edit of a group the add API needs to support this

    return (
        <>
            <Button variant="contained" color="primary"
                onClick={handleClickOpen} startIcon={<IconAdd />}>
                Add Activity
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Add Activity
                </DialogTitle>
                <DialogContent>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2} direction='column'>
                            {!isLoadingActivityTypes && !isLoadingActivityItems && (
                                <Grid item>
                                    <CustomInput
                                        id="ddlActivityTypeId"
                                        label="Activity Type"
                                        elementType="dropdown"
                                        combinedData={combinedData}
                                        value={selectedActivity}
                                        required={true}
                                        showEmpty={true}
                                        onChange={handleSelectChange}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <DropdownFilter
                                    values={lotsLocalOptions}
                                    onSelect={(val) => setLotIdLocal(val)}
                                    value={lotIdLocal}
                                    title='Select Activity Location'
                                    showEmpty
                                    required
                                />
                            </Grid>
                            {
                                isCustomActivity() &&
                                <Grid item>
                                    <CustomInput
                                        id="ddlCustomActivity"
                                        label="Custom"
                                        elementType="text"
                                        value={customActivity}
                                        required={true}
                                        onChange={val => setCustomActivity(val)}
                                    />
                                </Grid>
                            }
                            {!isLoadingActivity &&
                                <Grid item>
                                    <ActivityStatusAliasView
                                        activityIds={statusValues?.map(a => a.id)}
                                        clientId={clientId}
                                        readonly={false}
                                        dropdownValues={statusValues}
                                        onChange={val => onChangeValue("activity", val)}
                                    />
                                </Grid>
                            }
                            {currentActivityItem?.activityItemCustomFields?.map(customField =>
                                <Grid item>
                                    {activityCustomFieldSetup?.[customField?.mappedTo]?.component ?
                                        activityCustomFieldSetup?.[customField?.mappedTo]?.component(customField,
                                            workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value,
                                            onChangeCustomFieldData)
                                        :
                                        <CustomInput
                                            id="ddlCustomFieldData"
                                            label={customField?.label}
                                            elementType="text"
                                            value={workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value}
                                            onChange={(val) => onChangeCustomFieldData(customField, val)}
                                        />
                                    }
                                </Grid>
                            )
                            }
                            {!isLoadingServiceProviders &&
                                <Grid item>
                                    <CustomInput
                                        id="ddlServiceProviderId"
                                        label="Service Provider"
                                        elementType="dropdown"
                                        values={serviceProviders}
                                        value={serviceProviderId}
                                        showEmpty={true}
                                        onChange={val => onChangeValue("serviceProvider", val)}
                                    />
                                </Grid>
                            }
                            {notSupported && <Grid item>
                                <Alert severity="error">This service provider does not offer this service in this location</Alert>
                            </Grid>}
                            <Grid item>
                                <DateTimeField
                                    label='Activity Date'
                                    value={dateTime}
                                    onChange={onSelectDateTime}
                                />
                            </Grid>
                            <Grid item>
                                <CustomInput
                                    id="estimatedDaysToComplete"
                                    label="Estimated Days To Complete"
                                    numberOnly={true}
                                    value={estimatedDaysToComplete}
                                    elementType="input"
                                    onChange={(val) => setEstimatedDaysToComplete(val != "" ? val : 0)}
                                />
                            </Grid>
                        </Grid>
                        <Authorize profile={permissionProfiles.ASSETS.EDIT_ACTIVITY}>
                            <Grid container spacing={2} className={classes.pricing} >
                                <Grid item xs={10}>
                                    <CustomInput
                                        id="txtPrice"
                                        label="Internal Cost"
                                        elementType="input"
                                        value={price}
                                        numberOnly={true}
                                        onChange={val => onChangeValue("price", val != "" ? val : 0)}
                                    />
                                </Grid>
                                <Grid item >
                                    <PricingLookup
                                        onChange={val => onChangeValue("price", val != "" ? val : 0)}
                                        activityItemId={activityItemId}
                                        lotId={lotIdLocal}
                                        vehicleTypeId={vehicleTypeId}
                                        serviceProviderId={serviceProviderId}
                                        setServiceProviderId={setServiceProviderId}
                                        price={price}
                                        setUseInventory={setUseInventory}
                                        setNotSupported={setNotSupported}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={classes.pricing} alignItems="center" >
                                <Grid item xs={9}>
                                    <CustomInput
                                        id="txtRevenue"
                                        label="Charge"
                                        elementType="input"
                                        value={revenue}
                                        numberOnly={true}
                                        onChange={val => onChangeValue("revenue", val != "" ? val : 0)}
                                    />
                                </Grid>
                                <Grid item >
                                    <ChargeCalculation
                                        onChange={val => onChangeValue("revenue", val != "" ? val : 0)}
                                        clientId={clientId}
                                        activityType={activityTypeId}
                                        lotId={lotIdLocal}
                                        price={price}
                                        inventoryUsed={!!inventoryUsed?.length ? inventoryUsed : null}
                                    />
                                </Grid>
                            </Grid>
                        </Authorize>
                        <Grid item>
                            <div>Note</div>
                            <NoteContent content={note?.content} viewByClient={note?.viewByClient} onChange={onChangeValue} />
                        </Grid>
                    </Paper>
                    <Grid item>
                        {useInventory &&
                            <ActivityInventory inventoryUsed={inventoryUsed} setInventoryUsed={setInventoryUsed} activityLog={{ id: null, lotId: lotIdLocal }}></ActivityInventory>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.btnContainer}>
                        <Button className={classes.button} variant="outlined"
                            onClick={() => handleClose()}>Cancel</Button>
                        <Button
                            onClick={onClickFinish}
                            disabled={!isValid || isSaving}
                            className={classes.button}
                            variant="contained" >
                            {isSaving ? "Saving..." : "Finish"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddActivity;
