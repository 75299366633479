import { Grid, IconButton, Paper } from "@material-ui/core";
import { ArrowBack } from '@material-ui/icons';
import DropdownFilter from "components/DropdownFilter";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import ContractHeader from "features/clients/contracts/ContractHeader/ContractHeader";
import { useEffect, useState } from "react";
import FilterService from "services/filterService";
import AddRule from "../../AddRule";
import { useGetMechanicalRuleTypesQuery, useGetMechanicalsConfigQuery } from "../../mechanicalsSlice";
import LotParams from "./LotParams";
import Rule from './Rule';
import { lotMechanicalConfigStyles } from "./style";

const LotMechanicalConfig = ({ clientId, lotConfig, onClose, clientLots, mechanicalsRulesToEdit, setMechanicalsRulesToEdit }) => {
    const classes = lotMechanicalConfigStyles();

    let { data: mechanicalsRules, error: mechanicalsRulesError, isLoading: mechanicalsRulesLoading } = useGetMechanicalsConfigQuery({ clientId, lotId: !!lotConfig ? lotConfig?.lotId : 0}, {skip: !lotConfig});
    let { data: mechanicalsRulesTypes, error: mechanicalsRulesTypesError, isLoading: mechanicalsRulesTypesLoading } = useGetMechanicalRuleTypesQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const [filters, setFilters] = useState({})

    useEffect(() => {
        setMechanicalsRulesToEdit(mechanicalsRules?.data?.rules ?? []);
    }, [mechanicalsRules]);

    const onCancel = () => {
        setMechanicalsRulesToEdit([...mechanicalsRulesToEdit.filter(l => !l?.isNew)])
    }

    const onFilter = (filterName, filterValue) => {
        setFilters({ ...filters, [filterName]: { value: filterValue } })
    }

    const filteredData = FilterService.filter(filters, undefined, mechanicalsRulesToEdit, [{ type: 'text', size: 2, key: 'activityId', values: activityItems }]);
    const filteredActivityItems = activityItems?.filter(a => mechanicalsRulesTypes?.find(m => m.activityId === a.id))

    return <Grid container direction='column' spacing={2} className={classes.lotContainer}>
        <LoadingSpinner loading={mechanicalsRulesLoading} />
        <Grid item>
            <Paper className={classes.lotName}>
                <IconButton onClick={onClose} >
                    <ArrowBack />
                </IconButton>
                {lotConfig?.name}
            </Paper>
        </Grid>
        <Grid item>
            {mechanicalsRules?.data?.config && <LotParams lotConfig={mechanicalsRules?.data?.config} clientId={clientId} lotId={lotConfig.lotId} />}
        </Grid>

        <Grid item justifyContent="space-between">
            <ContractHeader
                label={`Mechanical Rules (${mechanicalsRulesToEdit?.length ?? 0})`}
            />
            <AddRule
                clientId={clientId}
                lotConfig={lotConfig}
                clientLots={clientLots}
                mechanicalsRulesToEdit={mechanicalsRulesToEdit}
                setMechanicalsRulesToEdit={setMechanicalsRulesToEdit}
            />
        </Grid>
        {!!mechanicalsRulesToEdit?.length && <Grid item>
            <DropdownFilter
                title='Filter by activity...'
                onSelect={(val) => onFilter('activityId', val)}
                values={filteredActivityItems}
                value={filters?.activityId?.value}
                showEmpty
            />
        </Grid>}
        <Grid item>
            {
                filteredData?.map(rule => <Rule key={rule.id} rule={rule} onCancel={onCancel} clientLots={clientLots} />)//shouldn't be .data check why
            }
            {!(!!filteredData?.length) && <EmptyPlaceholder text="No Rules Defined" />}
        </Grid>

    </Grid>
}
export default LotMechanicalConfig;
