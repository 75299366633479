import { makeStyles, TextField } from '@material-ui/core';
import React, { forwardRef, useEffect } from 'react';
import Input from 'react-phone-number-input/input';

const useStyles = makeStyles((theme) => ({
    phoneInput: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        '& fieldset': {
            border: 'none'
        }
    }
}));

const PhoneInput = ({
    title,
    identifier,
    onChange,
    minDate,
    maxDate,
    value = null,
    required = false,
    disabled = false,
    valid = true,
    label
}) => {
    const classes = useStyles();

    const [phoneValue, setPhoneValue] = React.useState(value)

    useEffect(() => {
        setPhoneValue(value)
    }, [value]);

    return (
        <div className={classes.phoneInput}>
            <Input
                variant="outlined"
                defaultCountry="US"
                value={value}
                //defaultValue={}
                onChange={onChange}
                error={!valid}
                label={label}
                disabled={disabled}
                required={required}
                fullWidth
                InputLabelProps={{ className: classes.label }}
                inputComponent={phoneTextField}
            />
        </div>
    )
}

export default PhoneInput

const phoneTextField = forwardRef((props, ref) => {

    return (
        <TextField
            {...props}
            inputRef={ref}
            variant='outlined'
            name='phone'
        />
    )
})

