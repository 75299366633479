import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Authorize from "components/Authorize";
import { isAuthorized } from "components/Authorize/authorizeUtil";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import PageComponent from "components/Page/PageComponent";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import usePrompt from "hooks/usePrompt";
import useSortOptions from "hooks/useSortOptions";
import { IconAdd, IconWarning } from "icons";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import FormatService from "services/formatService";
import { useDeletePriceMutation, useGetAllActivityPricingQuery } from "../pricingSlice";
import AddActivityPrice from "./AddActivityPrice/AddActivityPrice";
import BulkUpdatePriceDialog from "./BulkUpdatePriceDialog";
import UpdateActivityPrice from "./UpdateActivityPrice/UpdateActivityPrice";
import { setFilters } from "./activityPricingFiltersSlice";
import { toggleOptions } from "./pricingConsts";
import { PricingListStyle } from "./style";

const PricingList = ({ }) => {
    const classes = PricingListStyle()
    const { triggerPrompt } = usePrompt();

    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const handleClose = () => setOpenDialog(false);
    const handleEditClose = () => setOpenEditDialog(false);

    const [activityPriceToEdit, setActivityPriceToEdit] = useState(null);

    let { data: activityPricing, error: activityPricingError, isFetching: isLoadingActivityPricing } = useGetAllActivityPricingQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const [deletePrice, { isLoading: isDeleting }] = useDeletePriceMutation();

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    activityPricing = activityPricing?.map(activityPrice => {
        return {
            ...activityPrice,
            activityItem: activityItems?.find(item => item.id == activityPrice.activityItemId)?.name,
            lot: lotLookup?.[activityPrice?.lotId]?.name,
            vehicleTypes: vehicleTypes?.filter(v => activityPrice?.vehicleTypeIdsList?.includes(v.id))?.map(n => n.name).join(', '),
            serviceProvider: serviceProviders?.find(s => s.id == activityPrice.serviceProviderId)?.name,
            formattedPrice: FormatService.formatCurrency(activityPrice?.price)
        }
    });

    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.activityPricingFilters);
    const dispatch = useDispatch();

    const filterConfig = [
        { type: 'text', size: 2, title: 'Activity', key: 'activityItemId', values: activityItems },
        { type: 'text', size: 2, title: 'Service Provider', key: 'serviceProviderId', values: serviceProviders },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.VALUE_LIST, size: 4, title: 'Vehicle Types', key: 'vehicleTypeIdsList', values: vehicleTypes },
    ];

    let activityPricingColumns = [
        {
            name: "Activity",
            sortKey: "activityItem",
            key: "activityItem",
            component: (row) => <>
                <div className={classes.field}>{row?.activityItem}</div>
            </>
        },
        {
            name: "Service Provider",
            sortKey: "serviceProvider",
            key: "serviceProvider",
            component: (row) => <>
                <div className={classes.field}>{row?.serviceProvider}</div>
            </>
        },
        {
            name: "Lot",
            sortKey: "lot",
            key: "lot",
            component: (row) => <>
                <div className={classes.field}>{row?.lot ? row?.lot : (row?.allLots ? "All Lots" : "All PMF Lots")}</div>
            </>
        },
        {
            name: "Vehicle Type",
            sortKey: "vehicleType",
            key: "vehicleType",
            component: (row) => <>{
                <div className={classes.field}>
                    {
                        row?.vehicleTypeIdsList?.length > 1 ?
                            <Tooltip title={row?.vehicleTypes}>
                                <div>{row?.vehicleTypeIdsList?.length + " Vehicle types"}</div>
                            </Tooltip>
                            : (row?.vehicleTypeIdsList ?
                                row?.vehicleTypes : "All Vehicle types")
                    }
                </div>
            }
            </>
        },
        {
            name: "Price",
            sortKey: "price",
            key: "price",
            component: (row) => <>
                <div className={classes.field}>{row?.formattedPrice}</div>
            </>
        },
        {
            name: "Uses Inventory?",
            sortKey: 'useInventory',
            key: "useInventory",
            component: (row) => <>
                <div className={classes.field}>{row?.useInventory ? 'Yes' : 'No'}</div>
            </>
        },
        {
            name: "",
            key: "notSupported",
            component: (row) => <>
                {row?.notSupported ?
                    <Tooltip title='This combination is not supported'>
                        <div className={classes.warningIcon}><IconWarning /></div>
                    </Tooltip>
                    : null}
            </>
        }
    ];

    const rowActions = (rowId) => ([
        {
            component:
                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                    <>
                        <IconButton onClick={() => onEditClick(rowId)} >
                            <EditIcon />
                        </IconButton>
                    </>
                </Authorize>
        },
        {
            component:
                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                    <>
                        <IconButton disabled={isDeleting} onClick={() => onDelete(rowId)} >
                            <DeleteIcon />
                        </IconButton>
                    </>
                </Authorize>,
        }
    ])

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    let filteredData = FilterService.filter(filters, null, activityPricing, filterConfig);

    const isLoading = isLoadingActivityPricing || isLoadingActivityItems || isLoadingLots || isLoadingVehicleTypes || isLoadingServiceProviders || isDeleting;

    const [openModel, setOpenModel] = useState(false);

    const onAdd = () => {
        setActivityPriceToEdit(null);
        setOpenDialog(true);
    }

    const onEditClick = (id) => {
        let row = activityPricing.find(a => a.id === id);
        setActivityPriceToEdit({
            ...row,
            ...(row?.lotId ? { priceAppliesTo: toggleOptions.SPECIFIC_LOTS, includeNonPmf: true } : { priceAppliesTo: (row?.allLots ? toggleOptions.ALL_LOTS : toggleOptions.ALL_PMF_LOTS) })
        });
        setOpenEditDialog(true);
    }

    const onDelete = async (id) => {
        triggerPrompt({
            title: "Delete Activity Price",
            content: "Are you sure you want to delete this activity price?",
            onConfirm: async () => {
                let res = await deletePrice({ id });

                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        for (let [key, value] of params) {
            dispatch(setFilters({ ...filters, [key]: { value: Number.isInteger(value) ? value.toString() : value } }));
        }
    }, [window.location.search])

    return (
        <>
            <PageComponent className={classes.card} header={
                <DataListHeader
                    title={
                        <div className={classes.titleWrapper}>
                            Activity Pricing
                        </div>}
                    data={activityPricing}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <>
                            <Grid container spacing={1}>
                                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                            onClick={onAdd}
                                            startIcon={<IconAdd />}
                                        >
                                            Add Activity Price
                                        </Button>
                                    </Grid>
                                </Authorize>
                            </Grid>
                        </>
                    )}
                />}>

                <DataTable
                    columns={activityPricingColumns}
                    rows={orderBy(
                        filteredData,
                        sortOptions.columnToSort,
                        sortOptions.sortDirection
                    )}
                    rowIdentifier='id'
                    onSort={sortData}
                    actions={
                        rowActions
                    }
                    maxActionCount={2}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                    noItemsMessage='There are no activity prices matching this criteria'
                    headerStyle={{
                        paddingLeft: '1rem',
                    }}
                    data={activityPricing}
                    bulkActions={isAuthorized(permissionProfiles.PRICING.MANAGE_PPRICING) ?
                        [<BulkUpdatePriceDialog />] : undefined}
                />

                <AddActivityPrice open={openDialog} handleClose={handleClose} setOpenModel={setOpenModel} />
                <UpdateActivityPrice open={openEditDialog} handleClose={handleEditClose} setOpenModel={setOpenModel} activityPriceToEdit={activityPriceToEdit} setActivityPriceToEdit={setActivityPriceToEdit}
                    activityPricing={activityPricing} activityItems={activityItems} serviceProviders={serviceProviders} lots={lots} vehicleTypes={vehicleTypes} isLoading={isLoading} />

                <Dialog open={openModel} onClose={() => setOpenModel(false)}>
                    <DialogContent>The price database already contains a definition for this activity, lot, service provider and vehicle type</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModel(false)} color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
                <LoadingSpinner loading={isLoading} />
            </PageComponent>
        </>
    )
}

export default PricingList;
