import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useApprovePendingApprovalMutation } from "features/activity/activitySlice";
import { useEffect, useState } from "react";
import { approveActivityStyle } from "./style";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

const ApprovePendingApprovalActivity = ({
    activityLog,
}) => {

    const classes = approveActivityStyle();
    const [approveActivity, { isLoading }] = useApprovePendingApprovalMutation();
    const [cost, setCost] = useState(activityLog?.price ?? 0);
    const [revenue, setRevenue] = useState(activityLog?.revenue ?? 0);
    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCost(activityLog.price);
        setRevenue(activityLog.revenue);
    }, [activityLog]);

    const onSave = async () => {
        if (!cost || !revenue) {
            setShowError(true);
            return;
        }
        setShowError(false);
        const res = await approveActivity({
            activityLogId: activityLog.id,
            price: cost,
            revenue: revenue
        });
        if (res?.data?.error) {
            NotificationProvider.error(`An error occured while approving the activity, please try again.`);
            return;
        }
        if (!res?.data?.valid) {
            NotificationProvider.error(`Failed to approve the request for the following reason: ${res?.data?.statusText}. 
            The price has been saved and an email was sent to the Account Manager for further handling.
             The current sum is: ${res?.data?.currentSum}, the price cap is: ${res?.data?.priceCap}`);

        } else {
            NotificationProvider.success('Successfully approved the activity and updated the price');
        }
        setOpen(false);
    };

    return (
        <>
            {<Authorize profile={permissionProfiles.ACTIVITY.PENDING_APPROVAL_ADD_PRICE}>
                <Button startIcon={<CheckBoxOutlinedIcon style={{ fontSize: '1.2em' }} />} onClick={() => setOpen(true)} start>
                    Add Price & Approve
                </Button>
            </Authorize>}
            <Dialog open={open} onClose={() => setOpen(false)} >
                <DialogTitle id={'dialog-title'} >Approve Activity</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <CustomInput
                                id="cost"
                                label="Internal Cost"
                                value={cost}
                                elementType="input"
                                required={true}
                                onChange={setCost}
                                valid={cost || !showError}
                                numberOnly
                                touched
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="cost"
                                label="Charge"
                                value={revenue}
                                elementType="input"
                                required={true}
                                onChange={setRevenue}
                                valid={revenue || !showError}
                                numberOnly
                                touched
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={onSave} color='primary' variant="contained" disabled={isLoading}>
                        {isLoading ? 'Saving...' : 'Approve Activity'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ApprovePendingApprovalActivity;