import { createSlice } from "@reduxjs/toolkit";

export const loadGroupViewSlice = createSlice({
    name: 'loadGroupViewState',
    initialState: {
        selectedLoad: null,
    },
    reducers: {
        setSelectedLoad: (state, action) => {
            state.selectedLoad = action.payload;
        }
    },
});

export const { setSelectedLoad } = loadGroupViewSlice.actions;
export default loadGroupViewSlice.reducer;
