import { makeStyles } from "@material-ui/core"

export const VehicleListStyles = makeStyles((theme) => ({
    driversWrapper: {
        margin: '0 1rem',
    },
    input: {
        margin: 16,
        position: 'relative'
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    addButton: {
        marginTop: "0.5rem",
        width: '100%'
    },
    dropdown: {
        // marginBottom: '0.5rem'
    },
    label: {
        fontWeight: 500
    },
    listItemIcon: {
        marginRight: '2rem',
        padding: '1px'
    },
    lists: {
        // marginBottom: '1rem'
    },
    detailsWrapper: {
        marginTop: '1rem'
    },
    listWrapper: {
        padding: '0 0.5rem',
        backgroundColor: '#F3F5F6',
        maxHeight: 300,
        flex: 1,
        overflowY: 'auto',
    },
    listItem: {
        margin: '0.5rem 0',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '2px solid',
        borderColor: theme.palette.grey[200],
    },
    paper: {
        padding: '1rem'
    },
    title: {
        padding: '0 0.5rem',
        paddingBottom: '1rem',
        fontWeight: 500
    }
}));