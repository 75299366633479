import { Button, Grid } from "@material-ui/core"
import Authorize from "components/Authorize"
import { permissionProfiles } from "components/Authorize/permissionProfiles"
import IssuesTrackerList from "features/IssuesTracker/IssuesTrackerList"
import IssueTrackerView from "features/IssuesTracker/IssueTrackerView/IssueTrackerView"
import { IconAdd, IconMap } from "icons"
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle"
import { issueTrackerTypes } from "features/IssuesTracker/issueTrackerConsts"
import { useState } from "react"

const VehicleTickets = ({ vehicleDetails, client }) => {
    const classes = vehicleDetailsPageStyle();

    const [issueTrackerToAdd, setIssueTrackerToAdd] = useState();

    return (
        <>
            <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW}>
            <Grid className={classes.extendedDataWrapper} container spacing={2}>
                    <Grid container alignItems='center' justifyContent='space-between' style={{flexWrap: 'nowrap'}}>
                        <Grid item container direction="row" alignItems="center">
                            <Grid item className={classes.iconWrapper}>
                                <IconMap />
                            </Grid>
                            <Grid item className={classes.ticketsTitle}>
                                Open Tickets
                            </Grid>
                        </Grid>
                        <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    className={classes.addButton}
                                    onClick={() =>
                                        setIssueTrackerToAdd({
                                            ...issueTrackerToAdd,
                                            issueType: issueTrackerTypes.REGULAR,
                                            //reporter: loggedInUserId,
                                            clientId: client?.id,
                                            lotId: vehicleDetails?.lotId,
                                            linkedAssets: [
                                                vehicleDetails
                                            ],
                                        })
                                    }
                                    startIcon={<IconAdd />}
                                >
                                    New Ticket
                                </Button>
                            </Grid>
                        </Authorize>
                    </Grid>
                    <IssuesTrackerList vehicleId={vehicleDetails?.id} embeddedView={true} />
                </Grid>
            </Authorize>
            <IssueTrackerView withDialog issueTracker={issueTrackerToAdd} onClose={() => setIssueTrackerToAdd()} />
        </>
    )
}

export default VehicleTickets