import {
    Button,
    IconButton,
    Tooltip,
    Typography
} from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import { useGetLatestFlagQuery } from "features/flag/flagsSlice";
import { selectIsAccountManager, selectIsClient, selectIsOwner, selectUserClientId } from "features/user/userSlice";
import { IconFlag, IconRedFlag } from "icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FlagAssetDialog } from "./FlagAssetDialog";
import { FlagTooltip } from "./FlagTooltip";
import { bulkActionsStyle } from "./style";

export const FlagAsset = ({
    selectedRows,//injected by the bulkActions
    allData,
    addFlag,
    getFlagFromApi = true,
    flagOn,
    entityType,
    iconOnly,
    iconClassName,
    readonly,
    sourceType,
    flagObj
}) => {

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let { data: flagFromApi, error: flagsError, isFetching: isLoadingflags } = useGetLatestFlagQuery({ entityIds: selectedRows, entityType: entityType }, { skip: !getFlagFromApi || !selectedRows.join(',') });

    const flagObject = flagObj ?? flagFromApi;
    sourceType = flagFromApi?.sourceType ?? sourceType;

    const classes = bulkActionsStyle();
    const [open, setOpen] = useState(false);

    let isOwner = useSelector(selectIsOwner);
    let isAccountManager = useSelector(selectIsAccountManager);
    let isClient = useSelector(selectIsClient);
    let userClientId = useSelector(selectUserClientId);

    const loggedInClientConfig = clientsWithConfigs?.find(c => c.id == userClientId);
    const canSeeFlags = isOwner || isAccountManager || (isClient && loggedInClientConfig?.config?.lotConfig?.seeFlags)

    const isFlagOn = flagFromApi || flagOn;

    const textByType = {
        [FlagEntityTypes.ASSET]: selectedRows?.length > 1 ? 'Assets' : 'Asset',
        [FlagEntityTypes.ACTIVITY]: selectedRows?.length > 1 ? 'Activities' : 'Activity',
        [FlagEntityTypes.TRANSPORT]: selectedRows?.length > 1 ? 'Loads' : 'Load',
        [FlagEntityTypes.ISSUE_TRACKER]: selectedRows?.length > 1 ? 'Tickets' : 'Ticket',
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const onOpenPopover = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closePopover = (e) => {
        setAnchorEl();
    }

    const openPopover = Boolean(anchorEl);

    const onOpen = () => {
        setOpen(true);
    }


    return (
        <>{canSeeFlags && (!readonly || (readonly && isFlagOn)) && <>
            {!isFlagOn ?
                <Authorize profile={permissionProfiles.ASSETS.FLAG_ASSETS}>
                    {iconOnly ? <div className={iconOnly && classes.flagOffIconContainer}><Tooltip title={`Flag ${textByType[entityType]}`}>
                        <IconButton className={iconOnly ? classes.flagOffIcon : iconClassName} onClick={onOpen}><IconFlag /></IconButton ></Tooltip>
                    </div> :
                        <Authorize profile={permissionProfiles.ASSETS.FLAG_ASSETS}>
                            <Button onClick={onOpen} className={classes.button}>
                                <div>
                                    <div item>
                                        <IconFlag />
                                    </div>
                                    <div item>
                                        {`Flag ${textByType[entityType]}`}
                                    </div>
                                </div>
                            </Button>
                        </Authorize>}
                </Authorize>
                :
                <Authorize profile={permissionProfiles.ASSETS.VIEW_FLAGGED_ASSETS}>
                    <Typography
                        aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={onOpenPopover}
                        onMouseLeave={closePopover}
                    >
                        <FlagTooltip
                            anchorEl={anchorEl}
                            closePopover={closePopover}
                            sourceType={sourceType}
                            entityType={entityType}
                            flagObj={flagObject} />
                        <IconButton onClick={!readonly && onOpen} disabled={readonly}>
                            <IconRedFlag />
                        </IconButton>
                    </Typography>
                </Authorize>
            }
            {open && <FlagAssetDialog
                setOpen={setOpen}
                open={open}
                selectedRows={selectedRows}
                allData={allData}
                addFlag={addFlag}
                flagOn={isFlagOn}
                entityType={entityType}
                sourceType={sourceType}
            />}
        </>}
        </>
    )

}