import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { listSortStyle } from './style';
import DropdownFilter from 'components/DropdownFilter';
import { ArrowDownwardSharp, ArrowUpwardSharp } from '@material-ui/icons';
import { Button, Grid, Icon, IconButton, Tooltip } from '@material-ui/core';
import SortRoundedIcon from '@material-ui/icons/SortRounded';

export const ListSort = ({
    sortOptions,
    onOrderByChange,
    onDirectionChange,
    orderBy,
    orderByDirection
}) => {
    const classes = listSortStyle();

    const asc = 'asc';
    const desc = 'desc';
    return (
        <Grid container alignItems='center'>
            {/* <SortRoundedIcon /> */}
            <Grid item >
                Sort by
            </Grid>
            <Grid item>
                <DropdownFilter
                    className={classes.select}
                    values={sortOptions}
                    value={orderBy}
                    onSelect={(val) => onOrderByChange(val)}
                />
            </Grid>
            <Grid item>
                <Tooltip title='Ascending'>
                    <IconButton
                        size='small'
                        className={orderByDirection == asc ? classes.selectedSort : ''}
                        onClick={() => onDirectionChange(asc)}>
                        <Icon component={ArrowUpwardSharp} className={orderByDirection == asc ? classes.selectedSortIcon : ''} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Descending'>
                    <IconButton size='small'
                        className={orderByDirection == desc ? classes.selectedSort : ''}
                        onClick={() => onDirectionChange(desc)}>
                        <Icon component={ArrowDownwardSharp} className={orderByDirection == desc ? classes.selectedSortIcon : ''} />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}