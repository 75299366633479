import * as React from "react";

function SvgIconTruck(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.75C4.20421 2.75 2.75 4.20421 2.75 6V13.25H13C13.6858 13.25 14.25 12.6858 14.25 12V5V2.75H6ZM1.25 6V14V17C1.25 19.0742 2.92579 20.75 5 20.75H5.35352C5.67998 21.9043 6.74122 22.75 8 22.75C9.25878 22.75 10.32 21.9043 10.6465 20.75H13.3535C13.68 21.9043 14.7412 22.75 16 22.75C17.2588 22.75 18.32 21.9043 18.6465 20.75H19C21.0742 20.75 22.75 19.0742 22.75 17V14V12C22.75 11.8698 22.7161 11.7418 22.6516 11.6286L20.9416 8.6286C20.9414 8.62828 20.9412 8.62797 20.9411 8.62766M19.8542 9.75H19C18.8642 9.75 18.75 9.86421 18.75 10V13C18.75 13.1358 18.8642 13.25 19 13.25H21.25V12.1987L19.8542 9.75ZM20.9403 8.62632L19.231 5.63767L19.231 5.63766L19.2286 5.63341C18.7377 4.788 17.8339 4.25 16.84 4.25H15.75V2C15.75 1.58579 15.4142 1.25 15 1.25H6C3.37579 1.25 1.25 3.37579 1.25 6M18.9971 8.25L17.9314 6.38661L17.9303 6.38475C17.701 5.99126 17.2854 5.75 16.84 5.75H15.75V12C15.75 13.5142 14.5142 14.75 13 14.75H2.75V17C2.75 18.2458 3.75421 19.25 5 19.25H5.35352C5.67998 18.0957 6.74122 17.25 8 17.25C9.25878 17.25 10.32 18.0957 10.6465 19.25H13.3535C13.68 18.0957 14.7412 17.25 16 17.25C17.2588 17.25 18.32 18.0957 18.6465 19.25H19C20.2458 19.25 21.25 18.2458 21.25 17V14.75H19C18.0358 14.75 17.25 13.9642 17.25 13V10C17.25 9.03676 18.0342 8.25159 18.9971 8.25ZM16 21.25C15.3096 21.25 14.75 20.6904 14.75 20C14.75 19.3142 15.3142 18.75 16 18.75C16.6858 18.75 17.25 19.3142 17.25 20C17.25 20.6904 16.6904 21.25 16 21.25ZM8 18.75C8.68579 18.75 9.25 19.3142 9.25 20C9.25 20.6904 8.69036 21.25 8 21.25C7.30964 21.25 6.75 20.6904 6.75 20C6.75 19.3142 7.31421 18.75 8 18.75Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconTruck;
