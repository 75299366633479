import * as React from "react";

function SvgIconAreas(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15 28.017a.936.936 0 0 1-.67-.278l-7.28-7.28a11.25 11.25 0 1 1 15.9 0l-7.28 7.28a.936.936 0 0 1-.67.278zm0-24.863A9.354 9.354 0 0 0 8.39 19.12l6.61 6.61 6.61-6.61A9.354 9.354 0 0 0 15 3.154z"
                fill="#0090FE" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.475 17.247a.68.68 0 0 0 .288-.33L12.548 15l-.785 1.919a.68.68 0 0 1-.288.329zm3.469-10.594a.86.86 0 0 1 .054-.002c.329 0 .553.16.671.48l3.866 9.225c.1.256.082.489-.055.699.137-.21.155-.443.055-.7l-3.866-9.224c-.118-.32-.342-.48-.671-.48l-.054.002zM13.03 13.82h3.955-3.955l1.994-4.873-1.994 4.873zm3.361-.401h-2.763l1.393-3.404 1.37 3.404zm-4.26 3.66.687-1.679h4.37l.672 1.667c.083.209.22.382.405.51l.014.01.015.008c.184.106.384.163.597.163.383 0 .715-.155.926-.486.215-.33.234-.7.091-1.063l-3.865-9.226a1.14 1.14 0 0 0-.397-.538 1.095 1.095 0 0 0-.648-.195c-.228 0-.446.06-.634.195a1.228 1.228 0 0 0-.407.528l-3.851 9.197c-.16.36-.141.733.07 1.07.209.336.533.518.929.518.207 0 .4-.06.575-.166.206-.12.356-.297.45-.513z"
                fill="#0090FE" />
        </svg>
    );
}

export default SvgIconAreas;
