import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectUserClientId } from "../../user/userSlice";
import Button from '@material-ui/core/Button';
import { Add as AddIcon } from "@material-ui/icons";
import AddClientPopup from "../AddClientPopup";
import BasicModal from "../../../components/BasicModal";
import { useAddClientMutation, useGetClientsWithConfigsQuery } from "../clientConfigSlice";
import CustomInput from "../../../components/CustomInput";
import { Grid, Paper } from "@material-ui/core";
import ContractsPageStyles from "./ContractsPageStyles";
import ContractsList from "../contracts/ContractsList/ContractsList";
import Authorize from "../../../components/Authorize";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import NotificationProvider from "../../../components/NotificationProvider";
import LoadingSpinner from "../../../components/LoadingSpinner";
import EditIcon from '@material-ui/icons/Edit';
import EditClientPopup from "../EditClientPopup/EditClientPopup";
import { arrayToObject } from "utils/object-util";

const useStyles = ContractsPageStyles;

const emptyClient = {
    clientLotIds: [],
    clientTransporters: [],
    loadMoveTypes: [],
    config: {
        transportConfig: {
            useTransport: false,
            transportTechFee: null,
            transporterMargin: null
        },
        washConfig: {
            useWashProgram: false
        }
    },
    washPrices: [],
}

const ContractsPage = () => {

    const classes = useStyles();

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isLoading: isLoadingClientsWithConfigs, isFetching: isFetchingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let [addClient] = useAddClientMutation();

    clientsWithConfigs = clientsWithConfigs || [];

    const isLoading = () => isFetchingClientsWithConfigs;

    const [selectedClient, setSelectedClient] = useState();
    const [currentClient, setCurrentClient] = useState();
    const [open, setOpen] = useState(false);
    const [clientToAdd, setClientToAdd] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [clientToEdit, setClientToEdit] = useState();
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const clientLookup = arrayToObject(clientsWithConfigs, 'accountId');

    const onCreate = async () => {
        setClientToAdd({ ...emptyClient });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const onSave = async () => {
        setIsSaving(true);
        const result = await addClient(clientToAdd)
        if (result?.error) {
            NotificationProvider.error('Failed to save the contract');
        } else {
            NotificationProvider.success('Successfully saved the contract');
            setOpen(false);
        }
        setIsSaving(false);
    }

    const onEdit = async () => {
        setClientToEdit(currentClient);
        setOpenEditPopup(true);
    }

    const getClientValues = () => {
        let dropdownValues = clientsWithConfigs?.map(c => ({ ...c, id: c.accountId, clientId: c.id, name: c.name }))
        let defaultClientAccounts = dropdownValues?.filter(a => a.isDefaultAccount === true);
        if (!(!!defaultClientAccounts.length)) { //this is when, the only clients a user has access to are subclients then we want to show them
            return dropdownValues;
        } else
            return defaultClientAccounts;
    }

    const userClientId = useSelector(selectUserClientId);

    return (
        <Paper className={classes.contractsPage}>
            <LoadingSpinner loading={isLoading()} />
            <div className={classes.pageContent}>
                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_MANAGE}>
                    <div className={classes.header}>
                        <div className={classes.title}>Search Client</div>
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <CustomInput
                                    id="clientId"
                                    label="Client"
                                    value={selectedClient?.accountId}
                                    elementType="dropdown"
                                    onChange={val => {
                                        setSelectedClient({ id: clientLookup[+val].id, accountId: +val });
                                        setCurrentClient();
                                    }}
                                    values={getClientValues()}
                                    showEmpty
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={isSaving}
                                    onClick={() => setCurrentClient(selectedClient)}>
                                    Go to Client SLAs
                                </Button>
                                {!!currentClient && <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<EditIcon />}
                                    disabled={isSaving || !currentClient}
                                    onClick={onEdit}
                                >
                                    Edit Client
                                </Button>}
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        disabled={isSaving}
                                        onClick={onCreate}>
                                        Create Client
                                    </Button>
                                </Authorize>
                            </Grid>
                        </Grid>
                    </div>
                </Authorize>

                {!!currentClient && <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_MANAGE}><ContractsList clientId={currentClient?.id} clientAccountId={currentClient?.accountId} clientsWithConfigs={clientsWithConfigs} /></Authorize>}

                {userClientId && <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_VIEW}>
                    <ContractsList clientId={userClientId} clientsWithConfigs={clientsWithConfigs} />
                </Authorize>}

                <BasicModal
                    open={open}
                    handleClose={handleClose}
                    header={'Add New Client'}
                    component={<AddClientPopup setOpen={setOpen} clientToEdit={clientToAdd} setClientToEdit={setClientToAdd} onSave={onSave} />}
                />

                <EditClientPopup
                    open={openEditPopup}
                    setOpenEditPopup={setOpenEditPopup}
                    clientToEdit={clientToEdit}
                    setClientToEdit={setClientToEdit}
                />
            </div>
        </Paper>
    );
};

export default ContractsPage;
