import { Grid } from "@material-ui/core";
import { ReservationEditAction } from "../NewReservation/ReservationEditAction";
import { DocumentationRequest } from "../ReservationActions/DocumentationRequest";
import { reservationColors } from "../ReservationStatusFilter/ReservationStatusFilter";
import { ReservationViewStyle } from "./style";

export const ReservationSummary = ({ reservation }) => {
    const classes = ReservationViewStyle()

    const fields = [
        {
            value: `${reservation?.clientName} ${reservation?.subClientName ?? ''}`,
            label: 'Client',
        },
        {
            value: reservation?.carrierName ?? '-',
            label: 'DSP/Carrier'
        },
        {
            value: reservation?.pickupLotName ?? '-',
            label: 'Pickup at'
        },
        {
            value: reservation?.deliveryLotName ?? '-',
            label: 'Drop off at'
        },
        {
            value: reservation?.formattedPickupDate ?? '-',
            label: 'Estimated pickup date'
        },
        {
            value: reservation?.pickupTime ?? '-',
            label: 'Estimated pickup time'
        },
        {
            value: reservation?.formattedDeliveryDate ?? '-',
            label: 'Estimated drop off date'
        },
        {
            value: reservation?.deliveryTime ?? '-',
            label: 'Estimated drop off time'
        },
        {
            value: reservation?.formattedActualPickUpDate ?? '-',
            label: 'Actual pick up date'
        },
        {
            value: reservation?.formattedActualDropOffDate ?? '-',
            label: 'Actual drop off date'
        },
        {
            value: reservation?.formattedInsuranceExpiryDate ?? '-',
            label: 'Insurance expiry date'
        },
        {
            value: reservation?.totalQuantity ?? '-',
            label: 'Quantity'
        },
        {
            value: reservation?.pocName ?? '-',
            label: 'POC name',
        },
        {
            value: reservation?.pocEmail ?? '-',
            label: 'POC email'
        },
        {
            value: reservation?.pocPhone ?? '-',
            label: 'POC phone number',
            size: 12
        },


    ];

    return (<Grid container spacing={1} >
        <Grid item xs={12} alignItems="center" container spacing={1}
            className={classes.summaryStatus}
            style={{ borderColor: reservationColors?.[reservation.status], color: reservationColors?.[reservation.status] }}>
            <Grid item className={classes.summaryId}>{reservation?.resId}</Grid>
            <Grid item > {reservation?.statusName}</Grid>
        </Grid>
        {fields?.map((field, index) => <Grid container spacing={1} key={index} item xs={field?.size ?? 6}>
            <Grid item className={classes.summaryLabel}>{field?.label}</Grid>
            <Grid item className={classes.summaryField}>{field?.value}</Grid>
        </Grid>)}
        <Grid container justifyContent="flex-end" alignItems="center">
            <DocumentationRequest reservation={reservation} />
            <Grid item justifyContent="flex-end">
                <ReservationEditAction reservation={reservation} buttonVariant="outlined" />
            </Grid>
        </Grid>
    </Grid>)
}