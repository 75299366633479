import { makeStyles } from "@material-ui/core";

export const PricingLookupStyle = makeStyles((theme) => ({
    statusItem: {
        padding: '0.5em 1em',
        cursor: 'pointer',
    },
    selectItem: {
        padding: '0.5em 0',
        fontWeight: '500',
        '&.filled': {
            paddingLeft: '0.5em'
        }
    },
    selectMenu: {
    },
    select: {
        fontSize: '1em',
        padding: 0,
        borderRadius: 0,
        '& fieldset': {
            border: 'none'
        },
    },
    disabledIcon: {
        fill: 'grey'
    }
}));