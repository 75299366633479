import { makeStyles } from "@material-ui/core"

export const locationSelectorStyle = makeStyles((theme) => ({
    lotFilterContainer: {
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        backgroundColor: '#f3f5f6',
        cursor: 'pointer',
        lineHeight: '1.1876em'
    },
    lotFilterHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    lotFilterCheck: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '1rem'
    },
    lotFilterLabel: {
        fontWeight: 500,
        paddingLeft: '0.5rem',
        fontSize: 17
    },
    content: {
        padding: '0.5rem 0',
        fontWeight: 500
    },
    ItemName: {
        width: '75%'
    },
    locationTypesTitle: {
        padding: '0.5rem 1rem',
        color: theme.palette.primary.main,
    }
}));