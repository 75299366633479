import { makeStyles } from "@material-ui/core";

export const lotsListStyle = makeStyles((theme) => ({
    header: {
        padding: '16px',
        margin: "0 1rem 1rem 0",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    headerMobile: {
        marginRight: 0
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '1.5rem'
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        border: 0,
    },
    listContainer: {
        padding: '0.5rem 1rem 0 0'
    },
    lotMobile: {
        maxWidth: "100%",
        flexBasis: "100%"
    },
    listItemContainer: {
        padding: '0 0.5rem 0 0.5rem'
    },
    listItemPaper: {
        height: '100%'
    },
    deletePopup: {
        padding: '1em',
        maxWidth: 400,
        margin: '1em',
        fontWeight: 600
    },
}));
