import { makeStyles } from "@material-ui/core";

export const loadMapStyle = makeStyles((theme) => ({
    mapWrapper: {
        height: '100%'
    },
    lotIcon: {
        position: 'absolute',
        marginTop: '-6px'
    },
    historyIcon: {
        color: '#c93788'
    }
}));
