export const DoorReleaseTypes = [
    { id: 1, name: "Manual" },
    { id: 2, name: "Automatic" },
    { id: 3, name: "Api" }
];

export const DoorReleaseTypeById = {
    MANUAL: 1,
    AUTOMATIC: 2,
    API: 3,
};
