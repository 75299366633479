import { Button, Divider, Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { IconProfileCircle } from "icons";
import { useGetContactTypesQuery } from "../../../../../contactType/contactTypeSlice";
import { lotDispatchDetailsStyle } from "../style";

const LotContacts = ({
    lot, onEdit
}) => {
    const classes = lotDispatchDetailsStyle();
    let { data: contactTypes, error: contactTypesError, isFetching: isContactTypes } = useGetContactTypesQuery();

    const dispatchMemberType = contactTypes?.filter(type => type.isPoc);
    const filteredContacts = lot?.contacts?.filter(contact =>
        dispatchMemberType?.some(type => type.id === contact?.contactTypeId)
    );

    return (
        <Grid direction='column' spacing={1}>
            <Paper className={classes.detailCard}>
                <div className={classes.cardHeader}>
                    <div className={classes.cardHeaderTitle}>
                        <IconProfileCircle className={classes.titleIcon} />
                        <div className={classes.cardTitle}>Contacts</div>
                    </div>
                    <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                        {onEdit && <Button variant="outlined" onClick={() => onEdit(lot)} className={classes.editButton}>Edit</Button>}
                    </Authorize>
                </div>
                {filteredContacts?.length > 0 ? filteredContacts?.map((poc, index) =>
                    <>
                        <div className={classes.poc}>
                            <div className={classes.pocLabel}><label >Name: </label>{poc?.name}</div>
                            <div className={classes.pocLabel}><label >Email: </label>{poc?.email}</div>
                            <div className={classes.pocLabel}><label >Phone: </label>{poc?.phone}</div>
                        </div>
                        {index !== filteredContacts?.length - 1 && <Divider className={classes.openingHoursDivider} />}
                    </>
                ) :
                    <EmptyPlaceholder text="No Contacts" />
                }
            </Paper>
        </Grid>
    )
}
export default LotContacts;
