import FormOutput from "features/Forms/FormOutput/FormOutput";
import { useGetCustomCheckinQuery } from "../vehicleSlice";
import { vehicleDetailsPageStyle } from "../VehicleDetailsPage/VehicleDetailsPageStyle";
import { Grid } from "@material-ui/core";
import { EditFormOutput } from "features/Forms/FormOutput/EditFormOutput/EditFormOutput";
import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";

export const VehicleCustomCheckIn = ({ vehicle, formType }) => {
    const classes = vehicleDetailsPageStyle();

    let { data: customFormResponse, error: formError, isLoading: isLoadingForms } = useGetCustomCheckinQuery({ vehicleId: vehicle?.id, formType: formType });

    return (
        <>
            <FormOutput
                loading={isLoadingForms}
                fileName={`${formType == formTypes.Checkin ? "checkin" : "checkout"}-${vehicle?.descriptor}.pdf`}
                headerContent={<Grid container alignItems='center' justifyContent="space-between" style={{ padding: '1em' }}>
                    <h2 className={`${classes.title}`}>{`${formType == formTypes.Checkin ? "Check-In" : "Check-out"} For Asset: ${vehicle?.descriptor ?? vehicle?.assetId}`}</h2>
                    <Grid item ><EditFormOutput customFormResponse={customFormResponse} lotId={vehicle.lotId}/></Grid>
                </Grid>}
                {...customFormResponse}
            />
        </>
    )
}