import { IconCar } from "icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { vehicleMapStyle } from "../VehicleMapStyle";
import { Checkbox, Divider, Popover } from "@material-ui/core";
import { useState } from "react";
import { assetLocationsTypes } from "../VehicleMapConsts";

const AssetViewSelector = ({ assetFilters, setAssetFilters }) => {
    const classes = vehicleMapStyle();
    const [anchorEl, setAnchorEl] = useState(null);

    const openPanel = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closePanel = () => {
        setAnchorEl();
    }

    return (
        <>
            <div className={classes.lotFilterContainer} onClick={openPanel}>
                <div className={classes.lotFilterCheck}>
                    <IconCar color={"#0090FE"} height='24' width='24' />
                    <div className={classes.lotFilterLabel}>Asset View</div>
                </div>
                <ExpandMoreIcon />
            </div>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closePanel}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: { width: '21%' },
                }}
            >
                <div className={classes.content}>
                    {assetLocationsTypes?.map((location, index) => <>
                        <div className={classes.lotFilterCheck}>
                            <Checkbox
                                checked={!!assetFilters?.[location.id]}
                                onChange={(e, val) => {
                                    setAssetFilters({...assetFilters, [location.id]: val})
                                }}
                            />
                            <div className={classes.ItemName}> {location?.name}</div>
                            <div>{location.icon()}</div>
                        </div>
                        {index != assetLocationsTypes.length - 1 && <Divider />}

                    </>)}
                </div>
            </Popover>
        </>
    )
}
export default AssetViewSelector;