import { makeStyles } from "@material-ui/core";

export const LotSelectorStyle = makeStyles((theme) => ({
    text: {
        color: '#51AD97',
        paddingLeft: '0.3rem',
    },
    iconWrapper: {
        '& path': {
            stroke: '#51AD97'
        },
        '& svg': {
        }
    }
}))