import { Button, Grid, Grow, IconButton, Popover, Popper, Tooltip } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Authorize from "components/Authorize";
import { useEffect, useState } from "react";
import { rowActionsStyle } from "./style";

const RowActions = ({
    actions,
    cellData,
    showActionsInMenu,
    isContextMenu,
    contextMenuEvent
}) => {

    useEffect(() => {
        if (contextMenuEvent) {
            setShowMenu(true);
            setPosition({
                left: contextMenuEvent.clientX - 2,
                top: contextMenuEvent.clientY - 4,
            })
        } else {
            setShowMenu(false);
            setPosition();
        }
    }, [contextMenuEvent]);

    const classes = rowActionsStyle();
    const [anchorEl, setAnchorEl] = useState();
    const [position, setPosition] = useState();
    const [showMenu, setShowMenu] = useState(false);

    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
        setShowMenu(true);
    }

    const closeMenu = (e) => {
        setAnchorEl();
        setPosition();
        setShowMenu(false);
    }

    const toggleMenu = (e) => {
        if (showMenu) {
            closeMenu();
        } else {
            openMenu(e);
        }
    }

    const actionsList = (actions && actions(cellData)) ?? [];
    const content = <Grid className={showActionsInMenu ? classes.menu : undefined} direction={showActionsInMenu ? 'column' : 'row'} alignItems='center' container >
        {actionsList.map((action, index) =>
            <Authorize profile={action.profile} key={index}>
                <div className={showActionsInMenu && classes.buttonWrapper}>
                    {action.component ? action.component :
                        action.label ? <Button color='primary' className={classes.button} startIcon={action.icon} onClick={() => action.onClick(cellData)}>{action.label}</Button>
                            : <IconButton className={classes.iconButton} onClick={() => action.onClick(cellData)}>{action.icon}</IconButton>}

                </div>
            </Authorize>
        )}
    </Grid>;

    return (
        <>{showActionsInMenu ? <>
            {!isContextMenu && <Tooltip title='Actions'>
                <IconButton className={classes.toggleButton} onClick={toggleMenu}>
                    <MoreHorizIcon />
                </IconButton>
            </Tooltip>}

            <Popover
                open={showMenu}
                onClose={closeMenu}
                anchorEl={isContextMenu ? undefined : anchorEl}
                anchorReference={isContextMenu ? "anchorPosition" : undefined}
                anchorPosition={isContextMenu ? position : undefined}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {content}
            </Popover>
        </>
            :
            <>{content}</>}
        </>
    )
}
export default RowActions;