import { ResponsiveContainer, XAxis, Tooltip, LineChart, Line, AreaChart, YAxis, Label, Legend } from "recharts";
import { IssueTrackerDashboardStyle } from "../style";
import moment from "moment";
import { theme } from "styles/theme";
import GraphHeader from "../GraphHeader/GraphHeader";

export const OpenCloseIssuesOverTimeWidget = ({ dashboardData, graphHeight }) => {
    const classes = IssueTrackerDashboardStyle();

    const issueList = dashboardData?.issues;

    const dataGrouped = {};
    issueList?.forEach(issue => {
        let displayCreatedDate = moment(issue.createdDate).format('YYYY-MM-DD').toString();
        let displayCompletedDate = issue.completedDate ? moment(issue.completedDate).format('YYYY-MM-DD').toString() : undefined;
        if (!dataGrouped[displayCreatedDate]) {
            dataGrouped[displayCreatedDate] = {
                displayDate: displayCreatedDate,
                date: issue?.createdDate,
                name: displayCreatedDate,
                'Created': 0,
                'Completed': 0,
            };
        }
        dataGrouped[displayCreatedDate]['Created']++;
        if (!displayCompletedDate) return;
        if (!dataGrouped[displayCompletedDate]) {
            dataGrouped[displayCompletedDate] = {
                displayDate: displayCompletedDate,
                date: issue?.completedDate,
                name: displayCompletedDate,
                'Created': 0,
                'Completed': 0,
            };
        }
        dataGrouped[displayCompletedDate]['Completed']++;
    });

    let sortedData = Object.values(dataGrouped)?.sort((a, b) => (a?.date - b?.date));

    const formatDate = (date) => {
        return moment(date).format('MMM YY').toString();
    }

    return (
        <div style={{ height: graphHeight }}>
            <GraphHeader title='Created and Resolved Over Time' />
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={sortedData} margin={{ bottom: 15, top: 15 }}>
                    <XAxis axisLine={{ stroke: '#ccc' }} tickLine={false} tick={false} dataKey="displayDate" />
                    <YAxis
                        axisLine={{ stroke: '#ccc' }}
                        width={20}
                        tickLine={false}
                        tick={{ color: theme.palette.primary.main, fontSize: '0.7em', fontWeight: 'bold' }}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="Created" stroke={theme.palette.primary.main} dot={<></>} />
                    <Line type="monotone" dataKey="Completed" stroke={'#BF56FF'} dot={<></>} />
                    <Legend
                        iconType="circle"
                        layout="horizontal"
                        verticalAlign="bottom"
                        iconSize={10}
                        formatter={(value, entry, index) => <span style={{ fontSize: '0.8em' }}>{value}</span>}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

}