import { Button, Grid } from "@material-ui/core";
import { withUserPreferences } from "components/UserPreferences/WithUserPreferences"
import { userPreferencesComponentIds, userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import { IconMap } from "icons";
import { useEffect } from "react";

const VehiclesMapButton = ({showMap, setShowMap, style, savedCustomValue, saveCustomValue}) => {

    useEffect(() => {
        if(savedCustomValue) {
            setShowMap(savedCustomValue === "true" ? true : false);
        }
    }, [savedCustomValue])

    return (
        <Grid item>
            <Button variant="outlined" color="primary" className={style}
                startIcon={<IconMap />}
                onClick={() => {setShowMap(!showMap); saveCustomValue((!showMap).toString())}}>{showMap ? "Hide Map" : "Show Map"}</Button>
        </Grid>
    );
}

export default withUserPreferences(VehiclesMapButton, userPreferencesComponentIds.ASSET_MAP, userPreferencesTypes.CUSTOM_VALUE);