import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export const entityAuditStyle = makeStyles((theme) => ({
    label: {
        fontWeight: 'bold'
    },
    tooltip: {
        padding: '1em',
        width: '25em'
    },
    icon: {
        width: '0.8em'
    }
}));
