import { makeStyles } from "@material-ui/core";

export const virtualLotStyle = makeStyles((theme) => ({
    svg: {
        maxHeight: "calc(100vh - 100px)",
    },
    shape: {
        fill: 'none',
        stroke: '#b2b2b2',
        strokeMiterlimit: 10,
        strokeWidth: '5px'
    },
    spot: {
        stroke: '#b2b2b2',
        strokeMiterlimit: 10,
        strokeWidth: '3px',
        cursor: "pointer",
        // "&:hover": {
        //     fill: theme.palette.primary.main
        // }
    },
    empty: {
        fill: "#BADD7F"
    },
    occupied: {
        fill: theme.palette.primary.light
    },
    overage: {
        fill: '#ffd78d'
    },
    leased: {
        fill: theme.palette.primary.main
    },
    legendIcon: {
        height: '1em',
        width: '1em',
        borderRadius: '50%',
        marginRight: '1em',
        marginLeft: '1em'
    },
    emptyLegend: {
        background: "#BADD7F"
    },
    occupiedLegend: {
        background: theme.palette.primary.light
    },
    overageLegend: {
        background: '#ffd78d'
    },
    leasedLegend: {
        background: theme.palette.primary.main
    },
    legend: {
        background: '#efefef',
        borderRadius: '5px',
        padding: '0.5em',
        marginBottom: '1em'
    }
}));