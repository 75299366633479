import { makeStyles } from "@material-ui/core";

export const lotOverviewStyle = makeStyles((theme) => ({
    box: {
        padding: '2em',
        height:'100%'
    },
    mapWrapper: {
        height: 'calc(100vh - 30px)'
    }
}));