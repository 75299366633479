import { AccessGroupTypes } from "../accessConsts";



const AccessValidator = {
    validateGroupTypeStep: (access) => {
        return access?.groupType;
    },
    validateAccessInfoStep: (access, isClient = false) => {
        switch (access?.groupType) {
            case !isClient && AccessGroupTypes.CLIENT:
                return access?.clientId;
            case AccessGroupTypes.CARRIER:
                return access?.carrierId || access.carrierName;
            case AccessGroupTypes.TRANSPORTER:
                return access?.transporterId || access.transporterName;
            case AccessGroupTypes.VENDOR:
                return access?.serviceProviderId || access.serviceProviderName;
            default:
                return true;
        }
    },
};

export default AccessValidator;