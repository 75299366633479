import { Button, Card, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { activityStatusesList } from "features/activity/activityConsts";
import { useGetAllActivitiesQuery } from "features/activity/activitySlice";
import { useGetAliasesByClientQuery, useUpdateAliasesMutation } from "features/activity/activityStatusAliasSlice";
import { useEffect, useState } from "react";
import { arrayToObject } from "utils/object-util";
import { mechanicalConfigStyles } from "../../../style";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const ActivityStatusAliases = ({ clientId }) => {
    const classes = mechanicalConfigStyles();

    let { data: activities, error: activitiesError, isFetching: isLoadingActivities } = useGetAllActivitiesQuery();
    let { data: clientAliases, error: aliasesError, isFetching: isLoadingClientAliases } = useGetAliasesByClientQuery(clientId)
    let [saveAliases] = useUpdateAliasesMutation();

    const [aliases, setAliases] = useState(clientAliases);
    const [edited, setEdited] = useState(false);
    const aliasLookup = arrayToObject(aliases, 'activityStatusId');

    useEffect(() => {
        setAliases(clientAliases);
    }, [clientAliases])

    const onAliasChange = (statusId, val) => {
        setEdited(true);
        const index = aliases?.findIndex(alias => alias.activityStatusId === statusId);
        let aliasesToUpdate = aliases?.length > 0 ? [...aliases] : [];

        if(index != -1) {
            let updatedAlias = { ...aliasesToUpdate[index], alias: val };
            aliasesToUpdate[index] = updatedAlias;
        }
        else {
            let newAlias = {activityStatusId: statusId, clientId: clientId, alias: val};
            aliasesToUpdate.push(newAlias);
        }
        setAliases(aliasesToUpdate);
    }

    const onSave = async () => {
        let aliasesToSave = aliases.filter(a => a.alias.length > 0);
        setAliases(aliasesToSave);
        let result = await saveAliases({aliases: aliasesToSave});
        if(!result.error) {
            NotificationProvider.success('Aliases saved successfully');
            setEdited(false);
            setAliases([]);
        } else {
            NotificationProvider.error('Failed to save the aliases');
            setAliases([]);
        }
    }

    return (
        <>
            <List>
                {activities?.map(status => 
                    <Card className={classes.listItemStatuses} >
                        <ListItem key={status?.id} >
                            <ListItemText primary={activityStatusesList.find(a => a.id === status?.id)?.name} />
                            <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.MANAGE_ACTIVITY_STATUS_ALIASES} readonlyComponent={<><div className={classes.alias}>{aliasLookup[status?.id]?.alias}</div></>}>
                                <div>
                                    <CustomInput 
                                        id="alias"
                                        label='Enter Alias'
                                        value={aliasLookup[status?.id]?.alias}
                                        onChange={val => onAliasChange(status?.id, val)}
                                        elementType='input'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div> 
                            </Authorize>
                        </ListItem>
                    </Card>
                )}
            </List>
            <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.MANAGE_ACTIVITY_STATUS_ALIASES}>
                <Grid className={classes.save}>
                    <Button 
                        onClick={onSave} 
                        color="primary" 
                        variant='contained'
                        disabled={isLoadingActivities || isLoadingClientAliases || !edited}
                        style={{ width: '100px' }}
                    >
                        Save
                    </Button>
                </Grid>
            </Authorize>
        </>
    )
}

export default ActivityStatusAliases;