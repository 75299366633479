import { makeStyles } from '@material-ui/core/styles';

export const LAsTaskListStyle = makeStyles((theme) => ({
    card: {
        marginBottom: '1rem',
        padding: '2rem '
    },
    title: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    supersFilter: {
        marginTop: '1rem'
    }
}));
