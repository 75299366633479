import { formStatusOptions } from "../formBuilderConsts"
import { FormVersionStyles } from "./style"

export const FormStatusBadge = ({ status }) => {
    const classes = FormVersionStyles();
    return (
        <div className={`${classes.statusBadge} ${classes[`status_${status}`]}`}>
            {
                formStatusOptions?.find(s => s.id == status)?.name
            }
        </div>
    )
} 