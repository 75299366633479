import { apiTags } from "app/store/apiTags";
import { baseQuery } from "../../app/baseQuery";
import { baseApi } from 'app/baseApi';

export const driverApi = baseApi.injectEndpoints({
    reducerPath: 'driverApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getDrivers: builder.query({
            query: () => `driver/all`,
            providesTags:['Driver', apiTags.ACCESS]
        }),
        addDriver: builder.mutation({
            query: driver => ({
                url: "driver",
                method: "POST",
                body: driver
            }),
            invalidatesTags: ["Driver"]
        }),
        deleteDriver: builder.mutation({
            query: driver => ({
                url: "driver",
                method: "DELETE",
                body: driver
            }),
            invalidatesTags: ["Driver"]
        }),
    }),
});

export const { useGetDriversQuery, useAddDriverMutation, useDeleteDriverMutation } = driverApi;
