import { makeStyles } from "@material-ui/core/styles";

export const dashboardStyles = makeStyles((theme) => ({
    dashboard: {
        //maxWidth: 1100,
    },
    card: {
        margin: '1rem 0',
        padding: '2rem ',
        paddingBottom: '5rem'
    },
    icon: {
        marginRight: "8px",
        height: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontWeight: 500,
        marginRight: "8px",
        color: theme.typography.color
    },
    titleRow: {
        marginBottom: "32px"
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0
    },
    subTitleRow: {
        marginBottom: "16px"
    },
    event: {
        color: theme.palette.primary.dark,
        marginBottom: 19
    },
    counter: {
        color: theme.palette.primary.main,
        fontSize: 72,
        fontWeight: 700
    },
    counterIcon: {
        width: 50,
        height: 50,
        opacity: 0.3
    },
    formControl: {
        minWidth: 200,
    },
    ready: {
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '0.8em',
        width: '15px'
    },
    redIndicator: {
        background: '#fd8f8f',
        color: '#fd8f8f',
    },
    greenIndicator: {
        background: '#a6ef69',
        color: '#a6ef69',

    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    filters: {
        marginBottom: '1em'
    },
    button:{
        height: 55
    }
}));
