import { Button, Grid } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { IconEdit } from "icons";
import { useHistory } from "react-router-dom";
import { arrayToObject } from 'utils/object-util';
import { useGetActivityPricingByLotIdQuery } from '../../../../pricing/pricingSlice';
import { lotDialogStyle } from ".././LotDialogStyle";

const LotServiceProviders = ({ lotId }) => {
    const classes = lotDialogStyle();

    const history = useHistory();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    let { data: prices, error: pricesError, isLoading: isLoadingPrices } = useGetActivityPricingByLotIdQuery(lotId, { skip: !lotId });

    activityItems = activityItems || [];
    serviceProviders = serviceProviders || [];
    prices = prices || [];

    const activitiesLookup = arrayToObject(activityItems, 'id');

    // Grouping prices by serviceProviderId and filtering out duplicate activities
    const groupedData = serviceProviders
        ?.filter(serviceProvider => prices?.some(price => price?.serviceProviderId === serviceProvider?.id))
        ?.map(serviceProvider => {
            const serviceProviderPrices = prices?.filter(price => price?.serviceProviderId === serviceProvider?.id);
            const distinctActivityIds = new Set();
            const uniquePrices = serviceProviderPrices?.filter(p => !p.notSupported)?.filter(price => {
                if (!distinctActivityIds?.has(price?.activityItemId)) {
                    distinctActivityIds?.add(price?.activityItemId);
                    return true;
                }
                return false;
            });
            const activities = uniquePrices?.map(price => activitiesLookup[price?.activityItemId]);
            return {
                serviceProvider,
                activities
            };
        });

    const isLoading = isLoadingActivityItems || isLoadingServiceProviders || isLoadingPrices;

    const goToPricingDb = () => {
        history.push(`/pricing?selectedTab=1&lotId=${lotId}`);
    }

    return (
        <>
            <LoadingSpinner loading={isLoading} />
            <Grid container>

                <Grid item xs={12}>
                    <Grid item xs={12} className={classes.emptyWrapper}>
                        {!groupedData?.length && <EmptyPlaceholder text='No Service Providers Found' />}
                    </Grid>
                    {groupedData?.map((group) => (
                        <div key={group.serviceProvider?.id}>
                            <Grid container alignItems='center' justifyContent='flex-start' direction='column' className={classes.serviceProviderWrapper}>
                                <Grid container >
                                    <Grid item >
                                        <label className={classes.serviceProviderName}>{group.serviceProvider?.name}</label>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row'>
                                    {group.activities?.map(activity => (
                                        <Grid item key={activity?.id} className={classes.serviceProviderChip}>
                                            <Chip label={activity?.name} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Grid>

                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                    <Grid item xs={12} >
                        <Grid container>
                            <Button
                                variant="outlined"
                                onClick={() => goToPricingDb()}
                                startIcon={<IconEdit />}
                            >
                                Edit in Price DB
                            </Button>
                        </Grid>
                    </Grid>
                </Authorize>

            </Grid>
        </>
    );
}

export default LotServiceProviders;