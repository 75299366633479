import { makeStyles } from "@material-ui/core";

export const rowActionsStyle = makeStyles((theme) => ({
    menu: {
        minWidth: '150px',
        background: 'white',
    },
    
    buttonWrapper: {
        width: '100%',
    },
    button: {
        width: '100%',
        justifyContent: 'normal',
        color: theme.palette.text.primary,
    }
}));