import * as React from "react";

function SvgIconMileage(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.056 4.606A12.5 12.5 0 1 1 21.94 25.398 12.5 12.5 0 0 1 8.057 4.604l-.001.002zm1.067 19.188A10.577 10.577 0 0 0 15 25.577 10.589 10.589 0 0 0 25.576 15a10.577 10.577 0 1 0-16.453 8.794zM20.373 8.27l1.357 1.36-4.134 4.134a2.912 2.912 0 1 1-1.36-1.36l4.135-4.134h.002zm-5.91 7.53a.962.962 0 1 0 1.07-1.6.962.962 0 0 0-1.07 1.6zm8.242-4.71-1.467 1.468a6.49 6.49 0 0 1 .49 2.442 6.66 6.66 0 0 1-.961 3.418l1.413 1.413a8.609 8.609 0 0 0 .527-8.74h-.002zm-5.334-2.355A6.486 6.486 0 0 0 15 8.27 6.73 6.73 0 0 0 8.269 15a6.654 6.654 0 0 0 .962 3.418L7.819 19.83A8.653 8.653 0 0 1 18.846 7.256L17.37 8.735z"
                fill="#0090FE"
            />
        </svg>
    );
}

export default SvgIconMileage;
