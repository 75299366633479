import { Grid, IconButton, ListItem, Button, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import { useGetAllInventoryByWorkOrderQuery, useUpdateActivityTypeInventoryMutation } from 'features/activity/ActivityTypeInventorySlice';
import { useGetAllInventoryQuery } from "features/inventory/inventorySlice";
import { useEffect, useState } from 'react';
import NotificationProvider from 'components/NotificationProvider';
import { InventoryStyles } from './style';
import { IconDeleteBlue } from 'icons';
import AddIcon from '@material-ui/icons/Add';
import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import { useSelector } from 'react-redux';
import { selectIsClient } from 'features/user/userSlice';
import Authorize from 'components/Authorize';
import { activityPermissionProfile } from 'components/Authorize/permissionProfiles/activityPermissionProfile';

const InventoryUsed = ({ activity, setHasUnsavedChanges }) => {

    const classes = InventoryStyles();

    let isClient = useSelector(selectIsClient);

    let [updateInventories] = useUpdateActivityTypeInventoryMutation();
    let { data: inventoriesUsed, isFetching: isLoading, refetch: refetchInventories } = useGetAllInventoryByWorkOrderQuery(activity?.workOrderId, { skip: !activity?.workOrderId });
    let { data: inventories } = useGetAllInventoryQuery();

    const [inventoriesToEdit, setInventoriesToEdit] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [overwriteConfig, setOverwriteConfig] = useState(false);
    const [newInventory, setNewInventory] = useState(null)

    let inventoriesFilterByLot = inventories?.filter((inventory) => inventory?.lotId === activity?.lotId);
    inventoriesFilterByLot = inventoriesFilterByLot || [];

    if (newInventory) { inventoriesFilterByLot = [...inventoriesFilterByLot, newInventory] }

    useEffect(() => {
        if (inventoriesUsed && inventoriesUsed.length > 0) {
            setInventoriesToEdit(inventoriesUsed);
        } else if (!isClient) {
            setInventoriesToEdit([
                {
                    'inventoryId': null,
                    'unitNumber': null,
                    "isCompleted": false,
                    "entityId": parseInt(activity?.id),
                    "entityType": 1,
                    "id": null,
                    'activityGroupId': activity?.groupId,
                    'workOrderId': activity?.workOrderId,
                }
            ])
        }
    }, [inventoriesUsed]);

    const onDelete = (index) => {
        let inventoryArray = [...inventoriesToEdit];
        inventoryArray.splice(index, 1);
        setInventoriesToEdit(inventoryArray);
        setHasUnsavedChanges(true);
    }

    const onRefresh = async () => {
        await refetchInventories();
    }

    const onChangeValue = (index, val, fieldName) => {
        let inventorySettingsList = [...inventoriesToEdit];
        let newItem = { ...inventorySettingsList[index] };
        newItem[fieldName] = val;
        inventorySettingsList[index] = { ...newItem };
        setInventoriesToEdit(inventorySettingsList);
        setHasUnsavedChanges(true);
    }

    const onAddNewInventoryItem = () => {
        let inventorySettingsList = [...inventoriesToEdit];
        inventorySettingsList.push({
            'inventoryId': null,
            'unitNumber': null,
            "isCompleted": false,
            "entityId": parseInt(activity?.id),
            "entityType": 1,
            "id": null,
            'activityGroupId': activity?.groupId,
            'workOrderId': activity?.workOrderId,
        });
        setInventoriesToEdit(inventorySettingsList);
        setHasUnsavedChanges(true);
    };

    const onSaveAction = async () => {
        setIsAdding(true);
        let request = {
            activityTypeInventoryEntities: inventoriesToEdit,
            activityGroupId: activity?.groupId,
            workOrderId: activity?.workOrderId,
            activityItemId: activity.activityItemId,
            overwriteConfig: overwriteConfig,
        };
        let res = await updateInventories(request);
        if (res.error) {
            NotificationProvider.error("Failed to save the changes");
        } else {
            NotificationProvider.success("Successfully saved the changes");
            setOverwriteConfig(false);
            setNewInventory(null)
            setHasUnsavedChanges(false);
        }
        await onRefresh();
        setIsAdding(false);
    }

    const onChangeInventoryName = (index, name) => {
        let inventorySettingsList = [...inventoriesToEdit];
        let newItem = { ...inventorySettingsList[index] };
        newItem.inventoryName = name
        newItem.inventoryId = 0
        newItem.lotId = activity.lotId
        inventorySettingsList[index] = { ...newItem };
        setInventoriesToEdit(inventorySettingsList);
        setHasUnsavedChanges(true);
    }

    const onAddInventory = async (index, val) => {
        setNewInventory({ ...val, id: 0 });
        onChangeInventoryName(index, val.name);
    }

    return (
        <>
            <Grid container direction='column' spacing={1} className={classes.inventoryContainer}>
                {
                    inventoriesToEdit?.length > 0 &&
                    inventoriesToEdit?.map((inventory, index) => {
                        return <ListItem key={index}>
                            <Grid container spacing={1} >
                                <FormControlLabel
                                    control={
                                        <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_FIELDS}>
                                            <Checkbox
                                                checked={inventory.isCompleted}
                                                onChange={(event) => {
                                                    onChangeValue(index, event.target.checked, "isCompleted");
                                                }}
                                            />
                                        </Authorize>}
                                />
                                <Grid item xs={6}>
                                    <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_FIELDS}>
                                        <CreatableDropdown
                                            options={inventoriesFilterByLot}
                                            value={inventory.inventoryId}
                                            required
                                            addValue={(val) => onAddInventory(index, val)}
                                            label='Inventory'
                                            onChange={val => onChangeValue(index, parseInt(val), "inventoryId")}
                                            key={index}
                                        />
                                    </Authorize>
                                </Grid>
                                <Grid item xs={4}>
                                    <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_FIELDS}>
                                        <CustomInput
                                            id="unitNumber"
                                            label="Unit Number"
                                            elementType="input"
                                            value={inventory.unitNumber}
                                            required={true}
                                            onChange={val => onChangeValue(index, val ?? 0, "unitNumber")}
                                            numberOnly
                                        />
                                    </Authorize>
                                </Grid>
                                <Grid item xs={1}>
                                    <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                                        <IconButton edge="end" aria-label="delete"
                                            onClick={() => onDelete(index)}
                                        >
                                            <IconDeleteBlue />
                                        </IconButton>
                                    </Authorize>
                                </Grid>
                            </Grid>
                        </ListItem>
                    })
                }
                <Grid item>
                    <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                        <Button variant="text"
                            color="primary"
                            onClick={onAddNewInventoryItem}
                            startIcon={<AddIcon />}
                            className={classes.addItemBtn}
                        >
                            Add New Item
                        </Button>
                    </Authorize>
                </Grid>
            </Grid>
            <div className={classes.saveContainer}>
                <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                    <Button
                        onClick={onSaveAction}
                        color='primary'
                        variant="outlined"
                        className={classes.saveBtn}
                        disabled={isAdding}>
                        {isAdding ? 'Saving...' : 'Save'}
                    </Button>
                    <Tooltip title="Update the Inventory of this Activity for future use.">
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e, value) => setOverwriteConfig(value)}
                                checked={overwriteConfig}
                            />}
                            className={classes.overwriteConfig}
                            label='Overwrite Configuration'
                        />
                    </Tooltip>
                </Authorize>
            </div>
        </>
    )
}

export default InventoryUsed
