import * as React from "react";

function SvgIconSla(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 1.75C1.58579 1.75 1.25 2.08579 1.25 2.5C1.25 2.91421 1.58579 3.25 2 3.25H2.91992V14.47C2.91992 15.684 3.48987 16.8379 4.47114 17.5709L9.68078 21.4706C11.0571 22.4998 12.953 22.4995 14.3294 21.4704L19.5391 17.5706C20.5204 16.8375 21.0899 15.684 21.0899 14.47V3.25H22C22.4142 3.25 22.75 2.91421 22.75 2.5C22.75 2.08579 22.4142 1.75 22 1.75H21.0899H2.91992H2ZM19.5899 3.25H4.41992V14.47C4.41992 15.2158 4.77022 15.9221 5.3687 16.3691L10.579 20.2693C11.4227 20.9002 12.5871 20.9002 13.4308 20.2693L18.6405 16.3696C19.2389 15.9226 19.5899 15.2158 19.5899 14.47V3.25ZM7.25 8C7.25 7.58579 7.58579 7.25 8 7.25H16C16.4142 7.25 16.75 7.58579 16.75 8C16.75 8.41421 16.4142 8.75 16 8.75H8C7.58579 8.75 7.25 8.41421 7.25 8ZM8 12.25C7.58579 12.25 7.25 12.5858 7.25 13C7.25 13.4142 7.58579 13.75 8 13.75H16C16.4142 13.75 16.75 13.4142 16.75 13C16.75 12.5858 16.4142 12.25 16 12.25H8Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconSla;
