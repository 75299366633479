import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const baseUrl = process.env.REACT_APP_API_URL || "https://localhost:6001";

export const baseQuery = fetchBaseQuery({
    baseUrl: `${baseUrl}/api/v1/`,
    prepareHeaders: (headers, {getState}) => {
        let state = getState();
        let user = state.oidc.user;
        let seamlessToken = state.seamlessToken.token;
        let accessToken = user && user.access_token;
        accessToken = accessToken || seamlessToken;

        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
    }
});

export const getApiUrl = `${baseUrl}/api/v1/`

