import { makeStyles } from '@material-ui/core/styles';

export const selectedFilterStyle = makeStyles((theme) => ({
    selectedFilters: {
        border: '1px solid #CFD6DD',
        padding: '0.7em',
        borderRadius: '5px'
    },
    item: {
        background: '#E9F5FF',
        padding: '3px 7px',
        width: 'fit-content',
        borderRadius: '5px',
        fontWeight: '600',
        marginRight: '1em'
    },
    title: {
        margin: '0.5em 0',
        fontWeight: 'bold'
    },
    clear: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '50%',
        fontSize: 15,
    }
}));
