import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CustomInput from 'components/CustomInput';
import DateTimeField from 'components/DateTimePicker/DateTimePicker';
import NotificationProvider from 'components/NotificationProvider';
import { useAddActivityToLoadMutation } from 'features/loads/loadsSlice';
import moment from 'moment';
import { useMemo, useState } from 'react';

import { useGetAllActivityItemsWithCustomFieldsQuery, useGetAllActivityTypesQuery } from 'features/activity/activitySlice';
import FormatService from 'services/formatService';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { Alert } from '@mui/material';
import { LoadAssetSelector } from './LoadAssetSelector';
import { arrayToObject } from 'utils/object-util';
import { activityCustomFieldSetup } from 'features/activity/activityConsts';

const AddLoadActivity = ({ loadGroup, open, setOpen }) => {

    const [addActivity, { isLoading }] = useAddActivityToLoadMutation();

    const [activityTypeId, setActivityType] = useState(null);
    const [activityItemId, setActivityItem] = useState(null);
    const [vehicleId, setVehicleId] = useState();
    const [lotId, setLotId] = useState(null);
    const [dateTime, setDateTime] = useState(FormatService.formatDateTime(moment()));
    const [note, setNote] = useState();
    //const [serviceProviderId, setServiceProviderId] = useState(null);
    const [invalidLotAndDateWarning, setInvalidLotAndDateWarning] = useState();

    let { data: lots, error: getLotsError, isLoading: loadingLots } = useGetLotsQuery();
    let { data: activityTypes, error: activityTypesError, isLoading: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    //let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    let {
        data: activityItemsWithCustomFields, error: activityItemError,
        isLoading: isLoadingActivityItems
    } = useGetAllActivityItemsWithCustomFieldsQuery();

    activityTypes = activityTypes || [];
    activityItemsWithCustomFields = activityItemsWithCustomFields || [];
    //serviceProviders = serviceProviders || {};

    const activityItemsLookup = arrayToObject(activityItemsWithCustomFields);
    let currentActivityItem = activityItemsLookup?.[activityItemId];
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [workorderCustomData, setWorkorderCustomData] = useState([]);

    const loadLots = loadGroup?.loads?.flatMap(l => [
        l.pickupLocationId,
        l.deliveryLocationId
    ]);

    const onSelectDateTime = (e) => {
        setDateTime(e)
        validateLotAndDates(lotId, e);
    }

    const handleSelectChange = (option) => {
        const value = JSON.parse(option);
        onChangeValue("activitytype", value.activityTypeId);
        onChangeValue("activityitem", value.activityItemId);
        setSelectedActivity(option);
    };

    const onChangeCustomFieldData = (customField, val) => {
        let customDataList = [...workorderCustomData] || []
        let index = customDataList.findIndex(c => c.activityItemCustomFieldId == customField.id);
        if (index == -1) {
            customDataList = [...customDataList, {
                activityItemCustomFieldId: customField.id,
                value: val,
                mappedTo: customField?.mappedTo
            }]
        } else {
            customDataList[index] = { ...customDataList[index], ["value"]: val };
        }
        setWorkorderCustomData(customDataList)
    }

    const handleClose = () => {
        setOpen(false);
        setActivityType(null);
        setActivityItem(null);
        setDateTime();
        setSelectedActivity(null)
        setWorkorderCustomData([])
        //setServiceProviderId(null)
        setNote();
    };

    const onChangeValue = (key, val) => {
        switch (key) {
            case 'activitytype':
                setActivityType(getIntValue(val));
                break;
            case 'activityitem':
                setActivityItem(getIntValue(val));
                break;
            // case 'serviceProviderId':
            //     setServiceProviderId(getIntValue(val));
            //     break;
            case 'note':
                setNote(val);
                break;
            case 'lotId':
                setLotId(getIntValue(val));
                validateLotAndDates(val, dateTime);
                break
        }

    };

    const validateLotAndDates = (lotId, dateTime) => {
        let valid = false;
        if (!lotId || !dateTime) return true;
        const dateOnly = moment(dateTime);
        for (let i = 0; i < loadGroup?.loads?.length; i++) {
            const load = loadGroup?.loads?.[i];
            //Use case 1: this is the first load, the selected lot is the pickup location and the selected date is before the pickup, which means the activity should happen before
            if (i == 0 && load.pickupLocationId == lotId && moment(load?.pickupStartDate) > dateOnly) {
                valid = true;
                break;
            }
            //Use case 2: this is the last load, the selected lot is the delivery location and the selected date is after the delivery
            if (i == loadGroup?.loads?.length - 1 && load.deliveryLocationId == lotId && moment(load.deliveryStartDate) < dateOnly) {
                valid = true;
                break;
            }
            //Use case 3: the selected lot is the delivery location and the selected date is between the delivery and the next pickup
            if (load.deliveryLocationId == lotId) {
                const nextLoad = loadGroup?.loads?.[i + 1];
                if (moment(load?.deliveryStartDate) <= dateOnly && moment(nextLoad.pickupStartDate) >= dateOnly) {
                    valid = true;
                    break;
                }
            }
        }
        setInvalidLotAndDateWarning(valid ? null : "Based on the configured load legs, the asset will not be on this lot on the selected date")
        return false;
    }

    const getIntValue = (val) => {
        return parseInt(val);
    };


    const onClickFinish = async () => {
        let date = new Date(moment(dateTime).format('MMM DD, yyyy, hh:mm a') + ' UTC');
        let activityDate = date.toISOString();

        let data = {
            loadGroupId: loadGroup?.id,
            activityTypeId,
            activityItemId,
            vehicleId: vehicleId ?? loadGroup?.assets?.[0]?.id,
            //serviceProviderId,
            lotId,
            activityDate,
            note,
            workorderCustomData: workorderCustomData
        };

        let result = await addActivity(data);
        if (result && !result.error) {
            NotificationProvider.success('Activity added successfully');
            handleClose();
        } else {
            NotificationProvider.error('Something happened, try again or contact support');
        }

    };


    lots = lots?.filter(l => loadLots.includes(l.id));
    const isValid = activityTypeId && activityItemId && vehicleId;

    const combinedData = useMemo(() => {
        const sortedActivityTypes = [...activityTypes].sort((a, b) => a.name.localeCompare(b.name));
        const sortedActivityItems = [...activityItemsWithCustomFields].sort((a, b) => a.name.localeCompare(b.name));

        return sortedActivityTypes.map(type => ({
            label: type.name,
            options: sortedActivityItems
                .filter(item => item.activityTypeId === type.id)
                .map(item => ({ label: item.name, value: { activityTypeId: type.id, activityItemId: item.id } }))
        }));
    }, [activityTypes, activityItemsWithCustomFields]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' maxWidth='sm' fullWidth>
                <DialogTitle id='form-dialog-title'>
                    Add Activity
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} direction='column'>
                        <Grid item>
                            <LoadAssetSelector
                                loadGroup={loadGroup}
                                setSelectedAsset={(asset) => setVehicleId(asset?.id)}
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id='lot'
                                label='Lot'
                                value={lotId}
                                elementType='dropdown'
                                showEmpty={true}
                                required={true}
                                onChange={val => onChangeValue('lotId', val)}
                                values={lots ?? []}
                            />
                            {invalidLotAndDateWarning && <Alert severity='warning' >
                                {invalidLotAndDateWarning}
                            </Alert>}
                        </Grid>
                        {!isLoadingActivityTypes && !isLoadingActivityItems && (
                                <Grid item>
                                    <CustomInput
                                        id="ddlActivityTypeId"
                                        label="Activity Type"
                                        elementType="dropdown"
                                        combinedData={combinedData ?? []}
                                        value={selectedActivity}
                                        required={true}
                                        showEmpty={true}
                                        onChange={handleSelectChange}
                                    />
                                </Grid>
                            )}
                        {currentActivityItem?.activityItemCustomFields?.map(customField =>
                                <Grid item>
                                    {activityCustomFieldSetup?.[customField?.mappedTo]?.component ?
                                        activityCustomFieldSetup?.[customField?.mappedTo]?.component(customField,
                                            workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value,
                                            onChangeCustomFieldData)
                                        :
                                        <CustomInput
                                            id="ddlCustomFieldData"
                                            label={customField?.label}
                                            elementType="text"
                                            value={workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value}
                                            onChange={(val) => onChangeCustomFieldData(customField, val)}
                                        />
                                    }
                                </Grid>
                            )
                            }
                        {/* {!isLoadingServiceProviders &&
                            <Grid item>
                                <CustomInput
                                    id='ddlServiceProviderId'
                                    label='Vendor'
                                    elementType='dropdown'
                                    values={serviceProviders}
                                    value={serviceProviderId}
                                    showEmpty={true}
                                    onChange={val => onChangeValue('serviceProviderId', val)}
                                />
                            </Grid>
                        } */}
                        <Grid item>
                            <DateTimeField
                                label='Activity Date'
                                value={dateTime}
                                onChange={onSelectDateTime}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item>
                        <div>Note</div>
                        <RichTextEditor onChange={(val) => onChangeValue('note', val)} content={note} />
                    </Grid> */}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        color='primary'
                        onClick={onClickFinish}
                        disabled={!isValid || isLoading}
                        variant='contained' >
                        {isLoading ? 'Saving...' : 'Finish'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddLoadActivity;
