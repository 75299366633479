
import { Button, CircularProgress, DialogActions, DialogContent, Grid, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { Dialog } from "@mui/material";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useSaveNewRoleMutation } from "features/users/UserManagement/roleSlice";
import { useState } from "react";
import {IconAdd} from "../../../../icons";


const CreateRoleDialog = ({ allRoles, refetchRolesWithData }) => {
    
    const [saveNewRole, { isUpdating }] = useSaveNewRoleMutation();

    const [showList, setShowList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [roleName, setRoleName] = useState(null);

    const onCancel = () => {
        setRoleName(null);
        setShowList(false);
    }

    const onConfirm = async () => {
        setIsLoading(true);

        let body = {
            name: roleName
        };
        let res = await saveNewRole(body);


        if (res && !res.error) {
            refetchRolesWithData();
            setRoleName(null);
            NotificationProvider.success("Roles created successfully");            
        } else {
            NotificationProvider.error("Failed to create role");
        }

        setShowList(false);
        setIsLoading(false);
    }

    const invalidField = () => (!roleName || roleName === "")

    return (
        <>
            <Button variant="contained" color="primary" startIcon={<IconAdd/>} onClick={() => setShowList(true)}>
                Create New Role
            </Button>
            <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                <DialogContent>
                    <Grid container xs={20} direction="row" alignItems="center">
                        <label style={{ fontSize: "1.2rem", paddingBottom: 3 }}>Create New Role</label>
                        <Grid item xs={12}>
                            <CustomInput
                                id="name"
                                label="Role Name"
                                value={roleName}
                                elementType="input"
                                onChange={val => setRoleName(val)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color='primary'>
                        Cancel
                    </Button>
                    <Button disabled={isLoading || invalidField()} onClick={onConfirm} color='primary'>
                        Confirm
                    </Button>
                    {isLoading && <CircularProgress size={'1em'} />}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateRoleDialog;