import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { IconDelete, IconCopy } from "icons";
import { ReportSectionStyles } from "../ReportSectionStyles";

const SectionActions = ({ onDeleteSection, onDuplicateSection }) => {

    const classes = ReportSectionStyles();

    return (
        <div className={classes.sectionActions} >
            <div>
                <Tooltip title={`Duplicate question`}>
                    <IconButton onClick={() => onDuplicateSection()}>
                        <IconCopy style={{ width: '0.8em', height: '0.8em' }} className={classes.actionsIcons}/>
                    </IconButton>
                </Tooltip>
            </div>
            <div>
                <Tooltip title={`Delete Section`}>
                    <IconButton onClick={() => onDeleteSection()}>
                        <IconDelete style={{ width: '0.8em', height: '0.8em' }} className={classes.actionsIcons}/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}
export default SectionActions; 