import { CircularProgress, IconButton } from "@material-ui/core";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CustomInput from "components/CustomInput";
import NotificationProvider from 'components/NotificationProvider';
import { useGetVehicleLocationInfoQuery } from "features/vehicles/vehicleSlice";
import { useEffect, useState } from 'react';
import { searchStyle } from "./SearchStyle";

function AssetSearch({ createAssetMarker, reFocusMap, map }) {
    const classes = searchStyle();

    const [search, setSearch] = useState(false);
    const [descriptor, setDescriptor] = useState('');

    let { data: vehicleLocationInfo, error: vehicleLocationInfoError, isFetching: isLoadingVehicleLocationInfo } = useGetVehicleLocationInfoQuery({ id: 0, descriptor }, { skip: (!search || !descriptor.trim()) });

    useEffect(() => {
        if (!descriptor) {
            reFocusMap(map);
        }
    }, [descriptor])

    const onSearch = () => {
        if (!!descriptor) {
            setSearch(true);
        }
    }

    const onChange = (v) => {
        setDescriptor(v);
    }

    useEffect(() => {
        if (!isLoadingVehicleLocationInfo && (vehicleLocationInfo || vehicleLocationInfoError)) {
            if (vehicleLocationInfoError) {
                reFocusMap(map);
                NotificationProvider.error(vehicleLocationInfoError.data.messages[0]);
            } else {
                reFocusMap(map);
                createAssetMarker(vehicleLocationInfo);
            }
            setSearch(false);
        }
    }, [vehicleLocationInfo, vehicleLocationInfoError])


    return (
        <>
            <CustomInput
                elementType='input'
                icon={isLoadingVehicleLocationInfo ?
                    <CircularProgress size={'1em'} /> :
                    <IconButton className={classes.icon} >
                        <SearchRoundedIcon onClick={onSearch} />
                    </IconButton>

                }
                label='VIN or Asset ID'
                value={descriptor}
                onChange={onChange}
            />
        </>
    );
}

export default AssetSearch;