import { makeStyles } from "@material-ui/core";

export const AccessCodeListStyle = makeStyles((theme) => ({
    accessCodeWrapper: {
        padding: '1em 2em',
        margin: '1em 0'
    },
    label: {
        fontWeight: 'bold',
        marginRight: '0.5em'
    },
    lotName: {
        color: theme.palette.primary.main,
        fontSize: '1.1em',
        fontWeight: '500',
    },
    icon: {
        height: '1.2em'
    },
    fieldGroupWrapper: {
    },
    qrCode: {
        //border: '2px solid #ffda4c',
        borderRadius: '5px',
        padding: '1px'
    }
}));