import { Select } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "../../../../../components/NotificationProvider";
import { auditFileStatuses } from "../../auditConsts";
import { useEditAuditMutation } from "../../lotAuditSlice";
import { auditStatusStyle } from "./style";

const AuditStatusDropdown = ({ audit }) => {
    const classes = auditStatusStyle();

    let [editAudit, { isLoading: uploading }] = useEditAuditMutation();

    const onStatusChange = async (event) => {
        let body = {
            status: +event.target.value,
            id: audit?.id
        }
        let result = await editAudit(body);
        if (result && result.error) {
            NotificationProvider.error(`Failed to upload audit`);
        } else {
            NotificationProvider.success(`Successfully uploaded audit`);
        }
        return result;
    }

    const renderValue = (selected) => {
        return <div className={`${classes.selectItem}`}>
            {auditFileStatuses[selected]}
        </div>
    };


    return (
        <Authorize profile={permissionProfiles.AUDIT.AUDIT_STATUS}>
            <Select
                native={false}
                className={classes.select}
                value={audit?.status}
                onChange={onStatusChange}
                variant="outlined"
                inputProps={{
                    className: classes.select,
                }}
                MenuProps={{
                    className: classes.selectMenu,
                    style: { padding: 0 }
                }}
                IconComponent={ExpandMoreIcon}
                renderValue={renderValue}
            >
                {Object.keys(auditFileStatuses)?.map((value, key) => (
                    <div
                        className={classes.statusItem}
                        //style={{ color: value.color }}
                        id={key} key={key} value={value}>
                        {auditFileStatuses[value]}
                    </div>
                ))}
            </Select>
        </Authorize>
    )
}
export default AuditStatusDropdown;