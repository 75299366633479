import { Button } from '@material-ui/core';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetActivityReportsMutation } from '../../activityInvoiceSlice.js';
import BasicModal from 'components/BasicModal.js';
import SelectFieldsPopup from 'components/SelectFieldsPopup/selectFieldsPopup.js';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner.js';
import FormatService from "../../../../../services/formatService";

const ExportReportsButton = () => {

    const { filters, searchVal } = useSelector(state => state.activityInvoiceState);

    const [getActivityReports, { isLoading: gettingReports }] = useGetActivityReportsMutation()

    let reportColumns = [
        { name: "Invoice Report", key: "1", selectedByDefault: true, width: 200 },
        { name: "Activity VIN Report", key: "2", selectedByDefault: true, width: 200 }
    ];

    const [selectedColumns, setSelectedColumns] = useState([...reportColumns].filter(c => c.selectedByDefault));

    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true);
    }

    const onCloseModal = () => {
        setOpenSelectFieldsPopup(false);
    }

    const onSubmitSelectedFields = async (checked) => {
        setSelectedColumns(
            reportColumns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );

        let reportsToSend = [];
        reportColumns.map((column) => (isChecked(checked, column.name) ? column : null)).forEach((id) => {
            if (id !== null) {
                reportsToSend.push(parseInt(id.key));
            }
        })

        let reportsToSendObj = {
            ReportTypes: reportsToSend,
            ClientId: parseInt(filters?.['clientId']),
            Month: parseInt(filters?.['month']),
            Year: parseInt(filters?.['year']),
        }

        setOpenSelectFieldsPopup(false);
        
        await getActivityReports(reportsToSendObj);
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={onSelectExistingClick}
                disabled={gettingReports}>
                Export
            </Button>

            <LoadingSpinner loading={gettingReports}/>

            <BasicModal
                open={openSelectFieldsPopup}
                header={`Select Reports`}
                handleClose={() => onCloseModal(false)}
                component={<SelectFieldsPopup columns={reportColumns}
                    selectedColumns={selectedColumns}
                    isChecked={isChecked}
                    onSubmit={onSubmitSelectedFields}
                    handleClose={() => onCloseModal(false)} />}
            />
        </>
    )
}
export default ExportReportsButton;