import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clamp } from "./Clamp";
import { IncrementorStyles } from "./IncrementorStyles";
import { Container, IconButton } from "@material-ui/core";
import CustomInput from "components/CustomInput";


function Incrementor({ min, max, value, onChange }) {
  const clampV = clamp(min, max);
  const disableMin = value === min;
  const disableMax = value === max;

  const classes = IncrementorStyles();

  const onChangeInput = (val) => {
    let value = val;
    if (value < min) value = min
    if (value > max) value = max
    onChange(value)
  }

  return (
    <Container className={classes.container}>
      <IconButton className={classes.button} onClick={() => onChange(clampV(value - 1))} disabled={disableMin}>
        <FontAwesomeIcon icon={faMinus} />
      </IconButton>
      <CustomInput
        className={classes.value}
        value={value}
        onChange={onChangeInput}
      />
      <IconButton className={classes.button} onClick={() => onChange(clampV(value + 1))} disabled={disableMax}>
        <FontAwesomeIcon icon={faPlus} />
      </IconButton>
    </Container>
  );
}

export default Incrementor;
