import { Button } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { IconCar } from 'icons';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        width: '100%',
        display: 'inline-block'
    },
    button: {
        width: '100%',
        justifyContent: 'start'
    }

}));

export default function ({ id }) {
    const classes = useStyles();

    return (
        <NavLink className={classes.link} exact
            to={`/assets/detail/${id}`}>
            <Button startIcon={<IconCar width={15} />} className={classes.button} button variant='text'>
                View
            </Button>
        </NavLink>
    );
}
