import { Button, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { useGetIssueTrackerCategoriesQuery, useGetStatusIssueTrackerQuery } from "features/IssuesTracker/issuesTrackerSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetUsersNoFilteringQuery } from "features/users/usersSlice";
import { groupBy } from "lodash";
import { useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { arrayToObject } from "utils/object-util";
import GraphHeader from "../GraphHeader/GraphHeader";
import { IssueTrackerDashboardStyle } from "../style";

const filterFields = {
    CATEGORY: 'category',
    STATUS: 'status',
    REPORTER: 'reporter',
    ASSIGNEE: 'assignee',
    LOT: 'lotId',
    CLIENT: 'clientId'
}

const filterFieldsLabels = {
    [filterFields.CATEGORY]: 'Category',
    [filterFields.STATUS]: 'Status',
    [filterFields.REPORTER]: 'Reporter',
    [filterFields.ASSIGNEE]: 'Assignee',
    [filterFields.LOT]: 'Lot',
    [filterFields.CLIENT]: 'Client'
}

export const IssuesGroupedPieWidget = ({ dashboardData, graphHeight }) => {

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: categories, error: categoriesError, isFetching: isLoadingCategories } = useGetIssueTrackerCategoriesQuery();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersNoFilteringQuery();

    const [groupByFieldName, setGroupByFieldName] = useState(filterFields.CATEGORY);

    const lotsLookup = arrayToObject(lots);
    const clientsLookup = arrayToObject(clients);
    const statusLookup = arrayToObject(statuses);
    const categoryLookup = arrayToObject(categories);
    const userLookup = arrayToObject(users);

    const classes = IssueTrackerDashboardStyle();

    const issueList = dashboardData?.issues;

    const dataGrouped = groupBy(issueList, groupByFieldName);

    const getName = (id) => {
        const notSet = 'Not set';
        switch (groupByFieldName) {
            case filterFields.CATEGORY:
                return categoryLookup?.[id]?.name ?? notSet
            case filterFields.STATUS:
                return statusLookup?.[id]?.name ?? notSet
            case filterFields.CLIENT:
                return clientsLookup?.[id]?.name ?? id
            case filterFields.LOT:
                return lotsLookup?.[id]?.name ?? id
            case filterFields.REPORTER:
                return userLookup?.[id]?.name ?? id
            case filterFields.ASSIGNEE:
                return userLookup?.[id]?.name ?? id
            default:
                return true;
        }
    }

    let graphData = Object.keys(dataGrouped)?.map(groupId => {
        const list = dataGrouped[groupId];
        return {
            count: list?.length,
            name: getName(groupId),
            list
        }
    });

    const COLORS = ['#F0857D', '#F8AA9E', '#FFCEBF', '#A6F6E3', '#D3E6BD', '#E9DEAA', '#FFD596', '#D3E6BD', '#AADAFF', '#97DDE0', '#84DFC1', '#D5D4DF'];

    return (
        <div style={{ height: graphHeight }}>
            <TypeViewSelector selectedType={groupByFieldName} setSelectedType={setGroupByFieldName} />
            <ResponsiveContainer width="100%" height="80%">
                <PieChart>
                    <Pie
                        data={graphData}
                        innerRadius={10}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="count"
                    >
                        {graphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    {graphData?.length < 20 && <Legend
                        verticalAlign={graphData?.length > 10 ? 'bottom' : 'middle'}
                        align={graphData?.length > 10 ? 'left' : 'right'}
                        iconType="circle"
                        layout={graphData?.length > 10 ? 'horizontal' : 'vertical'}
                        iconSize={10}
                        formatter={(value, entry, index) => <span style={{ fontSize: '0.8em', maxWidth: '5em', maxHeight: '2em', overflow: 'hidden', textOverflow: 'ellipsis', color: 'black' }}>{value}</span>}
                    />}
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </div>
    )

}

const TypeViewSelector = ({ selectedType, setSelectedType }) => {

    const classes = IssueTrackerDashboardStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleSelectItem = (key) => {
        setSelectedType(filterFields[key])
        setAnchorEl();
    }

    return (<><Button className={classes.graphHeader} onClick={handleClick} endIcon={<KeyboardArrowDownOutlinedIcon />}>
        <GraphHeader title={`Tickets by ${filterFieldsLabels[selectedType]}`} />
    </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl()}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            {Object.keys(filterFields)?.map((key, index) =>
                <MenuItem key={index} onClick={() => handleSelectItem(key)}>
                    {`Tickets by ${filterFieldsLabels[filterFields[key]]}`}
                </MenuItem>)
            }
        </Menu></>)
}
