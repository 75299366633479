import { createSlice } from "@reduxjs/toolkit";

export const seamlessTokenSlice = createSlice({
    name: 'seamlessToken',
    initialState: {
        token: null,
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        }
    },
});

export const { setToken } = seamlessTokenSlice.actions;
export default seamlessTokenSlice.reducer;
