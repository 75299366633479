import * as React from "react";

function SvgIconRadioButton(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.97 22.25C6.32581 22.25 1.71997 17.6536 1.71997 12C1.71997 6.34645 6.32581 1.75 11.97 1.75C17.6141 1.75 22.22 6.34645 22.22 12C22.22 17.6539 17.6238 22.25 11.97 22.25ZM11.97 2.25C6.59383 2.25 2.21997 6.62386 2.21997 12C2.21997 17.3761 6.59383 21.75 11.97 21.75C17.3461 21.75 21.72 17.3761 21.72 12C21.72 6.62386 17.3461 2.25 11.97 2.25Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M12 16.48C9.52616 16.48 7.52002 14.4739 7.52002 12C7.52002 9.52616 9.52616 7.52002 12 7.52002C14.4739 7.52002 16.48 9.52616 16.48 12C16.48 14.4739 14.4739 16.48 12 16.48ZM12 8.02002C9.80388 8.02002 8.02002 9.80388 8.02002 12C8.02002 14.1962 9.80388 15.98 12 15.98C14.1962 15.98 15.98 14.1962 15.98 12C15.98 9.80388 14.1962 8.02002 12 8.02002Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconRadioButton;
