import { Grid } from "@material-ui/core";
import React from "react";
import FormatService from "../../../../../../services/formatService";
import { TaskItemStyles } from "../../TaskItemStyles";

const AuditsTask = ({ task }) => {
    const classes = TaskItemStyles();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }

    return (
        <Grid container direction='column'>
            {task?.lot && field('Lot', task?.lot?.name)}
            {field('Uploaded By', task?.createdBy)}
            {field('Date', FormatService.formatDate(task?.createdDate))}
        </Grid>
    )
}
export default AuditsTask;