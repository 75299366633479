import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { AddCircle } from "@material-ui/icons";
import { Service } from "./Service";

export const AddService = ({ invoice, onAdd }) => {

    const [add, setAdd] = useState(false);

    return (
        <>
            {!add && <Button onClick={() => setAdd(true)} startIcon={<AddCircle />}>Add Service</Button>}
            {add && <Service editMode={true} service={{}} invoice={invoice} onDone={(newService) => {

                setAdd(false);
                onAdd && onAdd(newService)
            }
            } />}
        </>
    )
}