import { IssueTrackerDashboardStyle } from "../style";

export const OpenIssuesMoreThanTwoWeeksWidget = ({ issues }) => {
    const classes = IssueTrackerDashboardStyle();
    let issuesOpenMoreThanTwoWeeks = issues?.filter(issue => issue.openMoreThanTwoWeeks);

    return (<>
        <div className={classes.cardCount}>{issuesOpenMoreThanTwoWeeks?.length}</div>
        <div className={classes.cardText}>Open for 2 Weeks or More</div>
    </>
    )
}