import * as React from "react";

function SvgIconGraph(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4909 2.2402C14.8232 1.7521 13.4383 1.68092 12.4721 2.37627C11.4919 3.08173 11.2502 4.35012 11.2502 5.68001V8.56001C11.2502 9.86051 11.5131 10.9423 12.193 11.697C12.8889 12.4694 13.8838 12.75 15.0002 12.75H18.3202C19.6501 12.75 20.9185 12.5083 21.624 11.5281C22.3193 10.5619 22.2482 9.17703 21.76 7.50933L21.7598 7.50838C21.0378 5.05375 18.9465 2.96243 16.4919 2.24048L16.4909 2.2402ZM12.7502 5.68001C12.7502 4.40989 13.0086 3.83829 13.3484 3.59375C13.7022 3.33912 14.4571 3.20794 16.0692 3.67969C18.0342 4.25787 19.7426 5.96631 20.3207 7.93139C20.7923 9.5432 20.6611 10.2981 20.4065 10.6519C20.162 10.9917 19.5904 11.25 18.3202 11.25H15.0002C14.1166 11.25 13.6116 11.0306 13.3075 10.693C12.9873 10.3377 12.7502 9.69951 12.7502 8.56001V5.68001ZM9.41003 4.74462C9.81589 4.66186 10.0778 4.26576 9.99506 3.8599C9.9123 3.45403 9.5162 3.19211 9.11034 3.27486C4.11938 4.29254 0.503695 9.09896 1.37972 14.539L1.38002 14.5408C2.05192 18.6481 5.35074 21.9482 9.45998 22.61C14.9227 23.4971 19.729 19.8583 20.7355 14.8474C20.8171 14.4413 20.554 14.046 20.1479 13.9644C19.7418 13.8829 19.3464 14.1459 19.2649 14.552C18.4114 18.8012 14.3377 21.8824 9.70039 21.1294L9.69936 21.1293C6.22936 20.5708 3.42885 17.7715 2.86048 14.2995C2.11711 9.67994 5.18129 5.60688 9.41003 4.74462Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconGraph;
