import { Grid } from "@material-ui/core";
import _ from 'lodash';
import { useSelector } from 'react-redux';
import CustomInput from '../../../../../components/CustomInput';
import { useGetLotsQuery } from '../../../../lots/lotSlice';
import ActivityFormStyles from "./ActivityFormStyles";
import { useGetAllActivitiesItemByTypeIdQuery, useGetAllActivityTypesQuery } from "features/activity/activitySlice";
import DateTimeField from "components/DateTimePicker/DateTimePicker";
import LoadingSpinner from "components/LoadingSpinner";
import { groupLegTypes } from "features/loads/enums";
import { Alert } from "@mui/material";
import { LoadAssetSelector } from "../../AddLoadActivity/LoadAssetSelector";


const ActivityForm = ({ loadGroup, setLoadGroup, clientConfigs, setClientConfigs, legIndex = 0 }) => {
    const classes = ActivityFormStyles();

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let {
        data: activityTypes, error: activityTypesError,
        isLoading: isLoadingActivityTypes
    } = useGetAllActivityTypesQuery();
    let {
        data: activityItems, error: activityItemError,
        isLoading: isLoadingActivityItems
    } = useGetAllActivitiesItemByTypeIdQuery(loadGroup?.loads?.[legIndex]?.activityTypeId, { skip: !loadGroup?.loads?.[legIndex]?.activityTypeId });

    const { newLots } = useSelector(state => state.loadState);

    lots = lots || [];
    lots = [...lots, ...newLots];



    const onChangeValue = (values) => {
        let loads = [...loadGroup?.loads?.map(l => ({ ...l }))];
        if (loads) {
            loads[legIndex] = { ...loads?.[legIndex], ...values }
            setLoadGroup({ ...loadGroup, loads });
        }
    }

    const getMinDate = () => {
        for (let i = legIndex - 1; i >= 0; i--) {
            let leg = loadGroup?.loads?.[i];
            if (leg?.type === groupLegTypes.TRANSPORT) {
                if (leg?.deliveryStartDate) {
                    return new Date(leg?.deliveryStartDate)
                } else if (leg?.pickupStartDate) {
                    return new Date(leg?.pickupStartDate)
                }
            } else {
                if (leg?.activityDate) {
                    return new Date(leg?.activityDate)
                }
            }
        }
        return null;
    }

    const getMaxDate = () => {
        for (let i = legIndex + 1; i < loadGroup?.loads?.length; i++) {
            let leg = loadGroup?.loads?.[i];
            if (leg?.type === groupLegTypes.TRANSPORT) {
                if (leg?.pickupStartDate) {
                    return new Date(leg?.pickupStartDate)
                } else if (leg?.deliveryStartDate) {
                    return new Date(leg?.deliveryStartDate)
                }
            } else {
                if (leg?.activityDate) {
                    return new Date(leg?.activityDate)
                }
            }
        }
        return null;
    }

    const getLotId = (prev, name, type) => {
        if (prev?.type === groupLegTypes.ACTIVITY) {
            return prev?.lotId;
        } else {
            return prev?.[`${type}NewLot`] ? prev?.[`${name}NewLotId`] : prev?.[`${name}LocationId`];
        }
    }

    const isPULocationAsPrev = () => {
        if (legIndex == 0) return true;
        let previousLeg = loadGroup?.loads?.[legIndex - 1];
        let prevLotId = getLotId(previousLeg, "delivery", 'destination');
        let currentLotId = getLotId(loadGroup?.loads?.[legIndex], "pickup", 'origin')
        return prevLotId === currentLotId;
    }


    const isLoading = isLoadingActivityTypes || isLoadingActivityItems || isLoadingLots;


    return (
        <>
            <LoadingSpinner loading={isLoading} />
            <Grid container direction='column' spacing={2} className={classes.container}>
                <Grid item className={classes.assetSelector}>
                    <LoadAssetSelector
                        selectedValue={loadGroup?.loads?.[legIndex]?.vehicleUuid}
                        isNewLoad={true}
                        loadGroup={loadGroup}
                        setSelectedAsset={(a) => onChangeValue({ "vehicleUuid": a?.id })}
                    />
                </Grid>
                <Grid item>
                    <div>
                        <CustomInput
                            id='lot'
                            label='Lot'
                            value={loadGroup?.loads?.[legIndex]?.lotId}
                            elementType='dropdown'
                            showEmpty={true}
                            required={true}
                            onChange={val => onChangeValue({ "lotId": val })}
                            values={lots ?? []}
                        />
                    </div>
                </Grid>
                {!isPULocationAsPrev() &&
                    <Grid item><Alert severity='warning' >
                        The selected lot is not the same of the previous leg's delivery lot, this may indicate a problem with the setup of the load
                    </Alert>
                    </Grid>
                }
                {!isLoadingActivityTypes &&
                    <Grid item>
                        <div >
                            <CustomInput
                                id='ddlActivityTypeId'
                                label='Activity Type'
                                elementType='dropdown'
                                values={activityTypes}
                                value={loadGroup?.loads?.[legIndex]?.activityTypeId}
                                required={true}
                                showEmpty={true}
                                onChange={val => onChangeValue({ 'activityTypeId': val })}
                            />
                        </div>
                    </Grid>
                }
                <Grid item>
                    <div >
                        <CustomInput
                            id='ddlActivityItemId'
                            label='Activity Item'
                            elementType='dropdown'
                            values={activityItems}
                            value={loadGroup?.loads?.[legIndex]?.activityItemId}
                            required={true}
                            showEmpty={true}
                            onChange={val => onChangeValue({ 'activityItemId': val })}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div>
                        <DateTimeField
                            label='Activity Date'
                            value={loadGroup?.loads?.[legIndex]?.activityDate || null}
                            onChange={val => onChangeValue({ 'activityDate': val })}
                            minDate={getMinDate()}
                            maxDate={getMaxDate()}
                            required={true}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default ActivityForm;
