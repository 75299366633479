import { Grid } from '@material-ui/core';
import React from "react";
import CustomInput from 'components/CustomInput';
import { PCOInvoiceDetailsStyle } from './PCOInvoiceDetailsStyle';
import { useGetAllInvoiceItemsQuery } from 'features/invoice/storage/storageInvoiceSlice.ts';
import { storageInvoiceServiceTypes } from 'features/invoice/storage/storageInvoiceConsts';

export const EditService = ({ onFieldChange, serviceToEdit, index = null, type = null }) => {

    let { data: invoiceItems, error, isFetching } = useGetAllInvoiceItemsQuery();

    const classes = PCOInvoiceDetailsStyle();
    return (
        <div className={classes.service}>

            <Grid container spacing={2} >
                <Grid item xs={4} >
                    <CustomInput
                        value={serviceToEdit?.qbItemId}
                        onChange={(val) => onFieldChange('qbItemId', val, index)}
                        elementType="dropdown"
                        values={invoiceItems?.map(i => ({ ...i, name: i.fullyQualifiedName }))}
                        showEmpty
                    />
                </Grid>
                <Grid item xs={4} className={classes.label}>
                    {type == storageInvoiceServiceTypes.ACTIVITY ? <CustomInput
                        value={serviceToEdit?.description}
                        onChange={(val) => onFieldChange('description', val, index)}
                        elementType="input"
                    /> : <>{serviceToEdit?.description}</>}
                </Grid>
                <Grid item xs={4} >
                    {type == storageInvoiceServiceTypes.ACTIVITY ? <CustomInput
                        value={serviceToEdit?.totalCharge}
                        onChange={(val) => onFieldChange('totalCharge', val, index)}
                        elementType="input"
                        numberOnly
                    /> : <>${serviceToEdit?.totalCharge}</>}
                </Grid>
            </Grid>
        </div>
    )
}

