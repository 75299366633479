import { modes } from "../modes";
import { permissions } from "../permissions/permissions";

export const issueTrackerPermissionProfile = {
    ISSUE_TRACKER_VIEW: {
        [modes.EDIT_PERMISSION]: [permissions.ISSUE_TRACKER.READ, permissions.ISSUE_TRACKER.VIEW_ASSIGNED_TO_ME, permissions.ISSUE_TRACKER.VIEW_ASSIGNED_TO_LOT, permissions.ISSUE_TRACKER.VIEW_ASSIGNED_TO_CLIENT],
    },
    ISSUE_TRACKER_EDIT: {
        [modes.EDIT_PERMISSION]: [permissions.ISSUE_TRACKER.EDIT],
    }
}