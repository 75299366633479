export enum InvoiceTypes {
    TRANSPORTS = 1,
    STORAGE = 2,
    ACTIVITY = 3,
}

export const InvoiceTypeRoute = {
    [InvoiceTypes.TRANSPORTS]: 'loads',
    [InvoiceTypes.STORAGE]: 'storage',
    [InvoiceTypes.ACTIVITY]: 'activity'
}

export const InvoiceTypesList = [
    {
        id: InvoiceTypes.TRANSPORTS,
        name: 'Transports'
    },
    {
        id: InvoiceTypes.STORAGE,
        name: 'Storage'
    },
    {
        id: InvoiceTypes.ACTIVITY,
        name: 'Activity'
    }
]