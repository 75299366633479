import { baseQuery } from "../../../app/baseQuery";
import { apiTags } from '../../../app/store/apiTags';
import { baseApi } from 'app/baseApi';

export const roleApi = baseApi.injectEndpoints({
    reducerPath: 'roleApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllRole: builder.query({
            query: () => `Role`,
            providesTags: [apiTags.ROLE]
        }),
        getAllRolesWithData: builder.query({
            query: () => `Role/GetAllRolesWithData`,
            providesTags: [apiTags.USER_MANAGER]
        }),
        updateRoles: builder.mutation({
            query: body => ({
                url: `Role/UpdateRolesDefault`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: [apiTags.USER_MANAGER]
        }),
        saveRoleConfiguration: builder.mutation({
            query: body => ({
                url: `Role/SaveRoleConfiguration`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: [apiTags.USER_MANAGER]
        }),
        getRoleConfiguration: builder.query({
            query: (roleId) => `Role/GetRoleConfiguration/${roleId}`,
            providesTags: [apiTags.USER_MANAGER]
        }),
        saveNewRole: builder.mutation({
            query: body => ({
                url: `Role/SaveNewRole`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: [apiTags.USER_MANAGER]
        }),
    }),
});

export const {
    useGetAllRoleQuery,useGetAllRolesWithDataQuery, useUpdateRolesMutation, useSaveRoleConfigurationMutation, useGetRoleConfigurationQuery , useSaveNewRoleMutation
} = roleApi;