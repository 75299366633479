import { Grid, makeStyles, Paper } from '@material-ui/core';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect } from 'react';
import { fieldTypes } from '../services/filterService';
import mobileBreakpoint from "../utils/mobileBreakpoint";
import CustomInput from './CustomInput';
import DataSearchBar from './DataSearchBar';
import DatePicker from './DatePicker';
import DateRange from './DateRange';
import DropdownFilter from './DropdownFilter';
import AssociatedLotsDropDown from './SharedDropdowns/LotsDropDown/AssociatedLotsDropDown';
import LotsDropdown from './SharedDropdowns/LotsDropDown/LotsDropDown';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "4px 20px 20px",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.darkText,
    },
    actionButtonsContainer: {
        padding: "17px 1px"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        margin: 20,
        border: 0
    },
    icon: {
        fill: theme.palette.common.white,
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    subtitle: {
        fontSize: 12,
        fontStyle: "italic",
        marginBottom: 6
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row"
    },
    filter: {
        minWidth: "164px"
    },
    titleContainerMobile: {
        flexDirection: "column"
    },
    filterContainerMobile: {
        justifyContent: "space-between"
    },
    searchBarMobile: {
        maxWidth: "100%",
        width: "100%",
        flexBasis: "100%"
    },
    filterMobile: {
        maxWidth: "100%",
        width: "100%",
        flexBasis: "100%"
    }
}));

function DataListHeaderInner({
    title,
    subtitle,
    titleComponent,
    titleSize,
    data,
    dropDownsConfig,
    filters,
    onDropFilter,
    onSearch,
    searchVal,
    actionButtons,
    searchSize = 2
}) {
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);

    const [filtersState, setFiltersState] = React.useState(filters);

    useEffect(() => {
        setFiltersState(filters);
    }, [filters]);

    const onFilterChange = (filterValue, filterKey) => {
        onDropFilter && onDropFilter(filterValue, filterKey)
    }

    return (
        <Paper className={classes.root}>
            <Grid container>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid xs={titleSize} item className={`${classes.titleContainer} ${isMobile ? classes.titleContainerMobile : ""}`} alignItems="center">
                        {titleComponent ??
                            <><h1 className={classes.title}>{title}</h1>
                                <p className={classes.subtitle}>{subtitle}</p></>}
                    </Grid>
                    {<Grid item
                        className={classes.actionButtonsContainer}
                        direction="row"
                        alignItems="center">
                        {actionButtons}
                    </Grid>}
                </Grid>
                {(!!onSearch || !!dropDownsConfig) && <Grid container spacing={2} className={isMobile ? classes.filterContainerMobile : ""}>
                    {!!onSearch && <Grid item xs={searchSize} className={isMobile ? classes.searchBarMobile : ""}>
                        <DataSearchBar
                            rows={data}
                            onSearch={onSearch}
                            searchVal={searchVal}
                        />
                    </Grid>}
                    {dropDownsConfig?.map((ddConfig, index) => (
                        <Grid key={index} item xs={ddConfig.size} className={`${classes.filter} ${isMobile ? classes.filterMobile : ""}`}>
                            {
                                (ddConfig.type === fieldTypes.TEXT || ddConfig.type === fieldTypes.LIST || ddConfig.type === fieldTypes.VALUE_LIST || ddConfig.type === fieldTypes.VALUE_LIST_OBJECT) &&
                                <DropdownFilter
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    values={ddConfig.getValues ? ddConfig.getValues() : ddConfig.values}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value ?? []}
                                    showEmpty={(ddConfig.type === fieldTypes.TEXT) ? true : false}
                                    multiple={ddConfig.type === fieldTypes.LIST || ddConfig.type === fieldTypes.VALUE_LIST}
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.DATE &&
                                <DatePicker
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    value={filtersState[ddConfig.key]?.value}
                                    onSelect={onFilterChange} />
                            }
                            {
                                ddConfig.type === fieldTypes.DATERANGE &&
                                <DateRange
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    onSelect={onFilterChange}
                                    dateValue={filtersState[ddConfig.key]?.value}
                                    filterButton
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.FREETEXT &&
                                <CustomInput
                                    label={ddConfig.title}
                                    value={filtersState[ddConfig.key].value}
                                    id={ddConfig.key}
                                    onChange={onFilterChange} />
                            }
                            {
                                ddConfig.type === fieldTypes.LOT &&
                                <LotsDropdown
                                    clientId={filtersState['clientId']?.value}
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    values={ddConfig.values ?? []}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    showEmpty={true}
                                    pmfLot={ddConfig.pmfLot}
                                    multiple={ddConfig.multiple}
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.ASSOCIATED_LOT &&
                                <AssociatedLotsDropDown
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    values={ddConfig.values}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    showEmpty={true} />
                            }
                            {
                                ddConfig.type === fieldTypes.COMPONENT &&
                                <div>
                                    {ddConfig.component}
                                </div>
                            }
                        </Grid>
                    ))
                    }
                </Grid>}
            </Grid>
        </Paper>
    )
}

export default DataListHeaderInner;
