import { sum } from 'lodash';
import { baseApi } from '../../app/baseApi';
import { apiTags } from '../../app/store/apiTags';
import { arrayToObject } from 'utils/object-util';


const getCacheKey = (args) => {
    return `${args.groupId}`
}

const apiPrefix = 'load';
const convertLoad = (load, lotLookup) => {
    return ({
        ...load,
        vinsForSearch: load?.assets?.map(a => a.vin).join('$$'),
        latestStatusObject: load?.statuses?.[0] ?? {},
        latestStatus: load?.statuses?.[0]?.status ?? {},
        latestStatusDate: load?.statuses?.[0]?.statusDate ?? {},
        actualPickupDate: load?.statuses?.find(s => s.status == 4)?.statusDate,
        actualDeliveryDate: load?.statuses?.find(s => s.status == 5)?.statusDate,
        carrierId: load.carrier?.transporterId,
        readyForPosting: true,
        pickupLocation: lotLookup?.[load?.pickupLocationId],
        deliveryLocation: lotLookup?.[load?.deliveryLocationId],
    })
}

const convertGroupLoadsList = (group, lotLookup) => {
    return group?.loads?.map((l, index) => {
        const load = convertLoad(l, lotLookup);
        load.order = index + 1;
        const prevLoadDelivered = index > 0 ? group?.loads?.[index - 1]?.isInTransit || group?.loads?.[index - 1]?.isComplete : true;
        load.readyForPosting = !!prevLoadDelivered;
        return load;
    });
}

export const loadApi = baseApi.injectEndpoints({
    reducerPath: 'loadApi',
    tagTypes: [apiTags.LOADS, apiTags.LOAD_GROUP],
    endpoints: (builder) => ({
        getLoads: builder.query({
            query: (body) => ({
                url: body?.lmStatus ? `${apiPrefix}?archived=${body?.archived ? true : false}&lmStatus=${body?.lmStatus}` : `load`
            }),
            providesTags: [apiTags.LOADS],
            transformResponse: (response) => {
                return response.map(load => convertLoad(load))
            }
        }),
        getLoadsGrouped: builder.query({
            query: (body) => ({
                url: `${apiPrefix}/grouped?archived=${body?.archived ? true : false}&${body?.lmStatus ? `lmStatus=${body?.lmStatus}}` : ""}`
            }),
            providesTags: [apiTags.LOADS],
            transformResponse: (response) => {
                const lotLookup = arrayToObject(response.lots, 'id');
                return response.groups.map((group) => {
                    const loads = convertGroupLoadsList(group, lotLookup);
                    group.statuses = loads?.flatMap(s => s.statuses);
                    return ({
                        ...(loads?.[0] ?? {}),//get fields like external id, move type etc
                        ...convertLoad(group, lotLookup),
                        miles: sum(loads?.map(l => l.miles)),
                        loads: loads,
                        lmIdForSearch: loads?.map(l => l.lmId).join("$$")
                    })
                })
            }
        }),
        getLoadGroup: builder.query({
            query: (groupId) => ({
                url: `${apiPrefix}/grouped/${groupId}`
            }),
            //providesTags: [apiTags.LOAD_GROUP],
            providesTags: (result, error, arg) => [{ type: apiTags.LOAD_GROUP, id: getCacheKey({ groupId: arg }) }],
            transformResponse: (response) => {
                const group = response?.groups?.[0];
                const lotLookup = arrayToObject(response.lots, 'id');
                const loads = convertGroupLoadsList(group, lotLookup);
                group.statuses = loads?.flatMap(s => s.statuses);
                return ({
                    ...(loads?.[0] ?? {}),//get fields like external id, move type etc
                    ...convertLoad(group, lotLookup),
                    miles: sum(loads?.map(l => l.miles)),
                    loads: loads
                })
            }
        }),
        getLoad: builder.query({
            query: (latestLoadID) => `${apiPrefix}/${latestLoadID}`,
        }),
        getLoadLocations: builder.query({
            query: (loadID) => `${apiPrefix}/history/${loadID}`,
        }),
        getLoadGroupLocations: builder.query({
            query: (groupId) => `${apiPrefix}/group/${groupId}/history`,
        }),
        getLoadAttachments: builder.query({
            query: (loadID) => `${apiPrefix}/attachments/${loadID}`,
        }),
        getLoadGroupAttachments: builder.query({
            query: (groupId) => `${apiPrefix}/group/${groupId}/attachments`,
        }),
        getLoadPayments: builder.query({
            query: (loadID) => `${apiPrefix}/payments/${loadID}`,
        }),
        getLoadGroupPayments: builder.query({
            query: (groupId) => `${apiPrefix}/group/${groupId}/payments`,
        }),
        importLoads: builder.mutation({
            query: form => ({
                url: `${apiPrefix}/import`,
                method: "POST",
                body: form
            }),
            invalidatesTags: [apiTags.LOADS]
        }),
        dispatchLoad: builder.mutation({
            query: ({ loadId, transporterId, groupId }) => ({
                url: `${apiPrefix}/dispatch`,
                method: "POST",
                body: {
                    loadId,
                    transporterId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
                apiTags.LOADS,
                apiTags.LOAD_GROUP
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.LOAD_GROUP, ]
        }),
        postLoad: builder.mutation({
            query: (body) => ({
                url: `${apiPrefix}/post`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [
                ...(arg.groupIds?.map(g => ({ type: apiTags.LOAD_GROUP, id: getCacheKey({ groupId: g }) })) ?? []),
                apiTags.LOADS,
                apiTags.LOAD_GROUP
            ],
        }),
        addStatus: builder.mutation({
            query: ({ status, statusDate, comment, loadIds, groupIds }) => ({
                url: `${apiPrefix}/status`,
                method: "POST",
                body: {
                    status,
                    statusDate,
                    comment,
                    loadIds
                }
            }),
            invalidatesTags: (result, error, arg) => [
                ...(arg.groupIds?.map(g => ({ type: apiTags.LOAD_GROUP, id: getCacheKey({ groupId: g }) })) ?? []),
                apiTags.LOADS,
                apiTags.VEHICLES
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES, apiTags.LOAD_GROUP]
        }),
        updateStatus: builder.mutation({
            query: ({ id, status, statusDate, comment, loadId, groupId }) => ({
                url: `${apiPrefix}/status/edit`,
                method: "POST",
                body: {
                    id,
                    status,
                    statusDate,
                    comment,
                    loadId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
                apiTags.LOADS
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.LOAD_GROUP]
        }),
        deleteStatus: builder.mutation({
            query: ({ id, groupId }) => ({
                url: `${apiPrefix}/status/delete`,
                method: "POST",
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
                apiTags.LOADS
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.LOAD_GROUP]
        }),
        cancelLoad: builder.mutation({
            query: ({ loadId, comment, groupId }) => ({
                url: `${apiPrefix}/cancel`,
                method: "POST",
                body: {
                    loadId,
                    comment,
                    groupId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
                apiTags.LOADS
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.LOAD_GROUP]
        }),
        updateActualDate: builder.mutation({
            query: (body) => ({
                url: `${apiPrefix}/updateschedule`,
                method: "POST",
                body: body
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
                apiTags.LOADS
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.LOAD_GROUP]
        }),
        getLoadsDashboard: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${apiPrefix}/dashboard?${queryString}`
            }
        }),
        getGroupedLoadsDashboard: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${apiPrefix}/groupedDashboard?${queryString}`
            }
        }),
        addLoad: builder.mutation({
            query: load => ({
                url: `${apiPrefix}/add`,
                method: "POST",
                body: load
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES, apiTags.LOTS, apiTags.CLIENT_CONFIG]
        }),
        // addLoadLeg: builder.mutation({
        //     query: load => ({
        //         url: `${apiPrefix}/addLeg`,
        //         method: "POST",
        //         body: load
        //     }),
        //     invalidatesTags: (result, error, arg) => [
        //         { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
        //         apiTags.LOADS,
        //         apiTags.LOTS,
        //         apiTags.CLIENT_CONFIG
        //     ],
        //     //invalidatesTags: [apiTags.LOADS, apiTags.LOAD_GROUP, apiTags.LOTS, apiTags.CLIENT_CONFIG]
        // }),
        updateLoad: builder.mutation({
            query: load => ({
                url: `${apiPrefix}/update`,
                method: "POST",
                body: load
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey(arg) },
                apiTags.LOADS,
                apiTags.LOTS,
                apiTags.CLIENT_CONFIG
            ],
            //invalidatesTags: [apiTags.LOADS, apiTags.LOTS, apiTags.CLIENT_CONFIG, apiTags.LOAD_GROUP]
        }),
        getImportLoadTemplate: builder.mutation({
            query: clientId => ({
                url: `${apiPrefix}/downloadtemplate`,
                method: "POST",
                body: { clientId },
                responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                cache: "no-cache"
            })
        }),
        addLoadGroup: builder.mutation({
            query: group => ({
                url: "load/addGroup",
                method: "POST",
                body: group
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey({ groupId: arg.id }) },
                apiTags.LOADS, apiTags.VEHICLES, apiTags.LOTS, apiTags.CLIENT_CONFIG, apiTags.VEHICLE
            ],
        }),
        addActivityToLoad: builder.mutation({
            query: ({ loadGroupId, activityItemId, activityTypeId, lotId, activityDate, serviceProviderId, vehicleId, workorderCustomData }) => ({
                url: `${apiPrefix}/addActivity`,
                method: "POST",
                body: {
                    loadGroupId,
                    activityItemId,
                    activityTypeId,
                    lotId,
                    activityDate,
                    serviceProviderId,
                    vehicleId,
                    workorderCustomData
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey({ ...arg, groupId: arg.loadGroupId }) },
                apiTags.LOADS
            ],
        }),
        deleteActivityFromLoad: builder.mutation({
            query: ({ loadGroupId, activityGroupId }) => ({
                url: `${apiPrefix}/deleteActivity`,
                method: "POST",
                body: {
                    loadGroupId,
                    activityGroupId
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.LOAD_GROUP, id: getCacheKey({ ...arg, groupId: arg.loadGroupId }) },
                apiTags.LOADS
            ],
        }),
    }),
});

export const {
    useGetLoadsQuery,
    useGetLoadQuery,
    useGetLoadLocationsQuery,
    useGetLoadAttachmentsQuery,
    useGetLoadPaymentsQuery,
    useGetLoadGroupPaymentsQuery,
    useImportLoadsMutation,
    useDispatchLoadMutation,
    useCancelLoadMutation,
    useGetLoadsDashboardQuery,
    useGetGroupedLoadsDashboardQuery,
    useUpdateStatusMutation,
    useAddStatusMutation,
    useDeleteStatusMutation,
    useUpdateActualDateMutation,
    usePostLoadMutation,
    useAddLoadMutation,
    useUpdateLoadMutation,
    useGetImportLoadTemplateMutation,
    useGetLoadsGroupedQuery,
    useGetLoadGroupLocationsQuery,
    useGetLoadGroupAttachmentsQuery,
    useGetLoadGroupQuery,
    useAddLoadGroupMutation,
    useAddActivityToLoadMutation,
    useDeleteActivityFromLoadMutation
} = loadApi;
