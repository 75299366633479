import { Button, CircularProgress, Grid, Paper, Tooltip } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { useDispatch } from "react-redux";
import StorageInvoiceDetails from "./StorageInvoiceDetails";
import { storageInvoiceStyles } from "./style";
import { StorageInvoiceStatus, storageHeaderColumns } from "../../storageInvoiceConsts";
import WarningIcon from '@material-ui/icons/Warning';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import usePrompt from "hooks/usePrompt";
import NotificationProvider from "components/NotificationProvider";
import { useSendStorageInvoiceToQBMutation } from "../../storageInvoiceSlice.ts";

const StorageInvoice = ({ invoice }) => {
    const classes = storageInvoiceStyles();
    const { triggerPrompt } = usePrompt();

    const [expanded, setExpanded] = useState(false);

    const [sendStorageInvoiceToQB, { isLoading: isSending }] = useSendStorageInvoiceToQBMutation();

    const columnStyling = {
        'lot': 'lotCol'
    };

    const sendInvoiceToQB = async () => {
        triggerPrompt({
            title: "Send Invoice to QB",
            content: <div>
                Are you sure you want to send this invoice to QuickBooks?
            </div>,
            onConfirm: async () => {
                let res = await sendStorageInvoiceToQB({ storageInvoiceId: invoice.id });
                if (res?.data?.[0]?.data) {
                    NotificationProvider.success('The invoice was sent to QB successfully');
                } else {
                    NotificationProvider.error('Failed to send the invoice to QB');
                }
            },
            onCancel: async () => {
            },
        });
    };

    return (
        <Paper className={classes.wrapper}>

            <Grid container spacing={1} alignItems='center' justifyContent="flex-end" >
                {storageHeaderColumns?.map((col, index) =>
                    <Grid className={`${classes.column} ${classes[columnStyling[col.key]]} columns`} xs={col.size} item key={`${invoice.id}_${index}_col.key`}>
                        {col?.value ? col.value(invoice) : invoice?.[col.key]}
                    </Grid>
                )}

                {invoice.status === StorageInvoiceStatus.FAILED && <Grid className={classes.column} xs={1} item container key={`failed_message`} justifyContent='flex-end'>
                    <>
                        <Tooltip title="We failed to send this invoice to QuickBooks. This might be due to a missing mapping in Parq. Please contact support.">
                            <div className={classes.warning}><WarningIcon /></div>
                        </Tooltip>
                    </>
                </Grid>}
                <Grid className={classes.column} xs={1} item container key={`empty`} justifyContent='flex-end'>
                    {/* {invoice.status === StorageInvoiceStatus.FAILED &&
                        <>
                            <Authorize profile={permissionProfiles.INVOICE.INVOICES_MANAGE}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.sendBtn}
                                    disabled={isSending}
                                    onClick={sendInvoiceToQB}
                                    >
                                    {isSending && <CircularProgress size={'1em'} style={{ marginRight: '0.1rem' }} />}
                                    {`Send to QB `}
                                </Button>
                            </Authorize>
                        </>
                    } */}
                </Grid>

                <Grid className={classes.column} xs={2} item container key={`col_actions`} justifyContent='flex-end'>
                    <Button
                        color='primary'
                        onClick={() => setExpanded(!expanded)}
                        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {expanded ? 'Less' : 'More'}
                    </Button>
                </Grid>
            </Grid>
            {<StorageInvoiceDetails invoice={invoice} expanded={expanded} />}
        </Paper>
    );
};

export default StorageInvoice;
