import { baseQuery } from "../../app/baseQuery";
import { baseApi } from 'app/baseApi';

export const rolesApi = baseApi.injectEndpoints({
    reducerPath: 'rolesApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllRoles: builder.query({
            query: () => `admin/roles/getall`,
        }),
    }) 
});

export const {
    useGetAllRolesQuery
} = rolesApi