import { Dialog, DialogContent, Grid, Paper } from "@material-ui/core"
import { permissionProfiles } from "components/Authorize/permissionProfiles"
import { useGetClientsQuery } from "features/clients/clientSlice"
import { useGetLotsQuery } from "features/lots/lotSlice"
import NoteList from "features/notes/NoteList"
import { NoteEntityTypes } from "features/notes/notesConsts"
import { useGetVendorsQuery } from "features/vendors/vendorSlice"
import ReservationDocuments from "../NewReservation/Components/ReservationDocuments/ReservationDocuments"
import ReservationVehicle from "../NewReservation/Components/ReservationVehicle/ReservationVehicle"
import { reservationStatusList } from "../reservationConsts"
import { ReservationSummary } from "./ReservationSummary"
import { ReservationViewStyle } from "./style"

export const ReservationView = ({ withDialog = true, reservation, onClose }) => {
    const classes = ReservationViewStyle()
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetVendorsQuery();
    const accounts = clients?.flatMap(c => c.clientAccounts).filter(c => c.isDefault == false);

    const reservationData = {
        ...reservation,
        statusName: reservationStatusList?.find(c => c.id == reservation?.status)?.name,
        clientName: clients?.find(c => c.id == reservation?.clientId)?.name,
        carrierName: vendors?.find(c => c.id == reservation?.carrierId)?.name,
        subClientName: accounts?.find(c => c.id == reservation?.clientAccountId)?.name,
        pickupLotName: lots?.find(c => c.id == reservation?.pickupLocationId)?.name,
        deliveryLotName: lots?.find(c => c.id == reservation?.deliveryLocationId)?.name
    }
    
    const view = <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper className={classes.box}>
                <ReservationSummary reservation={reservationData} />
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className={classes.box}>
                <div className={classes.boxTitle}>Assets</div>
                <ReservationVehicle reservation={reservation} />
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className={classes.box}>
                <div className={classes.boxTitle}>Documents</div>
                <ReservationDocuments reservation={reservation} />
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <Paper className={classes.box}>
                <div className={classes.boxTitle}>Notes</div>
                <NoteList entityId={reservation?.id} entityType={NoteEntityTypes.RESERVATION} listOnly collapsible={false} editPermissionProfile={permissionProfiles.RESERVATIONS.EDIT} />
            </Paper>
        </Grid>
    </Grid>

    return (<>{
        withDialog ? <Dialog maxWidth='md' fullWidth open={!!reservation} onClose={onClose}>
            <DialogContent>
                {view}
            </DialogContent>
        </Dialog> : <>{view}</>
    }
    </>)
}