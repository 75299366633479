import { Grid, Tooltip } from "@material-ui/core";
import { readinessStatuses } from "../readinessUtil";
import { readinessIndicatorStyle } from "./style";

const ReadinessIndicator = ({
    onOpen,
    className,
    text,
    status = readinessStatuses.RED
}) => {

    const classes = readinessIndicatorStyle();
    const statusToClass = {
        [readinessStatuses.GREEN]: classes.greenIndicator,
        [readinessStatuses.YELLOW]: classes.yellowIndicator,
        [readinessStatuses.RED]: classes.redIndicator
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div item xs={2} className={classes.indicatorWrapper}>
                <div
                    className={`${statusToClass[status]} ${classes.indicator} ${className}`}
                    onClick={onOpen}
                ></div>
            </div>
            {!!text && <Tooltip title={text}><div className={classes.indicatorText}>{text}</div></Tooltip>}
        </div>
    );
}

export default ReadinessIndicator;