import { makeStyles } from "@material-ui/core";

export const KpiInspectionStyles = makeStyles((theme) => ({
    card: {
        marginBottom: '1rem',
        padding: '2rem ',
        //height: '100%'
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    wrapper: {
        height: '100%',
        cursor: 'pointer'
    },
    dropdown: {
        padding: '1rem 0 2rem 0',
    },
    siteSizingHeader: {
        fontSize: 13,
        color: '#adb0b2',
        paddingLeft: '2px'

    },
    iconButton: {
        // display: 'flex',
    },
    siteSizingBody: {
        fontWeight: 500,
        paddingLeft: '2px'
    },
    sizing: {
        paddingTop: 20
    },
    addSizeButton: {
        paddingTop: 20,
    },
    inspectionTitle: {
        color: "#0090fe",
        fontWeight: "bold",
        letterSpacing: "1px"
    },
    newInspectionTitle: {
        color: "#0090fe",
        fontWeight: "bold",
        letterSpacing: "1px",
        fontSize: 20
    },
    filterWrapper: {
        marginTop: '1rem',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        whiteSpace: 'nowrap'
    },
    paper: {
        marginBottom: '1rem',
        padding: '2rem ',
    }
}));