import { makeStyles } from "@material-ui/core"

export const loadsListStyle = makeStyles((theme) => ({
    listWrapper: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '61vh',
        //marginTop: '1em',
        "& ul": {
            flex: '1'
        }
    },
    listItem: {
        borderBottom: '1px solid lightgrey',
        '&:last-of-type': {
            border: 'none'
        }
    },
    flaggedLoad: {
        backgroundColor: '#ffff0029'
    }
}));