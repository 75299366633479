import * as React from "react";

function SvgIconCopy(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill={props?.fill ?? "none"} {...props}>
      <path d="M12.95 26.0417H8.05004C5.83165 26.0417 4.341 25.5468 3.39714 24.6029C2.45327 23.659 1.95837 22.1684 1.95837 19.95V15.05C1.95837 12.8316 2.45327 11.341 3.39714 10.3971C4.341 9.45323 5.83165 8.95833 8.05004 8.95833H12.95C15.1684 8.95833 16.6591 9.45323 17.6029 10.3971C18.5468 11.341 19.0417 12.8316 19.0417 15.05V19.95C19.0417 22.1684 18.5468 23.659 17.6029 24.6029C16.6591 25.5468 15.1684 26.0417 12.95 26.0417ZM8.05004 9.70833C6.21216 9.70833 4.82532 10.0134 3.9194 10.9194C3.01349 11.8253 2.70837 13.2121 2.70837 15.05V19.95C2.70837 21.7879 3.01349 23.1747 3.9194 24.0806C4.82532 24.9866 6.21216 25.2917 8.05004 25.2917H12.95C14.7879 25.2917 16.1748 24.9866 17.0807 24.0806C17.9866 23.1747 18.2917 21.7879 18.2917 19.95V15.05C18.2917 13.2121 17.9866 11.8253 17.0807 10.9194C16.1748 10.0134 14.7879 9.70833 12.95 9.70833H8.05004Z" fill={props?.fill ?? "#242A31"} stroke={props?.fill ?? "#242A31"} />
      <path d="M19.0417 17.7917V18.2917H19.5417H19.95C21.7879 18.2917 23.1748 17.9866 24.0807 17.0806C24.9866 16.1747 25.2917 14.7879 25.2917 12.95V8.05C25.2917 6.21212 24.9866 4.82528 24.0807 3.91936C23.1748 3.01344 21.7879 2.70833 19.95 2.70833H15.05C13.2122 2.70833 11.8253 3.01344 10.9194 3.91936C10.0135 4.82528 9.70837 6.21212 9.70837 8.05V8.45833V8.95833H10.2084H12.95C15.1684 8.95833 16.6591 9.45323 17.6029 10.3971C18.5468 11.341 19.0417 12.8316 19.0417 15.05V17.7917ZM19.95 19.0417H18.6667C18.4645 19.0417 18.2917 18.8689 18.2917 18.6667V15.05C18.2917 13.2121 17.9866 11.8253 17.0807 10.9194C16.1748 10.0134 14.7879 9.70833 12.95 9.70833H9.33337C9.13118 9.70833 8.95837 9.53552 8.95837 9.33333V8.05C8.95837 5.83161 9.45327 4.34095 10.3971 3.39709C11.341 2.45323 12.8317 1.95833 15.05 1.95833H19.95C22.1684 1.95833 23.6591 2.45323 24.6029 3.39709C25.5468 4.34095 26.0417 5.83161 26.0417 8.05V12.95C26.0417 15.1684 25.5468 16.659 24.6029 17.6029C23.6591 18.5468 22.1684 19.0417 19.95 19.0417Z" fill={props?.fill ?? "#242A31"} stroke={props?.fill ?? "#242A31"} />
    </svg>
  );
}

export default SvgIconCopy;
