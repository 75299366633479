import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const SubscriptionPermissionProfiles = {
    SUBSCRIPTION_AUDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    EMAIL_TYPE_1: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER, roles.CLIENT]
    },
    EMAIL_TYPE_3: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.ADD_PRICE]
    },
    EMAIL_TYPE_4: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.APPROVE_ABOVE_PRICE_CAP]
    },
    EMAIL_TYPE_6: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.MANAGE_PENDING_INVOICE]
    },
}