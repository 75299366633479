import { CircularProgress, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { Button } from "@material-ui/core";
import TransportsConfig from "../clientConfigs/transports";
import AddIcon from '@material-ui/icons/Add';
import CustomInput from "../../../components/CustomInput";
import { IconUser } from "../../../icons";
import AddClientPopupStyle from "./AddClientPopupStyle";
import ContractHeader from "../contracts/ContractHeader/ContractHeader";
import LotsConfig from "../clientConfigs/lots";
import WashConfig from "../clientConfigs/washes";
import { clientConfigSetup } from "../clientConfigsSetup";

const useStyles = AddClientPopupStyle;

const AddClientPopup = ({ setOpen, clientToEdit, setClientToEdit, onSave }) => {

    const classes = useStyles();
    let [isSaving, setIsSaving] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        setIsSaving(true);
        await onSave();
        setIsSaving(false);
        handleClose();
    };

    const onConfigChange = (configName, key, val) => {
        if(configName){
            setClientToEdit({ ...clientToEdit, ["config"]: { ...clientToEdit.config, [configName]: { ...clientToEdit.config[configName], [key]: val } } });
        }else{
            setClientToEdit({ ...clientToEdit,  [key]: val });
        }  
    }

    const isValid = () => {
        return clientToEdit?.name
    }

    const allConfigs = clientConfigSetup(clientToEdit, onConfigChange)?.filter(c => c.showOnCreate);

    let form;

    form = (
        <>
            {isSaving && <CircularProgress className={classes.spinner} />}
            <Paper className={classes.configWrapper}>
                <ContractHeader label={'Basic Information'} />
                <div className={classes.input}>
                    <CustomInput
                        id="name"
                        label="Client Name"
                        value={clientToEdit?.name}
                        elementType="input"
                        icon={<IconUser width={30} height={30} className={classes.inputIcon} />}
                        onChange={val => onConfigChange(undefined, "name", val)}
                        disabled={isSaving}
                    />
                </div>
            </Paper>

            {allConfigs.map(config =>
                <Paper className={classes.configWrapper}>
                    {config.component}
                </Paper>
            )}
        </>
    )


    return (
        <>
            <Paper className={classes.formContainer}>
                {/* <div className={classes.mainTitle}><AddIcon fontSize="large" className={classes.icon} />Client Contracts</div> */}
                <form className={`${classes.form}`}>
                    {form}
                </form>
                <div className={`${classes.btnContainer}`}>
                    <Button
                        onClick={handleSave}
                        disabled={isSaving || !isValid()}
                        className={classes.button}
                        variant="contained"
                        color='primary'
                    >
                        {isSaving ? "Saving..." : "Save"}
                    </Button>
                    <Button className={classes.button} disabled={isSaving} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                </div>
            </Paper>
        </>
    );
};

export default AddClientPopup;
