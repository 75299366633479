import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { useDeleteActivityTypeMutation } from "features/activity/activitySlice";
import NotificationProvider from "components/NotificationProvider";
import { Alert } from "@mui/material";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { activityTypeStyle } from "../ActivityTypesList/style";

const DeleteActivityType = ({ open, handleClose, activityTypeToDelete, isLoading, canDeleteResponse, setCanDeleteResponse }) => {
    const classes = activityTypeStyle()

    const [deleteActivityType] = useDeleteActivityTypeMutation();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);

    const handleDelete = async () => {
        let result = await deleteActivityType(activityTypeToDelete?.id)
    
        setCanDeleteResponse(null);

        if (result && !result.error) {
            NotificationProvider.success('Activity deleted successfully');
            handleClose();
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
            handleClose();
        }
    };

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    return (
        <Dialog open={open} close={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form_dialog_title">Delete Activity Type</DialogTitle>
            <DialogContent>
                {
                    isLoading ? <> 
                        <LoadingSpinner loading={isLoading} />
                        <div>{"Checking for open requests or mechanical rules"}</div>
                    </> 
                    : 
                    <div>
                        {canDeleteResponse?.inSla == true && 
                            <Alert severity="warning" className={classes.warning}>
                                This activity type is included in the Mechanical SLA of {canDeleteResponse?.clients?.map(id => clientLookup[id]?.name).join(', ')}
                            </Alert>
                        }
                        {canDeleteResponse?.hasRequests == true ? 
                            <div>{"This activity type cannot be deleted since there are pending activities of this type"}</div>
                            : <div>{"Are you sure you want to delete this activity type?"}</div>
                        }                        
                    </div>
                }                              
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                { !canDeleteResponse?.hasRequests ?
                    <Button onClick={handleDelete} color="primary">
                        Delete
                    </Button> : null
                }
            </DialogActions>
        </Dialog>
    );
}

export default DeleteActivityType;