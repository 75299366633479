import store from "app/store/store";
import { toArray } from "utils/object-util";
import { modes } from './modes';
import { roles } from "./roles";
import { selectIsAccountManager, selectIsAdmin, selectIsClient, selectIsMechanic, selectIsOwner, selectUserPermissions } from "features/user/userSlice";


//TODO-chages this logic in only one place 
export const hasPermission = (permission) => {
    let state = store.getState();
    const permList = toArray(state?.oidc?.user?.profile?.permission)
    return permList.includes(permission);
}

export const isAuthorized = (profile) => {
    let state = store.getState();
    const isOwner = selectIsOwner(state);
    const isClient = selectIsClient(state);
    const isAdmin = selectIsAdmin(state);
    const isAccountManager = selectIsAccountManager(state);
    const isMechanic = selectIsMechanic(state);
    const permissions = selectUserPermissions(state);

    const hasRole = (role) => {
        const roleChecker = {
            [roles.OWNER]: isOwner,
            [roles.ADMIN]: isAdmin,
            [roles.CLIENT]: isClient,
            [roles.ACCOUNT_MANAGER]: isAccountManager,
            [roles.MECHANIC]: isMechanic,
        };
        return roleChecker[role] || false;
    };

    const checkProfile = (profileMode) => {
        return profileMode?.some(item =>
            typeof item === 'string' ? hasRole(item) : hasPermission(item.key)
        );
    };

    return checkProfile(profile?.[modes.READONLY]) || checkProfile(profile?.[modes.EDIT]) || checkProfile(profile?.[modes.EDIT_PERMISSION]);
};