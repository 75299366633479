import { Button, Chip } from "@material-ui/core";
import { DefaultRangesTypes, PredefinedRanges } from "components/DateRange/DefaultRanges";
import { useState } from "react";
import { RankingDateFiltersStyle } from "./style";

const RankingDateFilters = ({ setStartDate, setEndDate }) => {

    const classes = RankingDateFiltersStyle();

    const [buttonClicked, setButtonClicked] = useState(-1);

    const handleTimeFrame = (key) => {
        setStartDate(PredefinedRanges?.find(r => r.key == key)?.range().startDate.toDateString())
        setEndDate(PredefinedRanges?.find(r => r.key == key)?.range().endDate.toDateString())
        setButtonClicked(key);
    };

    const resetTimeFrame = () => {
        setStartDate(null)
        setEndDate(null)
        setButtonClicked(-1);
    }

    return (
        <div className={classes.timeFilters}>
            <Button className={classes.timeFilterButton} aria-selected={true} onClick={resetTimeFrame} >
                <Chip label="Current" variant={buttonClicked == -1 ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.LAST_WEEK)} >
                <Chip label="Last Week" variant={buttonClicked == DefaultRangesTypes.LAST_WEEK ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.MONTH)} >
                <Chip label="Month" variant={buttonClicked == DefaultRangesTypes.MONTH ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.LAST_MONTH)} >
                <Chip label="Last Month" variant={buttonClicked == DefaultRangesTypes.LAST_MONTH ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.QUARTER)} >
                <Chip label="Quarter" variant={buttonClicked == DefaultRangesTypes.QUARTER ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.LAST_QUARTER)} >
                <Chip label="Last Quarter" variant={buttonClicked == DefaultRangesTypes.LAST_QUARTER ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.YEAR)} >
                <Chip label="Year" variant={buttonClicked == DefaultRangesTypes.YEAR ? "default" : "outlined"} />
            </Button>
            <Button className={classes.timeFilterButton} onClick={() => handleTimeFrame(DefaultRangesTypes.LAST_YEAR)} >
                <Chip label="Last Year" variant={buttonClicked == DefaultRangesTypes.LAST_YEAR ? "default" : "outlined"} />
            </Button>
        </div>
    )
}

export default RankingDateFilters;