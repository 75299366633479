import { Grid, Link } from "@material-ui/core";
import { VehicleLinksStyle } from "./style.js";
import {  IconVehicle } from "icons";


export const VehicleLinks = ({
    reservation,
}) => {
    const classes = VehicleLinksStyle();
    return (<Grid container direction="column" >
        <div className={classes.linkedAssets}>
        {reservation?.reservationVehicles?.map((asset, index) =>
        <>
                <Grid item container spacing={1} key={index} className={classes.noMarginBottom}>
                    <Grid item className={classes.iconWrapper}><IconVehicle /></Grid>
                    <Grid item><Link target='_blank' href={`/assets/detail/${asset?.id}`} className={classes.link}>{asset?.vin|| asset?.assetId || asset?.id}</Link></Grid>
                </Grid ></>)}
        </div>
    </Grid>
    )
}