import { useState } from "react";
import { InspectionsTimeFrameStyles } from "./InspectionsTimeFrameStyles";
import DateRange from "components/DateRange";
import { useGetInspectionsByTimeFrameQuery } from "../../inspectionSlice";
import InspectionReport from "../ReportDialog/InspectionReport";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import FilterService from "services/filterService";
import { useSelector } from "react-redux";

const InspectionsTimeFrame = ({ }) => {
    const classes = InspectionsTimeFrameStyles();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onDateSelect = (e) => {
        setStartDate(e?.startDate?.toDateString());
        setEndDate(e?.endDate?.toDateString());
    }

    let { data: inspections, error: inspectionError, isFetching: isLoadingLoadsDashboard, refetch:refetvhLoadsDashboard } = useGetInspectionsByTimeFrameQuery({ startDate, endDate });
    inspections = inspections || [];

    inspections = inspections.map(ins => ({
        ...ins,
        needsAttentionFilter: ins.needsAttention ? '1' : '0'
    }));
    
    const { filters } = useSelector(state => state.inspectionState);
    const filteredInspections = FilterService.filter(filters, null, inspections, []);

    return (
        <div className={classes.timeFrameContainer}>
            <div className={classes.dateRange}>
                <DateRange
                    title='Time Frame'
                    identifier='timeFrame'
                    onSelect={onDateSelect}
                    filterButton={true}
                    showPredefinedRanges={true}
                    dateValue={null}
                />
            </div>
            {!!filteredInspections?.length ? filteredInspections?.map((inspection, key) => (
                <div key={inspection.id} className={classes.report}><InspectionReport inspection={inspection} /></div>
            )) : <EmptyPlaceholder text='No Inspections Found' />}
        </div>
    );
};

export default InspectionsTimeFrame;
