import { makeStyles } from "@material-ui/core";

export const GenerateBillingReportsStyle = makeStyles((theme) => ({
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    generatePaper: {
        padding: '1rem',
        marginBottom: '0.5rem'
    },
    datePicker: {
        margin: '1rem 1rem 1rem 0'
    },
    generateBillingTitle: {
        marginBottom: '0.5rem',
        fontWeight: 500,
        fontSize: 14
    },
}));
