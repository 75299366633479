import { makeStyles } from "@material-ui/core";

export const lotDispatchDetailsStyle = makeStyles((theme) => ({
    lotDetails: {
        maxHeight: '60vh',
        minHeight: '60vh',
        flex: 1,
        overflowY: 'auto',
    },
    detailCard: {
        margin: '1rem 0',
        padding: '1rem',
        minHeight: '4rem'
    },
    cardHeader: {
        alignItems: 'end',
        display: 'flex',
        marginBottom: '0.5rem',
        justifyContent: 'space-between'
    },
    cardHeaderTitle: {
        display: 'flex',
        alignContent: 'end'
    },
    cardTitle: {
        fontWeight: 600,
        paddingLeft: '1rem',
        fontSize: 16
    },
    titleIcon: {
        width: 25,
        height: 25,
    },
    poc: {
        margin: '1rem 0',
        color: theme.palette.grey[500]
    },
    pocLabel: {
        display: 'flex',
        textAlign: 'left',
        padding: '3px',
        '& label': {
            fontWeight: 600,
            width: '30%',
        }
    },
    openingHoursContainer: {
        minWidth: "170px",
    },
    openingHoursTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    openingHours: {
        color: theme.palette.grey[500],
        margin: "0.5rem 0",
        fontSize: "0.rem",
        display: 'flex',
        justifyContent: 'space-between',
        '& label': {
            fontWeight: '600',
            margin: "-0.5rem",
            fontSize: "0.85rem",
            padding: "0.5rem"
        }
    },
    openingHoursDivider: {
        margin: "1rem 0",

    },
    scheduleName: {
        margin: '0.5rem 0',
        fontWeight: 600
    },
    editIcon: {
        fontSize: '3rem',
        fontWeight: 200
    },
    editContainer: {
        textAlign: 'center',
        width: '100%',
        height: '100%'
    },
    directionsText: {
        padding: '0.5rem 0',
        fontSize: 15,
        color: theme.palette.grey[500],
    },
    editButton: {
        display: 'flex',
        justifySelf: 'flex-end',
        padding: '0'
    }
}));