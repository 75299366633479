import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    button: {
        margin: "0 0.5rem",
        padding: '0.5rem',
        height: 54,
        width: 170,
        fontSize: 15
    },
    title: {
        fontSize: 24,
        fontWeight: 550,
        paddingBottom: "1em"
    },
    pageContent: {
        padding: "3em"
    },
    paper: {
        minHeight: "700px",
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    list:{
        margin: "1rem 1rem 0 0",
        padding: '1rem',
        height: '100%',
        height: '100vh',
        overflowY: 'auto',
        overflow: 'hidden'
    },
    card: {
        maxHeight: "100px",
        padding: '.75em',
        backgroundColor: theme.palette.background.default,
        borderRadius: '3px'
    },
    subHeader: {
        fontSize: 18,
        fontWeight: 500,
    },
    input: {
        width: '130px',
        marginRight: '2em'
    },
    textbox:{
        height: '2px',
        backgroundColor: 'white',
        border: '1px solid #0090fe'
    },
    subTitle: {
        fontSize: 15,
        fontWeight: 500,
        paddingLeft: ".5em"
    },
    listItem: {
        backgroundColor: '#f6faff',
        borderRadius: '3px',
        marginTop: '.5em'
    },
    icon: {
        padding: '2em'
    },
    name: {
        fontSize: 14,
        fontWeight: 450,
        color: '#0090fe'
    },
    searchBar: {
        marginTop: '.5em'
    },
    updateButton: {
        paddingTop: '2em'
    },
    code: {
        marginRight: '1em',
        fontWeight: 500,
        marginRight: '3em'
    },
    checkbox: {
        marginLeft: '-.5em'
    },
    bulkButton: {
        height: '60px'
    },
    icons: {
        marginRight: '-1em'
    },
    editicon: {
        marginRight: '-.5em'
    },
    toolbar: {
        position: 'fixed',
        bottom: 0,
        paddingLeft: '70em',
        width: '300px'
    }
}))