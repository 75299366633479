import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import { useUpdateLoadMutation } from "features/loads/loadsSlice";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import GroupLoadForm from "../CreateLoadForm";
import CreateLoadFormOld from '../CreateLoadForm/CreateLoadForm_old';

const EditLoadLeg = ({ open, setOpen, loadGroup, loadIndex }) => {

    let [updateLoad, { isLoading: saving }] = useUpdateLoadMutation();

    const [loadGroupToEdit, setLoadGroupToEdit] = useState({})

    useEffect(() => {
        if (loadGroup) {
            setLoadGroupToEdit(cloneDeep(loadGroup ?? {}));
        }
    }, [loadGroup]);

    const onChange = (changedGroup) => {
        setLoadGroupToEdit(changedGroup);
    };

    const isValid = () => {
        const load = loadGroupToEdit?.loads?.[loadIndex];
        const res = load?.pickupStartDate &&
            load?.deliveryStartDate &&
            ((load.pickupLocationId && load.pickupLocationId !== 0 && !load?.originNewLot) ||
                (load?.originNewLot &&
                    load.pickupName &&
                    load.pickupNodeName &&
                    load.pickupAddress &&
                    load.pickupCity &&
                    load.pickupState &&
                    load.pickupZip &&
                    load.pickupPocEmail &&
                    load.pickupPhone)) &&
            ((load.deliveryLocationId && load.deliveryLocationId !== 0 && !load?.destinationNewLot) ||
                (load?.destinationNewLot &&
                    load.deliveryName &&
                    load.deliveryNodeName &&
                    load.deliveryAddress &&
                    load.deliveryCity &&
                    load.deliveryState &&
                    load.deliveryZip &&
                    load.deliveryPocEmail &&
                    load.deliveryPhone));
        return res;
    };

    const onSubmit = async () => {
        const validate = isValid();

        const newLoad = loadGroupToEdit?.loads?.[loadIndex];
        let result = await updateLoad(newLoad)
        if (result?.error) {
            NotificationProvider.error(`Failed to update the load`)
        } else {
            NotificationProvider.success(`Successfully updated the load`);
            setOpen(false);
        }
    };

    return (
        <Dialog open={open} aria-labelledby='dialog-title' maxWidth='md'>
            <DialogTitle id={'dialog-title'} >Edit Load</DialogTitle>
            <DialogContent>
                <>
                    {loadGroup?.loads?.[loadIndex]?.moveType ?
                        <CreateLoadFormOld // for really old loads (before we changed the move types)
                            handleClose={() => setOpen(false)}
                            loadToEdit={loadGroup?.loads?.[loadIndex]}
                        /> :
                        <GroupLoadForm
                            legIndex={loadIndex}
                            handleClose={() => setOpen(false)}
                            loadGroup={loadGroupToEdit}
                            setLoadGroup={onChange}
                        />
                    }</>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color='primary'>
                    Cancel
                </Button>
                <Button variant="contained" disabled={saving || !isValid()} onClick={onSubmit} color='primary'>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>

    )
}
export default EditLoadLeg;