import Authorize from "components/Authorize"
import { permissionProfiles } from "components/Authorize/permissionProfiles"
import CustomInput from "components/CustomInput"
import { ruleFieldType, ruleFieldTypeConfig } from "../consts"

export const TreadDepthField = ({ ruleParam, onChange }) => {
    return <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
        <CustomInput
            label={`${ruleFieldTypeConfig[ruleFieldType.TREAD_DEPTH]?.name} <=`}
            elementType="input"
            value={ruleParam?.paramValue}
            onChange={val => onChange(val)}
        />
    </Authorize>
}