import { makeStyles } from "@material-ui/core";

export const lotsMapStyle = makeStyles((theme) => ({
    mapWrapperCard: {
        padding: '1rem',
    },
    mapWrapper: {
        position: 'relative'
    },
    mapHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '1rem 0.7rem 0 0',
        position: 'absolute',
        zIndex: 1
    },
    lotFilterContainer: {
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5rem',
        backgroundColor: '#f3f5f6'
    },
    lotFilterCheck: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    lotFilterLabel: {
        fontWeight: 500
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        border: 0,
    },
    mobileButton: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        border: 0,
        maxWidth: '45px'
    },
    mapContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    addIcon: {
        fill: 'white'
    }
}));
