import { makeStyles } from "@material-ui/core";

export const IssueTrackerDashboardStyle = makeStyles((theme) => ({
    dashboardWrapper: {
        // marginBottom: '.5em',
    },
    card: {
        padding: '1em',
        height: '100%',
        boxShadow: '2px 3px 10px #d7d6d6',
        position: 'relative'
    },
    cardCount: {
        fontSize: '1.5em',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    clickableCard: {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: theme.shadows[5]
        }
    },
    cardText: {
        color: '#0061AA',
    },
    graphTootltip: {
        background: theme.palette.background.paper,
        padding: '1em',
        boxShadow: theme.shadows[3]
    },
    graphHeader: {
        color: theme.palette.primary.dark,
        marginTop: '0',
        marginBottom: 0,
        fontWeight: 500,
        fontSize: '1em'
    },
    dateRangeSelector: {
        fontSize: '1.3em'
    },
    countWidget: {
        borderTop: `7px solid #AADAFF`
    },
    filterIcon: {
        '& path': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.primary.main
        }
    }
}))