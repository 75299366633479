import { Grid, Popover } from "@material-ui/core";
import React from "react";
import { readinessStatuses } from "../readinessUtil";
import { readinessIndicatorStyle } from "./style";

const ReadyBadge = ({
    status = 3,
    tooltipContent
}) => {
    const classes = readinessIndicatorStyle();
    const statusToClass = {
        [readinessStatuses.GREEN]: classes.greenIndicator,
        [readinessStatuses.YELLOW]: classes.yellowIndicator,
        [readinessStatuses.RED]: classes.redIndicator
    }

    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleClick = (event) => {
        tooltipContent && setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };
    const open = Boolean(anchorEl);

    const ready = status == readinessStatuses.GREEN;
    return (
        <>
            <div onClick={handleClick}>
                <div className={`${statusToClass[status]} ${classes.readyBadge}`}>{ready ? 'Ready' : 'Not ready'}</div>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.tooltipContent}>
                    {tooltipContent}
                </div>
            </Popover>
        </>
    );
}

export default ReadyBadge;