import { baseQuery } from "../../../app/baseQuery";
import { apiTags } from '../../../app/store/apiTags';
import { baseApi } from 'app/baseApi';

export const userManagementApi = baseApi.injectEndpoints({
    reducerPath: 'userManagementApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUserManager: builder.query({
            query: () => `UserManager/GetAllUser`,
            providesTags: [apiTags.USER_MANAGER]
        }),
        getAllClientsManager: builder.query({
            query: () => `UserManager/GetAllClients`,
            providesTags: [apiTags.USER_MANAGER],
        }),
        getAllLotsManager: builder.query({
            query: () => `UserManager/GetAllLots`,
            providesTags: [apiTags.USER_MANAGER],
        }),
        getUserLotRelationsForUserByManager: builder.query({
            query: (userId) => `UserManager/userId/${userId}`,
            providesTags: [apiTags.USER_MANAGER],
        }),
        updateUserInformationManager: builder.mutation({
            query: user => ({
                url: "UserManager/UpdateUserInformation",
                method: 'POST',
                body: user
            }),
            invalidatesTags: [apiTags.USER_MANAGER]
        }),
        createUserByManager: builder.mutation({
            query: user => ({
                url: "UserManager/CreateUser",
                method: 'POST',
                body: user
            }),
            invalidatesTags: [apiTags.USER_MANAGER]
        }),
        saveUserRolesConfiguration: builder.mutation({
            query: (payload) => ({
                url: "UserManager/SaveUserRolesConfiguration",
                method: 'POST',
                body: payload
            }),
            invalidatesTags: [apiTags.USER_MANAGER]
        }),
        getUserTypeByUserId: builder.query({
            query: (userId) => `UserManager/getUserType/${userId}`,
            providesTags: [apiTags.USER_MANAGER],
        }),
    }),
});

export const {
    useGetUserManagerQuery,
    useGetUserTypeByUserIdQuery,
    useGetAllClientsManagerQuery,
    useGetAllLotsManagerQuery,
    useGetUserLotRelationsForUserByManagerQuery,
    useCreateUserByManagerMutation,
    useUpdateUserInformationManagerMutation,
    useSaveUserRolesConfigurationMutation
} = userManagementApi;