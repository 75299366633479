import { baseQuery } from "../../../app/baseQuery";
import { apiTags } from '../../../app/store/apiTags';
import { baseApi } from 'app/baseApi';

export const permissionApi = baseApi.injectEndpoints({
    reducerPath: 'permissionApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllPermission: builder.query({
            query: () => `Permission/GetAll`,
            providesTags: [apiTags.PERMISSION]
        })
    }),
});

export const {
    useGetAllPermissionQuery,
} = permissionApi;