const { makeStyles } = require("@material-ui/core");
const drawerWidth = 110;
const mobileDrawerWidth = 90;

export const sideNavStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        // overflowY: "auto",
        // '&::-webkit-scrollbar': {
        //     width: '0.4em'
        //   },
        //   '&::-webkit-scrollbar-track': {
        //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        //     webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        //   },
        //   '&::-webkit-scrollbar-thumb': {
        //     backgroundColor: 'rgba(0,0,0,.1)',
        //     outline: '1px solid slategrey'
        //   }
    },
    drawer: {
        // width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        // width: drawerWidth,
        backgroundColor: theme.palette.common.white,
        border: "none",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15)",

    },
    drawerContainer: {
        overflow: 'hidden',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        alignItems: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    logo: {
        maxWidth: "60px",
        marginLeft: "30px"
    },
    logoutButton: {
        textAlign: "left",
        margin: "1rem 1rem 2rem 1rem"
    },
    logoMobile: {
        maxWidth: 60,
        paddingLeft: 0,
        marginBottom: 32,
        marginTop: 4
    },
    drawerMobile: {
        width: mobileDrawerWidth
    },
    drawerPaperMobile: {
        width: mobileDrawerWidth,
        overflow: "hidden"
    },
    logoutButtonMobile: {
        marginLeft: "10px",
        "& .MuiButton-root": {
            minWidth: 0
        },
        "& .MuiButton-label": {
            paddingLeft: "8px"
        },
        "& .MuiButton-startIcon": {
            marginRight: 0
        }
    },
    addAssetButton: {
        margin: "1rem 0 2rem 2rem"
    },
    expand: {
        position: 'absolute',
        right: '0'
    },
    menuItemWrapper: {
        overflow: 'hidden'
    },
    subMenu: {
        background: theme.palette.background.default,
        background: 'white',
        marginLeft: '1.5em',
        width: '200px',
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
    },
    subMenusList: {
        background: theme.palette.background.default,
        display: 'block'
    },
    expandBtn: {
        cursor: 'pointer',
        borderRadius: '50%',
        '&:hover': {
            opacity: '0.5'
        },
        paddingTop: '4px',
        marginTop: '0.5rem',
        marginRight: '0.2rem',
    },

}));
