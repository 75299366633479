import { makeStyles } from "@material-ui/core";

export const TaskAttachmentsStyle = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: '#FAFCFF',
        padding: '1.5em'
    },

    title: {
        fontSize: 15,
        fontWeight: 500,
        alignItems: 'center',
    },
}))
