import CustomInput from 'components/CustomInput';
import { AccessGroupTypesList } from 'features/access/accessConsts';
import { StepsStyles } from './StepsStyles';
import { useDispatch, useSelector } from 'react-redux';
import {setAccessToEdit } from 'features/access/accessStateSlice';
import { selectIsClient } from 'features/user/userSlice';

const GroupTypeStep = ({ }) => {
    const classes = StepsStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();
    const isClient = useSelector(selectIsClient);

    const onChangeValue = (val) => {
        dispatch(setAccessToEdit({"groupType": val}))
    }

    return (
        <>
        <div className={classes.text}>Define the group access</div>
            <CustomInput
                label='Access Group Type'
                elementType='dropdown'
                value={accessToEdit?.groupType}
                values={AccessGroupTypesList?.filter(a => !isClient || (isClient && a.showForClient))}
                onChange={(val) =>onChangeValue(parseInt(val))}
                showEmpty
                required
            />
        </>
    );
}

export default GroupTypeStep;