

export const billingPrograms = {
    STORAGE: 1,
    TRANSPORT: 2,
    MECHANICALS: 3,
    WASHES: 4
}
export const programList = [{ id: billingPrograms.STORAGE, name: "Storage" },
{ id: billingPrograms.TRANSPORT, name: "Transports" },
{ id: billingPrograms.MECHANICALS, name: "Mechanicals" },
{ id: billingPrograms.WASHES, name: "Washes" }];