import { InvoiceStatuses } from "features/invoice/activity/activityInvoiceConsts";
import { Chip } from "@material-ui/core";
import { activityInvoiceStatusStyles } from "./style";

const ActivityInvoiceStatusDisplay = ({ status }) => {
    const classes = activityInvoiceStatusStyles();
    return (<>
        {!!InvoiceStatuses[status]?.warningComponent && <div className={classes.warningComponent}>{InvoiceStatuses[status]?.warningComponent}</div>}
        {!!status && <Chip
            icon={InvoiceStatuses[status]?.icon}
            label={InvoiceStatuses[status]?.name}
            className={classes.invoiceStatusWrapper} style={{ backgroundColor: InvoiceStatuses[status]?.color }}
        />}
    </>
    )
}

export default ActivityInvoiceStatusDisplay;