import { Badge, Drawer, Grid, IconButton, Popper } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { getLatestTaskSeen, saveLastSeenToLocalStorage } from "app/notifications/handlers/userTaskNotificationOptions";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { IconClipboard } from "icons";
import { orderBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import { TasksPanelStyles } from "../../TasksPanelStyles";
import { setFilters, setShowDomiciledOnly, setTaskPanelOpen } from "../../taskSlice";
import LotTasks from "../LotTasks/LotTasks";
import UserTasks from "../UserTasks";
import { useGetUserTasksQuery } from "../UserTasks/userTaskSlice";

const TasksPanel = () => {
    const classes = TasksPanelStyles();

    const dispatch = useDispatch();
    const { taskPanelOpen } = useSelector(state => state.taskState);
    const [selectedTab, setSelectedTab] = useState(0);
    const [notSeen, setNotSeen] = useState([]);

    let { data: tasks, error: tasksError, isLoading: isLoadingTasks } = useGetUserTasksQuery();

    const openPanel = value => {
        dispatch(setTaskPanelOpen(value));
    }

    useEffect(() => {
        if (tasks?.length) {
            const latestTaskSeen = getLatestTaskSeen();
            const latestSeenId = latestTaskSeen ?? 0;
            const newTasks = tasks?.filter(t => t.id > latestSeenId);
            setNotSeen(newTasks);
            const latesTask = orderBy(tasks, 'id', 'desc')?.[0];
            saveLastSeenToLocalStorage(latesTask?.id);
            setTimeout(() => {
                setNotSeen([]);
            }, 10000)
        }
    }, [tasks]);

    const tabs = [
        {
            label: 'Lot Tasks',
            permission: permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW
        },
        {
            label: `User Tasks (${tasks?.length ?? 0})`,
        }
    ]

    const onChangeTab = (tab) => {
        setSelectedTab(tab);
    }
    const ref = useRef();

    return (
        <>
            <Authorize profile={permissionProfiles.TASKS.TASK_LIST}>
                <IconButton ref={ref} title='Tasks' onClick={() => openPanel(true)} >
                    <Badge badgeContent={tasks?.length ?? 0} color="primary">
                        <IconClipboard />
                    </Badge>
                </IconButton>
                <Popper
                    transition
                    id={'new_tasks'}
                    open={!!notSeen?.length}
                    anchorEl={ref?.current}
                    className={classes.newTaskContent}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    hideBackdrop={true}
                    disableScrollLock={true}
                >
                    <Grid container spacing={1} direction='column'>
                        <Grid item style={{ fontSize: '1.1em' }}><b>Welcome Back!</b><CloseIcon style={{ cursor: 'pointer', height: '0.8em', position: 'absolute', right: '0.5em', top: '0.5em' }} onClick={() => setNotSeen([])} /></Grid>
                        <Grid item>You have {notSeen?.length ?? 0} new task{notSeen?.length > 1 ? 's' : ''} waiting for you to complete</Grid>
                    </Grid>
                </Popper>
            </Authorize >
            <Drawer
                anchor={'right'}
                open={taskPanelOpen}
                onClose={() => {
                    dispatch(setShowDomiciledOnly(false));
                    dispatch(setFilters({ lotId: { value: [] }, clientId: { value: '' } }));
                    openPanel(false)
                }}
                BackdropProps={{ invisible: true }}
            >

                <Grid direction="column" container className={classes.panel}>
                    <Grid container className={classes.header}>
                        <TabsWrapper tabs={tabs} selectedTab={selectedTab} setSelectedTab={onChangeTab} className={classes.tabs} />
                    </Grid>
                    {selectedTab == 1 && <UserTasks />}
                    {/* {selectedTab == 1 && <GeneralTasks />} */}
                    {selectedTab == 0 && <LotTasks />}
                </Grid>
            </Drawer>
        </>
    )
}
export default TasksPanel;