import { Button, FormControlLabel, Checkbox, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { orderBy } from "lodash";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { StepsToCompleteStyles } from "./style";
import { useGetAllStepsByWorkOrderQuery, useUpdateActivityTypeStepMutation } from "features/activity/ActivityTypeStepSlice";
import { useEffect, useState } from "react";
import NotificationProvider from "components/NotificationProvider";
import { useSelector } from "react-redux";
import { selectIsClient } from "features/user/userSlice";
import { activityPermissionProfile } from "components/Authorize/permissionProfiles/activityPermissionProfile";
import Authorize from "components/Authorize";

const StepsToComplete = ({ activity, setHasUnsavedChanges }) => {

    const classes = StepsToCompleteStyles();

    let isClient = useSelector(selectIsClient);

    let { data: steps, refetch: refetchSteps } = useGetAllStepsByWorkOrderQuery(activity?.workOrderId, { skip: !activity?.workOrderId });
    let [updateSteps, { isLoading: updatingSteps }] = useUpdateActivityTypeStepMutation();

    const [itemList, setItemList] = useState([]);
    const [overwriteConfig, setOverwriteConfig] = useState(false);

    useEffect(() => {
        if (steps) {
            steps = steps.map(a => ({
                ...a,
                id: a.id.toString()
            }));

            setItemList(steps);
        }
    }, [steps]);

    let values = orderBy([...itemList ?? [],] ?? [{}],
        'sequenceNumber');

    if (!isClient) {
        values.push({
            description: '',
            isNew: true,
            isCompleted: false,
            entityId: activity?.id,
            entityType: 1,
            activityGroupId: activity?.groupId,
            WorkOrderId: activity?.workOrderId,
            sequenceNumber: itemList?.length
        })
    }

    const getValues = () => {
        return values.filter(v => v.description);
    }

    const onValueChange = (newVal, index) => {
        let valuesToSave = getValues();
        if (index == values?.length - 1) {
            valuesToSave = values;//Saving the new option
            const { isNew, ...others } = valuesToSave[index];
            valuesToSave[index] = others;
        }
        valuesToSave[index] = { ...valuesToSave[index], description: newVal, sequenceNumber: index };
        changeItemList(valuesToSave);
    }


    const onSelectStep = (index, val) => {
        let valuesToSave = getValues();
        valuesToSave[index] = { ...valuesToSave[index], isCompleted: val };
        changeItemList(valuesToSave);
    }

    const changeItemList = (valuesToSave) => {
        setItemList(valuesToSave?.map((v, index) => ({ ...v, sequenceNumber: index })));
        setHasUnsavedChanges(true)
    }

    const onDeleteValue = (rowIndex) => {
        const valuesToSave = getValues();
        valuesToSave.splice(rowIndex, 1);
        setItemList(valuesToSave?.map((v, index) => ({ ...v, sequenceNumber: index })));
        setHasUnsavedChanges(true)
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const valuesToSave = getValues();
        setItemList(arrayMove(valuesToSave, oldIndex, newIndex).map((q, index) => ({ ...q, sequenceNumber: index })))
    };

    const onSaveAction = async () => {
        let arrayCopy = [...itemList];
        arrayCopy.forEach(element => {
            if (element.isNew) {
                element.id = 0
            }
        });
        let request = {
            ActivityTypeStepsEntities: arrayCopy,
            ActivityGroupId: activity?.groupId,
            WorkOrderId: activity?.workOrderId,
            OverwriteConfig: overwriteConfig,
            ActivityItemId: activity.activityItemId
        };
        let res = await updateSteps(request);
        if (res.error) {
            NotificationProvider.error("Failed to update steps");
        } else {
            NotificationProvider.success("Successfully updated steps");
            setOverwriteConfig(false)
            setHasUnsavedChanges(false)
        }
        onRefresh();
    }

    const onRefresh = async () => {
        await refetchSteps();
    }

    return (
        <div className={classes.optionsContainer}>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                {values.map((v, index) => (
                    <SortableItem
                        index={index}
                        option={v}
                        rowIndex={index}
                        onValueChange={onValueChange}
                        onDeleteValue={onDeleteValue}
                        onSelectStep={onSelectStep} />
                ))}
            </SortableContainer>

            <div>
                <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                    <Button className={classes.saveBtn} onClick={onSaveAction} color='primary' variant="outlined" disabled={updatingSteps} >
                        {updatingSteps ? 'Saving...' : 'Save'}
                    </Button>
                    <Tooltip title="Update the Steps to Complete of this Activity for future use.">
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e, value) => setOverwriteConfig(value)}
                                checked={overwriteConfig}
                            />}
                            className={classes.overwriteConfig}
                            label='Overwrite Configuration'
                        />
                    </Tooltip>
                </Authorize>
            </div>
        </div>
    );
}
export default StepsToComplete;



const SortableItem = sortableElement(({ option, rowIndex, onValueChange, onDeleteValue, onSelectStep }) => {
    const classes = StepsToCompleteStyles();
    const DragHandle = sortableHandle(() => <span className={classes.dragContainer}><DragIndicatorIcon className={classes.dragIcon} color="secondary" /></span>);

    return (
        <Grid item container alignItems="center" className={classes.multiValueOpt}>
            <Grid item>
                <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                    <>{option?.isNew ? <div style={{ width: '1.8em' }}></div> : <DragHandle />}</>
                </Authorize>
                {!option?.isNew &&
                    <FormControlLabel
                        control={
                            <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_FIELDS}>
                                <Checkbox
                                    onChange={(e, value) => onSelectStep(rowIndex, value)}
                                    checked={option.isCompleted}
                                    size="small"
                                />
                            </Authorize>
                        }
                        className={classes.checkbox}
                    />
                }
                <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_FIELDS}>
                    <TextField
                        value={option?.description ?? ""}
                        elementType="input"
                        variant="standard"
                        onChange={e => {
                            onValueChange(e.target.value, rowIndex)
                        }}
                        placeholder={option?.isNew ? '+ Add a new step...' : undefined}
                        style={{ width: 600, padding: '2px 0' }}
                        inputProps={{
                            style: { textDecoration: option.isCompleted ? 'line-through' : 'none' },
                        }}
                    />
                </Authorize>
                {!option?.isNew &&
                    <Authorize profile={activityPermissionProfile.WORK_ORDER_PAGE_SAVE}>
                        <IconButton onClick={() => onDeleteValue(rowIndex)}>
                            <HighlightOffOutlinedIcon style={{ width: '0.7em' }} />
                        </IconButton>
                    </Authorize>
                }
            </Grid>
        </Grid>
    )
});

const SortableContainer = sortableContainer(({ children }) => {
    return <Grid container direction="column" >
        {children}
    </Grid>;
});