import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { EditListsStyle } from './EditListsStyle';
import EditDriversList from './EditDriversList';


const EditDrivers = ({ access, open, onClose }) => {

    const classes = EditListsStyle()
    const [driversToEdit, setDriversToEdit] = useState(access?.drivers);


    const onCloseDialog = () => {
        setDriversToEdit(access?.drivers);
        onClose();
    }

    const onSave = async () => {
        onCloseDialog();
    }

    return (
        <>
            <Dialog maxWidth='md' fullWidth open={open} onClose={() => { }} aria-labelledby='dialog-title'>
                <DialogTitle id={'dialog-title'} >
                    Edit Drivers
                </DialogTitle>
                <DialogContent>
                    <EditDriversList access={access} driversToEdit={driversToEdit} setDriversToEdit={setDriversToEdit} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button className={classes.button} variant='contained' color='primary' onClick={onSave} /*disabled={isLoading}*/>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>



        </>
    );
}

export default EditDrivers



