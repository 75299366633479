import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarChart, XAxis, YAxis, Tooltip, Bar, Text, LabelList } from "recharts";
import EmptyPlaceholder from "../../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import { theme } from "../../../../../styles/theme";
import { washDashboardStyles } from "../WashDashboardStyles";
import { washCountCardStyle } from "./WashCountCardStyle";

const WashCountCard = ({ washRequests }) => {

    const classes = washDashboardStyles();
    const { filters } = useSelector(state => state.dashboardState);
    const washesGrouped = {};

    //Hard-coded showing last six months
    washRequests?.filter(req =>
        (moment(req.activityDate, 'YYYY-MM-DD').isBetween(moment().subtract(6, 'months'), moment(), undefined, '[]'))
        && (!filters?.lotId?.length || filters?.lotId?.includes(req.lotId))
        && (!filters?.clientId || filters?.clientId == req.clientId))
        .forEach(req => {
            let displayDate = moment(req.activityDate).format('YYYYMM').toString();
            if (!washesGrouped[displayDate]) {
                washesGrouped[displayDate] = { displayDate: displayDate, count: 1 };
            }
            else {
                washesGrouped[displayDate].count += 1;
            }
        });

    let sortedReqs = Object.values(washesGrouped)?.sort((a, b) => (a?.date - b?.date));

    const formatDate = (date) => {
        return moment(date).format('MMM YY').toString();
    }

    return (
        <div>
            {!!sortedReqs?.length ?
                <BarChart width={400} height={230} data={sortedReqs}>
                    <XAxis
                        axisLine={false}
                        dataKey="displayDate"
                        tickLine={false}
                        tick={{ color: theme.palette.primary.main, fontSize: '0.7em' }}
                        tickFormatter={date => {
                            return formatDate(date).toUpperCase()
                        }
                        } />
                    <Tooltip
                        content={(val) => <GraphTooltip val={val} />}
                    />
                    <Bar
                        dataKey="count"
                        fill={theme.palette.primary.light}
                    >
                        <LabelList dataKey="count" position="insideTop" style={{ fill: theme.palette.feedback.light }} />
                    </Bar>
                </BarChart> :
                <EmptyPlaceholder text='No Washes' />}
        </div>
    )
}

export default WashCountCard;

const GraphTooltip = ({ val }) => {
    const classes = washDashboardStyles();
    return (
        <div className={classes.tooltip}>
            <div>{val.payload?.[0]?.value} completed {val.payload?.[0]?.value > 1 ? 'washes' : 'wash'}</div>
        </div>
    )
}