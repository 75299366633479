import { Grid, Tooltip } from "@material-ui/core";
import { IconDelete } from "icons";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DragIndicatorOutlined } from "@material-ui/icons";
import { ReportSectionStyles } from "../../ReportSectionStyles";
import { elementTypeSetup } from "features/Forms/CustomReport/CustomReportConsts";

export const ReportColumn = ({ elements, columnIndex, sectionIndex, updateElements, questionsUsed }) => {
    const classes = ReportSectionStyles();

    const onDeleteElement = (sectionIndex, columnIndex, elementIndex) => {
        let elementsList = [...elements];
        elementsList.splice(elementIndex, 1);
        updateElements(sectionIndex, columnIndex, elementsList);
    }

    const onEditElement = (sectionIndex, columnIndex, elementIndex, value) => {
        let elementsList = [...elements];
        elementsList[elementIndex] = { ...elementsList[elementIndex], ...value };
        updateElements(sectionIndex, columnIndex, elementsList);
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        updateElements(sectionIndex, columnIndex, arrayMove(elements, oldIndex, newIndex).map((q, index) => ({ ...q, order: index })))
    };

    return (
        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {elements?.map((element, elementIndex) => (
                <SortableItem
                    index={elementIndex}
                    element={element}
                    onEditElement={(val) => onEditElement(sectionIndex, columnIndex, elementIndex, val)}
                    onDeleteElement={() => onDeleteElement(sectionIndex, columnIndex, elementIndex)}
                    questionsUsed={questionsUsed}
                />
            ))}
        </SortableContainer>
    )
}

const SortableItem = sortableElement(({ element,
    onEditElement,
    onDeleteElement,
    questionsUsed
}) => {
    const classes = ReportSectionStyles();

    const DragHandle = sortableHandle(() => <DragIndicatorOutlined className={classes.dragIconElement} color="secondary" />
    );

    return <div>
        <DragHandle />
        <div className={classes.elementContainer}>
            {elementTypeSetup?.[element.elementType]?.component(
                element,
                (val) => onEditElement(val),
                questionsUsed
            )}
            <div className={classes.deleteElement}>
                <Tooltip title={`Delete Element`}>
                    <div className={classes.buttomsContainer} onClick={onDeleteElement}>
                        <IconDelete style={{ width: '1em', height: '1em' }} className={classes.actionsIcons} />
                    </div>
                </Tooltip>
            </div>
        </div>
    </div>
});

const SortableContainer = sortableContainer(({ children }) => {
    const classes = ReportSectionStyles();
    return <Grid container direction="column" spacing={2} className={classes.questionsContainer}>
        {children}
    </Grid>;
});