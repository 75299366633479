import { makeStyles } from '@material-ui/core/styles';

export const searchStyle = makeStyles((theme) => ({
    searchButton: {
        height: '50px',
        width: '40px',
        zIndex: 1,
        margin: '0.5rem 0 0 1rem',
        backgroundColor: '#f3f5f6',
        borderRadius: 4
    },
    searchBar: {
        zIndex: 1,
        backgroundColor: '#f3f5f6',
        borderRadius: 4,
    },
    searchVin: {
        width: 300,
        zIndex: 1,
        padding: '0.5rem 0 0 1rem',
        borderRadius: 4,
    },
    searchMode: {
        zIndex: 1,
        margin: '0.5rem 0 0 1rem',
        backgroundColor: '#f3f5f6',
        borderRadius: 4,
        position: 'absolute',
        height: '3.5rem',
    },
    selectedSearchMode: {
        backgroundColor: '#c4d8f0'
    },
    icon: {
        padding: '0.5rem',
        margin: '0 0.12rem 0 0.12rem',
        backgroundColor: '#eeeeee'
    },
}));
