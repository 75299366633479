import * as React from "react";

function IconSeatRepair(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7462 5.0625C10.4811 5.0625 10.2662 5.2774 10.2662 5.5425V6.705C10.2662 6.9701 10.4811 7.185 10.7462 7.185H13.6337C13.8988 7.185 14.1137 6.9701 14.1137 6.705V5.5425C14.1137 5.2774 13.8988 5.0625 13.6337 5.0625H10.7462ZM9.14124 5.5425C9.14124 4.65608 9.85982 3.9375 10.7462 3.9375H13.6337C14.5202 3.9375 15.2387 4.65608 15.2387 5.5425V6.705C15.2387 6.92401 15.1949 7.13277 15.1154 7.32299C16.0834 7.64859 16.8062 8.51843 16.8062 9.58506V12.7609C16.8434 12.7588 16.881 12.7577 16.9188 12.7577H17.0013C18.0625 12.7658 18.9213 13.6295 18.9213 14.6927V20.3327H5.43628V14.6927C5.43628 13.6245 6.30312 12.7577 7.37128 12.7577H7.45378C7.49658 12.7577 7.53906 12.7591 7.58118 12.7618V9.58506C7.58118 8.52081 8.30073 7.65247 9.26544 7.32517C9.18544 7.13437 9.14124 6.92485 9.14124 6.705V5.5425ZM8.64587 13.1687C9.09805 13.523 9.38878 14.0741 9.38878 14.6927V16.0875C9.46332 16.0825 9.54344 16.0772 9.62792 16.0715C10.4325 16.0178 11.6334 15.9377 12.1863 15.9377C12.7968 15.9377 14.1884 16.0275 14.9838 16.0872V14.6927C14.9838 14.0692 15.2791 13.5144 15.7374 13.1604C15.7014 13.0863 15.6812 13.003 15.6812 12.9151V9.58506C15.6812 8.92 15.0861 8.31006 14.2712 8.31006H10.1162C9.30123 8.31006 8.70618 8.92 8.70618 9.58506V12.9151C8.70618 13.0063 8.68444 13.0925 8.64587 13.1687ZM16.9863 13.8827H16.9188C16.4719 13.8827 16.1088 14.2459 16.1088 14.6927V16.1102C16.1088 16.7479 15.5731 17.2573 14.9287 17.2113L14.9264 17.2111C14.1269 17.1508 12.7537 17.0627 12.1863 17.0627C11.6749 17.0627 10.5167 17.1398 9.6996 17.1943C9.60867 17.2003 9.52196 17.2061 9.44085 17.2115L9.40378 16.6502L9.44386 17.2113C8.79951 17.2573 8.26378 16.7479 8.26378 16.1102V14.6927C8.26378 14.2459 7.90062 13.8827 7.45378 13.8827H7.37128C6.92444 13.8827 6.56128 14.2459 6.56128 14.6927V19.2077H17.7963V14.6927C17.7963 14.2459 17.4331 13.8827 16.9863 13.8827Z" fill="#0090FE" />
        </svg>

    );
}

export default IconSeatRepair;
