import { makeStyles } from '@material-ui/core/styles';

export const pageStyles = makeStyles((theme) => ({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 100px)',
        marginBottom: '10px'
    },
    pageContainerHalf: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc((100vh - 100px)/2)'
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        minHeight: '50vh'
    },
    header: {
        marginBottom: '1em',
    }
}));
