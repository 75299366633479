import { Button, Chip, Popover, Popper, Tooltip } from "@material-ui/core";
import { StorageInvoiceStatus, StorageInvoiceStatuses } from "../../storageInvoiceConsts";
import { storageInvoiceStatusStyles } from "./style";
import FormatService from "services/formatService";


const StorageInvoiceStatusDisplay = ({ invoice }) => {
    const classes = storageInvoiceStatusStyles();
    const status = invoice?.status;
    return (<>
        {!!StorageInvoiceStatuses[status]?.warningComponent && <div className={classes.warningComponent}>{StorageInvoiceStatuses[status]?.warningComponent}</div>}
        {!!status && <Tooltip title={status == StorageInvoiceStatus.PENDING ? '' : `Approved by ${invoice?.approvedBy}, on ${FormatService.formatDateTime(invoice?.approvedDate)}`}><Chip
            icon={StorageInvoiceStatuses[status]?.icon}
            label={StorageInvoiceStatuses[status]?.name}
            className={classes.invoiceStatusWrapper} style={{ backgroundColor: StorageInvoiceStatuses[status]?.color }}
        /></Tooltip>}
    </>
    )
}

export default StorageInvoiceStatusDisplay;