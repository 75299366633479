import { Button, Grid, Paper } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setBulkEdit,
    useRemoveLotFromClientMutation,
    useRemoveSubMarketFromClientMutation
} from "../lotConfigSlice";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataSearchBar from "components/DataSearchBar";
import ContractHeader from "features/clients/contracts/ContractHeader/ContractHeader";
import configStyles from "../../configStyles";
import LotsConfigStyle from "../LotsConfigStyle";
import LotButton from "./LotButton";
import { ActiveLotsList } from "./ActiveLotsList";

const useStyles = LotsConfigStyle;
const useConfigStyles = configStyles;

export const clientLotConfigModel = {
    pricePerSpot: 0,
    overage: 0,
    numberOfSpots: 0,
    clientAlias: ''
}


function Lots({ clientToEdit }) {
    let [removeClientLot, { isLoading: removingLot }] = useRemoveLotFromClientMutation()
    let [removeClientSubMarket, { isLoading: removingSubMarket }] = useRemoveSubMarketFromClientMutation()
    const lotConfigParams = clientToEdit?.config?.['lotConfig'];

    const [search, setSearch] = useState();
    const [selectedClientLots, setSelectedClientLots] = useState(clientToEdit?.clientLots);
    const [selectedClientSubMarkets, setSelectedClientSubMarkets] = useState(clientToEdit?.clientSubmarkets);

    const configClasses = useConfigStyles();

    const remove = async (clientLot) => {
        let res = true;

        if (clientLot?.id) {
            if (clientLot?.isSubMarket) {
                res = await removeClientSubMarket(clientLot);
            } else {
                res = await removeClientLot(clientLot);
            }
        } else {
            setSelectedClientLots([...selectedClientLots.filter(l => (clientLot?.isNew && !l?.isNew) || (l.id != clientLot?.id))])
        }
        return res;
    }

    useEffect(() => {
        setSelectedClientLots(clientToEdit?.clientLots);
        setSelectedClientSubMarkets(clientToEdit?.clientSubmarkets);
    }, [clientToEdit?.clientLots, clientToEdit?.clientSubmarkets]);

    return (<div style={{ minHeight: '40rem' }} className={configClasses.card}>
        <Grid container spacing={1} alignItems='center' justifyContent="space-between">
            <Grid item xs={7}>
                <DataSearchBar onSearch={(val) => setSearch(val)} searchVal={search} />
            </Grid>
            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_EDIT}>
                <Grid spacing={1} item xs={5} container justifyContent="flex-end">
                    <Actions
                        hasNew={selectedClientLots?.find(a => a.isNew)}
                        onAdd={() => {
                            setSelectedClientLots([
                                ...selectedClientLots,
                                {
                                    clientId: clientToEdit.id,
                                    clientAccountId: clientToEdit?.accountId,
                                    ...clientLotConfigModel,
                                    isNew: true
                                }])
                        }}
                    />
                </Grid>
            </Authorize>
        </Grid>
        <div>
            <ActiveLotsList
                search={search}
                activeClientSubmarkets={selectedClientSubMarkets}
                activeClientLots={selectedClientLots}
                onRemove={remove}
                invoiceCycleType={lotConfigParams?.invoiceCycleType}
            />
        </div>
    </div>)
}

export default Lots;


function Actions({ onAdd, hasNew }) {
    const { bulkEdit } = useSelector(state => state.lotConfigState);
    const classes = useStyles();
    const dispatch = useDispatch();
    return (
        <>
            <LotButton lot={{}} icon={<AddIcon />} label={'Create Lot'} disabled={hasNew} />

            <Button
                id="basic-button"
                onClick={() => {
                    dispatch(setBulkEdit(!bulkEdit));
                }}
                startIcon={<ReorderOutlinedIcon />}
                disabled={hasNew}
            >
                {'Bulk Edit'}
            </Button>

            <Button
                id="basic-button"
                onClick={() => onAdd()}
                startIcon={<AddIcon />}
                disabled={hasNew}
            >
                {'Add'}
            </Button>


        </>
    );
}