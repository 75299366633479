import {Grid, Link} from "@material-ui/core";
import React from "react";
import Authorize from "../../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../../components/Authorize/permissionProfiles";
import FormatService from "../../../../../../services/formatService";
import { TaskItemStyles } from "../../TaskItemStyles";
import {NoteEntityTypes} from "../../../../../notes/notesConsts";
import NotesTooltip from "../../../../../notes/NotesTooltip";

const WashTask = ({ task }) => {
    const classes = TaskItemStyles();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }
    const notes = (entityId) => {
        return <Grid container direction="row">
            <Grid xs={4}><NotesTooltip entityType={ NoteEntityTypes.ACTIVITY} entityId={entityId} /></Grid>
            <Grid xs={8}></Grid>            
        </Grid>;
    }
    const fieldWithLink = (label, value, vehicleId) => {
        return (
            <Grid container direction="row">
                <Grid xs={4}> <label className={classes.label}>{label}</label> </Grid>
                <Grid xs={8}>
                    <Link style={{ color: "#46535d", paddingLeft: 3 }} href={`/assets/detail/${vehicleId}`} target="_blank">{value}</Link>
                </Grid>
            </Grid>
        );
    };
    
    return (
        <Grid container direction='column'>
            {task?.client && <Authorize profile={permissionProfiles.TASKS.TASK_VIEW_CLIENT}>
                {field('Client', task?.client?.name)}
            </Authorize>}
            {fieldWithLink('VIN', task?.entity?.descriptor,task?.entity?.vehicleId)}
            {task?.entity?.assetId && fieldWithLink('Asset ID', task?.entity?.assetId,task?.entity?.vehicleId)}
            {task?.entity?.licensePlate && fieldWithLink('License', task?.entity?.licensePlate,task?.entity?.vehicleId)}
            {task?.lot && field('Lot', task?.lot?.name)}
            {field('Requested', FormatService.formatDate(task?.activityDate))}
            {notes(task?.id)}
            {/* {task?.requestedCompletionDate && field('Requested Completion', FormatService.formatDate(task?.requestedCompletionDate))} */}
        </Grid>
    )
}
export default WashTask;