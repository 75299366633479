import * as React from "react";

function SvgIconParkingLot({ width = 24, height = 24 }) {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.95 6.5c1.738 0 3.151 1.385 3.151 3.088 0 1.702-1.413 3.087-3.15 3.087h-2.1v3.05c0 .59-.47 1.067-1.05 1.067-.58 0-1.051-.478-1.051-1.067v-8.29c0-.516.412-.935.921-.935h3.28zm-2.1 2.058h2.1c.58 0 1.05.462 1.05 1.03 0 .567-.47 1.029-1.05 1.029h-2.1V8.558z" fill="#0090FE" />
            <path d="M13 24.282a.81.81 0 0 1-.58-.241l-6.31-6.31a9.75 9.75 0 1 1 13.78 0l-6.31 6.31a.81.81 0 0 1-.58.24zm0-21.548A8.107 8.107 0 0 0 7.27 16.57L13 22.299l5.73-5.73A8.107 8.107 0 0 0 13 2.735z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconParkingLot;
