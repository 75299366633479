import ExportButton from "components/ExportButton/ExportButton";
import { secondLevelData, storageDetailsColumns } from "features/invoice/storage/storageInvoiceConsts";
import { billingPrograms } from "../consts";

const StorageExportData = ({ data, transformData }) => {
    //TEMP move to API


    const mapExportData = row => {
        const detailsInfo = {};
        detailsInfo['Lot'] = transformData['lotId']?.(row.lotId) ?? row.lotId;
        detailsInfo['Client'] = transformData['clientId']?.(row.clientId) ?? row.clientId;
        detailsInfo['Date'] = row.formattedDate;
        storageDetailsColumns?.map(col => {
            detailsInfo[col.label] = col.value ? col.value(row?.[col.key]) : row?.[col.key]
        });
        secondLevelData?.map(col => {
            detailsInfo[col.label] = col.value ? col.value(row?.[col.key]) : row?.[col.key]
        });

        return detailsInfo;
    };

    const programData = data?.filter(row => row.programType == billingPrograms.STORAGE).map(row => ({ ...row.storageInvoiceEntity, ...row })) ?? [];

    return <>
        <ExportButton mapExportData={mapExportData} data={programData} label="Export Storage Program Data" />
    </>
}
export default StorageExportData;