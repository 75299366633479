import CustomInput from 'components/CustomInput';
import { AccessCodeTypes, AccessGroupTypes, AccessGroupTypesList } from 'features/access/accessConsts';
import { StepsStyles } from './StepsStyles';
import { FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from 'components/DateRange';
import { useState } from 'react';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import Authorize from 'components/Authorize';
import { TimeField } from 'components/TimeField/TimeField';
import { DefaultRangesTypes } from 'components/DateRange/DefaultRanges';
import DriverList from './DriverList/DriverList';
import VehicleList from './VehicleList/VehicleList';
import moment from 'moment';
import AccessCodeParameters from 'features/access/GenerateAccessCode/AccessCodeParameters';

const DefiningAccessStep = ({ }) => {
    const classes = StepsStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isOneTime, setIsOneTime] = useState(false);
    const [isAllDay, setIsAllDay] = useState(false);
    const accessCode = accessToEdit?.accessCodes?.[0] ?? {}

    const updateAccess = (access) => {
        dispatch(setAccessToEdit(access));
    }

    return (
        <Grid container direction='column' spacing={2}>
            <AccessCodeParameters
                accessToEdit={accessToEdit}
                setAccessToEdit={updateAccess}
            />
            {(accessToEdit?.groupType === AccessGroupTypes.CARRIER || accessToEdit?.groupType === AccessGroupTypes.CLIENT) &&
                <Grid className={classes.driversWrapper}>
                    <VehicleList />
                </Grid>}
            {accessToEdit?.groupType === AccessGroupTypes.CARRIER &&
                <Grid className={classes.driversWrapper}>
                    <DriverList />
                </Grid>}
        </Grid>
    );
}

export default DefiningAccessStep;