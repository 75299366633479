import * as React from "react";

function SvgIconOil(props, width, height) {
    return (
        <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 0.166016C4.00437 6.13789 0.5 11.296 0.5 15.6535C0.5 22.1898 5.4875 26.416 11 26.416C16.5125 26.416 21.5 22.1898 21.5 15.6535C21.5 11.296 17.9956 6.13789 11 0.166016ZM11 23.791C6.60313 23.791 3.125 20.4179 3.125 15.6535C3.125 12.5823 5.68437 8.51351 11 3.65727C16.3156 8.51351 18.875 12.5691 18.875 15.6535C18.875 20.4179 15.3969 23.791 11 23.791ZM5.52688 15.916C6.0125 15.916 6.40625 16.2573 6.49812 16.7298C7.03625 19.6435 9.49063 20.641 11.2756 20.4966C11.84 20.4704 12.3125 20.9166 12.3125 21.481C12.3125 22.006 11.8925 22.4391 11.3675 22.4654C8.57187 22.636 5.30375 21.0348 4.55562 17.0579C4.53282 16.9172 4.54084 16.7732 4.57915 16.6359C4.61746 16.4986 4.68513 16.3712 4.7775 16.2626C4.86986 16.154 4.9847 16.0668 5.11409 16.0069C5.24348 15.9471 5.38432 15.9161 5.52688 15.916Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconOil;
