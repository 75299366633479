import { baseApi } from '../../app/baseApi';
import { createSlice } from "@reduxjs/toolkit";

export const mechanicalTaskApi = baseApi.injectEndpoints({
    reducerPath: 'mechanicalTaskApi',
    endpoints: (builder) => ({
        getMechanicalTasks: builder.query({
            query: (body) => ({
                url: 'mechanicalTask'
            }),
            providesTags: ["MechanicalTasks"],
            transformResponse: (response) => {
                return response;
            }
        }),
    })

});


export const { useGetMechanicalTasksQuery } = mechanicalTaskApi;

export const mechanicalTaskSlice = createSlice({
    name: 'machanicalTaskSlice',
    initialState: {
        searchVal: '',
        taskPanelOpen: false,
        filters: {
            'lotId': { value: [] },
            'activityItemId': { value: [] }
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setTaskPanelOpen: (state, action) => {
            state.taskPanelOpen = action.payload;
        }
    },
});

export const { setSearchVal, setTaskPanelOpen,  setFilters } = mechanicalTaskSlice.actions;
