import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { NoteContent } from "features/notes/NoteEditor";
import { useState } from "react";

const EnterCommentDialog = ({ open, onCancel, onSave }) => {

    const [note, setNote] = useState({ content: '' });

    const handleSave = async () => {
        await onSave(note);
        setNote({ content: '' });
    }

    const handleNoteChange = (field, value) => {
        setNote({ [field]: value });
    }

    return (
        <Dialog open={open} maxWidth='sm'>
            <DialogTitle>
                Please enter a comment before you save
            </DialogTitle>
            <DialogContent>
                <NoteContent content={note.content} onChange={handleNoteChange} hideViewByClient={true} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!note.content}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EnterCommentDialog;