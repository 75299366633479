import { DialogContent, IconButton } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import { Dialog } from "@mui/material";
import { useState } from "react";
import ActivityListTable from "./ActivityListTable";

const ActivityListDialog = ({ status, clientId, lotId, additionalFilters }) => {

    const [showList, setShowList] = useState(false);

    return (
        <>
            <IconButton size="small" onClick={() => setShowList(true)}><ListIcon /></IconButton>
            <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                <DialogContent>
                    <ActivityListTable status={status} dialog={true} clientId={clientId} lotId={lotId} additionalFilters={additionalFilters} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ActivityListDialog;