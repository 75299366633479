import FormatService from "services/formatService";
import { IssueTrackerDashboardStyle } from "../style";

export const AvgTimeToFirstResponseWidget = ({ dashboardData }) => {
    const classes = IssueTrackerDashboardStyle();
    return (<>
        <div className={classes.cardCount}>{FormatService.formatDuration(dashboardData?.averageTimeToFirstResponse)}</div>
        <div className={classes.cardText}>Avg Time to First Response</div>
    </>
    )
}