import { makeStyles } from "@material-ui/core"

export const postLoadStyle = makeStyles((theme) => ({
   error: {
      color: 'red'
   },
   dispatchButton: {
      marginTop: '1em'
   },
   select: {
      width: '100%'
   },
   btnWrapper: {
      display: 'flex',
      '& button': {
         marginLeft: 'auto'
      }
   }
}));