import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useApprovePendingInvoiceMutation } from "features/activity/activitySlice";
import { useEffect, useState } from "react";
import { approveActivityStyle } from "./style";
import { arrayToObject } from "utils/object-util";
import usePrompt from "hooks/usePrompt";
import { activityStatuses } from "features/activity/activityConsts";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

const ApprovePendingInvoiceActivity = ({
    selectedRows, //Injected by the bulk actions
    activityLogs,
    bulk,
}) => {

    const classes = approveActivityStyle();
    const [approveActivity, { isLoading }] = useApprovePendingInvoiceMutation();
    const [cost, setCost] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(false);
    const { triggerPrompt } = usePrompt();

    const activityLookup = arrayToObject(activityLogs);
    const pendingInvoiceSelected = selectedRows?.filter(r => activityLookup?.[r]?.activityStatus == activityStatuses.PendingInvoice)
    useEffect(() => {
        if (pendingInvoiceSelected?.length === 1) {
            const activityLog = activityLookup?.[pendingInvoiceSelected?.[0]]
            setCost(activityLog?.price ?? 0);
            setRevenue(activityLog?.revenue ?? 0);
        } else {
            setCost(0);
            setRevenue(0);
        }
    }, [selectedRows]);

    const onSave = async () => {
        if (!bulk && (!cost || !revenue)) {
            setShowError(true);
            return;
        }
        triggerPrompt({
            title: "Approve",
            content: <div>{`Are you sure you want to approve ${pendingInvoiceSelected?.length ?? 0} activities?`}</div>,
            onConfirm: async () => {
                const res = await approveActivity({
                    activityLogIds: pendingInvoiceSelected,// activityLogs.map(a => a.id),
                    price: !bulk ? cost : null,
                    revenue: !bulk ? revenue : null
                });

                if (res?.data?.error) {
                    NotificationProvider.error(`An error occured while approving the activity, please try again.`);
                    return;
                } else {
                    NotificationProvider.success(!bulk ? 'Successfully approved the activity and updated the price' : 'Successfully approved the activities');
                    if (!!bulk) {
                        //setSelected([]);
                    }
                    else
                        setOpen(false);
                }
            },
        });
        setShowError(false);
    };


    return (
        <>
            {<Authorize profile={permissionProfiles.ACTIVITY.PENDING_INVOICE_ADD_PRICE}>
                <Tooltip title={bulk ? `${pendingInvoiceSelected?.length ?? 0} out of ${selectedRows?.length ?? 0} selected row(s) are in status Pending Invoice` : ''}>
                    <div className={bulk && classes.btn}>
                        <Button startIcon={bulk ? undefined : <CheckBoxOutlinedIcon style={{ fontSize: '1.2em' }} />} variant={bulk ? "text" : undefined} disabled={!pendingInvoiceSelected?.length || isLoading} onClick={bulk ? () => onSave() : () => setOpen(true)} color={bulk ? 'primary' : undefined}>
                            {bulk ? `Approve Pending Invoice (${pendingInvoiceSelected?.length})` : 'Approve'}
                        </Button>
                    </div>
                </Tooltip>
            </Authorize>}
            <Dialog open={open && !bulk} onClose={() => setOpen(false)} >
                <DialogTitle id={'dialog-title'} >Approve Activity</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <CustomInput
                                id="cost"
                                label="Internal Cost"
                                value={cost}
                                elementType="input"
                                required={true}
                                onChange={setCost}
                                valid={cost || !showError}
                                numberOnly
                                touched
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="cost"
                                label="Charge"
                                value={revenue}
                                elementType="input"
                                required={true}
                                onChange={setRevenue}
                                valid={revenue || !showError}
                                numberOnly
                                touched
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={onSave} color='primary' variant="contained" disabled={isLoading}>
                        {isLoading ? 'Saving...' : 'Approve activity'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ApprovePendingInvoiceActivity;