import { makeStyles } from "@material-ui/core";

export const InventoryStyles = makeStyles((theme) => ({
    inventoryContainer: {
        margin: '1rem 1rem'
    },
    addItemBtn: {
        marginLeft: '3.5rem',
    },
    saveBtn: {
        width: '30%',
        height: '50%'
    },
    saveContainer: {
        display: 'flex',
        width: '100%',
        margin: '0 0.5rem',
        marginBottom: '0.5rem',
        alignContent: 'center'
    },
    overwriteConfig: {
        marginLeft: '1rem'
    }
}));