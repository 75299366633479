import { makeStyles } from "@material-ui/core";

export const LotSubMarketStyle = makeStyles((theme) => ({
    leftIcon: {
        marginTop: 5,
        marginRight: 10,
    },
    header: {
        padding: '16px',
        margin: "0 1rem 1rem 0",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        margin: 10,
        border: 0
    },
    headerMobile: {
        marginRight: 0
    },
    lotMobile: {
        maxWidth: "100%",
        flexBasis: "100%"
    },
    card: {
        margin: "0 1rem 1rem 0",
        padding: '1rem'
    },
    title: {
        margin: 0,
        padding: 0
    },
    showLots: {
        marginLeft: "-0.9rem",
        color: theme.palette.primary.main
    },
    lotsList: {
        padding: "0.5rem 1rem 0.5rem 1rem"
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    }
}));