import { makeStyles } from "@material-ui/core";

export const CreateWorkOrderStyles = makeStyles((theme) => ({
    card: {
        padding: '1em 2em',
        height: '100%',
        boxShadow: '2px 3px 10px #d7d6d6',
    },
    summaryCard: {
        padding: '1em 2em',
        height: '100%',
        boxShadow: '2px 3px 10px #d7d6d6',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    titleIcon: {
        margin: '0.5rem'
    },
    title: {
        fontWeight: 600,
        fontSize: '1.2rem'
    },
    sectionTitle: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '1.1rem',
        marginBottom: '0.5rem'
    },
    summaryItem: {
        padding: '0.5rem 0 0.5rem 1rem',
        minHeight: '45px',
    },
    summaryItemBorder: {
        padding: '0.5rem 0 0.5rem 1rem',
        minHeight: '45px',
        border: '1px solid lightGrey',
        borderRadius: '4px',
    },
    autoComplete: {
        margin: '0.5rem 0 ',
        padding: '0.5rem 0 0.5rem 0.5rem',
        color: theme.palette.common.darkText,
        border: '1px solid lightGrey',
        borderRadius: '4px',

        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: '0 !important',
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    itemLabel: {
        fontWeight: 600,
    },
    itemIcon: {
        paddingRight: '1em',
    },
    mapWrapper: {
        height: '100%',
        minHeight: '300px'
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    actionButton: {
        margin: '0.5rem',
        minWidth: '150px'
    },
    activityLabel: {
        marginLeft: '0.5rem',
        fontWeight: 500
    },
    searchIcon: {
        rotate: '180deg'
    },
    filter: {
        marginRight: '0.5rem'
    },
    filterIconWhite: {
        '& path': {
            stroke: theme.palette.primary.main,
        },
        '& svg': {
            stroke: theme.palette.primary.main,
        }
    },
    alertWrapper: {
        margin: '0.1rem 0 0.5rem'
    },
    otherActivity: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid lightGrey',
        borderRadius: '4px',
    },
    activityPricingVin: {
       width: '200px'
    },
    activityPricing: {
        fontWeight: 450,
        marginTop: '0.5rem'
    }
}));