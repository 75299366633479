import { Button, Grid, IconButton, ListItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from "react";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../components/CustomInput";
import { SubMarketDialogStyle } from "./SubMarketDialogStyle";
import { getLotsWithPmfText } from "../../../../features/lots/lotUtil";

const SubMarketDialog = ({ subMarket, lots, onSave, onCancel, saving }) => {
    const classes = SubMarketDialogStyle();

    const [subMarketToEdit, setSubMarketToEdit] = useState(subMarket);
    const [subMarketLots, setSubMarketLots] = useState(subMarket?.lotIds || []);

    useEffect(() => {
        setSubMarketToEdit(subMarket);
        setSubMarketLots(subMarket?.lotIds || [])
    }, [subMarket]);

    const lotsToDisplay = (currentLotId) => {
        let allLots = [...lots];
        subMarketLots.forEach(lot => {
            if (lot != currentLotId)
                allLots = allLots.filter(l => l.id != parseInt(lot));
        });
        return getLotsWithPmfText(lots);
    }

    const onDeleteLot = (lotId) => {
        let lots = [...subMarketLots];
        lots = lots.filter(l => l != lotId);
        setSubMarketLots(lots);
        setSubMarketToEdit({ ...subMarketToEdit, lotIds: lots });
    }

    const onAddNewLot = () => {
        addLotToFilteredSubMarkets(subMarketLots, subMarketLots.length, 0);
    }

    const addLotToFilteredSubMarkets = (filteredLots, index, lotId) => {
        let lots = [...filteredLots];
        lots[index] = lotId;
        setSubMarketLots(lots);
        setSubMarketToEdit({ ...subMarketToEdit, lotIds: lots });
    }

    const onChangeLot = (currentLotId, lotId) => {
        const index = subMarketLots.findIndex(l => l == currentLotId);
        if (index != null) {
            addLotToFilteredSubMarkets(subMarketLots, index, lotId);
        }
    }

    const handleSave = async () => {
        await onSave({ ...subMarketToEdit, lotIds: subMarketLots });
    };

    const onChange = (field, val) => {
        setSubMarketToEdit({ ...subMarketToEdit, [field]: val });
    };

    return (
        <Dialog open={!!subMarketToEdit} onClose={onCancel} aria-labelledby="form-dialog-title" maxWidth='sm'>
            <DialogTitle id="form-dialog-title">{subMarketToEdit?.id ? 'Sub Market Information' : 'Create Sub Market'}</DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid xs={8} className={classes.groupTitle}>
                        <CustomInput
                            label="name"
                            elementType="text"
                            value={subMarketToEdit?.name}
                            required={true}
                            onChange={val => onChange("name", val)}
                        />
                    </Grid>
                    {subMarketLots &&
                        <Grid item>
                            <div className={classes.lotListWrapper} >
                                {
                                    subMarketLots?.map((item, index) => {
                                        return <div key={index}>
                                            <ListItem>
                                                <CustomInput
                                                    key={index}
                                                    label="Select lot"
                                                    elementType="dropdown"
                                                    values={lotsToDisplay(item)}
                                                    value={item}
                                                    showEmpty={true}
                                                    onChange={val => onChangeLot(item, val)}
                                                />
                                                <IconButton edge="end" aria-label="delete"
                                                    onClick={() => onDeleteLot(item)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItem>
                                        </div>
                                    })
                                }
                            </div>
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            className={classes.addButton}
                            variant="outlined"
                            color="primary"
                            onClick={onAddNewLot}
                        >
                            Add lot to market
                        </Button>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                        disabled={saving}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Authorize>
            </DialogActions>
        </Dialog>
    );
};


export default SubMarketDialog;
