import { Grid } from "@material-ui/core"
import { activityTableStyle } from "./style"
import ActivityStatusAliasView from "features/activityStatusAlias/ActivityStatusAliasView";

export const ActivityStatus = ({ activityStatus, clientId }) => {
    const classes = activityTableStyle();
    return (
        <Grid direction='row' container alignItems='center' spacing={1}>
            <Grid item ><div className={`${classes.dot} ${classes?.[`activityTableCount${activityStatus?.activityStatus}`]}`}></div></Grid>
            <Grid item xs={10}><ActivityStatusAliasView activityIds={[activityStatus.activityStatus]} clientId={clientId} readonly={true} /></Grid>
        </Grid>
    )
}