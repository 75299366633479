import * as React from "react";

function SvgIconRedFlag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M4.29175 18.4584C4.2616 18.4584 4.23032 18.4461 4.20468 18.4205C4.17903 18.3948 4.16675 18.3636 4.16675 18.3334V1.66675C4.16675 1.6366 4.17903 1.60532 4.20468 1.57968C4.23032 1.55403 4.2616 1.54175 4.29175 1.54175C4.3219 1.54175 4.35318 1.55403 4.37882 1.57968C4.40446 1.60532 4.41675 1.6366 4.41675 1.66675V18.3334C4.41675 18.3636 4.40446 18.3948 4.37882 18.4205C4.35318 18.4461 4.3219 18.4584 4.29175 18.4584Z" fill="#FF5A4F" stroke="#FF5A4F" />
      <path d="M12.2841 3.40303L12.2841 3.40303L12.2853 3.40597C12.3801 3.63284 12.3223 4.08385 11.6598 4.74637L10.8598 5.54637C10.5464 5.85974 10.3687 6.29222 10.387 6.73712C10.3979 7.13326 10.5617 7.50841 10.8581 7.77174L11.6581 8.57174C11.6584 8.57203 11.6586 8.57232 11.6589 8.5726C12.3051 9.22547 12.3614 9.6705 12.2653 9.90053C12.1674 10.135 11.8058 10.4133 10.9 10.4133H3.93335H3.43335V2.90659H3.93335H10.9C11.833 2.90659 12.1948 3.18547 12.2841 3.40303Z" fill="#FF5A4F" stroke="#FF5A4F" />
      
    </svg>
  );
}

export default SvgIconRedFlag;
