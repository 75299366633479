import HttpService from './httpService';
import { usStates } from './usStates';

const VehicleService = {
    create: async (data) => {
        return HttpService.post('api/v1/vehicle', data);
    },
    saveAssetByFile: async (data) => {
        return HttpService.post(
            'api/v1/vehicle/addAssetByFileUpload',data
        );
    },
    getUsStates: () => {
        return usStates;
    }

};

export default VehicleService;