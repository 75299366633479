import { disabledContractStyle } from "./DisabledContractStyle";


const DisabledContract = ({ contractName }) => {

    const classes = disabledContractStyle();

    return (
        <div className={classes.wrapper}>
            {`The ${contractName} contract is not active`}.
            <br />Please contact your account manager to activate it
        </div>
    )
}
export default DisabledContract;