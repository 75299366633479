import { useGetLotsQuery } from "features/lots/lotSlice";
import { vehicleLotAuditStyle } from "../../VehicleLotAuditStyle";
import { useGetAuditsByUserQuery, useGetHistoryQuery } from "../../lotAuditSlice";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Checkbox, IconButton, Grid, Button } from "@material-ui/core";
import FormatService from "services/formatService";
import { useDispatch, useSelector } from "react-redux";
import useSortOptions from "hooks/useSortOptions";
import FilterService from "services/filterService";
import PageComponent from "components/Page/PageComponent";
import DataListHeader from "components/DataListHeader";
import { ArrowBack } from "@material-ui/icons";
import ExportButton from "components/ExportButton/ExportButton";
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from "components/LoadingSpinner";
import { orderBy } from "lodash";
import { auditStatusesById, statuses } from "../../auditConsts";
import { setAuditFilters, setSearchVal } from "../../auditFiltersSlice";
import AssetResolveDuplicate from "../AssetResolveDuplicate";
import AssetBulkUpdate from "../AssetBulkUpdate";
import { useBulkUpdateMutation } from "features/vehicles/vehicleSlice";
import NotificationProvider from "components/NotificationProvider";
import usePrompt from "hooks/usePrompt";
import EditAssetPopup from "features/vehicles/EditAssetPopup/EditAssetPopup";
import BasicModal from "components/BasicModal";
import { arrayToObject } from "utils/object-util";
import VehicleStatusView from "features/vehicles/VehicleStatus/VehicleStatusView";

const AuditTable = ({ params, auditResult, isLoadingLotAudit }) => {
    const classes = vehicleLotAuditStyle()
    const history = useHistory();

    let id = params?.id; //this is the auditId when called from the VehicleLotAudit and is the lot_audit_history id when called from the LotAuditHistoryList
    let auditId = params?.auditId;

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];
    let { data: audits, error: auditsError, isLoading: isAuditsingLots } = useGetAuditsByUserQuery(null);
    audits = audits?.data || [];
    let { data: lotAuditHistory, error: lotAuditHistoryError, isLoading: isLoadingAuditHistory } = useGetHistoryQuery({ auditId: auditId }, { skip: !auditId });
    lotAuditHistory = lotAuditHistory?.data || [];
    let [updateVehicle, { data: bulkUpdateResult, error: bulkError, isLoading: bulkLoading }] = useBulkUpdateMutation();
    const { triggerPrompt } = usePrompt();

    const lotLookup = arrayToObject(lots);

    const formatDates = (date, lotId) => {
        return FormatService.formatDateNoConversion(lotLookup?.[lotId]?.ianaTimeZone ? FormatService.formatDateByTimeZone(date, lotLookup?.[lotId]?.ianaTimeZone) : date)
    }

    const formatAsset = (asset, index) => {
        return (
            {
                ...asset,
                lotName: lotLookup?.[asset.lotId]?.name,
                statusName: statuses[asset.status]?.label,
                dateInFormatted: formatDates(asset.dateIn, asset.lotId),
                dateOutFormatted: formatDates(asset.dateOut, asset.lotId),
                vehicles: asset.vehicles?.map(a => formatAsset(a)),
                index,
            }
        )
    }

    const processedData = auditResult?.map((a, index) => formatAsset(a, index))

    // const auditResultMap = processedData?.reduce(function (map, obj) {
    //     map[obj.vehicleId] = obj;
    //     return map;
    // }, {});

    useEffect(() => {
        let lotId = !auditId ? lots?.find(l => l.id == audits?.find(a => a.id == id)?.lotId)?.id
            : lots?.find(l => l.id == audits?.find(a => a.id == auditId)?.lotId)?.id
        setSelectedLot(lotId);
    }, [lots])

    const [selectedLot, setSelectedLot] = useState(lots?.[0]?.id);
    const [selected, setSelected] = useState([]);
    const [assetToAdd, setAssetToAdd] = useState();

    const onSelectRow = (id) => {
        let newValue = [...selected];
        if (selected.includes(id)) {
            newValue?.splice(selected.indexOf(id), 1);
        } else {
            newValue.push(id);
        }
        setSelected(newValue)
    }

    const mapExportData = v => ({
        VIN: v.vin,
        AssetId: v.assetId,
        Status: statuses[v.status]?.label,
        ['Asset status']: v.assetStatus,
        Lot: lots?.find(l => l.id == v.lotId)?.name,
        ['Last In']: v.dateInFormatted,
        ['Last Out']: v.dateOutFormatted
    });

    let columns = [
        { name: "VIN", sortKey: 'vin', key: "vin", width: 200, selectedByDefault: true, component: (row) => <Link disabled={!row?.vehicleId} to={`/assets/detail/${row?.vehicleId}`} target="_blank">{row?.vin}</Link> },
        { name: "Asset ID", key: "assetId", selectedByDefault: true },
        { name: "Status", key: "status", selectedByDefault: true, component: (row) => <div className={classes.iconContainer}><div>{statuses[row?.status]?.icon}</div>{statuses[row?.status]?.label}</div> },
        { name: "Explanation", key: "status", width: 400, selectedByDefault: true, component: (row) => <div>{statuses[row?.status]?.explanation}</div> },
        { 
            name: "Asset Status", key: "assetStatusId", selectedByDefault: true,
            component: (row) => {
                return row.assetStatusId ? <VehicleStatusView value={row.assetStatusId} clientId={row?.clientId}/> : <>{row.assetStatus}</>
            }
        },
        { name: "Lot", key: "lotId", selectedByDefault: true, component: (row) => <>{row?.lotId && lots.find(l => l.id == row?.lotId)?.name}</> },
        { name: "Last In", key: "dateIn", selectedByDefault: true, component: (row) => <>{FormatService.formatDate(row?.dateIn)}</> },
        { name: "Last Out", key: "dateOut", selectedByDefault: true, component: (row) => <>{FormatService.formatDate(row?.dateOut)}</> }
    ];

    !auditId && columns.unshift({ name: "", key: "vehicleId", width: 50, selectedByDefault: true, component: (row) => <Checkbox checked={selected.includes(row?.vehicleId)} onChange={(e) => { onSelectRow(row?.vehicleId) }} /> })

    const auditResultFilterConfig = [
        { type: 'text', size: 2.5, title: 'Status', key: 'status', values: Object.keys(statuses).map(key => ({ id: key, name: statuses[key]?.label })) },
    ];

    const dispatch = useDispatch();
    const { filters, searchVal } = useSelector(state => state.auditFilters);

    const onDropFilter = (filterValue, prop) => {
        dispatch(setAuditFilters({ ...filters, ...{ [prop]: { value: filterValue } } }));
    };

    const onSearch = value => {
        dispatch(setSearchVal(value.trim()));
    }

    const goBack = () => {
        !auditId ?
            history.push('/assets/audit') :
            history.push(`/assets/audit/${auditId}/history`);
    };

    const [sortOptions, sortData] = useSortOptions();

    let filteredResults = FilterService.filter(filters, searchVal, processedData ?? [], auditResultFilterConfig);

    const onUpdateStatus = async (updateToAvailable, vehicleId, text) => {
        triggerPrompt({
            title: 'Update Asset',
            content: text,
            onConfirm: async () => {
                const result = await updateVehicle(
                    {
                        lotId: selectedLot,
                        updateToAvailable: updateToAvailable,
                        updateToUnAvailable: !updateToAvailable,
                        vehicleIds: [vehicleId]
                    }
                );
                if (result.error) {
                    NotificationProvider.error("Failed to update asset");
                } else {
                    NotificationProvider.success("Successfully updated the asset");
                }
            }
        })
    }

    const onCheckInAsset = async (vehicleStatusId, descriptor, text) => {
        triggerPrompt({
            title: 'Check In Asset',
            content: text,
            onConfirm: async () => {
                setAssetToAdd({
                    descriptor: descriptor,
                    vehicleStatusId: vehicleStatusId,
                    lotId: selectedLot
                });
            }
        })
    }

    const rowActions = (index) => {
        const row = processedData?.[index];
        switch (row?.status) {
            case (auditStatusesById.MATCH):
                return [
                    {
                        component: <Button
                            variant='outlined'
                            className={classes.actionButtons}
                            color='primary'
                        >
                            No Action Necessary!
                        </Button>
                    }
                ]
            case (auditStatusesById.NOT_ON_LOT_IN_PARQ):
                return [
                    {
                        component: <Button
                            variant='outlined'
                            onClick={() => onUpdateStatus(true, row?.vehicleId, "Have you confirmed with the SS that this asset is currently on the Lot?")}
                            className={classes.actionButtons}
                            color='primary'
                        >
                            Change Status to Available?
                        </Button>
                    }
                ]
            case (auditStatusesById.ON_LOT_IN_PARQ_MISSING_IN_FILE):
                return [
                    {
                        component: <Button
                            variant='outlined'
                            onClick={() => onUpdateStatus(false, row?.vehicleId, "Have you confirmed with the SS that this asset is currently off the Lot?")}
                            className={classes.actionButtons}
                            color='primary'
                        >
                            Check out the Asset?
                        </Button>
                    }
                ]
            case (auditStatusesById.NO_EXIST_IN_PARQ):
                return [
                    {
                        component: <Button
                            variant='outlined'
                            onClick={() => onCheckInAsset(20, row?.vin, "Have you confirmed this asset is on the Lot now? If you proceed and create this asset in the Web App, the actual check-in photos will be missing. Make sure the Lot takes those photos after this asset is created")}
                            className={classes.actionButtons}
                            color='primary'
                        >
                            Check in the Asset
                        </Button>
                    }
                ]
            case (auditStatusesById.DUPLICATE):
                return [
                    {
                        label: 'Resolve',
                        component: <AssetResolveDuplicate asset={row} />
                    }
                ]
        }
        return [];
    };

    const addAsset = () => {
        setAssetToAdd(null);
    };

    return (
        <>
            <BasicModal
                open={assetToAdd}
                header={`Adding Asset`}
                wide={true}
                handleClose={() => setAssetToAdd(null)}
                component={<EditAssetPopup asset={assetToAdd} handleClose={() => setAssetToAdd(null)}
                    updateAsset={addAsset} />}
            />
            <PageComponent header={
                <DataListHeader
                    title={(
                        <>
                            <IconButton onClick={() => goBack()} >
                                <ArrowBack />
                            </IconButton>
                            <span>{`Audit Lot ${selectedLot && auditResult?.length ? lots?.find(l => l.id == selectedLot)?.name : ''}` + `${auditId ? " taken on " + FormatService.formatDateTime(lotAuditHistory?.find(a => a.id === parseInt(id))?.createdDate) : ''}`}</span>
                        </>
                    )}
                    data={filteredResults}
                    dropDownsConfig={auditResult?.length ? auditResultFilterConfig : undefined}
                    filters={filters}
                    searchVal={auditResult?.length ? searchVal : undefined}
                    onDropFilter={onDropFilter}
                    onSearch={auditResult?.length ? onSearch : undefined}
                    actionButtons={(
                        <Grid container spacing={1}>
                            {!auditId ?
                                <Grid item>
                                    <AssetBulkUpdate assetIds={selected} />
                                </Grid> : null}
                            <Grid item>
                                <ExportButton fileLabel="Export" data={processedData} mapExportData={mapExportData} />
                            </Grid>
                        </Grid>
                    )}
                />
            }>
                {auditResult?.length ? <DataTable
                    columns={columns}
                    rows={orderBy(
                        filteredResults,
                        sortOptions.columnToSort,
                        sortOptions.sortDirection
                    )}
                    rowIdentifier='index'
                    onSort={sortData}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                    actions={!auditId ? rowActions : null}
                    maxActionCount={2}
                /> :
                    <LoadingSpinner loading={isLoadingLotAudit} />}
            </PageComponent>
        </>
    )
}

export default AuditTable;