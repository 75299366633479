import {vehicleDetailsPageStyle} from "../vehicles/VehicleDetailsPage/VehicleDetailsPageStyle";
import {Grid} from "@material-ui/core";
import React from "react";

export const CardHeader = ({ title, icon, actions }) => {
    const classes = vehicleDetailsPageStyle();
    return (
        <Grid className={classes.titleWrapper} container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <Grid container alignItems='center'>
                    {icon}
                    <h2 className={classes.title}>{title}</h2>
                </Grid>
            </Grid>
            <Grid item>
                {actions}
            </Grid>
        </Grid>
    )
}

export default CardHeader;