import { Button, Grid, Menu, MenuItem } from "@material-ui/core"
import { loadGroupDetailsStyle } from "./LoadGroupDetailsStyle"
import AddLoadActivity from "../AddLoadActivity/AddLoadActivity";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { useState } from "react";
import AddLoadLeg from "../AddLoadLeg/AddLoadLeg";

export const AddLegMenu = ({ loadGroup }) => {
    const classes = loadGroupDetailsStyle()

    const [anchorEl, setAnchorEl] = useState(null);
    const [addActivity, setAddActivity] = useState(false);
    const [addLoad, setAddLoad] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.addLegMenu}>
            <Button
                onClick={handleClick}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                variant="outlined"
            >
                Add Leg
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MenuItem onClick={() => setAddLoad(true)}>
                    Add transport leg
                </MenuItem>
                <MenuItem onClick={() => setAddActivity(true)}>
                    Add activity leg
                </MenuItem>
            </Menu>
            {addActivity && <AddLoadActivity loadGroup={loadGroup} open={addActivity} setOpen={setAddActivity} />}
            {addLoad && <AddLoadLeg loadGroup={loadGroup} open={addLoad} setOpen={setAddLoad} />}
        </div>
    )
}