import * as React from "react";

function SvgIconForm(props, width, height) {
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 12.2002C7 11.786 7.33579 11.4502 7.75 11.4502H14.75C15.1642 11.4502 15.5 11.786 15.5 12.2002C15.5 12.6144 15.1642 12.9502 14.75 12.9502H7.75C7.33579 12.9502 7 12.6144 7 12.2002Z" fill="#0090FE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 16.2002C7 15.786 7.33579 15.4502 7.75 15.4502H12.13C12.5442 15.4502 12.88 15.786 12.88 16.2002C12.88 16.6144 12.5442 16.9502 12.13 16.9502H7.75C7.33579 16.9502 7 16.6144 7 16.2002Z" fill="#0090FE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.67882 1.25015C9.70338 1.25022 9.72713 1.25029 9.75 1.25029H13.75C13.7729 1.25029 13.7966 1.25022 13.8212 1.25015C14.2642 1.24889 14.972 1.24688 15.541 1.62625C16.2253 2.08246 16.5 2.90124 16.5 4.00029C16.5 4.02316 16.5001 4.04691 16.5001 4.07147C16.5014 4.51445 16.5034 5.22226 16.124 5.79131C15.6678 6.47562 14.849 6.75029 13.75 6.75029H9.75C9.72713 6.75029 9.70338 6.75036 9.67882 6.75043C9.23584 6.75169 8.52803 6.7537 7.95897 6.37433C7.27467 5.91812 7 5.09934 7 4.00029C7 2.90124 7.27467 2.08246 7.95897 1.62625C8.52803 1.24688 9.23584 1.24889 9.67882 1.25015ZM8.79103 2.87433C8.72533 2.91812 8.5 3.09934 8.5 4.00029C8.5 4.90124 8.72533 5.08246 8.79103 5.12625C8.94042 5.22585 9.18524 5.25029 9.75 5.25029H13.75C14.651 5.25029 14.8322 5.02496 14.876 4.95926C14.9756 4.80987 15 4.56505 15 4.00029C15 3.09934 14.7747 2.91812 14.709 2.87433C14.5596 2.77473 14.3148 2.75029 13.75 2.75029H9.75C9.18524 2.75029 8.94042 2.77473 8.79103 2.87433Z" fill="#0090FE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49891 3.97907C8.52126 4.39268 8.20409 4.7461 7.79048 4.76846C6.18434 4.85528 5.18333 5.19275 4.55215 5.87598C3.9126 6.56827 3.5 7.78034 3.5 9.99955V15.9996C3.5 17.9863 3.76132 19.2307 4.43943 20.0057C5.09587 20.7559 6.31467 21.2495 8.75 21.2495H11.75C12.1642 21.2495 12.5 21.5853 12.5 21.9995C12.5 22.4138 12.1642 22.7495 11.75 22.7495H8.75C6.18533 22.7495 4.40413 22.2432 3.31057 20.9934C2.23868 19.7684 2 18.0128 2 15.9996V9.99955C2 7.65876 2.4224 5.97083 3.45035 4.85812C4.48667 3.73635 5.98566 3.36383 7.70952 3.27064C8.12313 3.24829 8.47655 3.56546 8.49891 3.97907ZM15.0011 3.97907C15.0235 3.56546 15.3769 3.24829 15.7905 3.27064C17.5131 3.36376 19.0126 3.73332 20.0493 4.85399C21.0778 5.96576 21.5 7.65427 21.5 9.99955V11.5C21.5 11.9142 21.1642 12.25 20.75 12.25C20.3358 12.25 20 11.9142 20 11.5V9.99955C20 7.77483 19.5872 6.56334 18.9482 5.87261C18.3174 5.19078 17.3169 4.85534 15.7095 4.76846C15.2959 4.7461 14.9787 4.39268 15.0011 3.97907Z" fill="#0090FE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7124 13.4569C20.3757 13.3887 20.9859 13.6866 21.5294 14.2301C22.076 14.7767 22.3775 15.3883 22.3117 16.0531C22.2498 16.6782 21.877 17.1632 21.5294 17.5108L17.997 21.0432C17.8505 21.1968 17.6701 21.3084 17.522 21.3825C17.3662 21.4604 17.1767 21.5312 16.9861 21.5613L16.9736 21.5632L15.6251 21.7529V21.7529C15.1836 21.8157 14.7277 21.6997 14.3987 21.3708C14.0698 21.0419 13.9536 20.5868 14.0165 20.1452V20.1452L14.2081 18.7835C14.2387 18.5897 14.311 18.4015 14.3886 18.2481C14.4662 18.0948 14.5764 17.9224 14.7188 17.7801V17.7801L18.2587 14.2401C18.6064 13.8924 19.0903 13.5208 19.7124 13.4569ZM19.8657 14.949C19.7578 14.9601 19.5817 15.0384 19.3194 15.3008L15.7808 18.8394C15.7808 18.8394 15.7747 18.8464 15.7653 18.86C15.7535 18.8772 15.74 18.8996 15.7271 18.9253C15.7141 18.9509 15.704 18.9751 15.697 18.9949C15.6927 19.0073 15.6907 19.0151 15.6899 19.0181L15.516 20.2535L16.7521 20.0795C16.7553 20.0788 16.7644 20.0765 16.7792 20.0714C16.8006 20.064 16.8257 20.0536 16.8512 20.0409C16.8768 20.028 16.8973 20.0157 16.9109 20.0062C16.9141 20.004 16.9164 20.0022 16.9181 20.001C16.9216 19.9973 16.9252 19.9937 16.9288 19.9901L20.4688 16.4501C20.7311 16.1878 20.8084 16.0127 20.819 15.9053C20.8257 15.8376 20.8222 15.6442 20.4688 15.2908C20.1223 14.9443 19.9325 14.9422 19.8657 14.949Z" fill="#0090FE" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0766 14.5578C18.4757 14.447 18.8891 14.6806 18.9999 15.0797C19.2295 15.9063 19.8715 16.5482 20.698 16.7778C21.0971 16.8887 21.3308 17.3021 21.2199 17.7012C21.1091 18.1003 20.6957 18.334 20.2966 18.2231C18.9631 17.8527 17.9251 16.8147 17.5547 15.4812C17.4438 15.0821 17.6775 14.6687 18.0766 14.5578Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconForm;
