import { Grid } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import CustomInput from 'components/CustomInput';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { useDispatch, useSelector } from 'react-redux';

const ClientFields = ({ }) => {
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, [key]: val }))
    }

    return (
        <>
            <Authorize profile={permissionProfiles.ACCESS.CLIENT_FIELDS}>
                <Grid item>
                    <CustomInput
                        label='Client'
                        elementType='dropdown'
                        value={accessToEdit?.clientId}
                        values={clients}
                        onChange={(val) => onChangeValue("clientId", val)}
                        showEmpty
                        required
                    />
                </Grid>
            </Authorize>
        </>
    );
}

export default ClientFields;