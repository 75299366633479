import * as React from "react";

function SvgIconTitle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M21.33 7.42003C21.1962 7.42003 21.08 7.30389 21.08 7.17003V5.35003C21.08 4.34389 20.2662 3.53003 19.26 3.53003H4.74001C3.73387 3.53003 2.92001 4.34389 2.92001 5.35003V7.18003C2.92001 7.31389 2.80387 7.43003 2.67001 7.43003C2.54045 7.43003 2.42001 7.3182 2.42001 7.17003V5.35003C2.42001 4.06732 3.465 3.03003 4.74001 3.03003H19.26C20.5427 3.03003 21.58 4.07502 21.58 5.35003V7.18003C21.58 7.30951 21.4783 7.42003 21.33 7.42003Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M12 20.97C11.8661 20.97 11.75 20.8538 11.75 20.72V4.10999C11.75 3.97613 11.8661 3.85999 12 3.85999C12.1339 3.85999 12.25 3.97613 12.25 4.10999V20.72C12.25 20.8595 12.1382 20.97 12 20.97Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M15.94 20.97H8.06C7.92614 20.97 7.81 20.8538 7.81 20.72C7.81 20.5861 7.92614 20.47 8.06 20.47H15.94C16.0739 20.47 16.19 20.5861 16.19 20.72C16.19 20.8538 16.0739 20.97 15.94 20.97Z" fill="#0090FE" stroke="#0090FE" />
    </svg>
  );
}

export default SvgIconTitle;
