import { Grid, Paper } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arrayToObject } from "utils/object-util";
import FormBuilder from "../formBuilder";
import { formTypeOptions, formTypes } from "../formBuilder/formBuilderConsts";
import { useGetFormsForClientQuery } from "../formBuilder/formBuilderSlice";
import { FormListStyle } from "./style";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import CustomReport from "../CustomReport";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useEffect } from "react";
import { setClientId, setFormType } from "./formStateSlice";

const FormList = ({ }) => {
    const classes = FormListStyle();
    const isClient = useSelector(selectIsClient);
    const currentClientId = useSelector(selectUserClientId);
    const { clientId, formType } = useSelector(state => state.formState);

    const dispatch = useDispatch()


    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: isClient });

    useEffect(() => {
        if (isClient) {
            dispatch(setClientId(currentClientId))
        }
    }, [])

    const tabs = [
        {
            label: 'Form',
            component: <FormBuilder />
        },
        {
            label: 'Custom Report',
            component: <CustomReport />
        }
    ]

    return (
        <Grid container direction="column">
            <Paper className={classes.formListFilters}>
                <Grid container spacing={2}>
                    {!isClient && <Grid item xs={6} container direction="column" >
                        <Grid item className={classes.filterLabel}>Select Client</Grid>
                        <Grid item >
                            <CustomInput
                                id="clientId"
                                value={clientId}
                                elementType="dropdown"
                                onChange={val => {
                                    dispatch(setClientId(+val));
                                }}
                                values={clients}
                                showEmpty
                                placeholder={'Select client'}
                            />
                        </Grid>
                    </Grid>}
                    <Grid item xs={6} container direction="column">
                        <Grid item className={classes.filterLabel}>Select Form Type</Grid>
                        <Grid item >
                            <CustomInput
                                id="clientId"
                                value={formType}
                                elementType="dropdown"
                                onChange={val => {
                                    dispatch(setFormType(+val));
                                }}
                                values={formTypeOptions}
                                showEmpty
                                placeholder={'Select form type'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <div className={classes.formBuilderContainer}>
                {(!clientId || !formType) && <EmptyPlaceholder
                    text="Please select client and form type"
                />}
                {!!clientId && !!formType && <>
                    {/* {Object.values(formTypes).map(formType =>
                    <div>
                        {formType}
                        {formByTypeMap?.[formType]?.name}
                    </div>)} */}
                    <TabsWrapper tabs={tabs} />
                </>}
            </div>
        </Grid>
    )
}
export default FormList;