import { makeStyles } from "@material-ui/core";

export const SubMarketDialogStyle = makeStyles((theme) => ({
    lotListWrapper: {
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    addButton: {
        marginLeft: "1.1rem"
    },
    groupTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 1em'
    }
}));