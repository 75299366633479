import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useBulkUpdatePriceMutation } from "../pricingSlice";
import { useState } from "react";
import usePrompt from "hooks/usePrompt";
import { IconDollar } from "icons";
const { Dialog, DialogTitle, DialogContent, DialogActions, Button } = require("@material-ui/core")

const BulkUpdatePriceDialog = (props) => {
    
    let [bulkUpdatePrice] = useBulkUpdatePriceMutation();

    const { selectedRows, allData } = props;

    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState()
    const { triggerPrompt } = usePrompt();

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    const onSave = async () => {
        triggerPrompt({
            title: 'Bulk Update Activities',
            content: `You are about to update the price of ${selectedRows?.length ?? 0} activity prices, are you sure?`,
            onConfirm: async () => {
                const result = await bulkUpdatePrice({
                    activityPriceIds: selectedRows,
                    price: price
                })
                if (result?.error) {
                    NotificationProvider.error("Failed to update activity prices")
                } else {
                    NotificationProvider.success(`Successfully updated activity prices`);
                    closeDialog();
                    setPrice();
                }
            }
        })
    }

    return (
        <div>
            <Button
                variant='primary'
                color="primary"
                onClick={openDialog}
            >
                Update Activity Price
            </Button>
            <Dialog 
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title">
                <DialogTitle  id="form-dialog-title">{`Update ${selectedRows?.length ?? 0} Activity Price${selectedRows?.length > 1 ? "s" : ""}`}</DialogTitle>
                <DialogContent>
                    <CustomInput
                        id="ddlActivityPrice"
                        label={"Price"}
                        elementType="input"
                        value={price}
                        required
                        onChange={val => setPrice(val)}
                        numberOnly
                        icon={<IconDollar />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSave}
                        disabled={!price}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BulkUpdatePriceDialog;