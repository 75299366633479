import { makeStyles } from "@material-ui/core";

export const commentsStyle = makeStyles((theme) => ({
    statusgrid: {
        backgroundColor: '#E9F5FF',
        borderRadius: '3px',
        padding: '.75em',
    },
    status: {
        fontSize: 13,
        fontWeight: '500',
        marginLeft: '.5em',
        fontColor: '#242A31'
    },
    commentsWrapper: {
        backgroundColor: '#FAFCFF',
        padding: '1.5em'
    },
    titleWrapper: {
        marginBottom: '1em'
    },
    title: {
        fontSize: 15,
        fontWeight: 500,
        alignItems: 'center',
    },
    date: {
        fontWeight: '350',
        marginLeft: '.8em',
        fontSize: 10
    },
    newNote: {
        marginTop: '0.5em',
        '& .rsw-editor': {//hack
            // maxWidth: '300px !important'
        }
    }
}))
