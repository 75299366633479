import { baseApi } from 'app/baseApi';
import { baseQuery } from "app/baseQuery";

const baseUrl = 'cameraEvent';
export const cameraEventsApi = baseApi.injectEndpoints({
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getCameraEvents: builder.query({
            query: () => `${baseUrl}`,
        }),

    }),
});

export const {
    useGetCameraEventsQuery,
} = cameraEventsApi;
