import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import DataTable from 'components/DataTable/DataTable';
import { distanceReportStyles } from './style';
import ExportButton from 'components/ExportButton/ExportButton';


const DistanceReportResults = ({ results, loading, open, setOpen }) => {

    const classes = distanceReportStyles();

    const columns = [
        { name: "Zip", key: "zipCode" },
        { name: "Address", key: "address" },
        { name: "VIN", key: "vin" },
        { name: "Asset Id", key: "assetId" },
        { name: "Lot 1", key: "lot1" },
        { name: "Zip 1", key: "zip1" },
        { name: "Distance 1", key: "distance1", component: (row) => <div className={classes.distance1}>{row?.distance1}</div> },
        { name: "Lot 2", key: "lot2" },
        { name: "Zip 2", key: "zip2" },
        { name: "Distance 2", key: "distance2", component: (row) => <div className={classes.distance2}>{row?.distance2}</div> },
        { name: "Lot 3", key: "lot3" },
        { name: "Zip 3", key: "zip3" },
        { name: "Distance 3", key: "distance3", component: (row) => <div className={classes.distance3}>{row?.distance3}</div> },
        { name: "Note", key: "note" },
    ]

    const resultsProcessed = results?.map(r => {
        const data = {
            ...r
        }
        r.distances?.map((d, index) => {
            data[`lot${index + 1}`] = d.lotName;
            data[`distance${index + 1}`] = d.distance;
            data[`zip${index + 1}`] = d.zipCode;
        });
        return data;
    }) ?? [];

    const mapExportData = (row) => {
        const data = {};
        columns.map(col => {
            data[col.name] = row[col.key] ?? '-'
        })
        return data;
    }

    return (
        <Dialog open={open} maxWidth='lg' fullWidth aria-labelledby='dialog-title'>
            <DialogTitle>{loading ? 'Generating distance report...' : 'Distance Report Results'}</DialogTitle>
            <DialogContent >
                <div >
                    {loading ?
                        <LinearProgress />
                        :
                        <DataTable
                            className={classes.tableWrapper}
                            rows={resultsProcessed}
                            columns={columns}
                        />
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => setOpen(false)}>
                    Close
                </Button>
                <ExportButton
                    data={resultsProcessed}
                    label='Export'
                    mapExportData={mapExportData}
                    fileLabel={'distance_report'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DistanceReportResults;
