import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { ProductivityStyles } from "./ProductivityStyle";
import { useGetLotScoresQuery, useGetLotsQuery } from "features/lots/lotSlice";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import { useState } from "react";
import RankingPieChart from "./RankingPieChart/RankingPieChart";
import LotRanking from "./LotRanking/LotRanking";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { IconParkingLot, IconSettings } from "icons";
import DropdownFilter from "components/DropdownFilter";
import ConfigureLot from "./ConfigureLot/ConfigureLot";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";


const Productivity = ({ lotRanking }) => {

    const classes = ProductivityStyles();

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots?.filter(l => l.pmfLot) || [];

    const ranks = lotRanking?.lotScores?.map(e => e?.rank)
    const maxRank = ranks ? Math.max(...ranks) : 0;

    const [selectedLot, setSelectedLot] = useState();
    const [selectedLotRanking, setSelectedLotRanking] = useState(); //lotRanking?.lotScores?.find((lot) => lot.id == lots[0].id));
    const [editLotOpen, setEditLotOpen] = useState(false);

    const isLoadingDashboardData = () => isLoadingLots;

    const onSelectLot = (val) => {
        setSelectedLot(val);
        setSelectedLotRanking(lotRanking?.lotScores.find((lot) => lot.id == val))
    }


    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <>
                        <Grid container alignItems="center">
                            <Grid item><img src="/icons/icon-inspect.svg" className={classes.icon} /></Grid>
                            <Grid item><h3 className={classes.subTitle}>Productivity</h3></Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" >
                            <Grid className={classes.dropdown} xs={11} >
                                <DropdownFilter
                                    title='Lot'
                                    identifier='lot'
                                    values={lotRanking?.lotScores ?? []}
                                    onSelect={(val) => onSelectLot(val)}
                                    value={selectedLot}
                                    showEmpty
                                />
                            </Grid>
                            {selectedLot &&
                                <Authorize profile={permissionProfiles.LOT.LOT_KPI_CONFIGURE}>
                                    <Grid className={classes.settings} xs={1}>
                                        <IconButton onClick={() => setEditLotOpen(true)}>
                                            {<IconSettings />}
                                        </IconButton>
                                    </Grid>
                                </Authorize>
                            }
                        </Grid>
                        {selectedLotRanking ?
                            <Grid container alignItems="center" justifyContent="center" >
                                <Grid item container alignItems="center" className={classes.rankingGraph}>
                                    <Grid item><RankingPieChart value={selectedLotRanking?.rank || 0} totalValue={maxRank} label='Rank' /></Grid>
                                    <Grid item><LotRanking lotRanking={selectedLotRanking} /></Grid>
                                    <Grid item><RankingPieChart value={selectedLotRanking?.totalPoints || 0} totalValue={10} label='Total Points' /></Grid>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid> : <EmptyPlaceholder text="Select Lot" icon={<IconParkingLot />} />
                        }
                        <Authorize profile={permissionProfiles.LOT.LOT_KPI_CONFIGURE}>
                            <ConfigureLot open={editLotOpen} onCancel={() => setEditLotOpen(false)} lot={lots.find(l => l.id == selectedLot)} />
                        </Authorize>
                    </>
            }
        </>
    );
};

export default Productivity;
