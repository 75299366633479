import { Button, Grid, Paper } from '@material-ui/core';
import { Alert } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import NotificationProvider from 'components/NotificationProvider';
import { groupLegTypes } from 'features/loads/enums';
import { useAddLoadGroupMutation } from 'features/loads/loadsSlice';
import { setErrors, setLoadsErrors, setLoadsMissingFields, setMissingFields, setNewLots, setShowMandatoryError } from 'features/loads/loadsStateSlice';
import { selectIsClient, selectUserClientId } from 'features/user/userSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { CreateLoadGroupStyles } from './CreateLoadGroupStyles';
import GroupAssetForm from './GroupAssetForm/GroupAssetForm';
import GroupLegsForm from './GroupLegsForm/GroupLegsForm';
import LoadGroupJourney from './LoadGroupJourney';

function CreateLoadGroup({
    allowMultileg = true,
    loadData,
    setUseDialog,
    disableAssetDetails,
    selectedLegIndex, //Used to add a leg to an existing load, when set, we will only send this load to the API and it'll be added to the existing load
    onClose,
    vehicleData
}) {
    const classes = CreateLoadGroupStyles()

    const isClient = useSelector(selectIsClient);
    const loggedInClients = useSelector(selectUserClientId);
    const { newLots, errors, loadsErrors, loadsMissingFields, missingFields } = useSelector(state => state.loadState);
    let [addLoadGroup, { isLoading: isSaving }] = useAddLoadGroupMutation();

    const [loadGroup, setLoadGroup] = useState({ loads: [{ uuid: uuidv4(), index: 0, type: groupLegTypes.TRANSPORT, pickupLocationId: loadData?.pickupLocationId }], ...loadData });
    const [legIndex, setLegIndex] = useState(0);
    const [clientConfigs, setClientConfigs] = useState();
    const [clientBUs, setClientBUs] = useState([]);
    const [checkMissingFields, setCheckMissingFields] = useState(false);

    const dispatch = useDispatch();

    const newLotsLookup = newLots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    useEffect(() => {
        if (selectedLegIndex) {
            setLegIndex(selectedLegIndex);
        }
    }, [selectedLegIndex])

    useEffect(() => {
        if (vehicleData) {
            const { vin, assetId, year, make, model, assetTypeId, fuelType, id, ...other } = vehicleData;
            const loads = [...loadGroup.loads];
            loads[legIndex] = {
                ...loads[legIndex],
                vin,
                assetId,
                year,
                make,
                model,
                vehicleFuelTypeId: fuelType,
                assetTypeId,
                vehicleId: vehicleData?.id
            }
            setLoadGroup({
                ...loadGroup,
                loads
                /*vin,
                assetId,
                year,
                make,
                model,
                vehicleFuelTypeId: fuelType,
                assetTypeId,
                vehicleId: vehicleData?.id*/
            });
        }
    }, [vehicleData])

    useEffect(() => {
        dispatch(setNewLots([]));
        dispatch(setShowMandatoryError(false));
        dispatch(setErrors({}));
        dispatch(setLoadsErrors({}));
        dispatch(setMissingFields({}));
        dispatch(setLoadsMissingFields({}));
        setCheckMissingFields(false)
    }, [])

    const reset = () => {
        setLegIndex(0)
        setLoadGroup({ loads: [{ uuid: uuidv4(), index: 0, type: groupLegTypes.TRANSPORT }] });
        dispatch(setNewLots([]));
        dispatch(setShowMandatoryError(false));
        dispatch(setErrors({}));
        dispatch(setLoadsErrors({}));
        dispatch(setMissingFields({}));
        dispatch(setLoadsMissingFields({}));
        setCheckMissingFields(false)
    }

    const isLoadValid = (load) => {
        let isValid = (!!(
            (clientConfigs?.config?.transportConfig?.needByWeek ? load.neededByWeek : true) &&
            (clientConfigs?.config?.transportConfig?.handOffWeek ? load.handoffWeek : true) &&
            //
            load.vin && !loadsErrors?.[load?.uuid]?.['vin'] &&
            load.assetId &&
            load.make &&
            load.model &&
            load.year && !loadsErrors?.[load?.uuid]?.['year'] &&
            load.assetTypeId &&
            load.vehicleFuelTypeId &&
            //
            load.configMoveType &&
            load.pickupStartDate && !loadsErrors?.[load?.uuid]?.['pickupStartDate'] &&
            load.deliveryStartDate && !loadsErrors?.[load?.uuid]?.['deliveryStartDate'] &&
            ((load.pickupLocationId && load.pickupLocationId !== 0 && !load?.originNewLot) ||
                (load?.originNewLot &&
                    load.pickupName &&
                    load.pickupNodeName &&
                    load.pickupAddress &&
                    load.pickupCity &&
                    load.pickupState &&
                    load.pickupZip &&
                    load.pickupPocEmail && !loadsErrors?.[load?.uuid]?.['pickupPocEmail'] &&
                    load.pickupPhone && !loadsErrors?.[load?.uuid]?.['pickupPhone'])) &&
            ((load.deliveryLocationId && load.deliveryLocationId !== 0 && !load?.destinationNewLot) ||
                (load?.destinationNewLot &&
                    load.deliveryName &&
                    load.deliveryNodeName &&
                    load.deliveryAddress &&
                    load.deliveryCity &&
                    load.deliveryState &&
                    load.deliveryZip &&
                    load.deliveryPocEmail && !loadsErrors?.[load?.uuid]?.['deliveryPocEmail'] &&
                    load.deliveryPhone && !loadsErrors?.[load?.uuid]?.['deliveryPhone']))))

        return isValid;
    }

    const isActivityValid = (activity) => {
        let isValid = (!!(
            activity.activityTypeId &&
            activity.activityItemId &&
            activity.lotId &&
            activity.activityDate
        ))

        return isValid;
    }

    const findMissingLoadFields = (load) => {
        let missingLoadFields = {};
        if (clientConfigs?.config?.transportConfig?.needByWeek && !load.neededByWeek) missingLoadFields = { ...missingLoadFields, ["neededByWeek"]: "Missing needed by week" };
        if (clientConfigs?.config?.transportConfig?.handOffWeek && !load.handoffWeek) missingLoadFields = { ...missingLoadFields, ["handoffWeek"]: "Missing hand off week" };
        if (!load.configMoveType) missingLoadFields = { ...missingLoadFields, ["configMoveType"]: "Missing move type" };
        if (!load.pickupStartDate) missingLoadFields = { ...missingLoadFields, ["pickupStartDate"]: "Missing pickup start date" };
        if (!load.deliveryStartDate) missingLoadFields = { ...missingLoadFields, ["deliveryStartDate"]: "Missing delivery start date" };
        if (!load?.originNewLot && (!load.pickupLocationId || load.pickupLocationId == 0)) missingLoadFields = { ...missingLoadFields, ["pickupLocationId"]: "Missing origin location" };
        if (load?.originNewLot && !load.pickupName) missingLoadFields = { ...missingLoadFields, ["pickupName"]: "Missing origin location name" };
        if (load?.originNewLot && !load.pickupNodeName) missingLoadFields = { ...missingLoadFields, ["pickupNodeName"]: "Missing origin node name" };
        if (load?.originNewLot && !load.pickupAddress) missingLoadFields = { ...missingLoadFields, ["pickupAddress"]: "Missing origin address" };
        if (load?.originNewLot && !load.pickupCity) missingLoadFields = { ...missingLoadFields, ["pickupCity"]: "Missing origin city" };
        if (load?.originNewLot && !load.pickupState) missingLoadFields = { ...missingLoadFields, ["pickupState"]: "Missing origin state" };
        if (load?.originNewLot && !load.pickupZip) missingLoadFields = { ...missingLoadFields, ["pickupZip"]: "Missing origin zip" };
        if (load?.originNewLot && !load.pickupPocEmail) missingLoadFields = { ...missingLoadFields, ["pickupPocEmail"]: "Missing origin POC email" };
        if (load?.originNewLot && !load.pickupPhone) missingLoadFields = { ...missingLoadFields, ["pickupPhone"]: "Missing origin phone" };
        if (!load?.destinationNewLot && (!load.deliveryLocationId || load.deliveryLocationId == 0)) missingLoadFields = { ...missingLoadFields, ["deliveryLocationId"]: "Missing destination location" };
        if (load?.destinationNewLot && !load.deliveryName) missingLoadFields = { ...missingLoadFields, ["deliveryName"]: "Missing destination location name" };
        if (load?.destinationNewLot && !load.deliveryNodeName) missingLoadFields = { ...missingLoadFields, ["deliveryNodeName"]: "Missing destination node name" };
        if (load?.destinationNewLot && !load.deliveryAddress) missingLoadFields = { ...missingLoadFields, ["deliveryAddress"]: "Missing destination address" };
        if (load?.destinationNewLot && !load.deliveryCity) missingLoadFields = { ...missingLoadFields, ["deliveryCity"]: "Missing destination city" };
        if (load?.destinationNewLot && !load.deliveryState) missingLoadFields = { ...missingLoadFields, ["deliveryState"]: "Missing destination state" };
        if (load?.destinationNewLot && !load.deliveryZip) missingLoadFields = { ...missingLoadFields, ["deliveryZip"]: "Missing destination zip" };
        if (load?.destinationNewLot && !load.deliveryPocEmail) missingLoadFields = { ...missingLoadFields, ["deliveryPocEmail"]: "Missing destination POC email" };
        if (load?.destinationNewLot && !load.deliveryPhone) missingLoadFields = { ...missingLoadFields, ["deliveryPhone"]: "Missing destination phone" };
        //Asset fields
        if (!load.vin) missingLoadFields = { ...missingLoadFields, ["vin"]: "Missing VIN" };
        if (!load.assetId) missingLoadFields = { ...missingLoadFields, ["assetId"]: "Missing asset ID" };
        if (!load.make) missingLoadFields = { ...missingLoadFields, ["make"]: "Missing make" };
        if (!load.model) missingLoadFields = { ...missingLoadFields, ["model"]: "Missing model" };
        if (!load.year) missingLoadFields = { ...missingLoadFields, ["year"]: "Missing year" };
        if (!load.assetTypeId) missingLoadFields = { ...missingLoadFields, ["assetTypeId"]: "Missing asset type" };
        if (!load.vehicleFuelTypeId) missingLoadFields = { ...missingLoadFields, ["vehicleFuelTypeId"]: "Missing vehicle fuel type" };
        return missingLoadFields;
    }

    const findMissingActivityFields = (load) => {
        let missingActivityFields = {};
        if (!load.activityTypeId) missingActivityFields = { ...missingActivityFields, ["activityTypeId"]: "Missing activity type" };
        if (!load.activityItemId) missingActivityFields = { ...missingActivityFields, ["activityItemId"]: "Missing activity item" };
        if (!load.lotId) missingActivityFields = { ...missingActivityFields, ["lotId"]: "Missing lot" };
        if (!load.activityDate) missingActivityFields = { ...missingActivityFields, ["activityDate"]: "Missing activity date" };
        return missingActivityFields;
    }

    const findMissingFields = (loadGroup) => {
        let missingAssetFields = {};
        //if (!loadGroup.clientId && !isClient) missingAssetFields = { ...missingAssetFields, ["clientId"]: "Missing client" };
        /*if (!loadGroup.vin) missingAssetFields = { ...missingAssetFields, ["vin"]: "Missing VIN" };
        if (!loadGroup.assetId) missingAssetFields = { ...missingAssetFields, ["assetId"]: "Missing asset ID" };
        if (!loadGroup.make) missingAssetFields = { ...missingAssetFields, ["make"]: "Missing make" };
        if (!loadGroup.model) missingAssetFields = { ...missingAssetFields, ["model"]: "Missing model" };
        if (!loadGroup.year) missingAssetFields = { ...missingAssetFields, ["year"]: "Missing year" };
        if (!loadGroup.assetTypeId) missingAssetFields = { ...missingAssetFields, ["assetTypeId"]: "Missing asset type" };
        if (!loadGroup.vehicleFuelTypeId) missingAssetFields = { ...missingAssetFields, ["vehicleFuelTypeId"]: "Missing vehicle fuel type" };*/
        if (clientBUs?.length && !loadGroup.businessUnit) missingAssetFields = { ...missingAssetFields, ["businessUnit"]: "Missing business unit" };
        dispatch(setMissingFields({ ...missingAssetFields }));
        let missingFieldsByLoad = {};
        loadGroup?.loads?.forEach((leg, index) => {
            if (!selectedLegIndex || selectedLegIndex == index) {
                if (leg?.type === groupLegTypes.TRANSPORT) {
                    let missingLoadFields = findMissingLoadFields(leg);
                    if (Object.values(missingLoadFields)) missingFieldsByLoad = { ...missingFieldsByLoad, [leg?.uuid]: missingLoadFields }
                } else {
                    let missingFields = findMissingActivityFields(leg);
                    if (Object.values(missingFields)) missingFieldsByLoad = { ...missingFieldsByLoad, [leg?.uuid]: missingFields }
                }
            }
        });
        dispatch(setLoadsMissingFields({ ...missingFieldsByLoad }));
    }

    const isValid = () => {
        const isAssetValid = !!(loadGroup &&
            //(loadGroup.clientAccountId || (isClient)) &&
            (!clientBUs?.length || loadGroup.businessUnit)
            /*loadGroup.vin && !errors['vin'] &&
            loadGroup.assetId &&
            loadGroup.make &&
            loadGroup.model &&
            loadGroup.year && !errors['year'] &&
            loadGroup.assetTypeId &&
            loadGroup.vehicleFuelTypeId */
        )
        if (!isAssetValid) return false;

        let isLegsValid = true
        loadGroup?.loads?.forEach((leg, index) => {
            let isLegValid = true;
            if (!selectedLegIndex || selectedLegIndex == index) {
                if (leg?.type === groupLegTypes.TRANSPORT) {
                    isLegValid = isLoadValid(leg);
                } else {
                    isLegValid = isActivityValid(leg);
                }
                if (!isLegValid) {
                    isLegsValid = isLegValid;
                }
            }
        });
        return isLegsValid;
    }

    const onSave = async () => {
        if(loggedInClients != "" && Array.isArray(loggedInClients))
        {
            loadGroup.loads[0].clientId = parseInt(loggedInClients);
        }
        const validate = isValid();
        if (!validate) {
            setCheckMissingFields(true);
            findMissingFields(loadGroup);
            dispatch(setShowMandatoryError(true));
            // window.scroll({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth'
            // });
            return;
        }
        // setErrors({});
        // if (!loadToEdit) {
        let activitiesToCreate = loadGroup?.loads?.filter(load => load?.type === groupLegTypes.ACTIVITY)?.map(activity => {
            return {
                ...activity,
                ["activityDate"]: new Date(moment(activity.activityDate).format('MMM DD, yyyy, hh:mm a') + ' UTC').toISOString()
            }
        });
        let loadsToCreate = loadGroup?.loads?.filter((load, index) => load?.type === groupLegTypes.TRANSPORT && (!selectedLegIndex || selectedLegIndex == index))?.map(load => {
            let loadToReturn = load;
            if (!load?.pickupNewLot && newLotsLookup?.[load?.pickupLocationId]) {
                loadToReturn = { ...loadToReturn, ...newLotsLookup?.[load?.pickupLocationId], ["pickupLocationId"]: 0 }
            }
            if (!load?.deliveryNewLot && newLotsLookup?.[load?.deliveryLocationId]) {
                loadToReturn = { ...loadToReturn, ...newLotsLookup?.[load?.deliveryLocationId], ["deliveryLocationId"]: 0 }
            }
            if (!load?.trailerType)
            {
                loadToReturn = { ...loadToReturn, ["trailerType"]: 'TO_BE_DRIVEN'};
            }
            return loadToReturn;
        })
        let result = await addLoadGroup({ ...loadGroup, ["loads"]: loadsToCreate, ["activities"]: activitiesToCreate })
        if (result?.error) {
            NotificationProvider.error(result?.error?.data?.messages ? result?.error?.data?.messages[0] : `Failed to create the load`)
        } else {
            NotificationProvider.success(`Successfully created the load`);
            reset();
            document.getElementById("createLoadForm").reset();
            document.getElementById("pickupLocationField").reset();
            document.getElementById("deliveryLocationField").reset();
            if(setUseDialog) { setUseDialog(false); }
            onClose(false);
        }
        // }
        // else {
        //     let result = await updateLoad(newLoad)
        //     if (result?.error) {
        //         NotificationProvider.error(`Failed to update the load`)
        //     } else {
        //         NotificationProvider.success(`Successfully updated the load`);

        //         setNewLoad(null)
        //         document.getElementById("createLoadForm").reset();
        //     }
        //     handleClose();
        // }
    }

    useEffect(() => {
        if (checkMissingFields) {
            findMissingFields(loadGroup);
        }
    }, [loadGroup, clientBUs])

    const errorsList = { ...errors, ...loadsErrors?.[loadGroup?.loads?.[legIndex]?.uuid] }
    const hasErrors = !!Object.values(errorsList)?.filter(v => v != null)?.length;
    const missingFieldsList = { ...missingFields, ...loadsMissingFields?.[loadGroup?.loads?.[legIndex]?.uuid] };
    const hasMissingFields = checkMissingFields && !!Object.values(missingFieldsList)?.filter(v => v != null)?.length;

    return (
        <div className={classes.paper}>

            {loadGroup?.loads?.[legIndex]?.type == groupLegTypes.TRANSPORT && <GroupAssetForm legIndex={legIndex} loadGroup={loadGroup} setLoadGroup={setLoadGroup} clientBUs={clientBUs} setClientBUs={setClientBUs} disableAssetDetails={disableAssetDetails} />}

            <GroupLegsForm loadGroup={loadGroup} legIndex={legIndex} key={legIndex} setLoadGroup={setLoadGroup} clientConfigs={clientConfigs} setClientConfigs={setClientConfigs} />

            {allowMultileg && <LoadGroupJourney legIndex={legIndex} setLegIndex={setLegIndex} loadGroup={loadGroup} setLoadGroup={setLoadGroup} />}

            {
                (hasErrors || hasMissingFields) && <Grid item xs={2}>
                    <Paper className={classes.errorWrapper}>
                        <h4>Please correct the following errors</h4>
                        <div className={classes.errors}>
                            {Object.values(errorsList)?.filter(v => v != null)?.map(error => <><Alert icon={false} severity="error">{error}</Alert><br /></>)}
                            {checkMissingFields && Object.values(missingFieldsList)?.filter(v => v != null)?.map(error => <><Alert icon={false} severity="error">{error}</Alert><br /></>)}
                        </div>
                    </Paper>
                </Grid>
            }

            {(hasErrors || hasMissingFields) && checkMissingFields && <div className={classes.errorMessage}>There are some missing or invalid fields, please correct the errors.</div>}

            <div className={classes.btnContainer}>
                {onClose &&
                    <Button
                        className={classes.button}
                        color='primary'
                        variant='outlined'
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </Button>
                }
                <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    onClick={onSave}
                    disabled={isSaving}>
                    {isSaving ? "Saving..." : "Finish"}
                </Button>
            </div>
            <LoadingSpinner loading={isSaving} />
        </div>
    );
}

export default CreateLoadGroup;