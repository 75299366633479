import * as React from "react";

function IconConditionReport(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3199 2.321C3.32076 1.32015 4.81842 0.9375 6.7489 0.9375H11.2489C13.1794 0.9375 14.677 1.32015 15.6779 2.321C16.6788 3.32185 17.0614 4.81952 17.0614 6.75V11.25C17.0614 13.1805 16.6788 14.6781 15.6779 15.679C14.677 16.6798 13.1794 17.0625 11.2489 17.0625H6.7489C4.81842 17.0625 3.32076 16.6798 2.3199 15.679C1.31905 14.6781 0.936401 13.1805 0.936401 11.25V6.75C0.936401 4.81952 1.31905 3.32185 2.3199 2.321ZM3.1154 3.1165C2.42875 3.80315 2.0614 4.93048 2.0614 6.75V11.25C2.0614 13.0695 2.42875 14.1969 3.1154 14.8835C3.80205 15.5702 4.92938 15.9375 6.7489 15.9375H11.2489C13.0684 15.9375 14.1958 15.5702 14.8824 14.8835C15.5691 14.1969 15.9364 13.0695 15.9364 11.25V6.75C15.9364 4.93048 15.5691 3.80315 14.8824 3.1165C14.1958 2.42985 13.0684 2.0625 11.2489 2.0625H6.7489C4.92938 2.0625 3.80205 2.42985 3.1154 3.1165ZM12.846 6.68793C13.0916 6.87826 13.1363 7.23159 12.946 7.47712L11.2102 9.7164C10.764 10.2807 9.94135 10.3836 9.36746 9.9401L9.36354 9.93707L7.99364 8.85908C7.90901 8.7948 7.79096 8.81079 7.72669 8.8937C7.72662 8.89378 7.72675 8.89362 7.72669 8.8937L5.94204 11.2107C5.75247 11.4569 5.39928 11.5027 5.15316 11.3131C4.90704 11.1236 4.8612 10.7704 5.05077 10.5243L6.8363 8.20606C7.28174 7.62962 8.10764 7.52543 8.68284 7.9699L8.68676 7.97293L10.0567 9.05092C10.1422 9.11588 10.2627 9.09914 10.3266 9.02001L12.0568 6.78788C12.2472 6.54235 12.6005 6.4976 12.846 6.68793Z" fill="#0090FE" />
        </svg>

    );
}

export default IconConditionReport;
