import { Grid, useMediaQuery } from '@material-ui/core';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { useEffect, useState } from 'react';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import FadeInImage from '../../../vehicles/components/FadeInImage/FadeInImage';
import { useGetLoadAttachmentsQuery, useGetLoadGroupAttachmentsQuery } from '../../loadsSlice';
import { loadAttachmentsStyle } from './LoadAttachmentsStyle';

function LoadAttachments({ groupId, loads, placeholder, showLoading = true, hasTitle }) {
    let { data: loadAttachments, isLoading: isLoadingAttachment } = useGetLoadAttachmentsQuery(loads?.[0]?.id, { skip: !!groupId || !loads?.[0]?.id });
    let { data: loadGroupAttachments, isLoading: isLoadingGroupAttachment } = useGetLoadGroupAttachmentsQuery(groupId, { skip: !groupId });

    let allAttachments = (groupId ? loadGroupAttachments : loadAttachments) ?? [];

    const isLoading = isLoadingAttachment || isLoadingGroupAttachment;

    const classes = loadAttachmentsStyle()
    const [galleryUrl, setGalleryUrl] = useState();
    const [filteredAttachments, setFilteredAttachments] = useState([]);
    const isMobile = useMediaQuery(mobileBreakpoint);

    pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
    const photoGalleryName = 'Pickup Photo Gallery';
    const photoGalleryNameFromServer = 'gallery_url';

    useEffect(() => {
        let url = galleryUrl;
        if (allAttachments?.length) {
            const att = allAttachments?.filter(a => a.originalFileName !== photoGalleryNameFromServer) ?? [];
            setFilteredAttachments([...att?.filter(a => a.originalFileName !== photoGalleryName), { originalFileName: photoGalleryName, fileUrl: url }]);
        }
    }, [galleryUrl]);

    // const getGalleryUrl = () => {
    //     const bol = loadAttachments?.find(a => a.originalFileName == photoGalleryNameFromServer);
    //     if (bol) {
    //         let pdfData = atob(bol.fileUrl);
    //         let doc = pdfjsLib?.getDocument({ data: pdfData });
    //         doc?.promise
    //             .then(function (doc) {
    //                 let page = doc.getPage(1);
    //                 page.then(function (page) {
    //                     let textContent = page.getTextContent();
    //                     textContent.then(function (text) {
    //                         let found = false;
    //                         for (const item of text.items) {
    //                             if (found && item.str.includes('https')) {
    //                                 setGalleryUrl(item.str);
    //                                 found = false;
    //                                 return;
    //                             }
    //                             if (item.str == "Vehicle Photos") {
    //                                 //The next item will be the gallery url
    //                                 found = true;
    //                             }
    //                         }
    //                     });
    //                 })
    //             });
    //     }
    // }

    useEffect(() => {
        if (allAttachments?.length) {
            const att = allAttachments?.filter(a => a.originalFileName !== photoGalleryNameFromServer) ?? [];
            setFilteredAttachments(att);
            // getGalleryUrl();
        }
    }, [allAttachments]);

    // if (!galleryUrl) {
    //     getGalleryUrl();
    // }

    const groupedAttachmentsByDate = {};

    filteredAttachments.forEach(attachment => {
        let date = new Date(attachment.createTime).toLocaleString("default", { year: 'numeric', month: 'long', day: 'numeric', });
        if (!groupedAttachmentsByDate[date]) groupedAttachmentsByDate[date] = [];
        groupedAttachmentsByDate[date].push({ ...attachment, createdDate: date });
    });

    let attachmentsDates = Object.keys(groupedAttachmentsByDate);
    attachmentsDates = attachmentsDates.sort((a, b) => (new Date(a) - new Date(b)));


    return (
        <div className={classes.attachmentsContainer}>
            {/* <LoadingSpinner loading={showLoading && isLoading} /> */}
            {filteredAttachments?.length ?
                <div>
                    {hasTitle && <div className={classes.title}>{`Load Attachments (${filteredAttachments.length})`}</div>}
                    {
                        attachmentsDates.map((attachmentDate) =>
                            <Grid container direction="column">
                                <Grid item>
                                    <div><b>{!!attachmentDate ? attachmentDate : 'Unknown'}</b></div>
                                </Grid>
                                <Grid container>
                                    {
                                        groupedAttachmentsByDate[attachmentDate].map((attachment, i) =>
                                            <Grid item key={i} className={isMobile && classes.imageMobile}>
                                                <FadeInImage
                                                    showFileName={true}
                                                    readonly={true}
                                                    img={{ url: attachment?.fileUrl, fileName: attachment?.originalFileName, thumbnail: attachment?.thumbnail, createdDate: attachment.createdDate }}
                                                    alt={attachment.originalFileName}
                                                    showCarousel
                                                    imagesForCarousel={groupedAttachmentsByDate}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </div> :
                placeholder ?? <EmptyPlaceholder text={isLoading ? 'Loading attachments...' : 'No attachments'} />
            }
        </div>
    );
}

export default LoadAttachments