import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import LoadingSpinner from 'components/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useStyles } from "./AddActivityPriceStyles";
import { useGetAllActivityItemsQuery } from 'features/activity/activitySlice';
import NotificationProvider from 'components/NotificationProvider';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useGetVehicleTypesQuery } from 'features/vehicles/vehicleSlice';
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { useAddActivityPriceMutation, useGetAllActivityPricingQuery } from 'features/pricing/pricingSlice';
import ActivityPriceDialogContent from '../ActivityPriceDialogContent';
import { toggleOptions } from '../pricingConsts';

function AddActivityPrice({ open, handleClose, setOpenModel }) {
    const classes = useStyles();

    let { data: activityPricing, error: activityPricingError, isFetching: isLoadingActivityPricing } = useGetAllActivityPricingQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const [addActivityPrice, { isLoading: isAdding }] = useAddActivityPriceMutation();

    const [activityPriceToAdd, setActivityPriceToAdd] = useState();

    useEffect(() => {
        setActivityPriceToAdd({
            lotId: [], 
            serviceProviderId: [], 
            priceAppliesTo: toggleOptions.SPECIFIC_LOTS, 
            includeNonPmf: false});
    }, [open])

    activityItems = activityItems || [];
    lots = lots || [];
    vehicleTypes = vehicleTypes || [];
    serviceProviders = serviceProviders || [];
    activityPricing = activityPricing || [];

    const onChange = (key, val) => {
        setActivityPriceToAdd({ ...activityPriceToAdd, [key]: val });
    };
    
    const onChangeVehicleTypes = (val) => {
        if(val[val.length - 1] == null && val.length !== 0) {
            val = [null];
            onChange("vehicleTypeIdsList", val);
        }
        else{
            if(activityPriceToAdd?.vehicleTypeIdsList?.includes(null)) { 
                val = val.filter(v => v != null);
                onChange("vehicleTypeIdsList", val);
            } else { 
                onChange("vehicleTypeIdsList", val);
            }
        }
    };

    const onClose = () => {
        setActivityPriceToAdd({})
        handleClose()
    }

    const onClickFinish = async () => {
        let existing = activityPricing.find(a =>
            a?.activityItemId === activityPriceToAdd?.activityItemId &&
            a?.serviceProviderId === activityPriceToAdd?.serviceProviderId &&
            a?.lotId === activityPriceToAdd?.lotId &&
            a?.vehicleTypeIdsList === activityPriceToAdd?.vehicleTypeIdsList &&
            a?.useInventory === activityPriceToAdd?.useInventory &&
            a?.allLots === activityPriceToAdd?.allLots &&
            a?.allPmfLots === activityPriceToAdd?.allPmfLots)
        if (existing) {
            setOpenModel(true)
        }
        else {
            activityPriceToAdd.vehicleTypeIdsList = activityPriceToAdd?.vehicleTypeIdsList?.includes(null) ? null : activityPriceToAdd?.vehicleTypeIdsList
            activityPriceToAdd.lotId = activityPriceToAdd?.priceAppliesTo == toggleOptions.SPECIFIC_LOTS ? activityPriceToAdd?.lotId : null;
            activityPriceToAdd.allLots = activityPriceToAdd?.priceAppliesTo == toggleOptions.ALL_LOTS ? true : false;
            activityPriceToAdd.allPmfLots = activityPriceToAdd?.priceAppliesTo == toggleOptions.ALL_PMF_LOTS ? true : false;           
            let result = await addActivityPrice(activityPriceToAdd);
            if (result && !result.error) {
                NotificationProvider.success("Activity added successfully");
                onClose();
            } else {
                NotificationProvider.error("Something happened, try again or contact support");
            }
        }
    }

    const isValid = activityPriceToAdd?.activityItemId && !!activityPriceToAdd?.serviceProviderId?.length && ((!activityPriceToAdd?.notSupported && activityPriceToAdd?.price) || activityPriceToAdd?.notSupported) 
                        && (activityPriceToAdd?.priceAppliesTo === toggleOptions.SPECIFIC_LOTS ? !!activityPriceToAdd?.lotId?.length : true);
    const isSaving = isAdding;
    const isLoading = isLoadingActivityItems || isLoadingLots || isLoadingVehicleTypes || isLoadingServiceProviders || isLoadingActivityPricing;

    return (
        <>
            <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                <Dialog open={open} onClose={onClose} >
                    <DialogTitle >
                        <div className={classes.dialogTitle}> Add Activity Price </div>
                    </DialogTitle>
                    <DialogContent>
                        <ActivityPriceDialogContent isLoading={isLoading} activityPrice={activityPriceToAdd} onChange={onChange} activityItems={activityItems} 
                            serviceProviders={serviceProviders} lots={lots} vehicleTypes={vehicleTypes} onChangeVehicleTypes={onChangeVehicleTypes} multiple={true} />
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.btnContainer}>
                            <Button className={classes.button} variant="outlined"
                                onClick={onClose}>Cancel</Button>
                            <Button
                                onClick={onClickFinish}
                                disabled={!isValid || isSaving}
                                className={classes.button}
                                variant="contained" >
                                {isSaving ? "Saving..." : "Finish"}
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <LoadingSpinner loading={isLoading || isSaving} />
            </Authorize>
        </>
    );
}

export default AddActivityPrice;

