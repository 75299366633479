import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { unauthorizedRouteStyle } from "./style";

const UnauthorizedRoute = ({ component: Component, profile, ...rest }) => {
    const classes = unauthorizedRouteStyle();
    return (
        <Grid className={classes.wrapper} direction="column" alignContent="center" alignItems="center">
            <div><h1>An error occured while processing your request.</h1></div>
            <div><Link to={'/'} >Go to home page</Link></div>
        </Grid>
    );
};

export default UnauthorizedRoute;
