import { makeStyles } from "@material-ui/core";

export const totalAssetsStyle = makeStyles((theme) => ({
    wrapper: {
        position: 'relative'
    },
    exportBtn: {
        position: 'absolute',
        right: '-1em'
    },
    count: {
        color: theme.palette.primary.dark,
        fontSize: 72,
        fontWeight: 700
    },
    text: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0
    },
    exportIcon: {

    }
}));