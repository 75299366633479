import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        filters: {
            lotId: [],
            clientId: '',
            date: {value: null}
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = dashboardSlice.actions;
