import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Select } from '@material-ui/core';
import { Grid } from '@mui/material';
import { useState } from 'react';
import ConfirmationPopup from '../../../../components/ConfirmationPopup';
import NotificationProvider from '../../../../components/NotificationProvider';
import { useGetLotsQuery } from '../../../lots/lotSlice';
import { useGetTransportersQuery } from '../../../transporter/transporterSlice';
import { usePostLoadMutation } from '../../loadsSlice';
import { postLoadStyle } from './PostLoadStyle';

const selectMenuStyle = {
    backgroundColor: '#f7f6fb',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.38)',

}

function PostLoad({ loads, btnStyle = 'contained' }) {
    const classes = postLoadStyle();
    let { data: transporters, error: transporterError, isLoading: loadingTransporters } = useGetTransportersQuery();
    let [postLoad, { isLoading: isLoadingDispatchLoad, isError, isSuccess }] = usePostLoadMutation();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    const [open, setOpen] = useState(false);
    const [lane, setLane] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const [createState, setCreateState] = useState('false');

    transporters = transporters?.filter(t => t.usdot)

    const flatLanes = transporters?.filter(t => t.lanes?.length).flatMap(t => t.lanes?.map(lane => ({ transporterId: t.id, transporterName: t.name, ...lane }))) ?? [];

    const openDialog = async () => {
        setOpen(true);
    }
    const closeDialog = async () => {
        setOpen(false);
    }

    const dispatch = async () => {
        setOpenPopup(true)
    }

    const handleChange = (event) => {
        let lane = flatLanes.find(t => t.id.toString() === event.target.value);
        setLane(lane);
        if (event.target.value) {
            setShowError(false)
        }
    };

    const onConfirm = async () => {
        let result = await postLoad([{
            loadIds: loads?.map(l => l.id),
            groupIds: loads?.map(l => l.groupId),
            transporterId: lane?.transporterId,
            laneId: lane?.id,
        }]);
        if (result?.error) {
            NotificationProvider.error(`Operation failed. ${result?.error?.data?.messages.join(" ")}`)
        } else {
            if (result?.data?.success) {
                NotificationProvider.success(`Successfully posted to LMP`);
            } else {
                NotificationProvider.error(`${result?.data?.messages?.join('; ')} `);
            }
        }
        setOpenPopup(false);
        setOpen(false);
    }

    return (
        <div>
            <Button
                variant={btnStyle}
                color="primary"
                onClick={openDialog}
            >
                Post Load to LoadMate
            </Button>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Post Load to LoadMate</DialogTitle>
                <DialogContent>
                    <Grid className={classes.box} item>
                        <div>
                            <b>
                                {loads?.map(load => <div>{`${lots?.find(l => l.id == load?.pickupLocationId)?.name} -> ${lots?.find(l => l.id == load?.deliveryLocationId)?.name} `}</div>)}
                            </b>
                        </div>

                        {loads?.length == 1 && <FormControl>
                            <RadioGroup
                                row
                                name="create-dispatch-radio-group"
                                value={createState}
                                onChange={(e) => {
                                    if (e.target.value == 'false') {
                                        setLane();
                                    }
                                    setCreateState(e.target.value)
                                }
                                }
                            >
                                <FormControlLabel value="false" control={<Radio />} label="Create only" />
                                <FormControlLabel value="true" control={<Radio />} label="Create and tender to carrier" />
                            </RadioGroup>
                        </FormControl>}

                        {createState == 'true' && <div>
                            <Select native
                                label={'Select bid'}
                                className={classes.select}
                                variant='outlined'
                                value={lane?.id}
                                onChange={handleChange}
                            >
                                <option style={selectMenuStyle} aria-label="None" value="" ></option>
                                {flatLanes?.filter(lane => lane.pickupLocationId == loads?.[0]?.pickupLocationId && lane.deliveryLocationId == loads?.[0]?.deliveryLocationId)?.map((lane) => (
                                    <option style={selectMenuStyle} value={lane.id} id={lane.id}>
                                        {lane.transporterName}
                                        {` - $${lane.amount} `}
                                    </option>
                                ))}
                            </Select>
                        </div>}
                        <div className={classes.btnWrapper}>
                            <Button
                                className={classes.dispatchButton}
                                variant="contained"
                                color="primary"
                                onClick={dispatch}
                            >
                                Submit
                            </Button>
                        </div>
                    </Grid>
                </DialogContent>
                <ConfirmationPopup
                    onCancel={() => setOpenPopup(false)}
                    onConfirm={onConfirm}
                    open={openPopup}
                    loading={isLoadingDispatchLoad}
                >
                    <div>{`Are you sure you want to submit the load`}</div>
                </ConfirmationPopup>
            </Dialog>
        </div>
    );
}

export default PostLoad;
