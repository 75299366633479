import { makeStyles } from "@material-ui/core"

export const loadDispatchStyle = makeStyles((theme) => ({
   error: {
    color: 'red'
   },
   dispatchButton: {
    margin: '1rem'
   },
   select: {
    width: '13rem'
   }
}));