import { Checkbox, Dialog, ListItem, Paper, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList, VariableSizeList } from "react-window";
import mobileBreakpoint from 'utils/mobileBreakpoint';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import LoadCard from '../LoadCard';
import LoadGroupDetails from '../LoadGroupDetails';
import { loadsListStyle } from './LoadsListStyle';
import { arrayToObject } from 'utils/object-util';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { FlagAsset } from 'features/vehicles/Flags/FlagAsset';
import { FlagEntityTypes } from 'features/flag/flagsConsts';

function LoadsList({
    loads,
    readinessForLoads,
    contactsForLoad,
    reload,
    selectedRows,
    toggleRowSelected,
    canSeeFlags
}) {
    const classes = loadsListStyle()
    const [selectedLoad, setSelectedLoad] = useState();

    const history = useHistory();

    const isMobile = useMediaQuery(mobileBreakpoint);

    useEffect(() => {
        if (selectedLoad) {
            setSelectedLoad(loads.find(l => l.id == selectedLoad.id))
        }
    }, [loads])

    const openLoad = (load) => {
        history.push(`/load/group/${load.id}`);
    }

    const Row = ({ index, style }) => {
        const load = loads[index];
        return <ListItem style={style} key={load.id} className={`${classes.listItem} ${canSeeFlags && load?.latestFlag ? classes.flaggedLoad : ""}`} selected={selectedLoad?.id == load.id} >
            <Authorize profile={permissionProfiles.LOAD.LOAD_BULK_ACTIONS}>
                <Checkbox
                    checked={selectedRows.includes(load?.id)}
                    onChange={(e, checked) => toggleRowSelected(checked, load.id)}
                />
            </Authorize>
            <FlagAsset
                iconOnly
                selectedRows={load?.loads?.map(load => load.id)}
                flagOn={load?.latestFlag}
                flagObj={load?.latestFlag}
                getFlagFromApi={false}
                allData={load?.loads?.map(load => ({ ...load, vehicleId: load?.assets?.[0]?.id }))}
                entityType={FlagEntityTypes.TRANSPORT}
                readonly
            />
            <LoadCard
                isGroup
                showJourney={!isMobile}
                key={load.id}
                load={load} readiness={readinessForLoads[load?.id]}
                contacts={contactsForLoad[load?.id]}
                onViewClick={() => openLoad(load)}
            />
        </ListItem>
    };
    const loadsLookup = arrayToObject(loads);

    const getItemSize = (index) => {
        let height = 250;
        const load = loads[index];
        if (load?.loads?.length > 1 || load?.activities?.length > 0) {
            height = 350
        }
        return height;
    }

    return (
        <>
            {!(!!loads?.length) && <EmptyPlaceholder className={classes.listWrapper} />}
            {!!loads?.length &&
                <Paper className={classes.listWrapper}>

                    <AutoSizer>
                        {({ height, width }) => (
                            <VariableSizeList
                                className="List"
                                height={height}
                                itemCount={loads?.length}
                                itemSize={getItemSize}
                                width={width}
                            >
                                {Row}
                            </VariableSizeList>
                        )}
                    </AutoSizer>
                </Paper>}
            <Dialog
                onClose={() => {
                    setSelectedLoad();
                }}
                open={!!selectedLoad}
                maxWidth={'lg'}
                fullWidth={true}
            >
                {
                    !!selectedLoad ? <LoadGroupDetails
                        loadGroup={selectedLoad}
                        readinessForLoads={readinessForLoads}
                        reload={reload}
                    /> : <></>
                }

            </Dialog>
        </>
    );
}

export default LoadsList;