import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { ConfigureLotStyle } from "./ConfigureLotStyles";
import CustomInput from "components/CustomInput";
import { useEffect, useState } from "react";
import { useUpdateLotMutation } from "features/lots/lotSlice";
import NotificationProvider from "components/NotificationProvider";


const ConfigureLot = ({ open, onCancel, lot }) => {

    const classes = ConfigureLotStyle();

    let [updateLot, { isLoading: updateLotLoading }] = useUpdateLotMutation();

    const days = [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thursday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" },
    ]

    const [auditDay, setAuditDay] = useState(lot?.auditDay);
    
    useEffect(() => {
        setAuditDay(lot?.auditDay);
    }, [lot]);

    const handleSave = async () => {

        let lotToSave = {
            ...lot,
            auditDay: auditDay === "" ? null : auditDay,
        }

        let res = await updateLot(lotToSave);

        handleClose();

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }
    }

    const handleClose = () => {
        onCancel();
        setAuditDay(lot?.auditDay);
    }

    return (
        <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title" maxWidth='sm'>
            <DialogTitle id="form-dialog-title">
                {'Configure Lot'}
            </DialogTitle>
            <DialogContent>
                <CustomInput
                    label="Audit Day"
                    elementType="dropdown"
                    value={auditDay}
                    values={days}
                    onChange={(val) => setAuditDay(val)}
                    showEmpty
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary" disabled={updateLotLoading}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    disabled={updateLotLoading}
                >
                    {updateLotLoading ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfigureLot;