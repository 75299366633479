import { Button, Grid, IconButton, Paper, Card, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { getLotsWithPmfText } from "features/lots/lotUtil";
import { selectIsOwner } from "features/user/userSlice";
import { useUpdateUserRestrictionClaimsMutation } from "features/users/usersSlice";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InfoOutlined } from "@material-ui/icons";

const { default: UsersPageStyle } = require("../../UsersPageStyle");

const useStyles = UsersPageStyle;

const EditUserRestrictions = ({ user, userClaims, userLotRelations }) => {
    const classes = useStyles();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    clients = clients || [];
    lots = lots || [];

    //const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));

    const [updateRestrictions] = useUpdateUserRestrictionClaimsMutation();

    const [lotClaims, setLotClaims] = useState([]);
    const [clientClaims, setClientClaims] = useState([]);
    const [clientAccountClaims, setClientAccountClaims] = useState([]);

    const [hasChanged, setHasChanged] = useState(false);
    const [saving, setSaving] = useState(false);

    const lotIds = lots?.map(x => x.id);
    const clientIds = clients?.map(x => x.id);
    //const clientAccountIds = clientAccounts?.map(x => x.id);

    useEffect(() => {    
        let lotIdClaims = userClaims?.filter(c => (c.claimType === 'lotId') && lotIds?.includes(parseInt(c.claimValue)));
        let clientIdClaims = userClaims?.filter(c => (c.claimType === 'clientId') && clientIds?.includes(parseInt(c.claimValue)));
       // let clientAccountClaims = userClaims?.filter(c => (c.claimType === 'clientAccountId') && clientAccountIds?.includes(parseInt(c.claimValue)));
        
        setLotClaims(lotIdClaims ?? []);
        setClientClaims(clientIdClaims ?? []);
        //setClientAccountClaims(clientAccountClaims ?? []);
    }, [userClaims])


    const handleRestrictionsSave = async () => {
        setSaving(true);

        const objToSend = {
            userId: user?.id,
            lotClaimEntities: lotClaims,
            clientClaimEntities: clientClaims
            //clientAccountClaimEntities: clientAccountClaims
        }

        let result = await updateRestrictions(objToSend);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    }

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    // const clientAccountLookup = clientAccounts?.reduce(function (map, obj) {
    //     map[obj.id] = obj;
    //     return map;
    // }, {})

    const onAddLot = (lotId) => {
        setHasChanged(true);
        let lotIdList = [...lotClaims];
        let toAdd = {
            userId: user?.id,
            claimType: "lotId",
            claimValue: lotId
        }
        lotIdList.push(toAdd);
        setLotClaims(lotIdList);
    }

    const onAddClient = (clientId) => {
        setHasChanged(true);
        let clientIdList = [...clientClaims];
        let toAdd = {
            userId: user?.id,
            claimType: "clientId",
            claimValue: clientId
        }
        clientIdList.push(toAdd);        
        setClientClaims(clientIdList);
    }

    const onRemoveLot = (lotId) => {
        setHasChanged(true);
        let lotIdList = lotClaims;
        lotIdList = lotIdList.filter(l => l.claimValue != lotId)
        setLotClaims(lotIdList);
    }

    const onRemoveClient = (clientId) => {
        setHasChanged(true);
        let clientIdList = clientClaims;
        clientIdList = clientIdList.filter(c => c.claimValue != clientId)
        setClientClaims(clientIdList);
        
        //setClientAccountClaims([]);
    }

    const isOwner = useSelector(selectIsOwner);

    let isRM = userLotRelations?.filter(u => u.relationType === 3); //lot association with rm
    let isSuper = userLotRelations?.filter(u => u.relationType === 2);
    let lotslist = []

    const getLotValues = () => {
        if((isRM?.length > 0) && isOwner) {
            lotslist = (lots?.filter(l => (isRM?.map(r => r.lotId)?.includes(l.id)) && (!lotClaims?.find(c => c.claimValue === l.id.toString()))));
            return getLotsWithPmfText(lotslist);
        }
        if (isSuper?.length > 0) {
            lotslist = (lots?.filter(l => (isSuper?.map(r => r.lotId)?.includes(l.id)) && (!lotClaims?.find(c => c.claimValue === l.id.toString()))));
            return getLotsWithPmfText(lotslist);
        }
        if (isOwner && (!isRM?.length > 0)) {
            return getLotsWithPmfText(lots);
        }
    }

    const clientValues = (val) => {
        let values = clients?.filter(c => !clientClaims?.find(l => l.claimValue === (c.id).toString()) || c.id === val)
        return values;
    }

    // const getSubClients = (clientId) => {
    //     let accounts = clientAccounts?.filter(c => c.clientId === parseInt(clientId));
    //     const index = accounts?.findIndex(c => c.isDefault == true)
    //     if(index !== -1)
    //     {
    //         let movedObject = accounts.splice(index, 1)[0];
    //         movedObject = {...movedObject, name: "Default"}
    //         accounts.unshift(movedObject);
    //         accounts = [...accounts];
    //     } 
    //     return accounts;
    // }

    // const onCheck = (accountId, isChecked) => {
    //     setHasChanged(true);
    //     setClientAccountClaims((clientAccountClaims) => {
    //         if (isChecked) {
    //             return [
    //                 ...clientAccountClaims,
    //                 {
    //                     userId: user?.id,
    //                     claimType: 'clientAccountId',
    //                     claimValue: accountId.toString(),
    //                 },
    //             ];
    //         } else {
    //             return clientAccountClaims.filter((c) => c.claimValue !== accountId.toString());
    //         }
    //     });
    // }

    return (
        <>
            <Paper className={classes.paper}>
                <div className={classes.label}>Lot Access</div>
                <Grid item className={classes.grids}>
                    <CustomInput
                        id="lot"
                        label="Add Lot Access"
                        value={""}
                        elementType="dropdown"
                        onChange={val => onAddLot(val)}
                        values={getLotValues()}
                        showEmpty
                    />
                </Grid>
                {
                    lotClaims?.map(claim =>
                        <Card className={classes.list}>
                            <Grid item className={classes.restrictions} alignItems='center'>
                                {lotLookup[claim?.claimValue]?.name}
                                <IconButton edge="end" aria-label="delete"
                                    onClick={() => onRemoveLot(claim?.claimValue)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Card>
                    )
                }
                <div className={classes.label}>Client Access</div>
                <Grid item className={classes.grids}>
                {/* <div style={{ position: 'relative' }}>
                <Grid item className={classes.grids} direction="row"> */}
                    <CustomInput
                        id="clientId"
                        label="Add Client Access"
                        value={""}
                        elementType="dropdown"
                        onChange={val => onAddClient(val)}
                        values={clientValues()}
                        showEmpty   
                       // disabled={!!clientAccountClaims.length}
                    />
                    {/* {!!clientAccountClaims.length &&
                        <Tooltip 
                            title='Only one top level and sub client combination is supported.
                                Remove the sub client selection to add additional clients'
                            placement="right-end">
                                <InfoOutlined className={classes.icon} />
                        </Tooltip>
                    } */}
                </Grid>
                {/* </div> */}
                {
                    clientClaims?.map(claim =>
                        <Card className={classes.list}>
                            <Grid item className={classes.restrictions} alignItems='center'>
                                {clientLookup[claim?.claimValue]?.name}
                                <IconButton edge="end" aria-label="delete"
                                    onClick={() => onRemoveClient(claim?.claimValue)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            {/* {clientClaims?.length === 1 && !!getSubClients(claim?.claimValue)?.filter(c => c.isDefault === false).length ? 
                                <Grid item>
                                    <div>Select Sub Clients:</div>
                                    {getSubClients(claim?.claimValue)?.map(account =>
                                        <FormControlLabel
                                            key={account.id}
                                            control={<Checkbox
                                                checked={clientAccountClaims?.find(c => parseInt(c.claimValue) === account?.id)}
                                                onChange={(e) => {onCheck(account?.id, e.target.checked)}}
                                            />}
                                            label={clientAccountLookup[account.id].name}
                                        />
                                    )
                                    }
                            </Grid> : null} */}
                        </Card>
                    )
                }
                {hasChanged ?
                    <Grid container className={classes.wrapperSave}>
                        <Grid item>
                            <Button
                                className={classes.resetButton}
                                onClick={handleRestrictionsSave}
                                color="primary"
                                variant="contained"
                                disabled={saving}
                            >
                                {saving ? 'Saving' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid> : null}

            </Paper>
        </>
    )
}

export default EditUserRestrictions;