import { createSlice } from "@reduxjs/toolkit";

export const workorderDashboardSlice = createSlice({
    name: 'workorderDashboardSlice',
    initialState: {
        filters: {
            lotId: [],
            clientId: '',
            month: 0
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = workorderDashboardSlice.actions;
