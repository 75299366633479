export const NoteEntityTypes = {
    VEHICLE: 1,
    VEHICLE_DETAIL: 2,
    ACTIVITY: 3,
    MECHANICAL_WORK: 4,
    VEHICLE_IMAGE: 5,
    LOT: 6,
    TRANSPORT: 7,
    WORKORDER_ATTACHMENT: 8,
    ISSUE_TRACKER: 9,
    ISSUE_TRACKER_STATUS: 10,
    RESERVATION: 11
}