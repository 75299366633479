import { CircularProgress, Grid, Paper } from '@material-ui/core'
import React from 'react'
import LoadingSpinner from '../LoadingSpinner';
import { useConfirmKeyQuery } from './confirmationSlice';
import { externalConfirmationStyle } from './ExternalConfirmationStyle';

const ExternalConfirmation = ({ }) => {
    const classes = externalConfirmationStyle();
    const key = new URLSearchParams(window.location.search).get('key');
    let { data, error, isLoading } = useConfirmKeyQuery(key);

    return (
        <div className={classes.container}>
            <img
                src="/images/ParqAIlogo.png"
                className={classes.logo}
                alt="parq.ai"
            />
            {isLoading ? <><div><CircularProgress /></div><div>Processing...</div></> :
                error ? <div> We are sorry but we were unable to process your request.<br /><br />Please send this key when reporting an issue: {key}</div> :
                    <div>

                        Thank you<br /> We have received your confirmation
                    </div>
            }
        </div>
    )
}

export default ExternalConfirmation
