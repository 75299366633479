export const relationTypeEnum = [
    { id: 1, name: "Attendant" },
    { id: 2, name: "Supervisor" },
    { id: 3, name: "Regional Manager" }
];

export const relationTypeEnumById = {
    ATTENDANT: 1,
    SUPERVISOR: 2,
    REGIONAL_MANAGER: 3
};

export const userTypeEnums = {
    InternalPMF: 1,
    Client: 2,
};

export const userTypeOptionsEnums = [
    {id: "1", name: "Internal PMF"},
    {id: "2", name: "Client"}
];