import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    formContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        margin: 20
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
        minHeight: 300
    },
    disabledForm: {
        pointerEvents: 'none',
        opacity: 0.7
    },
    input: {
        margin: 16,
    },
    dateInput: {
        margin: 16,
        display: 'flex',
        alignItems: "center",
    },
    tooltipIcon: {
        marginLeft: '0.5rem'
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    inverseButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        margin: 15,
        borderColor: theme.palette.primary.main,
        width: '100%',
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.7
        }
    },
    notesButton: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        color: theme.palette.primary.main,
        margin: 20,
        borderColor: theme.palette.primary.main
    },
    icon: {
        fill: theme.palette.common.white,
    },
    inputIcon: {
        fill: theme.palette.text.secondary,
        width: 30
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    btnGroup: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    addPhoto: {
        marginTop: 20
    },
    error: {
        textAlign: "center",
        fontSize: 18,
        color: theme.palette.error.main,
        maxWidth: 500,
        margin: "0 auto 16px auto"
    },
    hiddenButtons: {
        visibility: "hidden",
        opacity: 0
    },
    radioLable: {
        paddingRight: 10,
    },
    radioContainer: {
        alignItems: "center",
        margin: 'auto',
        fontSize: 16,
    },
    radioButton: {
        margin: '0.5rem',
    },
    lot: {
        border: "solid 1px lightgrey",
        borderRadius: '5px',
        marginBottom: 20
    },
    lotAddress: {
        paddingLeft: '1.5rem'
    },
    existingLotsContainer: {
        margin: '16px',
        padding: '0.5em 1em',
        borderRadius: '5px',
        border: '1px solid #36a03b',
        background: '#e4ffe4'
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        padding: 20,
        marginRight: 32
    },
    existingLotsTitle: {
        fontWeight: 'bold',
        padding: '0.4em 0',
        fontSize: 16
    }
}));
