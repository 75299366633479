import { createSlice } from "@reduxjs/toolkit";

export const reportsFilterSlice = createSlice({
    name: 'reportsFilter',
    initialState: {
        filters: {
            clientId: { value: null },
            createdDate: { value: null }
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters: (state, action) => {
            state.filters = {
                clientId: { value: null },
                createdDate: { value: null }
            }
        },
    },
});

export const { setFilters, resetFilters } = reportsFilterSlice.actions;
export default reportsFilterSlice.reducer;

