import {createSlice} from "@reduxjs/toolkit";

export const activityPricingFiltersSlice = createSlice({
    name: 'activityPricingFilters',
    initialState: {
        filters: {
            activityItemId: {value: null},
            serviceProviderId: {value: null},
            lotId: {value: null},
            vehicleTypeIdsList: {value: null},
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
});

export const { setFilters } = activityPricingFiltersSlice.actions;
export default activityPricingFiltersSlice.reducer;
