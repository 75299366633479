import { Grid } from '@material-ui/core';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import FormatService from '../../../../services/formatService';
import { useGetLoadGroupPaymentsQuery, useGetLoadPaymentsQuery } from '../../loadsSlice';
import { loadPaymentsStyle } from './LoadPaymentsStyle';

function LoadPayments({ groupId, load, placeholder, showLoading = true, hasTitle }) {

    let { data: loadPayments, isLoading: isLoadingLoadPayments } = useGetLoadPaymentsQuery(load?.id, { skip: !load?.id || groupId });
    let { data: loadGroupPayments, isLoading: isLoadingGroupPayments } = useGetLoadGroupPaymentsQuery(groupId, { skip: !groupId });

    const allPayments = groupId ? loadGroupPayments : loadPayments;
    const isLoading = isLoadingLoadPayments || isLoadingGroupPayments;

    const classes = loadPaymentsStyle()

    return (
        <Grid container direction='column' spacing={1} className={classes.paymentsWrapper}>
            {/* <LoadingSpinner loading={showLoading && isLoading} /> */}
            {allPayments?.length ?
                allPayments?.map(payment => <Grid container item>
                    <Grid >{payment.description}</Grid><div className={classes.paymentAmount}>{FormatService.formatCurrency(payment.amount)}</div>
                </Grid>)
                :
                placeholder ?? <EmptyPlaceholder text={isLoading ? 'Loading...' : 'No payments'} />
            }
        </Grid>
    );
}

export default LoadPayments