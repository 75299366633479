import { Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DropdownFilter from 'components/DropdownFilter';
import LoadingSpinner from "components/LoadingSpinner";
import { selectIsAccountManager, selectIsOwner, selectUserId } from "features/user/userSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";
import { useGetAllRelationsQuery } from 'features/users/usersSlice';
import { IconClipboard } from "icons";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserTaskGroups from "../UserTaskGroups/UserTaskGroups";
import { SupersTaskListStyle } from './style';

const SupersTaskList = () => {
    const classes = SupersTaskListStyle();

    let isOwner = useSelector(selectIsOwner);
    let userId = useSelector(selectUserId);

    const [selectedSuper, setSelectedSuper] = useState(null);
    const [dropdownValues, setDropdownValues] = useState([]);

    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    allRelations = allRelations || [];



    useEffect(() => {
        if (!isLoadingAllRelations && allRelations) {
            if (!isOwner && userId)
                allRelations = allRelations?.filter(relation => relation.userId == userId);
            let supervisors = [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.SUPERVISOR))];
            const groupedToUserId = groupBy(supervisors, 'userId');
            setDropdownValues(Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].name ?? groupedToUserId[key]?.[0].userName }))?.filter(u => u.name));
            setSelectedSuper(supervisors?.length > 0 && !isOwner ? userId : null);
        }
    }, [allRelations, isLoadingAllRelations]);

    return (
        <Authorize profile={permissionProfiles.TASKS.SUPERS_TASK_LIST}>
            {
                isLoadingAllRelations ? <LoadingSpinner loading={isLoadingAllRelations} /> :
                    <>
                        <Paper className={classes.card}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <IconClipboard />
                                        </Grid>
                                        <Grid item>
                                            <h3 className={classes.title}>Supervisor Tasks</h3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isOwner && <Grid item xs={3} className={classes.supersFilter}>
                                    <DropdownFilter
                                        title={'Select supervisor'}
                                        values={dropdownValues}
                                        onSelect={(event) => setSelectedSuper(event)}
                                        value={selectedSuper}
                                        showEmpty
                                    />
                                </Grid>}
                            </Grid>
                        </Paper>

                        <Grid item>
                            <Paper className={classes.card}>
                                <Grid container direction="column" spacing={1} >
                                    <UserTaskGroups
                                        selectedUser={selectedSuper}
                                        lotId={allRelations?.find(u => u.userId == selectedSuper)?.lotId}
                                        isSuper={true}
                                    />
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
            }
        </Authorize>

    );
}
export default SupersTaskList;