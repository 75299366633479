export const userPermissions = {
    CREATE: {
        key: 'User.Create',
        name: 'Create users'
    },
    MANAGE_PERMISSIONS: {
        key: 'Permission.ManagePermissions',
        name: 'Manage permissions',
    },
    ARCHIVE_PERMISSIONS: {
        key: 'Permission.ArchivePermissions',
        name: 'Archive permissions',
    },

    BLACKLIST_USERS: {
        key: 'Users.blacklist.users',
        name: 'Black List permissions'
    },
    CREATE_USERS: {
        key: 'Users.create.users',
        name: 'Access'
    },    
    ACCESS: {
        key: 'Users.access',
        name: 'Access'
    },
    SEE_USERS: {
        key: 'Users.see.users',
        name: 'See users'
    },
    SEE_USER_DASH: {
        key: 'Users.see.user.dash',
        name: 'See user dashboard'
    },
    SEE_ROLE_DEFINITIONS: {
        key: 'Users.see.role.definitions',
        name: 'See role definitions'
    },
    EDIT_ROLE_DEFINITIONS: {
        key: 'Users.edit.role.definitions',
        name: 'Edit role definitions'
    },
    EDIT_USERS: {
        key: 'Users.edit.users',
        name: 'Edit users'
    },
    USER_DETAILS: {
        key: 'Users.user.details',
        name: 'User details'
    },
    USER_ACCESS: {
        key: 'Users.user.access',
        name: 'User access'
    },
    USER_TASKS: {
        key: 'Users.user.tasks',
        name: 'User tasks'
    },
    USER_PERMISSION: {
        key: 'Users.permissions',
        name: 'Permission'
    },
    DELETE_USERS: {
        key: 'Users.delete.users',
        name: 'Delete users'
    },
    ACCESS_QR_CODE: {
        key: 'Users.access.qr.code',
        name: 'Access QR code'
    },
    VIEW_ACCESS_TABLE: {
        key: 'Users.view.access.table',
        name: 'View access table'
    }
};
