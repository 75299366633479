import { Button, Grid, Paper, Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import NoteList from 'features/notes/NoteList';
import { NoteEntityTypes } from 'features/notes/notesConsts';
import { IconCar, IconDollar, IconMap } from 'icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { arrayToObject } from 'utils/object-util';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import CancelLoad from '../CancelLoad';
import LoadAssets from '../LoadAssets';
import LoadAttachments from '../LoadAttachments';
import LoadCard from '../LoadCard';
import LoadDispatch from '../LoadDispatch';
import { CardTitle } from '../LoadGroupDetails/LoadCardTitle';
import LoadHistory from '../LoadHistory';
import LoadMap from '../LoadMap';
import LoadPayments from '../LoadPayments';
import LoadStatusUpdate from '../LoadStatusUpdate';
import PostLoad from '../PostLoad';
import { loadDetailsStyle } from './LoadDetailsStyle';

function LoadDetails({
    load,
    readiness,
    contacts,
    loadGroup
}) {
    let { data: lots, error: lostError, isLoading: isLoadingLots, isSuccess: isSuccessLots } = useGetLotsQuery(null, { skip: !load || (load?.pickupLocation && load?.deliveryLocation) });
    const classes = loadDetailsStyle()
    const [showLocations, setShowLocations] = useState(false);
    const history = useHistory();

    const setShowLocationsVal = (val) => {
        setShowLocations(val.target.checked);
    };

    const lotsLookup = arrayToObject(lots, 'id');

    const loadData = {
        ...load,
        pickupLocation: load?.pickupLocation ?? lotsLookup?.[load?.pickupLocationId],
        deliveryLocation: load?.deliveryLocation ?? lotsLookup?.[load?.deliveryLocationId]
    }

    return (
        <Grid container className={classes.container} spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.card} >
                    <LoadCard loadGroup={loadGroup} load={loadData} contacts={contacts} showFlag />
                </Paper>
            </Grid>
            {loadData.comments && <Grid xs={12} item>
                <Paper className={classes.card}>
                    {loadData.comments}
                </Paper>
            </Grid>}
            <Grid item container spacing={3}>
                <Grid item xs={6}>
                    <Paper className={`${classes.assetsCard} ${classes.card}`} elevation={1}>
                        <CardTitle title='Assets' icon={<IconCar height={'1.2em'} />} />
                        <LoadAssets assets={loadData.assets} readiness={readiness} />
                    </Paper>
                    <Paper className={classes.card} elevation={1}>
                        <CardTitle title='History' />
                        <LoadHistory load={loadData} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.longCard} elevation={1}>
                        <CardTitle title='Transport Map' icon={<IconMap />} />
                        <FormControlLabel
                            value={showLocations}
                            control={<Switch color="primary" />}
                            label="Show locations"
                            labelPlacement="start"
                            onChange={setShowLocationsVal}
                        />
                        <LoadMap loads={[loadData]} showLocations={showLocations} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.card}>
                    <CardTitle title='Attachments' />
                    <LoadAttachments loads={[loadData]} />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.card}>
                    <NoteList entityId={loadData?.id} entityType={NoteEntityTypes.TRANSPORT} />
                </Paper>
            </Grid>
            <Authorize profile={permissionProfiles.LOAD.LOAD_PAYMENTS}>
                <Grid item xs={12}>
                    <Paper className={classes.card} >
                        <CardTitle title='Payments' icon={<IconDollar />} />
                        <LoadPayments load={loadData} />
                    </Paper>
                </Grid>
            </Authorize>
            <Grid className={classes.footer} item xs={12} justifyContent='center' container spacing={1}>
                <Authorize profile={permissionProfiles.LOAD.LOAD_ACTIONS}>
                    {load?.lmId && <div><Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/invoices/loads/new/?loadId=${loadData?.id}`)}
                    >
                        Create Invoice
                    </Button></div>}
                    {loadData?.readyForPosting && !load?.lmId && <PostLoad loads={[loadData]} />}
                    {loadData?.lmId && <LoadDispatch load={loadData} />}
                    <LoadStatusUpdate loads={[loadData]} />
                    <CancelLoad load={loadData} />
                </Authorize>
            </Grid>
        </Grid>
    );
}

export default LoadDetails