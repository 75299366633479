import React, { useContext, useEffect, useState } from "react";
import { PromptContext } from "./PromptContext";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const Prompt = ({

}) => {
  const [prompt, setPrompt] = useContext(PromptContext);
  const [internalLoading, setLoading] = useState();

  const {
    isOpen,
    props: { content, title, onConfirm, onCancel, loading }
  } = prompt;

  const closePrompt = () => setPrompt({ ...prompt, isOpen: false });

  const cancelAction = () => {
    closePrompt();
    onCancel && onCancel();
  }

  const confirmAction = async () => {
    setLoading(true);
    onConfirm && (await onConfirm());
    setLoading(false);
    closePrompt();
  }

  useEffect(() => {
  }, []);

  return (
    <Dialog open={isOpen} onClose={() => onCancel(false)} aria-labelledby='dialog-title'>
      <DialogTitle id={'dialog-title'} >{title ?? 'Confirm'}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction} color='primary'>
          {onConfirm ? 'Cancel' : 'Close'}
        </Button>
        {onConfirm && <Button disabled={internalLoading} onClick={confirmAction} color='primary'>
          Confirm
        </Button>}
        {internalLoading && <CircularProgress size={'1em'} />}
      </DialogActions>
    </Dialog>
  );
};