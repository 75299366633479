import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import Authorize from "../../../../components/Authorize/Authorize";
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import {ExportLotButtonStyle} from "./ExportLotButtonStyle";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import FormatService from "../../../../services/formatService";

const ExportLotButton = ({lots}) => {
    const [showModal, setShowModal] = useState(false);
    const [nonPMFLotEnable, setNonPMFLotEnable] = useState(false);
    const [includeAllDetailsEnable, setIncludeAllDetailsEnable] = useState(false);
    const classes = ExportLotButtonStyle();
    const uniqueNames = new Set();
    lots.forEach(lot => {
        lot.clientLots.forEach(clientLot => {
            uniqueNames.add(clientLot.name);
        });
    });
    const onSubmitSelectedFields = () => {
        setShowModal(false);
    }
    
    const mapExportData = (lot) => {
        const dynamicData = Array.from(uniqueNames).reduce((acc, name) => {
            const clientLot = lot.clientLots.find(cl => cl.name === name);
            acc[name] = clientLot ? clientLot.clientAlias : '';
            return acc;
        }, {});

        const basicData  = ({
            ['Lot Name']: lot?.name,
            ['SKU']: lot?.sku,
            ['Address 1']: lot?.addressLine1,
            ['Address 2']: lot?.addressLine2,
            ['City']: lot?.city,
            ['State']: lot?.state,
            ['Zip']: lot?.zipCode,
            ['Phone Number']: lot?.phoneNumber,
            ['Number Of Assets']: lot?.numberOfAssets,
            ['Number Of Spots']: lot?.numberOfSpots
        });
        
        const otherData = includeAllDetailsEnable ? ({           
            ['PMF Lot']: lot?.pmfLot? "Yes" : "No",
            ['POC Email']: lot?.pocEmail,
            ['POC Name']: lot?.pocName,
            ['Size in Acres']: lot?.size,
            ['Truck Spots Count']: lot?.truckSpotsCount,
            ['Car Spots Count']: lot?.carSpotsCount,
            ['Office Count']: lot?.officeCount,
            ['Bathroom Count']: lot?.bathroomCount,
            ['Amenties Count']: lot?.amentiesCount,
            ['Mech Room Count']: lot?.mechRoomCount,
            ['Audit Day']: lot?.auditDay,
            ['Time Zone Data']: lot?.timeZoneData?.daylightName,
            ['Wash Price']: FormatService.formatCurrency(lot?.washPrice),
            ['Detailing Price']: FormatService.formatCurrency(lot?.detailingPrice),
            ['Driving Directions']: lot?.drivingDirections,
            ...dynamicData,
        }) : {};      
        
        return {
            ...basicData,
            ...otherData
        };
    }

    const filteredLots = nonPMFLotEnable ? lots : lots.filter(lot => lot.pmfLot);
    
    return (
        <div className={classes.container}>
            <Authorize profile={permissionProfiles.LOT.LOT_EXPORT}>
                <Button
                    className={classes.btn}
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowModal(true)}
                >
                    Export
                </Button>
            </Authorize>
            <Dialog maxWidth='sm' open={showModal} onClose={() => setShowModal(false)}>
                <DialogTitle id={'dialog-title'}> Lots Export </DialogTitle>
                <DialogContent>
                    <div className={classes.checkboxList}>
                        <FormControlLabel
                            control={<Checkbox checked={nonPMFLotEnable}
                                               onChange={(e, value) => setNonPMFLotEnable(value)}/>}
                            label="Include non-PMF Lots?"/>
                        <FormControlLabel
                            control={<Checkbox checked={includeAllDetailsEnable}
                                               onChange={(e, value) => setIncludeAllDetailsEnable(value)}/>}
                            label="Include all Details"/>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <ExportButton
                        fileLabel="lots"
                        data={filteredLots}
                        mapExportData={mapExportData}
                        onFinish={onSubmitSelectedFields}/>

                    <Button className={classes.button}
                            variant='outlined'
                            color='primary'
                            onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ExportLotButton;
