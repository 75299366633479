import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useEffect, useState } from "react";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import { useGetUserTypeByUserIdQuery, useUpdateUserInformationManagerMutation } from "../../userManagementSlice";
import ResetPasswordDialog from "../resetPassword/resetPasswordDialog";
import SendRestorePasswordDialog from "../sendRestorePassword/sendRestorePasswordDialog";
import UsersPageStyle from "../UsersPageStyle";
import { selectRole } from "features/user/userSlice";
import { useSelector } from "react-redux";
import { userTypeEnums, userTypeOptionsEnums } from "features/users/UsersEnums";

const useStyles = UsersPageStyle;

const EditUserInformation = ({user, roles, refetchUsers, userClaims,onCancel}) => {
    const classes = useStyles();
    roles = roles || [];

    const [updateInformation] = useUpdateUserInformationManagerMutation()
    const [hasChanged, setHasChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [roleId, setRoleId] = useState();
    const [fullName, setFullName] = useState("");
    const [userArchive, setUserArchive] = useState(false);
    const [userBlackList, setUserBlackList] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
    const [userTypeId, setUserTypeId] = useState(user?.userType ?? 1);
    const [userRoleInfo, setUserRoleInfo] = useState(null);
    const userLoggedProfileRoleName = useSelector(selectRole);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        let name = userClaims?.find(c => c.claimType === 'name')?.claimValue ?? '';
        setFullName(name ?? "");
        if (user?.assignedRole) {
            setRoleId(user?.assignedRole[0]?.roleId ?? "");
        }
        setUserArchive(user?.lockoutEnabled);
        setUserBlackList(user?.isBlackList);
    }, [userClaims, user])
    

    useEffect(() => {
        if (roles) {
            const myRoleInfo = [...roles]?.find(x => x.name === userLoggedProfileRoleName);
            setUserRoleInfo(myRoleInfo);
        }
    }, [roles])

    const validateDataBeforeSend = async () => {
        if (!roleId) return false;
        if (!fullName || fullName === "") return false;
        return !(!userTypeId || userTypeId === "" || userTypeId === 0);
        
    }

    const handleUserInfoSave = async () => {
        setSaving(true);
        setErrorMessage("");

        const validateData = await validateDataBeforeSend();

        if (!validateData) {
            setErrorMessage("Please enter fields required");
            setSaving(false);
            return false;
        } 

        const objToSend = {
            userId: user?.id,
            roleId: roleId,
            fullName: fullName,
            lockoutEnabled: userArchive,
            isBlackList: userBlackList,
            userType: parseInt(userTypeId ?? 1)
        }

        let result = await updateInformation(objToSend);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
            refetchUsers();
            onCancel();
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    }

    const getRoleValues = () => {
        let rolesCanISee = [];
        let rolesAssigment = userRoleInfo?.rolesIdsVisibles ? [...userRoleInfo?.rolesIdsVisibles] : [];
        let allRolesInformationCopy = [...roles]?.filter(x => x.key !== userRoleInfo?.key) ?? [...roles];
        if (rolesAssigment?.length === 0) return [];

        if (rolesAssigment) {
            allRolesInformationCopy = allRolesInformationCopy.filter((rol) => rolesAssigment.some(x => x == rol.id));
            allRolesInformationCopy.push(userRoleInfo);
        }

        if (userTypeId) {
            if (userTypeId == userTypeEnums.InternalPMF) {
                rolesCanISee = allRolesInformationCopy?.filter(y => y.isRoleForClient == false);
            }
            if (userTypeId ==  userTypeEnums.Client) {
                rolesCanISee =  allRolesInformationCopy?.filter(y => y.isRoleForClient == true);
            }
            return rolesCanISee.map(x => ({name: x.name, id: x.key}));
        }

        rolesCanISee = allRolesInformationCopy;
        return rolesCanISee.map(x => ({name: x.name, id: x.key}));
    }

    const onClickReset = () => {
        setOpenResetDialog(true);
    }

    const onClickRestorePassword = () => {
        setOpenRestoreDialog(true);
    }

    const changeUserType = (val) => {
        setRoleId(null);
        setUserTypeId(val); 
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.label}>User Information</div>

            <Grid container className={classes.wrapperSave}>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.RESET_PASSWORD}>
                    <Grid item>
                        <Button
                            className={classes.resetButton}
                            onClick={onClickReset}
                            color="primary"
                            variant="contained"
                        >
                            Reset Password
                        </Button>
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.SEND_RESTORE_PASSWORD}>
                    <Grid item>
                        <Button
                            className={classes.resetButton}
                            onClick={onClickRestorePassword}
                            color="primary"
                            variant="contained"
                        >
                            Send Restore Password
                        </Button>
                    </Grid>
                </Authorize>

            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="userName"
                    label="Username"
                    elementType="input"
                    classStyles={classes.dropdown}
                    value={user?.userName}
                    disabled
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="name"
                    label="Full Name"
                    elementType="input"
                    classStyles={classes.dropdown}
                    onChange={(val) => {
                        setFullName(val);
                        setHasChanged(true);
                    }}
                    value={fullName}
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="email"
                    label="Email"
                    elementType="input"
                    classStyles={classes.dropdown}
                    disabled
                    value={user?.email}
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="userType"
                    label="User Type"
                    elementType="dropdown"
                    values={[...userTypeOptionsEnums]}
                    onChange={val => {
                        changeUserType(val);
                        setHasChanged(true);
                    }}
                    touched
                    value={userTypeId}
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="role"
                    label="Role"
                    elementType="dropdown"
                    values={[{id: '', name: ""}, ...(getRoleValues() ?? [])]}
                    onChange={val => {
                        setRoleId(val);
                        setHasChanged(true);
                    }}
                    touched
                    value={roleId ?? ""}
                    disabled={!userTypeId}
                />
            </Grid>
            <Authorize profile={permissionProfiles.USER_MANAGEMENT.ARCHIVE_USER}>
                <Grid item className={classes.grids}>
                    <FormControlLabel
                        control={<Checkbox checked={userArchive} onChange={(e, value) => {
                            setUserArchive(value);
                            setHasChanged(true);
                        }}/>}
                        label={userArchive ? `User Archived (Uncheck to reactive user)` : `Archive User`}/>
                </Grid>
            </Authorize>
            <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_BLACK_LIST}>
                <Grid item className={classes.grids}>
                    <FormControlLabel
                        control={<Checkbox checked={userBlackList} onChange={(e, value) => {
                            setUserBlackList(value);
                            setHasChanged(true);
                        }}/>}
                        label={userBlackList ? `Is in Black List (Uncheck to reactive user)` : `Add to the Black List`}/>
                </Grid>
            </Authorize>
            {errorMessage && <p className={classes.requiredField}>{errorMessage}</p>}
            <Grid container className={classes.wrapperSave}>
                {hasChanged ?
                    <Grid item>
                        <Button
                            className={classes.resetButton}
                            onClick={handleUserInfoSave}
                            color="primary"
                            variant="contained"
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid> : null
                }
            </Grid>
            <SendRestorePasswordDialog
                open={openRestoreDialog}
                setOpenDialog={setOpenRestoreDialog}
                userId={user?.id}
            />

            <ResetPasswordDialog open={openResetDialog} setOpenDialog={setOpenResetDialog}
                                 userId={user?.id}></ResetPasswordDialog>

        </Paper>
    )
}

export default EditUserInformation;