import { List, ListItem, Paper } from "@material-ui/core";
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import NotificationProvider from "../../../../../components/NotificationProvider";
import FilterService from "../../../../../services/filterService";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { BulkEditLot } from "./BulkEditLot";
import { Lot } from "./Lot";
import { resetBulkUpdate, resetSelected, setSelectedLots, setSelectedSubmarkets, useUpdateClientLotMutation } from "../lotConfigSlice";
import LotsConfigStyle from "../LotsConfigStyle";
import { useGetSubMarketsQuery } from "../../../../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { arrayToObject } from "utils/object-util";
import { AutoSizer } from "react-virtualized";
import { VariableSizeList } from "react-window";
import LoadingSpinner from "components/LoadingSpinner";
import React, { useState } from "react";

const useStyles = LotsConfigStyle

const useResetCache = data => {
    const ref = React.useRef();
    React.useEffect(() => {
        // AutoSizer causes ref not being present on the component mount
        if (ref.current) {
            ref.current.resetAfterIndex(0);
        }
    }, [data]);
    return ref;
};

export const ActiveLotsList = ({
    activeClientLots,
    activeClientSubmarkets,
    onConfigChange,
    search,
    onRemove,
    invoiceCycleType
}) => {
    const classes = useStyles();

    let [updateClientLot, { isLoading: updatingLot }] = useUpdateClientLotMutation()
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: submarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();

    const { bulkEdit, selectedLots, selectedSubmarkets } = useSelector(state => state.lotConfigState);
    const dispatch = useDispatch();

    const saveMultipleLots = async (info) => {
        const { pricePerSpot, overage, numberOfSpots, ...other } = info;

        const clientLots = selectedLots?.map(s => {
            const lot = (activeLotsLookup?.[s]);
            return {
                ...lot,
                pricePerSpot: pricePerSpot == null ? lot.pricePerSpot : pricePerSpot,
                overage: overage == null ? lot.overage : overage,
                numberOfSpots: numberOfSpots == null ? lot.numberOfSpots : numberOfSpots
            }
        })

        const clientSubmarkets = selectedSubmarkets?.map(s => {
            const lot = (activeSubMarkets?.[s]);
            return {
                ...lot,
                pricePerSpot: pricePerSpot == null ? lot.pricePerSpot : pricePerSpot,
                overage: overage == null ? lot.overage : overage,
                numberOfSpots: numberOfSpots == null ? lot.numberOfSpots : numberOfSpots
            }
        })
        const res = await updateClientLot({ clientLots, clientSubmarkets });
        if (res.error) {
            NotificationProvider.error('Failed to update lots');
        } else {
            dispatch(resetBulkUpdate());
            NotificationProvider.success('Successfully updated lots');
        }
    }

    const lotMap = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const subMarketMap = submarkets?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    let activeLots = activeClientLots?.filter(l => l?.isNew || lotMap?.[l?.lotId]).map(lot => ({
        ...lot,
        name: lotMap?.[lot?.lotId]?.name,
    })) ?? [];

    let activeLotsLookup = arrayToObject(activeLots);

    const [lotsEdited, setLotsEdited] = useState(activeLots.filter(a => a.isNew && !a.type).map(al => al.lotId));

    let activeSubMarkets = activeClientSubmarkets?.map(submarket => {
        const subMarket = subMarketMap?.[submarket?.subMarketId];
        return ({
            name: subMarket?.name,
            lotIds: subMarket?.lotIds,
            lots: activeLots?.filter(l => subMarket?.lotIds?.includes(l.lotId)),
            isSubMarket: true,
            lotsForSearch: subMarket?.lotIds?.map(l => lotMap?.[l]?.name).join('$$'),
            ...submarket
        })
    }) ?? [];

    const activeLotsInSubMarket = activeSubMarkets?.flatMap(s => s.lotIds).reduce(function (map, obj) {
        map[obj] = obj;
        return map;
    }, {});

    let activeLotsAndSubMarkets = [
        ...activeLots?.filter(l => !activeLotsInSubMarket?.[l.lotId]),
        ...activeSubMarkets];

    activeLotsAndSubMarkets = orderBy(FilterService.filter([], search, activeLotsAndSubMarkets, {}),
        ['name'], ['asc']);


    const onSelectAll = (selected) => {
        if (selected) {
            dispatch(setSelectedLots(activeLots?.map(l => l.id)));
            dispatch(setSelectedSubmarkets(activeSubMarkets?.map(s => s.id)));

        } else {
            dispatch(setSelectedLots([]));
            dispatch(setSelectedSubmarkets([]));
        }
    }

    const activeLotsToDisplay = activeLotsAndSubMarkets.filter(a => !a.isNew)

    const Row = ({ index, style }) => {
        const activeLot = activeLotsToDisplay?.[index];
        return <ListItem style={style} key={index} className={classes.rowListItem}>
            <Paper className={`${classes.listItemWrapper} ${activeLot?.isNew && classes.listItemWrapperNew}`}>
                <Lot
                    activeLot={activeLot}
                    activeLots={activeLots}
                    activeSubMarkets={activeSubMarkets}
                    onRemove={onRemove}
                    key={activeLot?.id}
                    lotsEdited={lotsEdited}
                    setLotsEdited={setLotsEdited}
                    invoiceCycleType={invoiceCycleType} />

                {!!activeLot?.lots?.length && <Paper className={`${classes.subLots}`}>
                    <div className={classes.subLotsHeader}>Lots in Sub Market</div>
                    {activeLot.lots?.map(lot =>
                        <Lot
                            activeLot={lot}
                            activeLots={activeLots}
                            activeSubMarkets={activeSubMarkets}
                            onRemove={onRemove}
                            key={lot?.id}
                            lotsEdited={lotsEdited}
                            setLotsEdited={setLotsEdited}
                        />)}
                </Paper>}

            </Paper>
        </ListItem>
    };

    const getItemSize = (index) => {
        let height = 165;
        const lot = activeLotsToDisplay?.[index];
        let key = lot?.lotId;
        if (lot.isSubMarket) key = `${lot.id}_sub`
        if (lotsEdited.includes(key)) {
            height = 400
        }
        if (lot.isSubMarket) {
            lot?.lots.forEach(element => {
                if (lotsEdited.includes(element?.lotId)) {
                    height = height + 400
                } else {
                    height = height + 165
                }
            });
        }

        return height;
    }

    const ref = useResetCache(activeLotsToDisplay);

    return (
        <List>
            <LoadingSpinner loading={isLoadingSm || isLoadingLots} />
            {bulkEdit && <>
                <BulkEditLot
                    onBulkSave={saveMultipleLots}
                    onSelectAll={onSelectAll}
                />
            </>}

            {
                activeLots.filter(a => a.isNew && !a.type)?.map(activeLot =>
                    <Paper className={`${classes.listItemWrapper} ${classes.listItemWrapperNew}`}>
                        <Lot
                            activeLot={activeLot}
                            activeLots={activeLots}
                            activeSubMarkets={activeSubMarkets}
                            onRemove={onRemove} key={'new_lot'}
                            lotsEdited={lotsEdited}
                            setLotsEdited={setLotsEdited}
                        />
                    </Paper>
                )
            }
            <Paper className={classes.listWrapper}>
                <AutoSizer >
                    {({ height, width }) => (
                        <VariableSizeList
                            className="List"
                            height={height + 550}
                            itemCount={activeLotsToDisplay?.length}
                            itemSize={getItemSize}
                            width={width}
                            ref={ref}
                            itemKey={index => {
                                const item = activeLotsToDisplay[index];
                                return item.id;
                            }}
                        >
                            {Row}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </Paper>

        </List>
    )
}


