import {Button, Grid, IconButton} from "@material-ui/core";
import {DeleteOutline} from "@material-ui/icons";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Authorize from "../../../../../components/Authorize";
import {permissionProfiles} from "../../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../../components/CustomInput";
import NotificationProvider from "../../../../../components/NotificationProvider";
import usePrompt from "../../../../../hooks/usePrompt";
import {IconDollar, IconSettings, IconSlip} from "../../../../../icons";
import {useGetLotsQuery} from "../../../lotSlice";
import {
    useAddClientLotMutation,
    useUpdateClientLotMutation
} from "../../../../clients/clientConfigs/lots/lotConfigSlice";
import LotsConfigStyle from "../../../../clients/clientConfigs/lots/LotsConfigStyle";

const useStyles = LotsConfigStyle

export const Client = ({
                           isNew,
                           showAddNew,
                           activeLot,
                           activeClients,
                           activeClientLot,
                           onRemove,
                           refetchClientLot
                       }) => {
    const classes = useStyles();

    const {triggerPrompt} = usePrompt();
    const dispatch = useDispatch();

    let [addClientLot, {isLoading: addingLot}] = useAddClientLotMutation()
    let [updateClientLot, {isLoading: updatingLot}] = useUpdateClientLotMutation()
    let {data: lots, error: lotError, isLoading: isLoadingLots} = useGetLotsQuery();

    const [clientId, setClientId] = useState(activeClients?.id ?? -1);
    const [edit, setEdit] = useState(isNew);
    const [lotToEdit, setLotToEdit] = useState(activeClientLot);

    const isSaving = addingLot || updatingLot;

    useEffect(() => {
        setLotToEdit(activeClientLot);
    }, [activeClientLot]);
    const onDelete = async () => {
        triggerPrompt({
            title: "Remove Lot",
            content: "Are you sure you want to remove the lot from the client?",
            onConfirm: async () => {
                const res = await onRemove(activeClientLot);
                if (res?.error) {
                    NotificationProvider.error('Failed to remove the lot from the client');
                } else {
                    NotificationProvider.success('Successfully removed the lot from the client');
                }
            },
        });

    }
    
    const onSave = async () => {
        let res;
        if (lotToEdit.id) {
            const clientLots = [lotToEdit];
            const subMarkets = [];
            res = await updateClientLot({clientLots, subMarkets});
        } else {
            let request = {
                isNew: true,
                isSubMarket: false,
                clientId: clientId,
                lotId: activeLot.id,
                ...lotToEdit
            };
            res = await addClientLot(request);
        }
        if (!res?.error) {
            NotificationProvider.success('Successfully saved lot');
            lotToEdit['isNew'] = false;
            setEdit(false);
            showAddNew(false);
            refetchClientLot()
        } else {
            NotificationProvider.error('Failed to save lot');
        }
    }

    const onFieldValueChange = (fieldName, value) => {
        let lotName = lotToEdit?.name;
        if (value) {
            if (fieldName === 'lotId') {
                lotName = lots?.find(l => l.id === value)?.name;
            }
        }
        setLotToEdit({
            ...lotToEdit,
            [fieldName]: value,
            name: lotName
        })
    }

    const editField = (fieldName, label, numberOnly, icon) => (
        <Grid item alignItems='center' container className={classes.input}>
            <Grid xs={6} className={classes.label}>{label} </Grid>
            <Grid xs={6} className={classes.inputField}>
                {<CustomInput
                    value={lotToEdit?.[fieldName] ?? ''}
                    onChange={(val) => {
                        onFieldValueChange(fieldName, val)
                    }}
                    icon={icon}
                    elementType="input"
                    numberOnly={numberOnly}
                />}
            </Grid>
        </Grid>
    )

    const viewField = (fieldName, label, component, icon) => (
        <Grid item xs={3} className={classes.fieldWrapper}>
            <div className={classes.label}>{label} </div>
            <div className={classes.inputField}>
                {component ?? <span className={classes.viewFieldValue}>{lotToEdit?.[fieldName] ?? '-'}</span>}
            </div>
        </Grid>
    )


    return (
        <Grid container alignItems="center" spacing={3} className={` ${edit && classes.listItemWrapperEdit}`}>
            <Grid item xs={isNew ? 12 : 12} container direction='column'>
                <Grid item container alignItems="center" className={classes.lotName}>
                    <Grid spacing={1} container item xs={edit ? 12 : 9} alignItems='center'>
                        {isNew
                            ?
                            <CustomInput
                                id="client"
                                label="Client"
                                value={clientId}
                                elementType="dropdown"
                                onChange={val => setClientId(val)}
                                values={activeClients}
                                showEmpty
                                required
                            />
                            :
                            <>
                                <Grid item>
                                    <div>{activeClientLot?.clientName}</div>
                                </Grid>
                            </>
                        }
                    </Grid>
                    {!edit &&
                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_EDIT}>
                            <Grid container justify="flex-end" className={classes.lotActions}>
                                <Grid item>
                                    <IconButton onClick={() => setEdit(!edit)}>
                                        <IconSettings/>
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton title={'Delete'} onClick={() => onDelete()}>
                                        <DeleteOutline/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Authorize>
                    }
                </Grid>
                <Grid item>
                    <>
                        {edit
                            ?
                            <Grid container spacing={1} justifyContent="flex-end">
                                {editField('numberOfSpots', 'Total spots', true, <IconSlip/>)}
                                {/*{editField('pricePerSpot', 'Price per spot', true, <IconDollar/>)}*/}
                                {/*{editField('overage', 'Overage', true, <IconDollar/>)}*/}
                                {editField('clientAlias', 'Client alias', false)}
                                <Grid item className={classes.btnWrapper}>
                                    <Button
                                        onClick={() => {
                                            setEdit(false);
                                            showAddNew(false);
                                            setLotToEdit(activeClientLot)

                                        }}
                                        className={classes.button}
                                        color="primary">
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={onSave}
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        disabled={isSaving}>
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                {viewField('numberOfSpots', 'Total spots', <span
                                    className={classes.numOfSpots}>{lotToEdit?.['numberOfSpots'] > 0 ? lotToEdit?.['numberOfSpots'] :
                                    <AllInclusiveIcon className={classes.infinity}/>}</span>)}
                                {/*<Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>*/}
                                {/*    {viewField('pricePerSpot', 'Price per spot', <span*/}
                                {/*        className={classes.pricePerSpot}>${lotToEdit?.['pricePerSpot'] ?? '0'}</span>)}*/}
                                {/*</Authorize>*/}
                                {/*<Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>*/}
                                {/*    {viewField('overage', 'Overage', <span*/}
                                {/*        className={classes.overage}>${lotToEdit?.['overage'] ?? '0'}</span>)}*/}
                                {/*</Authorize>*/}
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                                    {viewField('clientAlias', 'Client alias', <span
                                        className={classes.overage}>{lotToEdit?.['clientAlias'] ?? '-'}</span>)}
                                </Authorize>
                            </Grid>}
                    </>
                </Grid>
            </Grid>
        </Grid>
    )
}