import { Button, FormControlLabel, FormGroup, Grid, IconButton, Switch } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { IconAdd } from 'icons';
import { orderBy } from "lodash";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Authorize from "../../../components/Authorize";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import DataListHeader from "../../../components/DataListHeader";
import DataTable from "../../../components/DataTable/DataTable";
import ExportButton from "../../../components/ExportButton/ExportButton";
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import PageComponent from '../../../components/Page/PageComponent';
import useSortOptions from "../../../hooks/useSortOptions";
import FilterService from "../../../services/filterService";
import { setSearchVal } from '../usersFiltersSlice';
import { useDeleteUserMutation, useGetUsersQuery } from '../usersSlice';
import CreateUserDialog from './CreateUserDialog';
import usersPageStyle from './UsersPageStyle';
import EditUserDialog from './editUser/EditUserDialog';
import DeleteUserCheckBox from './DeleteUserCheckBox';
import usePrompt from 'hooks/usePrompt';
import NotificationProvider from 'components/NotificationProvider';

const useStyles = usersPageStyle;

const UsersPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation();
    let { data: users, error: usersError, isLoading: isLoadingUsers, refetch: refecthUsers } = useGetUsersQuery();
    users = users?.data || [];

    const [clickedUser, setClickedUser] = useState(null);
    const [sortOptions, sortData] = useSortOptions();
    const { searchVal } = useSelector(state => state.usersFilters);
    const [openDialog, setOpenDialog] = useState(false);
    const { triggerPrompt } = usePrompt();

    let usersColumns = [
        { name: "Name", key: "name" },
        { name: "User Name", key: "userName" },
        { name: "Email", key: "email" }
    ];

    const onSearch = (searchedVal) => {
        dispatch(setSearchVal(searchedVal.trim()));
    }

    const onEdit = id => {
        const user = users?.find(u => u.id == id);
        setClickedUser(user);
    };

    const getUserById = (id) => {
        const user = users?.find(u => u.id == id);
        return user;
    }

    const deleteUserConfirmation = async(user) => {
        if (!user || !user?.id) return {error: "Failed to delete"};
        return await deleteUser({userId: user?.id});
    }

    const deleteUserRequest = (user) => {
        triggerPrompt({
            title: "Delete User",
            content: "Are you sure you want to delete this user?",
            onConfirm: async () => {
                let res = await deleteUserConfirmation(user);

                if (!res?.error) {
                    refecthUsers();
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    }

    const rowActions = (rowId) => ([
        {
            component: <Authorize profile={permissionProfiles.USER.CREATE_USER}>
                <IconButton onClick={() => onEdit(rowId)} ><EditIcon /></IconButton>
            </Authorize>,
        },
        {
            component: <Authorize profile={permissionProfiles.USER.CREATE_USER}>
                <DeleteUserCheckBox onDeleteUser={deleteUserRequest} user={getUserById(rowId)}></DeleteUserCheckBox>
            </Authorize>,
        }
    ])

    const onCancel = () => {
        setClickedUser(null);
    }

    const mapExportData = v => ({
        Email: v.email,
        UserName: v.userName,
    });

    const onClickCreate = () => {
        setOpenDialog(true);
        setClickedUser(null);
    }

    const isLoading = () => isLoadingUsers;

    let filteredUsers = FilterService.filter([], searchVal, users);

    const history = useHistory();

    return (
        <Authorize profile={permissionProfiles.USER.VIEW_USERS}>
            <PageComponent
                header={<DataListHeader
                    title={`Manage Users (${filteredUsers?.length ?? 0})`}
                    data={users}
                    onSearch={onSearch}
                    actionButtons={(
                        <Grid container spacing={1}>
                            <Authorize profile={permissionProfiles.USER.CREATE_USER}>
                                <Grid item><Button variant='contained' color='primary' startIcon={<IconAdd />} onClick={onClickCreate}>Create User</Button></Grid>
                            </Authorize>
                            <Grid item><Authorize profile={permissionProfiles.USER.VIEW_USERS}><Button variant='contained' color='primary' onClick={() => history.push(`permissions`)} >Permissions</Button></Authorize></Grid>
                            <Grid item><ExportButton fileLabel="users" data={users} mapExportData={mapExportData} /></Grid>
                        </Grid>
                    )}
                />}
            >
                <>
                    {
                        isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                            <DataTable
                                columns={usersColumns}
                                rows={orderBy(
                                    filteredUsers,
                                    sortOptions.columnToSort,
                                    sortOptions.sortDirection
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                noItemsMessage='There are no users to display'
                                actions={rowActions}
                            />
                    }
                </>
                <Authorize profile={permissionProfiles.USER.CREATE_USER}>
                    <EditUserDialog user={clickedUser} onCancel={onCancel} />
                    <CreateUserDialog open={openDialog} setOpenDialog={setOpenDialog} user={clickedUser}></CreateUserDialog>
                </Authorize>
            </PageComponent>
        </Authorize>
    );
};

export default UsersPage;
