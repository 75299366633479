import { makeStyles } from '@material-ui/core/styles';

export const AssetReadinessStatusesStyle = makeStyles((theme) => ({
    readiness: {
        //margin: '0 0.2rem'
    },
    readinessStatus: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    status: {
        display: 'inline',
        marginRight: '1rem'
    },
    loading: {
        position: 'absolute',
        right: '-1em'
    }
}));
