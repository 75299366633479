import { makeStyles } from "@material-ui/core";

export const ProductivityStyles = makeStyles((theme) => ({
    card: {
        //margin: '1rem 0',
        padding: '2rem ',
        height: '100%'
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    wrapper: {
        height: '100%',
        cursor: 'pointer'
    },
    dropdown: {
        padding: '1rem 0 2rem 0',
    },    
    settings: {
        padding: '1rem 0 2rem 1rem',
    },
    siteSizingHeader: {
        fontSize: 13,
        color: '#adb0b2',
        paddingLeft: '2px'

    },
    iconButton: {
        // display: 'flex',
    },
    siteSizingBody: {
        fontWeight: 500,
        paddingLeft: '2px'
    },
    sizing: {
        paddingTop: 20
    },
    addSizeButton: {
        paddingTop: 20,
    },
    rankingGraph: {
        // margin: '0 0.5rem',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '7em / 7em',
        width: 560,
        height: 180,
    }
}));