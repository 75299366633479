import { makeStyles } from "@material-ui/core"

export const VehicleMapStyle = makeStyles(theme => ({
    mapWrapper: {
        height: '480px'
    },
    lengendWrapper: {
        //padding: '0 1em'
    },
    legendItem: {
        position: 'relative',
        padding: '7px 14px',
        fontWeight: 'bold',
        fontSize: '0.7em',
        // "&::before": {
        //     content: "''",
        //     background: '#90C534',
        //     position: "absolute",
        //     height: '6px',
        //     width: '6px',
        //     left: "0",
        //     top: '14px',
        //     borderRadius: '50%',
        // },
    },
    itemName: {
        paddingLeft: '1em'
    },
    legendItemPmf: {
        "&::before": {
            background: '#0090FE',
        },
    },
    legendItemClient: {
        "&::before": {
            background: '#FFB240',
        },
    },
    legendItemDomicile: {
        "&::before": {
            background: '#32C997',
        },
    }
}));
