import { Button, Chip, Divider, Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { IconDatePicker } from "icons";
import _ from "lodash";
import moment from "moment";
import { ScheduleGroupType } from '../../../../../lots/components/LotDialog/Schedule/LotScheduleGroupsEnums';
import { lotDispatchDetailsStyle } from "../style";

const LotSchedule = ({
    lot, onEdit
}) => {

    const classes = lotDispatchDetailsStyle();

    const getDayOfWeekById = (id) => {
        switch (id) {
            case 1:
                return "Sunday:";
            case 2:
                return "Monday:";
            case 3:
                return "Tuesday:";
            case 4:
                return "Wednesday:";
            case 5:
                return "Thursday:"
            case 6:
                return "Friday:"
            case 7:
                return "Saturday:"
        }
    }

    const schedulesList = lot?.schedule?.map(s => ({
        ...s,
        lotSchedulesGrouped: _.groupBy(s?.lotSchedules, 'dayOfWeekId')
    }))

    const getTimeFormated = (time) => {
        return moment(time, "HH:mm:ss").format("hh:mm A");
    }

    return (
        <>
            <Grid direction='column' spacing={1}>
                <Paper className={classes.detailCard}>
                    <div className={classes.cardHeader}>
                        <div className={classes.cardHeaderTitle}>
                            <IconDatePicker className={classes.titleIcon} />
                            <div className={classes.cardTitle}>Schedule</div>
                        </div>
                        <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                            {onEdit && <Button variant="outlined" onClick={() => onEdit(lot)} className={classes.editButton}>Edit</Button>}
                        </Authorize>
                    </div>

                    {schedulesList?.length ? schedulesList?.map((schedule, index) =>
                        <div>
                            <Grid container className={classes.openingHoursContainer} >
                                <Grid item xs={12} className={classes.openingHoursTitle}>
                                    <label className={classes.scheduleName}>{schedule.name}</label>
                                    {schedule?.type == ScheduleGroupType.TRANSPORTS && <Chip label="Used For Transports" variant="outlined" />}
                                </Grid>
                                <Grid item xs={12}>
                                    {Object.keys(schedule?.lotSchedulesGrouped).length ?
                                        [1, 2, 3, 4, 5, 6, 7].map(function (key) {
                                            return (<div key={key.toString()}>
                                                <div className={classes.openingHours}>
                                                    <label>{getDayOfWeekById(key)}</label>
                                                    {schedule?.lotSchedulesGrouped?.[key] ? schedule?.lotSchedulesGrouped?.[key].map(
                                                        (schedule, index) => (
                                                            <div key={index}>{getTimeFormated(schedule.openTime)} - {getTimeFormated(schedule.closeTime)}</div>
                                                        )
                                                    ) : <> closed</>}
                                                </div>
                                            </div>)
                                        })
                                        : <div></div>
                                    }
                                    {index != schedulesList?.length - 1 && <Divider className={classes.openingHoursDivider} />}
                                </Grid>
                            </Grid>
                        </div>
                    ) :
                        <EmptyPlaceholder text="No Schedules" />
                    }
                </Paper>
            </Grid>
        </>
    )
}
export default LotSchedule;
