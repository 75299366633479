import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import formStyles from "../../../../features/vehicles/formStyles";
import DateRange from '../../../../components/DateRange';
import React, { useState } from 'react';
import { useGetMovementPerDayPerLotReportMutation } from "../../../../features/lots/lotSlice";

const useStyles = formStyles;

const NumberOfMovementReport = ({ handleClose }) => {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [getMovementPerDayPerLotReport, { isLoading: gettingReport }] = useGetMovementPerDayPerLotReportMutation();
    let [isSaving, setIsSaving] = useState(false);

    const onDateSelect = (value) => {
        setStartDate(value?.startDate);
        setEndDate(value?.endDate);
    }

    const onSubmit = async () => {
        let reportObj = {
            StartDate: startDate,
            EndDate: endDate,
        }
        
        await getMovementPerDayPerLotReport(reportObj);

        handleClose();
    };

    const isValid = () => {
        return !!startDate && !!endDate;
    };
    
    return (
        <Paper className={classes.formContainer}>
            <form >
                <Grid item >
                    <DateRange
                        title={"Select Report Date"}
                        identifier={"dt"}
                        onSelect={onDateSelect}
                        dateValue={null}
                    />
                </Grid>
            </form>
            <div className={classes.btnContainer}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving || !isValid()}
                        className={classes.button}
                        variant="contained"
                    >
                        Download
                    </Button>
                </div>
            </div>
        </Paper>
    );

};

export default NumberOfMovementReport;