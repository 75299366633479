import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import ExportButton from "components/ExportButton/ExportButton";
import { useState } from "react";
import { billingPrograms } from "../consts";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";

const ActivityExportData = ({ data, program, transformData, label }) => {
    //TEMP move to API
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const columns = [
        {
            name: 'Date',
            key: 'formattedDate',
        },
        {
            name: 'Activity',
            key: 'activityItemId',
            value: (itemId) => activityItems?.find(a => a.id == itemId)?.name
        },
        {
            name: 'Total Cost',
            key: 'totalInternalCost',
            value: (cost) => `$${cost}`
        },
        {
            name: 'Total Charge',
            key: 'totalCharge',
            value: (cost) => `$${cost}`
        },
        {
            name: 'Total Revenue',
            key: 'totalRevenue',
            value: (cost) => `$${cost}`
        },

    ];

    const mapExportData = row => {
        const detailsInfo = {};
        detailsInfo['Lot'] = transformData['lotId']?.(row.lotId) ?? row.lotId;
        detailsInfo['Client'] = transformData['clientId']?.(row.clientId) ?? row.clientId;
        columns?.map(col => {
            detailsInfo[col.name] = col?.value ? col.value(row?.[col.key]) : row?.[col.key]
        });
        return detailsInfo;
    };

    const programData = data?.filter(row => row.programType == program).flatMap(row => row.activityInvoiceEntities.map(det => ({
        lotId: row.lotId,
        clientId: row.clientId,
        formattedDate: row.formattedDate,
        ...det,
    }))) ?? [];

    return <>
        <ExportButton mapExportData={mapExportData} data={programData} label={`Export ${label} Program Data`} />
    </>
}
export default ActivityExportData;