import { useSubscriptionQuery } from "./subscriptionSlice";
import SubscriptionList from "./SubscriptionList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../user/userSlice";
import { subscriptionPageStyle } from "./SubscriptionPageStyle";

const SubscriptionPage = () => {
    const classes = subscriptionPageStyle()

    let { data: subscriptions, error: subscriptionError, isLoading: isLoadingSubscriptions } = useSubscriptionQuery();
    subscriptions = subscriptions || [];

    const isLoading = () => isLoadingSubscriptions;

    const userEmail = useSelector(selectUserEmail);

    return (
        isLoading() ? <CircularProgress /> :
            <div>
                <Grid container direction='row'>
                    <Grid item xs={2}>
                        <Grid item className={classes.pageTitle}>Subscriptions</Grid>
                        <Grid item className={classes.userEmail}>{userEmail}</Grid>
                    </Grid>
                </Grid>
                <SubscriptionList
                    subscriptions={subscriptions}
                />
            </div>
    );
};

export default SubscriptionPage;
