import { Grid, Grow, Popper } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useRef, useState } from "react";
import { useGetStatusIssueTrackerQuery } from "../issuesTrackerSlice";
import { UpdateIssueStyle } from "./style";

export const StatusTypesTooltip = ({

}) => {
    const classes = UpdateIssueStyle();
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    const [anchorEl, setAnchorEl] = useState(null);

    const onOpenPopover = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closePopover = (e) => {
        setAnchorEl();
    }


    return (
        <span onMouseEnter={onOpenPopover}
            onMouseLeave={closePopover} style={{ position: 'relative' }}>
            <div ><HelpOutlineIcon style={{ height: '0.7em', fill: '#5c5c5c' }} /></div>
            <div style={{ position: 'absolute', display: !!anchorEl ? 'block' : 'none', top: 0, zIndex: '9999' }}>
                <Grid container direction="column" spacing={1} className={classes.statusPopper}>
                    {statuses?.map(status => <Grid item className={classes.statusItem}>
                        <div><b>
                            {status.name}
                        </b>
                        </div>
                        <div>
                            {status.description}
                        </div>
                    </Grid>)}
                </Grid>
            </div>
        </span>
    )

}