import React, { createContext, useState } from "react";
//TODO typescript?
const defaultValue = {
  isOpen: false,
  props: {
    content: "",
    title: "",
    loading: false,
    onCancel: () => null,
    onConfirm: () => null
  }
};

export const PromptContext = createContext([defaultValue, () => { }]);

export const PromptContextProvider = ({ children }) => {
  const [prompt, setPrompt] = useState(defaultValue);

  return (
    <PromptContext.Provider value={[prompt, setPrompt]}>
      {children}
    </PromptContext.Provider>
  );
};