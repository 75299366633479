import * as React from "react";

function SvgIconFilters(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M17.9167 5.646H13.1667" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.24992 5.646H2.08325" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.41659 8.41667C9.94687 8.41667 11.1874 7.17612 11.1874 5.64583C11.1874 4.11554 9.94687 2.875 8.41659 2.875C6.8863 2.875 5.64575 4.11554 5.64575 5.64583C5.64575 7.17612 6.8863 8.41667 8.41659 8.41667Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.9167 14.354H14.75" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.83325 14.354H2.08325" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.5833 17.1252C13.1136 17.1252 14.3542 15.8846 14.3542 14.3543C14.3542 12.824 13.1136 11.5835 11.5833 11.5835C10.053 11.5835 8.8125 12.824 8.8125 14.3543C8.8125 15.8846 10.053 17.1252 11.5833 17.1252Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default SvgIconFilters;
