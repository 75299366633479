import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AdjustIcon from '@material-ui/icons/Adjust';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepConnector, stepConnectorClasses, styled } from "@mui/material";


const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#0090fe',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#0090fe',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#d5d5e1',
        borderTopWidth: 4,
        borderRadius: 8,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#d5d5e1',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-circle': {
        width: 18,
        height: 18,
        borderRadius: '50%',
        backgroundColor: 'white',
        border: '5px solid #d5d5e1'
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed && <CheckCircleIcon />}
            {active && <AdjustIcon color="primary" />}
            {(!active && !completed) && <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

const TimelineComponent = ({ numberOfSteps, currentStep }) => {
    currentStep = currentStep - 1
    
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={currentStep} alternativeLabel connector={<QontoConnector />}>
                    {[...Array(numberOfSteps)].map((e, index) => (
                        <Step key={e} completed={index < currentStep}>
                            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

        </>
    );
};

export default TimelineComponent
