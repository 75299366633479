import { Checkbox } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from 'components/LoadingSpinner';
import PageComponent from 'components/Page/PageComponent';
import { useGetClientsWithConfigsQuery } from 'features/clients/clientConfigSlice';
import { useGetClientsQuery } from "features/clients/clientSlice";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import useSortOptions from "hooks/useSortOptions";
import { IconDownload } from 'icons';
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import FormatService from 'services/formatService';
import { arrayToObject } from "utils/object-util";
import { ReportHistoryStatus, ReportTypeEnum, reportTypesList } from '../../reportsConsts.ts';
import { useGetBillingReportsQuery, useGetStorageReportsQuery } from '../ReportsSlice';
import { setFilters } from './reportsFilterSlice';

const ReportsPage = ({ reportTypes = [ReportTypeEnum.STORAGE_REPORT], title = "Storage Reports", actionButtons, clientId }) => {

    const dispatch = useDispatch();
    const isStorage = JSON.stringify(reportTypes) === JSON.stringify([ReportTypeEnum.STORAGE_REPORT]);
    const { filters } = useSelector(state => state.reportsFilter);
    const isClient = useSelector(selectIsClient);
    let userClientId = useSelector(selectUserClientId);
    const clientIdToSend = clientId ?? isClient ? userClientId : null;

    const [sortOptions, sortData] = useSortOptions();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: isClient });
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isLoading: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(clientIdToSend);
    let { data: storageReports, error: storageReportsError, isLoading: isLoadingStorageReports } = useGetStorageReportsQuery(clientIdToSend ?? 0, { skip: !isStorage });
    let { data: billingReports, error: billingReportsError, isLoading: isLoadingBillingReports } = useGetBillingReportsQuery({ reportTypes, clientId }, { skip: isStorage || !clientId });
    clients = clients || [];
    storageReports = storageReports || [];
    clientsWithConfigs = clientsWithConfigs || [];

    const reportsList = isStorage ? storageReports : billingReports;

    const filteredClients = clients?.filter(client => {
        const config = clientsWithConfigs?.find(c => c.id === client.id);
        return isStorage ? config?.config?.lotConfig?.usingAmzStorageReport === true : true;
    });

    const clientLookup = arrayToObject(clients);

    const mappedData = reportsList?.filter(r => reportTypes.includes(r.reportType))?.map(report => {
        const dateRangeObj = report.jsonData?.length > 0 ? JSON.parse(report.jsonData) : null;
        const startDate = FormatService.formatFullDate(dateRangeObj?.StartDate);
        const endDate = FormatService.formatFullDate(dateRangeObj?.EndDate);
        const formattedDateRange = dateRangeObj ? `${startDate} - ${endDate}` : '';

        return {
            ...report,
            client: clientLookup?.[report.clientId]?.name,
            formattedDate: FormatService.formatDateTime(report.createdDate),
            reportType: reportTypesList.find(r => r.id == report.reportType)?.name,
            dateRange: formattedDateRange,
            sentToClient: report.status == ReportHistoryStatus.SENT_TO_CLIENT
        }
    });

    const filterConfig = [
        ...(isClient || clientId ? [] : [{ type: fieldTypes.TEXT, size: 6, title: 'Client', key: 'clientId', values: filteredClients }]),
        { type: fieldTypes.DATERANGE, size: 6, title: 'Created Date', key: 'createdDate', values: [] }];

    let columns = [
        ...(isClient || clientId ? [] : [{ name: "Client", key: "client" }]),
        { name: "Date", key: "formattedDate" },
        ...(isStorage ? [] : [{ name: "Date Range", key: "dateRange" }]),
        ...(isStorage ? [] : [{ name: "Report Type", key: "reportType" }]),
        { name: isStorage ? 'Sent to Client' : 'Report sent', key: "sentToClient", component: (row) => <Checkbox disabled checked={row?.sentToClient} /> }
    ];

    let filteredData = FilterService.filter(filters, '', mappedData, filterConfig);

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const rowActions = (rowId) => {
        const row = mappedData?.find(report => report.id === rowId);

        return row?.fileUrl?.length > 0 ?
            [
                {
                    icon: <IconDownload />,
                    onClick: () => downloadFile(row?.fileUrl)
                }
            ] :
            [
                {
                    icon: <IconDownload fill={'#bbbb'} />,
                    onClick: () => { }
                }
            ];
    };

    const isLoading = () => isLoadingClients || isLoadingClientsWithConfigs || isLoadingStorageReports || isLoadingBillingReports;

    return (
        <Authorize profile={isStorage ? permissionProfiles.REPORTS.VIEW_STORAGE_REPORTS : permissionProfiles.REPORTS.VIEW_BILLING_REPORTS}>
            <PageComponent
                header={<DataListHeader
                    title={title}
                    data={filteredData}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={actionButtons}
                />}
            >
                <>
                    {isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                        <DataTable
                            columns={columns}
                            rows={orderBy(
                                filteredData,
                                sortOptions.columnToSort,
                                sortOptions.sortDirection
                            )}
                            rowIdentifier='id'
                            onSort={sortData}
                            sortDirection={sortOptions.sortDirection}
                            columnToSort={sortOptions.columnToSort}
                            noItemsMessage='There are no reports matching this criteria'
                            actions={rowActions}
                        />
                    }
                </>
            </PageComponent>
        </Authorize>
    )
}

export default ReportsPage;
