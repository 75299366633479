import { makeStyles } from "@material-ui/core";

export const activityInvoiceStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '2em',
        marginTop: '1em'
    },
    detailsWrapper: {
        padding: '2em 0',
    },
    label: {
        color: theme.palette.secondary.light,
    },
    field: {
        fontWeight: 'bold'
    },
    lotCol: {
        fontWeight: 'bold'
    },
    input: {
        margin: 15
    },
    invoiceStatusWrapper: {
        padding: '0 2.5rem',
        borderRadius: '40px',
        color: 'white',
        fontWeight: '500',
        marginRight: '1rem',
        '&:disabled': {
            color: 'white',
            opacity: '1'
        }
    },
    actionButtons: {
        minWidth: '720px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    warning: {
        '& path': {
            'fill': '#ff6f00'
        }
    },
    sendBtn: {
        fontSize: 12
    }
}));