import { makeStyles } from "@material-ui/core";

export const VehicleLinksStyle = makeStyles((theme) => ({
    iconWrapper: {
        '& svg': {
            width: '1em'
        }
    },
   
    noMarginBottom: {
        marginBottom: 0
    },
    linkedAssets: {
        maxHeight: '50px',
        overflowY: 'auto',
        overflowX: 'hidden',

        // Scrollbar customization
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        }
    }
}))