import * as React from "react";

function IconBasicWash(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4994 3.5C13.2903 3.5 11.4994 5.29086 11.4994 7.5C11.4994 9.70914 13.2903 11.5 15.4994 11.5C17.7086 11.5 19.4994 9.70914 19.4994 7.5C19.4994 5.29086 17.7086 3.5 15.4994 3.5ZM10.4994 7.5C10.4994 4.73858 12.738 2.5 15.4994 2.5C18.2608 2.5 20.4994 4.73858 20.4994 7.5C20.4994 10.2614 18.2608 12.5 15.4994 12.5C12.738 12.5 10.4994 10.2614 10.4994 7.5ZM14.9994 5C14.9994 4.72386 15.2233 4.5 15.4994 4.5C17.1563 4.5 18.4994 5.84315 18.4994 7.5C18.4994 7.77614 18.2756 8 17.9994 8C17.7233 8 17.4994 7.77614 17.4994 7.5C17.4994 6.39543 16.604 5.5 15.4994 5.5C15.2233 5.5 14.9994 5.27614 14.9994 5ZM10.3972 14.7236C9.96836 13.1232 8.32335 12.1734 6.72295 12.6022C5.12255 13.0311 4.1728 14.6761 4.60163 16.2765C5.03045 17.8769 6.67546 18.8266 8.27586 18.3978C9.87626 17.969 10.826 16.324 10.3972 14.7236ZM6.46413 11.6363C8.59799 11.0645 10.7913 12.3309 11.3631 14.4647C11.9349 16.5986 10.6685 18.792 8.53468 19.3637C6.40082 19.9355 4.20747 18.6692 3.6357 16.5353C3.06394 14.4014 4.33027 12.2081 6.46413 11.6363ZM7.59416 13.9217C7.66563 14.1884 7.50734 14.4626 7.2406 14.5341C6.70714 14.677 6.39055 15.2253 6.5335 15.7588C6.60497 16.0255 6.44668 16.2997 6.17994 16.3712C5.91321 16.4426 5.63904 16.2844 5.56757 16.0176C5.28169 14.9507 5.91485 13.854 6.98178 13.5681C7.24852 13.4967 7.52269 13.655 7.59416 13.9217ZM16.4994 16.5C15.3948 16.5 14.4994 17.3954 14.4994 18.5C14.4994 19.6046 15.3948 20.5 16.4994 20.5C17.604 20.5 18.4994 19.6046 18.4994 18.5C18.4994 17.3954 17.604 16.5 16.4994 16.5ZM13.4994 18.5C13.4994 16.8431 14.8426 15.5 16.4994 15.5C18.1563 15.5 19.4994 16.8431 19.4994 18.5C19.4994 20.1569 18.1563 21.5 16.4994 21.5C14.8426 21.5 13.4994 20.1569 13.4994 18.5Z" fill="#0090FE" />
        </svg>

    );
}

export default IconBasicWash;
