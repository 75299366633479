import FormatService from '../../../../services/formatService';
import ReadinessIndicator from './ReadinessIndicator/ReadinessIndicator';
import { AssetReadinessStyle } from "./AssetReadinessStyle";

const ManualReadinessTooltipContent = ({ readinessValues = {}, statusText }) => {
    const classes = AssetReadinessStyle();

    return (
        <div className={classes.tooltipContent}>
            {
                readinessValues.map((value, index) => <div key={index} className={classes.tooltipField}>
                    <label>{value.text}</label>
                    {value?.createdDate ? FormatService.formatDate(value?.createdDate) : ''}
                    <ReadinessIndicator
                        className={classes.tooltipIndicator}
                        status={value?.status}
                    />
                </div>)
            }
        </div>
    )
}

export default ManualReadinessTooltipContent;

