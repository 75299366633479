import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { lotDispatchDetailsStyle } from "./style";
import _ from "lodash";
import LotSchedule from "./components/LotSchedule";
import LotContacts from "./components/LotContacts";
import LotDirections from "./components/LotDirections";

const LotDispatchDetails = ({
    lot, onEdit
}) => {

    const isLoading = () => false;

    const classes = lotDispatchDetailsStyle();

    return (
        <div className={classes.lotDetails}>
            <LoadingSpinner loading={isLoading()} />

            <LotContacts lot={lot} onEdit={onEdit}/>
            
            <LotSchedule lot={lot} onEdit={onEdit}/>

            <LotDirections lot={lot} onEdit={onEdit}/>
        </div>
    )
}
export default LotDispatchDetails;
