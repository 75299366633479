import DropdownFilter from "components/DropdownFilter";
import { arrayToObject } from "utils/object-util";
import { getStatusColor } from "../issueTrackerConsts";
import { useGetStatusIssueTrackerQuery } from "../issuesTrackerSlice";
import { StatusSelectorStyle } from "./style";
import { Chip } from "@material-ui/core";

export const StatusSelector = ({ status, onChange, readonly }) => {
    const classes = StatusSelectorStyle();
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    const statuseLookup = arrayToObject(statuses);

    return (<>{readonly ? <StatusItem status={status} statusLookup={statuseLookup} /> :
        <DropdownFilter
            id="status"
            value={status ?? ''}
            onSelect={(val) => onChange(+val?.id)}
            elementType="dropdown"
            values={statuses}
            showEmpty
            withTypeAhead
            renderOption={(option) => <StatusItem status={option?.id} statusLookup={statuseLookup} showLabel />}
        />}</>
    )
}

const StatusItem = ({ status, statusLookup, showLabel }) => {
    const classes = StatusSelectorStyle();

    return <>{statusLookup?.[status] ? <Chip variant="outlined"
        style={{ ...getStatusColor(status), height: '26px' }}
        label={statusLookup?.[status]?.name}
        color={"primary"}
    /> : <></>}</>

}