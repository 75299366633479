import { FormControlLabel, Switch } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Authorize from "components/Authorize";
import NotificationProvider from "components/NotificationProvider";
import { useGetClientsWithConfigsQuery, useUpdateClientMutation } from "features/clients/clientConfigSlice";
import { clientConfigTypeToName } from "features/clients/clientConfigsSetup";
import { useEffect, useState } from "react";
import { editParamStyle } from "./style";


const EditBooleanConfigParameter = ({ configType, parameterName, label, clientId, profile }) => {

    const classes = editParamStyle();

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isLoading: isLoadingClientsWithConfigs, isFetching: isFetchingClientsWithConfigs } = useGetClientsWithConfigsQuery(clientId);
    let [updateClient, { isLoading }] = useUpdateClientMutation();
    const configName = clientConfigTypeToName[configType];

    const [config, setConfig] = useState();
    const [paramIntValue, setParamIntValue] = useState();

    useEffect(() => {
        const configuration = clientsWithConfigs?.[0]?.config?.[configName];
        setConfig(configuration);
        setParamIntValue(configuration?.[parameterName] ? 1 : 0)
    }, [clientsWithConfigs, configName]);

    const onChangeParameter = async (newValue) => {
        setParamIntValue(newValue);
        if (paramIntValue === newValue) return;

        const configToSave = { ...clientsWithConfigs?.[0]?.config, [configName]: { ...config, [parameterName]: !!newValue } }
        let result = await updateClient({ id: clientId, config: configToSave, configToUpdate: configType });
        if (result?.error) {
            NotificationProvider.error('Failed to update');
        } else {
            NotificationProvider.success('Successfully updated');
        }
    }

    return (
        <>{!isLoadingClientsWithConfigs &&
            <FormControlLabel
                control={
                    <Authorize profile={profile}>
                        <ToggleButtonGroup
                            color="primary"
                            disabled={isLoading}
                            value={paramIntValue}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton
                                disabled={isLoading}
                                selected={paramIntValue}
                                onClick={() => onChangeParameter(1)}
                                className={paramIntValue ? classes.onSelected : classes.on}
                            >
                                <span>On</span>
                            </ToggleButton>
                            <ToggleButton
                                disabled={isLoading}
                                selected={!paramIntValue}
                                onClick={() => onChangeParameter(0)}
                                className={paramIntValue ? classes.off : classes.offSelected}
                            >
                                <span>Off</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Authorize>
                }
            />}
        </>);
}

export default EditBooleanConfigParameter;