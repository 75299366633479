import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Link } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import { useGetAllIssuesTrackerQuery, useLinkTicketsMutation, useRemoveLinkedTicketMutation } from "../issuesTrackerSlice";
import { IssueTrackerLinksStyle } from "./style";
import { useEffect, useState } from "react";
import { IconDelete, IconDeleteBlue } from "icons";
import usePrompt from "hooks/usePrompt";
import NotificationProvider from "components/NotificationProvider";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

export const LinkedTicketsSelector = ({
    issue
}) => {

    const classes = IssueTrackerLinksStyle();
    const [includeArchived, setIncludeArchived] = useState(false);
    const { triggerPrompt } = usePrompt();
    let { data: issuesTrackerFromApi, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory: includeArchived, populateLinks: false });
    const [linkTickets, { isLoading: islinking }] = useLinkTicketsMutation();
    const [removeLink, { isLoading: isRemoving }] = useRemoveLinkedTicketMutation();
    const [newLink, setNewLink] = useState();


    const currentIds = issue?.linkedTickets?.map(t => t.id) ?? [];
    const dropdownValues = issuesTrackerFromApi?.filter(f => f.id !== issue?.id && !currentIds?.includes(f.id)).map(t => ({ ...t, name: `#${t.id} ${t.title}` })) ?? [];

    const onSave = async () => {
        const res = await linkTickets({ issueId: issue?.id, linkedIssueId: newLink?.id });
        setNewLink({ ...newLink, sent: true });
        if (!res?.error) {
            NotificationProvider.success('The tickets were linked successfully');
        } else {
            NotificationProvider.error('Failed to link the tickets');
        }
    }

    useEffect(() => {
        setNewLink();
    }, [issue])

    const onRemove = async (ticket) => {
        triggerPrompt({
            title: "Unlink Tickets",
            content: "Are you sure you want to delete this link?",
            onConfirm: async () => {
                const res = await removeLink({ issueId: issue?.id, linkedIssueId: ticket?.id })
                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    }

    return (
        <>
            {<Grid container direction="column" spacing={2} >
                {issue?.linkedTickets?.map((ticket, index) =>
                    <Grid item key={index} >
                        <TicketItem ticket={ticket} onDelete={() => onRemove(ticket)} />
                    </Grid >
                )}
                <Grid item>
                    {newLink ? <>
                        <Grid container justifyContent="space-between" alignItems='center' spacing={1} className={classes.addWrapper}>
                            <Grid item xs={8}>
                                <DropdownFilter
                                    disabled={isLoadingIssuesTracker}
                                    withTypeAhead
                                    id="ticket"
                                    value={newLink?.id}
                                    onSelect={(val) => {
                                        setNewLink(val);
                                    }}
                                    elementType="dropdown"
                                    values={dropdownValues}
                                    showEmpty
                                    renderOption={(option) => <TicketItem ticket={option} />}
                                />

                            </Grid>
                            <Grid xs={3} item container justifyContent="flex-end" wrap="nowrap">
                                <Grid item>
                                    <Button variant="outlined" onClick={onSave} disabled={!newLink?.id || islinking || newLink?.sent}>
                                        Link
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setNewLink()} >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item >
                                {(islinking || isLoadingIssuesTracker) && <CircularProgress size={'1em'} />}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                className={classes.checkbox}
                                control={<Checkbox
                                    checked={includeArchived}
                                    onChange={(e, value) => setIncludeArchived(value)}
                                    size="small"
                                />}
                                label={<label className={classes.radioName}>Select archived issue</label>}
                            />
                        </Grid>
                    </> :
                        <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}><Button variant="outlined" onClick={() => setNewLink({})}>
                            Add
                        </Button>
                        </Authorize>
                    }
                </Grid>
            </Grid>}
        </>
    )
}

const TicketItem = ({ ticket, onDelete }) => {
    const classes = IssueTrackerLinksStyle();
    return (ticket?.id ?
        <Grid container alignItems="center" spacing={1} className={classes.ticketWrapper}>
            <Grid item>
                <Link target='_blank' href={`/ticket/${ticket?.id}`} className={classes.link}>#{ticket?.id}</Link>
            </Grid>
            <Grid item>
                {ticket?.title}
            </Grid>
            {onDelete && <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
                <Grid item style={{ marginLeft: 'auto' }}>
                    <IconButton size="small" variant="outlined" onClick={onDelete}>
                        <IconDelete height={15} />
                    </IconButton>
                </Grid>
            </Authorize>}
        </Grid> : <></>
    )
}