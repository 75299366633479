import { makeStyles } from "@material-ui/core";

export const lotDialogStyle = makeStyles((theme) => ({
    inputWrapper: {
        margin: '0.8em'
    },
    fieldGroup: {
        padding: '0.5em',
        margin: '0 0.5em 1em 0.5em'
    },
    groupTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 1em',
    },
    buttonSchedule: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        margin: 10,
        border: 0
    },
    invalid: {
        color: 'red',
        paddingLeft: 10
    },
    emptyWrapper: {
        alignItems: "center",
        display: 'flex',
        justifyContent: "flex-start"
    },
    serviceProviderWrapper: {
        marginLeft: "1rem"
    },
    serviceProviderName: {
        fontWeight: 600,
    },
    serviceProviderChip: {
        margin: '0.5rem 0.2rem'
    }
}));