import { Grid, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import { useUpdateUserPreferenceMutation } from "components/UserPreferences/userPreferencesSlice";
import { debounce } from "lodash";
import FilterService from "services/filterService";
import { selectedFilterStyle } from "./style";

export const SelectedFilters = ({
    filters,
    filterConfig,
    onClearFilter,
    className,
    selectedViewId,
    tableId
}) => {
    const classes = selectedFilterStyle();

    const filtersDisplay = FilterService.getFilterDisplay(filters, filterConfig);
    const [updatePref, { isLoading: isSaving }] = useUpdateUserPreferenceMutation();

    const onClear = (filterKey) => {
        const newFilters = { ...filters, [filterKey]: { value: null } }
        onClearFilter(filterKey);
        saveViewFilters(newFilters);
    }

    //TODO this needs refactoring to share the saving with the TableFilters component
    const saveViewFilters = debounce((listToSave) => {
        let filtersList = Object.entries(listToSave).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }));
        const prefToSave = { filters: filtersList };
        let res = updatePref({ componentId: tableId, type: userPreferencesTypes.FILTERS, ...prefToSave, viewId: selectedViewId })
    }, 500)

    return (
        <>
            {!!filtersDisplay?.length && <div className={className}>
                <div className={classes.title}>
                    Selected Filters
                </div>
                <Grid container className={classes.selectedFilters} >
                    {filtersDisplay?.map((f, index) =>
                        <>{f?.tooltipContent != undefined ?
                            <Tooltip key={index} title={f?.tooltipContent}>
                                <Grid item className={classes.item} container spacing={1}>
                                    <Grid item>
                                        {f.label}:
                                    </Grid>
                                    <Grid item>
                                        {f.value}
                                    </Grid>
                                    <Grid item>
                                        <IconButton size="small" onClick={() => onClear(f.key)}>
                                            <CloseIcon className={classes.clear} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Tooltip> :
                            <Grid key={index} item className={classes.item} container spacing={1}>
                                <Grid item>
                                    {f.label}:
                                </Grid>
                                <Grid item>
                                    {f.value}
                                </Grid>
                                <Grid item>
                                    <IconButton size="small" onClick={() => onClear(f.key)}>
                                        <CloseIcon className={classes.clear} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        }</>)}
                </Grid>
            </div >}
        </>
    )
}