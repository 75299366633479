import { makeStyles } from "@material-ui/core"

export const importLoadsStyle = makeStyles((theme) => ({
    clientWrapper: {
        marginBottom: '2em'
    },
    actionButton: {
        marginLeft: '15em',
        marginBottom: '1em'
    }
}));