import { Paper, Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import statuses from '../../loadStatusesWithIcons';

function LoadStatus({ load, status }) {

    const getCarrierForStatus = (load, status) => {
        if (status?.dispatchId) {
            const dispatchInfo = load?.dispatch?.find(d => d.id === status.dispatchId)?.transporterName;
            return dispatchInfo;
        }
        return '';
    };
    const getStatus = (key) => {
        return { label: statuses[key?.status]?.label || key?.status, icon: statuses[key?.status]?.icon };
    };

    return (
        <>
            <Tooltip title={status?.comment ?? ''}><span>{getStatus(status).label}</span></Tooltip>
            &nbsp;
            {getCarrierForStatus(load, status)}
        </>
    );
}

export default LoadStatus
