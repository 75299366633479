import { Grid } from "@material-ui/core";
import { LotSelectorStyle, } from "./style";
import { IconLot } from "icons";

export const LotSelector = ({
    lotName,
    lotId,
    lotLookup
}) => {
    const classes = LotSelectorStyle();

    return (<Grid container  >
        <Grid item className={classes.iconWrapper}>
            <IconLot />
        </Grid>
        <Grid item className={classes.text}>{lotName ?? lotLookup?.[lotId]?.name}</Grid>
    </Grid>
    )
}
