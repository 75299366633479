import { Grid, Tooltip } from "@material-ui/core";
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import BasicModal from "components/BasicModal";
import { useEffect, useState } from "react";
import FormatService from "services/formatService";
import UserTaskItem from "../../UserTaskItem/UserTaskItem";
import { userTaskSetupByType, userTaskStatuses } from '../../userTaskConsts';
import StatusIndicator from './statusIndicator';
import { SuperTaskCardStyle } from "./style";

const SuperTaskCard = ({ task, lotId }) => {
    const classes = SuperTaskCardStyle();

    const [openDialog, setOpenDialog] = useState(false);

    // useEffect(() => {
    //     setHTMLComment({ __html: task?.comments })
    // }, [task]);

    //const TaskComponent = userTaskSetupByType[task?.taskTypeEnum]?.component;
    const userTaskStatusesList = Object.keys(userTaskStatuses).map(key => ({ id: userTaskStatuses[key], name: key }))

    return (
        <>
            {/* {TaskComponent && */}
            <>
                <div className={`${classes.itemHeader} ${classes.report} ${openDialog ? classes.openReport : ""}`} onClick={() => { setOpenDialog(true) }}>
                    <Grid container spacing={1}>
                        <Grid xs={4} item container spacing={1} direction="column">
                            <Grid item>
                                <b>{task?.title}</b>
                            </Grid>
                            <Grid item>
                                {task?.description}
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} alignItems="center" direction="row">
                                    <Grid item>
                                        {task?.userResponse && <div><b>Response: </b>{task?.userResponse}</div>}
                                    </Grid>
                                    <Grid item className={classes.commentsWrapper}>
                                        {task?.comments &&
                                            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: task?.comments }} />}>
                                                <SpeakerNotesOutlinedIcon fontSize="small" className={classes.commentsIcon} />
                                            </Tooltip>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={3} item container spacing={1} direction="column">
                            <Grid item>
                                <b>Created</b>
                            </Grid>
                            <Grid item>
                                {FormatService.formatDateTimeNoConversion(task.taskDate)}
                            </Grid>
                        </Grid>
                        <Grid xs={3} item container spacing={1} direction="column">
                            <Grid item>
                                <b>Status</b>
                            </Grid>
                            <Grid item>
                                <StatusIndicator
                                    taskStatus={userTaskStatusesList.find(s => s.id == task.taskStatus)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <BasicModal
                    open={openDialog}
                    header={<div>
                        {task.title}
                    </div>}
                    wide={true}
                    handleClose={() => setOpenDialog(false)}
                    component={
                        <>
                            <div className={classes.description}>{task?.description}</div>
                            <div className={classes.taskFormWrapper}><UserTaskItem task={task} lotId={lotId} setOpenDialog={setOpenDialog} /></div>
                        </>
                    }
                />
            </>

        </>
    );
};

export default SuperTaskCard;
