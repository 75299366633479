import { Dialog, DialogActions, DialogContent, Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EditWrapper } from "./EditWrapper";
import { Service } from "./Service";
import FormatService from "services/formatService";
import NotificationProvider from "components/NotificationProvider";
import DatePicker from "components/DatePicker";
import { useUpdatePCOInvoiceDetailMutation } from "features/invoice/storage/storageInvoiceSlice.ts";
import { PCOInvoiceDetailsStyle } from "./PCOInvoiceDetailsStyle";
import { storageInvoiceServiceTypes, StorageInvoiceStatus } from "features/invoice/storage/storageInvoiceConsts";
import PCOInvoiceStatusDropdown from "../PCOInvoiceStatusDropdown";
import CustomInput from "components/CustomInput";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetInvoiceCustomerQuery } from "features/invoice/loads/loadInvoiceSlice";
import { arrayToObject } from "utils/object-util";
import { Link } from "react-router-dom";

const PCOInvoiceDetails = ({ invoice, onClose }) => {
    const classes = PCOInvoiceDetailsStyle();
    
    const [invoiceToEdit, setInvoiceToEdit] = useState();

    let { data: customers, error: customerError, isLoading: isLoadingCustomer } = useGetInvoiceCustomerQuery({});
    const customersLookup = arrayToObject(customers);

    useEffect(() => {
        setInvoiceToEdit({ ...invoice })
    }, [invoice])

    const editable = invoice?.statusId != StorageInvoiceStatus.SENT_TO_QB && invoice?.storageInvoiceVehicles?.length > 0;

    const invoiceFields = [
        {
            name: "Customer",
            key: "qbCustomerId",
            editable: editable,
            component: (id) => <> {customersLookup?.[id]?.fullyQualifiedName ?? '-'}</>,
            editComponent: () => <CustomInput
                value={invoiceToEdit?.qbCustomerId}
                onChange={(val) => onFieldChange('qbCustomerId', val)}
                elementType="dropdown"
                showEmpty
                values={customers?.map(i => ({ ...i, name: i.fullyQualifiedName }))}
            />
        },
        {
            name: "Lot",
            key: "lotName",
            editable: false,
        },
        {
            name: 'VIN',
            key: 'vin',
            editable: false,
            component: (val) => <Link disabled={!invoice?.vehicleId} to={`/assets/detail/${invoice?.vehicleId}`} target="_blank">{val}</Link>
        },
        {
            name: 'Cycle Start',
            key: 'cycleStart',
            editable: editable,
            component: (date) => <>{FormatService.formatToDatePicker(date)}</>,
            editComponent: () => <DatePicker
                title="Check In Date"
                value={invoiceToEdit?.cycleStart ? FormatService.formatToDatePicker(invoiceToEdit?.cycleStart) : null}
                onSelect={(val) => onFieldChange('cycleStart', val)} />
        },
        {
            name: 'Cycle End',
            key: 'cycleEnd',
            editable: editable,
            component: (date) => <>{FormatService.formatToDatePicker(date)}</>,
            editComponent: () => <DatePicker
                title="Check Out Date"
                value={invoiceToEdit?.cycleEnd ? FormatService.formatToDatePicker(invoiceToEdit?.cycleEnd) : null}
                onSelect={(val) => onFieldChange('cycleEnd', val)} />
        },
        {
            name: 'Billed Days',
            key: 'billedDays',
            width: 100,
            editable: false,
        },
        {
            name: 'Days PMF',
            key: 'daysPmf',
            width: 100,
            editable: false,
        },
        {
            name: 'Days On Lot',
            key: 'daysOnLot',
            width: 100,
            editable: false,
        },
        {
            name: 'Check In Fee',
            key: 'checkInFee',
            width: 100,
            editable: false,
        },
        {
            name: 'Check In Fee Days',
            key: 'checkInFeeDayCount',
            width: 100,
            editable: false,
        },
        {
            name: "Number Of Lines",
            key: "numberOfLines",
            editable: false,
        },
        {
            name: "Charge",
            key: "totalCharge",
            editable: false,
            component: (v) => `${FormatService.formatCurrency(v)}`,
        },
    ];

    const allFields = [
        {
            fields: invoiceFields,
        },
    ]

    let [updateInvoice, { isLoading: isSaving, isSuccess: saveSuccess }] = useUpdatePCOInvoiceDetailMutation();

    const onInvoiceSave = async () => {
        const fields = invoiceFields.filter(f => f.editable)?.map(f => f.key);
        const invoiceToSave = {
            storageInvoiceId: invoiceToEdit.storageInvoiceId,
        }

        fields.map(f => {
            invoiceToSave[f] = invoiceToEdit[f];
        })

        const result = await updateInvoice(invoiceToSave);

        if (result?.error) {
            NotificationProvider.error(`Failed to update invoice`)
        } else {
            NotificationProvider.success(`Successfully updated invoice`);
        }

    }

    const onFieldChange = async (fieldName, value) => {
        setInvoiceToEdit({
            ...invoiceToEdit,
            [fieldName]: value
        })
    }

    return (
        <Dialog maxWidth='md' fullWidth open={!!invoice} onClose={() => {
            setInvoiceToEdit();
            onClose();
        }} aria-labelledby='dialog-title' className={classes.invoiceDetails} >
            <DialogContent >
                <LoadingSpinner loading={isSaving} />
                <Grid container direction="column" spacing={2}>
                    {allFields?.map((group, groupIndex) => (
                        <Grid item className={classes.groupWrapper} key={groupIndex} >
                            <Grid container spacing={2} direction="column" >
                                {group.label && <Grid item className={classes.groupLabel} >{group.label}</Grid>}
                                {group.fields?.map((field, fieldIndex) =>
                                    <Grid item key={fieldIndex}>
                                        <Field invoice={invoiceToEdit} field={field} onSave={onInvoiceSave} />
                                    </Grid>)
                                } </Grid>
                        </Grid>
                    )
                    )}
                    <Grid item className={classes.services} >
                        <Services invoiceToEdit={invoiceToEdit} editable={editable}/>
                    </Grid>
                    <Grid item className={classes.totals}>
                        <Grid container direction='column' spacing='5'>
                            <Grid item>
                                <Grid container alignContent='space-between' className={classes.totalSummary}>
                                    <Grid item xs={5} className={classes.label}>Total:</Grid>
                                    <Grid item xs={6} >${invoiceToEdit?.totalCharge}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.status}>
                                <PCOInvoiceStatusDropdown invoice={invoiceToEdit} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}
export default PCOInvoiceDetails;

const Field = ({ invoice, field, onSave }) => {
    const classes = PCOInvoiceDetailsStyle();

    return (
        <Grid container >
            <Grid item xs={4} className={classes.label}>{field.name}</Grid>
            <Grid item xs={6} >
                <EditWrapper
                    editComponent={!invoice?.readonly && field.editComponent && field.editComponent()}
                    readonlyComponent={(field.component ? field.component(invoice?.[field.key]) : invoice?.[field.key] ?? '-')}
                    onSave={onSave}
                    editable={field.editable}
                />
            </Grid>

        </Grid>
    )
}

const Services = ({ invoiceToEdit, editable }) => {
    const classes = PCOInvoiceDetailsStyle();

    return (
        <Grid container direction='column' spacing='2'>
            <Grid  ><Divider /></Grid>
            <Grid item className={classes.groupLabel} >Services</Grid>

            <Grid item xs={11} container className={classes.headers}>
                <Grid item xs={4}>Service</Grid>
                <Grid item xs={4}>Description</Grid>
                <Grid item xs={4} >Amount</Grid>
            </Grid>
            <Grid item>
                <Service service={{...invoiceToEdit, totalCharge: invoiceToEdit?.totalFee}} type={storageInvoiceServiceTypes.STORAGE} editable={editable}/>
            </Grid>
            {invoiceToEdit?.storageInvoiceActivities?.map((service, serviceIndex) =>
                <Grid item key={serviceIndex}>
                    <Service service={service} type={storageInvoiceServiceTypes.ACTIVITY} editable={editable}/>
                </Grid>
            )}

            <Grid  ><Divider /></Grid>
        </Grid>
    )
}