import { makeStyles } from "@material-ui/core";

export const UpdateIssueStyle = makeStyles((theme) => ({
    fieldLabel: {
        fontWeight: '500',
        fontSize: '0.9em',
        marginBottom: '0.5em',
        color: '#8793A1',
        textTransform: 'uppercase'
    },
    field: {
        color: '#ooo',
        fontWeight: 500
    },
    content: {
        padding: '1em 1em',
    },
    titleWrapper: {
        padding: '1em 1em',
    },
    titleField: {
        fontSize: '1.3em',
        fontWeight: '500',
        color: 'black',
        '& input': {
            fontSize: '1.2em'
        }
    },
    icon: {
        display: 'flex',
        '& svg': {
            height: '1.2em'
        },
        '& path': {
            fill: theme.palette.primary.main
        }
    },
    btn: {
        textAlign: 'right',
    },
    editIcon: {
        '& path': {
            fill: 'white'
        }
    },
    alertWrapper: {
        position: 'relative'
    },
    alert: {
        position: 'absolute',
        width: '100%',
        padding: '0'
    },
    statusPopper: {
        background: theme.palette.background.paper,
        boxShadow: theme.shadows?.[2],
        padding: '2em',
        color: theme.palette.text.primary,
        fontSize: '0.8em'
    },
    statusItem: {
        width: '350px'
    }
}));