import { createSlice } from "@reduxjs/toolkit";

export const assetsFiltersSlice = createSlice({
    name: 'assetsFilters',
    initialState: {
        filters: {
            clientId: { value: '' },
            lotId: { value: '' },
            vehicleStatusId: { value: '' },
            vehicleTypeId: { value: '' }
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters: (state, action) => {
            state.filters = {
                clientId: { value: state.filters.clientId },
                lotId: { value: '' },
                vehicleStatusId: { value: '' },
                vehicleTypeId: { value: '' },
            }
        },
    },
});

export const { setFilters, resetFilters } = assetsFiltersSlice.actions;
export default assetsFiltersSlice.reducer;
