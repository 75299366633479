import { makeStyles } from "@material-ui/core";

export const tagInputStyle = makeStyles(theme => ({
    wrapper: {
        background: theme.palette.background.default,
        borderRadius: '4px',
        padding: '0.5em',
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
    },
    input: {
        width: '100%',
        background: 'transparent',
        border: '0',
        marginBottom: '6px',
        marginTop: '1px',
        outline: 'none',
        padding: '5px',
        fontWeight: '400',
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
    },

    tag: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
        background: '#194160',
        border: '2px solid #194160 ',
        color: theme.palette.secondary.contrastText,
        borderRadius: '10px',
        display: 'inline-block',
        marginBottom: '5px',
        marginRight: '5px',
        padding: '5px 12px',
        fontSize: '0.9em'
    },
    removeTagBtn: {
        cursor: 'pointer',
        fontWeight: 'bold',
        marginLeft: '0.5em',
    }
}));