import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { emptyPlaceholderStyle } from './EmptyPlaceholderStyle';
import mobileBreakpoint from '../../utils/mobileBreakpoint';
import { merge } from 'lodash';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';

const EmptyPlaceholder = ({ text = 'No Results', className, icon }) => {
    const classes = emptyPlaceholderStyle();

    return (
        <div className={`${className} ${classes.wrapper}`} elevation={1}>
            {icon ? icon : <StorageOutlinedIcon className={classes.icon} />}
            <div>{text}</div>
        </div>
    )
}

export default EmptyPlaceholder
