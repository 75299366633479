import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    groupTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '0.5em 0em'
    },
    gridContainer:{
        border: '1px solid #ccc', 
        padding: '10px', 
        position: 'relative',
        marginTop: "10px"
    },
    buttonSchedule: {
        marginTop: 15,
    },
    deleteButton: {
        position: 'absolute',
        bottom: '5px',
        right: '5px',
    },
    addButton: {
        position: 'absolute',
        right: '5px',
    },
    dropDownLine: {
        marginTop: 5,
        paddingRight: 5
    },
    invalid: {
        color: 'red',
        paddingLeft: 10
    }
}));