import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    card: {
        margin: "0 2rem 2rem 0",
        padding: '1.5em',
        minHeight: "7rem",
        cursor: "pointer",
        '&:hover': {
            boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.15)"
        },

    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: "rgb(212, 236, 255)",
        borderRadius: "50%",
        maxWidth: "fit-content",
        padding: "5px",
        margin: "0 1rem"
    },
    title: {
        margin: 0,
        padding: 0,
        fontSize: 20,
        fontWeight: 450,
        paddingTop: "5px"
    },
    activeWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5em 0'
    },
    activeIcon: {
        marginRight: '0.5em',
        background: '#a6ef69',
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        minWidth: '0.8em',
    }
}));
