import { Grid } from '@material-ui/core';
import { useEffect, useState } from "react";
import NotificationProvider from "../../../../../components/NotificationProvider";
import usePrompt from "../../../../../hooks/usePrompt";
import { useAddInvoiceServiceMutation, useDeleteInvoiceServiceMutation, useGetInvoiceAccountsQuery, useGetInvoiceItemsQuery, useSaveInvoiceServiceMutation } from "../../loadInvoiceSlice";
import { EditService } from './EditService';
import { EditWrapper } from "./EditWrapper";
import { invoiceDetailsStyle } from "./InvoiceDetailsStyle";

export const Service = ({ invoice, service = {}, onDone, onDelete, editMode = false }) => {

    let { data: invoiceItems, error, isFetching } = useGetInvoiceItemsQuery({});
    let { data: invoiceAccounts, error: accountsError, isFetching: accountsFetching } = useGetInvoiceAccountsQuery({});

    let [saveInvoiceService, { isLoading: isSavingService, isSuccess: saveServiceSuccess }] = useSaveInvoiceServiceMutation();
    let [addInvoiceService, { isLoading: isAddingService, isSuccess: addServiceSuccess }] = useAddInvoiceServiceMutation();
    let [deleteInvoiceService, { isLoading: isDeletingService, isSuccess: deleteServiceSuccess }] = useDeleteInvoiceServiceMutation();

    const [serviceToEdit, setServiceToEdit] = useState();


    const onFieldChange = async (fieldName, value, index) => {
        setServiceToEdit({
            ...serviceToEdit,
            [fieldName]: value
        })
    }

    useEffect(() => {
        setServiceToEdit({ ...service })
    }, [service])


    const onServiceFieldSave = async () => {
        const fields = ['amount', 'description', 'quickbooksItemId', 'quickbooksAccountId'];
        const serviceToSave = {
            id: serviceToEdit.id,
        }
        fields.map(f => {
            serviceToSave[f] = serviceToEdit[f];
        })
        let res;
        if (serviceToEdit.id) {
            res = await onSave(serviceToSave);
        } else {
            res = await onAdd({ ...serviceToSave, loadInvoiceId: invoice.id });
        }

        onDone && onDone(!res?.error && serviceToEdit);
    }

    const onSave = async (serviceToSave) => {
        const result = await saveInvoiceService(serviceToSave);
        if (result?.error) {
            NotificationProvider.error(`Failed to update service`)
        } else {
            NotificationProvider.success(`Successfully updated service`);
        }
        return result;
    }

    const onAdd = async (serviceToAdd) => {

        const result = await addInvoiceService(serviceToAdd);
        if (result?.error) {
            NotificationProvider.error(`Failed to add service`)
        } else {
            NotificationProvider.success(`Successfully added service`);
        }
        return result;
    }

    const { triggerPrompt } = usePrompt();

    const onDeleteService = async () => {

        triggerPrompt({
            title: "Delete Service",
            content: "Are you sure you want to delete the service?",
            loading: isDeletingService,
            onConfirm: async () => {
                const result = await deleteInvoiceService(serviceToEdit?.id);
                if (result?.error) {
                    NotificationProvider.error(`Failed to delete service`)
                } else {
                    NotificationProvider.success(`Successfully deleted service`);
                    onDelete && onDelete(serviceToEdit?.id);
                }
            },
        });

    }

    const classes = invoiceDetailsStyle();
    return (
        <div className={classes.service}>
            <Grid container className={classes.service}>
                <EditWrapper
                    editMode={editMode}
                    loading={isSavingService || isAddingService || deleteServiceSuccess}
                    readonlyComponent={
                        <Grid container>
                            <Grid item xs={3} className={`${classes.label} ${classes.lineDesc}`}>{serviceToEdit?.description}</Grid>
                            <Grid item xs={3}>{invoiceItems?.find(t => t.id === serviceToEdit?.quickbooksItemId)?.name ?? '-'}</Grid>
                            <Grid item xs={2}>{invoiceAccounts?.find(t => t.id === serviceToEdit?.quickbooksAccountId)?.name ?? '-'}</Grid>
                            <Grid item xs={2} >${serviceToEdit?.amount}</Grid>
                            <Grid item xs={1} >{serviceToEdit?.passThroughs ? 'Yes' : 'No'}</Grid>
                        </Grid>
                    }
                    editComponent={!invoice?.readonly &&
                        <EditService onFieldChange={onFieldChange} serviceToEdit={serviceToEdit} />}
                    onSave={onServiceFieldSave}
                    onDelete={!invoice?.readonly && serviceToEdit?.id && onDeleteService}
                    onCancel={() => {
                        onDone && onDone();
                    }}
                />
            </Grid>
        </div>
    )
}

