import { CircularProgress, Grid } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataTable from "components/DataTable/DataTable";
import { selectIsClient } from "features/user/userSlice";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import configStyles from "../configStyles";
import { useGetSingleCheckInForClientQuery } from "./CheckInConfigSlice";
import CheckInConfigStyle from "./CheckInConfigStyle";
import { DataPointType } from "./DataPointType";

const useStyles = CheckInConfigStyle;
const useConfigStyles = configStyles;

const CheckInGeneralDataSinglePage = ({ open, clientConfig, handleClose, checkinSla, setCheckinSla, activeCount }) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();

    let { data: clientsCheckIn, error: clientsCheckInError, isLoading: isLoadingClientsCheckIn } = useGetSingleCheckInForClientQuery(clientConfig?.id);
    clientsCheckIn = clientsCheckIn?.data || [];

    const [sortOptions, sortData] = useSortOptions();
    let isClient = useSelector(selectIsClient);
    const headerColors = ["#d4ecff", "#d4ffea", "#f6d4ff", "#d4dbff", "#ffd4d4", "#d4faff", "#efffd4", "#ffd4ee", "#d4ddff"];
    
    useEffect(() => {
        if (clientsCheckIn?.length) {
            const clientsCheckInMapped = clientsCheckIn?.map(row => ({
                ...row
            }))
            setCheckinSla(clientsCheckInMapped);
        }
    }, [clientsCheckIn, open])

    const onConfigChange = (row, key, val) => {
        setCheckinSla(checkinSla?.map(r => {
            if (r === row) {
                return {
                    ...r,
                    [key]: val,
                    changed: true
                };
            }
            return r;
        }));
    }

    const HeaderComponent = (sectionTypeLabel, sectionTypeId) => {
        return <div
            style={{ backgroundColor: headerColors[sectionTypeId - 1] }}
            className={classes.header}
        >
            {sectionTypeLabel}
        </div>
    }

    const TextComponent = (rowObject, value) => {
        const row = checkinSla.find(row => row === rowObject) ?? {};
        return <div className={`${classes.text} ${!row['isActive'] ? classes.notActiveText : ''}`}>{value || '-'}</div>
    }

    const SwitchComponent = (label, key, rowObject) => {
        const row = checkinSla.find(row => row === rowObject) ?? {};
        const isVinDataPoint = row?.dataPointTypeId == DataPointType.Vin;
        return <div className={`${!row['isActive'] ? classes.notActive : ''}`} >
            <FormControlLabel
                control={
                    <Authorize profile={isVinDataPoint == true ? permissionProfiles.ASSETS.EDIT_VIN_ASSET : permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                        <Switch
                            checked={row[key]}
                            onChange={(e) => onConfigChange(row, key, e.target.checked)}
                            color="primary"
                        />
                    </Authorize>
                }
                label={<div className={classes.label}>{label}</div>}
                labelPlacement="start"
            />
        </div>
    }

    const CheckboxComponent = (label, key, rowObject) => {
        const row = checkinSla.find(row => row === rowObject) ?? {};
        const isVinDataPoint = row?.dataPointTypeId == DataPointType.Vin;
        return <div className={`${classes.checkbox} ${!row[key] ? classes.notActiveDark : ''}`} >
            <FormControlLabel
                control={
                    <Authorize profile={isVinDataPoint == true ? permissionProfiles.ASSETS.EDIT_VIN_ASSET : permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                        <Checkbox
                            checked={row[key]}
                            onChange={(e, value) => onConfigChange(row, key, value)}
                        />
                    </Authorize>
                }
                label={<div className={classes.label}>{label}</div>}
                labelPlacement="start" />
        </div >
    }

    let checkInColumns = [
        { name: "Section", key: "sectionTypeId", component: (rowObject) => HeaderComponent(rowObject?.sectionTypeLabel, rowObject?.sectionTypeId), width: 150 },
        { name: "Field", key: "dataPointTypeLabel", component: (rowObject) => TextComponent(rowObject, rowObject?.dataPointTypeLabel), width: 400 },
        { name: "Value", key: "value", requireAdmin: true, component: (rowObject) => TextComponent(rowObject, rowObject?.value), width: 400 },
        { name: "Photo", key: "needPhoto", component: (rowObject) => SwitchComponent("Photo", "needPhoto", rowObject), width: 150 },
        { name: "Notes", key: "needNote", component: (rowObject) => SwitchComponent("Notes", "needNote", rowObject), width: 150 },
        { name: "Required", key: "isRequired", component: (rowObject) => SwitchComponent("Required", "isRequired", rowObject), width: 150 },
        { name: "Active", key: "isActive", component: (rowObject) => CheckboxComponent("Active", "isActive", rowObject), width: 50 }
    ];

    if (isClient) {
        checkInColumns = checkInColumns.filter(c => !c.requireAdmin);
    }

    let isLoading = isLoadingClientsCheckIn;

    return (
        <>
            {isLoading ? <CircularProgress className={classes.spinner} />
                : <Grid>
                    <div className={activeCount > 25 ? classes.counterMaxQuantity : classes.counterCorrectQuantity}>{activeCount} out of {25} active</div>
                    <div className={classes.table} >
                        <DataTable
                            customStyling
                            columns={checkInColumns}
                            rows={orderBy(
                                checkinSla,
                                sortOptions.columnToSort,
                                sortOptions.sortDirection
                            )}
                            rowIdentifier='id'
                            onSort={sortData}
                            sortDirection={sortOptions.sortDirection}
                            columnToSort={sortOptions.columnToSort}
                            noItemsMessage='There is no check-in SLA to display'
                            headerStyle={{
                                color: 'white',
                                backgroundColor: '#54b5ff',
                                padding: '1rem 0 1rem 1rem',
                            }}
                        />
                    </div>
                </Grid>
            }
        </>
    )
}
export default CheckInGeneralDataSinglePage;
