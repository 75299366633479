import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NoteEditor from "features/notes/NoteEditor";
import NoteList from "features/notes/NoteList";
import { NoteEntityTypes } from "features/notes/notesConsts";
import { IconAddCircle, IconCommentBlue } from "icons";
import { useEffect, useState } from "react";
import FormatService from "services/formatService";
import { commentsStyle } from "./commentsStyle";
const { Grid, Collapse, Tooltip, IconButton } = require("@material-ui/core")

const TaskComments = ({ issueTracker, latestStatus, statusLookup }) => {
    const classes = commentsStyle();

    const [addNew, setAddNew] = useState(false);
    const [statuses, setStatuses] = useState(issueTracker?.statuses);

    useEffect(() => {
        setExpandedStatusId(null);
        setStatuses(issueTracker?.statuses);
    }, [issueTracker])

    const [expandedStatusId, setExpandedStatusId] = useState(null);

    const handleCollapsed = (statusId) => {
        if (expandedStatusId === statusId) {
            setExpandedStatusId(null);
        } else {
            setExpandedStatusId(statusId);
        }
    };

    return (
        <Grid className={classes.commentsWrapper}>
            <Grid container direction='row' alignItems="center" justifyContent='space-between' className={classes.titleWrapper}>
                <Grid item container alignItems='center' xs={6} spacing={1}>
                    <Grid item style={{ display: 'flex' }} > <IconCommentBlue width='18' height='18' /></Grid>
                    <Grid item> <div className={classes.title}>Comments</div></Grid>
                </Grid>
                <Grid item alignContent='end'>
                    {<Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
                        <Tooltip title='Add Comment'>
                            <IconButton onClick={() => setAddNew(true)} color="primary" >
                                <IconAddCircle />
                            </IconButton>
                        </Tooltip>
                    </Authorize>}
                </Grid>
                {addNew && <Grid item xs={12} className={classes.newNote}>
                    <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}>
                        <NoteEditor
                            note={{ entityId: latestStatus?.id, entityType: NoteEntityTypes.ISSUE_TRACKER_STATUS }}
                            onClose={() => setAddNew(false)}
                            withDialog={false}
                            hideViewByClient
                        />
                    </Authorize>
                </Grid>}
            </Grid>
            <Grid container direction='column' spacing={2} >
                {statuses?.map(status =>
                    <Grid item container direction='column' >
                        <Grid className={classes.statusgrid} item container onClick={() => handleCollapsed(status?.id)} justifyContent='space-between' alignItems="center">
                            <Grid item >
                                <div className={classes.status}>{statusLookup?.[status?.status]?.name}</div>
                                <div className={classes.date}>{FormatService.formatDate(status?.createdDate)} </div>
                            </Grid>
                            <Grid>
                                {expandedStatusId === status?.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Collapse in={expandedStatusId === status?.id} timeout='auto' unmountOnExit>
                                <div style={{ width: '100%' }}>
                                    <NoteList
                                        notes={null}
                                        entityId={status?.id}
                                        entityType={NoteEntityTypes.ISSUE_TRACKER_STATUS}
                                        listOnly
                                        readOnly
                                        collapsible={false}
                                        showEmpty={true}
                                    />
                                </div>
                            </Collapse>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default TaskComments;