import { useEffect, useState } from 'react';
import { Editor, BtnBold, BtnItalic, EditorProvider, Toolbar, BtnBulletList, BtnNumberedList, BtnUnderline, BtnUndo, BtnRedo } from 'react-simple-wysiwyg';
import { editorStyle } from './style';
import sanitizeHtml from 'sanitize-html';
import { Divider } from '@material-ui/core';

const RichTextEditor = ({
    content,
    onChange,
    style,
}) => {
    const [html, setHtml] = useState(content);

    const classes = editorStyle();
    function onValueChange(e) {
        let finalContent = `${e?.target?.value}`;
        let cleanContent = sanitizeHtml(finalContent, {
            allowedTags: ['div', 'b', 'i', 'strike', 'li', 'ul', 'ol', 'h1', 'h2', 'br'],
            allowedAttributes: [],
            nonBooleanAttributes: [],
            parser: {
                decodeEntities: false//If we don't set this to false, the &nbsp will be converted to &ampnbsp
            },
        });
        cleanContent = cleanContent.split("<br />").join("<br>")//This is a hack, the sanitizer changes <br> to <br /> but that doesn't work well for the editor
        finalContent = cleanContent;
        if (finalContent !== content) {
            setHtml(cleanContent);
            onChange && onChange(cleanContent)
        }
    }

    useEffect(() => {
        setHtml(content);
    }, [content]);

    return (
        <div className={classes.editorWrapper}>
            <EditorProvider>
                <Editor
                    containerProps={{ style: style }}
                    value={html}
                    onChange={onValueChange}
                >
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
}
export default RichTextEditor;