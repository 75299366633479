import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useState } from "react";
import FilterService from "services/filterService";
import ActivityStatusAliases from "./components/MechanicalTabs/ActivityStatusAliases/ActivityStatusAliases";
import { AutomationLogicTab } from "./components/MechanicalTabs/AutomationLogicTab";
import { GeneralTab } from "./components/MechanicalTabs/GeneralTab";

const MechanicalWorkConfig = ({ clientToEdit, onConfigChange, configName = 'mechanicalsConfig' }) => {

    const [selectedLot, setSelectedLot] = useState();
    const [search, setSearch] = useState();

    const mechanicalsConfigParams = clientToEdit?.config?.mechanicalsConfig;
    const lots = FilterService.filter({}, search, clientToEdit?.clientLots, {});

    const tabs = [
        {
            label: 'General',
            component: <GeneralTab mechanicalsConfigParams={mechanicalsConfigParams} selectedLot={selectedLot} 
                            onConfigChange={onConfigChange} configName={configName} clientToEdit={clientToEdit} />
        },
        {
            label: 'Automation Logic',
            component: <AutomationLogicTab mechanicalsConfigParams={mechanicalsConfigParams} search={search} setSearch={setSearch} 
                            selectedLot={selectedLot} setSelectedLot={setSelectedLot} lots={lots} clientToEdit={clientToEdit} />
        },
        {
            label: 'Statuses',
            component: <ActivityStatusAliases clientId={clientToEdit?.id} />

        }
    ]

    return <>
        <TabsWrapper tabs={tabs} />         
    </>
}
export default MechanicalWorkConfig;