import { Button } from '@material-ui/core';
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import PageComponent from "components/Page/PageComponent";
import { orderBy } from 'lodash';
import { PCOInvoiceColumns, StorageInvoiceStatus } from '../../../storageInvoiceConsts.js';
import DataTable from 'components/DataTable/DataTable';
import useSortOptions from 'hooks/useSortOptions';
import PCOInvoiceDetails from '../PCOInvoiceDetails/PCOInvoiceDetails.js';
import { useMemo, useState } from 'react';
import StorageInvoicePdfDisplay from '../../StorageInvoicePdfDisplay/StorageInvoicePdfDisplay.js';
import { PCOInvoiceDetailsStyle } from '../PCOInvoiceDetails/PCOInvoiceDetailsStyle.js';
import { useGetInvoiceCustomerQuery } from 'features/invoice/loads/loadInvoiceSlice.js';
import { arrayToObject } from 'utils/object-util.js';
import { userPreferencesComponentIds } from 'components/UserPreferences/userPreferencesConsts.js';
import FilterService from 'services/filterService.js';

const PCOInvoiceTable = ({ invoices }) => {
    const classes = PCOInvoiceDetailsStyle();

    const [invoiceDetailsId, setInvoiceDetailsId] = useState();
    const [searchVal, setSearchVal] = useState();
    const [sortOptions, sortData] = useSortOptions();

    let { data: customers, error: customerError, isLoading: isLoadingCustomer } = useGetInvoiceCustomerQuery({});
    const customersLookup = arrayToObject(customers);

    let invoicesData = invoices?.map(invoice => {
        return ({
            ...invoice,
            ...invoice?.storageInvoiceVehicles?.[0],
            lotName: invoice?.lotStorageInvoiceDetails?.[0]?.name,
            numberOfLines: invoice?.storageInvoiceActivities?.length + 1,
            totalCharge: invoice?.storageInvoiceDetails?.[0]?.totalFee,
            customerName: customersLookup?.[invoice.qbCustomerId]?.fullyQualifiedName,
            statusId: invoice.status,
            storageInvoiceId: invoice?.storageInvoiceVehicles?.[0]?.storageInvoiceId ?? invoice.id
        })
    })

    invoicesData = orderBy(invoicesData, 'cycleEnd', 'desc');

    const invoicesDataLookup = arrayToObject(invoicesData)

    const rowActions = (rowId) => {
        const row = invoicesDataLookup?.[rowId];
        let actions = [
            {
                label: 'Details',
                component: <Button
                    onClick={() => setInvoiceDetailsId(rowId)}
                    variant='outlined'
                    className={classes.detailsBtn}
                >Details
                </Button>,
            }
        ]

        if (row.status == StorageInvoiceStatus.SENT_TO_QB) {
            actions.push({
                label: 'Details',
                component: <StorageInvoicePdfDisplay invoiceDetail={row?.storageInvoiceDetails?.[0]} />,
            })
        }

        return actions;

    };


    let filteredItems = useMemo(() => FilterService.filter([], searchVal, invoicesData ?? [], []), [searchVal, invoicesData]);

    return (
        <PageComponent
        >
            {!!invoices?.length ? <DataTable
                noItemsMessage='No Invoices Found'
                allowColumnCustomization
                tableId={userPreferencesComponentIds.PCO_INVOICE_TABLE}
                onSearch={(v) => setSearchVal(v)}
                searchVal={searchVal}
                columns={PCOInvoiceColumns}
                actions={rowActions}
                maxActionCount={2.5}
                rows={orderBy(
                    filteredItems,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
            /> :
                <EmptyPlaceholder text={'No Invoices'} />}
            <PCOInvoiceDetails invoice={invoicesDataLookup?.[invoiceDetailsId]} onClose={() => setInvoiceDetailsId()} />
        </PageComponent>
    );
};

export default PCOInvoiceTable;
