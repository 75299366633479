import { IconPin, IconTruck } from "icons";
import { IconActivityDefault } from "icons/mechanicals";

export const costCodesById = {
    ACTIVITIES: 1,
    STORAGE: 2,
    TRANSPORT: 3
};

export const CostCodeTypes = [
    { 
        id: 1, 
        name: "Activity Item", 
        label: "Activities",
        icon: <IconActivityDefault />
    },
    { 
        id: 2, 
        name: "Storage Invoice Field", 
        label: "Storage",
        icon:  <IconPin />
    },
    { 
        id: 3, 
        name: "Transport Field", 
        label: "Transport",
        icon: <IconTruck />
    },
];

export const StorageInvoiceFieldEnum = [
    {id: 3, name: "Storage Fee"},
    {id: 6, name: "Tax Rate Fee"},
];

export const TransportFieldEnum = [
    {id: 1, name: "Transportation Fee"},
    {id: 2, name: "Service Fee"},
    {id: 3, name: "Fuel Fee"},
    {id: 4, name: "Tolls Fee"},
];
