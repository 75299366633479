import { createSlice } from '@reduxjs/toolkit';
import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'mobileProcess';
export const mobileActivityApi = baseApi.injectEndpoints({
    reducerPath: 'mobileActivityApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getMobileActivity: builder.query({
            query: ({ lotId }) => `${apiPrefix}/activity?lotId=${lotId}`,
        }),
        getMobilePings: builder.query({
            query: () => `${apiPrefix}/pings`,
        }),
    })

});

export const {
    useGetMobileActivityQuery,
    useGetMobilePingsQuery
} = mobileActivityApi;


export const mobileActivitySlice = createSlice({
    name: 'mobileActivitySlice',
    initialState: {
        filters: {
            lotId: { value: null },
        },

    },
    reducers: {
        setMobileActivityFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setMobileActivityFilters } = mobileActivitySlice.actions;

