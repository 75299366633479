import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Card } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useEffect, useState } from "react";
import { getLotsWithPmfText } from "features/lots/lotUtil";
import DeleteIcon from '@material-ui/icons/Delete';
import { useCreateUserMutation, useGetUserLotRelationsForUserQuery } from "../usersSlice";
import NotificationProvider from "components/NotificationProvider";
import { useGetAllRolesQuery } from "components/Authorize/rolesSlice";
import { useSelector } from "react-redux";
import { selectIsOwner, selectUserId } from "features/user/userSlice";
import LoadingSpinner from "components/LoadingSpinner";
import Validator from "services/validator";
import CreateUserStyle from "./CreateUserStyle";
import {validatePassword} from "./validatePasswordUtil";

const useStyles = CreateUserStyle;

const CreateUserDialog = ({open, setOpenDialog, user}) => {
    const classes = useStyles();

    const userId = useSelector(selectUserId);

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: roles, error: roleError, isLoading: isLoadingRoles } = useGetAllRolesQuery()
    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsForUserQuery(userId, { skip: !userId });
    clients = clients || [];
    lots = lots || [];
    roles = roles?.data || [];
    userLotRelations = userLotRelations || [];

    const [createUser, {isLoading: isCreating}] = useCreateUserMutation();
    
    const [userToCreate, setUserToCreate] = useState(user);    
    const [selectedLotIds, setSelectedLotIds] = useState([]);
    const [selectedClientIds, setSelectedClientIds] = useState([]);
    const [editingPassword, setEditingPassword] = useState(false);
    const [editingConfirm, setEditingConfirm] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState()
    const [isSaving, setIsSaving] = useState(false);
    const [validPassword, setValidPassword] = useState(true);
    const [validConfirm, setValidConfirm] = useState(true);
    const [validBoth, setValidBoth] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [validUsername, setValidUsername] = useState(false);

    useEffect(() => {
        setSelectedLotIds(selectedLotIds.length ? selectedLotIds : [])
        setSelectedClientIds(selectedClientIds.length ? selectedClientIds : [])
    }, [userToCreate]);

    const onChange = (key, val) => {
        setUserToCreate({ ...userToCreate, [key]: val });
    };

    const onRemoveLot = (lot) => {
        let lotIdList = selectedLotIds;
        lotIdList = lotIdList.filter(l => l != lot)
        setSelectedLotIds(lotIdList);
        setUserToCreate({...userToCreate, ['lotIds']: selectedLotIds})
    }

    const onRemoveClient = (client) => {
        let clientIdList = selectedClientIds;
        clientIdList = clientIdList.filter(c => c != client)
        setSelectedClientIds(clientIdList);
        setUserToCreate({...userToCreate, ['clientIds']: selectedClientIds})
    }

    const onAddLot = (lotId) => {
        let lotIdList = selectedLotIds;
        lotIdList.push(lotId); 
        setSelectedLotIds(lotIdList);
        setUserToCreate({...userToCreate, ['lotIds']: selectedLotIds})
    }

    const onAddClient = (clientId) => {
        let clientIdList = selectedClientIds;
        clientIdList.push(clientId);
        setSelectedClientIds(clientIdList);
        setUserToCreate({...userToCreate, ['clientIds']: selectedClientIds})
    }

    const onSave = async () => {   
        setIsSaving(true);   

        let isValid = await validateData();
        if (!isValid) {
            setIsSaving(false);
            return;
        }

        setErrorMessages({});

        userToCreate["password"] = password;
        userToCreate["confirmPassword"] = confirmPassword;

        userToCreate.email.trim();
        userToCreate.userName.trim();
        
        let result = await createUser(userToCreate);
        
        if(result && !result.error) { 
            NotificationProvider.success("User created successfully");
            onClose()
        }
        else { 
            NotificationProvider.error("Error creating the user");
            setIsSaving(false);
        }
    }

    const onClose = () => {
        setUserToCreate(null); 
        setOpenDialog(false);
        setSelectedClientIds([]);
        setSelectedLotIds([]);
        setEditingPassword(false);
        setDisableSave(true);
        setEditingConfirm(false);
        setPassword("");
        setConfirmPassword("");
        setIsSaving(false);
        setValidBoth(false);
        setValidConfirm(false);
        setValidPassword(false);
        setErrorMessages({});
        setValidUsername(false);
    }

    const validateData = async () => {
        let isValid = true;

        let validEmail = Validator.validateEmail(userToCreate?.email);
        
        const updatedMessages = { ...errorMessages };

        if(!userToCreate?.userName || !validUsername){
            updatedMessages.userName = "Please enter a valid username";
            isValid = false;
        }
        else { if(updatedMessages.userName) updatedMessages.userName = null}

        if(!userToCreate?.fullName){
            updatedMessages.fullName = "Please enter a full name";
            isValid = false;
        }
        else { if(updatedMessages.fullName) updatedMessages.fullName = null}

        if(!userToCreate?.email || !validEmail){
            updatedMessages.email = "Please enter a valid email";
            isValid = false;
        }
        else { if(updatedMessages.email) updatedMessages.email = null}

        // if(!password || !validPassword)
        // {
        //     updatedMessages.password = "Please enter a valid password";
        //     isValid = false;
        // }
        // else { if(updatedMessages.password) updatedMessages.password = null}

        // if(!confirmPassword || !validConfirm)
        // {
        //     updatedMessages.confirmPassword = "Please enter a valid confirm password";
        //     isValid = false;
        // }
        // else { if(updatedMessages.confirmPassword) updatedMessages.confirmPassword = null}

        if(!userToCreate?.roleId)
        {
            updatedMessages.role = "Please select a role";
            isValid = false;
        }
        else { if(updatedMessages.role) updatedMessages.role = null }

        setErrorMessages(updatedMessages);
        return isValid;
    }

    const getErrorMessage = (field) => {
        return "* " + errorMessages[field]
    }

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientValues = (val) => {
        let values = clients?.filter(c => !selectedClientIds?.find(id => id === (c.id).toString()) || c.id === val)
        return values;
    }

    const isOwner = useSelector(selectIsOwner);

    let isRM = userLotRelations?.filter(u => u.relationType === 3); //lot association with rm
    let isSuper = userLotRelations?.filter(u => u.relationType === 2);
    let lotslist = []

    const getRoleValues = () => {
        if((isRM?.length > 0) && isOwner) { 
            return roles?.filter(r => r?.name === "account_manager" || r?.name === "admin");        
        }
        if(isSuper?.length > 0) {
            return roles?.filter(r => r?.name === "admin");
        }
        if(isOwner && (!isRM?.length > 0)) {
            return roles;
        }
    }

    const getLotValues = () => {
        if((isRM?.length > 0) && isOwner) { 
            lotslist = (lots?.filter(l => (isRM?.map(r => r.lotId)?.includes(l.id)) && (!selectedLotIds?.includes((l.id).toString()))));
            return getLotsWithPmfText(lotslist);
        }
        if(isSuper?.length > 0) {
            lotslist = (lots?.filter(l => (isSuper?.map(r => r.lotId)?.includes(l.id)) && (!selectedLotIds?.includes((l.id).toString()))));
            return getLotsWithPmfText(lotslist);
        }
        if(isOwner && (!isRM?.length > 0)) {
            return getLotsWithPmfText(lots);
        }
    }

    const onChangePasswords = (pword, cPassword) => {
        pword ? setEditingPassword(true) : setEditingPassword(false);
        cPassword ? setEditingConfirm(true) : setEditingConfirm(false);

        let validPasswords = validatePassword(pword ? pword : password, cPassword ? cPassword : confirmPassword);
        
        setValidPassword(validPasswords.validPassword);
        setValidConfirm(validPasswords.validConfirm);
        setValidBoth(validPasswords.validBoth);

        setPassword(pword);
        setConfirmPassword(cPassword);

        if (validPasswords.validBoth) {
            setDisableSave(false);             
        } else { 
            setDisableSave(true);
        }       
    }

    const onChangeUsername = (username) => {
        const regex = /^[a-zA-Z0-9_@\\-\\.\\+]+$/;
        let valid = regex.test(username);

        setValidUsername(valid);

        if(valid) { onChange("userName", username); }
    }

    return (
        <>
        <LoadingSpinner loading={isSaving} />
        <Dialog open={open} className={classes.paper} fullWidth maxWidth='md' >
            <DialogTitle className={classes.title}>{'Create User'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} direction='column'>
                    <Paper className={classes.paper}>
                        <div className={classes.label}>Basic Information</div>  
                        <Grid item className={classes.grids}>
                            <CustomInput
                                id="userName"
                                label="Username"
                                elementType="input"
                                classStyles={classes.dropdown}
                                required={true}
                                onChange={(val) => onChangeUsername(val)}
                            />
                            {errorMessages.userName && <p className={classes.requiredField}>{getErrorMessage('userName')}</p>}
                        </Grid>
                        <Grid item className={classes.grids}>
                            <CustomInput
                                id="name"
                                label="Full Name"
                                elementType="input"
                                classStyles={classes.dropdown}
                                required={true}
                                onChange={(val) => onChange("fullName", val)}
                            />
                            {errorMessages.fullName && <p className={classes.requiredField}>{getErrorMessage('fullName')}</p>}
                        </Grid>
                        <Grid item className={classes.grids}>
                            <CustomInput
                                id="email"
                                label="Email"
                                elementType="input"
                                classStyles={classes.dropdown}
                                required={true}
                                onChange={(val) => onChange("email", val)}
                                valid={Validator.validateEmail(userToCreate?.email)}
                            />
                            {errorMessages.email && <p className={classes.requiredField}>{getErrorMessage('email')}</p>}
                       </Grid>
                        {/*<Grid item className={classes.grids}>*/}
                        {/*    <CustomInput*/}
                        {/*        id="password"*/}
                        {/*        label="Password"*/}
                        {/*        elementType="input"*/}
                        {/*        classStyles={classes.dropdown}*/}
                        {/*        onChange={val => onChangePasswords(val, confirmPassword)}*/}
                        {/*        required*/}
                        {/*        touched*/}
                        {/*    />*/}
                        {/*    {errorMessages.password && <p className={classes.requiredField}>{getErrorMessage('password')}</p>}*/}
                        {/*</Grid>*/}
                        {/*{ */}
                        {/*    !validPassword && editingPassword ?*/}
                        {/*        <Grid className={classes.grids}>*/}
                        {/*            <Alert severity="warning">*/}
                        {/*                {'Password requires a minimum of 8 characters, upper and lower case letters, a number and a special character'}*/}
                        {/*            </Alert> */}
                        {/*        </Grid> : null*/}
                        {/*}*/}
                        {/*<Grid item className={classes.grids}>*/}
                        {/*    <CustomInput*/}
                        {/*        id="confirmpassword"*/}
                        {/*        label="Confirm Password"*/}
                        {/*        elementType="input"*/}
                        {/*        classStyles={classes.dropdown}*/}
                        {/*        onChange={val => onChangePasswords(password, val)}*/}
                        {/*        required*/}
                        {/*        touched*/}
                        {/*    />*/}
                        {/*    {errorMessages.confirmPassword && <p className={classes.requiredField}>{getErrorMessage('confirmPassword')}</p>}*/}
                        {/*</Grid>*/}
                        {/*{*/}
                        {/*    !validConfirm && editingConfirm ?*/}
                        {/*        <Grid className={classes.grids}>*/}
                        {/*            <Alert severity="warning">*/}
                        {/*                {"Passwords do not match"}*/}
                        {/*            </Alert>*/}
                        {/*        </Grid> : null*/}
                        {/*}*/}
                        <Grid item className={classes.grids}>
                            <CustomInput
                                id="role"
                                label="Role"
                                elementType="dropdown"
                                values={getRoleValues()}
                                onChange={val => onChange("roleId", val)}
                                required
                                touched
                                showEmpty
                            />
                            {errorMessages.role && <p className={classes.requiredField}>{getErrorMessage('role')}</p>}
                        </Grid>      
                    </Paper>       
                    <Paper className={classes.paper}>    
                    <div className={classes.label}>Lot Access</div>  
                    <Grid item className={classes.grids}>
                        <CustomInput
                            id="lot"
                            label="Add Lot Access"
                            value={userToCreate?.lotIds}
                            elementType="dropdown"
                            onChange={val => onAddLot(val)}
                            values={getLotValues()}
                            showEmpty
                        />
                    </Grid>
                    {
                        selectedLotIds &&
                            selectedLotIds?.map(lot =>
                            <>
                            <Card className={classes.list}>
                                <Grid item className={classes.restrictions} alignItems='center'>
                                    {lotLookup[lot]?.name}
                                    <IconButton edge="end" aria-label="delete"
                                        onClick={() => onRemoveLot(lot)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Card>
                            </>
                            )
                    } 
                    </Paper>                        
                    <Paper className={classes.paper}>
                    <div className={classes.label}>Client Access</div>  
                    <Grid item className={classes.grids}>
                        <CustomInput
                            id="clientId"
                            label="Add Client Access"
                            value={userToCreate?.clientIds}
                            elementType="dropdown"
                            onChange={val => onAddClient(val)}
                            values={clientValues()}
                            showEmpty
                        />                        
                    </Grid>
                    {
                        selectedClientIds &&
                            selectedClientIds?.map(client =>
                            <Card className={classes.list}>
                                <Grid item className={classes.restrictions} alignItems='center'>
                                    {clientLookup[client]?.name}
                                    <IconButton edge="end" aria-label="delete"
                                        onClick={() => onRemoveClient(client)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Card>
                            )
                    }
                    </Paper>                        
                </Grid>
            </DialogContent>
            <DialogActions> 
                <Button onClick={() => onClose()} color="primary">Close</Button>
                <Button onClick={() => onSave()} color="primary" variant={"contained"} disabled = {isSaving}>{isSaving ? "Saving..." : "Save"}</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default CreateUserDialog;