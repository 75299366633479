import { makeStyles } from "@material-ui/core";

export const activityInvoiceSummaryStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '2em',
        background: '#F5FBFF',
        margin: '1em 0'
    },
    icon: {
        borderRadius: '50%',
        background: theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '3em',
        width: '3em',
    },
    label: {
        color: theme.palette.secondary.light,
        whiteSpace: 'normal'
    },
    amount: {
        color: theme.palette.secondary.light,
        fontSize: '1.2em',
        fontWeight: 'bold'
    },
    totalText: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        fontWeight: 'bold'
    }
}));