import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper
} from "@material-ui/core";
import CustomInput from "components/CustomInput";
import {useEffect, useState} from "react";
import {getLotsWithPmfText} from "features/lots/lotUtil";
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationProvider from "components/NotificationProvider";
import LoadingSpinner from "components/LoadingSpinner";
import Validator from "services/validator";
import CreateUserStyle from "./CreateUserStyle";
import {useCreateUserByManagerMutation} from "../userManagementSlice";
import { useSelector } from "react-redux";
import { selectRole } from "features/user/userSlice";
import { userTypeEnums, userTypeOptionsEnums } from "features/users/UsersEnums";

const useStyles = CreateUserStyle;

const CreateUserDialog = ({open, roles, setOpenDialog,refetchUsers, user, clients, lots}) => {
    const classes = useStyles();
    const [createUser, {isLoading: isCreating}] = useCreateUserByManagerMutation();

    clients = clients || [];
    lots = lots || [];
    roles = roles || [];

    const [userToCreate, setUserToCreate] = useState(user);
    const [selectedLotIds, setSelectedLotIds] = useState([]);
    const [selectedClientIds, setSelectedClientIds] = useState([]);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState()
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [validUsername, setValidUsername] = useState(false);
    const [userTypeId, setUserTypeId] = useState(null);
    const [userRoleInfo, setUserRoleInfo] = useState(null);
    const userLoggedProfileRoleName = useSelector(selectRole);

    useEffect(() => {
        setUserTypeId(null);
    }, [open]);

    useEffect(() => {
        setSelectedLotIds(selectedLotIds.length ? selectedLotIds : [])
        setSelectedClientIds(selectedClientIds.length ? selectedClientIds : [])
    }, [userToCreate]);

    useEffect(() => {
        if (roles) {
           let myRoleInfo = [...roles]?.find(x => x.name === userLoggedProfileRoleName);
           setUserRoleInfo(myRoleInfo);
        }
    }, [roles])

    const onChange = (key, val) => {
        if(key === "roleId"){
            let selectRole =  roles.find(x=> x.key == val);
            val = selectRole.key;
        }
        setUserToCreate({...userToCreate, [key]: val});
    };

    const onRemoveLot = (lot) => {
        let lotIdList = selectedLotIds;
        lotIdList = lotIdList.filter(l => l != lot)
        setSelectedLotIds(lotIdList);
        setUserToCreate({...userToCreate, ['lotIds']: selectedLotIds})
    }

    const onRemoveClient = (client) => {
        let clientIdList = selectedClientIds;
        clientIdList = clientIdList.filter(c => c != client)
        setSelectedClientIds(clientIdList);
        setUserToCreate({...userToCreate, ['clientIds']: selectedClientIds})
    }

    const onAddLot = (lotId) => {
        let lotIdList = selectedLotIds;
        lotIdList.push(lotId);
        setSelectedLotIds(lotIdList);
        setUserToCreate({...userToCreate, ['lotIds']: selectedLotIds})
    }

    const onAddClient = (clientId) => {
        let clientIdList = selectedClientIds;
        clientIdList.push(clientId);
        setSelectedClientIds(clientIdList);
        setUserToCreate({...userToCreate, ['clientIds']: selectedClientIds})
    }

    const onSave = async () => {

        setIsSaving(true);

        let isValid = await validateData();
        if (!isValid) {
            setIsSaving(false);
            return;
        }

        setErrorMessages({});

        userToCreate["password"] = password;
        userToCreate["confirmPassword"] = confirmPassword;

        userToCreate.email.trim();
        userToCreate.userName.trim();
        userToCreate.UserType = parseInt(userTypeId ?? 1);

        let result = await createUser(userToCreate);

        if (result && !result.error) {
            NotificationProvider.success("User created successfully");
            refetchUsers();
            onClose()
        } else {
            NotificationProvider.error("Error creating the user");
            setIsSaving(false);
        }
    }

    const onClose = () => {
        setUserToCreate(null);
        setOpenDialog(false);
        setSelectedClientIds([]);
        setSelectedLotIds([]);
        setPassword("");
        setConfirmPassword("");
        setIsSaving(false);
        setErrorMessages({});
        setValidUsername(false);
    }

    const validateData = async () => {
        let isValid = true;

        let validEmail = Validator.validateEmail(userToCreate?.email);

        const updatedMessages = {...errorMessages};

        if (!userToCreate?.userName || !validUsername) {
            updatedMessages.userName = "Please enter a valid username";
            isValid = false;
        } else {
            if (updatedMessages.userName) updatedMessages.userName = null
        }

        if (!userToCreate?.fullName) {
            updatedMessages.fullName = "Please enter a full name";
            isValid = false;
        } else {
            if (updatedMessages.fullName) updatedMessages.fullName = null
        }

        if (!userToCreate?.email || !validEmail) {
            updatedMessages.email = "Please enter a valid email";
            isValid = false;
        } else {
            if (updatedMessages.email) updatedMessages.email = null
        }

        if (!userToCreate?.roleId) {
            updatedMessages.role = "Please select a role";
            isValid = false;
        } else {
            if (updatedMessages.role) updatedMessages.role = null
        }

        if (!userTypeId) {
            updatedMessages.userType = "Please select a user type";
            isValid = false;
        } else {
            if (updatedMessages.userType) updatedMessages.userType = null
        }

        setErrorMessages(updatedMessages);
        return isValid;
    }

    const getErrorMessage = (field) => {
        return "* " + errorMessages[field]
    }

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientValues = (val) => {
        return clients?.filter(c => !selectedClientIds?.find(id => id === (c.id).toString()) || c.id === val);
    }
    const getRoleValues = () => {
        let rolesCanISee = [];
        let rolesAssigment = userRoleInfo?.rolesIdsVisibles ? [...userRoleInfo?.rolesIdsVisibles] : [];
        let allRolesInformationCopy = [...roles]?.filter(x => x.key != userRoleInfo?.key) ?? [...roles];
        if (rolesAssigment?.length == 0) return [];

        if (rolesAssigment) {
            allRolesInformationCopy = allRolesInformationCopy.filter((rol) => rolesAssigment.some(x => x == rol.id));
            allRolesInformationCopy.push(userRoleInfo);
        }

        if (userTypeId) {
            if (userTypeId == userTypeEnums.InternalPMF) {
                rolesCanISee = allRolesInformationCopy?.filter(y => y.isRoleForClient == false);
            }
            if (userTypeId ==  userTypeEnums.Client) {
                rolesCanISee =  allRolesInformationCopy?.filter(y => y.isRoleForClient == true);
            }
            return rolesCanISee.map(x => ({name: x.name, id: x.key}));
        }

        rolesCanISee = allRolesInformationCopy;
        return rolesCanISee.map(x => ({name: x.name, id: x.key}));
    }

    const getLotValues = () => {
        return getLotsWithPmfText(lots);
    }

    const onChangeUsername = (username) => {
        const regex = /^[a-zA-Z0-9_@\\-\\.\\+]+$/;
        let valid = regex.test(username);
        setValidUsername(valid);
        if (valid) {
            onChange("userName", username);
        }
    }

    const changeUserType = (val) => {
        setUserToCreate({...userToCreate, ["roleId"]: null});
        setUserTypeId(val); 
    }

    return (
        <>
            <LoadingSpinner loading={isSaving}/>
            <Dialog open={open} className={classes.paper} fullWidth maxWidth='md'>
                <DialogTitle className={classes.title}>{'Create User'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} direction='column'>
                        <Paper className={classes.paper}>
                            <div className={classes.label}>Basic Information</div>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="userName"
                                    label="Username"
                                    elementType="input"
                                    classStyles={classes.dropdown}
                                    required={true}
                                    onChange={(val) => onChangeUsername(val)}
                                />
                                {errorMessages.userName &&
                                    <p className={classes.requiredField}>{getErrorMessage('userName')}</p>}
                            </Grid>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="name"
                                    label="Full Name"
                                    elementType="input"
                                    classStyles={classes.dropdown}
                                    required={true}
                                    onChange={(val) => onChange("fullName", val)}
                                />
                                {errorMessages.fullName &&
                                    <p className={classes.requiredField}>{getErrorMessage('fullName')}</p>}
                            </Grid>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="email"
                                    label="Email"
                                    elementType="input"
                                    classStyles={classes.dropdown}
                                    required={true}
                                    onChange={(val) => onChange("email", val)}
                                    valid={Validator.validateEmail(userToCreate?.email)}
                                />
                                {errorMessages.email &&
                                    <p className={classes.requiredField}>{getErrorMessage('email')}</p>}
                            </Grid>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="userType"
                                    label="User Type"
                                    elementType="dropdown"
                                    values={[...userTypeOptionsEnums]}
                                    onChange={val => changeUserType(val)}
                                    required
                                    touched
                                    showEmpty
                                    value={userTypeId}
                                />
                                {errorMessages.userType &&
                                    <p className={classes.requiredField}>{getErrorMessage('userType')}</p>}
                            </Grid>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="role"
                                    label="Role"
                                    elementType="dropdown"
                                    values={getRoleValues()}
                                    onChange={val => onChange("roleId", val)}
                                    required
                                    touched
                                    showEmpty
                                    disabled={!userTypeId}
                                    value={userToCreate?.roleId ?? null}
                                />
                                {errorMessages.role &&
                                    <p className={classes.requiredField}>{getErrorMessage('role')}</p>}
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <div className={classes.label}>Lot Access</div>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="lot"
                                    label="Add Lot Access"
                                    value={userToCreate?.lotIds}
                                    elementType="dropdown"
                                    onChange={val => onAddLot(val)}
                                    values={getLotValues()}
                                    showEmpty
                                />
                            </Grid>
                            {
                                selectedLotIds &&
                                selectedLotIds?.map(lot =>
                                    <>
                                        <Card className={classes.list}>
                                            <Grid item className={classes.restrictions} alignItems='center'>
                                                {lotLookup[lot]?.name}
                                                <IconButton edge="end" aria-label="delete"
                                                            onClick={() => onRemoveLot(lot)}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Card>
                                    </>
                                )
                            }
                        </Paper>
                        <Paper className={classes.paper}>
                            <div className={classes.label}>Client Access</div>
                            <Grid item className={classes.grids}>
                                <CustomInput
                                    id="clientId"
                                    label="Add Client Access"
                                    value={userToCreate?.clientIds}
                                    elementType="dropdown"
                                    onChange={val => onAddClient(val)}
                                    values={clientValues()}
                                    showEmpty
                                />
                            </Grid>
                            {
                                selectedClientIds &&
                                selectedClientIds?.map(client =>
                                    <Card className={classes.list}>
                                        <Grid item className={classes.restrictions} alignItems='center'>
                                            {clientLookup[client]?.name}
                                            <IconButton edge="end" aria-label="delete"
                                                        onClick={() => onRemoveClient(client)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Card>
                                )
                            }
                        </Paper>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary">Close</Button>
                    <Button onClick={() => onSave()} color="primary" variant={"contained"}
                            disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreateUserDialog;