import * as React from "react";

function IconFederalDot(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.76 3.33035L15.38 6.62035C15.67 7.20035 16.22 7.60035 16.86 7.69035L20.49 8.22035C22.1 8.45035 22.74 10.4304 21.58 11.5704L18.96 14.1304C18.5 14.5804 18.29 15.2304 18.4 15.8704L19.02 19.4804C19.29 21.0804 17.61 22.3004 16.17 21.5504L12.93 19.8404C12.36 19.5404 11.68 19.5404 11.1 19.8404L7.86 21.5504C6.42 22.3104 4.74 21.0804 5.01 19.4804L5.63 15.8704C5.74 15.2304 5.53 14.5804 5.07 14.1304L2.45 11.5704C1.29 10.4404 1.93 8.46035 3.54 8.22035L7.17 7.69035C7.81 7.60035 8.36 7.20035 8.65 6.62035L10.27 3.33035C10.99 1.87035 13.07 1.87035 13.79 3.33035H13.76Z" stroke="#0090FE" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
        </svg>

    );
}

export default IconFederalDot;
