import { makeStyles } from "@material-ui/core";

export const washDashboardStyles = makeStyles((theme) => ({
    card: {
        //margin: '1rem 0',
        padding: '2rem ',
        height: '100%'
    },
    graphWrapper: {
        height: '100%'
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    wrapper: {
        height: '100%',
        cursor: 'pointer'
    },
    count: {
        color: theme.palette.primary.main,
        fontSize: 72,
        fontWeight: 700
    },
    text: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0
    },
    tooltip: {
        background: '#fff',
        padding: '1em',
        boxShadow: '1px 2px 7px #efefef'
    }
}));