import { makeStyles } from '@material-ui/core/styles';

export const SuperTaskCardStyle = makeStyles((theme) => ({
    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5em',
        cursor: 'pointer',
        alignItems: 'center'
    },
    report: {
        padding: '1em 1rem',
        cursor: 'pointer',
        borderBottom: '1.5px solid #e1e3e4',
        '&:last-of-type': {
            border: 'none'
        },
        '&:hover': {
            background: theme.palette.neutral.light,
        }
    },
    openReport: {
        background: theme.palette.neutral.light,
    },
    description: {
        margin: '0 1rem 1rem 1rem'
    },
    commentsWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    commentsIcon: {
        fill: "#747574"
    },
    greenIndicator: {
        background: '#a6ef69',
    },
    yellowIndicator: {
        background: '#ffda4c',
    },
    indicatorWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    indicator: {
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        minWidth: '0.8em',
        cursor: 'pointer',
        marginRight: '0.5rem'
    },
    taskFormWrapper: {
        margin: '0.5rem 1rem 0.5rem 1rem'
    },
}));
