export const LotTypeEnum = {
    SERVICE_PROVIDER: 1,
    OEM_SERVICE: 2,
    THIRD_PARTY: 3,
    STANDARD: 4
};

export const LotTypeEnumList = [
    {id: 1, name: "Service Provider"},
    {id: 2, name: "OEM Service Provider"},
    {id: 3, name: "3rd Party Lot"},
    {id: 4, name: "Standard Lot"}
];

export const LotTypeEnumListOptions = [
    {id: '1', name: "Service Provider"}, 
    {id: '2', name: "OEM Service Provider"}, 
    {id: '3', name: "3rd Party Lot"},
    {id: '4', name: "Standard Lot"}
];