export enum ReportHistoryStatus
{
    PENDING = 1,
    CREATED = 2,
    SENT_TO_CLIENT = 3,
}

export enum ReportTypeEnum 
{
    STORAGE_REPORT = 1,
    STORAGE_BILLING_REPORT = 2,
    INVOICE_REPORT = 3,
}

export const reportTypesList = [
    { id: ReportTypeEnum.STORAGE_REPORT, name: "Storage Report" },
    { id: ReportTypeEnum.STORAGE_BILLING_REPORT, name: "Storage Billing Report" },
    { id: ReportTypeEnum.INVOICE_REPORT, name: "Invoice Report" }
];
