import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    },
    header: {
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(2),
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    headerIcon: {
        marginRight: theme.spacing(1),
    },
    searchInput: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    }
}));