import { makeStyles } from '@material-ui/core/styles';

export const tableViewsStyle = makeStyles((theme) => ({
    item: {

    },
    toggleButton: {

    },
    title: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: '0 1em',
        height: '3.5em',
        fontWeight: '500'
    },
    actions: {
        padding: '0.5em 1em',
    },
    columnContainer: {
        padding: '0.5em 1em',
    },
    columnList: {
        maxHeight: '300px',
    },
    statusItem: {
        cursor: 'pointer',
        alignItems: "center",
        justifyContent: "space-between"
    },
    selectItem: {
        padding: '0.5em 0.5em',
        // fontWeight: '500',
        '&.filled': {
            paddingLeft: '0.5em'
        }
    },
    select: {
        fontSize: '1em',
        padding: 0,
        borderRadius: 0,
        '& fieldset': {
            border: 'none',
        },
        '& .MuiSelect-icon': {
            fill: 'black',
            width: '20px'
        }
    },
    selectTitle: {
        paddingRight: '1rem',
        fontWeight: 500
    },
    deleteIcon: {
        borderRadius: '50%',
        padding: '4px',
        marginLeft: '2em',
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
        }
    }
}));
