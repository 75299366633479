import { makeStyles } from "@material-ui/core";

export const IssueTrackerLinksStyle = makeStyles((theme) => ({
    iconWrapper: {
        '& svg': {
            width: '1em'
        }
    },
    woLink: {
        color: '#A159D8',
        fontWeight: 500,
        '& path': {
            stroke: '#A159D8'
        }
    },
    loadLink: {
        color: '#BF8F00',
        fontWeight: 500,
        '& path': {
            stroke: '#BF8F00'
        }
    },
    noMarginBottom: {
        marginBottom: 0
    },
    linkedAssets: {
        maxHeight: '50px',
        overflowY: 'auto',
        overflowX: 'hidden',

        // Scrollbar customization
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        }
    }
}))