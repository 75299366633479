import React from 'react';
import { LoadGroupJourneyStyles } from './LoadGroupJourneyStyles';
import { Button, Grid, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadsErrors } from 'features/loads/loadsStateSlice';
import { v4 as uuidv4 } from 'uuid'
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { useState } from 'react';
import { groupLegTypes } from 'features/loads/enums';
import LoadJourneyItem from './LoadJourneyItem';
import ActivityJourneyItem from './ActivityJourneyItem';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';


function LoadGroupJourney({ legIndex, setLegIndex, loadGroup, setLoadGroup, readonly, condensed }) {
    const classes = LoadGroupJourneyStyles()

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    const { newLots, loadsErrors, loadsMissingFields } = useSelector(state => state.loadState);

    const dispatch = useDispatch();

    lots = lots || [];
    lots = [...lots, ...newLots];

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const onAddTransportLeg = () => {
        let previousLeg = loadGroup?.loads?.[loadGroup?.loads?.length - 1];
        setLegIndex(loadGroup?.loads?.length);
        setLoadGroup({
            ...loadGroup,
            ["loads"]: [...loadGroup?.loads,
            {
                ...previousLeg,
                uuid: uuidv4(),
                type: groupLegTypes.TRANSPORT,
                pickupLocationId: getPrevLotId(previousLeg),
                pickupName: previousLeg?.deliveryName,
                pickupNodeName: previousLeg?.deliveryNodeName,
                pickupAddress: previousLeg?.deliveryAddress,
                pickupCity: previousLeg?.deliveryCity,
                pickupState: previousLeg?.deliveryState,
                pickupZip: previousLeg?.deliveryZip,
                pickupPhone: previousLeg?.deliveryPhone,
                pickupPocName: previousLeg?.deliveryPocName,
                pickupPocEmail: previousLeg?.deliveryPocEmail,
            }
            ]
        });
        handleClose();
    }

    const onAddActivityLeg = () => {
        let previousLeg = loadGroup?.loads?.[loadGroup?.loads?.length - 1];
        setLegIndex(loadGroup?.loads?.length);
        setLoadGroup({
            ...loadGroup,
            ["loads"]: [...loadGroup?.loads,
            {
                uuid: uuidv4(),
                type: groupLegTypes.ACTIVITY,
                lotId: getPrevLotId(previousLeg),
            }
            ]
        });
        handleClose();
    }

    const addLegByIndex = (ind) => {
        let previousLeg = loadGroup?.loads?.[ind - 1];
        let loadsList = [...loadGroup?.loads]
        loadsList.splice(ind, 0,
            {
                uuid: uuidv4(),
                type: groupLegTypes.ACTIVITY,
                lotId: getPrevLotId(previousLeg),
            })
        setLoadGroup({
            ...loadGroup,
            ["loads"]: loadsList
        });
        setLegIndex(ind);
    }

    const getPrevLotId = (prev) => {
        if (prev?.type === groupLegTypes.ACTIVITY) {
            return prev?.lotId;
        } else {
            return prev?.destinationNewLot ? prev?.deliveryNewLotId : prev?.deliveryLocationId;
        }
    }

    const deleteLeg = (loadIndex) => {
        let filteredLoads = loadsList?.filter(load => load?.index !== loadIndex)
        setLoadGroup({ ...loadGroup, ["loads"]: filteredLoads });
        setLegIndex(0)
        let errors = loadsErrors;
        if (errors?.[loadGroup?.loads?.[loadIndex]?.uuid]) {
            delete errors?.[loadGroup?.loads?.[loadIndex]?.uuid]
            dispatch(setLoadsErrors(errors))
        }
    }

    const loadsList = loadGroup?.loads?.map((load, index) => {
        return {
            ...load,
            index: index,
            pickupLotName: lotLookup?.[load?.pickupLocationId]?.name || load?.pickupName,
            deliveryLotName: lotLookup?.[load?.deliveryLocationId]?.name || load?.deliveryName,
        };
    })

    const sortedLoadsList = loadsList//?.sort((a, b) => (new Date(a?.pickupStartDate) - new Date(b?.pickupStartDate)))

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const showDeleteButton = (load) => {
        if (loadsList?.length <= 1) return false;
        if (load.index === 0 && loadsList?.[1]?.type === groupLegTypes.ACTIVITY) return false;
        return true;
    }

    return (
        <div className={`${classes.journey} ${condensed ? classes.condensed : ''}`}>
            <Grid direction='row' container>
                {sortedLoadsList?.map((load, ind) =>
                    <>
                        <Grid item key={ind} className={`${classes.legIten} ${load.index == legIndex ? classes.currentLeg : classes.otherLeg}`}>
                            {load?.type == groupLegTypes.ACTIVITY ?
                                <ActivityJourneyItem load={load} index={legIndex} setIndex={setLegIndex} loadGroup={loadGroup} setLoadGroup={setLoadGroup} key={ind} /> :
                                <LoadJourneyItem load={load} index={legIndex} setIndex={setLegIndex} loadGroup={loadGroup} setLoadGroup={setLoadGroup} key={ind} />
                            }
                            {!readonly && showDeleteButton(load) &&
                                <div className={classes.buttomsContainer} onClick={() => deleteLeg(load.index)}>
                                    <CloseIcon className={classes.deleteIcon} />
                                </div>
                            }
                            {(!!Object.values({ ...loadsMissingFields?.[load?.uuid] })?.filter(v => v != null)?.length ||
                                !!Object.values({ ...loadsErrors?.[load?.uuid] })?.filter(v => v != null)?.length) &&
                                <div className={classes.error} ><WarningIcon /></div>
                            }
                        </Grid>
                        {!readonly && ind !== (loadsList?.length - 1) &&
                            <Tooltip title="Add Activity Leg">
                                <div className={classes.addBtnContainer} onClick={() => addLegByIndex(ind + 1)}>
                                    <AddIcon className={classes.addIcon} />
                                </div>
                            </Tooltip>
                        }
                    </>
                )
                }
                {!readonly && <Button item className={classes.addLegButton} onClick={handleClick} endIcon={<KeyboardArrowDownOutlinedIcon />}>
                    Add Leg
                </Button>}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MenuItem onClick={onAddTransportLeg}>
                        Add transport leg
                    </MenuItem>
                    <MenuItem onClick={onAddActivityLeg}>
                        Add activity leg
                    </MenuItem>
                </Menu>
            </Grid>

        </div>
    );
}

export default LoadGroupJourney;

