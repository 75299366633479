import { Tooltip } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { ReportSectionStyles } from "../../ReportSectionStyles";

export const AddColumnButton = ({ columnIndex, sectionIndex, onAddColumn }) => {
    const classes = ReportSectionStyles();

    return (
        <div className={classes.addColumn}>
            <Tooltip title="Add Column">
                <AddIcon className={classes.addColumnButton} onClick={() => onAddColumn(sectionIndex, columnIndex + 1)} />
            </Tooltip>
        </div>
    )
}