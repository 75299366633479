import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";
import { Alert } from "@mui/material";
import Authorize from "components/Authorize";
import { hasPermission } from "components/Authorize/authorizeUtil";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { permissions } from "components/Authorize/permissions/permissions";
import DateTimeField from "components/DateTimePicker/DateTimePicker";
import NotificationProvider from "components/NotificationProvider";
import { useGetAllInventoryByWorkOrderQuery } from "features/activity/ActivityTypeInventorySlice";
import { activityCustomFieldSetup, activityStatusOrder, activityStatuses } from "features/activity/activityConsts";
import ActivityInventory from "features/mechanicalWork/components/Inventory/ActivityInventory";
import { NoteContent } from "features/notes/NoteEditor";
import { ChargeCalculation } from "features/pricing/ChargeCalculation/ChargeCalculation";
import { PricingLookup } from "features/pricing/PricingLookup/PricingLookup";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";
import { orderBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useGetAllActivitiesQuery, useGetAllActivityItemsWithCustomFieldsQuery, useGetWorkOrderCustomDataQuery, useUpdateActivityLogMutation } from "../../../activity/activitySlice";
import { useGetLotsQuery } from "../../../lots/lotSlice";
import { getLotsWithPmfText } from "../../../lots/lotUtil";
import { vehicleDetailsPageStyle } from "../VehicleDetailsPageStyle";
import ActivityStatusAliasView from "features/activityStatusAlias/ActivityStatusAliasView";
import { arrayToObject } from "utils/object-util";

const EditActivityLog = ({
    onCancel,
    activityLog,
    onConfirm,
    clientId,
    vehicleTypeId,
    withDialog = true
}) => {

    let { data: activities, error: activityError, isLoading: isLoadingActivity } = useGetAllActivitiesQuery();
    let { data: lots, error: getLotsError, isLoading: loadingLots } = useGetLotsQuery();
    let { data: activityItemsWithCustomFields, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsWithCustomFieldsQuery();
    let { data: inventoryUsed, isFetching: isLoadingInventory, refetch: refetchInventory } = useGetAllInventoryByWorkOrderQuery(activityLog.workOrderId);
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    let { data: activityCustomData, error: workOrderCustomDataError, isLoading: isLoadingworkOrderCustomData } = useGetWorkOrderCustomDataQuery(activityLog.workOrderId);

    const classes = vehicleDetailsPageStyle();
    const [dateTime, setDateTime] = useState(activityLog?.formattedDate ?? activityLog?.date);
    const [serviceProviderId, setServiceProviderId] = useState(activityLog?.serviceProviderId);
    const [cost, setCost] = useState(activityLog?.cost);
    const [estimatedDaysToComplete, setEstimatedDaysToComplete] = useState(activityLog?.estimateDaysToComplete);
    const [revenue, setRevenue] = useState(activityLog?.revenue);
    const [withinSla, setWithinSla] = useState(activityLog?.withinSla);
    const [selectedStatus, setSelectedStatus] = useState(activityLog?.activityId);
    const [selectedLot, setSelectedLot] = useState(activityLog?.lotId);
    const [updateActivityLog, { isLoading }] = useUpdateActivityLogMutation();
    const [note, setNote] = useState();
    const [inventoryUsedState, setInventoryUsedState] = useState(inventoryUsed);
    const [useInventory, setUseInventory] = useState();
    const [notSupported, setNotSupported] = useState(false);
    const [workorderCustomData, setWorkorderCustomData] = useState(activityCustomData || []);

    const onChangeNote = (key, val) => {
        switch (key) {
            case "content":
                setNote({ ...note, content: val });
                break;
            case "viewByClient":
                setNote({ ...note, viewByClient: val })
        }
    }

    const activityItemsLookup = arrayToObject(activityItemsWithCustomFields);
    let currentActivityItem = activityItemsLookup?.[activityLog?.activityItemId];

    serviceProviders = serviceProviders || [];
    activities = orderBy(activities?.filter(a => activityStatusOrder[a.id] >= activityStatusOrder[selectedStatus]).map(a => ({ ...a, order: activityStatusOrder[a.id] })), 'order') ?? [];

    const onSelectDateTime = (e) => {
        setDateTime(e)
    }

    useEffect(() => {
        if (inventoryUsed) {
            setInventoryUsedState(inventoryUsed);
        }
    }, [inventoryUsed])

    useEffect(() => {
        if (activityCustomData) {
            setWorkorderCustomData(activityCustomData);
        }
    }, [activityCustomData])

    const onChangeStatus = (val) => {
        setSelectedStatus(val);

        if (parseInt(val) === activityStatuses.PendingInvoice ||
            (parseInt(val) === activityStatuses.ItemCompleted && activityLog?.activityId != activityStatuses.PendingInvoice)) {
            setDateTime(Date.now());
        }
    }

    const onChangeCustomFieldData = (customField, val) => {
        let customDataList = [...workorderCustomData] || []
        let index = customDataList.findIndex(c => c.activityItemCustomFieldId == customField.id);
        if (index == -1) {
            customDataList = [...customDataList, {
                groupId: activityLog.groupId,
                workOrderId: activityLog.workOrderId,
                activityItemCustomFieldId: customField.id,
                value: val,
                mappedTo: customField?.mappedTo
            }]
        } else {
            customDataList[index] = { ...customDataList[index], ["value"]: val };
        }
        setWorkorderCustomData(customDataList)
    }

    const save = async () => {
        let date = new Date(moment(dateTime).format("MMM DD, yyyy, hh:mm a") + " UTC");
        let activityDate = date.toISOString();
        let invToSend = inventoryUsedState?.filter(i => !(i.inventoryId === null && i.unitNumber === null));

        let data = {
            id: activityLog.activityLogId,
            createdDate: moment(),
            activityDate: activityDate,
            serviceProviderId: serviceProviderId,
            price: cost,
            revenue: revenue,
            status: selectedStatus,
            withinSla: withinSla,
            vehicleId: activityLog?.vehicleId,
            clientId: clientId,
            lotId: selectedLot,
            groupId: activityLog.groupId,
            workOrderId: activityLog.workOrderId,
            note: note,
            inventoryUsed: invToSend,
            estimateDaysToComplete: !!estimatedDaysToComplete?.length ? estimatedDaysToComplete : null,
            workorderCustomData: workorderCustomData
        }

        const res = await updateActivityLog(data);  //daysCompletedIn: daysCompletedIn, daysToComplete: daysToComplete

        if (res.error) {
            NotificationProvider.error("Failed to update the activity");
        } else {
            NotificationProvider.success("Successfully updated the activity");
            onConfirm && onConfirm();
        }
    };

    let statusValues = ((!currentActivityItem?.noCharge && !hasPermission(permissions.ACTIVITIES.MANAGE_PENDING_INVOICE.key)) ? activities?.filter(a => a.id !== activityStatuses.ItemCompleted && a.id !== activityStatuses.CompleteBilling) : activities) ?? [];

    const form = <><Paper className={classes.paper}>
        <div className={classes.input}>
            <CustomInput
                id="lot"
                label="Lot"
                value={selectedLot}
                elementType="dropdown"
                showEmpty={true}
                required={false}
                onChange={val => setSelectedLot(val)}
                values={getLotsWithPmfText(lots) ?? []}
            />
        </div>
        <div className={classes.input}>
            <ActivityStatusAliasView activityIds={statusValues.map(s => s.id)}
                clientId={clientId}
                readonly={false}
                value={selectedStatus}
                dropdownValues={statusValues}
                onChange={val => onChangeStatus(val)}
            />
        </div>
        {currentActivityItem?.activityItemCustomFields?.map(customField =>
            <div className={classes.input}>
                {activityCustomFieldSetup?.[customField?.mappedTo]?.component ?
                    activityCustomFieldSetup?.[customField?.mappedTo]?.component(customField,
                        workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value,
                        onChangeCustomFieldData)
                    :
                    <CustomInput
                        id="ddlCustomFieldData"
                        label={customField?.label}
                        elementType="text"
                        value={workorderCustomData?.find(w => w.activityItemCustomFieldId == customField.id)?.value}
                        onChange={(val) => onChangeCustomFieldData(customField, val)}
                    />
                }
            </div>
        )
        }
        <div className={classes.input}>
            <DateTimeField
                label='Activity Date'
                value={dateTime}
                onChange={onSelectDateTime}
            />
        </div>
        <div className={classes.input}>
            <CustomInput
                id="estimatedDaysToComplete"
                label="Estimated Days To Complete"
                numberOnly={true}
                value={estimatedDaysToComplete}
                elementType="input"
                onChange={(val) => setEstimatedDaysToComplete(val != "" ? val : 0)}
            />
        </div>
        <Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_INTERNAL_FIELDS}>
            <div className={classes.input}>
                <CustomInput
                    id="ddlServiceProviderId"
                    label="Service Provider"
                    elementType="dropdown"
                    values={serviceProviders ?? []}
                    value={serviceProviderId}
                    classStyles={classes.dropdownStyle}
                    showEmpty={true}
                    onChange={setServiceProviderId}
                />
            </div>
        </Authorize>
        {notSupported && <Grid item className={classes.input}>
            <Alert severity="error">This service provider does not offer this service in this location</Alert>
        </Grid>}
        <Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_INTERNAL_FIELDS}>
            <div className={classes.input}>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item xs={10}>
                        <CustomInput
                            id="cost"
                            label="Internal Cost"
                            value={cost}
                            elementType="input"
                            required={true}
                            onChange={(val) => setCost(val != "" ? val : 0)}
                        />
                    </Grid>
                    <Grid item>
                        <PricingLookup
                            onChange={(val) => setCost(val != "" ? val : 0)}
                            activityItemId={activityLog?.activityItemId}
                            lotId={selectedLot}
                            vehicleTypeId={vehicleTypeId}
                            serviceProviderId={serviceProviderId}
                            setServiceProviderId={setServiceProviderId}
                            price={cost}
                            setUseInventory={setUseInventory}
                            setNotSupported={setNotSupported}
                        />
                    </Grid>
                </Grid>
            </div>
        </Authorize>
        <Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_INTERNAL_FIELDS}>
            <div className={classes.input}>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={9}>
                        <CustomInput
                            id="cost"
                            label="Charge"
                            value={revenue ?? 1.15 * cost}
                            elementType="input"
                            required={true}
                            onChange={(val) => setRevenue(val != "" ? val : 0)}
                        />
                    </Grid>
                    <ChargeCalculation
                        onChange={(val) => setRevenue(val != "" ? val : 0)}
                        clientId={clientId}
                        activityType={activityLog?.activityTypeId}
                        lotId={selectedLot}
                        price={cost}
                        inventoryUsed={!!inventoryUsedState?.length ? inventoryUsedState : null}
                    />
                </Grid>
            </div>
        </Authorize>
        <Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_INTERNAL_FIELDS}>
            <div className={classes.input}>
                <div>Note</div>
                <NoteContent content={note?.content} viewByClient={note?.viewByClient} onChange={onChangeNote} />
            </div>
        </Authorize>
        <Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_INTERNAL_FIELDS}>
            <div style={{ display: 'flex', alignItems: 'center', }}>
                <p>Within SLA?</p>
                <Checkbox
                    title="Within SLA?"
                    checked={withinSla}
                    onChange={(e, value) => (setWithinSla(value))}
                />
            </div>
        </Authorize>
    </Paper>
        <Authorize profile={permissionProfiles.ACTIVITY.EDIT_ACTIVITY_INTERNAL_FIELDS}>
            <div>
                {(useInventory || !!inventoryUsed?.length) &&
                    <ActivityInventory inventoryUsed={inventoryUsedState} setInventoryUsed={setInventoryUsedState} activityLog={{ id: activityLog.activityLogId, lotId: activityLog?.lotId }}></ActivityInventory>
                }
            </div>
        </Authorize></>

    const actions = <>
        <Button onClick={onCancel} color='primary'>
            Cancel
        </Button>
        <Button onClick={save} color='primary' variant="contained" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
        </Button>
    </>;

    return (<>
        {withDialog ?
            <Dialog open={!!activityLog} onClose={() => onCancel(false)} aria-labelledby='dialog-title' fullWidth maxWidth='lg'>
                <LoadingSpinner loading={isLoading} />
                <DialogTitle id={'dialog-title'} >Edit Activity {activityItemsWithCustomFields?.find(a => a.id == activityLog?.activityItemId)?.name}</DialogTitle>
                <DialogContent>
                    {form}
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog> : <div>
                <div>{form}</div>
                <div>{actions}</div>
            </div>
        }</>
    );
};

export default EditActivityLog;
