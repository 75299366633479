import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const EditTransporterFormDialog = ({onSave, setOpen, label, open, id, transporter}) => {    
    const [name, setName] = React.useState(transporter?.name);
    const [usdot, setUsdot] = React.useState(transporter?.usdot);

    const handleClose = () => {
        setName("");
        setOpen(false);
        setUsdot("");
    };
    
    const handleSave = async () => {
        await onSave({id, name, usdot});
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{label}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="USDOT"
                    fullWidth
                    value={usdot}
                    onChange={e => setUsdot(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditTransporterFormDialog;
