import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

export const reportsApi = baseApi.injectEndpoints({
    reducerPath: 'reportsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getStorageReports: builder.query({
            query: (clientId) => `storagereport/${clientId}`,
            providesTags: [apiTags.STORAGE_REPORT],
        }),
        getBillingReports: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => {
                    if (Array.isArray(params[param])) {
                        return params[param].map(value => `${param}=${value}`).join('&');
                    } else {
                        return params[param] ? `${param}=${params[param]}` : undefined;
                    }
                }).filter(k => k).join('&');
                return `billing/type/?${queryString}`
            },
            providesTags: [apiTags.BILLING_REPORT],
        }),
        generateBillingReport: builder.mutation({
            query: (body) => ({
                url: `billing/generate`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.BILLING_REPORT]
        }),
    }),
});

export const { useGetStorageReportsQuery, useGetBillingReportsQuery, useGenerateBillingReportMutation } = reportsApi;
