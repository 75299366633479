import { makeStyles } from "@material-ui/core";

export const GenerateAccessCodeStyle = makeStyles((theme) => ({
    paramContainer: {
        padding: '2em'
    },
    paramTitle: {
        paddingBottom: '1em',
        fontWeight: 'bold'
    },
}));