import { isRejectedWithValue } from '@reduxjs/toolkit';
import NotificationProvider from '../components/NotificationProvider';
import React from "react";
import CopyToClipboard from 'components/CopyToClipboard';
import moment from 'moment';

/**
 * Rtk middleware for handling our generic tracking id errors
 * @param {*} api 
 * @returns 
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && action?.payload?.data?.TicketId) {
        NotificationProvider.error(<div>
            <div>{action?.payload?.data?.Message}</div>
            <br />
            <div><strong>{action?.payload?.data?.TicketId}<CopyToClipboard text={`${action?.payload?.data?.TicketId} | ${moment().toString()}`} /></strong></div>
        </div>);
    }

    return next(action)
}

export const getErrorsFromResponse = (response, defaultMessage) => {
    return response?.error?.data?.messages?.join(',') ?? defaultMessage;
}
export const getErrorListFromResponse = (response, defaultMessage) => {
    return response?.error?.data?.messages ?? [];
}