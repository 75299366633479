import { getNotificationActions, getNotificationUrl } from "./handlers/notificationOptions";



const createNotification = async (notificationData) => {

    const options = {
        body: notificationData.message,
        vibrate: [100, 50, 100],
        data: {
            url: getNotificationUrl(notificationData)
        },
        actions: getNotificationActions(notificationData)
    };

    navigator.serviceWorker.getRegistration().then(reg => {
        reg.showNotification(notificationData.title, options);
    }).catch(err => {
        console.log(err);
    });
}

const notify = (notificationData) => {
    if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        createNotification(notificationData);
    } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                createNotification(notificationData);
            }
        });
    };
}

export const pushDesktopNotification = (
    notificationData
) => {
    notify(notificationData);
}