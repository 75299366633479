import { Grid } from '@material-ui/core';
import { CardsStyle } from './CardsStyle';
import SvgIconQRCode from 'icons/IconQRCode';
import ListComponent from './Lists/ListComponent';
import { IconLot } from 'icons';
import PocInfo from './POC/PocInfo';
import EditSites from './Lists/EditListsComponents/EditSites';
import SiteListItem from './Lists/ListItems/SiteListItem';

const EmployeeAccessCard = ({ access }) => {

    const classes = CardsStyle()

    return (
        <>
            <div className={classes.loadBox}>
                <Grid container direction='row' >
                    <Grid item container direction='row' className={classes.cardItemIcon}>
                        <div item className={classes.title}><SvgIconQRCode /></div>
                    </Grid>

                    <PocInfo access={access} />

                </Grid>

                <Grid container direction='row' spacing={2} className={classes.lists}>
                    <Grid item>
                        <ListComponent
                            access={access}
                            list={access?.lots}
                            listTitle='Sites'
                            listItem={(lot) => <SiteListItem access={access} lot={lot}/>}
                            addComponent={(access, open, onClose) => <EditSites access={access} open={open} onClose={onClose} />}
                        />
                    </Grid>
                </Grid>
            </div >
        </>
    );
}

export default EmployeeAccessCard



