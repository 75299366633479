import {createSlice} from "@reduxjs/toolkit";

export const loadsStateSlice = createSlice({
    name: 'loadState',
    initialState: {
        newLots: [],
        showMandatoryError: false,
        errors: {},
        loadsErrors: {},
        missingFields: {},
        loadsMissingFields: {},
    },
    reducers: {
        setNewLots: (state, action) => {
            state.newLots = action.payload;
        },
        setShowMandatoryError: (state, action) => {
            state.showMandatoryError = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setLoadsErrors: (state, action) => {
            state.loadsErrors = action.payload;
        },
        setMissingFields: (state, action) => {
            state.missingFields = action.payload;
        },
        setLoadsMissingFields: (state, action) => {
            state.loadsMissingFields = action.payload;
        },
    },
});

export const { setNewLots, setShowMandatoryError, setErrors, setLoadsErrors ,setMissingFields, setLoadsMissingFields } = loadsStateSlice.actions;
export default loadsStateSlice.reducer;
