import { makeStyles } from "@material-ui/core"

export const shimmerLoaderStyle = makeStyles((theme) => ({
    shine: {
        background: '#f6f7f8',
        backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
        backgroundRepeat: 'no-repeat',
        //backgroundSize: '800px 104px',
        display: 'inline-block',
        position: 'relative',
        animationDuration: '2s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: '$placeholderShimmer',
        animationTimingFunction: 'linear',
        marginBottom: '0.5em',
        width: '100%'
    },
    "@keyframes placeholderShimmer": {
        '0%': {
            backgroundPosition: '-80vw 0'
        },
        '100%': {
            backgroundPosition: '80vw 0'
        },
        
    }

}));