import React from 'react';
import { LoadGroupJourneyStyles } from './LoadGroupJourneyStyles';
import { Grid } from '@material-ui/core';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import { IconSettings } from 'icons';
import { useGetAllActivitiesItemByTypeIdQuery } from 'features/activity/activitySlice';
import FormatService from 'services/formatService';


function ActivityJourneyItem({ load, setIndex }) {
    const classes = LoadGroupJourneyStyles()

    let {
        data: activityItems, error: activityItemError,
        isLoading: isLoadingActivityItems
    } = useGetAllActivitiesItemByTypeIdQuery(load?.activityTypeId, { skip: !load?.activityTypeId });
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    const { newLots } = useSelector(state => state.loadState);

    const dispatch = useDispatch();

    activityItems = activityItems || [];
    lots = lots || [];
    lots = [...lots, ...newLots];

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const activityItemsLookup = activityItems?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const activity = {
        ...load,
        lotName: lotLookup?.[load?.lotId]?.name,
        activityName: activityItemsLookup?.[load?.activityItemId]?.name
    };

    return (
        <Grid container direction='row' onClick={() => setIndex(activity.index)}>
            <Grid item className={`${classes.activity} ${classes.journeyItem}`} alignContent='center'>
                <div className={`${classes.activityTitle} ${classes.journeyItemTitle}`}>
                    <IconSettings className={`${classes.activityIcon}`}/>
                    {`${activity?.activityName ? activity?.activityName : "Activity"}`}
                </div>
                <div className={classes.journeyItemText}>{FormatService.formatDateAndTimeWithFormat(activity?.activityDate, "yyyy-MM-DD")}</div>
                <div className={classes.journeyItemText}>{activity?.lotName}</div>
            </Grid>
        </Grid>
    );
}

export default ActivityJourneyItem;


