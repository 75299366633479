import Link from '@mui/material/Link';
import CustomInput from "components/CustomInput";
import { useGetSummaryQuery } from "features/lots/lotSlice";
import { useEffect, useState } from "react";
import { UserTaskItemStyle } from "../style";

const AssetsCount = ({ task, onChange, lotId, setValid }) => {
    const classes = UserTaskItemStyle();

    let { data: summary, error: summaryError, isFetching: isLoadingSummary } = useGetSummaryQuery(lotId);

    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        setValid(parseInt(task?.userResponse) > 0)
    }, [task])

    const handleChange = (val) => {
        setShowMessage(val > 0 && val != summary?.totalOnLot && lotId);
        onChange("userResponse", val);
    }

    return (
        <>
            <div className={classes.dropdownWrapper}>
                <CustomInput
                    label='Number of assets on lot'
                    value={parseInt(task?.userResponse)}
                    elementType="input"
                    numberOnly
                    minValue={0}
                    onChange={val => handleChange(+val)}
                />
            </div>
            {showMessage && <div className={classes.dropdownWrapper}>
                <div>
                    The number doesn't match the information saved in parq. <br />
                    <Link href={`/assets/audit?lotId=${lotId}`}>Please take a full audit</Link>
                </div>
            </div>}
        </>
    )
}
export default AssetsCount;