import { makeStyles } from "@material-ui/core"

export const EditListsStyle = makeStyles((theme) => ({
    addItemButton: {
        margin: "0.5rem 0 0 1rem",
    },
    dropdown: {
        // marginBottom: '0.5rem'
    },
    label: {
        fontWeight: 500
    },
    listItemIcon: {
        marginRight: '2rem',
        padding: '1px'
    },
    detailsWrapper: {
        marginTop: '1rem'
    },
    listWrapper: {
        padding: '0 0.5rem',
        backgroundColor: '#F3F5F6',
        maxHeight: 300,
        borderRadius: '5px',
        flex: 1,
        overflowY: 'auto',
    },
    listItem: {
        margin: '0.5rem 0',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '2px solid',
        borderColor: theme.palette.grey[200],
    },
    addButton: {
        margin: '1rem',
        backgroundColor: 'white',
        width: '95%'
    },
    select: {
        borderRadius: '5px',
        backgroundColor: '#F3F5F6',
        marginBottom: '0.5rem'
    },
    paper: {
        borderRadius: '5px',
        backgroundColor: '#F3F5F6',
    },
    buttonAdd: {
        width: '90%',
        marginLeft: '0.5rem'
    },
    dropdownLabel: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    input: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    },
}));
