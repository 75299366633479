import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IconCar, IconDetails } from 'icons';
import { lotSummaryStyle } from './style';

const SummaryViewToggle = ({ summaryView, handleViewChange }) => {
    const classes = lotSummaryStyle();

    return (
        <ToggleButtonGroup
            value={summaryView}
            exclusive
            onChange={handleViewChange}
            aria-label="screen view"
            className={classes.viewToggleBtn}
        >
            <ToggleButton value="stats" aria-label="left aligned">
                <IconCar />
            </ToggleButton>
            <ToggleButton value="details" aria-label="right aligned" >
                <IconDetails />
            </ToggleButton>
        </ToggleButtonGroup>

    )
}

export default SummaryViewToggle;
