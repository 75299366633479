import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useUpdateSiteSizingMutation } from "features/lots/lotSlice";
import { useState } from "react";
import { EditSiteSizingStyles } from "./EditSiteSizingStyles";


const EditSiteSizing = ({sizeToEdit, setSizeToEdit}) => {
    const classes = EditSiteSizingStyles();

    const [updateSiteSizing, { isLoading }] = useUpdateSiteSizingMutation();

    const [isSaving, setIsSaving] = useState(false);
    const [newSize, setNewSize] = useState(sizeToEdit);

    const handleClose = () => {
        setSizeToEdit(null);
        setIsSaving(false);
    };

    const onChangeValue = (key, val) => {
        setNewSize({ ...newSize, [key]: val });
    };

    const onClickFinish = async () => {
        setIsSaving(true);

        let result = await updateSiteSizing(newSize);
        if (result && !result.error) {
            NotificationProvider.success("Site size updated successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
        handleClose();
    };

    const isValid = newSize?.name;

    return (
        <>
            <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Add Site Sizing
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item>
                            <CustomInput
                                id="name"
                                label="Size Name"
                                elementType="input"
                                required={true}
                                value={newSize?.name}
                                onChange={val => onChangeValue("name", val)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item>
                            <CustomInput
                                id="minSize"
                                label="Min Size"
                                elementType="input"
                                value={newSize?.minSize}
                                numberOnly
                                onChange={val => onChangeValue("minSize", val)}
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="maxSize"
                                label="Max Size"
                                elementType="input"
                                value={newSize?.maxSize}
                                numberOnly
                                onChange={val => onChangeValue("maxSize", val)}
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="hoursTH"
                                label="Hours TH"
                                elementType="input"
                                value={newSize?.hoursTH}
                                numberOnly
                                onChange={val => onChangeValue("hoursTH", val)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.btnContainer}>
                        <Button className={classes.button} variant="outlined"
                            onClick={() => handleClose()}>Cancel</Button>
                        <Button
                            onClick={onClickFinish}
                            disabled={!isValid || isSaving}
                            className={classes.button}
                            variant="contained" >
                            {isSaving ? "Saving..." : "Finish"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditSiteSizing;
