import { makeStyles } from "@material-ui/core";

export const Style = makeStyles((theme) => ({
    input: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none',
        }
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    options: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    },
}));