import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const VendorFields = ({ }) => {
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const [newVendor, setNewVendor] = useState(null)
    
    if (newVendor) { serviceProviders = [...serviceProviders, newVendor] }

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({...accessToEdit, [key]: val}))
    }

    const onAddServiceProvider = async (val) => {
        setNewVendor({...val, id: 0});
        onChangeValue("serviceProviderName", val.name)
    }

    return (
        <>
            <CreatableDropdown
                options={serviceProviders}
                value={accessToEdit?.serviceProviderId}
                required
                addValue={onAddServiceProvider}
                label='Vendor Name'
                onChange={(val) => onChangeValue("serviceProviderId", val)}
            />
        </>
    );
}

export default VendorFields;