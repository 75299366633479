import React from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const ConfirmationPopup = ({
    title,
    children,
    onCancel,
    onConfirm,
    open,
    loading
}) => {

    return (
        <Dialog open={open} onClose={() => onCancel(false)} aria-labelledby='dialog-title'>
            <DialogTitle id={'dialog-title'} >{title ?? 'Confirm'}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary'>
                    Cancel
                </Button>
                <Button disabled={loading} onClick={onConfirm} color='primary'>
                    Confirm
                </Button>
                {loading && <CircularProgress size={'1em'} />}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationPopup
