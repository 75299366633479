import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { EditListsStyle } from './EditListsStyle';
import { useUpdateVehiclesMutation } from 'features/access/accessSlice';
import NotificationProvider from 'components/NotificationProvider';
import LoadingSpinner from 'components/LoadingSpinner';
import EditVehiclesList from './EditVehiclesList';


const EditVehicles = ({ access, open, onClose }) => {

    const classes = EditListsStyle()
    const [vehicles, setVehicles] = useState(access?.vehicles);

    const [updateVehicles, { isLoading }] = useUpdateVehiclesMutation();

    const onCloseDialog = () => {
        setVehicles(access?.vehicles);
        onClose();
    }

    const onSave = async () => {
        let body = {accessGroupId: access.id, vehicleIds: vehicles?.map(l => l.id)};
        let res = await updateVehicles(body);
        if (res && !res.error) {
            NotificationProvider.success("Access updated successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
        onCloseDialog();
    }

    return (
        <>
            <Dialog maxWidth='md' fullWidth open={open} onClose={onCloseDialog} aria-labelledby='dialog-title'>
                <DialogTitle id={'dialog-title'} >
                    Edit Vehicles
                </DialogTitle>
                <DialogContent>
                    <EditVehiclesList clientId={access?.clientId} vehicles={vehicles} setVehicles={setVehicles}/>
                    <LoadingSpinner loading={isLoading} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button className={classes.button} variant='outlined' color='primary' onClick={onCloseDialog}>
                        Cancel
                    </Button>
                    <Button className={classes.button} variant='contained' color='primary' onClick={onSave} disabled={isLoading}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default EditVehicles



