import * as React from "react";

function SvgIconSection(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path d="M23.2166 15.75H4.78325C3.03325 15.75 2.33325 16.4967 2.33325 18.3517V23.065C2.33325 24.92 3.03325 25.6667 4.78325 25.6667H23.2166C24.9666 25.6667 25.6666 24.92 25.6666 23.065V18.3517C25.6666 16.4967 24.9666 15.75 23.2166 15.75Z" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M23.2166 2.33337H4.78325C3.03325 2.33337 2.33325 3.08004 2.33325 4.93504V9.64838C2.33325 11.5034 3.03325 12.25 4.78325 12.25H23.2166C24.9666 12.25 25.6666 11.5034 25.6666 9.64838V4.93504C25.6666 3.08004 24.9666 2.33337 23.2166 2.33337Z" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default SvgIconSection;
