import {createSlice} from "@reduxjs/toolkit";

export const formStateSlice = createSlice({
    name: 'formState',
    initialState: {
        clientId: null,
        formType: null,
    },
    reducers: {
        setClientId: (state, action) => {
            state.clientId = action.payload;
        },
        setFormType: (state, action) => {
            state.formType = action.payload;
        }
    },
});

export const { setClientId, setFormType } = formStateSlice.actions;
export default formStateSlice.reducer;
