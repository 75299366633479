import { StepsStyles } from './StepsStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import EditSitesList from 'features/access/AccessGroupList/Cards/Lists/EditListsComponents/EditSitesList';

const AddLotsStep = ({ }) => {
    const classes = StepsStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({...accessToEdit, [key]: val}))
    }


    return (
        <>
            <EditSitesList access={accessToEdit} lots={accessToEdit?.lots} setLots={(val) => onChangeValue("lots", val)} />
        </>
    );
}

export default AddLotsStep;