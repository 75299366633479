import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import LoadingSpinner from 'components/LoadingSpinner';
import { useEffect, useState } from 'react';
import CustomInput from '../../../components/CustomInput';
import DropZoneInDialog from '../../../components/DropZoneInDialog';
import NotificationProvider from "../../../components/NotificationProvider";
import { useStyles } from "../ActivityReportPageStyles";
import { useGetAllActivityItemsQuery, useImportActivitiesMutation, useGetImportTemplateMutation } from '../activitySlice';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectUserClientAccountId } from 'features/user/userSlice';

function ImportActivities({ clients, refetch }) {
    const classes = useStyles();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let [importActivities, { data: importedData, error: importError, isLoading: importLoading }] = useImportActivitiesMutation();
    const [importTemplate, { data: importActivitiesTemplate, error: templateError, isLoading: templateLoading }] = useGetImportTemplateMutation();

    const [selectedClient, setSelectedClient] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogComponent, setDialogComponent] = useState(<></>);
    let loggedInUserClientAccountIds = useSelector(selectUserClientAccountId)

    let clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));

    const downloadTemplate = async () => {
        await importTemplate();
    }

    const onSave = async (file) => {
        let fd = new FormData();
        fd.append("uploaded_file", file);
        fd.append("clientAccountId", selectedClient ?? clientAccounts?.[0]?.id);

        const res = await importActivities(fd);
        if (res.error) {
            NotificationProvider.error("Failed to import activities");
            NotificationProvider.error(res.error?.data?.[0]);
        } else {
            if (res?.data?.length > 0) {
                setOpenDialog(true);
                setDialogComponent(
                    <>
                        {<div>
                            {res?.data?.map((item, index) => (
                                <div key={index}>
                                    <p>{item.descriptor}, {item.lotName}, {item.activityName}</p>
                                </div>
                            ))}
                        </div>}
                    </>
                );
            }
            else {
                NotificationProvider.info("No activities imported");
            }
        }
    }

    const onCloseDialog = () => {
        setOpenDialog(false);
        refetch();
    }

    return (
        <Authorize profile={permissionProfiles.ACTIVITY.ACTIVITY_UPLOAD}>
            <Dialog open={openDialog} onClose={() => onCloseDialog()} aria-labelledby='dialog-title'>
                <DialogTitle >
                    <div className={classes.dialogWrapper}>
                        <div className={classes.dialogTitle}> Successfully imported activities: </div>
                        <IconButton aria-label="close" onClick={() => onCloseDialog()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>
                    {dialogComponent}
                </DialogContent>
            </Dialog>
            <DropZoneInDialog
                labelDialog={'Import Activities'}
                buttonLabel={'Import Activities'}
                actionButtonLabel={'Save'}
                onSave={onSave}
                valid={!!selectedClient || !Array.isArray(loggedInUserClientAccountIds)}
                formContent={
                    <div className={classes.uploadFileFields}>                         
                        {clientAccounts?.length > 1 && 
                            <CustomInput
                                id="clientAccount"
                                label="Client"
                                value={selectedClient}
                                elementType="dropdown"
                                onChange={val => setSelectedClient(val)}
                                values={clientAccounts}
                                showEmpty
                                required
                            />
                        }
                        <Button color="primary" className={classes.downloadButton} onClick={downloadTemplate} disabled={templateLoading}>Download Template</Button>
                        <br />
                        <br />
                        <div className={classes.activityText}>
                            <div className={classes.ectivityTextHeader}>Use the following codes to indicate the activity to add</div>
                            <br />
                            {activityItems?.map((item, index) => (
                                <div key={index}>
                                    {item?.name} - {item?.id}<br />
                                </div>
                            ))}
                            <br />
                        </div>
                    </div>
                }
            />
            <LoadingSpinner loading={importLoading} />
        </Authorize>
    );
}

export default ImportActivities;