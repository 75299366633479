import { Grid } from "@material-ui/core";
import { unionBy } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { setFilter, setShowDomiciledOnly, setTaskPanelOpen } from "../../../../Tasks/taskSlice";
import { washDashboardStyles } from "../WashDashboardStyles";
import { pendingWashCardStyle } from "./PendingWashCardStyle";

const PendingWashCard = ({ washRequests, vehicleLookup, loading }) => {

    const classes = washDashboardStyles();
    const { filters } = useSelector(state => state.dashboardState);

    const requestWithData = washRequests?.filter(task => {
        const vehicle = vehicleLookup?.[task?.entityId];
        return !!(
            vehicle?.available &&
            vehicle?.domicileLot && vehicle?.domicileLot == task?.lotId
            && (!filters?.lotId?.length || filters?.lotId?.includes(task.lotId))
            && (!filters?.clientId || filters?.clientId == task.clientId)
        );
    });

    const pendingWashes = unionBy(requestWithData, 'entityId');

    const dispatch = useDispatch();


    const openPanel = () => {
        dispatch(setShowDomiciledOnly(true));
        dispatch(setFilter({ key: 'clientId', value: filters.clientId }));
        dispatch(setFilter({ key: 'lotId', value: filters.lotId }));
        dispatch(setFilter({ key: 'available', value: '1'}));
        dispatch(setFilter({ key: 'taskType', value: '1'}));
        dispatch(setTaskPanelOpen(true));

    }
    return (
        <Grid className={classes.wrapper} container direction="column" onClick={() => openPanel()}>
            <LoadingSpinner loading={loading} />
            <Grid item className={classes.count}>{pendingWashes?.length}</Grid>
            <Grid item><h3 className={classes.text}>{pendingWashes?.length == 1 ? 'Asset' : 'Assets'} Pending Wash</h3></Grid>
        </Grid>
    )
}

export default PendingWashCard;