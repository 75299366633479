import { Box, Button, Grid, IconButton, ListItem, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconVehicle } from 'icons';
import CustomInput from 'components/CustomInput';
import { useGetVehiclesQuery } from 'features/vehicles/vehicleSlice';
import { EditListsStyle } from './EditListsStyle';
import { useState } from 'react';
import { Autocomplete } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';


const EditVehiclesList = ({ clientId, vehicles, setVehicles }) => {

    const classes = EditListsStyle()

    let { data: vehiclesData, error: vehicleError, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: false });

    vehiclesData = vehiclesData || []

    vehiclesData = vehiclesData?.map(v => ({
        ...v,
        name: v?.descriptor
    }))?.filter(x => x?.clientId == clientId);

    const [selectedVehicle, setSelectedVehicle] = useState(null)

    const onSelectVehicle = (val) => {
        let vehiclesList = vehicles ? [...vehicles] : [];
        let newItem = vehiclesData?.find(d => d.id == val);
        if (newItem) {
            vehiclesList?.push(newItem)
            setVehicles(vehiclesList);
        }

    }

    const onDelete = (item) => {
        let vehiclesList = vehicles ? [...vehicles] : [];
        vehiclesList = vehiclesList.filter(i => i?.id != item?.id);
        setVehicles(vehiclesList);
    }

    const optionLabel = () => {
        let vehicle = vehiclesData?.find(l => l?.id == selectedVehicle)
        return `${vehicle?.descriptor} ${vehicle?.assetId}`
    }

    return (
        <>
            <LoadingSpinner loading={isFetchingVehicles} />
            <div className={classes.dropdown}>
                <Grid className={classes.select} container direction='row' alignItems='center'>
                    <Grid item xs={10} >
                        <Autocomplete
                            id="vehicleId"
                            value={selectedVehicle}
                            onChange={(event, val) => setSelectedVehicle(val)}
                            options={vehiclesData}
                            autoHighlight
                            getOptionLabel={(option) => (option?.descriptor || option?.assetId) ? `${option?.descriptor} ${option?.assetId}` : optionLabel()}//}
                            renderOption={(props, option) => (
                                <Box  {...props}>
                                    <Grid item xs={6} container direction='column'>
                                        <Grid item >
                                            <label className={classes.label}> VIN: </label>
                                            {option?.descriptor}
                                        </Grid>
                                        <Grid item >
                                            <label className={classes.label}> Asset ID: </label>
                                            {option?.assetId}
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    value={selectedVehicle}
                                    variant='outlined'
                                    label="Select Vehicle"
                                    InputLabelProps={{ className: classes.dropdownLabel }}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        notchedOutline: classes.notchedOutline,
                                        className: classes.input,
                                        // autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.buttonAdd} variant='contained' color='primary' disabled={!selectedVehicle}
                            onClick={() => { onSelectVehicle(selectedVehicle?.id) }}>
                            Add
                        </Button>
                    </Grid>
                </Grid>

            </div>
            <div className={classes.listWrapper}>
                {
                    vehicles?.map((item, index) => {
                        return <div key={item}>
                            <ListItem className={classes.listItem}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={1}>
                                        <IconVehicle className={classes.listItemIcon} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div><label className={classes.label}>VIN: </label>{item?.descriptor}</div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div><label className={classes.label}>Asset ID: </label>{item?.assetId}</div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton edge="end" aria-label="delete"
                                            onClick={() => onDelete(item)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </div>
                    })
                }
            </div>
        </>
    );
}

export default EditVehiclesList



