import { Grid, Paper } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import { useDispatch, useSelector } from "react-redux";
import FormatService from "services/formatService";
import { setFilters } from "../../activityInvoiceSlice.js";
import { activityHeaderStyles } from "./style";
import { activityHeaderColumns } from "../../activityInvoiceConsts.js";
import { useGetClientsWithConfigsQuery } from "../../../../clients/clientConfigSlice";
import { useState } from "react";

const ActivityInvoiceHeader = ({ }) => {
    const classes = activityHeaderStyles();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsWithConfigsQuery();
    const { filters, searchVal } = useSelector(state => state.activityInvoiceState);
    const dispatch = useDispatch();
    const [month, setMonth] = useState(0);

    const clientsWithMechanicals = clients?.filter(client => {
        return client.config && client.config.mechanicalsConfig && client.config.mechanicalsConfig.useMechanicals === true;
    });

    const dates = FormatService.getLast12Months();

    const dateList = dates?.map(x => ({
        name: FormatService.formatToMonthAndYear(x.date),
        id: x.id
    })) || [];

    const handleChange = (prop, filterValue) => {
        if (prop === "month") {
            setMonth(parseInt(filterValue));
            const selectedDate = dates.find(date => date.id === parseInt(filterValue));
            if (selectedDate) {
                const { month, year } = selectedDate;
                dispatch(setFilters({ ...filters, month, year }));
            }
        } else {
            dispatch(setFilters({ ...filters, ...{ [prop]: filterValue } }));
        }
    }

    const filterConfig = [
        {
            key: 'clientId',
            title: 'Client',
            values: clientsWithMechanicals,
            value: filters?.['clientId']
        },
        {
            key: 'month',
            title: 'Month',
            values: dateList,
            value: dates?.find(d => d.month == filters.month && d.year == filters.year)?.id
        }
    ]


    return (
        <Paper className={classes.wrapper}>
            <Grid container spacing={2}>
                {filterConfig.map(filter =>
                    <Grid item xs={6} key={filter.key}>
                        <DropdownFilter
                            title={filter?.title}
                            showEmpty
                            values={filter.values}
                            onSelect={(selected) => handleChange(filter.key, selected)}
                            value={filter.value}
                        />
                    </Grid>)}
            </Grid>
            <Grid container spacing={1} >
                {activityHeaderColumns?.map((col, index) =>
                    <Grid className={classes.column} xs={col.size} item key={`col_${index}`}>
                        {col?.label}
                    </Grid>)}
            </Grid>
        </Paper>
    )
}

export default ActivityInvoiceHeader;