import { makeStyles } from "@material-ui/core"

export const LoadGroupJourneyStyles = makeStyles((theme) => ({
    name: {
        margin: 20
    },
    groupContainer: {
        padding: 20
    },
    journey: {
        marginLeft: '3em',
    },
    journeyItem: {
        padding: '0.7rem 1rem 0.7rem 0.5rem',
        minHeight: '70px',
        maxHeight: '70px',
        maxWidth: '180px',
        minWidth: '180px',
        cursor: 'pointer',
    },
    condensed: {
        '& $journeyItem': {
            minWidth: '100px',
        },
    },
    departure: {
        backgroundColor: '#efc3be',
    },
    arrival: {
        backgroundColor: '#cbeee1',
    },
    activity: {
        backgroundColor: '#fbeacd',
    },
    departureTitle: {
        color: '#ce3e2c'
    },
    arrivalTitle: {
        color: '#409066'
    },
    activityTitle: {
        color: '#d78d32'
    },
    journeyItemTitle: {
        fontWeight: 600,
        fontSize: 12,
    },
    journeyItemText: {
        fontSize: 11.5,
        fontWeight: 500,
        lineHeight: 1.2,
        paddingLeft: '1.3rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    addLegButton: {
        cursor: 'pointer',
        padding: '1.5rem',
        minHeight: '70px',
        maxHeight: '70px',
        maxWidth: '180px',
        minWidth: '180px',
        backgroundColor: '#ebf5fe',
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 700,
    },
    icon: {
        background: 'transpert',
        padding: '1px',
        fontSize: 12,
        borderRadius: '50%',
        margin: '0 3px',
        transform: 'rotate(90deg)',
    },
    departureIcon: {
        '& path': {
            fill: '#ce3e2c',
        },
        border: '1px solid #ce3e2c',
    },
    arrivalIcon: {
        '& path': {
            fill: '#409066',
        },
        border: '1px solid #409066',
    },
    activityIcon: {
        '& path': {
            fill: '#d78d32',
        },
        fontSize: 8,
        margin: '0 5px',
        maxWidth: '13px',
        maxHeight: '13px'
    },
    currentLeg: {
        boxShadow: '0px 0px 7px 0px #999595',
    },
    otherLeg: {
        opacity: 0.6,
    },
    imageDeleteIcon: {
        opacity: 0,
        transition: "opacity 0.5s ease",
    },
    legIten: {
        marginBottom: '0.5rem',
        overflow: "hidden",
        position: "relative",
        // marginRight: '0.3rem',
        '&:hover': {
            // opacity: 1,
            boxShadow: '0px 0px 7px 0px #999595',
            "& $imageDeleteIcon": {
                opacity: 1,
            },
            "& $buttomsContainer": {
                opacity: 1,
            }
        },
    },
    buttomsContainer: {
        display: "flex",
        position: "absolute",
        top: 1,
        right: 1,
        // opacity: 0,
        transition: "opacity 0.5s ease",
        cursor: 'pointer'
    },
    addBtnContainer: {
        display: "flex",
        backgroundColor: '#bcb4b4',
        height: '70px',
        width: 22,
        opacity: 0.5,
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        },
        paddingTop: '1.5rem',
    },
    addIcon: {
        fontSize: 22,
        fill: 'white'
    },
    deleteIcon: {
        padding: '2px',
        fontSize: 20,
        margin: '2px',
        // border: '1px solid #0090fe',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: '#f3f5f69e',
        },
    },
    error: {
        // background: 'rgba(255, 0, 0, 0.2)',
        // padding: '0.5em',
        display: 'flex',
        position: "absolute",
        bottom: 1,
        // margin: 'auto',
        // right: '46.5%',
        right: 2,
        // alignItems: 'center',
        // marginBottom: '0.5em',
        '& path': {
            'fill': '#ff6f00'
        }
    },
}));
