import MapWrapper from "components/MapWrapper/MapWrapper";
import LotsMapMarker from 'features/maps/markers/LotsMapMarker/LotsMapMarker';

const LotMap = ({ lots, lot, setLot }) => {

    const defaultProps = {
        center: {
            lat: lot?.latitude > 0 ? lot?.latitude : 39.148171,
            lng: lot?.longitude > 0 ? lot?.longitude : -101.763726
        },
        zoom: 4
    };

    let markers = lots?.map(lot => {
        return {
            ...lot,
            address: lot.addressLine1 + ', ' + lot.city + ', ' + lot.state,
            icon: <LotsMapMarker
                lot={lot}
                color={lot.pmfLot ? "#0090FE" : "#E48900"}
                text={lot.name}
            />,
            onClick: setLot,
            id: lot?.id
        }
    })

    return (
        <MapWrapper
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            markers={markers}
        />
    )
}

export default LotMap;