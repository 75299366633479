import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import { IconEdit } from "icons";
import { useState } from 'react';
import { KanbanTableStyle } from './KanbanTableStyle';

const KanbanCardTitle = ({ colId, title, setTitle }) => {
    const classes = KanbanTableStyle();

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(title);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        setTitle(inputValue, colId);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleBlur();
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingLeft="0.8rem"
            bgcolor="#ebf5fe"
            borderRadius="6px"
            width={'100%'}
        >
            {isEditing ? (
                <TextField
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    variant="standard"
                    size="small"
                    InputProps={{
                        style: {
                            fontSize: '0.9em',
                            fontWeight: 500,
                        },
                    }}
                />
            ) : (
                <Typography variant="h6" className={classes.cardTitle}>
                    {title}
                </Typography>
            )}
            <IconButton onClick={handleEditClick}>
                <IconEdit className={classes.IconEdit} />
            </IconButton>
        </Box >
    );
};

export default KanbanCardTitle;
