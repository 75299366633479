import { Checkbox, Grid, Paper, useMediaQuery } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import { arrayMoveImmutable } from 'array-move';
import DataSearchBar from 'components/DataSearchBar';
import ExportButton from 'components/ExportButton/ExportButton';
import GenericPagination from 'components/Pagination/GenericPagination';
import { userPreferencesTypes } from 'components/UserPreferences/userPreferencesConsts';
import { useGetUserPreferencesQuery, useResetUserPreferenceMutation, useUpdateUserPreferenceMutation } from 'components/UserPreferences/userPreferencesSlice';
import ExportReportItemAction from 'features/vehicles/ExportReportsMenu';
import { IconDownload } from 'icons';
import { debounce, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import mobileBreakpoint from 'utils/mobileBreakpoint';
import { arrayToObject } from 'utils/object-util';
import BulkActionsToolbar from './BulkActionsToolbar';
import VirtualizedTable from './NewTable';
import TableCustomizationPanel from './TableCustomizationPanel';
import TableFilters from './TableFilters';
import { ExpandFiltersPanel } from './TableFilters/ExpandFiltersPanel';
import { SelectedFilters } from './TableFilters/SelectedFilters/SelectedFilters';
import { tableStyle } from './tableStyle';
import TableViewsSelector from './TableViews';

const DataTable = ({
    rows,//The date to display in the table
    columns, //Column defintion: {name, key, [width], [selectedByDefault], [component:function], [sortKey] }
    rowIdentifier, //Unique identifier key of every row (e.g: id)
    onSort,
    sortDirection,
    columnToSort,
    noItemsMessage,//Empty table text
    actions, //Row actions function
    showActionsInMenu, //When true the actions will be displayed in an ellipsis menu
    maxActionCount = 0,//The maximum number of actions being returned for a row, used for calculating the actions column width
    headerStyle,//Custom header styling
    className,
    tableId,//Unique identifier for the table, used for saving user prefernces, based on const userPreferencesComponentIds
    allowColumnCustomization, //Add user level customization of the table columns
    mapExportData, //Function to be called when exporting the data
    title,//Table title
    actionButtons, //Top level actions displayed next to the title
    onSearch,
    searchVal,
    data,
    searchButton,
    //getDataForExport,//used if api is doing filter and sorting, not in used right now
    customStyling,//Boolean indicating if the table cells are using a custom design and we shouldn't apply the zebra pattern
    allowHorizontalScroll = true,//Boolean indicating if the table show fit the screen or overflow with a horizontal scroll
    customExportChild,
    baselineColumnWidth, // Used to calculate if horizontal scroll is needed, no need to change unless the columns are all very narrow 
    bulkActions,//Bulk actions list to display in bulk actions toolbar
    //Paging parameters = only needed if table is using server side pagination, otherwise the table is optimized by using virtualization and pagination is not needed
    page,
    onChangePagination,
    totalPages,
    limit,
    //End paging
    //Filters
    withFilterPanel = false, //We have two flavors of filters, the new one is with a left panel
    dropDownsConfig,//Filter configuration
    filters,//Selected filters
    onDropFilter,//Function called when filter is changed
    updateAllFilters,//Function called to clear all filters
    onResetFilters,
    filtersSetFromOtherScreen = false,
    setFiltersSetFromOtherScreen,
    getRowClassName, //Function to determine a class for a specific row based on row index
    //End Filters
    listContainerStyle,
    onRowClick,
    customFilterBehavior = false
}) => {

    const [columnsSorted, setColumnsSorted] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedViewId, setSelectedViewId] = useState(0)
    const [initialUserPrefSet, setInitialUserPrefSet] = useState(false)

    const isMobile = useMediaQuery(mobileBreakpoint);

    let { data: userColumnsPreferences, error, isLoading } = useGetUserPreferencesQuery({ componentId: tableId, type: userPreferencesTypes.COLUMNS, viewId: selectedViewId || 0 }, { skip: !tableId });
    const [updatePref, { isLoading: isSaving }] = useUpdateUserPreferenceMutation();
    const [resetPref, { isLoading: isReset }] = useResetUserPreferenceMutation();

    const classes = tableStyle();

    useEffect(() => {
        if (userColumnsPreferences?.columns) {
            setColumnsByPref();
        } else {
            resetToDefault();
        }
    }, [columns]);

    const resetToDefault = () => {
        setColumnsSorted(columns?.filter(col => col.selectedByDefault !== false).map((col, index) => ({ ...col, order: index })));
    }

    const setColumnsByPref = () => {
        const columnPrefLookup = arrayToObject(userColumnsPreferences?.columns, 'key');
        const selectedColumns = orderBy(columns?.filter(col => col.customizable === false || columnPrefLookup?.[col.key]).map((col, index) => ({ ...col, order: columnPrefLookup?.[col.key]?.order ?? -1, index })), 'order');
        setColumnsSorted(selectedColumns);
    }

    useEffect(() => {
        if (!initialUserPrefSet) {
            if (userColumnsPreferences?.columns) {
                setColumnsByPref();
                setInitialUserPrefSet(true);
            } else {
                resetToDefault();
            }
        }
    }, [userColumnsPreferences]);


    const saveViewColumns = debounce((listToSave) => {
        const prefToSave = { columns: listToSave.map(col => ({ key: col.key, order: col.order })) };
        updatePref({ componentId: tableId, type: userPreferencesTypes.COLUMNS, ...prefToSave, viewId: selectedViewId })
    }, 500)

    const getData = () => {
        const columnsToSave = resetColumnsSorted(columnsSorted);
        let filtersToSave = Object.entries(filters).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }));
        return {
            filters: filtersToSave,
            columns: columnsToSave
        }
    }

    const onSelectView = (val) => {
        setInitialUserPrefSet(false);
        setSelectedViewId(val);
    }

    const resetColumnsSorted = (newColList) => {
        const newList = newColList?.map((col, index) => ({ ...col, order: index }));
        setColumnsSorted(newList);
        return newList;
    }

    const onColumnOrderChange = (oldIndex, newIndex) => {
        if (bulkActions) {
            oldIndex -= 1;
            newIndex -= 1;
        }
        const newList = arrayMoveImmutable(columnsSorted, oldIndex, newIndex);
        const listToSave = resetColumnsSorted(newList);
        saveViewColumns(listToSave);
    }

    const onColumnSelectionChange = (selectedColumns) => {
        const existingCols = arrayToObject(columnsSorted, 'key');
        const newCols = arrayToObject(selectedColumns, 'key')
        const newList = [...columnsSorted?.filter(col => newCols[col.key])];
        selectedColumns.map(col => {
            if (!existingCols?.[col.key]) {
                newList.push(col);
            }
        });
        const listToSave = resetColumnsSorted(newList);
        saveViewColumns(listToSave);
    }

    const reset = async () => {
        await resetPref({ componentId: tableId, type: userPreferencesTypes.COLUMNS });
        setInitialUserPrefSet(false);
    }

    const exportData = (row) => {
        const exportData = mapExportData(row);
        const filteredData = {};
        for (const field of columnsSorted) {
            filteredData[field.name] = exportData[field.name]
        }
        return filteredData;
    }

    const exportCustomData = async (reportType, data) => {
        if (customExportChild) return await customExportChild(reportType, data);
    }


    const onChangeFilter = (filterValue, filterKey) => {
        onDropFilter && onDropFilter(filterValue, filterKey)
    }

    const toggleRowSelected = (checked, id) => {
        let selected = [...selectedRows]
        const indexToRemove = selected.indexOf(id);
        if (indexToRemove > -1) {
            selected.splice(indexToRemove, 1);
        }
        if (checked) {
            selected.push(id);
        }
        setSelectedRows(selected);
    }

    const columnsToUse = allowColumnCustomization ? columnsSorted : columns;

    return (
        <>
            <div className={`${classes.listContainer} ${listContainerStyle}`}>
                {withFilterPanel &&
                    <>
                        <TableFilters
                            tableId={tableId}
                            selectedViewId={selectedViewId}
                            dropDownsConfig={dropDownsConfig}
                            filters={filters}
                            onDropFilter={onChangeFilter}
                            onResetFilters={onResetFilters}
                            updateAllFilters={updateAllFilters ? updateAllFilters : null}
                            filtersSetFromOtherScreen={filtersSetFromOtherScreen}
                            setFiltersSetFromOtherScreen={setFiltersSetFromOtherScreen}
                            customFilterBehavior={customFilterBehavior}
                        />
                    </>
                }
                <div style={{
                    flex: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: withFilterPanel ? '0.7em' : '0'
                }}>
                    {(withFilterPanel || onSearch) &&
                        <Paper className={classes.header}>
                            {withFilterPanel && <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                <Grid item
                                    className={`${classes.titleContainer} ${isMobile ? classes.titleContainerMobile : ""}`}
                                    alignItems="center">
                                    <ExpandFiltersPanel filtersState={filters} />
                                    <h1 className={classes.title}>{title}</h1>
                                </Grid>
                                <Grid item
                                    className={classes.actionButtonsContainer}
                                    direction="row"
                                    alignItems="center">
                                    {actionButtons}
                                </Grid>
                            </Grid>}
                            {onSearch && <Grid item xs={12} className={isMobile ? classes.searchBarMobile : ""}>
                                <DataSearchBar
                                    rows={data}
                                    onSearch={onSearch}
                                    searchVal={searchVal}
                                    searchButton={searchButton}
                                />
                            </Grid>}
                            {withFilterPanel && <SelectedFilters
                                filters={filters}
                                filterConfig={dropDownsConfig}
                                onClearFilter={(filterKey) => onChangeFilter(null, filterKey)}
                                tableId={tableId}
                                selectedViewId={selectedViewId}
                            />}
                        </Paper>
                    }

                    {rows?.length > 0 ?
                        <>
                            <TableContainer component={Paper} className={`${classes.tableContainer} ${className}`}>

                                {(allowColumnCustomization || mapExportData) &&
                                    <Grid alignItems='center' container className={classes.tableActions}
                                        justifyContent='space-between'>
                                        {allowColumnCustomization &&
                                            <Grid item className={classes.views}>
                                                <TableCustomizationPanel
                                                    columns={columns}
                                                    onSaveColumns={onColumnSelectionChange}
                                                    selectedColumns={columnsSorted}
                                                    isCustomized={initialUserPrefSet}
                                                    onReset={reset}
                                                    resetting={isReset}
                                                    showTip
                                                />
                                                <div className={classes.tableViewsSelector}>
                                                    <TableViewsSelector
                                                        selectedView={selectedViewId}
                                                        onSelectView={onSelectView}
                                                        tableId={tableId}
                                                        getData={getData}
                                                        setFiltersSetFromOtherScreen={setFiltersSetFromOtherScreen}
                                                    />
                                                </div>
                                            </Grid>
                                        }
                                        {mapExportData && <Grid item>
                                            <ExportButton btnStyle='outlined' icon={<IconDownload />} label='Export' fileLabel="export"
                                                data={rows}
                                                mapExportData={exportData} />
                                            {/* getData={getDataForExport}/> */}
                                        </Grid>}
                                        {
                                            !!customExportChild && (
                                                <Grid item>
                                                    <ExportReportItemAction data={rows}
                                                        downloadEvent={exportCustomData}></ExportReportItemAction>
                                                </Grid>
                                            )
                                        }
                                    </Grid>}

                                <VirtualizedTable
                                    onRowClick={onRowClick}
                                    getRowClassNameFunc={getRowClassName}
                                    baselineColumnWidth={baselineColumnWidth}
                                    allowHorizontalScroll={allowHorizontalScroll}
                                    customStyling={customStyling}
                                    allowColumnCustomization={allowColumnCustomization}
                                    onColumnOrderChange={onColumnOrderChange}
                                    rowCount={rows.length}
                                    rowGetter={({ index }) => {
                                        return rows[index]
                                    }}
                                    columns={
                                        [
                                            ...(bulkActions ? [{
                                                key: 'multiSelect',
                                                width: 80,
                                                dataKey: rowIdentifier,
                                                component: (row) => <Checkbox
                                                    checked={selectedRows.includes(row?.[rowIdentifier ?? 'id'])}
                                                    onChange={(e, checked) => toggleRowSelected(checked, row?.[rowIdentifier ?? 'id'])}
                                                />,
                                                customizable: false
                                            }] : []),
                                            ...columnsToUse.map(col => ({ ...col, dataKey: col.key, label: col.name })),
                                            ...(actions ? [{
                                                label: 'Actions', dataKey: rowIdentifier,
                                                isAction: true,
                                                width: showActionsInMenu ? 90 : (maxActionCount * 110),
                                                customizable: false
                                            }] : [])]
                                    }
                                    onSort={onSort}
                                    sortDirection={sortDirection}
                                    columnToSort={columnToSort}
                                    actions={actions}
                                    showActionsInMenu={showActionsInMenu}
                                    headerStyle={headerStyle}
                                    rowIdentifier={rowIdentifier}
                                />
                            </TableContainer>
                            {page &&
                                <Grid container className={classes.pagination}>
                                    <Grid item>
                                        <GenericPagination
                                            totalPages={totalPages}
                                            offset={page}
                                            onChangePagination={onChangePagination}
                                            limit={limit}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {!!selectedRows?.length && <BulkActionsToolbar
                                allData={data}
                                selectedRows={selectedRows}
                                actions={bulkActions}
                                onClear={() => setSelectedRows([])}
                            />}
                        </>
                        :
                        <>{noItemsMessage && <Paper className={classes.noItemsDiv}>
                            <div>{noItemsMessage}</div>
                        </Paper>}</>
                    }
                </div>
            </div>
        </>
    )
}

export default DataTable;