import * as React from "react";

function IconReplaceBattery(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1014 6.5625C5.97474 6.5625 5.0614 7.47584 5.0614 8.6025V15.3C5.0614 16.4267 5.97474 17.34 7.1014 17.34H14.3839C15.5106 17.34 16.4239 16.4267 16.4239 15.3V8.6025C16.4239 7.47584 15.5106 6.5625 14.3839 6.5625H7.1014ZM3.9364 8.6025C3.9364 6.85452 5.35342 5.4375 7.1014 5.4375H14.3839C16.1319 5.4375 17.5489 6.85452 17.5489 8.6025V15.3C17.5489 17.048 16.1319 18.465 14.3839 18.465H7.1014C5.35342 18.465 3.9364 17.048 3.9364 15.3V8.6025ZM8.09471 11.5976L7.45427 10.9572C7.2346 10.7375 6.87844 10.7375 6.65877 10.9572C6.4391 11.1768 6.4391 11.533 6.65877 11.7527L7.97127 13.0652C8.34094 13.4348 8.9296 13.4348 9.29927 13.0652L10.6193 11.7452C10.8389 11.5255 10.8389 11.1693 10.6193 10.9497C10.3996 10.73 10.0434 10.73 9.82377 10.9497L9.23943 11.534C9.43802 10.4477 10.3908 9.62256 11.5339 9.62256C12.8208 9.62256 13.8664 10.6682 13.8664 11.9551C13.8664 13.2419 12.8208 14.2876 11.5339 14.2876C11.2233 14.2876 10.9714 14.5394 10.9714 14.8501C10.9714 15.1607 11.2233 15.4126 11.5339 15.4126C13.4421 15.4126 14.9914 13.8632 14.9914 11.9551C14.9914 10.0469 13.4421 8.49756 11.5339 8.49756C9.74644 8.49756 8.27383 9.85711 8.09471 11.5976ZM18.1414 10.1475C18.1414 9.83686 18.3932 9.58502 18.7039 9.58502C19.1115 9.58502 19.5051 9.70243 19.8111 9.92223C20.117 10.142 20.3689 10.495 20.3689 10.9425V12.975C20.3689 13.4204 20.1153 13.7726 19.8125 13.9915C19.5078 14.2118 19.1142 14.3325 18.7039 14.3325C18.3932 14.3325 18.1414 14.0807 18.1414 13.77C18.1414 13.4594 18.3932 13.2075 18.7039 13.2075C18.9011 13.2075 19.0587 13.1482 19.1533 13.0798C19.1996 13.0463 19.2238 13.0161 19.2348 12.9973C19.2401 12.9883 19.2423 12.9823 19.2431 12.9794C19.2438 12.9768 19.2439 12.9756 19.2439 12.975V10.9425C19.2439 10.9401 19.2439 10.9325 19.2344 10.9163C19.2237 10.8981 19.2003 10.8686 19.1548 10.8359C19.0614 10.7689 18.9037 10.71 18.7039 10.71C18.3932 10.71 18.1414 10.4582 18.1414 10.1475Z" fill="#0090FE" />
        </svg>
    );
}

export default IconReplaceBattery;
