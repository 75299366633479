export const issueTrackerPermissions = {
    ISSUE_TRACKER_AVAILABLE: {
        key: 'IssueTracker.All',
        name: 'View and create issue tracker tickets (deprecated)'
    },
    ISSUE_TRACKER_EDIT: {
        key: 'IssueTracker.Edit',
        name: 'View and edit issue tracker tickets (deprecated)'
    },
    EDIT: {
        key: 'tickets.edit',
        name: 'Edit issue tracker tickets'
    },
    READ: {
        key: 'tickets.read',
        name: 'View all issue tracker tickets'
    },
    VIEW_ASSIGNED_TO_ME: {
        key: 'tickets.view.assigned.to.me',
        name: 'View issue tracker tickets assigned to me'
    },
    VIEW_ASSIGNED_TO_LOT: {
        key: 'tickets.view.assigned.to.lot',
        name: 'View issue tracker tickets that are associated with a lot the user is associated with'
    },
    VIEW_ASSIGNED_TO_CLIENT: {
        key: 'tickets.view.assigned.to.client',
        name: 'View issue tracker tickets that are associated with a client the user is associated with'
    }
}
