import { useFindMatchQuery } from "features/vehicles/vehicleSlice";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { CircularProgress, Grid, IconButton, Paper, Popover, Tooltip } from "@material-ui/core";
import { vehicleMatchStyle } from "./style";
import { useState } from "react";
import DetailsButton from "features/vehicles/DetailsButton";

const VehicleMatches = ({
    vin,
    assetId,
    currentId,
    clientAccountId,
    title = 'This asset already exists in ParQ, click for more details'
}) => {
    const classes = vehicleMatchStyle();

    let { data: matches, error: matchError, isFetching: isMatching, isSuccess: matchedEnd } = useFindMatchQuery({
        vin,
        assetId,
        clientAccountId
    }, { skip: !assetId && !vin });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const matchesExcludingCurrent = matches?.filter(m => m.id != currentId);

    return (
        <>
            {!!matchesExcludingCurrent?.length && <div className={classes.iconWrapper}>
                <Tooltip title={title}><IconButton onClick={handleClick}>
                    <ErrorOutlineOutlinedIcon
                        className={classes.icon}
                    />
                </IconButton>
                </Tooltip>
            </div>}
            <div className={classes.spinnerWrapper}>
                {isMatching && <CircularProgress className={classes.spinner} size={'1em'} />}
            </div>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.matchesContainer}
            >
                <Grid container direction='column' spacing={1} className={classes.matchTooltip}>
                    <Grid item ><h4>Duplicate Assets Found</h4></Grid>
                    {matchesExcludingCurrent?.map(match => <Grid item><Paper className={classes.record}>
                        <Grid container direction='column' spacing={1}>
                            {/* <div>{match.id}</div> */}
                            {!!match.vin && <Grid item ><b>VIN:</b> {match.vin}</Grid>}
                            {!!match.assetId && <Grid item ><b>Asset Id:</b> {match.assetId}</Grid>}
                            <Grid item style={{ alignSelf: 'end' }} ><DetailsButton id={match.id} /></Grid>
                        </Grid>
                    </Paper></Grid>)}
                </Grid>
            </Popover>

        </>
    )
}

export default VehicleMatches; 