import { Autocomplete, TextField, createFilterOptions } from "@mui/material"
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { Style } from "./style";
import {useAddContactTypeMutation, useGetContactTypesQuery} from "../../contactTypeSlice";

export const ContactTypeField = ({ value, onChange, isPoc,required, disabled,label }) => {
    const classes = Style()

    const filter = createFilterOptions();
    let { data: contactType, error: contactTypeError, isLoading: isLoadingContactTypes, refetch: refetchContactType } = useGetContactTypesQuery();
    const [addContactType, { isLoading: isAdding }] = useAddContactTypeMutation();

    let contact = contactType?.find(s => s.id === value);
    
    let contactTypeFilter = contactType?.filter(x=> x.isPoc === isPoc);
    
    const onValueChange = async (newValue) => {
        if ((newValue && newValue.inputValue) || typeof newValue === 'string') {
            // Create a new value from the user input
            let result = await addContactType({ 
                name: newValue.inputValue,
                key: newValue.inputValue,
                description: newValue.inputValue,
                isPoc:isPoc,
                isDefault:false });
            
            if (result && !result.error) {
                refetchContactType();
                NotificationProvider.success("Contact type added successfully");
            } else {
                NotificationProvider.error("Something happened, try again or contact support");
            }
            onChange(result?.data?.id);
        } else {
            onChange(newValue?.id);
        }
    }

    return <>
        <LoadingSpinner loading={isAdding || isLoadingContactTypes} />
        <Autocomplete
            value={contact}
            onChange={(event, newValue) => {
                onValueChange(newValue)
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            disabled={disabled}
            autoHighlight
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="contactType"
            options={contactTypeFilter}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props, option) => <li {...props}> <div className={classes.options}>{option.name}</div></li>}
            freeSolo
            renderInput={(params) => (
                <TextField
                    value={contact}
                    variant='outlined'
                    label={label}
                    InputLabelProps={{ className: classes.label }}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        className: classes.input,
                        notchedOutline: classes.notchedOutline,
                    }}
                />
            )}
            className={classes.input}
        />
    </>

}