import { createSlice } from "@reduxjs/toolkit";

export const usersFiltersSlice = createSlice({
    name: 'usersFilters',
    initialState: {
        searchVal: '',
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        }
    },
});

export const { setSearchVal } = usersFiltersSlice.actions;
export default usersFiltersSlice.reducer;
