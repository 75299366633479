import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DropdownFilter from "components/DropdownFilter";
import { groupLegTypes } from "features/loads/enums";
import { cloneDeep, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { arrayToObject } from "utils/object-util";
import { v4 } from "uuid";
import CreateLoadGroup from "../CreateLoadGroup";
import { addLegStyle } from "./style";
import { Alert } from "@mui/material";
import { LoadAssetSelector } from "../AddLoadActivity/LoadAssetSelector";

const AddLoadLeg = ({ open, setOpen, loadGroup }) => {
    const classes = addLegStyle();
    const [loadGroupToEdit, setLoadGroupToEdit] = useState({});
    const [selectedAsset, setSelectedAsset] = useState(null)
    const [loadIndex, setLoadIndex] = useState()

    useEffect(() => {
        if (loadGroup) {
            const group = cloneDeep(loadGroup ?? {});
            const lastLoad = group?.loads?.[group?.loads?.length - 1];
            const newLoad = {
                groupId: loadGroup.id,
                clientAccountId: loadGroup.clientAccountId,
                clientId: loadGroup.clientId,
                id: 'new',
                pickupLocationId: lastLoad?.deliveryLocationId,
                uuid: v4(),
                type: groupLegTypes.TRANSPORT,
            }
            group.loads = [...group.loads, newLoad]
            setLoadGroupToEdit(group);
            setLoadIndex(group.loads?.length - 1);
        }
    }, [loadGroup]);

    return (
        <Dialog open={open} aria-labelledby='dialog-title' maxWidth='lg' fullWidth>
            <DialogTitle id={'dialog-title'} >
                <Grid container justifyContent="space-between">
                    Add Load Leg
                    <IconButton onClick={() => setOpen()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div className={classes.assetSelector}>
                    <Alert className={classes.desc} severity='info'>Select an asset from the list or enter asset information manually.</Alert>
                    <LoadAssetSelector
                        loadGroup={loadGroupToEdit}
                        allowNewAsset
                        setSelectedAsset={setSelectedAsset}
                    />
                </div>
                <div className={classes.loadInfo}>
                    <CreateLoadGroup
                        allowMultileg={false}
                        selectedLegIndex={loadGroupToEdit?.loads?.length - 1}
                        loadData={{ ...loadGroupToEdit }}
                        vehicleData={selectedAsset}
                        disableAssetDetails={!!selectedAsset?.id}
                        onClose={() => setOpen(false)}
                    />
                </div>
            </DialogContent>
        </Dialog>

    )
}
export default AddLoadLeg;