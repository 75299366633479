import { baseApi } from "app/baseApi";
import { baseQuery } from "app/baseQuery";
import { apiTags } from "app/store/apiTags";

const apiPrefix = 'account'

export const clientAccountApi = baseApi.injectEndpoints({
    reducerPath: 'clientAccountApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllClientAccounts: builder.query({ //these are all sub clients of a specific client
            query: (clientId) => `${apiPrefix}/getall/${clientId}`,
            providesTags: [apiTags.CLIENT_ACCOUNTS]
        }),
        addClientAccount: builder.mutation({
            query: clientAccount => ({
                url: `${apiPrefix}/add`,
                method: "POST",
                body: clientAccount
            }),
            invalidatesTags: [apiTags.CLIENT_ACCOUNTS]
        }),
        updateClientAccount: builder.mutation({
            query: clientAccount => ({
                url: `${apiPrefix}/update`,
                method: "PUT",
                body: clientAccount?.subClient
            }),
            invalidatesTags: [apiTags.CLIENT_ACCOUNTS]
        })
    })
});

export const { useGetAllClientAccountsQuery, useAddClientAccountMutation, useUpdateClientAccountMutation } = clientAccountApi; 