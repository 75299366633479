import { useGetLoadsQuery } from 'features/loads/loadsSlice';
import { LoadsDropDownStyle } from './LoadsDropDownStyle';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import { Box, Grid, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { arrayToObject } from 'utils/object-util';


export default function LoadsDropDown({ onLoadChange, value, loadsValues }) {
    const classes = LoadsDropDownStyle();

    let { data: loads, error: loadListError, isFetching: isLoadingLoads } = useGetLoadsQuery({});
    let { data: lots } = useGetLotsQuery();
    loads = loads || [];

    const lotLookup = arrayToObject(lots, 'id');
    const loadValuesMap = loadsValues?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const loadsMap = loadsValues?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const optionLabel = () => {
        let load = loadsValues ? loadValuesMap[value] : loadsMap[value];
        return load?.lmId
    }

    //TODO virtualize
    const filterOptions = createFilterOptions({
        ignoreCase: true,
        matchFrom: "start",
        limit: 30,
    });


    return (
        <Autocomplete
            id="loadId"
            value={value}
            onChange={(event, val) => onLoadChange(val)}
            options={loadsValues || loads}
            getOptionLabel={(option) => option?.lmId ?? optionLabel()}//}
            filterOptions={filterOptions}
            renderOption={(props, option) => (
                <Box  {...props}>
                    <Grid container direction='row'>

                        <Grid item xs={6} container direction='column'>
                            <Grid item >
                                <label className={classes.loadLabel}> VIN: </label>
                                {option?.vinsForSearch}
                            </Grid>
                            <Grid item >
                                <label className={classes.loadLabel}> LoadMate ID: </label>
                                {option?.lmId}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container direction='row'>
                            <Grid item xs={6}>
                                <ArrowUpwardSharpIcon className={classes.upwardArrow} />
                                {lotLookup?.[option?.pickupLocationId]?.name}
                            </Grid>
                            <Grid item xs={6}>
                                <ArrowDownwardSharpIcon className={classes.downwardArrow} />
                                {lotLookup?.[option?.deliveryLocationId]?.name}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    value={value}
                    variant='outlined'
                    label="Select Load"
                    InputLabelProps={{ className: classes.label }}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        notchedOutline: classes.notchedOutline,
                        className: classes.input,
                        // autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
            className={classes.input}
        />
    )
}