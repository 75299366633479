import { Grid } from "@material-ui/core";
import { billingDashboardStyle } from "../style";
import FormatService from "services/formatService";

const CountGraph = ({ title, count }) => {

    const classes = billingDashboardStyle();
    return (
        <>
            <Grid className={classes.countWrapper} container direction="column">
                {/* <div className={classes.exportBtn}>
                    <ActivityListDialog status={StatusEnum.COMPLETED} />
                    <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={filteredActivities} mapExportData={mapExportData} />
                </div> */}
                <Grid item className={classes.count}>{FormatService.formatCurrency(count)}</Grid>
                <Grid item><h3 className={classes.text}>{title}</h3></Grid>
            </Grid>
        </>
    )
}

export default CountGraph;