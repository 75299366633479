export const TaskStatus = {
    REQUESTED_BY_CLIENT: 1,
    REQUESTED_BY_VENDOR: 2,
    IN_PROGRESS: 3,
    COMPLETE: 4,    
    PENDING_APPROVAL: 5,
    PENDING_INVOICE: 6,
    CANCELLED: 7
}

export const TaskSetup = {
    [TaskStatus.PENDING_APPROVAL]: {
        label: 'Pending Approval',
        backgroundColor: "red",
        color:"white"
    },
    [TaskStatus.REQUESTED_BY_CLIENT]: {
        label: 'Requested By Client',
        backgroundColor: "Green",
        color:"white"
    },
    [TaskStatus.IN_PROGRESS]: {
        label: 'In Progress',
        backgroundColor: "orange",
        color:"black"
    },
    [TaskStatus.PENDING_INVOICE]: {
        label: 'Pending Invoice',
        backgroundColor: "#8B0000",
        color:"white"
    },
    [TaskStatus.COMPLETE]: {
        label: 'Complete',
        backgroundColor: "#006400",
        color:"white"
    },
    [TaskStatus.CANCELLED]: {
        label: 'Cancelled',
        backgroundColor: "#808080",
        color:"white"
    }
}