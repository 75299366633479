import * as React from "react";

function SvgIconMessage(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M16 22.25H3C2.31614 22.25 1.75 21.6839 1.75 21V8C1.75 5.87378 2.30947 4.33263 3.32105 3.32105C4.33263 2.30947 5.87378 1.75 8 1.75H16C18.1262 1.75 19.6674 2.30947 20.6789 3.32105C21.6905 4.33263 22.25 5.87378 22.25 8V16C22.25 18.1262 21.6905 19.6674 20.6789 20.6789C19.6674 21.6905 18.1262 22.25 16 22.25ZM8 2.25C6.14167 2.25 4.67505 2.68285 3.67895 3.67895C2.68285 4.67505 2.25 6.14167 2.25 8V21C2.25 21.4161 2.58386 21.75 3 21.75H16C17.8583 21.75 19.325 21.3172 20.3211 20.3211C21.3172 19.325 21.75 17.8583 21.75 16V8C21.75 6.14167 21.3172 4.67505 20.3211 3.67895C19.325 2.68285 17.8583 2.25 16 2.25H8Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M17 9.75H7C6.86614 9.75 6.75 9.63386 6.75 9.5C6.75 9.36614 6.86614 9.25 7 9.25H17C17.1339 9.25 17.25 9.36614 17.25 9.5C17.25 9.63386 17.1339 9.75 17 9.75Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M14 14.75H7C6.86614 14.75 6.75 14.6339 6.75 14.5C6.75 14.3661 6.86614 14.25 7 14.25H14C14.1339 14.25 14.25 14.3661 14.25 14.5C14.25 14.6339 14.1339 14.75 14 14.75Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconMessage;
