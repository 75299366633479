import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    headerTitle: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    headerTitleRoleName: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontWeight: "bold",
        paddingLeft: 3
    }
}));