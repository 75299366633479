import CustomInput from "components/CustomInput"
import LotsDropdown from "components/SharedDropdowns/LotsDropDown"
import { ServiceProviderField } from "./ServiceProviderField"
import { IconDollar } from "icons"
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch } from "@material-ui/core"
import DropdownFilter from "components/DropdownFilter"

export const activityPriceFields = {
    ACTIVITY: 1,
    SERVICE_PROVIDER: 2,
    LOT_OPTIONS: 3,
    LOT_TOGGLE: 4,
    LOT: 5,
    VEHICLE_TYPES: 6,
    NOT_SUPPORTED: 7,
    PRICE: 8,
    USE_INVENTORY: 9,
}

export const activityPriceConfig = {
    [activityPriceFields.ACTIVITY]: {
        name: 'Activity',
        component: (value, values, onChange, required, label) => <CustomInput
            id="ddlActivityId"
            label={label}
            elementType="dropdown"
            values={values}
            value={value}
            required={required}
            showEmpty={true}
            onChange={val => onChange(val)}
        />
    },
    [activityPriceFields.SERVICE_PROVIDER]: {
        name: 'Service Provider',
        component: (value, values, onChange, required, label, multiple) => <ServiceProviderField
            value={value}
            onChange={val => onChange(val)}
            label={label}
            required={required}
            multiple={multiple}
        />
    },
    [activityPriceFields.LOT_OPTIONS]: {
        name: '',
        component: (value, values, onChange, required, label) => <FormControl>
            <FormLabel id="lot-radio-group">{label}</FormLabel> 
            <RadioGroup
                value={value}
                onChange={onChange}
                aria-labelledby="lot-radio-group"
                name="lot-radio-group"
                row
            >
                <FormControlLabel value={toggleOptions.SPECIFIC_LOTS} control={<Radio />} label="Specific Lots" />
                <FormControlLabel value={toggleOptions.ALL_LOTS} control={<Radio />} label="All Lots" />
                <FormControlLabel value={toggleOptions.ALL_PMF_LOTS} control={<Radio />} label="All PMF Lots" />
            </RadioGroup>                                    
        </FormControl>
    },
    [activityPriceFields.LOT_TOGGLE]: {
        name: "Include Non PMF Lots",
        component: (checked, onChange, label) => <FormControlLabel
            control={<Switch
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={onChange}
                checked={checked}
                />}
            label={label}
        />
    },
    [activityPriceFields.LOT]: {
        name: 'Lot',
        component: (value, values, onChange, required, label, multiple, pmfLot) => <LotsDropdown
            title={label}
            identifier='lot'
            values={values}
            onSelect={val => onChange(val)}
            value={value}
            showEmpty={true}
            required={required}
            pmfLot={pmfLot}
            multiple={multiple}
        />
    },
    [activityPriceFields.VEHICLE_TYPES]: {
        name: 'Vehicle Type',
        component: (value, values, onChange, required, label) => <DropdownFilter
            required={required}
            title={label}
            value={value}
            onSelect={val => onChange(val)}
            values={values}
            multiple={true}
            showEmpty={false}
        />
    },
    [activityPriceFields.NOT_SUPPORTED]: {
        name: '',
        component: (checked, onChange, label) => <FormControlLabel
            control = {<Checkbox
                        checked={checked}
                        onChange={onChange}
                    />}
            label = {label} 
            labelPlacement="end"
        />
    },
    [activityPriceFields.PRICE]: {
        name: 'Price',
        component: (value, values, onChange, required, label) => <CustomInput
            id="ddlActivityPrice"
            label={label}
            elementType="input"
            value={value}
            required={required}
            onChange={val => onChange(val)}
            numberOnly
            icon={<IconDollar />}
        />
    },
    [activityPriceFields.USE_INVENTORY]: {
        name: 'Use Inventory',
        component: (checked, onChange, label) => <FormControlLabel
            control = {<Checkbox
                        checked={checked}
                        onChange={onChange}
                    />}
            label = {label} 
            labelPlacement="end"
        />
    }
}

export const toggleOptions = {
    SPECIFIC_LOTS: 'specific-lots',
    ALL_LOTS: 'all-lots',
    ALL_PMF_LOTS: 'all-pmf-lots'
}