import { makeStyles } from "@material-ui/core"

export const subscriptionListStyle = makeStyles((theme) => ({
    // container: {
    //     marginTop: "1rem",
    //     maxWidth: "100%"
    // },
    subscriptionBox: {
        width: '100%',
        padding: 20,
        alignItems: 'center'
    },
    multiSelect: {
        width: '300px',
        flexWrap: 'wrap',
        display: 'flex',
    },
    saveButton: {
        //paddingRight: 50,
        height: '2em',
        marginLeft: 'auto'
    },
    selectLabel: {
        marginLeft: '0.7em'
    },
    tooltip: {
        margin: 'right'
    }
}));