import { baseApi } from '../../../../app/baseApi';
import { baseQuery } from '../../../../app/baseQuery';
import { apiTags } from '../../../../app/store/apiTags';

const basePath = 'checkin';
export const checkinConfigApi = baseApi.injectEndpoints({
    reducerPath: 'checkinConfigApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.CLIENT_CHECKIN_CONFIG],
    endpoints: (builder) => ({
        getCheckInForClient: builder.query({
            query: (clientId) => `${basePath}/${clientId}`,
            providesTags: [apiTags.CLIENT_CHECKIN]
        }),
        getSingleCheckInForClient: builder.query({
            query: (clientId) => `${basePath}/${clientId}/single`,
            providesTags: [apiTags.CLIENT_CHECKIN]
        }),
        updateCheckIn: builder.mutation({
            query: checkIn => ({
                url: `${basePath}`,
                method: "PUT",
                body: checkIn
            }),
            invalidatesTags: [apiTags.CLIENT_CHECKIN, apiTags.CLIENT_VALUES, apiTags.VEHICLES_TYPES]
        }),
    }),
});

export const {
    useGetCheckInForClientQuery, useUpdateCheckInMutation, useGetSingleCheckInForClientQuery
} = checkinConfigApi;

