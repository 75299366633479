import { Grid } from '@material-ui/core';
import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import CustomInput from 'components/CustomInput';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetTransportersQuery } from 'features/transporter/transporterSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TransporterFields = ({ }) => {
    
    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({...accessToEdit, [key]: val}))
    }
    
    let { data: transporters, error: transportersError, isLoading } = useGetTransportersQuery();

    const [newTransporter, setNewTransporter] = useState(null)
    
    if (newTransporter) { transporters = [...transporters, newTransporter] }

    const transportersLookup = transporters?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    useEffect(() => {
        let usdot = transportersLookup?.[accessToEdit?.transporterId]?.usdot
        if(accessToEdit?.transporterId && usdot) {
            setDisableUsdot(true);
        }
        dispatch(setAccessToEdit({...accessToEdit, ["usdot"]: usdot}))
    }, [])

    const [disableUsdot, setDisableUsdot] = useState(false);

    const onAddTransporter = async (val) => {
        setDisableUsdot(false);
        setNewTransporter({...val, id: 0})
        dispatch(setAccessToEdit({
            ...accessToEdit, 
            ["transporterId"]: 0, 
            ["transporterName"]: val.name, 
            ["usdot"]: null
        }))
    }

    const onSelectTransporter = (val) => {
        //When creating a new transporter:
        // if (val == 0) {
        //     setDisableUsdot(false);
        //     onChangeValue("transporterId", val)
        // }
        //When selecting an existing transporter:
        // else {
            let transporterUsdot = transporters?.find(t => t.id === val)?.usdot;
            if(transporterUsdot) {
                setDisableUsdot(true);
            } else {
                setDisableUsdot(false);
            }
            dispatch(setAccessToEdit({ ...accessToEdit, 
                ["transporterId"]: val, 
                ["usdot"]: transporterUsdot, 
                ["transporterName"]: null 
            }));
        // }
    }

    // useEffect(() => {
    //     if (accessToEdit?.transporterId) {
    //         let transporterUsdot = transporters?.find(t => t.id === accessToEdit?.transporterId)?.usdot;
    //         onChangeValue("usdot", transporterUsdot)
    //     }
    // }, [accessToEdit?.transporterId])


    return (
        <>
            <Grid container direction='row' spacing={2}>
                <Grid item xs={6}>
                    <CreatableDropdown
                        options={transporters}
                        value={accessToEdit?.transporterId}
                        required
                        addValue={onAddTransporter}
                        label='Transporter'
                        onChange={(val) => onSelectTransporter(val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        label='USDOT'
                        elementType='input'
                        value={accessToEdit?.usdot}
                        onChange={(val) => onChangeValue("usdot", val)}
                        disabled={disableUsdot}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default TransporterFields;