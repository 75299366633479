import { useContext } from "react";
import { PromptContext } from "../components/Prompt/PromptContext";

function usePrompt() {
    const [, setPrompt] = useContext(PromptContext);

    const triggerPrompt = (props) => {
        setPrompt({ isOpen: true, props });
    };

    return { triggerPrompt };
};
export default usePrompt;