import { Button } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import NotificationProvider from 'components/NotificationProvider';
import { useEffect, useState } from "react";
import { userTaskSetupByType, userTaskStatuses } from '../userTaskConsts';
import { useEditUserTaskMutation } from '../userTaskSlice';
import { UserTaskItemStyle } from "./style";

const UserTaskItem = ({ task, lotId, setOpenDialog }) => {
    const classes = UserTaskItemStyle();

    const [editUserTask, { isLoading: isSaving }] = useEditUserTaskMutation();

    const [saveMode, setSaveMode] = useState(null);
    const [onChange, setOnChange] = useState(false);
    const [taskToEdit, setTaskToEdit] = useState(task);
    const [valid, setValid] = useState(true);

    useEffect(() => {
        setTaskToEdit({ ...(task ?? {}) });
    }, [task]);

    const TaskComponent = userTaskSetupByType[taskToEdit?.taskTypeEnum]?.component;

    const taskCompleted = () => {
        return task?.taskStatus === userTaskStatuses.Complete;
    }

    // const setDefault = (key, val) => {
    //     setTaskToEdit({ ...taskToEdit, [key]: val });
    // }

    const handleChange = (key, val) => {
        setTaskToEdit({ ...taskToEdit, [key]: val })
        setOnChange(true);
    }

    const handleClose = () => {
        setOpenDialog && setOpenDialog(false);
        setTaskToEdit(task);
        setOnChange(false);
        setSaveMode(null);
    }

    const onSave = async () => {
        setSaveMode(true);
        let userTask = { ...taskToEdit, ['taskStatus']: parseInt(taskToEdit?.taskStatus), ['userResponse']: taskToEdit?.userResponse?.toString() ?? userTaskSetupByType[taskToEdit?.taskTypeEnum]?.defaultUserResponse };

        let res = await editUserTask(userTask);
        if (res.error) {
            NotificationProvider.error("Failed to save task");
        } else {
            NotificationProvider.success("Saved task successfully");
        }
        handleClose();
    }

    const onComplete = async () => {
        setSaveMode(false);
        let userTask = { ...taskToEdit, ['taskStatus']: userTaskStatuses.Complete, ['userResponse']: taskToEdit?.userResponse?.toString() ?? userTaskSetupByType[taskToEdit?.taskTypeEnum]?.defaultUserResponse };

        let res = await editUserTask(userTask);
        if (res.error) {
            NotificationProvider.error("Failed to complete task");
        } else {
            NotificationProvider.success("Completed successfully");
        }
        handleClose();
    }

    return (
        <>
            {TaskComponent &&
                <>
                    <div className={classes.taskComponent}>
                        <TaskComponent task={taskToEdit} taskStatus={task?.taskStatus}
                            onChange={handleChange} lotId={lotId}
                            valid={valid} setValid={setValid} />
                    </div>

                    <div className={classes.buttonWrapper}>

                        {onChange && taskCompleted() && <Button
                            onClick={onSave}
                            disabled={!valid || isSaving}
                            className={classes.button}
                            variant="contained"
                        >
                            {isSaving && saveMode === true ? "Saving..." : "Save"}
                        </Button>}

                        {!taskCompleted() && <Button
                            onClick={onComplete}
                            disabled={!valid || isSaving}
                            className={classes.button}
                            variant="contained"
                            startIcon={<CheckIcon style={{ fill: 'white' }} />}
                        >
                            {isSaving && saveMode === false ? "Completing..." : "Complete Task"}
                        </Button>}
                    </div>
                </>
            }
        </>
    );
};

export default UserTaskItem;
