import * as React from "react";

function IconInfo(props) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99984 15.6144C5.07556 15.6144 1.88525 12.4241 1.88525 8.49984C1.88525 4.57556 5.07556 1.38525 8.99984 1.38525C12.9241 1.38525 16.1144 4.57556 16.1144 8.49984C16.1144 12.4241 12.9241 15.6144 8.99984 15.6144ZM8.99984 1.44775C5.1112 1.44775 1.94775 4.6112 1.94775 8.49984C1.94775 12.3885 5.1112 15.5519 8.99984 15.5519C12.8885 15.5519 16.0519 12.3885 16.0519 8.49984C16.0519 4.6112 12.8885 1.44775 8.99984 1.44775Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M9 9.23942C8.99548 9.23942 8.98763 9.23776 8.97902 9.22915C8.97041 9.22054 8.96875 9.21269 8.96875 9.20817V5.6665C8.96875 5.66198 8.97041 5.65414 8.97902 5.64553C8.98763 5.63691 8.99548 5.63525 9 5.63525C9.00452 5.63525 9.01237 5.63691 9.02098 5.64553C9.02959 5.65414 9.03125 5.66198 9.03125 5.6665V9.20817C9.03125 9.21269 9.02959 9.22054 9.02098 9.22915C9.01237 9.23776 9.00452 9.23942 9 9.23942Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M8.92298 11.5233L8.92298 11.5233C8.89832 11.5131 8.87421 11.4992 8.85014 11.4803C8.82855 11.4539 8.82075 11.4382 8.81484 11.4229L8.81502 11.4228L8.80971 11.4101C8.7956 11.3762 8.7915 11.3494 8.7915 11.3332C8.7915 11.317 8.7956 11.2902 8.80971 11.2564C8.81977 11.2322 8.83327 11.2086 8.85162 11.185C8.8752 11.1666 8.89882 11.1531 8.92298 11.1431C8.9699 11.1235 9.02977 11.1235 9.0767 11.1431L9.269 10.6815L9.0767 11.1431C9.10085 11.1531 9.12448 11.1666 9.14806 11.185C9.16641 11.2086 9.1799 11.2322 9.18997 11.2564L9.18997 11.2564C9.20407 11.2902 9.20817 11.317 9.20817 11.3332C9.20817 11.3494 9.20407 11.3762 9.18997 11.4101L9.18979 11.41L9.18483 11.4229C9.17892 11.4382 9.17113 11.4539 9.14953 11.4803C9.12546 11.4992 9.10136 11.5131 9.0767 11.5233L9.0767 11.5233C9.04284 11.5374 9.01607 11.5415 8.99984 11.5415C8.9836 11.5415 8.95683 11.5374 8.92298 11.5233Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default IconInfo;
