import { makeStyles } from "@material-ui/core";

export const linkedWorkordersStyle = makeStyles((theme) => ({
    itemIcon: {
        paddingRight: '1em'
    },
    itemLabel: {
        fontWeight: 600
    },
    autoComplete: {
        backgroundColor: theme.palette.background.default,
        margin: '0.5rem 0 ',
        padding: '0.5rem 0 0.5rem 0.5rem',
        color: theme.palette.primary.main,
        borderRadius: '4px',

        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: '0 !important',
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        },        
        '& .MuiChip-root': {
            backgroundColor: 'white',
            borderRadius: '3px',
            color: '#A159D8',
            fontWeight: 400,
            '& path': {
                stroke: '#A159D8'
            }
        }
    }
}))