import { Grid, Paper } from "@material-ui/core"
import { IconImage } from "icons"
import { EditFormOutputStyle } from "../style";
import { useRef } from "react";
import FileService from "services/fileService";

export const ImageComponent = ({ value, onChange }) => {
    const classes = EditFormOutputStyle();
    const inputFile = useRef(null);

    const selectPhoto = () => {
        inputFile.current.click();
    };

    const onSelectFile = async e => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        let files = [...e.target.files];
        files.forEach(async file => {
            let url = URL.createObjectURL(file);
            let base64Data = await FileService.getBase64Data(file);
            onChange({ url: url, fileName: file.name, data: base64Data }, "pictureObj");
        });
    };


    return (
        <>
            {value ? <img
                id="theImage"
                height={100}
                src={value}
                onClick={selectPhoto}
                className={classes.image} /> :
                <Grid item xs={3}>
                    <Paper className={classes.addPhotoContainer} onClick={selectPhoto}>
                        <IconImage />
                        Add Photo
                    </Paper>

                </Grid>
            }
            <input
                type='file'
                ref={inputFile}
                style={{ display: 'none' }}
                accept={'image/*'}
                onChange={(e) => {
                    onSelectFile(e)
                }} />
        </>
    )
}