import { makeStyles } from '@material-ui/core/styles';

export const bulkActionsToolbarStyle = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        bottom: '1em',
        display: 'flex',
        alignItems: 'stretch',
        left: '50%',
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
        background: 'white',
        borderRadius: '5px',
    },
    selectedCount: {
        background: theme.palette.primary.main,
        padding: '0 1em',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    clearIcon: {
        padding: '0 0.5em',
        display: 'flex',
        alignItems: 'center',
        borderLeft: '1px solid #CFD6DD',
        '& svg': {
            fill: '#8793A1',
            height: '0.7em',
            width: '0.7em'
        }
    },
    selectedText: {
        width: '6em',
        padding: '1em',
        fontSize: '0.9em',
        fontWeight: 'bold'
    },
    actions: {
        borderLeft: '1px solid #CFD6DD',
    }

}));
