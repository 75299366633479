import { createSlice } from "@reduxjs/toolkit";

export const issuesTrackerFiltersSlice = createSlice({
    name: 'issuesTrackerFilters',
    initialState: {
        searchVal: '',
        viewArchived: false,
        filters: {
            clientId: { value: null },
            lotId: { value: null },
            statusId: { value: null },
            latestStatusId: { value: [] },
        },
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setViewArchived: (state, action) => {
            state.viewArchived = action.payload;
        },
        resetFilters: (state, action) => {
            state.filters = {
                clientId: { value: null },
                lotId: { value: null },
                statusId: { value: null },
                latestStatusId: { value: [] },
            }
        },
    },
});

export const { setSearchVal, setFilters, setViewArchived, resetFilters } = issuesTrackerFiltersSlice.actions;
export default issuesTrackerFiltersSlice.reducer;