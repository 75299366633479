import { Grid, Paper, makeStyles } from "@material-ui/core";
import * as icons from "icons";
import * as mechIcons from "icons/mechanicals";

const useStyles = makeStyles((theme) => ({
    icon: {
        '& svg': {
            height: '2em',
            width: '2em'
        }
    },
    page: {
        padding: '2em'
    }
}));

export const IconLibrary = ({ }) => {
    const classes = useStyles();
    const iconComponents = Object.keys(icons)?.map(iconName => {
        const icon = icons?.[iconName];
        const iconComponent = icon({});
        return { component: iconComponent, name: iconName };
    });

    const mechIconComponents = Object.keys(mechIcons)?.map(iconName => {
        const icon = mechIcons?.[iconName];
        const iconComponent = icon && icon({});
        return { component: iconComponent, name: iconName };

    });

    return (
        <Paper className={classes.page}>
            {/* <>{Object.values(mechIcons)?.map(icon => icon)}</> */}
            <h3>Parq Icon Library</h3>
            <Grid container>
                {iconComponents?.map(icon => <Grid className={classes.icon} item container xs={4}>
                    <Grid item xs={4}>{icon?.name}</Grid>
                    <Grid item>{icon?.component}</Grid>
                </Grid>)}
            </Grid>

            <h3>Mechanical Icons (activites)</h3>
            <Grid container>
                {mechIconComponents?.map(icon => <Grid className={classes.icon} item container xs={4}>
                    <Grid item xs={4}>{icon?.name}</Grid>
                    <Grid item>{icon?.component}</Grid>
                </Grid>)}
            </Grid>
        </Paper>
    )
}