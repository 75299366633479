import * as React from "react";

function IconTireRotation(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9989 1.875C5.06456 1.875 1.8739 5.06566 1.8739 9C1.8739 12.9343 5.06456 16.125 8.9989 16.125C9.30956 16.125 9.5614 16.3768 9.5614 16.6875C9.5614 16.9982 9.30956 17.25 8.9989 17.25C4.44324 17.25 0.748901 13.5557 0.748901 9C0.748901 4.44434 4.44324 0.75 8.9989 0.75C13.5546 0.75 17.2489 4.44434 17.2489 9C17.2489 9.69317 17.1602 10.3599 17.0083 10.9915C16.9357 11.2936 16.6319 11.4795 16.3299 11.4069C16.0278 11.3343 15.8419 11.0305 15.9145 10.7285C16.0476 10.1751 16.1239 9.59683 16.1239 9C16.1239 5.06566 12.9332 1.875 8.9989 1.875ZM9.0065 5.15991C6.88716 5.15991 5.1665 6.88057 5.1665 8.99991C5.1665 10.1772 5.6956 11.2307 6.5284 11.9389C6.76506 12.1402 6.79376 12.4952 6.59251 12.7318C6.39126 12.9685 6.03626 12.9972 5.7996 12.7959C4.72741 11.8841 4.0415 10.5226 4.0415 8.99991C4.0415 6.25925 6.26584 4.03491 9.0065 4.03491C11.7472 4.03491 13.9715 6.25925 13.9715 8.99991C13.9715 9.31057 13.7197 9.56241 13.409 9.56241C13.0983 9.56241 12.8465 9.31057 12.8465 8.99991C12.8465 6.88057 11.1258 5.15991 9.0065 5.15991ZM9.23398 13.4422L8.58659 12.7948C8.36692 12.5751 8.01077 12.5751 7.7911 12.7948C7.57143 13.0145 7.57143 13.3706 7.7911 13.5903L9.1036 14.9028C9.47327 15.2725 10.0619 15.2725 10.4316 14.9028L11.7516 13.5828C11.9713 13.3631 11.9713 13.007 11.7516 12.7873C11.5319 12.5676 11.1758 12.5676 10.9561 12.7873L10.3811 13.3623C10.5833 12.2804 11.534 11.4598 12.6739 11.4598C13.9608 11.4598 15.0064 12.5055 15.0064 13.7923C15.0064 15.0792 13.9608 16.1248 12.6739 16.1248C12.3633 16.1248 12.1114 16.3767 12.1114 16.6873C12.1114 16.998 12.3633 17.2498 12.6739 17.2498C14.5821 17.2498 16.1314 15.7005 16.1314 13.7923C16.1314 11.8842 14.5821 10.3348 12.6739 10.3348C10.884 10.3348 9.40973 11.6982 9.23398 13.4422Z" fill="#0090FE" />
        </svg>
    );
}

export default IconTireRotation;
