import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { EditListsStyle } from './EditListsStyle';
import { useUpdateLotsMutation } from 'features/access/accessSlice';
import NotificationProvider from 'components/NotificationProvider';
import LoadingSpinner from 'components/LoadingSpinner';
import EditSitesList from './EditSitesList';


const EditSites = ({ access, open, onClose }) => {

    const classes = EditListsStyle()
    const [lots, setLots] = useState(access?.lots);

    const [updateLots, { isLoading }] = useUpdateLotsMutation();
    
    const onCloseDialog = () => {
        setLots(access?.lots);
        onClose();
    }

    const onSave = async () => {
        let body = {accessGroupId: access.id, lotIds: lots?.map(l => l.id)};
        let res = await updateLots(body);
        if (res && !res.error) {
            NotificationProvider.success("Access group sites updated successfully");
        } else {
            NotificationProvider.error("Failed to update access group sites");
        }
        onCloseDialog();
    }

    return (
        <>
            <Dialog maxWidth='md' fullWidth open={open} onClose={onCloseDialog} aria-labelledby='dialog-title'>
                <DialogTitle id={'dialog-title'} >
                    Edit Sites
                </DialogTitle>
                <DialogContent>
                    <EditSitesList access={access} lots={lots} setLots={setLots} />
                    <LoadingSpinner loading={isLoading} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button className={classes.button} variant='outlined' color='primary' onClick={onCloseDialog}>
                        Cancel
                    </Button>
                    <Button className={classes.button} variant='contained' color='primary' onClick={onSave} disabled={isLoading}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}

export default EditSites



