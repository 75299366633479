import { apiTags } from '../../../../app/store/apiTags';
import { baseApi } from "../../../../app/baseApi";
import { baseQuery } from "../../../../app/baseQuery";

export const submarketApi = baseApi.injectEndpoints({
    reducerPath: 'submarketApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.MARKETS],
    endpoints: (builder) => ({
        getSubMarkets: builder.query({
            query: () => `submarket`,
            providesTags: [apiTags.MARKETS],
        }),
        addSubMrket: builder.mutation({
            query: submarket => ({
                url: 'submarket',
                method: "PUT",
                body: submarket
            }),
            invalidatesTags: [apiTags.MARKETS],
        }),
        updateSubMrket: builder.mutation({
            query: submarket => ({
                url: 'submarket',
                method: "POST",
                body: submarket
            }),
            invalidatesTags: [apiTags.MARKETS],
        }),
        deleteSubMarket: builder.mutation({
            query: submarket => ({
                url: 'submarket',
                method: "DELETE",
                body: submarket
            }),
            invalidatesTags: [apiTags.MARKETS],
        }),
    }),
});

export const { useGetSubMarketsQuery, useAddSubMrketMutation, useUpdateSubMrketMutation, useDeleteSubMarketMutation } = submarketApi;
