import { Grid } from "@material-ui/core";
import FormatService from "services/formatService";
import { entityAuditStyle } from "./style";

const EntityAudit = ({ entity, labelClassName, itemWidth = 5 }) => {
    const classes = entityAuditStyle();

    const auditFields = {
        CREATED_DATE: 'createdDate',
        MODIFIED_DATE: 'modifiedDate',
        MODIFIED_BY: 'modifiedBy',
        CREATED_BY: 'createdBy'
    };

    const created = {
        [auditFields.CREATED_DATE]: {
            label: 'Created date',
            value: FormatService.formatDateTime(entity[auditFields.CREATED_DATE])
        },
        [auditFields.CREATED_BY]: {
            label: 'Created by'
        },
    };

    const modified = {
        [auditFields.MODIFIED_DATE]: {
            label: 'Modified date',
            value: FormatService.formatDateTime(entity[auditFields.MODIFIED_DATE])
        },
        [auditFields.MODIFIED_BY]: {
            label: 'By'
        }
    };

    const data = <>
        <Grid xs={itemWidth} container item spacing={1}>
            <Grid item className={labelClassName ?? classes.label}>Created</Grid>
            {!!created[auditFields.CREATED_DATE] && <Grid item>{created[auditFields.CREATED_DATE]?.value}</Grid>}
            <Grid item>{entity[auditFields.CREATED_BY]}</Grid>
        </Grid>
        {(entity[auditFields.MODIFIED_BY] || entity[auditFields.MODIFIED_BY]) && <Grid xs={itemWidth} container item spacing={1}>
            <Grid item className={labelClassName ?? classes.label}>Modified</Grid>
            {!!modified[auditFields.MODIFIED_DATE] && <Grid item>{modified[auditFields.MODIFIED_DATE]?.value}</Grid>}
            <Grid item>{entity[auditFields.MODIFIED_BY]}</Grid>
        </Grid>}
    </>;

    return (
        data
    )
}

export default EntityAudit