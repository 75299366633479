import { makeStyles } from "@material-ui/core";

export const ReservationViewStyle = makeStyles((theme) => ({
    boxTitle: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: '1em'
    },
    box: {
        padding: '2em'
    },
    summaryLabel: {

    },
    summaryField: {
        fontWeight: 'bold'
    },
    summaryId: {
    },
    summaryStatus: {
        border: '1px solid',
        padding: '0.5em',
        borderRadius: '5px',
        marginBottom: '1em',
        fontWeight: '500'
    }
}));