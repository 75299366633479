import { makeStyles } from '@material-ui/core/styles';

export const UsersTaskPageStyle = makeStyles((theme) => ({
    tabsWrapper: {
        padding: '0.5em 0 2em',
        maxHeight: '90px'
    },
    listWrapper: {
        marginTop: '2.5rem'
    }
}));
