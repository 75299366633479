import { makeStyles } from "@material-ui/core"

export const CardsStyle = makeStyles((theme) => ({
    loadBox: {
        padding: '0.5em',
        width: '95%'
    },
    cardItem: {
        display: 'flex',
        width: 220,
        paddingBottom: '2rem'
    },
    cardItemIcon: {
        display: 'flex',
        width: 100,
    },
    title: {
        fontWeight: 500,
        fontSize: 15,
        paddingTop: 10,
    },
    data:{
        fontWeight: 300,
        paddingTop: 10,
    },
    columnName: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 15,
        paddingBottom: 10,
        paddingTop: 20,
    },
    showLots: {
        width: 5,
        marginTop: '0.5rem'
    },
    lotsPopover: {
        padding: '0 1rem 1rem 1rem'
    },
    errorIcon: {
        '& path': {
            fill: 'orange'
        }
    },
    listItemIcon: {
        marginRight: '1rem',
        padding: '1px'
    },
    lists: {
        marginBottom: '1rem'
    },
    detailsWrapper: {
        marginTop: '1rem'
    }
}));
