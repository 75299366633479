import { makeStyles } from "@material-ui/core";

export const FormVersionStyles = makeStyles((theme) => ({
    versionsContainer: {
        background: 'white',
    },
    versionsList: {
        maxHeight: '70vh',
        overflowY: 'auto'
    },
    title: {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        padding: '1em',
        fontWeight: '500'
    },
    statusBadge: {
        color: 'white',
        borderRadius: '10px ',
        padding: '2px 7px',
        width: 'fit-content',
        fontSize: '0.8em'
    },
    status_1: {
        //background: '#ffc55b'
        color: 'orange',
        border: '1px solid orange'
    },
    status_2: {
        //background: '#a7e245'
        color: '#13c113',
        border: '1px solid #13c113'
    }
}))