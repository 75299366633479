import { Button, Grid, IconButton, ListItem, Paper } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useGetAllInventoryQuery } from "features/inventory/inventorySlice";
import DeleteIcon from '@material-ui/icons/Delete';
import { vehicleDetailsPageStyle } from "features/vehicles/VehicleDetailsPage/VehicleDetailsPageStyle";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";

const ActivityInventory = ({ inventoryUsed, setInventoryUsed, activityLog }) => {
    const classes = vehicleDetailsPageStyle();

    let { data: inventory, error: inventoryError, isLoading: isLoadingInventory} = useGetAllInventoryQuery();
    
    let inventoryFilterByLot = inventory?.filter((inventory) => inventory.lotId === activityLog.lotId);

    const getValues = (inventory) => {
        let inventoryLookup = inventoryUsed?.map(i => i.inventoryId);
        let dropdownValues = inventoryFilterByLot?.filter(inv => !(inventoryLookup?.includes(inv.id)) || (inv.id === inventory.inventoryId));
        return dropdownValues 
    }

    const onChangeInventory = (item, val, index) => {
        let inventoryExists = inventoryUsed.some(x => x.inventoryId === parseInt(val));
        let inventorySettingsList = [...inventoryUsed];
        let newItem = { ...item };
        if (inventoryExists === false) {
            newItem.inventoryId = parseInt(val);
        }
        inventorySettingsList[index] = {...newItem};
        setInventoryUsed(inventorySettingsList);
    }

    const onChangeUnitNumber = (item, val, index) => {
        if (!val){
            val = 0;
        }
        let inventorySettingsList = [...inventoryUsed];
        let newItem = { ...item };
        newItem.unitNumber = val;
        inventorySettingsList[index] = {...newItem};
        setInventoryUsed(inventorySettingsList);
    }

    const onDelete = (item) => {
        let inventoryArray = [...inventoryUsed];
        inventoryArray = inventoryArray.filter((inventoryUsed) => inventoryUsed.inventoryId !== item.inventoryId)
        setInventoryUsed(inventoryArray);
    }

    const onAddNewInventoryItem = () => {
        let inventorySettingsList = [...inventoryUsed];
        inventorySettingsList.push({
            'inventoryId': null,
            'unitNumber': null,
            "entityId": parseInt(activityLog?.id),
            "entityType": 1,
            "workOrderId": activityLog.workOrderId,
        });
        setInventoryUsed(inventorySettingsList);
    }

    return (
        <Paper className={classes.paper}>
        <div className={classes.title}>Inventory Used</div>
        <div>
        {
            !!inventoryUsed?.length ?
            inventoryUsed?.map((inventory, index) => {
                    return <div key={index}>
                        <ListItem className={classes.inventoryWrapper}>
                            <Grid container spacing={0} justifyContent="space-between">
                                <Grid item xs={5}>
                                    <CustomInput
                                        id="inventoryId"
                                        label="Inventory"
                                        elementType="dropdown"
                                        values={getValues(inventory) || []}
                                        value={inventory.inventoryId}
                                        required={true}
                                        showEmpty={true}
                                        onChange={val => onChangeInventory(inventory, val, index)}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <CustomInput
                                        id="unitNumber"
                                        label="Unit Number"
                                        elementType="input"
                                        value={inventory.unitNumber}
                                        required={true}
                                        onChange={val => onChangeUnitNumber(inventory, val, index)}
                                    />
                                </Grid>
                                <IconButton xs={2} aria-label="delete"
                                    onClick={() => onDelete(inventory)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </ListItem>
                    </div>
                }) :
                <EmptyPlaceholder text= "No inventory has been used yet"/>
            }
            </div>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button className={classes.addInventoryBtn} onClick={onAddNewInventoryItem} color='primary' variant="outlined">
                        Add Inventory
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ActivityInventory;