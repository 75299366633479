import { Button, Grid } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import configStyles from "../configStyles";
import LotsConfigStyle from "./LotsConfigStyle";
import {
    resetBulkUpdate,
    useMonthlyBillingReportMutation,
} from "./lotConfigSlice";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import Lots from "./components/Lots";
import Pricing from "./components/Pricing";
import VehicleStatuses from "./components/VehicleStatuses";

const useStyles = LotsConfigStyle;
const useConfigStyles = configStyles;

export const clientLotConfigModel = {
    pricePerSpot: 0,
    overage: 0,
    numberOfSpots: 0,
    clientAlias: ''
}

const LotsConfig = ({ clientToEdit, onConfigChange }) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetBulkUpdate());
        }
    }, []);

    

    const tabs = [
        {
            label: 'Pricing',
            component: <Pricing clientToEdit={clientToEdit} onConfigChange={onConfigChange} />
        },
        {
            label: 'Lots',
            component: <Lots clientToEdit={clientToEdit}  />
        },
        {
            label: 'Statuses',
            component: <VehicleStatuses clientId={clientToEdit?.id} />
        }
    ]

    return (
        <>
            <TabsWrapper tabs={tabs} />
        </>
    )
}
export default LotsConfig;
