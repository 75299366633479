import React from "react";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import PendingWashCard from "./PendingWashCard/PendingWashCard";
import { useGetTasksQuery } from "../../../Tasks/taskSlice";
import { washDashboardStyles } from "./WashDashboardStyles";
import { useGetReadinessQuery } from '../../../../features/vehicles/readiness/readinessSlice';
import WashCountCard from "./WashCountCard/WashCountCard";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import SlaChartCard from "./SlaChartCard/SlaChartCard";
import { useTitle } from "hooks/useTitle";

const WashDashboardPage = () => {
    useTitle('Wash Dashboard');

    let { data: tasks, error: tasksError, isLoading: isLoadingTasks } = useGetTasksQuery();
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isFetching: isLoadingVehiclesReadiness } = useGetReadinessQuery({});

    const { filters } = useSelector(state => state.dashboardState);

    let filteredVehicleData = vehiclesReadiness?.filter(v =>
        (!filters.lotId?.length || filters.lotId.includes(v.lotId))
        && (!filters.clientId || filters.clientId == v.clientId)
    )

    const readinessByVehicleId = vehiclesReadiness?.reduce(function (map, obj) {
        map[obj.vehicleId] = obj;
        return map;
    }, {})

    const domiciled = filteredVehicleData?.filter(v => v.domicileLot && v.domicileLot == v.lotId);
    const notDomiciled = filteredVehicleData?.filter(v => !v.domicileLot);

    const classes = washDashboardStyles();

    const isLoadingDashboardData = () => isLoadingVehiclesReadiness;

    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={4} >
                                <Paper elevation={1} className={classes.card}>
                                    <PendingWashCard washRequests={tasks?.data?.pendingTasks} vehicleLookup={readinessByVehicleId} loading={isLoadingVehiclesReadiness} />
                                </Paper>
                            </Grid>
                            <Grid item xs={4} >
                                <Paper elevation={1} className={classes.card}>
                                    <Grid className={classes.wrapper} container direction="column">
                                        <Grid item className={classes.count}>{domiciled?.length ?? 0}</Grid>
                                        <Grid item><h3 className={classes.text}>Domiciled Vehicles</h3></Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={4} >
                                <Paper elevation={1} className={classes.card}>
                                    <Grid className={classes.wrapper} container direction="column">
                                        <Grid item className={classes.count}>{notDomiciled?.filter(v => !v.domicileLot || (v.domicileLot != v.lotId))?.length ?? 0}</Grid>
                                        <Grid item><h3 className={classes.text}>Non-Domiciled Vehicles</h3></Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.card} elevation={1}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Grid container alignItems="center">
                                                <Grid item><img src="/icons/icon-inspect.svg" className={classes.icon} /></Grid>
                                                <Grid item> <h3 className={classes.subTitle}>Monthly Washes</h3></Grid>
                                            </Grid>
                                            <Grid>
                                                <WashCountCard washRequests={tasks?.data?.completedTasks} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Authorize profile={permissionProfiles.DASHBOARD.WASH_SLA}>
                                <Grid item xs={6}>
                                    <Paper className={classes.card} elevation={1}>
                                        <Grid container direction="column" className={classes.graphWrapper} >
                                            <Grid item>
                                                <Grid container>
                                                    <img src="/icons/icon-inspect.svg" className={classes.icon} />
                                                    <h3 className={classes.subTitle}>Washes Within SLA This Cycle</h3>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ flexGrow: 1 }}>
                                                <SlaChartCard vehiclesReadiness={vehiclesReadiness?.filter(v => v.status != null)} />
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </Grid>
                            </Authorize>

                        </Grid>
                    </>
            }
        </>
    );
};

export default WashDashboardPage;
