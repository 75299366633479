import { Autocomplete, TextField, createFilterOptions } from "@mui/material"
import { CreatableDropdownStyle } from "./style";
import { useState } from "react";

export const CreatableDropdown = ({ value, onChange, addValue, required, label, options, disabled }) => {
    const classes = CreatableDropdownStyle()

    const filter = createFilterOptions();

    let selectedValue = options?.find(o => o?.id === value);

    const onValueChange = async (newValue) => {
        if ((newValue && newValue.inputValue) || typeof newValue === 'string') {
            // Create a new value from the user input
            let result = await addValue({ name: newValue.inputValue });
            if (result) {
                onChange(result?.data);
            }
        } else {
            onChange(newValue?.id);
        }
    }

    return <>
        <Autocomplete
            disabled={disabled}
            value={selectedValue || null}
            onChange={(event, newValue) => {
                onValueChange(newValue)
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options?.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            autoHighlight
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="id"
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props, option) => <li {...props} key={option?.id}> <div className={classes.options}>{option.name}</div></li>}
            freeSolo
            renderInput={(params) => (
                <TextField
                    id="text"
                    value={selectedValue || null}
                    variant='outlined'
                    label={label}
                    required={required}
                    InputLabelProps={{ className: classes.label }}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        className: classes.input,
                        notchedOutline: classes.notchedOutline,
                    }}
                />
            )}
            className={classes.input}
        />
    </>

}