import { Switch } from '@material-ui/core'

export const BooleanComponent = ({ value, onChange, label }) => {

    return (
        <Switch
            color="primary"
            checked={value && JSON.parse(value)}
            onChange={(e) => onChange(JSON.stringify(e.target.checked), "values")}
        />
    )
}