import { Grid, IconButton, Paper } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CopyToClipboard from "components/CopyToClipboard";
import { IssueTrackerDateSelector } from "features/IssuesTracker/IssueTrackerDateSelector/IssueTrackerDateSelector";
import { IssueTrackerDescriptionField } from "features/IssuesTracker/IssueTrackerDescriptionField/IssueTrackerDescriptionField";
import { IssueTrackerLinks } from "features/IssuesTracker/IssueTrackerLinks/IssueTrackerLinks";
import { IssueTrackerUser } from "features/IssuesTracker/IssueTrackerUser/IssueTrackerUser";
import { LotSelector } from "features/IssuesTracker/LotSelector/LotSelector";
import { PrioritySelector } from "features/IssuesTracker/PrioritySelector/PrioritySelector";
import { StatusSelector } from "features/IssuesTracker/StatusSelector/StatusSelector";
import { LotTaskItemStyle } from "./style";


const LotTaskItem = ({
    task,
    style,
    onClose,
    clientsLookup,
    statusLookup,
    categoryLookup,
    lotsLookup,
    onOpenTask
}) => {
    const classes = LotTaskItemStyle();

    return (
        <Paper className={`${classes.wrapper} ${task.overdue ? classes.overdue : ''}`} style={style}>
            <Grid container alignItems="center">
                <Grid item xs={1} className={classes.iconContainer}>
                </Grid>
                <Grid container xs={10} spacing={1} alignItems="center">
                    <Grid item >
                        <b>{task?.title}</b>
                    </Grid>
                    <Grid item  >
                        <CopyToClipboard title={'Copy link to issue'} text={`${window.location.origin}/ticket/${task?.id}`} >
                            #{task?.id}
                        </CopyToClipboard>
                    </Grid>
                    <Grid item xs={12}>
                        <IssueTrackerDescriptionField
                            content={task?.description ?? ''}
                            readonly
                        />
                    </Grid>
                    <Grid item xs={12} container>
                        <StatusSelector readonly status={task?.status} />
                    </Grid>
                    <Grid item xs={3} container>
                        <Grid item > <LotSelector lotName={lotsLookup?.[task?.lotId]?.name} /></Grid>
                    </Grid>
                    {!!task?.linkedAssets?.length && <Grid item xs={5} container alignItems="center" >
                        <Grid item><IssueTrackerLinks issue={task} showAssets /></Grid>
                    </Grid>}
                    {!!task?.linkedWorkOrders?.length && <Grid item xs={2} container alignItems="center" >
                        <Grid item><IssueTrackerLinks issue={task} showWorkorders /></Grid>
                    </Grid>}
                    {!!task?.linkedLoads?.length && <Grid item xs={2} container alignItems="center" >
                        <Grid item><IssueTrackerLinks issue={task} showLoads /></Grid>
                    </Grid>}

                    <Grid item xs={4} container alignItems="center" >
                        <Grid item><IssueTrackerUser userId={task?.assignee} /></Grid>
                    </Grid>
                    <Grid item xs={4} container alignItems="center" >
                        <Grid item className={classes.label}>Priority</Grid>
                        <Grid item><PrioritySelector priority={task?.priority} readonly /></Grid>
                    </Grid>

                    <Grid item xs={4} container>
                        <Grid item className={classes.label}>Client</Grid>
                        {clientsLookup?.[task?.clientId]?.name}
                    </Grid>
                    <Grid item xs={12} container></Grid>
                    <Grid item xs={6} container>
                        <Grid item className={classes.label}>Issue Date</Grid>
                        <IssueTrackerDateSelector
                            issue={task}
                            field='issueDate'
                            readonly
                            date={task?.issueDate}
                        />
                    </Grid>
                    <Grid item xs={6} container>
                        <Grid item className={classes.label}>Due Date</Grid>
                        <IssueTrackerDateSelector
                            issue={task}
                            showEmpty
                            field='solutionEta'
                            readonly
                            date={task?.solutionEta}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={1} alignContent='flex-end'>
                    <IconButton onClick={() => onOpenTask(task)}>
                        <ArrowForwardIcon title='View Ticket' />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper >
    )
}
export default LotTaskItem;