import * as React from "react";

function SvgIconInspect(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.179 5.179h2.678a1.786 1.786 0 0 1 1.786 1.785v18.75a1.786 1.786 0 0 1-1.786 1.786H6.786A1.786 1.786 0 0 1 5 25.714V6.964A1.786 1.786 0 0 1 6.786 5.18h2.678v-.893A1.786 1.786 0 0 1 11.25 2.5h7.143a1.785 1.785 0 0 1 1.786 1.786v.893zm-1.786-.893H11.25v3.571h7.143V4.286zM6.786 25.714h16.071V6.964H20.18v2.679H9.464V6.964H6.786v18.75zm13.393-13.393H9.464v1.786H20.18v-1.786zm-3.572 4.465H9.464v1.785h7.143v-1.785zM9.464 21.25h4.465v1.786H9.464V21.25z"
                fill="#0090FE" />
        </svg>
    );
}

export default SvgIconInspect;
