import { makeStyles } from "@material-ui/core"

export const dateRangeStyle = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        height: '100%',
        width: '100%'
    },
    dateRange: {
        backgroundColor: theme.palette.background.default,
        '& fieldset': {
            border: 'none'
        },

    },
    rangeSelected: {
        backgroundColor: theme.palette.background.default,

    },
    rangeOpt: {
        padding: '1em',
        width: '100%',
        height: '100%'
    },
    dateRangePicker: {
        '& .rdrInputRanges': {
            display: 'none !important'
        },
        '& .rdrStaticRangeLabel': {
            padding: '0'
        },
        display: 'flex'
    },
    input: {
        border: 0,
        color: theme.palette.common.darkText,
    },
    label: {
        color: theme.palette.text.secondary,

    },
    boxCalendar: {
        position: 'fixed',
        background: '#fff',
        border: "solid 1px #aab5bd",
        borderRadius: '5px',
        zIndex: 2,
        marginTop: '3.5rem',
    },
    closeAndClear: {
        textAlign: 'center',
        padding: '0.5rem',
    },
    close: {
        cursor: 'pointer',
        border: "1px solid #aab5bd",
        borderRadius: '5px',
        background: '#f7f6fb',
        textAlign: 'center',
        padding: '0.3rem',
        margin: '0.5rem',
        '&:hover': {
            opacity: 0.6
        }
    }

}));