import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from "react";
import { invoiceDetailsStyle } from "./InvoiceDetailsStyle";

export const EditWrapper = ({
    readonlyComponent,
    editComponent,
    onSave,
    onCancel,
    onDelete,
    editMode = false,
    loading
}) => {

    const classes = invoiceDetailsStyle();
    const [edit, setEdit] = useState(editMode);

    return (
        <Grid container className={classes.fieldWrapper}>
            <Grid item xs={11} className={classes.field} >
                {edit ? editComponent : readonlyComponent}
                <div className={classes.icons} >
                    {!edit && editComponent &&
                        <IconButton size='small' onClick={() => setEdit(true)} >
                            <EditIcon />
                        </IconButton>
                    }
                    {!edit && onDelete && <IconButton disabled={loading} size='small' onClick={async () => {
                        onDelete && onDelete();
                    }} ><DeleteIcon /></IconButton>}
                </div>
            </Grid>
            {edit &&
                <Grid item xs={1} >
                    <IconButton disabled={loading} size='small' onClick={async () => {
                        await onSave();
                        setEdit(false);
                    }} >{loading ? <CircularProgress size={'1em'} /> : <CheckIcon />}</IconButton>
                    <IconButton disabled={loading} size='small' onClick={async () => {
                        onCancel && onCancel();
                        setEdit(false);
                    }} ><CloseIcon /></IconButton>

                </Grid>
            }
        </Grid>
    )
}