import { Grid } from "@material-ui/core";
import { LotRankingStyle } from "./LotRankingStyles";


const LotRanking = ({ lotRanking }) => {

    const classes = LotRankingStyle();

    const roundPoint = (point) => {
        return Math.floor(point * 100) / 100;
    }

    return (
        <div className={classes.lotRankingWrapper}>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                    <div className={classes.value}>{lotRanking?.siteSizeName}</div>
                    <div className={classes.name}>Lot Size</div>
                    <div className={classes.value}>{lotRanking?.totalHours}</div>
                    <div className={classes.name}>Pay Hours</div>
                    <div className={classes.value}>{lotRanking?.totalHoursOverThreshold}</div>
                    <div className={classes.name}>Hours {'>'} TH</div>
                    <div className={classes.value}>{roundPoint(lotRanking?.inspectionsMissed)}</div>
                    <div className={classes.name}>Insp. Missed</div>
                </Grid>
                <Grid item >
                    <div className={classes.value}>{roundPoint(lotRanking?.auditPoints)}</div>
                    <div className={classes.name}>Audit</div>
                    <div className={classes.value}>{roundPoint(lotRanking?.washSlaPercentage)}%</div>
                    <div className={classes.name}>Washes</div>
                    <div className={classes.value}>{roundPoint(lotRanking?.lotTasks)}%</div>
                    <div className={classes.name}>Tasks</div>
                </Grid>
            </Grid>

        </div>
    )
}

export default LotRanking;