import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Image } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomInput from "../../../../../src/components/CustomInput";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import ConfirmationPopup from "../../../../components/ConfirmationPopup";
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import { FadeInImageCustomStyle } from "./FadeInImageCustomStyle";
import NotesTooltip from "../../../notes/NotesTooltip";
import { useGetEntityNotesQuery } from "features/notes/notesSlice";
import { Grid, Link, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const FadeInImageCustom = ({
    img, alt, readonly = false,
    showFileName = false, isDocument = false,
    onDelete, onEdit = null,
    imageTypes = null,
    changeImageTypeId = null,
    existingImages = null,
    imgHeight,
    imgWidth,
    noteType }) => {
    const classes = FadeInImageCustomStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const [loaded, setOnLoaded] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [imageTypeId, setImageTypeId] = useState(img?.imageTypeId || 0);
    let { data: notesFromApi, error: notesError, isFetching: isLoadingNotes } = useGetEntityNotesQuery({ entityId: img?.id, entityType: noteType }, { skip: !img });

    notesFromApi = notesFromApi ?? [];

    const dispatch = useDispatch();

    const onLoad = () => setOnLoaded(true);

    const openModalConfirmDelete = () => {
        setIsEditing(false);
        setOpenPopup(true);
    }

    const openModalConfirmEdit = () => {
        setIsEditing(true);
        setOpenPopup(true);
    }

    const onSelectImageType = (index, val) => {
        let imageTypeEntity = imageTypes.find(x => x.id == val);
        let imageEntity = existingImages.find(x => x.id == index);
        let image =
        {
            id: imageEntity?.id,
            url: imageEntity?.url,
            imageTypeId: val,
            fileName: imageTypeEntity?.label
        };
        changeImageTypeId(image);
        setImageTypeId(val);
    }

    const onCancelEvent = () => {
        setOpenPopup(false);
    }

    const isDoc = img?.type === 'pdf' || (img?.url ? img?.url?.endsWith("pdf") : img?.fileUrl?.endsWith("pdf"));

    return (
        <div className={`${classes.imageContainer} ${isMobile && classes.imageContainerMobile}`}>
            <div item className={classes.linkContent}>
                <Link
                    className={classes.openInNewTab}
                    href={img?.url ?? img?.fileUrl} target="_blank" color="primary" >
                    <Tooltip title='Open in new tab'><OpenInNewIcon /></Tooltip>
                </Link>
            </div>
            <div className={classes.placeholder}>
                {!isDoc && <Image color="secondary" className={classes.placeholderIcon} />}
                {/* <PictureAsPdfOutlinedIcon color="secondary" className={classes.placeholderIcon} /> */}
                {isDoc &&
                    <embed src={img?.url ?? img?.fileUrl} style={{ width: '100%', height:'100%' }} />

                }
            </div>
            <a href={img?.url ?? img?.fileUrl} target="_blank">
                <img
                    className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                    src={img?.thumbnail || img?.fileUrl || img?.url}
                    alt={alt}
                    onLoad={onLoad}
                />
                {(showFileName || (img?.fileName && img?.url.endsWith("pdf"))) &&
                    <div className={classes.fileName}>{img?.fileName}</div>
                }
            </a>


            <Grid className={classes.buttomsContainer} alignItems="center">

                {(img?.id && !isDoc && !readonly) && <>

                    {onEdit &&
                        <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                            <IconButton
                                title={'Edit image'}
                                edge='end'
                                aria-label='edit'
                                className={classes.imageDeleteIcon}
                                onClick={openModalConfirmEdit}>
                                <EditIcon />
                            </IconButton>
                        </Authorize>
                    }
                    {onDelete &&
                        <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                            <IconButton
                                title={'Delete image'}
                                edge='end'
                                aria-label='delete'
                                className={{ ...classes.imageDeleteIcon }}
                                onClick={openModalConfirmDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Authorize>
                    }
                    <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                        <NotesTooltip entityId={img?.id} entityType={noteType} />
                    </Authorize>
                    <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                        {
                            notesFromApi?.length > 0 && (
                                <div className={classes.lastNoteContent}>
                                    <div>Last Note Content: {notesFromApi[0]?.content}</div>
                                </div>
                            )
                        }
                    </Authorize>
                </>}

            </Grid>
            <ConfirmationPopup
                title={!isEditing ? 'Delete Image' : "Edit Image"}
                onCancel={() => onCancelEvent()}
                onConfirm={async () => {
                    setProcessing(true);
                    if (!isEditing) {
                        await onDelete(img, isDocument);
                    }
                    else if (isEditing) {
                        onEdit && (await onEdit(img, isDocument));
                    }
                    setProcessing(false);
                    setOpenPopup(false);
                }}
                open={openPopup}
                loading={processing}
            >
                {
                    !isEditing ?
                        <div>
                            <div>{'Are you sure you want to delete this file?'}</div>
                            <div className={classes.imagePreviewWrapper}>
                                <img
                                    className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                                    src={img?.url ?? img?.fileUrl}
                                    alt={alt}
                                    onLoad={onLoad}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <div>{"Are you sure you want to change the type for this image?"}</div>
                            <div className={classes.imagePreviewWrapperEditting}>
                                <img
                                    className={`${classes.imageEditing} ${loaded ? classes.imageVisible : ""}`}
                                    src={img?.url ?? img?.fileUrl}
                                    alt={alt}
                                    onLoad={onLoad}
                                />
                                {
                                    (imageTypes && !img.isExternal) &&
                                    <div style={{ marginTop: 10 }}>
                                        <CustomInput
                                            id={imageTypeId}
                                            label="Image type"
                                            value={imageTypeId}
                                            elementType="dropdown"
                                            onChange={val => onSelectImageType(img.id, +val)}
                                            values={imageTypes}
                                            showEmpty={true}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                }

            </ConfirmationPopup>
        </div>
    );
};

export default FadeInImageCustom;
