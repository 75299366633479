import * as React from "react";

function SvgIconRedo(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M19.6817 21.7367H10.3483C6.92616 21.7367 4.14001 18.9505 4.14001 15.5283C4.14001 12.1061 6.92616 9.32 10.3483 9.32H23.1817C23.3839 9.32 23.5567 9.49281 23.5567 9.695C23.5567 9.89719 23.3839 10.07 23.1817 10.07H10.3483C7.34221 10.07 4.89001 12.5222 4.89001 15.5283C4.89001 18.5345 7.34221 20.9867 10.3483 20.9867H19.6817C19.8839 20.9867 20.0567 21.1595 20.0567 21.3617C20.0567 21.5682 19.8912 21.7367 19.6817 21.7367Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M22.6019 9.97855L22.9554 9.625L22.6019 9.27145L20.2336 6.90311C20.0905 6.76004 20.0905 6.51663 20.2336 6.37355C20.3766 6.23048 20.62 6.23048 20.7631 6.37355L23.7498 9.36022C23.8929 9.50329 23.8929 9.74671 23.7498 9.88978L20.7631 12.8764C20.6868 12.9527 20.5945 12.9867 20.4983 12.9867C20.4021 12.9867 20.3098 12.9527 20.2336 12.8764C20.0905 12.7334 20.0905 12.49 20.2336 12.3469L22.6019 9.97855Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconRedo;
