import { createSlice } from '@reduxjs/toolkit';
import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const basePath = 'billing';

export const billingApi = baseApi.injectEndpoints({
    reducerPath: 'billingApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getBillingSummary: builder.query({
            query: ({ clientId }) => `${basePath}?clientId=${clientId}`,
        })
    })
});

export const {
    useGetBillingSummaryQuery,
} = billingApi;



export const billingDashboardSlice = createSlice({
    name: 'billingDashboardSlice',
    initialState: {
        filters: {
            lotId: { value: [] },
            clientId: { value: '' },
            program: { value: '' },
            date: { value: null }
        },
        showCost: true,
        showCharge: true
    },
    reducers: {
        setBillingFilters: (state, action) => {
            state.filters = action.payload;
        },
        setShowAll: (state, action) => {
            state.showCost = true;
            state.showCharge = true;
        },
        setShowCost: (state, action) => {
            state.showCost = true;
            state.showCharge = false;
        },
        setShowCharge: (state, action) => {
            state.showCost = false;
            state.showCharge = true;
        },
    },
});

export const { setBillingFilters, setShowCharge, setShowCost, setShowAll } = billingDashboardSlice.actions;