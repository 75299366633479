import { Grid, Paper } from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { orderBy } from "lodash";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { AddSectionButton } from "./AddSectionButton";
import { ReportSection } from "./ReportSection/ReportSection";
import { ReportSectionStyles } from "./ReportSectionStyles";
import SectionActions from "./ReportSection/SectionActions";

const ReportSections = ({
    report, onAddSection, onReportFieldChange,
}) => {

    const classes = ReportSectionStyles();

    let sectionsSorted = orderBy(report?.sections ?? [], 'order');
    let questionsUsed = sectionsSorted?.flatMap(s => s.columns.flatMap(c => c.elements)).map(e => parseInt(e.value));
    
    const onSectionChange = (sectionIndex, columnIndex, value) => {
        let columns = [...sectionsSorted[sectionIndex].columns]
        columns[columnIndex] = { ...columns[columnIndex], elements: value };
        sectionsSorted[sectionIndex] = { ...sectionsSorted[sectionIndex], columns: columns };
        onReportFieldChange('sections', sectionsSorted);
    }

    const onAddColumn = (sectionIndex, columnIndex) => {
        let columns = [...sectionsSorted[sectionIndex].columns]
        columns.splice(columnIndex, 0,
            {
                width: 0,
                elements: []
            });
        sectionsSorted[sectionIndex] = { ...sectionsSorted[sectionIndex], columns: columns };
        onReportFieldChange('sections', sectionsSorted);
    }

    const onDeleteColumn = (sectionIndex, columnIndex) => {
        let columns = [...sectionsSorted[sectionIndex].columns]
        columns.splice(columnIndex, 1);
        sectionsSorted[sectionIndex] = { ...sectionsSorted[sectionIndex], columns: columns };
        onReportFieldChange('sections', sectionsSorted);
    }

    const onDuplicateSection = (sectionIndex) => {
        const section = sectionsSorted[sectionIndex];
       
        let columns = section.columns.map(column => ({
            ...column,
            elements: column.elements.map(element => ({
                ...element,
                value: ''
            }))
        }));
        
        let toAdd = {...section, columns: columns}
        const sectionToAdd = { ...toAdd, id: undefined };
        let sections = [...sectionsSorted, sectionToAdd]
        onReportFieldChange('sections', sections.map((q, index) => ({ ...q, order: index })))
    }

    const onDeleteSection = (sectionIndex) => {
        const sections = [...sectionsSorted];
        sections.splice(sectionIndex, 1);
        onReportFieldChange('sections', sections.map((q, index) => ({ ...q, order: index })))
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        onReportFieldChange('sections', arrayMove(sectionsSorted, oldIndex, newIndex).map((q, index) => ({ ...q, order: index })))
    };

    return (
        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {sectionsSorted.map((section, index) => (
                <>
                    <AddSectionButton onAddSection={onAddSection} index={index} />
                    <SortableItem
                        index={index}
                        section={section}
                        sectionIndex={index}
                        onSectionChange={onSectionChange}
                        onDeleteSection={() => onDeleteSection(index)}
                        onDuplicateSection={onDuplicateSection}
                        onAddColumn={onAddColumn}
                        onDeleteColumn={onDeleteColumn}
                        questionsUsed={questionsUsed}
                    />
                </>
            ))}
            <AddSectionButton onAddSection={onAddSection} index={sectionsSorted.length} />
        </SortableContainer>
    );
}
export default ReportSections;

const SortableItem = sortableElement(({ section,
    sectionIndex,
    onSectionChange,
    onDeleteSection,
    onDuplicateSection,
    onAddColumn,
    onDeleteColumn,
    questionsUsed
}) => {
    const classes = ReportSectionStyles();

    const DragHandle = sortableHandle(() => <div className={classes.dragHandler}>
        <DragIndicatorIcon className={classes.dragIconQuestion} color="secondary" />
    </div>);

    return <Grid item >
        <Paper className={classes.sectionWrapper}>
            <DragHandle />
            <SectionActions
                onDeleteSection={() => onDeleteSection(sectionIndex)}
                onDuplicateSection={() => onDuplicateSection(sectionIndex)}
            />
            <ReportSection
                section={section}
                onSectionChange={onSectionChange}
                sectionIndex={sectionIndex}
                onAddColumn={onAddColumn}
                onDeleteColumn={onDeleteColumn}
                questionsUsed={questionsUsed}
            />
        </Paper>
    </Grid>
});

const SortableContainer = sortableContainer(({ children }) => {
    const classes = ReportSectionStyles();
    return <Grid container direction="column" spacing={2} className={classes.questionsContainer}>
        {children}
    </Grid>;
});
