import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    input: {
        margin: 16,
    },
    wrapper: {
        marginTop: 20
    }
}));
