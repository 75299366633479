import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import React from 'react';
import LotSubMarkets from '../LotSubMarketsPage/lotSubMarkets';
import LotsPage from "../LotsPage/LotsPage";

const tabs = <TabsWrapper tabs={[
    {
        label: 'Lots',
        component: <LotsPage />
    },
    {
        label: 'Sub Markets',
        component: <LotSubMarkets />
    }
]} />

const LotsTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {tabs}
        </>
    )
}

export default LotsTabs;
