import { Button, Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { IconDirection } from "icons";
import { lotDispatchDetailsStyle } from "../style";

const LotDirections = ({
    lot, onEdit
}) => {

    const classes = lotDispatchDetailsStyle();

    return (
        <Grid direction='column' spacing={1}>
            <Paper className={classes.detailCard}>
                <div className={classes.cardHeader}>
                    <div className={classes.cardHeaderTitle}>
                        <IconDirection className={classes.titleIcon} />
                        <div className={classes.cardTitle}>Driving Directions</div>
                    </div>
                    <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                        {onEdit && <Button variant="outlined" onClick={() => onEdit(lot)} className={classes.editButton}>Edit</Button>}
                    </Authorize>
                </div>
                {lot?.drivingDirections ?
                    <div className={classes.directionsText} dangerouslySetInnerHTML={{ __html: lot?.drivingDirections }} /> :
                    <EmptyPlaceholder text="No Directions" />
                }
            </Paper>
        </Grid>
    )
}
export default LotDirections;
