
function SvgIconUpdate(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5467 16.2004L11.5469 16.2003L11.5415 16.1876C11.5128 16.1187 11.5 16.054 11.5 15.9999C11.5 15.9457 11.5128 15.881 11.5415 15.8122C11.566 15.7535 11.5996 15.6977 11.6444 15.6443C11.6978 15.5995 11.7536 15.5659 11.8123 15.5414C11.9292 15.4927 12.0708 15.4927 12.1877 15.5414C12.2464 15.5659 12.3022 15.5995 12.3556 15.6443C12.4004 15.6977 12.434 15.7535 12.4585 15.8122C12.4872 15.881 12.5 15.9457 12.5 15.9999C12.5 16.054 12.4872 16.1187 12.4585 16.1876L12.4583 16.1875L12.4533 16.2004C12.4336 16.2516 12.4069 16.2961 12.3565 16.3548C12.3028 16.3999 12.2467 16.4337 12.1877 16.4583C12.1188 16.487 12.0541 16.4999 12 16.4999C11.9459 16.4999 11.8812 16.487 11.8123 16.4583C11.7533 16.4337 11.6972 16.3999 11.6435 16.3548C11.5931 16.2961 11.5664 16.2516 11.5467 16.2004ZM12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25ZM12 13.25C11.8661 13.25 11.75 13.1339 11.75 13V8C11.75 7.86614 11.8661 7.75 12 7.75C12.1339 7.75 12.25 7.86614 12.25 8V13C12.25 13.1339 12.1339 13.25 12 13.25Z" fill="black" stroke="black" />
    </svg>
  );
}

export default SvgIconUpdate;
