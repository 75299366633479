import { Button, CircularProgress, Collapse, Grid, IconButton, Paper } from "@material-ui/core";
import { useState } from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MobileActivityStyle } from "./style";
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import { useGetMobilePingsQuery } from "./mobileActivitySlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import FormatService from "services/formatService";
import CachedIcon from '@material-ui/icons/Cached';
import { groupBy } from "lodash";


export const LastPingPerLot = ({ }) => {
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: mobileActivityData, error: activityError, isFetching: isLoadingActvity, refetch } = useGetMobilePingsQuery(null, { pollingInterval: 600000 });//ping API every 10 min

    ///const pingsGroupedByLot = groupBy(mobileActivityData ?? [], 'lotId');
    const classes = MobileActivityStyle();
    const [collapsed, setCollapsed] = useState(false);
    const [spin, setSpin] = useState(false);

    const latestPings = mobileActivityData?.latestPings;

    const lotStatsLookup = mobileActivityData?.stats?.reduce(function (map, obj) {
        map[obj.lotId] = obj;
        return map;
    }, {});


    const reloadData = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
        refetch();
        return false;
    }
    return (<Paper className={classes.latestPing}>
        <Grid container justifyContent="space-between" alignItems="center">
            <h3>
                <Grid container alignItems="center">
                    <div>
                        <Grid alignItems="center" container onClick={() => setCollapsed(!collapsed)}>
                            <WifiTetheringIcon className={classes.icon} />
                            <div className={classes.pingTitle}>Latest Lot Pings</div>
                        </Grid>
                    </div>
                    <IconButton onClick={reloadData} size={"small"} variant="outlined" >
                        <CachedIcon
                            className={`${classes.icon} ${spin ? classes.spin : classes.refresh}`}
                            spin={spin} />
                    </IconButton>
                    {isLoadingActvity && <CircularProgress size={'1em'} />}
                </Grid>
            </h3>
            <div onClick={() => setCollapsed(!collapsed)}>
                <Grid container alignItems="center">
                    <IconButton><KeyboardArrowDownIcon className={classes.icon} /></IconButton>
                </Grid>
            </div>
        </Grid>
        <Collapse in={collapsed} timeout="auto" unmountOnExit >
            <Grid container direction='column' className={classes.lots} spacing={1}>
                <Grid item container className={classes.pingTitle}>
                    <Grid item xs={2}>Lot</Grid>
                    <Grid item xs={2}>Last Ping</Grid>
                    <Grid item xs={2}>Avg. Sync Time</Grid>
                    <Grid item xs={2}>Standard Deviation</Grid>
                </Grid>
                {latestPings?.map((pingForLot, index) =>
                    <Grid item container key={index}>
                        <Grid item xs={2}><b>{lots?.find(l => l.id == pingForLot?.lotId)?.name}</b></Grid>
                        <Grid item xs={2}>{FormatService.formatDateTimeNoConversion(pingForLot?.createDate)} UTC</Grid>
                        <Grid item xs={2}>{lotStatsLookup?.[pingForLot?.lotId]?.avgSyncTime ? `${lotStatsLookup?.[pingForLot?.lotId]?.avgSyncTime} min` : ''}</Grid>
                        <Grid item xs={2}>{lotStatsLookup?.[pingForLot?.lotId]?.standardDeviation ? `${lotStatsLookup?.[pingForLot?.lotId]?.standardDeviation}` : ''}</Grid>
                    </Grid>
                )}
            </Grid>
        </Collapse>
    </Paper>)
}
