import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const invoicePermissionProfile = {
    INVOICES_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    INVOICES_MANAGE: {
        [modes.EDIT_PERMISSION]: [permissions.STORAGE_INVOICES.MANAGE],
    },
}