import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'lotAudit';
export const lotAuditApi = baseApi.injectEndpoints({
    reducerPath: 'lotAuditApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.LOT_AUDIT],
    endpoints: (builder) => ({
        getAuditsByUser: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.LOT_AUDIT]
        }),
        uploadAudit: builder.mutation({
            query: form => ({
                url: `${apiPrefix}/upload`,
                method: "POST",
                body: form
            }),
            invalidatesTags: [apiTags.LOT_AUDIT, apiTags.LOT_SCORES]
        }),
        editAudit: builder.mutation({
            query: form => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: form
            }),
            invalidatesTags: [apiTags.LOT_AUDIT]
        }),
        deleteAudit: builder.mutation({
            query: id => ({
                url: `${apiPrefix}`,
                method: "DELETE",
                body: { id }
            }),
            invalidatesTags: [apiTags.LOT_AUDIT, apiTags.LOT_SCORES]
        }),
        runAudit: builder.mutation({
            query: form => ({
                url: `${apiPrefix}/run`,
                method: "POST",
                body: form
            }),
            invalidatesTags: [apiTags.AUDIT]
        }),
        getHistory: builder.query({
            query: ({auditId}) => `${apiPrefix}/history?auditId=${auditId}`,
            providesTags: [apiTags.AUDIT]
        }),
        getHistoryById: builder.query({
            query: (id) => `${apiPrefix}/history/${id}`,
        })
    })

});

export const {
    useGetAuditsByUserQuery,
    useUploadAuditMutation,
    useDeleteAuditMutation,
    useRunAuditMutation,
    useEditAuditMutation,
    useGetHistoryQuery,
    useGetHistoryByIdQuery
} = lotAuditApi;

