import { makeStyles } from "@material-ui/core";

export const editParamStyle = makeStyles(theme => ({
    on: {

    },
    onSelected: {
        background: '#42d17f !important',
        color: 'white !important'
    },
    off: {

    },
    offSelected: {
        background: 'orange !important',
        color: 'white !important'
    }
}));

