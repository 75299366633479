import { arrayToObject } from "utils/object-util";
import FormatService from "./formatService";

const matchDatesRange = (propValue, filterValue, specialBehavior) => {
    if (specialBehavior) {
        return true;
    }

    filterValue?.startDate?.setHours(0, 0, 0, 0);
    filterValue?.endDate?.setHours(0, 0, 0, 0);

    if (Array.isArray(propValue) && propValue.length > 1) {
        let propValueEdited = propValue.map(p => (new Date(p)).setHours(0, 0, 0, 0))
        return propValueEdited.some(p => p >= filterValue.startDate?.getTime()
            && p <= filterValue.endDate?.getTime());
    }
    else {
        let propDate = new Date(propValue)
        propDate.setHours(0, 0, 0, 0);
        return (filterValue.startDate == null && filterValue.endDate == null) || (propDate.getTime() >= filterValue.startDate?.getTime()
            && propDate.getTime() <= filterValue.endDate?.getTime());
    }
};

const isMatchArrayFilter = (propValue, filterValue, specialBehavior) => {
    const isFilterReallyAnArray = Array.isArray(filterValue);
    const isPropAnArray = Array.isArray(propValue);
    if (isPropAnArray) {
        propValue = propValue?.map(p => p.toString());
        return (isFilterReallyAnArray ? (!filterValue?.length || filterValue.some(f => propValue.includes(f?.toString() ?? ""))) : (!filterValue || propValue.includes(filterValue?.toString() ?? "")));
    } else {
        return (isFilterReallyAnArray ? (!filterValue?.length || filterValue.includes(propValue)) : (!filterValue || filterValue === propValue));
    }
}

const isMatchArrayObject = (propValue, filterValue, specialBehavior) => {
    if (specialBehavior) {
        return specialBehavior(propValue, filterValue);
    }
    return false;
};

const isMatchArrayValue = (propValue, filterValue, specialBehavior) => {
    if (Array.isArray(filterValue)) {
        if (filterValue?.length > 0) {
            propValue = propValue?.map(p => p.toString());
            return Array.isArray(propValue) && filterValue.some(value => propValue.includes(value?.toString() ?? ""));
        } else
            return true; //return all data when empty array
    } else {
        propValue = propValue?.map(p => p.toString());
        return !filterValue || (Array.isArray(propValue) && propValue?.includes(filterValue.toString()));
    }
}

const isMatch = (propValue, filterValue, specialBehavior, isSearch) => {
    if (specialBehavior) {
        return true;
    }

    if (!filterValue || !propValue === null || typeof propValue === "undefined") {
        return false;
    }

    if (Array.isArray(filterValue)) {
        return isMatchArrayFilter(propValue, filterValue, specialBehavior);
    }

    if (!isSearch && Array.isArray(propValue)) {
        return isMatchArrayValue(propValue, filterValue, specialBehavior);
    }

    if (typeof propValue === "number") {
        return propValue === +filterValue;
    }

    if (typeof propValue === "boolean" || typeof propValue === "object") {
        return false;
    }

    let match = propValue?.toLowerCase().includes(filterValue?.toLowerCase());
    return match;
};

export const fieldTypes = {
    'TEXT': 'text',
    'FREETEXT': 'freetext',
    'DATE': 'date',
    'DATERANGE': 'dateRange',
    'LIST': 'list',//Multiple filter
    'VALUE_LIST': 'valueList',//Multiple property value
    'VALUE_LIST_OBJECT': 'valueListObject',//Multiple property value
    'LOT': 'lot',
    'ASSOCIATED_LOT': 'AssociatedLot',
    'COMPONENT': 'component',
    'ASSET_STATUS': 'assetStatus',
    'VEHICLE_TYPE': 'vehicleType',
    'ACTIVITY_TYPE': 'activityType',
};

const typeToMatchFuntion = {
    [fieldTypes.TEXT]: isMatch,
    [fieldTypes.FREETEXT]: isMatch,
    [fieldTypes.DATE]: isMatch,
    [fieldTypes.DATERANGE]: matchDatesRange,
    [fieldTypes.LIST]: isMatchArrayFilter,
    [fieldTypes.VALUE_LIST]: isMatchArrayValue,
    [fieldTypes.VALUE_LIST_OBJECT]: isMatchArrayObject,
    [fieldTypes.LOT]: isMatch,
    [fieldTypes.ASSET_STATUS]: isMatchArrayFilter,
    [fieldTypes.VEHICLE_TYPE]: isMatchArrayFilter,
    [fieldTypes.ACTIVITY_TYPE]: isMatchArrayFilter,
};

const filterData = (prop, value, data, type, specialBehaviorHdl, applySpecialBehavior) => {

    if (!value) {
        return data;
    }

    let specialBehavior = applySpecialBehavior ? specialBehaviorHdl : specialBehaviorHdl && specialBehaviorHdl(prop, value);

    if (specialBehavior && !applySpecialBehavior) {
        return data;
    }

    //Check if the selected filters match the data
    //If the proprty does not exist on the object we assume it's an older filter and we just ignore it -> the row will return from the filtering
    data = data.filter(obj => !(prop in obj) || (type && typeToMatchFuntion[type] ? typeToMatchFuntion[type](obj[prop], value, specialBehavior) : isMatch(obj[prop], value, specialBehavior)));

    return data;
};

const getFilterDisplay = (filters, filterConfig) => {

    const getName = (config, value) => {
        return configMap?.[config?.key]?.values ? configMap[config?.key]?.values?.find(c => c.id == value)?.name : value
    }

    const getLabel = (config, value) => {
        let values = configMap?.[config?.key]?.values;
        let status = values?.find(c => c.id == value);
        return status?.alias ? status?.label : status?.name;
    }

    const getTooltipContent = (config, filterValue) => {
        let tooltipContent = "";

        if (config?.type === fieldTypes.ASSET_STATUS || config?.type === fieldTypes.VEHICLE_TYPE || config?.type === fieldTypes.ACTIVITY_TYPE) {
            if (Array.isArray(filterValue)) {
                tooltipContent = filterValue?.map(v => getLabel(config, v)).join(", ");
            } else {
                tooltipContent = getLabel(config, filterValue);
            }
        }
        return tooltipContent;
    }

    const getValue = (config, filterValue) => {
        let value = "";

        if (Array.isArray(filterValue)) {
            value = filterValue?.map(v => getName(config, v)).join(", ");
        } else {
            value = getName(config, filterValue);;
        }

        if (config?.type) {
            switch (config.type) {
                case fieldTypes.DATERANGE:
                    value = filterValue?.startDate && filterValue?.endDate ? `${FormatService.formatDate(filterValue?.startDate)} - ${FormatService.formatDate(filterValue?.endDate)}` : ''
                    break;
            }
        }
        return value;
    }

    const configMap = arrayToObject(filterConfig, 'key');

    const f = Object.keys(filters).map(filterKey => ({
        key: filterKey,
        label: configMap[filterKey]?.title,
        value: getValue(configMap[filterKey], filters[filterKey]?.value),
        tooltipContent: getTooltipContent(configMap[filterKey], filters[filterKey]?.value)
    })).filter(f => f.value);

    return f;
}

const FilterService = {
    fieldTypes,
    getFilterDisplay,
    filter: (filters, searchVal, data, filterConfigTypes) => {
        const filterProps = Object.keys(filters)
            .filter(prop => filters[prop].value);

        let result = [...(data ?? [])];

        if (searchVal) {
            result = result.filter(v => {
                for (let prop of Object.keys(v)) {
                    if (isMatch(v[prop], searchVal, null, true)) {
                        return true;
                    }
                }

                return false;
            });
        }

        for (let prop of filterProps) {
            let filterConf = filterConfigTypes?.find(t => t.key === prop);
            let type = filterConf?.type;
            let specialBehaviorHdl = filterConf?.specialBehaviorHdl;
            let applySpecialBehavior = filterConf?.applySpecialBehavior ?? false;
            let valDefinition = filterConf?.values?.find(v => v.id == filters[prop].value);
            let fieldName = valDefinition?.filterOn ?? prop;
            let value = valDefinition?.filterOnValue ?? filters[prop].value;
            result = filterData(fieldName, value, result, type, specialBehaviorHdl, applySpecialBehavior);
        }
        return result;
    }
};

export default FilterService;
