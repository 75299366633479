import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useGetLotsQuery } from "features/lots/lotSlice";
import { IconLot } from "icons";
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { lotSummaryStyle } from "./style";
import { useGetClientsQuery } from "features/clients/clientSlice";
import LotStats from './LotStats/LotStats';
import { useState } from 'react';
import SummaryViewToggle from './SummaryViewToggle';
import LotDispatchDetails from './LotDispatchDetails/LotDispatchDetails';

const LotSummary = ({
    lotId, onEdit, showLotTitle = true
}) => {

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    clients = clients || [];

    const params = useParams();
    const correctLotId = params.id ?? lotId;

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();

    const lot = lots?.find(l => l.id == correctLotId);

    const isLoading = () => isLoadingLots;

    const classes = lotSummaryStyle();

    const [summaryView, setSummaryView] = useState('stats');

    const handleViewChange = (e, newView) => {
        setSummaryView(newView);
    }


    return (
        <>
            <LoadingSpinner loading={isLoading()} />
            {showLotTitle && <>
                <div className={classes.title}><IconLot height='27' width='27' />
                    <div>{lot?.name}</div>
                </div>
                <div className={classes.address}>{lot?.addressLine1} {lot?.city} {lot?.state} {lot?.zipCode}</div>
            </>}
            <div className={classes.viewToggle}>
                <SummaryViewToggle
                    summaryView={summaryView}
                    handleViewChange={handleViewChange}
                />
            </div>
            {summaryView == 'details' ?
                <LotDispatchDetails lot={lot} lotId={correctLotId} onEdit={onEdit}/> :
                <LotStats lot={lot} lotId={correctLotId}/>
            }
        </>
    )
}
export default LotSummary;
