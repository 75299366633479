import React from 'react';
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min';

const PromptUnsavedChanges = ({
    hasUnsavedChanges
}) => {

    // window.addEventListener('beforeunload',
    //     function (e) {

    //         // Check if any of the input
    //         // fields are filled
    //         if (hasUnsavedChanges) {

    //             // Cancel the event and show alert that the unsaved changes would be lost
    //             e.preventDefault();
    //             e.returnValue = '';
    //         }
    //     });

    return (
        <Prompt
            when={hasUnsavedChanges}
            message='You have unsaved changes, are you sure you want to leave?'
        />
    );
};

export default PromptUnsavedChanges
