import { Grid } from "@material-ui/core"
import Authorize from "components/Authorize"
import { permissionProfiles } from "components/Authorize/permissionProfiles"
import PageComponent from "components/Page/PageComponent"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { IssueTrackerDashboard } from "../IssueTrackerDashboard/IssueTrackerDashboard"
import IssueTrackerKanban from "../IssueTrackerKanban/IssueTrackerKanban"
import IssuesTrackerList from "../IssuesTrackerList"
import { setFilters } from "../issuesTrackerFiltersSlice"
import { IssueTrackerStyles } from "../style"

export const WorkflowPage = ({

}) => {
    const classes = IssueTrackerStyles();
    const params = useParams();
    const view = params.view ?? 'list';

    const { searchVal, filters } = useSelector(state => state.issuesTrackerFilters);

    const dispatch = useDispatch();

    const populateMultipleFilters = (filterValues) => {
        dispatch(setFilters({ ...filterValues }));
    };
    const history = useHistory();

    return (
        <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW}>
            <PageComponent>
                <Grid container direction="row" spacing={2} className={classes.dashboardWrapper}>
                    {view == 'dashboard' && <IssueTrackerDashboard
                        populateFilters={(filters) => {
                            populateMultipleFilters(filters);
                            history.push(`/workflow/list`);
                        }}
                    />}
                    {view == 'list' && <Grid item xs={12}>
                        <IssuesTrackerList view={view} />
                    </Grid>}
                    {view == 'kanban' && <Grid item xs={12}>
                        <IssueTrackerKanban view={view} />
                    </Grid>}
                </Grid>
            </PageComponent>
        </Authorize>
    )
}