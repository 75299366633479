import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { StepDefinitionTypeById } from '../../enums';
import EditWorkFlow from './EditWorkFlow';
import EditWorkFlowPopupStyle from './EditWorkFlowPopupStyle';

const useStyles = EditWorkFlowPopupStyle;

const EditWorkFlowPopup = ({ contract, open, clientConfig, handleClose, stepDefinitionTypeId = StepDefinitionTypeById.CHECKIN }) => {

    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>
                <Grid container justifyContent='space-between'>
                    <div>{`${contract?.label} SLA`}</div>
                    {contract?.headerComponent}
                </Grid>
            </DialogTitle>

            <DialogContent>
                <EditWorkFlow
                    contract={contract}
                    open={open}
                    clientConfig={clientConfig}
                    name={contract.configName}
                    handleClose={handleClose}
                    stepDefinitionTypeId={stepDefinitionTypeId}
                />
            </DialogContent>
        </Dialog>
    );
};

export default EditWorkFlowPopup;


