import { questionTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { DateElement } from "./DateElement";
import { ImageElement } from "./ImageElement";
import { SignatureElement } from "./SignatureElement";
import { TextElement } from "./TextElement";
import { ValueElement } from "./ValueElement";
import { FormOutputStyle } from "../style";
import { Grid } from "@material-ui/core";
import { SingleValueElement } from "./SingleValueElement";
import { BooleanElement } from "./BooleanElement";

export const QuestionElement = ({ element, question, answer, base64ImagesLookup }) => {
    const classes = FormOutputStyle();

    const questionTypeComponents = {
        [questionTypes.Date]: DateElement,
        [questionTypes.Freetext]: TextElement,
        [questionTypes.Dropdown]: SingleValueElement,
        [questionTypes.Image]: ImageElement,
        [questionTypes.MultipleChoice]: ValueElement,
        [questionTypes.Signature]: SignatureElement,
        [questionTypes.Boolean]: BooleanElement,
    };

    const QuestionComponent = questionTypeComponents[question?.questionType];

    const getValue = () => {
        switch (question?.questionType) {
            case questionTypes.Image:
            case questionTypes.Signature:
                return `data:image/png;base64,${base64ImagesLookup?.[answer?.id]?.imageData}`;
            default:
                return answer?.values
        }
    }

    return (
        <Grid container direction="column">
            <Grid item>
                {element.showQuestion && <div><b>{question?.question}</b></div>}
                <div>
                    {QuestionComponent ? <QuestionComponent value={getValue()} /> : <></>}
                </div>
                {!!answer?.note && <div>{answer?.note}</div>}
            </Grid>
            {question?.questionType != questionTypes.Signature && question?.questionType != questionTypes.Image && !!answer?.pictureUrl && !!base64ImagesLookup[answer.id]?.imageData &&
                <Grid item className={classes.imageElement}><ImageElement value={`data:image/png;base64,${base64ImagesLookup?.[answer?.id]?.imageData}`} /></Grid>}
        </Grid>
    )
}