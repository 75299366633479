import { Grid, IconButton } from "@material-ui/core";
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useGetSummaryQuery } from "features/lots/lotSlice";
import _ from 'lodash';
import { useGetOnLotStatusesQuery } from "features/vehicles/vehicleSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLot, setStatuses } from "features/vehicles/vehicleFiltersSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { selectIsClient } from "features/user/userSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { lotSummaryStyle } from "../style";
import ClientInLot from "../../ClientInLot/ClientInLot";

const LotStats = ({
    lot,
    lotId
}) => {
    let { data: onLotStatuses, isLoading: isLoadingStatuses } = useGetOnLotStatusesQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    clients = clients || [];
    const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));

    let { data: summary, error: summaryError, isFetching: isLoadingSummary } = useGetSummaryQuery(lotId);


    const isLoading = () => isLoadingSummary;

    const dispatch = useDispatch();
    const history = useHistory();

    const classes = lotSummaryStyle();

    const goToAssets = () => {
        if (onLotStatuses?.length) {
            dispatch(setStatuses(onLotStatuses));
            dispatch(setLot(lot?.id));
            history.push(`/assets`);
        }
    }
    const dynamicCounts = [
        {
            key: 'totalOnLot',
            label: 'Assets on lot',
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY,
            onClick: goToAssets
        },
        {
            key: 'leasedSpots',
            label: 'Commited spots',
            className: classes.leasedCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'currentlyDomiciled',
            label: 'Currently Domiciled',
            className: classes.domiciledCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'uncommitedSlips',
            label: 'Uncommited Slips',
            className: classes.emptyCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        // {
        //     key: 'lotCapacity',
        //     label: 'Lot Capacity',
        //     profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        // },
        {
            key: 'availableForSale',
            label: 'Available for sale',
            className: classes.emptyCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'onLotForClient',
            label: 'Assets on lot',
            className: classes.domiciledCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_CLIENT
        },
        {
            key: 'leasedForClient',
            label: 'Commited spots',
            className: classes.leasedCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_CLIENT
        }
    ];

    return (
        <>
            <LoadingSpinner loading={isLoading()} />
            <Grid direction='column' spacing={1}>
                {dynamicCounts?.map(count =>
                    <Authorize profile={count.profile}>
                        <Grid onClick={count.onClick} item spacing={2} alignItems='center' container key={count.key} className={`${classes.countWrapper}`}>
                            <Grid item className={`${classes.count}  ${count.className}`}> {summary?.[count.key] ?? 0} </Grid>
                            <Grid item className={classes.label}>{count?.label}</Grid>
                            {count.onClick && <Grid item>
                                <IconButton title='View asset list' onClick={count.onClick}><ArrowForwardIcon /></IconButton>
                            </Grid>}
                        </Grid>
                    </Authorize>)}
            </Grid>

            <Authorize profile={permissionProfiles.LOT.LOT_STORAGE_SLA_EDIT}>
                <Grid direction='column' spacing={1}>
                    <ClientInLot activeLot={lot} clients={clientAccounts} />
                </Grid>
            </Authorize>

        </>
    )
}
export default LotStats;
