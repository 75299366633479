import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WarningIcon from '@material-ui/icons/Warning';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import { useState } from 'react';
import {ImportLotsResultsDialogStyle} from "./ImportLotsResultsDialogStyle";
function ImportLotsResultsDialog({ importResults, error, open, setOpen, loading,reloadLots }) {

    const classes = ImportLotsResultsDialogStyle();
    const [showErrors, setShowErrors] = useState(true);
    const [showSkipped, setShowSkipped] = useState(true);
    const [showSuccess, setShowSuccess] = useState(true);

    const handleClose = () => {
        reloadLots();
        setOpen && setOpen(false);
    };

    const handleShowErrors = () => {
        setShowErrors(!showErrors);
    };
    const handleShowSkipped = () => {
        setShowSkipped(!showSkipped);
    };
    const handleShowSuccess = () => {
        setShowSuccess(!showSuccess);
    };


    const importedLots = error ? [] : importResults?.importedLots;
    const failedLots = error ? [] : importResults?.failedLots;
    const skippedLots = error ? [] : importResults?.skippedLots;
    const accessCodeGeneratedLots = error ? [] : importResults?.accessCodeGeneratedLots;

    const stats = [
        {
            title: 'Imported Lots',
            count: importedLots?.length,
            onClick: () => handleShowSuccess(),
            selected: showSuccess
        },
        {
            title: 'Skipped Lots',
            count: skippedLots?.length,
            onClick: () => handleShowSkipped(),
            selected: showSkipped
        },
        {
            title: 'Failed Lots',
            count: failedLots?.length,
            onClick: () => handleShowErrors(),
            selected: showErrors
        }
    ]

    const resultsToShow = [
        ...(showSuccess ? importedLots ?? [] : []),
        ...(showSkipped ? skippedLots ?? [] : []),
        ...(showErrors ? failedLots ?? [] : [])
    ];

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="form-dialog-title"
            maxWidth={'lg'}
            fullWidth={true}>
            <DialogTitle id="import-dialog-title">{'Import Results'}</DialogTitle>
            <DialogContent className={classes.content}>
                {loading ?
                    <div>
                        <div className={classes.progressText}>Processing lots, please wait</div>
                        <div><LinearProgress /> </div>
                    </div> :
                    <>
                        <div className={classes.stats}>
                            {stats.map(s =>
                                <div onClick={() => s.onClick()} className={`${classes.importCountWrapper} ${s?.selected ? classes.selected : ''}`}>
                                    <div className={classes.count}> {s?.count}</div>
                                    <div className={classes.text}>{s?.title}</div>
                                </div>)}
                        </div>
                        {error?.data && Array.isArray(error?.data) ? <div>
                                {error?.data?.map((e, i) => <div className={classes.error} key={i}><WarningIcon />{e}</div>)}
                            </div> :
                            <div className={classes.items}>
                                {resultsToShow?.length ? resultsToShow?.map((lotResult, i) =>
                                        <ImportItem itemResult={lotResult} key={i}/>)
                                    :
                                    <EmptyPlaceholder />}
                            </div>
                        }
                    </>}
            </DialogContent>
            <DialogActions>
                {!loading && <Button onClick={handleClose} color="primary">
                    Done
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

export default ImportLotsResultsDialog;

function ImportItem({ itemResult}) {
    const classes = ImportLotsResultsDialogStyle();
    const [collapsed, setCollapsed] = useState(true);

    return (

        <div className={classes.itemWrapper} onClick={() => setCollapsed(!collapsed)}>
            <div className={classes.itemHeader}>
                <div>
                    {itemResult.record.name && <div className={classes.itemTitle}>Name: {itemResult.record.name}</div>}
                </div>
                <KeyboardArrowDownIcon />
            </div>
            <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                <div> {itemResult.message}</div>                
            </Collapse>
        </div>

    )
}