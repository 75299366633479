import { Button, Grid, IconButton, ListItem, Paper } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import UsersPageStyle from "../../UsersPageStyle";
import CustomInput from "components/CustomInput";
import { useState, useEffect } from "react";
import { useGetUserPermissionClaimsQuery, useSaveUserPermissionClaimsMutation } from "features/users/usersSlice";
import { permissionDisplay } from "components/Authorize/permissions/PermissionList/permissionDisplay";
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationProvider from "components/NotificationProvider";
import DropdownFilter from "components/DropdownFilter";

const useStyles = UsersPageStyle;

const EditUserPermissions = ({ userId, onCancel }) => {
    const classes = useStyles();

    let { data: userPermissions, error: userPermissionsError, isLoading: isLoadingUserPermissions } = useGetUserPermissionClaimsQuery(userId, { skip: !userId });
    userPermissions = userPermissions || [];

    let permissionClaims = userPermissions?.data;

    let [updatePermissions] = useSaveUserPermissionClaimsMutation();

    const [saving, setSaving] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [permissions, setPermissions] = useState(permissionClaims);

    useEffect(() => {
        setPermissions(permissionClaims?.length ? permissionClaims : [{
            'id': null,
            'claimType': 'permission',
            'claimValue': null
        }]);
    }, [isLoadingUserPermissions, userId]);

    const getValues = (item) => {
        let dropdownValues = permissionDisplay?.map(p => ({
            id: p.key,
            name: p.name || p.key,
            description: p.description || null
        }));

        if (!dropdownValues.find(perm => perm.id === item.claimValue)) {
            dropdownValues.push({
                id: item.claimValue,
                name: item.claimValue,
                description: null
            });
        }

        return dropdownValues.filter(perm =>
            !permissions?.find(p =>
                p.claimValue?.toString().toLowerCase().trim() === perm.id?.toString().toLowerCase().trim()
            ) || perm.id?.toString().toLowerCase().trim() === item.claimValue?.toString().toLowerCase().trim());
    };

    const onChangePermission = (item, val) => {
        setHasChanged(true);
        let permissionList = [...permissions];
        let newItem = { ...item };
        newItem.claimType = "permission";
        const selectedPermission = permissionDisplay?.find(p => p.key === val) || { key: val };
        newItem.claimValue = selectedPermission.key;
        permissionList[permissions.findIndex(i => i === item)] = { ...newItem };
        setPermissions(permissionList);
    };

    const onAddNewPermission = () => {
        setHasChanged(true);
        let permissionList = [...permissions];
        permissionList.push({ 'userId': userId, 'claimType': null, 'claimValue': null });
        setPermissions(permissionList);
    };

    const onDelete = (item) => {
        setHasChanged(true);
        let permissionList = [...permissions];
        permissionList = permissionList.filter(p => p !== item);
        setPermissions(permissionList);
    };

    const handleSave = async () => {
        setSaving(true);

        let permissionsToSave = {
            userId: userId,
            permissionClaimEntities: permissions
        };

        let result = await updatePermissions(permissionsToSave);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    };

    const renderOption = (value) => {
        return (
            <div className={classes.dropdown}>
                <div className={classes.name}>{value?.name}</div>
                <div className={classes.description}>{value?.description}</div>
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <div className={classes.label}>User Permissions</div>
            <div>
                {permissions?.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <ListItem>
                                <Grid container spacing={12}>
                                    <Grid item xs={12} className={classes.dropdown}>
                                        <DropdownFilter
                                            withTypeAhead
                                            key={index}
                                            label="Permission"
                                            elementType="dropdown"
                                            values={getValues(item)}
                                            value={item.claimValue}
                                            onSelect={val => onChangePermission(item, val?.id)}
                                            showEmpty={true}
                                            renderOption={renderOption}
                                        />
                                    </Grid>
                                </Grid>
                                <IconButton edge="end" aria-label="delete"
                                    onClick={() => onDelete(item)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        </div>
                    );
                })}
            </div>
            <Grid container className={classes.buttonWrapper}>
                <Grid item>
                    <Button
                        className={classes.addButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onAddNewPermission}
                    >
                        Add user permission
                    </Button>
                </Grid>
                {hasChanged ?
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid> : null
                }
            </Grid>
        </Paper>
    );
};

export default EditUserPermissions;
