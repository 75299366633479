import { Button } from "@material-ui/core";
import { useState } from "react";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import NotificationProvider from "../../../../../components/NotificationProvider";
import LotDialog from "../../../../lots/components/LotDialog";
import { useUpdateLotMutation } from "../../../../lots/lotSlice";

const LotButton = ({lot, disabled, icon,label}) => {
    const [lotToEdit, setLotToEdit] = useState();
    let [addOrUpdateLot, { isLoading: updateLotLoading }] = useUpdateLotMutation();
    const onCancelEditLot = () => {
        setLotToEdit();
    }
    const onSaveLot = async (lot) => {
        const res = await addOrUpdateLot(lot);
        if (!res?.error) {
            NotificationProvider.success("Lot was saved successfully");
            setLotToEdit();
        } else {
            NotificationProvider.error("Failed to save lot");
        }
    }

    const onNewLot = (lot) => {
        setLotToEdit(lot);
    }
    
    return (
        <>
            <LoadingSpinner loading={updateLotLoading} />
            <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                <Button
                    id="basic-button"
                    onClick={()=> onNewLot(lot)}
                    startIcon={icon}
                    disabled={disabled}
                >
                    {label}
                </Button>
            </Authorize>

            <LotDialog lot={lotToEdit}
                       onSave={onSaveLot}
                       onCancel={onCancelEditLot}
                       saving={updateLotLoading}/>
        </>
    )
}

export default LotButton;