import { Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { selectIsClient, selectUserClientAccountId } from "features/user/userSlice";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Authorize from "../../../components/Authorize";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import CustomInput from '../../../components/CustomInput';
import DropZoneInDialog from '../../../components/DropZoneInDialog';
import NotificationProvider from "../../../components/NotificationProvider";
import formStyles from "../formStyles";
import { useImportAssetsMutation } from "../vehicleSlice";
import ImportAssetsResultsDialog from './ImportAssetsResultsDialog';
import ImportAssetsTemplate from './ImportAssetsTemplate';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import _ from 'lodash';

const useStyles = formStyles;

const ImportAssets = ({ clients, reloadAssets }) => {
    const classes = useStyles();

    let [importAssets, { data: importedData, error: importError, isLoading: importLoading }] = useImportAssetsMutation();

    const [selectedClient, setSelectedClient] = useState('');
    const [generateAccessCode, setGenerateAccessCode] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [clientAccounts, setClientAccounts] = useState([]);
    
    let isClient = useSelector(selectIsClient);
    let loggedInUserClientAccountIds = useSelector(selectUserClientAccountId)

    useEffect(() => {
        let ca = _.flatten(clients?.map(c => c.clientAccounts));
        setClientAccounts(ca);
    }, [clients])

    const onCloseDialog = () => {
        setOpenDialog(false);
    }

    const downloadTemplate = async () => {
        setOpenTemplateDialog(true);
    }

    const onSave = async (file) => {
        let fd = new FormData();
        fd.append("uploaded_file", file);
        fd.append("clientAccountId", isClient ? null : selectedClient);
        fd.append("generateAccessCode", generateAccessCode);

        const res = await importAssets(fd);
        if (res.error) {
            NotificationProvider.error("Failed to import assets");
            NotificationProvider.error(res.error?.data?.[0]);
        } else {
            if (res?.data != []) {
                setOpenDialog(false);
                setShowResults(true);
            }
        }
    }

    return (
        <>
            <Authorize profile={permissionProfiles.ASSETS.ASSET_IMPORT}>
                <Dialog open={openDialog} onClose={() => onCloseDialog()} aria-labelledby='dialog-title'>
                    <DialogTitle >
                        <div className={classes.dialogWrapper}>
                            <div className={classes.dialogTitle}> Successfully imported assets: </div>
                            <IconButton aria-label="close" onClick={() => onCloseDialog()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                </Dialog>
                <DropZoneInDialog
                    labelDialog={'Import Assets'}
                    buttonLabel={'Import Assets'}
                    onSave={onSave}
                    valid={selectedClient != '' || isClient || !Array.isArray(loggedInUserClientAccountIds)}
                    // accept={{ 'text/csv': ['.csv'], 'text/xlsx': ['.xlsx'], 'text/xls': ['.xls'] }}
                    formContent={
                        <>
                            {!(isClient && loggedInUserClientAccountIds != "" && !Array.isArray(loggedInUserClientAccountIds)) && 
                                <CustomInput
                                    id="clientAccount"
                                    label="Client"
                                    value={selectedClient}
                                    elementType="dropdown"
                                    onChange={val => setSelectedClient(val)}
                                    values={clientAccounts}
                                    required={true}
                                    showEmpty
                                />}
                            <br />
                            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                                <div className={classes.checkboxWrapper}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={generateAccessCode}
                                                onChange={(e, value) => setGenerateAccessCode(value)}
                                                className={classes.checkbox}
                                            />
                                        }
                                        label={<div className={classes.generateLabel}>Generate access code for pending arrival assets</div>}
                                    />
                                    <Tooltip
                                        title="The system will attempt to generate access codes for all assets that are imported in status pending arrival, and that Parq has access control configured for the lot defined for the asset. The access codes will be sent to the contact defined in the client access group.">
                                        <HelpOutlineIcon className={classes.iconHelp} />
                                    </Tooltip>
                                </div>
                                <br />
                            </Authorize>
                            <Button
                                color="primary"
                                className={classes.downloadButton}
                                onClick={downloadTemplate}>
                                Download Template
                            </Button>
                            <br />
                        </>}
                />
                <ImportAssetsResultsDialog
                    importResults={importedData}
                    open={showResults || importLoading}
                    setOpen={setShowResults}
                    loading={importLoading}
                    error={importError}
                    reloadAssets={reloadAssets}
                />
                <ImportAssetsTemplate
                    open={openTemplateDialog}
                    setOpen={setOpenTemplateDialog}
                />
            </Authorize>
        </>
    );
};

export default ImportAssets;
