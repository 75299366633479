import AssetsCount from './UserTaskItem/components/assetsCount';
import CommentsInput from './UserTaskItem/components/commentsInput';
import DoneDropdown from './UserTaskItem/components/doneDropdown';
import PictureSecurity from './UserTaskItem/components/pictureSecurity';
import StatusDropdown from './UserTaskItem/components/statusDropdown';
import LinkByComment from "./UserTaskItem/components/linkByComment";

export const userTaskTypes = {
    LaDailyAudit: 1,
    LaWeeklyAudit: 2,
    LaInspectionAm: 3,
    LaInspectionPm: 4,
    LaBathroom: 5,
    LaGarabage: 6,
    LaCleanliness: 7,
    LaSafetyWalk: 8,
    LaSecurityPhotos: 9,
    LaGateSla: 10,
    LaFeedback: 11,
    LaSsInteraction: 12,
    LaTimesheets: 13,
    SuperInventoryCheck: 14,
    SuperScheduleCheck: 15,
    SuperClocksOut: 16,
    SuperSafetyChecks: 17,
    SuperSiteInsepctions: 18,
    SuperLaFeedback: 19,
    SuperLaEngagement: 20,
    SuperCameras: 21,
    SuperParqSanity: 22,
    SuperWashes: 23,
    SuperPto: 24,
    SuperOneOnOnes: 25
}

const defaultDoneResponse = 'yes';

export const userTaskSetupByType = {
    [userTaskTypes.LaDailyAudit]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false
    },
    [userTaskTypes.LaWeeklyAudit]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false
    },
    [userTaskTypes.LaInspectionAm]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <LinkByComment task={task} taskStatus={taskStatus} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaInspectionPm]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <LinkByComment task={task} taskStatus={taskStatus} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaBathroom]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaGarabage]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaCleanliness]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaSafetyWalk]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaSecurityPhotos]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <PictureSecurity task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false
    },
    [userTaskTypes.LaGateSla]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false
    },
    [userTaskTypes.LaFeedback]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaSsInteraction]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.LaTimesheets]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: false,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperInventoryCheck]: {
        component: ({ task, taskStatus, onChange }) => (
            <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
        ),
        isSuper: true
    },
    [userTaskTypes.SuperScheduleCheck]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperClocksOut]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperSafetyChecks]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperSiteInsepctions]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true
    },
    [userTaskTypes.SuperLaFeedback]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true
    },
    [userTaskTypes.SuperLaEngagement]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperCameras]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} label={'What have you observed and what actions they are taking?'} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true
    },
    [userTaskTypes.SuperParqSanity]: {
        component: ({ task, taskStatus, onChange, lotId, setValid }) => (
            <>
                <AssetsCount task={task} onChange={onChange} lotId={lotId} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true
    },
    [userTaskTypes.SuperWashes]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperPto]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <DoneDropdown task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true,
        defaultUserResponse: defaultDoneResponse
    },
    [userTaskTypes.SuperOneOnOnes]: {
        component: ({ task, taskStatus, onChange, setValid }) => (
            <>
                <CommentsInput task={task} onChange={onChange} setValid={setValid} />
                <StatusDropdown task={task} taskStatus={taskStatus} userTaskStatuses={userTaskStatuses} onChange={onChange} />
            </>
        ),
        isSuper: true
    }
}
export const userTaskStatuses = {
    Pending: 1,
    Complete: 2
}
