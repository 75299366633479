import { Paper } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import LoadingSpinner from 'components/LoadingSpinner';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PageComponent from "../../components/Page/PageComponent";
import IssueTrackerView from "./IssueTrackerView/IssueTrackerView";
import { useGetIssueTrackerByIdQuery } from "./issuesTrackerSlice";
import { IssueTrackerStyles } from "./style";

const IssueTracker = () => {
    const classes = IssueTrackerStyles();
    const params = useParams();
    const id = params.id;

    let { data: issuesTracker, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetIssueTrackerByIdQuery({ id });

    const issue = issuesTracker?.[0];

    return (
        <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW}>
            <PageComponent>
                <LoadingSpinner loading={isLoadingIssuesTracker} />
                <Paper className={classes.issueTrackerPageWrapper}>
                    <IssueTrackerView issueTracker={issue} />
                </Paper>
            </PageComponent>
        </Authorize>
    );
};

export default IssueTracker;