import { Grid } from "@material-ui/core";
import Permission from "./Permission";
import roleManagementStyles from "./roleManagementStyles";

const useStyles = roleManagementStyles;
const RoleCategory = ({category, onChangePermission}) =>{
    const classes = useStyles();

    const onChangePermissionBridge = async(p_permission, p_val, attribute) => {
        await onChangePermission(category, p_permission, p_val, attribute);
    }

    return(<Grid container direction="row" spacing={2}  xs={12}>
        {
            category?.permisions?.map((permision) => (
                <Grid item xs={6}>
                    <Permission permision={permision} onChangePermission={onChangePermissionBridge}/>
                </Grid>
            ))
        }
    </Grid>);
}
export default RoleCategory;