import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Authorize from 'components/Authorize/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import CopyToClipboard from 'components/CopyToClipboard';
import PageComponent from 'components/Page/PageComponent';
import { permissionDisplay } from './permissionDisplay';

const PermissionList = ({ }) => {

    return (
        <Authorize profile={permissionProfiles.USER.VIEW_USERS}>
            <PageComponent header={<>
                <h1>Permissions</h1>
                <h4>Defined using the 'permission' claim</h4>
            </>}>
                <Paper>{
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Permission Key</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {permissionDisplay?.map(perm =>
                                <TableRow key={perm.key} >
                                    <TableCell>
                                        {perm.key}
                                        <CopyToClipboard text={perm.key} />
                                    </TableCell>
                                    <TableCell>{perm.name}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                }
                </Paper>
            </PageComponent>
        </Authorize>
    );
}

export default PermissionList;