import { makeStyles } from '@material-ui/core/styles';

export const readinessIndicatorStyle = makeStyles((theme) => ({
    indicator: {
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        minWidth: '0.8em',
        cursor: 'pointer'
    },
    redIndicator: {
        background: '#fd8f8f',
    },
    greenIndicator: {
        background: '#a6ef69',
    },
    yellowIndicator: {
        background: '#ffda4c',
    },
    tooltipField: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.2em 0'
    },
    tooltipIndicator: {
        marginLeft: '0.5em'
    },
    tooltipContent: {
        padding: '1em',
        '& label': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
    indicatorWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    indicatorText: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    indicatorDetailsText: {
        marginLeft: '0.5em',
    },
    readyBadge: {
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '0.8em',
        padding: '0px 15px',
        color: 'white',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        width: 'fit-content'
    },
    indicatorWrapper: {
        marginRight: '0.5em',
    }
}));
