import { makeStyles } from "@material-ui/core";

export const IssueDescStyle = makeStyles((theme) => ({
    userInitials: {
        borderRadius: '50%',
        textTransform: 'uppercase',
        background: theme.palette.primary.dark,
        color: 'white',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: '0.8em',
    },
    descTootlip: {
        padding: '1em',
        background: 'white',
        boxShadow: theme.shadows[2]
    },
    descTooltipTrigger: {
        maxWidth: '5em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}))