import * as React from "react";

function IconReplaceTire(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64869 8.3325H4.45136C4.70535 6.31108 6.30986 4.70657 8.33128 4.45258V2.64991C5.3181 2.91803 2.91681 5.31932 2.64869 8.3325ZM1.49878 8.895C1.49878 4.81184 4.81062 1.5 8.89378 1.5C9.20444 1.5 9.45628 1.75184 9.45628 2.0625V4.98C9.45628 5.29066 9.20444 5.5425 8.89378 5.5425C7.04444 5.5425 5.54128 7.04566 5.54128 8.895C5.54128 9.20566 5.28944 9.4575 4.97878 9.4575H2.06128C1.75062 9.4575 1.49878 9.20566 1.49878 8.895ZM10.5949 2.85471C10.6628 2.55156 10.9636 2.36086 11.2667 2.42876C14.57 3.16871 17.0388 6.11788 17.0388 9.64499C17.0388 13.7291 13.7279 17.04 9.64377 17.04C6.11666 17.04 3.16749 14.5712 2.42754 11.2679C2.35964 10.9648 2.55034 10.664 2.85349 10.5961C3.15663 10.5282 3.45743 10.7189 3.52534 11.022C4.15267 13.8226 6.65454 15.915 9.64377 15.915C13.1066 15.915 15.9138 13.1078 15.9138 9.64499C15.9138 6.65576 13.8214 4.15389 11.0208 3.52656C10.7177 3.45865 10.527 3.15786 10.5949 2.85471ZM9.84169 5.69456C9.90086 5.38959 10.1961 5.19033 10.501 5.24949C12.5638 5.6497 14.1214 7.46481 14.1214 9.64493C14.1214 12.1178 12.1167 14.1224 9.6439 14.1224C7.46377 14.1224 5.64866 12.5649 5.24846 10.5021C5.18929 10.1971 5.38855 9.9019 5.69353 9.84273C5.9985 9.78356 6.29369 9.98283 6.35286 10.2878C6.65242 11.8318 8.01265 12.9974 9.6439 12.9974C11.4954 12.9974 12.9964 11.4965 12.9964 9.64493C12.9964 8.01368 11.8308 6.65346 10.2868 6.3539C9.98179 6.29473 9.78252 5.99954 9.84169 5.69456Z" fill="#0090FE" />
        </svg>
    );
}

export default IconReplaceTire;
