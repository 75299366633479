import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Paper } from '@material-ui/core';
import DatePicker from "components/DatePicker";
import NotificationProvider from "components/NotificationProvider";
import { useState } from 'react';
import { ReportTypeEnum, reportTypesList } from '../../reportsConsts.ts';
import { useGenerateBillingReportMutation } from '../ReportsSlice.js';
import { GenerateBillingReportsStyle } from './GenerateBillingReportsStyle';

const GenerateBillingReports = ({ clientId }) => {
    const classes = GenerateBillingReportsStyle();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [includeStorageBilling, setIncludeStorageBilling] = useState(true);
    const [includeInvoiceReport, setIncludeInvoiceReport] = useState(true);

    const [generate, { isLoading: isGenerating }] = useGenerateBillingReportMutation();

    const handleClose = () => {
        setStartDate(null);
        setEndDate(null);
        setIncludeStorageBilling(true);
        setIncludeInvoiceReport(true);
    }

    const onSubmit = async () => {
        let reportTypes = [];
        if (includeStorageBilling)
            reportTypes.push(ReportTypeEnum.STORAGE_BILLING_REPORT);
        if (includeInvoiceReport)
            reportTypes.push(ReportTypeEnum.INVOICE_REPORT);

        const body = {
            clientId,
            reportTypes,
            startDate,
            endDate
        }

        let result = await generate(body);
        if (result && !result.error) {
            NotificationProvider.success("Request to generate reports submitted successfully.");
        } else {
            NotificationProvider.error("Request to generate reports failed. ");
            NotificationProvider.error(result.error?.data?.[0]);
        }
        handleClose();
    };

    return (
        <Paper className={classes.generatePaper}>
            <Grid container justifyContent='flex-end'>
                <Grid container xs={12}>
                    <Grid container >
                        <Grid item xs={12} className={classes.generateBillingTitle}>
                            Select Date Range
                        </Grid>
                        <Grid item xs={5} className={classes.datePicker}>
                            <DatePicker
                                title='Start Date'
                                value={startDate}
                                onSelect={setStartDate}
                                required
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.datePicker}>
                            <DatePicker
                                title='End Date'
                                value={endDate}
                                onSelect={setEndDate}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className={classes.generateBillingTitle}>
                            Select which reports to include
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={includeStorageBilling}
                                    onChange={(e, value) => setIncludeStorageBilling(value)}
                                />}
                                label={reportTypesList.find(t => t.id == ReportTypeEnum.STORAGE_BILLING_REPORT)?.name}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={includeInvoiceReport}
                                    onChange={(e, value) => setIncludeInvoiceReport(value)}
                                />}
                                label={reportTypesList.find(t => t.id == ReportTypeEnum.INVOICE_REPORT)?.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    color='primary' variant='contained'
                    disabled={isGenerating || (!includeInvoiceReport && !includeStorageBilling) || startDate == null || endDate == null}
                    onClick={onSubmit}
                >
                    Generate Reports
                </Button>
                {isGenerating && <CircularProgress size={'1em'} />}
            </Grid>
        </Paper>
    )
}

export default GenerateBillingReports;