import { makeStyles } from "@material-ui/core";

export const lotAttachmentsDialogStyle = makeStyles((theme) => ({
    inputWrapper: {
        margin: '0.8em'
    },
    fieldGroup: {
        padding: '0.5em',
        margin: '0 0.5em 1em 0.5em'
    },
    groupTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 1em'
    }
}));