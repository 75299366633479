import LoadingSpinner from "components/LoadingSpinner";
import { ChargeCalculationStyle } from "./style";
import { Button, Tooltip } from "@material-ui/core";
import { useGetChargeForClientMutation } from "../pricingSlice";

export const ChargeCalculation = ({ clientId, activityType, lotId, price, inventoryUsed, onChange }) => {
    const classes = ChargeCalculationStyle()

    let [getCharge, { data: charge, error: chargeError, isLoading: isLoadingCharge }] = useGetChargeForClientMutation({ clientId, activityType, lotId, price, inventoryUsed }, { skip: !clientId || !price || !activityType || !lotId || !inventoryUsed });


    const handleClick = async (event) => {
        const res = await getCharge({ clientId, activityType, lotId, price, inventoryUsed })
        onChange(res.data)
    };

    return <>
        <LoadingSpinner loading={isLoadingCharge} />
        {
            <>
                <Tooltip title='Calculate Charge'>
                    <Button
                        onClick={handleClick}
                        variant="outlined"
                        color="primary"
                        className={classes.calculateButton}
                        disabled={!price}
                    >
                        Calculate
                    </Button>
                </Tooltip>
            </>
        }
    </>

}