import { makeStyles } from "@material-ui/core";

export const selectFieldsPopupStyle = makeStyles((theme) => ({
    popupContainer: {
        boxShadow: 'none',
        margin: 20,
    },
    checkboxList: {
        display: 'flex',
        flexDirection: 'column',        
    },
    btnContainer: {
        margin: "12px",
        alignItems: 'center',
        display: "flex",
        flexDirection: 'column'
    },
    button: {
        margin: "12px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    }
}));