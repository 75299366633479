import { makeStyles } from '@material-ui/core/styles';

export const mapWrapperStyle = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        width: '100%'
    },
    icon: {
        position: 'absolute',
        marginTop: '-6px'
    },
    searchBox: {
        position: 'absolute'
    },
    searchButton: {
        height: '50px',
        width: '40px',
        zIndex: 1,
        margin: '0.5rem 0 0 1rem',
        backgroundColor: '#f3f5f6',
        borderRadius: 4
    },
    searchBar: {
        zIndex: 1,
        backgroundColor: '#f3f5f6',
        borderRadius: 4,
    },
    searchVin: {
        width: 300,
        zIndex: 1,
        padding: '0.5rem 0 0 1rem',
        borderRadius: 4,
    },
    searchMode: {
        zIndex: 1,
        margin: '0.8rem 0 0 1.5rem',
        backgroundColor: '#f3f5f6',
        borderRadius: 4,
        position: 'absolute'
    }
}));
