import { makeStyles } from "@material-ui/core";

export const ReservationStatusFilterStyle = makeStyles((theme) => ({
    filterItem: {
        padding: '2em',
        padding: '1em 2em',
        height: '100%',
        //boxShadow: '2px 3px 5px #d7d6d6',
        //position: 'relative',
        fontWeight: 600,
    },
    filterName: {
        whiteSpace: 'nowrap',
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    count: {
        background: theme.palette.primary.main,
        //border: '1px solid',
        //borderColor: theme.palette.primary.main,
        //color: theme.palette.primary.main,
        color: 'white',
        padding: '2px 5px',
        minWidth: '1.5em',
        borderRadius: '5px'
    },
    filterContainer: {
        marginBottom: '0.5em'
    }
}))