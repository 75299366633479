import * as React from "react";

function SvgIconContent(props) {
    return (
        <svg
            width={38}
            height={30}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M22.5 17.5 15 25l-7.5-7.5M7.5 12.5 15 5l7.5 7.5"
                stroke="#0090FE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgIconContent;
