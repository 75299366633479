import { Box, Button, Grid } from '@material-ui/core';
import { IconLot } from 'icons';
import { CreateWorkOrderStyles } from '../CreateWorkOrderStyles';

const LotSelectorItem = ({ props, option, isNarrowScreen }) => {
    const classes = CreateWorkOrderStyles();

    return (
        <Box {...props} key={option?.id}>
            <Grid container xs={12} direction='row' alignItems='center' justifyContent='space-between'>
                <Grid item xs={8}>
                    <Grid container alignItems='center'>
                        <Grid item className={classes.itemIcon}>
                            <IconLot height='27' width='27' />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container direction='column'>
                                <Grid item>
                                    <label className={classes.itemLabel}>{option?.name}</label>
                                </Grid>
                                <Grid item>
                                    {option?.addressLine1}, {option?.city}, {option?.state}, {option?.zipCode}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isNarrowScreen && <Grid item>
                    <Button size='small' variant='contained' color='primary'>
                        Select Location
                    </Button>
                </Grid>}
            </Grid>
        </Box>
    )
}
export default LotSelectorItem;