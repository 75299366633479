import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../components/CustomInput";
import { useGetLotsQuery } from "../../../lots/lotSlice";
import configStyles from "../configStyles";
import { washRateTypes } from "./enums";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedLot, removeSelectedLot } from "./WashSlaSlice";

const useStyles = configStyles;

export const WashLots = ({ washConfigParams, activeLotsIds, washPricesPerLot, onConfigChange }) => {
    const classes = useStyles();

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    const { bulkEdit, selectedLots } = useSelector(state => state.washConfigState);
    const dispatch = useDispatch();

    const onWashPriceChange = (lotId, newPrice) => {
        let updatedPrices = washPricesPerLot.filter(washPrice => washPrice.lotId != lotId).map(x => ({ lotId: x.lotId, price: x.price }))
        updatedPrices.push({ lotId: lotId, price: parseInt(newPrice) });

        onConfigChange(undefined, "washPrices", updatedPrices)
    };

    let activeLots = [];

    activeLotsIds?.map(lotId => {
        let lot = lots?.find(lot => lot?.id == lotId);
        if (lot) {
            activeLots = ([...activeLots, { id: lot?.id, name: lot?.name, washPrice: lot?.washPrice }])
        }
    }
    )

    const onSelectRow = (selected, row) => {  
        if (selected) {
            dispatch(addSelectedLot(row.id));
        } else {
            dispatch(removeSelectedLot(row.id));
        }        
    }

    return (
        <List>
            {
                activeLots?.map(activeLot =>
                    <Paper className={classes.listItem}>
                        <ListItem key={activeLot.id} className={classes.spacing}>
                           
                            <ListItemIcon className={classes.icon}>
                                {bulkEdit && <Checkbox
                                                checked={selectedLots.includes(activeLot?.id)}
                                                onChange={(e, value) => { onSelectRow(value, activeLot) }}
                                            />} 
                                {!bulkEdit && <img src="/icons/icon-parking.svg" />}
                            </ListItemIcon>
                            <ListItemText primary={activeLot.name} />
                            <div className={classes.prices}>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                                    <ListItemText className={classes.prices} secondary={activeLot.washPrice ? `Wash Cost: $${activeLot.washPrice}` : null} />
                                </Authorize>
                                <div className={classes.washPriceField}>
                                    <div>
                                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                                            <LotPrice
                                                onWashPriceChange={onWashPriceChange}
                                                washPricesPerLot={washPricesPerLot}
                                                washConfigParams={washConfigParams}
                                                activeLot={activeLot}
                                            />
                                        </Authorize>
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    </Paper>
                )
            }
        </List>
    )
}

const LotPrice = ({ washConfigParams, washPricesPerLot, activeLot, onWashPriceChange, disabled }) => {

    let price = washConfigParams?.rateType == washRateTypes.FLAT_RATE ?
        washPricesPerLot?.find(washPrice => washPrice.lotId == activeLot.id)?.price :
        (activeLot?.washPrice * (100 + washConfigParams?.washMargin) / 100);
    let priceWithDollar = price ? `Charge: $${price}` : '';

    return (
        <div>{(disabled || washConfigParams?.rateType == washRateTypes.MARGIN) ? priceWithDollar :
            <CustomInput
                numberOnly
                id="price"
                label='Charge'
                disabled={!washConfigParams?.useWashProgram}
                value={price}
                onChange={val => onWashPriceChange(activeLot.id, +val)}
                elementType='input'
                InputLabelProps={{
                    shrink: true,
                }}
            />}
        </div>
    )
}