import { Cell, Label, Pie, PieChart, Text } from "recharts";
import { RankingPieChartStyle } from "./RankingPieChartStyles";


const RankingPieChart = ({ value, totalValue, label }) => {


    const classes = RankingPieChartStyle();

    const data = [
        { name: 'Group A', value: totalValue - value },
        { name: 'Group B', value: value },
    ];

    const COLORS = ['#bee1fd', '#0090fe'];

    return (
        <>
            <PieChart width={180} height={180} fill="#0061a9" className={classes.pieChart}>
                <Pie
                    data={data}
                    innerRadius={70}
                    outerRadius={90}
                    cx="50%"
                    cy="50%"
                    startAngle={90}
                    endAngle={450}
                    dataKey="value"

                >
                    {/* <Label value={value} position="center" className={classes.valueLabel} style={{ color: '#d4ecff' }} /> */}
                    <Label
                        content={props => {
                            const { viewBox: { cx, cy } } = props
                            const positioningPropsValue = {
                                x: cx,
                                y: cy,
                                textAnchor: 'middle',
                            }
                            const positioningPropsLabel = {
                                x: cx,
                                y: cy + 25,
                                textAnchor: 'middle',
                            }
                            const presentationPropsValue = {
                                fill: '#0061a9',
                                fontSize: 28,
                                fontWeight: 700
                            }
                            const presentationPropsLabel = {
                                fill: '#0090fe',
                                fontSize: 14,
                            }

                            return (
                                <>
                                    <Text {...positioningPropsValue} {...presentationPropsValue}>{Math.floor(value * 100) / 100}</Text>
                                    <Text {...positioningPropsLabel} {...presentationPropsLabel}>{label}</Text>
                                </>
                            )
                        }}
                    />
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </>
    )
}

export default RankingPieChart;