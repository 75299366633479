import DropdownFilter from "components/DropdownFilter";
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useEffect } from "react";
import { UserTaskItemStyle } from "../style";

const DoneDropdown = ({ task, onChange, setValid }) => {
    const classes = UserTaskItemStyle();

    useEffect(() => {
        if (task?.userResponse == 'no') {
            setValid(task?.comments?.length > 0);
        }
        else {
            setValid(true);
        }
    }, [task])

    const handleDropdownChange = (selectedVal, identifier) => {
        onChange(identifier, selectedVal);
    }

    return (
        <>
            <div className={classes.dropdownWrapper}>
                <DropdownFilter
                    label='Status'
                    values={[{ id: 'yes', name: 'yes' }, { id: 'no', name: 'no' }]}
                    value={task?.userResponse}
                    identifier={'userResponse'}
                    onSelect={handleDropdownChange}
                />
            </div>
            {task?.userResponse == 'no' &&
                <div className={classes.dropdownWrapper}>
                    <div className={classes.commentsLabel}>Comments</div>
                    <RichTextEditor
                        onChange={(e) => onChange('comments', e)}
                        content={task?.comments}
                    />
                </div>
            }
        </>
    )
}
export default DoneDropdown;