import * as React from "react";

function SvgIconMechanic(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.915 2.5c.681 0 1.336.262 1.829.733a2.589 2.589 0 0 1 0 3.658l-6.45 6.448-1.218-1.22 6.448-6.448a.864.864 0 0 0 0-1.22.883.883 0 0 0-1.219 0l-6.448 6.45-1.22-1.22 6.45-6.448a2.648 2.648 0 0 1 1.828-.733zm-2.587 24.138a5.179 5.179 0 0 1-5.173-5.172c0-.451.061-.9.181-1.334L9.87 12.664A5.152 5.152 0 0 1 3.727 5.79l.48-1.225L7.064 7.42a.882.882 0 0 0 1.218 0 .861.861 0 0 0 0-1.219L5.427 3.344l1.226-.48a5.153 5.153 0 0 1 6.873 6.142l7.468 7.468a5.152 5.152 0 0 1 6.141 6.874l-.48 1.225-2.856-2.855a.882.882 0 0 0-1.218 0 .86.86 0 0 0 0 1.22l2.855 2.855-1.226.481a5.14 5.14 0 0 1-1.883.364zM10.312 10.67l9.018 9.018-.206.526a3.426 3.426 0 0 0 2.988 4.694l-.75-.75a2.585 2.585 0 0 1 0-3.658 2.648 2.648 0 0 1 3.657 0l.75.751a3.427 3.427 0 0 0-4.695-2.988l-.525.206-9.017-9.017.207-.526a3.426 3.426 0 0 0-2.99-4.694l.75.75a2.587 2.587 0 0 1 0 3.658 2.648 2.648 0 0 1-3.657 0l-.75-.75a3.427 3.427 0 0 0 4.694 2.987l.526-.207zm-.99 11.228 3.67-3.67-1.219-1.22-3.67 3.67-.683-.683a.862.862 0 0 0-1.22 0l-3.448 3.448a.862.862 0 0 0 0 1.22l2.587 2.586a.862.862 0 0 0 1.219 0l3.448-3.449a.862.862 0 0 0 0-1.219l-.684-.683zm-3.374 3.522-1.367-1.367 2.23-2.23 1.366 1.368-2.229 2.23z"
                fill="#0090FE" />
        </svg>
    );
}

export default SvgIconMechanic;
