import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable/DataTable";
import LoadingSpinner from "components/LoadingSpinner";
import PageComponent from "components/Page/PageComponent";
import { useGetLotsQuery } from "features/lots/lotSlice";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FilterService, { fieldTypes } from "services/filterService";
import { setMobileActivityFilters, useGetMobileActivityQuery } from "./mobileActivitySlice";
import ExportButton from "components/ExportButton/ExportButton";
import FormatService from "services/formatService";
import { Button, Dialog, Paper } from "@material-ui/core";
import { useState } from "react";
import { groupBy } from "lodash";
import { LastPingPerLot } from "./LastPingPerLot";
import { MobileActivityStyle } from "./style";


const MobileActivityPage = () => {
    const history = useHistory();
    const { filters } = useSelector(state => state.mobileActivityState);

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: mobileActivityData, error: activityError, isFetching: isLoadingActvity } = useGetMobileActivityQuery({ lotId: filters?.lotId?.value }, { skip: !filters?.lotId?.value });

    const mobileActivity = (mobileActivityData?.mobileActivity ?? []).map(m => ({ ...m, type: 'activity' }));
    const mobilePings = (mobileActivityData?.mobilePing ?? []).map(m => ({ ...m, type: 'ping' }));
    const classes = MobileActivityStyle();

    let allActivity = [...mobilePings, ...(mobileActivity ?? [])]?.map(activity => {
        return {
            ...activity,
            //lotName: lots?.find(l => l.id == activity.lotId)?.name,
            createdDateObj: new Date(activity.createDate),
            formattedCreateDate: FormatService.formatDateTimeNoConversion(activity.createDate),
            formattedFinishDate: FormatService.formatDateTimeNoConversion(activity.finishDate),
            formattedSyncDate: FormatService.formatDateTimeNoConversion(activity.syncDate)
        }
    }) ?? [];



    lots = lots || [];

    const [jsonDataToView, setJsonDataToView] = useState();

    const [sortOptions, sortData] = useSortOptions();

    /*
       public DateTime CreateDate { get; set; }
    public DateTime FinishDate { get; set; }
    public DateTime SyncDate { get; set; }
    public int StepDefinitionTypeId { get; set; }
    public int LotId { get; set; }
    public string LotIdsString { get; set; }
    public string UserEmail { get; set; }
    public int ScreenTypeId { get; set; }
    public string JsonValue { get; set; }
    public string? DeviceInfo { get; set; }
    public string? NetworkInfo { get; set; }
    */
    const getViewJsonButton = (jsonValue) => {
        return jsonValue ? <Button variant='outlined' onClick={() => {
            setJsonDataToView(jsonValue)
        }}>View</Button> : <></>;
    }
    let columns = [
        { name: "Lot", key: "lotId", component: (row) => lots?.find(l => l.id == row?.lotId)?.name },
        { name: "Type", key: "type" },
        { name: "Created Date", key: "formattedCreateDate" },
        { name: "Finish Date", key: "formattedFinishDate" },
        { name: "Sync Date", key: "formattedSyncDate" },
        { name: "User Email", key: "userEmail", width: 300 },
        { name: "Network Info", key: "networkInfo", component: (row) => getViewJsonButton(row?.networkInfo) },
        { name: "Device Info", key: "deviceInfo", component: (row) => getViewJsonButton(row?.deviceInfo) },
        { name: "Request Info", key: "jsonValue", component: (row) => getViewJsonButton(row?.jsonValue) },
    ];

    const dispatch = useDispatch();


    const filterConfig = [
        { type: fieldTypes.LOT, size: 2, title: 'Lots', key: 'lotId', values: lots, pmfLot: true },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Created Date', key: 'createDate', values: [] },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Finish Date', key: 'finishDate', values: [] },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Sync Date', key: 'syncDate', values: [] },
        { type: fieldTypes.TEXT, size: 3, title: 'Type', key: 'type', values: [{ id: 'ping', name: 'Ping' }, { id: 'activity', name: 'Activity' }] },
    ];

    const onDropFilter = (filterValue, prop) => {
        dispatch(setMobileActivityFilters({ ...filters, ...{ [prop]: { value: filterValue } } }));
    };

    const isLoading = () => isLoadingActvity;

    let filteredData = orderBy(FilterService.filter(filters, null, allActivity, filterConfig), 'createdDateObj', 'desc');

    const mapExportData = row => {
        const issues = {};
        columns?.map(col => {
            issues[col.name] = row?.[col.key]
        });
        return issues;
    };


    return (
        <>
            <Authorize profile={permissionProfiles.MOBILE.VIEW_ACTIVITY}>
                <LastPingPerLot />
                <PageComponent header={
                    <DataListHeader
                        title={(
                            <>
                                <span>Mobile App Activity</span>
                            </>
                        )}
                        data={filteredData}
                        dropDownsConfig={filterConfig}
                        filters={filters}
                        onDropFilter={onDropFilter}
                        actionButtons={
                            <>

                                <ExportButton data={filteredData} mapExportData={mapExportData} />
                            </>
                        }
                    />}>
                    <>

                        {
                            <>
                                <LoadingSpinner loading={isLoading()} />
                                <DataTable
                                    columns={columns}
                                    rows={orderBy(
                                        filteredData,
                                        sortOptions.columnToSort || 'createdDate',
                                        'desc'
                                    )}
                                    rowIdentifier='id'
                                    onSort={sortData}
                                    sortDirection={sortOptions.sortDirection}
                                    columnToSort={sortOptions.columnToSort}
                                    noItemsMessage={filters?.lotId?.value ? 'No Data to Display' : 'Please select a lot'}
                                />
                            </>
                        }
                    </>
                </PageComponent>
                <Dialog open={!!jsonDataToView} onClose={() => setJsonDataToView()}>
                    {jsonDataToView && <pre style={{ padding: '1em 2em' }}>
                        {JSON.stringify(JSON.parse(jsonDataToView), null, 2)}
                    </pre>}
                </Dialog>
            </Authorize>
        </>
    )
}
export default MobileActivityPage;