import { makeStyles } from '@material-ui/core/styles';

export const AssetReadinessStyle = makeStyles((theme) => ({
    indicator: {
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        // marginRight: '1em',
        // boxShadow: '1px 2px 3px lightgrey',
        //border: '1px solid white',
        minWidth: '0.8em',
        cursor: 'pointer'
    },
    redIndicator: {
        background: '#fd8f8f',
    },
    greenIndicator: {
        background: '#a6ef69',
    },
    yellowIndicator: {
        background: '#ffda4c',
    },
    tooltipField: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.2em 0'
    },
    tooltipIndicator: {
        marginLeft: '0.5em'
    },
    tooltipContent: {
        //padding: '1em',
        whiteSpace: 'nowrap',
        '& label': {
            fontWeight: 'bold',
            marginRight: '0.5em'
        }
    },
    indicatorWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    indicatorDetailsWrapper: {
        // marginLeft: '0.5rem'
    },
    indicatorText: {
        marginLeft: '1em',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    indicatorDetailsText: {
        marginLeft: '0.5em',
    },
    ready: {
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '0.8em',
        padding: '5px 15px',
        color: 'white',
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    }
}));
