import { useDispatch, useSelector } from 'react-redux';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { DriverStyles } from './DriverStyles';
import EditDriversList from 'features/access/AccessGroupList/Cards/Lists/EditListsComponents/EditDriversList';
import { Paper } from '@material-ui/core';

const DriverList = ({ }) => {
    const classes = DriverStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, [key]: val }))
    }

    return (
        <>
            <Paper className={classes.paper}>
                <div className={classes.title}>Drivers</div>
                <EditDriversList driversToEdit={accessToEdit?.drivers} setDriversToEdit={(val) => onChangeValue("drivers", val)} />
            </Paper>
        </>
    );
}

export default DriverList;