import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useGetDecodedVinQuery, useGetVehicleTypesQuery } from '../vehicleSlice';
import { vinDecoderStyle } from './style';

const VinDecode = ({ vin, applyDecode, setDecodedData, openDecoded, setOpenDecoded }) => {

    const classes = vinDecoderStyle();

    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: decoded, error: decodeError, isFetching: isDecoding, isSuccess: decodeEnd } = useGetDecodedVinQuery(vin, { skip: !vin });

    const [decodedDataInternal, setDecodedDataInternal] = useState(false);

    const decodedSuccessfully = () => {
        return !!(decodedDataInternal?.year || decodedDataInternal?.make || decodedDataInternal?.model || decodedDataInternal?.vehicleType);
    }

    useEffect(() => {
        if (decoded) {
            setDecodedData(decoded);
            setDecodedDataInternal(decoded)
        }
    }, [decoded])

    useEffect(() => {
        setDecodedData(null);
        setDecodedDataInternal(null)
    }, [vin])

    const convertToSentenceCase = (str) => {
        if (str)
            return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    };


    return (
        <>
            <Button className={classes.button} variant="contained" color='primary' size="large" disabled={!vin} onClick={() => setOpenDecoded(true)} >
                Decode VIN
            </Button>
            <Dialog open={openDecoded} onClose={() => setOpenDecoded(false)} aria-labelledby='dialog-title'>
                {isDecoding ?
                    <div style={{ minHeight: '10rem' }}>
                        <DialogTitle>Loading...</DialogTitle>
                        <DialogContent>
                            <div style={{ position: 'relative' }}>
                                <LoadingSpinner loading />
                            </div>
                        </DialogContent>
                    </div> :
                    <>
                        <DialogTitle id={'dialog-title'} >{decodedSuccessfully() ? 'Vin Decoding Result' : 'Failed to decode vin'}</DialogTitle>
                        {decodedSuccessfully() && <>
                            <DialogContent>
                                <div><strong>Year:</strong> {decodedDataInternal?.year}</div>
                                <div><strong>Make:</strong> {convertToSentenceCase(decodedDataInternal?.make)}</div>
                                <div><strong>Model:</strong> {decodedDataInternal?.model}</div>
                                {decodedDataInternal?.vehicleType && (
                                    <>
                                        <div><strong>Vehicle Type:</strong> {vehicleTypes?.find(t => t.id == decodedDataInternal?.vehicleType)?.name}</div>
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDecoded(false)} color='primary'>
                                    Cancel
                                </Button>
                                <Button onClick={applyDecode} variant="contained" color='primary'>
                                    Apply
                                </Button>
                            </DialogActions>
                        </>}
                    </>}
            </Dialog>
        </>
    );
};

export default VinDecode;
