import CustomInput from 'components/CustomInput';
import { AccessGroupInfoFields } from 'features/access/accessConsts';
import { StepsStyles } from './StepsStyles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import PhoneInput from 'components/PhoneInput';
import Validator from 'services/validator';

const AccessInfoStep = ({ }) => {
    const classes = StepsStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, [key]: val }))
    }

    return (
        <>
            {AccessGroupInfoFields[accessToEdit?.groupType]?.fields()}
            <Grid container direction='row' spacing={2} className={classes.pocInfo}>
                <Grid item xs={4}>
                    <CustomInput
                        label='POC Name'
                        elementType='input'
                        value={accessToEdit?.pocName}
                        onChange={(val) => onChangeValue("pocName", val)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomInput
                        label='POC email'
                        elementType='input'
                        value={accessToEdit?.pocEmail}
                        valid={Validator.validateEmail(accessToEdit?.pocEmail)}
                        touched={accessToEdit?.pocEmail}
                        onChange={(val) => onChangeValue("pocEmail", val)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PhoneInput
                        label='POC Phone Number'
                        value={accessToEdit?.pocPhone}
                        valid={accessToEdit?.pocPhone && Validator.validatePhone(accessToEdit?.pocPhone)}
                        onChange={(val) => onChangeValue("pocPhone", val)} />
                </Grid>
            </Grid>
        </>
    );
}

export default AccessInfoStep;