import { createSlice } from "@reduxjs/toolkit";

export const cameraEventsStateSlice = createSlice({
    name: 'cameraEventsState',
    initialState: {
        filters: {
            lotId: { value: null },
            clientId: { value: null }
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },

    },
});

export const { setFilters } = cameraEventsStateSlice.actions;
export default cameraEventsStateSlice.reducer;
