import * as React from "react";

function SvgIconFlag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M4.29175 18.4584C4.2616 18.4584 4.23032 18.4461 4.20468 18.4205C4.17903 18.3948 4.16675 18.3636 4.16675 18.3334V1.66675C4.16675 1.6366 4.17903 1.60532 4.20468 1.57968C4.23032 1.55403 4.2616 1.54175 4.29175 1.54175C4.3219 1.54175 4.35318 1.55403 4.37882 1.57968C4.40446 1.60532 4.41675 1.6366 4.41675 1.66675V18.3334C4.41675 18.3636 4.40446 18.3948 4.37882 18.4205C4.35318 18.4461 4.3219 18.4584 4.29175 18.4584Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M13.7036 9.58803L13.6921 9.57652L13.6799 9.56577C13.3837 9.30583 13.1863 8.89739 13.1663 8.42146C13.1471 7.93216 13.3407 7.44136 13.7036 7.07847L14.7036 6.07847C15.0385 5.74359 15.2779 5.42116 15.4221 5.13023C15.4941 4.98488 15.5482 4.83559 15.5732 4.688C15.5973 4.54509 15.5999 4.36847 15.5272 4.19676C15.3945 3.88311 15.0736 3.71286 14.8002 3.62016C14.4946 3.51651 14.0997 3.45825 13.6251 3.45825H4.29175C4.22223 3.45825 4.16675 3.40313 4.16675 3.33325C4.16675 3.3031 4.17903 3.27182 4.20468 3.24618C4.23032 3.22054 4.2616 3.20825 4.29175 3.20825H13.6251C14.469 3.20825 14.9742 3.38334 15.2748 3.56904C15.569 3.75082 15.7009 3.96341 15.7635 4.1089C15.8215 4.25288 15.8791 4.49499 15.799 4.83233C15.7171 5.17721 15.4817 5.66116 14.8799 6.26303L13.8799 7.26303C13.5753 7.56756 13.4046 7.99185 13.4169 8.42253L13.417 8.42491C13.4294 8.7956 13.5782 9.1483 13.8552 9.40372L14.8799 10.4201C14.8801 10.4204 14.8803 10.4206 14.8806 10.4208C15.4681 11.0086 15.6972 11.4851 15.7765 11.8273C15.8538 12.1609 15.7979 12.406 15.7385 12.5575C15.6755 12.6999 15.5407 12.9129 15.248 13.0955C14.9484 13.2823 14.4495 13.4583 13.6251 13.4583H4.29175C4.2616 13.4583 4.23032 13.446 4.20468 13.4203C4.17903 13.3947 4.16675 13.3634 4.16675 13.3333C4.16675 13.3031 4.17903 13.2718 4.20468 13.2462C4.23032 13.2205 4.2616 13.2083 4.29175 13.2083H13.6251C14.1193 13.2083 14.5161 13.1431 14.8186 13.0295C15.0994 12.924 15.3869 12.7416 15.506 12.4439C15.6217 12.1548 15.552 11.8303 15.4255 11.5542C15.2911 11.2608 15.0556 10.9357 14.7025 10.5869C14.7021 10.5865 14.7017 10.5861 14.7013 10.5858L13.7036 9.58803Z" fill="#0090FE" stroke="#0090FE" />
    </svg>
  );
}

export default SvgIconFlag;
