import { apiTags } from 'app/store/apiTags';
import { baseApi } from '../../../app/baseApi';
import { baseQuery } from "../../../app/baseQuery";

const apiPrefix = 'vehicleStatus';

export const vehicleStatusApi = baseApi.injectEndpoints({
    reducerPath: 'vehicleStatusApi',
    tagTypes: [apiTags.VEHICLE_STATUS],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getStatusesForClient: builder.query({
            query: ({clientId}) => `${apiPrefix}/${clientId}`,
            providesTags: [apiTags.VEHICLE_STATUS]
        }),
        getStatusesSlaForClient: builder.query({
            query: ({clientId}) => `${apiPrefix}/sla/${clientId}`,
            providesTags: [apiTags.VEHICLE_STATUS]
        }),
        getAllStatuses: builder.query({
            query: () => `${apiPrefix}/all`,
            providesTags: [apiTags.VEHICLE_STATUS]
        }),
        updateClientStatusesSla: builder.mutation({
            query: body => ({
                url: `${apiPrefix}/sla`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: [apiTags.VEHICLE_STATUS]
        })
    }),
});

export const {
    useGetStatusesForClientQuery,
    useGetStatusesSlaForClientQuery,
    useGetAllStatusesQuery,
    useUpdateClientStatusesSlaMutation
} = vehicleStatusApi;
