
function SvgIconMarket(props, width, height) {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.4138C11.7987 22.4148 11.6055 22.3346 11.4641 22.1913L5.63951 16.3668C3.06368 13.7936 2.292 9.92194 3.68444 6.55783C5.07688 3.19372 8.3591 1 12 1C15.6409 1 18.9231 3.19372 20.3156 6.55783C21.708 9.92194 20.9363 13.7936 18.3605 16.3668L12.5359 22.1913C12.3945 22.3346 12.2013 22.4148 12 22.4138ZM12 2.52338C8.97507 2.5255 6.24892 4.34863 5.09162 7.14342C3.93431 9.93822 4.5735 13.1549 6.71139 15.2949L12 20.5835L17.2886 15.2949C19.4265 13.1549 20.0657 9.93822 18.9084 7.14342C17.7511 4.34863 15.0249 2.5255 12 2.52338Z" fill="#0061a9" />
            <path d="M15.0373 6.55183C14.7067 6.55183 14.4587 6.67581 14.2315 7.04772L12.0103 10.6533L9.79944 7.03739C9.57215 6.67581 9.31388 6.55183 8.98328 6.55183C8.46673 6.55183 8.18779 6.93408 8.18779 7.40931V12.9468C8.18779 13.3497 8.41508 13.5976 8.78699 13.5976C9.16924 13.5976 9.39653 13.3497 9.39653 12.9468V8.68003H9.41719L11.4524 11.924C11.5764 12.1306 11.7727 12.2649 12.0206 12.2649C12.2686 12.2649 12.4649 12.1306 12.5992 11.9033L14.5621 8.71103H14.5827V12.9364C14.5827 13.3497 14.8203 13.5976 15.1923 13.5976C15.5745 13.5976 15.8121 13.3497 15.8121 12.9364V7.37832C15.8121 6.91342 15.5332 6.55183 15.0373 6.55183Z" fill="#0061a9" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79944 7.03739L12.0103 10.6533L14.2315 7.04772C14.4587 6.67581 14.7067 6.55183 15.0373 6.55183C15.5332 6.55183 15.8121 6.91342 15.8121 7.37832V12.9364C15.8121 13.3497 15.5745 13.5976 15.1923 13.5976C14.8203 13.5976 14.5827 13.3497 14.5827 12.9364V8.71103H14.5621L12.5992 11.9033C12.4649 12.1306 12.2686 12.2649 12.0206 12.2649C11.7727 12.2649 11.5764 12.1306 11.4524 11.924L9.41719 8.68003H9.39653V12.9468C9.39653 13.3497 9.16924 13.5976 8.78699 13.5976C8.41508 13.5976 8.18779 13.3497 8.18779 12.9468V7.40931C8.18779 6.93408 8.46673 6.55183 8.98328 6.55183C9.31388 6.55183 9.57215 6.67581 9.79944 7.03739ZM9.58441 9.29994V12.9468C9.58441 13.1827 9.51751 13.3957 9.37263 13.5513C9.22613 13.7086 9.02019 13.7855 8.78704 13.7855C8.5576 13.7855 8.35429 13.7077 8.2097 13.5504C8.06687 13.3951 8 13.1827 8 12.9468V7.40931C8 7.13639 8.08014 6.87525 8.24993 6.67934C8.42268 6.48002 8.67495 6.364 8.98333 6.364C9.17306 6.364 9.354 6.39986 9.52283 6.49564C9.69083 6.59095 9.83297 6.73771 9.95851 6.93743L9.95975 6.9394L12.0109 10.2942L14.0712 6.94978C14.0713 6.94967 14.0712 6.94989 14.0712 6.94978C14.1956 6.74639 14.3346 6.59597 14.5004 6.49841C14.6678 6.39995 14.8474 6.364 15.0373 6.364C15.3342 6.364 15.5808 6.47395 15.7512 6.6661C15.9192 6.85543 16 7.10937 16 7.37832V12.9364C16 13.1785 15.9299 13.3943 15.7822 13.5509C15.6333 13.7088 15.4252 13.7855 15.1923 13.7855C14.9631 13.7855 14.7577 13.7079 14.6106 13.55C14.465 13.3937 14.3949 13.1785 14.3949 12.9364V9.34152L12.76 12.0004C12.5997 12.2709 12.3484 12.4528 12.0207 12.4528C11.6961 12.4528 11.4441 12.2742 11.2924 12.0223L9.58441 9.29994Z" fill="#0061a9" />
        </svg>


    );
}

export default SvgIconMarket;
