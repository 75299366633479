import {Grid, Link} from "@material-ui/core";
import React from "react";
import FormatService from "../../../../../../services/formatService";
import { TaskItemStyles } from "../../TaskItemStyles";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { arrayToObject } from "utils/object-util";
import NotesTooltip from "../../../../../notes/NotesTooltip";
import {NoteEntityTypes} from "../../../../../notes/notesConsts";

const MechanicalTask = ({ task }) => {
    const classes = TaskItemStyles();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }
    const fieldWithLink = (label, value, vehicleId) => {
        return (
            <Grid container direction="row">
                <Grid xs={4}> <label className={classes.label}>{label}</label> </Grid>
                <Grid xs={8}>
                    <Link style={{ color: "#46535d", paddingLeft: 3 }} href={`/assets/detail/${vehicleId}`} target="_blank">{value}</Link>
                </Grid>
            </Grid>
        );
    };
    const notes = (entityId) => {
        return <Grid container direction="row">
            <Grid xs={4}><NotesTooltip entityType={ NoteEntityTypes.ACTIVITY} entityId={entityId} /></Grid>
            <Grid xs={8}></Grid>
        </Grid>;
    }
    const activityItemsLookup = arrayToObject(activityItems);
    
    return (
        <Grid container direction='column'>
            {task?.lot && field('Lot', task?.lot?.name)}
            {field('Activity', activityItemsLookup?.[task.activityItemId]?.name)}
            {!!task?.descriptor && fieldWithLink('VIN', task?.descriptor, task?.vehicleId)}
            {!!task?.assetId && fieldWithLink('Asset Id', task?.assetId, task?.vehicleId)}
            {!!task?.licensePlate && fieldWithLink('License', task?.licensePlate, task?.vehicleId)}
            {field('Date', FormatService.formatDate(task?.activityDate))}
            {notes(task?.id)}
        </Grid>
    )
}
export default MechanicalTask;