import FormatService from "services/formatService";
import { IssueTrackerDateSelectorStyle } from "./style";
import { Chip, Grid, Tooltip } from "@material-ui/core";
import moment from "moment";
import { IconClock, IconWarning } from "icons";
import DatePicker from "components/DatePicker";

export const IssueTrackerDateSelector = ({
    issue,
    field,
    date,
    onChange,
    readonly,
    showEmpty
}) => {
    const classes = IssueTrackerDateSelectorStyle();

    const getClassName = () => {
        let className = '';

        if (field == 'createdDate') {
            className = classes.dateOrange;
        }
        if (field == 'issueDate') {
            className = classes.dateRed;
        }
        if (field == 'solutionEta') {
            className = classes.dateBlue;
        }
        if (field == 'completedDate') {
            className = classes.dateGreen;
        }

        return className;
    }

    return (<>{readonly ?
        <>
            {(showEmpty || date) && <Chip variant="outlined"
                className={`${classes.chip} ${getClassName()}`}
                icon={<IconClock />}
                label={<Grid container spacing={1} wrap="nowrap" >
                    <Grid item>{date ? FormatService?.formatDateNoConversion(date) : 'Not set'}</Grid>
                    {field == 'solutionEta' && issue?.overdue && <Tooltip title='This ticket is overdue'>
                        <Grid item className={classes.overdueIcon}><IconWarning /></Grid>
                    </Tooltip>}
                </Grid>}
                color={"primary"}
            />}

        </>
        :
        <DatePicker
            value={date ? moment(date).format("YYYY-MM-DD") : null}
            onSelect={(val) => onChange(val ? val : null)} />}</>
    )
}
