
function SvgIconSearch(props) {
  return (
    <svg
      width={30}
      height={30}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 13.75a9 9 0 1115.468 6.258 1.017 1.017 0 00-.21.21A9 9 0 014.75 13.75zm16.039 8.453a10.955 10.955 0 01-7.039 2.547c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11a10.96 10.96 0 01-2.547 7.04l4.754 4.753a1 1 0 01-1.414 1.414l-4.754-4.754z"
        fill={props?.fill ?? "#323B42"}
      />
    </svg>
  );
}

export default SvgIconSearch;
