import { Box } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IconSearch } from 'icons';
import { theme } from 'styles/theme';
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { CreateWorkOrderStyles } from '../CreateWorkOrderStyles';
import LotSelectorItem from './LotSelectorItem';

const LotSelector = ({ lots, clientId, isLoading, lot, setLot, isNarrowScreen, disabled = false }) => {
    const classes = CreateWorkOrderStyles();

    let { data: clientsWithConfigs, error: error, isLoading: isLoadingConfigs } = useGetClientsWithConfigsQuery(null);

    const clientsConfigs = clientsWithConfigs?.find(c => c.id == clientId);
    let filteredLots = clientsConfigs?.clientLots.length > 0 ? lots?.filter(lot => clientsConfigs?.clientLots?.find(l => l.lotId == lot.id)) : lots;

    const getOptionLabel = (option) => {
        return `${option.name || ''} ${option.addressLine1 || ''} ${option.zipCode || ''}`;
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        limit: 30,
    });

    return (
        <Autocomplete
            autoComplete
            filterSelectedOptions
            disabled={disabled}
            PaperComponent={Box}
            PopperComponent={Box}
            open
            options={filteredLots}
            value={filteredLots?.find((l) => l.id == lot) ?? null}
            isOptionEqualToValue={(option, value) => {
                return option?.id == value?.id
            }}
            loading={isLoading}
            getOptionLabel={(option) => getOptionLabel(option)}
            filterOptions={filterOptions}
            onChange={(event, newInputValue) => {
                setLot(newInputValue?.id);
            }}
            renderOption={(props, option) => <LotSelectorItem props={props} option={option} isNarrowScreen={isNarrowScreen} />}
            popupIcon={<IconSearch fill={theme.palette.primary.main} className={classes.searchIcon} />}
            renderInput={(params) => (
                <TextField {...params} fullWidth />
            )}
            className={classes.autoComplete}
        />
    );
}

export default LotSelector;