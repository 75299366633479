import React, {useState} from "react";
import {Button, Grid, Paper} from "@material-ui/core";
import Style from "./style";
import CustomInput from "../../../components/CustomInput";
import {useChangePasswordMutation} from "../meSlice";
import NotificationProvider from "../../../components/NotificationProvider";

const MePassword = () => {
    let [password, setPassword] = useState("");
    let [confirmPassword, setConfirmPassword] = useState("");
    let [isSaving, setIsSaving] = useState(false);
    const classes = Style();
    let [updatePassword, {isLoading: isLoadingUpdateStatus, isError, isSuccess}] = useChangePasswordMutation();

    const onSubmit = async () => {
        setIsSaving(true);
        let result = await updatePassword({
            password,
            confirmPassword
        });

        if (result?.error) {
            let errors = result?.error?.data?.errors || [];
            let messages = result?.error?.data?.messages || [];
            let confirmPassword = result.error?.data?.errors?.ConfirmPassword[0] || "";
            let errorShow =false;
            if (confirmPassword !== "") {
                errorShow = true;
                NotificationProvider.error(confirmPassword);
            }
            
            for (let a = 0; a< messages.length ; a++) {
                errorShow = true;
                NotificationProvider.error(messages[a]);
            }
            
            for (let b = 0; b< errors.length ; b++) {
                errorShow = true;
                NotificationProvider.error(errors[b]);
            }
            
            if (!errorShow)
            {
                NotificationProvider.error("Operation failed.");
            }
                       
        } else {
            NotificationProvider.success(`Password change successfully.`);
        }
        setIsSaving(false);
    };

    return (
        <>
            <Paper className={classes.formContainer}>
                <h2>
                    Change Password
                </h2>

                <form id="passwordForm"
                      className={classes.form}>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="password"
                                label="Password"
                                elementType="input"
                                onChange={val => setPassword(val)}
                                required
                                touched
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="confirmpassword"
                                label="Confirm Password"
                                elementType="input"
                                onChange={val => setConfirmPassword(val)}
                                required
                                touched
                            />
                        </div>
                    </Grid>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving}
                        variant="contained"
                        className={classes.button}>
                        {isSaving ? "Saving..." : "Change"}
                    </Button>
                </form>
            </Paper>
        </>
    );
};

export default MePassword;