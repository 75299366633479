
function IconTasks(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6833 7.51666H10.3083C10.2782 7.51666 10.2469 7.50438 10.2212 7.47873C10.1956 7.45309 10.1833 7.42181 10.1833 7.39166C10.1833 7.36152 10.1956 7.33023 10.2212 7.30459C10.2469 7.27895 10.2782 7.26666 10.3083 7.26666H14.6833C14.7135 7.26666 14.7447 7.27895 14.7704 7.30459C14.796 7.33023 14.8083 7.36152 14.8083 7.39166C14.8083 7.46154 14.7528 7.51666 14.6833 7.51666Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M5.57979 7.49524L5.93334 7.84879L6.28689 7.49524L7.72023 6.0619C7.74176 6.04038 7.77249 6.0271 7.80834 6.0271C7.84419 6.0271 7.87493 6.04038 7.89645 6.0619C7.91798 6.08343 7.93126 6.11416 7.93126 6.15002C7.93126 6.18587 7.91798 6.2166 7.89645 6.23813L6.02145 8.11313C5.99749 8.1371 5.96589 8.15002 5.93334 8.15002C5.91566 8.15002 5.90014 8.1468 5.88681 8.14143C5.87396 8.13625 5.85991 8.12781 5.84523 8.11313L5.22023 7.48813C5.1987 7.4666 5.18542 7.43587 5.18542 7.40002C5.18542 7.36417 5.1987 7.33343 5.22023 7.3119C5.24176 7.29038 5.27249 7.2771 5.30834 7.2771C5.34419 7.2771 5.37493 7.29038 5.39645 7.3119L5.75001 6.95835L5.39646 7.3119L5.57979 7.49524Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M14.6833 13.35H10.3083C10.2782 13.35 10.2469 13.3377 10.2212 13.312C10.1956 13.2864 10.1833 13.2551 10.1833 13.225C10.1833 13.1948 10.1956 13.1635 10.2212 13.1379C10.2469 13.1123 10.2782 13.1 10.3083 13.1H14.6833C14.7135 13.1 14.7447 13.1123 14.7704 13.1379C14.796 13.1635 14.8083 13.1948 14.8083 13.225C14.8083 13.2949 14.7528 13.35 14.6833 13.35Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M5.57979 13.3285L5.93334 13.6821L6.28689 13.3285L7.72023 11.8952C7.74176 11.8737 7.77249 11.8604 7.80834 11.8604C7.84419 11.8604 7.87493 11.8737 7.89645 11.8952C7.91798 11.9167 7.93126 11.9475 7.93126 11.9833C7.93126 12.0192 7.91798 12.0499 7.89645 12.0714L6.02145 13.9464C5.99749 13.9704 5.9659 13.9833 5.93334 13.9833C5.91566 13.9833 5.90014 13.9801 5.88681 13.9747C5.87396 13.9696 5.85991 13.9611 5.84523 13.9464L5.22023 13.3214C5.1987 13.2999 5.18542 13.2692 5.18542 13.2333C5.18542 13.1975 5.1987 13.1667 5.22023 13.1452C5.24176 13.1237 5.27249 13.1104 5.30834 13.1104C5.34419 13.1104 5.37493 13.1237 5.39645 13.1452L5.75001 12.7917L5.39646 13.1452L5.57979 13.3285Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M12.5 18.4584H7.49999C5.29489 18.4584 3.84033 17.9853 2.9275 17.0725C2.01467 16.1597 1.54166 14.7051 1.54166 12.5V7.50002C1.54166 5.29492 2.01467 3.84036 2.9275 2.92753C3.84033 2.0147 5.29489 1.54169 7.49999 1.54169H12.5C14.7051 1.54169 16.1596 2.0147 17.0725 2.92753C17.9853 3.84036 18.4583 5.29492 18.4583 7.50002V12.5C18.4583 14.7051 17.9853 16.1597 17.0725 17.0725C16.1596 17.9853 14.7051 18.4584 12.5 18.4584ZM7.49999 1.79169C5.54388 1.79169 4.0689 2.13442 3.10164 3.10168C2.13439 4.06893 1.79166 5.54391 1.79166 7.50002V12.5C1.79166 14.4561 2.13439 15.9311 3.10164 16.8984C4.0689 17.8656 5.54388 18.2084 7.49999 18.2084H12.5C14.4561 18.2084 15.9311 17.8656 16.8983 16.8984C17.8656 15.9311 18.2083 14.4561 18.2083 12.5V7.50002C18.2083 5.54391 17.8656 4.06893 16.8983 3.10168C15.9311 2.13442 14.4561 1.79169 12.5 1.79169H7.49999Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default IconTasks;
