import { makeStyles } from "@material-ui/core";

export const editorStyle = makeStyles((theme) => ({
    editorWrapper: {
        maxWidth: '100%',
        '& .rsw-editor': {
            //background: theme.palette.background.default,
            border: '1px solid #efefef',
            //boxShadow: '1px 2px 7px grey',
            borderRadius: '3px',
            fontFamily: theme.typography.fontFamily,
            maxWidth: '100% !important',
        },
        '& .rsw-toolbar': {
            padding: '0.5em',
            background: theme.palette.background.default
        }
    }
}));