import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import style from "./style";

export const LotsMarketsTreeView = ({
    data,
    onSelect
}) => {
    const classes = style();
    const treeItem = (item, level) => {
        return (<TreeItem
            className={classes.treeItem}
            nodeId={`${item.id}${item.isSubMarket ? `_submarket` : ``}`}
            label={item.name}
            onClick={(e) => {
                if (!item.blockSelection && !e.target.classList.contains('MuiSvgIcon-root')) {//item.selectable &&
                    onSelect(item)
                }
            }}>
            {!!item?.lots?.length && item?.lots?.map(lot => treeItem(lot, level + 1))}
        </TreeItem >)
    }

    const Icon = ({ icon }) => {
        return (icon)
    }
    return (
        <TreeView
            defaultCollapseIcon={<Icon icon={<ExpandMoreIcon />} />}
            defaultExpandIcon={<Icon icon={<ChevronRightIcon />} />}
        >
            {data?.map(item => treeItem(item, 1))}
        </TreeView>
    )
}
