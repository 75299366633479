import { Button, Checkbox, Paper, TextField } from "@material-ui/core";
import React, { useState } from 'react';
import configStyles from "../../../configStyles";
import { calcTypeEnum } from "./TransportsEnums";
import { v4 as uuid } from 'uuid'
import CustomInput from "../../../../../../components/CustomInput";

const useStyles = configStyles;

const EditMovementType = ({ setAddNewMovementType, movementType, onConfigChange, localMovementTypes, setLocalMovementTypes }) => {
    const [typeName, setTypeName] = useState(movementType?.name);
    const [calcTypeId, setCalcTypeId] = useState(movementType?.calcType || 1);
    const [useMinPickup, setUseMinPickup] = useState(movementType?.minPickup > 0);
    const [minPickup, setMinPickup] = useState(movementType?.minPickup);
    const [useMaxDelivery, setUseMaxDelivery] = useState(movementType?.maxDelivery > 0);
    const [maxDelivery, setMaxDelivery] = useState(movementType?.maxDelivery);

    const onAddMovementType = () => {
        let newLocalMovementTypes = localMovementTypes || [];
        if (movementType) {
            newLocalMovementTypes = localMovementTypes.map(m =>
                m.id == movementType.id ? {
                    "id": m.id,
                    "calcType": calcTypeId,
                    "minPickup": useMinPickup ? minPickup : 0,
                    "maxDelivery": useMaxDelivery ? maxDelivery : 0,
                    "name": typeName
                } : m
            );
        }
        else {
            newLocalMovementTypes = [...newLocalMovementTypes,
            {
                "calcType": calcTypeId,
                "minPickup": useMinPickup ? minPickup : 0,
                "maxDelivery": useMaxDelivery ? maxDelivery : 0,
                "name": typeName
            }];
        }
        setLocalMovementTypes(newLocalMovementTypes);
        onConfigChange(undefined, "loadMoveTypes", newLocalMovementTypes);
        setAddNewMovementType(true);
    };

    const classes = useStyles();

    return (
        <Paper className={classes.card}>

            <CustomInput
                className={classes.inputText}
                label={"name"}
                elementType="input"
                value={typeName}
                required={true}
                onChange={(e) => { setTypeName(e) }}
            />

            <div>
                {
                    calcTypeEnum?.map((item) => (
                        <div className={classes.inputWrapper}>
                            <input className={classes.radioButton} type="radio" checked={calcTypeId == item.id} onChange={(e) => { if (e.target.value) setCalcTypeId(item.id) }} />
                            <div className={classes.radioButtonLabel}>
                                <CustomInput value={item.name} />
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className={classes.rectangle} fullWidth variant='outlined' value={""} />

            <div className={classes.inputWrapper}>
                <Checkbox checked={useMinPickup} onChange={(e, value) => { setUseMinPickup(value) }} />
                <div>{"Pickup cannot be less than"}</div>
                <div className={classes.inputNumberWrapper}>
                    <TextField InputProps={{ className: `${classes.inputNumber}`, type: 'number', step: 1 }}
                        value={minPickup} onChange={(e) => setMinPickup(e.target.value)}
                        inputProps={{ min: 1 }}
                    />
                </div>
                <div>{"days after transport Request"}</div>
            </div>

            <div className={classes.inputWrapper}>
                <Checkbox checked={useMaxDelivery} onChange={(e, value) => { setUseMaxDelivery(value) }} />
                <div>{"Delivery no more than"}</div>
                <div className={classes.inputNumberWrapper}>
                    <TextField InputProps={{ className: `${classes.inputNumber}`, type: 'number', step: 1, }}
                        value={maxDelivery} onChange={(e) => setMaxDelivery(e.target.value)}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div>{"days after pickup"}</div>
            </div>

            <div className={classes.selectActionButtons}>
                <Button onClick={onAddMovementType} color="primary" variant="contained" disabled={!typeName}>
                    {"Done"}
                </Button>
            </div>
        </Paper>
    );
};

export default EditMovementType;
