import { Button, Dialog, DialogContent, Grid, IconButton, Tooltip } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DataListHeader from "../../../components/DataListHeader";
import DataTable from "../../../components/DataTable/DataTable";
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import PageComponent from '../../../components/Page/PageComponent';
import { useGetLotsQuery } from "../../../features/lots/lotSlice";
import useSortOptions from "../../../hooks/useSortOptions";
import FilterService, { fieldTypes } from "../../../services/filterService";
import FormatService from '../../../services/formatService';
import { useGetCameraEventsQuery } from './cameraEventSlice';
import { setFilters } from './cameraEventsStateSlice';
import { Carousel } from 'react-responsive-carousel';
import { arrayToObject } from 'utils/object-util';
import { useState } from 'react';
import { IconImage, IconVehicle } from 'icons';
import { cameraEventsStyle } from './style';
import CachedIcon from '@material-ui/icons/Cached';

const CameraEventsList = () => {
    const dispatch = useDispatch();
    const classes = cameraEventsStyle();

    const [spin, setSpin] = useState(false);

    let { data: cameraEvents, error: doorReleaseError, isFetching: isLoadingEvents, refetch } = useGetCameraEventsQuery({}, { pollingInterval: 600000 });
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);

    cameraEvents = cameraEvents || [];
    clients = clients || [];
    lots = lots || [];

    const [showImagesForEvent, setShowImages] = useState();



    const mappedEvents = cameraEvents?.map(event => {
        return {
            ...event,
            lotName: lots?.find(l => l.id == event.lotId)?.name,
            date: FormatService.formatDateTimeNoConversion(event?.createdDate),
            imagesParsed: JSON.parse(event.images ?? "[]")
        }
    });

    const dataLookup = arrayToObject(mappedEvents);

    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.cameraEventsState);

    let columns = [
        { name: "Lot", key: "lotName" },
        { name: "Vin", key: "vin" },
        { name: "Asset Id", key: "assetId" },
        { name: "License Plate", key: "licensePlate" },
        { name: "Log Id", key: "logId" },
        { name: "Log Type", key: "logType" },
        { name: "Date", key: "date" },

    ];

    const filterConfig = [
        { type: fieldTypes.LOT, size: 3, title: 'Lot', key: 'lotId', values: lots, pmfLot: true },
    ];

    let filteredData = FilterService.filter(filters, '', mappedEvents, filterConfig);


    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };


    const isLoading = () => isLoadingEvents;

    const reloadData = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
        refetch();
        return false;
    }

    return (
        <Authorize profile={permissionProfiles.ACCESS.CAMERA_EVENTS_LIST}>
            <PageComponent
                header={<DataListHeader
                    title={'Camera Events'}
                    data={filteredData}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <Grid container spacing={1}>
                            <IconButton onClick={reloadData} size={"small"} variant="outlined" >
                                <CachedIcon
                                    className={`${classes.icon} ${spin ? classes.spin : classes.refresh}`}
                                    spin={spin} />
                            </IconButton>
                            {/* <Grid item><ExportButton fileLabel="Camera Events" data={filteredData} mapExportData={mapExportData} /></Grid> */}
                        </Grid>
                    )}
                />}
            >
                <>
                    {
                        isLoading() && <LoadingSpinner loading={isLoading()} />}
                    <DataTable
                        columns={columns}
                        rows={orderBy(
                            filteredData,
                            sortOptions.columnToSort,
                            sortOptions.sortDirection
                        )}
                        rowIdentifier='id'
                        onSort={sortData}
                        sortDirection={sortOptions.sortDirection}
                        columnToSort={sortOptions.columnToSort}
                        noItemsMessage='There are no events to display'
                        maxActionCount={1}
                        actions={(id) => {
                            const actions = [

                            ];
                            if (dataLookup?.[id]?.imagesParsed?.length) {
                                actions.push(
                                    {
                                        component: <Tooltip title='View Images'>
                                            <IconButton className={classes.action} onClick={() => setShowImages(id)} variant='outlined' >
                                                <IconImage />
                                            </IconButton></Tooltip>
                                    }
                                );
                            }
                            if (dataLookup?.[id]?.matchedVehicleId) {
                                actions.push({
                                    component: <Tooltip title='View Asset'><IconButton className={classes.action} color="primary" variant="outlined" href={`/assets/detail/${dataLookup?.[id]?.matchedVehicleId}`}
                                        target="_blank"><IconVehicle /></IconButton>
                                    </Tooltip>
                                });
                            }
                            return actions;
                        }
                        }
                    />


                </>
                {showImagesForEvent && <ImagesViewer images={dataLookup?.[showImagesForEvent]?.imagesParsed ?? []} onClose={() => setShowImages()} />}
            </PageComponent>
        </Authorize>
    );
};

export default CameraEventsList;

const ImagesViewer = ({ images, onClose }) => {
    return <Dialog open onClose={onClose} fullWidth maxWidth='md'>
        <DialogContent>
            <Carousel dynamicHeight autoPlay={false} useKeyboardArrows infiniteLoop>
                {
                    images?.map((img, i) =>
                        <div key={i} onClick={() => window.open(img)}>
                            <img
                                src={img}
                                alt=""
                            />
                            <p className="legend">{img} - Click here to open the photo.</p>
                        </div>
                    )
                }
            </Carousel>
        </DialogContent>
    </Dialog>
}
