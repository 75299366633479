import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { IconAccessCode, IconLot } from 'icons';
import { useState } from 'react';
import { useGetAccessCodesQuery } from '../accessSlice';
import { AccessCodeListStyle } from './style';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import LoadingSpinner from 'components/LoadingSpinner';
import FormatService from 'services/formatService';
import AccessCodeView from './AccessCodeView';

const AccessCodeList = ({ accessGroup, vehicleId = undefined, btnStyle = 'outlined' }) => {
    const classes = AccessCodeListStyle()

    const [dialogOpen, setDialogOpen] = useState(false);

    let { data: accessCodes, error, isLoading } = useGetAccessCodesQuery(accessGroup?.id, { skip: !dialogOpen });



    let accessCodeData = accessCodes?.map(a => ({ ...a?.lot, lotName: a?.lot?.name, ...a }))

    if (vehicleId) {
        accessCodeData = accessCodeData?.filter(a => a.vehicleId == vehicleId);
    } else {
        accessCodeData = accessCodeData?.filter(a => a.vehicleId == null);
    }

    return (
        <>
            <Button
                variant={btnStyle}
                color="primary"
                startIcon={<IconAccessCode width={20} className={classes.icon} />}
                onClick={() => setDialogOpen(true)}
            >
                View Access Codes
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => { }}
                fullWidth
                maxWidth='sm'>
                <DialogTitle>{`Access Codes For ${vehicleId ? 'Vehicle' : 'Group'}`}</DialogTitle>
                <DialogContent>
                    {!accessCodeData?.length && <EmptyPlaceholder text='No Access Codes Found' />}
                    <LoadingSpinner loading={isLoading} />
                    {accessCodeData?.map((accessCode, index) =>
                        <AccessCodeView accessCode={accessCode} key={index} />)}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDialogOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AccessCodeList;