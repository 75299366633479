import * as React from "react";

function IconReplaceWindshield(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6289 4.875C3.51852 4.875 3.3739 4.98339 3.3739 5.175V5.22865L1.87615 13.0094C1.89468 13.1782 2.02381 13.2675 2.1289 13.2675H15.8614C15.9636 13.2675 16.095 13.1747 16.1141 13.009L14.6164 5.22865V5.175C14.6164 4.97916 14.4749 4.875 14.3614 4.875H3.6289ZM2.25016 5.11483C2.28057 4.38622 2.86037 3.75 3.6289 3.75H14.3614C15.1273 3.75 15.7101 4.37659 15.7402 5.11501L17.2414 12.9139V12.9675C17.2414 13.7209 16.651 14.3925 15.8614 14.3925H2.1289C1.34238 14.3925 0.748901 13.7317 0.748901 12.9675V12.9139L2.25016 5.11483ZM6.34717 8.71779L5.70664 8.07726C5.48697 7.85759 5.13081 7.85759 4.91114 8.07726C4.69147 8.29693 4.69147 8.65308 4.91114 8.87275L6.22364 10.1853C6.59331 10.5549 7.18197 10.5549 7.55164 10.1853L8.87164 8.86525C9.09131 8.64558 9.09131 8.28943 8.87164 8.06976C8.65197 7.85009 8.29581 7.85009 8.07614 8.06976L7.49191 8.65399C7.69048 7.56764 8.64328 6.7425 9.7864 6.7425C11.0732 6.7425 12.1189 7.78816 12.1189 9.075C12.1189 10.3618 11.0732 11.4075 9.7864 11.4075C9.47574 11.4075 9.2239 11.6593 9.2239 11.97C9.2239 12.2807 9.47574 12.5325 9.7864 12.5325C11.6946 12.5325 13.2439 10.9832 13.2439 9.075C13.2439 7.16684 11.6946 5.6175 9.7864 5.6175C7.99885 5.6175 6.52619 6.97718 6.34717 8.71779Z" fill="#0090FE" />
        </svg>

    );
}

export default IconReplaceWindshield;
