import { Button, Checkbox, FormControlLabel, Grid, IconButton, Popover, Tooltip } from "@material-ui/core";
import { Alert } from '@mui/material';
import { IconSettings } from "icons";
import { useEffect, useState } from "react";
import { columnCustomizationPanelStyle } from "./style";
import TableViewsSelector from "../TableViews";

const TableCustomizationPanel = ({
    columns,
    selectedColumns,
    onSaveColumns,
    isCustomized,
    onReset,
    resetting,
    title='Customize View',
    showTip
}) => {


    const classes = columnCustomizationPanelStyle();
    const [configureColumns, setConfigureColumns] = useState(false);
    const [checkedChanged, setCheckedChanged] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [selectedColumnKeys, setSelectedColumnKeys] = useState(selectedColumns?.map(s => s.key));

    useEffect(() => {
        setSelectedColumnKeys(selectedColumns?.map(s => s.key));
    }, [selectedColumns]);


    const onSelectField = (column, value) => {
        if (value) {
            setSelectedColumnKeys([...selectedColumnKeys.filter(s => s != column.key), column.key])
        }
        else {
            setSelectedColumnKeys([...selectedColumnKeys.filter(s => s != column.key)])
        }
        setCheckedChanged(true);
    }

    const checked = (column) => {
        return !!selectedColumnKeys?.includes(column?.key);
    }

    const save = async () => {
        onSaveColumns(columns.filter(s => selectedColumnKeys.includes(s.key)));
        setCheckedChanged(false);
        closePanel();
    }

    const openPanel = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closePanel = (e) => {
        setCheckedChanged(false);
        setSelectedColumnKeys(selectedColumns?.map(s => s.key));
        setAnchorEl();
    }

    return (
        <>
            <Tooltip title='Customize Table'>
                <IconButton className={classes.toggleButton} onClick={openPanel}>
                    <IconSettings />
                </IconButton>
            </Tooltip>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closePanel}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={classes.content}>
                    <Grid container className={classes.title} justifyContent="space-between" alignItems="center">
                        <div>{title}</div>
                        {isCustomized &&
                            <Tooltip title='You are using a personalized view. Click to reset to the default view.'>
                                <Button color="primary" onClick={onReset}>
                                    {resetting ? 'Resetting...' : 'Reset'}
                                </Button>
                            </Tooltip>
                        }
                    </Grid>
                    <ConfigureColumns checkedChanged={checkedChanged} columns={columns} selectedColumnKeys={selectedColumnKeys} onSelectField={onSelectField} save={save} close={() => setConfigureColumns(false)} />
                    {showTip && <Alert severity='info' className={classes.alert}>
                        Tip: Drag and drop the column headers in the table to customize the order of the columns
                    </Alert>}
                </div>
            </Popover>
        </>
    )
}
export default TableCustomizationPanel;

const ConfigureColumns = ({ columns, selectedColumnKeys, onSelectField, save, checkedChanged }) => {
    const classes = columnCustomizationPanelStyle();

    const checked = (column) => {
        return !!selectedColumnKeys?.includes(column?.key);
    }

    let columnsWithSelected = columns?.filter(c => c.customizable !== false).map(c => { return { ...c, ["selected"]: checked(c) } })

    return (<div className={classes.columnContainer}>
        <Grid container direction='column' className={classes.columnList}>
            {columnsWithSelected?.filter(col => !col.hideForSelection).map((column, index) => (
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox
                            checked={column.selected}
                            onChange={(e, value) => onSelectField(column, value)}
                        />}
                        label={column.name}
                    />
                </Grid>
            ))}
        </Grid>
        <Grid container justifyContent="flex-end">
            <Button
                onClick={() => save()}
                variant='outlined'
                color='primary'
                disabled={!checkedChanged}
            >
                Apply
            </Button>
        </Grid>
    </div>)
}