import React from "react";
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import FadeInImageCustom from "./FadeInImageCustom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { vehicleDetailsPageStyle } from "../../VehicleDetailsPage/VehicleDetailsPageStyle";

const Carrousel = ({ 
    images,
    onDelete,
    onEdit,
    imageTypes,
    changeImageTypeId,
    existingImages,
    defaultValue = 0,
    noteType }) => {
    const classes = vehicleDetailsPageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    
    return (
        <div>
           
            <Carousel
                autoPlay={false} useKeyboardArrows selectedItem={defaultValue}>
            {
                
                images.map((img, i) =>
                    <Grid item key={i} className={isMobile && classes.imageMobile}>                        
                        <FadeInImageCustom
                            img={img}
                            alt={`Vehicle Image ${i}`}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            imageTypes={imageTypes}
                            changeImageTypeId={changeImageTypeId}
                            existingImages={existingImages}
                            imgHeight={"400px !important"}
                            imgWidth={"400px !important"}
                            noteType={noteType}
                        />
                        
                    </Grid>
                )
            }
            </Carousel>
        </div>
       
    );
};

export default Carrousel;
