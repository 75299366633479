import { Button, Card, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, Switch, Tooltip } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useEffect, useState } from "react";
import LotsConfigStyle from "../LotsConfigStyle";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetStatusesSlaForClientQuery, useUpdateClientStatusesSlaMutation } from "features/vehicles/VehicleStatus/vehicleStatusSlice";
import AddIcon from '@material-ui/icons/Add';
import { IconDelete } from "icons";
import LoadingSpinner from "components/LoadingSpinner";
import { sortBy } from "lodash";

const useStyles = LotsConfigStyle;

const VehicleStatuses = ({ clientId }) => {
    const classes = useStyles();

    let { data: clientStatuses, error: clientStatusesError, isFetching: isLoadingClientStatuses } = useGetStatusesSlaForClientQuery({ clientId: clientId })
    let [saveStatuses, { isLoading: isSaving }] = useUpdateClientStatusesSlaMutation();

    clientStatuses = clientStatuses || []

    const [aliases, setAliases] = useState(sortBy(clientStatuses, [cs => cs.canEdit]));
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setAliases(sortBy(clientStatuses, [cs => cs.canEdit]));
    }, [clientStatuses])

    const onChange = (index, key, val) => {
        setEdited(true);
        let aliasesToUpdate = aliases?.length > 0 ? [...aliases] : [];

        let updatedAlias = { ...aliasesToUpdate[index], [key]: val };
        aliasesToUpdate[index] = updatedAlias;

        setAliases(aliasesToUpdate);
    }

    const onAddStatus = () => {
        setEdited(true);
        let aliasesToUpdate = aliases?.length > 0 ? [...aliases] : [];
        aliasesToUpdate.push({ id: 0, clientId: clientId, isPmf: false, canEdit: true });
        setAliases(aliasesToUpdate);
    }

    const onDeleteStatus = (index) => {
        setEdited(true);
        let aliasesToUpdate = aliases?.length > 0 ? [...aliases] : [];
        aliasesToUpdate.splice(index, 1);
        setAliases(aliasesToUpdate);
    }

    const onSave = async () => {
        let result = await saveStatuses({ clientId: clientId, statuses: aliases });
        if (!result.error) {
            NotificationProvider.success('Aliases saved successfully');
            setEdited(false);
        } else {
            NotificationProvider.error('Failed to save the aliases');
        }
    }

    return (
        <>
            <LoadingSpinner loading={isLoadingClientStatuses} />
            <List>
                {aliases?.map((status, index) =>
                    <Card className={classes.listItemStatuses} >
                        <ListItem key={status?.id} >
                            {status.isPmf &&
                                <FormControlLabel
                                    control={
                                        <Tooltip title={status.canEdit ? `${status?.disabled ? "Enable" : "Disable"} this status for this client` : "This status is a system status and can not be turned off"}>
                                            <div>
                                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_VIEW}>
                                                    <Switch
                                                        color="primary"
                                                        checked={!status?.disabled}
                                                        onChange={(e, value) => onChange(index, "disabled", !value)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        disabled={!status.canEdit}
                                                    />
                                                </Authorize>
                                            </div>
                                        </Tooltip>
                                    }
                                />
                            }

                            <ListItemText primary={status?.isPmf ?
                                <div className={classes.statusLabel}>
                                    <span>{status?.label}</span> <span className={`${status?.onLot ? classes.onLotIndicator : classes.offLotIndicator} ${classes.readyBadge}`}>{status?.onLot ? 'On Lot' : 'Off Lot'}</span>
                                </div> :
                                <div className={classes.listItemText} >
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_VIEW} readonlyComponent={<><div className={classes.alias}>{status?.label}</div></>}>
                                        <CustomInput
                                            id="status"
                                            label='Enter Status'
                                            value={status?.label}
                                            onChange={val => onChange(index, "label", val)}
                                            elementType='input'
                                            shrink
                                            fullWidth={false}
                                        />
                                    </Authorize>
                                    <FormControlLabel
                                        control={
                                            <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_VIEW}>
                                                <Switch
                                                    color="primary"
                                                    checked={status?.onLot}
                                                    onChange={(e, value) => onChange(index, "onLot", value)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Authorize>
                                        }
                                        label="On Lot"
                                        className={classes.onLot}
                                    />

                                </div>
                            } />
                            {status?.isPmf ?
                                <div>
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_VIEW} readonlyComponent={<><div className={classes.alias}>{status?.alias}</div></>}>
                                        <CustomInput
                                            id="alias"
                                            label='Enter Alias'
                                            value={status?.alias}
                                            onChange={val => onChange(index, "alias", val == "" ? null : val)}
                                            elementType='input'
                                            shrink
                                            disabled={status?.disabled}
                                        />
                                    </Authorize>
                                </div> :
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_EDIT}>
                                    <IconButton onClick={() => onDeleteStatus(index)} ><IconDelete /></IconButton>
                                </Authorize>
                            }
                        </ListItem>
                    </Card>
                )}
            </List>
            <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_EDIT}>
                <Button
                    onClick={onAddStatus}
                    color="primary"
                    variant='outlined'
                    startIcon={<AddIcon />}
                    disabled={isLoadingClientStatuses}
                >
                    Add Status
                </Button>
            </Authorize>

            <Grid className={classes.save}>
                <Authorize profile={permissionProfiles.CLIENT_CONFIG.VEHICLE_STATUSES_EDIT}>
                    <Button
                        onClick={onSave}
                        color="primary"
                        variant='contained'
                        disabled={isLoadingClientStatuses || !edited || isSaving}
                        style={{ width: '100px' }}
                    >
                        Save
                    </Button>
                </Authorize>
            </Grid>
        </>
    )
}

export default VehicleStatuses;