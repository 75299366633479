import React from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useState } from "react";
import { KpiInspectionStyles } from "features/landing/components/KpiDashboard/navigations/KpiInspection/KpiInspectionStyle";
import BasicModal from "components/BasicModal";
import InspectionForm from "features/landing/components/KpiDashboard/navigations/KpiInspection/InspectionReports/ReportDialog/InspectionForm";
import { Button, Grid, Paper } from "@material-ui/core";
import PageComponent from "components/Page/PageComponent";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetInspectionsByIdQuery } from "features/landing/components/KpiDashboard/navigations/KpiInspection/inspectionSlice";

const NewLotInspection = () => {

    const classes = KpiInspectionStyles();

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    let { id } = useParams();
    let { data: inspection, error: inspectionError, isLoading: isLoadingInspection } = useGetInspectionsByIdQuery(id);
    
    if (id === "new") {
        inspection = null;
    };

    let newInspection = {
        id: inspection?.id,
        userId: inspection?.userId ?? 0,
        createdDate: inspection?.createdDate ?? new Date(),
        inspectionDate: inspection?.inspectionDate ?? new Date(),
        numberOfTrucks: inspection?.numberOfTrucks ?? "",
        isBatchromCleaned: inspection?.isBatchromCleaned.toString() ?? "true",
        bathroom: inspection?.bathroom ?? "",
        isGateSecured: inspection?.isGateSecured.toString() ?? "true",
        gateSecurity: inspection?.gateSecurity ?? "",
        isOfficeCleaned: inspection?.isOfficeCleaned.toString() ?? "true",
        office: inspection?.office ?? "",
        isYardCleaned: inspection?.isYardCleaned.toString() ?? "true",
        yard: inspection?.yard ?? "",
        isThereAnyTrash: inspection?.isThereAnyTrash.toString() ?? "true",
        trashRemoval: inspection?.trashRemoval ?? "",
        isParkAIWorking: inspection?.isParkAIWorking.toString() ?? "true",
        parqAI: inspection?.parqAI ?? "",
        otherIssuesReport: inspection?.otherIssuesReport ?? "",
        hoursVerification: inspection?.hoursVerification.toString() ?? "true",
        inspectionDocuments: [],
        lotId: inspection?.lotId
    }

    const isLoadingDashboardData = () => isLoadingLots;

    return (
        <Authorize profile={permissionProfiles.DASHBOARD.CREATE_INSPECTION}>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <>
                        <Paper className={`${classes.card} ${classes.dashboard}`} elevation={1}>
                            <div className={classes.newInspectionTitle}>{inspection ? `Edit Inspection` : "New Inspection form"}</div>
                            <InspectionForm 
                                    lotId={inspection?.lotId ?? 0} 
                                    inspection={newInspection} 
                                    handleClose={undefined}/>
                        </Paper>
                    </>
            }
        </Authorize>
        
    );
};

export default NewLotInspection;
