import { Grid } from "@material-ui/core";
import ExportButton from "components/ExportButton/ExportButton";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { IconDownload } from 'icons';
import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { totalAssetsStyle } from "./style";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";

const TotalAssets = ({ onLot = false, outOfPmfResponsibilityId }) => {
    const classes = totalAssetsStyle();

    let { data: vehiclesData, error: vehicleError, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: onLot ? false : true });
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    const pmfLots = lots?.filter(l => l.pmfLot)?.map(l => l.id);

    const { filters } = useSelector(state => state.dashboardState);

    let filteredData = vehiclesData?.filter(v =>
        (!filters.clientId || filters.clientId == v.clientId) &&
        v.vehicleStatusId != outOfPmfResponsibilityId &&
        pmfLots?.includes(v.lotId)
    );

    const groupedToClient = groupBy(filteredData, 'clientId');
    const dataForExport = Object.keys(groupedToClient).map(key => ({ clientId: key, count: groupedToClient[key]?.length }))

    const mapExportData = row => {
        return ({
            client: clients?.find(c => c.id == row.clientId)?.name,
            count: row.count
        })
    };

    return (
        <>
            <Grid className={classes.wrapper} container direction="column">
                <div className={classes.exportBtn}>
                    <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={dataForExport} mapExportData={mapExportData} />
                </div>
                <Grid item className={`${classes.count} ${classes.countBlue}`}>{filteredData?.length || 0}</Grid>
                <Grid item><h3 className={classes.text}>{onLot ? 'Assets on PMF Lots Now' : 'Total Assets Under PMF Management'}</h3></Grid>
            </Grid>
        </>
    )
}

export default TotalAssets;