import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    activityText: {
        marginLeft: '0.5rem'
    },
    ectivityTextHeader: {
        fontWeight: 600
    },
    dialogWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dialogTitle: {
        fontSize: '1.1rem',
        marginRight: '3rem'
    },
    tabs: {
        padding: '2em 0',
    },
    paper: {
        padding: '1rem 2rem'
    }
}));
