import { Button, Card, CircularProgress, Grid, IconButton, List, ListItem, ListItemSecondaryAction, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { useGetBusinessUnitsQuery, useSaveBusinessUnitsMutation } from 'features/clients/clientConfigSlice';
import { useEffect, useState } from "react";
import CustomInput from '../../../../../components/CustomInput';
import NotificationProvider from '../../../../../components/NotificationProvider';
import configStyles from "../../../clientConfigs/configStyles";
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';

const useStyles = configStyles;

const BusinessUnits = ({ clientToEdit }) => {
    const classes = useStyles();

    let [saveBusinessUnits] = useSaveBusinessUnitsMutation();
    let { data: businessUnits, error: businessUnitsError, isLoading: isLoadingbusinessUnits, isSuccess: businessUnitsSuccess } = useGetBusinessUnitsQuery();

    const [businessUnitsList, setBusinessUnitsList] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [disableSave, setDisableSave] = useState(false);

    useEffect(() => {
        if (businessUnits)
            setBusinessUnitsList(businessUnits?.filter(u => u.clientId == clientToEdit?.id));
    }, [isLoadingbusinessUnits]);

    const handleEdit = (index, e) => {
        setShowSave(true);
        let updatedList = businessUnitsList?.map(s => ({ ...s }));
        let updatedItem = updatedList[index];
        updatedItem.name = e;
        updatedList[index] = { ...updatedItem };
        setBusinessUnitsList(updatedList);
    };

    const handleDelete = (index) => {
        setShowSave(true);
        let newList = businessUnitsList?.map(s => ({ ...s }));
        let itemToDelete = newList[index];
        newList = newList.filter(item => item != itemToDelete);
        setBusinessUnitsList(newList);
    };

    const handleAdd = () => {
        setShowSave(true);
        const newItemObject = { id: 0, name: '', clientId: clientToEdit?.id, type: 1 };
        const updatedList = [...businessUnitsList, newItemObject];
        setBusinessUnitsList(updatedList);
    };

    const handleSave = async () => {
        setDisableSave(true);

        let businessUnitsToSave = {
            type: 1,
            clientId: clientToEdit?.id,
            clientValues: businessUnitsList
        }

        let res = await saveBusinessUnits(businessUnitsToSave);

        setDisableSave(false);

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }
    }

    return (
        <Paper className={classes.card}>
            {isLoadingbusinessUnits ? <CircularProgress /> : <>
                <Authorize profile={permissionProfiles.CLIENT_CONFIG.BUSINESS_UNITS_EDIT}>
                    <Grid container>
                        <Grid item xs={9}>
                            <div className={classes.title}>Business Units:</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.addButton}>
                                <Button
                                    disabled={false}
                                    variant="contained"
                                    onClick={handleAdd}
                                >
                                    Add Business Unit
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction='column'>
                        <Grid item className={classes.businessUnitWrapper}>
                            <List>
                                {
                                    businessUnitsList?.map((item, index) =>
                                        <ListItem key={index} >
                                            <CustomInput
                                                elementType="input"
                                                value={item.name}
                                                required={true}
                                                onChange={(e) => handleEdit(index, e)}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete"
                                                    onClick={() => handleDelete(index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            </List>
                            {!(businessUnitsList?.length) && <EmptyPlaceholder text="No Business Units" />}
                        </Grid>
                    </Grid>
                    <Grid container>
                        {showSave && <Grid item xs={12}>
                            <div className={classes.saveButton}>
                                <Button
                                    disabled={disableSave}
                                    variant="contained"
                                    color='primary'
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>}
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.CLIENT_CONFIG.BUSINESS_UNITS_VIEW}>
                    <Grid container direction='column'>
                        <Grid item xs={9}>
                            <div className={classes.title}>Business Units:</div>
                        </Grid>
                        <List>
                            {businessUnitsList?.map((item, index) =>
                                <Card className={classes.listItem}>
                                    <ListItem key={index} className={classes.spacing}>
                                        {item.name}
                                    </ListItem>
                                </Card>
                            )}
                        </List>
                        {!(businessUnitsList?.length) && <EmptyPlaceholder text="No Business Units" />}
                    </Grid>
                </Authorize>
            </>}
        </Paper>
    );
};

export default BusinessUnits;
