import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';

const apiPrefix = 'unitMeasure';
export const unitMeasureApi = baseApi.injectEndpoints({
    reducerPath: 'unitMeasureApi',
    tagTypes: [apiTags.UNIT_MEASURE],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllUnitMeasure: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.UNIT_MEASURE],
        }),
        addUnitMeasure: builder.mutation({
            query: (unitMeasure) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: unitMeasure
            }),
            invalidatesTags: [apiTags.UNIT_MEASURE],
        }),
        updateUnitMeasure: builder.mutation({
            query: (unitMeasure) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: unitMeasure
            }),
            invalidatesTags: [apiTags.UNIT_MEASURE],
        })
    }),
});

export const {
    useGetAllUnitMeasureQuery,
    useAddUnitMeasureMutation,
    useUpdateUnitMeasureMutation
} = unitMeasureApi;