import { Button, ButtonGroup, Grid, Menu, MenuItem, Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@mui/material/Popover';
import React, { useState } from "react";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import DataSearchBar from "../../../../components/DataSearchBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NotificationProvider from "../../../../components/NotificationProvider";
import { IconMarket } from "../../../../icons";
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import { useGetLotsQuery } from "../../lotSlice";
import SubMarketDialog from "../SubMarketDialog/SubMarketDialog";
import { useAddSubMrketMutation, useDeleteSubMarketMutation, useGetSubMarketsQuery, useUpdateSubMrketMutation } from "./lotSubMarketsSlice";
import { LotSubMarketStyle } from "./lotSubMarketStyle";
import usePrompt from "../../../../hooks/usePrompt";

const LotSubMarkets = () => {
    const classes = LotSubMarketStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: subMarkets, error: subMarketsError, isLoading: isLoadingSubMarkets } = useGetSubMarketsQuery();
    let [addSubMarket, { isLoading: addSubMarketLoading }] = useAddSubMrketMutation();
    let [updateSubMarket, { isLoading: updateSubMarketLoading }] = useUpdateSubMrketMutation();
    let [deleteSubMarket, { isLoading: deleteSubMarketLoading }] = useDeleteSubMarketMutation();

    subMarkets = subMarkets || [];
    lots = lots || [];

    const [searchedVal, setSearchedVal] = useState('');
    const [subMarketToEdit, setSubMarketToEdit] = React.useState();

    const search = (searchedVal) => {
        setSearchedVal(searchedVal.trim());
    }

    const onEditSubMarket = (subMarket) => {
        setSubMarketToEdit({ ...subMarket });
    }

    const { triggerPrompt } = usePrompt();

    const onDeleteSubMarket = async (subMarket) => {

        triggerPrompt({
            title: "Delete Sub Market",
            content: "Are you sure you want to delete the sub market?",
            loading: deleteSubMarketLoading,
            onConfirm: async () => {
                await deleteSubMarket(subMarket);
                let res = await deleteSubMarket(subMarket);
                if (!res?.error) {
                    NotificationProvider.success("Sub market was deleted successfully");
                    setSubMarketToEdit();
                } else {
                    NotificationProvider.error("Failed to delete sub market");
                }
            },
        });
    }

    const onNewSubMarket = () => {
        setSubMarketToEdit({});
    }

    const onSaveSubMarket = async (subMarket) => {
        let res;
        if (subMarket.id) {
            res = await updateSubMarket(subMarket);
        } else {
            res = await addSubMarket(subMarket);
        }

        if (!res?.error) {
            NotificationProvider.success("Sub market was saved successfully");
            setSubMarketToEdit();
        } else {
            NotificationProvider.error("Failed to save sub market");
        }
    }

    const onCancelEditSubMarket = () => {
        setSubMarketToEdit();
    }

    const searchSubMarkets = () => {
        if (!searchedVal) {
            return subMarkets;
        }

        return subMarkets.filter((sub) => {
            return Object.values(sub)
                .find((value) => {
                    if (!value) {
                        value = ""
                    }

                    return value.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
        });
    }

    let subMarketsFiltered = searchSubMarkets();

    const getLotName = (id) => {
        return lots?.filter(l => l.id == id)[0]?.name;
    }

    const isLoading = isLoadingLots || isLoadingSubMarkets || addSubMarketLoading || updateSubMarketLoading || deleteSubMarketLoading;
    const isSaving = addSubMarketLoading || updateSubMarketLoading || deleteSubMarketLoading;

    return (
        <>
            <LoadingSpinner loading={isLoading} />
            <Paper className={`${classes.header} ${isMobile ? classes.headerMobile : ""}`} elevation={1}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <DataSearchBar
                            rows={subMarkets}
                            onSearch={search}
                        />
                    </Grid>
                    <Authorize profile={permissionProfiles.LOT.SUB_MARKET_CREATE}>
                        <Grid item xs={2}>
                            <ButtonGroup size="large" fullWidth>
                                <Button onClick={onNewSubMarket} className={classes.button}>
                                    Create Sub Market
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Authorize>
                </Grid>
            </Paper>

            <Grid container>
                {subMarketsFiltered.length > 0 && subMarketsFiltered.map((subMarket, key) => (
                    <Grid item xs={6} key={key} className={isMobile ? classes.lotMobile : ""}>
                        <SubMarketItem subMarket={subMarket} lots={lots} onEdit={(subMarket) => onEditSubMarket(subMarket)} onDelete={(subMarket) => onDeleteSubMarket(subMarket)} getLotName={getLotName} />
                    </Grid>
                ))}
            </Grid>
            <SubMarketDialog subMarket={subMarketToEdit} lots={lots} onSave={onSaveSubMarket} onCancel={onCancelEditSubMarket} saving={isSaving} />
        </>
    )
}

export default LotSubMarkets;

const SubMarketItem = ({ subMarket, lots, onEdit, onDelete, getLotName }) => {
    const classes = LotSubMarketStyle();

    const [lotsAnchorEl, setLotsAnchorEl] = useState(null);
    const openLots = Boolean(lotsAnchorEl);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    let filteredSubMarketLots = subMarket.lotIds?.filter(lotId => lots?.find(l => l.id == lotId));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openLotsPopover = (event) => {
        setLotsAnchorEl(event.currentTarget);
    }

    const closeLotsPopover = () => {
        setLotsAnchorEl(null);
    }

    return (
        <>
            <Paper className={`${classes.card}`}  >
                <Grid container>
                    <Grid item xs={2} display="flex" className={classes.iconContainer}>
                        <div className={classes.leftIcon}><IconMarket /></div>
                    </Grid>
                    <Grid item xs={9}>
                        <h2 className={classes.title}>{subMarket.name}</h2>
                        <Button onClick={openLotsPopover} className={classes.showLots}>
                            {filteredSubMarketLots.length == 1 ? filteredSubMarketLots.length + ' lot' : filteredSubMarketLots.length + ' lots'}
                        </Button>
                        <Popover
                            open={openLots}
                            anchorEl={lotsAnchorEl}
                            onClose={closeLotsPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}>
                            <>
                                {filteredSubMarketLots.length > 0 ?
                                    filteredSubMarketLots.map((id, key) => (
                                        <Grid item xs={12} key={key} className={classes.lotsList}>
                                            {getLotName(id)}
                                        </Grid>)) :
                                    <Grid item xs={12} className={classes.lotsList}>
                                        There are no lots in the submarket
                                    </Grid>
                                }
                            </>
                        </Popover>
                    </Grid>
                    <Authorize profile={permissionProfiles.LOT.SUB_MARKET_CREATE}>
                        <Grid item xs={1} display="flex">
                            <LotItemAction handleClick={handleClick} />
                        </Grid>
                    </Authorize>
                    <Grid item xs={2} />
                </Grid>
            </Paper>
            <Menu
                id="lot-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lot-menu',
                }}
            >
                <Authorize profile={permissionProfiles.LOT.SUB_MARKET_CREATE}>
                    <MenuItem onClick={() => {
                        onEdit(subMarket);
                        handleClose();
                    }} >
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                        onDelete(subMarket);
                        handleClose();
                    }} >
                        Delete
                    </MenuItem>
                </Authorize>
            </Menu>
        </>
    )
}

const LotItemAction = ({ handleClick }) => {
    return (
        <div>
            <Button onClick={handleClick}>
                <MoreVertIcon />
            </Button>
        </div>
    )
}