import { makeStyles } from "@material-ui/core";

export const ReportSectionStyles = makeStyles((theme) => ({
    dragHandler: {
        display: 'flex',
        justifyContent: 'center',
        background: '#efefef',
        marginBottom: '1em'
    },
    dragIcon: {
        cursor: 'move',
        '& path': {
            fill: '#ddd'
        },
        '&:hover': {
            '& path': {
                fill: theme.palette.secondary.dark
            },
        }
    },
    dragIconQuestion: {
        color: 'black',
        transform: 'rotate(90deg)',
        cursor: 'move',
        '& path': {
            fill: '#ddd'
        },
        '&:hover': {
            '& path': {
                fill: theme.palette.secondary.dark
            },
        }
    },
    addSectionIcon: {
        border: '1.3px solid white',
        borderRadius: '50%',
        fill: 'white',
        fontSize: 18,
    },
    addSectionIconContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '30%',
        padding: '0.5rem',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
            opacity: '70%'
        }
    },
    addSectionLine: {
        width: '97%',
        backgroundColor: theme.palette.primary.main,
        height: '2px'
    },
    addSectionButton: {
        width: '103%',
        marginLeft: '-3%',
        marginRight: '-2%',
        display: 'flex',
        marginTop: '0.5rem'
    },
    reportSection: {
        minHeight: '10rem',
        padding: '1rem',
        display: 'flex',
        position: 'relative',
    },
    addElement: {
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        height: '2px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem'
    },
    addElementIcon: {
        backgroundColor: theme.palette.primary.light,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '50%',
        fontSize: 15,

    },
    deleteColumnIcon: {
        backgroundColor: '#ffe2e2',
        border: '1px solid',
        borderColor: 'red',
        borderRadius: '50%',
        fontSize: 15,
        padding: '1.5px',
        '& path': {
            fill: 'red'
        },
    },
    deleteColumnIconContainer: {
        backgroundColor: '#ffe2e2',
        borderRadius: '50%',
        padding: '0.3rem',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
            opacity: '70%'
        },
        margin: '0 5px'
    },
    addElementIconContainer: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',
        padding: '0.3rem',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        '&:hover': {
            opacity: '70%'
        },
        margin: '0 5px'
    },
    selectColumns: {
        padding: '0.5rem',
        display: 'flex',
        alignContent: 'center',
        verticalAlign: 'center'
    },
    selecetColumn: {
        width: '4rem',
        textAlign: 'center',
        fontSize: 9,
        fontWeight: 600,
        margin: '0 0.5rem',
        display: 'inline',
        verticalAlign: 'middle',
        padding: '0.5rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        }
    },
    columns: {
        display: 'flex',
        width: '1.4rem',
        verticalAlign: 'middle',
        marginBottom: '0.5rem',
        margin: 'auto',
    },
    oneColumn: {
        height: '1.5rem',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        margin: 'auto',
    },
    section: {
        display: 'flex',
        width: 'fit-content'
    },
    sectionActions: {
        backgroundColor: theme.palette.primary.light,
        width: 'fit-content',
        borderRadius: '15px',
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
        position: 'absolute',
        marginLeft: '-4%'
    },
    sectionWrapper: {
        position: 'relative'
    },
    actionsIcons: {
        '& path': {
            stroke: '#0090fe'
        },
    },
    elementType: {
        width: '5rem',
        textAlign: 'center',
        fontWeight: 600,
        margin: '0 0.5rem',
        display: 'inline',
        verticalAlign: 'middle',
        padding: '0.5rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        }
    },
    elementTypeName: {
        fontSize: 9,
    },
    elements: {
        margin: '2rem'
    },
    column: {
        width: '100%',
        margin: '0 1rem',
        marginTop: '1rem'
    },
    elementContainer: {
        margin: '0.5rem',
        padding: '0.5rem',
        border: '1px solid',
        borderRadius: '5px',
        borderColor: 'transparent',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            '& $buttomsContainer': {
                display: 'flex'
            }
        },
        position: 'relative',
        width: '95%',
    },
    deleteElement: {
        '&:hover': {
            '& $buttomsContainer': {
                display: 'flex'
            },
            '& $elementContainer': {
                borderColor: theme.palette.primary.main,
            }
        },

    },
    buttomsContainer: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '5px',
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
        width: 'fit-content',
        padding: '5px',
        display: "none",
        position: "absolute",
        top: 0,
        right: '-25px',
        transition: "opacity 0.5s ease",
        cursor: 'pointer',
        '&:hover': {
            display: 'flex',
            '& $elementContainer': {
                borderColor: theme.palette.primary.main,
            }
        },
    },
    dragIconElement: {
        color: 'black',
        cursor: 'move',
        '& path': {
            fill: '#ddd'
        },
        '&:hover': {
            '& path': {
                fill: theme.palette.secondary.dark
            },
        },
        position: "absolute",
        marginLeft: '-20px',
        marginTop: '20px',
    },
    addColumn: {
        position: 'relative',
        minWidth: '3px',
        backgroundColor: theme.palette.grey[100],
        display: 'inline',
        opacity: 0,
        '&:hover': {
            opacity: 1
        },
    },
    addColumnButton: {
        position: 'absolute',
        top: '-1rem',
        right: '-1rem',
        width: '2rem',
        height: '2rem',
        backgroundColor: theme.palette.grey[100],
        borderRadius: '50%',
        padding: '0.5rem',
        alignItems: 'center',
        cursor: 'pointer',
    }
}));