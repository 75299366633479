import { makeStyles } from "@material-ui/core";

export const lotSummaryStyle = makeStyles((theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.2em',
        color: theme.palette.primary.dark,
        '& svg': {
            height: '2em',
            width: '2em',
            marginRight: '0.5em'
        }
    },
    address: {
        color: theme.palette.primary.dark,
        marginTop: '1.5em',
        marginBottom: '2em',
        fontSize: '1.2em',
    },
    countWrapper: {
        marginBottom: '2em',
        padding: '0 0.5em',
        cursor: 'pointer'
    },
    label: {
        color: theme.palette.secondary.light,
        whiteSpace: 'normal'
    },
    count: {
        padding: '5px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderRadius: '50%',
        background: theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '3em',
        width: '3em',
        color: theme.palette.primary.dark
    },
    emptyCount: {
        border: "2px solid #BADD7F",
        background: 'white',
        color: theme.palette.secondary.main
    },
    overageCount: {
        border: "2px solid #ffd78d",
        background: 'white',
        color: theme.palette.secondary.main
    },
    domiciledCount: {
        border: "2px solid #ffd78d",
        borderColor: theme.palette.primary.main,
        background: 'white',
        color: theme.palette.secondary.main
    },
    leasedCount: {
        border: "2px solid #d4ecff",
        background: 'white',
        color: theme.palette.secondary.main
    },
    viewToggle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '1rem'
    },
    viewToggleBtn: {
        backgroundColor: '#f3f5f6',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
}));