import { IconButton, Typography } from "@material-ui/core";
import { Menu, MenuItem } from "@mui/material";
import { IconProfile } from "icons";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectUserEmail } from "../../user/userSlice";
import { userMenuStyle } from "./UserMenuStyle";

const UserMenu = () => {
    const userEmail = useSelector(selectUserEmail);
    const classes = userMenuStyle();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const userMenu = [
        {
            label: 'Details',
            path: '/user',
        },
        {
            label: 'Manage Subscriptions',
            path: '/subscriptions',
        },
        {
            label: 'Reset Password',
            path: '/resetpassword',
        },
        {
            label: 'Logout',
            path: '/logout',
        },
    ];

    return (
        <>
            <IconButton item
                title='User'
                id="basic-menu"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {/* {userEmail} */}
                {/* <AccountCircleIcon className={classes.icon} /> */}
                <IconProfile />
            </IconButton>


            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    className: classes.userMenu
                }}

            >
                <div className={classes.userEmail}>{userEmail}</div>
                {userMenu.map(menuItem =>
                    <MenuItem >
                        <NavLink className={classes.link} exact to={menuItem.path}>
                            <Typography>{menuItem.label}</Typography>
                        </NavLink>
                    </MenuItem>
                )}
            </Menu>

        </>
    );
};

export default UserMenu;
