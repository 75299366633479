import { Button, Grid, Paper } from "@material-ui/core";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../components/CustomInput";
import ContractHeader from "../../contracts/ContractHeader/ContractHeader";
import configStyles from "../configStyles";
import DisabledContract from "../DisabledContract";
import { washIntervalTypesMap, washProgramIntervalTypes, washProgramRateTypes, washRateTypes } from "./enums";
import { WashLots } from "./WashLots";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined';
import { resetBulkUpdate, setBulkEdit, setSelectedLots } from "./WashSlaSlice";
import { useEffect } from "react";
import { BulkEditPricing } from "./BulkEditPricing";

const useStyles = configStyles;

const WashConfig = ({ clientToEdit, onConfigChange, configName = 'washConfig' }) => {
    const classes = useStyles();

    const washConfigParams = clientToEdit?.config?.washConfig;

    const { bulkEdit, selectedLots } = useSelector(state => state.washConfigState);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetBulkUpdate());
        }
    }, []);

    const onWashPriceChange = (newPrice) => {
        let washPricesPerLot = clientToEdit?.washPrices.map(x => ({ lotId: x.lotId, price: x.price }));

        selectedLots?.map(s => {
            if(washPricesPerLot.find(p => p.lotId == s) != null) {
                //set the newPrice at that lot
                const oldWashPrice =  washPricesPerLot.find(p => p.lotId == s);
                oldWashPrice.price = newPrice;
            }
            else {
                const newWashPrice = {lotId: s, price: newPrice};
                washPricesPerLot.push(newWashPrice);
            }
        })
        onConfigChange(undefined, "washPrices", washPricesPerLot);
    }

    const onSelectAll = (selected) => {
        if (selected) {
            dispatch(setSelectedLots(clientToEdit?.clientLots?.map(l => l.lotId)));
        } else {
            dispatch(setSelectedLots([]));
        }
    }

    return (
        <>
            <ContractHeader
                label={'Wash Program'}
                flag={washConfigParams?.useWashProgram}
                onChange={() => {
                    onConfigChange(configName, "useWashProgram", !washConfigParams?.useWashProgram)
                }}
            />
            {!washConfigParams?.useWashProgram && <Authorize profile={permissionProfiles.CLIENT_CONFIG.CONTRACT_OFF_CLIENT}>
                <DisabledContract contractName={'wash program'} />
            </Authorize>}
            <Authorize profile={washConfigParams?.useWashProgram ? permissionProfiles.CLIENT_CONFIG.CONTRACT_ON : permissionProfiles.CLIENT_CONFIG.CONTRACT_OFF}>
                <div>
                    <Paper className={classes.card}>
                        <ContractHeader
                            label={'Wash Schedule'}
                        />

                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                            <WashInterval
                                washConfigParams={washConfigParams}
                                onConfigChange={onConfigChange}
                                configName={configName}
                            />
                        </Authorize>

                    </Paper >
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                        <Paper className={classes.card}>
                            <ContractHeader
                                label={'Pricing'}
                            />
                            {/* <div className={classes.label}>Rate Type: </div> */}
                            <div className={classes.inputWrapper}>
                                <div className={classes.inputField}>
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                                        <CustomInput
                                            disabled={!washConfigParams?.useWashProgram}
                                            id="washRateType"
                                            label='Rate Type'
                                            value={washConfigParams?.rateType}
                                            values={washProgramRateTypes}
                                            onChange={(val) => onConfigChange(configName, "rateType", parseInt(val))}
                                            elementType="dropdown"
                                            showEmpty
                                        />
                                    </Authorize>
                                </div>
                                <div className={classes.inputField}>
                                    {washConfigParams?.rateType == washRateTypes.MARGIN ?
                                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_PRICING}>
                                            <CustomInput
                                                disabled={!washConfigParams?.useWashProgram}
                                                id="washMargin"
                                                value={washConfigParams?.washMargin}
                                                onChange={(val) => onConfigChange(configName, "washMargin", +val)}
                                                elementType="input"
                                                numberOnly
                                                step="0.1"
                                                icon={<MonetizationOnOutlinedIcon width={30} height={30} className={classes.inputIcon} />}
                                            />
                                        </Authorize> : null}
                                </div>
                                {washConfigParams?.rateType == washRateTypes.FLAT_RATE ?
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.BULK_UPDATE_PRICES}>
                                        <Grid spacing={1} item xs={5} container justifyContent="flex-end">
                                            <Button
                                                id="basic-button"
                                                onClick={() => {
                                                    dispatch(setBulkEdit(!bulkEdit));
                                                }}
                                                startIcon={<ReorderOutlinedIcon />}
                                                disabled={!washConfigParams?.useWashProgram}
                                            >
                                                {'Bulk Edit'}
                                            </Button>
                                        </Grid>
                                    </Authorize> : null}
                            </div>
                            <div>
                                {bulkEdit && <>
                                    <BulkEditPricing
                                        onSelectAll={onSelectAll}
                                        onWashPriceChange={onWashPriceChange}
                                    />
                                </>}
                                <WashLots
                                    washConfigParams={washConfigParams}
                                    disabled={!washConfigParams?.useWashProgram}
                                    activeLotsIds={clientToEdit?.clientLots?.map(c => c.lotId)}
                                    washPricesPerLot={clientToEdit?.washPrices}
                                    showFlatRate={washConfigParams?.rateType == washRateTypes.FLAT_RATE}
                                    onConfigChange={onConfigChange}
                                    clientToEdit={clientToEdit}
                                />
                            </div>
                        </Paper>
                    </Authorize>
                </div>
            </Authorize>
        </>
    )
}
export default WashConfig;


const WashInterval = ({ washConfigParams, onConfigChange, disabled, configName }) => {
    const classes = useStyles();

    const getTextualRep = () => {
        let intervalType = washProgramIntervalTypes.find(w => w.id == washConfigParams?.washProgramInterval?.intervalType)?.textual;
        if (washConfigParams?.washProgramInterval?.intervalValue > 1 && washConfigParams?.washProgramInterval?.intervalType != washIntervalTypesMap.STAY) {
            intervalType = intervalType + "s"
        }
        const intervalValue = washConfigParams?.washProgramInterval?.intervalType != washIntervalTypesMap.STAY ? washConfigParams?.washProgramInterval?.intervalValue : undefined;
        if (!intervalValue || !intervalType) return '';
        return `Every ${intervalValue} ${intervalType}`;
    }
    const onNumWashesChange = (val) => {
        if (val !== '' && val <= 0) {
            return;
        }
        onConfigChange(configName, "washProgramInterval", { ...washConfigParams?.washProgramInterval, numberOfWashes: val })
    }

    return (
        <>
            {disabled ?
                <div className={classes.label}>{getTextualRep()}</div> :
                <>
                    <div className={classes.label}>Wash Interval </div>
                    <div className={classes.inputWrapper}>
                        <Grid container spacing={1}>
                            {washConfigParams?.washProgramInterval?.intervalType != washIntervalTypesMap.STAY ?
                                <Grid item>
                                    <div className={classes.inputField}>
                                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                                            <CustomInput
                                                disabled={!washConfigParams?.useWashProgram}
                                                id="washProgramInterval"
                                                label='Interval'
                                                value={washConfigParams?.washProgramInterval?.intervalValue ?? ''}
                                                onChange={(val) => onConfigChange(configName, "washProgramInterval", { ...washConfigParams.washProgramInterval, intervalValue: +val })}
                                                elementType="input"
                                                numberOnly
                                                step="1"
                                                required
                                            />
                                        </Authorize>
                                    </div></Grid> : null}
                            <Grid item>
                                <div className={classes.inputField}>
                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                                        <CustomInput
                                            disabled={!washConfigParams?.useWashProgram}
                                            id="washProgramInterval"
                                            label='Interval Type'
                                            value={washConfigParams?.washProgramInterval?.intervalType}
                                            values={washProgramIntervalTypes}
                                            onChange={(val) => onConfigChange(configName, "washProgramInterval", { ...washConfigParams?.washProgramInterval, intervalType: +val })}
                                            elementType="dropdown"
                                            showEmpty
                                            required
                                        />
                                    </Authorize>
                                </div>
                            </Grid>
                            {washConfigParams?.washProgramInterval?.intervalType != washIntervalTypesMap.STAY ?
                                <Grid item>
                                    <div className={classes.inputField}>
                                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_FIELD_EDIT}>
                                            <CustomInput
                                                disabled={!washConfigParams?.useWashProgram}
                                                id="numberOfWashes"
                                                label='Number of Washes'
                                                value={washConfigParams?.washProgramInterval?.numberOfWashes}
                                                onChange={onNumWashesChange}
                                                elementType="input"
                                                numberOnly
                                                minValue={1}
                                                required
                                            />
                                        </Authorize>
                                    </div>
                                </Grid> : null}
                        </Grid>
                    </div>

                </>}
        </>
    );
}
