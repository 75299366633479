import { StepsStyles } from './StepsStyles';
import { Checkbox, FormControlLabel, Paper, Tooltip } from '@material-ui/core';
import { AccessGroupInfoFields } from 'features/access/accessConsts';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ConfirmAccessStep = ({ }) => {
    const classes = StepsStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, ["generateCode"]: val }))
    }

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();

    const lotsLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const canGenerateCode = accessToEdit?.pocName &&
        (accessToEdit?.pocEmail || accessToEdit?.pocPhone) &&
        accessToEdit?.lots?.length > 0 &&
        accessToEdit?.startDate &&
        accessToEdit?.endDate;

    useEffect(() => {
        dispatch(setAccessToEdit({ ...accessToEdit, ["generateCode"]: canGenerateCode ? true : false }))
    }, [])

    return (
        <>
            <Paper className={classes.overviewWrapper}>
                <div className={classes.overviwTitle}>Overview</div>
                {AccessGroupInfoFields[accessToEdit?.groupType]?.overview()}
                <div className={classes.overviewField}><label >POC Name: </label>{accessToEdit?.pocName}</div>
                <div className={classes.overviewField}><label >POC email: </label>{accessToEdit?.pocEmail}</div>
                <div className={classes.overviewField}><label >POC Phone Number: </label>{accessToEdit?.pocPhone}</div>
                <div className={classes.overviewField}><label >Sites: </label>{accessToEdit?.lots?.map(lot => `${lotsLookup?.[lot?.id]?.name}, `)}</div>

                <Tooltip disableHoverListener={canGenerateCode}
                    title="POC information, access code dates and at least one site are required when creating a access code. An access code can be generated after creation.">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!canGenerateCode}
                                defaultChecked={canGenerateCode}
                                checked={accessToEdit?.generateCode && canGenerateCode}
                                onChange={(e, value) => onChangeValue(value)}
                            />
                        }
                        label={<div className={classes.generateLabel}>Generate QR Code</div>}
                    />
                </Tooltip>
            </Paper>
        </>
    );
}

export default ConfirmAccessStep;