import { makeStyles } from "@material-ui/core";

export const OneOnOneStyle = makeStyles((theme) => ({
    formContainer: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.darkText,
        boxShadow: 'none',
        position: 'relative '
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
        minHeight: 300
    },
    disabledForm: {
        pointerEvents: 'none',
        opacity: 0.7
    },
    input: {
        margin: 16,
    },
    table: {
        flex: 1
    }
}));