import { baseApi } from '../../app/baseApi';

export const confirmationApi = baseApi.injectEndpoints({
    reducerPath: 'confirmationApi',
    endpoints: (builder) => ({
        confirmKey: builder.query({
            query: (key) => `confirmation?key=${key}`,
        }),
       
    }),
});


export const { useConfirmKeyQuery} = confirmationApi;
