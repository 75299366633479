import { createSlice } from "@reduxjs/toolkit";

export const genericTableStateSlice = createSlice({
    name: 'genericTableState',
    initialState: {
        collapsedFilters: false,
    },
    reducers: {
        collapseFilters: (state, action) => {
            state.collapsedFilters = action.payload;
        },

    },
});

export const { collapseFilters } = genericTableStateSlice.actions;
export default genericTableStateSlice.reducer;
