import { Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import { questionTypeSetup } from "../formBuilderConsts";
import { FormBuilderStyles } from "../style";
import { toLower } from "lodash";
import { IconDelete } from "icons";

const SectionActions = ({ onAddQuestion, onDeleteSection }) => {

    const classes = FormBuilderStyles();

    return (
        <Grid container className={classes.sectionActions} justifyContent="center">
            {Object.keys(questionTypeSetup)?.map(type => <Grid item>
                <Tooltip title={`Add ${toLower(questionTypeSetup[type].name)} question`}>
                    <IconButton onClick={() => onAddQuestion(+type)}>
                        {questionTypeSetup[type]?.icon}
                    </IconButton>
                </Tooltip>
            </Grid>)}
            <Grid item className={classes.deleteSectionIcon}>
                <Tooltip title={`Delete Section`}>
                    <IconButton onClick={() => onDeleteSection()}>
                        <IconDelete style={{ width: '1em' }} />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
export default SectionActions; 