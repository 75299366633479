import { baseApi } from "app/baseApi";
import { baseQuery } from "app/baseQuery";
import { apiTags } from "app/store/apiTags";

const apiPrefix = 'activityStatusAlias'
export const activityStatusAliasApi = baseApi.injectEndpoints({
    reducerPath: 'activityStatusAliasApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({        
        getAllAliases: builder.query({
            query: () => `${apiPrefix}`
        }),
        getAliasesByClient: builder.query({
            query: (clientId) => `${apiPrefix}/${clientId}`,
            providesTags: [apiTags.ACTIVITY_STATUS_ALIAS]
        }),
        updateAliases: builder.mutation({
            query: entities => ({
                url: `${apiPrefix}`,
                method: 'POST',
                body: entities
            }),
            invalidatesTags: [apiTags.ACTIVITY_STATUS_ALIAS]
        })
    })
})

export const { useGetAllAliasesQuery, useGetAliasesByClientQuery, useUpdateAliasesMutation } = activityStatusAliasApi;