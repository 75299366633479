import { baseApi } from "app/baseApi";
import { baseQuery } from "app/baseQuery";
import { apiTags } from "app/store/apiTags";

const apiPrefix = 'costcode'
export const costCodeApi = baseApi.injectEndpoints({
    reducerPath: 'costCodeApi',
    tagTypes: [apiTags.COST_CODES],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getClientsCostCodes: builder.query({
            query: (client) => `${apiPrefix}?${client ? `clientId=${client.clientId}` : ''}`,
            providesTags: [apiTags.COST_CODES],
        }),
        updateCostCodes: builder.mutation({
            query: (costCodes) => ({
                url: `${apiPrefix}/update`,
                method: 'POST',
                body: costCodes
            }),
            invalidatesTags: [apiTags.COST_CODES]
        })
    })
});

export const { useGetClientsCostCodesQuery, useUpdateCostCodesMutation } = costCodeApi; 