import store from "app/store/store";
import { pushDesktopNotification } from "../desktopNotifications";
import { baseApi } from "app/baseApi";
import { apiTags } from "app/store/apiTags";

export const getUserTaskActions = (data) => {
    return [
        {
            action: 'complete',
            title: 'Complete Task',
        },
    ]
}

export const getUserTaskNotificationUrl = (data) => {
    return `/usertask/${data.taskId}`
}

export const handleUserTaskNotification = (data) => {
    pushDesktopNotification(data);
    saveLastSeenToLocalStorage(data.taskId);
    store.dispatch(baseApi.util.invalidateTags([apiTags.USER_TASK]))
}

export const saveLastSeenToLocalStorage = (taskId) => {
    localStorage.setItem(`latest_user_task_id`, taskId);
}

export const getLatestTaskSeen = () => {
    return localStorage.getItem(`latest_user_task_id`);
}