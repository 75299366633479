import { Dialog, Grid } from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { useGetAllIssuesTrackerForUserQuery, useGetIssueTrackerPrioritiesQuery, useGetStatusIssueTrackerQuery } from "features/IssuesTracker/issuesTrackerSlice";
import { TasksPanelStyles } from "features/Tasks/TasksPanelStyles";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import DataSearchBar from "../../../../components/DataSearchBar";
import DropdownFilter from "../../../../components/DropdownFilter";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import LotsDropdown from "../../../../components/SharedDropdowns/LotsDropDown/LotsDropDown";
import FilterService, { fieldTypes } from "../../../../services/filterService";
import { useGetClientsQuery } from "../../../clients/clientSlice";
import { useGetLotsQuery } from "../../../lots/lotSlice";
import { setFilters } from "../../taskSlice";
import { LotTaskList } from "./LotTaskList";
import IssueTrackerView from "features/IssuesTracker/IssueTrackerView/IssueTrackerView";
import { ListSort } from "components/ListSort/ListSort";

const LotTasks = () => {
    const classes = TasksPanelStyles();
    let { data: tickets, error: ticketError, isFetching: isLoadingTicket } = useGetAllIssuesTrackerForUserQuery();
    let { data: lots, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: priorities, error: prioritiesError, isFetching: isLoadingPriorities } = useGetIssueTrackerPrioritiesQuery();
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();

    const isLoading = isLoadingTicket;

    const [searchVal, setSearchVal] = useState();
    const [sortBy, setSortBy] = useState();
    const [sortDirection, setSortDirection] = useState();
    const [issuesTrackerToEdit, setIssuesTrackerToEdit] = useState();

    const dispatch = useDispatch();
    const { taskPanelOpen, filters } = useSelector(state => state.taskState);

    useEffect(() => {
        if (issuesTrackerToEdit && !isLoadingTicket) {
            let updatedIssue = tickets?.find(i => i.id == issuesTrackerToEdit?.id);
            setIssuesTrackerToEdit(updatedIssue);
        }
    }, [issuesTrackerToEdit, isLoadingTicket])

    const filterConfig = [
        {
            type: fieldTypes.TEXT,
            size: 3,
            key: 'priority',
            values: [{ id: '', name: 'All priorities' }, ...(priorities ?? [])],
        },
        {
            type: fieldTypes.TEXT,
            size: 3,
            key: 'status',
            values: [{ id: '', name: 'All statuses' }, ...(statuses ?? [])],
        },
        {
            type: fieldTypes.TEXT,
            size: 2,
            key: 'clientId',
            values: [{ id: '', name: 'All clients' }, ...(clients ?? [])],
            profile: permissionProfiles.TASKS.TASK_VIEW_CLIENT
        },
        {
            type: fieldTypes.LOT,
            size: 3,
            label: !!filters?.lotId?.value?.length ? undefined : 'All lots',
            key: 'lotId',
            values: [...(lots ?? [])],
            multiple: true
        },
    ];

    tickets = (tickets ?? [])?.filter(t => !t.isComplete);
    const sortedTasks = orderBy(FilterService.filter(filters, searchVal, tickets, filterConfig)
        , sortBy ? [sortBy, 'dueDate'] : ['priority', 'dueDate', 'issueDate'],
        sortBy ? [sortDirection] : ['asc', 'asc', 'asc']);

    const sorts = [
        {
            id: '',
            name: 'Default'
        },
        {
            id: 'priority',
            name: 'Priority'
        },
        {
            id: 'issueDateObj',
            name: 'Issue Date'
        },
        {
            id: 'createdDateObj',
            name: 'Reported Date'
        }
    ]
    return (
        <>
            <Grid container direction='row' alignItems='center' className={classes.filters}>
                <Grid item className={classes.iconContainer}><FilterListIcon /></Grid>
                {filterConfig?.map(filter =>
                    <Authorize profile={filter.profile} key={filter.key}>
                        <Grid xs={filter.size} item className={classes.filterWrapper}>
                            <Grid container direction='row' alignItems='center' >
                                <Grid xs={12} item >
                                    {filter?.type === fieldTypes.LOT ?
                                        <LotsDropdown
                                            clientId={filters['clientId']?.value}
                                            title={filter.label}
                                            values={filter.values}
                                            value={filters?.[filter.key]?.value ?? (filter.multiple && [])}
                                            onSelect={(val) => dispatch(setFilters({ ...filters, [filter.key]: { value: val } }))}
                                            multiple
                                        />
                                        : <DropdownFilter
                                            title={filter.label}
                                            values={filter.values}
                                            value={filters?.[filter.key]?.value ?? (filter.multiple && [])}
                                            onSelect={(val) => dispatch(setFilters({ ...filters, [filter.key]: { value: val } }))}
                                            multiple={filter.multiple}
                                        />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Authorize>
                )}
            </Grid>
            <Grid alignItems='center' className={classes.search} container>
                <Grid item xs={7}><DataSearchBar onSearch={(val) => setSearchVal(val)} searchVal={searchVal} /></Grid>
                <Grid item xs={5} alignItems='center' container className={classes.sorts}>
                    <ListSort
                        sortOptions={sorts}
                        onOrderByChange={(orderBy) => {
                            setSortBy(orderBy)
                            if (!sortDirection) {
                                setSortDirection('asc')
                            }
                        }}
                        onDirectionChange={(direction) => setSortDirection(direction)}
                        orderBy={sortBy}
                        orderByDirection={sortDirection}
                    />
                </Grid>
            </Grid>


            {issuesTrackerToEdit && <IssueTrackerView
                withDialog
                onClose={() => setIssuesTrackerToEdit()}
                issueTracker={issuesTrackerToEdit}
            />}
            {isLoading ?
                <LoadingSpinner loading={isLoading} />
                : <LotTaskList tasks={sortedTasks} onOpenTask={(task) => setIssuesTrackerToEdit(task)} />}
        </>
    )
}
export default LotTasks;



