import * as React from "react";

function SvgIconLogout(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.075 4.2574C10.2846 4.89875 9.79254 5.93631 9.64718 7.62435C9.61164 8.03704 9.24829 8.34278 8.8356 8.30724C8.42291 8.2717 8.11717 7.90835 8.15271 7.49566C8.31735 5.5837 8.9053 4.08626 10.1299 3.09261C11.3338 2.11576 13.0163 1.74001 15.1099 1.74001H15.2399C17.5491 1.74001 19.3609 2.19779 20.5765 3.41343C21.7922 4.62906 22.2499 6.44083 22.2499 8.75001V15.27C22.2499 17.5792 21.7922 19.391 20.5765 20.6066C19.3609 21.8222 17.5491 22.28 15.2399 22.28H15.1099C13.0315 22.28 11.3591 21.9093 10.1577 20.9482C8.93457 19.9697 8.33885 18.4941 8.16318 16.6096C8.12474 16.1972 8.42791 15.8317 8.84033 15.7932C9.25276 15.7548 9.61826 16.058 9.65671 16.4704C9.81104 18.1259 10.3053 19.1453 11.0947 19.7769C11.9058 20.4257 13.1684 20.78 15.1099 20.78H15.2399C17.4008 20.78 18.719 20.3428 19.5159 19.5459C20.3127 18.7491 20.7499 17.4308 20.7499 15.27V8.75001C20.7499 6.58918 20.3127 5.27095 19.5159 4.47409C18.719 3.67722 17.4008 3.24001 15.2399 3.24001H15.1099C13.1536 3.24001 11.8861 3.59925 11.075 4.2574ZM4.31065 11.25L6.38033 9.18032C6.67322 8.88743 6.67322 8.41256 6.38033 8.11966C6.08744 7.82677 5.61256 7.82677 5.31967 8.11966L1.96967 11.4697C1.67678 11.7626 1.67678 12.2374 1.96967 12.5303L5.31967 15.8803C5.61256 16.1732 6.08744 16.1732 6.38033 15.8803C6.67322 15.5874 6.67322 15.1126 6.38033 14.8197L4.31067 12.75H15.0001C15.4143 12.75 15.7501 12.4142 15.7501 12C15.7501 11.5858 15.4143 11.25 15.0001 11.25H4.31065Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconLogout;
