import * as React from "react";

function IconOilChangeDiesel(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.94138 2.0625C4.70692 2.0625 4.45388 2.27976 4.45388 2.6175V14.955C4.45388 15.2657 4.20204 15.5175 3.89138 15.5175C3.58072 15.5175 3.32888 15.2657 3.32888 14.955V2.6175C3.32888 1.72524 4.02084 0.9375 4.94138 0.9375H12.4264C13.3569 0.9375 14.0389 1.72794 14.0389 2.6175V14.9625C14.0389 15.2732 13.787 15.525 13.4764 15.525C13.1657 15.525 12.9139 15.2732 12.9139 14.9625V2.6175C12.9139 2.27706 12.6658 2.0625 12.4264 2.0625H4.94138ZM8.18138 4.5825C8.50138 4.3425 8.93388 4.3425 9.25388 4.5825L9.47888 4.75125V4.7674C9.83533 5.0586 10.3514 5.52666 10.8166 6.11236C11.383 6.82568 11.9314 7.78549 11.9314 8.8575C11.9314 10.6242 10.4935 12.06 8.72138 12.06C6.94757 12.06 5.512 10.6152 5.51138 8.85113C5.50726 7.7647 6.05273 6.80473 6.62029 6.09279C7.19523 5.3716 7.84866 4.83503 8.1788 4.58445L8.18138 4.58249L8.18138 4.5825ZM8.71268 5.59456C8.39958 5.84475 7.92105 6.26588 7.49997 6.79407C6.99899 7.4225 6.63341 8.13458 6.63638 8.84765L6.63639 8.85C6.63638 9.99537 7.57058 10.935 8.72138 10.935C9.87426 10.935 10.8064 10.0008 10.8064 8.8575C10.8064 8.1595 10.4397 7.44681 9.93558 6.812C9.50861 6.27436 9.02392 5.84404 8.71268 5.59456ZM0.936401 16.635C0.936401 16.3243 1.18824 16.0725 1.4989 16.0725H15.8689C16.1796 16.0725 16.4314 16.3243 16.4314 16.635C16.4314 16.9457 16.1796 17.1975 15.8689 17.1975H1.4989C1.18824 17.1975 0.936401 16.9457 0.936401 16.635Z" fill="#0090FE" />
        </svg>


    );
}

export default IconOilChangeDiesel;
