import { Button, Chip, Grid, Paper, useMediaQuery } from "@material-ui/core";
import { IconTruck } from "icons";
import { round } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Bar, BarChart, Cell, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import DateRange from "../../../../components/DateRange";
import { DefaultRangesTypes, PredefinedRanges } from "../../../../components/DateRange/DefaultRanges";
import DropdownFilter from "../../../../components/DropdownFilter";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import LotsDropdown from "../../../../components/SharedDropdowns/LotsDropDown/LotsDropDown";
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import { useGetBusinessUnitsQuery } from '../../../clients/clientConfigSlice';
import { useGetClientsQuery } from "../../../clients/clientSlice";
import { useGetLotsQuery } from "../../../lots/lotSlice";
import { useGetTransportersQuery } from "../../../transporter/transporterSlice";
import { selectIsAdmin, selectUserClientId } from "../../../user/userSlice";
import { useGetGroupedLoadsDashboardQuery } from "../../loadsSlice";
import { LoadDashboardStyle } from "./LoadDashboardStyle";
import LoadViewSelector from "../LoadViewSelector/LoadViewSelector";

const LoadDashboard = () => {
    const classes = LoadDashboardStyle();
    const history = useHistory();
    const isAdmin = useSelector(selectIsAdmin);
    const isMobile = useMediaQuery(mobileBreakpoint);
    const userClientId = useSelector(selectUserClientId);

    const [clientId, setClientId] = useState(userClientId || 0);
    const [businessUnitId, setBusinessUnitId] = useState(0);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(new Date().toDateString());
    const [lotId, setLot] = useState(0);
    const [carrierId, setCarrierId] = useState();
    const [dateValue, setDateValue] = useState((startDate && endDate) ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}` : '');

    const threeMonthsStartDate = PredefinedRanges?.find(r => r.key == DefaultRangesTypes.THREE_MONTHS)?.range().startDate.toDateString();
    const threeMonthsEndDate = PredefinedRanges?.find(r => r.key == DefaultRangesTypes.THREE_MONTHS)?.range().endDate.toDateString();
    const sixMonthsStartDate = PredefinedRanges?.find(r => r.key == DefaultRangesTypes.SIX_MONTHS)?.range().startDate.toDateString();
    const sixMonthsEndtDate = PredefinedRanges?.find(r => r.key == DefaultRangesTypes.SIX_MONTHS)?.range().endDate.toDateString();

    const [slaStartDate, setSlaStartDate] = useState(threeMonthsStartDate);
    const [slaEndDate, setSlaEndDate] = useState(threeMonthsEndDate);
    const [buttonClicked, setButtonClicked] = useState(1);

    const { searchVal, filters, viewArchived } = useSelector(state => state.loadFilters);

    const handleSlaFilter3Months = () => {
        setSlaStartDate(threeMonthsStartDate);
        setSlaEndDate(threeMonthsEndDate);
        setButtonClicked(1);
    }

    const handleSlaFilter6Months = () => {
        setSlaStartDate(sixMonthsStartDate);
        setSlaEndDate(sixMonthsEndtDate);
        setButtonClicked(2);
    }

    const handleSlaFilter = () => {
        setSlaStartDate(startDate);
        setSlaEndDate(endDate);
        setButtonClicked(3);
    }

    const onTimeFrameSelect = (e) => {
        setStartDate(e?.startDate?.toDateString());
        setEndDate(e?.endDate?.toDateString());
        if (e?.startDate && e?.endDate) {
            setDateValue(e ? `${e.startDate?.toLocaleDateString()} - ${e.endDate?.toLocaleDateString()}` : "")
        }
        if (buttonClicked == 3) {
            setSlaStartDate(e?.startDate?.toDateString());
            setSlaEndDate(e?.endDate?.toDateString());
        }
    }

    const goToLoadsPage = (status) => {
        let pickupStartDate = startDate && endDate ? `${startDate},${endDate}` : undefined;
        const params = {
            allCarriers: carrierId,
            latestStatus: status,
            clientId: clientId,
            allPickupLocations: lotId,
            allPickupDates: pickupStartDate
        }
        const paramString = Object.keys(params).filter(param => params[param])?.map(p => `${p}=${params[p]}`).join("&");
        history.push(`/loads/list?${paramString}`);
    }

    let { data: loadsDashboard, error: loadsDashboardError, isFetching: isLoadingLoadsDashboard, refetch: refetchLoadsDashboard } = useGetGroupedLoadsDashboardQuery({ clientId, businessUnitId, lotId, startDate, endDate, carrierId, slaStartDate, slaEndDate, archived: viewArchived });
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: transporters, error: transportersError, isLoading: isLoadingTransporters } = useGetTransportersQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: (!isAdmin) });
    let { data: businessUnits, error: businessUnitsError, isLoading: isLoadingbusinessUnits, isSuccess: businessUnitsSuccess } = useGetBusinessUnitsQuery();

    clients = clients || [];
    lots = lots || [];
    transporters = transporters || [];
    businessUnits = businessUnits || [];

    loadsDashboard = loadsDashboard || { "loadCount": 0, "underSlaCount": 0, "pendingLoads": 0, "delayedLoads": 0, "loadsAtRisk": 0, "activeLoads": 0, "groupedLoadsAtRisk": 0, "usedGPSGroupCount": 0, "usedGPSCount": 0 };

    const SLAPercent = round((100 * loadsDashboard.underSlaCount) / (loadsDashboard.deliveredLoadsUnderSla), 2) || 0
    const SLAData = [
        { name: "Within SLA", value: loadsDashboard.underSlaCount },
        { name: "Above SLA", value: loadsDashboard.deliveredLoadsUnderSla - loadsDashboard.underSlaCount },
    ]

    const isLoadingDashboardData = () => isLoadingLoadsDashboard || isLoadingLots || isLoadingClients;

    const COLORS = ['#90c534', '#FB8C00'];

    const groupedLoadsByDate = {};

    loadsDashboard?.loadCreatedDates?.forEach(createdDate => {
        let date = new Date(createdDate).toLocaleString('default', { year: 'numeric', month: 'numeric', day: 'numeric', });
        let displayDate = new Date(createdDate).toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', });
        if (!groupedLoadsByDate[date]) {
            groupedLoadsByDate[date] = { date: date, displayDate: displayDate, count: 1 };
        }
        else {
            groupedLoadsByDate[date].count += 1;
        }
    });

    let loadsByDateCount = Object.values(groupedLoadsByDate)
    loadsByDateCount = loadsByDateCount?.sort((a, b) => (new Date(a?.date) - new Date(b?.date)))
    if (loadsByDateCount.length > 1) {
        let firstDate = new Date(loadsByDateCount[0].date)
        let lastDate = new Date(loadsByDateCount[loadsByDateCount.length - 1].date)
        if (firstDate.getMonth() !== lastDate.getMonth()) {

        }
    }

    return (
        <>
            <Paper className={`${classes.card} ${classes.dashboard}`} elevation={1}>
                {<LoadingSpinner loading={isLoadingDashboardData()} />}
                <Grid container direction="row" alignItems="center" spacing={2} className={classes.titleRow}>
                    <Grid container className={classes.pageTitle} spacing={1}>
                        <IconTruck />
                        <span className={classes.pageTitle}>Loads</span>
                        <LoadViewSelector />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" spacing={2} >
                    <Authorize profile={permissionProfiles.LOAD.CLIENT_FILTER}>
                        <Grid item xs={2} className={`${classes.filter} ${isMobile ? classes.filterMobile : ""}`}>
                            <DropdownFilter
                                title='Client'
                                identifier='client'
                                values={clients}
                                onSelect={(e) => setClientId(e)}
                                value={clientId}
                                showEmpty={true} />
                        </Grid>
                    </Authorize>

                    {businessUnits?.filter(b => b.clientId == clientId)?.length ?
                        <Grid item xs={2} className={`${classes.filter} ${isMobile ? classes.filterMobile : ""}`}>
                            <DropdownFilter
                                title='Business Unit'
                                identifier='businessUnit'
                                values={businessUnits?.filter(b => b.clientId == clientId)}
                                onSelect={(e) => setBusinessUnitId(e)}
                                value={businessUnitId}
                                showEmpty={true} />
                        </Grid> : <></>
                    }

                    <Grid item xs={2} className={`${classes.filter} ${isMobile ? classes.filterMobile : ""}`}>
                        <DateRange
                            title='Time Frame'
                            identifier='timeFrame'
                            onSelect={onTimeFrameSelect}
                            filterButton={true}
                            showPredefinedRanges={true}
                            dateValue={dateValue}
                        //selectedRange={DefaultRangesTypes.THREE_MONTHS}
                        />
                    </Grid>
                    {(lots.length > 1) &&
                        <Grid item xs={2} className={`${classes.filter} ${isMobile ? classes.filterMobile : ""}`}>
                            <LotsDropdown
                                clientId={clientId}
                                title='Lot'
                                identifier='lot'
                                values={lots}
                                onSelect={(e) => setLot(e)}
                                value={lotId}
                                showEmpty={true} />
                        </Grid>}
                    <Grid item xs={2} className={`${classes.filter} ${isMobile ? classes.filterMobile : ""}`}>
                        <DropdownFilter
                            title='Carrier'
                            identifier='carrier'
                            values={transporters}
                            onSelect={(e) => setCarrierId(e)}
                            value={carrierId}
                            showEmpty={true} />
                    </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2} >
                    <Grid item xs={2}>
                        <Paper className={classes.topCards} elevation={1}>
                            <div className={classes.activeLoads} onClick={() => goToLoadsPage()}>
                                <Grid item className={classes.counter}>{loadsDashboard?.loadCount}</Grid>
                                <Grid item>
                                    <h3 className={classes.subTitle}>All Loads</h3>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper className={classes.topCards} elevation={1}>
                            <div className={classes.activeLoads} onClick={() => { goToLoadsPage('4') }}>
                                <Grid item className={classes.counter}>{loadsDashboard?.activeLoads}</Grid>
                                <Grid item>
                                    <h3 className={classes.subTitle}>Active Loads</h3>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper className={classes.topCards} elevation={1}>
                            <div className={classes.activeLoads} onClick={() => { goToLoadsPage('3') }}>
                                <Grid item className={classes.counter}>{loadsDashboard?.pendingLoads}</Grid>
                                <Grid item>
                                    <h3 className={classes.subTitle}>Pending Loads</h3>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <Paper className={classes.card} elevation={1}>
                            <div className={classes.activeLoads} onClick={() => { goToLoadsPage('112') }}>
                                <Grid item className={classes.counter}>{loadsDashboard?.delayedLoads}</Grid>
                                <Grid item>
                                    <h3 className={classes.subTitle}>Delayed Loads</h3>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid> */}
                    <Grid item xs={2}>
                        <Paper className={classes.topCards} elevation={1}>
                            <div className={classes.activeLoads} onClick={() => { goToLoadsPage('113') }}>
                                <Grid item className={classes.counter}>{loadsDashboard?.groupedLoadsAtRisk}</Grid>
                                <Grid item>
                                    <h3 className={classes.subTitle}>Groups At Risk</h3>
                                    <h5 className={classes.atRisk}>{loadsDashboard?.loadsAtRisk} loads affected</h5>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Paper className={classes.topCards} elevation={1}>
                            <div className={classes.activeLoads} >
                                <Grid item className={classes.counter}>{loadsDashboard?.usedGPSGroupCount ? loadsDashboard?.usedGPSGroupCount : 0}</Grid>
                                <Grid item>
                                    <h3 className={classes.subTitle}>Groups Used GPS</h3>
                                    <h5 className={classes.atRisk}>{loadsDashboard?.usedGPSCount ? loadsDashboard?.usedGPSCount : 0} loads used a GPS</h5>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <Paper className={classes.card} elevation={1}>

                            <div className={classes.slaFilters}>
                                <Button aria-selected={true} onClick={handleSlaFilter3Months} >
                                    <Chip label="3 Months" variant={buttonClicked == 1 ? "default" : "outlined"} />
                                </Button>
                                <Button onClick={handleSlaFilter6Months} >
                                    <Chip label="6 Months" variant={buttonClicked == 2 ? "default" : "outlined"} />
                                </Button>
                                <Button onClick={handleSlaFilter} >
                                    <Chip label="Use general time frame" variant={buttonClicked == 3 ? "default" : "outlined"} />
                                </Button>
                            </div>


                            <Grid container direction="row" alignItems="center" spacing={2} className={classes.subTitleRow}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Grid container direction="column" >
                                            <Grid item>
                                                <PieChart width={200} height={180}>
                                                    <Pie data={SLAData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={90} fill="#FB8C00" >
                                                        {SLAData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                </PieChart>
                                            </Grid>
                                            <h3 className={classes.subTitleSla}>{SLAPercent}% Within SLA</h3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.graphCard} elevation={1}>
                            <h3 >Loads created over time</h3>
                            <Grid container direction="row" alignItems="center" spacing={2} className={classes.subTitleRow}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Grid container direction="column" >
                                            <Grid item>
                                                <BarChart width={400} height={230} data={loadsByDateCount}>
                                                    <XAxis dataKey="displayDate" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Bar dataKey="count" fill="#8884d8" />
                                                </BarChart>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default LoadDashboard;
