import { activityStatuses } from "features/activity/activityConsts";
import moment from "moment";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import FormatService from "services/formatService";
import { theme } from "styles/theme";
import GraphHeader from "../GraphHeader/GraphHeader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";
import { dashboardColors } from "../workorderDashboardConsts";
import { sum } from "lodash";

const WorkorderCostTotal = ({ workorders }) => {
    const classes = workorderDashboardPageStyles();

    const completed = workorders?.filter(a => a.activityStatus == activityStatuses.ItemCompleted) ?? [];
    const total = sum(completed?.map(a => a.revenue) ?? []);


    return (
        <div style={{ height: 350 }}>
            <GraphHeader title={'Total Cost'} />
            <div className={classes.totalCost}>
                {FormatService.formatCurrency(total)}
            </div>
        </div>
    )
}

export default WorkorderCostTotal;
