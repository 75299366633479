import { Chip, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setViewArchived } from "../issuesTrackerFiltersSlice";
import { IssueTrackerStyles } from "../style";

const IssueViewSelector = ({ }) => {
    const classes = IssueTrackerStyles();
    const { viewArchived } = useSelector(state => state.issuesTrackerFilters);
    const dispatch = useDispatch();

    return (
        <Grid container spacing={1} className={classes.viewTypeSelector}>
            <Grid item>
                <Chip variant="outlined" onClick={() => dispatch(setViewArchived(false))} label={`Active Tickets`} color={viewArchived ? "" : "primary"} />
            </Grid>
            <Grid item>
                <Chip variant="outlined" onClick={() => dispatch(setViewArchived(true))} label="Archived Tickets" color={viewArchived ? "primary" : ""} />
            </Grid>
        </Grid>
    )
}
export default IssueViewSelector;