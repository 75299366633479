import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WarningIcon from '@material-ui/icons/Warning';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import { useState } from 'react';
import { importAssetsResultsDialogStyle } from './ImportAssetsResultsDialogStyle';

function ImportAssetsResultsDialog({ importResults, error, open, setOpen, loading, reloadAssets }) {

    const classes = importAssetsResultsDialogStyle();
    const [showErrors, setShowErrors] = useState(true);
    const [showSkipped, setShowSkipped] = useState(true);
    const [showSuccess, setShowSuccess] = useState(true);

    const handleClose = () => {
        reloadAssets();
        setOpen && setOpen(false);
    };

    const handleShowErrors = () => {
        setShowErrors(!showErrors);
    };
    const handleShowSkipped = () => {
        setShowSkipped(!showSkipped);
    };
    const handleShowSuccess = () => {
        setShowSuccess(!showSuccess);
    };


    const importedAssets = error ? [] : importResults?.importedAssets;
    const failedAssets = error ? [] : importResults?.failedAssets;
    const skippedAssets = error ? [] : importResults?.skippedAssets;
    const accessCodeGeneratedAssets = error ? [] : importResults?.accessCodeGeneratedAssets;

    const stats = [
        {
            title: 'Imported Assets',
            count: importedAssets?.length,
            onClick: () => handleShowSuccess(),
            selected: showSuccess
        },
        {
            title: 'Skipped Assets',
            count: skippedAssets?.length,
            onClick: () => handleShowSkipped(),
            selected: showSkipped
        },
        {
            title: 'Failed Assets',
            count: failedAssets?.length,
            onClick: () => handleShowErrors(),
            selected: showErrors
        }
    ]

    const resultsToShow = [
        ...(showSuccess ? importedAssets ?? [] : []),
        ...(showSkipped ? skippedAssets ?? [] : []),
        ...(showErrors ? failedAssets ?? [] : [])
    ];

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="form-dialog-title"
            maxWidth={'lg'}
            fullWidth={true}>
            <DialogTitle id="import-dialog-title">{'Import Results'}</DialogTitle>
            <DialogContent className={classes.content}>
                {loading ?
                    <div>
                        <div className={classes.progressText}>Processing assets, please wait</div>
                        <div><LinearProgress /> </div>
                    </div> :
                    <>
                        <div className={classes.stats}>
                            {stats.map(s =>
                                <div onClick={() => s.onClick()} className={`${classes.importCountWrapper} ${s?.selected ? classes.selected : ''}`}>
                                    <div className={classes.count}> {s?.count}</div>
                                    <div className={classes.text}>{s?.title}</div>
                                </div>)}
                        </div>
                        {error?.data && Array.isArray(error?.data) ? <div>
                            {error?.data?.map((e, i) => <div className={classes.error} key={i}><WarningIcon />{e}</div>)}
                        </div> :
                            <div className={classes.items}>
                                {resultsToShow?.length ? resultsToShow?.map((assetResult, i) =>
                                    <ImportItem assetResult={assetResult} accessCodeGeneratedAssets={accessCodeGeneratedAssets} key={i}/>)
                                    :
                                    <EmptyPlaceholder />}
                            </div>
                        }
                    </>}
            </DialogContent>
            <DialogActions>
                {!loading && <Button onClick={handleClose} color="primary">
                    Done
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

export default ImportAssetsResultsDialog;

function ImportItem({ assetResult, accessCodeGeneratedAssets }) {
    const classes = importAssetsResultsDialogStyle();
    const [collapsed, setCollapsed] = useState(true);

    const accessCodeMessage = accessCodeGeneratedAssets?.find(a => 
        a?.asset?.assetId === assetResult?.asset?.assetId &&
        a?.asset?.vin === assetResult?.asset?.vin)?.message;

    return (

        <div className={classes.itemWrapper} onClick={() => setCollapsed(!collapsed)}>
            <div className={classes.itemHeader}>
                <div>
                    {assetResult.asset.vin && <div className={classes.itemTitle}>VIN: {assetResult.asset.vin}</div>}
                    {assetResult.asset.assetId && <div className={classes.itemTitle}> Asset id: {assetResult.asset.assetId}</div>}
                </div>
                <KeyboardArrowDownIcon />
            </div>
            <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                <div> {assetResult.message}</div>
                {accessCodeMessage && <div> {accessCodeMessage}</div>}
            </Collapse>
        </div>

    )
}