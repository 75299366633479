import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner';
import { useState } from 'react';
import { getApiUrl } from '../../../../app/baseQuery';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import CustomInput from '../../../../components/CustomInput';
import DropZoneInDialog from '../../../../components/DropZoneInDialog';
import NotificationProvider from '../../../../components/NotificationProvider';
import { useGetClientsQuery } from '../../../clients/clientSlice';
import { useGetImportLoadTemplateMutation, useImportLoadsMutation } from '../../loadsSlice';
import ImportLoadResultsDialog from './ImportLoadResultsDialog';
import { importLoadsStyle } from './ImportLoadsStyle';
import _ from 'lodash';

function ImportLoads({ }) {
    const classes = importLoadsStyle()
    let [importLoads, { data: importedLoads, error: importError, isLoading: importLoading }] = useImportLoadsMutation();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let [getTemplate, { data: importLoadTemplate, error: templateError, isLoading: templateLoading }] = useGetImportLoadTemplateMutation();

    const [selectedClient, setSelectedClient] = useState(clients?.[0]?.id);
    const [directDispatch, setDirectDispatch] = useState(false);
    const [showResults, setShowResults] = useState(false);

    let clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));

    const url = `${getApiUrl}load/downloadtemplate/`;

    const onSave = async (file) => {
        let fd = new FormData();
        fd.append("uploaded_file", file);
        fd.append("clientAccountId", selectedClient ?? clientAccounts?.[0]?.id);
        fd.append("directDispatch", directDispatch);
        const res = await importLoads(fd);
        setShowResults(true);
    }

    const getImportLoadTemplate = async () => {
        //window.open(url+selectedClient, "_blank")
        const result = await getTemplate(selectedClient);
        if (result && !result.error) {
            NotificationProvider.success("Downloaded template successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
    }

    return (
        <>
            <DropZoneInDialog
                labelDialog={'Import loads'}
                buttonLabel={'Import Loads'}
                onSave={onSave}
                accept={{ 'text/csv': ['.csv'], 'text/xlsx': ['.xlsx'], 'text/xls': ['.xls'] }}
                // valid={(selectedClient != null)}
                formContent={
                    <div className={classes.clientWrapper}>
                        <LoadingSpinner loading={templateLoading} />
                        {/* <Authorize profile={permissionProfiles.LOAD.LOAD_IMPORT_FIELDS}> */}
                        {clientAccounts?.length > 1 && 
                            <CustomInput
                                id="clientAccount"
                                label="Client"
                                value={selectedClient}
                                elementType="dropdown"
                                onChange={val => setSelectedClient(val)}
                                values={clientAccounts}
                                showEmpty
                                required
                            />
                        }
                        {/* </Authorize> */}
                        {selectedClient ? <div><Button color="primary"
                            className={classes.actionButton}
                            onClick={() => getImportLoadTemplate()}>Download Template</Button>
                        </div> : null}
                        <Authorize profile={permissionProfiles.LOAD.LOAD_IMPORT_FIELDS}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={directDispatch}
                                    onChange={(e, value) => setDirectDispatch(value)}
                                />}
                                label="Directly dispatch to carrier" />
                        </Authorize>
                    </div>
                }
            />
            <ImportLoadResultsDialog
                importResults={importedLoads}
                open={showResults || importLoading}
                setOpen={setShowResults}
                loading={importLoading}
                error={importError} />
        </>
    );
}

export default ImportLoads;