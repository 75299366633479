
function SvgIconLocationWrapper(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 29" fill={props?.fill ?? "#0090FE"} {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0427489 10.6078C0.28388 7.71693 1.615 5.0214 3.77189 3.05632C5.92877 1.09124 8.75371 0.000279836 11.686 0H12.3139C15.2462 0.000279836 18.0711 1.09124 20.228 3.05632C22.3849 5.0214 23.716 7.71693 23.9571 10.6078C24.2265 13.8353 23.2192 17.0403 21.1473 19.5476L14.1803 27.977C13.9162 28.2967 13.5836 28.5543 13.2065 28.7313C12.8294 28.9082 12.4173 29 11.9999 29C11.5826 29 11.1704 28.9082 10.7933 28.7313C10.4162 28.5543 10.0836 28.2967 9.81956 27.977L2.85398 19.5476C0.781614 17.0405 -0.226229 13.8355 0.0427489 10.6078Z" fill={props?.fill ?? "#0090FE"}  {...props}/>
    </svg>
  );
}

export default SvgIconLocationWrapper;
