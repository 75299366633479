import { Button, Checkbox, FormControlLabel, Grid, Paper, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DropdownFilter from "components/DropdownFilter";
import { EntityAuditTooltip } from "components/EntityAudit/EntityAuditTooltip";
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { LotTypeEnum, LotTypeEnumList } from 'features/lots/LotsEnums';
import { useGetTimeZonesQuery } from "features/lots/lotSlice";
import NoteList from 'features/notes/NoteList';
import { NoteEntityTypes } from 'features/notes/notesConsts';
import { useEffect, useState } from "react";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../components/CustomInput";
import { useGetContactTypesQuery } from "../../../contactType/contactTypeSlice";
import { lotDialogStyle } from "./LotDialogStyle";
import LotSupervisorDropdown from './LotRelations/Supervisor/LotSupervisorDropdown';
import PocContacts from "./PocContacts/PocContacts";
import LotScheduleGroups from "./Schedule/LotScheduleGroups";
import ServiceContacts from "./ServiceContacts/ServiceContacts";
import LotServiceProviders from './ServiceProviders/LotServiceProviders';

const LotDialog = ({ lot, onSave, onCancel, saving }) => {
    const classes = lotDialogStyle();
    const [lotToEdit, setLotToEdit] = useState();
    const [serviceContacts, setServiceContacts] = useState([]);
    const [relations, setRelations] = useState([]);
    const [pocContacts, setPocContacts] = useState([]);

    let { data: timeZones, error: timeZonesError, isFetching: isLoadingtimeZones } = useGetTimeZonesQuery();
    let { data: contactTypes, error: contactTypesError, isFetching: isContactTypes } = useGetContactTypesQuery();
    timeZones = timeZones || [];

    const timeZonesLookup = timeZones?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    let daysOfWeekList = [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thursday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" }
    ];

    const handleSave = async () => {
        let lotToSave = lotToEdit
        if (!lotToSave?.timeZone) {
            const easternTimeZone = timeZones.find(zone => zone.name === 'Eastern Standard Time')?.name;
            lotToSave.timeZone = easternTimeZone;
        }
        if (!lotToSave?.type) {
            lotToSave.type = LotTypeEnum.STANDARD;
        }
        let services = lotToEdit?.servicesContacts?.filter(l => l?.deletedDate === null);
        let contacts = lotToEdit?.pocContacts?.filter(l => l?.deletedDate === null);
        let relationsRequest = relations.filter(r => r.relationType && r.lotId && r.userId);
        let mergedContacts = [...services, ...contacts];
        lotToSave = { ...lotToSave, contacts: mergedContacts, relations: relationsRequest };
        await onSave(lotToSave);
    };

    const onChange = (field, val) => {
        setLotToEdit({ ...lotToEdit, [field]: val })
    };

    const includeField = (name) => {
        let include = false;
        let lotType = lotToEdit?.type;

        if (name == 'auditDay')
            include = lotToEdit?.pmfLot;
        else if (name == 'size' || name == 'featureFields' || name == 'timeZone')
            include = lotToEdit?.pmfLot || !(lotType == LotTypeEnum.OEM_SERVICE || lotType == LotTypeEnum.SERVICE_PROVIDER);
        else if (name == 'numberOfSpots' || name == 'carSpotsCount' || name == 'truckSpotsCount')
            include = lotType == LotTypeEnum.THIRD_PARTY || !lotToEdit?.pmfLot && lotType != LotTypeEnum.SERVICE_PROVIDER && lotType != LotTypeEnum.OEM_SERVICE;
        else if (name == 'lotNote')
            include = lotType == LotTypeEnum.OEM_SERVICE || lotType == LotTypeEnum.SERVICE_PROVIDER;
        else if (name == 'addressLine2')
            include = lotToEdit?.pmfLot || (lotType != LotTypeEnum.OEM_SERVICE && lotType != LotTypeEnum.SERVICE_PROVIDER);

        return include;
    }

    const fieldRequired = (name) => {
        let required = false;
        let lotType = lotToEdit?.type;

        if (name == 'size')
            required = lotToEdit?.pmfLot;
        else if (name == 'numberOfSpots')
            required = lotType == LotTypeEnum.THIRD_PARTY;
        else if (name == 'city' || name == 'addressLine1')
            required = lotToEdit?.pmfLot || !(lotType == LotTypeEnum.OEM_SERVICE || lotType == LotTypeEnum.SERVICE_PROVIDER);
        else if (name == 'pocContact')
            required = lotType != LotTypeEnum.OEM_SERVICE && lotType != LotTypeEnum.SERVICE_PROVIDER;

        return required;
    }

    const isServiceContactsValid = () => {
        if (lotToEdit?.type == LotTypeEnum.OEM_SERVICE || lotToEdit?.type == LotTypeEnum.SERVICE_PROVIDER)
            return serviceContacts?.length ? serviceContacts.every(s => s?.valid) : false;
        return serviceContacts?.length ? serviceContacts.every(s => s?.valid) : true;
    }

    useEffect(() => {
        if (lot) {
            let contactTypeFilter = contactTypes?.filter(x => x.isPoc);

            const updatedPocContacts = (lot.contacts || [])?.filter(contact =>
                contactTypeFilter?.some(filter => filter.id === contact.contactTypeId)
            )?.map(contact => ({
                ...contact,
                isRequiredField: true,
                valid: ["name", "phone", "email"].every(field =>
                    contact[field] && contact[field]?.trim() !== ""
                )
            }));

            const updatedServicesContacts = (lot.contacts || [])?.filter(contact =>
                !contactTypeFilter?.some(filter => filter.id === contact.contactTypeId)
            )?.map(contact => ({
                ...contact,
                isRequiredField: false,
                valid: ["email"].every(field =>
                    contact[field] && contact[field]?.trim() !== ""
                )
            }));

            setLotToEdit({ ...lot, pocContacts: updatedPocContacts, servicesContacts: updatedServicesContacts });
            setServiceContacts(updatedServicesContacts || []);
            setPocContacts(updatedPocContacts || []);
        } else {
            setLotToEdit();
            setServiceContacts([]);
            setPocContacts([]);
        }
    }, [lot, contactTypes]);


    const textField = (field) => (
        <>
            <CustomInput
                id={field?.name}
                label={field?.label}
                value={lotToEdit?.[field?.name] ?? ''}
                elementType="input"
                onChange={val => onChange(field?.name, val)}
                required={field?.required}
                numberOnly={field?.numberOnly}
            />
            {
                field?.valid === false &&
                <p className={classes.invalid}>{field?.invalidMessage}</p>
            }
        </>
    );

    const basicFields = [
        {
            name: 'name',
            label: 'Name',
            required: true
        },
        {
            name: 'sku',
            label: 'SKU',
            profile: permissionProfiles.LOT.LOT_EDIT_CREATE,
            required: lotToEdit?.pmfLot
        },
        {
            name: 'addressLine1',
            label: 'Address',
            required: fieldRequired('addressLine1')
        },
        includeField('addressLine2') ? {
            name: 'addressLine2',
            label: 'Address 2',
        } : null,
        {
            name: 'city',
            label: 'City',
            required: fieldRequired('city')
        },
        {
            name: 'state',
            label: 'State',
            required: true
        },
        {
            name: 'zipCode',
            label: 'Zip Code',
            required: true
        },
        includeField('numberOfSpots') ? {
            name: 'numberOfSpots',
            label: 'Number of Spots',
            numberOnly: true,
            required: fieldRequired('numberOfSpots')
        } : null,
        includeField('size') ? {
            name: 'size',
            label: 'Size in Acres',
            numberOnly: true,
            required: fieldRequired('size')
        } : null,
        includeField('carSpotsCount') ? {
            name: 'carSpotsCount',
            label: 'Count of Car Spots',
            numberOnly: true
        } : null,
        includeField('truckSpotsCount') ? {
            name: 'truckSpotsCount',
            label: 'Count of Truck Spots',
            numberOnly: true
        } : null,
        includeField('auditDay') ?
            {
                name: 'auditDay',
                label: 'Audit Day',
                component: <DropdownFilter
                    title='Audit Day'
                    identifier='auditDay'
                    values={daysOfWeekList}
                    onSelect={(val) => onChange("auditDay", val === "" ? null : val)}
                    value={lotToEdit?.auditDay ?? ""}
                    showEmpty={true} />
            } : null,
        includeField('timeZone') ?
            {
                name: 'timeZone',
                label: 'Time Zone',
                component: <DropdownFilter
                    title='Time Zone'
                    identifier='timeZone'
                    values={timeZones}
                    onSelect={(val) => onChange("timeZone", timeZonesLookup?.[val]?.name)}
                    value={timeZones?.find(t => t.name === lotToEdit?.timeZone)?.id ?? timeZones.find(zone => zone.name === 'Eastern Standard Time')?.id}
                />
            } : null,
        {
            name: 'type',
            label: 'Type',
            component: <DropdownFilter
                title='Type'
                identifier='id'
                values={lotToEdit?.pmfLot ? LotTypeEnumList?.filter(t => t.id == LotTypeEnum.SERVICE_PROVIDER || t.id == LotTypeEnum.STANDARD) : LotTypeEnumList}
                onSelect={(val) => onChange("type", val === "" ? LotTypeEnum.STANDARD : parseInt(val))}
                value={lotToEdit?.['type'] ?? LotTypeEnum.STANDARD}
            />
        },
        {
            name: 'pmfLot',
            label: 'PMF Lot',
            component: <FormControlLabel control={<Checkbox
                title="PMF Lot"
                checked={lotToEdit?.pmfLot}
                onChange={(e, val) => onChange("pmfLot", val)}
            />}
                label="PMF Lot">
            </FormControlLabel>
        }
    ];

    const drivingFields = [
        {
            name: 'drivingDirections',
            label: 'Driving Directions',
            component: <RichTextEditor
                content={lotToEdit?.['drivingDirections'] ?? ''}
                onChange={val => onChange('drivingDirections', val)}
            />
        }
    ];

    const notesField = [
        includeField('lotNote') ? {
            name: 'lotNotes',
            label: 'Notes',
            component: <NoteList entityId={lotToEdit?.id} entityType={NoteEntityTypes.LOT} />
        } : null,
    ]

    const featureFields = includeField('featureFields') ?
        [
            {
                name: 'officeCount',
                label: 'Count of Offices',
                numberOnly: true
            },
            {
                name: 'bathroomCount',
                label: 'Count of Bathrooms',
                numberOnly: true
            },
            {
                name: 'amentiesCount',
                label: 'Count of Amenties',
                numberOnly: true
            },
            {
                name: 'mechRoomCount',
                label: 'Count of Mechanical Rooms',
                numberOnly: true
            },
        ] : [];

    const serviceProvidersField = [
        {
            name: 'serviceProviders',
            label: 'Service Providers',
            component: <LotServiceProviders lotId={lot?.id} />
        }
    ]

    const lotRelationFields = [
        {
            name: 'lotSupervisor',
            label: 'Supervisor',
            component: <LotSupervisorDropdown lot={lotToEdit} onChange={setRelations} isPMFlot={lotToEdit?.pmfLot}></LotSupervisorDropdown>,
            profile: permissionProfiles.LOT.LOT_DIALOG
        },
    ]

    const pocContactsFields = [
        {
            name: 'pocContact',
            label: 'POC Contact',
            profile: permissionProfiles.LOT.LOT_CONTACTS,
            component: <PocContacts
                contacts={pocContacts}
                setContacts={setPocContacts}
                lotToEdit={lotToEdit}
                setLotToEdit={setLotToEdit} />,
            valid: pocContacts.length > 0 ? pocContacts.every(s => s?.valid) : true,
            required: fieldRequired('pocContact')
        },
    ]
    const serviceContactsFields = [
        {
            name: 'services',
            label: 'Service Contact',
            component: <ServiceContacts
                contacts={serviceContacts}
                setContacts={setServiceContacts}
                lotToEdit={lotToEdit}
                setLotToEdit={setLotToEdit} />,

            profile: permissionProfiles.LOT.LOT_CONTACTS,
            valid: isServiceContactsValid()
        },
    ]

    const fieldGroups = [
        {
            label: "Basic Information",
            fields: basicFields
        },
        {
            label: "Driving Directions",
            fields: drivingFields
        },
        {
            label: "Features",
            fields: featureFields
        },
        {
            label: "Service Providers",
            fields: serviceProvidersField,
            profile: permissionProfiles.LOT.LOT_SERVICE_PROVIDERS
        },
        ...(includeField('lotNote') ? [{
            label: "",
            fields: notesField,
        }] : []),
        // {
        //     label: "Supervisors",
        //     fields: lotRelationFields,
        //     profile: permissionProfiles.LOT.LOT_DIALOG
        // },
        {
            label: "POC Contacts",
            fields: pocContactsFields,
            profile: permissionProfiles.LOT.LOT_CONTACTS
        },
        {
            label: "Service Contacts",
            fields: serviceContactsFields,
            profile: permissionProfiles.LOT.LOT_CONTACTS
        },
    ]?.filter(group => group.fields?.length);

    const isValid = () => {
        const basicValid = basicFields.every(field => !field?.required || lotToEdit?.[field?.name] || field?.valid);
        const pocValid = pocContactsFields?.every(field => !field?.required || field?.valid);
        const serviceValid = serviceContactsFields?.every(field => field?.valid);
        return basicValid && pocValid && serviceValid;
    }

    const getValidMessages = () => {
        let messages = [];
        const basicValid = basicFields.every(field => !field?.required || lotToEdit?.[field?.name] || field?.valid);
        if (!basicValid)
            messages.push("Please make sure all required basic information is filled out.\n");
        const pocValid = pocContactsFields?.every(field => !field?.required || field?.valid);
        if (!pocValid)
            messages.push("Please make sure all required POC Contact fields are filled out.\n");
        const serviceValid = serviceContactsFields?.every(field => field?.valid);
        if (!serviceValid)
            messages.push("Please make sure all required Service Contacts fields are filled out.");
        if ((lotToEdit?.type == LotTypeEnum.OEM_SERVICE || lotToEdit?.type == LotTypeEnum.SERVICE_PROVIDER) && serviceContacts?.length < 1)
            messages.push("At least one Service Contact is required.");
        return messages;
    }


    const hideSection = (group) => {
        if (group.label == 'Supervisors' && !lotToEdit?.pmfLot) {
            return true;
        }
        return false;
    }

    return (
        <Dialog open={!!lotToEdit} onClose={onCancel} aria-labelledby="form-dialog-title" maxWidth='lg'>
            <DialogTitle id="form-dialog-title">
                {lotToEdit?.id ? 'Lot Information' : 'Create Lot'}
                <EntityAuditTooltip entity={lotToEdit} />
            </DialogTitle>
            <DialogContent>
                {fieldGroups?.map(group =>
                    <Authorize profile={group.profile} key={group.label}>
                        {
                            !hideSection(group) &&
                            <Paper className={classes.fieldGroup}>
                                <Grid container xs={12}>
                                    <Grid xs={12} className={classes.groupTitle}>{group.label}</Grid>
                                    {group?.fields?.filter(field => field != null)?.map(field =>
                                        <Grid key={field?.name} item xs={12} >
                                            <div className={classes.inputWrapper}>
                                                <Authorize profile={field?.profile ?? permissionProfiles.LOT.LOT_DIALOG} readonlyComponent={<div>{field?.label}: {lotToEdit?.[field?.name]}</div>}>
                                                    {field?.component ??
                                                        textField(field)
                                                    }
                                                </Authorize>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        }
                    </Authorize>
                )}
                <Paper className={classes.fieldGroup}>
                    <LotScheduleGroups
                        schedules={lotToEdit?.schedule || []}
                        lotToEdit={lotToEdit}
                        setLotToEdit={setLotToEdit}
                    />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                    <Tooltip title={
                        <div>
                            {getValidMessages()?.length ? getValidMessages()?.map((line, index) => (
                                <div key={index}>
                                    {line}
                                    <br />
                                </div>
                            )) : "Save"}
                        </div>
                    } >
                        <span>
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                                disabled={saving || !isValid()}>
                                {saving ? 'Saving...' : 'Save'}
                            </Button>
                        </span>

                    </Tooltip>

                </Authorize>
            </DialogActions>
        </Dialog >
    );
};

export default LotDialog;
