import {Button, Grid, Paper} from "@material-ui/core";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import BasicModal from "components/BasicModal";
import CsvDownload from "components/CsvDownload";
import moment from "moment";
import {useState} from "react";
import {vehiclePageStyles} from "./vehiclePageStyle";
import {isAuthorized} from "../../components/Authorize/authorizeUtil";
import {permissionProfiles} from "../../components/Authorize/permissionProfiles";

const ExportReportItemAction = ({downloadEvent, data, vin = null,}) => {
    const [openedModal, setOpenedModall] = useState(null);
    const icon = <img src="/icons/icon-download.svg"/>;
    const classes = vehiclePageStyles();
    const [csvExport, setCsvExport] = useState('');

    const getFileName = () => {
        const timestamp = moment().format("YYYYDDMMhmmss");
        const fileNameMiddleStamp = vin ? '-' + vin : '';
        return `export${fileNameMiddleStamp}-${timestamp}.csv`;
    };

    const handledConfirmDownload = async (reportType) => {
        downloadEvent(reportType, data);
        setOpenedModall(false);
    }

    return (
        <>
            <CsvDownload href={csvExport} fileName={getFileName()}/>
            <BasicModal
                open={openedModal}
                header={`Download Report`}
                wide={true}
                handleClose={() => setOpenedModall(false)}
                component={<ExportAssetReportMenu downloadEvent={handledConfirmDownload}/>}
            />
            <Button variant="contained" color="primary" className={classes.actionButton} startIcon={icon}
                    onClick={() => setOpenedModall(true)}>
                Export
            </Button>
        </>
    )
}

const ExportAssetReportMenu = ({downloadEvent}) => {
    const [value, setValue] = useState(null);
    const classes = vehiclePageStyles();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const reportsTypes = [
        {
            name: "Short Report",
            report: 1,
            permission: isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_SHORT_REPORT)
        },
        {
            name: "Everything on the page",
            report: 2,
            permission: isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_EVERYTHING_ON_THE_PAGE_REPORT)
        },
        {
            name: "Long Report (Include activities)",
            report: 3,
            permission: isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_LONG_REPORT)
        }
    ];

    const confirmDownloadReport = () => {
        downloadEvent(value);
    }

    return (
        <>
            <Paper className={classes.card}>
                <Grid container direction="column">
                    <Grid item xs={8}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                                style={{padding: 20}}
                            >
                                {
                                    reportsTypes && (
                                        reportsTypes.map((reportType) => (reportType.permission && (
                                                <FormControlLabel value={reportType.report} control={<Radio/>}
                                                                  label={reportType.name}/>
                                            )
                                        ))
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                {value && (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button variant="contained" color="primary" onClick={() => confirmDownloadReport()}
                                disabled={!value}>
                            Export
                        </Button>
                    </div>)
                }
                {
                    !value && (
                        <div className="ml-5" style={{color: "red", fontWeight: "bold"}}>
                            <Alert severity="error"
                                   style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Please select a
                                report type.</Alert>
                        </div>
                    )
                }
            </Paper>
        </>
    )
}


export default ExportReportItemAction;