import { Button, Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { IconDeleteBlue } from "icons";
import CustomInput from "../../../../../components/CustomInput";
import PhoneInput from "../../../../../components/PhoneInput";
import Validator from "../../../../../services/validator";
import { ContactTypeField } from "../../../../contactType/component/ContactTypeField/ContactTypeField";
import ServiceContactsStyle from "./ServiceContactsStyle";

const ServiceContacts = ({contacts, setContacts, lotToEdit, setLotToEdit}) => {
  const classes = ServiceContactsStyle();
  contacts = contacts || [];
  const addNewContact = () => {
    const newObj = {
      id: 0,
      lotId: lotToEdit.id,
      name: null,
      email: null,
      isEmailValid: null,
      phone: null,
      note: null,
      contactTypeId: 0,
      deletedDate: null,
      valid: false,
      isRequiredField: false
    };
    setContacts([...contacts, newObj]);
  };
  const onDelete = (index) => {
    let updatedContacts = [...contacts];
    updatedContacts[index] = {...updatedContacts[index], deletedDate: new Date(), valid: true}
    setContacts(updatedContacts);
    setLotToEdit({...lotToEdit, servicesContacts: updatedContacts});
  }
  const updateContacts = (index, val, field, validateEmail = true) => {
    const updatedContacts = [...contacts];
    updatedContacts[index] = {
      ...updatedContacts[index],
      [field]: val,
    };
        
    if (validateEmail)
      updatedContacts[index].valid = !!Validator.validateEmail(val);

    setContacts(updatedContacts);
    setLotToEdit({...lotToEdit, servicesContacts: updatedContacts});
  };
  const onChangeEmail = (index, val, field) => {
    updateContacts(index, val, field);
  };
  const onChange = (index, val, field) => {
    updateContacts(index, val, field, false);
  };

  return (
      <>
        {contacts.map((item, index) => {
          if (item.deletedDate === null) {
            return (
                <Grid container key={index} className={classes.gridContainer}>
                  <Grid item xs={11} className={classes.dropDownLine}>
                    <ContactTypeField label={"Title"}
                                      value={item?.contactTypeId}
                                      contactTypeFilter={{}}
                                      isPoc={false}
                                      onChange={(val) => onChange(index, val, 'contactTypeId')}
                                      required={true}/>
                  </Grid>
                  <Grid item xs={11} className={classes.dropDownLine}>
                    <CustomInput
                        label="Name"
                        identifier="name"
                        required={item.isRequiredField}
                        value={item?.name}
                        onChange={(val) => onChange(index, val, 'name')}
                    />
                  </Grid>
                  <Grid item xs={11} className={classes.dropDownLine}>
                    <CustomInput
                        label="Email"
                        identifier="email"
                        required={true}
                        value={item?.email}
                        onChange={(val) => onChangeEmail(index, val, 'email')}
                        valid={item?.isEmailValid}
                    />
                    {
                        item?.email?.valid === false &&
                        <p className={classes.invalid}>Invalid Email</p>
                    }
                  </Grid>
                  <Grid item xs={11} className={classes.dropDownLine}>
                    <PhoneInput
                        label="Phone Number"
                        identifier="phone"
                        required={item.isRequiredField}
                        value={item?.phone}
                        onChange={(val) => onChange(index, val, 'phone')}
                    />
                  </Grid>
                  <Grid item xs={11} className={classes.dropDownLine}>
                    <CustomInput
                        label="Note"
                        identifier="note"
                        value={item?.note}
                        onChange={(val) => onChange(index, val, 'note')}
                    />
                  </Grid>
                  <>
                    <IconButton
                        className={classes.deleteButton}
                        aria-label="delete"
                        onClick={() => onDelete(index)}
                    >
                      <IconDeleteBlue width={"25"}/>
                    </IconButton>
                  </>
                </Grid>
            );
          }
          return null;
        })}
        <div>
          <Button
              variant="outlined"
              color="primary"
              onClick={addNewContact}
              className={classes.buttonSchedule}
              startIcon={<AddIcon/>}
          >
            Add Service Contact
          </Button>
        </div>
      </>
  );
};

export default ServiceContacts;