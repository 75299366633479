import AttachmentsView from 'features/attachments/AttachmentsView';
import { AttachmentEntityTypes } from 'features/attachments/attachmentsConsts';
import { IconCommentBlue, IconPhoto } from "icons";
import { useEffect, useState } from "react";
import { TaskAttachmentsStyle } from './style';
import { Grid } from '@material-ui/core';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';


const TaskAttachments = ({ issueTracker }) => {
    const classes = TaskAttachmentsStyle();

    useEffect(() => {
    }, [])


    return (
        <Grid className={classes.wrapper}>
            <Grid container direction='column' alignItems="center" justifyContent='space-between' className={classes.titleWrapper}>
                <Grid item container alignItems='center' spacing={1}>
                    <Grid item style={{ display: 'flex' }} > <IconPhoto /></Grid>
                    <Grid item> <div className={classes.title}>Attachments</div></Grid>
                </Grid>
                <AttachmentsView
                    addPermissionProfile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_EDIT}
                    addBtnStyle=''
                    useIconButton
                    entityId={issueTracker?.id}
                    entityType={AttachmentEntityTypes.ISSUE_TRACKER}
                />
            </Grid>

        </Grid>
    )
}

export default TaskAttachments;