import { makeStyles } from "@material-ui/core"

export const cameraEventsStyle = makeStyles((theme) => ({
    action: {
        border: '1px solid ',
        borderColor: theme.palette.primary.main,
        padding: '3px',
        marginRight: '0.2em',
        height: '1.2em',
        width: '1.2em',
        '& svg': {
            height: '80%',
            width: '80%',
        }
    },
    refresh: {
        margin: "auto",
    },
    spin: {
        margin: "auto",
        animation: "$spin 1s 1",
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },

}));