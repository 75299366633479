import { makeStyles } from "@material-ui/core";

export const lotPanelStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: '100%'
    },
    detailsPaper: {
        padding: '1rem',
        width: '100%',
        height: '100%',
    },
    lotSummary: {
        padding: '1.5rem 0 0 0.5rem'
    },
    mapPaper: {
        width: '100%',
        height: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    closeIcon: {
        position: 'absolute',
        right: 0,
        marginRight: '1rem'
    },
    closeIconSmall: {
        position: 'absolute',
        right: 0,
    },
    paperSmall: {
        padding: '0.5rem 1rem 1rem 1rem',
        width: '100%',
    },
    lotMapWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    noMapWrapper: {
        marginTop: '80%'
    },
    deletePopup: {
        padding: '1em',
        maxWidth: 400,
        margin: '1em',
        fontWeight: 600
    },
}));
