import { Grid } from "@material-ui/core";
import { CreateWorkOrderStyles } from '.././CreateWorkOrderStyles';

const ActivityPricing = ({ pricing, cost }) => {
    const classes = CreateWorkOrderStyles();

    return (<>
        {pricing?.map(p =>
            <Grid container spacing={1}>

                <div className={classes.activityPricingVin}>
                    <Grid item className={classes.activityPricing} >
                        Asset:
                    </Grid>

                    <Grid item>
                        {p.descriptor}
                    </Grid>
                </div>

                <div>
                    <Grid item className={classes.activityPricing}>
                        {cost ? " Internal Cost: " : " Charge: "}
                    </Grid>

                    <Grid item>
                        {cost ? p['cost'] : p['revenue']}
                    </Grid>
                </div>

            </Grid>
        )}
    </>);
}
export default ActivityPricing;