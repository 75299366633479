import { apiTags } from 'app/store/apiTags';
import { baseApi } from '../../../app/baseApi';
import { baseQuery } from "../../../app/baseQuery";

export const readinessApi = baseApi.injectEndpoints({
    reducerPath: 'readinessApi',
    tagTypes: ["Readiness"],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getReadiness: builder.query({
            query: ({ includeHistory = false }) => `vehicle/readiness?includeHistory=${true}`,
            providesTags: [apiTags.READINESS]
        }),
        updateReadinessConfig: builder.mutation({
            query: body => ({
                url: `readiness/config`,
                method: "PUT",
                body: body
            }),
            providesTags: [apiTags.READINESS_CONFIG],
            invalidatesTags: [apiTags.CLIENT_CONFIG, apiTags.READINESS_CONFIG],
        }),
        updateVehicleReadiness: builder.mutation({
            query: ({ vehicleId, readiness }) => ({
                url: `readiness`,
                method: "PUT",
                body: {
                    vehicleId,
                    readiness
                }
            }),
            invalidatesTags: [apiTags.READINESS],
        }),
    }),
});

export const {
    useGetReadinessQuery,
    useUpdateReadinessConfigMutation,
    useUpdateVehicleReadinessMutation
} = readinessApi;
