import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
import NotificationProvider from "../../../../../components/NotificationProvider";
import { useMergeAssetsMutation } from "../../../vehicleSlice";
import { assetResolveDuplicateStyle } from "./AssetResolveDuplicateStyle";
import VehicleStatusView from "features/vehicles/VehicleStatus/VehicleStatusView";

const AssetResolveDuplicate = ({ asset }) => {
    const classes = assetResolveDuplicateStyle();
    let [mergeAssets, { data: mergeAssetsResult, error: mergeAssetsError, isLoading: mergeLoading }] = useMergeAssetsMutation();

    const [assetIdToKeep, setAssetIdToKeep] = useState(null);
    const [assetIdsToDelete, setAssetIdsToDelete] = useState([]);
    const [open, setOpen] = useState();

    const addAssetIdToDelete = (assetId) => {
        let ids = [...assetIdsToDelete];
        if (assetIdsToDelete?.find(id => id == assetId)) {
            ids = ids?.filter(id => id != assetId);
        }
        else {
            ids = [...ids, assetId];
        }
        setAssetIdsToDelete(ids);
    }

    const keepThisAsset = (assetId) => {
        return assetIdToKeep == assetId;
    }

    const deleteThisAsset = (assetId) => {
        return assetIdsToDelete?.find(id => id == assetId);
    }

    const onClose = () => {
        setOpen(false);
    }

    const onSave = async () => {
        const res = await mergeAssets(
            asset?.vehicles?.map(a => ({
                assetId: a.vehicleId,
                keep: keepThisAsset(a.vehicleId),
                delete: !!deleteThisAsset(a.vehicleId)
            }))
        );
        if (res?.error) {
            NotificationProvider.error('Failed to merge assets');
        } else {
            NotificationProvider.success('Successfully merged assets');
            onClose();
        }
    }

    const fields = [
        {
            key: 'vin',
            label: 'VIN',
        },
        {
            key: 'assetId',
            label: 'Asset Id',
        },
        {
            key: 'lotName',
            label: 'Lot',
        },
        {
            key: 'assetStatusId',
            label: 'Asset Status',
            component: (asset) => {
                return asset.assetStatusId ? <VehicleStatusView value={asset.assetStatusId} clientId={asset?.clientId} /> : <>{asset.assetStatus}</>
            }
        },
        {
            key: 'dateInFormatted',
            label: 'Last In',
        },
        {
            key: 'dateOutFormatted',
            label: 'Last Out',
        },
        {
            key: 'statusName',
            label: 'Status',
        },
    ]

    return (
        <>
            <Button color='primary' variant="outlined" className={classes.button} onClick={() => setOpen(true)}>Resolve</Button>
            <Dialog maxWidth='sm' fullWidth open={open} onClose={() => onClose()} aria-labelledby='dialog-title'>
                <DialogTitle>
                    Merge Assets
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={3}>
                        {asset?.vehicles &&
                            <Grid item>
                                <Alert severity="warning" className={classes.mergeAlert} >
                                    {asset?.vehicles?.length + ' duplicate assets were found. Please select the Asset Information which is correct for this asset and the assets that should be deleted and merged into the correct asset. Please note that all information from the assets that are marked to be deleted, including photos and activity log, will be merged into the asset selected to keep.'}
                                </Alert>
                            </Grid>
                        }
                        {asset?.vehicles?.map((a, index) =>
                            <Grid item key={index} >
                                <Paper className={`${classes.assetWrapper} ${keepThisAsset(a.vehicleId) ? classes.keepBorder : deleteThisAsset(a.vehicleId) ? classes.deleteBorder : ''}`}>
                                    <Grid container alignItems='center'>
                                        <Grid item >
                                            <Grid container direction="column" spacing={2}>
                                                {fields.map((f, findex) =>
                                                    <Grid key={findex} item >
                                                        <Grid container spacing={2}>
                                                            <Grid item className={classes.label}> {f.label}:</Grid>
                                                            <Grid item>{f.component ? f.component(a) :
                                                                <>{a[f.key]}</>
                                                            }</Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item className={classes.buttonWrapper}>
                                        <Button
                                            variant="outlined"
                                            className={classes.keepButton}
                                            startIcon={
                                                <Checkbox
                                                    color="success"
                                                    icon={<CheckBoxOutlineBlankIcon className={assetIdToKeep && !keepThisAsset(a.vehicleId) || deleteThisAsset(a.vehicleId) ? classes.disabledIcon : classes.checkIcon} />}
                                                    checkedIcon={<CheckIcon className={assetIdToKeep && !keepThisAsset(a.vehicleId) || deleteThisAsset(a.vehicleId) ? classes.disabledIcon : classes.checkIcon} />}
                                                    checked={keepThisAsset(a.vehicleId)}
                                                />
                                            }
                                            onClick={() => setAssetIdToKeep(keepThisAsset(a.vehicleId) ? null : a.vehicleId)}
                                            disabled={assetIdToKeep && !keepThisAsset(a.vehicleId) || deleteThisAsset(a.vehicleId)}
                                        >
                                            Keep
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            className={classes.deleteButton}
                                            startIcon={
                                                <Checkbox
                                                    color="error"
                                                    icon={<CheckBoxOutlineBlankIcon className={keepThisAsset(a.vehicleId) ? classes.disabledIcon : classes.closeIcon} />}
                                                    checkedIcon={<CloseIcon className={keepThisAsset(a.vehicleId) ? classes.disabledIcon : classes.closeIcon} />}
                                                    checked={deleteThisAsset(a.vehicleId)}
                                                />
                                            }
                                            onClick={() => addAssetIdToDelete(a.vehicleId)}
                                            disabled={keepThisAsset(a.vehicleId)}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color='primary'
                        onClick={onSave}
                        disabled={!assetIdToKeep || mergeLoading}>
                        {mergeLoading ? 'Saving...' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AssetResolveDuplicate;