import * as React from "react";

function IconText(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.875 3.21875H2.125C2.12048 3.21875 2.11263 3.21709 2.10402 3.20848C2.09541 3.19987 2.09375 3.19202 2.09375 3.1875C2.09375 3.18298 2.09541 3.17513 2.10402 3.16652C2.11263 3.15791 2.12048 3.15625 2.125 3.15625H14.875C14.8795 3.15625 14.8874 3.15791 14.896 3.16652C14.9046 3.17513 14.9062 3.18298 14.9062 3.1875C14.9062 3.19202 14.9046 3.19987 14.896 3.20848C14.8874 3.21709 14.8795 3.21875 14.875 3.21875Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M8.83292 6.76025H2.125C2.12048 6.76025 2.11263 6.75859 2.10402 6.74998C2.09541 6.74137 2.09375 6.73353 2.09375 6.729C2.09375 6.72448 2.09541 6.71664 2.10402 6.70803C2.11263 6.69941 2.12048 6.69775 2.125 6.69775H8.83292C8.83744 6.69775 8.84528 6.69941 8.8539 6.70803C8.86251 6.71664 8.86417 6.72448 8.86417 6.729C8.86417 6.73793 8.86097 6.74551 8.85493 6.75161C8.85194 6.75463 8.84875 6.75669 8.84573 6.75798C8.84307 6.75911 8.83918 6.76025 8.83292 6.76025Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M14.875 10.3022H2.125C2.12048 10.3022 2.11263 10.3006 2.10402 10.292C2.09541 10.2834 2.09375 10.2755 2.09375 10.271C2.09375 10.2665 2.09541 10.2586 2.10402 10.25C2.11263 10.2414 2.12048 10.2397 2.125 10.2397H14.875C14.8795 10.2397 14.8874 10.2414 14.896 10.25C14.9046 10.2586 14.9062 10.2665 14.9062 10.271C14.9062 10.2755 14.9046 10.2834 14.896 10.292C14.8874 10.3006 14.8795 10.3022 14.875 10.3022Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M8.83292 13.8438H2.125C2.12048 13.8438 2.11263 13.8421 2.10402 13.8335C2.09541 13.8249 2.09375 13.817 2.09375 13.8125C2.09375 13.808 2.09541 13.8001 2.10402 13.7915C2.11263 13.7829 2.12048 13.7812 2.125 13.7812H8.83292C8.83744 13.7812 8.84528 13.7829 8.8539 13.7915C8.86251 13.8001 8.86417 13.808 8.86417 13.8125C8.86417 13.8214 8.86097 13.829 8.85493 13.8351C8.85194 13.8381 8.84875 13.8402 8.84573 13.8415C8.84307 13.8426 8.83918 13.8438 8.83292 13.8438Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default IconText;
