import { makeStyles } from "@material-ui/core";

export const PrioritySelectorStyle = makeStyles((theme) => ({
    priority: {
        color: 'white',
        borderRadius: '5px',
        padding: '3px 5px',
    },
    priority1: {
        color: '#E02B1D',
        '& path': {
            stroke: '#E02B1D',
            fill: '#E02B1D',
        }
    },
    priority2: {
        color: '#E02B1D',
        '& path': {
            stroke: '#E02B1D',
            fill: '#E02B1D',
        }
    },
    priority3: {
        color: '#E48900',
        '& path': {
            stroke: '#E48900',
            fill: '#E48900',
        }
    },
    priority4: {
        color: '#E48900',
        '& path': {
            stroke: '#E48900',
            fill: '#E48900',
        }
    },
    priority5: {
        color: '#8793A1',
        '& path': {
            stroke: '#8793A1',
            fill: '#8793A1',
        }
    }
}))