import { baseApi } from '../../app/baseApi';

export const meApi = baseApi.injectEndpoints({
    reducerPath: 'loadApi',
    endpoints: (builder) => ({
        changePassword: builder.mutation({
            query: (body) => ({
                url: `me/changepassword`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Me"]
        }),
    }),
});


export const {useChangePasswordMutation } = meApi;
