import {createSlice} from "@reduxjs/toolkit";

export const activityTypeFiltersSlice = createSlice({
    name: 'activityTypeFilters',
    initialState: {
        filters: {
            activityTypeId: {value: null},
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
});

export const { setFilters } = activityTypeFiltersSlice.actions;
export default activityTypeFiltersSlice.reducer;
