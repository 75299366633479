import { createSlice } from '@reduxjs/toolkit';
import { baseApi } from '../../../../../app/baseApi';
import { baseQuery } from "../../../../../app/baseQuery";
import { apiTags } from '../../../../../app/store/apiTags';

export const workflowApi = baseApi.injectEndpoints({
    reducerPath: 'workflowApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getDefaultStepDefinition: builder.query({
            query: () => `stepDefinition/defaultStepDefinition`,
        }),
        getStepDefinitionDetailsGroup: builder.query({
            query: () => `stepDefinitionDetailsGroup`,
        }),
        getStepDefinitionsByClient: builder.query({
            query: (clientId) => `stepDefinition/${clientId}/GetByClient`,
            providesTags: [apiTags.STEP_DEFINITION],
        }),
        getStepDefinitionsDetailsById: builder.query({
            query: (stepDefinitionId) => `stepDefinitionDetails/${stepDefinitionId}/GetByStepDefinition`,
            providesTags: [apiTags.STEP_DEFINITION_DETAILS],
        }),
        updateStepDefinition: builder.mutation({
            query: stepDefinition => ({
                url: "stepDefinition",
                method: "POST",
                body: stepDefinition
            }),
            invalidatesTags: [apiTags.STEP_DEFINITION, apiTags.STEP_DEFINITION_DETAILS, apiTags.VEHICLES_TYPES]
        })
    }),
});

export const {
    useGetDefaultStepDefinitionQuery,
    useGetStepDefinitionDetailsGroupQuery,
    useGetStepDefinitionsByClientQuery,
    useGetStepDefinitionsDetailsByIdQuery,
    useUpdateStepDefinitionMutation
} = workflowApi;

export const mobileWorkflowSlice = createSlice({
    name: 'mobileWorkflowSlice',
    initialState: {
        selectedVehicleTypes: [],
    },
    reducers: {
        setSelectedVehicleTypes: (state, action) => {
            state.selectedVehicleTypes = action.payload;
        },

    },
});

export const { setSelectedVehicleTypes } = mobileWorkflowSlice.actions;