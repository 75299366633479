import { Box, Grid } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IconSearch } from 'icons';
import { theme } from 'styles/theme';
import { CreateWorkOrderStyles } from '../CreateWorkOrderStyles';
import ServiceProviderSelectorItem from './ServiceProviderSelectorItem';

const ServiceProvider = ({ serviceProvider, setServiceProvider, serviceProviders, isNarrowScreen }) => {
    const classes = CreateWorkOrderStyles();

    return (
        <Grid container direction='row' spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12} className={classes.activityLabel}>
                    Service Provider
                </Grid>
                <Autocomplete
                    autoComplete
                    filterSelectedOptions
                    options={serviceProviders}
                    value={serviceProviders?.find(a => a.id == serviceProvider)}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                        return option?.id == value?.id
                    }}
                    PaperComponent={Box}
                    PopperComponent={Box}
                    open
                    onChange={(event, val) => setServiceProvider(val?.id)}
                    placeholder='Service Provider'
                    noOptionsText={'No Service Providers'}
                    renderOption={(props, option) =>
                        <ServiceProviderSelectorItem
                            props={props}
                            option={option}
                            isNarrowScreen={isNarrowScreen}
                        />}
                    popupIcon={<IconSearch fill={theme.palette.primary.main} className={classes.searchIcon} />}
                    renderInput={(params) => (
                        <TextField {...params} fullWidth />
                    )}
                    className={classes.autoComplete}
                />
            </Grid>
        </Grid>
    )
}

export default ServiceProvider;