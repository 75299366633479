import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from '@material-ui/core';
import { EntityAuditTooltip } from 'components/EntityAudit/EntityAuditTooltip';
import NotificationProvider from 'components/NotificationProvider';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useEffect, useState } from 'react';
import { NoteEntityTypes } from './notesConsts';
import { useAddNoteMutation, useEditNoteMutation } from './notesSlice';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';

const NoteEditor = ({
    note,
    onClose,
    withDialog = true,
    hideViewByClient
}) => {
    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState({});
    const [addNote] = useAddNoteMutation();
    const [saveNote] = useEditNoteMutation();

    useEffect(() => {
        setNoteToEdit({ ...note });
    }, [note]);

    const onChange = (field, value) => {
        setNoteToEdit({ ...noteToEdit, [field]: value });
    }

    const onSave = async () => {
        setSaving(true);
        let res;
        noteToEdit.showInReport = !noteToEdit.viewByClient ? false : noteToEdit.showInReport;
        if (noteToEdit.id) {
            res = await saveNote(noteToEdit);
        } else {
            res = await addNote(noteToEdit);
        }
        if (res.error) {
            NotificationProvider.error('Failed to save the note');
        } else {
            NotificationProvider.success('Successfully save the note');
            onClose();
        }
        setSaving(false)
    }
    const noteContent = <NoteContent
        content={noteToEdit.content}
        viewByClient={noteToEdit?.viewByClient}
        onChange={onChange}
        showInReport={noteToEdit?.showInReport}
        entityType={noteToEdit.entityType}
        hideViewByClient={hideViewByClient}
    />

    const buttons = <><Button onClick={() => onClose()} >
        Cancel
    </Button>
        <Button disabled={saving} onClick={onSave} variant="contained" color='primary'>
            {saving ? 'Saving...' : 'Save'}
        </Button></>

    return (
        <> {withDialog ?
            <Dialog open={true} onClose={onClose}>
                <DialogTitle>
                    {note?.id ? 'Edit Note' : 'Add Note'}
                    {note?.id && <EntityAuditTooltip entity={note} />}
                </DialogTitle>
                <DialogContent>
                    {noteContent}
                </DialogContent>
                <DialogActions>
                    {buttons}
                </DialogActions>
            </Dialog>
            : <Grid container direction='column'>
                <Grid item>
                    {noteContent}
                </Grid>
                <Grid container style={{ justifyContent: 'end' }}>
                    {buttons}
                </Grid>
            </Grid>
        } </>
    );
}
export default NoteEditor;

export const NoteContent = ({
    viewByClient,
    content,
    onChange,
    hideViewByClient,
    showInReport = null,
    entityType = null,
    style
}) => {
    return (
        <>
            <RichTextEditor onChange={(val) => onChange('content', val)} content={content} style={style} />
            <Authorize profile={permissionProfiles.NOTES.VIEW_BY_CLIENT}>
                {!hideViewByClient && <div>
                    <FormControlLabel
                        control={<Checkbox checked={viewByClient}
                            onChange={(e, value) => onChange('viewByClient', value)} />}
                        label="Note can be viewed by client" />
                </div>}
            </Authorize>
            {!hideViewByClient && <div>
                {(viewByClient === true && entityType == NoteEntityTypes.VEHICLE || entityType == NoteEntityTypes.ACTIVITY) && (
                    <FormControlLabel
                        control={<Checkbox checked={showInReport}
                            onChange={(e, value) => onChange('showInReport', value)} />}
                        label="Include Note in Export" />
                )}
            </div>}
        </>
    )
}