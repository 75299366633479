import { useGetIssueTrackerCategoriesQuery, useGetStatusIssueTrackerQuery } from "features/IssuesTracker/issuesTrackerSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { arrayToObject } from "utils/object-util";
import EmptyPlaceholder from "../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import { TasksPanelStyles } from "../../TasksPanelStyles";
import LotTaskItem from "./LotTaskItem";

export const LotTaskList = ({
    tasks,
    placeholder,
    onOpenTask
}) => {
    const classes = TasksPanelStyles();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: statuses, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: categories, error: categoriesError, isFetching: isLoadingCategories } = useGetIssueTrackerCategoriesQuery();

    const lotsLookup = useMemo(() => arrayToObject(lots), [lots]);
    const clientsLookup = useMemo(() => arrayToObject(clients), [clients]);
    const statusLookup = useMemo(() => arrayToObject(statuses), [statuses]);
    const categoryLookup = useMemo(() => arrayToObject(categories), [categories]);

    const Row = ({ index, style }) => (
        <div style={style}>
            <LotTaskItem
                onOpenTask={onOpenTask}
                task={tasks?.[index]}
                key={index}
                lotsLookup={lotsLookup}
                clientsLookup={clientsLookup}
                statusLookup={statusLookup}
                categoryLookup={categoryLookup}
            />
        </div>
    );

    return (
        <>
            {!!tasks?.length ? <div style={{ flex: 1 }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            className="List"
                            height={height}
                            itemCount={tasks?.length}
                            itemSize={300}
                            width={width}
                        >
                            {Row}
                        </List>
                    )}
                </AutoSizer>
            </div> :
                placeholder ? placeholder : <EmptyPlaceholder text="There are no pending tasks" />}
        </>

    )
}

