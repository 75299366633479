// export const vehicleFuelTypes = [
//     { id: 1, name: "CNG" },
//     { id: 2, name: "Diesel" },
//     { id: 3, name: "Electric" },
//     { id: 4, name: "Gasoline" }
// ];

export const moveTypes = [
    { id: 1, name: "Network Transfer" },
    { id: 2, name: "Amazon Transfer" },
    { id: 3, name: "Direct to AFP" },
    { id: 4, name: "Expedited" }
];

export const moveTypesById = {
    NETWORK_TRANSFER: 1,
    AMAZON_TRANSFER: 2,
    DIRECT_TO_AFP: 3,
    EXPEDITED: 4,
};

export const moveTypeMessages = {
    [moveTypesById.NETWORK_TRANSFER]: {
        pickup: () => 'Based on the selected move type, the pickup date must be 72 hours after the request date',
        delivery: (days) => `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${days} days after the pickup and within three week of the pickup date`
    },
    [moveTypesById.AMAZON_TRANSFER]: {
        pickup: () => 'Based on the selected move type, the pickup date must be at least 72 hours after the request date',
        delivery: (days) => `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${days} days after the pickup`
    },
    [moveTypesById.DIRECT_TO_AFP]: {
        pickup: (days) => `Based on the selected move type and the distance to the delivery location, the pickup is ${days} days before the delivery`,
        delivery: (days) => `Based on the selected move type and the distance to the delivery location, the pickup is ${days} days before the delivery`
    },
    [moveTypesById.EXPEDITED]: {
        pickup: () => 'Based on the selected move type, there are no restrictions on the pickup date',
        delivery: () => 'Based on the selected move type, there are no restrictions on the delivery date'
    },
};

export const calcTypes = [
    { id: 1, name: "No scheduling logic" },
    { id: 2, name: "Calculate latest pickup from selected latest delivery" },
    { id: 3, name: "Calculate delivery from selected pickup" },
    { id: 4, name: "Calculate and enforce minimum transit time, but don't select pickup or delivery" }
];

export const calcTypesById = {
    NO_LOGIC: 1,
    PICKUP_FROM_DELIVERY: 2,
    DELIVERY_FROM_PICKUP: 3,
    MIN_TRANSIT_TIME: 4,
};

const getDaysText = (days) => days > 1 ? `${days} days` : `${days} day`

export const calcTypeMessages = {
    [calcTypesById.NO_LOGIC]: {
        pickup: (minPickup) => minPickup > 0 ? `Based on the selected move type, the pickup date must be at least ${getDaysText(minPickup)} after the request date` : 'There are no restrictions on the pickup date',
        delivery: (maxDelivery) => maxDelivery > 0 ? `Based on the selected move type, the delivery date must be within ${getDaysText(maxDelivery)} of the pickup date` : 'There are no restrictions on the delivery date',
    },
    [calcTypesById.PICKUP_FROM_DELIVERY]: {
        pickup: (days) => `Based on the selected move type and the distance to the delivery location, the pickup is ${getDaysText(days)} before the delivery`,
        delivery: (days, maxDelivery) => {
            let message = '';
            if (days > 0) {
                message = `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${getDaysText(days)} after the request date`
                if (maxDelivery > 0)
                    message = message + ` and within ${getDaysText(maxDelivery)} of the pickup date`
            }
            else message = 'There are no restrictions on the delivery date'
            return message;
        }
    },
    [calcTypesById.DELIVERY_FROM_PICKUP]: {
        pickup: (days) => days > 0 ? `Based on the selected move type, the pickup date must be at least ${getDaysText(days)} after the request date` : 'There are no restrictions on the pickup date',
        delivery: (days) => `Based on the selected move type, the delivery date is ${getDaysText(days)} after the pickup date`
    },
    [calcTypesById.MIN_TRANSIT_TIME]: {
        pickup: (minPickup) => minPickup > 0 ? `Based on the selected move type, the pickup date must be at least ${getDaysText(minPickup)} after the request date` : 'There are no restrictions on the pickup date',
        delivery: (transit, maxDelivery) => {
            let message = `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${getDaysText(transit)} after the pickup `;
            if (maxDelivery > 0)
                message = message + ` and within ${getDaysText(maxDelivery)} of the pickup date`
            return message
        }
    },
};

export const loadStatuses = {
    PENDING_ASSIGNMENT: 1,
    PENDING_ACCEPTANCE: 2,
    ACCEPTED: 3,
    TRANSIT: 4,
    DELIVERED: 5,
    COMPLETE: 6,
    CANCELLED: 7,
    CREATED: 8,
    AMERIT_CONFIRMED: 9,
    EXCEPTION: 10,
    CANCELLED_PARQ: 11,
    PENDING_CLIENT_APPROVAL: 12,
    CREATED_BY_CLIENT: 13
};

export const loadExportColumnIds = {
    GROUP_NAME: 1,
    LOAD_ID: 2,
    CARRIER: 3,
    ASSET_ID:4,
    VRID: 5,
    VIN: 6,
    MAKE: 7,
    MODEL: 8,
    YEAR: 9,
    VEHICLE_TYPE: 10,
    FUEL_TYPE: 11,
    BUSINESS_UNIT: 12,
    REQUEST_DATE: 13,
    MOVE_TYPE: 14,
    MILES: 15,
    TRAILER_TYPE: 16,
    LATEST_STATUS: 17,
    ALL_STATUSES: 18,
    PICKUP_NODE_NAME: 19,
    PICKUP_DOMICILE_SITE: 20,
    PICKUP_ADDRESS: 21,
    PICKUP_CITY: 22,
    PICKUP_STATE: 23,
    PICKUP_ZIP: 24,
    EARLIEST_PICKUP_DATE: 25,
    ESTIMATED_PICKUP_DATE: 26,
    ACTUAL_PICKUP_DATE: 27,
    DELIVERY_NODE_NAME: 28,
    DELIVERY_DOMICILE_SITE: 29,
    DELIVERY_ADDRESS: 30,
    DELIVERY_CITY: 31,
    DELIVERY_STATE: 32,
    DELIVERY_ZIP: 33,
    LATEST_DELIVERY_DATE: 34,
    ESTIMATED_DELIVERY_DATE: 35,
    ACTUAL_DELIVERY_DATE: 36,
    NEEDED_BY_WEEK: 37,
    HANDOFF_WEEK: 38,
    COMMENTS: 39,
    READINESS: 40,
    LATEST_WASH: 41,
    LATEST_INSPECTION: 42,
    USED_GPS: 43,
}; 

export const loadExportColumns = {
    [loadExportColumnIds.GROUP_NAME]: {name: 'Group Name', key: '1', selectedByDefault: true},
    [loadExportColumnIds.LOAD_ID]: {name: 'Load ID', key: '2', selectedByDefault: true},
    [loadExportColumnIds.CARRIER]: {name: 'Carrier', key: '3', selectedByDefault: true},
    [loadExportColumnIds.ASSET_ID]: { name: 'Asset ID', key: '4', selectedByDefault: true },
    [loadExportColumnIds.VRID]: { name: 'VRID', key: '5'},
    [loadExportColumnIds.VIN]: {name: 'VIN', key: '6', selectedByDefault: true},
    [loadExportColumnIds.MAKE]: {name: 'Make', key: '7'},
    [loadExportColumnIds.MODEL]: {name: 'Model', key: '8'},
    [loadExportColumnIds.YEAR]: {name: 'Year', key: '9'},
    [loadExportColumnIds.VEHICLE_TYPE]: {name: 'Vehicle Type', key: '10', selectedByDefault: true},
    [loadExportColumnIds.FUEL_TYPE]: {name: 'Fuel Type', key: '11'},
    [loadExportColumnIds.BUSINESS_UNIT]: {name: 'Business Unit', key: '12'},
    [loadExportColumnIds.REQUEST_DATE]: {name: 'Request Date', key: '13', selectedByDefault: true},
    [loadExportColumnIds.MOVE_TYPE]: {name: 'Move Type', key: '14', selectedByDefault: true},
    [loadExportColumnIds.MILES]: {name: 'Miles', key: '15'},
    [loadExportColumnIds.TRAILER_TYPE]: {name: 'Trailer Type', key: '16'},
    [loadExportColumnIds.LATEST_STATUS]: {name: 'Latest Status', key: '17', selectedByDefault: true},
    [loadExportColumnIds.ALL_STATUSES]: { name: 'All Statuses', key: '18' },
    [loadExportColumnIds.PICKUP_NODE_NAME]: {name: 'Pickup Node Name', key: '19'},
    [loadExportColumnIds.PICKUP_DOMICILE_SITE]: {name: 'Pickup Domicile Site', key: '20'},
    [loadExportColumnIds.PICKUP_ADDRESS]: {name: 'Pickup Address', key: '21', selectedByDefault: true},
    [loadExportColumnIds.PICKUP_CITY]: {name: 'Pickup City', key: '22'},
    [loadExportColumnIds.PICKUP_STATE]: {name: 'Pickup State', key: '23'},
    [loadExportColumnIds.PICKUP_ZIP]: {name: 'Pickup Zip', key: '24'},
    [loadExportColumnIds.EARLIEST_PICKUP_DATE]: {name: 'Earliest Pickup Date', key: '25'},
    [loadExportColumnIds.ESTIMATED_PICKUP_DATE]: {name: 'Estimated Pickup Date', key: '26', selectedByDefault: true},
    [loadExportColumnIds.ACTUAL_PICKUP_DATE]: {name: 'Actual Pickup Date', key: '27'},
    [loadExportColumnIds.DELIVERY_NODE_NAME]: {name: 'Delivery Node Name', key: '28'},
    [loadExportColumnIds.DELIVERY_DOMICILE_SITE]: {name: 'Delivery Domicile Site', key: '29'},
    [loadExportColumnIds.DELIVERY_ADDRESS]: {name: 'Delivery Address', key: '30', selectedByDefault: true},
    [loadExportColumnIds.DELIVERY_CITY]: {name: 'Delivery City', key: '31'},
    [loadExportColumnIds.DELIVERY_STATE]: {name: 'Delivery State', key: '32'},
    [loadExportColumnIds.DELIVERY_ZIP]: {name: 'Delivery Zip', key: '33'},
    [loadExportColumnIds.LATEST_DELIVERY_DATE]: {name: 'Latest Delivery Date', key: '34'},
    [loadExportColumnIds.ESTIMATED_DELIVERY_DATE]: {name: 'Estimated Delivery Date', key: '35', selectedByDefault: true},
    [loadExportColumnIds.ACTUAL_DELIVERY_DATE]: {name: 'Actual Delivery Date', key: '36'},
    [loadExportColumnIds.NEEDED_BY_WEEK]: {name: 'Needed By Week', key: '37'},
    [loadExportColumnIds.HANDOFF_WEEK]: {name: 'Handoff Week', key: '38'},    
    [loadExportColumnIds.COMMENTS]: {name: 'Comments', key: '39'},
    [loadExportColumnIds.READINESS]: {name: 'Readiness', key: '40'},
    [loadExportColumnIds.LATEST_WASH]: {name: 'Latest Wash', key: '41', selectedByDefault: true},
    [loadExportColumnIds.LATEST_INSPECTION]: {name: 'Latest Inspection', key: '42', selectedByDefault: true},
    [loadExportColumnIds.USED_GPS]: {name: 'Used GPS', key: '43'},
};

export const groupLegTypes = {
    TRANSPORT: 1,
    ACTIVITY: 2
};

export const trailerTypes = [
    { id: "OPEN", name: "Towed / Hauled" },
    { id: "ENCLOSED", name: "Enclosed Trailer" },
    { id: "TO_BE_DRIVEN", name: "To be driven" } 
];