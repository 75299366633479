import { Grid } from '@material-ui/core';
import { CardsStyle } from './CardsStyle';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetTransportersQuery } from 'features/transporter/transporterSlice';
import SvgIconQRCode from 'icons/IconQRCode';
import ListComponent from './Lists/ListComponent';
import { IconLot } from 'icons';
import PocInfo from './POC/PocInfo';
import EditSites from './Lists/EditListsComponents/EditSites';
import SiteListItem from './Lists/ListItems/SiteListItem';

const TransporterAccessCard = ({ access }) => {

    const classes = CardsStyle()
    let { data: transporters, error: transportersError, isLoading } = useGetTransportersQuery();

    const transportersLookup = transporters?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    access = {
        ...access,
        transporterName: transportersLookup?.[access?.transporterId]?.name,
        usdot: transportersLookup?.[access?.transporterId]?.usdot,
    }

    return (
        <>
            <div className={classes.loadBox}>
                <LoadingSpinner loading={isLoading} />
                <Grid container direction='row' >
                    <Grid item container direction='row' className={classes.cardItemIcon}>
                        <div item className={classes.title}><SvgIconQRCode /></div>
                    </Grid>
                    <Grid item container direction='column' className={classes.cardItem} >
                        <Grid item className={classes.data}>
                            <label className={classes.title}>Transporter: </label>
                            <label className={classes.data}>{access?.transporterName}</label>
                        </Grid>
                        <Grid item className={classes.data}>
                            <label className={classes.title}>USDOT: </label>
                            <label className={classes.data}>{access?.usdot}</label>
                        </Grid>
                    </Grid>

                    <PocInfo access={access} />

                </Grid>

                <Grid container direction='row' spacing={2} className={classes.lists}>
                    <Grid item>
                        <ListComponent
                            access={access}
                            list={access?.lots}
                            listTitle='Sites'
                            listItem={(lot) => <SiteListItem access={access} lot={lot}/>}
                            addComponent={(access, open, onClose) => <EditSites access={access} open={open} onClose={onClose} />}
                        />
                    </Grid>
                </Grid>
            </div >
        </>
    );
}

export default TransporterAccessCard



