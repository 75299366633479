import * as React from "react";

function SvgIconWarning(props) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 9.94792C8.49548 9.94792 8.48763 9.94626 8.47902 9.93764C8.47041 9.92903 8.46875 9.92119 8.46875 9.91667V6.375C8.46875 6.37048 8.47041 6.36263 8.47902 6.35402C8.48763 6.34541 8.49548 6.34375 8.5 6.34375C8.50452 6.34375 8.51237 6.34541 8.52098 6.35402C8.52959 6.36263 8.53125 6.37048 8.53125 6.375V9.91667C8.53125 9.92119 8.52959 9.92903 8.52098 9.93764C8.51237 9.94626 8.50452 9.94792 8.5 9.94792Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M8.44965 12.2438L8.42249 12.2302L8.39142 12.2199L8.3624 12.1966L8.34349 12.1815C8.3089 12.1367 8.2915 12.0833 8.2915 12.0416C8.2915 12 8.3089 11.9466 8.34349 11.9018L8.39142 11.8634L8.42249 11.8531L8.45078 11.8389L8.45558 11.8381L8.4706 11.8347C8.47899 11.8327 8.48906 11.8315 8.49895 11.8315C8.50746 11.8315 8.51235 11.8324 8.51315 11.8326C8.51329 11.8326 8.5133 11.8326 8.51318 11.8326L8.53818 11.8388L8.5528 11.8409L8.57718 11.8531L8.60825 11.8634L8.65618 11.9018C8.69077 11.9466 8.70817 12 8.70817 12.0416C8.70817 12.0833 8.69077 12.1367 8.65618 12.1815L8.63728 12.1966L8.60825 12.2199L8.57718 12.2302L8.55244 12.2426L8.55222 12.2426C8.53482 12.2455 8.52101 12.2476 8.50981 12.2488C8.50498 12.2494 8.50168 12.2497 8.49976 12.2498C8.49749 12.2497 8.49333 12.2493 8.48688 12.2486C8.4764 12.2474 8.46486 12.2459 8.44965 12.2438Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M6.17443 3.52243L6.1745 3.52229C6.83077 2.34101 7.6687 1.80322 8.49992 1.80322C9.33114 1.80322 10.1691 2.341 10.8253 3.52226C10.8253 3.52227 10.8253 3.52228 10.8253 3.52229L15.1178 11.2502L15.1178 11.2502C15.7352 12.3615 15.7567 13.3258 15.3375 14.0387C14.9164 14.7411 14.0575 15.1966 12.7924 15.1966H4.20742C2.9426 15.1966 2.0844 14.7413 1.6631 14.033C1.24916 13.3253 1.26556 12.3528 1.88192 11.2433C1.88195 11.2432 1.88198 11.2432 1.882 11.2431L6.17443 3.52243ZM6.22937 3.55174L6.22807 3.55408L1.94318 11.281C1.94309 11.2812 1.943 11.2814 1.94291 11.2815C1.42013 12.2197 1.2616 13.2153 1.72079 14.0067C2.18402 14.805 3.14467 15.1411 4.2145 15.1411H12.7995C13.8729 15.1411 14.8212 14.8032 15.2909 14.0106C15.7626 13.2146 15.5909 12.2218 15.0717 11.2826L15.071 11.2814L10.7714 3.55346L10.7705 3.55174C10.2209 2.57298 9.43745 1.86572 8.49992 1.86572C7.56239 1.86572 6.77899 2.57298 6.22937 3.55174Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default SvgIconWarning;
