import { IssueTrackerBarCounterStyle } from "./style";

const IssueTrackerBarCounter = ({ days }) => {
    const classes = IssueTrackerBarCounterStyle();

    const getColorClass = (index) => {
        const lightBlueThreshold = 3 * (index + 1);
        const darkBlueThreshold = 15 + 3 * (index + 1);

        if (days >= darkBlueThreshold) {
            return classes.darkBlue;
        } else if (days >= lightBlueThreshold) {
            return classes.lightBlue;
        } else {
            return '';
        }
    };


    const squares = Array.from({ length: 5 }, (v, i) => (
        <div
            key={i}
            className={`${classes.square} ${getColorClass(i)}`}
        />
    ));

    return (
        <>
            <div className={classes.barContainer}>{squares}</div>
        </>
    );
};

export default IssueTrackerBarCounter;