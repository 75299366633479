import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';

const apiPrefix = 'activityTypeInventory';
export const activityTypeInventoryApi = baseApi.injectEndpoints({
    reducerPath: 'activityTypeInventoryApi',
    tagTypes: [apiTags.ACTIVITY_TYPE_INVENTORY],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllInventoryByWorkOrder: builder.query({
            query: (workOrderId) => `${apiPrefix}/getByWorkOrder/${workOrderId}`,
            providesTags: [apiTags.ACTIVITY_TYPE_STEP],
        }),
        updateActivityTypeInventory: builder.mutation({
            query: inventories => ({
                url: `${apiPrefix}/saveInventoryUsedByActivity`,
                method: "POST",
                body: inventories
            }),
            invalidatesTags: [apiTags.ACTIVITY_TYPE_INVENTORY, apiTags.INVENTORY]
        }),
    }),
});

export const {
    useGetAllInventoryByWorkOrderQuery, 
    useUpdateActivityTypeInventoryMutation
} = activityTypeInventoryApi;