import React, { useEffect } from "react";
import { DateRange as ReactDateRange } from "react-date-range";
import { dateRangeStyle } from './DateRangeStyle';
import { TextField, FormControl, Button } from '@material-ui/core'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { DefinedRange } from 'react-date-range';
import moment from "moment";
import { PredefinedRanges } from "./DefaultRanges";


export default function DateRange({
    title,
    identifier,
    onSelect,
    filterButton = false,
    dateValue = null,
    showPredefinedRanges = false,
    selectedRange,
    futureOnly,
    required,
    minDate,
    maxDate
}) {

    const classes = dateRangeStyle();

    const [state, setState] = React.useState(
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    );

    const [value, setValue] = React.useState(dateValue)
    const [selectedPredefinedRange, setSelectedPredefinedRange] = React.useState(selectedRange)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (selectedRange) {
            const selection = { selection: PredefinedRanges?.find(r => r.key == selectedRange)?.range() };
            setSelectedPredefinedRange(selectedRange);
            handleChange(selection);
        }
    }, [selectedRange]);

    const getDateValue = (selected) => {
        return `${selected?.startDate?.toLocaleDateString()} - ${selected?.endDate?.toLocaleDateString()}`;
    }

    useEffect(() => {
        if (dateValue?.startDate && dateValue.endDate) {
            setValue(getDateValue({ startDate: dateValue.startDate, endDate: dateValue.endDate }))
        }
        if (!dateValue && value) {
            clear();
        }
    }, [dateValue]);


    const handleChange = (event) => {
        setState(event.selection)
        if (!filterButton) {
            onSelect({ ...event.selection }, identifier);
        }
        if (event.selection?.startDate && event.selection?.endDate) {
            setValue(getDateValue(event.selection));
        } else {
            clear();
        }
    };

    const clear = () => {
        setState({
            startDate: null,
            endDate: null,
            key: 'selection'
        })
        onSelect(null, identifier);
        setValue("")
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterDates = () => {
        onSelect(state, identifier);
        handleClose();
    }



    const renderStaticRangeLabel = (props) => (
        <Button className={`${classes.rangeOpt} `}>{props.label}</Button>
        //${props.isSelected() ? classes.rangeSelected : ''}
    );

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <TextField
                className={classes.dateRange}
                variant='outlined'
                onClick={handleClick}
                label={title}
                value={value}
                InputLabelProps={{
                    shrink: true,
                    className: classes.label
                }}
                readOnly
                inputProps={{
                    className: classes.input
                }}
                onChange={() => { }}
                aria-describedby={id}
                placeholder={'Start - End'}
                required={required}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }} >
                    <div >
                        <div className={classes.dateRangePicker}>
                            {showPredefinedRanges && <div>
                                <DefinedRange
                                    onChange={(event) => {
                                        handleChange({ selection: Object.values(event)?.[0] });
                                    }}
                                    ranges={[state]}
                                    staticRanges={PredefinedRanges.filter(f => !futureOnly || !f.past)}
                                    renderStaticRangeLabel={renderStaticRangeLabel}
                                />
                            </div>}
                            <ReactDateRange
                                editableDateInputs={true}
                                onChange={handleChange}
                                moveRangeOnFirstSelection={false}
                                ranges={[state]}
                                dateDisplayFormat="d/M/yyyy"
                                startDatePlaceholder="Start Date"
                                endDatePlaceholder="End Date"
                                inputRanges={[]}
                                minDate={minDate ?? futureOnly ? today : undefined}
                                maxDate={maxDate}
                            />

                        </div>
                        <div className={classes.closeAndClear}>
                            <Button className={classes.close} onClick={clear}>
                                Clear
                            </Button>
                            {filterButton &&
                                <Button className={classes.close} onClick={filterDates}>
                                    Apply
                                </Button>}
                        </div>
                    </div>
                </Typography>
            </Popover>
        </FormControl>
    );
}
