import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mobileBreakpoint from "../utils/mobileBreakpoint";

const useStyles = makeStyles(theme => ({
    linkContainer: {
        padding: '0',
        //maxWidth: "110px",
        borderRadius: "5px",
        //height: "40px"
        //textAlign: 'center',
        margin: '1px'
    },
    link: {
        textAlign: 'center',
        textDecoration: "none",
        color: theme.palette.common.darkText,
        alignItems: "center",
        width: "100%",
        padding: "11px 16px",
        borderRadius: "5px",
    },
    selected: {
        background: theme.palette.neutral.light,
    },
    icon: {
        minWidth: "10px",
    },
    text: {
        fontSize: "12px",
    },
    linkContainerMobile: {
        marginLeft: "-5px",
        maxWidth: "40px"
    },
    linkMobile: {
        paddingLeft: "9px"
    },
    subItem: {
        fontSize: '1em',
        whiteSpace: 'nowrap',
        textAlign: 'left'
        //marginLeft: '1em',
    }
}));

export default function SideNavLink({ text, icon, link, isSubItem, onMenuItemClick, style }) {
    const classes = useStyles();
    const isMobile = false;//useMediaQuery(mobileBreakpoint);

    return (
        <ListItem onClick={onMenuItemClick} button style={style} className={`${classes.linkContainer} ${isMobile ? classes.linkContainerMobile : ""} ${isSubItem ? classes.subItem : ''}`} key={text}>
            <NavLink
                className={isActive =>
                    classes.link + (isActive && !isSubItem ? ` ${classes.selected}` : "") + (isMobile ? ` ${classes.linkMobile}` : "")
                } to={`/${link ?? text.toLowerCase()}`}>

                {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
                {
                    !isMobile ?
                        <div
                            className={`${classes.text} ${isSubItem ? classes.subItem : ''}`}
                        >{text}</div>
                        : null
                }
            </NavLink>
        </ListItem >
    );
}
