export const lotPermissions = {
    SIZING: {
        EDIT: {
            key: 'SiteSizing.Edit',
            name: 'Site sizing configuration',
            description: 'Add, edit and delete site sizing configuration'
        }
    },
    ACCESS: {
        OPEN_GATE: {
            key: 'Lot.Access.OpenGate',
            name: 'Open lot gate',
            description: 'Unlock a lot gate from within Parq'
        }
    },
    DELETE: {
        key: 'Lot.Delete',
        name: 'Delete lot',
    },
    IMPORT:{
        key: 'Lot.Import',
        name: 'Allow importing lot',
    }
}