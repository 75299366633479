import { groupBy, orderBy } from "lodash";
import moment from "moment";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { theme } from "styles/theme";
import GraphHeader from "../GraphHeader";
import { useSelector } from "react-redux";
import { billingDashboardStyle } from "../style";


const BillingPerLotGraph = ({ data }) => {
    const classes = billingDashboardStyle();

    const { showCost, showCharge } = useSelector(state => state.billingDashboardState);
    const dataGrouped = {};
    data?.forEach(req => {

        if (!dataGrouped[req.lotId]) {
            dataGrouped[req.lotId] = { name: req.lotName ?? 'Unspecified lot', cost: 0, charge: 0, revenue: 0, value: 0 };
        }
        dataGrouped[req.lotId].cost += req.totalCost;
        dataGrouped[req.lotId].charge += req.totalChargeToClient;
        dataGrouped[req.lotId].revenue += req.totalRevenue;
        dataGrouped[req.lotId].value++;
    });

    const values = orderBy(Object.values(dataGrouped), ['revenue'], ['desc']).map(val => ({
        ...val,
        cost: +(val.cost.toFixed(2)),
        charge: +(val.charge.toFixed(2)),
        revenue: +(val.revenue.toFixed(2))
    }));

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.graphTootltip}>
                <p>
                    <div><b>{payload?.[0]?.payload?.name} </b></div>
                    {showCost && <div><b>Cost: </b>${payload?.[0]?.payload?.cost}</div>}
                    {showCharge && <div><b>Charge: </b>${payload?.[0]?.payload?.charge}</div>}
                    {<div><b>Revenue: </b>${payload?.[0]?.payload?.revenue}</div>}
                </p>
            </div>
        );
    };

    return (
        <div style={{ height: 500, overflowY: 'auto' }}>
            <GraphHeader title={'Revenue Per Lot'} />
            <div style={{ height: values?.length * 40 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        layout="vertical"
                        data={values}
                        margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis
                            type="number"
                            hide
                            axisLine={false}
                            tickLine={false}
                        />
                        <YAxis
                            type="category"
                            axisLine={false}
                            dataKey="name"
                            tickLine={false}
                            tick={{ color: theme.palette.primary.main, fontSize: '1em', fontWeight: 'bold' }}
                        />
                        <Tooltip
                            content={<CustomTooltip />}
                        />
                        {showCost && <Bar
                            dataKey="cost"
                            fill={'#06aed5'}
                        >
                            <LabelList dataKey="cost" position="outside" style={{ fill: theme.palette.primary.contrastText }} />
                        </Bar>}
                        {showCharge && <Bar
                            dataKey="charge"
                            fill={'#086788'}
                        >
                            <LabelList dataKey="charge" position="outside" style={{ fill: theme.palette.primary.contrastText }} />
                        </Bar>}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default BillingPerLotGraph;
