import {baseQuery} from "../../app/baseQuery";
import { apiTags } from 'app/store/apiTags';
import { baseApi } from 'app/baseApi';

export const configurationApi = baseApi.injectEndpoints({
    reducerPath: 'configurationApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getConfigurationByVehicle: builder.query({
            query: (groupType) => `configuration/${groupType}`,
            providesTags:[apiTags.CONFIGURATION]
        }),
    }),
});

export const {
    useGetConfigurationByVehicleQuery,
} = configurationApi;
