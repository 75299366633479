import { useEffect, useState } from "react";
import { userPreferencesTypes } from "./userPreferencesConsts";
import { useGetUserPreferencesQuery, useUpdateUserPreferenceMutation } from "./userPreferencesSlice";
import { debounce } from "lodash";

export const withUserPreferences = (BaseComponent, componentId, type) => {

    return function UserPreferencesWrapper(props) {

        const [preference, setPreference] = useState();

        let { data, error, isLoading } = useGetUserPreferencesQuery({ componentId, type, viewId: 0 }, { skip: !componentId || !type || preference });
        const [updatePref, { isLoading: isSaving }] = useUpdateUserPreferenceMutation();

        useEffect(() => {
            if (data && !isLoading && !isSaving && !preference) {
                setPreference(data);
            }
        }, [data, isLoading]);

        const savePref = debounce((value) => {
            if (componentId && type) {
                const prefObj = {};
                switch (type) {
                    case userPreferencesTypes.FILTERS:
                        prefObj.filters = Object.entries(value).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }));
                        break;
                    case userPreferencesTypes.COLUMNS:
                        prefObj.columns = value.map(col => ({ key: col.key }));
                        break;
                    case userPreferencesTypes.CUSTOM_VALUE:
                        prefObj.customValue = value;
                        break;
                }
                //Update locally
                setPreference({ ...prefObj });
                //Update API
                updatePref({ componentId, type, ...prefObj })
            }
        }, 500);

        const convertFilters = () => {
            return convertFiltersFunc(preference)
        }

        return (
            <BaseComponent
                {...props}
                saveFilters={savePref}
                saveColumns={savePref}
                saveCustomValue={savePref}
                savedFilters={convertFilters()}
                savedCustomValue={preference?.customValue}
                savedColumns={preference?.columns}
            />
        );
    };

};

export const convertFiltersFunc = (preference) => {
    if (!preference?.filters) return undefined;
    const filters = {};
    preference?.filters?.map(filter => {
        let filterValue = JSON.parse(filter.value);
        //TODO date range needs special handling
        if (filterValue.value?.startDate) {
            filterValue.value.startDate = new Date(filterValue.value?.startDate);
        }
        if (filterValue.value?.endDate) {
            filterValue.value.endDate = new Date(filterValue.value?.endDate);
        }
        filters[filter.key] = filterValue;
    })
    return filters;
}