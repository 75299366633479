import { List, ListItem, ListItemText } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import AssetReadinessStatuses from '../../../vehicles/components/AssetReadinessStatuses/AssetReadinessStatuses';
import { loadAssetsStyle } from './LoadAssetsStyle';

function LoadAssets({ assets, readiness }) {
    const classes = loadAssetsStyle()

    return (
        <>
            {!(assets?.length) ?
                <EmptyPlaceholder text='no assets' className={classes.emptyPlaceHolder} /> :

                <div className={classes.listWrapper}>
                    <List>
                        {assets?.map(asset =>
                            <ListItem > 
                                <NavLink exact
                                    className={classes.link}
                                    to={`/assets/detail/${asset.id}`}>
                                    <ListItemText
                                        primary={`${[asset?.vin, asset?.assetId].join(', ')}`}
                                        secondary={`${asset?.year} ${asset?.make} ${asset?.model}`}
                                        className={classes.listWrapper} />
                                </NavLink>
                                {readiness &&
                                    <div style={{ marginLeft: 'auto' }}><AssetReadinessStatuses readinessStatus={readiness} /></div>
                                }
                            </ListItem>
                        )}
                    </List>
                </div>
            }
        </>
    );
}

export default LoadAssets;