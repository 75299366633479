import MapIcon from '@material-ui/icons/Map';
import ViewListIcon from '@material-ui/icons/ViewList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { lotsPageStyle } from "./lotsPageStyle";

const ViewToggle = ({ screenView, handleViewChange }) => {
    const classes = lotsPageStyle();

    return (
        <ToggleButtonGroup
            value={screenView}
            exclusive
            onChange={handleViewChange}
            aria-label="screen view"
            className={classes.viewToggle}
        >
            <ToggleButton value="map" aria-label="left aligned">
                <MapIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="right aligned">
                <ViewListIcon />
            </ToggleButton>
        </ToggleButtonGroup>

    )
}

export default ViewToggle;
