import { Button } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useState } from "react";
import { ReservationView } from "../ReservationView/ReservationView";

export const ReservationOverviewAction = ({ reservation, buttonVariant }) => {
    const [open, setOpen] = useState();

    return (
        <>
            <Authorize profile={permissionProfiles.RESERVATIONS.VIEW}>
                <Button
                    color='primary'
                    variant={buttonVariant}
                    onClick={() => setOpen(true)}
                    style={{ width: '100%', justifyContent: 'start' }}
                >
                    Overview
                </Button>
            </Authorize>
            {open && <ReservationView reservation={reservation} onClose={() => setOpen(false)} />}
        </>)
}