import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import LoadingSpinner from 'components/LoadingSpinner';
import MapWrapper from 'components/MapWrapper/MapWrapper';
import { googleMapService } from "components/MapWrapper/googleMapService";
import moment from "moment";
import { useEffect, useState } from 'react';
import LotsMapMarker from '../../../maps/markers/LotsMapMarker/LotsMapMarker';
import { useGetLoadGroupLocationsQuery, useGetLoadLocationsQuery } from '../../loadsSlice';
import { loadMapStyle } from './LoadMapStyle';

function LoadMap({ groupId, loads, showLocations }) {
    const classes = loadMapStyle()

    let { data: singleLoadLocations, error: loadLocationsError, isLoading: isLoadingLoadLocations } = useGetLoadLocationsQuery(loads?.[0]?.id, { skip: !!groupId });
    let { data: loadGroupLocations, error: loadGroupLocationsError, isLoading: isLoadingLoadGroupLocations } = useGetLoadGroupLocationsQuery(groupId, { skip: !groupId });

    let loadLocations = (groupId ? loadGroupLocations : singleLoadLocations) ?? [];
    const [markers, setMarkers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDate = (date, readable = false) => {
        return readable ? moment(date).format('YYYY-MM-DD, hh:mm a') : moment(date).format('YYYY-MM-DD hh:mm a');
    };

    const getInfoWindowContent = (location, startDate, endDate) => {
        return `<b>${location.city ? `${location.city},` : ''}
            ${location.state}
            ${location.street ? `${location.street},` : ''}
            ${location.zipCode}<br></b>
            ${getDate(startDate)} - ${getDate(endDate)}`;
    };

    const getHistoryInfoWindowContent = (date) => {
        return `<b>${getDate(date, true)}</b>`;
    };

    const setAllMarkers = () => {
        // TODO Add asset marker
        let markersList = [];
        const historyMarkers = showLocations ? loadLocations?.map(h => ({
            latitude: h.location?.latitude,
            longitude: h.location?.longitude,
            content: getHistoryInfoWindowContent(h.timestamp),
            icon: <MyLocationRoundedIcon className={classes.historyIcon} />,
        })) : [];

        loads?.map(load => {
            markersList.push({
                address: googleMapService.convertAddressPartsToAddressString(load?.pickupLocation ?? {}),
                content: getInfoWindowContent(load?.pickupLocation, load?.pickupStartDate, load?.pickupEndDate),
                icon: <LotsMapMarker
                    lot={load?.pickupLocation}
                    color={load?.pickupLocation?.pmfLot ? "#0090FE" : "#E48900"}
                    text={load?.pickupLocation?.name}
                />
            });

            markersList.push({
                address: googleMapService.convertAddressPartsToAddressString(load?.deliveryLocation ?? {}),
                content: getInfoWindowContent(load?.deliveryLocation, load?.deliveryStartDate, load?.deliveryEndDate),
                icon: <LotsMapMarker
                    lot={load?.deliveryLocation}
                    color={load?.deliveryLocation?.pmfLot ? "#0090FE" : "#E48900"}
                    text={load?.deliveryLocation?.name}
                />
            });
        })


        markersList = [...markersList, ...historyMarkers];
        setMarkers(markersList);
        setIsLoading(false);
    }

    useEffect(() => {
        if (!isLoadingLoadLocations && !isLoadingLoadGroupLocations)
            setAllMarkers();
    }, [showLocations, isLoadingLoadLocations, isLoadingLoadGroupLocations]);

    return (
        <div className={classes.mapWrapper}>
            {/* {isLoading ?
                <LoadingSpinner loading={isLoading} /> : */}
            {!isLoading && <MapWrapper
                defaultCenter={{ lat: Number(loads?.[0]?.pickupLocation?.latitude), lng: Number(loads?.[0]?.pickupLocation?.longitude) }}
                markers={markers}
                routes={loads?.map(load => ({ startLocation: load?.pickupLocation ?? {}, endLocation: load?.deliveryLocation ?? {} }))}//[{ startLocation: loads?.[0]?.pickupLocation, endLocation: loads?.[loads?.length - 1]?.deliveryLocation }]
            />}
            {/* } */}
        </div>
    );
}

export default LoadMap