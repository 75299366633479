import { IconLocationWrapper } from 'icons';
import { BasicMarkerStyle } from './BasicMarkerStyle';
import { Tooltip } from '@material-ui/core';

const BasicMarker = ({ color, text, subText, textColor, icon, wrapperSize = '40', tooltip }) => {

    const classes = BasicMarkerStyle();
    return (
        <Tooltip title={tooltip ?? ''}>
            <div className={classes.markerWrapper}>

                <div className={classes.iconWrapper}>
                    <IconLocationWrapper fill={color} width={wrapperSize} height={wrapperSize} />
                    {icon}
                </div>

                {text && <div className={`${classes.textWrapper} ${textColor ? classes.coloredTextWrapper : ""}`} >
                    {text}
                </div>}
                {subText && <div className={`${classes.textWrapper} ${classes.coloredSubTextWrapper}`} >
                    {subText}
                </div>}
            </div>
        </Tooltip>
    )
}
export default BasicMarker;
