import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimePickerStyle } from "./style";
import { TextField } from '@mui/material';

export const TimeField = ({
    title,
    value,
    onChange,
    required = false,
    disabled = false,
    views = ["hours", "minutes"],
    readOnly = false
}) => {
    const classes = TimePickerStyle()

    return (
        <div className={classes.picker}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    onChange={onChange}
                    disabled={disabled}
                    views={views}
                    value={value}
                    renderInput={(params) =>
                        <TextField {...params}
                            variant='outlined'
                            sx={{ svg: { color: '#0090FE' } }}
                            inputProps={{ ...params.inputProps, readOnly: readOnly }}
                            required={required}
                        />
                    }
                    label={title} />
            </LocalizationProvider>
        </div>
    )
}