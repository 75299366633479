import { ReservationVehicleStyles } from "./ReservationVehicleStyles";
import LinkedAssetsSelector from "../../../../IssuesTracker/LinkedAssetsSelector/LinkedAssetsSelector"; //C:\Users\Rachel\project\web\parqai-web\src\features\IssuesTracker\LinkedAssetsSelector\LinkedAssetsSelector.js
import { Paper, Grid, Button } from "@material-ui/core";
import LinkedVehicleSelector from "./LinkedVehicleSelector";
import { useAddVehicleMutation } from "../../../reservationSlice";
import NotificationProvider from "components/NotificationProvider";
import { useState } from "react";
export const AddVehicle = ({ setAdd, client, reservationId,vehiclesChosen }) => {
  const classes = ReservationVehicleStyles();
  const [assets, setAssets] = useState([]);
  const [addVehicle, { isLoading: isAdding }] = useAddVehicleMutation();
  const [saving, setSaving] = useState(false);
  const onSave = async () => {
    setSaving(true);
    let listReservationVehicles = assets?.map((a) => ({
      vehicleId: a.id,
      reservationId: reservationId,
    }));
    let body = { reservationVehicles: listReservationVehicles };
    let result;
    result = await addVehicle(body);
    if (result?.error) {
      NotificationProvider.error("Failed to add vehicle");
      setSaving(false);
    } else {
      NotificationProvider.success("Successfully add vehicle");
      setSaving(false);
      setAdd(false);
    }
  };
  return (
    <Paper
      className={`${classes.listItemWrapper} ${classes.listItemWrapperNew}`}
    >
      <Grid container spacing={1} justifyContent="flex-end">
        <LinkedVehicleSelector
          assets={assets}
          setAssets={setAssets}
          selectedClient={client}
          vehiclesChosen={vehiclesChosen}
        ></LinkedVehicleSelector>
        <Grid item className={classes.btnWrapper}>
          <Button
            onClick={() => {
              setAdd(false);
            }}
            className={classes.button}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={onSave}
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
