import { makeStyles } from "@material-ui/core"

export const SecurityPhotosStyle = makeStyles(theme => ({
    pageContent: {
        padding: "3em"
    },
    header: {
        paddingBottom: "1.5em",
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
        fontSize: 24,
        fontWeight: 550,
        paddingBottom: "1em"
    },
    filter: {
        minWidth: "164px"
    },
    filterMobile: {
        maxWidth: "100%",
        width: "100%",
        flexBasis: "100%"
    },
    containerFolter: {display: "flex", justifyContent: "center", alignItems: "center", padding: 20}
}));
