import * as React from "react";

function SvgIconParkingLot2(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.097 7.5c2.004 0 3.635 1.598 3.635 3.563 0 1.964-1.63 3.562-3.635 3.562h-2.424v3.52c0 .679-.542 1.23-1.211 1.23-.67 0-1.212-.551-1.212-1.23V8.58c0-.597.476-1.08 1.063-1.08h3.784zm-2.424 2.375h2.424c.668 0 1.212.533 1.212 1.188 0 .654-.544 1.187-1.212 1.187h-2.424V9.875z"
                fill="#0090FE" />
            <path
                d="M15 28.017a.936.936 0 0 1-.67-.278l-7.28-7.28a11.25 11.25 0 1 1 15.9 0l-7.28 7.28a.936.936 0 0 1-.67.278zm0-24.863A9.354 9.354 0 0 0 8.39 19.12l6.61 6.61 6.61-6.61A9.354 9.354 0 0 0 15 3.154z"
                fill="#0090FE" />
        </svg>
    );
}

export default SvgIconParkingLot2;
