import { makeStyles } from "@material-ui/core";

export const KanbanTableStyle = makeStyles((theme) => ({
    tableContainer: {
        height: '100%',
        minHeight: '50vh',
        width: '100%',
        overflowX: 'auto'
    },
    tableActions: {
        borderRadius: '5px 5px 0 0',
        maxHeight: '45px',
        margin: '10px 0',
        alignContent: 'center'
    },
    views: {
        display: 'flex'
    },
    tableViewsSelector: {
        marginLeft: '0.5em',
        paddingLeft: '1em',
        paddingTop: '3px',
        fontWeight: '500',
        borderLeft: '1px solid #efefef'
    },
    cardSection: {
        padding: '0.1rem 0 0.7rem',
    },
    usersOverlap: {
        bottom: 0,
        position: 'absolute',
        right: 0,
        zIndex: 1,
        '&:not(:first-child)': {
            right: 25,
            zIndex: 0
        }
    },
    cardWrapper: {
        position: 'relative'
    },
    cardTitleWrapper: {
        paddingBottom: '0.7rem',
        marginRight: '0.3rem',
        marginLeft: '0.2rem'
    },
    cardTitle: {
        fontSize: '0.9em', fontWeight: 600
    },
    issueId: {
        fontSize: '0.9em', fontWeight: 450
    },
    category: {
        fontSize: '0.9em', fontWeight: 450, color: "#3f8ef6", margin: "0.2rem 0 0.2rem 0.5rem"
    },
    linksWrapper: {
        height: '100px'
    },
    iconEdit: {
        color: theme.palette.primary.main
    },
    tableContentWrapper: {
        overflowX: 'auto',
        height: 'calc(100%-50px)',
        padding: '1rem'
    },
    droppableWrapper: {
        minWidth: '255px',
        marginRight: '0.5rem',
        overflowX: 'unset',
        height: "100%"
    },
    rowWrapper: {
        margin: '0 0 0.5rem 0.2rem',
        overflowX: 'unset'
    },
    scrollContainer: {
        // Scrollbar customization
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        }
    },
    droppableArea: {
        padding: '3px',
        border: '2px dashed',
        borderColor: 'transparent',
        transition: 'border-color 0.2s ease',
        //backgroundColor: 'transparent',
        //transition: 'background-color 0.2s ease',
    },
    dragHandler: {
        width: '100%',
        cursor: 'move',
        transform: 'rotate(90deg)',
        textAlign: 'center'
    },
}));
