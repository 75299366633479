import { makeStyles } from '@material-ui/core/styles';

export const UserTaskGroupStyle = makeStyles((theme) => ({
    itemWrapper: {
        paddingBottom: '0.5em 0',
        marginBottom: '1em'
    },
    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        background: theme.palette.neutral.light,
        padding: '0 1rem',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '5px',
        marginBottom: '0.5rem'
    },
}));
