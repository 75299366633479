import React, { useState } from "react";
import moment from "moment";
import { useUpdateVehicleDetailMutation } from "../vehicleSlice";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import DatePicker from "../../../components/DatePicker";
import { useGetLotsQuery } from "../../lots/lotSlice";
import CustomInput from "../../../components/CustomInput";
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import { getLotsWithPmfText } from "../../../features/lots/lotUtil";

const EditVehicleDetails = ({
    onCancel,
    vehicleDetails,
    onConfirm,
    vehicleId
}) => {
    const classes = vehicleDetailsPageStyle();
    const [date, setDate] = useState(moment(vehicleDetails?.date).format('YYYY-MM-DD'));
    const [updateVehicleDetails, { isLoading }] = useUpdateVehicleDetailMutation();
    let { data: lots, error: getLotsError, isLoading: loadingLots } = useGetLotsQuery();
    const [selectedLot, setSelectedLot] = useState(vehicleDetails?.lotId);

    const save = async () => {
        let dateToSave = new Date(moment(date).format("YYYY-MM-DD, hh:mm a") + " UTC");
        let activityDate = dateToSave.toISOString();
        try {
            await updateVehicleDetails({ id: vehicleDetails.vehicleDetailsId, createdDate: activityDate, lot: selectedLot, vehicleId: vehicleId });
            onConfirm(vehicleDetails.vehicleDetailsId, date);
        } catch (err) {
        }
    };

    if (isLoading) {
        return <LoadingSpinner />
    }

    return (
        <Dialog open={!!vehicleDetails} onClose={() => onCancel(false)} aria-labelledby='dialog-title'>
            <DialogTitle id={'dialog-title'} >Edit Activity</DialogTitle>
            <DialogContent>
                <div className={classes.input}>
                    <CustomInput
                        id="lot"
                        label="Lot"
                        value={selectedLot}
                        elementType="dropdown"
                        showEmpty={true}
                        required={false}
                        onChange={val => setSelectedLot(val)}
                        values={getLotsWithPmfText(lots)}
                    />
                </div>
                <div className={classes.input}>
                <DatePicker
                    title="Date"
                    value={date}
                    onSelect={setDate} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary'>
                    Cancel
                </Button>
                <Button onClick={save} color='primary' variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditVehicleDetails;
