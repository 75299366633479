import { Dialog, Grid, Paper } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@mui/material/Divider';
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { useGetMapUrlQuery } from "features/lots/lotSlice";
import LotAttachmentsDialog from "../../LotAttachmentsDialog";
import LotSummary from "../../LotSummary";
import VirtualLot from "../../VirtualLot";
import LotItem from "../LotItem/LotItem";
import { lotPanelStyle } from "./LotsPanelStyle";
import LotDispatchDetails from "../../LotSummary/LotDispatchDetails/LotDispatchDetails";

const LotPanel = ({ lot, subMarketsByLot, onEditLot, onViewImages,
    onDelete, onCloseImages, onSaveLot, lotViewImages, updateLotLoading,
    deletePopup, setDeletePopup, handleClosePanel }) => {

    const classes = lotPanelStyle();

    let { data: map, error: attachmentsError, isFetching: isLoadingAttachments } = useGetMapUrlQuery(lot?.id, { skip: !lot?.pmfLot });

    return (
        <>
            {lot &&
                <Grid container spacing={2} className={classes.container} >
                    {lot.pmfLot ?
                        <>
                            <Grid item xs={6} >
                                <Paper className={classes.detailsPaper} >
                                    <LotItem
                                        hideElements
                                        lot={lot}
                                        onEdit={(lot) => onEditLot(lot)}
                                        onViewImages={onViewImages}
                                        subMarketsByLot={subMarketsByLot}
                                        onDelete={(lot) => onDelete(lot)}
                                    />
                                    <Divider />
                                    <div className={classes.lotSummary}>
                                        <LotSummary lotId={lot.id} showLotTitle={false} onEdit={(lot) => onEditLot(lot)} />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.mapPaper}>
                                    <IconButton aria-label="close" onClick={handleClosePanel} className={classes.closeIcon}>
                                        <CloseIcon />
                                    </IconButton>
                                    <div className={classes.lotMapWrapper}>
                                        {map ? <VirtualLot id={lot.id} /> :
                                            <div className={classes.noMapWrapper} >
                                                <EmptyPlaceholder text="Lot map not available" />
                                            </div>}
                                    </div>
                                </Paper>
                            </Grid>
                        </> :
                        <Grid item xs={12}>
                            <Paper className={classes.paperSmall}>
                                <IconButton aria-label="close" onClick={handleClosePanel} className={classes.closeIconSmall}>
                                    <CloseIcon />
                                </IconButton>
                                <LotItem
                                    hideElements
                                    lot={lot}
                                    onEdit={(lot) => onEditLot(lot)}
                                    onViewImages={onViewImages}
                                    subMarketsByLot={subMarketsByLot}
                                    onDelete={(lot) => onDelete(lot)}
                                />
                                <Divider />
                                <div className={classes.lotSummary}>
                                    <LotDispatchDetails lot={lot} lotId={lot.id} onEdit={(lot) => onEditLot(lot)} />
                                </div>
                            </Paper>
                        </Grid>}

                    <LotAttachmentsDialog lot={lotViewImages} onSave={onSaveLot} onClose={() => onCloseImages()} saving={updateLotLoading} />
                    <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
                        <div className={classes.deletePopup}>
                            There are still Assets on this Lot. Please move them all to another Lot and then delete this Lot
                        </div>
                    </Dialog>
                </Grid >}
        </>
    )
}

export default LotPanel;