import React from "react";
import { toast } from 'react-toastify';

const NotificationProvider = {
    info: (message, options = {}) => {
        toast.info(message, options);
    },
    error: (message, options = {}) => {
        toast.error(message, { autoClose: false, closeOnClick: false, ...options });
    },
    success: (message, options = {}) => {
        toast.success(message, options);
    }
};

export default NotificationProvider;