import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { useState } from "react";
import { approveActivityStyle } from "./style";
import { useConfirmActivityPriceMutation } from "features/activity/activitySlice";
import usePrompt from "hooks/usePrompt";
import { Button, Grid } from "@material-ui/core";


const ApproveAbovePriceCap = ({
    activityLog,
}) => {

    const classes = approveActivityStyle();
    const [confirmActivity, { isLoading }] = useConfirmActivityPriceMutation();
    const [open, setOpen] = useState(false);
    const { triggerPrompt } = usePrompt();
    const onSave = async (confirmed = true) => {
        triggerPrompt({
            title: confirmed ? 'Approve Request' : 'Cancel Request',
            content: confirmed ? 'The activity price is above the price cap, are you sure you want to approve it?' : 'Are you sure you want to cancel the request?',
            loading: isLoading,
            onConfirm: async () => {
                const res = await confirmActivity({
                    activityLogId: activityLog.id,
                    confirmed: confirmed
                });
                if (res?.error) {
                    NotificationProvider.error(`An error occured while saving the activity, please try again.`);
                    return;
                } else {
                    NotificationProvider.success(confirmed ? 'Successfully approved the activity' : 'Successfully cancelled the activity');
                    setOpen(false);
                }
            },
            onCancel: () => {
                setOpen(false);
            }
        });

    };


    return (
        <>

            {activityLog?.abovePriceCap && !activityLog?.approvedDate &&
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Authorize profile={permissionProfiles.ACTIVITY.ABOVE_CAP_APPROVAL}>
                            <Button disabled={isLoading} onClick={() => onSave(false)} color='primary'>
                                {'Cancel activity'}
                            </Button>
                        </Authorize>
                    </Grid>
                    <Grid item >
                        <Authorize profile={permissionProfiles.ACTIVITY.ABOVE_CAP_APPROVAL}>
                            <Button variant="contained" disabled={isLoading} onClick={() => onSave(true)} color='primary'>
                                {'Confirm price'}
                            </Button>
                        </Authorize>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default ApproveAbovePriceCap;