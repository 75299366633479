import * as React from "react";

function SvgIconMap(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M1.90845 6.48299V14.5913C1.90845 16.1747 3.03345 16.8247 4.40011 16.0413L6.35845 14.9247C6.78345 14.683 7.49178 14.658 7.93345 14.883L12.3084 17.0747C12.7501 17.2913 13.4584 17.2747 13.8834 17.033L17.4918 14.9663C17.9501 14.6997 18.3334 14.0497 18.3334 13.5163V5.40799C18.3334 3.82465 17.2084 3.17465 15.8418 3.95799L13.8834 5.07465C13.4584 5.31632 12.7501 5.34132 12.3084 5.11632L7.93345 2.93299C7.49178 2.71632 6.78345 2.73299 6.35845 2.97465L2.75011 5.04132C2.28345 5.30799 1.90845 5.95799 1.90845 6.48299Z" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.1333 3.33301V14.1663" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.1084 5.5166V16.6666" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default SvgIconMap;
