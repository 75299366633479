import AttachmentsView from "features/attachments/AttachmentsView";
import { AttachmentEntityTypes } from "features/attachments/attachmentsConsts";
import { ReservationDocumentsStyle } from "./ReservationDocumentsStyle";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const ReservationDocuments = ({ reservation }) => {
    const classes = ReservationDocumentsStyle();

    return (
        <div className={classes.documentsWrapper}>
            <AttachmentsView
                entityId={reservation?.id}
                entityType={AttachmentEntityTypes.RESERVATION}
                label='Document'
                addPermissionProfile={permissionProfiles.RESERVATIONS.EDIT}
            />
        </div>
    )
};

export default ReservationDocuments;
