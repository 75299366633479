import { makeStyles } from "@material-ui/core";

export const storageInvoiceStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '2em',
        marginTop: '1em'
    },
    detailsWrapper: {
        padding: '2em 0 0',
    },
    label: {
        color: theme.palette.secondary.light,
    },
    field: {
        fontWeight: 'bold'
    },
    lotCol: {
        fontWeight: 'bold'
    },
    input: {
        margin: 15
    },
    warning: {
        '& path': {
            'fill': '#ff6f00'
        }
    },
    sendBtn: {
        fontSize: 12
    }

}));
