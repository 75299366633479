import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { useState } from "react";
import { InspectionReportsStyles } from "./InspectionReportsStyles";
import InspectionsTimeFrame from "./InspectionsTimeFrame/InspectionsTimeFrame";
import InspectionsGroups from "./InspectionsGroups/InspectionsGroups";

const InspectionReports = ({ inspections, filteredInspections }) => {
    const classes = InspectionReportsStyles();

    const [groupDates, setGroupDates] = useState('true');
    return (
        <>
            <FormControl className={classes.formControl}>
                <RadioGroup
                    row
                    name="create-dispatch-radio-group"
                    value={groupDates}
                    onChange={(e) => { setGroupDates(e.target.value) }}
                >
                    <FormControlLabel value="true" control={<Radio />} label="Recent" />
                    <FormControlLabel value="false" control={<Radio />} label=" Select Time Frame" />
                </RadioGroup>
            </FormControl>

            {groupDates === 'true' ?
                <InspectionsGroups inspections={inspections} filteredInspections={filteredInspections} /> :
                <InspectionsTimeFrame />}
        </>
    );
};

export default InspectionReports;
