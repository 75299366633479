import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export const copyToClipboardStyle = makeStyles((theme) => ({
    icon: {
        fill: 'lightgrey',
        height: '0.8em',
        '&:hover': {
            fill: 'darkgrey'
        }
    },
    copied: {
        display: 'inline-flex',
        alignItems: 'center',
        height: '100%'
    }
}));
