import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Paper } from "@material-ui/core";
import { Image, List } from "@material-ui/icons";
import { getErrorsFromResponse } from "app/errorHandling";
import { diff } from 'deep-object-diff';
import { useGetDriversQuery } from "features/drivers/driversSlice";
import { NoteEntityTypes } from "features/notes/notesConsts";
import _, { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import AddPhoto from "../../../components/AddPhoto";
import CustomInput from "../../../components/CustomInput";
import NotificationProvider from '../../../components/NotificationProvider';
import FileService from '../../../services/fileService';
import FormatService from '../../../services/formatService';
import VehicleService from '../../../services/vehicleService';
import { useGetClientsQuery } from "../../clients/clientSlice";
import { useGetLotsQuery } from "../../lots/lotSlice";
import { getLotsWithPmfText } from "../../lots/lotUtil";
import { useGetVendorsQuery } from '../../vendors/vendorSlice';
import { vehicle_detail_extended_type } from "../VehicleDetailExtended/VehicleDetailExtendedTypes";
import VehicleStatusView from "../VehicleStatus/VehicleStatusView";
import VinDecode from "../VinDecode/VinDecodePopup";
import VehicleMatches from "../components/VehicleMatches/VehicleMatches";
import VehicleTypeDropDown from "../components/VehicleTypeDropDown/VehicleTypeDropDown";
import VinMatching from "../components/VinMatching/VinMatching";
import { licensePlateTypesList } from '../vehicleConsts';
import {
    useAddAssetMutation,
    useEditAssetMutation,
    useGetFuelTypesQuery,
    useGetImageTypesQuery,
    useGetVehicleDetailsExtendedQuery,
    useGetVehicleDetailsQuery,
    useUpdateImageTypeMutation, useUploadImageMutation
} from "../vehicleSlice";
import { EditExtendedInfo } from "./EditExtendedInfo";
import { editAssetStyle } from "./style";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { arrayToObject } from "utils/object-util";

const EditAssetPopup = ({ handleClose, asset, updateAsset, openPhotos }) => {

    let { data: vehicleDetailsExtended, error: VehicleDetailExtendedError, isLoading: isLoadingVehicleDetailExtended } = useGetVehicleDetailsExtendedQuery(asset.id);
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: vehicleDetails, error: vehicleDetailsError, isLoading: isLoadingVehicleDetails, isSuccess: isVehicleDetailsSuccess, refetch: refetchDetails } = useGetVehicleDetailsQuery(asset.id, { skip: !asset.id });
    let { data: imageTypes, error: imageTypesError, isLoading: isLoadingImageTypes } = useGetImageTypesQuery();
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();
    let { data: clientsWithConfigs, error: clientConfigError, isLoading: isLoadingAllLots } = useGetClientsWithConfigsQuery(null);
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();

    let [editAsset] = useEditAssetMutation();
    let [addAsset] = useAddAssetMutation();
    let [uploadImage] = useUploadImageMutation();
    let [updateImageType, { isLoadingUpdateImageType, isError, isSuccess }] = useUpdateImageTypeMutation();

    vendors = vendors || [];
    clients = clients || [];
    vehicleDetails = vehicleDetails || {};

    let [editedAsset, setEditedAsset] = useState(asset);

    const classes = editAssetStyle();
    let [isSaving, setIsSaving] = useState(false);
    let [showPhotos, setShowPhotos] = useState(false);
    let [hasVehicleDetailExtendedChanged, setHasVehicleDetailExtendedChanged] = useState(false);
    const [error, setError] = useState(null);
    const [mandatoryFields, setMandatoryFields] = useState({});
    let [existingImages, setExistingImages] = useState();
    let [newVehicleDetailsExtended, setNewVehicleDetailsExtended] = useState(vehicleDetailsExtended);
    const [decodedData, setDecodedData] = useState({});
    const [openDecoded, setOpenDecoded] = useState(false);
    const [colorDecoded, setColorDecoded] = useState(false);
    const [colorDecodedType, setColorDecodedType] = useState(false);
    const [newImages, setNewImages] = useState([]);

    let clientAccounts = [];
    clients.map(c => {
        clientAccounts = [...clientAccounts, ...c.clientAccounts?.map(ca => ({ ...ca }))]
    });

    const clientLookup = arrayToObject(clientAccounts, 'id');
    const [clientsConfig, setClientsConfig] = useState({});

    useEffect(() => {
        if (!existingImages) {
            setExistingImages(vehicleDetails?.images);
        };
        if (!isEmpty(vehicleDetails)) {
            setEditedAsset({ ...asset, driverCarrierId: !asset.driverCarrierId ? asset.driverCarrierId : vehicleDetails?.driverCarrierId })
        }
    }, [vehicleDetails])

    drivers = drivers?.map(d => ({
        ...d,
        name: `${d?.firstName} ${d?.lastName}`
    }))

    drivers = drivers?.filter(d => d.vendorId == editedAsset?.driverCarrierId ?? vehicleDetails?.driverCarrierId);

    useEffect(() => {
        if (openPhotos) {
            setShowPhotos(true);
        }
    }, [openPhotos])

    useEffect(() => {
        setNewVehicleDetailsExtended(vehicleDetailsExtended)
    }, [vehicleDetailsExtended])

    useEffect(() => {
        // let clientConfig = clientsWithConfigs?.find(c => c.accountId === editedAsset?.customerId);
        let clientConfig = clientsWithConfigs?.find(c => c.id === editedAsset?.clientId); //changed to check by clientId until storage sla dealt with
        setClientsConfig(clientConfig); 
    }, [editedAsset.customerId])

    const showClientDropdown = () => { //if user only has access to one account hide dropdown 
        if (clientAccounts?.length == 1) {
            return false;
        } else {
            return true;
        }
    }

    const isLoading = () => isLoadingClients || isLoadingLots || isLoadingVehicleDetails || isLoadingImageTypes || isLoadingVendors;

    const uploadImages = async (vehicleId) => {
        let result;
        for (let i = 0; i < newImages.length; i++) {
            let base64Data = await FileService.getBase64Data(newImages[i].file);
            let image = { vehicleId: vehicleId, fileName: newImages[i].file.name, imageData: base64Data, imageTypeId: newImages[i].imageTypeId }
            result = await uploadImage(image);
            if (result && result.error) {
                setError(result.error);
                return result;
            }
        }
        for (let i = 0; i < existingImages?.length; i++) {
            if (existingImages[i] !== vehicleDetails.images[i]) {
                result = await updateImageType(existingImages[i]);
                if (result && result.error) {
                    setError(result.error);
                    return result;
                }
            }
        }
        return result;
    };

    const convertToSentenceCase = (str) => {
        if (str)
            return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    };

    const populateDecoded = () => {
        setOpenDecoded(false);
        if (decodedData) {

            setColorDecoded(true);
            if (decodedData?.vehicleType)
                setColorDecodedType(true);

            setEditedAsset({
                ...editedAsset,
                year: decodedData?.year,
                make: convertToSentenceCase(decodedData?.make),
                model: decodedData?.model,
                ...(decodedData?.vehicleType && { vehicleTypeId: decodedData.vehicleType }),
            });

            setTimeout(() => {
                setColorDecoded(false);
            }, 5000);
        }
    }

    const validateAsset = () => {
        let errors = {};
        if (editedAsset.hasLicensePlate) {
            if (!editedAsset.licensePlate) {
                errors['licensePlate'] = true;
            }
            if (!editedAsset.licensePlateState) {
                errors['licensePlateState'] = true;
            }
            if (!editedAsset.licensePlateType) {
                errors['licensePlateType'] = true;
            }
        }
        // if (editedAsset.hasRegistration) {
        //     if (!editedAsset.registrationYear) {
        //         errors['registrationYear'] = true;
        //     }
        // }
        setMandatoryFields(errors);
        if (Object.keys(errors)?.length) return false;
        return true;
    }

    const onSubmit = async () => {
        if (!validateAsset()) {
            return;
        }
        setIsSaving(true);
        if (editedAsset.includeInStorageReport === undefined) {
            editedAsset.includeInStorageReport = false;
        }

        let changes = diff(asset, editedAsset);
        let hasChanged = !!Object.entries(changes).length || hasVehicleDetailExtendedChanged;
        let vehicleId = asset.id;

        let assetUpdateResult;
        if (hasChanged) {
            changes.vehicleId = asset.id;
            const vdExtended = newVehicleDetailsExtended?.filter(a => a.changed);
            if (changes.vehicleId) {
                changes.vehicleDetailExtended = vdExtended;
                assetUpdateResult = await editAsset(changes);
            } else {
                assetUpdateResult = await addAsset({ ...editedAsset, vehicleDetailExtended: vdExtended });
                vehicleId = assetUpdateResult?.data?.id;
            }

            if (assetUpdateResult && assetUpdateResult.error) {
                NotificationProvider.error(`Failed to save the asset. ${getErrorsFromResponse(assetUpdateResult)}`);
                setError(assetUpdateResult.error);
                setIsSaving(false);
                return;
            }
        }

        let imageUpdateResult = await uploadImages(vehicleId);

        if (imageUpdateResult && imageUpdateResult.error) {
            NotificationProvider.error("Failed to save asset images");
            setError(imageUpdateResult.error);
            setIsSaving(false);
            return;
        }

        if ((assetUpdateResult && hasChanged) || imageUpdateResult) {
            NotificationProvider.success("The asset was saved successfully");
        }

        // refetchDetails();
        if (hasChanged) {
            updateAsset(editedAsset);
        } else {
            handleClose();
        }
    };

    const onChange = (key, val) => {
        setEditedAsset({ ...editedAsset, [key]: val === "" ? undefined : val });
        setMandatoryFields({ ...mandatoryFields, [key]: val ? false : true });
    };

    const isValid = () => {
        return !!editedAsset.descriptor &&
            (!!editedAsset.mileage || editedAsset.mileage === 0)
            && !!editedAsset.clientId && !!editedAsset.vehicleTypeId;
    };

    const addImage = image => {
        setNewImages([...newImages, image]);
    };

    const changeImageTypeId = (image) => {
        setExistingImages([...existingImages].map((img) => {
            if (img.id == image.id) {
                return { id: img.id, url: img.url, createdDate: img.createdDate, imageTypeId: image.imageTypeId };
            }
            else {
                return img;
            }
        }));
    };

    const onExtendedDataChange = (key, val, isBoolean) => {
        let editVehicleDetail = newVehicleDetailsExtended?.find(x => x.vehicleDetailExtendedTypeId === key) ?? {};
        let allVehicleDetails = newVehicleDetailsExtended?.filter(function (x) { return x.vehicleDetailExtendedTypeId !== key; }) ?? [];

        let newRecord = { ...editVehicleDetail, vehicleDetailExtendedTypeId: key, vehicleId: vehicleDetails.id, changed: true };
        newRecord.value = isBoolean ? val ? 'Yes' : 'No' : val;
        allVehicleDetails.push(newRecord);
        setNewVehicleDetailsExtended(allVehicleDetails);
        setHasVehicleDetailExtendedChanged(true);
    }

    let client = clients?.find(c => c.clientAccounts.find(ca => ca.id === editedAsset?.customerId));

    let form;
    if (isLoading() || isSaving) {
        form = <CircularProgress className={classes.spinner} />
    } else if (showPhotos) {
        form = (
            <AddPhoto newImages={newImages} existingImages={existingImages || []} addImage={addImage} changeImageTypeId={changeImageTypeId} imageTypes={imageTypes} noteType={NoteEntityTypes.VEHICLE_IMAGE} />
        )
    } else {
        form = (
            <Grid container spacing={3} className={classes.formWrapper}>
                {showClientDropdown() &&
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="clientAccount"
                                label="Client"
                                value={editedAsset.customerId}
                                elementType="dropdown"
                                onChange={val => {
                                    setEditedAsset({ ...editedAsset, ['customerId']: val == "" ? undefined : +val, ['clientId']: clientLookup?.[val]?.clientId })
                                }}
                                values={clientAccounts}
                                showEmpty
                            />
                        </div>
                    </Grid>
                }
                {clientsConfig?.config?.lotConfig?.usingAmzStorageReport &&
                    <Grid item xs={6}>
                        <div >
                            <FormControlLabel
                                control={<Checkbox
                                    checked={editedAsset.includeInStorageReport ?? false}
                                    onChange={(e, value) => onChange("includeInStorageReport", value)}
                                />}
                                label="Include In Storage Report" />
                        </div>
                    </Grid>
                }
                <Grid item xs={12} className={classes.input}>
                    <div className={classes.vinWrappper}>
                        <Grid xs={9}>
                            <div className={classes.vinInput}>
                                <CustomInput
                                    id="vin"
                                    label="VIN"
                                    value={editedAsset.descriptor}
                                    elementType="input"
                                    required={true}
                                    onChange={val => onChange("descriptor", val)}
                                />
                                {!!editedAsset?.id && <VehicleMatches
                                    title={'This VIN already exists in ParQ, click for more details'}
                                    currentId={editedAsset?.id}
                                    vin={editedAsset.descriptor}
                                    clientAccountId={editedAsset.customerId}
                                />}
                            </div>
                        </Grid>
                        <Grid >
                            <VinDecode vin={editedAsset?.descriptor} setDecodedData={setDecodedData} applyDecode={populateDecoded} openDecoded={openDecoded} setOpenDecoded={setOpenDecoded} />
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <Grid>
                            <CustomInput
                                id="assetId"
                                label="Asset ID"
                                value={editedAsset.assetId}
                                elementType="input"
                                onChange={val => onChange("assetId", val)}
                            />
                            {!!editedAsset?.id && <VehicleMatches
                                title={'This asset id already exists in ParQ, click for more details'}
                                currentId={editedAsset?.id}
                                assetId={editedAsset.assetId}
                                clientAccountId={editedAsset.customerId}
                            />}
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            id="mileage"
                            label="Mileage"
                            value={editedAsset.mileage}
                            elementType="input"
                            required={true}
                            onChange={val => onChange("mileage", +val)}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        id="fuelType"
                        label="Fuel Type"
                        value={editedAsset.fuelType}
                        elementType="dropdown"
                        onChange={val => onChange("fuelType", +val)}
                        values={fuelTypes}
                        showEmpty
                    />
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            id="year"
                            label="Year"
                            value={editedAsset.year ?? ''}
                            elementType="input"
                            onChange={val => onChange("year", val)}
                            colorInput={colorDecoded}
                        />
                        {decodedData && <VinMatching decodedData={decodedData.year} editedData={editedAsset.year} field='year' />}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            id="make"
                            label="Make"
                            value={editedAsset?.make ?? ''}
                            elementType="input"
                            onChange={val => onChange("make", val)}
                            colorInput={colorDecoded}
                        />
                        {decodedData && <VinMatching decodedData={convertToSentenceCase(decodedData.make)} editedData={convertToSentenceCase(editedAsset.make)} field='make' />}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            id="model"
                            label="Model"
                            value={editedAsset.model ?? ''}
                            elementType="input"
                            onChange={val => onChange("model", val)}
                            colorInput={colorDecoded}
                        />
                        {decodedData && <VinMatching decodedData={convertToSentenceCase(decodedData.model)} editedData={convertToSentenceCase(editedAsset.model)} field='model' />}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            id="trim"
                            label="Trim"
                            value={editedAsset.trim}
                            elementType="input"
                            onChange={val => onChange("trim", val)}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        id="lot"
                        label="Lot"
                        value={editedAsset.lotId}
                        elementType="dropdown"
                        onChange={val => onChange("lotId", +val)}
                        values={getLotsWithPmfText(lots)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        id="domicileLot"
                        label="Domicile Lot"
                        showEmpty
                        value={editedAsset.domicileLot}
                        elementType="dropdown"
                        onChange={val => onChange("domicileLot", +val)}
                        values={lots.filter(lot => lot.pmfLot)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VehicleTypeDropDown
                        label={"Asset Type"}
                        required={true}
                        showEmpty={true}
                        colorInput={colorDecodedType}
                        value={editedAsset.vehicleTypeId}
                        onChange={(obj) => onChange("vehicleTypeId", +obj.vehicleTypeId)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VehicleStatusView
                        readonly={false}
                        clientId={client?.id}
                        value={editedAsset.vehicleStatusId}
                        onChange={val => onChange("vehicleStatusId", +val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            id="carrier"
                            label="Carrier"
                            value={editedAsset.driverCarrierId}
                            elementType="dropdown"
                            onChange={val => onChange("driverCarrierId", +val)}
                            values={[{ id: null, name: '' }, ...vendors]}
                            showEmpty
                        />
                    </div>
                </Grid>
                <Grid item xs={6} >
                    <div className={classes.input}>
                        <CustomInput
                            id="driver"
                            label="Driver"
                            elementType="dropdown"
                            values={drivers}
                            value={editedAsset.driverId}
                            showEmpty
                            onChange={val => onChange("driverId", +val)}
                        />
                    </div>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={3}>
                        <div className={classes.input}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={editedAsset.hasLicensePlate ?? false}
                                    onChange={(e, value) => onChange("hasLicensePlate", value)}
                                />}
                                label="Has license plate" />
                        </div>
                    </Grid>
                    {editedAsset.hasLicensePlate && <><Grid item xs={3}>
                        <div className={classes.input}>
                            <CustomInput
                                id="license"
                                label="License Plate"
                                value={editedAsset.licensePlate ?? ''}
                                elementType="input"
                                onChange={val => onChange("licensePlate", val)}
                                valid={!mandatoryFields?.['licensePlate']}
                                touched={true}
                            />
                        </div>
                    </Grid>
                        <Grid item xs={3} >
                            <div className={classes.input}>
                                <CustomInput
                                    id="licensePlateState"
                                    label="License State"
                                    value={editedAsset.licensePlateState}
                                    elementType="dropdown"
                                    onChange={val => onChange("licensePlateState", val)}
                                    values={[{ name: '', id: null }, ...VehicleService.getUsStates()]}
                                    valid={!mandatoryFields?.['licensePlateState']}
                                    touched={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div className={classes.input}>
                                <CustomInput
                                    id="licensePlateType"
                                    label="License Type"
                                    value={editedAsset.licensePlateType}
                                    elementType="dropdown"
                                    onChange={val => onChange("licensePlateType", val)}
                                    values={licensePlateTypesList}
                                    valid={!mandatoryFields?.['licensePlateType']}
                                    touched={true}
                                    showEmpty
                                />
                            </div>
                        </Grid>
                    </>}
                </Grid >
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={8} container>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={editedAsset.hasRegistration ?? false}
                                        onChange={(e, value) => {
                                            if (!value) {
                                                onChange("registrationExpired", false)
                                                onChange("registrationYear", null)
                                                onChange("registrationMonth", null)
                                            }
                                            onChange("hasRegistration", value);
                                        }
                                        }
                                    />}
                                    label="Has registration" />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={newVehicleDetailsExtended?.find(x => x.vehicleDetailExtendedTypeId === vehicle_detail_extended_type.registration_present)?.value == "Yes" ? true : false}
                                        onChange={(e, value) => onExtendedDataChange(vehicle_detail_extended_type.registration_present, value, true)}
                                    />}
                                    label="Has Physical Registration" />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={editedAsset.registrationExpired ?? false}
                                        onChange={(e, value) => onChange("registrationExpired", value)}
                                    />}
                                    label="Expired" />
                            </div>
                        </Grid>
                    </Grid>
                    {editedAsset.hasRegistration && <><Grid item xs={2}>
                        <div className={classes.input}>
                            <CustomInput
                                id="regDateYear"
                                label="Year"
                                value={editedAsset.registrationYear}
                                elementType="dropdown"
                                onChange={val => onChange("registrationYear", val)}
                                values={[{ name: '', id: null }, ...FormatService.getYears(30)?.map(y => ({ id: y, name: y }))]}
                                valid={!mandatoryFields?.['registrationYear']}
                                touched={true}
                            />
                        </div>
                    </Grid>
                        <Grid item xs={2}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="regDateMonth"
                                    label="Month"
                                    value={editedAsset.registrationMonth}
                                    elementType="dropdown"
                                    onChange={val => onChange("registrationMonth", val)}
                                    values={[{ name: '', id: null }, ...FormatService.getMonths()]}
                                />
                            </div>
                        </Grid>
                    </>
                    }
                </Grid>

                <EditExtendedInfo vehicleDetails={vehicleDetails} extendedInfo={newVehicleDetailsExtended} onChangeField={onExtendedDataChange} />
                {/* {
                    <Paper className={classes.fieldGroup}>
                        <Grid container xs={12}>
                            <Grid xs={12} className={classes.groupTitle}>
                                Vehicle Detail Extended
                            </Grid>
                            
                             <Grid xs={12} className={classes.groupTitleForm}>
                                {newVehicleDetailsExtended && _.sortBy(newVehicleDetailsExtended, 'vehicleDetailExtendedTypeId').map(entity =>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={entity.value === "Yes" ? true : false}
                                                onChange={(e, value) => onConfigChange(entity.vehicleDetailExtendedTypeId, value)}
                                            />}
                                            label={entity.label} />
                                    </Grid>
                                )}

                            </Grid> 
                        </Grid>
                    </Paper>
                } */}
            </Grid>
        );
    }

    return (
        <Paper className={classes.formContainer}>
            {error && <div className={classes.error}>There was an error saving the asset</div>}
            <form className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                {form}
            </form>
            <div className={`${classes.btnContainer}`}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => setShowPhotos(!showPhotos)} startIcon={showPhotos ? <List /> : <Image />}>{showPhotos ? "Details" : "Photos"}</Button>
                </div>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving || !isValid()}
                        className={classes.button}
                        color='primary'
                        variant="contained"
                    >
                        {isSaving ? "Saving..." : "Finish"}
                    </Button>
                </div>
            </div>
        </Paper>
    );
};

export default EditAssetPopup;
