import { sumBy } from "lodash";
import { billingDashboardStyle } from "../style";
import CountGraph from "./CountGraph";

const TotalCost = ({ data }) => {

    const classes = billingDashboardStyle();
    const totalCost = (sumBy(data, item => item.totalCost) ?? 0).toFixed(2);
    return (
        <CountGraph title='Total Cost' count={totalCost} />
    )
}

export default TotalCost;