import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Grid } from '@mui/material';
import { useState } from 'react';
import ConfirmationPopup from '../../../../components/ConfirmationPopup';
import NotificationProvider from '../../../../components/NotificationProvider';
import { useGetLotsQuery } from '../../../lots/lotSlice';
import { usePostLoadMutation } from '../../loadsSlice';
import { bulkPostGroupsStyle } from './BulkPostGroupsStyle';

function BulkPostGroups({ groups, btnStyle = 'contained' }) {

    const classes = bulkPostGroupsStyle();
    let [postLoad, { isLoading: isLoadingDispatchLoad, isError, isSuccess }] = usePostLoadMutation();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [createState, setCreateState] = useState('1');

    const openDialog = async () => {
        setOpen(true);
    }
    const closeDialog = async () => {
        setOpen(false);
    }

    const dispatch = async () => {
        setOpenPopup(true)
    }

    const loads = createState == '1' ? groups?.flatMap(g => g.loads?.filter(l => !l.lmId && !l.isComplete)) :
        groups?.flatMap(g => g.loads?.filter(l => !l.lmId && l.readyForPosting && !l.isComplete));

    const onConfirm = async () => {
        let result = await postLoad([{
            loadIds: loads?.map(l => l.id),
            groupIds: loads?.map(l => l.groupId),
        }]);
        if (result?.error) {
            NotificationProvider.error(`Operation failed. ${result?.error?.data?.messages.join(" ")}`)
        } else {
            if (result?.data?.success) {
                NotificationProvider.success(`Successfully posted to LMP`);
            } else {
                NotificationProvider.error(`${result?.data?.messages?.join('; ')} `);
            }
        }
        setOpenPopup(false);
        setOpen(false);
    }

    return (
        <div>
            <Button
                variant={btnStyle}
                color="primary"
                onClick={openDialog}
            >
                Post Loads to LoadMate
            </Button>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Post Loads to LoadMate</DialogTitle>
                <DialogContent>
                    <Grid item>
                        <div>
                            <FormControl>
                                <RadioGroup
                                    row
                                    name="post-load-radio-group"
                                    value={createState}
                                    onChange={(e) => {
                                        setCreateState(e.target.value)
                                    }}
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="Post All" />
                                    <FormControlLabel value="2" control={<Radio />} label="Post Next Loads" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            {!!loads?.length ?
                                <b>
                                    {loads?.map(load => <div>{`${lots?.find(l => l.id == load?.pickupLocationId)?.name} -> ${lots?.find(l => l.id == load?.deliveryLocationId)?.name} `}</div>)}
                                </b>
                                : <div>There are no load legs ready for posting in the selected groups</div>}
                        </div>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={dispatch}
                        disabled={!loads?.length}
                    >
                        Submit
                    </Button>
                </DialogActions>
                <ConfirmationPopup
                    onCancel={() => setOpenPopup(false)}
                    onConfirm={onConfirm}
                    open={openPopup}
                    loading={isLoadingDispatchLoad}
                >
                    <div>{`Are you sure you want to submit the load`}</div>
                </ConfirmationPopup>
            </Dialog>
        </div>
    );
}

export default BulkPostGroups;
