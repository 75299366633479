import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const getCacheKey = (args) => {
    return `${args.formType ?? 'form_list'}_${args.clientId}`
}

const convertResponse = (form) => {
    return {
        ...form,
        sections: form.sections?.map(section => ({
            ...section,
            questions: section.questions?.map(q => ({
                ...q,
                values: q.values ? JSON.parse(q.values) : undefined
            })) ?? []
        })) ?? []
    }
}

const apiPrefix = 'formBuilder';
export const formBuilderApi = baseApi.injectEndpoints({
    reducerPath: 'formBuilderApi',
    tagTypes: [apiTags.FORM_BUILDER],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getForm: builder.query({
            query: ({ formType, clientId }) => `${apiPrefix}?formType=${formType}`,
            providesTags: (result, error, arg) => [{ type: apiTags.FORM_BUILDER, id: getCacheKey(arg) }],
        }),
        createForm: builder.mutation({
            query: ({ id, name, clientId, formType, formStatus, questions, sections }) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: {
                    id, 
                    name,
                    clientId,
                    formType,
                    formStatus,
                    questions,
                    sections
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: apiTags.FORM_BUILDER, id: getCacheKey(arg) },
            ],
        }),
        getFormsForClient: builder.query({
            query: (clientId) => `${apiPrefix}/getForClient?${clientId ? `clientId=${clientId}` : ''}`,
            providesTags: (result, error, arg) => [{ type: apiTags.FORM_BUILDER, id: getCacheKey(arg) }],
        }),
        getFormVersionsByTypeForClient: builder.query({
            query: ({ formType, clientId }) => `${apiPrefix}/getVersions?formType=${formType}&${clientId ? `clientId=${clientId}` : ''}`,
            providesTags: (result, error, arg) => [{ type: apiTags.FORM_BUILDER, id: `${getCacheKey(arg)}` }],
        }),
        getFormByTypeForClient: builder.query({
            query: ({ formType, clientId, version }) => `${apiPrefix}/getByType?formType=${formType}&${clientId ? `clientId=${clientId}` : ''}&${version ? `version=${version}` : ''}`,
            providesTags: (result, error, arg) => [{ type: apiTags.FORM_BUILDER, id: getCacheKey(arg) }],
            transformResponse: (response) => convertResponse(response)
        }),
    }),
});

export const {
    useGetFormQuery,
    useGetFormsForClientQuery,
    useCreateFormMutation,
    useGetFormByTypeForClientQuery,
    useGetFormVersionsByTypeForClientQuery
} = formBuilderApi;
