import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';


const SendQRCodeTo = ({ open, handleClose, accessToEdit, setAccessToEdit, onConfirm }) => {

    const onFinish = async () => {
        handleClose();
        await onConfirm();
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby='dialog-title'>
                <DialogTitle id={'dialog-title'} >Send QR code to:</DialogTitle>
                <DialogContent>
                    {accessToEdit?.pocEmail &&
                        <div>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={accessToEdit?.sendEmail}
                                    onChange={(e, value) => setAccessToEdit({ ...accessToEdit, "sendEmail": value })}
                                />}
                                label="Email"
                            />
                        </div>
                    }
                    {accessToEdit?.pocPhone &&
                        <div>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={accessToEdit?.sendSMS}
                                    onChange={(e, value) => setAccessToEdit({ ...accessToEdit, "sendSMS": value })}
                                />}
                                label="SMS"
                            />
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onFinish} color='primary'>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SendQRCodeTo;