import { makeStyles } from "@material-ui/core";

export const billingDashboardStyle = makeStyles((theme) => ({
    graphHeader: {
        color: theme.palette.primary.dark,
    },
    graphContainer: {
        padding: '1em 2em',
    },
    count: {
        color: theme.palette.primary.main,
        fontSize: '3em',
        fontWeight: 700
    },
    text: {
        color: theme.palette.primary.dark,
        fontSize: '1.2em',
        fontWeight: 500,
        marginTop: 0
    },
    graphTootltip: {
        background: 'white',
        padding: '0.5em 1em',
        borderRadius: '5px',
        boxShadow: '1px 2px 7px #ddd'
    }
}));