import { Grid } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import EnterCommentDialog from "features/IssuesTracker/EnterCommentDialog/EnterCommentDialog";
import { useUpdateIssueTrackerMutation } from "features/IssuesTracker/issuesTrackerSlice";
import { useEffect, useMemo, useState } from "react";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";
import KanbanCardTitle from './KanbanCardTitle';
import KanbanTableColumn from './KanbanTableColumn';
import { KanbanTableStyle } from "./KanbanTableStyle";
import { DndContext, DragOverlay, useDroppable } from "@dnd-kit/core";
import KanbanCard from "./KanbanCard";
import { arrayToObject } from "utils/object-util";
import usePrompt from "hooks/usePrompt";
import { issueTrackerStatuses } from "features/IssuesTracker/issueTrackerConsts";

const KanbanTableContent = ({ columns, data, setIssuesTrackerToEdit, setColumnPref }) => {
    const classes = KanbanTableStyle();

    const [updateIssueTracker, { isLoading: isUpdating }] = useUpdateIssueTrackerMutation();

    const [openCommentDialog, setOpenCommentDialog] = useState(false);
    const [issueTrackerToEdit, setIssueTrackerToEdit] = useState();
    const [myColumns, setMyColumns] = useState(columns);
    const [activeId, setActiveId] = useState();
    const { triggerPrompt } = usePrompt();

    useEffect(() => {
        setMyColumns(columns);
    }, [columns]);

    const dataById = useMemo(() => arrayToObject(data), [data])

    const dataLookup = data?.reduce((map, obj) => {
        const status = obj.status;
        if (!map[status]) {
            map[status] = [];
        }
        map[status].push(obj);
        return map;
    }, {});

    const columnLookup = arrayToObject(myColumns);

    const handleTitleChange = (newTitle, colId) => {
        let changedColumns = [...(myColumns ?? [])];
        let columnIndex = changedColumns.findIndex(column => column.id === colId);
        let changedColumn = { ...changedColumns[columnIndex] };
        changedColumn.name = newTitle;
        changedColumns[columnIndex] = changedColumn;
        setMyColumns(changedColumns);
        setColumnPref(changedColumns);
    };

    // const handleDragAndDrop = (results) => {
    //     const { source, destination, type } = results;
    //     if (!destination) return;
    //     if (source.droppableId === destination.droppableId)
    //         return;
    //     const issueId = results?.draggableId;
    //     const destinationStatusId = results?.destination?.droppableId;
    //     let issueToEdit = dataById?.[issueId];
    //     issueToEdit.status = destinationStatusId;
    //     setIssueTrackerToEdit(issueToEdit);
    //     setOpenCommentDialog(true);
    // }

    const saveChanges = async (issueToSave) => {
        let result;
        issueToSave = { ...issueToSave, linkedAssetIds: issueToSave?.linkedAssets?.map(a => a.id), linkedWorkOrderIds: (issueToSave?.linkedAssets.length != 1) ? [] : issueToSave?.linkedWorkOrders?.map(w => w.id) }
        if (issueToSave.id) {
            result = await updateIssueTracker(issueToSave);
        }
        if (result && !result.error) {
            NotificationProvider.success("Status was updated successfully");
        } else {
            NotificationProvider.error("Failed to update status of the ticket. Try again or contact support.");
        }
        setOpenCommentDialog(false);
    }

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        setActiveId(null);
        const sourceColumn = event?.active?.data?.current?.originalColumnId;
        const targetColumn = +event?.over?.id;
        if (!targetColumn) return;
        if (sourceColumn === targetColumn)
            return;
        const destinationStatusId = targetColumn;
        const issueId = +event?.active?.id;
        let issueToEdit = dataById[issueId];
        issueToEdit.status = destinationStatusId;

        triggerPrompt({
            title: "Update Status",
            content: `Are you sure you want to move the ticket to status ${columnLookup?.[targetColumn]?.name}`,
            onConfirm: async () => {


                let issueToEdit = dataById[issueId];
                issueToEdit.status = destinationStatusId;
                setIssueTrackerToEdit(issueToEdit);
                setOpenCommentDialog(true);
            },
            onCancel: () => {
                issueToEdit.status = sourceColumn;
            }
        });

    }

    return (
        <>
            {/* <DragDropContext onDragEnd={handleDragAndDrop}> */}
            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <div className={classes.tableContentWrapper}>
                    <Grid container spacing={1} justifyContent="space-between" wrap="nowrap" columns={myColumns?.length}>
                        {myColumns?.sort((a, b) => a.id - b.id)?.map((col, index) =>
                            <>
                                <Droppable
                                    key={col?.id}
                                    droppableId={col?.id?.toString()}
                                >
                                    {/* {(provided, snapshot) => ( */}
                                    <div container spacing={1} direction="column" xs={Math.ceil(12 / (myColumns?.length + 1))}
                                        className={classes.droppableWrapper}
                                        key={index}
                                    //{...provided.droppableProps}
                                    //ref={provided.innerRef}
                                    >
                                        <Grid className={classes.cardTitleWrapper}>
                                            <KanbanCardTitle colId={col?.id} title={col?.name} setTitle={handleTitleChange} />
                                        </Grid>
                                        <KanbanTableColumn
                                            columnId={col?.id}
                                            columns={myColumns}
                                            columnData={dataLookup[col?.id]}
                                            setIssuesTrackerToEdit={setIssuesTrackerToEdit}
                                        />
                                        {/* {provided.placeholder} */}
                                    </div>
                                    {/* )} */}
                                </Droppable>
                            </>
                        )}
                    </Grid>
                </div>
                <DragOverlay>
                    {activeId ? <div style={{ boxShadow: '2px 3px 5px #efefef' }}><KanbanCard issue={dataById?.[activeId]} /> </div> : <></>}
                </DragOverlay>
                <EnterCommentDialog
                    onCancel={() => setOpenCommentDialog(false)}
                    open={openCommentDialog}
                    onSave={async (note) => {
                        setOpenCommentDialog(false);
                        await saveChanges({ ...issueTrackerToEdit, note: note.content, status: parseInt(issueTrackerToEdit?.status) });
                    }}
                />
            </DndContext>
            {/* </DragDropContext> */}
        </>
    );
}


function Droppable({ droppableId, children }) {
    const classes = KanbanTableStyle();
    const { setNodeRef, isOver } = useDroppable({
        id: droppableId,
        data: {
            id: droppableId
        }
    });

    const style = {
        borderColor: isOver ? "#ddd" : 'transparent',
        //backgroundColor: isOver ? "#ddd" : 'transparent',
    };

    return (
        <div ref={setNodeRef} style={style} className={classes.droppableArea}>
            {/* <span>-</span> */}
            {children}
        </div>
    );
}

export default KanbanTableContent;