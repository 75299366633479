import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput';
import DatePicker from 'components/DatePicker';
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from 'components/NotificationProvider';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { selectIsOwner } from "features/user/userSlice";
import { useGetUserLotRelationsQuery } from 'features/users/usersSlice';
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { meetingPlaceEnum } from './OneOnOneEnums';
import { useAddOneOnOneMutation, useEditOneOnOneMutation, useGetWorkersByLotQuery } from './OneOnOneSlice';
import { OneOnOneStyle } from "./OneOnOneStyle";

const OneOnOneForm = ({ meetingToEdit }) => {

    const classes = OneOnOneStyle();
    const isOwner = useSelector(selectIsOwner);

    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsQuery();
    let { data: lotWorkers, error: workersError, isLoading: isLoadingWorkers } = useGetWorkersByLotQuery();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    userLotRelations = userLotRelations || [];
    lots = lots || [];
    lotWorkers = lotWorkers || [];

    const filteredLots = lots?.filter(l => (isOwner && !userLotRelations?.length) || userLotRelations?.filter(r => r.relationType === 2)?.map(r => r.lotId)?.includes(l.id));

    const [addOneOnOne] = useAddOneOnOneMutation();
    const [editOneOnOne] = useEditOneOnOneMutation();
    const [openDialog, setOpenDialog] = useState(true);
    const [meeting, setMeeting] = useState(meetingToEdit || null);
    const [showMandatoryError, setShowMandatoryError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const history = useHistory();

    lotWorkers = lotWorkers.filter(l => l.lotId == meeting?.lotId);

    lotWorkers = lotWorkers?.map(w => ({
        ...w,
        name: w.firstName + ' ' + w.lastName
    }));

    useEffect(() => {
        setMeeting(meetingToEdit || null);
        setOpenDialog(true);
    }, [meetingToEdit])

    const onChange = (key, val) => {
        setMeeting({ ...meeting, [key]: val });
    };

    const isValid = () => {
        return !!(meeting?.meetingDate &&
            meeting?.lotId &&
            meeting?.employeeId &&
            meeting?.meetingPlace &&
            meeting?.successes &&
            meeting?.opportunities &&
            meeting?.nextSteps
        );
    }

    const handleSave = async () => {
        const validate = isValid();
        if (!validate) {
            setShowMandatoryError(true);
            return;
        }
        setShowMandatoryError(false);
        setIsSaving(true);

        let res = null;

        if (meeting?.id) {
            res = await editOneOnOne(meeting);
        }
        else
            res = await addOneOnOne(meeting);

        setIsSaving(false);
        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        history.push(`oneonones`);
        setOpenDialog(false);
        setMeeting(null);
    }

    const handleClose = () => {
        setOpenDialog(false);
        setMeeting(null);
        setShowMandatoryError(false);
        history.push(`oneonones`);

    }

    const isLoading = () => isLoadingLots || isLoadingRelations || isLoadingWorkers;

    let form = (
        <Grid container>
            <Grid item xs={12}>

                <div className={classes.input}>
                    <DatePicker
                        id="meetingDate"
                        title="Date of meeting"
                        value={(meeting?.meetingDate) ? moment(meeting?.meetingDate).format("YYYY-MM-DD") : null}
                        onSelect={val => onChange("meetingDate", val)}
                        required
                        touched
                        valid={!(showMandatoryError && !meeting?.meetingDate)}
                        maxDate={moment().format("YYYY-MM-DD")}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="loId"
                        label="Lot"
                        value={meeting?.lotId}
                        elementType="dropdown"
                        onChange={val => onChange("lotId", +val)}
                        values={filteredLots}
                        showEmpty
                        required
                        touched
                        valid={!(showMandatoryError && !meeting?.lotId)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="employee"
                        label="Employee Name"
                        value={meeting?.employeeId}
                        elementType="dropdown"
                        onChange={val => onChange("employeeId", +val)}
                        values={lotWorkers}
                        showEmpty
                        required
                        touched
                        valid={!(showMandatoryError && !meeting?.employeeId)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="meetingPlace"
                        label="Place Of Meeting"
                        value={meeting?.meetingPlace}
                        elementType="dropdown"
                        onChange={val => onChange("meetingPlace", +val)}
                        values={meetingPlaceEnum}
                        showEmpty
                        required
                        touched
                        valid={!(showMandatoryError && !meeting?.meetingPlace)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="goals"
                        label="Goals"
                        value={meeting?.goals}
                        elementType="textarea"
                        onChange={val => onChange("goals", val)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="successes"
                        label="Successes"
                        value={meeting?.successes}
                        elementType="textarea"
                        onChange={val => onChange("successes", val)}
                        required
                        touched
                        valid={!(showMandatoryError && !meeting?.successes)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="opportunities"
                        label="Opportunities"
                        value={meeting?.opportunities}
                        elementType="textarea"
                        onChange={val => onChange("opportunities", val)}
                        valid={!(showMandatoryError && !meeting?.opportunities)}
                        required
                        touched
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="nextSteps"
                        label="Timeline and next steps"
                        value={meeting?.nextSteps}
                        elementType="textarea"
                        onChange={val => onChange("nextSteps", val)}
                        required
                        touched
                        valid={!(showMandatoryError && !meeting?.nextSteps)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="changes"
                        label="Is there anything the employee would like to change on the lot or at work?"
                        value={meeting?.changes}
                        elementType="textarea"
                        onChange={val => onChange("changes", val)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="notes"
                        label="Notes"
                        value={meeting?.notes}
                        elementType="textarea"
                        onChange={val => onChange("notes", val)}
                    />
                </div>

                <div className={classes.input}>
                    <FormControlLabel
                        control={<Checkbox
                            title="Does the employee know who to contact in an emergency?"
                            checked={meeting?.contactEmergency}
                            onChange={(e, val) => onChange("contactEmergency", val)}
                        />}
                        label="Does the employee know who to contact in an emergency?" />
                </div>

                <div className={classes.input}>
                    <FormControlLabel
                        control={<Checkbox
                            title="Does the employee know how to contact HR?"
                            checked={meeting?.contactHr}
                            onChange={(e, val) => onChange("contactHr", val)}
                        />}
                        label="Does the employee know how to contact HR?" />

                </div>

            </Grid>
        </Grid>
    );

    return (
        <>
            {
                isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                    <Paper variant='outlined' className={classes.formContainer}>
                        <Dialog open={openDialog} onClose={() => handleClose()} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
                            <DialogTitle id="form-dialog-title">{'One On One'}</DialogTitle>
                            <DialogContent>
                                <form id="createOneOnOneForm" className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                                    {form}
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose()} color="primary" disabled={isSaving}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleSave}
                                    color="primary"
                                    variant="contained"
                                    disabled={isSaving}
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
            }
        </>
    );
};

export default OneOnOneForm;
