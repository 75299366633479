import { Button, Grid, Paper } from "@material-ui/core";
import { getErrorsFromResponse } from "app/errorHandling";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import ContractHeader from "features/clients/contracts/ContractHeader/ContractHeader";
import { IconDollar } from "icons";
import { useState } from "react";
import { useUpdateMechanicalsConfigMutation } from "../../mechanicalsSlice";
import { lotMechanicalConfigStyles } from "./style";

const LotParams = ({ clientId, lotId, lotConfig }) => {

    const classes = lotMechanicalConfigStyles();
    let [updateConfig, { isLoading, isError, isSuccess }] = useUpdateMechanicalsConfigMutation();

    let [changedConfig, setChangedConfig] = useState(lotConfig);
    let [changed, setChanged] = useState(false);

    const onChange = (field, val) => {
        setChangedConfig({ ...changedConfig, [field]: val })
        setChanged(true)
    }

    const onSave = async () => {
        const configToSave = { ...lotConfig, ...changedConfig };
        const res = await updateConfig({ clientId, lotId, ...configToSave });
        if (res.error) {
            NotificationProvider.error(getErrorsFromResponse(res, 'Failed to save'));
        } else {
            NotificationProvider.success('Saved successfully');
            setChanged(false);
        }
    }

    const lotParams = [
        {
            key: 'MechanicalsPriceCap',
            component: <CustomInput
                icon={<IconDollar />}
                label={'Price Cap'}
                elementType={'input'}
                numberOnly
                value={changedConfig?.mechanicalsPriceCap}
                onChange={val => onChange('mechanicalsPriceCap', val)}
            />
        },
        {
            key: 'mechanicalsPriceCapType',
            component: <CustomInput
                label={'Type'}
                elementType={'dropdown'}
                values={[
                    {
                        id: 1,
                        name: 'Per Asset'
                    },
                    {
                        id: 2,
                        name: 'For Lot'
                    }
                ]}
                showEmpty={true}
                value={changedConfig?.mechanicalsPriceCapType}
                onChange={val => onChange('mechanicalsPriceCapType', +val)}
            />
        },
        {
            key: 'mechanicalsPriceCapTimeSpan',
            component: <CustomInput
                label={'Time span'}
                elementType={'dropdown'}
                values={[
                    {
                        id: 1,
                        name: 'Monthly'
                    },
                    {
                        id: 2,
                        name: 'Annually'
                    },
                    {
                        id: 3,
                        name: 'Vehicle lifetime'
                    }
                ]}
                showEmpty={true}
                value={changedConfig?.mechanicalsPriceCapTimeSpan}
                onChange={val => onChange('mechanicalsPriceCapTimeSpan', +val)}
            />
        }
    ];

    return (
        <Paper className={classes.paramWrapper}>
            <ContractHeader
                label={'Pricing'}
            />
            <Grid container direction='column' spacing={2}>
                {lotParams?.map(param =>
                    <Grid item key={param.key}>
                        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                            {param.component}
                        </Authorize>
                    </Grid>)}
                <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.SAVE_CONFIG}>
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                        {changed && <Button
                            onClick={onSave}
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Save'}
                        </Button>}
                    </Grid>
                </Authorize>
            </Grid>
        </Paper>
    )
}

export default LotParams;
