import DatePicker from "components/DatePicker"
import moment from "moment"

export const DateComponent = ({ value, onChange }) => {
    return (
        <DatePicker 
            value={value ? moment(JSON.parse(value ?? "")).format("YYYY-MM-DD"): null}
            onSelect={(val) => onChange(JSON.stringify(val ?? ""), "values")}
        />
    )
}