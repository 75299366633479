import { Grid } from "@material-ui/core"
import { loadGroupDetailsStyle } from "./LoadGroupDetailsStyle"

export const CardTitle = ({ title, icon }) => {
    const classes = loadGroupDetailsStyle()
    return (
        <Grid container className={classes.cardTitleWrapper}>
            {!!icon && <Grid className={classes.cardIcon}>
                {icon}
            </Grid>}
            <Grid className={classes.cardTitle}>
                {title}
            </Grid>
        </Grid>
    )
}