import { baseQuery } from "../../app/baseQuery";
import { baseApi } from 'app/baseApi';

export const vendorApi = baseApi.injectEndpoints({
    reducerPath: 'vendorApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getVendors: builder.query({
            query: () => `vendor`,
        }),
        getVendorsByLotId: builder.query({
            query: (lotId) => `vendor/getVendorsByLotId?lotId=${lotId}`,
        }),
        getVendorsLotsByLotId: builder.query({
            query: (lotId) => `vendor/getVendorsLotByLotId?lotId=${lotId}`,
        }),
    }),
});

export const { useGetVendorsQuery, useGetVendorsByLotIdQuery,  useGetVendorsLotsByLotIdQuery} = vendorApi;
