import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const userPermissionProfiles = {
    VIEW_CLIENTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
    },
    VIEW_ALL_CLIENTS_LOTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN],
    },
    VIEW_LIMITED_CLIENTS_LOTS: {
        [modes.EDIT]: [roles.ACCOUNT_MANAGER],
    },
    VIEW_USERS: {
        [modes.EDIT]: [roles.OWNER],
    },    
    VIEW_USERS_PAGE: {
        [modes.EDIT_PERMISSION]: [permissions.USER.ACCESS]
    },
    CREATE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER.CREATE,permissions.USER.CREATE_USERS,permissions.USER_MANAGEMENT.CREATE]
    },
    EDIT_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER.EDIT_USERS]
    },
    DELETE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER.DELETE_USERS]
    },
    USER_DETAILS: {
        [modes.EDIT_PERMISSION]: [permissions.USER.USER_DETAILS]
    },
    MANAGE_PERMISSIONS: {
        [modes.EDIT_PERMISSION]: [permissions.USER.MANAGE_PERMISSIONS],
    },
    USER_RESTORE_PASSWORD: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN],
    },
    ARCHIVE_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER.ARCHIVE_PERMISSIONS],
    },
    BLACKLIST_USER: {
        [modes.EDIT_PERMISSION]: [permissions.USER.BLACKLIST_USERS],
    },
    TABLE:{
        [modes.EDIT_PERMISSION]: [permissions.USER.VIEW_ACCESS_TABLE]
    },
    SEE_ROLE_DEFINITIONS:{
        [modes.EDIT_PERMISSION]: [permissions.USER.SEE_ROLE_DEFINITIONS]
    },
    SEE_USER_DASH:{
        [modes.EDIT_PERMISSION]: [permissions.USER.SEE_USER_DASH]
    }
}