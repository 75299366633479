import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { IconVehicle } from 'icons';
import GenerateAccessCode from 'features/access/GenerateAccessCode/GenerateAccessCode';
import { useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListsStyle } from '../ListsStyle';
import AccessCodeList from 'features/access/AccessCodeList/AccessCodeList';


const VehicleListItem = ({ access, vehicle }) => {

    const classes = ListsStyle()

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconVehicle className={classes.listItemIcon} alt="Asset ID" />
            <div className={classes.name}>{`${vehicle?.descriptor ? vehicle?.descriptor : vehicle?.assetId}`}</div>
            <IconButton className={classes.moreVertIcon}>
                <MoreVertIcon onClick={handleClick} />
            </IconButton>
            <Menu
                id="vehicle-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'vehicle-menu',
                }}
            >
                <MenuItem>
                    <GenerateAccessCode accessGroup={access} vehicleId={vehicle?.id} btnStyle="none" />
                </MenuItem>
                <MenuItem>
                    <AccessCodeList accessGroup={access} vehicleId={vehicle?.id} btnStyle="none" />
                </MenuItem>
            </Menu>
        </>
    );
}

export default VehicleListItem



