import { Collapse, Paper } from "@material-ui/core"
import NoteList from "features/notes/NoteList"
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import { NoteEntityTypes } from "features/notes/notesConsts";
import { useState } from "react";
import {permissionProfiles} from "../../../components/Authorize/permissionProfiles";
import Authorize from "../../../components/Authorize";
import {isAuthorized} from "../../../components/Authorize/authorizeUtil";

export const VehicleNotes = ({ vehicleDetails }) => {
    const classes = vehicleDetailsPageStyle();
    const readOnly = !isAuthorized(permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_ASSET) 
    return (
        <>
            <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_VIEW_NOTE_ASSET}>
                <Paper className={classes.card}>
                    <NoteList entityId={vehicleDetails?.id}                       
                              readOnly={readOnly}                              
                              entityType={NoteEntityTypes.VEHICLE} />
                </Paper>
            </Authorize>
        </>
        
    )
}