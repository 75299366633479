import CustomInput from "components/CustomInput"

export const TextComponent = ({ value, onChange, label }) => {
    return (
        <CustomInput 
            elementType='input'
            value={value ? JSON.parse(value) : null}
            onChange={(val) => onChange(JSON.stringify(val), "values")}
            label={label}
        />
    )
}