import { makeStyles } from "@material-ui/core"

export const VehicleAccessStyle = makeStyles(theme => ({
    card: {
        margin: '1rem 0',
        padding: '2rem',
    },
    title: {
        fontSize: '1.5em',
        fontWeight: 500
    },
    commentIcon: {
        marginRight: '0.5em'
    },
    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5em',
        alignItems: 'center',
        
    },
    access: {
        padding: '1em 1rem',
        borderBottom: '1.5px solid #e1e3e4',
        '&:last-of-type': {
            border: 'none'
        },
    },
    titleWrapper: {
       marginBottom: '2rem' 
    },
    listItem: {
        padding: '1rem',
        margin: '0.5rem',
        '&:hover': {
            background: theme.palette.neutral.light,
        },
        cursor: 'pointer',
    },
    listItemSelected: {
        background: theme.palette.neutral.light,
    }
}));
