import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { CustomReportStyles } from "../style";
import { IconRedo, IconUndo } from "icons";

const ReportActions = ({
    onSave,
    saving,
    disabled,
    redo,
    undo,
    redoEnabled,
    undoEnabled,
}) => {

    const classes = CustomReportStyles();

    return (
        <Grid container className={classes.formActions} >
            <Tooltip title={`Undo`}>
                <IconButton disabled={!undoEnabled} onClick={() => undo()}>
                    <IconUndo />
                </IconButton>
            </Tooltip>
            <Tooltip title={`Redo`}>
                <IconButton disabled={!redoEnabled} onClick={() => redo()}>
                    <IconRedo />
                </IconButton>
            </Tooltip>
            <div className={classes.saveFormBtn} >
                <Button
                    disabled={saving || disabled}
                    variant="outlined"
                    onClick={onSave}
                >
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </div>
        </Grid >
    );
}
export default ReportActions; 