import * as React from "react";

function SvgIconImage(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none" {...props}>
            <path d="M17.4999 26.0417H10.4999C7.38981 26.0417 5.28943 25.3754 3.95701 24.0429C2.6246 22.7105 1.95825 20.6101 1.95825 17.5V10.5C1.95825 7.38994 2.6246 5.28955 3.95701 3.95714C5.28943 2.62472 7.38981 1.95837 10.4999 1.95837H17.4999C20.61 1.95837 22.7104 2.62472 24.0428 3.95714C25.3752 5.28955 26.0416 7.38994 26.0416 10.5V17.5C26.0416 20.6101 25.3752 22.7105 24.0428 24.0429C22.7104 25.3754 20.61 26.0417 17.4999 26.0417ZM10.4999 2.70837C7.77547 2.70837 5.77966 3.18778 4.48366 4.48378C3.18766 5.77978 2.70825 7.7756 2.70825 10.5V17.5C2.70825 20.2245 3.18766 22.2203 4.48366 23.5163C5.77966 24.8123 7.77548 25.2917 10.4999 25.2917H17.4999C20.2244 25.2917 22.2202 24.8123 23.5162 23.5163C24.8122 22.2203 25.2916 20.2245 25.2916 17.5V10.5C25.2916 7.7756 24.8122 5.77978 23.5162 4.48378C22.2202 3.18778 20.2244 2.70837 17.4999 2.70837H10.4999Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M10.5001 12.0417C9.00289 12.0417 7.79175 10.8305 7.79175 9.33333C7.79175 7.83614 9.00289 6.625 10.5001 6.625C11.9973 6.625 13.2084 7.83614 13.2084 9.33333C13.2084 10.8305 11.9973 12.0417 10.5001 12.0417ZM10.5001 7.375C9.41894 7.375 8.54175 8.25219 8.54175 9.33333C8.54175 10.4145 9.41894 11.2917 10.5001 11.2917C11.5812 11.2917 12.4584 10.4145 12.4584 9.33333C12.4584 8.25219 11.5812 7.375 10.5001 7.375Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M2.80776 22.321L2.80775 22.321C2.87448 22.4211 2.99513 22.4833 3.11506 22.4833C3.18508 22.4833 3.26611 22.4629 3.33617 22.4216L9.07607 18.5678C9.85504 18.0349 10.9887 18.0919 11.6984 18.6936L11.7052 18.6993L11.7051 18.6994L12.0869 19.0349C13.1495 19.936 14.8202 19.9332 15.8677 19.0339L15.8678 19.0339L20.7207 14.8692L2.80776 22.321ZM2.80776 22.321L2.80587 22.3182C2.69308 22.1514 2.7369 21.9175 2.91131 21.8028L2.91133 21.8029L2.91543 21.8001L8.6671 17.9384L8.66839 17.9376C9.74058 17.2128 11.2303 17.3021 12.2036 18.1237M2.80776 22.321L12.2036 18.1237M12.2036 18.1237L12.585 18.4589L12.585 18.4589L12.589 18.4624C12.9852 18.8031 13.5004 18.9596 13.9928 18.9596C14.4839 18.9596 14.9975 18.8038 15.3893 18.4611L20.2407 14.2978L20.2407 14.2977C21.2889 13.3979 22.961 13.3956 24.0235 14.2984C24.0238 14.2987 24.0241 14.2989 24.0245 14.2992L25.9217 15.9287C25.922 15.929 25.9224 15.9293 25.9227 15.9296C26.0776 16.0655 26.0986 16.2998 25.9638 16.4629C25.8272 16.6166 25.593 16.6365 25.4305 16.5007C25.4301 16.5003 25.4296 16.4999 25.4292 16.4995L23.5311 14.8692C23.531 14.8692 23.5309 14.8691 23.5308 14.869C22.7602 14.2065 21.4918 14.2064 20.7211 14.8689L12.2036 18.1237Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconImage;
