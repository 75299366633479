import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    wfContainer: {
        minHeight: '200px'
    },
    formContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: theme.palette.common.white,
        margin: "16px 20px"
    },
    btnsWrapper: {
        marginLeft: 'auto',
    },
    btn: {
        marginLeft: '0.5rem',
    },
    configWrapper: {
        borderRadius: '5px',
        margin: 16,
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    slaTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 0.5em',
    },
    listItem: {
        padding: '0.5em 0',
        //margin: '0.5em 0',
        backgroundColor: '#f3f3f3',
        border: '1px solid #d0d5dd'
    },
    stepName: {
        color: theme.palette.primary.dark,
        fontWeight: '500',
        fontSize: '0.9em'
    },
    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '2rem'
    },
    requiredLabel: {
        color: 'red',
        fontSize: '0.9em',
    },
    description: {
        padding: '0 0 0.5rem 4rem',
        fontSize: '0.9em',
        fontWeight: 'lighter'
    },
    groupNames: {
        paddingLeft: '4rem',
        padding: '0 0 0.3rem 4rem',
    },
    dropdownStatuses: {
        marginTop: 16,
        position: 'relative',
        width: "30%"
    },
    validationAlert: {
        padding: '0.2rem 0.7rem 0.2rem 0.7rem',
    },
    chip: {
        padding: '0.5rem'
    },
    info: {
        marginLeft: '1.5rem'
    }
}));
