import { makeStyles } from "@material-ui/core";

export const IssueTrackerDateSelectorStyle = makeStyles((theme) => ({
    chip: {
        height: '26px',
        color: '#242A31',
        paddingLeft: '10px'
    },
    dateOrange: {
        borderColor: '#FFB240',
        '& path': {
            fill: '#FFB240',
        }
    },
    dateRed: {
        borderColor: '#FF5A4F',
        '& path': {
            fill: '#FF5A4F',
        }
    },
    dateBlue: {
        borderColor: '#0090FE',
        '& path': {
            fill: '#0090FE',
        }
    },
    dateGreen: {
        borderColor: '#51AD97',
        '& path': {
            fill: '#51AD97',
        }
    },
    overdueIcon: {
        display: 'inline-block',
        '& svg': {
            height: '1em'
        },
        '& path': {
            stroke: '#FF5A4F'
        }
    }
}))