import PageComponent from "components/Page/PageComponent";
import { useGetHistoryQuery } from "./lotAuditSlice";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable/DataTable";
import { useParams, useHistory } from 'react-router-dom';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import FormatService from "services/formatService";
import LoadingSpinner from "components/LoadingSpinner";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const LotAuditHistoryList = () => {

    const {auditId} = useParams();
    const history = useHistory();

    let {data: lotAuditHistory, error: lotAuditHistoryError, isLoading: isLoadingAuditHistory } = useGetHistoryQuery({auditId: auditId});
    lotAuditHistory = lotAuditHistory?.data || [];

    lotAuditHistory = lotAuditHistory?.map(h => ({
        ...h,
        formattedDate: FormatService.formatDateTime(h.createdDate),
    }));

    let historyColumns = [
        { name: "Created Date", key: "formattedDate", selectedByDefault: true },
        { name: "User", key: "createdBy", selectedByDefault: true  },
    ];

    const rowActions = (id) => ([
        {
            icon: <ArrowForwardRoundedIcon />,
            label: "View",
            onClick: () => history.push(`/assets/audit/${auditId}/history/${id}`)
        },
    ]);

    const goBack = () => {
        history.push('/assets/audit')
    };

    return (
        <>
        {isLoadingAuditHistory ?
            <LoadingSpinner loading={isLoadingAuditHistory} /> :
            <PageComponent header={
                <DataListHeader
                    title={(
                        <>
                            <IconButton onClick={() => goBack()} >
                                <ArrowBack />
                            </IconButton>
                            <span>{'Lot Audit History'}</span>
                        </>
                    )}                
            />}>
            <DataTable
                columns={historyColumns}
                rows={lotAuditHistory}
                rowIdentifier='id'
                noItemsMessage='There are no audits to show'
                actions={rowActions}
            />
            </PageComponent>
        }
        </>
    )
}
export default LotAuditHistoryList;