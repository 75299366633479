import { makeStyles } from "@material-ui/core";

export const FormBuilderStyles = makeStyles((theme) => ({
    formBuilderWrapper: {
        position: 'relative',
        paddingBottom: '3em'
    },
    loaderWrapper: {
        background: 'white',
        width: '85%',
        margin: '0 auto',
        padding: '1em',
        marginTop: '2em'
    },
    formBuilderPageHeader: {
        padding: '0.5em 2em'
    },
    headerSection: {
        marginBottom: '1em',
        padding: '1em',
        fontSize: '1.2em'
    },
    formBuilderContainer: {
        width: '85%',
        margin: '0 auto',
    },
    sectionContainer: {
        padding: '1em 2em'
    },
    sectionName: {
        marginBottom: '2em'
    },
    questionsContainer: {
    },
    questionContainer: {
        padding: '0 1em 1em 1em',
    },
    dragHandler: {
        display: 'flex',
        justifyContent: 'center',
        background: '#efefef',
        marginBottom: '1em'
    },
    dragIcon: {
        cursor: 'move',
        '& path': {
            fill: '#ddd'
        },
        '&:hover': {
            '& path': {
                fill: theme.palette.secondary.dark
            },
        }
    },
    dragIconQuestion: {
        color: 'black',
        transform: 'rotate(90deg)',
        cursor: 'move',
        '& path': {
            fill: '#ddd'
        },
        '&:hover': {
            '& path': {
                fill: theme.palette.secondary.dark
            },
        }
    },
    optionsContainer: {
        padding: '0.5em 1.5em'
    },
    optionsContainerTitle: {
        fontSize: '1.1em',
        fontWeight: 500,
        marginBottom: '0.5em'
    },
    formActions: {
        background: 'white',
        borderRadius: '5px',
        padding: '0.5em 1em',
        position: 'fixed',
        bottom: '1em',
        width: 'fit-content',
        left: '50%',
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
        transform: 'translateX(-30%)'
    },
    sectionActions: {
        background: 'white',
        borderRadius: '5px',
        padding: '0.5em 1em',
        bottom: '1em',
        //width: 'fit-content',
        margin: '0 auto',
        borderTop: '1px solid #efefef',
        //boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)'
    },
    questionActions: {
        borderTop: '1px solid #efefef',
        paddingTop: '0.5em'
    },

    questionFlag: {
        borderRight: '1px solid #efefef',
        padding: '0.5em 1em 0.5em 0',
    },
    sectiontTitle: {
        marginBottom: '0.5em',
        fontSize: '1.1em'
    },
    deleteSectionIcon: {
        borderLeft: '1px solid #efefef'
    },
    multiValueOpt: {
        //borderBottom: '1px solid #efefef',
        padding: '5px 0',
        '&:nth-child(odd)': {
            backgroundColor: '#f3f5f6',
            
        }
    },
    saveFormBtn: {
        borderLeft: '1px solid #efefef',
        padding: '0.5em 1em'
    },
    questionType: {
        background: '#E9F5FF',
        padding: '1.2em',
        borderRadius: '5px',
        border: '1px solid lightgrey',
    },
    fieldMapped: {
        background: '#efefef',
        padding: '1.2em',
        borderRadius: '5px',
        border: '1px solid lightgrey',
    },
    statusItem: {
        cursor: 'pointer',
        alignItems: "center",
        justifyContent: "space-between",
    },
    selectItem: {
        padding: '0.5em 1em',
        // fontWeight: '500',
        '&.filled': {
            paddingLeft: '0.5em'
        }
    },
    select: {
        fontSize: '1em',
        padding: 0,
        alignItems: "center",
        justifyContent: "space-between",
        width: '240px',
        borderRadius: 0,
        '& fieldset': {
            border: 'none',
        },
        '& .MuiSelect-icon': {
            fill: 'black',
            width: '20px'
        }
    },
}));