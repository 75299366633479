import { Grid, Paper } from "@material-ui/core";
import PageComponent from "components/Page/PageComponent";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useParams } from "react-router-dom";
import LotSummary from "../LotSummary";
import VirtualLot from "../VirtualLot";
import { lotOverviewStyle } from "./style";

const LotOverview = ({ }) => {
    const classes = lotOverviewStyle();

    const params = useParams();

    return (
        <PageComponent>
            <Grid container spacing={1}>
                <Grid item xs={3} >
                    <Paper className={`${classes.box}`}>
                        <LotSummary lotId={params.id} />
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper className={`${classes.box} ${classes.mapWrapper}`}>
                        <VirtualLot />
                    </Paper>
                </Grid>
            </Grid>
        </PageComponent >
    )
}
export default LotOverview;