import {  useSelector } from 'react-redux';
import { StepsStyles } from '../StepsStyles';
import { useGetAllVendorsQuery } from 'features/access/accessSlice';

const CarrierOverview = ({ }) => {
    const classes = StepsStyles()
    
    const { accessToEdit } = useSelector(state => state.accessState);
    
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();

    const vendorsLookup = vendors?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});



    return (
        <>
            <div className={classes.overviewField}><label >Carrier: </label>{accessToEdit?.carrierName || vendorsLookup[accessToEdit?.carrierId]?.name}</div>
            <div className={classes.overviewField}><label >SCAC: </label>{accessToEdit?.scac || vendorsLookup[accessToEdit?.carrierId]?.scac}</div>
        </>
    );
}

export default CarrierOverview;