import {createSlice} from "@reduxjs/toolkit";

export const inventoryFiltersSlice = createSlice({
    name: 'inventoryFilters',
    initialState: {
        searchVal: '',
        filters: {
            lotId: {value: null}
        },
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
});

export const { setSearchVal, setFilters } = inventoryFiltersSlice.actions;
export default inventoryFiltersSlice.reducer;