import DatePicker from "components/DatePicker";
import DropZoneInDialog from "components/DropZoneInDialog";
import LoadingSpinner from "components/LoadingSpinner";
import AssociatedLotsDropDown from "components/SharedDropdowns/LotsDropDown/AssociatedLotsDropDown";
import NotificationProvider from "components/NotificationProvider";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import FileService from "services/fileService";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { useUploadAuditMutation } from "../../lotAuditSlice";
import { AuditListStyles } from "../AuditList/AuditListStyle";

const AuditForm = () => {
    const classes = AuditListStyles();

    const history = useHistory();

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    let [uploadAudit, { isLoading: uploading }] = useUploadAuditMutation();

    const [selectedLot, setSelectedLot] = useState(null);
    const [auditDate, setAuditDate] = useState(moment().format("YYYY-MM-DD"));


    const onSave = async (file) => {
        let base64Data = await FileService.getBase64Data(file);
        let body = {
            lotId: selectedLot,
            auditDate: new Date(moment().format("MMM DD, yyyy, hh:mm a") + " UTC"),
            file: base64Data
        }
        let result = await uploadAudit(body);
        if (result && result.error) {
            NotificationProvider.error(`Failed to upload audit`);
        } else {
            NotificationProvider.success(`Successfully uploaded audit`);
        }
        // setAuditDate(moment().format("YYYY-MM-DD"));
        return result;
    }

    const getDayOfWeek = (number) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOfWeek[number - 1];
    }

    return (
        <>
            <LoadingSpinner loading={uploading} />
            <DropZoneInDialog
                labelDialog={'Upload File'}
                buttonLabel={'Upload File'}
                actionButtonLabel={'Save'}
                onSave={onSave}
                valid={!!selectedLot}
                formContent={
                    <div className={classes.uploadFileFields}>
                        <AssociatedLotsDropDown
                            identifier="lot"
                            title="Lot"
                            value={selectedLot}
                            onSelect={val => setSelectedLot(val)}
                            showEmpty
                            required
                        />
                        <br />
                        <br />
                        {selectedLot &&
                            `Required audit day for this lot is ${getDayOfWeek(lots?.find(l => l.id == selectedLot)?.auditDay) ?? "not configured"}`
                        }
                        {/* <DatePicker
                        title="Audit Date"
                        value={auditDate}
                        onSelect={setAuditDate} /> */}
                        <br />
                        <br />
                    </div>
                }
            />
        </>
    )
}
export default AuditForm;