import { makeStyles } from "@material-ui/core";

export const activityInvoiceStatusStyles = makeStyles((theme) => ({
    invoiceStatusWrapper: {
        color: 'white',
        marginRight: '1rem',
        padding: '0.5em 1em'
    },
    warningComponent: {
        marginRight: '0.5rem'
    }

}));