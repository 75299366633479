
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import LoadingSpinner from "components/LoadingSpinner";
import { groupBy } from "lodash";
import moment from "moment";
import UserTaskGroup from "../UserTaskGroup/userTaskGroup";
import { userTaskSetupByType } from '../userTaskConsts';
import { useGetTasksByUserIdQuery } from '../userTaskSlice';
import { UserTaskGroupsStyle } from "./style";

const UserTaskGroups = ({ selectedUser, lotId, isSuper = false }) => {
    const classes = UserTaskGroupsStyle();

    let { data: tasks, error: tasksError, isFetching: isLoadingTasks } = useGetTasksByUserIdQuery(selectedUser, { skip: !selectedUser });
    tasks = tasks || [];

    const groups = {
        'thisWeek': {
            groupName: 'This week',
            key: 'thisWeek',
            checkRange: (date) => {
                let today = new Date();
                let thisSunday = new Date(today.setDate(today.getDate() - today.getDay()));
                return moment(date).isSameOrAfter(thisSunday, 'day');
            }
        },
        'lastWeek': {
            groupName: 'Last week',
            key: 'lastWeek',
            checkRange: (date) => {
                let today = new Date();
                let lastSunday = new Date(today.setDate(today.getDate() - today.getDay() - 7));
                return moment(date).isSameOrAfter(lastSunday, 'day');
            }
        },
        'twoWeeksAgo': {
            groupName: 'Two weeks ago',
            key: 'twoWeeksAgo',
            checkRange: (date) => {
                let today = new Date();
                let twoWeeksAgoSunday = new Date(today.setDate(today.getDate() - today.getDay() - 14));
                return moment(date).isSameOrAfter(twoWeeksAgoSunday, 'day');
            }
        },
        'threeWeeksAgo': {
            groupName: 'Three weeks ago',
            key: 'threeWeeksAgo',
            checkRange: (date) => {
                let today = new Date();
                let threeWeeksAgoSunday = new Date(today.setDate(today.getDate() - today.getDay() - 21));
                return moment(date).isSameOrAfter(threeWeeksAgoSunday, 'day');
            }
        }
    }

    const filteredTasks = tasks?.filter(t => userTaskSetupByType[t?.taskTypeEnum]?.isSuper == isSuper);

    let sortedTasks = [...filteredTasks].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    let groupedTasks = sortedTasks.map(task => {
        for (let index = 0; index < Object.values(groups).length; index++) {
            if (Object.values(groups)[index].checkRange(task.createdDate)) {
                return { ...task, dateGroup: Object.values(groups)[index].key }
            }
        }
    });

    const dataGrouped = groupBy(groupedTasks, 'dateGroup');

    const tasksGroups = Object.keys(groups).map((entry, key) => (
        <div>
            <UserTaskGroup key={key} tasks={dataGrouped[entry]} groupName={groups[entry]?.groupName} lotId={lotId} isSuper={isSuper} />
        </div>
    ))

    return (
        <>
            {isLoadingTasks && <LoadingSpinner loading={isLoadingTasks} />}
            <div>
                {filteredTasks?.length > 0 ? tasksGroups : <EmptyPlaceholder text='No Tasks Found' />}
            </div>
        </>
    );
};

export default UserTaskGroups;
