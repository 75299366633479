import { Dialog, DialogContent } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { useDispatch } from 'react-redux';
import { ReportTypeEnum } from '../../reportsConsts.ts';
import ReportsPage from '../reportsPage/ReportsPage';
import { resetFilters } from '../reportsPage/reportsFilterSlice';
import GenerateBillingReports from './GenerateBillingReports';
import { GenerateBillingReportsStyle } from './GenerateBillingReportsStyle';

const BillingReportsDialog = ({ open, onClose, clientId }) => {
    const classes = GenerateBillingReportsStyle();
    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
        dispatch(resetFilters());
    }

    return (
        <Authorize profile={permissionProfiles.REPORTS.VIEW_BILLING_REPORTS}>
            <Dialog maxWidth='lg' fullWidth open={open} onClose={handleClose}>
                <DialogContent>
                    <h1 className={classes.title}>Billing Reports</h1>
                    <GenerateBillingReports clientId={clientId} />
                    <ReportsPage reportTypes={[ReportTypeEnum.STORAGE_BILLING_REPORT, ReportTypeEnum.INVOICE_REPORT]} title={'Report History'} clientId={clientId} />
                </DialogContent>
            </Dialog>
        </Authorize>
    )
}

export default BillingReportsDialog;