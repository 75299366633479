import { Grid, Paper } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { selectUserId } from "features/user/userSlice";
import { useGetAllRelationsQuery } from 'features/users/usersSlice';
import { IconClipboard } from "icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserTaskItem from "../UserTaskItem/UserTaskItem";
import { useGetTaskByIdQuery } from "../userTaskSlice";
import { EditUserTaskStyle } from "./style";

const EditUserTask = () => {
    const classes = EditUserTaskStyle();

    const params = useParams();
    let { data: task, error: tasksError, isFetching: isLoadingTasks } = useGetTaskByIdQuery(params?.id, { skip: !params?.id });
    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    allRelations = allRelations || [];

    let userId = useSelector(selectUserId);
    const [taskToEdit, setTaskToEdit] = useState(task?.taskEntity ?? {});

    useEffect(() => {
        setTaskToEdit(task?.taskEntity ?? {});
    }, [task, isLoadingTasks]);

    const isLoading = () => isLoadingTasks;

    return (
        <>{
            isLoading() ? < LoadingSpinner loading={isLoading()} /> :
                <Paper className={classes.taskWrapper}>
                    <Grid container direction="column"  >
                        <Grid item container >
                            <Grid item md={6} sm={12} >
                                <div className={classes.title}>
                                    <div className={classes.icon}> <IconClipboard /></div>
                                    <div>{task?.taskEntity?.title}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item md={6} sm={12} container alignItems="center" >
                                <div className={classes.subTitle}>
                                    {/* <div className={classes.icon}> <IconClipboard /></div> */}
                                    <div>{task?.taskEntity?.description}</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item md={6} sm={12}>
                                <UserTaskItem
                                    lotId={allRelations?.find(u => u.userId == userId)?.lotId}
                                    task={taskToEdit}
                                />
                            </Grid>
                        </Grid >
                    </Grid >
                </Paper>
        }</>

    )
}
export default EditUserTask;