export const DataPointType = {
    Client : 1,
    Vin : 2,
    Asset_ID : 3,
    Vehicle_Year : 4,
    Vehicle_Make : 5,
    Vehicle_Model : 6,
    Vehicle_Color : 7,
    Vehicle_Serries : 8,
    Vehicle_Type : 9,
    Carrier : 10,
    Driver_Name : 11,
    Keys : 12,
    Take_Full_Frontal_Image_Standing_10FT_Back_ : 13,
    Windshield_Damage : 14,
    Windshield_Wipers_Working : 15,
    Hood_Operational : 16,
    Driver_Side_Engine : 17,
    Front_Facing_Engine : 18,
    Passenger_Side_Engine : 19,
    Oil_Leakage : 20,
    Hose_Damage : 21,
    Battery_Wire_Corrosion : 22,
    Coolant_Overflow_Tank : 23,
    Washer_Fluid : 24,
    Driver_Side_Headlight_Turn_Signal_Damage : 25,
    Passenger_Headlight_Turn_Signal_Damage : 26,
    Additional_Front_Lighting_Issues : 27,
    Body_Damage : 28,
    Take_Front_Driver_Side_Image_Standing_10FT_Back_ : 29,
    Door_Operational : 30,
    Sideview_Mirror_Damage : 31,
    Window_Damage : 32,
    Tire_Damage : 33,
    Tire_Tread_Measurement : 34,
    Brake_Measurement : 35,
    Take_Driver_Side_Rear_Image_Standing_10FT_Back_ : 36,
    Door_Operational_Includes_Sliding_Door_ : 37,
    Tire_Tread_Measurement_ : 38,
    Take_Rear_Image_Standing_10FT_Back_ : 39,
    Rear_Glass_Damage : 40,
    Door_s_Trunk_Operational : 41,
    Reverse_Light_s_Working : 42,
    Rear_Driver_Side_Headlight_Turn_Signal_Damage : 43,
    Rear_Passanger_Side_Headlight_Turn_Signal_Working : 44,
    Rear_Wipers_Working : 45,
    Spare_Tire : 46,
    Take_Passenger_Rear_Vehicle_Image_Standing_10FT_Back_ : 47,
    Take_Front_Passenger_Side_Image_Standing_10FT_Back_ : 48,
    Front_Driver_Side_Image_From_Outside_Vehicle_with_Doors_Open_ : 49,
    Rearview_Mirror_Damage : 50,
    Main_Dome_Light_Working : 51,
    Driver_Dash_Image_Close_Up_ : 52,
    Driver_Dash_Image : 53,
    Odometer : 54,
    Fuel_Level : 55,
    Check_Engine_Light : 56,
    Other_Service_Lights : 57,
    Vehicle_Starts_and_Operates : 58,
    Radio_Working : 59,
    Defroster_Working : 60,
    Air_Conditioning_Working_ : 61,
    Heat_Working : 62,
    Center_Dash_Image_Radio_Climate_Controls_ : 63,
    Center_Console_Between_Seats_ : 64,
    Driver_Side_Seat_Damage : 65,
    Driver_Side_Floor_Damage : 66,
    Driver_Side_Seat_Belt_Damage : 67,
    // Rear_Driver_Side_Image_If_Applicable,
    _From_Outside_Vehicle_with_Doors_Open_ : 68,
    Rear_Driver_Side_Seat_Damage : 69,
    Rear_Driver_Side_Floor_Damage : 70,
    Rear_Driver_Side_Seat_Belt_Damage : 71,
    Rear_Image_with_Door_s_Trunk_Open : 72,
    // Rear_Passenger_Side_Image_If_Applicable,
    _From_Outside_Vehicle_with_Door_Open_ : 73,
    Rear_Passenger_Side_Seat_Damage : 74,
    Rear_Passenger_Side_Floor_Damage : 75,
    Rear_Passenger_Side_Seat_Belt_Damage : 76,
    Front_Passenger_Side_Image_From_Outside_Vehicle_with_Door_Open_ : 77,
    Front_Passenger_Dash_Image : 78,
    Under_Carrige_Driver_Side : 79,
    Under_Carrige_Driver_Side_Suspension : 80,
    Under_Carrige_Front : 81,
    Under_Carrige_Passenger_Side : 82,
    Under_Carrige_Passenger_Side_Front_Suspension : 83,
    Under_Carrige_Passenger_Side_Rear : 84,
    Under_Carrige_Passenger_Side_Rear_Suspension : 85,
    Under_Carrige_Rear_Exhaust_System_ : 86,
    Under_Carrige_Driver_Side_Rear : 87,
    Under_Carrige_Driver_Side_Rear_Suspension : 88,
    License_Plate : 89,
    Registration : 90,
    DOT_Inspection_Sticker : 91,
    Battery_Strength : 92,
    Keys_Handed_Over : 101,
    Fobs_Handed_Over : 102,
}