import { makeStyles } from "@material-ui/core";

export const IssueTrackerKanbanStyle = makeStyles((theme) => ({
    listContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        position: 'relative',
    },
    header: {
        padding: '0 1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
        //height: '17vh'
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row"
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    actionButtonsContainer: {
        padding: "17px 1px"
    },
    tableActions: {
        borderRadius: '5px 5px 0 0',
        maxHeight: '45px',
        margin: '10px 0',
        alignContent: 'center'
    },
    views: {
        display: 'flex'
    },
    tableViewsSelector: {
        marginLeft: '0.5em',
        paddingLeft: '1em',
        paddingTop: '3px',
        fontWeight: '500',
        borderLeft: '1px solid #efefef'
    },
    kanbanWrapper:{
        flex: 6,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0.7em',
        width: '100%',
        overflowX: 'auto'
    }
}));