import * as React from "react";

function SvgIconSign(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
            <path d="M16.1001 12.2751C16.0039 12.2751 15.9116 12.2411 15.8353 12.1648L15.4818 12.5184L15.8353 12.1648C15.6923 12.0218 15.6923 11.7783 15.8353 11.6353L25.402 2.06861C25.5451 1.92554 25.7885 1.92554 25.9316 2.06861C26.0746 2.21168 26.0746 2.4551 25.9316 2.59817L16.3649 12.1648C16.2943 12.2355 16.1993 12.2751 16.1001 12.2751Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M15.5417 11.9584V12.4584H16.0417H20.8017C21.0039 12.4584 21.1767 12.6312 21.1767 12.8334C21.1767 13.0356 21.0039 13.2084 20.8017 13.2084H15.1667C14.9646 13.2084 14.7917 13.0356 14.7917 12.8334V7.19836C14.7917 6.99617 14.9646 6.82336 15.1667 6.82336C15.3689 6.82336 15.5417 6.99617 15.5417 7.19836V11.9584Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M17.4999 26.0417H10.4999C7.38981 26.0417 5.28943 25.3754 3.95701 24.0429C2.6246 22.7105 1.95825 20.6101 1.95825 17.5V10.5C1.95825 7.38994 2.6246 5.28955 3.95701 3.95714C5.28943 2.62472 7.38981 1.95837 10.4999 1.95837H12.8333C13.0354 1.95837 13.2083 2.13118 13.2083 2.33337C13.2083 2.53556 13.0354 2.70837 12.8333 2.70837H10.4999C7.77547 2.70837 5.77966 3.18778 4.48366 4.48378C3.18766 5.77978 2.70825 7.7756 2.70825 10.5V17.5C2.70825 20.2245 3.18766 22.2203 4.48366 23.5163C5.77966 24.8123 7.77548 25.2917 10.4999 25.2917H17.4999C20.2244 25.2917 22.2202 24.8123 23.5162 23.5163C24.8122 22.2203 25.2916 20.2245 25.2916 17.5V15.1667C25.2916 14.9645 25.4644 14.7917 25.6666 14.7917C25.8688 14.7917 26.0416 14.9645 26.0416 15.1667V17.5C26.0416 20.6101 25.3752 22.7105 24.0428 24.0429C22.7104 25.3754 20.61 26.0417 17.4999 26.0417Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconSign;
