import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'conditionReport';
export const conditionReportApi = baseApi.injectEndpoints({
    reducerPath: 'conditionReportApi',
    baseQuery: baseQuery,
    tagTypes: ["Vehicles", "Vehicle"],
    endpoints: (builder) => ({
        GetConditionReportSections: builder.query({
            query: (id) => ({
                url: `${apiPrefix}/${id}`,
            }),
        }),
        UpdateConditionReport: builder.mutation({
            query: conditionReport => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: conditionReport
            }),
            //invalidatesTags: [apiTags.VEHICLES, apiTags.VEHICLE]
        }),
        DocumentConditionReport: builder.mutation({
            query: downloadDocument => ({
                url: `${apiPrefix}/download`,
                method: "POST",
                body: downloadDocument
            }),
            providesTags: ["Vehicles", "Vehicle"]
        }),
        UpdateAssetConditionReport: builder.mutation({
            query: conditionReport => ({
                url: `${apiPrefix}/asset`,
                method: "POST",
                body: conditionReport
            }),
            //invalidatesTags: [apiTags.VEHICLES, apiTags.VEHICLE]
        })
    }),
});

export const {
    useGetConditionReportSectionsQuery,
    useUpdateConditionReportMutation,
    useDocumentConditionReportMutation,
    useUpdateAssetConditionReportMutation
} = conditionReportApi;

