import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    lotName: {
        padding: '0 0.5em',
        background: theme.palette.background.default,
        minHeight: '3em',
        width: '100%',
        cursor: 'pointer'
    },
    menuWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '30em'
    },
    searchWrapper: {
        padding: '0 1em',
        marginBottom: '1em'
    },
    listWrapper: {
        flex: 1,
        overflowY: 'auto',
        width: '100%',
    },
    treeItem: {
        borderBottom: '1px solid #f4f4f4',
        padding: '0.5em'
    }
}));