import {Grid, Link} from "@material-ui/core";
import React from "react";
import FormatService from "../../../../../../services/formatService";
import { TaskItemStyles } from "../../TaskItemStyles";
import NotesTooltip from "../../../../../notes/NotesTooltip";
import {NoteEntityTypes} from "../../../../../notes/notesConsts";

const LoadsTask = ({ task }) => {
    const classes = TaskItemStyles();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }

    const notes = (entityId) => {
        return <Grid container direction="row">
            <Grid xs={4}><NotesTooltip entityType={ NoteEntityTypes.TRANSPORT} entityId={entityId} /></Grid>
            <Grid xs={8}></Grid>
        </Grid>;
    }    
    const fieldWithLink = (label, value, vehicleId) => {
        return (
            <Grid container direction="row">
                <Grid xs={4}> <label className={classes.label}>{label}</label> </Grid>
                <Grid xs={8}>
                    <Link style={{ color: "#46535d", paddingLeft: 3 }} href={`/assets/detail/${vehicleId}`} target="_blank">{value}</Link>
                </Grid>
            </Grid>
        );
    };

    //change fields show
    return (
        <Grid container direction='column'>
            {field('Pickup Location', task?.pickupLocation?.name)}
            {field('Pickup Date', FormatService.formatDate(task?.calcPickupDate))}
            {task?.assets[0].assetId && fieldWithLink('Asset ID', task?.entity?.assetId, task?.entity?.vehicleId)}
            {task?.entity?.licensePlate && fieldWithLink('License', task?.entity?.licensePlate,task?.entity?.vehicleId)}
            {fieldWithLink('VIN', task?.entity?.descriptor, task?.entity?.vehicleId)}
            {notes(task?.id)}
        </Grid>
    )
}
export default LoadsTask;