import { ButtonBase, Grid, Paper } from "@material-ui/core";
import { groupBy } from "lodash";
import { arrayToObject } from "utils/object-util";

const { reservationStatusList } = require("../reservationConsts")
const { ReservationStatusFilterStyle } = require("./style")

export const reservationColors = [
    '#F6B6CD',
    '#FC9154',
    '#FC9154',
    '#FFB240',
    '#FCC354',
    '#64E5C6',
    '#9bdc8a',
    '#9bdc8a'
]

export const ReservationStatusFilter = ({ onSelectStatus, reservations }) => {
    const classes = ReservationStatusFilterStyle();

    const reservationsGrouped = groupBy(reservations, 'status');

    return <Grid container className={classes.filterContainer} spacing={2}>
        {reservationStatusList?.map((status, index) => <Grid item  >
            <ButtonBase key={status.id} item onClick={() => onSelectStatus(status.id)}>
                <Paper className={classes.filterItem} style={{ borderTop: `7px solid ${reservationColors?.[index]}` }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item className={classes.filterName}>{status.name}</Grid>
                        <Grid item ><div className={classes.count}>{reservationsGrouped?.[status.id]?.length ?? 0}</div></Grid>
                    </Grid>
                </Paper></ButtonBase>
        </Grid>)}
    </Grid>;
}