import { Button, Grid, Paper } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { resetSelected, setBulkEdit } from "./WashSlaSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LotsConfigStyle from "../lots/LotsConfigStyle";

export const BulkEditPricing = ({ onSelectAll, onWashPriceChange }) => {
    const classes = LotsConfigStyle();
    
    const [price, setPrice] = useState(null);

    const { bulkEdit, selectedLots } = useSelector(state => state.washConfigState);
    const dispatch = useDispatch();

    const onApply = (price) => {
        onWashPriceChange(price);
    }
    
    return (
        <Paper className={`${classes.listItemWrapper} ${classes.listItemWrapperEdit} ${classes.listItemWrapperBulk}`}>
            <Grid container direction='column'>
                <Grid item container direction="column">
                    <Grid className={classes.bulkTitle}>
                        Bulk Update
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item alignItems='center' container className={classes.input} >
                            <Grid xs={6} className={classes.label}>{"Price"} </Grid>
                            <Grid xs={6} className={classes.inputField}>
                                {<CustomInput
                                    value={price}
                                    onChange={(val) => {setPrice(val)}}
                                    //icon={icon}
                                    elementType="input"
                                    numberOnly
                                />}
                            </Grid>
                        </Grid>
                        <Grid item className={classes.btnWrapper}>
                            <Button
                                onClick={() => {
                                    dispatch(setBulkEdit(false));
                                    return;
                                }}
                                className={classes.button}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => onApply(price)}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                disabled={!!(!selectedLots?.length)}
                            >
                                {"Apply"}
                            </Button>
                        </Grid>
                    </Grid >
                    <Grid className={classes.bulkCountTitle} item container justifyContent="space-between" alignItems="center">
                        <div>
                            {selectedLots?.length} selected lot{selectedLots?.length > 1 ? 's' : ''}
                            <div>
                                <Button
                                    onClick={onSelectAll}
                                    className={classes.button}
                                    color="primary"
                                >
                                    Select All
                                </Button>
                                <Button
                                    onClick={() => {
                                        dispatch(resetSelected());
                                        return;
                                    }}
                                    className={classes.button}
                                    color="primary"
                                >
                                    Clear All
                                </Button>
                            </div>
                        </div>

                    </Grid>
                </Grid>

            </Grid>
        </Paper >
    )
}