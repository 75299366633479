import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import EmptyPlaceholder from "../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import TaskItem from "../TaskItem";
import { TasksPanelStyles } from "../../TasksPanelStyles";

const TaskList = ({ tasks, itemHeight = 180, placeholder }) => {
    const classes = TasksPanelStyles();

    const Row = ({ index, style }) => (
        <div style={style}>
            <TaskItem task={tasks?.[index]} key={index} />
        </div>
    );

    return (
        <>
            {!!tasks?.length ? <div style={{ flex: 1 }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            className="List"
                            height={height}
                            itemCount={tasks?.length}
                            itemSize={itemHeight}
                            width={width}
                        >
                            {Row}
                        </List>
                    )}
                </AutoSizer>
            </div> :
                placeholder ? placeholder : <EmptyPlaceholder text="There are no pending tasks" />}
        </>

    )
}
export default TaskList;