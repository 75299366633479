import CustomInput from "components/CustomInput";
import { useAddClientAccountMutation, useGetAllClientAccountsQuery, useUpdateClientAccountMutation } from "../clientAccountSlice";
import { useGetClientsQuery } from "../clientSlice";
import CreateIcon from '@material-ui/icons/Create';
import { useSelector } from "react-redux";
import { selectIsAdmin } from "features/user/userSlice";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect, useState } from "react";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import NotificationProvider from "components/NotificationProvider";
import EditClientPopupStyle from "./EditClientPopupStyle";
import { IconEdit } from "icons";
const { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, IconButton, Card, Grid, Paper } = require("@material-ui/core");

const useStyles = EditClientPopupStyle;

const EditClientPopup = ({ open, setOpenEditPopup, clientToEdit, setClientToEdit }) => {
    const classes = useStyles();

    const isAdmin = useSelector(selectIsAdmin);

    let { data: clientAccounts, error: clientAccountsError, isLoading: isLoadingClientAccounts } = useGetAllClientAccountsQuery(clientToEdit?.id, { skip: !clientToEdit?.id });
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });

    clientAccounts = clientAccounts || [];
    clients = clients || [];
    let client = clients?.find(c => c.id === clientToEdit?.id);

    const [editStates, setEditStates] = useState(Array(clientAccounts?.length).fill(false));
    const [subClients, setSubClients] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    const [editSubClient] = useUpdateClientAccountMutation()
    const [addSubClient] = useAddClientAccountMutation()

    const setEditState = (index, value) => {
        const newEditStates = [...editStates];
        newEditStates[index] = value;
        setEditStates(newEditStates);
    };

    const resetEditStates = () => {
        setEditStates(Array(clientAccounts?.length).fill(false));
    };

    useEffect(() => {
        if (!!clientAccounts.length) {
            setSubClients(clientAccounts)
        }
    }, [clientAccounts]);

    const onSaveName = async (subClient, index) => {
        let result = await editSubClient({ subClient });
        if (result.error) {
            NotificationProvider.error("Error saving your change");
        } else {
            NotificationProvider.success("Successfully saved your change");
            setEditState(index, false);
        }
        return true;
    }

    const onChangeName = (index1, newName) => {
        setSubClients((subClients) => {
            return subClients.map((subClient, index) => {
                if (index === index1) {
                    return { ...subClient, name: newName };
                }
                return subClient;
            });
        });
    };

    const onAddSubClient = async (name) => {
        let result = await addSubClient({ name: name, clientId: clientToEdit?.id });
        if (result.error) {
            NotificationProvider.error("Error adding the sub client");
        } else {
            NotificationProvider.success("Successfully added the sub client");
            setOpenDialog(false);
        }
        return true;
    }

    return (
        <Dialog open={open} maxWidth='md' fullWidth >
            <DialogTitle>{`Edit Client ${client?.name}`}</DialogTitle>
            <DialogContent>
                {isLoadingClientAccounts ?
                    <LoadingSpinner loading={isLoadingClientAccounts} /> :
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between'>
                            <div className={classes.title}>Sub Clients</div>
                            <AddSubClient
                                onAddSub={onAddSubClient}
                                openDialog={openDialog}
                                setOpenDialog={setOpenDialog}
                            />
                        </Grid>
                        {!!subClients?.length ?
                            <List >
                                {subClients?.map((subClient, index) =>
                                    <>
                                        <Paper className={classes.paper}>
                                            {editStates[index] ?
                                                <Grid alignItems="center" container direction='row' justifyContent="space-between">

                                                    <Grid item xs={10}>
                                                        <CustomInput
                                                            label='Sub Client Name'
                                                            elementType='input'
                                                            value={subClient?.name}
                                                            onChange={(val) => onChangeName(index, val)}
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.icons} >
                                                        <IconButton edge="end" size='small' onClick={() => onSaveName(subClient, index)} disabled={!subClient?.name}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                        <IconButton edge='end' size='small' onClick={() => setEditState(index, false)} >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                : <>
                                                    <Grid container direction='row' alignItems="center">
                                                        <ListItemText primary={subClient?.name} />
                                                        <Grid item className={classes.icons} >
                                                            <IconButton edge="end" size='small' onClick={() => setEditState(index, true)}>
                                                                <IconEdit />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                        </Paper>
                                    </>
                                )}
                            </List>
                            : <>
                                <div className={classes.noItemsDiv}>
                                    <h3>There are no sub clients to display</h3>
                                </div>
                            </>}
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => { resetEditStates(); setOpenEditPopup(false); setSubClients([])} }
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditClientPopup;

export function AddSubClient({ onAddSub, openDialog, setOpenDialog }) {

    const [subToAdd, setSubToAdd] = useState();
    const [loading, setLoading] = useState();

    return (
        <>
            <div>
                <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => setOpenDialog(true)}
                    startIcon={<AddIcon />}
                >
                    Add Sub Client
                </Button>
            </div>
            <Dialog open={openDialog}>
                <DialogTitle>Add Sub Client</DialogTitle>
                <DialogContent>
                    <CustomInput
                        label='Name'
                        elementType='input'
                        value={subToAdd}
                        onChange={(val) => setSubToAdd(val)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { setSubToAdd(); setOpenDialog(false); }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            setLoading(true);
                            await onAddSub(subToAdd);
                            setLoading(false);
                            setSubToAdd();
                        }}
                        disabled={!subToAdd || loading}
                    >
                        {loading ? 'Saving' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}