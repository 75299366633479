import { createSlice } from "@reduxjs/toolkit";
import { sum } from 'lodash';
import { baseApi } from "../../../app/baseApi";
import { apiTags } from "../../../app/store/apiTags";

const basePath = 'storageinvoice';

export const storageInvoiceApi = baseApi.injectEndpoints({
    reducerPath: 'loadInvoiceApi',
    endpoints: (builder: any) => ({
        getStorageInvoices: builder.query({
            query: (body: StorageInvoiceRequest) => ({
                url: `${basePath}?${Object.keys(body)?.filter(k => body[k]).map(k => `${k}=${body[k]}`)?.join('&')}`,

            }),
            providesTags: [apiTags.STORAGE_INVOICES],
            transformResponse: (response: Array<StorageInvoice>) => {
                return response.map(invoice => ({
                    ...invoice,
                }));
            }
        }),
        getStorageInvoicePdf: builder.query({
            query: ({ invoiceDetailId }) => ({
                url: `${basePath}/pdf?invoiceDetailId=${invoiceDetailId}`,

            })
        }),
        sendStorageInvoicePdf: builder.mutation({
            query: ({ invoiceDetailId, toEmails, bccEmails }) => ({
                url: `${basePath}/pdf/send`,
                body: {
                    invoiceDetailId,
                    toEmails,
                    bccEmails
                },
                method: "POST",
            }),
        }),
        updateStorageInvoiceDetails: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/details`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        updateStorageInvoiceTotals: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/totals`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        approveStorageInvoice: builder.mutation({
            query: (id: any) => ({
                url: `${basePath}/approve`,
                method: "PUT",
                body: {
                    id
                }
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        sendStorageInvoiceToQB: builder.mutation({
            query: (body) => ({
                url: `${basePath}/sendToQB`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        updatePCOInvoiceDetail: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/pco/details`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        updateInvoiceActivity: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/pco/activity`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        getAllInvoiceItems: builder.query({
            query: () => ({
                url: `${basePath}/items`
            })
        }),
        updateInvoiceStorageService: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/pco/storageService`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        updatePCOInvoiceStatus: builder.mutation({
            query: (body) => ({
                url: `${basePath}/pco/status`,
                method: "POST",
                body: {
                    id: body.id,
                    statusId: body.statusId,
                }
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES]
        }),
    })

});


export const {
    useGetStorageInvoicesQuery,
    useGetStorageInvoicePdfQuery,
    useUpdateStorageInvoiceDetailsMutation,
    useUpdateStorageInvoiceTotalsMutation,
    useApproveStorageInvoiceMutation,
    useSendStorageInvoiceToQBMutation,
    useUpdatePCOInvoiceDetailMutation,
    useSendStorageInvoicePdfMutation,
    useUpdateInvoiceActivityMutation,
    useGetAllInvoiceItemsQuery,
    useUpdateInvoiceStorageServiceMutation,
    useUpdatePCOInvoiceStatusMutation,
} = storageInvoiceApi;

export const storageInvoiceStateSlice = createSlice({
    name: 'storageInvoiceStateSlice',
    initialState: {
        searchVal: '',
        filters: {
            'clientId': '',
            'month': '',
            'year': ''
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = { ...state.filters, [action.payload.key]: action.payload.value }
        },
    },
});

export const { setSearchVal, setFilters, setFilter } = storageInvoiceStateSlice.actions;
