import { makeStyles } from "@material-ui/core";

export const InspectionsGroupStyles = makeStyles((theme) => ({
    dropdownStyle: {
        minWidth: 260,
        width: '100%',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    itemWrapper: {
        paddingBottom: '0.5em 0',
        marginBottom: '1em'
    },
    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        background: theme.palette.neutral.light,
        padding: '0 1rem',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '5px',
        marginBottom: '0.5rem'
    },
    report: {
        padding: '0.5em 0',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'underline'
    }
}));
