import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    contractHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1em 0',
    },
    title: {
        fontSize: '1.2em',
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));
