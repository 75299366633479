import CheckInMarker from "features/maps/markers/CheckInMarker/CheckInMarker"
import MechanicalMarker from "features/maps/markers/MechanicalMarker/MechanicalMarker"
import RecentUpdateMarker from "features/maps/markers/RecentUpdateMarker/RecentUpdateMarker"
import TelematicsMarker from "features/maps/markers/TelematicsMarker/TelematicsMarker"
import TransportMarker from "features/maps/markers/TransportMarker/TransportMarker"
import { keyBy } from "lodash"

export const AssetFilterTypes = {
    RECENT_UPDATE: 1,
    LAST_CHECKIN: 2,
    TRANSPORT: 3,
    MECHANICAL: 4,
    TELEMATICS: 5
}

export const assetLocationsTypes = [
    {
        id: AssetFilterTypes.RECENT_UPDATE,
        name: 'Most Recent Update',
        icon: (text, subText, color) => <RecentUpdateMarker text={text} subText={subText} color="#66ddfb" wrapperSize="30" iconSize="17" />,
    },
    {
        id: AssetFilterTypes.LAST_CHECKIN,
        name: 'Last Status Update',
        icon: (text, subText, color) => <CheckInMarker text={text} subText={subText} color={color} wrapperSize="30" iconSize="18" />,
    },
    {
        id: AssetFilterTypes.TRANSPORT,
        name: 'Active Transport',
        icon: (text, subText, color) => <TransportMarker text={text} subText={subText} color="#b25cf7" wrapperSize="30" iconSize="17" />,
    },
    {
        id: AssetFilterTypes.MECHANICAL,
        name: "Mechanical Service",
        icon: (text, subText, color) => <MechanicalMarker text={text} subText={subText} color="#f14040" wrapperSize="30" iconSize="18" />,
    },
    {
        id: AssetFilterTypes.TELEMATICS,
        name: "Telematics Location",
        icon: (text, subText, color, tooltip) => <TelematicsMarker text={text} subText={subText} wrapperSize="30" iconSize="18" tooltip={tooltip} />,
    }
]

export const assetLocationsTypesById = keyBy(assetLocationsTypes, 'id');