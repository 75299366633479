import moment from "moment";
import { arrayToObject } from "utils/object-util";

export const DefaultRangesTypes = {
    LAST_WEEK: 0,
    MONTH: 1,
    LAST_MONTH: 2,
    THREE_MONTHS: 3,
    QUARTER: 4,
    LAST_QUARTER: 5,
    SIX_MONTHS: 6,
    YEAR: 12,
    LAST_YEAR: 13,
    NEXT_THREE_DAYS: 14,
    NEXT_WEEK: 15,
    NEXT_TWO_WEEKS: 16,
    NEXT_MONTH: 17,
    NEXT_SIX_MONTHS: 18,
    NEXT_YEAR: 19,
    TWELVE_MONTHS: 20,

};

export const PredefinedRanges = [
    {
        key: DefaultRangesTypes.LAST_WEEK,
        label: 'last week',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')),
            endDate: new Date(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'))
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.MONTH,
        label: 'month',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().startOf('month').format("YYYY-MM-DD")),
            endDate: new Date()
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.LAST_MONTH,
        label: 'last month',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD")),
            endDate: new Date(moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD"))
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.THREE_MONTHS,
        label: '3 months',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().subtract(3, 'months').format("YYYY-MM-DD")),
            endDate: new Date()
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.QUARTER,
        label: 'quarter',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY-MM-DD")),
            endDate: new Date()
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.LAST_QUARTER,
        label: 'last quarter',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().quarter(moment().quarter() - 1).startOf('quarter').format("YYYY-MM-DD")),
            endDate: new Date(moment().quarter(moment().quarter() - 1).endOf('quarter').format("YYYY-MM-DD"))
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.SIX_MONTHS,
        label: '6 months',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().subtract(6, 'months').format("YYYY-MM-DD")),
            endDate: new Date()
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.TWELVE_MONTHS,
        label: '12 months',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().subtract(12, 'months').format("YYYY-MM-DD")),
            endDate: new Date()
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.YEAR,
        label: 'year',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().startOf('year').format("YYYY-MM-DD")),
            endDate: new Date()
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.LAST_YEAR,
        label: 'last year',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD")),
            endDate: new Date(moment().subtract(1, 'year').endOf('year').format("YYYY-MM-DD"))
        }),
        isSelected() {
            return false//TODO 
        },
        past: true
    },
    {
        key: DefaultRangesTypes.NEXT_THREE_DAYS,
        label: 'Next 3 Days',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(),
            endDate: new Date(moment().add(2, 'days').format('YYYY-MM-DD')),
        }),
        isSelected() {
            return false//TODO 
        },
    },
    {
        key: DefaultRangesTypes.NEXT_WEEK,
        label: 'Next Week',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(),
            endDate: new Date(moment().add(6, 'days').format('YYYY-MM-DD')),
        }),
        isSelected() {
            return false//TODO 
        },
    },
    {
        key: DefaultRangesTypes.NEXT_TWO_WEEKS,
        label: 'Next 2 Weeks',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(),
            endDate: new Date(moment().add(13, 'days').format('YYYY-MM-DD')),
        }),
        isSelected() {
            return false//TODO 
        },
    },
    {
        key: DefaultRangesTypes.NEXT_MONTH,
        label: 'Next Month',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(),
            endDate: new Date(moment().add(1, 'months').format('YYYY-MM-DD')),
        }),
        isSelected() {
            return false//TODO 
        },
    },
    {
        key: DefaultRangesTypes.NEXT_SIX_MONTHS,
        label: 'Next 6 Months',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(),
            endDate: new Date(moment().add(6, 'months').format('YYYY-MM-DD')),
        }),
        isSelected() {
            return false//TODO 
        },
    },
    {
        key: DefaultRangesTypes.NEXT_YEAR,
        label: 'Next Year',
        hasCustomRendering: true,
        range: () => ({
            startDate: new Date(),
            endDate: new Date(moment().add(1, 'years').format('YYYY-MM-DD')),
        }),
        isSelected() {
            return false//TODO 
        },
    },
]

export const PredefinedRangesByKey = arrayToObject(PredefinedRanges, 'key');