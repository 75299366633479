import { Paper } from "@material-ui/core";
import { Grid } from "@mui/material";
import { IconBill, IconDollar } from "icons";
import { useState } from "react";
import FormatService from "services/formatService";
import { storageInvoiceSummaryStyles } from "./style";


const StorageInvoiceSummary = ({ invoice }) => {
    const classes = storageInvoiceSummaryStyles();



    const [open, setOpen] = useState(false);
    const [invoiceToEdit, setInvoiceToEdit] = useState(invoice);

    const onEditClick = () => {
        setInvoiceToEdit(invoice);
        setOpen(true)
    }

    return (
        <>
            <Paper className={classes.wrapper}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    {/* <Grid item xs={0.5} className={classes.totalText}>Totals</Grid> */}
                    <Grid item xs={11} >
                        <Grid container alignItems={'center'} spacing={1}>
                            {summaryColumns.map(count =>
                                <Grid item xs={count.width ?? 2} container key={count.key} spacing={1} alignItems='center'>
                                    <Grid item ><div className={classes.icon}>{count.icon}</div></Grid>
                                    <Grid item>
                                        <div className={classes.amount}>{count.value(invoice?.[count?.key] ?? 0)}</div>
                                        <div className={classes.label}>{count.label}</div>
                                    </Grid>
                                </Grid>)}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={0.5}><IconButton className={classes.iconButton} variant="outlined" onClick={onEditClick}><EditIcon /></IconButton></Grid> */}
                </Grid>
            </Paper>
            {/* <EditStorageInvoiceSummary invoice={invoice} open={open} setOpen={setOpen} invoiceToEdit={invoiceToEdit} setInvoiceToEdit={setInvoiceToEdit} /> */}
        </>
    )
}

export default StorageInvoiceSummary;
export const summaryColumns = [
    {
        key: 'totalLotRevenue',
        label: 'Lot Revenue',
        icon: <IconDollar />,
        value: (v) => `${FormatService.formatCurrency(v)}`
    },
    {
        key: 'totalOverage',
        label: 'Overage Fee',
        icon: <IconDollar />,
        value: (v) => `${FormatService.formatCurrency(v)}`
    },
    {
        key: 'crossMarketOverage',
        label: 'Cross Market Overage',
        icon: <IconDollar />,
        value: (v) => `${FormatService.formatCurrency(v)}`
    },
    {
        key: 'retainer',
        label: 'Retainer',
        icon: <IconDollar />,
        value: (v) => `${FormatService.formatCurrency(v)}`
    },
    {
        key: 'corporateCharge',
        label: 'Due to Corporate',
        icon: <IconBill />,
        value: (v) => `${FormatService.formatCurrency(v)}`,
    },
    {
        key: 'totalStorageFee',
        label: 'Storage Fee',
        icon: <IconDollar />,
        value: (v) => `${FormatService.formatCurrency(v)}`,
    }
]
