import {Button, Grid, Paper} from "@material-ui/core";
import LotsConfigStyle from "../../../clients/clientConfigs/lots/LotsConfigStyle";
import ContractHeader from "../../../clients/contracts/ContractHeader/ContractHeader";
import configStyles from "../../../clients/clientConfigs/configStyles";
import DataSearchBar from "../../../../components/DataSearchBar";
import AddIcon from "@material-ui/icons/Add";
import {useState} from "react";
import {Client} from "./components/client";
import {useGetClientLotsQuery} from "../../lotSlice";
import {useRemoveLotFromClientMutation} from "../../../clients/clientConfigs/lots/lotConfigSlice";
import {orderBy} from "lodash";
import FilterService from "../../../../services/filterService";
import DropdownFilter from "../../../../components/DropdownFilter";

const useConfigStyles = configStyles;
const useStyles = LotsConfigStyle
const ClientInLot = ({activeLot, clients}) => {
    const classes = useStyles();
    const configClasses = useConfigStyles();

    const [search, setSearch] = useState();
    const [hasNew, setHasNew] = useState(false);

    let [removeClientLot, {isLoading: removingLot}] = useRemoveLotFromClientMutation()
    let {
        data: clientsInLot,
        error: clientsInLotError,
        isLoading: isLoadingLots,
        refetch: refetchClientLot
    } = useGetClientLotsQuery(activeLot?.id);
    let clientFilter = clients?.filter(client => !clientsInLot?.some(clientInLot => clientInLot?.clientId === client?.id));
    let clientsInLotOrderBy = orderBy(FilterService.filter([], search, clientsInLot, {}),
        ['name'], ['asc']);
    const dropdownValues = clientsInLot?.map(client => ({ id: client.clientName, name: client.clientName }));
    const onRemove = async (clientLot) => {
        let result = await removeClientLot(clientLot);
        refetchClientLot();
        return result;
    }

    const showAddNew = (value) => {
        console.log(value);
        setHasNew(value);
    }

    const onAddClick = () => {
        setHasNew(true);
    }

    return (<>

        <Paper className={configClasses.card}>
            <ContractHeader label={'Clients'}/>
            <Grid container spacing={1} alignItems='center' justifyContent="space-between">
                <Grid item xs={6}>
                    <DropdownFilter
                        title='Lots'
                        identifier='search'
                        onSelect={(e) => setSearch(e)}
                        values={dropdownValues}
                        showEmpty={true} />
                </Grid>
                <Button
                    id="basic-button"
                    onClick={() => onAddClick()}
                    startIcon={<AddIcon/>}
                    disabled={hasNew}
                >
                    {'Add'}
                </Button>
            </Grid>

            {hasNew &&
                <Paper className={`${classes.listItemWrapper} ${classes.listItemWrapperNew}`}>
                    <Client
                        showAddNew={showAddNew}
                        isNew={true}
                        activeLot={activeLot}
                        refetchClientLot={refetchClientLot}
                        activeClients={clientFilter}
                        activeClient={{name: "New"}}
                        onRemove={onRemove}
                        key={'new_lot'}
                    />
                </Paper>
            }
            {
                clientsInLotOrderBy?.map(client =>
                    <Paper className={`${classes.listItemWrapper}`}>
                        <Client
                            showAddNew={() => {
                            }}
                            isNew={false}
                            refetchClientLot={refetchClientLot}
                            activeLot={activeLot}
                            activeClientLot={client}
                            activeClients={clientFilter}
                            onRemove={onRemove}
                            key={client?.id}
                        />
                    </Paper>)
            }

        </Paper>
    </>)
}

export default ClientInLot;