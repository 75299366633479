import { makeStyles } from "@material-ui/core";

export const AccessLoaderStyle = makeStyles((theme) => ({
    sendingIcon: {
        fill: 'green'
    },
    iconWrapper: {
        overflow: 'visible',
        //position: 'relative'
        position: 'absolute',
        top: '50%',
        left: ' 50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    icon: {
        animation: '$breathing 1.5s linear infinite alternate',
        fill: '#7dd931',
        width: '7em',
        height: '7em'
    },
    text: {
        color: '#65a82e',
        position: 'absolute',
        top: '4em',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        left: '-5em'
    },
    "@keyframes breathing": {
        '50%': {
            transform:
                'scale(1.1);'
        }

    },
}));