import { makeStyles } from "@material-ui/core"

export const AccessDialogStyle = makeStyles((theme) => ({
    accessDialog: {
        margin: '1rem'
    },
    dialogActions: {
        margin: 'auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    timeline: {
        marginTop: '2rem'
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
}));