import { baseQuery } from "../../app/baseQuery";
import { apiTags } from 'app/store/apiTags';
import { baseApi } from 'app/baseApi';

const apiPrefix = 'contactType';
export const contactTypeApi = baseApi.injectEndpoints({
    reducerPath: 'contactTypeApi',
    tagTypes: [apiTags.CONTACT_TYPE],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getContactTypes: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.CONTACT_TYPE],
        }),
        addContactType: builder.mutation({
            query: (contactType) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: contactType
            }),
            invalidatesTags: [apiTags.CONTACT_TYPE],
        }),
    }),
});

export const {
    useGetContactTypesQuery,
    useAddContactTypeMutation
} = contactTypeApi;
