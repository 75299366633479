import { userTaskStatuses } from '../../userTaskConsts';
import { SuperTaskCardStyle } from "./style";

const StatusIndicator = ({ taskStatus = userTaskStatuses.Pending }) => {

    const classes = SuperTaskCardStyle();
    const statusToClass = {
        [userTaskStatuses.Complete]: classes.greenIndicator,
        [userTaskStatuses.Pending]: classes.yellowIndicator,
    }
    return (
        <div item xs={2} className={classes.indicatorWrapper}>
            <div
                className={`${statusToClass[taskStatus?.id]} ${classes.indicator}`}
            ></div>
            <div>{taskStatus?.name}</div>
        </div>
    );
}

export default StatusIndicator;