import { Button, Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { IconDeleteBlue } from "icons";
import { useState } from "react";
import CustomInput from "../../../../../components/CustomInput";
import PhoneInput from "../../../../../components/PhoneInput";
import Validator from "../../../../../services/validator";
import PocContactsStyle from "./PocContactsStyle";
import {ContactTypeField} from "../../../../contactType/component/ContactTypeField/ContactTypeField";

const PocContacts = ({contactTypes, contacts, setContacts, lotToEdit, setLotToEdit}) => {
    const classes = PocContactsStyle();
    const [isRedOnly, setIsRedOnly] = useState(false);
        
    const dispatchMemberType = contactTypes?.find(type => type.key === 'dispatch_member');

    let firstDispatchMemberContact = null;
    if (dispatchMemberType) {
        firstDispatchMemberContact = contacts?.find(contact => contact.contactTypeId === dispatchMemberType.id);
    }

    const addNewContact = () => {
        const newObj = {
            id: 0,
            lotId: lotToEdit.id,
            name: null,
            email: null,
            isEmailValid: null,
            phone: null,
            note: null,
            contactTypeId: 0,
            deletedDate: null,
            valid: false,
            isRequiredField: true
        };
        setContacts([...contacts, newObj]);
    };
    const onDelete = (index) => {
        let updatedContacts = [...contacts];
        updatedContacts[index] = {...updatedContacts[index], deletedDate: new Date(), valid: true}
        setContacts(updatedContacts);
        setLotToEdit({...lotToEdit, pocContacts: updatedContacts});
    }
    const updateContacts = (index, val, field, validateEmail = true) => {
        const updatedContacts = [...contacts];
        updatedContacts[index] = {
            ...updatedContacts[index],
            [field]: val,
        };
        
        if (validateEmail)
            updatedContacts[index].valid = !!Validator.validateEmail(val);

        setContacts(updatedContacts);
        setLotToEdit({...lotToEdit, pocContacts: updatedContacts});
    };
    const onChangeEmail = (index, val, field) => {
        updateContacts(index, val, field);
    };
    const onChange = (index, val, field) => {
        updateContacts(index, val, field, false);
    };

    const activeContactsCount = contacts.filter(contact => contact.deletedDate === null).length;

    return (
        <>
            {contacts.map((item, index) => {
                if (item.deletedDate === null) {
                    return (
                        <Grid container key={index} className={classes.gridContainer}>
                            <Grid item xs={11} className={classes.dropDownLine}>
                                <ContactTypeField label={"Title"}
                                                  value={item?.contactTypeId}
                                                  contactTypeFilter={{}}
                                                  isPoc={true}
                                                  onChange={(val) => onChange(index, val, 'contactTypeId')}
                                                  required={true}
                                                  disabled={isRedOnly}/>
                            </Grid>
                            <Grid item xs={11} className={classes.dropDownLine}>
                                <CustomInput
                                    label="Name"
                                    identifier="name"
                                    required={item.isRequiredField}
                                    value={item?.name}
                                    onChange={(val) => onChange(index, val, 'name')}
                                    disabled={isRedOnly}
                                />
                            </Grid>
                            <Grid item xs={11} className={classes.dropDownLine}>
                                <CustomInput
                                    label="Email"
                                    identifier="email"
                                    required={item.isRequiredField}
                                    value={item?.email}
                                    onChange={(val) => onChangeEmail(index, val, 'email')}
                                    valid={item?.isEmailValid}
                                    disabled={isRedOnly}
                                />
                                {
                                    item?.email?.valid === false &&
                                    <p className={classes.invalid}>Invalid Email</p>
                                }
                            </Grid>
                            <Grid item xs={11} className={classes.dropDownLine}>
                                <PhoneInput
                                    label="Phone Number"
                                    identifier="phone"
                                    required={item.isRequiredField}
                                    value={item?.phone}
                                    onChange={(val) => onChange(index, val, 'phone')}
                                    disabled={isRedOnly}
                                />
                            </Grid>
                            <Grid item xs={11} className={classes.dropDownLine}>
                                <CustomInput
                                    label="Note"
                                    identifier="note"
                                    value={item?.note}
                                    onChange={(val) => onChange(index, val, 'note')}
                                    disabled={isRedOnly}
                                />
                            </Grid>
                            {
                                !isRedOnly && (
                                    <>
                                        <IconButton
                                            className={classes.deleteButton}
                                            aria-label="delete"
                                            onClick={() => onDelete(index)}
                                        >
                                            <IconDeleteBlue width={"25"} />
                                        </IconButton>
                                    </>
                                )
                            }
                        </Grid>
                    );
                }
                return null;
            })}
            {
                !isRedOnly && activeContactsCount < 3 && (
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={addNewContact}
                            className={classes.buttonSchedule}
                            startIcon={<AddIcon />}
                        >
                            Add POC
                        </Button>
                    </div>
                )
            }
        </>
    );
};

export default PocContacts;