import * as React from "react";

function IconActivityDefault(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.57563 2.67377L10.4041 7.50229C10.7064 7.8045 10.7064 8.29903 10.4041 8.60124L7.5194 11.486L2.1414 6.10799C1.32406 5.29065 1.32406 3.95817 2.1414 3.14082L2.60846 2.67377C3.4258 1.85642 4.75828 1.85642 5.57563 2.67377Z" stroke="#0090FE" stroke-width="1.2" stroke-linejoin="round" />
            <path d="M4.08862 4.62305L9.24647 9.78089" stroke="#AADAFF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.2096 15.4633L13.1125 17.9305L17.4058 20.5143L18.688 19.2224L19.9799 17.9305L17.4058 13.6372L14.9289 13.7441L12.6754 11.5002L10.9658 13.2098L13.2096 15.4633Z" stroke="#0090FE" stroke-width="1.2" stroke-linejoin="round" />
            <path d="M19.6981 8.26594C19.2998 9.20815 18.6005 10.0338 17.6291 10.5777C16.2692 11.3548 14.6957 11.384 13.3552 10.8206L7.51742 16.6584C7.70198 17.8531 7.12888 19.0867 6.01184 19.7278C4.55482 20.5535 2.69955 20.0386 1.8739 18.5816C1.04826 17.1246 1.56307 15.2693 3.02009 14.4437C3.62233 14.1037 4.30227 13.9872 4.94336 14.0843L10.7714 8.25623C9.81951 6.01242 10.684 3.34122 12.8695 2.12704C13.025 2.03962 13.1707 1.96191 13.3261 1.89392C14.5791 1.35968 15.9487 1.3791 17.1435 1.89392L13.9574 5.07993L14.3848 7.2266L16.5315 7.65399L19.7175 4.46798C20.2518 5.72102 20.2129 7.09061 19.6981 8.27565" stroke="#0090FE" stroke-width="1.2" stroke-linejoin="round" />
        </svg>

    );
}

export default IconActivityDefault;
