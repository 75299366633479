import { NoteContent } from "features/notes/NoteEditor";

const SingleNote = ({ note, handleNoteChange }) => {

    return (
        <NoteContent
            content={note?.content}
            viewByClient={note?.viewByClient}
            onChange={handleNoteChange}
            style={{ minHeight: "480px" }}
        />
    )
}

export default SingleNote;