import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import CopyToClipboard from "components/CopyToClipboard";
import IssueTrackerBarCounter from 'features/IssuesTracker/IssueTrackerBarCounter/IssueTrackerBarCounter';
import { IssueTrackerDateSelector } from 'features/IssuesTracker/IssueTrackerDateSelector/IssueTrackerDateSelector';
import { IssueTrackerLinks } from 'features/IssuesTracker/IssueTrackerLinks/IssueTrackerLinks';
import { IssueTrackerUser } from 'features/IssuesTracker/IssueTrackerUser/IssueTrackerUser';
import { LotSelector } from 'features/IssuesTracker/LotSelector/LotSelector';
import { LotTask } from 'features/IssuesTracker/LotTask/LotTask';
import { PrioritySelector } from 'features/IssuesTracker/PrioritySelector/PrioritySelector';
import { StatusSelector } from 'features/IssuesTracker/StatusSelector/StatusSelector';
import { IconEdit } from "icons";
import { KanbanTableStyle } from './KanbanTableStyle';

const KanbanCard = ({
    issue,
    setIssuesTrackerToEdit,
    dragHandle
}) => {
    const classes = KanbanTableStyle();

    return (
        <Box
            padding="1rem"
            borderRadius="6px"
            border="1px solid #e8f3fe"
            bgcolor="#fafcff"
            display="flex"
            flexDirection="column"
        >
            <Grid container direction='column' className={classes.cardWrapper}>
                {dragHandle}
                <Grid container xs={12} alignItems='center' justifyContent='space-between' className={classes.cardSection}>
                    <Grid item xs={8}>
                        <Typography variant="h6" className={classes.cardTitle}>
                            {issue?.title}
                        </Typography>
                    </Grid>
                    <Grid container direction='row' xs={4} alignItems='center'>
                        <Grid item>
                            <PrioritySelector
                                readonly
                                priority={issue?.priority}
                            />
                        </Grid>
                        <Grid item alignItems='center'>
                            <IconButton onClick={() => setIssuesTrackerToEdit(issue)}>
                                <IconEdit className={classes.IconEdit} />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid container className={classes.cardSection} alignItems='center'>
                    <Grid item>
                        <Typography className={classes.issueId}>
                            #{issue?.id}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CopyToClipboard title={'Copy link to issue'} text={`${window.location.origin}/ticket/${issue?.id}`} />
                    </Grid>
                </Grid>
                <Grid className={classes.cardSection}>
                    <LotTask />
                </Grid>
                <Grid className={classes.cardSection}>
                    <Box
                        width="100%"
                        borderRadius="4px"
                        border="1px solid #d5dbe0"
                        display="flex"
                        alignItems="center"
                    >
                        <Typography className={classes.category}>
                            {issue?.categoryName}
                        </Typography>
                    </Box>

                </Grid>
                <Grid className={classes.cardSection}>
                    <StatusSelector readonly status={issue?.status} />
                </Grid>
                <div className={classes.linksWrapper}>
                    <Grid className={classes.cardSection}>
                        <LotSelector lotName={issue?.lotName} />
                    </Grid>
                    <Grid className={classes.cardSection}>
                        <IssueTrackerLinks issue={issue} showAssets />
                    </Grid>
                    <Grid className={classes.cardSection}>
                        <IssueTrackerLinks issue={issue} showWorkorders />
                    </Grid>
                </div>

                <Grid className={classes.cardSection}>
                    <IssueTrackerDateSelector issue={issue} date={issue?.createdDate} readonly field='createdDate' />
                </Grid>
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid className={classes.usersOverlap}>
                        <IssueTrackerUser iconOnly userId={issue?.assignee} />
                    </Grid>
                    <Grid>
                        <IssueTrackerBarCounter days={issue?.timeSpentOnLastStatus} />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
};

export default KanbanCard;
