
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { useGetUserPermissionClaimsQuery } from "features/users/usersSlice";
import { useEffect, useState } from "react";
import { useSaveUserRolesConfigurationMutation } from "../../userManagementSlice";
import UsersPageStyle from "../UsersPageStyle";
import UserPermissionManagement from "./UserPermissionManagement";

const useStyles = UsersPageStyle;

const EditUserPermissions = ({ userId, onCancel, rolesData, user }) => {
    const classes = useStyles();
    let { data: userPermissions, error: userPermissionsError, isLoading: isLoadingUserPermissions } = useGetUserPermissionClaimsQuery(userId, { skip: !userId });
    let [saveUserRolesConfiguration] = useSaveUserRolesConfigurationMutation();

    const [userRoles, setUserRoles] = useState([]);
    const [permissionClaims, setPermissionClaims] = useState([]);
    const [saving, setSaving] = useState(false);


    const buildDataUserRoles = () => {
        const assignedRoles = [...user?.assignedRole];
        const allMyClaims = [...user?.assignedPermission];
        if (!assignedRoles || assignedRoles?.length === 0) return;
        let rolesFiltered = rolesData.filter( rol => assignedRoles[0].roleId === rol?.key);

        const rolesUpdated = rolesFiltered?.map((myRole) => {
            const categoriesUpdated = myRole?.categories?.map((category) => {
                const permissionsUpdated = category?.permisions?.map((myPermission) => {
                    const hasClaim = allMyClaims?.some((x) => x.permissionKey === myPermission?.key);
                    return {
                        ...myPermission,
                        isActivInMapping: hasClaim
                    }
                });
                return {
                    ...category,
                    permisions: permissionsUpdated
                }
            });
            return {
                ...myRole,
                categories: categoriesUpdated
            }
        });
        setUserRoles(rolesUpdated);
    }

    useEffect(() => {
        if (!user) return;
        const getRolesToShow = async () => {
            await buildDataUserRoles();
        }
        getRolesToShow();
    }, [user, rolesData]);

    useEffect(() => {
        if (!userPermissions?.data) return;
        setPermissionClaims(userPermissions?.data);
    }, [userPermissions?.data]);

    const handleSave = async (request) => {
        setSaving(true);
        let payload = {
            ...request,
            OldList: userRoles,
            ExistingClaims: permissionClaims,
            userId: userId,
        };

        let result = await saveUserRolesConfiguration(payload);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }
    }

    return (
        <>
            <LoadingSpinner loading={saving} />
            <UserPermissionManagement rolesWithAllData={userRoles} refetchRolesWithData={() => {}} handleSave={handleSave}/>
        </>
    );
}
export default EditUserPermissions;