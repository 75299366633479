import {createSlice} from "@reduxjs/toolkit";
import {apiTags} from '../../app/store/apiTags';
import {baseApi} from '../../app/baseApi';
import {baseQuery} from "../../app/baseQuery";
import FormatService from "../../services/formatService";

const apiPrefix = 'lot';
export const lotApi = baseApi.injectEndpoints({
    reducerPath: 'lotApi',
    tagTypes: [apiTags.LOTS],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getLots: builder.query({
            query: (countAssets) => `${apiPrefix}?countAssets=${countAssets ?? false}`,
            providesTags: [apiTags.LOTS, apiTags.LOT_SCHEDULE],
        }),
        getDeletedLots: builder.query({
            query: () => `${apiPrefix}/deleted`,
            providesTags: [apiTags.LOTS],
        }),
        updateLot: builder.mutation({
            query: (body) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.LOTS, apiTags.LOT_SCHEDULE],
        }),
        deleteLot: builder.mutation({
            query: (body) => ({
                url: `${apiPrefix}`,
                method: "DELETE",
                body
            }),
            invalidatesTags: [apiTags.LOTS],
        }),
        getAttachments: builder.query({
            query: (lotId) => lotId ? `${apiPrefix}/${lotId}/attachment` : undefined,
            providesTags: [apiTags.LOT_ATTACHMENTS],
        }),
        uploadAttachment: builder.mutation({
            query: files => ({
                url: `${apiPrefix}/attachment`,
                method: "POST",
                body: {
                    attachments: files
                }
            }),
            invalidatesTags: [apiTags.LOT_ATTACHMENTS, apiTags.LOT_MAP_URL, apiTags.LOT_MAP]
        }),
        deleteLotAttachment: builder.mutation({
            query: id => ({
                url: `${apiPrefix}/attachment/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [apiTags.LOT_ATTACHMENTS]
        }),
        getSiteSizing: builder.query({
            query: () => `lot/siteSizing`,
            providesTags: [apiTags.LOTS],
        }),
        addSiteSizing: builder.mutation({
            query: siteSizing => ({
                url: `lot/siteSizing`,
                method: "POST",
                body: {
                    siteSizing
                }
            }),
            invalidatesTags: [apiTags.LOTS, apiTags.LOT_SCORES],
        }),
        updateSiteSizing: builder.mutation({
            query: siteSizing => ({
                url: `lot/siteSizing`,
                method: "PUT",
                body: {
                    siteSizing
                }
            }),
            invalidatesTags: [apiTags.LOTS, apiTags.LOT_SCORES],
        }),
        deleteSiteSizing: builder.mutation({
            query: siteSizing => ({
                url: `lot/siteSizing`,
                method: "DELETE",
                body: {
                    siteSizing
                }
            }),
            invalidatesTags: [apiTags.LOTS, apiTags.LOT_SCORES],
        }),
        getMap: builder.query({
            query: (lotId) => lotId ? `${apiPrefix}/${lotId}/map` : undefined,
            providesTags: [apiTags.LOT_MAP_URL],
        }),
        getMapUrl: builder.query({
            query: (lotId) => lotId ? `${apiPrefix}/${lotId}/mapUrl` : undefined,
        }),
        getSummary: builder.query({
            query: (lotId) => lotId ? `${apiPrefix}/${lotId}/summary` : undefined,
            providesTags: [apiTags.LOT_SUMMARY],
        }),
        getSummaryLeasedPerClient: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${apiPrefix}/summary/leasedPerClient?${queryString}`
            },
            providesTags: [apiTags.LOT_SUMMARY]
        }),
        getInvoiceReports: builder.mutation({
            query: reports => ({
                url: `lot/InvoiceReport`,
                method: "POST",
                body: reports,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const fileURL = window.URL.createObjectURL(blob);
                    const reportDate = FormatService.formatDateAndTimeWithFormat(new Date(reports.Year, reports.Month - 1), 'yyyy-MM');

                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = `Invoice_Reports_${reportDate}.zip`;
                    alink.click();
                },
                cache: "no-cache"
            })
        }),
        getMovementPerDayPerLotReport: builder.mutation({
            query: report => ({
                url: `lot/numberOfMovementPerDayPerLot`,
                method: "POST",
                body: report,
                responseHandler: async (response) => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'Number Of Movement Per Day Per Lot.csv';
                        alink.click();
                    })
                    //window.location.assign(window.URL.createObjectURL(await response.blob()))
                },
                cache: "no-cache"
            })
        }),
        getLotScores: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${apiPrefix}/scores?${queryString}`
            },
            providesTags: [apiTags.LOT_SCORES, apiTags.LOT_AUDIT]
        }),
        getAllSubRegions: builder.query({
            query: () => `region/subregions`,
        }),
        updateStorageInvoice: builder.mutation({
            query: option => ({
                url: `storageInvoice/generateStorageInvoice`,
                method: "POST",
                body: option
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        getTimeZones: builder.query({
            query: () => `lot/timeZones`,
        }),
        getDistanceReport: builder.mutation({
            query: body => ({
                url: `${apiPrefix}/distanceReport`,
                method: "POST",
                body
            }),
        }),
        getImportLotsTemplate: builder.mutation({
            query: body => ({
                url: `${apiPrefix}/downloadtemplate`,
                method: "POST",
                body: body,
                cache: "no-cache"
            })
        }),
        importLots: builder.mutation({
            query: assets => ({
                url: `${apiPrefix}/import`,
                method: "POST",
                body: assets
            }),
        }),
        getClientLots: builder.query({
            query: (lotId) => lotId ? `${apiPrefix}/${lotId}/clientLots` : undefined,
        })        
    }),
});

export const {
    useGetLotsQuery,
    useGetClientLotsQuery,
    useGetDeletedLotsQuery,
    useUpdateLotMutation,
    useDeleteLotMutation,
    useGetAttachmentsQuery,
    useUploadAttachmentMutation,
    useDeleteLotAttachmentMutation,
    useGetSiteSizingQuery,
    useAddSiteSizingMutation,
    useUpdateSiteSizingMutation,
    useDeleteSiteSizingMutation,
    useGetMapQuery,
    useGetMapUrlQuery,
    useGetSummaryQuery,
    useGetSummaryLeasedPerClientQuery,
    useGetInvoiceReportsMutation,
    useGetMovementPerDayPerLotReportMutation,
    useGetLotScoresQuery,
    useGetAllSubRegionsQuery,
    useUpdateStorageInvoiceMutation,
    useGetTimeZonesQuery,
    useGetDistanceReportMutation,
    useGetImportLotsTemplateMutation,
    useImportLotsMutation
} = lotApi;


export const subMarketsSlice = createSlice({
    name: 'subMarketsSlice',
    initialState: {
        searchVal: '',
        taskPanelOpen: false,
        showDomiciledOnly: false,
        filters: {
            'subMarket': {value: null},
            'locationsFilter' : {value: []}
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = {...state.filters, [action.payload.key]: {value: action.payload.value}}
        },
    },
});

export const {setSearchVal, setFilters,
    setFilter} = subMarketsSlice.actions;

