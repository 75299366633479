import {useState} from "react";

function useSortOptions() {
    const sortDirections = {
        asc: "asc",
        desc: "desc",
    };

    const [sortOptions, setSortOptions] = useState({
        columnToSort: '',
        sortDirection: sortDirections.asc
    });

    const sortData = columnKey => {
        let columnToSort = columnKey;
        let sortDirection = sortDirections.asc;

        if (sortOptions.columnToSort === columnKey && sortOptions.sortDirection === sortDirections.asc) {
            columnToSort = columnKey;
            sortDirection = sortDirections.desc;
        }
        if (sortOptions.columnToSort === columnKey && sortOptions.sortDirection === sortDirections.desc) {
            columnToSort = '';
            sortDirection = sortDirections.asc;
        }

        setSortOptions({columnToSort, sortDirection});
    };

    return [sortOptions, sortData];
}

export default useSortOptions;
