import { makeStyles } from "@material-ui/core";

export const PricingListStyle = makeStyles((theme) => ({
    errorIcon: {
        fill: 'white',
    },
    errorIconWrapper: {
        background: '#fd8f8f',
    },
    errorTooltip: {
        padding: '2em',
        width: '500px'
    },
    card: {
        margin: '1rem 0',
        padding: '2rem ',
        paddingBottom: '5rem',
        height: '100%'
    },
    titleWrapper: {
        margin: '1rem 0'
    },
    editField: {
        padding: '0 1rem 0 0',
        paddingLeft: "1rem",
        marginLeft: '-14px',
        width: '100%',
        '& .MuiOutlinedInput-input': {
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '0.875rem',
            // backgroundColor: 'white',
            // border: "solid 1.5px #EFEFEF",
        }
    },
    field: {
        paddingLeft: "1rem",
    },
    input: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none',
        }
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    options: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
    },
    checkbox: {
        marginLeft: '10rem'
    },
    warningIcon: {
        paddingLeft: "3.5rem",        
        '& path': {
            stroke: '#FF0000',
        }
      },
}));