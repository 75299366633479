import { Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { readinessStatuses } from "features/vehicles/components/AssetReadiness/readinessUtil";
import { editReadinessStyles } from "./style";

export const ReadinessItem = ({ option, onChange }) => {
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    const classes = editReadinessStyles();

    const statuses = [
        {
            id: readinessStatuses.GREEN,
            name: option?.statusText1
        },
        {
            id: readinessStatuses.YELLOW,
            name: option?.statusText2
        },
        {
            id: readinessStatuses.RED,
            name: option?.statusText3
        }
    ];

    return (
        <Grid key={option.id} container alignItems="center" spacing={1}>
            <Grid xs={4} item>
                {activityItems?.find(a => a.id === option.activityItemId)?.name}
            </Grid>
            <Grid xs={8} item container>
                <CustomInput
                    label="Status"
                    elementType="dropdown"
                    values={statuses}
                    required={true}
                    touched
                    value={option?.status}
                    onChange={val => onChange(option, val)}
                />
            </Grid>

        </Grid >
    );
}