import { Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DropdownFilter from 'components/DropdownFilter';
import LoadingSpinner from "components/LoadingSpinner";
import { selectIsAccountManager, selectIsAdmin, selectIsOwner, selectUserId } from "features/user/userSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";
import { useGetAllRelationsQuery } from 'features/users/usersSlice';
import { IconClipboard } from "icons";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserTaskGroups from "../UserTaskGroups/UserTaskGroups";
import { LAsTaskListStyle } from './style';

const LAsTaskList = () => {
    const classes = LAsTaskListStyle();

    let isOwner = useSelector(selectIsOwner);
    let isAccountManager = useSelector(selectIsAccountManager);
    let userId = useSelector(selectUserId);

    const [selectedLA, setSelectedLA] = useState(null);
    const [dropdownValues, setDropdownValues] = useState([]);

    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    allRelations = allRelations || [];



    useEffect(() => {
        if (!isLoadingAllRelations && allRelations) {
            if (!isOwner && userId) {
                let userRelations = allRelations?.filter(relation => relation.userId == userId);
                if (isAccountManager && userRelations?.some(a => a.relationType === relationTypeEnumById.SUPERVISOR)) {
                    const lots = userRelations?.map(a => a.lotId) ?? [];
                    allRelations = allRelations?.filter(a => lots.includes(a.lotId));
                } else {
                    allRelations = userRelations
                }
            }
            let lotAttendant = [...new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.ATTENDANT))];
            const groupedToUserId = groupBy(lotAttendant, 'userId');
            setDropdownValues(Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].name ?? groupedToUserId[key]?.[0].userName }))?.filter(u => u.name));
            setSelectedLA(lotAttendant?.length > 0 && (isAccountManager || isOwner) ? null : userId);
        }
    }, [allRelations, isLoadingAllRelations]);

    const isLoading = () => isLoadingAllRelations;

    return (
        <Authorize profile={permissionProfiles.TASKS.USERS_TASK_LIST}>
            {
                isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                    <>
                        <Paper className={classes.card}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <IconClipboard />
                                        </Grid>
                                        <Grid item>
                                            <h3 className={classes.title}>LA Tasks</h3>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {(isAccountManager || isOwner) && <Grid item xs={3} className={classes.supersFilter}>
                                    <DropdownFilter
                                        title={'Select LA'}
                                        values={dropdownValues}
                                        onSelect={(event) => setSelectedLA(event)}
                                        value={selectedLA}
                                        showEmpty
                                    />
                                </Grid>}
                            </Grid>
                        </Paper>

                        <Grid item>
                            <Paper className={classes.card}>
                                <Grid container direction="column" spacing={1}>
                                    <UserTaskGroups
                                        selectedUser={selectedLA}
                                        lotId={allRelations?.find(u => u.userId == selectedLA)?.lotId}
                                    />
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
            }
        </Authorize>

    );
}
export default LAsTaskList;