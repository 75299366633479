import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import {Button, Dialog, DialogTitle} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DropZoneInDialog from "../../../../components/DropZoneInDialog";
import {useState} from "react";
import formStyles from "./Styles";
import NotificationProvider from "../../../../components/NotificationProvider";
import {useImportLotsMutation} from "../../lotSlice";
import ImportLotsTemplateDialog from "./ImportLotsTemplateDialog";
import ImportLotsResultsDialog from "./ImportLotResultDialog";

const ImportLots = ({reloadLots}) => {
    const classes = formStyles();

    let [importLots, { data: importedData, error: importError, isLoading: importLoading }] = useImportLotsMutation();
    
    const [openDialog, setOpenDialog] = useState(false);
    const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const onCloseDialog = () => {
        setOpenDialog(false);
    }

    const downloadTemplate = async () => {
        setOpenTemplateDialog(true);
    }

    const onSave = async (file) => {
        let fd = new FormData();
        fd.append("uploaded_file", file);

        const res = await importLots(fd);
        if (res.error) {
            NotificationProvider.error("Failed to import lots");
            NotificationProvider.error(res.error?.data?.[0]);
        } else {
            if (res?.data != []) {
                setOpenDialog(false);
                setShowResults(true);
            }
        }
    }

    return (
        <>
            <Authorize profile={permissionProfiles.LOT.LOT_IMPORT}>
                <Dialog open={openDialog} onClose={() => onCloseDialog()} aria-labelledby='dialog-title'>
                    <DialogTitle>
                        <div className={classes.dialogWrapper}>
                            <div className={classes.dialogTitle}> Successfully imported lots:</div>
                            <IconButton aria-label="close" onClick={() => onCloseDialog()}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                </Dialog>

                
                {/*ImportLotsTemplate*/}
                <DropZoneInDialog
                    className={classes.btn}
                    btnStyle="outlined"
                    labelDialog={'Import Lots'}
                    buttonLabel={'Import Lots'}
                    onSave={onSave}
                    formContent={
                        <>
                            <Button
                                color="primary"
                                className={classes.downloadButton}
                                onClick={downloadTemplate}>
                                Download Template
                            </Button>
                        </>}
                />

                <ImportLotsResultsDialog
                    importResults={importedData}
                    open={showResults || importLoading}
                    setOpen={setShowResults}
                    loading={importLoading}
                    error={importError}
                    reloadLots={reloadLots}
                />
                
                <ImportLotsTemplateDialog
                    open={openTemplateDialog}
                    setOpen={setOpenTemplateDialog}
                />

            </Authorize>
        </>
    )
}

export default ImportLots;