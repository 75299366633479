import BasicMarker from '../BasicMarker/BasicMarker';
import { BasicMarkerStyle } from '../BasicMarker/BasicMarkerStyle';

const MixedMarker = ({ color='#3f8ef6', text, number, textColor, wrapperSize = '40', iconSize = '24' }) => {

    const classes = BasicMarkerStyle();
    return (
        <BasicMarker
            color={color}
            text={text}
            textColor={textColor}
            wrapperSize={wrapperSize}
            icon={<div height={iconSize} width={iconSize} className={`${classes.lotIcon} ${classes.mixedNumber}`} >{number}</div>}
        />
    )
}
export default MixedMarker;
