import { makeStyles } from "@material-ui/core";

export const IssueTrackerLinksStyle = makeStyles((theme) => ({
    ticketWrapper: {
        padding: '5px',
        border: '1px solid #BF8F00',
        borderRadius: '5px',
    },
    link: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
        color: '#BF8F00',
    },
    addWrapper: {
        border: '1px solid #BF8F00',
        borderRadius: '5px',
    },
    checkbox: {
        '& label': {
            fontSize: '0.8em !important',
        }
    }
}))