import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    paper: {
        padding: "20px 20px 20px",
        color: theme.palette.common.darkText,
        marginBottom: '10px',
        minHeight: '320px',
    },
    label: {
        color: theme.palette.primary.main,
        fontSize: '1.3em',
        padding: '.5em .5em 0.5em .5em'
    },
    taskHeader: {
        padding: '1em',
        marginBottom: '0.5em',
        background: theme.palette.background.default,
        // fontWeight: 'bold',
        minHeight: '3em'
    },
    taskItemWrapper: {
        width: '100%',
        padding: '1rem'
    },
    icon: {
        padding: '1rem'
    },
    taskOnWrapper: {
        borderLeft: '5px solid rgb(166, 239, 105)'
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
    scheduleWrapper: {
        padding: '1rem',

    },
    scheduleTitle: {
        color: theme.palette.primary.main,
        fontSize: 17,
    },
    intervalToggle: {
        margin: '0 1rem',
    },
    scheduleTitleWrapper: {
        marginBottom: '1rem'
    },
    day: {
        fontWeight: 500,
        fontSize: 15,
        marginRight: '1rem',
    },
    daySchedule: {
        '&:last-of-type': {
            border: 'none'
        },
        margin: '0.5rem 0'
    },
    button: {
        margin: '0.5rem'
    },
    addButton: {
        margin: '0.5rem 0'
    },
    error: {
        color: 'red',
        fontWeight: 500,
        fontSize: 16,
        marginLeft: '2rem',
    }
}));