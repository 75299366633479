import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CostCodeStyles from "./CostCodeStyles";
import CostCodesList from "./CostCodesList";
import { CostCodeTypes, costCodesById } from "./consts";
import NotificationProvider from "components/NotificationProvider";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";
import { isEmpty } from "lodash";
import BulkActionsToolbar from "components/DataTable/BulkActionsToolbar";
import BulkUpdateCostCodes from "./BulkUpdateCostCodes";
const { useGetClientsQuery } = require("features/clients/clientSlice");
const { useGetClientsCostCodesQuery, useUpdateCostCodesMutation } = require("./costCodeSlice");
const { selectUserClientId, selectIsClient } = require("features/user/userSlice");
const { Paper, Grid, Button } = require("@material-ui/core");
const { default: LoadingSpinner } = require("components/LoadingSpinner");
const { default: CustomInput } = require("components/CustomInput");

const useStyles = CostCodeStyles;

const ResetEditingContext = createContext();

const useResetEditing = () => {
  return useContext(ResetEditingContext);
};

const CostCodesPage = () => {
    const classes = useStyles();

    let loggedInClientClaim = useSelector(selectUserClientId);
    const isClient = useSelector(selectIsClient);

    const [currentClient, setCurrentClient] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const [disableUpdate, setDisableUpdate] = useState(true);

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, {skip: isClient});
    let { data: costCodes, error: costCodeError, isFetching: isFetchingCodes, refetch: refetchCodes } = useGetClientsCostCodesQuery({clientId: parseInt(loggedInClientClaim) > 0 ? parseInt(loggedInClientClaim) : selectedClient}, {skip: (!selectedClient && loggedInClientClaim === "")});
    clients = clients || [];
    costCodes = costCodes || [];
    
    let isLoading = () => isLoadingClients || isFetchingCodes;

    let [updateCodes, {isLoading: isSaving}] = useUpdateCostCodesMutation();
    
    const [codesToSave, setCodesToSave] = useState(costCodes);
    const [resetEditing, setResetEditing] = useState(false); //used after codes are saved to reset the isEditing state in CostCodeItem for each item to false
    const [selectedRows, setSelectedRows] = useState([]); //for bulkUpdate

    const triggerResetEditing = () => {
        setResetEditing(true);
        setTimeout(() => setResetEditing(false), 0);
    };
      
    useEffect(() => {
        setCodesToSave(costCodes?.length ? costCodes : []);
    }, [isFetchingCodes])

    useEffect(() => {
        if(!isEmpty(loggedInClientClaim)) {
            setCurrentClient(parseInt(loggedInClientClaim));
        }
    }, [loggedInClientClaim])

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const onEditCode = (code) => {
        let codesList = !!codesToSave?.length ? [...codesToSave] : [];
        let newItem = {...code};
        newItem.clientId = currentClient;
        
        let indexOfCode;
        if(!!codesList.length) //finding the index of the code we're editing or deleting
        {
            if(code?.costCodeType === costCodesById.ACTIVITIES) {
                indexOfCode = codesList?.findIndex(i => i.activityItemId === code?.activityItemId);
            } else if (code?.costCodeType === costCodesById.STORAGE) {
                indexOfCode = codesList?.findIndex(i => i.storageInvoiceField === code?.storageInvoiceField);
            } else if (code?.costCodeType === costCodesById.TRANSPORT) {
                indexOfCode = codesList?.findIndex(i => i.transportField === code?.transportField);
            }        
        }

        if(indexOfCode != undefined && indexOfCode != -1) 
        { 
            newItem.id = codesList[indexOfCode]?.id; //find the original id of the code we're editing
        
            if(isEmpty(code?.costCode.trim())) { //if its being removed
                codesList = codesList.filter((c, index) => index !== indexOfCode)
            } else {
                codesList[indexOfCode] = {...newItem}; //if we're editing an existing code
            }
        } else { 
            codesList.push(newItem); //if we're adding a code
        }

        setCodesToSave(codesList);
    }

    const onClickClient = async () => {
        await refetchCodes({clientId: selectedClient})
        setCurrentClient(selectedClient);
        setDisableUpdate(true);
    }

    const onClickSave = async () => {
        let result = await updateCodes({ clientId: currentClient, costCodes: codesToSave, bulkEdit: false });

        if (result && result.error) {
            NotificationProvider.error(`Failed to save changes made to cost codes`);
        } else {
            NotificationProvider.success(`Successfully saved cost codes`);
            triggerResetEditing();
            setDisableUpdate(true);
        }
    }

    const toggleRowSelected = (checked, id, costCodeType) => {
        let object = { id: id, costCodeType: costCodeType };
        let selected = [...selectedRows]
        const indexToRemove = selected?.findIndex(s => JSON.stringify(s) === JSON.stringify(object));
        if (indexToRemove > -1) {
            selected.splice(indexToRemove, 1);
        }
        if (checked) {
            selected.push(object);
        }
        setSelectedRows(selected);
    }

    const findSelectedCostCodes = () => {
        //find and create the cost code entities to save in bulk from the selectedRows
        const selectedItems = [];

        selectedRows.forEach(r => {
            const existingItem = codesToSave?.find(c => 
                (r.costCodeType === costCodesById.ACTIVITIES && c.activityItemId === r.id) ||
                (r.costCodeType === costCodesById.STORAGE && c.storageInvoiceField === r.id) ||
                (r.costCodeType === costCodesById.TRANSPORT && c.transportField === r.id)
            );

            if (existingItem) {
                selectedItems.push(existingItem);
            } else {
                const newCostCode = {
                    costCodeType: r.costCodeType,
                    activityItemId: r.costCodeType === costCodesById.ACTIVITIES ? r.id : null,
                    storageInvoiceField: r.costCodeType === costCodesById.STORAGE ? r.id : null,
                    transportField: r.costCodeType === costCodesById.TRANSPORT ? r.id : null,
                    clientId: currentClient
                };
                selectedItems.push(newCostCode);
            }
        });

        return selectedItems;
    }

    return (
        <Authorize profile={permissionProfiles.REPORTING.VIEW_COST_CODES_PAGE}>
            <Paper className={classes.paper}>
                <LoadingSpinner loading={isLoading()} />
                    <div className={classes.pageContent}>
                        {!isClient && <>
                            <div className={classes.title}>Search Client</div>
                            <Grid container spacing={2} >
                                <Grid item xs={6}>
                                    <CustomInput
                                        id="clientId"
                                        label="Client"
                                        value={selectedClient}
                                        elementType="dropdown"
                                        onChange={val => {
                                            setSelectedClient(+val);
                                            setCurrentClient();
                                            setSelectedRows([]);
                                        }}      
                                        values={clients}
                                        showEmpty
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={onClickClient}
                                    >
                                        Go to Client Codes
                                    </Button>                            
                                </Grid>
                        </Grid>
                        </>}
                        {currentClient && <>
                           <ResetEditingContext.Provider value={{resetEditing, triggerResetEditing}}>
                            <Grid container direction='row' className={classes.updateButton} justifyContent="space-between">
                                <Grid item className={isClient ? classes.title : classes.subHeader}>{(!isClient ? clientLookup[currentClient]?.name : "" )+ " Cost Codes"}</Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        color='primary' 
                                        onClick={onClickSave}
                                        disabled={isSaving || disableUpdate}
                                    >
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.cardGrid} direction='row'>
                                {CostCodeTypes.map(type => 
                                    <Grid item xs={4}>
                                        <CostCodesList 
                                            costCodeType={type} 
                                            codes={!!codesToSave.length ? codesToSave?.filter(c => c.costCodeType === type?.id) : []} 
                                            setDisableUpdate={setDisableUpdate}
                                            onEditCode={onEditCode}
                                            selectedRows={selectedRows}
                                            toggleRowSelected={toggleRowSelected}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            </ResetEditingContext.Provider></>
                        }
                        {!!selectedRows?.length && <div className={classes.toolbar}><BulkActionsToolbar
                        allData={codesToSave}
                        selectedRows={selectedRows}
                        actions={[
                            <BulkUpdateCostCodes
                                selectedItems={findSelectedCostCodes()}
                                clientId={currentClient}
                                setSelectedRows={setSelectedRows}
                            />
                        ]}
                        onClear={() => setSelectedRows([])}
                    /></div>}
                    </div>
            </Paper>
        </Authorize>
    );
};

export default CostCodesPage;
export { useResetEditing };