
import { DialogContent, IconButton } from "@material-ui/core";
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog } from "@mui/material";
import { useState } from "react";
import RoleConfiguration from "./RoleConfiguration";


const RoleConfigurationDialog = ({ role, allRoles, refetchRolesWithData }) => {

    const [showList, setShowList] = useState(false);

    const onCancel = () => {
        setShowList(false);
    }

    return (
        <>
            <IconButton size="small" onClick={() => setShowList(true)}><SettingsIcon /></IconButton>
            <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                <DialogContent>
                    <RoleConfiguration role={role} allRoles={allRoles} refetchRolesWithData={refetchRolesWithData} onCancel={onCancel}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RoleConfigurationDialog;