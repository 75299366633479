import { Grid } from "@material-ui/core";
import { LotTaskStyle } from "./style";
import { IconLocationTick } from "icons";

export const LotTask = ({
    lotId,
    lotName
}) => {
    const classes = LotTaskStyle();

    return (<Grid container alignItems="center">
        <Grid item>
            <IconLocationTick />
        </Grid>
        <Grid item className={classes.text}>Lot Task</Grid>
    </Grid>
    )
}
