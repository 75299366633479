import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { activityTableStyle } from "./style";
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import ApproveAbovePriceCap from 'features/vehicles/Activities/ApproveActivity/ApproveAbovePriceCap';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';

export const ActivityPriceError = ({ activity }) => {
    const classes = activityTableStyle();
    const [anchorEl, setAnchorEl] = useState(false);

    return (
        <>
            <Authorize profile={permissionProfiles.ACTIVITY.ABOVE_CAP_VIEW}>
                {activity?.abovePriceCap && !activity?.cancelledDate && !activity?.approvedDate ?
                    <Tooltip title={'The price defined is above the client price cap and requires further confirmation. Click for more information'}>
                        <IconButton size='small' className={classes.errorIconWrapper} onClick={(e) => setAnchorEl(e.target)}>
                            <ErrorOutlineIcon className={classes.errorIcon} />
                        </IconButton>
                    </Tooltip> : <></>}
                <Popover

                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.errorTooltip}>
                        <p><b>The price defined is above the client price cap and requires further confirmation</b></p>
                        <ApproveAbovePriceCap activityLog={activity} />
                    </div>
                </Popover>
            </Authorize>
        </>

    )
}