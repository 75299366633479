import FormatService from "services/formatService";
import { IssueTrackerDashboardStyle } from "../style";

export const AvgCloseTimeWidget = ({ dashboardData }) => {
    const classes = IssueTrackerDashboardStyle();
    return (<>
        <div className={classes.cardCount}>{FormatService.formatDuration(dashboardData?.averageTimeToResolution)}</div>
        <div className={classes.cardText}>Avg Time to Resolution</div>
    </>
    )
}