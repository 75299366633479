import * as React from "react";

function SvgIconDollar(props, width, height) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5625 7.0625C8.1875 6.96875 7.8125 6.78125 7.53125 6.5C7.25 6.40625 7.15625 6.125 7.15625 5.9375C7.15625 5.75 7.25 5.46875 7.4375 5.375C7.71875 5.1875 8 5 8.28125 5.09375C8.84375 5.09375 9.3125 5.375 9.59375 5.75L10.4375 4.625C10.1562 4.34375 9.875 4.15625 9.59375 3.96875C9.3125 3.78125 8.9375 3.6875 8.5625 3.6875V2.375H7.4375V3.6875C6.96875 3.78125 6.5 4.0625 6.125 4.4375C5.75 4.90625 5.46875 5.46875 5.5625 6.03125C5.5625 6.59375 5.75 7.15625 6.125 7.53125C6.59375 8 7.25 8.28125 7.8125 8.5625C8.09375 8.65625 8.46875 8.84375 8.75 9.03125C8.9375 9.21875 9.03125 9.5 9.03125 9.78125C9.03125 10.0625 8.9375 10.3438 8.75 10.625C8.46875 10.9062 8.09375 11 7.8125 11C7.4375 11 6.96875 10.9062 6.6875 10.625C6.40625 10.4375 6.125 10.1562 5.9375 9.875L5 10.9062C5.28125 11.2812 5.5625 11.5625 5.9375 11.8438C6.40625 12.125 6.96875 12.4062 7.53125 12.4062V13.625H8.5625V12.2188C9.125 12.125 9.59375 11.8438 9.96875 11.4688C10.4375 11 10.7188 10.25 10.7188 9.59375C10.7188 9.03125 10.5312 8.375 10.0625 8C9.59375 7.53125 9.125 7.25 8.5625 7.0625ZM8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 14.4688C4.4375 14.4688 1.53125 11.5625 1.53125 8C1.53125 4.4375 4.4375 1.53125 8 1.53125C11.5625 1.53125 14.4688 4.4375 14.4688 8C14.4688 11.5625 11.5625 14.4688 8 14.4688Z" fill="#0090FE" />
        </svg>

    );
}

export default SvgIconDollar;
