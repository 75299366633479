import ExportButton from "components/ExportButton/ExportButton";
import { activityStatuses } from "features/activity/activityConsts";
import { IconDownload } from "icons";
import moment from "moment";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import FormatService from "services/formatService";
import { theme } from "styles/theme";
import GraphHeader from "../GraphHeader/GraphHeader";
import { GraphLoader } from "../GraphLoader";
import { workorderDashboardPageStyles } from "../WorkorderDashboardPageStyles";
import { dashboardColors } from "../workorderDashboardConsts";

const WorkorderCostTrendGraph = ({ workorders, loading }) => {
    const classes = workorderDashboardPageStyles();

    const completed = workorders?.filter(a => a.activityStatus == activityStatuses.ItemCompleted);

    const dataGrouped = {};
    completed?.forEach(req => {
        let displayDate = req.activityMonth;//moment(req.activityDate).format('YYYYMM').toString();
        if (!dataGrouped[displayDate]) {
            dataGrouped[displayDate] = {
                displayDate: displayDate,
                charge: 0,
                name: displayDate
            };
        }
        dataGrouped[displayDate].charge += req.revenue;
    });

    let sortedReqs = Object.values(dataGrouped)?.sort((a, b) => (a?.date - b?.date));

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.graphTootltip}>
                <p>
                    <div><b>{formatDate(payload?.[0]?.payload?.name)} </b></div>
                    <div>{FormatService.formatCurrency(payload?.[0]?.payload?.charge)}</div>
                </p>
            </div>
        );
    };

    const formatDate = (date) => {
        return moment(date).format('MMM YY').toString();
    }


    const mapExportData = (a => {
        return {
            ['Month']: formatDate(a.displayDate),
            ['Charge']: FormatService.formatCurrency(a.charge)
        }
    })

    return (
        <div style={{ height: 350 }}>
            <GraphHeader
                title={'Cost Trend Overtime'}
                rightContent={<ExportButton
                    className={classes.exportButton}
                    btnStyle='text' label=""
                    icon={<IconDownload />}
                    fileLabel="Export"
                    data={sortedReqs}
                    mapExportData={mapExportData}
                />} />
            <ResponsiveContainer width="100%" height="80%">
                <AreaChart
                    data={sortedReqs}
                >
                    <defs>
                        <linearGradient id="colorCharge" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={dashboardColors?.[2]} stopOpacity={0.6} />
                            <stop offset="95%" stopColor={dashboardColors?.[3]} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        tickLine={false}
                        axisLine={false}
                        dataKey="displayDate"
                        tick={{ color: theme.palette.primary.main, fontSize: '0.7em' }}
                        tickFormatter={date => {
                            return formatDate(date).toUpperCase()
                        }}
                    />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tick={{ color: theme.palette.primary.main, fontSize: '0.7em' }}
                        tickFormatter={amount => {
                            return FormatService.formatCurrency(amount)
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area type="monotone" dataKey="charge" stackId="1" stroke={dashboardColors?.[2]} fillOpacity={0.8} fill="url(#colorCharge)" />
                </AreaChart>
            </ResponsiveContainer>
            <GraphLoader loading={loading} />
        </div>
    )
}

export default WorkorderCostTrendGraph;
