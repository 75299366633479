import * as React from "react";

function IconAddCircle(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99984 18.4582C5.33431 18.4582 1.5415 14.6654 1.5415 9.99984C1.5415 5.33431 5.33431 1.5415 9.99984 1.5415C14.6654 1.5415 18.4582 5.33431 18.4582 9.99984C18.4582 14.6654 14.6654 18.4582 9.99984 18.4582ZM9.99984 1.7915C5.47369 1.7915 1.7915 5.47369 1.7915 9.99984C1.7915 14.526 5.47369 18.2082 9.99984 18.2082C14.526 18.2082 18.2082 14.526 18.2082 9.99984C18.2082 5.47369 14.526 1.7915 9.99984 1.7915Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M13.3332 10.125H6.6665C6.63636 10.125 6.60508 10.1127 6.57943 10.0871C6.55379 10.0614 6.5415 10.0301 6.5415 10C6.5415 9.96985 6.55379 9.93857 6.57943 9.91293C6.60508 9.88728 6.63636 9.875 6.6665 9.875H13.3332C13.3633 9.875 13.3946 9.88728 13.4202 9.91293C13.4459 9.93857 13.4582 9.96985 13.4582 10C13.4582 10.0301 13.4459 10.0614 13.4202 10.0871C13.3946 10.1127 13.3633 10.125 13.3332 10.125Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M10 13.4582C9.96985 13.4582 9.93857 13.4459 9.91293 13.4202C9.88728 13.3946 9.875 13.3633 9.875 13.3332V6.6665C9.875 6.63636 9.88728 6.60508 9.91293 6.57943C9.93857 6.55379 9.96985 6.5415 10 6.5415C10.0301 6.5415 10.0614 6.55379 10.0871 6.57943C10.1127 6.60508 10.125 6.63636 10.125 6.6665V13.3332C10.125 13.3633 10.1127 13.3946 10.0871 13.4202C10.0614 13.4459 10.0301 13.4582 10 13.4582Z" fill="#0090FE" stroke="#0090FE" />
    </svg>

  );
}

export default IconAddCircle;
