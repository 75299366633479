import { makeStyles } from "@material-ui/core";

export const InspectionsTimeFrameStyles = makeStyles((theme) => ({
    timeFrameContainer: {
        marginTop: '1rem',
        //paddingRight: '70%',
        paddingBottom: '0.5em 0',
        marginBottom: '1em'
    },
    dateRange: {
        maxWidth: 300,
        marginBottom: '1em'
    },
    report: {
        paddingLeft: '1rem',
    }

}));
