import {useDispatch} from "react-redux";
import {logOut} from "../user/userSlice";
import userManager from "../../app/userManager";

function LogoutPage () {
    
    let dispatch = useDispatch();
    const logout = () => {
        dispatch(logOut());
        userManager.clearStaleState().then(r => {
            userManager.signoutRedirect()
                .then(() => { });
        });
    }

    logout();    
}
export default LogoutPage;
