import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect, useState } from 'react';

const DeleteUserCheckBox = ({ user, onDeleteUser }) => {
    const [userDeleted, setUserDeleted] = useState(null);

    useEffect(() => {
        if (user) {
            setUserDeleted(user?.isPurged);
        }
    }, [user]);
    
    return (
        <IconButton color='primary' disabled={userDeleted == null} onClick={() => onDeleteUser(user)} ><DeleteIcon /></IconButton>
    );
};

export default DeleteUserCheckBox;
