import { Box, Grid } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import { uniqBy } from "lodash";
import { arrayToObject } from "utils/object-util";
import { addLegStyle } from "../AddLoadLeg/style";

export const LoadAssetSelector = ({ loadGroup, setSelectedAsset, selectedValue, isNewLoad, allowNewAsset }) => {
    const classes = addLegStyle();

    const assets = isNewLoad ? loadGroup?.loads?.map(l => ({ id: l.uuid, vin: l.vin, assetId: l.assetId })) : [...uniqBy(loadGroup?.loads?.filter(l => l.assets?.length)
        .flatMap(l => l.assets), 'id')];
    if (allowNewAsset) {
        assets.unshift({ id: null, name: '' });
    }
    const assetLookup = arrayToObject(assets);

    return (
        <DropdownFilter
            value={selectedValue}
            title={'Select asset'}
            renderOption={(option) => (
                <Box className={classes.option}>
                    <Grid item xs={6} container direction='column'>
                        {option?.id ? <>
                            {!!option?.vin && <Grid item >
                                <b> VIN: </b>
                                {option?.vin}
                            </Grid>}
                            {!!option?.assetId && <Grid item >
                                <b> Asset ID: </b>
                                {option?.assetId}
                            </Grid>}
                        </> : <Grid container alignItems="center" item >{option.name}</Grid>
                        }
                    </Grid>
                </Box>
            )}
            values={assets}
            onSelect={(id) => {
                setSelectedAsset(assetLookup?.[id])
            }}
        />
    )
}
