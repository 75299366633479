export const validatePassword = (password, confirmPassword) => {
    const specialCharacterRegex = /[!@#$£%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const numberRegex = /\d+/;
    const length = password ? password?.length : null;
    const hasUpper = password ? /[A-Z]/.test(password) : null;
    const hasLower = password ? /[a-z]/.test(password) : null;
    const hasSpecial = password ? specialCharacterRegex.test(password) : null;
    const hasNumber = password ? numberRegex.test(password) : null;
    const longEnough = length >= 8;
    
    let validPass = false;
    if(hasUpper && hasLower && hasSpecial && hasNumber && longEnough)
    {
        validPass = true
    }
    
    let confirmTheSame = false;
    if (confirmPassword === password) {
        confirmTheSame = true;
    }
  
    let both = false;
    if (validPass && confirmTheSame)
    {
        both = true;
    }

    return {validPassword: validPass, validConfirm: confirmTheSame, validBoth: both};
} 