import { makeStyles } from "@material-ui/core"

export const MobileActivityStyle = makeStyles((theme) => ({
    latestPing: {
        padding: '1em 2em',
        marginBottom: '1em',
        cursor: 'pointer',
        background: '#fff1d8'
    },
    icon: {
        fill: 'orange',
        marginLeft: '-0.2em'
    },
    lots: {
        padding: '0 1.5em 1.5em 1.5em'
    },
    pingTitle: {
        marginLeft: '0.2em',
        marginRight: '0.2em',
    },
    refresh: {
        margin: "auto",
    },
    spin: {
        margin: "auto",
        animation: "$spin 1s 1",
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
    pingTitle: {
        fontWeight: 'bold'
    }
}));