import { permissions } from "../permissions";

function extractPermissions(permissionObj, result = []) {
    for (const key in permissionObj) {
        if (permissionObj[key].key) {
            result.push({
                key: permissionObj[key].key,
                name: permissionObj[key].name,
                description: permissionObj[key].description || null
            });
        } else if (typeof permissionObj[key] === 'object') {
            extractPermissions(permissionObj[key], result);
        }
    }
    return result;
}
const permissionDisplay = Object.keys(permissions).reduce((acc, key) => {
    return acc.concat(extractPermissions(permissions[key]));
}, []);

export { permissionDisplay };