import { makeStyles } from "@material-ui/core"

export const bulkActionsStyle = makeStyles((theme) => ({
    button: {
        padding: '0.5em 1.5em',
        fontSize: '0.8em'
    },
    flagOffIcon: {
        opacity: 0.2,
        transition: "opacity 0.5s ease",
    },
    flagOffIconContainer: {
        "&:hover": {
            "& $flagOffIcon": {
                opacity: 1,
            },
        }
    },
    flagTooltip: {
        display: 'flex',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600
    },
    tooltipIcon: {
        marginRight: '0.5rem'
    },
    lineItem: {
        marginTop: 5,
        paddingRight: 5
    },
    flagPopover: {
        padding: '1rem',
        minWidth: 250,
        maxWidth: 250,
        background: 'white',
        boxShadow: theme.shadows[2]
    },
    flagNote: {
        border: '1px solid lightgrey',
        borderRadius: '5px',
        padding: '0.5rem',
        marginTop: '0.5rem',
        fontSize: theme.typography.pxToRem(12),
    }
}));