import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import UsersPageStyle from "../../UsersPageStyle";
import { useState } from "react";
import {useUserChangePasswordMutation} from "features/users/usersSlice";
import NotificationProvider from "components/NotificationProvider";

const useStyles = UsersPageStyle;

const SendRestorePasswordDialog = ({open, setOpenDialog, userId}) => {
    const classes = useStyles();

    const [resetPassword] = useUserChangePasswordMutation()

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const onReset = async () => {
        setIsSaving(true);

        let objToSend = {
            userId: userId,
            password: password,
            confirmPassword: confirmPassword
        }
        let result = await resetPassword(objToSend);

        if (result?.error) {
            NotificationProvider.error("Restore password failed to save.");
                       
        } else {
            NotificationProvider.success(`Restore password sent successfully.`);
            setOpenDialog(false);
        }
        setIsSaving(false);

    }

    const onClose = () => {
        setOpenDialog(false);
        setPassword("");
        setConfirmPassword(""); 
    }
    

    return (
        <Dialog open={open}>
            <DialogTitle className={classes.label}>Reset Password</DialogTitle>
            <DialogContent>
                <div>A new password will be generated and the link will be sent to the user to generate the new password.</div>               
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">Close</Button>
                <Button 
                    onClick={onReset} 
                    disabled={isSaving} 
                    variant="contained" 
                    color="primary"
                >
                    {isSaving ? "Saving..." : "Reset"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SendRestorePasswordDialog;