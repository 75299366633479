import { makeStyles } from "@material-ui/core"

export const loadAttachmentsStyle = makeStyles((theme) => ({
    attachmentsContainer: {
        position: 'relative',
    },
    attachmentsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        fontWeight: 600,
        //fontSize: 16,
        padding: '0.5em 0' 
    },
    imageMobile: {
        width: "100%"
    },
}));
