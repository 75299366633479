import * as React from "react";

function SvgIconQuestion(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.40704 21.6697L7.40286 21.6675C7.00233 21.4567 6.75 21.0302 6.75 20.5699V19.15V18.6987L6.30106 18.6526C4.904 18.5092 3.77429 17.9287 2.99282 17.0408C2.21066 16.1522 1.75 14.9243 1.75 13.4399V7.43994C1.75 5.83448 2.28687 4.53018 3.18855 3.62849C4.09024 2.72681 5.39454 2.18994 7 2.18994H17C18.6055 2.18994 19.9098 2.72681 20.8114 3.62849C21.7131 4.53018 22.25 5.83448 22.25 7.43994V13.4399C22.25 15.0453 21.7131 16.3496 20.8114 17.2513C19.9098 18.153 18.6055 18.6899 17 18.6899H13.23H13.0786L12.9526 18.7739L8.69596 21.6117C8.69544 21.6121 8.69492 21.6124 8.69441 21.6128C8.48661 21.7485 8.24257 21.8199 8 21.8199C7.80132 21.8199 7.59293 21.7698 7.40704 21.6697ZM8.40845 21.1918L8.40851 21.1919L8.41693 21.1863L12.8669 18.2263L12.8674 18.226C12.9123 18.1961 12.9669 18.18 13.01 18.18H17.01C18.4099 18.18 19.6074 17.7247 20.4561 16.876C21.3048 16.0274 21.76 14.8299 21.76 13.43V7.42993C21.76 6.03004 21.3048 4.83257 20.4561 3.98388C19.6074 3.13518 18.4099 2.67993 17.01 2.67993H7C5.60011 2.67993 4.40264 3.13518 3.55395 3.98388C2.70525 4.83257 2.25 6.03004 2.25 7.42993V13.43C2.25 14.8299 2.70525 16.0274 3.55395 16.876C4.40264 17.7247 5.60011 18.18 7 18.18C7.13386 18.18 7.25 18.2961 7.25 18.43V20.56C7.25 20.9526 7.51585 21.1446 7.62275 21.2087C7.78654 21.307 8.09587 21.3907 8.40845 21.1918Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M11.9998 11.61C11.866 11.61 11.7498 11.4938 11.7498 11.36V11.15C11.7498 10.2733 12.377 9.83623 12.7014 9.61314C12.8997 9.47908 13.0812 9.33445 13.2098 9.15529C13.3541 8.9541 13.4098 8.74165 13.4098 8.52002C13.4098 7.74392 12.776 7.10999 11.9998 7.10999C11.2236 7.10999 10.5898 7.74392 10.5898 8.52002C10.5898 8.65388 10.4737 8.77002 10.3398 8.77002C10.206 8.77002 10.0898 8.65388 10.0898 8.52002C10.0898 7.46615 10.946 6.60999 11.9998 6.60999C13.0537 6.60999 13.9098 7.46615 13.9098 8.52002C13.9098 9.3775 13.2904 9.81533 12.9809 10.025C12.7742 10.1629 12.5849 10.3109 12.452 10.4965C12.3022 10.7058 12.2498 10.9249 12.2498 11.15V11.36C12.2498 11.4995 12.138 11.61 11.9998 11.61Z" fill="#0090FE" stroke="#0090FE" />
      <path d="M12 14.1C11.8605 14.1 11.75 13.9882 11.75 13.85C11.75 13.7161 11.8661 13.6 12 13.6C12.1339 13.6 12.25 13.7161 12.25 13.85C12.25 13.9882 12.1395 14.1 12 14.1Z" fill="#0090FE" stroke="#0090FE" />
    </svg>
  );
}

export default SvgIconQuestion;
