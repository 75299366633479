import React, { useState, useEffect, useRef, createContext } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import './App.scss';
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./Routes";
import { history } from "./app/store/store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mobileBreakpoint from "./utils/mobileBreakpoint";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import Header from "./features/Header/Header";
import { Prompt } from './components/Prompt/Prompt';
import { PromptContextProvider } from './components/Prompt/PromptContext';
import SideNav from './menu/SideNav';
import { realTimeNotification } from 'app/notifications/realTimeNotification';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appContainer: {
        marginTop: "5rem",
        marginLeft: "8.5rem",
        marginRight: "1rem"
    },
    appContainerMobile: {
        marginLeft: 100
    }
}));

export const AppContext = createContext();

function App() {
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const [connection, setConnection] = useState(null);
    const [dontShowHeaderApp, setDontShowHeaderApp] = useState(false);

    useEffect(() => {
        const newConnection = realTimeNotification.GetHubConnectionBuilder();
        setConnection(newConnection);
        console.log(`GA key ${process.env.REACT_APP_GA_KEY}`)
        if (process.env.REACT_APP_GA_KEY) ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');
                    connection.on('ReceiveMessage', receiveObject => {
                        realTimeNotification.ProcessMessage(receiveObject);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return (
        <AppContext.Provider value={{ dontShowHeaderApp, setDontShowHeaderApp }}>
            <PromptContextProvider>
                <ConnectedRouter history={history}>
                    <Prompt />
                    <CssBaseline />
                    {!dontShowHeaderApp &&
                        (
                            <>
                                <Header />
                                <SideNav />
                            </>
                        )
                    }
                    <div
                        className={`${!dontShowHeaderApp ? classes.appContainer : ""} ${isMobile ? classes.appContainerMobile : ""}`}>
                        <Routes />
                    </div>
                    <ToastContainer />
                </ConnectedRouter>
            </PromptContextProvider>
        </AppContext.Provider>
    );
}

export default App;
