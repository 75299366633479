import * as React from "react";

function IconBackupCameraRepair(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.35062 3C7.35062 2.68934 7.60246 2.4375 7.91312 2.4375H10.3183C10.629 2.4375 10.8808 2.68934 10.8808 3V4.18475H12.1114C12.4221 4.18475 12.6739 4.43659 12.6739 4.74725V6.75C12.6739 7.06066 12.4221 7.3125 12.1114 7.3125H6.1114C5.80074 7.3125 5.5489 7.06066 5.5489 6.75V4.74725C5.5489 4.43659 5.80074 4.18475 6.1114 4.18475H7.35062V3ZM8.47562 3.5625V4.74725C8.47562 5.05791 8.22378 5.30975 7.91312 5.30975H6.6739V6.1875H11.5489V5.30975H10.3183C10.0076 5.30975 9.75579 5.05791 9.75579 4.74725V3.5625H8.47562ZM3.7264 9.30002C2.80685 9.30002 2.0614 10.0455 2.0614 10.965V12.2325C2.0614 13.1521 2.80685 13.8975 3.7264 13.8975H6.47141V9.30002H3.7264ZM3.7264 8.17502C2.18553 8.17502 0.936401 9.42414 0.936401 10.965V12.2325C0.936401 13.7734 2.18553 15.0225 3.7264 15.0225H14.4889C16.0298 15.0225 17.2789 13.7734 17.2789 12.2325V10.965C17.2789 9.42414 16.0298 8.17502 14.4889 8.17502H3.7264ZM7.59641 9.30002V13.8975H14.4889C15.4085 13.8975 16.1539 13.1521 16.1539 12.2325V10.965C16.1539 10.0455 15.4085 9.30002 14.4889 9.30002H7.59641ZM4.28889 11.3475C4.1522 11.3475 4.04139 11.4583 4.04139 11.595C4.04139 11.7317 4.1522 11.8425 4.28889 11.8425C4.42558 11.8425 4.53639 11.7317 4.53639 11.595C4.53639 11.4583 4.42558 11.3475 4.28889 11.3475ZM2.91639 11.595C2.91639 10.837 3.53088 10.2225 4.28889 10.2225C5.0469 10.2225 5.66139 10.837 5.66139 11.595C5.66139 12.353 5.0469 12.9675 4.28889 12.9675C3.53088 12.9675 2.91639 12.353 2.91639 11.595Z" fill="#0090FE" />
        </svg>

    );
}

export default IconBackupCameraRepair;
