import { createSlice } from "@reduxjs/toolkit";

export const activityReportFiltersSlice = createSlice({
    name: 'activityReportFilters',
    initialState: {
        searchVal: '',
        filters: {
            clientId: { value: null },
            lotId: { value: null },
            date: { value: null },
            activityTypeId: { value: null },
            activityItemId: { value: null },
            activityId: { value: null },
        },
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters: (state, action) => {
            state.filters = {
                clientId: { value: null },
                lotId: { value: null },
                date: { value: null },
                activityTypeId: { value: null },
                activityItemId: { value: null },
                activityId: { value: null },
            }
        },
    },
});

export const { setSearchVal, setFilters, resetFilters } = activityReportFiltersSlice.actions;
export default activityReportFiltersSlice.reducer;
