import AddIcon from '@material-ui/icons/Add';
import { ReportSectionStyles } from '../../ReportSectionStyles';
import { Popover, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { elementTypeSetup } from 'features/Forms/CustomReport/CustomReportConsts';
import CloseIcon from '@material-ui/icons/Close';

export const AddElementButton = ({ onAddElement, sectionIndex, columnIndex, onDeleteColumn }) => {
    const classes = ReportSectionStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const openPanel = (e) => {
        setAnchorEl(e.currentTarget);
    }

    return (
        <>
            <div className={classes.addElement}>
                <Tooltip title="Add Element">
                    <div className={classes.addElementIconContainer} onClick={openPanel}>
                        <AddIcon className={classes.addElementIcon} />
                    </div>
                </Tooltip>
                <Tooltip title="Delete Column">
                    <div className={classes.deleteColumnIconContainer} onClick={() => {onDeleteColumn(sectionIndex, columnIndex)}}>
                        <CloseIcon className={classes.deleteColumnIcon} />
                    </div>
                </Tooltip>
            </div>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div container spacing={2} direction='row' className={classes.selectColumns}>
                    {Object.values(elementTypeSetup)?.map(val => <div
                        className={classes.elementType} onClick={() => {
                            onAddElement(sectionIndex, val.id, columnIndex);
                            setAnchorEl();
                        }
                        }>
                        <div>{val.icon}</div>
                        <div className={classes.elementTypeName}>{val.name}</div>
                    </div>)}
                </div>
            </Popover>
        </>
    )
}