import React from "react";

function IconParqLight(props) {
    return (
        <svg width="100" height="64" viewBox="0 0 100 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M76.6612 5.93341C75.3659 4.12495 73.6661 2.6525 71.7006 1.63651C69.7352 0.620519 67.56 0.0898423 65.353 0.0878906H34.7189C32.5134 0.0911678 30.3398 0.622479 28.3759 1.63842C26.412 2.65436 24.7135 4.1261 23.419 5.93341L20.0841 10.5997H24.789L26.5132 8.18815C27.4545 6.87396 28.6896 5.8037 30.1177 5.06477C31.5457 4.32584 33.1262 3.93918 34.73 3.93635H65.353C66.9569 3.93918 68.5373 4.32584 69.9653 5.06477C71.3934 5.8037 72.6285 6.87396 73.5699 8.18815L75.294 10.5997H79.9989L76.6612 5.93341Z"
                fill="white"/>
            <path
                d="M13.0241 18.5235C9.6081 18.5252 6.3293 19.8842 3.89346 22.308C1.45762 24.7319 0.0594842 28.0267 0 31.4834C0 31.5422 0 31.6038 0 31.6626V50.3952C0 51.0333 0.250468 51.6453 0.696303 52.0965C1.14214 52.5477 1.74682 52.8012 2.37733 52.8012C3.00784 52.8012 3.61252 52.5477 4.05835 52.0965C4.50419 51.6453 4.75466 51.0333 4.75466 50.3952V41.8664C6.36605 43.204 8.27142 44.1301 10.3103 44.5669C12.3492 45.0036 14.462 44.9381 16.4707 44.3759C18.4794 43.8137 20.3252 42.7713 21.8526 41.3364C23.3801 39.9015 24.5446 38.1162 25.248 36.1307C25.9513 34.1453 26.173 32.0178 25.8943 29.9276C25.6157 27.8373 24.8448 25.8454 23.6467 24.1196C22.4485 22.3938 20.8582 20.9847 19.0096 20.0108C17.161 19.0369 15.1082 18.5268 13.0241 18.5235ZM13.0241 40.0626C11.3897 40.0626 9.79191 39.5721 8.43292 38.6531C7.07393 37.7341 6.01472 36.4279 5.38925 34.8996C4.76377 33.3714 4.60012 31.6898 4.91898 30.0674C5.23785 28.445 6.02491 26.9548 7.18064 25.7851C8.33636 24.6155 9.80885 23.8189 11.4119 23.4962C13.0149 23.1735 14.6765 23.3391 16.1866 23.9721C17.6966 24.6052 18.9872 25.6771 19.8953 27.0525C20.8033 28.4279 21.288 30.0449 21.288 31.699C21.2858 33.9165 20.4145 36.0425 18.8651 37.6105C17.3158 39.1785 15.2152 40.0604 13.0241 40.0626Z"
                fill="white"/>
            <path
                d="M71.1068 18.5235C67.6936 18.5296 64.4191 19.8906 61.9868 22.3139C59.5545 24.7373 58.1587 28.0296 58.0993 31.4834C58.0993 31.5422 58.0993 31.6038 58.0993 31.6626V42.4714C58.0993 43.1095 58.3497 43.7214 58.7956 44.1727C59.2414 44.6239 59.8461 44.8774 60.4766 44.8774C61.1071 44.8774 61.7118 44.6239 62.1576 44.1727C62.6034 43.7214 62.8539 43.1095 62.8539 42.4714V31.8419C62.8539 31.7943 62.8539 31.7467 62.8539 31.699C62.8561 29.4835 63.7259 27.3592 65.2729 25.7915C66.8198 24.2239 68.9176 23.3407 71.1068 23.3355C71.7373 23.3355 72.3419 23.082 72.7878 22.6308C73.2336 22.1796 73.4841 21.5676 73.4841 20.9295C73.4841 20.2914 73.2336 19.6794 72.7878 19.2282C72.3419 18.777 71.7373 18.5235 71.1068 18.5235Z"
                fill="white"/>
            <path
                d="M99.9917 31.4834C99.9477 28.494 98.9019 25.6084 97.0257 23.2992C95.1495 20.99 92.554 19.394 89.6644 18.7728C86.7748 18.1515 83.7625 18.5418 81.1207 19.8797C78.4789 21.2176 76.3644 23.4238 75.1234 26.1369C73.8825 28.8499 73.5887 31.9091 74.2902 34.8133C74.9917 37.7174 76.6468 40.2944 78.9846 42.1221C81.3223 43.9498 84.2041 44.9199 87.1578 44.8734C90.1115 44.827 92.9621 43.7667 95.2426 41.8664V50.3952C95.2636 51.0195 95.5234 51.611 95.9672 52.0451C96.411 52.4792 97.0041 52.7218 97.6213 52.7218C98.2385 52.7218 98.8315 52.4792 99.2753 52.0451C99.7191 51.611 99.9789 51.0195 100 50.3952V31.6626C100 31.6038 99.9944 31.5422 99.9917 31.4834ZM86.9842 40.0626C85.3497 40.0626 83.752 39.5721 82.393 38.6531C81.034 37.7341 79.9748 36.4279 79.3493 34.8996C78.7238 33.3714 78.5602 31.6898 78.8791 30.0674C79.1979 28.445 79.985 26.9548 81.1407 25.7851C82.2964 24.6155 83.7689 23.8189 85.372 23.4962C86.975 23.1735 88.6366 23.3391 90.1466 23.9722C91.6567 24.6052 92.9473 25.6771 93.8554 27.0525C94.7634 28.4279 95.2481 30.0449 95.2481 31.6991C95.2459 33.918 94.3734 36.0453 92.8223 37.6135C91.2712 39.1817 89.1684 40.0626 86.9759 40.0626H86.9842Z"
                fill="white"/>
            <path
                d="M55.0743 31.4834C55.0303 28.494 53.9846 25.6084 52.1083 23.2992C50.2321 20.99 47.6366 19.394 44.7471 18.7728C41.8575 18.1515 38.8451 18.5418 36.2033 19.8797C33.5616 21.2176 31.447 23.4238 30.2061 26.1368C28.9651 28.8499 28.6713 31.9091 29.3728 34.8133C30.0743 37.7174 31.7295 40.2944 34.0672 42.1221C36.405 43.9498 39.2867 44.9199 42.2404 44.8734C45.1941 44.827 48.0447 43.7667 50.3252 41.8664V42.4714C50.3252 43.1095 50.5757 43.7215 51.0215 44.1727C51.4673 44.6239 52.072 44.8774 52.7025 44.8774C53.333 44.8774 53.9377 44.6239 54.3835 44.1727C54.8294 43.7215 55.0799 43.1095 55.0799 42.4714V31.6626C55.0743 31.6038 55.0743 31.5422 55.0743 31.4834ZM42.0668 40.0626C40.4324 40.0626 38.8346 39.5721 37.4756 38.6531C36.1166 37.7341 35.0574 36.4279 34.432 34.8996C33.8065 33.3714 33.6428 31.6898 33.9617 30.0674C34.2806 28.445 35.0676 26.9548 36.2234 25.7851C37.3791 24.6155 38.8516 23.8189 40.4546 23.4962C42.0577 23.1735 43.7192 23.3391 45.2293 23.9722C46.7393 24.6052 48.03 25.6771 48.938 27.0525C49.8461 28.4279 50.3307 30.0449 50.3307 31.6991C50.3285 33.9165 49.4572 36.0425 47.9079 37.6105C46.3586 39.1785 44.2579 40.0604 42.0668 40.0626Z"
                fill="white"/>
            <path
                d="M55.0964 57.9857C55.0738 56.7307 54.6292 55.521 53.836 54.5561C53.0429 53.5911 51.9485 52.9285 50.7333 52.6775C49.5182 52.4265 48.2547 52.6021 47.1514 53.1753C46.0481 53.7485 45.1707 54.6852 44.6639 55.8308C44.1571 56.9764 44.0512 58.2625 44.3636 59.4772C44.676 60.6919 45.3881 61.7626 46.3824 62.5128C47.3768 63.263 48.594 63.6478 49.8335 63.6038C51.073 63.5599 52.2606 63.0898 53.2007 62.2711V62.6464C53.1937 62.7771 53.2131 62.9079 53.2577 63.0307C53.3024 63.1536 53.3713 63.266 53.4602 63.361C53.5492 63.456 53.6564 63.5317 53.7752 63.5834C53.894 63.6351 54.022 63.6618 54.1513 63.6618C54.2807 63.6618 54.4087 63.6351 54.5275 63.5834C54.6463 63.5317 54.7535 63.456 54.8424 63.361C54.9314 63.266 55.0003 63.1536 55.0449 63.0307C55.0895 62.9079 55.1089 62.7771 55.102 62.6464V58.0669C55.102 58.0473 55.0992 58.0165 55.0964 57.9857ZM49.6499 61.6829C48.9482 61.6829 48.2622 61.4723 47.6787 61.0777C47.0953 60.6832 46.6405 60.1223 46.372 59.4662C46.1034 58.8101 46.0332 58.0881 46.1701 57.3916C46.307 56.695 46.6449 56.0552 47.1411 55.553C47.6373 55.0508 48.2695 54.7089 48.9577 54.5703C49.646 54.4318 50.3594 54.5029 51.0077 54.7746C51.656 55.0464 52.2101 55.5067 52.6 56.0972C52.9898 56.6877 53.1979 57.3819 53.1979 58.0921C53.1972 59.0442 52.8231 59.9571 52.1579 60.6303C51.4927 61.3036 50.5907 61.6821 49.6499 61.6829Z"
                fill="white"/>
            <path
                d="M57.7339 63.6099C57.4819 63.6091 57.2405 63.5073 57.0625 63.3267C56.8846 63.1461 56.7846 62.9014 56.7846 62.6464V53.7619C56.7978 53.5157 56.9037 53.2839 57.0805 53.1144C57.2573 52.9448 57.4917 52.8503 57.7353 52.8503C57.9789 52.8503 58.2133 52.9448 58.3901 53.1144C58.567 53.2839 58.6728 53.5157 58.686 53.7619V62.6464C58.686 62.9019 58.5857 63.147 58.4071 63.3277C58.2286 63.5084 57.9864 63.6099 57.7339 63.6099Z"
                fill="white"/>
            <path
                d="M57.7533 51.7648C58.299 51.7648 58.7413 51.3172 58.7413 50.7649C58.7413 50.2127 58.299 49.765 57.7533 49.765C57.2076 49.765 56.7653 50.2127 56.7653 50.7649C56.7653 51.3172 57.2076 51.7648 57.7533 51.7648Z"
                fill="white"/>
            <path
                d="M42.338 63.6099C42.8837 63.6099 43.326 63.1622 43.326 62.61C43.326 62.0577 42.8837 61.6101 42.338 61.6101C41.7923 61.6101 41.35 62.0577 41.35 62.61C41.35 63.1622 41.7923 63.6099 42.338 63.6099Z"
                fill="white"/>
            <path
                d="M37.4395 59.7474V63.5987H33.1885C30.1505 63.5936 27.2141 62.4911 24.908 60.4897C22.5733 58.4755 20.8924 55.7963 20.0841 52.8012H24.0777C24.7463 54.6391 25.8721 56.2714 27.3462 57.5403C28.9719 58.9565 31.0435 59.7391 33.1885 59.7474H37.4395Z"
                fill="white"/>
            <path
                d="M79.9823 52.8012C79.1725 55.797 77.4896 58.4763 75.1529 60.4897C72.8498 62.4939 69.9154 63.6004 66.8779 63.6099H62.6325V59.7474H66.8835C69.026 59.7334 71.0935 58.9469 72.7147 57.5291C74.188 56.2594 75.3136 54.6274 75.9832 52.79L79.9823 52.8012Z"
                fill="white"/>
        </svg>
    );
}

export default IconParqLight;
