import { makeStyles } from "@material-ui/core"

export const StepsStyles = makeStyles((theme) => ({
    text: {
        paddingBottom: '1rem'
    },
    pocInfo: {
        paddingTop: '1rem'
    },
    addButton: {
        margin: "0.5rem 0 0 1rem",
    },
    overviewWrapper: {
        padding: '1.5rem',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
    },
    overviwTitle: {
        color: theme.palette.primary.main,
        marginBottom: '1rem'
    },
    overviewField: {
        '& label': {
            fontWeight: 'bold',
        },
        paddingBottom: '5px'

    },
    toggleWrapper: {
        borderRadius: '3px',
        background: theme.palette.background.default,
        margin: '0 0.5em',
    },
    driversWrapper: {
        margin: '1rem 0',
    },
    generateLabel: {
        fontSize: "0.875rem"
    }
}));