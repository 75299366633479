import { makeStyles } from '@material-ui/core/styles';

export const TasksPanelStyles = makeStyles((theme) => ({
    panel: {
        background: theme.background,
        width: '50em',
        height: '100%'
    },
    header: {
        fontSize: '1.5em',
        background: theme.palette.secondary.main,
        padding: '0 1em ',
        //position: 'fixed',
        //top: '0px',
        width: '100%',
        color: theme.palette.secondary.contrastText,
        zIndex: 1
    },
    filters: {
        //marginTop: '5.5em',
        paddingLeft: '1em',
        background: theme.palette.background.default
    },
    sorts: {
    },
    search: {
        background: theme.palette.background.default,
        width: '100%'
    },
    icon: {
        fill: theme.palette.primary.contrastText
    },
    tabs: {
        margin: 0,
        "& .MuiTab-root:hover": {
            backgroundColor: 'transparent',

        },
        "& .MuiTab-root": {
            fontWeight: '500',
            padding: '1.5em',
            fontSize: '1rem'
        },
        "& .Mui-selected": {
            color: `${theme.palette.secondary.contrastText} !important`
        }
    },
    exportButton: {
        position: 'absolute',
        top: '1em',
        zIndex: 1,
        right: 0
    },
    newTaskContent: {
        maxWidth: '20em',
        padding: '1.5em',
        background: 'white',
        borderRadius: '5px',
        boxShadow: '-1px 4px 11px #6e6e6e',
        zIndex: 9999,//needs to be above header
        borderTop: '10px solid',
        borderColor: theme.palette.primary.main,
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.71em",
            top: '-34px',
            right: '55px',
            width: 30,
            height: 30,
            backgroundColor: theme.palette.primary.main,
            transform: "translate(-50%, 50%) rotate(-45deg)",
            clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
    emptyText: {
        color: theme.palette.text.primary,
        fontSize: '1.5em',
        textAlign: 'center',
        padding: '3em',
        marginTop: '2em'
    }
}));
