import { getUserTaskActions, getUserTaskNotificationUrl, handleUserTaskNotification } from "./userTaskNotificationOptions";

export const getNotificationActions = (data) => {
    return notificationHandler[data.type]?.getNotificationActions(data);
}


export const getNotificationUrl = (data) => {
    return notificationHandler[data.type]?.getNotificationUrl(data);
}

export const notificationTypes = {
    USER_TASK: 1
}

export const notificationHandler = {
    [notificationTypes.USER_TASK]: {
        getNotificationActions: (data) => {
            return getUserTaskActions(data);
        },
        getNotificationUrl: (data) => {
            return getUserTaskNotificationUrl(data);
        },
        handle: (data) => {
            handleUserTaskNotification(data);
        }
    }
}