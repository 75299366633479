import { Button } from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

const PushUserConfig = () => {

    let configMode = Notification.permission;

    const resuestPermission = () => {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
            }
        });
    }

    return (
        <>
            {configMode == 'default' && <Button startIcon={<NotificationsNoneIcon style={{ fill: 'white' }} />} color="primary" variant="contained" onClick={resuestPermission}>
                Enable Browser Push Notifications
            </Button>}
        </>
    )
}
export default PushUserConfig;