import { Collapse, Grid } from "@material-ui/core";
import { storageInvoiceStyles } from "./style";
import { useState } from "react";
import EditStorageInvoiceDetail from "./EditStorageInvoiceDetail/EditStorageInvoiceDetail";
import { secondLevelData, storageDetailsColumns } from "../../storageInvoiceConsts";

const StorageInvoiceDetails = ({ invoice, expanded }) => {
    const classes = storageInvoiceStyles();

    const [open, setOpen] = useState(false);
    const [invoiceToEdit, setInvoiceToEdit] = useState(invoice);

    // const onEditClick = () => {
    //     setInvoiceToEdit(invoice);
    //     setOpen(true)
    // }

    return (
        <>
            <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                <Grid container className={classes.detailsWrapper} spacing={2}>
                    {storageDetailsColumns?.map((col, index) =>
                        <Grid className={classes.column} xs={col.size ?? 1} item container direction='column' key={`col_${index}`}>
                            <Grid className={classes.label}>{col?.label}</Grid>
                            <Grid className={classes.field}>{col.value ? col.value(invoice?.[col.key] ?? 0) : invoice?.[col.key] ?? 0}</Grid>
                        </Grid>)}
                    {/* <Grid className={classes.column} xs={1} item >
                        <IconButton className={classes.iconButton} variant="outlined" onClick={onEditClick}><EditIcon /></IconButton>
                    </Grid> */}
                    <Grid className={classes.column} xs={12} item container spacing={2} >
                        {secondLevelData?.map((col, index) =>
                            <Grid className={classes.column} xs={col.size ?? 1} item container direction='column' key={`col_${index}`}>
                                <Grid className={classes.label}>{col?.label}</Grid>
                                <Grid className={classes.field}>{col.value ? col.value(invoice?.[col.key] ?? 0) : invoice?.[col.key] ?? 0}</Grid>
                            </Grid>)}
                    </Grid>
                </Grid>
            </Collapse>
            <EditStorageInvoiceDetail invoice={invoice} open={open} setOpen={setOpen} invoiceToEdit={invoiceToEdit} setInvoiceToEdit={setInvoiceToEdit} />
        </>
    )
}

export default StorageInvoiceDetails;