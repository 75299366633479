import { makeStyles } from "@material-ui/core"

export const vehicleMapStyle = makeStyles((theme) => ({
    button: {
        height: '95%',
        width: '100%',
        marginBottom: '1em',
    },
    mapPaper: {
        height: '65vh',
        padding: '1rem'
    },
    mapContainer: {
        height: '50vh',
    },
    header: {
        padding: '1rem 0'
    },
    lotFilterContainer: {
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5rem',
        backgroundColor: '#f3f5f6',
        height: '95%',
        cursor: 'pointer'
    },
    lotFilterCheck: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    lotFilterLabel: {
        fontWeight: 500,
        paddingLeft: '0.5rem',
        fontSize: 17
    },
    content: {
        padding: '0.5rem 0',
        fontWeight: 500
    },
    ItemName: {
        width: '75%'
    }
}));