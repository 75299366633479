import * as React from "react";

function SvgIconReservations(props, width, height) {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0833 9H7.91666C7.88651 9 7.85523 8.98772 7.82958 8.96207C7.80394 8.93643 7.79166 8.90515 7.79166 8.875C7.79166 8.84485 7.80394 8.81357 7.82958 8.78793C7.85523 8.76228 7.88651 8.75 7.91666 8.75H12.0833C12.1135 8.75 12.1448 8.76228 12.1704 8.78793C12.196 8.81357 12.2083 8.84485 12.2083 8.875C12.2083 8.90515 12.196 8.93643 12.1704 8.96207C12.1448 8.98772 12.1135 9 12.0833 9Z" fill="#0090FE" stroke="#0090FE" />
            <path d="M14.7845 18.138L14.7844 18.138L10.7177 15.8796L10.7165 15.879C10.4935 15.7559 10.2199 15.7167 9.99583 15.7167C9.77179 15.7167 9.49821 15.7559 9.27513 15.879L9.27392 15.8796L5.20725 18.138L5.20718 18.138C4.81504 18.3559 4.4397 18.4584 4.10833 18.4584C3.84135 18.4584 3.60332 18.3958 3.39189 18.2755C2.9478 18.0148 2.63333 17.4524 2.63333 16.6334V4.88341C2.63333 3.04289 4.13447 1.54175 5.975 1.54175H14.0167C15.8572 1.54175 17.3583 3.04289 17.3583 4.88341V16.6251C17.3583 17.4454 17.0429 18.0082 16.5977 18.2684C16.1484 18.531 15.5002 18.5356 14.7845 18.138ZM5.98333 1.79175C4.28219 1.79175 2.89166 3.18227 2.89166 4.88341V16.6251C2.89166 17.1593 3.03897 17.774 3.53135 18.057C4.0169 18.3446 4.62188 18.1877 5.09485 17.9194C5.09562 17.9189 5.09638 17.9185 5.09715 17.9181L9.15898 15.6624C9.37898 15.5406 9.67943 15.4688 10 15.4688C10.3205 15.4688 10.6209 15.5405 10.8409 15.6624C10.8409 15.6624 10.841 15.6624 10.841 15.6624L14.9028 17.9181C14.9036 17.9185 14.9044 17.9189 14.9051 17.9194C15.3798 18.1887 15.9875 18.3457 16.4739 18.0538C16.9564 17.7644 17.1083 17.1609 17.1083 16.6251V4.88341C17.1083 3.18227 15.7178 1.79175 14.0167 1.79175H5.98333Z" fill="#0090FE" stroke="#0090FE" />
        </svg>
    );
}

export default SvgIconReservations;
