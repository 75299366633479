import { makeStyles } from "@material-ui/core";

export const distanceReportStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    btn: {
        marginLeft: 'auto',
        marginBottom: '1em'
    },
    tableWrapper: {
        minHeight: '500px'
    },
    downloadButton: {
        marginLeft: 'auto'
    },
    distance1: {
        border: '2px solid #a6ef69',
        padding: '3px 5px',
        borderRadius: '4px'
    },
    distance2: {
        border: '2px solid #ffda4c',
        padding: '3px 5px',
        borderRadius: '4px'
    },
    distance3: {
        border: '2px solid #fd8f8f',
        padding: '3px 5px',
        borderRadius: '4px'
    }
}));