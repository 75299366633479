import { makeStyles } from "@material-ui/core"

export const PCOInvoiceStatusDropdownStyle = makeStyles((theme) => ({
    statusItem: {
        padding: '0.5em 1em',
        cursor: 'pointer',
        color: 'white'
    },
    selectItem: {
        padding: '0.5em 0',
        fontWeight: '500',
        '&.filled': {
            paddingLeft: '0.5em'
        }
    },
    selectMenu: {
        width: '100%'
    },
    select: {
        fontSize: '1em',
        width: '100%',
        padding: 0,
        color: 'white',
        borderRadius: 0,
        '& fieldset': {
            border: 'none'
        },
        '& .MuiSelect-icon': {
            fill: '#ccc'
        }
    },

}));