import LoadingSpinner from "components/LoadingSpinner";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { useGetMapQuery, useGetSummaryQuery } from "features/lots/lotSlice";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { parse } from "svgson";
import Legend from "./Legend";
import { virtualLotStyle } from "./style";

const svgElements = {
    GROUP: 'g',
    USE: 'use',
    SYMBOL: 'symbol',
    DEFS: 'defs',
    LINE: 'line',
    RECT: 'rect',
    PATH: 'path',
    POLYGON: 'polygon',
    POLYLINE: 'polyline'
}

const VirtualLot = ({ id }) => {
    const params = useParams();
    const lotId = id ?? params.id;
    const classes = virtualLotStyle();
    const isClient = useSelector(selectIsClient);
    let userClientId = useSelector(selectUserClientId);

    let { data: attachments, error: attachmentsError, isFetching: isLoadingAttachments } = useGetMapQuery(lotId);
    let { data: summary, error: summaryError, isFetching: isLoadingSummary } = useGetSummaryQuery(lotId);
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(userClientId, { skip: !isClient });
    const showOtherClientOccupied = () => clientsWithConfigs?.find(c => c.id == userClientId)?.config?.lotConfig?.otherClientOccupied == false ? false : true;

    const [spots, setSpots] = useState([]);
    const [shapes, setShapes] = useState([]);
    // const [symbols, setSymbols] = useState([]);
    const [rootAttr, setRootAttr] = useState({});

    const colorSpots = (spots) => {
        let leasedToMark = isClient ? summary.leasedForClient : summary?.leasedSpots;
        let domiciledToMark = isClient ? Math.max((summary.onLotForClient - summary.leasedForClient), 0) : summary?.currentlyDomiciled;
        let otherClientsToMark = isClient && showOtherClientOccupied() ? (summary.totalOnLot - summary.onLotForClient) : 0;
        spots.forEach(s => {
            if (leasedToMark) {
                s.status = 'leased';
                leasedToMark--;
                return;
            }
            if (domiciledToMark) {
                s.status = 'overage';
                domiciledToMark--;
                return;
            }
            if (otherClientsToMark) {
                s.status = 'occupied';
                otherClientsToMark--;
                return;
            }
        });
    }

    useEffect(() => {
        if (attachments && summary) {
            parse(attachments.data ?? '').then(res => {
                const allElements = [
                    ...res.children?.filter(r => r.name != svgElements.GROUP && r.name != svgElements.DEFS),
                    ...res.children?.filter(r => r.name == svgElements.GROUP)?.flatMap(g => g.children)
                ];
                //const symbols = res.children?.find(r => r.name == svgElements.DEFS)?.children?.filter(c => c.name == svgElements.SYMBOL);
                const spots = allElements?.filter(r => r.attributes.id.includes('Slip_'));
                const shapes = allElements?.find(r => r.attributes.id.includes('Outline'))?.children; // r.name != svgElements.USE);
                colorSpots(spots);
                setSpots(spots);
                setShapes(shapes);
                // setSymbols(symbols)
                setRootAttr(res.attributes);
            });
        }
    }, [attachments, summary]);

    const renderShape = (shape, className) => {
        switch (shape?.name) {
            case "line":
                return <line className={className} x1={shape.attributes?.x1} y1={shape.attributes?.y1} x2={shape.attributes?.x2} y2={shape.attributes?.y2} />;
            case "rect":
                return <rect className={className} x={shape.attributes?.x} y={shape.attributes?.y} width={shape.attributes?.width} height={shape.attributes?.height} transform={shape.attributes?.transform} />;
            case "path":
                return <path className={className} d={shape.attributes?.d} />;
            case "polygon":
                return <polygon className={className} points={shape.attributes?.points} />;
            case "polyline":
                return <polyline className={className} points={shape.attributes?.points} />;
        }
    }

    const renderShapeContent = (element) => {
        return <>
            {renderShape(element, classes.spot)}
            {!!element?.children?.length && element?.children?.map(c => renderShapeContent(c))}
        </>
    }

    return (
        <>
            <LoadingSpinner loading={isLoadingAttachments || isLoadingSummary || isLoadingClientsWithConfigs} />
            <Legend />
            <svg viewBox={rootAttr?.viewBox} className={classes.svg}>
                {/* <defs>
                    {symbols?.map(s =>
                        <symbol xmlns="http://www.w3.org/2000/svg" id={s.attributes?.id} viewBox={s.attributes?.viewBox} key={s.attributes?.id}>
                            {renderShapeContent(s)}
                        </symbol>)}
                </defs > */}
                <g id="lot_map">
                    <g id="spots">
                        {
                            spots?.map((spot, i) => (
                                /*<use
                                    key={`spot_${i}`}
                                    className={`${classes.spot} ${spot.status ? classes[spot.status] : classes.empty}`}
                                    width={spot.attributes?.width}
                                    height={spot.attributes?.height}
                                    transform={spot.attributes?.transform}
                                    xlinkHref={spot.attributes?.['xlink:href']}
                                />*/
                                <g
                                    key={`spot_${i}`}
                                    className={classes.spot}
                                >
                                    {renderShape(spot.children.find(c => c.name == 'rect'), `${spot.status ? classes[spot.status] : classes.empty}`)}
                                    {
                                        spot.children.find(c => c.name == 'path') ?
                                            renderShape(spot.children.find(c => c.name == 'path'), `${spot.status ? classes[spot.status] : classes.empty}`) :
                                            renderShape(spot.children.find(c => c.name == 'g')?.children?.find(c => c.name == 'path'), `${spot.status ? classes[spot.status] : classes.empty}`)
                                    }
                                </g>
                            ))
                        }
                    </g>
                    <g id="shapes">
                        {
                            shapes?.map((shape, i) => (
                                renderShape(shape, classes.shape)
                            ))
                        }
                    </g>
                </g>
            </svg >
        </>
    )
}
export default VirtualLot;
