import { Grid, Paper } from "@material-ui/core";
import { Dialog } from "@mui/material";
import DataSearchBar from "components/DataSearchBar";
import { activityStatuses } from "features/activity/activityConsts";
import { useGetMechanicalActivitiesQuery } from "features/activity/activitySlice";
import { useGetLoadsQuery } from "features/loads/loadsSlice";
import MixedMarker from "features/maps/markers/MixedMarker/MixedMarker";
import { useState } from "react";
import MapWrapper from "../../../components/MapWrapper/MapWrapper";
import { useGetLotsQuery } from "../../lots/lotSlice";
import AssetTable from "./AssetTable/AssetTable";
import AssetViewSelector from "./AssetViewSelector/AssetViewSelector";
import { AssetFilterTypes, assetLocationsTypesById } from "./VehicleMapConsts";
import { vehicleMapStyle } from "./VehicleMapStyle";
import VehiclesMapButton from "./VehiclesMapButton";

const VehiclesMap = ({ showMap, setShowMap, vehiclesData, onSearch, searchVal }) => {
    const classes = vehicleMapStyle();
    let { data: lots = [], error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery(false);
    let { data: mechanicalActivities, error: mechanicalActivitiesError, isLoading: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: loads, error: loadListError, isLoading: isLoadingLoads } = useGetLoadsQuery({});
    mechanicalActivities = mechanicalActivities || [];

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const mechanicalActivitiesLookup = mechanicalActivities?.reduce(function (map, obj) {
        if (map[obj.vehicleId]) {
            map[obj.vehicleId] = [...map[obj.vehicleId], obj]
        } else {
            map[obj.vehicleId] = [obj];
        }
        return map;
    }, {});

    const loadsLookup = loads?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const [assetFilters, setAssetFilters] = useState({
        [AssetFilterTypes.RECENT_UPDATE]: true,
        [AssetFilterTypes.LAST_CHECKIN]: true,
        [AssetFilterTypes.TRANSPORT]: true,
        [AssetFilterTypes.MECHANICAL]: true,
        [AssetFilterTypes.TELEMATICS]: false,
    });

    const [showList, setShowList] = useState(false);
    const [assetsToShow, setAssetsToShow] = useState([]);
    const [lotToShow, setLotToShow] = useState();

    const onClickLot = (assets, lot) => {
        setAssetsToShow(assets);
        setLotToShow(lot);
        setShowList(true);
    };

    let lotsWithVehicles = {};

    const telematicVehicles = [];

    let transportsRouts = [];

    let createdRoutes = {};

    const setLotsWithVehicles = (lotId, vehicle, type) => {
        if (!lotsWithVehicles[lotId]?.vehicles) {
            lotsWithVehicles[lotId] = {
                ...lotLookup?.[lotId],
                vehicles: []
            }
        }
        lotsWithVehicles[lotId]?.vehicles?.push({
            ...vehicle,
            locationType: type
        })
    }

    const updateTransportRoutes = (pickupLocationId, deliveryLocationId) => {
        const routeKey1 = `${pickupLocationId}_${deliveryLocationId}`;
        const routeKey2 = `${deliveryLocationId}_${pickupLocationId}`;
        if (!createdRoutes[routeKey1] && !createdRoutes[routeKey2]) {
            transportsRouts.push({ startLocation: lotLookup?.[pickupLocationId] ?? {}, endLocation: lotLookup?.[deliveryLocationId] ?? {} })
            createdRoutes[routeKey1] = true;
            createdRoutes[routeKey2] = true;
        }
    }
    const markers = [];
    vehiclesData.map(v => {

        let lastActivity = mechanicalActivitiesLookup?.[v.id]?.sort(
            (a, b) => new Date(b.activityDate) - new Date(a.activityDate))?.find(
                a => a.activityStatus == activityStatuses.ItemInProgress || a.activityStatus == activityStatuses.PendingInvoice)

        if (assetFilters?.[AssetFilterTypes.RECENT_UPDATE]) {
            let lotId = lastActivity?.activityDate > v.latestStatusDate ? lastActivity?.lotId : v?.lotId
            setLotsWithVehicles(lotId, v, AssetFilterTypes.RECENT_UPDATE)
        }
        if (assetFilters?.[AssetFilterTypes.LAST_CHECKIN]) {
            const checkinLot = v.lotId;
            setLotsWithVehicles(checkinLot, v, AssetFilterTypes.LAST_CHECKIN)
        }
        if (assetFilters?.[AssetFilterTypes.TRANSPORT]) {
            if (v?.latestLoadId && loadsLookup?.[v?.latestLoadId] && !loadsLookup?.[v?.latestLoadId]?.isComplete) {
                let load = loadsLookup?.[v?.latestLoadId]
                setLotsWithVehicles(load?.pickupLocationId, v, AssetFilterTypes.TRANSPORT)
                setLotsWithVehicles(load?.deliveryLocationId, v, AssetFilterTypes.TRANSPORT)
                updateTransportRoutes(load?.pickupLocationId, load?.deliveryLocationId)
            }
        }
        if (assetFilters?.[AssetFilterTypes.MECHANICAL]) {
            if (mechanicalActivitiesLookup?.[v.id]) {
                setLotsWithVehicles(lastActivity?.lotId, v, AssetFilterTypes.MECHANICAL)
            }
        }
        if (assetFilters?.[AssetFilterTypes.TELEMATICS]) {
            if (v.lastGpsLocation) {
                markers.push({
                    icon: assetLocationsTypesById?.[AssetFilterTypes.TELEMATICS]?.icon(
                        `${v.descriptor ?? v.assetId ?? v.licensePlate}`,
                        '',
                        undefined,
                        `Latitude: ${v.lastGpsLocation?.lat}, Longitude: ${v.lastGpsLocation?.long}`
                    ),
                    onClick: () => onClickLot([{ ...v, locationType: AssetFilterTypes.TELEMATICS }]),
                    id: v.id,
                    center: false,
                    latitude: v.lastGpsLocation?.lat,
                    longitude: v.lastGpsLocation?.long,
                    address: "",
                });
            }
        }
    })

    let filteredLotsWithVehicles = Object.values(lotsWithVehicles)

    filteredLotsWithVehicles.forEach((lot) => {
        const lotId = lot?.id?.toString();

        const vehiclesWithLotName = lot?.vehicles?.map((vehicle) => ({
            ...vehicle,
            lotName: lot.name,
            asset: { id: vehicle.id, descriptor: vehicle?.descriptor, assetId: vehicle?.assetId }
        }));

        if (lot?.vehicles && lot?.vehicles?.length > 0) {
            let types = [...new Set(lot?.vehicles?.map(v => v.locationType))]
            if (types.length > 1) {
                markers.push({
                    icon: <MixedMarker text={`${lot.name}`} number={vehiclesWithLotName?.length} wrapperSize="40" iconSize="17" />,
                    onClick: () => onClickLot(vehiclesWithLotName, lot),
                    id: lotId,
                    center: false,
                    latitude: lot.latitude,
                    longitude: lot.longitude,
                    address: "",
                });
            } else {
                markers.push({
                    icon: assetLocationsTypesById?.[types?.[0]]?.icon(
                        `${lot.name}`,
                        `${vehiclesWithLotName?.length} Assets`,
                        lot?.pmfLot ? "#0090fe" : "#E48900"
                    ),
                    onClick: () => onClickLot(vehiclesWithLotName, lot),
                    id: lotId,
                    center: false,
                    latitude: lot.latitude,
                    longitude: lot.longitude,
                    address: "",
                });
            }
        }
    });

    return (
        <>
            <Paper className={classes.mapPaper} >
                <Grid container spacing={3} className={classes.header} justifyContent="space-between">
                    <Grid item xs={3}>
                        <AssetViewSelector assetFilters={assetFilters} setAssetFilters={setAssetFilters} />
                    </Grid>
                    <Grid item xs={7}>
                        <DataSearchBar
                            rows={vehiclesData}
                            onSearch={onSearch}
                            searchVal={searchVal}
                        />
                    </Grid>
                    <VehiclesMapButton showMap={showMap} setShowMap={setShowMap} style={classes.button} />
                </Grid>
                <div className={classes.mapContainer}>
                    <MapWrapper
                        defaultZoom={7}
                        markers={markers}
                    // routes={transportsRouts} //todo: filter routs when filter vehicles
                    />
                </div>
                <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                    <AssetTable assets={assetsToShow} color={lotToShow?.pmfLot ? '#0090fe' : '#E48900'} />
                </Dialog>
            </Paper>
        </>
    )
}
export default VehiclesMap;