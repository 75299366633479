import NotificationProvider from "components/NotificationProvider";
import { useUpdateActivityPriceMutation } from "features/pricing/pricingSlice";
import { toggleOptions } from "../pricingConsts";
const { Dialog, DialogTitle, DialogContent, DialogActions, Button } = require("@material-ui/core");
const { useStyles } = require("../AddActivityPrice/AddActivityPriceStyles");
const { default: ActivityPriceDialogContent } = require("../ActivityPriceDialogContent");

function UpdateActivityPrice({ open, handleClose, setOpenModel, activityPriceToEdit, setActivityPriceToEdit, activityPricing, activityItems, serviceProviders, lots, vehicleTypes, isLoading  }) {
    const classes = useStyles();

    const [updatePrice, { isLoading: isUpdating }] = useUpdateActivityPriceMutation();

    const onClose = () => {
        setActivityPriceToEdit({});
        handleClose();
    }

    const onChange = (key, val) => {
        setActivityPriceToEdit({ ...activityPriceToEdit, [key]: val });
    };

    const onChangeVehicleTypes = (val) => {
        if(val[val.length - 1] == null && val.length !== 0) {
            val = [null];
            onChange("vehicleTypeIdsList", val);
        }
        else{
            if(activityPriceToEdit.vehicleTypeIdsList?.includes(null)) { 
                val = val.filter(v => v != null);
                onChange("vehicleTypeIdsList", val);
            } else { 
                onChange("vehicleTypeIdsList", val);
            }
        }
    };

    const onEdit = async () => {
        let existing = activityPricing?.find(a =>
            a?.id !== activityPriceToEdit?.id &&
            a?.activityItemId === activityPriceToEdit?.activityItemId &&
            a?.serviceProviderId === activityPriceToEdit?.serviceProviderId &&
            a?.lotId === activityPriceToEdit?.lotId &&
            a?.vehicleTypeIdsList === activityPriceToEdit?.vehicleTypeIdsList &&
            a?.useInventory === activityPriceToEdit?.useInventory &&
            a?.allLots === activityPriceToEdit?.allLots &&
            a?.allPmfLots === activityPriceToEdit?.allPmfLots)
        if (existing) {
            setOpenModel(true)
        }
        else {
            activityPriceToEdit.vehicleTypeIdsList = activityPriceToEdit?.vehicleTypeIdsList?.includes(null) ? null : activityPriceToEdit?.vehicleTypeIdsList
            activityPriceToEdit.lotId = activityPriceToEdit?.priceAppliesTo == toggleOptions.SPECIFIC_LOTS ? activityPriceToEdit?.lotId : null;
            activityPriceToEdit.allLots = activityPriceToEdit?.priceAppliesTo == toggleOptions.ALL_LOTS ? true : false;
            activityPriceToEdit.allPmfLots = activityPriceToEdit?.priceAppliesTo == toggleOptions.ALL_PMF_LOTS ? true : false;           
            let res = await updatePrice(activityPriceToEdit);
            onClose();

            if (!res?.error) {
                NotificationProvider.success('Updated successfully');
                setActivityPriceToEdit(null);
            } else {
                NotificationProvider.error('Failed to update');
            }
        }
    }

    const onCancel = () => {
        onClose();
    }

    const isValid = activityPriceToEdit?.activityItemId && activityPriceToEdit?.serviceProviderId && ((!activityPriceToEdit?.notSupported && activityPriceToEdit?.price) || activityPriceToEdit?.notSupported) 
                        && (activityPriceToEdit?.priceAppliesTo === toggleOptions.SPECIFIC_LOTS ? activityPriceToEdit?.lotId : true);
    const isSaving = isUpdating;

    return (
        <>
            <Dialog open={open} onClose={onClose} >
                <DialogTitle>
                    <div className={classes.dialogTitle}>Edit Activity Price</div>
                </DialogTitle>
                <DialogContent>
                    <ActivityPriceDialogContent isLoading={isLoading} activityPrice={activityPriceToEdit} onChange={onChange} activityItems={activityItems} 
                            serviceProviders={serviceProviders} lots={lots} vehicleTypes={vehicleTypes} onChangeVehicleTypes={onChangeVehicleTypes} multiple={false} />
                </DialogContent>
                <DialogActions>
                    <div className={classes.btnContainer}>
                        <Button className={classes.button} variant="outlined"
                            onClick={onCancel}>Cancel</Button>
                        <Button
                            onClick={onEdit}
                            disabled={!isValid || isSaving}
                            className={classes.button}
                            variant="contained" >
                            {isSaving ? "Saving..." : "Save"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateActivityPrice;