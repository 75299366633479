import { Button, Grid, IconButton, Menu, MenuItem, Paper, Tooltip } from "@material-ui/core";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { DefaultRangesTypes, PredefinedRanges, PredefinedRangesByKey } from "components/DateRange/DefaultRanges";
import { useState } from "react";
import { useGetIssueTrackerDashboardDataQuery } from "../issuesTrackerSlice";
import { AvgCloseTimeWidget } from "./AvgCloseTimeWidget/AvgCloseTimeWidget";
import { AvgTimePerAssigneWidget } from "./AvgTimePerAssigneWidget/AvgTimePerAssigneWidget";
import { AvgTimeToFirstResponseWidget } from "./AvgTimeToFirstResponseWidget/AvgTimeToFirstResponseWidget";
import { CriticalOpenIssuesWidget } from "./CriticalOpenIssuesWidget/CriticalOpenIssuesWidget";
import { IssuesGroupedPieWidget } from "./IssuesGroupedPieWidget/IssuesGroupedPieWidget";
import { OpenCloseIssuesOverTimeWidget } from "./OpenCloseIssuesOverTimeWidget/OpenCloseIssuesOverTimeWidget";
import { OpenIssuesMoreThanTwoWeeksWidget } from "./OpenIssuesMoreThanTwoWeeksWidget/OpenIssuesMoreThanTwoWeeksWidget";
import { OpenIssuesWidget } from "./OpenIssuesWidget/OpenIssuesWidget";
import { IssueTrackerDashboardStyle } from "./style";
import { GraphLoader } from "features/landing/components/WorkorderDashboard/GraphLoader";
import CachedIcon from '@material-ui/icons/Cached';
import CreateIssueMenu from "../CreateIssueMenu";
import { issueTrackerPriorities } from "../issueTrackerConsts";
import WorkflowPageViewSelector from "../WorkflowPageViewSelector/WorkflowPageViewSelector";

export const IssueTrackerDashboard = ({
    issues,
    issuesTrackerToAdd,
    setIssueTrackerToAdd,
    populateFilters
}) => {
    const classes = IssueTrackerDashboardStyle();
    const [selectedRange, setSelectedRange] = useState(DefaultRangesTypes.THREE_MONTHS);
    const [anchorEl, setAnchorEl] = useState(null);

    const range = PredefinedRangesByKey[selectedRange]?.range();
    let { data: issueTrackerDashboardData, error: issuesTrackerError, isFetching: loading, refetch } = useGetIssueTrackerDashboardDataQuery({ startDate: range?.startDate.toDateString(), endDate: range?.endDate.toDateString() });

    const graphHeight = 250;

    const supportedRanges = [DefaultRangesTypes.THREE_MONTHS, DefaultRangesTypes.SIX_MONTHS, DefaultRangesTypes.TWELVE_MONTHS];
    const ranges = PredefinedRanges.filter(range => supportedRanges.includes(range.key));

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleSelectItem = (item) => {
        setSelectedRange(item);
        setAnchorEl();
    }

    const reloadData = () => {
        refetch();
    }

    return (
        <>
            <Grid item xs={12}>
                <Paper elevation={1} className={`${classes.card} `}>
                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item xs={9}>
                            <Button className={`${classes.graphHeader} ${classes.dateRangeSelector}`} onClick={handleClick} endIcon={<KeyboardArrowDownOutlinedIcon />}>
                                Last {PredefinedRangesByKey[selectedRange]?.label}
                            </Button>
                            <Tooltip title='Refresh Dashboard Data'>
                                <IconButton onClick={reloadData} size={"small"} variant="outlined" >
                                    <CachedIcon
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={!!anchorEl}
                                onClose={() => setAnchorEl()}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                {ranges?.map((range, index) =>
                                    <MenuItem key={index} onClick={() => handleSelectItem(range.key)}>
                                        Last {range.label}
                                    </MenuItem>)
                                }
                            </Menu>
                        </Grid>
                        <Grid item xs={3} container direction='row' spacing={1} justifyContent="flex-end">
                            <Grid item>
                                <WorkflowPageViewSelector />
                            </Grid >
                            <Grid item>
                                <CreateIssueMenu issuesTrackerToAdd={issuesTrackerToAdd} setIssueTrackerToAdd={setIssueTrackerToAdd} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={2}>
                <Paper elevation={1} className={`${classes.card} ${classes.countWidget} ${classes.clickableCard}`}
                    onClick={() => populateFilters({ ['isCompleteStr']: { value: 'no' } })}>
                    <GraphLoader loading={loading} />
                    <OpenIssuesWidget issues={issueTrackerDashboardData?.issues} />
                </Paper>
            </Grid>
            <Grid item xs={2}>
                <Paper elevation={1} className={`${classes.card} ${classes.countWidget} ${classes.clickableCard}`}
                    onClick={() => populateFilters({ ['isCompleteStr']: { value: 'no' }, ['priority']: { value: [issueTrackerPriorities.One, issueTrackerPriorities.Two] } })}>
                    <GraphLoader loading={loading} />
                    <CriticalOpenIssuesWidget issues={issueTrackerDashboardData?.issues} />
                </Paper>
            </Grid>
            <Grid item xs={2}>
                <Paper elevation={1} className={`${classes.card} ${classes.countWidget} ${classes.clickableCard}`}
                    onClick={() => populateFilters({ ['openMoreThanTwoWeeksStr']: { value: 'yes' } })} >
                    <GraphLoader loading={loading} />
                    <OpenIssuesMoreThanTwoWeeksWidget issues={issueTrackerDashboardData?.issues} />
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={1} className={`${classes.card} ${classes.countWidget}`}>
                    <GraphLoader loading={loading} />
                    <AvgTimeToFirstResponseWidget dashboardData={issueTrackerDashboardData} issues={issues} />
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={1} className={`${classes.card} ${classes.countWidget}`}>
                    <GraphLoader loading={loading} />
                    <AvgCloseTimeWidget dashboardData={issueTrackerDashboardData} issues={issues} />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={1} className={classes.card} >
                    <GraphLoader loading={loading} />
                    <IssuesGroupedPieWidget dashboardData={issueTrackerDashboardData} graphHeight={graphHeight} />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={1} className={classes.card}>
                    <GraphLoader loading={loading} />
                    <OpenCloseIssuesOverTimeWidget dashboardData={issueTrackerDashboardData} graphHeight={graphHeight} />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={1} className={classes.card} >
                    <GraphLoader loading={loading} />
                    <AvgTimePerAssigneWidget dashboardData={issueTrackerDashboardData} graphHeight={graphHeight * 2} selectedDashboardRange={range} />
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={1} className={classes.card} >
                    <GraphLoader loading={loading} />
                    <AvgTimePerAssigneWidget dashboardData={issueTrackerDashboardData} calcTimeToResolution graphHeight={graphHeight * 2} selectedDashboardRange={range} />
                </Paper>
            </Grid>
        </>
    )
}