import * as React from "react";

function SvgIconKanbanView({ width = 24, height = 24, ...props }) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 7.5V17.5C18 18.12 17.98 18.67 17.91 19.16C17.62 21.79 16.38 22.5 13 22.5H11C7.62 22.5 6.38 21.79 6.09 19.16C6.02 18.67 6 18.12 6 17.5V7.5C6 6.88 6.02 6.33 6.09 5.84C6.38 3.21 7.62 2.5 11 2.5H13C16.38 2.5 17.62 3.21 17.91 5.84C17.98 6.33 18 6.88 18 7.5Z" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 17.5C6 18.12 6.02 18.67 6.09 19.16C5.95 19.17 5.82 19.17 5.67 19.17H5.33C2.67 19.17 2 18.5 2 15.83V9.16996C2 6.49996 2.67 5.82996 5.33 5.82996H5.67C5.82 5.82996 5.95 5.82996 6.09 5.83996C6.02 6.32996 6 6.87996 6 7.49996V17.5Z" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22 9.16996V15.83C22 18.5 21.33 19.17 18.67 19.17H18.33C18.18 19.17 18.05 19.17 17.91 19.16C17.98 18.67 18 18.12 18 17.5V7.49996C18 6.87996 17.98 6.32996 17.91 5.83996C18.05 5.82996 18.18 5.82996 18.33 5.82996H18.67C21.33 5.82996 22 6.49996 22 9.16996Z" stroke="#0090FE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default SvgIconKanbanView;
