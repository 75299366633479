import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    noItemsDiv: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontSize: '1.2em',
        color: theme.palette.primary.main
    },
    paper: {
        padding: ".5em .5em .7em 1.2em",
        marginBottom: '5px'
    },
    icons: {
        paddingRight: "1em"
    }
}))