import { makeStyles } from "@material-ui/core";

export const IssueTrackerBarCounterStyle = makeStyles((theme) => ({
    barContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '25px 0 12px 5px'
    },
    square: {
        width: '10px',
        height: '6px',
        margin: '0 2px',
        backgroundColor: '#ddd',
    },
    lightBlue: {
        backgroundColor: '#b4d9fb',
    },
    darkBlue: {
        backgroundColor: '#3f8ef6',
    },
}))