import { makeStyles } from "@material-ui/core";

export const ActivityStyles = makeStyles((theme) => ({
    dropdownStyle: {
        minWidth: 260,
        width: '100%',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "11px"
    },
    button: {
        margin: "16px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.neutral.medium,
            opacity: 0.7,
            '&:hover': {
                background: theme.palette.primary.dark,
            }
        },
        '&.Mui-disabled:hover': { background: theme.palette.secondary.dark }
    },
    dateWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    timeInput: {
        padding: 17,
        color: '#46535d',
        backgroundColor: '#F3F5F6',
        font: 'inherit',
        fontSize: 15.5,
        width: 110,
        border: 0,
        margin: 0,
        display: 'block',
        minWidth: 0,
        boxSizing: 'content-box',
        letterSpacing: 'inherit'
    },
    label: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,
        minWidth: 260,
    },
    pricing: {
        marginBottom: 2,
        marginTop: 2
    },
    paper: {
        width: "100%",
        padding: '1rem',
        marginTop: "1rem"
    }
}));
