import CopyToClipboard from 'components/CopyToClipboard';
import { AuthorizeTokenViewStyle } from './AuthorizeTokenViewStyle';

const AuthorizeTokenView = ({ }) => {
    const classes = AuthorizeTokenViewStyle();
    const code = new URLSearchParams(window.location.search).get('code');

    
    return (
        <div className={classes.container}>
            <div>Copy this code and use this to get an access token:</div>
            {code}<CopyToClipboard text={code} />
        </div>
    )
}

export default AuthorizeTokenView
