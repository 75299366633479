import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CostCodeStyles from "./CostCodeStyles";
import { useState } from "react";
import { useUpdateCostCodesMutation } from "./costCodeSlice";
import CustomInput from "components/CustomInput";
import ConfirmationPopup from "components/ConfirmationPopup";
import NotificationProvider from "components/NotificationProvider";

const BulkUpdateCostCodes = ({ selectedItems, clientId, setSelectedRows }) => {
    const classes = CostCodeStyles();

    let [bulkUpdate, {isLoading: isLoadingUpdate}] = useUpdateCostCodesMutation();

    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [costCode, setCostCode] = useState("");

    const openDialog = async () => {
        setOpen(true);
    }
    const closeDialog = async () => {
        setOpen(false);
        setCostCode("");
    }
    const dispatch = async () => {
        setOpenPopup(true)
    }

    const onConfirm = async () => {
        let codesToSave = selectedItems.map(s => ({...s, costCode: costCode}));

        let result = await bulkUpdate({ clientId: clientId, costCodes: codesToSave, bulkEdit: true })

        if (result && result.error) {
            NotificationProvider.error(`Failed to save changes made to cost codes`);
        } else {
            NotificationProvider.success(`Successfully saved cost codes`);
            closeDialog();
            setSelectedRows([]);        
        }
    }

    return (
        <div>
            <Button
                onClick={openDialog}
                className={classes.bulkButton}
            >
                Bulk Update Codes
            </Button>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Enter Cost Code</DialogTitle>
                <DialogContent>
                    <Grid>
                        <CustomInput
                            label='Cost Code'
                            elementType='input'
                            value={costCode}
                            onChange={(val) => setCostCode(val)}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={dispatch}
                        disabled={!costCode}
                    >
                        Save
                    </Button>
                </DialogActions>
                <ConfirmationPopup
                    onCancel={() => setOpenPopup(false)}
                    onConfirm={onConfirm}
                    open={openPopup}
                    loading={isLoadingUpdate}
                >
                    <div>{`Are you sure you want to update ${selectedItems?.length} codes?`}</div>
                </ConfirmationPopup>
            </Dialog>
        </div>
    )
};

export default BulkUpdateCostCodes;