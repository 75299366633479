import * as React from "react";

function SvgIconSend(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M8.9307 13.666L8.93058 13.6656L8.36058 11.9556L8.28152 11.7184L8.04435 11.6394L6.33435 11.0694C4.81046 10.5614 3.94489 10.0037 3.46485 9.50483C2.99659 9.01824 2.88416 8.57775 2.88416 8.24246C2.88416 7.91168 2.99623 7.47071 3.46544 6.98147C3.94587 6.48052 4.81168 5.91911 6.3349 5.40746C6.33508 5.4074 6.33525 5.40734 6.33543 5.40728L13.0556 3.16722L13.0563 3.167C14.6464 2.63447 15.8159 2.84124 16.4881 3.50723C17.1573 4.17034 17.366 5.34416 16.833 6.93576L16.8327 6.93643L14.5924 13.6573C14.0804 15.1895 13.5206 16.0593 13.0205 16.5414C12.5329 17.0115 12.0925 17.1237 11.7575 17.1237C11.4223 17.1237 10.982 17.0114 10.4954 16.5423C9.99658 16.0614 9.43874 15.1939 8.9307 13.666ZM6.38971 5.59121L6.3897 5.59121C5.25534 5.97069 4.44928 6.39641 3.91628 6.82751C3.39918 7.24576 3.06374 7.73399 3.06374 8.24246C3.06374 8.75102 3.39924 9.23932 3.91678 9.65683C4.45007 10.087 5.25638 10.5107 6.39083 10.8862C6.39097 10.8862 6.3911 10.8863 6.39123 10.8863L8.38521 11.551L8.38519 11.551L8.39172 11.5531C8.41132 11.5593 8.43271 11.5807 8.43894 11.6003L8.4389 11.6003L8.44106 11.6068L9.10572 13.6008C9.10579 13.601 9.10586 13.6012 9.10592 13.6014C9.48158 14.7364 9.90745 15.5429 10.34 16.0762C10.7597 16.5938 11.2498 16.9283 11.7575 16.9283C12.266 16.9283 12.7542 16.5929 13.1724 16.0758C13.6035 15.5428 14.0293 14.7367 14.4087 13.6023L14.4089 13.6018L16.6493 6.88058L16.6496 6.87965C16.8658 6.22706 16.9644 5.60645 16.9272 5.05873C16.89 4.51141 16.7135 4.00474 16.3465 3.63766L15.9929 3.99121L16.3465 3.63766C15.9783 3.26953 15.4704 3.09563 14.9243 3.06077C14.3778 3.02589 13.7595 3.12668 13.1115 3.34271L13.111 3.34287L6.38971 5.59121Z" fill="#0090FE" stroke="white" />
            <path d="M8.43773 11.2403L8.43822 11.2398L11.2719 8.39818C11.2719 8.39814 11.272 8.3981 11.272 8.39806C11.2875 8.38264 11.3102 8.37244 11.3379 8.37244C11.3658 8.37244 11.3885 8.38269 11.404 8.39818C11.4194 8.41366 11.4297 8.43638 11.4297 8.46421C11.4297 8.49204 11.4194 8.51475 11.404 8.53024L11.4035 8.53073L8.5693 11.3728L8.56346 11.3787L8.55781 11.3847C8.55745 11.3851 8.55392 11.3888 8.5438 11.3928C8.533 11.3971 8.51872 11.4 8.50376 11.4C8.49003 11.4 8.47847 11.3976 8.4689 11.3937C8.45979 11.39 8.44928 11.3839 8.43773 11.3723C8.42224 11.3568 8.41199 11.3341 8.41199 11.3063C8.41199 11.2785 8.42224 11.2557 8.43773 11.2403Z" fill="#0090FE" stroke="white" />
        </svg>
    );
}

export default SvgIconSend;
