import { Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import LoadingSpinner from "../../components/LoadingSpinner";
import ActivityTypesList from "./ActivityTypesList/ActivityTypesList";
import PricingList from "./PricingList/PricingList";
import { useStyles } from "./PricingPageStyles";

const PricingPage = () => {
    const classes = useStyles();

    const getSelectedTab = () => {
        const params = new URLSearchParams(window.location.search);
        for (let [key, value] of params) {
            if (key === "selectedTab")
                return parseInt(value);
        }
        return 0;
    }

    const isLoading = () => false;


    if (isLoading()) {
        return <LoadingSpinner loading={true} />;
    }


    const tabs = [
        {
            label: 'Activity types',
            component: <ActivityTypesList />
        },
        {
            label: 'Pricing',
            component: <PricingList />
        }
    ]

    return (
        <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
            <Paper className={classes.paper}>
                <TabsWrapper tabs={tabs} selectedTab={() => getSelectedTab()} />
            </Paper>
        </Authorize>
    );
};

export default PricingPage;
