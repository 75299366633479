import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';

const Validator = {
    validateVin: (vin) => {
        return vin.length === 17 && vin.toLowerCase().indexOf('q') == -1 && vin.toLowerCase().indexOf('i') == -1 && vin.toLowerCase().indexOf('o') == -1;
    },
    validateEmail: (email) => {
        const res = String(email).toLowerCase()
            .match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
        return res;
    },
    validateNumber: (str) => {
        return !str.isNan()
    },
    validatePhone: (phone) => {
        return phone && isValidPhoneNumber(phone);
    },
    validateWeekDaysOnly: (date) => {
        let day = moment(date).startOf('day').day();
        if ([6, 0].includes(day)) {
            return false;
        } else {
            return true;
        }
    }
};

export default Validator;
