import { Paper, TextField } from "@material-ui/core";
import { CustomReportStyles } from "../style";

const ReportHeader = ({ report, onReportFieldChange }) => {

    const classes = CustomReportStyles();

    return (
        <Paper className={classes.headerSection}>
            <TextField
                style={{ width: '100%' }}
                value={report?.title}
                label=""
                variant="outlined"
                onChange={e => onReportFieldChange('title', e.target.value)}
                required
                touched
                placeholder="Add report title..."
                className={classes.title}
            />
            <TextField
                style={{ width: '100%' }}
                value={report?.description}
                label=""
                variant="outlined"
                onChange={e => onReportFieldChange('description', e.target.value)}
                required
                touched
                placeholder="Add report description..."
            />
        </Paper>
    );
}
export default ReportHeader; 