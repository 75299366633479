import { Badge, Button, Tooltip } from "@material-ui/core"
import { tableFiltersStyle } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { collapseFilters } from "../genericTableStateSlice";
import { IconFilters } from "icons";

export const ExpandFiltersPanel = ({ filtersState }) => {

    const classes = tableFiltersStyle();
    const dispatch = useDispatch();
    const { collapsedFilters } = useSelector(state => state.genericTableState);

    return (
        <>
            {collapsedFilters && <Tooltip title={'Expand filters panel'}>
                <Badge
                    className={classes.openFiltersBadge} badgeContent={Object.values(filtersState ?? {})?.filter(f => f?.value)?.length ?? 0} color="error"
                    classes={{ badge: classes.badge }}>
                    <Button
                        className={classes.openFiltersBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(collapseFilters(false))}>
                        <IconFilters />
                    </Button>
                </Badge>
            </Tooltip>}
        </>
    )
}