import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useEffect, useState } from "react";
import { editReadinessStyles } from "./style";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import NotificationProvider from "components/NotificationProvider";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { readinessStatuses } from "features/vehicles/components/AssetReadiness/readinessUtil";
import { useUpdateVehicleReadinessMutation } from "features/vehicles/readiness/readinessSlice";
import usePrompt from "hooks/usePrompt";
import { ReadinessItem } from "./ReadinessItem";

const EditReadiness = ({ vehicleId, readinessValues, clientId }) => {
    const classes = editReadinessStyles();

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);

    const clientConfig = clientsWithConfigs?.find(c => c.id == clientId);

    const [updateReadiness, { isLoading }] = useUpdateVehicleReadinessMutation();

    const [readinessOptions, setReadinessOptions] = useState([]);
    const [open, setOpen] = useState(false);

    const [messages, setMessages] = useState([]);
    const [changed, setChanged] = useState(false);

    const { triggerPrompt } = usePrompt();

    const handleSave = async () => {
        const isValid = validate();
        if (isValid) {
            setMessages([]);
            const toUpdate = readinessOptions.filter(opt => opt.changed);
            if (toUpdate.length) {
                const res = await updateReadiness({
                    readiness: toUpdate,
                    vehicleId: vehicleId,
                });
                if (!res?.error) {
                    setChanged(false);
                    NotificationProvider.success('Saved successfully');
                } else {
                    NotificationProvider.error('Failed to save');
                }
            }
        } else {
            setMessages([...messages, 'Please enter all required fields']);
        }
    }


    const validate = () => {
        return true;
    }

    const onChange = (row, status) => {
        const updated = [...readinessOptions];
        updated?.find((current, index) => {
            if (current.readinessConfigId == row.readinessConfigId) {
                current.status = status;
                current.changed = true;
                setChanged(true);
                return true;
            }
            return false;
        });
        setReadinessOptions(updated);
    }

    useEffect(() => {
        if (open) {
            setChanged(false);
            const options = clientConfig?.readinessOptions?.map(option => ({
                ...option,
                readinessConfigId: option.id,
                status: readinessValues?.find(r => r.readinessConfigId === option.id)?.status ?? readinessStatuses.RED
            }))
            setReadinessOptions(options ?? [])
        }
    }, [readinessValues, clientConfig, open]);

    return (
        <>
            <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT_READINESS}>
                <IconButton className={classes.editIcon} onClick={() => setOpen(true)}><EditIcon /></IconButton>
            </Authorize>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth='xs'
            >
                <DialogTitle>Update Asset Readiness</DialogTitle>
                <DialogContent >
                    <div className={classes.configsContainer}>
                        <Grid className={classes.configsContainer} container direction="column" spacing={2}>
                            {
                                readinessOptions?.map(readinessOption =>
                                    <Grid item key={readinessOption.id}>
                                        <ReadinessItem
                                            option={readinessOption}
                                            onChange={onChange}
                                        />
                                    </Grid>)
                            }</Grid>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                        className={classes.button}
                        variant="contained"
                    >
                        Close
                    </Button>
                    {changed &&
                        <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT_READINESS}>
                            <Button
                                onClick={handleSave}
                                disabled={isLoading}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </Button>
                        </Authorize>}
                </DialogActions>

            </Dialog >
        </>
    )
}
export default EditReadiness;

