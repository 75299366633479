import { createSlice } from "@reduxjs/toolkit";

export const usersTableFiltersSlice = createSlice({
    name: 'usersTableFilters',
    initialState: {
        searchVal: '',
        filters: {
            clientId: { value: null },
            lotId: { value: null },
            roleId: { value: null },
            permissions: [],
        },
        filtersSetFromOtherScreen: false
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        mergeSavedFilter: (state, action) => {
            if (Object.values(state.filters).every(value => !value?.value?.length)) {
                state.filters = action.payload;
            }
        },
        setLot: (state, action) => {
            state.filters.lotId = { value: action.payload + "" };
        },
        setFiltersSetFromOtherScreen: (state, action) => {
            state.filtersSetFromOtherScreen = action.payload;
        }
    },
});

export const { setSearchVal, setFilters, setStatuses,
    setLot, mergeSavedFilter, 
    setFiltersSetFromOtherScreen } = usersTableFiltersSlice.actions;
export default usersTableFiltersSlice.reducer;
