import { Checkbox, Grid, IconButton, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { IconDeleteBlue, IconEdit } from "icons";
import { useEffect, useState } from "react";
import { costCodesById } from "./consts";
import { useResetEditing } from "./CostCodesPage";
import CostCodeStyles from "./CostCodeStyles";

const useStyles = CostCodeStyles;

const CostCodeItem = ({ costCodeType, item, onClickEdit, setDisableUpdate, selectedRows, toggleRowSelected }) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    const [costCode, setCostCode] = useState(item?.costCode);

    useEffect(() => {
        setCostCode(item?.costCode);
    }, [item])

    const onClickRemove = () => {
        setCostCode("");
        setDisableUpdate(false);
        onEdit("");
    }

    const { resetEditing } = useResetEditing();

    useEffect(() => {
        if (resetEditing) {
            setIsEditing(false);
        }
    }, [resetEditing]);

    const onEdit = (val) => {
        setCostCode(val); 

        let codeToSave = { 
            costCodeType: costCodeType, 
            costCode: val, 
            activityItemId: costCodeType === costCodesById.ACTIVITIES ? item?.id : null,
            storageInvoiceField: costCodeType === costCodesById.STORAGE ? item?.id : null,
            transportField: costCodeType === costCodesById.TRANSPORT ? item?.id : null,
        }
        onClickEdit(codeToSave);
    }

    return (
        <ListItem key={item?.id} className={classes.listItem}>
            <Checkbox
                checked={selectedRows?.some(row => row.id === item?.id && row.costCodeType === costCodeType)}
                onChange={(e, checked) => toggleRowSelected(checked, item.id, costCodeType)}
                className={classes.checkbox}
            />
            <ListItemText primary={<div className={classes.name}>{item?.name + ":"}</div>}></ListItemText>
                {isEditing ? 
                <div className={classes.input}>
                    <CustomInput
                        elementType='input'
                        value={costCode}
                        onChange={(val) => onEdit(val)}
                        className={classes.textbox}
                    />
                </div>
                : <Grid item className={classes.code}>{costCode}</Grid>}
            <ListItemSecondaryAction>
                <Grid className={classes.icons}>
                <IconButton className={classes.editicon} onClick={() => {setIsEditing(!isEditing); setDisableUpdate(false);}} ><IconEdit/></IconButton>
                <IconButton onClick={() => onClickRemove()}><IconDeleteBlue width={"18"} /></IconButton>
                </Grid>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default CostCodeItem;