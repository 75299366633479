import { getNoteInvalidateTag, getVehicleActivityLogNotesTag } from 'features/notes/notesSlice';
import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';
import { NoteEntityTypes } from "features/notes/notesConsts";
import moment from 'moment';

const apiPrefix = 'activity';
export const activityApi = baseApi.injectEndpoints({
    reducerPath: 'activityApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllActivityTypes: builder.query({
            query: () => `activitytype`,
            providesTags: [apiTags.ACTIVITY_TYPES]
        }),
        getAllActivitiesItemByTypeId: builder.query({
            query: (activitytypeid) => `activityitem/${activitytypeid}`,
        }),
        getActivityTypePriceByItemId: builder.query({
            query: (activityitemid) => `activitytypeprice/${activityitemid}`,
        }),
        getAllActivities: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.ACTIVITIES]
        }),
        // getActivityStatuses: builder.query({
        //     query: () => `vehicle/activitystatuses`,
        // }),
        getAllActivityItems: builder.query({
            query: () => `activityitem`,
            providesTags: [`activityitem`]
        }),
        getAllActivityLogs: builder.query({
            query: () => `activityLog`,
            providesTags: [apiTags.ACTIVITY_LOG]
        }),
        getImportTemplate: builder.mutation({
            query: () => ({
                url: `activitylog/downloadtemplate`,
                method: "GET",
                responseHandler: async (response) => {
                    const filename = 'Import activities template.csv';
                    const url = window.URL.createObjectURL(await response.blob());
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    link.click();
                },
                cache: "no-cache"
            })
        }),
        updateActivityLog: builder.mutation({
            query: activityLog => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: activityLog
            }),
            invalidatesTags: (result, error, arg) => [
                "Tasks",
                getVehicleActivityLogNotesTag(),
                getNoteInvalidateTag(arg.id, NoteEntityTypes.ACTIVITY),
                apiTags.MECHANICAL_ACTIVITIES,
                apiTags.ACTIVITY_TYPE_INVENTORY,
                apiTags.MECHANICAL_TASK,
                apiTags.VEHICLE,
                apiTags.ACTIVITY_LOG
            ],
        }),
        addActivity: builder.mutation({
            query: activityLog => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: activityLog
            }),
            invalidatesTags: (result, error, arg) => [
                "Tasks",
                "Readiness",
                getVehicleActivityLogNotesTag(),
                apiTags.MECHANICAL_ACTIVITIES,
                apiTags.ACTIVITY_TYPE_INVENTORY,
                apiTags.VEHICLE,
                apiTags.ACTIVITY_LOG
            ],
        }),
        deleteActivity: builder.mutation({
            query: id => ({
                url: `activitylog/delete`,
                method: "POST",
                body: { activityLogId: id }
            }),
            invalidatesTags: ["Tasks", "Vehicle"]
        }),
        importActivities: builder.mutation({
            query: activities => ({
                url: `activitylog/import`,
                method: "POST",
                body: activities
            }),
        }),
        approvePendingApproval: builder.mutation({
            query: ({ activityLogId, price, revenue }) => ({
                url: `${apiPrefix}/approve/pendingApproval`,
                method: "POST",
                body: {
                    activityLogId,
                    revenue,
                    price
                }
            }),
            invalidatesTags: [apiTags.MECHANICAL_ACTIVITIES]
        }),
        approvePendingInvoice: builder.mutation({
            query: ({ activityLogIds, price, revenue }) => ({
                url: `${apiPrefix}/approve/pendingInvoice`,
                method: "POST",
                body: {
                    activityLogIds,
                    revenue,
                    price
                }
            }),
            invalidatesTags: [apiTags.MECHANICAL_ACTIVITIES]
        }),
        moveToNext: builder.mutation({
            query: ({ activityLogIds }) => ({
                url: `${apiPrefix}/moveToNext`,
                method: "POST",
                body: {
                    activityLogIds
                }
            }),
            invalidatesTags: [apiTags.MECHANICAL_ACTIVITIES]
        }),
        confirmActivityPrice: builder.mutation({
            query: ({ activityLogId, confirmed }) => ({
                url: `${apiPrefix}/confirmPrice`,
                method: "POST",
                body: {
                    activityLogId,
                    confirmed
                }
            }),
            invalidatesTags: [apiTags.MECHANICAL_ACTIVITIES, apiTags.ACTIVITY_LOG]
        }),
        addActivityType: builder.mutation({
            query: activityType => ({
                url: `activityItem`,
                method: "POST",
                body: activityType
            }),
            invalidatesTags: [`activityitem`, apiTags.ACTIVITY_TYPES, apiTags.MECHANICAL_RULE_TYPES]
        }),
        getActivityFieldsTypes: builder.query({
            query: () => `activityItem/fieldTypes`,
        }),
        updateActivityType: builder.mutation({
            query: activityType => ({
                url: `activityItem/update`,
                method: "PUT",
                body: activityType
            }),
            invalidatesTags: ["activityitem", apiTags.ACTIVITY_TYPES, apiTags.MECHANICAL_RULE_TYPES]
        }),
        canDelete: builder.mutation({
            query: (id) => ({
                url: `activityItem/candelete/${id}`,
                method: "GET",
            }),
        }),
        deleteActivityType: builder.mutation({
            query: (id, canDelete) => ({
                url: `activityitem/delete`,
                method: "PUT",
                body: { Id: id, canDelete: canDelete }
            }),
            invalidatesTags: ["activityitem", apiTags.ACTIVITY_TYPES, apiTags.MECHANICAL_RULE_TYPES]
        }),
        addActivityTypeInventoryConfiguration: builder.mutation({
            query: activityTypeInventoryConfiguration => ({
                url: `activityType/activityTypeInventoryConfiguration`,
                method: "POST",
                body: activityTypeInventoryConfiguration
            }),
            invalidatesTags: (result, error, arg) => [apiTags.ACTIVITY_TYPES, apiTags.MECHANICAL_RULE_TYPES],
        }),
        getAllActivityTypeInventoryConfigs: builder.query({
            query: () => `activitytype/activityTypeInventorySettings`,
            providesTags: [apiTags.ACTIVITY_TYPES]
        }),
        getAllStepsToCompleteConfig: builder.query({
            query: () => `activitytype/stepsToCompleteConfiguration`,
            providesTags: [apiTags.ACTIVITY_TYPES]
        }),
        getMechanicalActivities: builder.query({
            query: () => `mechanicals/activities`,
            providesTags: [apiTags.MECHANICAL_ACTIVITIES],
            transformResponse: (response) => {
                return response.map(activity => ({
                    ...activity,
                    activityMonth: moment(activity.activityDate).format('YYYYMM')
                }));
            }
        }),
        getWorkOrderInfo: builder.query({
            query: (workOrderId) => `mechanicals/workOrderInfo?workOrderId=${workOrderId}`,
            providesTags: [apiTags.MECHANICAL_TASK]
        }),
        getAllActivityItemsWithCustomFields: builder.query({
            query: () => `activityitem/customFields`,
            providesTags: [`activityitem`]
        }),
        getWorkOrderCustomData: builder.query({
            query: (workOrderId) => `activityLog/customData/${workOrderId}`,
            providesTags: [apiTags.ACTIVITY_LOG]
        }),
        getWorkordersForVehicle: builder.query({
            query: ({ vehicleId }) => `activityLog/workorders?vehicleId=${vehicleId}`
        })
    }),
});

export const { useGetAllActivityTypesQuery,
    useGetAllActivitiesItemByTypeIdQuery,
    useGetActivityTypePriceByItemIdQuery,
    useGetAllActivitiesQuery,
    //useGetActivityStatusesQuery,
    useGetAllActivityItemsQuery,
    useGetAllActivityLogsQuery,
    useGetImportTemplateMutation,
    useUpdateActivityLogMutation,
    useAddActivityMutation,
    useDeleteActivityMutation,
    useImportActivitiesMutation,
    useApprovePendingApprovalMutation,
    useApprovePendingInvoiceMutation,
    useConfirmActivityPriceMutation,
    useAddActivityTypeMutation,
    useGetActivityFieldsTypesQuery,
    useUpdateActivityTypeMutation,
    useCanDeleteMutation,
    useDeleteActivityTypeMutation,
    useAddActivityTypeInventoryConfigurationMutation,
    useGetAllActivityTypeInventoryConfigsQuery,
    useGetAllStepsToCompleteConfigQuery,
    useMoveToNextMutation,
    useGetMechanicalActivitiesQuery,
    useGetWorkOrderInfoQuery,
    useGetAllActivityItemsWithCustomFieldsQuery,
    useGetWorkOrderCustomDataQuery,
    useGetWorkordersForVehicleQuery
} = activityApi;
