import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { activityStatusById } from "features/activity/activityConsts";
import { useGetWorkOrderInfoQuery } from "features/activity/activitySlice";
import ActivityStatusAliasView from "features/activityStatusAlias";
import { useState } from "react";
import { IssueTrackerCompleteWorkflowStyle } from "./style";
import CloseIcon from '@material-ui/icons/Close';

export const IssueTrackerCompleteWorkflow = ({ issue, open, onSave, onCancel }) => {

    const classes = IssueTrackerCompleteWorkflowStyle();

    let { data: workorder, error: vehicleDetailsError, isLoading: isVehicleDetailsLoading, refetch: refecthLog } = useGetWorkOrderInfoQuery(issue?.linkedWorkOrders?.[0]?.id, { skip: !open || !issue?.linkedWorkOrders });
    workorder = workorder?.data;

    const [activityStatus, setActivityStatus] = useState();

    const onChangeStatus = (status) => {
        setActivityStatus(status);
    }

    const onSaveAction = async (saveStatus) => {
        onSave(saveStatus ? activityStatus : null)
    };

    return (<>
        <Dialog open={open} fullWidth maxWidth='sm' >
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <div>Resolve Ticket & Linked Activity</div>
                    <CloseIcon className={classes.closeIcon} onClick={onCancel} />
                </Grid>
            </DialogTitle>
            <DialogContent >
                <Grid container direction="column" className={classes.content}>
                    <div className={classes.text} >
                        You are about to resolve the ticket, would you like to update the status of the linked activity?
                    </div>
                    <Grid item alignItems='center' container spacing={1} className={classes.text}>
                        <Grid item>Current activity status is:</Grid>
                        <div style={{ fontWeight: '500' }}>{activityStatusById?.[workorder?.activityId]?.name}</div>
                    </Grid>
                    <Grid item alignItems='center' container spacing={1} className={classes.text}>
                        <Grid item>Update Status To:</Grid>
                        <ActivityStatusAliasView
                            clientId={workorder?.vehicle?.clientId}
                            readonly={false}
                            onChange={val => onChangeStatus(val)}
                            selectCompleteStatusesdOnly
                            label={''}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSaveAction(false)} variant='outlined'>
                    No, save the ticket only
                </Button>
                <Button disabled={!activityStatus} onClick={() => onSaveAction(true)} variant='outlined' color='primary'>
                    Yes, save the ticket and update the activity
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}