import { Button } from '@material-ui/core';
import { useState } from 'react';
import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WarningIcon from '@material-ui/icons/Warning';
import EmptyPlaceholder from '../../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import importStatuses from '../importStatuses';
import { importLoadResultsDialogStyle } from './ImportLoadResultsDialogStyle';

function ImportLoadResultsDialog({ importResults, error, open, setOpen, loading }) {

    const classes = importLoadResultsDialogStyle();
    const [showErrors, setShowErrors] = useState(true);
    const [showSuccess, setShowSuccess] = useState(true);

    const [selectedClient, setselectedClient] = useState('1');


    const handleClose = () => {
        setOpen && setOpen(false);
    };

    const handleShowErrors = () => {
        setShowErrors(!showErrors);
    };
    const handleShowSuccess = () => {
        setShowSuccess(!showSuccess);
    };


    const importedLoads = error ? [] : importResults?.filter(l => !l.errorMessages?.length);
    const failedLoad = error ? [] : importResults?.filter(l => l?.errorMessages?.length);

    const stats = [
        {
            title: 'Imported Loads',
            count: importedLoads?.length,
            onClick: () => handleShowSuccess(),
            selected: showSuccess
        },
        {
            title: 'Failed Loads',
            count: failedLoad?.length,
            onClick: () => handleShowErrors(),
            selected: showErrors
        }
    ]

    const resultsToShow = [
        ...(showSuccess ? importedLoads ?? [] : []),
        ...(showErrors ? failedLoad ?? [] : [])
    ];

    return (
        <Dialog
            open={open}
            onClose={() => { }}
            aria-labelledby="form-dialog-title"
            maxWidth={'lg'}
            fullWidth={true}>
            <DialogTitle id="import-dialog-title">{'Import Results'}</DialogTitle>
            <DialogContent className={classes.content}>
                {loading ?
                    <div>
                        <div className={classes.progressText}>Processing loads, please wait</div>
                        <div><LinearProgress /> </div>
                    </div> :
                    <>
                        <div className={classes.stats}>
                            {stats.map(s =>
                                <div onClick={() => s.onClick()} className={`${classes.importCountWrapper} ${s?.selected ? classes.selected : ''}`}>
                                    <div className={classes.count}> {s?.count}</div>
                                    <div className={classes.text}>{s?.title}</div>
                                </div>)}
                        </div>
                        {error?.data && Array.isArray(error?.data) ? <div>
                            {error?.data?.map((e, i) => <div className={classes.error} key={i}><WarningIcon />{e}</div>)}
                        </div> :
                            <div className={classes.items}>
                                {resultsToShow?.length ? resultsToShow?.map((loadResult, i) =>
                                    <ImportItem loadResult={loadResult} key={i} />)
                                    :
                                    <EmptyPlaceholder />}
                            </div>
                        }
                    </>}
            </DialogContent>
            <DialogActions>
                {!loading && <Button onClick={handleClose} color="primary">
                    Done
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

export default ImportLoadResultsDialog;

function ImportItem({ loadResult }) {
    const classes = importLoadResultsDialogStyle();
    const [collapsed, setCollapsed] = useState(true);

    return (

        <div className={classes.itemWrapper} onClick={() => setCollapsed(!collapsed)}>
            <div className={classes.itemHeader}>
                <div>
                    <div className={classes.itemTitle}>External id: {loadResult.externalId}</div>
                    <div >Row number: {loadResult.rowNumber}</div>
                    <div>{importStatuses[loadResult.processStatus]?.label}</div>
                </div>
                <KeyboardArrowDownIcon />
            </div>
            <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                <div>{loadResult.messages?.map(msg => <div>{msg}</div>)}</div>
                <div>{loadResult.errorMessages?.map(msg => <div>{msg}</div>)}</div>
            </Collapse>
        </div>

    )
}