import * as React from "react";

function SvgIconPin(props) {
  return (
    <svg
      width={29}
      height={29}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.852 27.283c.171.173.405.27.648.269a.904.904 0 00.648-.269l7.038-7.038a10.875 10.875 0 10-15.372 0l7.038 7.038zM6.152 9.1A9.042 9.042 0 0114.5 3.518a9.042 9.042 0 016.39 15.432l-6.39 6.39-6.39-6.39A9.042 9.042 0 016.152 9.1zm3.766 2.91a4.582 4.582 0 109.164 0 4.582 4.582 0 00-9.164 0zm1.833 0a2.75 2.75 0 115.498 0 2.75 2.75 0 01-5.498 0z"
        fill="#0090FE"
      />
    </svg>
  );
}

export default SvgIconPin;
