import { makeStyles } from '@material-ui/core/styles';

export const LotTaskItemStyle = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        padding: '1.5em',
        margin: '0.5em 0',
        borderRadius: 0,
        boxSizing: 'border-box',
        boxShadow: '1px 3px 7px #dddddd'
    },
    label: {
        color: theme.palette.secondary.dark,
        marginRight: '0.5em',
        fontWeight: 500,
    },
    longText: {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical'
    },
}));
