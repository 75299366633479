import { makeStyles } from '@material-ui/core/styles';

export const Styles = makeStyles((theme) => ({
    panel: {
        background: theme.background,
        width: '50em',
        height: '100%'
    },
    header: {
        fontSize: '1.5em',
        background: theme.palette.secondary.main,
        padding: '1em',
        //position: 'fixed',
        //top: '0px',
        width: '100%',
        color: theme.palette.secondary.contrastText,
        zIndex: 1
    },
    filters: {
        //marginTop: '5.5em',
        paddingLeft: '1em',
        background: theme.palette.background.default
    },
    search:{
        background: theme.palette.background.default,
        width:'100%'
    },
    icon: {
        fill: theme.palette.primary.contrastText
    }

}));
