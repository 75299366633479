import { Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { VehicleListStyles } from './VehicleListStyles';
import EditVehiclesList from 'features/access/AccessGroupList/Cards/Lists/EditListsComponents/EditVehiclesList';
import { selectUserClientId } from 'features/user/userSlice';

const VehicleList = ({ }) => {
    const classes = VehicleListStyles()

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();
    const clientId = useSelector(selectUserClientId);

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, [key]: val }))
    }

    return (
        <>
            <Paper className={classes.paper}>
                <div className={classes.title}>Vehicles</div>
                <EditVehiclesList clientId={accessToEdit?.clientId || clientId} vehicles={accessToEdit?.vehicles} setVehicles={(val) => onChangeValue("vehicles", val)} />
            </Paper>
        </>
    );
}

export default VehicleList;