import { makeStyles } from "@material-ui/core";

export const FormOutputStyle = makeStyles((theme) => ({
    reportContainer: {
        position: 'relative',
        paddingBottom: '7em',
        width: '80%',
        margin: '0 auto',
    },
    reportActions: {

    },
    exportableReport: {
        fontSize: '14px',
        background: 'white',
        position: 'relative',
    },
    reportActions: {
        padding: '1em',
        background: 'white',
        position: 'fixed',
        width: 'fit-content',
        bottom: '2em',
        left: '50%',
        zIndex:1
    },
    elementContainer: {
        padding: '0.5em 0'
    },
    imageElement: {
        marginTop: '0.5em'
    },
    generated: {
        fontSize: '0.8em',
        color: 'grey',
        padding: '0.5em 1em 0 0',
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: '1em'
    }
}))