import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'report';
export const reportApi = baseApi.injectEndpoints({
    reducerPath: 'reportApi',
    baseQuery: baseQuery,
    tagTypes: ["Reports", "Report"],
    endpoints: (builder) => ({
        addNewReport: builder.mutation({
            query: createReportRequest => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: createReportRequest
            }),
        }),
        getMyReports: builder.query({
            query: () => `${apiPrefix}`
        }),
        getFunctionDate: builder.query({
            query: (date) => `${apiPrefix}/GetFunctionDate?startDate=${date}`
        }),
    }),
});

export const {
    useAddNewReportMutation,
    useGetMyReportsQuery,
    useGetFunctionDateQuery,
} = reportApi;

