import { IssueTrackerDashboardStyle } from "../style";

export const OpenIssuesWidget = ({ issues }) => {
    const classes = IssueTrackerDashboardStyle();
    let openIssues = issues?.filter(issue => !issue.isComplete);

    return (<>
        <div className={classes.cardCount}>{openIssues?.length}</div>
        <div className={classes.cardText}>Open Issues</div>
    </>
    )
}