import LoadingSpinner from '../LoadingSpinner';
import { pageStyles } from './PageStyles';

const PageComponent = ({ header, content, children, loading, showHalf =false }) => {
    const classes = pageStyles();

    return (
        <div className={showHalf?classes.pageContainerHalf : classes.pageContainer}>
            {!!header &&<div className={classes.header}>
                {header}
            </div>}
            <div className={classes.content}>
                {content ?? children}
            </div>
            <LoadingSpinner loading={loading} />
        </div>
    )
}

export default PageComponent
