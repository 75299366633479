import { issueTrackerPriorities } from "features/IssuesTracker/issueTrackerConsts";
import { IssueTrackerDashboardStyle } from "../style";

export const CriticalOpenIssuesWidget = ({ issues }) => {
    const classes = IssueTrackerDashboardStyle();
    let criticalOpenIssues = issues?.filter(issue => !issue.isComplete && issue.priority < issueTrackerPriorities.Three)
    return (<>
        <div className={classes.cardCount}>{criticalOpenIssues?.length}</div>
        <div className={classes.cardText}>Urgent Open Issues</div>
    </>
    )
}