import { Grid, IconButton, List, ListItem, ListItemText, Paper } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import FormatService from "services/formatService";
import { useGetFormVersionsByTypeForClientQuery } from "../formBuilderSlice";
import { FormStatusBadge } from "./FormStatusBadge";
import { FormVersionStyles } from "./style";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";

export const FormVersions = ({
    formType,
    clientId,
    onClose,
    onSelectVersion
}) => {

    const classes = FormVersionStyles();

    let { data: versions, error: formError, isFetching: loadingVersions } = useGetFormVersionsByTypeForClientQuery({ clientId, formType });

    return (
        <>
            <Paper className={classes.versionsContainer}>
                <Grid container justifyContent="space-between" alignItems="center" className={classes.title}>
                    <div>History ({versions?.length ?? 0})</div>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <List className={classes.versionsList}>
                    {loadingVersions && <div style={{ padding: '1em' }}><ShimmerLoader count={10} /></div>}
                    {!loadingVersions && !versions?.length && <EmptyPlaceholder text="There are no previous versions" />}
                    {versions?.map(v => <ListItem button divider onClick={() => onSelectVersion(v.latest ? undefined : v.version)}>
                        <ListItemText
                            secondary={<>
                                <Grid container justifyContent="space-between">
                                    {/* <div>Version {v.version ?? '-'}</div> */}
                                    <div><b>{v.name}</b></div>
                                    <FormStatusBadge status={v.formStatus} />
                                </Grid>
                                <div>{FormatService.formatDateTime(v.modifiedDate ?? v.createdDate)}</div>
                            </>}
                        >
                        </ListItemText>
                    </ListItem>)}
                </List>
            </Paper>
        </>
    )
}