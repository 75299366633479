import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper
} from '@material-ui/core';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { hasPermission } from 'components/Authorize/authorizeUtil';
import { TabsWrapper } from 'components/TabsWrapper/TabsWrapper';
import { selectUserId } from 'features/user/userSlice';
import { IconKey, IconLot, IconUser } from 'icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userPermissions } from "../../../../components/Authorize/permissions/userPermissions";
import { useGetClaimsByUserIdQuery } from '../../usersSlice';
import { useGetUserLotRelationsForUserByManagerQuery } from "../userManagementSlice";
import UserTasksList from './UserTasks/UserTasksList';
import usersPageStyle from './UsersPageStyle';
import EditUserInformation from './userInformation/EditUserInformation';
import EditUserLotRelations from './userLotRelations/EditUserLotRelations';
import EditUserPermissions from './userPermissions/EditUserPermissions';
import EditUserRestrictions from './userRestrictions/EditUserRestrictions';
import {userManagementPermissions} from "../../../../components/Authorize/permissions/userManagementPermissions";

const useStyles = usersPageStyle;

const EditUserDialog = ({user, roles, clients, refetchUsers, lots, onCancel}) => {
    const classes = useStyles();
    lots = lots || [];
    clients = clients || [];

    const userId = useSelector(selectUserId);

    let {
        data: userLotRelations,
        error: relationsError,
        isLoading: isLoadingRelations
    } = useGetUserLotRelationsForUserByManagerQuery(userId, {skip: !userId});
    let {
        data: userClaims,
        error: userClaimsError,
        isLoading: isLoadingUserClaims
    } = useGetClaimsByUserIdQuery(user?.id, {skip: !user?.id});

    userLotRelations = userLotRelations || [];
    userClaims = userClaims?.data || [];

    const [relations, setRelations] = useState(userLotRelations);

    useEffect(() => {
        setRelations(userLotRelations);
    }, [isLoadingRelations]);


    const tabs = [];
    if (hasPermission(userManagementPermissions.USER_INFORMATION.key)) {
        tabs.push({
            label: <><IconUser width={25} height={25} className={classes.labelIcon}/>User Information</>,
            component: <EditUserInformation user={user} roles={roles} userClaims={userClaims}
                                            refetchUsers={refetchUsers}
                                            onCancel={onCancel}
                                            relations={userLotRelations}></EditUserInformation>
        });
    }

    if (hasPermission(userManagementPermissions.LOT_RELATIONS.key)) {
        tabs.push({
            label: <><IconLot width={25} height={25} className={classes.labelIcon}/>Lot Relations</>,
            component: <Paper className={classes.paper}>
                <EditUserLotRelations userId={user?.id} lots={lots} relations={relations} refetchUsers={refetchUsers}
                                      setRelations={setRelations}
                                      onCancel={onCancel}/>
            </Paper>
        });
    }

    if (hasPermission(userManagementPermissions.ACCESS_LOT_CLIENT.key)) {
        tabs.push(
            {
                label: <><IconKey width={25} height={25} className={classes.labelIcon}/>Access</>,
                component: <EditUserRestrictions user={user} userClaims={userClaims} lots={lots} clients={clients}
                                                 refetchUsers={refetchUsers}
                                                 userLotRelations={userLotRelations}></EditUserRestrictions>
            });
    }
    if (hasPermission(userManagementPermissions.TASK.key)) {
        tabs.push(
            {
                label: <><AlarmOnOutlinedIcon width={25} height={25} className={classes.labelIcon}/>Tasks</>,
                component: <Paper className={classes.componentWrapper}><UserTasksList userId={user?.id}/></Paper>
            });
    }

    if (hasPermission(userManagementPermissions.PERMISSIONS.key)) {
        tabs.push({
            label: <><VerifiedUserOutlinedIcon width={25} height={25} className={classes.labelIcon}/>Permissions</>,
            component: <EditUserPermissions userId={user?.id} refetchUsers={refetchUsers} onCancel={onCancel}
                                            rolesData={roles} user={user}/>
        })
    }

    return (
        <Dialog open={!!user?.id} onClose={() => onCancel()} aria-labelledby="form-dialog-title" fullWidth
                maxWidth='lg'>
            <DialogTitle id="form-dialog-title">
                <Grid container direction='row' alignItems='center' spacing={2}>
                    <Grid item>
                        <IconUser width={40} height={40} className={classes.titleIcon}/>
                    </Grid>
                    <Grid item>
                        Edit User
                        <div className={classes.subTitle}>{user?.email}</div>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <div className={classes.editUserDialogContent}>
                    <TabsWrapper tabs={tabs}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onCancel()
                }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default EditUserDialog;
