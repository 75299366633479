import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {Grid, Paper} from "@material-ui/core";
import {SecurityPhotosStyle} from "./SecurityPhotosStyle";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useGetSecurityPhotosByLotIdQuery } from "./SecurityPhotosSlice";
import FormatService from "services/formatService";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AssociatedLotsDropDown from "components/SharedDropdowns/LotsDropDown/AssociatedLotsDropDown";
import { IconPhoto } from "icons";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import DropdownFilter from "components/DropdownFilter";

const SecurityPhotos = () => {
    const params = useParams();
    const classes = SecurityPhotosStyle();
    const [lotId, setLotId] = useState(params.id);
    const [createdDateFilter, setCreatedDateFilter] = useState("");
    let { data: photos, error: error, isLoading: isLoading, refetch: refecthSecurityPhotos } = useGetSecurityPhotosByLotIdQuery(lotId);
    const [photosCopy, setPhotosCopy] = useState([]);

    useEffect(() => {
        setPhotosCopy(photos)
    }, [photos]);
    
    useEffect(() => {
        setCreatedDateFilter([]);
        refecthSecurityPhotos(lotId);
    }, [lotId]);

    let images = photosCopy || [];
    images = images.map(a => ({
        ...a,
        month: FormatService.formatToMonth(a.createdDate),
        createdDate: FormatService.formatToMonthAndYear(a.createdDate),
        formatShowByGroup: FormatService.formatDate(a.createdDate)
    }))
    images = images.sort((a, b) => (new Date(a.month) - new Date(b.month)));

    const groupedImagesByDate = {};

    images.forEach(img => {
        if (!groupedImagesByDate[img.month]){
            groupedImagesByDate[img.month] = [];
        }
        groupedImagesByDate[img.month].push(img);
    });

    let imagesDates = Object.keys(groupedImagesByDate);

    let imagesForFitlter = photos || [];
    imagesForFitlter = imagesForFitlter.map(a => ({
        ...a,
        month: FormatService.formatToMonth(a.createdDate),
        createdDate: FormatService.formatToMonthAndYear(a.createdDate),
        formatShowByGroup: FormatService.formatDate(a.createdDate)
    }))
    imagesForFitlter = imagesForFitlter.sort((a, b) => (new Date(a.month) - new Date(b.month)));

    let dateFilter = imagesForFitlter.map(a => ({
        value: FormatService.formatToMonthAndYear(a.createdDate),
        name: FormatService.formatToMonthAndYear(a.createdDate)
    }));

    dateFilter = new Map([
        ...dateFilter.map((date) => [date.value, date.name])
      ]);

    
    dateFilter = [...dateFilter].map(([name, value]) => ({ name, value }));
    
    return (
        <>
            {<LoadingSpinner loading={isLoading} />}
            <Paper className={`${classes.card}`} elevation={1}>
            <div className={classes.pageContent}>
                <div className={classes.header}>
                    <Grid container alignItems="center" className={classes.title}>
                        <IconPhoto style={{ marginRight: '2.5em' }} />
                        <div style={{marginLeft: 5}}>{'Security Photos'}</div>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <AssociatedLotsDropDown
                                identifier="lot"
                                title="Lot"
                                value={lotId}
                                onSelect={val => setLotId(val)}
                                showEmpty
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <DropdownFilter
                            title={"Folder Date"}
                            identifier={"createdDateFilter"}
                            values={dateFilter}
                            onSelect={setCreatedDateFilter}
                            value={createdDateFilter}
                            showEmpty
                            required
                        />
                        </Grid>
                       
                    </Grid>
                </div>
            </div>
                <Grid style={{width: "90%", display: "flex", justifyContent: "space-around", flexDirection: "row"}} container spacing={2}>
                    {
                        (imagesDates && imagesDates?.length > 0) ?
                            imagesDates.map((imageDate) => 
                                <Grid>
                                    <Grid item>
                                    <div style={{display: "flex", justifyContent: "start"}}>
                                        <div style={{color: "black"}}>
                                            Folder Date:    
                                        </div>
                                        <div className="primary" style={{fontWeight: "bold", paddingLeft: 4}}>
                                            {!!groupedImagesByDate[imageDate][0].createdDate ? `  ${groupedImagesByDate[imageDate][0].createdDate}` : '  Unknown'}
                                        </div>
                                    </div>
                                    
                                </Grid>
                                    <Grid container direction="column">
                                        <Grid container className={classes.containerFolter}>
                                            <div>
                                                <Carousel dynamicHeight width={500} autoPlay={false} useKeyboardArrows infiniteLoop>
                                                {
                                                    groupedImagesByDate[imageDate].map((img, i) => 
                                                        <div key={i} onClick={() => window.open(img.url)}>
                                                            <img
                                                                src={img.url}                                    
                                                                alt=""
                                                            />
                                                            <p className="legend">{img.formatShowByGroup} - Click here to open the photo.</p>
                                                        </div>
                                                    )
                                                }
                                                </Carousel>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) :
                            <EmptyPlaceholder text="There are no security photos to show."/>
                    }
                </Grid>
            </Paper>
        </>
    )
}

export default SecurityPhotos;


