import { Checkbox, Grid, IconButton, Tooltip } from "@material-ui/core";
import { FormBuilderStyles } from "../style";
import { IconCopy, IconDelete } from "icons";
import { questionTypes } from "../formBuilderConsts";

export const QuestionActions = ({ question, questionIndex, onDeleteQuestion, onDuplicateQuestion, onQuestionChange }) => {
    const classes = FormBuilderStyles();
    const fields = [
        {
            name: 'includeNotes',
            label: 'Include Note'
        },
        {
            name: 'reasonToFail',
            label: 'Reason to fail'
        }
    ];

    if(question?.questionType !== questionTypes.Image && question?.questionType !== questionTypes.Signature) {
        fields.unshift({
            name: 'includePhoto',
            label: 'Include Photo'
        })
    }

    return (
        <Grid container justifyContent="space-between" className={classes.questionActions}>
            <Grid item xs={9}>
                <span className={classes.questionFlag}>
                    <Checkbox
                        color="primary"
                        checked={question?.['required'] ?? false}
                        onChange={(e) => onQuestionChange(questionIndex, { ...question, ['required']: e.target.checked })}
                    />
                    <b>Required</b>
                </span>
                {fields.map(field => <span className={classes.questionFlag}>
                    <Checkbox
                        color="primary"
                        checked={question?.[field.name] ?? false}
                        onChange={(e) => onQuestionChange(questionIndex, { ...question, [field.name]: e.target.checked })}
                    />
                    {field.label}
                </span>
                )}
            </Grid>
            <Grid item>
                <Tooltip title={`Duplicate question`}>
                    <IconButton onClick={() => onDuplicateQuestion(questionIndex)}>
                        <IconCopy />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Remove question`}>
                    <IconButton onClick={() => onDeleteQuestion(questionIndex)}>
                        <IconDelete />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}