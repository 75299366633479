import * as React from "react";

function SvgIconClean(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m23.75 0-.834 3.036-1.666.893 1.666.893.834 2.678.834-2.679 1.666-.893-1.666-.892L23.75 0zm-.239 13.57-1.349-4.047A2.953 2.953 0 0 0 19.357 7.5h-8.992A2.953 2.953 0 0 0 7.56 9.523L6.21 13.57A1.976 1.976 0 0 0 5 15.39v4.93c0 .743.417 1.383 1.025 1.72a.931.931 0 0 1-.016.064.478.478 0 0 0-.023.127v2.034a.986.986 0 0 0 .986.986h.986a.986.986 0 0 0 .986-.986v-1.972h11.834v1.972a.986.986 0 0 0 .986.986h.986a.986.986 0 0 0 .986-.986V22.23a.486.486 0 0 0-.023-.128c-.005-.021-.011-.042-.015-.064a1.966 1.966 0 0 0 1.024-1.719v-4.93c0-.818-.5-1.52-1.21-1.819zM6.972 20.32v-4.931H22.75l.002 4.93H6.972zm3.393-10.848h8.99c.426 0 .802.27.937.675l1.09 3.27H8.34l1.09-3.27a.986.986 0 0 1 .935-.675zm-.927 9.861a1.48 1.48 0 1 0 0-2.958 1.48 1.48 0 0 0 0 2.958zm12.327-1.479a1.48 1.48 0 1 1-2.959 0 1.48 1.48 0 0 1 2.959 0zM17.656 4l.469-1.5.469 1.5L20 4.375l-1.406.375-.469 1.5-.469-1.5-1.406-.375L17.656 4z"
                fill="#0090FE" />
        </svg>
    );
}

export default SvgIconClean;
